import React, { useEffect, useState } from 'react'
import { getCy365ExchangeTable, getExchangeOU, getExchangeSourceCounty, getOffice365ExchangeCounts } from '../../../../Methods/CyKitInvestigate'
import NoDataFound from '../../../../components/NoDataFount/NoDataFound'
import { ArrowsOutSimple } from 'phosphor-react'
import { Tab, Table, Tabs, Button } from 'react-bootstrap'
import TableLoader from '../../../../components/Loader/TableLoader'
import Pagination from '../../../../components/Pagination/PaginationTi'
import styles from '../CykitHunter.styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import BarGraph from '../../../../components/Charts/BarGraph';
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader'
import useOnclickOutside from 'react-cool-onclickoutside';
import AddFilter from '../../../../components/AddFilter/AddFilter'
import FilterList from '../../../../components/FilterList/FilterList'
import InlineFilter from '../../../../components/InlineFilter/InlineFilter'
import CykitINVExport from '../../../Investigate/InvestigationSummary/CyDashboards/Export'
import { fpostDl } from '../../../../axios/axios'
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument'
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore'
import CustomToolTip from '../../../../components/ToolTip/CustomToolTip'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";

const Office365Exchange = ({ globalSearch, dateFilter, setDateFilter, exchangePageCount, setExchangePageCount, init }) => {

    // loader
    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)
    const [operationLoader, setOperationLoader] = useState(true)
    const [userIdLoader, setUserIdLoader] = useState(true)

    const [sourceCountryCount, setSourceCountryCount] = useState([])
    const [userIdCount, setUserIdCount] = useState([])

    // graph
    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [sourceCountryData, setSourceCountryData] = useState([])

    const [operationLabel, setOperationLabel] = useState([])
    const [operationData, setOperationData] = useState([])

    const [userIdLabel, setUserIdLabel] = useState([])
    const [userIdData, setUserIdData] = useState([])

    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])

    // table
    const [tableData, setTableData] = useState([])

    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    // loader
    const [loadTableData, setLoadTableData] = useState(true)
    //filter
    const [filter, setFilter] = useState([]);
    // const [dateFilter, setDateFilter] = useState({});

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()
    const datalakeURL = localStorage.getItem("DATALAKE_URL");
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    const [doc, setDoc] = useState(null)

    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    const [manageDatas, setManageDatas] = useState([
        { text: "Client IP", value: "ClientIP", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "User ID", value: "UserId", isSelected: true },
        { text: "External Access", value: "ExternalAccess", isSelected: false },
        { text: "Operation", value: "Operation", isSelected: true },
        { text: "Folder Path", value: "Folder.Path", isSelected: true },
        { text: "Mail box Owner", value: "MailboxOwnerUPN", isSelected: false },
        { text: "Subject", value: "Item.Subject", isSelected: true },
        { text: "Result Status", value: "ResultStatus", isSelected: true },
        { text: "Client Info", value: "ClientInfoString", isSelected: true },
        { text: "Attachments", value: "Item.Attachments", isSelected: false },


    ])

    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.office.exchange') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.office.exchange")))
            }
        })
    }, [manageDatas])
    useEffect(() => {
        if (dateFilter !== undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], date: dateFilter } },
            };
            setPage(1)
            localStorage.setItem("filters", JSON.stringify(temp2));
        }
    }, [dateFilter])
    useEffect(() => {
        if (globalSearch === '' && init === true) {
            console.log()
        }
        else if (globalSearch === '' && init === false) {
            console.log(filter)
            var newfilter = filter.filter(item => item?.isGlobal != true)

            setFilter(
                newfilter
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setPage(1)
            // let temp2 = {
            //     ...storedFilter,
            //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter] } },
            //   };

            //   localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable(newfilter, dateFilter)
        }
        else {
            var filterNew = [{
                "column": {
                    "label": "Raw Search",
                    "value": "raw.global",
                    "isWildCard": null,
                    "isNumber": null
                },
                "type": "is",
                "selectedValue": globalSearch,
                "from": "",
                "to": "",
                "isInline": false,
                "isGlobal": true
            }]
            var newfilter = filter.filter(item => item?.isGlobal != true)
            setFilter((filter) => [...newfilter, ...filterNew]
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter,...filterNew] } },
              };

              localStorage.setItem("filters", JSON.stringify(temp2));
            setPage(1)
            getFilteredTable([...newfilter, ...filterNew], dateFilter)
        }
    }, [globalSearch])
    const module = 'hunter'
    const tab = 'cykit-o365ex'
    let count = [];
    let count2;
    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            if (currentPageFilter !== undefined) {
                setFilter(currentPageFilter);
            }
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);


    useEffect(() => {
        // getcounts



        // graph
        // source country

        if (!count?.length && !count2 && !(globalSearch?.length>0 && init === true)) {

        setLoadTableData(true)
        // table
        getCy365ExchangeTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            setTableData(hits.hits)
            setLoadTableData(false)
            setExchangePageCount(hits.total.value)

        }).catch((err) => {
            setTableData([])
            setExchangePageCount(0)

            console.log("Error", err)
            setLoadTableData(false)
        })
    }
    }, [page, perPage, filter, dateFilter])

    useEffect(() => {
        setLoadTableData(true)
    }, [page, perPage])

    const getFilteredTable = async (filter, date = {}) => {
        setLoadTableData(true)
        if (filter || date?.to) {
            await getCy365ExchangeTable(filter, date, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTableData(hits.hits)
                setLoadTableData(false)
                console.log("t1", hits.hits)
                setExchangePageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
                setExchangePageCount(0)

            })
        }
        else {
            await getCy365ExchangeTable("", "", 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTableData(hits.hits)
                setLoadTableData(false)
                setExchangePageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])
                setExchangePageCount(0)

                setLoadTableData(false)

            })
        }
    }
    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value,
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }
    const O365UserTypes = {
        "0": {
            "Value": 0,
            "MemberName": "Regular",
            "Description": "A regular user."
        },
        "1": {
            "Value": 1,
            "MemberName": "Reserved",
            "Description": "A reserved user."
        },
        "2": {
            "Value": 2,
            "MemberName": "Admin",
            "Description": "An administrator."
        },
        "3": {
            "Value": 3,
            "MemberName": "DcAdmin",
            "Description": "A Microsoft datacenter operator."
        },
        "4": {
            "Value": 4,
            "MemberName": "System",
            "Description": "A system account."
        },
        "5": {
            "Value": 5,
            "MemberName": "Application",
            "Description": "An application."
        },
        "6": {
            "Value": 6,
            "MemberName": "ServicePrincipal",
            "Description": "A service principal."
        },
        "7": {
            "Value": 7,
            "MemberName": "CustomPolicy",
            "Description": "A custom policy."
        },
        "8": {
            "Value": 8,
            "MemberName": "SystemPolicy",
            "Description": "A system policy."
        }
    }
    const MailboxAuditLogEvents = {
        "Create": {
            "Action": "Create",
            "Description": "Item created in the mailbox, like a new email or calendar appointment."
        },
        "MailboxLogin": {
            "Action": "MailboxLogin",
            "Description": "User logged into their mailbox."
        },
        "Update": {
            "Action": "Update",
            "Description": "An existing item was updated."
        },
        "MailItemsAccessed": {
            "Action": "MailItemsAccessed",
            "Description": "Mailbox or individual message is accessed."
        },
        "MoveToDeletedItems": {
            "Action": "MoveToDeletedItems",
            "Description": "Item was moved to the 'Deleted Items' folder."
        },
        "SoftDelete": {
            "Action": "SoftDelete",
            "Description": "Item was deleted from the 'Deleted Items' folder."
        },
        "HardDelete": {
            "Action": "HardDelete",
            "Description": "Item was permanently deleted."
        },
        "Send": {
            "Action": "Send",
            "Description": "User sent or replied to an email."
        },
        "SendAs": {
            "Action": "SendAs",
            "Description": "An email was sent as another user (requires SendAs permission)."
        },
        "SendOnBehalf": {
            "Action": "SendOnBehalf",
            "Description": "An email was sent on behalf of another user (requires SendOnBehalf permission)."
        },
        "Move": {
            "Action": "Move",
            "Description": "Item was moved to a different folder."
        },
        "Copy": {
            "Action": "Copy",
            "Description": "Item was copied to another folder."
        },
        "FolderBind": {
            "Action": "FolderBind",
            "Description": "User viewed content in a folder."
        }
    }
    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.office.exchange", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={{
                    position: "absolute",
                    right: "0",
                    top: "-48px",
                    zIndex: 3
                }}
            >

                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "ClientIP", label: "Client IP" },
                                { value: "UserId", label: "User ID" },
                                { value: "cybSourceCountry", label: "Source Country", isWildCard: true },
                                { value: "ClientInfoString", label: "Client Info", isWildCard: true },
                                { value: "ExternalAccess", label: "External Access" },
                                { value: "Folder.Path", label: "Folder Path", isWildCard: true },
                                { value: "MailboxOwnerUPN", label: "Mail box Owner" },
                                { value: "ResultStatus", label: "Result Status" },
                                { value: "Item.Subject", label: "Subject", isWildCard: true },
                                { value: "Item.SizeInBytes", label: "Size In Bytes" },
                                { value: "Operation", label: "Operation", isWildCard: true },
                                { value: "Item.Attachments", label: "Attachments", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                <div className="position-relative">
                    <Button
                        variant="light"
                        ref={ref}
                        onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                    >
                        Manage columns
                    </Button>
                    {showManageColumn && (
                        <div
                            className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                            style={{
                                position: "absolute",
                                right: 0,
                                zIndex: "111",
                                minWidth: "20rem",
                                maxHeight: "50vh",
                                overflow: "auto"
                            }}
                        >
                            <div className="table-fields">
                                {manageDatas?.map((item) => (
                                    <div className="d-flex align-items-center py-2 gap-2">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={item.isSelected ? true : false}
                                            // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {item.text}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="position-relative ms-2">
                    <CykitINVExport tabledata={getCy365ExchangeTable} type={"365Exchange"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    currentPageCount={currentPageCount}
                    totalDataCounts={exchangePageCount}
                />


            </div>

            <div className="col-12 d-flex flex-column  rounded-3 bg-white position-relative">


                <div className="col-12">



                    <div className="" style={styles.tableWrap}>
                        <FilterList
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            getFilteredTable={getFilteredTable}
                            module={module}
                            tab={tab}
                        />
                        {loadTableData ? (
                            <TableLoader rowCount={30} />
                        ) : (
                            tableData?.length && tableData?.length !== 0 ? (
                                <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                            <th>Date</th>
                                            <th>Time</th>
                                            {manageDatas.map((item) => (
                                                item.isSelected === true &&
                                                <th>{item.text}</th>
                                            ))}
                                            {/* <th>Client IP</th>
                                                    <th>User ID</th>
                                                    <th>Source Country</th>
                                                    <th>Client Info</th>
                                                    <th>External Access</th>
                                                    <th>Folder Path</th>
                                                    <th>Mail box Owner</th>
                                                    <th>Result Status</th>
                                                    <th>Subject</th>
                                                    <th>Size In Bytes</th>
                                                    <th>Attachments</th>
                                                    <th>Operation</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {/* <tbody>
                                        {tableData?.map((item, index) => (
                                            <tr
                                                onMouseOver={() =>
                                                    setShowInlineFilter({ show: true, index: index })
                                                }
                                                onMouseOut={() =>
                                                    setShowInlineFilter({ show: false, index: index })
                                                }>
                                                <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                {manageDatas?.map((data) => (
                                                    data.value === "ClientIP" && data.isSelected === true ? (
                                                        <td>{item?._source?.ClientIP}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ClientIP",
                                                                                    label: "Client IP",
                                                                                }}
                                                                                value={item?._source?.ClientIP}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "UserId" && data.isSelected === true ? (
                                                        <td>{item?._source?.UserId}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "UserId",
                                                                                    label: "User ID",
                                                                                }}
                                                                                value={item?._source?.UserId}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                        <td>{item?._source?.cybSourceCountry}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "cybSourceCountry",
                                                                                    label: "Source Country",
                                                                                }}
                                                                                value={item?._source?.cybSourceCountry}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "ClientInfoString" && data.isSelected === true ? (
                                                        <td>{item?._source?.ClientInfoString}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ClientInfoString",
                                                                                    label: "Client Info",
                                                                                }}
                                                                                value={item?._source?.ClientInfoString}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "ExternalAccess" && data.isSelected === true ? (
                                                        <td>{item?._source?.ExternalAccess}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ExternalAccess",
                                                                                    label: "External Access",
                                                                                }}
                                                                                value={item?._source?.ExternalAccess}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "Folder.Path" && data.isSelected === true ? (
                                                        <td>{item?._source?.["Folder.Path"]}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "Folder.Path",
                                                                                    label: "Folder Path",
                                                                                }}
                                                                                value={item?._source?.["Folder.Path"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "MailboxOwnerUPN" && data.isSelected === true ? (
                                                        <td>{item?._source?.["MailboxOwnerUPN"]}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "MailboxOwnerUPN",
                                                                                    label: "Mail box Owner",
                                                                                }}
                                                                                value={item?._source?.["MailboxOwnerUPN"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "ResultStatus" && data.isSelected === true ? (
                                                        <td>{item?._source?.ResultStatus}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ResultStatus",
                                                                                    label: "Result Status",
                                                                                }}
                                                                                value={item?._source?.ResultStatus}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "Item.Subject" && data.isSelected === true ? (
                                                        <td>{item?._source?.["Item.Subject"]}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "Item.Subject",
                                                                                    label: "Subject",
                                                                                }}
                                                                                value={item?._source?.["Item.Subject"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "Item.Attachments" && data.isSelected === true ? (
                                                        <td>{item?._source?.["Item.Attachments"]}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "Item.Attachments",
                                                                                    label: "Attachments",
                                                                                }}
                                                                                value={item?._source?.["Item.Attachments"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "Operation" && data.isSelected === true ? (
                                                        <td><span className="font-monospace">
                                                        <CustomToolTip content={<span>{item?._source?.Operation}</span>} dataPlacement={"bottom"}
                                                            dataToggle={
                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                        <span>Description - </span>
                                                                        <span><strong>{MailboxAuditLogEvents[item?._source?.Operation]?.Description}</strong></span>
                                                                    </div>
                                                                    {item?.item?.["Item.ParentFolder.Path"] &&
                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                            <span>Path - </span>
                                                                            <span><strong>{item?._source?.["Item.ParentFolder.Path"]}</strong></span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            } />
                                                        {showInlineFilter.show &&
                                                            showInlineFilter.index === index && (
                                                                <div className="inline-filter-wrap">
                                                                    <InlineFilter
                                                                        filter={filter}
                                                                        setFilter={setFilter}
                                                                        column={{
                                                                            value: "Operation",
                                                                            label: "Operation",
                                                                        }}
                                                                        value={item?._source?.Operation}
                                                                        getFilteredTable={getFilteredTable}
                                                                        module={module}
                                                                        tab={tab}
                                                                        dateFilter={dateFilter}
                                                                        setPage={setPage}

                                                                    />
                                                                </div>
                                                            )}
                                                    </span>
                                                        </td>
                                                    ) : (<></>)
                                                    // <td>{item?._source?.["Item.SizeInBytes"]}</td>
                                                ))}
                                                <td>
                                                    <a onClick={() => { setExpClickedNode(item._id); setExpClose(false) }} style={{ marginLeft: "20px" }}>
                                                        {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                        {/* <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> */} 
                                                                                <tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "ClientIP" && data.isSelected === true ? (
                                                                <td>{item?._source?.ClientIP}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ClientIP",
                                                                                            label: "Client IP",
                                                                                        }}
                                                                                        value={item?._source?.ClientIP}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "UserId" && data.isSelected === true ? (
                                                                <td className='text-break'><CustomToolTip content={<span>{item?._source?.UserId}</span>} dataPlacement={"bottom"}
                                                                    dataToggle={
                                                                        <div className="d-flex flex-column text-left">
                                                                            {/* {item?.item?.UserType && */}
                                                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                <span>Account type - </span>
                                                                                <span><strong>{O365UserTypes[item?._source?.UserType]?.MemberName}</strong></span>
                                                                            </div>
                                                                            {/* } */}
                                                                        </div>
                                                                    } />
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "UserId",
                                                                                            label: "User ID",
                                                                                        }}
                                                                                        value={item?._source?.UserId}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                                <td>{item?._source?.cybSourceCountry}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "cybSourceCountry",
                                                                                            label: "Source Country",
                                                                                        }}
                                                                                        value={item?._source?.cybSourceCountry}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ClientInfoString" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{item?._source?.ClientInfoString?.length > 30 ? item?._source?.ClientInfoString?.substring(0, 30) + "..." : item?._source?.ClientInfoString}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div style={styles.tooltipWrap}>
                                                                                {item?._source?.ClientInfoString}

                                                                            </div>
                                                                        }>

                                                                    </CustomToolTip>
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ClientInfoString",
                                                                                            label: "Client Info",
                                                                                        }}
                                                                                        value={item?._source?.ClientInfoString}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ExternalAccess" && data.isSelected === true ? (
                                                                <td>{item?._source?.ExternalAccess}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ExternalAccess",
                                                                                            label: "External Access",
                                                                                        }}
                                                                                        value={item?._source?.ExternalAccess}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Folder.Path" && data.isSelected === true ? (
                                                                <td>{item?._source?.["Folder.Path"]}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Folder.Path",
                                                                                            label: "Folder Path",
                                                                                        }}
                                                                                        value={item?._source?.["Folder.Path"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "MailboxOwnerUPN" && data.isSelected === true ? (
                                                                <td>{item?._source?.["MailboxOwnerUPN"]}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "MailboxOwnerUPN",
                                                                                            label: "Mail box Owner",
                                                                                        }}
                                                                                        value={item?._source?.["MailboxOwnerUPN"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ResultStatus" && data.isSelected === true ? (
                                                                <td>{item?._source?.ResultStatus}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ResultStatus",
                                                                                            label: "Result Status",
                                                                                        }}
                                                                                        value={item?._source?.ResultStatus}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Item.Subject" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{item?._source?.["Item.Subject"]?.length > 30 ? item?._source?.["Item.Subject"]?.substring(0, 30) + "..." : item?._source?.["Item.Subject"]}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div style={styles.tooltipWrap}>
                                                                                {item?._source?.["Item.Subject"]}

                                                                            </div>
                                                                        }>

                                                                    </CustomToolTip>
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Item.Subject",
                                                                                            label: "Subject",
                                                                                        }}
                                                                                        value={item?._source?.["Item.Subject"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Item.Attachments" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{(item?._source?.["Item.Attachments"]?.split(';', 1)[0]?.length>30?item?._source?.["Item.Attachments"]?.split(';', 1)[0]?.substring(0,30)+"...":item?._source?.["Item.Attachments"]?.split(';', 1)[0] )}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div style={styles.tooltipWrap}>
                                                                                <ul>
                                                                                    {item?._source?.["Item.Attachments"]?.split(";").map(item =>
                                                                                        <li>{item}</li>
                                                                                    )}
                                                                                </ul>

                                                                            </div>
                                                                        }>

                                                                    </CustomToolTip>
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Item.Attachments",
                                                                                            label: "Attachments",
                                                                                        }}
                                                                                        value={item?._source?.["Item.Attachments"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Operation" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        <CustomToolTip content={<span>{item?._source?.Operation}</span>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                        <span>Description - </span>
                                                                                        <span><strong>{MailboxAuditLogEvents[item?._source?.Operation]?.Description}</strong></span>
                                                                                    </div>
                                                                                    {item?.item?.["Item.ParentFolder.Path"] &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            <span>Path - </span>
                                                                                            <span><strong>{item?._source?.["Item.ParentFolder.Path"]}</strong></span>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            } />
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Operation",
                                                                                            label: "Operation",
                                                                                        }}
                                                                                        value={item?._source?.Operation}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : (<></>)
                                                            // <td>{item?._source?.["Item.SizeInBytes"]}</td>
                                                        ))}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                </Table>
                            ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                    <NoDataFound errorText={"No data found"} />
                                </div>
                            )
                        )}
                    </div>
                </div>

            </div>
            <ExpandedDocument clickedNode={expclickedNode} close={expclose} setDoc={setDoc} doc={doc} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_o365}/_search?track_total_hits=true`} req={fpostDl} />
        </div>
    )
}

export default Office365Exchange