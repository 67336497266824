export let anomaliesDataList = [
  {
    label: "UEBA ID",
    key: "ueba_id",
  },

  {
    label: "User Name",
    key: "user_name",
  },

  {
    label: "User Domain",
    key: "user_domain",
  },

  {
    label: "Subject",
    key: "subject",
  },

  {
    label: "Host Name",
    key: "host_name",
  },

  {
    label: "Host Name",
    key: "hostname",
  },

  {
    label: "Host OS",
    key: "host_os",
  },

  {
    label: "Detection Name",
    key: "detection_name",
  },

  {
    label: "Detection date",
    key: "detection_timestamp",
  },

  {
    label: "Detection time",
    key: "detection_timestamp",
  },

  {
    label: "Event Category",
    key: "event_category",
  },

  {
    label: "Event Action",
    key: "event_action",
  },

  {
    label: "Event Code",
    key: "event_code",
  },

  {
    label: "Logon Type",
    key: "LogonType",
  },
  {
    label: "Logon ID",
    key: "SubjectLogonId",
  },

  {
    label: "Process Name",
    key: "process_name",
  },

  {
    label: "File Name",
    key: "file_name",
  },

  {
    label: "File Path",
    key: "file_path",
  },

  {
    label: "Source IP",
    key: "source_ip",
  },

  {
    label: "Source Port",
    key: "source_port",
  },

  {
    label: "Destination IP",
    key: "dest_ip",
  },

  {
    label: "Destination Port",
    key: "dest_port",
  },

  {
    label: "Source Bytes",
    key: "source_bytes",
  },

  {
    label: "Destination Bytes",
    key: "dest_bytes",
  },

  {
    label: "User Id",
    key: "UserId",
  },

  {
    label: "Client IP",
    key: "ClientIP",
  },

  {
    label: "Operation",
    key: "Operation",
  },

  {
    label: "Result Status",
    key: "ResultStatus",
  },

  {
    label: "Request Type",
    key: "ExtendedProperties.RequestType",
  },

  {
    label: "Request Type",
    key: "ExtendedProperties_RequestType",
  },

  {
    label: "Source Country",
    key: "cybSourceCountry",
  },

  {
    label: "Source Country",
    key: "cybersourcecountry",
  },

  {
    label: "Device Properties OS",
    key: "DeviceProperties.OS",
  },

  {
    label: "Device Properties OS",
    key: "DeviceProperties_OS",
  },

  {
    label: "Browser Type",
    key: "DeviceProperties.BrowserType",
  },

  {
    label: "Browser Type",
    key: "DeviceProperties_BrowserType",
  },

  {
    label: "Workload",
    key: "Workload",
  },

  {
    label: "Event Source",
    key: "EventSource",
  },

  {
    label: "Type",
    key: "type",
  },

  {
    label: "Item Type",
    key: "ItemType",
  },

  {
    label: "Source File Name",
    key: "SourceFileName",
  },

  {
    label: "From Address",
    key: "email_from_address",
  },

  {
    label: "Receiver Address",
    key: "email_receiver_address",
  },

  {
    label: "Delivery Action",
    key: "delivery_action",
  },

  {
    label: "Host IP",
    key: "host_ip",
  },

  {
    label: "Host MAC",
    key: "host_mac",
  },
];
