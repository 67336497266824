import axios from 'axios';
import { Pen, PencilSimple, Plus, X } from 'phosphor-react';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Badge, Button, FormControl, InputGroup } from 'react-bootstrap';
import useOnclickOutside from 'react-cool-onclickoutside';
import Select from "react-select";
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { fget } from '../../axios/axios';
import AlertBox from '../AlertBox/AlertBox';
import globalStyles from '../../constants/globalStyles';
import CustomModal from '../CustomModal/CustomModal';
import styles from './SearchDeepDark.style';
import TableViewMore from '../ViewMoreBtn/TableViewMore';
import LoaderBtn from '../LoaderBtn/LoaderBtn';
import NoDataFound from '../NoDataFount/NoDataFound';
import { SingleTileLoader } from '../Loader/SingleTileLoader';
import { getTimeDifferenceFromTimestamp } from '../../Utils/Utils';
import DotLoader from '../Loader/DotLoader';

const SearchDeepDark = ({ changeFunction, setDetailsPageKeyword, detailsPageKeyword, setLoader, loader }) => {
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")
    const [searchQuery, setSearchQuery] = useState('');
    const [previousSearches, setPreviousSearches] = useState([]);
    const [showRecentList, setShowRecentList] = useState(false);
    const [watchList, setWatchList] = useState([])
    const [updatedData, setUpdatedData] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);

    // keyword Loader
    const [keywordListLoader, setKeywordListLoader] = useState(true);

    // modal
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [addModalVisible, setAddModalVisible] = useState(false)

    // edit

    const [editKeywordQuery, setEditKeywordQuery] = useState('')

    // alert popup
    const [alertPopup, setAlertPopup] = useState(false);

    const [alertMessage, setAlertMessage] = useState('Success')
    const [statusMessage, setStatusMessage] = useState('success')

    const [activeKeyword, setActiveKeyword] = useState(null)

    const dropData = [
        { value: "url", label: "URL" },
        { value: "text", label: "Text" },
    ]

    const [selectedValue, setSelectedValue] = useState(null)

    const ref = useOnclickOutside(() => {
        setShowRecentList(false);
    });

    // useEffect(() => {

    // }, [searchQuery, watchList])

    // const handleSearch = (term) => {

    // }

    const handleEdit = (editData) => {
        // setSearchQuery(editData.keyword)
        setEditKeywordQuery(editData?.keyword)
        setUpdatedData(editData)
        setSelectedValue({ value: editData?.type, label: editData?.type })
    }

    const handleDropChange = (selectedData) => {
        setTypeData(selectedData.value)
        setSelectedValue(selectedData)
    }

    const handleClosePopup = () => {
        setAlertPopup(false);
    };

    useEffect(() => {

        fget(`${apiBaseUrl}/ti/tor-intel/keywords`).then(({ data }) => {
            data && setWatchList(data)
            setKeywordListLoader(false)
            setLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setKeywordListLoader(false)
            setLoader(false)
        })
    }, [alertPopup])

    const existKeywordCheck = () => {

        if (watchList?.filter((item) => item?.keyword?.toLowerCase() === editKeywordQuery?.toLowerCase())?.length > 0) {
            return false
        } else {
            return true
        }
    }

    const handleEditField = (e) => {
        e.preventDefault();

        if (updatedData) {
            setButtonLoader(true)

            if (existKeywordCheck()) {
                let data = {
                    "keyword": editKeywordQuery,
                    "type": typeData ? typeData : updatedData?.type,
                    "id": updatedData.id,
                    "updated_at": new Date().toISOString()
                }
                axios.put(`${apiBaseUrl}/ti/tor-intel/keywords`, data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("auth"),
                    },
                }).then((res) => {
                    setEditKeywordQuery('');
                    setUpdatedData(null)
                    setButtonLoader(false)
                    setAlertMessage("Updated")
                    setStatusMessage("")
                    setAlertPopup(true);
                    setEditModalVisible(false)
                    setKeywordListLoader(true)
                }).catch((err) => {
                    console.log("Error", err)
                    setButtonLoader(false)
                })
            } else {
                setButtonLoader(false)
                setAlertMessage("This Keyword already exist")
                setStatusMessage("error")
                setAlertPopup(true);
                setEditModalVisible(false)
            }

        }
    }

    // add keyword

    const handleAddKeyword = (e) => {
        e.preventDefault();

        setButtonLoader(true)

        if (existKeywordCheck()) {
            let data = {
                "keyword": editKeywordQuery,
                "type": typeData ? typeData : updatedData?.type,
                "id": updatedData?.id
            }
            axios.post(`${apiBaseUrl}/ti/tor-intel/keywords`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("auth"),
                },
            }).then((res) => {
                console.log(res)
                setEditKeywordQuery('');
                setUpdatedData(null)
                setButtonLoader(false)
                setAlertMessage("New keyword added")
                setStatusMessage("")
                setAlertPopup(true);
                setAddModalVisible(false)
                setKeywordListLoader(true)
                setLoader(true)
            }).catch((err) => {
                console.log("Error", err)
                setButtonLoader(false)
            })
        } else {
            setButtonLoader(false)
            setAlertMessage("Keyword already exist")
            setStatusMessage("error")
            setAlertPopup(true);
            setAddModalVisible(false)
        }


    }

    const handleRemove = (id) => {

        const confirmDelete = window.confirm(
            "Are you sure you want to delete this data?"
        );

        if (confirmDelete) {
            setKeywordListLoader(true)
            axios.delete(`${apiBaseUrl}/ti/tor-intel/keywords?id=${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("auth"),
                },
            }).then((res) => {
                setAlertMessage("Keyword deleted")
                setStatusMessage("error")
                setAlertPopup(true);
            }).catch((err) => {
                setAlertMessage("Failed")
                setStatusMessage("error")
                setAlertPopup(true);
                console.log("Error", err)
            })
        }

    }

    const checkMatch = watchList?.filter((data) => data?.keyword?.toLowerCase()?.includes(searchQuery?.toLowerCase()))

    const getMatchingSearches = () => {
        if (checkMatch) {
            return checkMatch
        } else {
            return []
        }
    };

    const handleClearClick = (e) => {
        setSearchQuery('');
        setUpdatedData(null)
    };

    const handleOpenDeepDarkModal = (display) => {
        setEditModalVisible(display)
    }

    function handleCloseModal() {
        setEditModalVisible(false)
        setAddModalVisible(false)
    }

    function renderDeepDarkModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={handleEditField}>
                    <div className="row">
                        <div className="form-group d-flex flex-column gap-3">
                            <div className="col-md-12">
                                <label className="control-label">Keyword</label>
                                <input type="text" className="form-control" id="keyword" placeholder="Enter Keyword" name="keyword" value={editKeywordQuery} onChange={(e) => setEditKeywordQuery(e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <label className="control-label">Type</label>
                                <Select options={dropData} value={selectedValue} onChange={handleDropChange} required />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        {/* <div className="form-group d-flex justify-content-end">
                            {buttonLoader ? (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Spinner animation="border" size="sm" />
                                </div>
                            ) : (
                                <Button className="btn-primary" type="submit">
                                    Update
                                </Button>
                            )}

                        </div> */}
                        <LoaderBtn isLoading={buttonLoader} btnText={"Update"} />
                    </div>
                </form>
            </div>
        )
    }


    // add keyword form

    const handleOpenAddKeywordModal = (display) => {
        setAddModalVisible(display)
    }

    function renderAddKeywordModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={handleAddKeyword}>
                    <div className="row">
                        <div className="form-group d-flex flex-column gap-3">
                            <div className="col-12">
                                <label className="control-label">Keyword</label>
                                <input type="text" className="form-control" id="keyword" placeholder="Enter Keyword" name="keyword" onChange={(e) => setEditKeywordQuery(e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <label className="control-label">Type</label>
                                <Select options={dropData} onChange={handleDropChange} required />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        {/* <div className="form-group d-flex justify-content-end">
                            {buttonLoader ? (
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Spinner animation="border" size="sm" />
                                </div>
                            ) : (
                                <Button className="btn-primary" type="submit">
                                    Add
                                </Button>
                            )}

                        </div> */}
                        <LoaderBtn isLoading={buttonLoader} btnText={"Add"} />
                    </div>
                </form>
            </div>
        )
    }

    return (

        <div className="col-12" style={{ position: "relative" }}>
            <div className='d-flex flex-column col-12 gap-3 p-4 position-sticky' style={{ backgroundColor: globalStyles.white, top: 0 }}>
                <div className='col-12'>
                    <h4 className='mb-0'>Keywords</h4>
                </div>
                <div className='d-flex col-12 gap-3'>
                    <InputGroup className="search-component">

                        <FormControl
                            type="search"
                            className="me-2 custom-search-input"
                            placeholder="Search for keyword"
                            onClick={() => setShowRecentList(true)}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => changeFunction(searchQuery, e, '')}
                            value={searchQuery} />
                        {searchQuery &&
                            <span onClick={handleClearClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg custom-clear-button" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </span>
                        }

                        <InputGroup.Text className="bg-white  " style={{ marginLeft: "-9px", cursor: "pointer" }} >
                            <span onClick={(e) => changeFunction(searchQuery, e, 'click')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                        </InputGroup.Text>

                    </InputGroup>
                    <div className=''>
                        <button onClick={() => handleOpenAddKeywordModal(true)} className='btn btn-primary d-flex btn-square'>
                            <TableViewMore
                                Icon={Plus}
                                size={24}
                                color={globalStyles.white}
                                weight="bold"
                                dataToggle={"New Keyword"} dataPlacement={"bottom"}
                            />
                        </button>
                    </div>
                </div>
            </div>

            <div className='ignore-onclickoutside d-flex col-12 p-4 pt-0 overflow-auto' style={{}} ref={ref}>
                <ul className='col-12 p-0 m-0 d-flex flex-column gap-3'>
                    {keywordListLoader ? (
                        <SingleTileLoader width="100%" count="13" />
                    ) : (
                        <>
                            {getMatchingSearches().length === 0 ? (
                                <>
                                    <NoDataFound errorText={"No data found"} />
                                </>
                            ) : (
                                <>
                                    {
                                        getMatchingSearches().map((item) => (
                                            <li className='p-3 rounded cursor-pointer list-item d-flex align-items-center'
                                                style={{
                                                    backgroundColor:
                                                        item?.keyword === activeKeyword || item?.keyword === detailsPageKeyword
                                                            ? globalStyles.yellowLite1
                                                            : globalStyles.greyLite1,
                                                    listStyle: "none",
                                                    cursor: loader ? "not-allowed" : "pointer"
                                                }}

                                                onClick={() => {
                                                    if (loader) {

                                                    } else {
                                                        setActiveKeyword(item.keyword); setDetailsPageKeyword(item.keyword);
                                                    }
                                                }}
                                            // style={{ listStyle: "none", display: "flex", alignItems: "center", padding: "0.4rem 1.1rem", justifyContent: "space-between", cursor: "pointer" }}
                                            // onClick={() => { setDetailsPageKeyword(item.keyword); setSearchQuery(item.keyword) }}
                                            >
                                                {/* <div className='d-flex justify-content-between align-items-center w-100'> */}
                                                <div className='col-10 d-flex flex-column'>
                                                    <span>
                                                        {item.keyword.length > 20 ? (item.keyword.substring(0, 20) + "...") : item.keyword}
                                                        {item?.count > 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {item?.count}
                                                            </Badge>
                                                        )}
                                                    </span>

                                                    {/* process */}
                                                    {item?.created_at && getTimeDifferenceFromTimestamp(item?.created_at) < 1 && (
                                                        <div className='d-flex justify-content-start mt-2'>
                                                            {/* <DotLoader text={"Processing"} /> */}
                                                            <span className='text-info' style={{ fontSize: globalStyles.label }}><i>Processing</i></span>
                                                        </div>
                                                    )}

                                                    {/* updated */}
                                                    {item?.updated_at && getTimeDifferenceFromTimestamp(item?.updated_at) < 1 && (
                                                        <>
                                                            {!item?.count && (
                                                                <div className='d-flex justify-content-start mt-2'>
                                                                    {/* <DotLoader text={"Processing"} /> */}
                                                                    <span className='text-info' style={{ fontSize: globalStyles.label }}><i>Processing</i></span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>


                                                <div className="button_wrap gap-3" style={{ marginLeft: "auto" }}>
                                                    {/* <PencilSimple onClick={() => { handleEdit(item); handleOpenDeepDarkModal(true) }} size={14} color={globalStyles.greyDark1} style={{ marginRight: "10px" }} /> */}
                                                    {/* <X onClick={() => handleRemove(item.id)} size={16} color="red" /> */}
                                                    <TableViewMore
                                                        Icon={PencilSimple}
                                                        size={16}
                                                        color={globalStyles.greyDark1}
                                                        // weight="bold"
                                                        dataToggle={"Edit"} dataPlacement={"bottom"}
                                                        onClick={() => { handleEdit(item); handleOpenDeepDarkModal(true) }}
                                                    />
                                                    <TableViewMore
                                                        Icon={X}
                                                        size={16}
                                                        color={globalStyles.red}
                                                        // weight="bold"
                                                        dataToggle={"Delete"} dataPlacement={"bottom"}
                                                        onClick={() => handleRemove(item?.id)}
                                                    />
                                                </div>

                                                {/* </div> */}

                                            </li>
                                        ))
                                    }
                                </>
                            )}
                        </>
                    )}
                </ul>
            </div>

            {
                editModalVisible && (
                    <CustomModal
                        title={"Update Keyword"}
                        ShowOrHide={editModalVisible}
                        bodyData={renderDeepDarkModal}
                        handleClose={handleCloseModal}
                        modalSize={"md"}
                        footer={false}
                        downloadBtn={false}
                        noBodyPadding={true}
                        overflowHidden={true}
                        modalPosition={"right"}
                        outsideClickDisabled={true}
                    ></CustomModal>
                )
            }

            {
                addModalVisible && (
                    <CustomModal
                        title={"Add Keyword"}
                        ShowOrHide={addModalVisible}
                        bodyData={renderAddKeywordModal}
                        handleClose={handleCloseModal}
                        modalSize={"md"}
                        footer={false}
                        downloadBtn={false}
                        noBodyPadding={true}
                        overflowHidden={true}
                        modalPosition={"right"}
                        outsideClickDisabled={true}
                    ></CustomModal>
                )
            }

            {
                alertPopup && (
                    <AlertBox
                        setAlertPopup={setAlertPopup}
                        alertPopup={alertPopup}
                        message={alertMessage}
                        status={statusMessage}
                        close={handleClosePopup}
                        setOutTime={5000}
                        bgColor={"lightGreen"}
                    />
                )
            }
        </div >
    )
}

export default SearchDeepDark