import { ArrowRight, DownloadSimple, FileSearch } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "../../../index.css";
import styles from "./Alerts.styles";
import { closeAlert, getAlerts, getCloseAlert } from "../../../Methods/ComplianceMethods";
import { convertTimestamp } from "../../../Methods/TiMethods";
import { Badge, Button, Form, Tab, Table, Tabs } from "react-bootstrap";
import jsPDF from "jspdf";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from "../../../Utils/Utils.js";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Pagination from "../../../components/Pagination/Pagination";
import FilterModal from "../../../components/CustomModal/FilterModal";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import { Link } from "react-router-dom";
import { fgetCy, fpostC, fpostCy } from "../../../axios/axios.js";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument.js";
import AddFilter from "../../../components/AddFilter/AddFilter.jsx";
import useOnclickOutside from 'react-cool-onclickoutside';
import AlertExport from "./Export/index.js";
import ReactQuill from "react-quill";
import AlertBox from "../../../components/AlertBox/AlertBox.jsx";
import ReactJson from "react-json-view";

ChartJS.register(ArcElement, Tooltip, Legend);

let baseURL = localStorage.getItem("CYBOT_URL")

const Alerts = ({ selectedDateFilter }) => {
  const [alerts, setalerts] = useState([]);
  const [alertsError, setAlertsError] = useState([]);

  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);


  const [Labels, setLabels] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  const [updateStatus, setUpdateStatus] = useState(false)

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [totalPageCount, setTotalPageCount] = useState()

  const [loadingTable, setLoadingTable] = useState('true')

  // raw log
  const [clickedNodeLog, setClickedNodeLog] = useState()
  const [expcloseLog, setExpCloseLog] = useState(false)
  const [rawLogId, setRawLogId] = useState("")
  const [rawLogIndex, setRawLogIndex] = useState("")

  // Filter 

  // filter
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [timelineIsLoading, setTimelineIsLoading] = useState(false);
  const [tableData, setTableData] = useState([])
  const [stepDetails, setStepDetails] = useState([]);

  const [showAddFilter, setShowAddFilter] = useState(false);
  const [showManageColumn, setShowManageColumn] = useState(false);


  const [commentModalVisible, setCommentModalVisible] = useState(false)
  const [detectionStatusCheck, setDetectionStatusCheck] = useState('')
  const [closeRefresh, setCloseRefresh] = useState(true)

  const [detectionClosed, setDetectionClosed] = useState({
    closed_at: "",
    closed_by: "",
    comment: "",
    detection_status: "",
  });

  const [detectionOpen, setDetectionOpen] = useState({
    opened_at: "",
    opened_by: "",
    comment: "",
    detection_status: "",
  });



  // alert message
  const [alertMessage, setAlertMessage] = useState('Success')
  const [alertPopup, setAlertPopup] = useState(false)

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
    setShowManageColumn(false);
  });

  const handleInvestigationOpen = (tenandID, id, status) => {
    if (status && status !== "close") {
      getCloseAlert(tenandID, id).then((res) => {
        setUpdateStatus(!updateStatus)
      })
    }
  }

  function closeAlertData(id, index) {
    closeAlert(id, index)
      .then(({ data }) => {
        setUpdateStatus(!updateStatus)
        setShow(false);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  const checkDefaultStatus = async (status, id, index) => {
    if (status === 'new' || status === undefined) {
      const data = { "doc": { "detection_status": "open" } }

      try {
        const response = await fpostC(`${baseURL}/cybot_proxy/${index}/_update/${id}`, data);
        response && setCloseRefresh(response)
      } catch (error) {
        console.error('Error', error);
        // setCloseDetectionCheck([])
      }
    }
  }

  useEffect(() => {
    setCurrentPageCount((page - 1) * perPage + perPage);

    getAlerts(filter, dateFilter, 100, (page - 1) * perPage)
      .then(
        (res) => {
          if (!res.isError) {
            let hits = res.data.hits
            let label = ["closed", "new", "open"];
            let data = [];
            if (hits?.hits?.length > 0) {
              let close = hits?.hits.filter(
                (item) => item._source.detection_status === "close"
              );
              data.push(close.length);
              let newAlert = hits?.hits.filter(
                (item) => item._source.detection_status === undefined
              );
              data.push(newAlert.length);
              let open = hits?.hits.filter(
                (item) => item._source.detection_status === "open"
              );
              data.push(open.length);
            }

            setTotalPageCount(hits?.hits?.total)
            setLabels(label);
            setDataChart(data);
            setRawLogId(hits?.hits?.[0]?._source?.id)
            setRawLogIndex(hits?.hits?.[0]?._source?.index)
            setalerts(hits?.hits);
            setLoadingTable(false)
          } else {
            setAlertsError(res)
            setLoadingTable(false)
          }
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setLoadingTable(false)
      });
  }, [selectedDateFilter, updateStatus, closeRefresh]);

  const communityChart = {
    labels: Labels,
    datasets: [
      {
        label: "Community Feeds",
        data: dataChart,
        backgroundColor: [
          "rgb(60, 179, 113)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  // download pdf
  const downloadPDF = () => {
    const doc = new jsPDF();

    // doc.autoTable({
    //   head: [["Event Time", "Event Name", "Feed Name", "Severity"]],

    //   body: tableDatas.map((item) => [
    //   ]),
    // });

    // download the pdf document
    doc.save("alerts.pdf");
  };

  const handleOpenModal = (data, display) => {
    setShowMore(data);
    setShow(display);
    // handleInvestigationOpen(data._index, data._id, data._source?.detection_status)
    checkDefaultStatus(data?._source?.detection_status, data?._id, data?._index)
  };

  function handleCloseModal() {
    setShow(false)
  }

  function handleCloseCommentModal() {
    setCommentModalVisible(false)
  }


  const handleOpenCommentModal = (display, status, data) => {
    setCommentModalVisible(display);
    setDetectionStatusCheck(status)
  };

  const handleInputChange = (value) => {
    // setDetectionClosed(e.target.value)
    // setDetectionOpen(e.target.value)
    setDetectionClosed({
      comment: value
    })
    setDetectionOpen({
      comment: value
    })
  }

  const handleCloseSelectedDetections = async (e) => {
    e.preventDefault();

    if (detectionStatusCheck === 'closed') {
      let data = { "doc": { "comment": detectionClosed?.comment, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString(), "detection_status": "close" } }

      try {
        const response = await fpostC(`${baseURL}/cybot_proxy/${showMore?._index}/_update/${showMore?._id}`, data);
        response && setCloseRefresh(response)
        setCommentModalVisible(false)
        setAlertMessage("Alert Closed")
        setAlertPopup(true);
      } catch (err) {
        setCommentModalVisible(false)
        console.error('Error', err);
      }
    } else {
      const data = { "doc": { "comment": detectionOpen?.comment, "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }

      try {
        const response = await fpostC(`${baseURL}/cybot_proxy/${showMore?._index}/_update/${showMore?._id}`, data);
        response && setCloseRefresh(response)
        setCommentModalVisible(false)
        setAlertMessage("Alert Open")
        setAlertPopup(true);
      } catch (error) {
        setCommentModalVisible(false)
        console.error('Error', error);
        // setCloseDetectionCheck([])
      }
    }

  };

  const [nodeModalData, setNodeModalData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    if (clickedNodeLog !== undefined && clickedNodeLog !== "") {
      var pattern = rawLogIndex !== undefined ? `${rawLogIndex}/_doc/${rawLogId}` : `logs-*/_search?track_total_hits=true`
      if (pattern?.charAt(0) === '/') {
        // If it does, remove the first character
        pattern = pattern?.slice(1);
      }
      setIsModalOpen(true);
      const data = JSON.stringify({
        query: {
          bool: {
            must: {
              term: {
                _id: clickedNodeLog,
              },
            },
          },
        },
      });

      rawLogIndex !== undefined ? (
        fgetCy(
          `${localStorage.getItem("DATALAKE_URL")}/${pattern}`,
          data
        ).then((response) => {
          let formatIndex = response?.data?._index?.replace(/-\d{4}\.\d{2}\.\d{2}-\d+/g, '*');
          if (response?.data?.found === false) {
            fpostCy(
              `${localStorage.getItem("DATALAKE_URL")}/${formatIndex}/_search?track_total_hits=true`,
              data
            ).then((res) => {
              if (res?.data?.found === false) {
                fpostCy(`${localStorage.getItem("DATALAKE_URL")}/${'logs-*,winlogbeat*/_search?track_total_hits=true'}`, data).then((finalRes) => {
                  finalRes?.data &&
                    setNodeModalData(finalRes?.data?._source);
                  // setNodeData()
                })
              }
              setNodeModalData(res?.data?._source);
            });
          }

          setNodeModalData(response?.data?._source);
          setIsLoading(false);

        })
      ) : (
        fpostCy(
          `${localStorage.getItem("DATALAKE_URL")}/${pattern}`,
          data
        ).then((response) => {
          let formatIndex = response?.data?._index?.replace(/-\d{4}\.\d{2}\.\d{2}-\d+/g, '*');
          if (response?.data?.found === false) {
            fpostCy(
              `${localStorage.getItem("DATALAKE_URL")}/${formatIndex}/_search?track_total_hits=true`,
              data
            ).then((res) => {
              if (res?.data?.found === false) {
                fpostCy(`${localStorage.getItem("DATALAKE_URL")}/${'logs-*,winlogbeat*/_search?track_total_hits=true'}`, data).then((finalRes) => {
                  finalRes?.data &&
                    setNodeModalData(finalRes?.data?._source);
                  // setNodeData()
                })
              }
              setNodeModalData(res?.data?._source);
            });
          }

          setNodeModalData(response?.data?._source);
          setIsLoading(false);

        }))

      // setIsLoading(false)

    }


  }, [clickedNodeLog]);

  const [key, setKey] = useState('info');

  const handleSelect = (k) => {
    setKey(k);
  };

  function renderCommentModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleCloseSelectedDetections}>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                {detectionStatusCheck === 'closed' ?
                  <ReactQuill
                    value={detectionClosed.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed.comment} onChange={handleInputChange} required ></textarea>
                  :
                  <ReactQuill
                    value={detectionOpen.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen.comment} onChange={handleInputChange} required ></textarea>
                }
              </div>
            </div>
          </div>
          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-primary" type="submit">
                Submit
              </Button>
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">Create</button>
          <button type="button" className="btn btn-secondary">Close</button> */}
        </form>
      </div>
    )
  }


  const renderModal = () => {
    return (
      <Tabs
        defaultActiveKey={key}
        onSelect={handleSelect}
        id="compliance_alert_details"
        className="mb-3"
      >
        <Tab eventKey="info" title="Info">
          <div className="px-3">
            <div className="modal-row-item">
              <span className="modal-label col-3">Rule name</span>
              <span className="modal-text">{showMore._source?.com_name}</span>
            </div>
            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Alert type</span>
              <span className="modal-text">
                {showMore._source?.alert_info?.type}
              </span>
            </div> */}
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert description</span>
              <span className="modal-text">
                {showMore._source?.com_description}
              </span>
            </div>
            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Alert recepients</span>
              <span className="modal-text">
                {showMore._source?.alert_info?.recipients.map((item) => item)}
              </span>
            </div> */}
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert date</span>
              <span className="modal-text">
                {showMore._source?.detection_timestamp && getDateFromTimestamp(showMore._source?.detection_timestamp)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert time</span>
              <span className="modal-text">
                {showMore._source?.detection_timestamp && getTimeFromTimestamp(showMore._source?.detection_timestamp)}
              </span>
            </div>
            {/* <h6>Alert time : {showMore._source?.alert_time}</h6> */}
            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Process name</span>
              <span className="modal-text">
                {showMore._source?.process_name}
              </span>
            </div> */}
            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Event category</span>
              <span className="modal-text">
                {showMore._source?.event?.category?.map(
                  (item) => item
                )}
              </span>
            </div> */}
            <div className="modal-row-item">
              <span className="modal-label col-3">User name</span>
              <span className="modal-text">
                {showMore._source?.user_name}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Host</span>
              <span className="modal-text">
                {showMore._source?.host_name}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">ISO 27001</span>
              <span className="modal-text">
                {showMore._source?.ISO_27001}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">NIST</span>
              <span className="modal-text">
                {showMore._source?.NIST}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">PCI DSS</span>
              <span className="modal-text">
                {showMore._source?.PCI_DSS}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">User Domain</span>
              <span className="modal-text">
                {showMore._source?.user_domain}
              </span>
            </div>


            <div className="modal-row-item">
              <span className="modal-label col-3">Host name</span>
              <span className="modal-text">
                {showMore._source?.host_name}
              </span>
            </div>
            {showMore._source?.host?.mac && (
              <div className="modal-row-item">
                <span className="modal-label col-3">MAC</span>
                <span className="modal-text">
                  {showMore._source?.host?.mac[0]}
                </span>
              </div>
            )}
            {showMore._source?.host?.ip && (
              <div className="modal-row-item">
                <span className="modal-label col-3">IP</span>
                {/* <span className="modal-text">{showMore._source?.host?.ip}</span> */}
                <span className="modal-text">
                  {showMore._source?.host?.ip.map((item) => {
                    return <span>{item + " , "}</span>;
                  })}
                </span>
              </div>
            )}
            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Architecture</span>
              <span className="modal-text">
                {showMore._source?.host?.architecture}
              </span>
            </div> */}
            <div className="modal-row-item">
              <span className="modal-label col-3">OS</span>
              <span className="modal-text">
                {showMore._source?.host_os}
              </span>
            </div>

            {/* <div className="modal-row-item">
              <span className="modal-label col-3">Status</span>
              <span className="modal-text">
                {showMore._source?.detection_status == "open" ? (
                  <div className="p-2 m-0 alert alert-danger">Open</div>
                ) : showMore._source?.detection_status == "close" ? (
                  <div className="p-2 m-0 alert alert-success">Closed</div>
                ) : (
                  <div className="p-2 m-0 alert alert-info">New</div>
                )}
              </span>
            </div>
            {showMore._source?.detection_status === "open" && (
              <button
                onClick={() => closeAlertData(showMore._id, showMore?._index)}
                className="btn btn-danger position-absolute fixed-bottom py-4"
              >
                Close Alert
              </button>
            )} */}

            <div className="modal-row-item">
              <span className="modal-label col-3">Status</span>
              {showMore?._source?.detection_status === 'close' ? (
                <div className="close-detection">
                  <button
                    className="btn btn-secondary"
                    // disabled={showMore?._source?.detection_status.length === 0} 
                    onClick={() => handleOpenCommentModal(true, 'open', showMore)}>Open</button>
                </div>
              ) : (
                <div className="close-detection">
                  <button className="btn btn-secondary"
                    // disabled={showMore?._source?.detection_status.length === 0} 
                    onClick={() => handleOpenCommentModal(true, 'closed', showMore)}>Close </button>
                </div>
              )}
            </div>

            {showMore._source?.comment && (
              <div className="modal-row-item">
                <span className="modal-label col-3">Comment</span>
                <span className="modal-text" dangerouslySetInnerHTML={{ __html: showMore._source?.comment }} >
                </span>
              </div>
            )}

            {/* <h6>timestamp : { }</h6> */}
          </div>
        </Tab>
        <Tab eventKey="rawLog" title="Raw Log">
          {isModalOpen ? (
            <ReactJson className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={nodeModalData} collapsed={false} onEdit={false} onAdd={false} onDelete={false} enableClipboard={false} onSelect={false} displayDataTypes={false} displayObjectSize={false} />
          ) : (
            ''
          )}
        </Tab>
      </Tabs>
    );
  };

  const handleCloseModalExport = () => {
    setShowExportModal(false);

  };

  const module = 'compliance'
  const tab = 'alert'
  var count = [];
  var count2;

  const getFilteredTable = async (filter, date = {}) => {
    setLoadingTable(true)
    if (filter || date?.to) {
      await getAlerts(filter, date, 100, (page - 1) * perPage)
        .then(
          (res) => {
            if (!res.isError) {
              let hits = res.data.hits
              let label = ["closed", "new", "open"];
              let data = [];
              if (hits?.hits?.length > 0) {
                let close = hits?.hits.filter(
                  (item) => item._source.detection_status === "close"
                );
                data.push(close.length);
                let newAlert = hits?.hits.filter(
                  (item) => item._source.detection_status === undefined
                );
                data.push(newAlert.length);
                let open = hits?.hits.filter(
                  (item) => item._source.detection_status === "open"
                );
                data.push(open.length);
              }

              setTotalPageCount(hits?.hits.length)
              setLabels(label);
              setRawLogId(hits?.hits?.[0]?._source?.id)
              setRawLogIndex(hits?.hits?.[0]?._source?.index)
              setDataChart(data);
              setalerts(hits?.hits);
              setLoadingTable(false)
            } else {
              setAlertsError(res)
              setLoadingTable(false)
            }
          }
        )
        .catch((err) => {
          console.log("Error", err);
          setLoadingTable(false)
        });
    }
    else {
      getAlerts('', '', 100, (page - 1) * perPage)
        .then(
          (res) => {
            if (!res.isError) {
              let hits = res.data.hits
              let label = ["closed", "new", "open"];
              let data = [];
              if (hits?.hits?.length > 0) {
                let close = hits?.hits.filter(
                  (item) => item._source.detection_status === "close"
                );
                data.push(close.length);
                let newAlert = hits?.hits.filter(
                  (item) => item._source.detection_status === undefined
                );
                data.push(newAlert.length);
                let open = hits?.hits.filter(
                  (item) => item._source.detection_status === "open"
                );
                data.push(open.length);
              }

              setTotalPageCount(hits?.hits.length)
              setLabels(label);
              setDataChart(data);
              setRawLogId(hits?.hits?.[0]?._source?.id)
              setRawLogIndex(hits?.hits?.[0]?._source?.index)
              setalerts(hits?.hits);
              setLoadingTable(false)
            } else {
              setAlertsError(res)
              setLoadingTable(false)
            }
          }
        )
        .catch((err) => {
          console.log("Error", err);
          setLoadingTable(false)
        });
    }
  }


  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filters"));
    const currentPageFilter = storedFilter?.[module]?.[tab]?.fields;
    const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date;
    console.log("currentPageFilter", currentPageFilter, currentPageDateFilter);
    if (currentPageFilter?.length || currentPageDateFilter?.to) {
      count = currentPageFilter;
      count2 = currentPageDateFilter?.to;
      setDateFilter(currentPageDateFilter);
      setFilter(currentPageFilter);
      getFilteredTable([], currentPageDateFilter);
    }
  }, []);


  return (
    <>
      <div
        className="d-flex flex-column p-4 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >
        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <h4>Overview</h4>
          <p>
            Alerts are triggered when logs matching specific criteria are met.
            Below listed are the alerts that have been triggered. It helps the
            analyst to continuously monitor events that are occuring in the
            organization.
          </p>
        </div>

        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <Doughnut data={communityChart} />
        </div>
      </div>
      <div className="col-9 d-flex flex-column p-4 rounded-3 gap-3 bg-white position-relative">
        <div className="col-12 d-flex flex-row pb-4" style={styles.filterWrap}>
          <div className="col-5 d-flex flex-row align-items-center">
            <h4 className="mb-0">Alerts</h4>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end ml-auto col-7">
            {/* <div className="d-flex flex-row align-items-center col-3">
              <Form.Select>
                <option>25 items</option>
                <option>50 items</option>
              </Form.Select>
            </div> */}
            {/* <Form.Control
              type="text"
              placeholder="Search..."
              className="mx-2"
            /> */}
            {/* <Button
              variant="dark"
              className="col-3 d-flex flex-row align-items-center justify-content-center"
            >
              <DownloadSimple /> */}
            {/* <span className="px-2" onClick={() => downloadPDF()}>
                Save PDF
              </span> */}
            {/* <span className="px-2" onClick={(e) => setShowExportModal(true)}>
                Export
              </span>
            </Button> */}

            <div className="d-flex align-items-center gap-3">
              <AlertExport getDetectionTableData={getAlerts} filter={filter} dateFilter={dateFilter} />

              <div className="p-2 d-flex cursor-pointer" style={{ marginLeft: "auto" }}>
                <Button
                  ref={ref}
                  onClick={() => { setShowFilter(!showFilter) }}
                >
                  Add filter
                </Button>
                {showFilter && (
                  <div style={{ position: "absolute", zIndex: 9999 }}>
                    <AddFilter
                      filter={filter}
                      setFilter={setFilter}
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                      setShowAddFilter={setShowFilter}
                      getFilteredTable={getFilteredTable}
                      // setPage={setAttentionPage}
                      //  columnValues={columnValues.length > 0 ? columnValues : defaultColumnValues}
                      columnValues={[
                        // { value: "com_name", label: "Alert Name", },
                        // { value: "host_name", label: "Host Name", },
                        // { value: "user_name", label: "User Name", },
                        { value: "detection_status", label: "Status", },

                        // { value: "detection_status", label: "Detection Status" },

                      ]}
                      module={module}
                      tab={tab}
                    />
                  </div>
                )}
              </div>

              <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                currentPageCount={currentPageCount}
                totalDataCounts={totalPageCount}
              // dataLength={}
              />
            </div>
          </div>
        </div>
        <div style={styles.tableWrap}>
          {loadingTable ? (
            <TableLoader rowCount={18} />
          ) : (
            alerts?.length !== 0 && !alertsError.isError ? (
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>Alert</th>
                    <th>Host</th>
                    <th>User</th>
                    <th>ISO 27001</th>
                    <th>NIST</th>
                    <th>PCI DSS</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.map((item) => (
                    <tr
                      style={{ cursor: "pointer" }}
                    >
                      <td>{item._source?.com_name}</td>
                      <td>{item._source?.host_name}</td>
                      <td>{item._source?.user_name}</td>
                      <td>{item._source?.ISO_27001}</td>
                      <td>{item._source?.NIST}</td>
                      <td>{item._source?.PCI_DSS}</td>
                      <td className="font-monospace">
                        {item?._source?.detection_timestamp &&
                          getDateFromTimestamp(item?._source?.detection_timestamp)
                        }
                      </td>
                      <td className="font-monospace">
                        {item?._source?.detection_timestamp &&
                          getTimeFromTimestamp(item?._source?.detection_timestamp)
                        }
                      </td>
                      <td>
                        {item._source?.detection_status == "close" && (
                          <Badge bg="success" className="p-2">Closed</Badge>
                        )}
                        {item._source?.detection_status == undefined && (
                          <Badge bg="info" className="p-2">New</Badge>
                        )}
                        {item._source?.detection_status == "open" && (
                          <Badge bg="warning" className="p-2">Open</Badge>
                        )}
                      </td>
                      <td>
                        <Link onClick={() => handleOpenModal(item, true)}>
                          <TableViewMore Icon={ArrowRight} dataToggle={"Alert details"} dataPlacement={"bottom"} />
                        </Link>

                        {item._source?.id &&
                          <Link onClick={() => setClickedNodeLog(item._source?.id)} style={{ marginLeft: "1.1rem" }}>
                            <TableViewMore Icon={FileSearch} dataToggle={"Raw Log"} dataPlacement={"bottom"} />
                          </Link>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                <NoDataFound errorText={"No data found"} errorData={alertsError} />
              </div>
            )
          )}
        </div>
        <ExpandedDocument title={"Raw Log"} clickedNode={clickedNodeLog} close={expcloseLog} setClick={setClickedNodeLog} url={localStorage.getItem("DATALAKE_URL")} pattern={rawLogIndex !== undefined ? `${rawLogIndex}/_doc/${rawLogId}` : `logs-*/_search?track_total_hits=true`} req={rawLogIndex !== undefined ? fgetCy : fpostCy} />
      </div>

      {
        commentModalVisible && (
          <CustomModal
            title={detectionStatusCheck === 'closed' ? "Close Alert" : "Open Alert"}
            ShowOrHide={commentModalVisible}
            bodyData={renderCommentModal}
            handleClose={handleCloseCommentModal}
            modalSize={"md"}
            footer={false}
            downloadBtn={false}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }

      {show && (
        <CustomModal
          title={"Alert Details"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize={"md"}
          noBodyPadding={true}
          overflowHidden={true}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}


      <FilterModal
        show={showExportModal}
        title={"Export Data"}
        ShowOrHide={show}
        modalSize={"md"}
        downloadPDF={downloadPDF}
        handleClose={handleCloseModalExport}
        footer={false}
        autoHeight />

      {alertPopup && (
        <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={alertMessage} bgColor={"lightGreen"} />
      )}
    </>
  );

  return (
    <>
      <div className="col-12">
        {alerts.map(
          ({ _source: { rule_name, alert_time, detection_status } }) => (
            <div className="p-3 mb-4 border border-1 border-solid border-dark rounded">
              <span className="fw-bold">{rule_name}</span>
              <br />
              <span className="font-weight-light">{detection_status}</span>
              <br />
              <span style={{ fontSize: "0.8rem" }}>{alert_time}</span>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Alerts;
