const styles = {
    section1: {
      // width: "70%",
      // height: "100%",
      // backgroundColor: "white",
      // borderRadius: "10px 10px 0 0",
      height: "calc(100vh - 8rem)",
      paddingRight: "1rem"
    },
    section2: {
      // width: "30%",
      backgroundColor: "white",
      // borderRadius: "10px 10px 0 0",
    },
    overviewTile: {
      // backgroundColor: "#f1f1f1",
      backgroundColor: "#DBEEFF",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    overviewIcon: {
      width: "50px",
      height: "50px",
      fontSize: "28px",
      backgroundColor: "#47A8FF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      color: "#FFFFFF"
    },
    detectionCardUser:{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.9rem",
    },
    userDetails:{
      display: "flex",
      gap: "10px"
    },
    userPic:{
      width: '50px',
      height: '50px',
      backgroundColor: "yellow",
      borderRadius: "50%"
    },
    userText: {
      lineHeight: "1",
      maxWidth: "220px"
    },
    fullscreen :{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    buttonright:{
      position: "absolute",
      top: 98,
      right: 31,
      width: "36px",
      zIndex: 100
    },
    buttonrightfullscreen:{
      position: "absolute",
      top: 10,
      right: 31,
      width: "36px",
      zIndex: 100
    }
  };
  
  export default styles;
  