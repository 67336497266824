export const filterDetails = {
    "investigation":{
        "main":[
            {
              "name": "Detection Timestamp",
              "description": "The timestamp indicating when the security incident was detected, including both the date and time."
            },
            {
              "name": "Investigation Name",
              "description": "The designation or title of the specific use case being referred to."
            },
            {
              "name": "Detection ID",
              "description": "Unique identifier for the detection event."
            },
            {
              "name": "User",
              "description": "Username associated with the event."
            },
            {
              "name": "Host",
              "description": "The host or system involved in the event."
            },
            {
              "name": "Status",
              "description": "The current status or state of the event. Eg: New, Open, Closed"
            },
            {
              "name": "Severity",
              "description": "The severity level assigned to the event. Eg: Low, Medium, High"
            },
            {
              "name": "Source IP",
              "description": "IP address of the event source."
            },
            {
              "name": "Destination IP",
              "description": "IP address of the event destination."
            },
            {
              "name": "URL",
              "description": "The Uniform Resource Locator (URL) pertinent to the event, for example, 'http://example.com/malicious-page.'"
            },
            {
              "name": "Hash",
              "description": "The unique hash value linked to a file or data, such as 'SHA256 - 094d1476331d6f693f1d546b53f1c1a42863e6cde014e2ed655f3cbe63e5ecde.'"
            },
            {
              "name": "Host OS",
              "description": "The operating system of the host involved, as illustrated by 'Windows 10.'"
            },
            {
              "name": "Source Port",
              "description": "Port number at the source of the event."
            },
            {
              "name": "Destination Port",
              "description": "Port number at the destination of the event."
            },
            {
              "name": "Process Name",
              "description": "Name of the process related to the event. Eg: explorer.exe"
            },
            {
              "name": "Process Identifier",
              "description": "Unique identifier for the process."
            },
            {
              "name": "Process Executable",
              "description": "Executable file associated with the process. Eg: C:\\Windows\\System32\\explorer.exe"
            },
            {
              "name": "Process Signer",
              "description": "Entity or authority that signed the process. Eg: Microsoft Corporation"
            },
            {
              "name": "Process CommandLine",
              "description": "The command line employed to execute the process, like 'explorer.exe /select,C:\\example.txt.'"
            },
            {
              "name": "Process ParentName",
              "description": "Name of the parent process. Eg: svchost.exe"
            },
            {
              "name": "Process ParentExecutable",
              "description": "Executable file of the parent process. Eg: C:\\Windows\\System32\\svchost.exe"
            },
            {
              "name": "Process ParentCommandLine",
              "description": "Command line used for the parent process. Eg: svchost.exe -k netsvcs"
            },
            {
              "name": "File Path",
              "description": "Path to the file related to the event. Eg: C:\\Users\\jdoe\\Documents\\malware.exe"
            },
            {
              "name": "File Extension",
              "description": "Extension of the file related to the event. Eg: .exe, .dll, .jpeg"
            },
            {
              "name": "Registry Path",
              "description": "Path in the Windows registry related to the event. Eg: HKEY_LOCAL_MACHINE\\Software\\Microsoft\\Windows\\CurrentVersion\\Run"
            },
            {
              "name": "Registry Value",
              "description": "Value associated with the registry key. Eg: string (REG_SZ or REG_MULTI_SZ), expandable string (REG_EXPAND_SZ)"
            },
            {
              "name": "Destination CountryName",
              "description": "Country name of the destination IP."
            },
            {
              "name": "Firewall Event Category",
              "description": "The overarching classification or genre of the firewall event."
            },
            {
              "name": "Firewall Event Type",
              "description": "The precise classification or nature of the firewall event."
            },
            {
              "name": "Firewall Threat Name",
              "description": "The name of the threat identified and flagged by the firewall, such as 'SYN Flood Attack.'"
            },
            {
              "name": "Source Country Name",
              "description": "Country name of the source IP."
            },
            {
              "name": "User Name",
              "description": "The username or identifier linked to the event, indicating the user involved."
            },
            {
              "name": "Host Name",
              "description": "The designation or identifier of the host or system that played a role in the event."
            },
            {
              "name": "Target User Name",
              "description": "The name or identifier of the user who was the target or victim of the event."
            },
            {
              "name": "Subject User Name",
              "description": "The term 'subject user' typically refers to the user who is the primary focus or actor in the event."
            },
            {
              "name": "Summary",
              "description": "Brief summary or description of the event."
            },
            {
              "name": "Source Country Name",
              "description": "Country name of the source IP."
            },
            {
              "name": "Event Code",
              "description": "It denotes a numerical or alphanumeric code that serves as a unique identifier for a particular type of event or incident."
            },
            {
              "name": "Event Type",
              "description": "The term used to describe the type or character of the event, such as 'Signature' or 'SSL Anomaly.'"
            },
            {
              "name": "Event Outcome",
              "description": "The outcome or result of the event, which can be described as 'Success' or 'Blocked,' indicating whether the event was executed successfully or prevented."
            },
            {
              "name": "Event Action",
              "description": "The action implemented in response to the event, which could include actions like 'Quarantined,' 'Blocked,' or 'Allowed.' This indicates the firewall or system's response to the detected event."
            },
            {
              "name": "Event Category",
              "description": "The categorization or classification of the security event based on its type or nature."
            },
            {
              "name": "Firewall Attack",
              "description": "The specific type of attack that the firewall has identified, such as 'SQL Injection.' This term describes the method or technique used in the security event."
            },
            {
              "name": "Firewall Subtype",
              "description": "The subtype or specific variation of the firewall event, which provides additional context or details about the event."
            },
            {
              "name": "Firewall Type",
              "description": "The category or type of firewall in use, such as 'Network Firewall' or 'Layer 7 (L7) Firewall,' which specifies the firewall's primary function or focus in terms of network security."
            },
            {
              "name": "Firewall Level",
              "description": "The level or degree of severity associated with the firewall event, for example, 'High,' indicating the criticality or seriousness of the event."
            },
            {
              "name": "Firewall Severity",
              "description": "The severity level assigned to the firewall event, such as 'Critical,' indicating the event's high importance and potential impact on the security of the system or network."
            },
            {
              "name": "Bytes IN",
              "description": "The quantity of incoming bytes originating from an IP address that is relevant to the event."
            },
            {
              "name": "Bytes OUT",
              "description": "The volume of outgoing bytes originating from an IP address that is associated with the event."
            },
            {
              "name": "Threat Categories",
              "description": "The categories or types of threats linked to the event, such as 'Malware' or 'Phishing,' which describe the nature of potential security risks involved in the incident."
            },
            {
              "name": "Threat Family",
              "description": "The family or group of threats that are connected to the event, like 'Ransomware.' This term denotes a broader category or classification of threats, often sharing similar characteristics or behaviors."
            },
            {
              "name": "Threat Title",
              "description": "The title or specific name of the threat, as exemplified by ''Packunwan' unwanted software was prevented.' This name identifies the particular threat or security issue that the event is referring to."
            },
            {
              "name": "File Name",
              "description": "The filename associated with the event, indicating a specific file that is relevant to the incident or security event in question."
            },
            {
              "name": "Subject",
              "description": "The subject or title of the email message, which provides a brief description of the email's content or purpose."
            },
            {
              "name": "Recipient",
              "description": "The email address of the recipient who received the email, indicating the destination or target of the message."
            },
            {
              "name": "Sender",
              "description": "The email address of the sender who initiated or sent the email message."
            },
            {
              "name": "Direction",
              "description": "The direction of the event, which specifies whether it is 'inbound' (coming into the network or system) or 'outbound' (going out from the network or system). This indicates the flow or direction of the network activity associated with the event."
            },
            {
              "name": "Account UPN",
              "description": "The User Principal Name (UPN) associated with the user account. UPN is a user account identifier in the format of 'username@domain' and is often used for user authentication and identification in systems and services."
            },
            {
              "name": "Account Display Name",
              "description": "The display name associated with the account is the name by which the user or account is typically identified or presented in a user interface or directory. It is the name that is commonly visible to others when interacting with the account."
            },
            {
              "name": "IP Address",
              "description": "The relevant IP address for the event."
            },
            {
              "name": "Account Name",
              "description": "The account's name implicated in the event."
            },
            {
              "name": "Action Type",
              "description": "The action type performed in connection with the event."
            },
            {
              "name": "UserID",
              "description": "A unique identifier for the user who is part of the event."
            },
            {
              "name": "Client IP",
              "description": "The IP address of the client or user involved in the event."
            },
            {
              "name": "Country Name",
              "description": "The country name linked to an IP address."
            }
          ],
        "cykit-firewall":[
            {
              "name": "Event Category",
              "description": "The firewall event is categorized based on its type or general classification. For FortiAnalyzer event logs (fortianalyzer-event-), these categories may include Information, Authentication Success, and others. For FortiAnalyzer UTM (fortianalyzer-utm-) and traffic (fortianalyzer-traffic-*) logs, categories may include Network Allow, Information, and more."
            },
            {
              "name": "Event Type",
              "description": "The firewall event can also be further specified by its specific type. In the case of FortiAnalyzer event logs (fortianalyzer-event-) and FortiAnalyzer UTM logs (fortianalyzer-utm-), these types may include Traffic Allowed by IDS/IPS, General Information, and more. For FortiAnalyzer traffic logs (fortianalyzer-traffic-*), specific types can include Connection Closed, Traffic Allowed by Network Firewall, and others."
            },
            {
              "name": "Destination IP",
              "description": "The IP address of the destination server or device that was involved in the network traffic."
            },
            {
              "name": "Destination Port",
              "description": "The port number on the destination server or device where the network traffic was directed."
            },
            {
              "name": "Destination Country",
              "description": "The country associated with the destination IP address."
            },
            {
              "name": "Source IP",
              "description": "The IP address of the source device or server that initiated the network traffic."
            },
            {
              "name": "Source Port",
              "description": "The port number on the source device or server from which the network traffic originated."
            },
            {
              "name": "Source Country",
              "description": "The country associated with the source IP address."
            },
            {
              "name": "Bytes In",
              "description": "The amount of data received during the network event."
            },
            {
              "name": "Bytes Out",
              "description": "The amount of data sent out during the network event."
            },
            {
              "name": "Severity",
              "description": "The severity level or rating of the firewall event (e.g., Low, Medium, High)."
            },
            {
              "name": "Type",
              "description": "The general type of firewall event, such as 'Access Control' or 'Threat Prevention.'"
            },
            {
              "name": "Sub type",
              "description": "A more specific subtype of the firewall event, providing additional context."
            },
            {
              "name": "ObjectName",
              "description": "The name or identifier of the object involved in the event (e.g., application name)."
            },
            {
              "name": "URL",
              "description": "The URL associated with the event, if applicable."
            },
            {
              "name": "Action",
              "description": "The action performed by the firewall in response to the event, including options like 'Allow,' 'Block,' or 'Deny.'"
            },
            {
              "name": "Destination Region",
              "description": "The geographical region linked to the destination IP address."
            },
            {
              "name": "DevName",
              "description": "The name or identifier of the firewall device in use."
            },
            {
              "name": "Vendor",
              "description": "The vendor or manufacturer of the firewall device."
            },
            {
              "name": "OS",
              "description": "The operating system running on the firewall device."
            },
            {
              "name": "OSVersion",
              "description": "The version of the operating system on the firewall device."
            }
          ],
        "cykit-ad":[
        {
          "name": "Host Name",
          "description": "The name of the host or server where the Active Directory or file share event occurred."
        },
        {
          "name": "Event Action",
          "description": "The action taken in the Active Directory or file share event (e.g., 'Create,' 'Modify,' 'Delete')."
        },
        {
          "name": "Event Code",
          "description": "The code or identifier associated with the specific event, such as an Active Directory event code."
        },
        {
          "name": "Subject Account",
          "description": "The user or account that initiated the action/event."
        },
        {
          "name": "Target Account",
          "description": "The user or account affected by the action/event."
        },
        {
          "name": "Share Name",
          "description": "The name of the file share, if applicable."
        },
        {
          "name": "Target File",
          "description": "The name or path of the file or resource that was the subject of the action/event."
        }
      ],
        "cykit-email":[
            {
              "name": "Sender Address",
              "description": "The email address of the sender who sent the email."
            },
            {
              "name": "Receiver Address",
              "description": "The email address of the recipient who received the email."
            },
            {
              "name": "Subject",
              "description": "The subject or title of the email message."
            },
            {
              "name": "Status",
              "description": "The status of the email, indicating whether it was delivered successfully, marked as spam, or encountered any issues during transmission or reception."
            }
          ],
        "cykit-waf":[
            {
              "name": "Website",
              "description": "The name or identifier of the website or web application that the WAF is protecting."
            },
            {
              "name": "URL",
              "description": "The specific URL or endpoint within the website or web application that was targeted or accessed during the WAF event."
            },
            {
              "name": "Status",
              "description": "The status or outcome of the WAF event, indicating whether it was a successful or blocked access attempt."
            },
            {
              "name": "Method",
              "description": "The HTTP method used in the request, such as GET, POST, PUT, DELETE, etc., providing insights into the type of request made."
            },
            {
              "name": "Threat",
              "description": "Information about the detected threat or suspicious activity associated with the WAF event, including details about the attack type or characteristics."
            }
          ],
        "cykit-atp-alerts":[
            {
              "name": "Detection Status",
              "description": "The current status of the ATP (Advanced Threat Protection) alert, indicating whether it's open, resolved, or in another state."
            },
            {
              "name": "Host",
              "description": "The name or identifier of the host or endpoint where the ATP alert was triggered."
            },
            {
              "name": "Directory",
              "description": "The directory or location on the host where the suspicious activity or threat was detected."
            },
            {
              "name": "Detection Type",
              "description": "The type or category of detection that triggered the ATP alert, which can include malware detection, suspicious behavior, or other threat types."
            },
            {
              "name": "Event Category",
              "description": "The general category or classification of the event that led to the ATP alert, such as 'Malware,' 'Suspicious Behavior,' or 'Unauthorized Access.'"
            },
            {
              "name": "User Principal Name",
              "description": "The user's principal name associated with the activity or event that triggered the ATP alert. This helps identify the user account involved."
            },
            {
              "name": "Export As",
              "description": "An option to export or save the ATP alert information in a specific format or for further analysis."
            }
          ],
        "cykit-o365ex":[
            {
              "name": "ClientIP",
              "description": "The IP address of the client or device accessing Exchange services."
            },
            {
              "name": "UserID",
              "description": "The unique identifier or username associated with the user accessing Exchange."
            },
            {
              "name": "Source Country",
              "description": "The country or location associated with the source IP address of the client accessing Exchange."
            },
            {
              "name": "Client Info",
              "description": "Information about the client application or device used for accessing Exchange."
            },
            {
              "name": "External Access",
              "description": "Indication of whether the access to Exchange was from an external source or internal network."
            },
            {
              "name": "FolderPath",
              "description": "The path or location within the mailbox or folder where the action occurred."
            },
            {
              "name": "Mailbox Owner",
              "description": "The owner of the mailbox being accessed or modified."
            },
            {
              "name": "Result Status",
              "description": "The status or outcome of the operation (e.g., success, failure, sent, received)."
            },
            {
              "name": "Subject",
              "description": "The subject or title of the email message or operation, if applicable."
            },
            {
              "name": "Attachments",
              "description": "Information about email attachments or file attachments, if relevant to the Exchange operation."
            },
            {
              "name": "Operation",
              "description": "Information about the type of operation or action performed in the Exchange operation."
            }
          ],
        "cykit-o365onedrive":[
            {
              "name": "ClientIP",
              "description": "The IP address of the client or device accessing OneDrive resources."
            },
            {
              "name": "UserID",
              "description": "The unique identifier or username associated with the user accessing OneDrive."
            },
            {
              "name": "Source Country",
              "description": "The country or location associated with the source IP address of the client accessing OneDrive."
            },
            {
              "name": "Operation",
              "description": "The type of operation or action performed in OneDrive, such as file upload, download, or sharing."
            },
            {
              "name": "Event Source",
              "description": "The source or origin of the event, indicating where the event was triggered within OneDrive."
            },
            {
              "name": "Item Type",
              "description": "The type of item (e.g., file, folder) involved in the OneDrive operation."
            },
            {
              "name": "Source FileName",
              "description": "The name of the source file if applicable (e.g., in file upload or download events)."
            },
            {
              "name": "URL",
              "description": "The URL associated with the OneDrive operation, providing a link to the affected resource."
            },
            {
              "name": "Platform",
              "description": "The platform or device type (e.g., Windows, macOS, iOS, Android) used for accessing OneDrive."
            },
            {
              "name": "User Agent",
              "description": "The user agent string that identifies the client or application making the request."
            },
            {
              "name": "Authentication",
              "description": "Information related to the authentication process, such as the method used for authentication (e.g., username/password, single sign-on)."
            }
          ],
        "cykit-o365ad":[
            {
              "name": "ClientIP",
              "description": "The IP address of the client or device making requests to the Active Directory."
            },
            {
              "name": "UserId",
              "description": "The unique identifier or username associated with the user making requests to Active Directory."
            },
            {
              "name": "Source Country",
              "description": "The country or location associated with the source IP address of the client making requests."
            },
            {
              "name": "Device OS",
              "description": "The operating system of the device making requests to Active Directory."
            },
            {
              "name": "Operation",
              "description": "The type of operation or action performed in Active Directory, such as login, authentication, or directory access."
            },
            {
              "name": "Result Status",
              "description": "The status or outcome of the operation in Active Directory, indicating success or failure."
            },
            {
              "name": "Requested Type",
              "description": "The type of request made to Active Directory, which can include authentication requests, user queries, or other types of directory operations."
            },
            {
              "name": "Browser Type",
              "description": "The type of browser or client application used to make requests to Active Directory, if applicable."
            }
          ]
    },

    
}