import React, { useCallback, useState } from 'react'
import ImageViewer from 'react-simple-image-viewer';

const ImageViewerComponent = ({ image, imageIndex }) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((imageIndex) => {
        setCurrentImage(imageIndex);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div>
            <img
                className='w-100 rounded-3'
                src={image}
                onClick={() => openImageViewer(imageIndex)}
                width="300"
                key={imageIndex}
                style={{ margin: '2px' }}
                alt=""
            />

            {isViewerOpen && (
                <ImageViewer
                    src={[image]}
                    // currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </div>
    )
}

export default ImageViewerComponent