import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { exportCSVFile } from "../../../Utils/Utils";
import {
    processfields,
    networkfields,
    filefields,
    registryfields,
    timelinefields,
    authenticationfields
} from "./fields";

import moment from "moment";
let edr = localStorage.getItem("EDR_TYPE");
const getTimeFromTimestamp = (timestamp) => {
    return moment(timestamp).format('DD MMM YYYY, hh:mm:ss A');
}
const UebaCykitExport = ({ filter, type, dateFilter, fields, isAnomaly, getProcessList, kqlQuery, is_anomaly, is_unified, showSystem, selectedDataTree, }) => {
    const [loader, setLoader] = useState(false);

    const getFields = (type) => {
        if (type === "process") {
            var fields = processfields[edr];
        } else if (type === "network") {
            var fields = networkfields[edr];
        } else if (type === "file") {
            var fields = filefields[edr];
        } else if (type === "timeline") {
            var fields = timelinefields[edr];
        } else if (type === "registry") {
            var fields = registryfields[edr];
        } else if (type === "authentication") {
            var fields = authenticationfields[edr];
        }
        return fields;
    };
    const convertdata = (fields, data) => {
        var convertedData = [];
        data.map((item) => {
            var dynamicJSON = {};
            for (const field of fields) {
                const { name, value } = field;

                // var test = "val."+value
                // if(test.includes(".@"))
                // {
                //     var new1 = test.split(".@")
                //     test= new1[0]+`['@${new1[1]}']`
                // }
                if (value != `item._source['@timestamp']`) {
                    var value1 = eval(value);
                }
                else {
                    var value1 = getTimeFromTimestamp(eval(value))
                }
                dynamicJSON[name] = value1;
            }
            convertedData.push(dynamicJSON);
        });
        return convertedData;
    };
    const csvExport = async () => {
        setLoader(true);
        var size = 1000;
        var newType = type === "timeline" ? "" : type;

        var data = await getProcessList(
            filter,
            dateFilter,
            "",
            0,
            1000,
            is_anomaly,
            showSystem,
            selectedDataTree,
            kqlQuery
        );

        var data3 = data.data.hits.hits;

        var count = data.data.hits.total.value;
        var iter = 1000;
        while (count > 1000 && iter < count) {
            var tempdata = await getProcessList(
                filter,
                dateFilter,
                "",
                iter,
                size,
                is_anomaly,
                showSystem,
                selectedDataTree,
                kqlQuery
            );
            data3.push(...tempdata.data.hits.hits);
            iter += size;
        }
        var fields = getFields(type);
        var filename = type + "_" + moment().toISOString();

        data3.forEach((item) => {
            if (item._source["@timestamp"]) {
                // Parse timestamp and format it
                item._source["@timestamp"] = getTimeFromTimestamp(item._source["@timestamp"]);
            }
        });
        var data1 = convertdata(fields, data3);
        
        exportCSVFile(fields, data1, filename);
        setLoader(false);
    };

    return (
        <div className="">
            <DropdownButton
                variant="light"
                title={loader ? "Downloading" : "Export as"}
                disabled={loader}
            >
                <Dropdown.Item
                    onClick={() => {
                        csvExport();
                    }}
                >
                    CSV
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};

export default UebaCykitExport;
