import React, { useEffect, useState } from 'react'
import { getCyLinuxTable, getMostPerformed, getUniqueCounts } from '../../../../Methods/CyKitInvestigate'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


const CyLinux = () => {

    // filters
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({});

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
    });

    // loader
    const [loadTableData, setLoadTableData] = useState(false)

    // counts
    const [userCount, setUserCount] = useState()
    const [hostCount, setHostCount] = useState()

    // graph
    const [mostProcessPerformedLabel, setMostProcessPerformedLabel] = useState([])
    const [mostProcessPerformedData, setMostProcessPerformedData] = useState([])

    const [mostEventActivityPerformedLabel, setMostEventActivityPerformedLabel] = useState([])
    const [mostEventActivityPerformedData, setMostEventActivityPerformedData] = useState([])

    // table
    const [tableData, setTableData] = useState([])

    // useEffect(() => {
    //     const storedFilter = JSON.parse(localStorage.getItem("filters"));
    //     const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
    //     const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
    //     console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
    //     if (currentPageFilter?.length || currentPageDateFilter?.to) {
    //         count = currentPageFilter;
    //         count2 = currentPageDateFilter?.to
    //         setDateFilter(currentPageDateFilter)
    //         setFilter(currentPageFilter);

    //         getFilteredTable(currentPageFilter, currentPageDateFilter);
    //     }
    // }, []);



    useEffect(() => {
        // get username count
        getUniqueCounts("user.name").then((res) => {
            console.log("get user count", res)
        }).catch((err) => {

        })

        // get host count

        getUniqueCounts("host.name").then((res) => {
            console.log("get host count", res)
        }).catch((err) => {

        })

        // get most processes performed graph

        getMostPerformed("process.name").then(({ data: { aggregations: { 1: { buckets } } } }) => {
            console.log("get most process per", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setMostProcessPerformedLabel(label);
                    setMostProcessPerformedData(data);
                });
            }
        }).catch((err) => {

        })

        // Most event activities performed

        getMostPerformed("event.action").then(({ data: { aggregations: { 1: { buckets } } } }) => {
            console.log("get most event active per", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setMostProcessPerformedLabel(label);
                    setMostProcessPerformedData(data);
                });
            }
        }).catch((err) => {

        })

        // table data

        getCyLinuxTable().then(({ data: { hits: { hits } } }) => {
            console.log("get table datas ", hits)

            setTableData(hits)
        }).catch((err) => {

        })

    }, [])

    return (
        <div>
            <div className="col-12 position-relative">
                <div
                    className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                    style={styles.filterWrap}
                >
                    <div className="position-relative mr-2">
                        {/* <FilterList
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            getFilteredTable={getFilteredTable}
                            module={module}
                            tab={tab}
                        /> */}
                        <Button
                            // className="position-relative"
                            ref={ref}
                            onClick={() => setShowAddFilter(!showAddFilter)}
                        >
                            Add filter
                        </Button>
                        {showAddFilter && (
                            <AddFilter
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                setShowAddFilter={setShowAddFilter}
                                // getFilteredTable={getFilteredTable}
                                columnValues={[
                                    { value: "agent.hostname", label: "Host Name", isWildCard: true },
                                    { value: "event.action", label: "Event Action", isWildCard: true },
                                    { value: "event.code", label: "Event Code" },
                                    { value: "winlog.event_data.SubjectUserName", label: "User Name", isWildCard: true },
                                ]}
                                module={module}
                            // tab={tab}
                            />
                        )}
                    </div>
                </div>

                <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                    <Tabs
                        defaultActiveKey="dashboard"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    // onSelect={(e) => setActiveTab(e)}
                    >
                        <Tab eventKey="dashboard" title="Overview">
                            <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                                <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{userCount ? userCount : "--"}</h4>
                                                <span>No of unique Users </span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{hostCount ? hostCount : "--"}</h4>
                                                <span>No of unique Hosts</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border">
                                            <h5>Authentication Failed</h5>
                                            {mostProcessPerformedLabel.length || mostProcessPerformedData.length !== 0 ? (
                                                <BarGraph ChartLabel={mostProcessPerformedLabel} ChartData={mostProcessPerformedData} />
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex-fill p-4 rounded-3 border" >
                                            <h5>Authentication Success</h5>
                                            {mostEventActivityPerformedLabel.length || mostEventActivityPerformedData.length !== 0 ? (
                                                <BarGraph ChartLabel={mostEventActivityPerformedLabel} ChartData={mostEventActivityPerformedData} />
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="table" title="Details">
                            <div className="col-12">
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    // getFilteredTable={getFilteredTable}
                                    module={module}
                                // tab={tab}
                                />
                                <div className="" style={styles.tableWrap}>
                                    {loadTableData === true ? (
                                        <TableLoader rowCount={30} />
                                    ) : (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>Host Name</th>
                                                    <th>Process Name</th>
                                                    <th>Event Action</th>
                                                    <th>Source IP</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.length !== 0 ? (
                                                    <>
                                                        {tableData?.map((item, index) => (
                                                            <tr>
                                                                {/* <td>{item?._source.agent.hostname}</td>
                                                                <td>{item?._source.event.action}</td>
                                                                <td className='font-monospace'>{item?._source.event.code}</td>
                                                                <td>{item?._source.winlog.event_data.SubjectUserName}</td>
                                                                <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                                <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td> */}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default CyLinux