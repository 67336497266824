import React, { useState } from "react";

import { Button } from "react-bootstrap";
export default function CustomInvInformation({
  otherInformation,
  setOtherInformation,
  index,
  data,
  addMore,
}) {
  const [information, setInformation] = useState(data?.information);
  const [value, setValue] = useState(data?.value);

  const removeItem = () => {
    setOtherInformation([
      ...otherInformation.slice(0, index),
      ...otherInformation.slice(index + 1),
    ]);
  };

  const updateData = (value, key) => {
    if (key === "value") {
      setValue(value);
    } else {
      setInformation(value);
    }
    let newArr = [...otherInformation];

    newArr[index][key] = value;

    setOtherInformation(newArr);
  };

  return (
    <div className="form-group d-flex">
      <div className="col-5 col-xs-12 p-1">
        {" "}
        <label className="control-label">Other information *</label>
        <input
          type="text"
          className="form-control input-lg form-control-style"
          name="information"
          value={information}
          onChange={(e) => updateData(e.target.value, "information")}
          placeholder="Information"
        />
      </div>
      <div className="col-md-4 col-xs-12 p-1">
        {" "}
        <label className="control-label">Value *</label>
        <input
          type="text"
          className="form-control input-lg form-control-style"
          name="value"
          value={value}
          onChange={(e) => updateData(e.target.value, "value")}
          placeholder="Value"
        />
      </div>
      <div className="col-md-2 col-xs-12 p-1 mt-auto">
        {index === 0 ? (
          <Button className="btn-secondary" onClick={addMore}>
            + Add more
          </Button>
        ) : (
          <Button className="btn-danger" onClick={removeItem}>
            - Remove
          </Button>
        )}
      </div>
    </div>
  );
}