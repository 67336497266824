import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
  useMemo,
} from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  useNodesInitialized,
  useReactFlow,
  Background,
  Controls,
  getIncomers,
  // useStore,
  getOutgoers,
  isNode,
  getConnectedEdges,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import graphlib from "graphlib";
import { fpostCy } from "../../axios/axios";
import CustomNodeProcess from "./CustomNodes/CustomNodeProcess";
// import Loader from "../Loader";
import { getProcessQuery } from "../../Queries/CykitQuery";
import { shallow } from "zustand/shallow";
import useStore from "./Store/store";
import SideBar from "./SideBar";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpinnerLoader from "../Loader/SpinnerLoader";
import { getLogSourceData } from "./Methods";

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  searchType: state.searchType,
  keyword: state.keyword,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  setCollapseNodes: state.setCollapseNodes,
  setCollapseEdges: state.setCollapseEdges,
  refresh: state.refresh,
  expandedNode: state.expandedNode,
  setExpandedNode: state.setExpandedNode,
  setKeyword: state.setKeyword,
  setSearchType: state.setSearchType,
  isLoading: state.isLoading,
  setIsLoading: state.setIsLoading
});

let baseURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");

const dagreGraph = new graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 0, y: 0 };

const edgeType = "smoothstep";
const initialNodes = [];
const initialEdges = [];
const nodeWidth = 180;
const nodeHeight = 40;
const edr = localStorage.getItem("EDR_TYPE");

const getLayoutedElements = (nodes, edges, direction = "RL") => {
  const isHorizontal = direction === "RL";
  dagreGraph.setGraph({ rankdir: direction,  ranksep: 100, nodesep: 100 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "right" : "bottom";
    node.sourcePosition = isHorizontal ? "left" : "top";
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2 + 800,
      y: nodeWithPosition.y - nodeHeight / 2 + 400,
    };
    console.log("parent node");
    console.log(node);
    return node;
  });
  console.log("layout changed");
  console.log(nodes);
  console.log(edges);
  return { nodes, edges };
};

const nodeTypes = {
  CustomNodeProcess: CustomNodeProcess,
};

const CorelationFlow = (props) => {
  const proOptions = { hideAttribution: true };

  const nodesInitialized = useNodesInitialized();
  const {
    zoomIn,
    fitView,
    getNodes,
    getEdges,
    getNode,
    setViewport,
    deleteElements,
  } = useReactFlow();
  const fitViewOptions = { padding: 0.5 };
  const [startTimeRange, setStartTimeRange] = useState(null);
  const [endTimeRange, setEndTimeRange] = useState(null);
  const [hostNode, setHostNode] = useState();
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    setNodes,
    setEdges,
    setCollapseNodes,
    setCollapseEdges,
    refresh,
    expandedNode,
    setExpandedNode,
    setKeyword,
    setSearchType,
    searchType,
    keyword,
    isLoading,
    setIsLoading
  } = useStore(selector, shallow);

  const [clickedNode, setClickedNode] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const [isclosed, setIsClosed] = useState(false);
  // const [expandedNode, setExpandedNode] = useState()

  const [currentParentPage, setCurrentPartentPage] = useState(0);
  const [currentParentSize, setCurrentPartentSize] = useState();
  const [parentData, setParentData] = useState([]);
  const [direction, setDirection] = useState("");
  const [fdir, setFdir] = useState(false);
  const [logSource, setLogSource] = useState([])
  // const dimensions = useStore((store) => ({ width: store.width, height: store.height }));

  const onClick = (node, direction) => {
    setIsLoading(true);
    console.log("pop2", node);
    const dirNode = { ...node, direction: direction };
    setExpandedNode(dirNode);
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);
  }, []);
  useEffect(() => {
    setKeyword(props.keyword);
    setSearchType(props.searchType);

  }, [])
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsClosed(true);
    }
  };

  // useEffect((
  // ) => {
  //   if(props?.refreshSearch!==0)
  //   {
  //     setNodes([])
  //     setEdges([])
  //     setClickedNode("")
  //     setExpandedNode([])
  //   }

  // }, [props?.refreshSearch])

  const getLogSource = (keyword, filter, dateFilter, searchType) => {
    setIsLoading(true);
    getLogSourceData(keyword, filter, dateFilter, searchType)
      .then(res => {
        setLogSource(res?.data?.aggregations?.field?.buckets);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        // Handle error
      });
  }

  useEffect(() => {
    setIsLoading(true)
    if (props.keyword !== undefined) {
      console.log("test" + props.host);
      const result = getLogSource(props?.keyword, props?.filter, props?.dateFilter, props?.searchType);

      const test = {
        id: "1a",
        type: "CustomNodeProcess",
        nodetype: "keyword",
        sourcePosition: "right",
        data: {
          nodetype: "keyword",
          type: "keyword",
          id: `1a`,
          initFilter: [],
          log_source: logSource,
          logSource: logSource,
          keyword: props.keyword,
          filter: props.filter,
          setFilter: props.setFilter,
          dateFilter: props.dateFilter,
          searchType: props?.searchType,
          label: props?.filter?.length > 0 ? `${props.keyword} + filters` : props.keyword
        },
        position,
      };
      setNodes([...nodes, test]);
      zoomIn(4);
      // var dirNode = { ...test.data, direction: "forward" };
      setFdir(true);
      // setExpandedNode(dirNode);
      console.log("pop1");
    }
  }, [props.keyword]);

  useEffect(() => {
    setIsLoading(true)
    if (nodesInitialized) {
      const { nodes: layoutedNode, edges: layoutedEdge } = getLayoutedElements(
        nodes,
        edges
      );

      // setIsLoading(true);
      // debugger
      setNodes([...layoutedNode]);
      setEdges([...layoutedEdge]);
      zoomIn(4);

      // setViewport({ x: (dimensions.width / 2) - 20, y: 350, zoom: 2 })
      // fitView(fitViewOptions)
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }

  }, [nodesInitialized]);


  return (
    <div
      className="flow"
      style={{ height: "calc(100vh - 20.5rem)", width: "1790px" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        // fitView
        attributionPosition="bottom-left"
      >
        <Controls />
        <Background color="#cc" />
        
      </ReactFlow>

      <div className="controls">
        <SideBar close={isclosed} closefn={setIsClosed} />
      </div>
      {/* <Button
        variant="link"
        className="position-absolute top-0 left-5 bg-white"
        size="lg"
      >
        <Link
          color="light"
          to="/hunter/cykit"
          onClick={() => {
            refresh();
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>
        </Link>
      </Button> */}
      <div>
        <SpinnerLoader isLoading={isLoading} />
      </div>
    </div>
  );
};

export default CorelationFlow;
