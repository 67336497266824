import React from 'react'
import CustomToolTip from '../ToolTip/CustomToolTip';
import { Cloud, Cpu, Envelope, EnvelopeSimple, File, FileArrowUp, FileMinus, FilePlus, FileText, FileX, Files, GlobeSimple, Key, Password, SignIn, SignOut, TreeStructure, User, UserGear, UsersThree } from 'phosphor-react';
import { getTimeFromTimestamp, } from '../../Utils/Utils';
import globalStyles from '../../constants/globalStyles';
import SharePointAuditOperation from '../../constants/SharePointAuditOperation';

const UebaCykitTimelineItem = ({ log_source, item,active, expClickedNode }) => {
    let event = ""
    let icon = ""

    // console.log('expClickedNode', expClickedNode)

    const styles = {
        tooltipWrap: {
            // width: "500px"
        }
    };

    // // Define a style object with the green background color
const greenBackgroundColor = {  
     backgroundColor: 'green' 
      };

    const LogonTypes = {
        "1": {
            "Description": "Interactive",
            "Explanation": "This logon type indicates a user logged on interactively to a computer. For example, a user logging on to their own workstation would generate this type of logon."
        },
        "2": {
            "Description": "Network",
            "Explanation": "This logon type indicates a user logged on to the computer using a network connection. For example, accessing a shared folder on another computer or server."
        },
        "3": {
            "Description": "Batch",
            "Explanation": "This logon type indicates a batch job (scheduled task) has started. For example, a task running under a scheduled job."
        },
        "4": {
            "Description": "Service",
            "Explanation": "This logon type indicates a service has started. For example, a service that logs on with a service account."
        },
        "5": {
            "Description": "Unlock",
            "Explanation": "This logon type indicates the workstation was unlocked. For example, when a user presses Ctrl+Alt+Delete to unlock their computer."
        },
        "6": {
            "Description": "NetworkCleartext",
            "Explanation": "This logon type indicates a user logged on using a Cleartext password. Not recommended for security reasons."
        },
        "7": {
            "Description": "NewCredentials",
            "Explanation": "This logon type indicates a user logged on with new credentials after authenticating to a network. For example, a user accessing a resource using different credentials after initially logging on."
        },
        "8": {
            "Description": "RemoteInteractive",
            "Explanation": "This logon type indicates a remote user logged on interactively to a computer. For example, a user connecting to a computer via Remote Desktop Protocol (RDP)."
        },
        "9": {
            "Description": "CachedInteractive",
            "Explanation": "This logon type indicates a user logged on using cached credentials. For example, a user who disconnected from a network but can still log on using their cached credentials."
        },
        "10": {
            "Description": "CachedRemoteInteractive",
            "Explanation": "This logon type represents a remote user logging on interactively to a computer using cached credentials."
        },
        "11": {
            "Description": "CachedUnlock",
            "Explanation": "This logon type indicates a workstation was unlocked using cached credentials after being locked."
        }
    };


    const WindowsLoginEventCodes = {
        "4624": {
            "Description": "Successful account logon",
            "Explanation": "This event is generated when a user successfully logs on to a computer or network."
        },
        "4625": {
            "Description": "Failed account logon",
            "Explanation": "This event is generated when a user's logon attempt fails, often due to incorrect credentials or permission issues."
        },
        "4634": {
            "Description": "An account was logged off",
            "Explanation": "This event is generated when a user logs off from a computer or network."
        },
        "4648": {
            "Description": "A logon was attempted using explicit credentials",
            "Explanation": "This event is generated when a user attempts to log in using explicit credentials, usually for remote access."
        },
        "4768": {
            "Description": "A Kerberos authentication ticket (TGT) request was received",
            "Explanation": "This event is generated when a user requests a Ticket Granting Ticket (TGT) from the Key Distribution Center (KDC) in a Kerberos authentication scenario."
        },
        "4672": {
            "Description": "Special privileges assigned to new logon",
            "Explanation": "This event is generated when a user logs in, and special privileges are assigned to the new logon session."
        },
        "4776": {
            "Description": "The computer attempted to validate credentials",
            "Explanation": "This event is generated when a computer attempts to validate the credentials of a user."
        },
        "4778": {
            "Description": "A session was reconnected to a Window Station",
            "Explanation": "This event is generated when a user reconnects to a session on a Window Station."
        },
        "5140": {
            "Description": "A network share object was accessed",
            "Explanation": "This event is generated when a user or application accesses a network share object, such as a file or folder."
        }
    };

    const EmailDeliveryActions = {
        "delivered": {
            "Action": "Deliver",
            "Description": "Indicates that the message was successfully delivered to the recipient's mailbox."
        },
        "failed": {
            "Action": "Fail",
            "Description": "Indicates that the message wasn't delivered."
        },
        "expanded": {
            "Action": "Expand",
            "Description": "Related to distribution groups. When a message is sent to a group, it's 'expanded' to show all individual recipients."
        },
        "redirected": {
            "Action": "Redirect",
            "Description": "Indicates that the message was rerouted to a different destination, possibly due to mail flow rules."
        },
        "resolved": {
            "Action": "Resolve",
            "Description": "Indicates that the email has been validated with mail flow rules that require a decision about whether to allow or block the message."
        },
        "deleted": {
            "Action": "Delete",
            "Description": "The message was deleted by a transport/mail flow rule."
        },
        "quarantined": {
            "Action": "Quarantine",
            "Description": "The message was moved to quarantine, usually due to matching a malware or spam policy."
        }
    };

    const O365UserTypes = {
        "0": {
            "Value": 0,
            "MemberName": "Regular",
            "Description": "A regular user."
        },
        "1": {
            "Value": 1,
            "MemberName": "Reserved",
            "Description": "A reserved user."
        },
        "2": {
            "Value": 2,
            "MemberName": "Admin",
            "Description": "An administrator."
        },
        "3": {
            "Value": 3,
            "MemberName": "DcAdmin",
            "Description": "A Microsoft datacenter operator."
        },
        "4": {
            "Value": 4,
            "MemberName": "System",
            "Description": "A system account."
        },
        "5": {
            "Value": 5,
            "MemberName": "Application",
            "Description": "An application."
        },
        "6": {
            "Value": 6,
            "MemberName": "ServicePrincipal",
            "Description": "A service principal."
        },
        "7": {
            "Value": 7,
            "MemberName": "CustomPolicy",
            "Description": "A custom policy."
        },
        "8": {
            "Value": 8,
            "MemberName": "SystemPolicy",
            "Description": "A system policy."
        }
    }

    const MailboxAuditLogEvents = {
        "Create": {
            "Action": "Create",
            "Description": "Item created in the mailbox, like a new email or calendar appointment."
        },
        "MailboxLogin": {
            "Action": "MailboxLogin",
            "Description": "User logged into their mailbox."
        },
        "Update": {
            "Action": "Update",
            "Description": "An existing item was updated."
        },
        "MoveToDeletedItems": {
            "Action": "MoveToDeletedItems",
            "Description": "Item was moved to the 'Deleted Items' folder."
        },
        "MailItemsAccessed": {
            "Action": "MailItemsAccessed",
            "Description": "Mailbox or individual message is accessed."
        },
        "SoftDelete": {
            "Action": "SoftDelete",
            "Description": "Item was deleted from the 'Deleted Items' folder."
        },
        "HardDelete": {
            "Action": "HardDelete",
            "Description": "Item was permanently deleted."
        },
        "Send": {
            "Action": "Send",
            "Description": "User sent or replied to an email."
        },
        "SendAs": {
            "Action": "SendAs",
            "Description": "An email was sent as another user (requires SendAs permission)."
        },
        "SendOnBehalf": {
            "Action": "SendOnBehalf",
            "Description": "An email was sent on behalf of another user (requires SendOnBehalf permission)."
        },
        "Move": {
            "Action": "Move",
            "Description": "Item was moved to a different folder."
        },
        "Copy": {
            "Action": "Copy",
            "Description": "Item was copied to another folder."
        },
        "FolderBind": {
            "Action": "FolderBind",
            "Description": "User viewed content in a folder."
        }
    }
    function isJSONObject(obj) {
        return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
    }
    if (log_source === 'EDR') {
       

        switch (item?.event_category) {
            // file
            case "file":
                icon = <span className="tile-icon rounded-5 bg-orange"><File size={24} color={globalStyles.white} /></span>
                break;
            case "process":
                icon = <span className="tile-icon rounded-5 bg-success"><Cpu size={24} color={globalStyles.white} /></span>
                break;
            case "network":
                icon = <span className="tile-icon rounded-5 bg-warning"><GlobeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "registry":
                icon = <span className="tile-icon rounded-5 bg-warning"><FileX size={24} color={globalStyles.white} /></span>
                break;
            case "authentication":
                icon = <span className="tile-icon rounded-5 bg-primary">
                    {(item?.event_action == 'logged-in' || item?.event_action == 'log_on') &&
                        <SignIn size={24} color={globalStyles.white} />
                    }
                    {(item?.event_action == 'logon-failed') &&
                        <Password size={24} color={globalStyles.white} />
                    }
                    {(item?.event_action == 'log_off') &&
                        <SignOut size={24} color={globalStyles.white} />
                    }
                </span>
                break;
            case "iam":
                icon = <span className="tile-icon rounded-5 bg-success"><UserGear size={24} color={globalStyles.white} /></span>
                break;

            case "library":
                icon = <span className="tile-icon rounded-5 bg-amber"><Files size={24} color={globalStyles.white} /></span>
                break;

            default:
                icon = <span className="tile-icon rounded-5 bg-secondary"><File size={24} color={globalStyles.white} /></span>
                event = item?.event_action

        }
        switch (item?.event_action) {
            // file
            case "deletion":
                event = 'deleted'
                break;
            case "creation":
                event = 'created'
                break;
            case "overwrite":
                event = 'overwrited'
                break;
            case "rename":
                event = 'renamed'
                break;
            // process
            case "end":
                event = 'ended'
                break;
            case "start":
                event = 'started'
                break;
            case "fork":
                event = 'forked'
                break;
            // network
            case "connection_attempted":
                event = 'attempted to connect to the IP'
                break;
            case "lookup_result":
                event = 'made a DNS request and is completed for the domain'
                break;
            case "disconnect_received":
                event = 'attempted to disconnect from the IP'
                break;
            case "lookup_requested":
                event = 'made a DNS lookup request for the domain'
                break;
            // registry
            case "modification":
                event = 'modified registry of'
                break;
            case "query":
                event = 'queried registry'
                break;
            // Authentication
            case "logon-failed":
                event = 'made a failed attempt to login to'
                break;
            case "logged-in":
                event = 'logged in to'
                break;
            case "log_on":
                event = item?.outcome == 'success' ? 'logged in to' : 'made a failed attempt to login'
                break;
            case "log_off":
                event = item?.outcome == 'success' ? 'logged out from' : 'made a failed attempt to logout'
                break;
            // IAM
            case "added-user-account":
                event = item?.outcome == 'success' ? 'created a new user account' : 'made a failed attempt to create an account'
                break;
            case "disabled-user-account":
                event = item?.outcome == 'success' ? 'disabled the account' : 'made a failed attempt to disable the account'
                break;
            case "reset-password":
                event = item?.outcome == 'success' ? 'reset the password of account' : 'made a failed attempt to reset the password of account'
                break;
            case "changed-password":
                event = item?.outcome == 'success' ? 'changed the password of account' : 'made a failed attempt to change the password of account'
                break;
            // library
            case "load":
                event = 'loaded'
                break;
            case "Publish":
                event = 'Published Registry of'
                break;
            default:
                event = item?.event_action

        }
     

        return (
            <div className={`d-flex flex-row gap-3 `}>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>
                    {item?.event_category === 'file' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event} {item?.event_category}{" "}
                            <CustomToolTip content={<strong>{item?.file_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>File name - </span>
                                            <span><strong>{item?.file_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>File path - </span>
                                            <span><strong>{item?.file_path}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process - </span>
                                            <span><strong>{item?.process_name}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" on device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }

                    {item?.event_category === 'process' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event} {item?.event_category}{" "}
                            <CustomToolTip content={<strong>{item?.process_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process name - </span>
                                            <span><strong>{item?.process_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process ID - </span>
                                            <span><strong>{item?.item?.process?.pid}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Command - </span>
                                            <span><strong>{item?.command}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" on device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }

                    {item?.event_category === 'network' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event}{" "}
                            {(item?.event_action === 'lookup_requested' || item?.event_action === 'lookup_result') &&
                                <CustomToolTip content={<strong>{item?.item?.dns?.question?.name}</strong>} dataPlacement={"bottom"}
                                    dataToggle={
                                        <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>URL - </span>
                                                <span><strong>{item?.item?.dns?.question?.name}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Type - </span>
                                                <span><strong>{item?.item?.dns?.question?.type}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Result - </span>
                                                <span><strong>{item?.message}</strong></span>
                                            </div>
                                        </div>
                                    } />
                            }
                            {(item?.event_action === 'connection_attempted' || item?.event_action === 'disconnect_received') &&
                                <>
                                    <CustomToolTip content={<strong>{item?.item?.destination?.ip}</strong>} dataPlacement={"bottom"}
                                        dataToggle={
                                            <div className="d-flex flex-column text-left">
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Destination IP - </span>
                                                    <span><strong>{item?.item?.destination?.ip}</strong></span>
                                                </div>
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Destination port - </span>
                                                    <span><strong>{item?.item?.destination?.port}</strong></span>
                                                </div>
                                                {item?.item?.destination?.geo?.region_name !== undefined || item?.item?.destination?.geo?.country_name !== undefined &&
                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                        <span>Destination - </span>
                                                        <span><strong>{item?.item?.destination?.geo?.region_name + ", " + item?.item?.destination?.geo?.country_name}</strong></span>
                                                    </div>
                                                }
                                            </div>
                                        } />

                                    {" from IP "}
                                    <CustomToolTip content={<strong>{item?.item?.source?.ip}</strong>} dataPlacement={"bottom"}
                                        dataToggle={
                                            <div className="d-flex flex-column text-left">
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Source IP - </span>
                                                    <span><strong>{item?.item?.source?.ip}</strong></span>
                                                </div>
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Source port - </span>
                                                    <span><strong>{item?.item?.source?.port}</strong></span>
                                                </div>
                                            </div>
                                        } />
                                </>
                            }
                            {" on device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        {item?.item?.host?.ip &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>IP - </span>
                                                <span><strong>{item?.item?.host?.ip ? item?.item?.host?.ip[0] : "-"}</strong></span>
                                            </div>
                                        }
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.item?.host?.os?.full ? item?.item?.host?.os?.full : item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {/* {" "} using process {" host "} */}
                        </div>
                    }

                    {item?.event_category === 'registry' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event}{" "}
                            <CustomToolTip content={<strong>{item?.registry_key}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Registry key - </span>
                                            <span><strong>{item?.registry_key}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Registry value - </span>
                                            <span><strong>{item?.registry_value}</strong></span>
                                        </div>
                                        {item?.item?.registry?.data &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Data - </span>
                                                <span><strong>{item?.item?.registry?.data?.strings?.join(", ")}</strong></span>
                                            </div>
                                        }
                                    </div>
                                } />
                            {" using process "}
                            <CustomToolTip content={<strong>{item?.process_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process name - </span>
                                            <span><strong>{item?.process_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process ID - </span>
                                            <span><strong>{item?.pid}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Executable - </span>
                                            <span><strong>{item?.item?.process?.executable}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" on the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.item?.host?.os?.full ? item?.item?.host?.os?.full : item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }

                    {item?.event_category === 'library' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event} {item?.event_category}{" "}
                            {item?.item?.dll?.name &&
                                <CustomToolTip content={<strong>{item?.item?.dll?.name}</strong>} dataPlacement={"bottom"}
                                    dataToggle={
                                        <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Library - </span>
                                                <span><strong>{item?.item?.dll?.name}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Path - </span>
                                                <span><strong>{item?.item?.dll?.path}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Process - </span>
                                                <span><strong>{item?.item?.process?.name}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Process ID - </span>
                                                <span><strong>{item?.item?.process?.pid}</strong></span>
                                            </div>
                                        </div>
                                    } />
                            }

                            <CustomToolTip content={<strong>{item?.file_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>File name - </span>
                                            <span><strong>{item?.file_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>File path - </span>
                                            <span><strong>{item?.file_path}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Process - </span>
                                            <span><strong>{item?.process_name}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" on device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }

                    {item?.event_category === 'authentication' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event} {" the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>OS - </span>
                                            <span><strong>{item?.item?.host?.os?.full ? item?.item?.host?.os?.full : item?.os ? item?.os : item?.item?.host?.product?.name ? item?.item?.host?.product?.name : ''}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }

                    {item?.event_category === 'iam' &&
                        <div className="col-12">The user {" "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2'>
                                            <span>User name - </span>
                                            <span><strong>{item?.user_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}{event} {" "}
                            <CustomToolTip content={<strong>{item?.target_account}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>User name - </span>
                                            <span><strong>{item?.target_account}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" on the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                        </div>
                    }


                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-purlpe'>EDR</span>
                        <span className='badge bg-info'>{item?.event_category}</span>
                    </div>
                </div>
            </div>
        )
    }

    if (log_source === 'AD') {
        {console.log(item)}

        switch (item?.event_action) {
            // AD
            case "Logon":
                icon = <span className="tile-icon rounded-5 bg-primary">
                    {/* {(item?.event_action == 'Logon') && */}
                    <SignIn size={24} color={globalStyles.white} />
                    {/* } */}
                </span>
                break;
            case "Special Logon":
                icon = <span className="tile-icon rounded-5 bg-primary"><SignIn size={24} color={globalStyles.white} /></span>
                break;
            case "Security Group Management":
                icon = <span className="tile-icon rounded-5 bg-amber"><UsersThree size={24} color={globalStyles.white} /></span>
                break;
            case "User Account Management":
                icon = <span className="tile-icon rounded-5 bg-green-2"><User size={24} color={globalStyles.white} /></span>
                break;

            default:
                icon = <span className="tile-icon rounded-5 bg-amber"><TreeStructure size={24} color={globalStyles.white} /></span>

        }
        switch (item?.event_action) {
            // logon
            case "Logon":
                event = item?.outcome == 'success' ? 'successful login attempt' : 'failed login attempt'
                break;
            case "Special Logon":
                event = item?.outcome == 'success' ? 'successful login attempt' : 'failed login attempt'
                break;
            case "Security Group Management":
                event = item?.message.substring(0, item?.message.indexOf("."))
                break;
            case "User Account Management":
                event = item?.message.substring(0, item?.message.indexOf("."))
                break;
            case "rename":
                event = 'renamed'
                break;
            default:
                event = item?.event_action

        }

        return (
            <div className={`d-flex flex-row gap-3 `}>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>
                    {(item?.event_action === 'Logon' || item?.event_action === 'Special Logon') &&
                        <div className="col-12">The user {" "} 
                            <CustomToolTip content={<strong>{item?.subject_account == '-' ? item?.target_account : item?.subject_account}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.subject_account === '-' ? item?.target_account : item?.subject_account}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Domain - </span>
                                            <span><strong>{item?.user_domain ? item?.user_domain : item?.item?.winlog?.event_data?.TargetDomainName}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {/* {" "}{event} {item?.event_category}{" "} */}
                            {" made a "}
                            <CustomToolTip content={<strong>{event}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        {item?.item?.winlog?.event_data?.LogonType &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Logon type - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.LogonType + " (" + LogonTypes[item?.item?.winlog?.event_data?.LogonType].Description + ")"}</strong></span>
                                            </div>
                                        }

                                        {item?.item?.winlog?.event_data?.LogonType &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Explanation - </span>
                                                <span><strong>{LogonTypes[item?.item?.winlog?.event_data?.LogonType].Explanation}</strong></span>
                                            </div>
                                        }

                                        {(item?.event_code && WindowsLoginEventCodes[item?.event_code]) &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Event code - </span>
                                                <span><strong>{item?.event_code + " (" + WindowsLoginEventCodes[item?.event_code]?.Explanation + ")"}</strong></span>
                                            </div>
                                        }
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" to the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}
                            {(item?.item?.winlog?.event_data?.LogonType == '3') &&
                                <>
                                    {" from the device "}
                                    <CustomToolTip content={<strong>{item?.item?.winlog?.event_data?.WorkstationName}</strong>} dataPlacement={"bottom"}
                                        dataToggle={
                                            <div className="d-flex flex-column text-left">
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Workstation - </span>
                                                    <span><strong>{item?.item?.winlog?.event_data?.WorkstationName}</strong></span>
                                                </div>
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>IP - </span>
                                                    <span><strong>{item?.item?.winlog?.event_data?.IpAddress}</strong></span>
                                                </div>
                                            </div>
                                        } />
                                </>
                            }

                        </div>
                    }

                    {(item?.event_action === 'Security Group Management' || item?.event_action === 'User Account Management') &&
                        <div className="col-12">
                            {/* {event} */}
                            <CustomToolTip content={<strong>{event}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        {item?.item?.winlog?.event_data?.TargetUserName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Target user - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.TargetUserName}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.TargetDomainName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Target Domain - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.TargetDomainName}</strong></span>
                                            </div>
                                        }
                                        {(item?.event_code && WindowsLoginEventCodes[item?.event_code]) &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Event code - </span>
                                                <span><strong>{item?.event_code + " (" + WindowsLoginEventCodes[item?.event_code]?.Explanation + ")"}</strong></span>
                                            </div>
                                        }
                                        {(item?.message) &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Details - </span>
                                                <span><strong>{item?.message}</strong></span>
                                            </div>
                                        }
                                    </div>
                                } />
                            {" by the user "}
                            <CustomToolTip content={<strong>{item?.subject_account == '-' ? item?.target_account : item?.subject_account}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>User name - </span>
                                            <span><strong>{item?.subject_account === '-' ? item?.target_account : item?.subject_account}</strong></span>
                                        </div>
                                        {item?.item?.winlog?.event_data?.SubjectDomainName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Domain - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.SubjectDomainName}</strong></span>
                                            </div>
                                        }
                                    </div>
                                } />
                            {" on the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />
                            {" "}
                            {(item?.item?.winlog?.event_data?.LogonType == '3') &&
                                <>
                                    {" from the device "}
                                    <CustomToolTip content={<strong>{item?.item?.winlog?.event_data?.WorkstationName}</strong>} dataPlacement={"bottom"}
                                        dataToggle={
                                            <div className="d-flex flex-column text-left">
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Workstation - </span>
                                                    <span><strong>{item?.item?.winlog?.event_data?.WorkstationName}</strong></span>
                                                </div>
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>IP - </span>
                                                    <span><strong>{item?.item?.winlog?.event_data?.IpAddress}</strong></span>
                                                </div>
                                            </div>
                                        } />
                                </>
                            }

                        </div>
                    }


                    {/* {(item?.event_action !== 'Logon' && item?.event_action !== 'Special Logon' && item?.event_action !== 'Logon' && item?.event_action !== 'Special Logon') && */}
                    {(!item?.event_action || (item?.event_action !== 'Logon' && item?.event_action !== 'Special Logon' && item?.event_action !== 'Security Group Management' && item?.event_action !== 'User Account Management')) &&
                        <div className="col-12">
                            {/* {event} */}
                            {/* {item?.message} */}
                            <CustomToolTip content={<strong>{item?.message?.substring(0, item?.message?.indexOf("."))}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        {item?.item?.winlog?.event_data?.Application &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Application - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.SubjectUserName}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.ProcessName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Process - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.ProcessName}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.ProcessId &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Process ID - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.ProcessId}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.SubjectUserName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Subject user - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.SubjectUserName}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.TargetUserName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Target user - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.TargetUserName}</strong></span>
                                            </div>
                                        }
                                        {item?.item?.winlog?.event_data?.SubjectDomainName &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Subject domain - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.SubjectDomainName}</strong></span>
                                            </div>
                                        }
                                    </div>
                                } />
                            {" on the device "}
                            <CustomToolTip content={<strong>{item?.host_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>OS - </span>
                                            <span><strong>{item?.os}</strong></span>
                                        </div>
                                    </div>
                                } />

                        </div>
                    }


                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-green-2'>Windows Events</span>
                    </div>
                </div>
            </div>
        )
    }

    if (log_source === 'OFFICE 365 EMAIL') {
        switch (item?.email_status) {
            // Email
            case "Delivered":
                icon = <span className="tile-icon rounded-5 bg-green-1"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Resolved":
                icon = <span className="tile-icon rounded-5 bg-primary"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Failed":
                icon = <span className="tile-icon rounded-5 bg-red"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Quarantined":
                icon = <span className="tile-icon rounded-5 bg-amber"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;

            default:
                icon = <span className="tile-icon rounded-5 bg-purlpe"><User size={24} color={globalStyles.white} /></span>

        }
        switch (item?.email_status) {
            // Email
            case "Delivered":
                event = 'delivered'
                break;
            case "Resolved":
                event = 'resolved'
                break;
            case "Failed":
                event = 'failed'
                break;
            case "Quarantined":
                event = 'quarantined'
                break;
            default:
                event = item?.event_action

        }

        return (
            <div className='d-flex flex-row gap-3'>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>
                                    {/* {(item?.event_action === 'Logon' || item?.event_action === 'Special Logon') && */}
                    <div className="col-12">
                        {"An email with subject "} 
                        <strong>{'"' + (isJSONObject(item?.subject) ? item?.subject?.["#text"]!==undefined?item?.subject?.["#text"]:"" : item?.subject!= undefined ?item?.subject:"" ) + '"'}</strong>{" to "}
                        <strong>{item?.reciever}</strong>{" from "}
                        {/* <strong>{item?.sender}</strong> */}
                        <CustomToolTip content={<strong>{item?.sender}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                    {item?.host_name &&
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Host name - </span>
                                            <span><strong>{item?.host_name}</strong></span>
                                        </div>
                                    }
                                </div>
                            } />
                        {" has been "}
                        <CustomToolTip content={<strong>{event}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                    {EmailDeliveryActions[event] &&
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            {/* <span>Description - </span> */}
                                            <span><strong>{EmailDeliveryActions[event]?.Description}</strong></span>
                                        </div>
                                    }
                                </div>
                            } />
                    </div>
                    {/* } */}

                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        {/* <span className='badge bg-orange'>Office 365</span> */}
                        <span className='badge bg-pink-2'>Email</span>
                    </div>
                </div>
            </div>
        )
    }


    if (log_source === 'AZURE AD') {
        switch (item?.event_action) {
            // AD
            case "UserLoggedIn":
                icon = <span className="tile-icon rounded-5 bg-primary"><SignIn size={24} color={globalStyles.white} /></span>
                break;
            case "UserLoginFailed":
                icon = <span className="tile-icon rounded-5 bg-red"><SignIn size={24} color={globalStyles.white} /></span>
                break;
            default:
                icon = <span className="tile-icon rounded-5 bg-purlpe"><User size={24} color={globalStyles.white} /></span>

        }
        switch (item?.event_action) {
            // logon
            case "UserLoggedIn":
                event = (item?.status)?.toLowerCase() == 'success' ? 'successful login attempt' : 'failed login attempt'
                break;
            case "UserLoginFailed":
                event = 'failed login attempt'
                break;

            default:
                event = item?.event_action

        }

        return (
            <div className='d-flex flex-row gap-3'>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>                    {(item?.event_action === 'UserLoggedIn' || item?.event_action === 'UserLoginFailed') &&
                        <div className="col-12">
                            {"The account "}
                            <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left">
                                        {/* {item?.item?.UserType && */}
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Account type - </span>
                                            <span><strong>{O365UserTypes[item?.item?.UserType]?.MemberName}</strong></span>
                                        </div>
                                        {/* } */}
                                    </div>
                                } />
                            {/* {" "}{event} {item?.event_category}{" "} */}
                            {" made a "}
                            <CustomToolTip content={<strong>{event}</strong>} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                        {item?.item?.winlog?.event_data?.LogonType &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Logon type - </span>
                                                <span><strong>{item?.item?.winlog?.event_data?.LogonType + " (" + LogonTypes[item?.item?.winlog?.event_data?.LogonType].Description + ")"}</strong></span>
                                            </div>
                                        }

                                        {item?.item?.winlog?.event_data?.LogonType &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Explanation - </span>
                                                <span><strong>{LogonTypes[item?.item?.winlog?.event_data?.LogonType].Explanation}</strong></span>
                                            </div>
                                        }

                                        {(item?.event_code && WindowsLoginEventCodes[item?.event_code]) &&
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Event code - </span>
                                                <span><strong>{item?.event_code + " (" + WindowsLoginEventCodes[item?.event_code]?.Explanation + ")"}</strong></span>
                                            </div>
                                        }
                                    </div>
                                } />
                            {/* {" to "} */}
                            {(item?.item?.ClientIP) &&
                                <>
                                    {" from "}
                                    <CustomToolTip content={<strong>{item?.item?.ClientIP + " (" + item?.item?.cybSourceCountry + ")"}</strong>} dataPlacement={"bottom"}
                                        dataToggle={
                                            <div className="d-flex flex-column text-left">
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Source IP - </span>
                                                    <span><strong>{item?.item?.ClientIP}</strong></span>
                                                </div>
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Source country - </span>
                                                    <span><strong>{item?.item?.cybSourceCountry}</strong></span>
                                                </div>
                                                {item?.item?.["DeviceProperties.DisplayName"] &&
                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                        <span>Device name - </span>
                                                        <span><strong>{item?.item?.["DeviceProperties.DisplayName"]}</strong></span>
                                                    </div>
                                                }
                                                {item?.item?.["DeviceProperties.BrowserType"] &&
                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                        <span>Browser - </span>
                                                        <span><strong>{item?.item?.["DeviceProperties.BrowserType"]}</strong></span>
                                                    </div>
                                                }
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>OS - </span>
                                                    <span><strong>{item?.os}</strong></span>
                                                </div>
                                            </div>
                                        } />
                                </>
                            }

                        </div>
                    }




                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-orange'>Office 365</span>
                        <span className='badge bg-info'>Azure AD</span>
                    </div>
                </div>
            </div>
        )
    }

    if (log_source === 'OneDrive') {
        switch (item?.event_action) {
            // OneDrive
            case "PageViewed":
                icon = <span className="tile-icon rounded-5 bg-primary"><Cloud size={24} color={globalStyles.white} /></span>
                break;
            default:
                icon = <span className="tile-icon rounded-5 bg-primary"><Cloud size={24} color={globalStyles.white} /></span>

        }
        switch (item?.event_action) {
            // OneDrive
            case "PageViewed":
                event = 'viewed the page'
                break;

            default:
                event = item?.event_action

        }

        return (
            <div className='d-flex flex-row gap-3'>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>                    {/* {(item?.event_action === 'PageViewed' || item?.event_action === 'UserLoginFailed') && */}
                    <div className="col-12">
                        {"The account "}
                        <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left">
                                    {/* {item?.item?.UserType && */}
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Account type - </span>
                                        <span><strong>{O365UserTypes[item?.item?.UserType]?.MemberName}</strong></span>
                                    </div>
                                    {/* } */}
                                </div>
                            } />
                        {/* {" "}{event}{" "} */}

                        {(item?.item?.ClientIP) &&
                            <>
                                {" from "}
                                <CustomToolTip content={<strong>{item?.item?.ClientIP + " (" + item?.item?.cybSourceCountry + ")"}</strong>} dataPlacement={"bottom"}
                                    dataToggle={
                                        <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Source IP - </span>
                                                <span><strong>{item?.item?.ClientIP}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Source country - </span>
                                                <span><strong>{item?.item?.cybSourceCountry}</strong></span>
                                            </div>
                                            {item?.item?.BrowserName &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Browser - </span>
                                                    <span><strong>{item?.item?.BrowserName}</strong></span>
                                                </div>
                                            }
                                            {item?.platform &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Platform - </span>
                                                    <span><strong>{item?.platform}</strong></span>
                                                </div>
                                            }
                                            {item?.os &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>OS - </span>
                                                    <span><strong>{item?.os}</strong></span>
                                                </div>
                                            }
                                            {item?.item?.UserAgent &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>User agent - </span>
                                                    <span><strong>{item?.item?.UserAgent}</strong></span>
                                                </div>
                                            }
                                        </div>
                                    } />
                            </>
                        }
                        {" has performed an action "}
                        <CustomToolTip content={<strong>{item?.event_action}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left">
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Object - </span>
                                        <span><strong>{item?.objectId}</strong></span>
                                    </div>
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Details - </span>
                                        <span><strong>{SharePointAuditOperation[item?.event_action]}</strong></span>
                                    </div>
                                </div>
                            } />

                    </div>
                    {/* } */}




                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-orange'>Office 365</span>
                        <span className='badge bg-info'>Onedrive</span>
                    </div>
                </div>
            </div>
        )
    }

    if (log_source === 'SharePoint') {
        switch (item?.event_action) {
            // SharePoint
            case "PageViewed":
                icon = <span className="tile-icon rounded-5 bg-primary"><Cloud size={24} color={globalStyles.white} /></span>
                break;
            default:
                icon = <span className="tile-icon rounded-5 bg-primary"><Cloud size={24} color={globalStyles.white} /></span>

        }
        switch (item?.event_action) {
            // SharePoint
            case "PageViewed":
                event = 'viewed the page'
                break;

            default:
                event = item?.event_action

        }
 
        return (
            <div className='d-flex flex-row gap-3'>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>                    {/* {(item?.event_action === 'PageViewed' || item?.event_action === 'UserLoginFailed') && */}
                    <div className="col-12">
                        {"The account "}
                        <CustomToolTip content={<strong>{item?.user_name}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left">
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Account type - </span>
                                        <span><strong>{O365UserTypes[item?.item?.UserType]?.MemberName}</strong></span>
                                    </div>
                                </div>
                            } />

                        {(item?.item?.ClientIP) &&
                            <>
                                {" from "}
                                <CustomToolTip content={<strong>{item?.item?.ClientIP + " (" + item?.item?.cybSourceCountry + ")"}</strong>} dataPlacement={"bottom"}
                                    dataToggle={
                                        <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Source IP - </span>
                                                <span><strong>{item?.item?.ClientIP}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Source country - </span>
                                                <span><strong>{item?.item?.cybSourceCountry}</strong></span>
                                            </div>
                                            {item?.item?.BrowserName &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Browser - </span>
                                                    <span><strong>{item?.item?.BrowserName}</strong></span>
                                                </div>
                                            }
                                            {item?.platform &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>Platform - </span>
                                                    <span><strong>{item?.platform}</strong></span>
                                                </div>
                                            }
                                            {item?.os &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>OS - </span>
                                                    <span><strong>{item?.os}</strong></span>
                                                </div>
                                            }
                                            {item?.item?.UserAgent &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    <span>User agent - </span>
                                                    <span><strong>{item?.item?.UserAgent}</strong></span>
                                                </div>
                                            }
                                        </div>
                                    } />
                            </>
                        }
                        {" has performed an action "}
                        <CustomToolTip content={<strong>{item?.event_action}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left">
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Object - </span>
                                        <span><strong>{item?.objectId}</strong></span>
                                    </div>
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Details - </span>
                                        <span><strong>{SharePointAuditOperation[item?.event_action]}</strong></span>
                                    </div>
                                </div>
                            } />

                    </div>
                    {/* } */}


                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-orange'>Office 365</span>
                        <span className='badge bg-info'>Share point</span>
                    </div>
                </div>
            </div>
        )
    }

    if (log_source === 'Exchange') {
        switch (item?.event_action) {
            // Email
            case "Delivered":
                icon = <span className="tile-icon rounded-5 bg-green-1"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Resolved":
                icon = <span className="tile-icon rounded-5 bg-primary"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Failed":
                icon = <span className="tile-icon rounded-5 bg-red"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;
            case "Quarantined":
                icon = <span className="tile-icon rounded-5 bg-amber"><EnvelopeSimple size={24} color={globalStyles.white} /></span>
                break;

            default:
                icon = <span className="tile-icon rounded-5 bg-purlpe"><EnvelopeSimple size={24} color={globalStyles.white} /></span>

        }
        switch (item?.event_action) {
            // Email
            case "Create":
                event = 'created'
                break;
            case "Update":
                event = 'updated'
                break;
            case "MailboxLogin":
                event = 'logged in to mailbox'
                break;
            case "MoveToDeletedItems":
                event = 'moved to trash'
                break;
            case "SoftDelete":
                event = 'soft deleted'
                break;
            case "HardDelete":
                event = 'hard deleted'
                break;
            case "Send":
                event = 'sent'
                break;
            case "SendAs":
                event = 'sent'
                break;
            case "SendOnBehalf":
                event = 'sent'
                break;
            case "Move":
                event = 'moved'
                break;
            case "Copy":
                event = 'copied'
                break;
            case "FolderBind":
                event = 'viewed'
                break;
            default:
                event = item?.event_action

        }

        return (
            <div className='d-flex flex-row gap-3'>
                {icon}
                {/* <span className='icon'><FileMinus /></span> */}
                <div className={`p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2 ${active && expClickedNode === item.id ? 'active' : ''}`}
            style={active && expClickedNode === item.id ? { backgroundColor: '#fff3cd' } : {}}>                    {/* {(item?.event_action === 'Logon' || item?.event_action === 'Special Logon') && */}
                    <div className="col-12">
                        {item?.event_action == 'FolderBind' ? "A folder was " : "An email was "}
                        <CustomToolTip content={<strong>{event}</strong>} dataPlacement={"bottom"}
                            dataToggle={
                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                        <span>Description - </span>
                                        <span><strong>{MailboxAuditLogEvents[item?.event_action]?.Description}</strong></span>
                                    </div>
                                    {item?.item?.["Item.ParentFolder.Path"] &&
                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Path - </span>
                                            <span><strong>{item?.item?.["Item.ParentFolder.Path"]}</strong></span>
                                        </div>
                                    }
                                </div>
                            } />
                        {" by the account "}
                        <strong>{item?.user_name}</strong>
                        {" from IP "}
                        <strong>{item?.ClientIP + " (" + item?.srcCountry + ")"}</strong>
                        {/* {" has done an action "} */}
                        {" "}

                        {item?.exchange_sub ?
                            <>{" with subject "}  <strong>{'"' + item?.exchange_sub + '"'}</strong></> :
                            " without any subject"}


                        {item?.item?.["Item.Attachments"] &&
                            <>
                                {" and "}
                                <CustomToolTip content={<strong>attachments</strong>} dataPlacement={"bottom"}
                                    dataToggle={
                                        <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                            {item?.host_name &&
                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                    {/* <span>Attachments - </span> */}
                                                    <span><strong>{item?.item?.["Item.Attachments"]}</strong></span>
                                                </div>
                                            }
                                        </div>
                                    } />
                            </>
                        }
                    </div>
                    {/* } */}


                    <div className='d-flex gap-2 col-12'>
                        <span className="text-left d-flex justify-content-start modal-label modal-label-sm">
                            {getTimeFromTimestamp(
                                item?.timestamp
                            )}
                        </span>
                        <span className='badge bg-orange'>Office 365</span>
                        <span className='badge bg-info'>Exchange</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default UebaCykitTimelineItem