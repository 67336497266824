import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>
      <div className="loader-container m-5">
        <div className="left ">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="right  mt-3 mr-5">
          <Skeleton />
        </div>
      </div>


    </>
  );
};

export default Loader;