import React, { useCallback, useMemo, useState } from "react";
import styles from "../Activities.styles";
import {
  Form,
  Tab,
  Tabs,
  Table,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import { numberToLetter } from "../../../../Utils/Utils";

import Process from "./Process";
import Registry from "./Registry";
import Network from "./Network";
import File from "./File";
import Authentication from "./Authentication";

const EDRMerge = ({ selectedDateFilter, globalSearch, init, dateFilter, setDateFilter,activeTab, setActiveTab }) => {
  const [processTotalPageCount, setProcessTotalPageCount] = useState(0)
  const [registeryTotalPageCount, setRegisteryTotalPageCount] = useState(0)
  const [fileTotalPageCount, setFileTotalPageCount] = useState(0)
  const [networkTotalPageCount, setNetworkTotalPageCount] = useState(0)
  const [authenticationTotalPageCount, setAuthenticationTotalPageCount] = useState(0)
  const [isAnomaly, setIsAnomaly] = useState(false);
  const [anomaliFilter, setAnomaliFilter] = useState("");
  const [edrTab, setEdrTab] = useState(localStorage.getItem("hunter.cykit.edr.activeTab",false)?localStorage.getItem("hunter.cykit.edr.activeTab"):"process");
  return (
    <>
      <div
        className="col-12  position-relative rounded-3 w-100"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
          style={styles.filterWrap}
        >


        </div>

        <div className="detections col-12">
          <Tabs
            defaultActiveKey="process"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={edrTab}
            onSelect={(e) => setEdrTab(e)}
          >
            <Tab
              eventKey="process"
              // title="Process"
              title={
                <React.Fragment>
                  Process
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(processTotalPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Process globalSearch={globalSearch} setProcessTotalPageCount={setProcessTotalPageCount} processTotalPageCount={processTotalPageCount} isAnomaly={isAnomaly} setIsAnomaly={setIsAnomaly} anomaliFilter={anomaliFilter} setAnomaliFilter={setAnomaliFilter} dateFilter={dateFilter} setDateFilter={setDateFilter} init={init} />
            </Tab>

            <Tab
              eventKey="network"
              // title="Process"
              title={
                <React.Fragment>
                  Network
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(networkTotalPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Network globalSearch={globalSearch} setNetworkTotalPageCount={setNetworkTotalPageCount} networkTotalPageCount={networkTotalPageCount} isAnomaly={isAnomaly} setIsAnomaly={setIsAnomaly} anomaliFilter={anomaliFilter} setAnomaliFilter={setAnomaliFilter} dateFilter={dateFilter} setDateFilter={setDateFilter} init={init} />
            </Tab>

            <Tab
              eventKey="file"
              // title="Process"
              title={
                <React.Fragment>
                  File
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(fileTotalPageCount)}
                  </Badge>
                </React.Fragment>

              }
            >
              <File globalSearch={globalSearch} setFileTotalPageCount={setFileTotalPageCount} fileTotalPageCount={fileTotalPageCount} isAnomaly={isAnomaly} setIsAnomaly={setIsAnomaly} anomaliFilter={anomaliFilter} setAnomaliFilter={setAnomaliFilter} dateFilter={dateFilter} setDateFilter={setDateFilter} init={init} />
            </Tab>
            <Tab
              eventKey="registry"
              // title="Process"
              title={
                <React.Fragment>
                  Registry
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(registeryTotalPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Registry globalSearch={globalSearch} registeryTotalPageCount={registeryTotalPageCount} setRegisteryTotalPageCount={setRegisteryTotalPageCount} isAnomaly={isAnomaly} setIsAnomaly={setIsAnomaly} anomaliFilter={anomaliFilter} setAnomaliFilter={setAnomaliFilter} dateFilter={dateFilter} setDateFilter={setDateFilter} init={init} />
            </Tab>
            <Tab
              eventKey="authentication"
              // title="Process"
              title={
                <React.Fragment>
                  Authentication
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(authenticationTotalPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Authentication globalSearch={globalSearch} setAuthenticationTotalPageCount={setAuthenticationTotalPageCount} authenticationTotalPageCount={authenticationTotalPageCount} isAnomaly={isAnomaly} setIsAnomaly={setIsAnomaly} anomaliFilter={anomaliFilter} setAnomaliFilter={setAnomaliFilter} dateFilter={dateFilter} setDateFilter={setDateFilter} init={init} />
            </Tab>
          </Tabs>
        </div>

      </div>
    </>
  );
};

export default EDRMerge;