import React, { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import CustomModal from "../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Button, Form, Badge } from "react-bootstrap";

import {
  getExpectedOutputCustomInv,
  customInvestigation,
  getInvCatalogueDetails,
  getInvCatalogueDetailsQuery
} from "../../../Methods/RuleStudioMethods";
import { dateDMY, removeHtmlChars, splitStringByLength } from "../../../Utils/Utils";

import CustomInvInformation from "../CustomInvInformation";
import Loader from "../../Loader/Loader";
import Modules from "./Custom/Modules";
import CountBasedInputs from "./Custom/CountBasedInputs";
import { Link, useLocation, useParams } from "react-router-dom";




const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


export default function
  InvDetailModal({
    setDetailShow,
    detailShow,
    setInvId,
    invIdEdit,
    setSubmitLoader,
    submitLoader
  }) {
    // const { invIdEdit } = useParams()
    // console.log(invIdEdit);

  const dispatch = useDispatch();

  const [masterId, setMasterId] = useState("INV-");
  const [investigateName, setInvestigateName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [scheduleVal, setScheduleVal] = useState("");
  const [suppress, setSuppress] = useState("");
  const [description, setDescription] = useState("");
  const [detailsDescription, setDetailDescription] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [query, setQuery] = useState();
  const [notify, setNotify] = useState(['']);
  const [severity, setSeverity] = useState('');
  const [techId, setTechId] = useState("");
  const [techName, setTechName] = useState("");
  const [tacticName, setTacticName] = useState("");
  const [subTech, setSubTech] = useState("");
  const [inv, setInv] = useState("INV-")
  const [inv_created, setInvCreated] = useState(dateDMY(new Date()))
  const [inv_last_edited, setInvLastEdited] = useState(dateDMY(new Date()))

  const [correlationInput1, setCorrelationInput1] = useState("")
  const [correlationInput2, setCorrelationInput2] = useState("")

  const [isHunter, setIsHunter] = useState("hunter")


  const [inputValue, setInputValue] = useState('');
  const [isValidJson, setIsValidJson] = useState(true);


  // const [otherInformation, setOtherInformation] = useState([
  //   {
  //     information: "",
  //     value: "",
  //   },
  // ]);

  const [countBasedInputList, setCountBasedINputList] = useState([
    {
      value: "",

    },
  ]);




  const [selectedLogCategory, setLogCategory] = useState([]);
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);

  const [modules, setModules] = useState([]);

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;

  const detailsCustomRsIn = useSelector((state) => state.detailsCustomRsIn);
  const { catalogue, loading: loading } = detailsCustomRsIn;

  const detailsCustomQueryRsIn = useSelector((state) => state.detailsCustomQueryRsIn);
  const { catalogueQuery } = detailsCustomQueryRsIn;

  const createMasterRsInv = useSelector((state) => state.createMasterRsInv);
  const { success, error } = createMasterRsInv;
 


  useEffect(() => {
    console.log("invIdEdit:", invIdEdit); // Log invIdEdit here to see its value
  }, [invIdEdit]);
 
  const setDataInEditList = (severity, schedule) => {


    const logData = catalogue?.log?.map((data) => {
      return { "value": data, 'label': data }
    })

    let expOutData = catalogueQuery?.data?.extract_values_from_query_output?.map((data) => {
      return { "value": data, 'label': data }
    })




    let queryData
    if (catalogue?.count_based === true) {
      queryData = catalogueQuery?.data?.query_1
    } else {
      queryData = catalogueQuery?.data?.query ? catalogueQuery?.data?.query : catalogueQuery?.data?.result_out_2

    }


    delete queryData?.extract_values_from_query_output

    // console.log(JSON.stringify(removeHtmlChars(catalogueQuery)))

console.log(catalogue,"i");

    if (catalogue?.hunter === true) {

      setIsHunter("hunter")

    } else if (catalogue?.count_based === true) {

      setIsHunter("count_based")
      setCorrelationInput1(catalogueQuery?.data?.inputs?.[0]?.input_1)
      setCorrelationInput2(catalogueQuery?.data?.inputs?.[1]?.input_2)
    } else {
      setIsHunter("")
    }

    // Set schedule value
    let scheduleData = catalogue?.schedule;
    let scheduleLength = scheduleData?.length

    if (scheduleData) {
      const scheduleDataList = splitStringByLength(scheduleData, scheduleLength - 1)

      setSchedule(scheduleDataList[1])
      setScheduleVal(scheduleDataList[0])
    }




    setMasterId(invIdEdit === '' ? '' : catalogue?.inv_id);
    console.log("id:",catalogue?.inv_id);
 
    setInvestigateName(invIdEdit === '' ? '' : catalogue?.inv_name);

    setSchedule(invIdEdit === '' ? '' : catalogue?.schedule);
    setSuppress(invIdEdit === '' ? '' : catalogue?.suppress);
    setDescription(invIdEdit === '' ? '' : catalogue?.Description);
    setDetailDescription(invIdEdit === '' ? '' : catalogue?.detailed_description);
    // setConclusion(invIdEdit === '' ? '' : catalogue?.detailed_description);
    // setConclusion(invIdEdit === '' ? '' : catalogue?.conclution? catalogue?.conclution: catalogue?.mitigation );
    setConclusion(invIdEdit === '' ? '' : catalogue?.mitigation);
    // {value: "Firewall", label: "Firewall"}
    setLogCategory(invIdEdit === '' ? '' : logData)
    setExpectedOutput(invIdEdit === '' ? '' : expOutData)
    setQuery(invIdEdit === '' ? '' : JSON.stringify(queryData))
    setModules(invIdEdit === '' ? [] : catalogue?.modules)
    setNotify(invIdEdit === '' || catalogue?.notify === "undefined" || [] ? "NULL" : catalogue?.notify);
    setSeverity(invIdEdit === '' ? '' : catalogue?.severity)
    setTechId(invIdEdit === '' ? '' : catalogue?.technique_id);
    setTacticName(invIdEdit === '' ? '' : catalogue?.tactic_name);
    setTechName(invIdEdit === '' ? '' : catalogue?.technique_name);
    setSubTech(invIdEdit === '' ? '' : catalogue?.subTech);
    // setIsHunter(invIdEdit === '' ? '' : catalogue?.hunter || catalogue?.count_based);
    setInvCreated(invIdEdit === '' ? '' : catalogue?.inv_created);
    setInvLastEdited(invIdEdit === '' ? '' : dateDMY(new Date()));
    // setOtherInformation(invIdEdit === '' ? [{
    //   information: "",
    //   value: "",
    // }] : catalogue.otherInformation)
  };



  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());

    if (
      (invIdEdit || Object.keys(catalogue).length === 0) &&
      invIdEdit != catalogue?.inv_id && invIdEdit != ''
    ) {
      dispatch(getInvCatalogueDetails(invIdEdit));
      dispatch(getInvCatalogueDetailsQuery(invIdEdit))
    }

    if (invIdEdit != '') {
      setDataInEditList(severity, schedule);
    } else {
      setMasterId("INV-");
      setInvestigateName("");
      setSchedule("");
      setSuppress("");
      setDescription("");
      setDetailDescription("");
      setConclusion("");
      setQuery();
      setNotify([]);
      setSeverity('');
      setLogCategory('');
      setTechId("");
      setTechName("");
      setTacticName("");
      setSubTech("");
      // setOtherInformation([{
      //   information: "",
      //   value: "",
      // }])
    }

  }, [invIdEdit, catalogue, detailShow, catalogueQuery]);



 
  console.log(modules)

const removeHtmlTags = (text) => {
    return text?.replace(/<[^>]*>/g, '');
};


const handleClose = () => {
  
    setDetailShow(false);
    setInvId("");

  
};
function containsOnlyTags(htmlString) {
  // Use regex to match only HTML tags
  const tagRegex = /<[^>]*>/;
  // Check if the string only contains HTML tags
  return !htmlString.replace(/\s/g, '').replace(tagRegex, '').length;
}


const renderModal = () => (
    <>
    {console.log("inside the invdetail modalllllll")}
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
          <span className="was-validated" style={{ overflowY: 'auto' }}>  
                                
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Investigate ID</span>
                    {console.log(masterId)}
                    <span className="modal-text ">{masterId}</span>
                    {/* <span className="modal-text">{console.log("GET DETECCTT TIME :", detectionData?._source)}</span> */}
                  </div>
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Investigate name</span>
                    <span className="modal-text ">{investigateName}</span>
                    {/* <span className="modal-text">{console.log("GET DETECCTT TIME :", detectionData?._source)}</span> */}
                  </div>  

                  {selectedLogCategory &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Log category</span>
                    <span className="modal-text ">
                    {Array.isArray(selectedLogCategory) ? selectedLogCategory?.map(category => category?.label).join(', ') : selectedLogCategory?.label}</span>
                  </div>  
                  )} 

                  {scheduleVal &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Schedule</span>
                    <span className="modal-text ">
                    {schedule}</span>
                  </div>
                  )}    

                  {suppress &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Suppress</span>
                    <span className="modal-text ">
                    {suppress}</span>
                  </div>
                  )}  

                  {severity &&(
                   <div className="modal-row-item">
                    <span className="modal-label col-2">Severity</span>
                    <span className="modal-text ">
                    {severity == "Critical" && (
                        <Badge bg="danger" className="p-2">Critical</Badge>
                      )}
                      {severity == "High" && (
                        <Badge bg="danger" className="p-2">High</Badge>
                      )}
                      {severity == "Medium" && (
                        <Badge bg="warning" className="p-2">Medium</Badge>
                      )}
                      {severity == "Low" && (
                        <Badge bg="success" className="p-2">Low</Badge>
                      )}
                      </span>
                  </div>   
                  )}
                  
                  
                  {(isHunter === 'hunter' || isHunter === 'count_based') ?(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Hunter/Count Based</span>
                    <span className="modal-text ">
                      {isHunter === 'hunter' ? 'Hunter' : isHunter === 'count_based' ? 'Count Based' : ''}
                    </span>
                  </div> 
                  ): ""}

                  {techId &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Technique_id</span>
                    <span className="modal-text ">
                      {techId}
                    </span>
                  </div>   
                  )}

                  {tacticName &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Tactic_name</span>
                    <span className="modal-text ">
                      {tacticName}
                    </span>
                  </div>
                  )}  

                  {techName &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Technique_name</span>
                    <span className="modal-text ">
                      {techName}
                    </span>
                  </div>  
                  )}

                  {description &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Description</span>
                    <span className="modal-text ">
                      {removeHtmlTags(description)}
                    </span>
                  </div>  
                  )}  
                  {console.log(detailsDescription)}
                  {detailsDescription && !containsOnlyTags(detailsDescription) && (
                    <div className="modal-row-item">
                      <span className="modal-label col-2">Detailed description</span>
                      <span className="modal-text">
                        {removeHtmlTags(detailsDescription)}
                      </span>
                    </div>
                  )}
                  
                  {conclusion &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Mitigation</span>
                    <span className="modal-text ">
                     {removeHtmlTags(conclusion)} 
                    </span>
                  </div> 
                  )}  
                  
                  {query &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Query</span>
                    <span className="modal-text ">
                      {query}
                    </span>
                  </div>  
                  )} 

                   {selectedExpectedOutput &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Expected output</span>
                    <span className="modal-text ">                    
                      {Array.isArray(selectedExpectedOutput) ? selectedExpectedOutput?.map(output => output?.label).join(', ') : selectedExpectedOutput?.label}
                    </span>                 
                  </div>  
                   )}

                  {module && (
                      <div className="modal-row-item">
                          <span className="modal-label col-2">Modules</span>
                          <span className="modal-text">
                              {Array.isArray(output?.module) ? (
                                  output?.module?.map(output => (
                                      <span>
                                         <strong> {output?.moduleType}</strong> - {output?.modules.map((value) => value.value).join(', ')}
                                          <br />
                                      </span>
                                  ))
                              ) : (
                                  output?.module?.moduleType
                              )}
                          </span>
                      </div>
                  )} 
          </span>
      

      )
      }
    </ >
  );

  return (
    <>
      {" "}
      {detailShow === true && (
        <CustomModal
          title={ 'Detail investigate' }
          ShowOrHide={detailShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          noBodyPadding={true}
          overflowHidden={false}
          modalSize={'lg'}
          modalPosition={"right"}
          outsideClickDisabled={true}

        />
      )}
    </>
  );

}