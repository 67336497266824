import React from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { CornersOut, FrameCorners, Minus } from "phosphor-react";

const handleClose = () => {
  alert("Do you want to save changes?")
};



const CustomModal = ({
  title,
  ShowOrHide,
  bodyData,
  handleClose,
  footer,
  modalSize,
  downloadBtn,
  noBodyPadding,
  overflowHidden,
  autoHeight,
  hasStickyTab,
  modalPosition,
  outsideClickDisabled,
  minimizeBtn
}) => {

  const [isMinimize, setIsMinimize] = useState(false)

  const handleMinimize = () => {
    if (minimizeBtn === false || minimizeBtn === undefined) {
      setIsMinimize(false)
    }
    setIsMinimize(!isMinimize)
  }

  return (
    <Modal
      show={ShowOrHide}
      onHide={handleClose}
      className={[
        outsideClickDisabled ? "modal-no-backdrop" : "",
        isMinimize ? 'minimized' : ""
      ]}
      dialogClassName={[
        autoHeight ? "modal-auto-height" : "",
        modalPosition === "right" ? isMinimize ? "modal-right" : "modal-right modal-full-height" : "",

      ]}
      // size= "lg"
      size={modalSize ? modalSize : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      data-backdrop="static"
      // backdrop={false}
      backdrop={outsideClickDisabled ? false : true}
    >

      {/* closeButton type onClick={() => handleCloseModal()} */}
      <Modal.Header >

        <Modal.Title id="contained-modal-title-vcenter" className="d-flex">
          {title}
          <div>{downloadBtn && <Button className="primary">Export as PDF</Button>}</div>
        </Modal.Title>
        <div className="ml-auto d-flex flex-row gap-2 align-items-center px-2">
          {/* <button type="button" className="btn" onClick={() => handleMinimize()}>
            {isMinimize ? <CornersOut size={18} /> : <Minus size={18} />}
          </button> */}

          <button type="button" className="btn-close ml-0" onClick={() => handleClose()} ></button>
        </div>
        {/* {minimizeBtn &&
          <span
            onClick={() => handleMinimize()}
          >
            {isMinimize ? <FrameCorners size={28} /> : <Minus size={28} />}
          </span>
        } */}

      </Modal.Header>
      <Modal.Body className={[
        noBodyPadding ? "p-0" : "p-2",
        overflowHidden ? "" : "overflow-auto",
        hasStickyTab ? "sticky-tab" : "",
      ]}>{bodyData()}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Button>Close</Button>
        </Modal.Footer>
      )}
    </Modal>

  );
};

export default CustomModal;