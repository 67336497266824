import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    Button,
    Form
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import CustomModal from "../../../components/CustomModal/CustomModal";

import { fget, fpost } from "../../../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import JsonEditor from "../../../components/JsonEditor/JsonEditor";
const User = () => {
    const navigate = useNavigate();
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    useEffect(() => {
        fget(`${apiBaseUrl}/core/userpermissions`).then(({ data }) => {
            data && data?.user_type === "super_admin" ? console.log() : navigate("/", { replace: true });

        }).catch((err) => {
            console.log("Error", err)
        })
    }, [])
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [show, setShow] = useState(false)
    const [temp, setTemp] = useState('')
    const [isOnPremiseUser, setIsOnPremiseUser] = useState(false)
    const [isSSOUser, setIsSSOUser] = useState(false)
    const [expclose, setExpClose] = useState(false);
    const [showOnPrem, setOnPrem] = useState(false);

    const [enableAll, setEnableAll] = useState(false);

    const [modules, setModules] = useState(
        {
            "hunter": {
                "is_active": true,
                "read": false,
                "write": false,
                "delete": false
            },
            "compliance": {
                "is_active": false,

                "read": false,
                "write": false,
                "delete": false,
                "child": [
                    {
                        "name": "alerts",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "controlview",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "dashboard",
                        "read": false,
                        "write": false
                    }
                ],
                "delete": false
            },
            "investigate": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false,
                "child": [
                    {
                        "name": "cykit-firewall",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "cykit-email",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "cykit-waf",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "cykit-ad",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "cykit-atp_alerts",
                        "read": false,
                        "write": false
                    },
                    {
                        "name": "cykit-office",
                        "read": false,
                        "write": false
                    }
                ]
            },
            "analytics": {
                "is_active": true,

                "read": false,
                "write": false,
                "child": [
                    {
                        "name": "security",
                        "read": false,
                        "write": false,
                        "delete": false
                    },
                    {
                        "name": "ciso",
                        "read": false,
                        "write": false,
                        "delete": false
                    },
                    {
                        "name": "mngmt",
                        "read": false,
                        "write": false,
                        "delete": false
                    }
                ]
            },
            "ti": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false
            },
            "ueba": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false

            },
            "rulesstudio": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false,
                "child": [
                    {
                        "name": "investigate",
                        "read": false,
                        "write": false,
                        "delete": false
                    },
                    {
                        "name": "hunter",
                        "read": false,
                        "write": false,
                        "delete": false
                    },
                    {
                        "name": "compliance",
                        "read": false,
                        "write": false,
                        "delete": false
                    }
                ]
            },
            "casemgmt": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false

            },
            "management_dash": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false
            },
            "threat_landscape": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false
            },
            "rulesstudioadmin": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false
            },
            "core": {
                "is_active": true,

                "read": false,
                "write": false,
                "delete": false

            }
        }
    );
    const Modules = {
        "hunter": "Hunter",
        "compliance": "Compliance",
        "investigate": "Investigate",
        "analytics": "Analytics",
        "ti": "Threat Intellegence",
        "ueba": "UEBA",
        "rulesstudio": "Rules Studio",
        "rulesstudioadmin": "Rules Studio Admin",
        "core": "Core",
        "controlview": "Control View",
        "dashboard": "Dashboard",
        "casemgmt": "Case Management",
        "management_dash": "Management Dashboard",
        "threat_landscape": "Threat Landscape"
    }
    // console.log("tenantId", tenantId[0].tenantId)
    const hostname = window.location.hostname

    useMemo(() => {
        if (
            hostname.endsWith('.active-bytes.com') ||
            hostname === 'active-bytes.com' ||
            hostname === 'localhost'
        ) {
            setOnPrem(false)
        }
        else {
            setOnPrem(true)
        }
    }, [])

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Unselect the "Enable All" checkbox when any individual checkbox is clicked
        document.getElementById('enableAllCheckbox').checked = false;

        if (type === 'checkbox') {
            const [moduleName, permissionType] = name.split('-');
            setModules((prevModules) => ({
                ...prevModules,
                [moduleName]: {
                    ...prevModules[moduleName],
                    [permissionType]: checked,
                },
            }));
        } else {
            // Your existing code for handling other input changes
            if (name === 'userName') {
                setUserName(value);
            } else if (name === 'userType') {
                setUserType(value);
            } else if (name === 'email') {
                setEmail(value);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const reqData = {
            user_name: userName,
            is_sso_user: isSSOUser,
            is_onPremise: isOnPremiseUser,
            user_type: userType,
            email: email,
            modules,
        };
        if (isOnPremiseUser) {
            try {
                await fget(`${apiBaseUrl}/core/user?user=${userName}`).then(({ data }) => {
                    if (data?.status === "done") {
                        toast.error("User with Username already Exists", {
                            position: "bottom-right",
                        });
                        throw "exit"
                    }
                    else {
                        fpost(`${apiBaseUrl}/core/user`, reqData).then(({ data }) => {
                            if (data?.status === "done") {
                                toast.success("User Created Successfully!", {
                                    position: "bottom-right",
                                });
                                // setShow(true)
                                setTemp(data?.temp_pass)
                            }
                            else if (data?.status === "error exist") {
                                toast.error("User already Exists", {
                                    position: "bottom-right",
                                });
                            }
                            else {
                                // toast.error("Oops, some error occured", {
                                //     position: "bottom-right",
                                // });
                                console.log("error", data)
                            }
                        }).catch((err) => {
                            console.log("Error", err)
                        })
                    }

                }).catch((err) => {
                    console.log("Error", err)
                })


            }
            catch (error) {
                console.log("Error", error)

            }
        }
        else {
            await fget(`${apiBaseUrl}/core/user?user=${userName}`).then(({ data }) => {
                if (data?.status === "done") {
                    toast.error("User with Username already Exists", {
                        position: "bottom-right",
                    });
                    throw "exit"
                }
                else {
                    fpost(`${apiBaseUrl}/core/user`, reqData).then(({ data }) => {
                        if (data?.status === "done") {
                            toast.success("User Created Successfully!", {
                                position: "bottom-right",
                            });
                            !showOnPrem && setShow(true)
                            setTemp(data?.temp_pass)
                        }
                        else if (data?.status === "error exist") {
                            toast.error("User already Exists", {
                                position: "bottom-right",
                            });
                        }
                        else {
                            toast.error("Oops, some error occured", {
                                position: "bottom-right",
                            });
                        }
                    }).catch((err) => {
                        console.log("Error", err)
                    })
                }
            }).catch((err) => {
                console.log("Error", err)
            })

        }
        // You can add code here to handle the JSON file download.
    };

    const getLabel = (module) => {

        return Modules?.[module]
    }
    function renderModal() {
        return isSSOUser ? <div>
            <p className="d-flex justify-content-center align-items-center mt-2">Add This User In Azure AD Enterprise Application To Gain Access</p>
        </div>
            :
            <div>
                <p className="font-weight-bold">User Created with Password </p>
                <span style={{ "fontWeight": "700", "fontSize": "small" }} className="d-flex justify-content-center align-items-center input-group-text">  {temp}</span>
            </div>
    }
    function handleCloseModal() {
        setShow(false);
        navigate("/settings/users", { replace: true });
    }
    const handleEnableAllChange = (e) => {
        const isChecked = e.target.checked;

        // Update the state for all modules with the same value
        const updatedModules = Object.fromEntries(
            Object.entries(modules).map(([moduleName, moduleData]) => {
                return [moduleName, { ...moduleData, read: isChecked, write: isChecked, delete: isChecked }];
            })
        );

        setModules(updatedModules);
    };
    return (
        <div
            className="col-12 p-4 position-relative bg-white rounded-3"
        >
            <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-0">Create User</h5>
            </div>
            <div style={{ "overflow": "auto", maxHeight: "calc(100vh - 13rem)" }}>
                <form className="" onSubmit={handleSubmit}>
                    {/* User Name */}
                    <div className="form-group border rounded-3 p-4 mb-3">
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex flex-row gap-2">
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="userName">
                                        User Name
                                    </label>
                                    <input
                                        type="text"
                                        id="userName"
                                        name="userName"
                                        className="form-control mt-2"
                                        value={userName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control mt-2"
                                        value={email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* User Type */}
                            <div className="d-flex gap-3 mt-2">
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="userType">
                                        User Type
                                    </label>
                                    <select
                                        id="userType"
                                        name="userType"
                                        className="form-control mt-2"
                                        value={userType}
                                        onChange={handleInputChange}
                                    >
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        <option value="super_admin">Super Admin</option>
                                    </select>
                                </div>
                                <div className="form-group col-3 d-flex mt-4 gap-3 ms-2">
                                    {showOnPrem && <Form.Check
                                        className="mt-3"
                                        type="switch"
                                        id="isOnPremiseUser"
                                        label="On Premise"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIsOnPremiseUser(true)
                                            }
                                            else {
                                                setIsOnPremiseUser(false)
                                            }
                                        }}
                                    />}
                                    <Form.Check
                                        className="mt-3"
                                        type="switch"
                                        id="isSSOUser"
                                        label="SSO Access"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIsSSOUser(true)
                                            }
                                            else {
                                                setIsSSOUser(false)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modules */}
                    <div className="form-group border rounded-3 p-4 mb-3">
                        <h5 className="font-weight-bold d-inline-block mr-2" style={{ marginRight: '15px' }}>Modules</h5>
                        <input
                            type="checkbox"
                            id="enableAllCheckbox"
                            className="form-check-input d-inline-block"
                            onChange={handleEnableAllChange}
                        />
                        <label className="form-check-label ml-2 d-inline-block" htmlFor="enableAllCheckbox">Enable All</label>
                        <hr />
                        <div id="modules-wrapper ms-4 mt-2">
                            {Object.entries(modules).map(([moduleName, moduleData]) => (
                                 moduleData?.["is_active"] === true && <div className="module mb-4 ms-2" key={moduleName}>
                                    <h5 className="font-weight-bold mb-2">{getLabel(moduleName)}</h5>
                                    <div className="form-check form-check-inline ml-2">
                                        <input
                                            type="checkbox"
                                            name={`${moduleName}-read`}
                                            className="form-check-input"
                                            checked={moduleData.read}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label ml-2">Read</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="checkbox"
                                            name={`${moduleName}-write`}
                                            className="form-check-input"
                                            checked={moduleData.write}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label ml-2">Write</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="checkbox"
                                            name={`${moduleName}-delete`}
                                            className="form-check-input"
                                            checked={moduleData.delete}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label ml-2">Delete</label>
                                    </div>
                                    {/* {moduleData.child && (
                                    <div className="ms-4 mt-2">
                                    {moduleData.child.map((permissionType) => (
                                        <div className="ms-4" key={`${moduleName}-${permissionType.name}`}>
                                            <span className="font-weight-bold mb-2 mt-2" style={{fontWeight:"400"}}>{getLabel(permissionType.name)}</span>
                                            <div>
                                                {moduleData.read!=undefined&&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-read`}
                                                    className="form-check-input"
                                                    checked={moduleData.read}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Read</label>
                                                </div>}
                                                {moduleData.write!=undefined&&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-write`}
                                                    className="form-check-input"
                                                    checked={moduleData.write}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Write</label>
                                                </div>}
                                                {(moduleData.delete!=undefined &&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-delete`}
                                                    className="form-check-input"
                                                    checked={moduleData.delete}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Delete</label>
                                                </div>)}
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                )} */}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="text-right mr-4">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => navigate("/settings/users", { replace: true })}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={() => setExpClose(true)}
                        >
                            Advanced Permissions
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Create User
                        </button>

                    </div>
                </form>
            </div>
            {show && (
                <CustomModal
                    title={"User Created"}
                    ShowOrHide={show}
                    modalSize={"sm"}
                    bodyData={renderModal}
                    handleClose={handleCloseModal}
                    autoHeight={true}
                />
            )}
            <JsonEditor data={Object.fromEntries(
  Object.entries(modules).filter(([key, value]) => value?.is_active)
)} close={expclose} setData={setModules} setClick={setExpClose} />
            <ToastContainer />
        </div>
    );
};

export default User;
