import globalStyles from "../../constants/globalStyles";

const styles = {
  sidebarWrap: {
    zIndex: 20,
    position: "fixed",
    // width: '30%',
    // left: 'auto',
    right: "1rem",
    top: "7rem",
    height: "calc(100% - 8rem)"
  },
  sidebarContentWrap: {
    height: "calc(100% - 2.5rem)",
    overflow: "auto"
  },
};

export default styles;