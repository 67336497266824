import {
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from "../../../Utils/Utils.js";
// import {
//   getDateFromTimestamp,
//   getTimeFromTimestamp,
// } from "../../Utils/Utils.js";

export const eventfields = {
  event: [
    {
      name: "Event Date & Time",
      value: "item._source.attribute_timestamp",
      // value: getTimeFromTimestamp(
        // "parseInt(item._source.attribute_timestamp) * 1000"
      
    },
    {
      name: "Event Name",
      value: "item._source.event_name",
    },
    {
      name: "Feed Name",
      value: "item._source.feed_name",
    },
    {
      name: "Severity",
      value: "item._source.severity",
    }
  ],
};