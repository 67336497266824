const styles = {
  section1: {
    // width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "auto",
  },
  section2: {
    height: "calc(100vh - 13.3rem)"
  },
  contentWrap: {
    height: "calc(100vh - 14rem)",
    // height: "calc(100vh - 17rem)",
    overflow: "auto",
  },
  filterWrap: {
    position: "absolute",
    right: "1.5rem",
    top: "14px",
    zIndex: 3
  },
  tableWrap: {
    height: "calc(100vh - 14rem)",
    overflow: "auto",
  }
};

export default styles;
