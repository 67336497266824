import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCybotDashboardDetails,
  detectedObservables,
  recentFileCreation,
  recentFileDeletion,
  processRunByDetectionUser,
  processRunByDetectionHost,
  UncommonProcessesRunDetectedUser,
  recentAuthHost,
  hashReputationVirusTotal,
  hashReputationAlienVault,
  hashReputationThreatScore,
  ipReputationAlienVault,
  ipReputationVirusTotal,
  ipReputationThreatScore,
  urlReputationVirusTotal,
  urlReputationAlienVault,
  urlReputationThreatScore,
  hostsLoggedByUser,
  trafficHostDetectedIpSummary,
  trafficHostDetectedIpDetailed,
  fireWallTrafficFromIpSummary,
  fireWallTrafficFromIpDetailed,
  fireWallTrafficToIpSummary,
  fireWallTrafficToIpDetailed,
  wafTrafficFromIpSummary,
  wafTrafficFromIpDetailed,
  wafThreatDetectedIp,
  wafUserAttemptIp,
} from "../../Methods/InvestigationMethods";
import { rawHTML, removeHtmlChars } from "../../Utils/Utils";
import DataTable from "../Investigation/DataTable";
import Tile from "../Investigation/Tile";
import VirusTotalSummary from "../Investigation/VirusTotalSummary";
import GaugeChart from "react-gauge-chart";
import { Tab, Tabs } from "react-bootstrap";
import styles from "./style.styles";
import TableLoader from '../Loader/TableLoader';
import NoDataFound from "../NoDataFount/NoDataFound";

const InvestigationDashboard = ({ investigationData }) => {
  // console.log('investigationData ====', investigationData)
  const {
    _source: { user_name, playbook_id, hunt_detection_id, process_name },
  } = investigationData;

  const [investigationDescription, setInvestigationDescription] = useState([]);
  const [detectionObservable, setDetectionObservable] = useState([]);

  const [recentFileData, setRecentFileData] = useState([]);
  const [deletionFileData, setDeletionFileData] = useState([]);
  const [recentAuthHostDetails, setRecentAuthHostDetails] = useState([]);

  const [hostLoggedByUserData, setHostLoggedByUserData] = useState([]);


  const [trafficHostDetectionFromSummary, setTrafficHostDetectionFromSummary] =
    useState([]);

  const [processRunDetectionUserData, setProcessRunDetectionUserData] =
    useState([]);
  const [processRunDetectionHostData, setProcessRunDetectionHostData] =
    useState([]);

  const [uncommonProcessData, setUncommonProcessData] = useState([]);

  // traffic
  const [trafficHostDetailedData, setTrafficHostDetailedData] = useState([]);
  const [firewallTrafficFromSummaryData, setFirewallTrafficFromSummaryData] =
    useState([]);

  const [firewallTrafficFromDetailedData, setFirewallTrafficFromDetailedData] =
    useState([]);

  const [firewallTrafficToSummaryData, setFirewallTrafficToSummaryData] =
    useState([]);

  const [firewallTrafficToDetailedData, setFirewallTrafficToDetailedData] =
    useState([]);

  const [wafTrafficFromSummaryData, setWafTrafficFromSummaryData] = useState(
    []
  );
  const [wafTrafficFromDetailedData, setWafTrafficFromDetailedData] = useState(
    []
  );
  const [wafThreatDetectedIpData, setWafThreatDetectedIpData] = useState([]);
  const [wafUserAttemptedIpData, setWafUserAttemptedIpData] = useState([]);

  // loader table 

  const [recentAuthLoad, SetRecentAuthLoad] = useState(true)
  const [recentCreationLoad, setRecentCreationLoad] = useState(true)
  const [recentDeletionLoad, setRecentDeletionLoad] = useState(true)
  const [proDetectUserLoad, setProDetectUserLoad] = useState(true)
  const [proDetectHostLoad, setProDetectHostLoad] = useState(true)
  const [unProDetectUserLoad, setUnProDetectUserLoad] = useState(true)
  const [hostLoggedUserLoad, setHostLoggedUserLoad] = useState(true)
  const [trafficFromHostLoad, setTrafficFromHostLoad] = useState(true)
  const [firewallFromIpLoad, setFirewallFromIpLoad] = useState(true)
  const [firewallToIpLoad, setFirewallToIpLoad] = useState(true)
  const [wafFromIpLoad, setWafFromIpLoad] = useState(true)
  const [wafThreatIpLoad, setWafThreatIpLoad] = useState(true)
  const [wafUserAttemptedIpLoad, setWafUserAttemptedIpLoad] = useState(true)

  // widgets States
  const [hashVirusTotalData, setHashVirusTotalData] = useState([]);
  const [hashAvData, setHashAvData] = useState([]);
  const [hashTsData, setHashTsData] = useState([]);

  // Ip

  const [ipVirusData, setIpVirusData] = useState([]);
  const [ipAvData, setIpAvData] = useState([]);
  const [ipTsData, setIpTsData] = useState([]);

  // URL

  const [urlVirusData, setUrlVirusData] = useState([]);
  const [urlAvData, setUrlAvData] = useState([]);
  const [urlTsData, setUrlTsData] = useState([]);

  useEffect(() => {
    getCybotDashboardDetails(playbook_id)
      .then(({ data }) => {
        data && setInvestigationDescription(data);
      })
      .catch((err) => {
        console.log("Error", err);
      });

    detectedObservables(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          hits && setDetectionObservable(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    recentFileCreation(user_name, hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let recentTableData = [];
          hits.map((item) => {
            recentTableData.push({
              user_name: item._source.user_name,
              host_name: item._source.host_name,
              file_name: item._source.file_name,
              file_path: item._source.file_path,
            });
          });
          setRecentFileData(hits);
          setRecentCreationLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    recentFileDeletion(user_name, hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let deletionTableData = [];
          hits.map((item) => {
            deletionTableData.push({
              user_name: item._source.user_name,
              host_name: item._source.host_name,
              file_name: item._source.file_name,
              file_path: item._source.file_path,
            });
          });
          setDeletionFileData(hits);
          setRecentDeletionLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    processRunByDetectionUser(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let processRunByUser = [];
          hits.map((item) => {
            processRunByUser.push({
              detection_timestamp: item._source.detection_timestamp,
              process_name: item._source.process_name,
              process_hash: item._source.process_hash,
              file_path: item._source?.file_path,
            });
          });
          setProcessRunDetectionUserData(hits);
          setProDetectUserLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    processRunByDetectionHost(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let processRunByHost = [];
          hits.map((item) => {
            processRunByHost.push({
              detection_timestamp: item._source.detection_timestamp,
              process_name: item._source.process_name,
              process_hash: item._source.process_hash,
              file_path: item._source?.file_path,
            });
          });
          setProcessRunDetectionHostData(hits);
          setProDetectHostLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    UncommonProcessesRunDetectedUser(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let uncommonProcessData = [];
          hits.map((item) => {
            uncommonProcessData.push({
              uncommon_process_vt_score: item._source.uncommon_process_vt_score,
              uncommon_process_name: item._source.uncommon_process_name,
              uncommon_process_hash: item._source.uncommon_process_hash,
              detection_timestamp: item._source?.detection_timestamp,
            });
          });
          setUncommonProcessData(hits);
          setUnProDetectUserLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    recentAuthHost(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let recentAuthDetails = [];
          hits.map((item) => {
            recentAuthDetails.push({
              timeStamp: item._source.timestamp,
              user_name: item._source.user_name,
            });
          });
          setRecentAuthHostDetails(hits);
          SetRecentAuthLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    hostsLoggedByUser(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let hostLoggedUserData = [];
          hits.map((item) => {
            hostLoggedUserData.push({
              count: item._source.count,
              host_name: item._source.host_name,
            });
          });
          setHostLoggedByUserData(hits);
          setHostLoggedUserLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    //   traffic
    // Traffic from the IP

    trafficHostDetectedIpSummary(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let trafficHostDetectionSummary = [];
          hits.map((item) => {
            trafficHostDetectionSummary.push({
              host_name: item._source.host_name,
              count: item._source.count,
            });
          });
          setTrafficHostDetectionFromSummary(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    trafficHostDetectedIpDetailed(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let trafficHostDetectedDetailedData = [];
          hits.map((item) => {
            trafficHostDetectedDetailedData.push({
              host_name: item._source.host_name,
              user_name: item._source.user_name,
              process_name: item._source.process_name,
              timestamp: item._source.timestamp,
              dest_port: item._source.dest_port,
            });
          });
          setTrafficHostDetailedData(hits);
          setTrafficFromHostLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    //   Firewall
    // traffic from ip
    fireWallTrafficFromIpSummary(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let firewallTrafficSummaryData = [];
          hits.map((item) => {
            firewallTrafficSummaryData.push({
              dest_ip: item._source.dest_ip,
              dest_port: item._source.dest_port,
              dest_country: item._source.dest_country,
              count: item._source.count,
            });
          });
          setFirewallTrafficFromSummaryData(hits);

        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    fireWallTrafficFromIpDetailed(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let firewallTrafficDetailedData = [];
          hits.map((item) => {
            firewallTrafficDetailedData.push({
              dest_ip: item._source.dest_ip,
              dest_port: item._source.dest_port,
              dest_country: item._source.dest_country,
              timestamp: item._source.timestamp,
            });
          });
          setFirewallTrafficFromDetailedData(hits);
          setFirewallFromIpLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // traffic to the ip
    fireWallTrafficToIpSummary(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let firewallTrafficToSummary = [];
          hits.map((item) => {
            firewallTrafficToSummary.push({
              source_ip: item._source.source_ip,
              source_port: item._source.source_port,
              source_country: item._source.source_country,
              count: item._source.count,
            });
          });
          setFirewallTrafficToSummaryData(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    fireWallTrafficToIpDetailed(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let firewallTrafficToDetailed = [];
          hits.map((item) => {
            firewallTrafficToDetailed.push({
              source_ip: item._source.source_ip,
              source_port: item._source.source_port,
              source_country: item._source.source_country,
              timestamp: item._source.timestamp,
            });
          });
          setFirewallTrafficToDetailedData(hits);
          setFirewallToIpLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    //   WAF
    // traffic from ip

    wafTrafficFromIpSummary(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let wafTrafficFromSummary = [];
          hits.map((item) => {
            wafTrafficFromSummary.push({
              url: item._source.url,
              count: item._source.count,
            });
          });
          setWafTrafficFromSummaryData(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    wafTrafficFromIpDetailed(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let wafTrafficToSummary = [];
          hits.map((item) => {
            wafTrafficToSummary.push({
              dest_ip: item._source.dest_ip,
              dest_port: item._source.dest_port,
              url: item._source.url,
              timestamp: item._source.timestamp,
            });
          });
          setWafTrafficFromDetailedData(hits);
          setWafFromIpLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // waf threat detected on ip

    wafThreatDetectedIp(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let wafThreatDetected = [];
          hits.map((item) => {
            wafThreatDetected.push({
              dest_ip: item._source.dest_ip,
              dest_port: item._source.dest_port,
              url: item._source?.url,
              timestamp: item._source.timestamp,
            });
          });
          setWafThreatDetectedIpData(hits);
          setWafThreatIpLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    wafUserAttemptIp(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let wafUserAttempted = [];
          hits.map((item) => {
            wafUserAttempted.push({
              dest_ip: item._source.dest_ip,
              dest_port: item._source.dest_port,
              url: item._source?.url,
              timestamp: item._source.timestamp,
            });
          });
          setWafUserAttemptedIpData(hits);
          setWafUserAttemptedIpLoad(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // widgets
    // Hash Reputation

    hashReputationVirusTotal(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let hashVirus = [];
          hits.map((item) => {
            hashVirus.push({
              name: item._source.hash,
              malicious: item._source.vt_malicious,
              suspicious: item._source.vt_suspicious,
              harmless: item._source.vt_harmless,
            });
          });
          setHashVirusTotalData(hashVirus);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    hashReputationAlienVault(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let hashAV = [];
          hits.map((item) => {
            hashAV.push({ av_score: item._source.hash_av_score });
          });
          setHashAvData(hashAV);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    hashReputationThreatScore(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let hashTs = [];
          hits.map((item) => {
            hashTs.push({ threat_score: item._source.hash_threat_score });
          });
          setHashTsData(hashTs);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // IP Reputation

    ipReputationVirusTotal(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let ipVirus = [];
          hits.map((item) => {
            ipVirus.push({
              name: item._source.ip,
              malicious: item._source.vt_malicious,
              suspicious: item._source.vt_suspicious,
              harmless: item._source.vt_harmless,
            });
          });
          setIpVirusData(ipVirus);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    ipReputationAlienVault(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let ipAv = [];
          hits.map((item) => {
            ipAv.push({ av_score: item._source.ip_av_score });
          });
          setIpAvData(ipAv);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    ipReputationThreatScore(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let ipTs = [];
          hits?.map((item) => {
            ipTs.push({ threat_score: item._source?.ip_threat_score });
          });
          setIpTsData(ipTs);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // URL Reputation

    urlReputationVirusTotal(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let urlVirus = [];
          hits?.map((item) => {
            urlVirus.push({
              name: item._source.url,
              malicious: item._source.vt_malicious,
              suspicious: item._source.vt_suspicious,
              harmless: item._source.vt_harmless,
            });
            setUrlVirusData(urlVirus);
          });
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    urlReputationAlienVault(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let urlAv = [];
          hits.map((item) => {
            urlAv.push({ av_score: item._source.url_av_score });
          });
          setUrlAvData(urlAv);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    urlReputationThreatScore(hunt_detection_id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let urlTs = [];
          hits?.map((item) => {
            urlTs.push({ threat_score: item._source.url_threat_score });
          });
          setUrlTsData(urlTs);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  return (
    <div className="col-12 gap-3 d-flex flex-row bg-grey p-3">
      <div
        className="col-3 d-flex flex-column observable-datas gap-3 rounded-3"
        style={styles.section1}
      >
        <div className="col-12 rounded-3 p-4 border bg-white">
          <h3>About the Investigation</h3>
          <p className="col-12 text-break">{removeHtmlChars(investigationDescription?.Description)}</p>
          <p className="col-12 text-break">{rawHTML(investigationDescription?.detailed_description)}</p>
        </div>
        <div className="col-12 rounded-3 p-4 border bg-white">
          <h3>Detection Observables</h3>
          {detectionObservable.map(({ _source }) => (
            <div className="col-12 text-break">
              <div className="modal-row-item">
                <span className="modal-label col-3">User name</span>
                <span className="modal-text">{_source?.user_name}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Host name</span>
                <span className="modal-text">{_source?.host_name}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Detection timestamp</span>
                <span className="modal-text">
                  {_source?.detection_timestamp}
                </span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Source IP</span>
                <span className="modal-text">{_source?.source_ip}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Destination IP</span>
                <span className="modal-text">{_source?.dest_ip}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Process name</span>
                <span className="modal-text">{_source?.process_name}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Process PID</span>
                <span className="modal-text">{_source?.process_pid}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Process commandline</span>
                <span className="modal-text">
                  {_source?.process_commandline}
                </span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-3">Process hash</span>
                <span className="modal-text">{_source?.process_hash}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="d-flex gap-3 flex-column flex-fill rounded-3"
        style={styles.section2}
      >
        <div className="d-flex gap-3 flex-row flex-fill">
          <div className="flex-fill rounded-3 p-4 border recent-file-container bg-white">
            <div>
              <h3>Investigating the Hash</h3>
              <p>
                Detailed automated investigation by CYBOT about the suspicious
                Hash observed
              </p>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-3">
                <VirusTotalSummary data={hashVirusTotalData} />
              </div>
              <div className="d-flex flex-row gap-3">
                <div
                  className="flex-fill d-flex align-items-center alert border mb-0"
                  style={{ height: "240px" }}
                >
                  <GaugeChart
                    id="gauge-chart2"
                    nrOfLevels={20}
                    arcPadding={0.02}
                    cornerRadius={2}
                    percent={hashTsData[0]?.threat_score / 100}
                    formatTextValue={(value) => value}
                    textColor={"#000000"}
                  />
                </div>
              </div>
              <div className="flex-fill alert alert-info mb-0">
                <span>AlienVault-OTX</span>
                <h3>{hashAvData[0]?.av_score}</h3>
              </div>
            </div>
          </div>
          <div className="flex-fill rounded-3 p-4 border recent-file-container bg-white">
            <div>
              <h3>Investigating the URL</h3>
              <p>
                Detailed automated investigation by CYBOT about the suspicious
                URL observed
              </p>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row gap-3">
                <VirusTotalSummary data={urlVirusData} />
              </div>
              <div className="d-flex flex-row gap-3">
                <div
                  className="flex-fill d-flex align-items-center alert border mb-0"
                  style={{ height: "240px" }}
                >
                  <GaugeChart
                    id="gauge-chart2"
                    nrOfLevels={20}
                    arcPadding={0.02}
                    cornerRadius={2}
                    percent={urlTsData[0]?.threat_score / 100}
                    formatTextValue={(value) => value}
                    textColor={"#000000"}
                  />
                </div>
              </div>
              <div className="flex-fill alert alert-info mb-0">
                <span>AlienVault-OTX</span>
                <h3>{urlAvData[0]?.av_score}</h3>
              </div>
            </div>
          </div>
          <div className="flex-fill rounded-3 p-4 border recent-file-container bg-white">
            <div>
              <h3>Investigating the IP</h3>
              <p>
                Detailed automated investigation by CYBOT about the suspicious
                IP observed
              </p>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row gap-3">
                <VirusTotalSummary data={ipVirusData} />
              </div>
              <div className="d-flex flex-row gap-3">
                <div
                  className="flex-fill d-flex align-items-center alert border mb-0"
                  style={{ height: "240px" }}
                >
                  <GaugeChart
                    id="gauge-chart2"
                    nrOfLevels={20}
                    arcPadding={0.02}
                    cornerRadius={2}
                    percent={ipTsData[0]?.uncommon_process_vt_score / 100}
                    formatTextValue={(value) => value}
                    textColor={"#000000"}
                  />
                </div>
              </div>
              <div className="flex-fill alert alert-info mb-0">
                <span>AlienVault-OTX</span>
                <h3>{ipAvData[0]?.av_score}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 rounded-3 p-4 border recent-file-container bg-white">
          <div>
            <h3>Investigation on Host & User</h3>
            <p>
              Detailed automated investigation by CYBOT about the Host & User
              which executed the suspected activity
            </p>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="col-12 rounded-3 p-4 border recent-auth-container">
              <h4>Recent Authentication in Host</h4>
              {recentAuthLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    recentAuthHostDetails.length === 0 ? (
                      <NoDataFound errorText={"No data found"} />
                    ) : (
                      <DataTable
                        // tableHead={["Sl No", "User Name", "Timestamp"]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          { value: "user_name", label: "User Name" },
                          { value: "timeStamp", label: "Timestamp" },
                        ]}
                        tableData={recentAuthHostDetails}
                        title={""}
                        description={""}
                        pagination={[true]}
                        isFilter={true}
                      />
                    )}
                </div>

              )}
            </div>
            <div className="col-12 rounded-3 p-4 border recent-file-container">
              <h4>Recent File Creations</h4>
              {recentCreationLoad ? (<TableLoader rowCount={4} />) : (
                <DataTable
                  // tableHead={[
                  //   "Sl No",
                  //   "User Name",
                  //   "Host Name",
                  //   "File Name",
                  //   "File Path",
                  // ]}
                  tableHead={[
                    //   { value: "sl_no", label: "Sl No" },
                    { value: "user_name", label: "User Name" },
                    { value: "host_name", label: "Host Name" },
                    { value: "file_name", label: "File Name" },
                    { value: "file_path", label: "File Path" },
                  ]}
                  tableData={recentFileData}
                  // title={"Recent file creations"}
                  description={
                    "An investigation was carried out in the datalake to determine the recent file creations performed by the suspected user on the host in which suspicious activity occurred examining time period from 2 hours before to 2 hours after the detection time."
                  }
                  pagination={true}
                  isFilter={true}
                />
              )}
            </div>
            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Recent File Deletions</h4>
              {recentDeletionLoad ? (<TableLoader rowCount={4} />) : (
                <DataTable
                  // tableHead={[
                  //   "Sl No",
                  //   "User Name",
                  //   "Host Name",
                  //   "File Name",
                  //   "File Path",
                  // ]}
                  tableHead={[
                    //   { value: "sl_no", label: "Sl No" },
                    { value: "user_name", label: "User Name" },
                    { value: "host_name", label: "Host Name" },
                    { value: "file_name", label: "File Name" },
                    { value: "file_path", label: "File Path" },
                  ]}
                  tableData={deletionFileData}
                  // title={"Recent file deletions"}
                  description={
                    "An investigation was performed in the datalake to determine the recent file deletions performed by the suspected user in the host in which suspicious activity occurred examining time range from 2 hours before to 2 hours after the detection time."
                  }
                  pagination={true}
                  isFilter={true}
                />
              )}
            </div>

            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Processes Run by Detected User </h4>
              {proDetectUserLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    processRunDetectionUserData.length === 0 ? (
                      <NoDataFound errorText={"No data found"} />
                    ) : (
                      <DataTable
                        // tableHead={[
                        //   "Sl No",
                        //   "Detection Timestamp",
                        //   "Process Name",
                        //   "Process Hash",
                        //   "File Path",
                        // ]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          { value: "detection_timestamp", label: "Timestamp" },
                          { value: "process_name", label: "Process Name" },
                          { value: "process_hash", label: "Process Hash" },
                          { value: "file_path", label: "Path Name" },
                        ]}
                        tableData={processRunDetectionUserData}
                        // title={"Processes Run by Detected User"}
                        description={
                          "An investigation was conducted to determine the processes that were run on the detected host around the time of detection. The panel below displays a list of all processes run during that period, along with the frequency of their execution. The investigation covered a time range from 6 hours before the detection time to 6 hours after the detection time"
                        }
                        pagination={true}
                        isFilter={true}
                      />)}
                </div>
              )}
            </div>

            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Processes Run by Detected Host </h4>
              {proDetectHostLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    processRunDetectionHostData.length === 0 ? (
                      <NoDataFound errorText={"No data found"} />
                    ) : (
                      <DataTable
                        // tableHead={[
                        //   "Sl No",
                        //   "Detection Timestamp",
                        //   "Process Name",
                        //   "Process Hash",
                        //   "File Path",
                        // ]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          { value: "detection_timestamp", label: "Timestamp" },
                          { value: "process_name", label: "Process Name" },
                          { value: "process_hash", label: "Process Hash" },
                          { value: "file_path", label: "Path Name" },
                        ]}
                        tableData={processRunDetectionHostData}
                        // title={"Processes Run by Detected Host"}
                        description={
                          "An investigation was performed to determine the processes run in the detected host. The below panel shows the list of all processes that were run in the detected host around the time of detection along with the number of times they were run. The investigation covered a time range from 6 hours before the detection time to 6 hours after the detection time."
                        }
                        pagination={true}
                        isFilter={true}
                      />)}
                </div>
              )}
            </div>

            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Uncommon Processes Run by Detected User</h4>
              {unProDetectUserLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    uncommonProcessData.length === 0 ? (
                      <NoDataFound errorText={"No Data Founted"} />
                    ) : (
                      <DataTable
                        // tableHead={[
                        //   "Sl No",
                        //   "VirusTotal Score",
                        //   "Process Name",
                        //   "Process Hash",
                        //   "Detection Timestamp",
                        // ]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          {
                            value: "uncommon_process_vt_score",
                            label: "Uncommon Process Data",
                          },
                          {
                            value: "uncommon_process_name",
                            label: "Uncommon Process Name",
                          },
                          {
                            value: "uncommon_process_hash",
                            label: "Uncommon Process Hash",
                          },
                          { value: "detection_timestamp", label: "Timestamp" },
                        ]}
                        tableData={uncommonProcessData}
                        // title={"Uncommon Processes run by detected User"}
                        description={
                          ""
                        }
                        pagination={true}
                        isFilter={true}
                      />)}
                </div>
              )}
            </div>

            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Hosts Logged into by User</h4>
              {hostLoggedUserLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    hostLoggedByUserData.length === 0 ? (
                      <NoDataFound errorText={"No data found"} />
                    ) : (
                      <DataTable
                        // tableHead={["Sl No", "Host Name", "Count"]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          { value: "host_name", label: "Host Name" },
                          { value: "count", label: "Count" },
                        ]}
                        tableData={hostLoggedByUserData}
                        // title={"Hosts logged into by user"}
                        description={
                          "The investigation aims to determine if the user had logged into any additional hosts, and the panel below displays the list of other hosts that were accessed by the user. The investigation examined a time range starting from 12 hours before the detection time"
                        }
                        pagination={true}
                        isFilter={true}
                      />
                    )}
                </div>
              )}
            </div>

            {/* <div className="col-12 rounded-3 p-4 border deletion-file-container"> */}
            {/* <h4>Traffic from host to IP summary</h4> */}
            {/* <DataTable */}
            {/* // tableHead={["Sl No", "Host Name", "Count"]}
                tableHead={[
                  //   { value: "sl_no", label: "Sl No" },
                  { value: "host_name", label: "Host Name" },
                  { value: "count", label: "Count" },
                ]}
                tableData={trafficHostDetectionFromSummary}
                title={"Traffic from host to IP"}
                description={
                  ""
                }
                pagination={true}
                isFilter={true}
              />
            </div> */}

            <div className="col-12 rounded-3 p-4 border deletion-file-container">
              <h4>Traffic from Other Hosts to Detected IP</h4>
              {trafficFromHostLoad ? (<TableLoader rowCount={4} />) : (
                <div>
                  {
                    trafficHostDetailedData.length === 0 ? (
                      <NoDataFound errorText={"No data found"} />
                    ) : (
                      <DataTable
                        // tableHead={[
                        //   "Sl No",
                        //   "Host Name",
                        //   "User Name",
                        //   "Process Name",
                        //   "Timestamp",
                        //   "Destination Port",
                        // ]}
                        tableHead={[
                          //   { value: "sl_no", label: "Sl No" },
                          { value: "host_name", label: "Host Name" },
                          { value: "user_name", label: "User Name" },
                          { value: "process_name", label: "Process Name" },
                          { value: "timestamp", label: "Timestamp" },
                          { value: "dest_port", label: "Destination Port" },
                        ]}
                        tableData={trafficHostDetailedData}
                        // title={"Traffic from other host to detected IP"}
                        description={
                          ""
                        }
                        pagination={true}
                        isFilter={true}
                      />)}
                </div>
              )}
            </div>
          </div>
        </div>


        <div className="col-12 rounded-3 p-4 border recent-file-container bg-white">
          <div>
            <h2>Investigation on Firewall</h2>
            <p>
              Detailed automated investigation by CYBOT about the Host & User
              which executed the suspected activity
            </p>
          </div>

          {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "dest_ip", label: "Destination IP" },
                { value: "dest_port", label: "Destination Port" },
                { value: "dest_country", label: "Destination Country" },
                { value: "count", label: "Count" },
              ]}
              tableData={firewallTrafficFromSummaryData}
              title={"Firewall from IP summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={true}
            />
          </div> */}

          <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <h4>Firewall from IP</h4>
            {firewallFromIpLoad ? (<TableLoader rowCount={4} />) : (
              <div>
                {
                  firewallTrafficFromDetailedData.length === 0 ? (
                    <NoDataFound errorText={"No data found"} />
                  ) : (
                    <DataTable
                      // tableHead={[
                      //   "Sl No",
                      //   "Destination IP",
                      //   "Destination Port",
                      //   "Destination Country",
                      //   "Timestamp",
                      // ]}
                      tableHead={[
                        //   { value: "sl_no", label: "Sl No" },
                        { value: "dest_ip", label: "Destination IP" },
                        { value: "dest_port", label: "Destination Port" },
                        { value: "dest_country", label: "Destination Country" },
                        { value: "timestamp", label: "Timestamp" },
                      ]}
                      tableData={firewallTrafficFromDetailedData}
                      // title={"Firewall from IP"}
                      description={
                        ""
                      }
                      pagination={true}
                      isFilter={true}
                    />
                  )}
              </div>
            )}
          </div>

          {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "source_ip", label: "Source IP" },
                { value: "source_port", label: "Source Port" },
                { value: "source_country", label: "Source Country" },
                { value: "count", label: "Count" },
              ]}
              tableData={firewallTrafficToSummaryData}
              title={"Firewall to IP Summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={true}
            />
          </div> */}

          <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <h4>Firewall to IP </h4>
            {firewallToIpLoad ? (<TableLoader rowCount={4} />) : (
              <div>
                {
                  firewallTrafficToDetailedData.length === 0 ? (
                    <NoDataFound errorText={"No data found"} />
                  ) : (
                    <DataTable
                      // tableHead={[
                      //   "Sl No",
                      //   "Source IP",
                      //   "Source Port",
                      //   "Source Country",
                      //   "Timestamp",
                      // ]}
                      tableHead={[
                        //   { value: "sl_no", label: "Sl No" },
                        { value: "source_ip", label: "Source IP" },
                        { value: "source_port", label: "Source Port" },
                        { value: "source_country", label: "Source Country" },
                        { value: "timestamp", label: "Timestamp" },
                      ]}
                      tableData={firewallTrafficToDetailedData}
                      // title={"Firewall to IP"}
                      description={
                        ""
                      }
                      pagination={true}
                      isFilter={true}
                    />
                  )}
              </div>
            )}
          </div>

        </div>


        <div className="col-12 rounded-3 p-4 border recent-file-container bg-white">
          <div>
            <h2>Investigation on WAF</h2>
            <p>
              Detailed automated investigation by CYBOT about the Host & User
              which executed the suspected activity
            </p>
          </div>

          {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "url", label: "URL" },
                { value: "count", label: "Count" },
              ]}
              tableData={wafTrafficFromSummaryData}
              title={"WAF from IP Summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={true}
            />
          </div> */}

          <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <h3>WAF from IP</h3>
            {wafFromIpLoad ? (<TableLoader rowCount={4} />) : (
              <div>
                {
                  wafTrafficFromDetailedData.length === 0 ? (
                    <NoDataFound errorText={"No data found"} />
                  ) : (
                    <DataTable
                      // tableHead={[
                      //   "Sl No",
                      //   "Destination IP",
                      //   "Destination Port",
                      //   "URL",
                      //   "Timestamp",
                      // ]}
                      tableHead={[
                        //   { value: "sl_no", label: "Sl No" },
                        { value: "dest_ip", label: "Destination IP" },
                        { value: "dest_port", label: "Destination Port" },
                        { value: "url", label: "URL" },
                        { value: "timestamp", label: "Timestamp" },
                      ]}
                      tableData={wafTrafficFromDetailedData}
                      // title={"WAF from IP"}
                      description={
                        ""
                      }
                      pagination={true}
                      isFilter={true}
                    />
                  )}
              </div>
            )}
          </div>

          <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <h3>WAF threat detected IP</h3>
            {wafThreatIpLoad ? (<TableLoader rowCount={4} />) : (
              <div>
                {
                  wafThreatDetectedIpData.length === 0 ? (
                    <NoDataFound errorText={"No data found"} />
                  ) : (
                    <DataTable
                      // tableHead={[
                      //   "Sl No",
                      //   "Destination IP",
                      //   "Destination Port",
                      //   "URL",
                      //   "Timestamp",
                      // ]}
                      tableHead={[
                        //   { value: "sl_no", label: "Sl No" },
                        { value: "dest_ip", label: "Destination IP" },
                        { value: "dest_port", label: "Destination Port" },
                        { value: "url", label: "URL" },
                        { value: "timestamp", label: "Timestamp" },
                      ]}
                      tableData={wafThreatDetectedIpData}
                      // title={"WAF threat detected IP"}
                      description={
                        ""
                      }
                      pagination={true}
                      isFilter={true}
                    />
                  )}
              </div>
            )}
          </div>

          <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <h3>WAF username attempted by the IP</h3>
            {wafUserAttemptedIpLoad ? (<TableLoader rowCount={4} />) : (
              <div>
                {
                  wafUserAttemptedIpData.length === 0 ? (
                    <NoDataFound errorText={"No data found"} />
                  ) : (
                    <DataTable
                      // tableHead={[
                      //   "Sl No",
                      //   "Destination IP",
                      //   "Destination Port",
                      //   "URL",
                      //   "Timestamp",
                      // ]}
                      tableHead={[
                        //   { value: "sl_no", label: "Sl No" },
                        { value: "dest_ip", label: "Destination IP" },
                        { value: "dest_port", label: "Destination Port" },
                        { value: "url", label: "URL" },
                        { value: "timestamp", label: "Timestamp" },
                      ]}
                      tableData={wafUserAttemptedIpData}
                      // title={"WAF username attempted by the IP"}
                      description={
                        ""
                      }
                      pagination={true}
                      isFilter={true}
                    />
                  )}
              </div>
            )}
          </div>


        </div>




        {/* <div>
                    <h3>INVESTIGATING THE HASH</h3>
                    <Tile
                        dataHead={["VirusTotal", "AlienVault-OTX", "Hash Threat Score"]}
                        Vt={hashVirusTotalData}
                        Av={hashAvData}
                        Ts={hashTsData}
                    />
                </div>

                <div>
                    <h3>INVESTIGATING THE IP</h3>
                    <Tile
                        dataHead={["VirusTotal", "AlienVault-OTX", "URL Threat Score"]}
                        Vt={ipVirusData}
                        Av={ipAvData}
                        Ts={ipTsData}
                    />
                </div>

                <div>
                    <h3>INVESTIGATING THE URL</h3>
                    <Tile
                        dataHead={["VirusTotal", "AlienVault-OTX", "URL Threat Score"]}
                        Vt={urlVirusData}
                        Av={urlAvData}
                        Ts={urlTsData}
                    />
                </div> */}
      </div>
    </div>
  );
};

export default InvestigationDashboard;
