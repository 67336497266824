import React, { useEffect, useState } from "react";
import {
  Table,
  Button
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { PencilSimple, X, CheckCircle, ShieldPlus, ShieldSlash } from "phosphor-react";
import { Link, useNavigate } from "react-router-dom";
import { fget } from "../../../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import globalStyles from "../../../constants/globalStyles";

const Users = () => {
  const datalakeUrl = localStorage.getItem("DATALAKE_URL")
  const navigate = useNavigate();


  const [tableData, setTableData] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  const [userData, setUserData] = useState();

  // console.log("tenantId", tenantId[0].tenantId)

  useEffect(() => {
    fget(`${apiBaseUrl}/core/userpermissions`).then(({ data }) => {
      data && data?.user_type === "super_admin" ? console.log() : navigate("/", { replace: true });
      setUserData(data)
    }).catch((err) => {
      console.log("Error", err)
    })
    setTableIsLoading(true)
    fget(`${apiBaseUrl}/core/users`).then(({ data }) => {
      setTableData(data)
      setTableIsLoading(false)
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])
  const disableUser = (user_name) => {
    fget(`${apiBaseUrl}/core/user/status/disable?user_name=${user_name}`).then(({ data }) => {
      if (data?.status === "done") {
        toast.success("User has been disabled", {
          position: "bottom-right",
        });
        setTableIsLoading(true)
        fget(`${apiBaseUrl}/core/users`).then(({ data }) => {
          setTableData(data)
          setTableIsLoading(false)
        }).catch((err) => {
          console.log("Error", err)
        })
      } else {
        toast.error("Error", {
          position: "bottom-right",
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  const enableUser = (user_name) => {
    fget(`${apiBaseUrl}/core/user/status/enable?user_name=${user_name}`).then(({ data }) => {
      if (data?.status === "done") {
        toast.success("User has been enabled", {
          position: "bottom-right",
        });
        setTableIsLoading(true)
        fget(`${apiBaseUrl}/core/users`).then(({ data }) => {
          setTableData(data)
          setTableIsLoading(false)
        }).catch((err) => {
          console.log("Error", err)
        })
      } else {
        toast.error("Error", {
          position: "bottom-right",
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <div
        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
        style={styles.filterWrap}
      >
      </div>

      <div className="detections col-12">
        <div className="d-flex justify-content-between">
          <h5 className="mb-0">Manage Users</h5>
          <div className="d-flex mb-2 mr-2">
            <Link to="/settings/create-user"><Button>Add User</Button></Link>
          </div>
        </div>
        <div className="col-12" style={styles.tableWrap}>
          {tableIsLoading ? (
            <div className="col-12">
              <TableLoader rowCount={25} />
            </div>
          ) : tableData.length !== 0 ? (
            <Table borderless hover>
              <thead className="fixed-head">
                <tr>
                  <th >User Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th className="text-center">Is SSO User</th>
                  <th>Status</th>
                  <th className="text-right">Manage</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item) => (
                  <tr>
                    <td >
                      {item?.user_name}{item?.user_name === userData?.user_name ? <span className='badge bg-info ms-1'>Current user</span> : ''}
                    </td>

                    <td>
                      {item?.email}
                    </td>
                    <td>
                      {item?.user_type}
                    </td>
                    <td className="text-center">
                      {item?.is_sso_user ? <CheckCircle size={16} color={globalStyles.green} /> : <X size={16} />}
                    </td>
                    <td>
                      {item?.status ? <span className='badge bg-success'>Active</span> : <span className='badge bg-secondary'>Disabled</span>}
                    </td>
                    <td className="text-center">
                      <div className="d-flex justify-content-end gap-3">
                        {item?.status ?
                          item?.user_name !== userData?.user_name && <span onClick={() => disableUser(item?.user_name)} className="cursor-pointer">
                            <TableViewMore Icon={ShieldPlus} dataToggle={"Disable User"} dataPlacement={"bottom"} />
                          </span> :
                          <span onClick={() => enableUser(item?.user_name)} className="cursor-pointer">
                            <TableViewMore Icon={ShieldSlash} dataToggle={"Enable User"} dataPlacement={"bottom"} />
                          </span>}
                        <Link to={`/settings/edit-user/${item.user_name}`} className="me-2">
                          <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />
                        </Link>

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoDataFound errorText={"No data Found"} />
          )}
        </div>

      </div>
      <ToastContainer />
    </div>
  );
};

export default Users;
