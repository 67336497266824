import React, { useState, useEffect } from "react";
import styles from "../Activebytes.styles";
import { convertTimestamp } from "../../../../Methods/TiMethods";
import LoaderList from "../../../../components/Loader/LoaderList";
import { Calendar } from "phosphor-react";
import TableViewMore from "../../../../components/ViewMoreBtn/TableViewMore";
import { ArrowRight } from "phosphor-react";
import NoDataFound from "../../../../components/NoDataFount/NoDataFound";
export default function HeadLines({ headlines, headLoading, timeoutLoader }) {


  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(perPage);
  const [prevCount, setPrevCount] = useState();
  const [redirecting, setRedirecting] = useState(false)

  const handleRedirect = (path) => {
    setRedirecting(true)
    setTimeout(() => {
      setRedirecting(false)
      window.open(path, "_blank");
    }, 2000);

  }

  return (
    <div className="overflow-auto" style={styles.section1}>
      {redirecting === true ?
        <div className="col-2 mx-auto">
          <div className="alert alert-warning flex-row d-flex text-center gap-3 justify-content-center align-items-center" role="alert">
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
            <span>Redirecting...</span>
          </div>
        </div>
        :


        headLoading === true || timeoutLoader === true ? (
          <LoaderList />
        ) : (


          headlines?.length === 0 ? <NoDataFound /> :
            headlines?.map(
              ({
                _source: { event_name, attribute_timestamp, reference_link }, index
              }) => (



                <div
                  className="col-12 d-flex flex-row align-items-center p-4 mb-4 rounded-3 card ti-card"
                  style={styles.tile}
                >
                  <div className="d-flex flex-column">
                    <h6 className="">{event_name}</h6>
                    <div className="d-flex mt-3 gap-2 align-items-center">
                      <Calendar size={18} />
                      <h6 className="m-0">{convertTimestamp(attribute_timestamp)}</h6>
                    </div>
                  </div>
                  <div className="ml-auto p-2">
                    <a className="reset-link" onClick={(e) => handleRedirect(reference_link)}><TableViewMore Icon={ArrowRight} dataToggle={"Open Link"} dataPlacement={"bottom"} /></a>
                  </div>


                  {/* <span style={{ fontSize: "0.8rem" }}>
                  {convertTimestamp(attribute_timestamp)}
                </span> */}

                </div>

              )
            )
        )}
    </div>

  );
}