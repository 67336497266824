import React, { useEffect, useState } from 'react'
import { CaretDown } from "phosphor-react";
import { Table } from "react-bootstrap";
import { getList, mostCommonDetection, getTacticsDetected } from '../../../Methods/InvestigationMethods.js';
import styles from '../../../components/Sidebar/Sidebar.styles'
import Dropstyles from './Correlation.styles.js';
const Correlation = ({ selectedDateFilter }) => {

    const [userList, setUserList] = useState([])
    const [hostList, sethostList] = useState([])
    const [mostCommonDetect, setMostCommonDetect] = useState([])

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");



    useEffect(() => {
        getList('user').then(({ data: { aggregations: { 2: { buckets } } } }) => {
            // let listName = []
            buckets && setUserList(buckets)

            // buckets.map((item) => {
            //     listName.push(item.key)
            //     console.log("L NAME :", listName)
            // })


        }).catch((err) => {
            console.log("Error", err)
        })

        getList('host').then(({ data: { aggregations: { 2: { buckets } } } }) => {
            buckets && sethostList(buckets)
        }).catch((err) => {
            console.log("Error", err)
        })


    }, [])

    useEffect(() => {
        mostCommonDetection({ "user": selectedOption }).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            buckets && setMostCommonDetect(buckets)
        }).catch((err) => {
            console.log("Error", err)
        })

        // getTacticsDetected

        getTacticsDetected({ "user": selectedOption }).then((res) => {

            console.log("chart data response :", res)
        }).catch((err) => {
            console.log("Error", err)
        })

    }, [selectedOption, selectedDateFilter])

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    return (
        <>
            <div style={Dropstyles.dropdown}>
                <div className="dropdown__header" onClick={() => setIsOpen(!isOpen)}>
                    {selectedOption || "Select an option"}
                    <CaretDown
                        color="black"
                        size={16}
                        style={{ marginLeft: "10px" }}
                    />
                </div>
                {isOpen && (
                    <div style={Dropstyles.dropdown__options}>
                        {userList.map((item) => (
                            <div style={Dropstyles.dropdown__list} key={item.key} onClick={() => handleOptionClick(item.key)} >{item.key}</div>
                        ))}
                    </div>
                )}
            </div>

            <div className="table-container">
                {mostCommonDetect.map((item) => (
                    <div>{item.key}</div>
                ))}
            </div>
        </>
    )
}

export default Correlation