import {
  RT_COMPLIANCE_LIST_CAT_REQUEST,
  RT_COMPLIANCE_LIST_CAT_SUCCESS,
  RT_COMPLIANCE_LIST_CAT_FAIL,
  COMPLIANCE_REMOVE_CATALOGUE_REQUEST,
  COMPLIANCE_REMOVE_CATALOGUE_SUCCESS,
  COMPLIANCE_REMOVE_CATALOGUE_FAIL,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL,
  RT_COMPLIANCE_CUSTOM_MASTER_REQUEST,
  RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS,
  RT_COMPLIANCE_CUSTOM_MASTER_FAIL,
  RT_COMP_LIST_MASTER_REQUEST,
  RT_COMP_LIST_MASTER_SUCCESS,
  RT_COMP_LIST_MASTER_FAIL,
  RT_COMP_ADD_CATALOGUE_REQUEST,
  RT_COMP_ADD_CATALOGUE_SUCCESS,
  RT_COMP_ADD_CATALOGUE_FAIL,
  RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE,
  RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
  RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS,
  RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL,
  RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
  RT_COMP_MASTER_SINGLE_LIST_FAIL,
  RT_COMP_MASTER_SINGLE_LIST_SUCCESS
} from "../../reduxConstants/ruleStudio/ComplianceConstants";

// tenent CATALOGUE
export const listCatalogueTenentCompliance = (
  state = { catalogue: [] },
  action
) => {
  switch (action.type) {
    case RT_COMPLIANCE_LIST_CAT_REQUEST:
      return { loading: true, catalogue: [] };
    case RT_COMPLIANCE_LIST_CAT_SUCCESS: {
      return { loading: false, catalogue: action.payload.data };
    }
    case RT_COMPLIANCE_LIST_CAT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// REMOVE CATALOGUE
export const removeFromCatalogueCompliance = (state = {}, action) => {
  switch (action.type) {
    case COMPLIANCE_REMOVE_CATALOGUE_REQUEST:
      return { loading: true };
    case COMPLIANCE_REMOVE_CATALOGUE_SUCCESS:
      return { loading: false, success: true };
    case COMPLIANCE_REMOVE_CATALOGUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CUSTOM SELECT LIST
export const listMasterCustomRsCompliance = (state = { list: [] }, action) => {
  switch (action.type) {
    case RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST:
      return { loading: true, list: [] };
    case RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS: {
      return { loading: false, list: action.payload.data };
    }
    case RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Compliance CUSTOM ADD TO COLLOCTION
export const createCompliance = (state = {}, action) => {
  switch (action.type) {
    case RT_COMPLIANCE_CUSTOM_MASTER_REQUEST:
      return { loading: true };
    case RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS:
      return { loading: false, success: true, master: action.payload };
    case RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE:
      return { success: false };

    case RT_COMPLIANCE_CUSTOM_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
//MASTER CATALOGUE

export const listCatalogueMasterCompliance = (
  state = { master: [] },
  action
) => {
  switch (action.type) {
    case RT_COMP_LIST_MASTER_REQUEST:
      return { loading: true, master: [] };
    case RT_COMP_LIST_MASTER_SUCCESS: {
      return { loading: false, master: action.payload.data };
    }
    case RT_COMP_LIST_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// MASTER CATALOGUE ADD TO CAT

export const addToCatalogueRsComp = (state = {}, action) => {
  switch (action.type) {
    case RT_COMP_ADD_CATALOGUE_REQUEST:
      return { loading: true };
    case RT_COMP_ADD_CATALOGUE_SUCCESS:
      return { loading: false, success: true, address_: action.payload };
    case RT_COMP_ADD_CATALOGUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


// Single details
export const detailsCustomRsComp = (
  state = { catalogue: {} },
  action
) => {
  switch (action.type) {
    case RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST:
      return { ...state, loading: true };
    case RT_COMP_MASTER_SINGLE_LIST_SUCCESS:
      return { loading: false, catalogue: action.payload.data };
    case RT_COMP_MASTER_SINGLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// query
export const detailsCustomCompQueryRsComp = (
  state = { catalogueQuery: {} },
  action
) => {
  switch (action.type) {
    case RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST:
      return { ...state, loading: true };
    case RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS:
      return {
        loading: false,
        catalogueQuery: action.payload,
      };
    case RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};