import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import globalStyles from "../../constants/globalStyles";

const RS_Filter = ({ filter, setFilter, count, data, comData,
  dataList, cData, masterFilterAll, allCount, cat, filterCount, searchData }) => {
  const [showList, setShowList] = useState(false);
  const [dotColor, setDotColor] = useState("white");
  const [all, setAll] = useState(0)

  useEffect(() => {

  }, [filter]);



  // const setColor = (val) => {

  //   if (val.toLowerCase() === 'edr') {
  //     val = globalStyles.greyDark3
  //   }
  //   else if (val.toLowerCase() === "dns") { val = globalStyles.greyDark3 }
  //   else if (val.toLowerCase() === "firewall") { val = globalStyles.greyDark3 }
  //   else if (val.toLowerCase() === "active directory") { val = globalStyles.greyDark3 }
  //   else {
  //     val = globalStyles.greyDark3
  //   }

  //   return val
  // }



  return (
    <div
      className="mx-2"
      onMouseOver={() => setShowList(true)}
      onMouseOut={() => setShowList(false)}
      style={{ zIndex: 2 }}
      type="button"
    >
      <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 h-100">
        <span
          style={{
            borderRadius: "50%",
            backgroundColor: dotColor,
            height: "7px",
            width: "7px",
          }}
        />

        <span>
          Category - <strong>{filter ? filter : "ALL"} {`(${count})` ? count : `-(${0})`}</strong>
        </span>



      </div>
      {showList && (
        <div className="pt-3 position-absolute" style={{ zIndex: 2 }}>
          <div className="col-12 d-flex flex-column gap-2 bg-white rounded-3 shadow-sm p-3 px-4">
            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  height: "7px",
                  width: "7px",
                }}
              />




              <span className="" onClick={() => setFilter("All")}>
                All- {searchData?.length}
              </span>




            </div>
            {data?.map((val) => {

              // let mapData = cat === false ? (filter === 'All' ? dataList : comData) : (filter === 'All' ? dataList : cData)
              let count = searchData?.filter((obj) => obj.log[0] === val).length
              return (
                <div className="d-flex flex-row align-items-center gap-2">
                  <span
                    style={{
                      borderRadius: "50%",
                      height: "7px",
                      width: "7px",
                    }}
                  />
                  <span onClick={() => setFilter(val)}>{val}-({count})</span>

                </div>
              )

            })}


          </div>
        </div>
      )}
    </div>
  );
};

export default RS_Filter;