import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCorCatalogueMaster,
    getCorCatalogueTenant,
    addToOrganizationCatalogueCor,
    removeFromCatalogueCor,
    refreshCorTenantCatalogue,
    refreshCorMasterCatalogue,
    setLoadingFalseCor

} from "../../../Methods/RuleStudioMethods";
import styles from "./Correlation.styles";
import Card from "react-bootstrap/Card";
import { toastsSuccess, toastsDanger, toastsWarning } from "../../../components/Toster/Toster";

import Table from "react-bootstrap/Table";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";


import CustomModal from "../../../components/CustomModal/CustomModal";
import { Button, Form, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
// import Custom from "../../../components/CustomModal/RSCustomModal";

import MasterCardCor from "../../../components/RuleStudio/Correlation/MasterCardCor";
import LoaderList from "../../../components/Loader/LoaderList";
import { Pencil, PencilSimple, X } from "phosphor-react";
import {
    escapeHtmlChars,
    formatDate,
    getDateFromTimestamp,
    rawHTML,
    removeHtmlChars,
    dateFormat,

} from "../../../Utils/Utils.js";
import LoaderGrid from "../../../components/Loader/LoaderGrid";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import RS_Filter from "../../../components/RuleStudio/RS_Filter";
import Search from "../../../components/Search/Search";
import { ToastContainer, toast } from 'react-toastify';
import CustomCorrelation from "../../../components/RuleStudio/Correlation/CustomCorrelation";
import SearchTi from "../../../components/Search/SearchTi";

import TableLoader from "../../../components/Loader/TableLoader";


const Correlation = () => {
    const dispatch = useDispatch();

    const addToCatalogueRsCor = useSelector((state) => state.addToCatalogueRsCor);
    const { success: catalogueAddSuccess, loading } = addToCatalogueRsCor;

    const listMasterRsCor = useSelector((state) => state.listMasterRsCor);
    const { master, } = listMasterRsCor;

    const listCatalogueRsCor = useSelector((state) => state.listCatalogueRsCor);
    const { catalogues: catalogues, listLoading: catLoading, deleteSuccess, deletedId } = listCatalogueRsCor;
    console.log('catalogues', catalogues);





    // const removeCatRsInv = useSelector((state) => state.removeCatRsInv);
    // const { success, error } = removeCatRsInv;

    const createCorrelationRsCor = useSelector((state) => state.createCorrelationRsCor);
    const { success: createSuccess, error: createError } = createCorrelationRsCor;




    const [show, setShow] = useState(false);
    const [corID, setCorId] = useState("");



    const [masterFilterAll, setMasterFilterAll] = useState(false);

    const [filteredDataMaster, setFilteredDataMaster] = useState([]);
    const [filteredDataCatalogue, setFilteredDataCatalogue] = useState([]);
    const [customShow, setCustomShow] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [add, setAdd] = useState(false);
    const [cat, setCat] = useState(true)
    const [data, setData] = useState("")

    const [searchQueryCatalogue, setSearchQueryCatalogue] = useState("");
    const [catalogueDataFinal, setCatalogueDataFinal] = useState([]);

    const [searchQueryMaster, setSearchQueryMaster] = useState("");
    const [filterQueryMaster, setFilterQueryMaster] = useState("All");
    const [masterDataFinal, setMasterDataFinal] = useState([]);
    const [masterSort, setMasterSort] = useState("asc");
    const [ORGFilter, setORGFilter] = useState("");
    const [excludedElements, setExcludedElements] = useState([]);
    const [masterFilterData, setMasterFilterData] = useState([]);
    const [commonData, setCommonData] = useState([]);
    const [allMasterData, setAllMasterData] = useState([]);
    const [toggleViewAll, setToggleViewAll] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [masterLoading, setMasterLoading] = useState(false);




    const getFilteredItems = (state) => {

        // if (state.length > 0) {
        //     let data = [];

        //     state?.map((val, index) => {

        //         if (!data.includes(val.log[0])) {
        //             data.push(val.log[0])
        //         }
        //     })

        //     return data
        // }


    }

    const sevFilterListTelnet = useSelector((state) => getFilteredItems(state.listCatalogueRsCor.catalogues));

    // const sevFilterListMaster = useSelector((state) => getFilteredItems(state.listMasterRsCor.master));



    const filterCatalogue = (val) => {

        let filteredItems
        let search = val != undefined ? val : searchQueryCatalogue


        if (ORGFilter === 'All') {
            filteredItems = catalogues?.filter((item) =>
                item.cac_id.toLowerCase().includes(search.toLowerCase()) ||
                item.cac_name.toLowerCase().includes(search.toLowerCase()) ||
                item.Description.toLowerCase().includes(search.toLowerCase())
            )
        } else {

            filteredItems = catalogues?.filter((item) =>
            (item.cac_id.toLowerCase().includes(search.toLowerCase()) ||
                item.cac_name.toLowerCase().includes(search.toLowerCase()) ||
                item.Description.toLowerCase().includes(search.toLowerCase())
            )
                // && (item.log[0]?.toLowerCase().includes(ORGFilter.toLowerCase()))


            )
        }


        setCatalogueDataFinal(filteredItems)

    }

    const searchDataListCatalogue = () => {
        let filteredItems = catalogues?.filter((item) =>
            item.cac_id.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
            item.cac_name.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
            item.Description.toLowerCase().includes(searchQueryCatalogue.toLowerCase())
        )
        return filteredItems
    }


    useEffect(() => {


        if (searchQueryCatalogue || ORGFilter || deletedId) {

            filterCatalogue()
            filterDataCatalogue(searchQueryCatalogue);
        } else {

            dispatch(getCorCatalogueTenant());
            setCorId("");
        }



    }, [searchQueryCatalogue, dispatch, ORGFilter, deletedId]);


    useEffect(() => {
        if (catalogueAddSuccess === true) {
            toastsSuccess("Data added successfully")
            toastsWarning("It might take a while for the data to be updated !")
        }
    }, [catalogueAddSuccess])



    // Refresh
    useEffect(() => {
        refreshCorTenantCatalogue()
        refreshCorMasterCatalogue()
    }, [])


    //  Toasts
    useEffect(() => {



        if (createSuccess === true) {

            toastsSuccess("Data saved successfully")
            toastsWarning("It might take a while for the data to be updated !")
            setSubmitLoader(false)
            setCustomShow(false)

        }

    }, [createSuccess])


    if (catalogues?.length > 0 && catalogueDataFinal.length === 0 && searchQueryCatalogue === '' && ORGFilter === '') {
        filterCatalogue()
    }


    const handleOpenModal = (display) => {
        setCat(false);
        setShow(display);
    };


    function handleClose() {
        setMasterFilterAll(false);
        setCat(true);
        setShow(false);
    }


    const filterDataCatalogue = (query) => {

        const filtered = filteredDataCatalogue?.filter((item) => {
            return (
                item[0].toLowerCase().includes(query.toLowerCase()) ||
                item[1].toLowerCase().includes(query.toLowerCase()) ||
                item[2].toLowerCase().includes(query.toLowerCase())
            );
        });


        setFilteredDataCatalogue(filtered);
    };

    const AddToCatalogue = (id) => {

        setAdd(true);

        if (window.confirm("Are you sure?")) {
            dispatch(addToOrganizationCatalogueCor(id));
        }
    };

    const deleteFromCatalogue = (id, index) => {
        if (window.confirm("Delete the item?")) {
            toastsDanger("Deleted successfully")
            toastsWarning("It might take a while for the data to be updated !")

            dispatch(removeFromCatalogueCor(id, index));

            if (deleteSuccess === true || deleteSuccess === undefined) {
                refreshCorTenantCatalogue().then((res) => {
                    if (res.status === 200) {
                        dispatch(getCorCatalogueTenant())
                    }
                })
            }
        }
    };



    const handleRefresh = (status = false) => {

        setRefresh(true)

        if (refresh === true || status === true) {
            refreshCorMasterCatalogue().then((res) => {

                if (res.status === 200) {
                    setRefresh(false)
                }
            })
        }


    };

    const handleCustom = (display) => {
        setCorId("");
        setCustomShow(display);
    };

    const handleEdit = (corID) => {
        setCorId(corID);
        setCustomShow(true);
    };


    const handleSearch = (val, e, action) => {
        if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
            setSearchQueryCatalogue(val)
            filterCatalogue(val)

        }
    };


    // master data load when change search
    const handleSearchMaster = (val, e, action) => {
        if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
            setSearchQueryMaster(val)
            getMasterDataFn(val, masterSort, toggleViewAll, filterQueryMaster)

        }
    };


    // master data load when change sort
    useEffect(() => {
        if (show === true && masterSort) {
            getMasterDataFn(searchQueryMaster, masterSort, toggleViewAll, filterQueryMaster)
        }
    }, [dispatch, masterSort, show])




    // master data load when change toggle
    const handleToggleChange = (val) => {

        const tog = !toggleViewAll;

        setToggleViewAll(!toggleViewAll);

        getMasterDataFn(searchQueryMaster, masterSort, tog, filterQueryMaster)

    }


    //  master data load when change filter
    useEffect(() => {

        getMasterDataFn(searchQueryMaster, masterSort, toggleViewAll, filterQueryMaster)
    }, [filterQueryMaster])







    const filterMasterData = (data, search, toggle, filter) => {
        let filteredItemsMaster

        if (filterQueryMaster === 'All') {

            filteredItemsMaster = data?.filter((item) =>

                item.cac_id.toLowerCase().includes(search.toLowerCase()) ||
                item.cac_name.toLowerCase().includes(search.toLowerCase()) ||
                item.Description.toLowerCase().includes(search.toLowerCase())
            )
        } else {


            filteredItemsMaster = data.filter((item) =>
                (item.cac_id.toLowerCase().includes(search.toLowerCase()) ||
                    item.cac_name.toLowerCase().includes(search.toLowerCase()) ||
                    item.Description.toLowerCase().includes(search.toLowerCase())
                )
                && (item.log[0].toLowerCase().includes(filter.toLowerCase()))


            )
        }
        if (toggle === false) {
            filteredItemsMaster = filteredItemsMaster?.filter(item1 => !catalogues?.find(item2 => item1.cac_id === item2.cac_id));
        }


        setCommonData(filteredItemsMaster)

    }



    const getMasterDataFn = (search, sort, toggle, filter) => {



        setMasterLoading(true)
        getCorCatalogueMaster(sort).then((res) => {

            if (res.status === 200) {
                setMasterLoading(false)
                setAllMasterData(res.data)
                filterMasterData(res.data, search, toggle, filter)

            }

        })
    }

    const searchDataListMaster = () => {

        let filteredItems = allMasterData?.filter((item) =>

            item.cac_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
            item.cac_name.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
            item.Description.toLowerCase().includes(searchQueryMaster.toLowerCase())
        )


        if (toggleViewAll === false) {
            filteredItems = filteredItems?.filter(item1 => !catalogues?.find(item2 => item1.cac_id === item2.cac_id));
        }


        return filteredItems
    }


    function renderModal() {
        return (
            <div>
                <div className="mb-3 py-2 sticky-top">
                    {/* <h5>Master Catalogue</h5> */}
                    <div className="row">
                        <div className="col-12 d-flex py-2 gap-2">
                            <div className="col-3">
                                <SearchTi changeFunction={handleSearchMaster} module='rulestudio' />

                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <RS_Filter
                                        filter={filterQueryMaster}
                                        setFilter={setFilterQueryMaster}
                                        count={commonData.length}
                                        allCount={commonData.length}
                                        cData={cat === true ? master : commonData}
                                        data={getFilteredItems(allMasterData)}
                                        dataList={commonData}
                                        masterFilterAll={masterFilterAll}
                                        cat={cat}
                                        comData={data}
                                        searchData={searchDataListMaster()}
                                    />



                                </div>
                            </div>

                            <div className="flex-fill d-flex flex-row align-items-center gap-2">
                                <span className="w-25 text-right">Sort by</span>
                                <select className="form-select" onChange={(e) => setMasterSort(e.target.value)}>
                                    <option value="desc">Oldest</option>
                                    <option value="asc">Newest</option>
                                </select>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="toggle_view_switch"
                                        label="View All"
                                        defaultChecked={toggleViewAll}
                                        onChange={(e) => handleToggleChange(e.target.value)}
                                    />
                                </Form>
                            </div>
                            <div className="col-1">
                                <Link style={{ color: "white" }} onClick={() => handleRefresh(true)}>
                                    <button className="btn btn-primary">Refresh</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



                {masterLoading == true || refresh === true ? (
                    <LoaderList />
                ) : (
                    <div div className="col-12" style={styles.section1}>

                        {commonData?.length === 0 ? <NoDataFound /> :
                            <div className="d-flex flex-column gap-3">
                                {commonData?.map((item, index) => {
                                    const filter = catalogues?.some(
                                        (data) => data.cac_id.toLowerCase() === item.cac_id.toLowerCase()
                                    );

                                    return (
                                        <MasterCardCor
                                            item={item}
                                            filter={filter}
                                            AddToCatalogue={AddToCatalogue}
                                            loading={loading}
                                            index={index}
                                        />
                                    );
                                })}
                            </div>
                        }
                    </div>
                )}
            </div>
        );
    }




    return (
        <div className="col-12 rounded-3 p-4" style={styles.section1}>
            <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
                <h5 className="mb-0">Organization Catalogue</h5>

                <div className="d-flex">
                    <div className="form-group mx-2">
                        <SearchTi changeFunction={handleSearch} module='rulestudio' />
                        {/* <Form.Control
          type="text"
          placeholder="search"
          className=""
          style={{ width: "" }}
          onChange={(e) => setSearchQueryCatalogue(e.target.value)}
        /> */}
                    </div>
                    <div className="form-group">
                        <RS_Filter
                            filter={ORGFilter}
                            setFilter={setORGFilter}
                            count={catalogueDataFinal.length}
                            cData={catalogues}
                            data={sevFilterListTelnet}
                            dataList={catalogueDataFinal}
                            allCount={catalogueDataFinal.length}
                            cat={cat}
                            searchData={searchDataListCatalogue()}
                        />


                    </div>
                    <Link
                        className="mx-2"
                        style={{ color: "white" }}
                        onClick={() => handleOpenModal(true)}
                    >
                        <button className="btn btn-primary">Add from master</button>
                    </Link>
                    <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
                        <button className="btn btn-light">Create Custom</button>
                    </Link>

                </div>
            </div>

            {/* <LoaderGrid /> */}
            <div style={styles.mainWrap}>
                {catLoading === true ? (
                    <TableLoader rowCount={15} />
                ) : (
                    catalogueDataFinal.length === 0 ? <NoDataFound /> :
                        <div className="d-flex flex-row flex-wrap py-3">
                            <Table borderless hover>
                                <thead className="fixed-head">
                                    <tr>
                                        <th>ID</th>
                                        <th>Alert name</th>
                                        <th>Description</th>
                                        <th> Created on</th>
                                        <th> Updated on</th>
                                        <th>  </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {catalogueDataFinal.map((item, index) => {
                                        return (
                                            <tr key={"rs_inv_" + index}>
                                                <td className="align-middle">
                                                    {item.cac_id}
                                                </td>
                                                <td className="align-middle">
                                                    {item.cac_name}
                                                </td>
                                                <td className="align-middle">
                                                    {removeHtmlChars(item.Description)}
                                                </td>
                                                <td className="align-middle">
                                                    {item?.cac_created && formatDate(item?.cac_created)}
                                                </td>
                                                <td className="align-middle">
                                                    {item?.cac_last_edited && formatDate(item?.cac_last_edited)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div className="d-flex flex-row gap-3 justify-content-center">
                                                        <i
                                                            className="cursor-pointer"
                                                            onClick={(e) => handleEdit(item.cac_id)}
                                                        >
                                                            <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />
                                                        </i>
                                                        <i className="cursor-pointer" onClick={(e) => deleteFromCatalogue(item.cac_id, index)}>
                                                            <TableViewMore Icon={X} dataToggle={"Delete"} dataPlacement={"bottom"} />
                                                        </i>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>

                )}
            </div>



















            <ToastContainer />
            {
                show === true && (
                    <CustomModal
                        title={`Master Catalogue`}
                        ShowOrHide={show}
                        bodyData={renderModal}
                        handleClose={handleClose}
                        footer={false}
                    />
                )
            }

            <CustomCorrelation
                setCustomShow={setCustomShow}
                customShow={customShow}
                setCorId={setCorId}
                corIdEdit={corID}
            />
        </div >
    );
};
export default Correlation;
