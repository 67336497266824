import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

const SingleTileLoader = ({ width, count = 5 }) => {

    const [skeletonArray, setSkeletonArray] = useState([]);

    useEffect(() => {
        const skeletons = [];
        for (let i = 0; i < count; i++) {
            skeletons.push(
                <div key={i} className='mb-4'>
                    <Skeleton className='rounded-3' style={{ height: "10px", width: "70px" }} />
                    <Skeleton className='rounded-3 py-2' style={{ height: "10px", width: width }} />
                </div>
            );
        }
        setSkeletonArray(skeletons);
    }, [count, width]);

    return (
        <div className="left col-12">
            {skeletonArray}
        </div>
    )
}


const SingleLoader = ({ height }) => {
    return (
        <div className='mb-3 col-12'>
            <Skeleton className='rounded-3' style={{ height: height ? height : "calc(100vh - 8.3rem)", width: "100%" }} />
        </div>
    )
}
export { SingleTileLoader, SingleLoader }