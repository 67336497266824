import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import { getMultiQuery } from "../../Queries/CykitQuery";
import ReactJson from "react-json-view";
import useStore from "./Store/store";

import { Tabs, Tab, Table } from "react-bootstrap";
import styles from "./Sidebar.styles";
import { File, Globe, Key, X } from "phosphor-react";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";
import SpinnerLoader from "../Loader/SpinnerLoader";
import NoDataFound from "../NoDataFount/NoDataFound";

const RegistryCols = [

  {
    name: 'Key',
    selector: row => row._source.registry.key,
    sortable: true,
  },
  {
    name: 'data',
    selector: row => row._source.registry.data,
    sortable: true,
  },
  {
    name: 'hive',
    selector: row => row._source.registry.hive,
    sortable: true,
  },
]
const NetworkCols = [


  {
    name: 'URL',
    selector: row => row._source.destination.url,
    sortable: true,
  },
  {
    name: 'Port',
    selector: row => row._source.destination.port,
    sortable: true,
  }
]
const FileCols = [
  {
    name: 'File name',
    selector: row => row._source.file.name,
    sortable: true,
  },
  {
    name: 'Path',
    selector: row => row._source.file.path,
    sortable: true,
  },
  {
    name: 'action',
    selector: row => row._source.event.action,
    sortable: true,
  }
]
const selector = (state) => ({
  clickedNode: state.clickedNode
});
let baseURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

export default function SideBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [node, setNode] = useState();
  const [nodeData, setNodeData] = useState();
  const [processData, setprocessData] = useState();
  const clickedNode = useStore((state) => state.clickedNode);
  const setClick = useStore((state) => state.setClick)
  const [file, setFile] = useState()
  const [registry, setRegistry] = useState()
  const [network, setNetwork] = useState()
  const [fileCount, setFileCount] = useState(0)
  const [registryCount, setRegistryCount] = useState(0)
  const [networkCount, setNetworkCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (clickedNode.length > 0) {
      setIsLoading(true)
      setIsOpen(true);
      const data = JSON.stringify({
        query: {
          bool: {
            must: {
              term: {
                _id: clickedNode,
              },
            },
          },
        },
      });
      fpostCy(
        `${baseURL}/${indexPattern}`,
        data).then((response) => {
          // props.parentData(response.data)
          response.data.hits.hits &&
            response.data.hits.hits.map((val) => {
              setNodeData(val._source);
              const data = {
                "timestamp": val._source['@timestamp'],
                "executable": val._source.process.executable,
                "pid": val._source.process.pid,
                // "hash": val._source.process.hash.md5,
                "user_domain": val._source.user.domain,
                "user_name": val._source.user.name,
                "name": val._source.process.name
              }
              setprocessData(data)
              getData(val)

            });
        })       
    }
  }, [clickedNode]);

  useEffect(()=>{
    if(nodeData){
      setIsLoading(false)
    }
  },[nodeData])
  useEffect(() => {
    if (props.close === true) {
      setIsOpen(false)
      setClick('')
      props.closefn(false)
    }
  }, [props.close])

  const cleanDataProcess = (data) => {
    if (data.initiating && data.initiating.processcreationtime) {
      var time = data.initiating.processcreationtime;
    }
    else if (data.properties && data.properties.InitiatingProcessCreationTime) {
      var time = data.properties.InitiatingProcessCreationTime
    }
    return time
  }

  const getData = (val) => {
    const time = cleanDataProcess(val._source)
    if (edr === "atp") {
      var filetype = "AdvancedHunting-DeviceFileEvents"
      var networktype = "AdvancedHunting-DeviceNetworkEvents"
      var registrytype = "AdvancedHunting-DeviceRegistryEvents"

    }
    else if (edr === "elastic") {
      var filetype = "file"
      var networktype = "network"
      var registrytype = "registry"

    }
   if(val._source.process?.entity_id){ 
    var filedate = getMultiQuery(val._source.host.name, time, val._source.process.pid, filetype, val._source.process?.entity_id)

    var file = []
    var network = []
    var registry = []

    fpostCy(
      `${baseURL}/${indexPattern}`,
      filedate)
      .then((response) => {
        setFile(response.data.hits.hits)
      })

    var netdata = getMultiQuery(val._source.host.name, time, val._source.process.pid, networktype, val._source.process?.entity_id)
    fpostCy(
      `${baseURL}/${indexPattern}`,
      netdata)
      .then((response) => {
        setNetwork(response.data.hits.hits)

      })

    var regdata = getMultiQuery(val._source.host.name, time, val._source.process.pid, registrytype, val._source.process?.entity_id)
    fpostCy(
      `${baseURL}/${indexPattern}`,
      regdata)
      .then((response) => {
        setRegistry((registry) => response.data.hits.hits)

      })
}
else{
  var filedate = getMultiQuery(val._source.host.name, time, val._source.process.pid, filetype)

  var file = []
  var network = []
  var registry = []
  
  fpostCy(
    `${baseURL}/${indexPattern}`,
    filedate)
    .then((response) => {
      setFile(response.data.hits.hits)
    })
  
  var netdata = getMultiQuery(val._source.host.name, time, val._source.process.pid, networktype)
  fpostCy(
    `${baseURL}/${indexPattern}`,
    netdata)
    .then((response) => {
      setNetwork(response.data.hits.hits)
  
    })
  
  var regdata = getMultiQuery(val._source.host.name, time, val._source.process.pid, registrytype)
  fpostCy(
    `${baseURL}/${indexPattern}`,
    regdata)
    .then((response) => {
      setRegistry((registry) => response.data.hits.hits)
  
    })
}

  }
  useEffect(() => {
    if (registry !== undefined) {
      setRegistryCount(registry.length)
    }
  }, [registry])
  useEffect(() => {
    if (file !== undefined) {
      setFileCount(file.length)
    }
  }, [file])
  useEffect(() => {
    if (network !== undefined) {
      setNetworkCount(network.length)
    }
  }, [network])

  const handleClose = () => {
    setClick('')
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal"
              id="offcanvasRightLabel"
            >
              Process Details
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}
          {isLoading?<SpinnerLoader isLoading={isLoading} />:

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar">
            <Tabs defaultActiveKey="details" aria-label="Tabs with underline">
              <Tab eventKey="details" title="Details" className="overflow-scroll" style={{ height: '750px' }}>
                {/* this not workgin */}
                <div className="py-3" style={styles.sidebarContentWrap}>
                  {nodeData ?
                    (
                      <div>
                        <div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Process Name</span>
                            <span className="modal-text">
                              {processData?.name}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Date</span>
                            <span className="modal-text">
                              {getDateFromTimestamp(processData?.timestamp)}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Time</span>
                            <span className="modal-text">
                              {getTimeFromTimestamp(processData?.timestamp)}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Timestamp</span>
                            <span className="modal-text">
                              {processData?.timestamp}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Process executable</span>
                            <span className="modal-text">
                              {processData?.executable}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Process ID</span>
                            <span className="modal-text">
                              {processData?.pid}
                            </span>
                          </div>
                          {/* <div className="modal-row-item">
                            <span className="modal-label col-3">Process hash MD5</span>
                            <span className="modal-text">
                              {processData?.hash}
                            </span>
                          </div> */}
                          <div className="modal-row-item">
                            <span className="modal-label col-3">User name</span>
                            <span className="modal-text">
                              {processData?.user_name}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">User domain</span>
                            <span className="modal-text">
                              {processData?.user_domain}
                            </span>
                          </div>
                        </div>


                        <div className="mt-4">
                          <h5 className="mb-3">Event Counts</h5>

                          <div className="d-flex flex-row gap-3">
                            {registry && (<div className="d-flex flex-column flex-fill gap-2 alert alert-secondary rounded-3">
                              <div className="d-flex gap-3">
                                <Key size={24} />
                                <h3>{registry.length}</h3>
                              </div>
                              <span className="font-bold my-auto">Registry Event</span>
                            </div>)}
                            {file && <div className="d-flex flex-column flex-fill gap-2 alert alert-secondary rounded-3">
                              <div className="d-flex gap-3">
                                <File size={24} />
                                <h3>{file.length}</h3>
                              </div>
                              <p className="font-bold my-auto">Files Operations</p>
                            </div>}
                            {network && <div className="d-flex flex-column flex-fill gap-2 alert alert-secondary rounded-3">
                              <div className="d-flex gap-3">
                                <Globe size={24} />
                                <h3>{network.length}</h3>
                              </div>
                              <p className="font-bold my-auto">Network Calls </p>
                            </div>}
                          </div>
                        </div>

                        <div className="mt-4">
                          <h5 className="mb-3">Details</h5>

                          <Tabs
                            defaultActiveKey="registrycounts"
                          >
                            <Tab eventKey="registrycounts" title="Registry Events">
                              <div className="py-3">
                                {registry?.length>0?<Table>
                                  <thead className="fixed-head">
                                    <tr>
                                      <th>Key</th>
                                      <th>Path</th>
                                      <th>Hive</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {registry?.map((item, i) => (
                                      <tr key={i}>
                                        <td className="align-middle">{item._source?.registry.key}</td>
                                        <td className="align-middle">{item._source?.registry.path}</td>
                                        <td className="align-middle">{item._source?.registry.hive}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>:<NoDataFound />}
                              </div>
                            </Tab>
                            <Tab eventKey="filecounts" title="File Events">
                              <div className="py-3">
                                {file?.length>0?<Table>
                                  <thead className="fixed-head">
                                    <tr>
                                      <th>Name</th>
                                      <th>Path</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {file?.map((item, i) => (
                                      <tr key={i}>
                                        <td className="align-middle">{item._source?.file.name}</td>
                                        <td className="align-middle">{item._source?.file.path}</td>
                                        <td className="align-middle">{item._source?.file.action}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>: <NoDataFound />}
                              </div>
                            </Tab>
                            <Tab eventKey="networkcounts" title="Network Events">
                              <div className="py-3">
                                {network?.length>0?<Table>
                                  <thead className="fixed-head">
                                    <tr>
                                      <th>IP</th>
                                      <th>Port</th>
                                      <th>URL</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {network && edr === "atp" ? network.map((item) => (
                                      <tr>
                                        <td className="align-middle">{item._source.destination?.ip && item._source.destination.ip}</td>
                                        <td className="align-middle">{item._source.destination?.port && item._source.destination.port}</td>
                                        <td className="align-middle">{item._source.destination?.url && item._source.destination.url}</td>
                                      </tr>
                                    )) : network?.map((item, i) => (
                                      (item._source.destination !== undefined) ?
                                        (<tr key={i}>
                                          <td className="align-middle">{item._source.destination.ip}</td>
                                          <td className="align-middle">{item._source.destination.port}</td>
                                          <td className="align-middle">{item._source.destination.address}</td>
                                        </tr>) :
                                        <tr key={i}>
                                          <td className="align-middle"> </td>
                                          <td className="align-middle">{item._source.network.destination.port}</td>
                                          <td className="align-middle"> </td>
                                        </tr>
                                    ))}
                                  </tbody> 
                                </Table>:<NoDataFound />}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                        <div>

                        </div>
                      </div>
                    ) :
                    <div>
                      No data found about the process
                    </div>

                  }
                </div>
              </Tab>
              <Tab eventKey="json" title="JSON" >
                <div className="py-3" style={styles.sidebarContentWrap}>
                  {nodeData ? <ReactJson className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={nodeData} collapsed={false} onEdit={false} onAdd={false} onDelete={false} enableClipboard={false} onSelect={false} displayDataTypes={false} displayObjectSize={false} />
                    : <div>
                      No data found about the process
                    </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>
              </Tab>
            </Tabs>

          </div>}
        </div>
      ) : (
        ""
      )}
    </>
  );
}