import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import styles from "./Case.styles";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { Badge, Button, Form, Dropdown } from "react-bootstrap";
import { Pencil, PencilSimple, X } from "phosphor-react";
import EditModal from "../../../components/CaseManagement/EditModal";
import DetailModal from "../../../components/CaseManagement/DetailModal";
import TableLoader from "../../../components/Loader/TableLoader";
import { toastsSuccess, toastsDanger } from "../../../components/Toster/Toster";
import {
  listCaseManagement,
  deleteCaseManagement,
  caseManagementTableData,
  activityPost,
  resetReduxData
} from "../../../Methods/CaseManagementMethods";

import { getDateFromTimestamp, getTimeFromTimestamp, convertUTCTimeToLocal } from "../../../Utils/Utils";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import Pagination from "../../../components/Pagination/Pagination";
import { fPostCm, fgetCm, fgetCy, fpostCy } from "../../../axios/axios";
import { filterGen } from "../../../Queries/InvestigationQuery";
import useOnclickOutside from "react-cool-onclickoutside";
import AddFilter from "../../../components/AddFilter/AddFilter";
import CaseManagementExport from "../Export";
import FilterList from "../../../components/FilterList/FilterList";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";
import Search from "../../../components/Search/CaseManagement/Search";



const Case = ({ selectedDateFilter }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [case_id, setCase_ID] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [totalPageCount, setTotalPageCount] = useState()

  const [state, setState] = useState('')


  // loader
  const [tableLoader, setTableLoader] = useState(true)
  // filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [search, setSearch] = useState("");

  const module = 'casemanagement'
  const tab = 'main'

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
  });


  const listCaseManagement_ = useSelector((state) => state.listCaseManagement);
  const { case_, loading: listloading, } = listCaseManagement_;

  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;

  const caseUpdate = useSelector((state) => state.editCaseManagement);
  const { success: caseUpdateSuccess } = caseUpdate;

  const caseDelete = useSelector((state) => state.deleteCaseManagement);
  const { success: caseDeleteSuccess } = caseDelete;



  useEffect(() => {
    // dispatch(listCaseManagement(page, state));
    setTableLoader(true)
    getFilteredTable(filter, dateFilter)
  }, [page, dateFilter, detailShow, search])


  useEffect(() => {
    setPage(1)
    getFilteredTable(filter, dateFilter)
  }, [filter])



  // filter funtion
  const getFilteredTable = (filter, dateFilter) => {
    setTableLoader(true)
    caseManagementTableData(filter, dateFilter, (page - 1) * perPage, perPage, search).then(({ data: { hits } }) => {
      setTotalPageCount(hits?.total?.value)
      setFilteredData(hits?.hits)
      setTableLoader(false)
    }).catch((err) => {
      console.log("Error", err)
    })
  }


  useEffect(() => {




    if (caseCreateSuccess === true) {
      toastsSuccess("Case created successfully")
      setShow(false)
      // setDetailShow(false)
      getFilteredTable(filter, dateFilter)
    }
    setTimeout(() => {
      dispatch(resetReduxData())
    }, 2000);


  }, [caseCreateSuccess])


  useEffect(() => {




    if (caseUpdateSuccess === true) {
      toastsSuccess("Case updated successfully")
      setShow(false)
      // setDetailShow(false)
      getFilteredTable(filter, dateFilter)
    }
    setTimeout(() => {
      dispatch(resetReduxData())
    }, 2000);


  }, [caseUpdateSuccess])

  useEffect(() => {

    if (caseDeleteSuccess === true) {
      toastsDanger("Case Deleted successfully")
      setDetailShow(false)
      getFilteredTable(filter, dateFilter)
      setTimeout(() => {
        dispatch(resetReduxData())
      }, 2000);
    }
  }, [caseDeleteSuccess])


  // edit modal
  const handleEdit = (case_id) => {

    setCase_ID(case_id)
    setShow(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Delete the item?")) {
      dispatch(deleteCaseManagement(id));
    }
  }
  const handleCreate = () => {
    setShow(true);
  };

  // detail modal
  const handleModal = (caseId) => {
    setCase_ID(caseId)
    setDetailShow(true);


  }

  const handleSearchQueryMaster = (search, e, action) => {
    let val = ''
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearch(search)

      setPage(1);
    }

  };





  // function to post and get activities
  const handleActivity = (caseid, action, activity, oldData) => {

    let loggedUserName = localStorage.getItem("loggedUser").split(" ");

    let text = `${loggedUserName[0]} updated ${action} from ${oldData} to ${activity} `

    activityPost(caseid, text);

  }


  return (

    <>
      <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">
        <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
          <h5 className="mb-0">Cases</h5>

          <div
            className="d-flex flex-row align-items-center justify-content-end ml-auto col-8"
            style={styles.filterWrap}
          >

            <div className="d-flex">
              <div className="form-group mx-2">
                {/* <Form.Control
                  type="text"
                  placeholder="search"
                  className=""
                  style={{ width: "" }}


                /> */}
                <Search changeFunction={handleSearchQueryMaster} />
              </div>

              <div className="position-relative">
                <Button
                  className=""
                  ref={ref}
                  onClick={() => { setShowAddFilter(!showAddFilter) }}
                >
                  Add filter
                </Button>
                {showAddFilter && (
                  <AddFilter
                    filter={filter}
                    setFilter={setFilter}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    setShowAddFilter={setShowAddFilter}
                    getFilteredTable={getFilteredTable}
                    columnValues={[
                      { value: "case_id", label: "Case ID", isWildCard: true },
                      { value: "case_title", label: "Case Name", isWildCard: true },
                      { value: "category", label: "Category", isWildCard: true },
                      { value: "state", label: "State", isWildCard: true },
                      { value: "assigned_to", label: "Assigned To", isWildCard: true },
                      { value: "severity", label: "Severity", isWildCard: true }

                    ]}
                    module={module}
                    tab={tab}
                    setPage={setPage}
                  />
                )}
              </div>

              <Button
                className="ml-2 btn btn-light"
                onClick={(e) => handleCreate()}
              >
                New Case
              </Button>

              <div className="position-relative ms-2">
                <CaseManagementExport caseManagementTableData={caseManagementTableData} filter={filter} dateFilter={dateFilter} />
              </div>



              <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                currentPageCount={currentPageCount}
                totalDataCounts={totalPageCount}
              />

            </div>
          </div>
        </div>

        <div className="detections col-12" style={{ zIndex: "0" }}>
          <FilterList
            filter={filter}
            setFilter={setFilter}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            getFilteredTable={getFilteredTable}
            module={module}
            tab={tab}
          />

          <div className="" style={styles.tableWrap}>
            {tableLoader ? (<TableLoader rowCount={20} />) : (
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>Case ID</th>
                    <th>Case Name</th>
                    <th>Created on</th>
                    <th>Created at</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Severity</th>
                    <th className="text-center">Assigned to</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                  {filteredData?.length !== 0 ?
                    filteredData?.map((item) => (
                      <tr key={item?._source?.case_id}>
                        <td className="align-middle font-monospace" >
                          {item?._source?.case_id}


                        </td>
                        <td className="align-middle">{item?._source?.case_title}  </td>

                        <td className="align-middle font-monospace">{getDateFromTimestamp(item?._source?.case_created)}  </td>
                        <td className="align-middle font-monospace">{convertUTCTimeToLocal(item?._source?.case_created)}  </td>
                        <td className="align-middle  ">{item?._source?.category}  </td>
                        <td className="align-middle">
                          {item?._source?.state?.toLowerCase() == "open" && (
                            <Badge bg="warning" className="p-2">Open</Badge>

                          )}
                          {item?._source?.state?.toLowerCase() == "closed" && (
                            <Badge bg="success" className="p-2">Closed</Badge>

                          )}

                        </td>
                        <td className="align-middle">
                          {item?._source?.severity?.toLowerCase() == "critical" && (
                            <Badge bg="danger" className="p-2">Critical</Badge>
                          )}
                          {item?._source?.severity?.toLowerCase() == "high" && (
                            <Badge bg="danger" className="p-2">High</Badge>
                          )}
                          {item?._source?.severity?.toLowerCase() == "medium" && (
                            <Badge bg="warning" className="p-2">Medium</Badge>
                          )}
                          {item?._source?.severity?.toLowerCase() == "low" && (
                            <Badge bg="success" className="p-2">Low</Badge>
                          )}
                        </td>
                        {/* <td className="align-middle">{item?._source?.assigned_to}  </td> */}

                        <td>
                          {item?._source?.assigned_to &&
                            <CustomToolTip content={
                              <span className={"user-icon mx-auto bg-" + (item?._source?.assigned_to).charAt(0).toLowerCase()}>
                                {(item?._source?.assigned_to).charAt(0)}
                              </span>} dataPlacement={"bottom"}
                              dataToggle={
                                <div className="d-flex flex-column text-left">
                                  <div className='d-flex flex-row gap-2 tooltip-row'>
                                    {/* <span>Assigned to - </span> */}
                                    <span><strong>{item?._source?.assigned_to}</strong></span>
                                  </div>
                                </div>
                              } />
                          }
                        </td>

                        <td>
                          <i
                            className="cursor-pointer"
                            onClick={(e) => handleEdit(item?._source?.case_id)}
                          >
                            <TableViewMore Icon={PencilSimple} dataToggle={"Edit case"} dataPlacement={"bottom"} />
                          </i>  &nbsp;&nbsp;
                          <i className="cursor-pointer" onClick={(e) => handleDelete(item?._source?.case_id)}>
                            <TableViewMore Icon={X} dataToggle={"Delete case"} dataPlacement={"bottom"} />
                          </i>  &nbsp;&nbsp;
                          <Link onClick={(e) => handleModal(item?._source?.case_id)}>
                            <TableViewMore Icon={ArrowRight} dataToggle={"View details"} dataPlacement={"bottom"} />
                          </Link>
                        </td>
                      </tr>
                    )) : (
                      <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} />
                      </div>
                    )}
                </tbody>
              </Table>
            )}
          </div>
        </div>

      </div>

      {
        show === true && (
          <EditModal
            setShow={setShow}
            show={show}
            autoHeight={false}
            caseidEdit={case_id}
            setCase_ID={setCase_ID}
            handleActivity={handleActivity}
            module={module}

          />
        )}
      {
        detailShow === true && (
          <DetailModal
            setDetailShow={setDetailShow}
            detailShow={detailShow}
            caseid={case_id}
            setCase_ID={setCase_ID}
            filteredData={filteredData}
            handleActivity={handleActivity}
          />
        )}
    </>
  );
}


export default Case;
