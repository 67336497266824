import React, { useEffect, useState } from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../Loader/Loader";

import { dateDMY, removeHtmlChars } from "../../../../Utils/Utils";

import {
  getComplianceSelectList,
  customCompliance,
  getExpectedOutputCustomInv, getCompCatalogueDetails,
  getCompCatalogueDetailsQuery
} from "../../../../Methods/RuleStudioMethods";
import CustomComplianceInfo from "./CustomComplianceInfo";
import { Button, Form } from "react-bootstrap";


const CompDetailModal = ({ setDetailShow, detailShow,
  setSubmitLoader,
  submitLoader, comIdEdit, setComId }) => {
  const dispatch = useDispatch();

  const [selectedList, setList] = useState([]);
  const [complianceId, setComplianceID] = useState("COM-");
  const [complianceName, setComplianceName] = useState("");
  const [description, setDescription] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");
  const [emailbody, setEmailBody] = useState("");
  const [email, setEmail] = useState("");
  const [iso, setISO] = useState("");
  const [nist, setNIST] = useState("");
  const [pci, setPCI] = useState("");
  const [extractValues, setExtractValues] = useState("");
  const [query, setQuery] = useState("");
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);
  const [com_created, setComCreated] = useState(dateDMY(new Date()))
  const [com_last_edited, setComLastEdited] = useState(dateDMY(new Date()))


  const [inputValue, setInputValue] = useState('');
  const [isValidJson, setIsValidJson] = useState(true);

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;



  const detailsCustomRsComp = useSelector((state) => state.detailsCustomRsComp);
  const { catalogue, loading: loading } = detailsCustomRsComp;

  // const detailsCustomQueryRsComp = useSelector((state) => state.detailsCustomQueryRsComp);
  // const { catalogueQuery } = detailsCustomQueryRsComp;
  const detailsCustomQueryRsIn = useSelector((state) => state.detailsCustomQueryRsIn);
  const { catalogueQuery } = detailsCustomQueryRsIn;



  useEffect(() => {

    dispatch(getExpectedOutputCustomInv());
    dispatch(getComplianceSelectList());
  }, []);

  const expectedOutput = output?.expected_values?.map((output) => {
    return { value: output.value, label: output.text };
  });




  const styles = {
    footerWrap: {
      position: "sticky",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      background: "#FFFFFF"
    }
  };


  const setDataInEditList = () => {



    let expOutData = catalogue?.extract_values?.map((data) => {
      return { "value": data, 'label': data }
    })

    const queryData = catalogue?.query
    delete queryData?.extract_values_from_query_output





    setComplianceID(comIdEdit === '' ? '' : catalogue?.com_id);

    setComplianceName(comIdEdit === '' ? '' : catalogue?.com_name);
    setDescription(comIdEdit === '' ? '' : catalogue?.com_description);
    setEmail(comIdEdit === '' ? '' : catalogue?.email_to);
    setEmailBody(comIdEdit === '' ? '' : catalogue?.email_body);
    setISO(comIdEdit === '' ? '' : catalogue?.ISO_27001);
    setNIST(comIdEdit === '' ? '' : catalogue?.NIST);
    setPCI(comIdEdit === '' ? '' : catalogue?.PCI_DSS);
    setExtractValues(comIdEdit === '' ? '' : catalogue?.extract_values);
    setQuery(comIdEdit === '' ? '' : JSON.stringify(queryData));
    setExpectedOutput(comIdEdit === '' ? '' : expOutData)
    setComCreated(comIdEdit === '' ? '' : catalogue?.com_created);
    setComLastEdited(comIdEdit === '' ? '' : dateDMY(new Date()));

  };

  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());

    if (
      (comIdEdit || Object.keys(catalogue).length === 0) &&
      comIdEdit != catalogue?.com_id && comIdEdit != ''
    ) {
      dispatch(getCompCatalogueDetails(comIdEdit));

    }

    if (comIdEdit != '') {
      setDataInEditList();
    } else {
      setComplianceID("COM-");
      setComplianceName("");
      setDescription("");
      setEmail("");
      setEmailBody("");
      setISO("");
      setNIST("");
      setPCI();
      setExpectedOutput([]);
      setQuery('');

    }

  }, [comIdEdit, catalogue, detailShow, catalogueQuery]);


  const onSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to save changes?")) {

      setSubmitLoader(true)

      let extractValuesData = selectedExpectedOutput?.map((data) => {
        return data.value
      })


      let data = {
        com_id: complianceId,
        com_name: complianceName,
        com_description: description,
        PCI_DSS: pci,
        NIST: nist,
        ISO_27001: iso,
        email_body: emailbody,
        email_to: email,
        extract_values: extractValuesData,
        com_created: com_created,
        com_last_edited: com_last_edited,
        query

      };



      dispatch(customCompliance(data));





    }



  };

  const handleMultiChange = (selectedExpectedOutput) => {
    setExpectedOutput(selectedExpectedOutput);
  };

  const handleClose = () => {
    setDetailShow(false);
  };

  const handleQuery = (val) => {

    setQuery(val.replace(/\s/g, "").replace(/"/g, "'"))
    setInputValue(val);
  
      // try {
      //   JSON.parse(val);
      //   setIsValidJson(true);
      // } catch (error) {
      //   setIsValidJson(false);
      // }
  }

  const removeHtmlTags = (text) => {
          return text?.replace(/<[^>]*>/g, '');
      };

  const renderModal = () => (
    < >
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
        <span className="was-validated" style={{ overflowY: 'auto' }}>
          <div className="modal-row-item">
                    <span className="modal-label col-2">Compliance ID</span>
                    <span className="modal-text ">{complianceId}</span>
          </div>
          <div className="modal-row-item">
                    <span className="modal-label col-2">Compliance name</span>
                    <span className="modal-text ">{complianceName}</span>
          </div> 
          {description &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Description</span>
                    <span className="modal-text ">
                      {removeHtmlTags(description)}
            </span>
          </div>  
          )}  
          {pci &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">PCI_DSS</span>
                    <span className="modal-text ">
                      {pci}
            </span>
          </div>  
          )} 
          {nist &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">NIST</span>
                    <span className="modal-text ">
                      {nist}
            </span>
          </div>  
          )} 
          {iso &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">ISO_27001</span>
                    <span className="modal-text ">
                      {iso}
            </span>
          </div>  
          )} 
          {emailbody &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Email Body</span>
                    <span className="modal-text ">
                      {emailbody}
            </span>
          </div>  
          )} 
          {email &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">Email To</span>
                    <span className="modal-text ">
                      {email}
            </span>
          </div>  
          )} 
           {selectedExpectedOutput &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">EXTRACT VALUES</span>
                    <span className="modal-text "> 
                      {Array.isArray(selectedExpectedOutput) ? selectedExpectedOutput?.map(output => output?.label).join(', ') : selectedExpectedOutput?.label}
                    </span>                 
                  </div>  
                   )}
          {email &&(
                  <div className="modal-row-item">
                    <span className="modal-label col-2">QUERY</span>
                    <span className="modal-text ">
                      {query}
            </span>
          </div>  
          )} 

          </span>
      )
      }
    </>
  );


  return (
    <>
      {" "}
      {detailShow === true && (
        <CustomModal
          title={ 'Detail Compliance'}
          ShowOrHide={detailShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          modalSize={'lg'}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}{" "}
    </>
  );
};

export default CompDetailModal;
