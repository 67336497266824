import React from 'react'
import iconNotfound from "../../assets/images/no_data.png";
import icon404 from "../../assets/images/error-404.png";
import icon500 from "../../assets/images/error-500.png";

const NoDataFound = ({ errorText, errorData }) => {


    console.log("Error DATA :", errorData)

    return (
        <div className="d-flex flex-column col-12 h-100 align-items-center justify-content-center">
            {errorData ? (
                <>
                    {errorData?.status === 400 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Bad Request | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 401 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Unauthorized | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 403 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Access forbidden | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 404 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Not Found | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 405 &&
                        <>
                            {/* <img src={icon404} style={{ width: "7rem" }} /> */}
                            <p>{"Method Not Allowed| " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 408 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Request timeout | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 415 &&
                        <>
                            <img src={icon404} style={{ width: "7rem" }} />
                            <p>{"Unsupported media type | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 500 &&
                        <>
                            <img src={icon500} style={{ width: "7rem" }} />
                            <p>{"Internal Server Error | " + errorData?.status}</p>
                        </>
                    }
                    {errorData?.status === 503 &&
                        <>
                            <img src={icon500} style={{ width: "7rem" }} />
                            <p>{"Service Unavailable | " + errorData?.status}</p>
                        </>
                    }

                    {(errorData?.status > 503 || errorData?.status < 400) &&
                        <>
                            <img src={icon500} style={{ width: "7rem" }} />
                            <p>{"Service Unavailable | " + errorData?.status}</p>
                        </>
                    }
                </>
            ) : (
                <>
                    <img src={iconNotfound} style={{ width: "7rem" }} />
                    <p style={{ textAlign: "center", color: "#999999", fontSize: "1rem", fontWeight: "400" }}>
                        {errorText ? errorText : "No data found"}
                    </p>
                </>
            )
            }

        </div >
    )
}

export default NoDataFound