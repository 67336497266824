export default {
  black: "#000000",
  white: "#FFFFFF",
  blueLite1: "#DBEEFF",
  blueLite2: "#47A8FF",
  greyLite1: "#f1f1f1",
  greyLite2: "#F6F7FB",
  greyDark1: "#16171B",
  greyDark2: "#666666",
  greyDark3: "#999999",
  orangeLite1: "#FFECE7",
  orangeDark1: "#FF6633",
  purpleLite1: "#DEE4FF",
  purpleDark1: "#6584FF",
  greenLite1: "#DAFFDF",
  greenDark1: "#44BC53",
  red: "#ef5350",
  redLite1: "#f8d7da",
  yellowLite1: "#fff3cd",
  yellowDark1: "#ecc24a",
  green: "#00C853",
  green2: "#5D9C59",
};
