import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TableViewMore = ({ Icon, size, dataToggle, dataPlacement, color, weight, onClick }) => {
  return (
    <>
      {dataToggle ? (
        <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Tooltip id={`tooltip-${dataPlacement}`}>
              {dataToggle}
            </Tooltip>
          }
        >
          <Icon style={{ color: color ? color : "#666666" }} size={size ? size : 18} weight={weight} onClick={onClick} />
        </OverlayTrigger>
      ) : (
        <Icon style={{ color: "#666666" }} size={size} />
      )}

    </>
  );
};

export default TableViewMore;
