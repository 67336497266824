let edr = localStorage.getItem("EDR_TYPE");
export const getCykitDocQuery = (doc_id,index_type) =>
{
  if(edr==="elastic")
    {
      var data = {
    "query": {
        "bool": {
            "must":[
                    
                ],
            "filter": {
                "term": {
                    "_id":doc_id 
                }
            }
        }
    },
}

if(index_type){
  data.query.bool.must.push(
    {
      "term": {
          "event.category": index_type
      }
  }
  )
}
}
else if (edr==="atp"){
  var data = {
"query": {
    "bool": {
        "must":[
                {
                    "term": {
                        "category": index_type
                    }
                }
            ],
        "filter": {
            "term": {
                "_id":doc_id 
            }
        }
    }
},
}

if(index_type){
  data.query.bool.must.push(
    {
      "term": {
          "category": index_type
      }
  }
  )
}
}
  return data
}

export const getMultiQuery = (host_name,time,pid,category,entity_id) =>
{
  if(edr==="atp"){
    var data = JSON.stringify(
      {
        "query": {
            "bool": {
                "must": [
                  {
                    "bool":{
                      "should":[
                        {
                          "match": {
                              "initiating.processcreationtime": time
                          }
                      },
                      {
                        "match": {
                            "properties.InitiatingProcessCreationTime": time
                        }
                    },
                      ]
                    }
                  },
                    {
                        "term": {
                            "process.pid": pid
                        }
                    },
                    {
                      "term": {
                        "host.name": host_name
                      }
                    },
                    {
                        "term": {
                          "category": category
                        }
                      }
                ],
                
            }
        },
        size:200
    }
    );
  }
  else if(edr==="elastic"){
    var data = JSON.stringify(
      {
        "query": {
            "bool": {
                "must": [
                    {
                        "term": {
                            "process.entity_id": entity_id
                        }
                    },
                    {
                      "term": {
                        "host.name": host_name
                      }
                    },
                    {
                        "term": {
                          "event.category": category
                        }
                      }
                ],
                
            }
        },
        size:200
    }
    );
    if(category==="network"){
      data = JSON.stringify(
        {
          "query": {
              "bool": {
                  "must": [
                      {
                          "term": {
                              "process.entity_id": entity_id
                          }
                      },
                      {
                        "term": {
                          "host.name": host_name
                        }
                      },
                      {
                          "term": {
                            "event.category": category
                          }
                        }
                  ],
                  "filter":[
                    {
                      exists: {
                        field: "destination",
                      },
                    }
                  ]
                  
              }
          },
          size:200
      }
      );
    }
  }
  
      return data
}



export const getProcessQuery = (node,direction) =>
{
  if(edr==="atp"){
    if(direction==="forward"){
        var data = JSON.stringify(
            {
              "query": {
                "bool": {
                  "must": [
                    {
                      "bool":{
                        "should":[
                    {
                      "match": {
                        "initiating.processparent.creationTime": node.p_creation_time
                      }
                    },
                    {
                      "match": {
                        "Initiating.processparent.creationTime": node.p_creation_time
                      }
                    },
                    {
                      "match": {
                        "properties.InitiatingProcessParentCreationTime": node.p_creation_time
                      }
                    },
                  ]
                      }
                    },
                    {
                      "term": {
                        "process.parent.pid": node.pid
                      }
                    },
                    {
                      "term": {
                        "host.name": node.host_name
                      }
                    },
    
                  ],
                  
                }
              },
              "size":0,
              "aggs": {
                "unique_field_name": {
                  "terms": {
                    "field": "process.name",
                    "size": 10
                  },
                  "aggs": {
                    "all_fields": {
                      "top_hits": {
                        "size": 1
                      }
                    }
                  }
                }
              }
            }
        );
      }
      else if(direction==="backward"){
        var data = JSON.stringify(
          {
            "query": {
                "bool": {
                    "must": [
                      {
                        "bool":{
                          "should":[
                            {
                              "match": {
                                  "initiating.processcreationtime": node.parent_creation_time
                              }
                          },
                          {
                            "match": {
                                "properties.InitiatingProcessCreationTime": node.parent_creation_time
                            }
                        },
                          ]
                        }
                      },
                        {
                            "term": {
                                "process.pid": node.p_pid
                            }
                        },
                        {
                          "term": {
                            "host.name": node.host_name
                          }
                        },
                    ],
                    
                }
            }
        }
        );
      }
          return data
    }
    else if(edr==="elastic"){
      if(direction==="forward"){
      
        var data =
            {
              "query": {
                "bool": {
                  "must": [
                    {
                      "script": {
                        "script": {
                          "source": "doc['process.Ext.ancestry'].length == params.length",
                          "params": {
                            "length": node.parents?.length+1
                          }
                        }
                      }
                    },
                    {
                      "term": {
                        "host.name": node.host_name
                      }
                    },
                  ]
                      }
                    },
              "size":0,
              "aggs": {
                "unique_field_name": {
                  "terms": {
                    "field": "process.name",
                    "size": 10
                  },
                  "aggs": {
                    "all_fields": {
                      "top_hits": {
                        "size": 1
                      }
                    }
                  }
                }
              }
            }
        if(node?.parents?.length>0)
        {
          for (var parent of (node?.parents)){
          var must ={
            "match":{
            }
          } 
          must.match["process.Ext.ancestry"]= parent
          data.query.bool.must.push(must)
        }
      
        var must ={
          "match":{
          }
        } 
        must.match["process.Ext.ancestry"]= node.process_id
        data.query.bool.must.push(must)
        }
      }
      else if(direction==="backward"){
        if (node?.parents?.length>0)
        {var data = JSON.stringify(
          {
            "query": {
                "bool": {
                    "must": [
                            {
                              "term": {
                                  "process.entity_id": node.parents[0]
                              }
                          },
                          {
                            "term": {
                              "host.name": node.host_name
                            }
                          }
                    ],
                    
                }
            }
        }
        );
      }
      else{
        var data = JSON.stringify(
          {
            "query": {
                "bool": {
                    "must": [
                          {
                            "term": {
                              "host.name": node.host_name
                            }
                          }
                    ],
                    
                }
            }
        }
        )
      }
      }
      return data
    }

}
