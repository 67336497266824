import axios from "../axios";

import { fpost, fget,fpostC } from "../axios/axios";
import {
  communityEventsListQuery,
  getHeadlinesQuery,
} from "../Queries/TiQueries";

let baseURL = `${localStorage.getItem("apiBaseUrl")}/ti/platform`;
let baseURLCy = localStorage.getItem("CYBOT_URL")

// TI Headlines

export async function getHeadlines(count, sort, page, search, timeFrame) {
  let data = getHeadlinesQuery(count, sort, page, search, timeFrame);

  return await fpost(
    `${baseURL}/misp_event_data/_search?pretty&track_total_hits=true`,
    data
  );
}

export async function getEventDetails() {
  let data = communityEventsListQuery();

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}


export async function getTenant(){
  let data = {
      "query": {
        "match_all": {}
      }
    }
return await fpostC(`${baseURLCy}/cybot_proxy/thp_license*/_search`, data)
}