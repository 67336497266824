import React, { useEffect, useState } from "react";
import {
  getTotalHuntDetectedQuery,
  getTotalHuntAlertQuery,
  getSeverityCountsQuery,
  getDetectionDataTableQuery,
  getDetectionTimelineQuery,
  groupedDetectionDetailsQuery,
  getHuntStatusQuery,
  getShowAllTimeQuery,
  getShowDateQuery,
  getShowStatusQuery,
  getExecutionQuery,
  getTotalCountQuery,
  getAckCountQuery

} from "../Queries/HunterQuery";
import { fpostC, fget } from "../axios/axios";
const baseURL = localStorage.getItem("CYBOT_URL");
const apiBaseUrl = localStorage.getItem("apiBaseUrl")
export async function getTotalHuntDetectedCount() {
  let data = getTotalHuntDetectedQuery();
  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_observables*/_search?track_total_hits=true`,
    data
  );
}

export async function getHuntStatus(id) {
  let statusData = getHuntStatusQuery(id);
  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_status*/_search?track_total_hits=true`,
    statusData
  );
}

export async function getTotalHuntAlertCount() {
  let data = getTotalHuntAlertQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_observables*/_search?track_total_hits=true`,
    data
  );
}

export async function getSeverityCounts() {
  let data = getSeverityCountsQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_observables*/_search?track_total_hits=true`,
    data
  );
}

export async function getDetectionTableData(count, order, page) {
  let data = getDetectionDataTableQuery(count, order, page);

  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_observables*/_search?track_total_hits=true`,
    // `https://analyticsapi.active-bytes.com/hunt/getcatalogue?type=tenant`,
    data
  );
}

export async function getDetectionDetails(id) {
  // console.log("lod id:",id);
  return await fget(
    // `https://analyticsapi.active-bytes.com/hunt/gethuntinfo?huntid=${id}`
    `${apiBaseUrl}/hunter/hunt/info?huntid=${id}`
  );
}

export async function getDetectionTimelineDatas(id) {
  let data = getDetectionTimelineQuery(id);

  return await fpostC(`${baseURL}/cybot_proxy/hunt_group*/_search`, data);
}

export async function getGroupedDetectionDetails(group_id, type) {
  let data = groupedDetectionDetailsQuery(group_id, type);

  if (type === "hunter") {
    return await fpostC(`${baseURL}/cybot_proxy/hunt_enriched*/_search`, data);
  } else {
    return await fpostC(
      `${baseURL}/cybot_proxy/thp_false_positive*/_search`,
      data
    );
  }
}


export async function getExecutionData(id) {
  let data = getExecutionQuery(id)

  return await fpostC(`${baseURL}/cybot_proxy/hunt_observables*/_search?track_total_hits=true`, data);
}

export async function getUpdateDetectionNumberData(tenandId, _id, formattedTime, AccData) {
  let data = { "doc": { "new_detections": "0", "last_acknowledged_time": formattedTime, 'checkpoint': AccData?._source?.timestamp } }
  return await fpostC(`${baseURL}/cybot_proxy/${tenandId}/_update/${_id}`, data);
}

// new feature- show all time

export async function getShowAllTime(id) {
  let allData = getShowAllTimeQuery(id);
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    allData
  );
}


export async function getDateFilter(id, filter, date, count, sort, page) {
  let allData = getShowDateQuery(id, filter, date, count, sort, page);
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    allData
  );
}


export async function getStatusFilter(id, filter, date, count, sort, page) {
  let allData = getShowStatusQuery(id, filter, date, count, sort, page);
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    allData
  );
}

export async function getCount(count, sizee) {
  console.log("COUNt", count);
  let allCount = getTotalCountQuery(count, sizee);
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    allCount
  );
}

export async function getAckCount(count, time, sizee) {
  console.log("COUNTTTT", count, time);
  let allCount = getAckCountQuery(count, time?.gte, time?.lte, sizee);
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    allCount
  );
}
