// CASE MANAGEMENT --- LIST
export const CM_LIST_REQUEST = "CM_LIST_REQUEST";
export const CM_LIST_SUCCESS = "CM_LIST_SUCCESS";
export const CM_LIST_FAIL = "CM_LIST_FAIL";

// CASE MANAGEMENT -- CREATE
export const CM_CREATE_REQUEST = "CM_CREATE_REQUEST";
export const CM_CREATE_SUCCESS = "CM_CREATE_SUCCESS";
export const CM_CREATE_FAIL = "CM_CREATE_FAIL";
export const CM_CREATE_RESET = "CM_CREATE_RESET";


// CASE MANAGEMENT --- EDIT 
export const CM_EDIT_REQUEST = "CM_EDIT_REQUEST";
export const CM_EDIT_SUCCESS = "CM_EDIT_SUCCESS";
export const CM_EDIT_FAIL = "CM_EDIT_FAIL";
export const CM_EDIT_RESET = "CM_EDIT_RESET";


// CASE MANAGEMENT --- DELETE 
export const CM_DELETE_REQUEST = "CM_DELETE_REQUEST";
export const CM_DELETE_SUCCESS = "CM_DELETE_SUCCESS";
export const CM_DELETE_FAIL = "CM_DELETE_FAIL";
export const CM_DELETE_RESET = "CM_DELETE_RESET";

// CASE MANAGEMENT --- SINGLE LIST VIEW 
export const CM_SINGLE_LIST_REQUEST = "CM_SINGLE_LIST_REQUEST ";
export const CM_SINGLE_LIST_SUCCESS = "CM_SINGLE_LIST_SUCCESS";
export const CM_SINGLE_LIST_FAIL = "CM_SINGLE_LIST_FAIL";







