import React, { useEffect, useState, useRef } from "react";
import * as htmlToImage from 'html-to-image';
import { useDispatch, useSelector } from "react-redux";
import {
  getIndicatorsTable,
  communityEventsDetail,
} from "../../Methods/TiMethods";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Table } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import DetailCommunity from "../PDF/Ti/DetailCommunity";
import EditModal from "../CaseManagement/EditModal";
import AlertBox from "../AlertBox/AlertBox";

ChartJS.register(ArcElement, Tooltip, Legend);

const CommunityView = ({ eventDetails, useThirdParty }) => {
  const id = eventDetails.event_id

  const divRefImage = useRef(null);

  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;

  const [tableData, setTableData] = useState([]);
  const [imageBlob, setImageBlob] = useState(null);

  const [Labels, setLabels] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(true);

  // Add Case
  const [caseShow, setCaseShow] = useState(false)
  const [case_id, setCase_ID] = useState("");

  const module = 'ti'
  const tab = 'community'

  // case modal

  const [alertCasePopup, setAlertCasePopup] = useState(false)

  const communityChart = {
    labels: Labels,
    datasets: [
      {
        label: "Community Feeds",
        data: dataChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  useEffect(() => {
    // details
    // communityEventsDetail(id)
    //   .then(
    //     ({
    //       data: {
    //         hits: { hits },
    //       },
    //     }) => {
    //       console.log(hits);
    //       setEventDetails(hits[0]);
    //     }
    //   )
    //   .catch((err) => {
    //     console.log("Error", err);
    //   });

    getIndicatorsTable(id, useThirdParty)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
            hits: { hits },
          },
        }) => {
          let label = [];
          let data = [];
          if (buckets.length > 0) {
            buckets.map((item) => {
              label.push(item.key);
              data.push(item.doc_count);
              setLabels(label);
              setDataChart(data);
            });
          }
          setTableData(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

  }, []);

  useEffect(() => {
    setShowDownloadButton(false)
    const timeoutId = setTimeout(() => {
      // Code to be executed after the timeout
      // convertToBlob()
      setShowDownloadButton(true)

    }, 1000); // Timeout duration in milliseconds (2 seconds)

    return () => {
      clearTimeout(timeoutId); // Clean up the timeout on component unmount
    };

  }, [])

   // case
   useEffect(() => {

    if (caseCreateSuccess === true) {

      setCaseShow(false)
      setAlertCasePopup(true)

    }

  }, [caseCreateSuccess])



  const currentTimestamp = Date.now();

  const convertToBlob = () => {

    htmlToImage.toBlob(divRefImage.current).then((blob) => {

      setImageBlob(blob);
      setShowDownloadButton(true)
    });
  };

  const hasHTMLTags = str => {
    return /<[^>]*>/g.test(str);
  };

  // Add Case
  const handleCreate = () => {
    setCaseShow(true);
  };



  return (
    <div className="container">

      {/* <h2>Event Details</h2> */}
      <div className="d-grid d-md-flex justify-content-md-end mb-3 sticky-top pt-4">
       <button className="btn btn-light"
              onClick={(e) => handleCreate()}
            >Create Case
        </button>
        &nbsp;&nbsp;&nbsp;

        {showDownloadButton === true ?
          <PDFDownloadLink document={<DetailCommunity detail={eventDetails} tableData={tableData} chartData={communityChart} imageBlob={imageBlob} />} fileName={`indicators-feeds-${currentTimestamp}.pdf`}>
            {({ blob, url, loading, error }) =>
              (<button className="form-control btn btn-primary">Download PDF</button>)
            }
          </PDFDownloadLink>
          : <button className="btn btn-primary" disabled>Export as PDF</button>
        }
      </div>

      <div className="modal-row-item">
        <span className="modal-label col-3">Event name</span>
        <span className="modal-text">{eventDetails?.event_name}</span>
      </div>

      <div className="modal-row-item">
        <span className="modal-label col-3">Severity</span>
        <span className="modal-text">
          {eventDetails?.severity?.toLowerCase() == "critical" && (
            <div className="p-2 m-0 alert alert-danger">Critical</div>
          )}
          {eventDetails?.severity?.toLowerCase() == "high" && (
            <div className="p-2 m-0 alert alert-danger">High</div>
          )}
          {eventDetails?.severity?.toLowerCase() == "medium" && (
            <div className="p-2 m-0 alert alert-warning">Medium</div>
          )}
          {eventDetails?.severity?.toLowerCase() == "low" && (
            <div className="p-2 m-0 alert alert-success">Low</div>
          )}
        </span>
      </div>
      <div className="modal-row-item">
        <span className="modal-label col-3">Event ID</span>
        <span className="modal-text">{eventDetails?.event_id}</span>
      </div>
      <div className="modal-row-item">
        <span className="modal-label col-3">Feed</span>
        <span className="modal-text">{eventDetails?.feed_name}</span>
      </div>
      <div className="modal-row-item">
        <span className="modal-label col-3">Description</span>
        <span className="modal-text">
          {!hasHTMLTags(eventDetails?.event_description) && eventDetails?.event_description}
        </span>
      </div>
      {/* {eventDetails?.all_fields?.hits?.hits[0]?._source.reference_link && (
        <div className="modal-row-item">
          <span className="modal-label col-3">Reference links</span>
          <span className="modal-text">
            <a href={eventDetails?.all_fields.hits.hits[0]._source.reference_link} target="_blank">
              {eventDetails?.all_fields.hits.hits[0]._source.reference_link}
            </a>
          </span>
        </div>
      )} */}

      <div className="modal-row-item">
        <span className="modal-label col-3">Reference</span>
        <span className="modal-text">
          {eventDetails?.reference_link && (
            <div className="reference-link">
              <a href={eventDetails?.reference_link} target="_blank">
                Click Here
              </a>
            </div>
          )}
        </span>
      </div>

      {/* <div className="event-details-container">
        <div className="r3">
        </div>
      </div> */}

      {!useThirdParty && <div className="col-12 d-flex align-items-center justify-content-center border rounded p-4 my-2" ref={divRefImage}>
        <div
          style={{ width: "300px", height: "300px" }}
          className="ioc-type-chart"
        >
          <Doughnut data={communityChart} />
        </div>
      </div>}
      <div className="col-12 d-flex align-items-center justify-content-center border rounded" >
        {tableData && (
          <Table borderless hover  >
            <thead>
              <tr>
                <th>IOC Value</th>
                <th>{useThirdParty ? "IOC Class" : "IOC Type"}</th>
                {/* <th>IOC ID</th> */}
              </tr>
            </thead>
            <tbody style={{ height: "300px" }}>
              {tableData.map(
                ({
                  _source: { attribute_value, attribute_type, attribute_id, attribute_class },
                }) => (
                  // attributeLength(attribute_value),

                  <tr>
                    <td>{attribute_value.length <= 50 ? attribute_value :
                      <p data-toggle="tooltip" data-placement="bottom" title={attribute_value}>
                        {attribute_value.slice(0, 20)}&nbsp;...
                      </p>
                    }</td>
                    <td>{useThirdParty ? attribute_class : attribute_type}</td>
                    {/* <td>{attribute_id}</td> */}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </div>


      {
        caseShow && (
          <EditModal
            setShow={setCaseShow}
            show={caseShow}
            autoHeight={false}
            caseidEdit={case_id}
            setCase_ID={setCase_ID}
            id={eventDetails?.event_name}
            idd={eventDetails?.event_name}
            module={module}
            tab={tab}
            preModule={"TI_FEEDS"}
            description_={!hasHTMLTags(eventDetails?.event_description) && eventDetails?.event_description}
            severity_={eventDetails?.severity}


          />
        )
      }

      {alertCasePopup && (
        <AlertBox setAlertPopup={setAlertCasePopup} alertPopup={alertCasePopup} message={"Case created successfully"} bgColor={"lightGreen"} />
      )}


    </div>
  );
};

export default CommunityView;
