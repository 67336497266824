import React, { useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import AddFilter from "../AddFilter/AddFilter";
import useOnclickOutside from "react-cool-onclickoutside";
import { Link } from "react-router-dom";
import { ArrowsInSimple, ArrowsOutSimple, CaretDown, CaretUp, Info } from "phosphor-react";
import TableViewMore from "../ViewMoreBtn/TableViewMore";
import { isJSONObject } from "../../Utils/Utils";

const DataTable = ({
  tableHead,
  tableData,
  title,
  description,
  pagination,
  isFilter,
  link,
  expandBtn
}) => {

  // states for add filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);

  const [expand, setExpand] = useState(false)

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
  });


  function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
  }

  // function export_table_to_csv(html, filename) {
  //   var csv = [];
  //   var rows = document.querySelectorAll(`.${title?.split(" ")?.join("-")} table tr`);

  //   for (var i = 0; i < rows.length; i++) {
  //     var row = [], cols = rows[i].querySelectorAll("td, th");

  //     for (var j = 0; j < cols.length; j++)
  //       row.push(cols[j].innerText);

  //     csv.push(row.join(","));
  //   }

  // new csv download except file/process hash 
  function export_table_to_csv(html, filename) {
    var csv = [];
    var rows = document.querySelectorAll(`.${title?.split(" ")?.join("-")} table tr`);

    // Specify the column name to exclude
    const columnToExclude = "file_hash";

    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        // Skip the column with the specified name in both th and td
        if (tableHead[j]?.value !== columnToExclude) {
          row.push(cols[j].innerText);
        }
      }

      csv.push(row.join(","));
    }


    // Download CSV 
    download_csv(csv.join("\n"), filename);
  }

  const handleDownloadCsv = () => {
    var html = document.querySelector("table").outerHTML;
    export_table_to_csv(html, `${title}.csv`);
  }


  return (
    <div className="d-flex flex-column position-relative">
      <div className="d-flex justify-content-between">
        <div>
          {/* <h4>{title}</h4> */}
          <p style={{ maxWidth: "1000px" }}>{description}</p>
        </div>

        {expand && (
          <div className="pagination-container d-flex mb-2">
            {/* {tableData.length && (
            // <span style={{ position: "absolute", padding: '0.4rem 1rem', background: tableData.length > 0 ? "green" : "red", color: "white", left: "-30px", top: "-50px", borderRadius: "25px" }}>{tableData.length}</span>
            <Badge className="ml-2" bg="info">
              {tableData.length}
            </Badge>
          )} */}
            <Button variant="light" onClick={() => handleDownloadCsv()}>Download CSV</Button>

            {isFilter && (
              <div className="position-relative">
                <Button
                  // className="position-relative"
                  ref={ref}
                  onClick={() => setShowAddFilter(!showAddFilter)}
                >
                  Add filter
                </Button>
                {showAddFilter && (
                  <AddFilter
                    filter={filter}
                    setFilter={setFilter}
                    setShowAddFilter={setShowAddFilter}
                  />
                )}
              </div>
            )}

            {/* {pagination && (
            <Pagination
            // page={page}
            // setPage={setPage}
            // perPage={perPage}
            // currentPageCount={currentPageCount}
            />
          )} */}
          </div>
        )}
      </div>


      <span style={{ display: "flex", justifyContent: "center", color: "#808080", cursor: "pointer", padding: "0.5rem" }} onClick={() => setExpand(!expand)}>
        {/* <TableViewMore Icon={expand ? ArrowsInSimple : ArrowsOutSimple} dataToggle={expand ? "collapse document" : "Expand document"} dataPlacement={"bottom"} /> */}
        <TableViewMore Icon={!expand ? CaretDown : CaretUp} size={24} dataPlacement={"bottom"} />
      </span>

      {expand && (
        <div className={`col-12 overflow-auto ${title?.split(" ")?.join("-")}`} style={{ height: '800px' }}>
          <Table className="table" borderless hover style={{ width: '100%' }} >
            <thead>
              <tr>
                {tableHead?.map((item) => (
                  <th>{item.label}</th>
                ))}
                {/* {link && (
                  link.map(() => (
                    // console.log("TESTTT TD :", link)
                    // <th>Reputation check</th>
                    <th></th>
                  ))
                )} */}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr>
                  {tableHead?.map((head) => (
                    <td className={head.isMonospace ? "font-monospace text-break" : "text-break"} style={{ minWidth: head.isLongText ? "300px" : "" }}>
                      {isJSONObject(item[head.value]) ? Object.entries(item[head.value]).map(([key, value]) => {
                        return (
                          <Badge>{key} - {value}</Badge>
                        )
                      }) : head.value === "file_hash" ?
                        (link && (
                          <>
                            {link?.map((urls) => (
                              item?.[urls?.name] !== undefined && item?.[urls?.name] !== "initiating process hash not found" && item?.[urls?.name] !== "process hash not found" && (
                                <td key={urls?.name} style={{ textAlign: 'center' }}>
                                  <Link to={`${urls?.url}${item?.[urls?.name]}`} target="_blank">
                                    {/* {console.log("urls?.name", item?.[urls?.name])} */}
                                    <TableViewMore Icon={Info} size={16} dataToggle={`Reputation of ${urls?.label}`} dataPlacement={"bottom"} />
                                  </Link>
                                </td>
                              )
                            ))}
                          </>
                        ))
                        : item[head.value]}
                    </td>
                  ))}

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}



    </div>
  );
};

export default DataTable;
