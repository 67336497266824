import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "../Loader/Loader.css";

const SpinnerLoader = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.isLoading !== undefined) {

            if (props.isLoading === true) {
                setIsLoading(true)
            }
            else {
                setIsLoading(false)
            }
        }
    }, [props.isLoading])
    return (
        <div>
            {isLoading &&
                <div
                    className="loader-spinner"
                // style={{
                //     position: "absolute",
                //     top: "50%",
                //     left: "40%",
                // }}
                >
                    <Spinner />
                </div>}
        </div>

    );
};

export default SpinnerLoader;
