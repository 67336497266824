import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { CaretDoubleRight } from "phosphor-react";

export default function CheckBox({ data, index, selectedInputs, selected, setSelected, expectedOutput, setData, setModules }) {

  const [isChecked, setIsChecked] = useState(false)
  const [dataIsAvailable, setDataAvailable] = useState()

  useEffect(() => {


    const isChecked_ = selectedInputs?.some((element) =>
      element.hasOwnProperty(data.value)
    );
    setDataAvailable(isChecked_)

    if (isChecked_ === true) {
      setIsChecked(true)
    }

  }, [])






  const onChange = (object) => {

    setData(data);


    setModules(object);

  };

  const handleCheckboxChange = (click) => {

    if (!click) {
      setIsChecked(!isChecked);
    }



    let value = data.value;



    if (dataIsAvailable) {
      const index_ = selectedInputs.findIndex((o) => o[value] === "true");
      let arr = selectedInputs.filter((obj, index) => index !== index_);



      // onChange(arr);
    } else {

      let obj = {
        [value]: "true",
        "input": data?.default_values,
        // "description": ""
      };


      onChange([...selectedInputs, obj]);
      setSelected([])
    }
  };

  const handleClick = () => {


    setData(data)

    if (isChecked === false) {
      setIsChecked(true)
      handleCheckboxChange('click')

    }




  }

  return (

    <div className="mb-3" key={index}>
      <div className="d-flex">
        <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
          <Form.Check.Input type="checkbox" onChange={(e) => handleCheckboxChange('')} checked={isChecked} />
          {/* <Form.Check.Label > <CaretDoubleRight size={12} /> </Form.Check.Label> */}
        </Form.Check></div>
        <div onClick={(e) => handleClick()} className="p-2 cursor-pointer">
          {data.text}
        </div>

      </div>


    </div>
  );
}
