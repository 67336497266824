import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./CaseOverview.styles";
import Case from "./Case/Case";


const CaseOverview = () => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState("");
    const [currentPath, setCurrentPath] = useState("");
    const [selectedDateFilter, setSelectedDateFilter] = useState("7 Days");
    const [title, setTitle] = useState("");
    const [breadcrumb, setBreadcrumb] = useState();
    const [dateFilter, setDateFilter] = useState();

    useEffect(() => {

        setCurrentUrl(location.pathname);
        setCurrentPath(location.pathname.split("/")[2]);
    }, [location]);

    useEffect(() => {
        if (currentPath === "management") {
            setTitle(" Case Management")
            setBreadcrumb({
                "module": "case",
                "path": [{ "title": "Case Management", "url": "/case/management" }, { "title": "Case Management", "url": "/case/management" },]
            });
        }
    }, [currentPath]);

    return (
        <div className="col-12 d-flex" style={styles.root1}>
            <Sidebar module={"case"} />
            <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
                <Topbar
                    title={title}
                    breadcrumb={breadcrumb}
                    currentUrl={currentUrl}
                    selectedDateFilter={selectedDateFilter}
                    setSelectedDateFilter={setSelectedDateFilter}
                    isFilterEnabled={false}
                    setDateFilter={setDateFilter}
                />
                <div className="col-12 d-flex" style={styles.main}>
                    {currentPath === "management" ? (
                        <Case />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CaseOverview;