import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getProcessList } from "../../../Methods/UebaMethods";
import { exportCSVFile } from "../../../Utils/Utils";
import moment from "moment";

// function getTimeFromTimestamp(timestamp) {
//   return moment(timestamp).format("hh:mm:ss A");
// }
// function getDateFromTimestamp(timestamp) {
//   return moment(timestamp).format("DD MMM YYYY");
// }
const getTimeFromTimestamp = (timestamp) => {
  return moment(timestamp).format('DD MMM YYYY, hh:mm:ss A');
}            



const InvestigateExport = ({ filter, dateFilter, getInvestigationTable2, selectedDropItem, columns }) => {
  const [loader, setLoader] = useState(false);

  let storedData = columns

  const getFields = () => {

    var filterExportData = []

    storedData && storedData[selectedDropItem].filter((item) => {
      item.isSelected === true && filterExportData.push({ name: item.text, value: `item?._source?.${item.value}` })
    })


    var fields = filterExportData

    return fields;
  };
  const convertdata = (fields, data) => {
    var convertedData = [];
    data.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {
        const { name, value } = field;
        if (value !== `item?._source?.detection_timestamp`) {
          var value1 = eval(value);
      }
      else {
          var value1 = getTimeFromTimestamp(eval(value))
      }

        dynamicJSON[name] = value1;
      }
      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };
  const csvExport = async () => {
    setLoader(true);
    var size = 1000;
    var data = await getInvestigationTable2(
      filter,
      dateFilter,
      size,
      "desc",
      0
    );
    var data3 = data.data?.hits?.hits;
    var count = 10000;
    var iter = 1000;
    while (count > 1000 && iter < count) {
      try {
        var tempdata = await getInvestigationTable2(
          filter,
          dateFilter,
          size,
          "desc",
          iter
        );
        data3.push(...tempdata.data.hits.hits);
      }
      catch (err) {

      }
      iter += size;
    }
    var fields = getFields();
    var filename = "Detection" + "_" + moment().toISOString();
     
  // Convert UTC timestamps to a human-readable format
  console.log(data3);
  data3.forEach((item) => {
        if (item._source.detection_timestamp) {
            // Parse timestamp and format it
            item._source.detection_timestamp = getTimeFromTimestamp(item._source.detection_timestamp);
        }
    });
 
    var data1 = convertdata(fields, data3);
    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default InvestigateExport;
