import React, { useEffect, useState } from "react";
import "../../../index.css";
import styles from "./Controlview.styles";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import iso from "../../../assets/compliance/compliance-iso27001.json";
import nist from "../../../assets/compliance/compliance-NIST.json";
import pci from "../../../assets/compliance/compliance-PCI-DSS.json";
import { fget, fpost } from "../../../axios/axios";
import { CaretDown, CaretRight, ArrowClockwise, ArrowsOutSimple, Pencil } from "phosphor-react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableLoader from '../../../components/Loader/TableLoader'
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
const Controlview = ({ selectedDateFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState("ISO-27001");
  const [selectedData, setSelectedData] = useState({});
  const [annexData, setAnnexData] = useState({});
  const [annexBData, setAnnexBData] = useState({});
  const [showInfoDown, setShowInfoDown] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [editHeading, setEditHeading] = useState(false);
  const [getInfoData, setGetInfoData] = useState({});
  const [showIndex, setShowIndex] = useState([]);
  const [moreInfoText, setMoreInfoText] = useState();
  const [loading, setLoading] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [status, setStatus] = useState("");
  const [savedFormattedId, setSavedFormattedId] = useState("");
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [activeTab, setActiveTab] = useState("main");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")


  useEffect(() => {
    console.log("LOADING", isMainLoading);
    setIsMainLoading(true); // Set isMainLoading to true when selectedFilter changes
    if (selectedFilter === "PCI-DSS" || selectedFilter === "ISO-22301") setActiveTab("annex");
    else setActiveTab("main");

    const viewName = selectedFilter.replace('-', '').toLowerCase();
    getSummary(viewName);
  }, [selectedFilter, selectedDateFilter]);
  useEffect(() => {
    // When selectedFilter changes, set isMainLoading to true
    setIsMainLoading(true);
    // Here you can place any additional logic or API calls related to the selectedFilter change
    // For demonstration purposes, let's simulate an API call delay with setTimeout
    const delay = setTimeout(() => {
      // After the API call or any related logic, set isMainLoading back to false
      setIsMainLoading(false);
    }, 2000); // Simulating a delay of 2000 milliseconds (2 seconds). You can adjust as needed.
    // Cleanup function to clear the timeout in case the component unmounts or selectedFilter changes again
    return () => clearTimeout(delay);
  }, [selectedFilter]);

  const getSummary = async (item) => {
    console.log("LOADING", isMainLoading);
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/summary?std=${item}`
      );
      console.log("SUMMARY", response, typeof response?.data);
      setSelectedData(typeof response?.data === "object" ? response?.data : {})
      setAnnexData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex" || item.category === "annex-a") : {})
      setAnnexBData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex-b") : {})
      console.log("LOADING", isMainLoading);
      setIsMainLoading(false)
    } catch (error) {
      setIsMainLoading(false)
      console.log("summary error", error);
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
    }
  };
  const getInfo = async (id) => {
    setLoading(true);
    // getcomplianceinfo?std=iso27001&control=4.2
    let temp = id?.split("-");
    let temp2 = temp[0]?.toLowerCase();
    let temp3 = temp[1];
    let formattedId = temp2 + temp3;
    // let formattedId = temp2 + temp[1];
    setSavedFormattedId(formattedId);
    console.log("formattedId", formattedId);
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/info?std=${formattedId}`
        // "https://api-gw1.active-bytes.com/api/v2/compliance/info?std=iso27001&control=A.5"
      );
      console.log("info RESPONSE", response);
      setGetInfoData(response.data)
      if (response.data?.response_richtext)
        setMoreInfoText(response.data?.response_richtext);
      else setMoreInfoText(null);
      setStatus(response.data?.status);
      setLoading(false);
    } catch (error) {
      // setShowMoreInfo(false);
      setLoading(false);
      console.log("info error");
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
    }
  };
  const doRefresh = async () => {
    setIsMainLoading(true)
    let temp = selectedFilter.split("-");
    let temp2 = temp[0]?.toLowerCase();
    let temp3 = temp[1]?.toLowerCase();
    let formattedId = temp2 + temp3;
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/refresh?std=${formattedId}`
      );
      console.log("refresh", response);
      if (response) {
        const viewName = selectedFilter.replace('-', '').toLowerCase()
        getSummary(viewName);
      }
    } catch (error) {
      setIsMainLoading(false)
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
      console.log("refresh error", error);
    }
  };
  const setComplianceStatus = async (value) => {
    setIsUpdating(true);
    console.log("savedFormattedId", savedFormattedId);
    let temp = savedFormattedId?.split("&control=");
    console.log("temp", temp);
    let data;
    if (value === "text") {
      data = {
        response_richtext: moreInfoText,
        status: getInfoData?.status,
        clause: getInfoData?.clause,
        description: getInfoData?.description
      };
      // data = getInfoData
    } else {
      data = {
        std: temp[0],
        control: temp[1],
      };
    }
    try {
      const response = await fpost(
        `${apiBaseUrl}/compliance/info?std=${savedFormattedId}`,
        data
      );
      console.log("info RESPONSE", response);
      setIsUpdating(false);
      doRefresh()
      toast.success("Updated!", {
        position: "bottom-right",
      });
    } catch (error) {
      setIsUpdating(false);
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
      console.log("save/update error");
    }
  };
  const createMarkup = (data) => {
    return { __html: data };
  };

  useEffect(() => {
    if (status === "conformity") { setStatus("managed") }
    else if (status === "unconformity") { setStatus("unknown") }
  })

  // Function to handle changes in the text
  const handleChange = (value) => {
    setMoreInfoText(value);
  };
  const renderModal = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner animation="border" role="status" />
        </div>
      );
    } else {
      return (
        <div className="p-2">
          {!editHeading &&
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Select
                value={status}
                onChange={(e) => {
                  e.currentTarget.value==="conformity"?setStatus("managed"):
                  e.currentTarget.value==="unconformity"?setStatus("unknown"):
                  setStatus(e.currentTarget.value);
                  setComplianceStatus(e.currentTarget.value);
                }}
              >
                {/* <option value="managed">Conformity</option>
                {/* <option value="unconformity">Non-Conformity</option> */}
              {/* <option value="unknown">Unknown</option> */}
              {/* <option value="nonexistent">Nonexistent</option> */}
              {/* <option value="initial">Initial</option> */}
              {/* <option value="limited">Limited</option> */}
              {/* <option value="defined">Defined</option> */}
              {/* <option value="managed">Managed</option> */}
              {/* <option value="optimised">Optimised</option> */}
              {/* <option value="notapplicable">Not applicable</option> */}

              {/* </Form.Select> */}
              <Form.Select
                value={status === "conformity" ? "managed" : status === "unconformity" ? "unknown" : status}
                onChange={(e) => {
                  const selectedValue = e.currentTarget.value;

                  // Using a switch statement for clarity
                  switch (selectedValue) {
                    case "conformity":
                      setStatus("managed");
                      setComplianceStatus("managed");
                      break;
                    case "unconformity":
                      setStatus("unknown");
                      setComplianceStatus("unknown");
                      break;
                    default:
                      setStatus(selectedValue);
                      setComplianceStatus(selectedValue);
                  }
                }}
              >
                {/* <option value="managed">Conformity</option> */}
                {/* <option value="unconformity">Non-Conformity</option> */}
                <option value="unknown">Unknown</option>
                <option value="nonexistent">Nonexistent</option>
                <option value="initial">Initial</option>
                <option value="limited">Limited</option>
                <option value="defined">Defined</option>
                <option value="managed">Managed</option>
                <option value="optimised">Optimised</option>
                <option value="notapplicable">Not applicable</option></Form.Select>
            </Form.Group>
          }
          {console.log("status", status)}

          {
            // moreInfoText !== null ? (
            !showTextEditor ? (
              <>
                {editHeading ?
                  <>
                    <div dangerouslySetInnerHTML={createMarkup(getInfoData?.clause)} />
                    <div dangerouslySetInnerHTML={createMarkup(getInfoData?.description)} />
                  </>
                  :
                  <div dangerouslySetInnerHTML={createMarkup(moreInfoText)} />

                }
                <Button onClick={() => setShowTextEditor(true)}>Edit</Button>
              </>
            ) : (
              <>
                <div className="col-md-12 col-xs-12 p-1">
                  {editHeading ?
                    <div className="d-flex flex-column gap-3">
                      {/* <ReactQuill
                        theme="snow"
                        value={getInfoData?.clause}
                        onChange={(e) => {
                          console.log("e.target.value", e);
                          const temp = getInfoData
                          temp.clause = e
                          setGetInfoData(temp);
                        }}
                      /> */}
                      <div className="d-flex flex-column gap-1">
                        <label>Clause</label>
                        <input
                          type="text"
                          className="p-2"
                          defaultValue={getInfoData?.clause}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            const temp = getInfoData
                            temp.clause = e.target.value
                            setGetInfoData(temp);
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column gap-1">
                        <label>Description</label>
                        <input
                          type="text"
                          className="p-2"
                          defaultValue={getInfoData?.description}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            const temp = getInfoData
                            temp.description = e.target.value
                            setGetInfoData(temp);
                          }}
                        />
                      </div>
                      {/* <ReactQuill
                        theme="snow"
                        value={getInfoData?.description}
                        onChange={(e) => {
                          console.log("e.target.value", e);
                          const temp = getInfoData
                          temp.description = e
                          setGetInfoData(temp);
                        }}
                      /> */}
                    </div>
                    :
                    <div className="form-group d-flex justify-content-center">
                      <div className="col-md-12 col-xs-12">
                        {/* <ReactQuill
                            value={moreInfoText}
                            onChange={(e) => {
                                console.log("e.target.value", e);
                                setMoreInfoText(e);
                            }} 
                        /> */}
                        <ReactQuill
                          value={moreInfoText}
                          onChange={handleChange}
                          style={{ textAlign: 'center' }} // Center-align the editor content
                        />
                      </div>
                    </div>

                  }
                </div>
                <div className="col-md-12 col-xs-12 p-1 mb-5"></div>
                <div className="row mt-3 py-3" style={styles.footerWrap}>
                  <div className="form-group d-flex justify-content-end">
                    <Button
                      style={{ width: "70px" }}
                      onClick={() => setComplianceStatus("text")}
                    >
                      {isUpdating ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )
            // ) : !showTextEditor ? (
            //   <div className="row mt-3 py-3" style={styles.footerWrap}>
            //     <div className="form-group d-flex justify-content-end">
            //       <Button onClick={() => setShowTextEditor(true)}>Add</Button>
            //     </div>
            //   </div>
            // ) : (
            //   <>
            //     <div className="col-md-12 col-xs-12 p-1">
            //       <ReactQuill
            //         theme="snow"
            //         value=""
            //         onChange={(e) => setMoreInfoText(e)}
            //       />
            //     </div>
            //     <div className="row mt-3 py-3" style={styles.footerWrap}>
            //       <div className="form-group d-flex justify-content-end">
            //         <Button style={{ width: "70px" }}>
            //           {isUpdating ? (
            //             <Spinner animation="border" role="status" size="sm" />
            //           ) : (
            //             "Update"
            //           )}
            //         </Button>
            //       </div>
            //     </div>
            //   </>
            // )
          }
        </div>
      );
    }
  };
  const renderRow = (item0, item1, item2, item3, item4, item, clickfn, index) => (
    <>
      <span
        className="p-3 col-1 align-items-center d-flex"
      >
        {item0}
      </span>
      <span
        className="p-3 col-4 align-items-center d-flex"
      >
        {item2}
      </span>
      <span
        className="p-3 col-6 align-items-center d-flex"
      >
        {item3}
      </span>
      {item4 && (
        <span
          className="p-3 col-1 cursor-pointer align-items-center justify-content-center d-flex gap-2"
          style={{
            cursor: "pointer",
          }}
        >
          <Pencil
            style={{
              padding: '5px'
            }}
            size={24}
            onClick={() => {
              // setShowMoreInfo(true);
              setShowTextEditor(true)
              setEditHeading(true)
              getInfo(
                `${selectedFilter}&control=${item.id}`
              );
            }} />
          {item4}
        </span>
      )}
    </>
  );
  return (
    <>
      <div
        className="bg-white col-12 p-4 rounded-3 position-relative"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-5 gap-2"
          style={styles.filterWrap}
        >
          {/* <ArrowClockwise
            size={19}
            weight="fill"
            className="mb-3"
            onClick={doRefresh}
          /> */}
          <div className="position-relative">
            <Form.Check
              className="mb-0"
              type="switch"
              id="toggle_detections_switch"
              label={expandAll ? "Collapse all" : "Expand all"}
              onChange={() => {
                console.log('clicked', expandAll);
                if (expandAll) {
                  setExpandAll(false)
                  setShowIndex([]);
                  setShowInfoDown(false)
                }
                else {
                  let temp = []
                  selectedData?.map(item =>
                    item?.parent?.map(item2 => {
                      temp.push(item2)
                      if (item2?.child?.length) {
                        item2?.child.map(item3 => {
                          temp.push(item3)
                          if (item3?.child?.length) {
                            item3?.child?.map(item4 => {
                              temp.push(item4)
                            })
                          }
                        })
                      }
                    })
                  )
                  console.log("new temp", temp);
                  setShowIndex(temp);
                  setShowInfoDown(true)
                  setExpandAll(true)
                }
              }}
            />
          </div>
          <div className="position-relative">
            <Button
              variant="light"
              className="m-0"
              onClick={doRefresh}
            >
              Refresh
            </Button>
          </div>
          <Form.Group className="mb-0 col-4" controlId="formBasicEmail">
            <Form.Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.currentTarget.value)}
            >
              <option value="ISO-27001">ISO-27001</option>
              <option value="ISO-22301">ISO-22301</option>
              <option value="NIST">NIST</option>
              <option value="PCI-DSS">PCI-DSS</option>
              <option value="ISA-62443">ISA-62443</option>
            </Form.Select>
          </Form.Group>
        </div>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {selectedData[0]?.category === "main" &&
            <Tab
              eventKey={selectedData[0]?.category}
              title={
                selectedData[0]?.category === "main"
                  ? "Main"
                  : "Annex"
              }
            >
              {selectedData[0]?.parent === null ?
                <NoDataFound errorText={"No data found"} /> :
                (isMainLoading ? <TableLoader rowCount={25} /> :
                  <div style={styles.tableWrap}>
                    <div className="d-flex sticky-header">
                      {selectedFilter === "ISO-27001" ?
                        renderRow(
                          "",
                          "",
                          "Clause & Requirement",
                          "Description",
                          // "Status",
                        )
                        : selectedFilter === "NIST" ?
                          renderRow(
                            "Control Identifier",
                            "",
                            "Control Text",
                            "Discussion",
                            // "Action",
                          )
                          : selectedFilter === "ISA-62443" ?
                            renderRow(
                              "",
                              "",
                              "Clause & Requirement",
                              "Description",
                              // "Status",
                            )
                            :
                            renderRow(
                              "PCI DSS ID",
                              "",
                              "Defined Approach Requirements",
                              "Defined Approach Testing Procedures",
                              // "Status",
                            )
                      }
                    </div>
                    <div className="d-flex flex-column justify-content-between gap-3 mt-3">
                      {selectedData[0]?.parent?.map(
                        (item, index) => (
                          <div className="d-flex flex-column gap-0 justify-content-between custom-table-row-group border mb-0 p-0 rounded-3">
                            <div className="d-flex flex-row custom-table-row-head" type="button"
                              onClick={() => {
                                if (showIndex?.includes(item)) {
                                  let temp = [...showIndex]
                                  let temp2 = temp.filter(i => i !== item)
                                  setShowIndex(temp2);
                                  console.log('includes', showIndex);
                                }
                                else {
                                  let temp = [...showIndex]
                                  temp.push(item)
                                  setShowIndex(temp);
                                  setShowInfoDown(true)
                                  console.log('not', showIndex);
                                }
                              }}>
                              {renderRow(
                                item?.id,
                                "",
                                item?.clause,
                                item?.description,
                                <CaretDown />,
                                item
                              )}
                            </div>
                            {showInfoDown && showIndex.includes(item) &&
                              <>
                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(item?.response_richtext)} />
                                  <div className="col-1 text-center">
                                    <Button
                                      variant="secondary"
                                      onClick={() => {
                                        setShowMoreInfo(true);
                                        setShowTextEditor(true)
                                        getInfo(
                                          `${selectedFilter}&control=${item.id}`
                                        );
                                      }}>Edit</Button>
                                  </div>
                                </div>

                                {/*  */}
                                {item?.child?.length ? item?.child?.map((childItem1, childIndex) => (
                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                    <div
                                      className="d-flex flex-row"
                                      type="button"
                                      onClick={() => {
                                        if (showIndex?.includes(childItem1)) {
                                          let temp = [...showIndex]
                                          let temp2 = temp.filter(i => i !== childItem1)
                                          setShowIndex(temp2);
                                          console.log('includes', showIndex);
                                        }
                                        else {
                                          let temp = [...showIndex]
                                          temp.push(childItem1)
                                          setShowIndex(temp);
                                          setShowInfoDown(true)
                                          console.log('not', showIndex);
                                        }
                                      }}
                                    >
                                      {renderRow(
                                        childItem1?.id,
                                        "",
                                        childItem1?.clause,
                                        childItem1?.description,
                                        <CaretDown />,
                                        childItem1)}
                                    </div>

                                    {showInfoDown && showIndex.includes(childItem1) &&
                                      <>

                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem1?.response_richtext)} />
                                          <div className="col-1 text-center">
                                            <Button className=""
                                              variant="secondary"
                                              onClick={() => {
                                                setShowMoreInfo(true);
                                                setShowTextEditor(true)
                                                getInfo(
                                                  `${selectedFilter}&control=${childItem1?.id}`
                                                );
                                              }}>Edit</Button>
                                          </div>
                                        </div>

                                        {childItem1?.child?.length ? childItem1?.child?.map((childItem2, childIndex) => (
                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                            <div
                                              className="d-flex flex-row"
                                              type="button"
                                              onClick={() => {
                                                if (showIndex?.includes(childItem2)) {
                                                  let temp = [...showIndex]
                                                  let temp2 = temp.filter(i => i !== childItem2)
                                                  setShowIndex(temp2);
                                                  console.log('includes', showIndex);
                                                }
                                                else {
                                                  let temp = [...showIndex]
                                                  temp.push(childItem2)
                                                  setShowIndex(temp);
                                                  setShowInfoDown(true)
                                                  console.log('not', showIndex);
                                                }
                                              }}
                                            >
                                              {renderRow(
                                                childItem2?.id,
                                                "",
                                                childItem2?.clause,
                                                childItem2?.description,
                                                <CaretDown />,
                                                childItem2)}
                                            </div>
                                            {showInfoDown && showIndex.includes(childItem2) &&
                                              <>
                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem2?.response_richtext)} />
                                                  <div className="col-1 text-center">
                                                    <Button
                                                      variant="secondary"
                                                      onClick={() => {
                                                        setShowMoreInfo(true);
                                                        setShowTextEditor(true)
                                                        getInfo(
                                                          `${selectedFilter}&control=${childItem2.id}`
                                                        );
                                                      }}>Edit</Button>
                                                  </div>
                                                </div>

                                                {/* level3 */}
                                                {childItem2?.child?.length ? childItem2?.child?.map((childItem3, childIndex) => (
                                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                    <div
                                                      className="d-flex flex-row"
                                                      type="button"
                                                      onClick={() => {
                                                        if (showIndex?.includes(childItem3)) {
                                                          let temp = [...showIndex]
                                                          let temp2 = temp.filter(i => i !== childItem3)
                                                          setShowIndex(temp2);
                                                          console.log('includes', showIndex);
                                                        }
                                                        else {
                                                          let temp = [...showIndex]
                                                          temp.push(childItem3)
                                                          setShowIndex(temp);
                                                          setShowInfoDown(true)
                                                          console.log('not', showIndex);
                                                        }
                                                      }}
                                                    >
                                                      {renderRow(
                                                        childItem3?.id,
                                                        "",
                                                        childItem3?.clause,
                                                        childItem3?.description,
                                                        <CaretDown />,
                                                        childItem3)}
                                                    </div>
                                                    {showInfoDown && showIndex.includes(childItem3) &&
                                                      <>
                                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem3?.response_richtext)} />
                                                          <div className="col-1 text-center">
                                                            <Button
                                                              variant="secondary"
                                                              onClick={() => {
                                                                setShowMoreInfo(true);
                                                                setShowTextEditor(true)
                                                                getInfo(
                                                                  `${selectedFilter}&control=${childItem3.id}`
                                                                );
                                                              }}>Edit</Button>
                                                          </div>
                                                        </div>


                                                        {/* level4 */}
                                                        {childItem3?.child?.length ? childItem3?.child?.map((childItem4, childIndex) => (
                                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                            <div
                                                              className="d-flex flex-row"
                                                              type="button"
                                                              onClick={() => {
                                                                if (showIndex?.includes(childItem4)) {
                                                                  let temp = [...showIndex]
                                                                  let temp2 = temp.filter(i => i !== childItem4)
                                                                  setShowIndex(temp2);
                                                                  console.log('includes', showIndex);
                                                                }
                                                                else {
                                                                  let temp = [...showIndex]
                                                                  temp.push(childItem4)
                                                                  setShowIndex(temp);
                                                                  setShowInfoDown(true)
                                                                  console.log('not', showIndex);
                                                                }
                                                              }}
                                                            >
                                                              {renderRow(
                                                                childItem4?.id,
                                                                "",
                                                                childItem4?.clause,
                                                                childItem4?.description,
                                                                <CaretDown />,
                                                                childItem4)}
                                                            </div>
                                                            {showInfoDown && showIndex.includes(childItem4) &&
                                                              <>
                                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem4?.response_richtext)} />
                                                                  <div className="col-1 text-center">
                                                                    <Button
                                                                      variant="secondary"
                                                                      onClick={() => {
                                                                        setShowMoreInfo(true);
                                                                        setShowTextEditor(true)
                                                                        getInfo(
                                                                          `${selectedFilter}&control=${childItem4.id}`
                                                                        );
                                                                      }}>Edit</Button>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            }

                                                          </div>
                                                        )) : ""}
                                                        {/* level4 */}

                                                      </>
                                                    }

                                                  </div>
                                                )) : ""}
                                                {/* level3 */}

                                              </>
                                            }

                                          </div>
                                        )) : ""}
                                      </>
                                    }

                                    {/* last div */}
                                  </div>

                                )) : ""}
                                {/*  */}
                              </>
                            }
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
            </Tab>
          }

          {/* TAB 2 */}
          {(selectedData[0]?.category === "annex" || Object.keys(selectedData)?.length > 1) && (
            // <Tab eventKey="annex" title="Annex">
            <Tab eventKey={selectedFilter === "ISA-62443" ? "annex-a" : "annex"} title={selectedFilter === "ISA-62443" ? "Annex-A" : "Annex"}>
              {annexData[0]?.parent === null ?
                <NoDataFound errorText={"No data found"} /> :
                (isMainLoading ? <TableLoader rowCount={25} /> :
                  <div style={styles.tableWrap}>
                    <div className="d-flex sticky-header">
                      {
                        renderRow(
                          "",
                          "",
                          "Controls & Requirement",
                          "Description",
                          // "Status",
                        )
                      }
                    </div>
                    <div className="d-flex flex-column justify-content-between gap-3 mt-3">
                      {annexData[0]?.parent?.map(
                        (item, index) => (
                          console.log(item),
                          <div className="d-flex flex-column gap-0 justify-content-between border m-0 p-0 rounded-3">
                            <div
                              className="d-flex flex-row"
                              type="button"
                              onClick={() => {
                                if (showIndex?.includes(item)) {
                                  let temp = [...showIndex]
                                  let temp2 = temp.filter(i => i !== item)
                                  setShowIndex(temp2);
                                  console.log('includes', showIndex);
                                }
                                else {
                                  let temp = [...showIndex]
                                  temp.push(item)
                                  setShowIndex(temp);
                                  setShowInfoDown(true)
                                  console.log('not', showIndex);
                                }
                              }}
                            >
                              {renderRow(
                                item?.id,
                                "",
                                item?.clause,
                                item?.description,
                                <CaretDown />,
                                item

                              )}
                            </div>
                            {showInfoDown && showIndex.includes(item) &&

                              <>

                                {/* <div className="col-12 d-flex justify-content-end border-top p-3">
                                <div className="col-10" dangerouslySetInnerHTML={createMarkup(item?.response_richtext)} />
                                <div className="col-1 text-center">
                                  <Button
                                    variant="secondary"
                                    onClick={() => {
                                      setShowMoreInfo(true);
                                      setShowTextEditor(true)
                                      getInfo(
                                        `${selectedFilter}&control=${item.id}`
                                      );
                                    }}>Edit</Button>
                                </div>
                              </div> */}
                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(item?.response_richtext)} />
                                  <div className="col-1 text-center">
                                    <Button
                                      variant="secondary"
                                      onClick={() => {
                                        setShowMoreInfo(true);
                                        setShowTextEditor(true)
                                        getInfo(
                                          `${selectedFilter}&control=${item.id}`
                                        );
                                      }}>Edit</Button>
                                  </div>
                                </div>

                                {item?.child?.length ? item?.child?.map((childItem1, childIndex) => (
                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                    <div
                                      className="d-flex flex-row"
                                      type="button"
                                      onClick={() => {
                                        if (showIndex?.includes(childItem1)) {
                                          let temp = [...showIndex]
                                          let temp2 = temp.filter(i => i !== childItem1)
                                          setShowIndex(temp2);
                                          console.log('includes', showIndex);
                                        }
                                        else {
                                          let temp = [...showIndex]
                                          temp.push(childItem1)
                                          setShowIndex(temp);
                                          setShowInfoDown(true)
                                          console.log('not', showIndex);
                                        }
                                      }}
                                    >
                                      {renderRow(
                                        childItem1?.id,
                                        "",
                                        childItem1?.clause,
                                        childItem1?.description,
                                        <CaretDown />,
                                        childItem1)}
                                    </div>
                                    {showInfoDown && showIndex.includes(childItem1) &&
                                      <>

                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem1?.response_richtext)} />
                                          <div className="col-1 text-center">
                                            <Button className=""
                                              variant="secondary"
                                              onClick={() => {
                                                setShowMoreInfo(true);
                                                setShowTextEditor(true)
                                                getInfo(
                                                  `${selectedFilter}&control=${childItem1?.id}`
                                                );
                                              }}>Edit</Button>
                                          </div>
                                        </div>

                                        {childItem1?.child?.length ? childItem1?.child?.map((childItem2, childIndex) => (
                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                            <div
                                              className="d-flex flex-row"
                                              type="button"
                                              onClick={() => {
                                                if (showIndex?.includes(childItem2)) {
                                                  let temp = [...showIndex]
                                                  let temp2 = temp.filter(i => i !== childItem2)
                                                  setShowIndex(temp2);
                                                  console.log('includes', showIndex);
                                                }
                                                else {
                                                  let temp = [...showIndex]
                                                  temp.push(childItem2)
                                                  setShowIndex(temp);
                                                  setShowInfoDown(true)
                                                  console.log('not', showIndex);
                                                }
                                              }}
                                            >
                                              {renderRow(
                                                childItem2?.id,
                                                "",
                                                childItem2?.clause,
                                                childItem2?.description,
                                                <CaretDown />,
                                                childItem2)}
                                            </div>
                                            {showInfoDown && showIndex.includes(childItem2) &&
                                              <>
                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem2?.response_richtext)} />
                                                  <div className="col-1 text-center">
                                                    <Button
                                                      variant="secondary"
                                                      onClick={() => {
                                                        setShowMoreInfo(true);
                                                        setShowTextEditor(true)
                                                        getInfo(
                                                          `${selectedFilter}&control=${childItem2.id}`
                                                        );
                                                      }}>Edit</Button>
                                                  </div>
                                                </div>

                                                {/* level3 */}
                                                {childItem2?.child?.length ? childItem2?.child?.map((childItem3, childIndex) => (
                                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                    <div
                                                      className="d-flex flex-row"
                                                      type="button"
                                                      onClick={() => {
                                                        if (showIndex?.includes(childItem3)) {
                                                          let temp = [...showIndex]
                                                          let temp2 = temp.filter(i => i !== childItem3)
                                                          setShowIndex(temp2);
                                                          console.log('includes', showIndex);
                                                        }
                                                        else {
                                                          let temp = [...showIndex]
                                                          temp.push(childItem3)
                                                          setShowIndex(temp);
                                                          setShowInfoDown(true)
                                                          console.log('not', showIndex);
                                                        }
                                                      }}
                                                    >
                                                      {renderRow(
                                                        childItem3?.id,
                                                        "",
                                                        childItem3?.clause,
                                                        childItem3?.description,
                                                        <CaretDown />,
                                                        childItem3)}
                                                    </div>
                                                    {showInfoDown && showIndex.includes(childItem3) &&
                                                      <>
                                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem3?.response_richtext)} />
                                                          <div className="col-1 text-center">
                                                            <Button
                                                              variant="secondary"
                                                              onClick={() => {
                                                                setShowMoreInfo(true);
                                                                setShowTextEditor(true)
                                                                getInfo(
                                                                  `${selectedFilter}&control=${childItem3.id}`
                                                                );
                                                              }}>Edit</Button>
                                                          </div>
                                                        </div>


                                                        {/* level4 */}
                                                        {childItem3?.child?.length ? childItem3?.child?.map((childItem4, childIndex) => (
                                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                            <div
                                                              className="d-flex flex-row"
                                                              type="button"
                                                              onClick={() => {
                                                                if (showIndex?.includes(childItem4)) {
                                                                  let temp = [...showIndex]
                                                                  let temp2 = temp.filter(i => i !== childItem4)
                                                                  setShowIndex(temp2);
                                                                  console.log('includes', showIndex);
                                                                }
                                                                else {
                                                                  let temp = [...showIndex]
                                                                  temp.push(childItem4)
                                                                  setShowIndex(temp);
                                                                  setShowInfoDown(true)
                                                                  console.log('not', showIndex);
                                                                }
                                                              }}
                                                            >
                                                              {renderRow(
                                                                childItem4?.id,
                                                                "",
                                                                childItem4?.clause,
                                                                childItem4?.description,
                                                                <CaretDown />,
                                                                childItem4)}
                                                            </div>
                                                            {showInfoDown && showIndex.includes(childItem4) &&
                                                              <>
                                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem4?.response_richtext)} />
                                                                  <div className="col-1 text-center">
                                                                    <Button
                                                                      variant="secondary"
                                                                      onClick={() => {
                                                                        setShowMoreInfo(true);
                                                                        setShowTextEditor(true)
                                                                        getInfo(
                                                                          `${selectedFilter}&control=${childItem4.id}`
                                                                        );
                                                                      }}>Edit</Button>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            }

                                                          </div>
                                                        )) : ""}
                                                        {/* level4 */}

                                                      </>
                                                    }

                                                  </div>
                                                )) : ""}
                                                {/* level3 */}


                                              </>
                                            }

                                          </div>
                                        )) : ""}
                                      </>
                                    }

                                  </div>
                                )) : ""}
                              </>
                            }
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
            </Tab>
          )}

          {/* TAB 3 */}
          {Object.keys(selectedData)?.length > 2 && (
            // <Tab eventKey="annex" title="Annex">
            <Tab eventKey="annex-b" title="Annex-B">
              {annexBData[0]?.parent === null ?
                <NoDataFound errorText={"No data found"} /> :
                (isMainLoading ? <TableLoader rowCount={25} /> :
                  <div style={styles.tableWrap}>
                    <div className="d-flex sticky-header">
                      {
                        renderRow(
                          "",
                          "",
                          "Controls & Requirement",
                          "Description",
                          "Status",
                        )
                      }
                    </div>
                    <div className="d-flex flex-column justify-content-between gap-3 mt-3">
                      {annexBData[0]?.parent?.map(
                        (item, index) => (
                          <div className="d-flex flex-column gap-0 justify-content-between border m-0 p-0 rounded-3">
                            <div
                              className="d-flex flex-row"
                              type="button"
                              onClick={() => {
                                if (showIndex?.includes(item)) {
                                  let temp = [...showIndex]
                                  let temp2 = temp.filter(i => i !== item)
                                  setShowIndex(temp2);
                                  console.log('includes', showIndex);
                                }
                                else {
                                  let temp = [...showIndex]
                                  temp.push(item)
                                  setShowIndex(temp);
                                  setShowInfoDown(true)
                                  console.log('not', showIndex);
                                }
                              }}
                            >
                              {renderRow(
                                item?.id,
                                "",
                                item?.clause,
                                item?.description,
                                <CaretDown />,
                                item

                              )}
                            </div>
                            {showInfoDown && showIndex.includes(item) &&

                              <>
                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(item?.response_richtext)} />
                                  <div className="col-1 text-center">
                                    <Button
                                      variant="secondary"
                                      onClick={() => {
                                        setShowMoreInfo(true);
                                        setShowTextEditor(true)
                                        getInfo(
                                          `${selectedFilter}&control=${item.id}`
                                        );
                                      }}>Edit</Button>
                                  </div>
                                </div>

                                {item?.child?.length ? item?.child?.map((childItem1, childIndex) => (
                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                    <div
                                      className="d-flex flex-row"
                                      type="button"
                                      onClick={() => {
                                        if (showIndex?.includes(childItem1)) {
                                          let temp = [...showIndex]
                                          let temp2 = temp.filter(i => i !== childItem1)
                                          setShowIndex(temp2);
                                          console.log('includes', showIndex);
                                        }
                                        else {
                                          let temp = [...showIndex]
                                          temp.push(childItem1)
                                          setShowIndex(temp);
                                          setShowInfoDown(true)
                                          console.log('not', showIndex);
                                        }
                                      }}
                                    >
                                      {renderRow(
                                        childItem1?.id,
                                        "",
                                        childItem1?.clause,
                                        childItem1?.description,
                                        <CaretDown />,
                                        childItem1)}
                                    </div>
                                    {showInfoDown && showIndex.includes(childItem1) &&
                                      <>

                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem1?.response_richtext)} />
                                          <div className="col-1 text-center">
                                            <Button className=""
                                              variant="secondary"
                                              onClick={() => {
                                                setShowMoreInfo(true);
                                                setShowTextEditor(true)
                                                getInfo(
                                                  `${selectedFilter}&control=${childItem1?.id}`
                                                );
                                              }}>Edit</Button>
                                          </div>
                                        </div>

                                        {childItem1?.child?.length ? childItem1?.child?.map((childItem2, childIndex) => (
                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                            <div
                                              className="d-flex flex-row"
                                              type="button"
                                              onClick={() => {
                                                if (showIndex?.includes(childItem2)) {
                                                  let temp = [...showIndex]
                                                  let temp2 = temp.filter(i => i !== childItem2)
                                                  setShowIndex(temp2);
                                                  console.log('includes', showIndex);
                                                }
                                                else {
                                                  let temp = [...showIndex]
                                                  temp.push(childItem2)
                                                  setShowIndex(temp);
                                                  setShowInfoDown(true)
                                                  console.log('not', showIndex);
                                                }
                                              }}
                                            >
                                              {renderRow(
                                                childItem2?.id,
                                                "",
                                                childItem2?.clause,
                                                childItem2?.description,
                                                <CaretDown />,
                                                childItem2)}
                                            </div>
                                            {showInfoDown && showIndex.includes(childItem2) &&
                                              <>
                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem2?.response_richtext)} />
                                                  <div className="col-1 text-center">
                                                    <Button
                                                      variant="secondary"
                                                      onClick={() => {
                                                        setShowMoreInfo(true);
                                                        setShowTextEditor(true)
                                                        getInfo(
                                                          `${selectedFilter}&control=${childItem2.id}`
                                                        );
                                                      }}>Edit</Button>
                                                  </div>
                                                </div>

                                                {/* level3 */}
                                                {childItem2?.child?.length ? childItem2?.child?.map((childItem3, childIndex) => (
                                                  <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                    <div
                                                      className="d-flex flex-row"
                                                      type="button"
                                                      onClick={() => {
                                                        if (showIndex?.includes(childItem3)) {
                                                          let temp = [...showIndex]
                                                          let temp2 = temp.filter(i => i !== childItem3)
                                                          setShowIndex(temp2);
                                                          console.log('includes', showIndex);
                                                        }
                                                        else {
                                                          let temp = [...showIndex]
                                                          temp.push(childItem3)
                                                          setShowIndex(temp);
                                                          setShowInfoDown(true)
                                                          console.log('not', showIndex);
                                                        }
                                                      }}
                                                    >
                                                      {renderRow(
                                                        childItem3?.id,
                                                        "",
                                                        childItem3?.clause,
                                                        childItem3?.description,
                                                        <CaretDown />,
                                                        childItem3)}
                                                    </div>
                                                    {showInfoDown && showIndex.includes(childItem3) &&
                                                      <>
                                                        <div className="col-12 d-flex justify-content-end border-top p-3">
                                                          <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem3?.response_richtext)} />
                                                          <div className="col-1 text-center">
                                                            <Button
                                                              variant="secondary"
                                                              onClick={() => {
                                                                setShowMoreInfo(true);
                                                                setShowTextEditor(true)
                                                                getInfo(
                                                                  `${selectedFilter}&control=${childItem3.id}`
                                                                );
                                                              }}>Edit</Button>
                                                          </div>
                                                        </div>


                                                        {/* level4 */}
                                                        {childItem3?.child?.length ? childItem3?.child?.map((childItem4, childIndex) => (
                                                          <div className="d-flex flex-column gap-0 justify-content-between border-bottom">

                                                            <div
                                                              className="d-flex flex-row"
                                                              type="button"
                                                              onClick={() => {
                                                                if (showIndex?.includes(childItem4)) {
                                                                  let temp = [...showIndex]
                                                                  let temp2 = temp.filter(i => i !== childItem4)
                                                                  setShowIndex(temp2);
                                                                  console.log('includes', showIndex);
                                                                }
                                                                else {
                                                                  let temp = [...showIndex]
                                                                  temp.push(childItem4)
                                                                  setShowIndex(temp);
                                                                  setShowInfoDown(true)
                                                                  console.log('not', showIndex);
                                                                }
                                                              }}
                                                            >
                                                              {renderRow(
                                                                childItem4?.id,
                                                                "",
                                                                childItem4?.clause,
                                                                childItem4?.description,
                                                                <CaretDown />,
                                                                childItem4)}
                                                            </div>
                                                            {showInfoDown && showIndex.includes(childItem4) &&
                                                              <>
                                                                <div className="col-12 d-flex justify-content-end border-top p-3">
                                                                  <div className="col-10" dangerouslySetInnerHTML={createMarkup(childItem4?.response_richtext)} />
                                                                  <div className="col-1 text-center">
                                                                    <Button
                                                                      variant="secondary"
                                                                      onClick={() => {
                                                                        setShowMoreInfo(true);
                                                                        setShowTextEditor(true)
                                                                        getInfo(
                                                                          `${selectedFilter}&control=${childItem4.id}`
                                                                        );
                                                                      }}>Edit</Button>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            }

                                                          </div>
                                                        )) : ""}
                                                        {/* level4 */}

                                                      </>
                                                    }

                                                  </div>
                                                )) : ""}
                                                {/* level3 */}


                                              </>
                                            }

                                          </div>
                                        )) : ""}
                                      </>
                                    }

                                  </div>
                                )) : ""}
                              </>
                            }
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
            </Tab>
          )}

        </Tabs>
        <CustomModal
          title={"More Info"}
          ShowOrHide={showMoreInfo || editHeading}
          modalSize={"md"}
          bodyData={renderModal}
          handleClose={() => {
            setShowTextEditor(false);
            setShowMoreInfo(false);
            setEditHeading(false)
          }}
          footer={false}
          noBodyPadding={true}
          // overflowHidden={true}
          modalPosition={"right"}
          outsideClickDisabled={true}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default Controlview;
