import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import ReactJson from "react-json-view";

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./ExpandedDocument.styles";


export default function ExpandedDocument(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    if (props?.doc) {
      setIsOpen(true);

      setNodeData(props?.doc)
      setIsLoading(false)
    }
    else {
      if (props.clickedNode !== undefined && props.clickedNode !== "") {
        var pattern = props?.pattern
        if (pattern?.charAt(0) === '/') {
          // If it does, remove the first character
          pattern = pattern?.slice(1);
        }
        setIsOpen(true);
        const data = JSON.stringify({
          query: {
            bool: {
              must: {
                term: {
                  _id: props.clickedNode,
                },
              },
            },
          },
        });

        props.req(
          `${props.url}/${pattern}`,
          data
        ).then((response) => {
          if (props.title === 'Raw Log') {
            // props.parentData(response.data)

            // response.data && 
            let newpattern = pattern.split('/_doc')[0]
            let formatIndex = newpattern.replace(/-\d{4}\.\d{2}\.\d{2}-\d+|\.\d{2}\.\d{2}-\d+/g, '*').split('/_doc')[0];
            if (!response) {
              fpostCy(
                `${props.url}/${formatIndex}/_search?track_total_hits=true`,
                data
              ).then((res) => {
                if (res?.data?.found === false) {
                  fpostCy(`${props.url}/${'logs-*,winlogbeat*/_search?track_total_hits=true'}`, data).then((finalRes) => {
                    finalRes?.data &&
                      setNodeData(finalRes?.data?._source);
                    // setNodeData()
                    if (finalRes?.data?.hits?.hits.length === 0) {
                      fpostCy(`${props.url}/${'cybot_evidence_*/_search?track_total_hits=true'}`, data).then((finalRes1) => {
                        finalRes1?.data &&
                          setNodeData(finalRes?.data?.[0]?._source);
                        // setNodeData()
                        setIsLoading(false)
                      })
                      setIsLoading(false)
                    }
                  })
                }
                setNodeData(res?.data?._source);
              });
            }
            console.log("test", response)


            setNodeData(response?.data?._source);
            setIsLoading(false);
          } else {
            response?.data?.hits?.hits &&
              response?.data?.hits?.hits.map((val) => {
                setNodeData(val?._source);
              });
            setIsLoading(false);
          }
        }).catch((err) => {
          console.log("Error123", err)
        })
        // setIsLoading(false)

      }
    }

  }, [props.clickedNode]);

  useEffect(() => {
    if (props.close === true) {
      setIsOpen(false)
      props.setClick('')
    }
  }, [props.close])



  const handleClose = () => {
    props.setClick('')
    if (props?.doc) {
      props?.setDoc(null)

    }
    setIsLoading(true)
    props?.setActive && props?.setActive(false)
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal"
              id="offcanvasRightLabel"
            >
              {props.title ? props.title : "Expanded Document"}
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar">
            <Tabs defaultActiveKey="json" aria-label="Tabs with underline">
              <Tab eventKey="json" title="JSON" >
                {isLoading ?
                  <div style={{ "position": "fixed", "right": "16%", "top": "55%" }}><Spinner animation="border" role="status" /></div> :
                  <div className="py-3" style={styles.sidebarContentWrap}>

                    {nodeData ? <ReactJson className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={nodeData} collapsed={false} onEdit={false} onAdd={false} onDelete={false} enableClipboard={false} onSelect={false} displayDataTypes={false} displayObjectSize={false} />
                      : <div>
                        No Data Found about the process
                      </div>}
                    {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                  </div>}
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}