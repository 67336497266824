import React, { useState, useEffect } from "react";


import CheckBox from "./CheckBox";
import Values from "./Values";

export default function Modules({ data, expectedOutput, modules, setModules }) {
  const [selected, setSelected] = useState([]);
  const [selectedData, setData] = useState({});

  useEffect(() => {
    setData(data?.modules[0]);
  }, []);





  return (
    <>
      {/* {" "} */}
      <div className="row mb-3">
        <div className="form-group d-flex gap-3">
          <div className="p-3 card col-5">
            {" "}
            <label className="control-label">{data.moduleType} *</label>
            <div>{data.Description} </div>
            {data?.modules?.map((object, index) => (
              <CheckBox
                data={object}
                index={index}
                selectedInputs={modules}
                selected={selected}
                setSelected={setSelected}
                expectedOutput={expectedOutput}
                setData={setData}
                setModules={setModules}
              />
            ))}
          </div>
          <Values expectedOutput={expectedOutput} selectedData={selectedData} modules={modules} setModules={setModules} />
        </div>
      </div>
    </>
  );
}
