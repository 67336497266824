import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toastsSuccess, toastsDanger } from "../../../components/Toster/Toster";
import EditModal from "../../../components/CaseManagement/EditModal";

import { falsePositive, getTableData, groupedInvestigation } from '../../../Methods/InvestigationMethods';
import { useEffect } from 'react';
import Utils, { isJSONObject, rawHTML, replaceWithSpace } from '../../../Utils/Utils'
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils';
import styles from './GroupedInvestigationView.styles';
import { ArrowsOutSimple, Desktop, FileSearch, Info, MagnifyingGlass } from 'phosphor-react';
import { Badge, Button, Form, Modal, ProgressBar, Tab, Table, Tabs } from 'react-bootstrap';
import TableViewMore from '../../../components/ViewMoreBtn/TableViewMore';
import globalStyles from '../../../constants/globalStyles';
import { SingleLoader } from '../../../components/Loader/SingleTileLoader';
import { TimelineLoader } from '../../../components/Loader/TimelineLoader';
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import ExpandedDocument from '../../../components/ExpandedDocument/ExpandedDocument';
import { fgetCy, fpostC, fpostCy } from '../../../axios/axios';
import TableLoader from '../../../components/Loader/TableLoader';
import InlineFilter from '../../../components/InlineFilter/InlineFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import IncidentDownload from '../../../components/PDF/Investigate/IncidentDownload';
import CustomToolTip from '../../../components/ToolTip/CustomToolTip';
import CustomModal from '../../../components/CustomModal/CustomModal';
import AlertBox from '../../../components/AlertBox/AlertBox';
import ReactQuill from 'react-quill';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const GroupedInvestigationView = () => {
    const navigate = useNavigate();
    const indexId = localStorage.getItem("HUNTER_INDEX_ALL_INDEX_ID")

    let baseURL = localStorage.getItem("CYBOT_URL")
    const { id } = useParams()

    const [indicatorsDataLoader, setIndicatorsDataLoader] = useState(true)

    const [groupDetailsLoader, setGroupDetailsLoader] = useState(true)
    const [groupedInvestigationData, setGroupedInvestigationData] = useState([])
    const [indicatorsData, setIndicatorsData] = useState([])



    // expand
    const [expandedItems, setExpandedItems] = useState([]);
    const [isAllItemsExpanded, setIsAllItemsExpanded] = useState(false);

    const [expclickedNodeLog, setExpClickedNodeLog] = useState("")
    const [expClickedIndex, setExpClickedIndex] = useState("")
    const [expcloseLog, setExpCloseLog] = useState(false)
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    const [groupId, setGroupId] = useState()

    // manage column
    const [tableHead, setTableHead] = useState([]);
    const [checkBox, setCheckBox] = useState([]);

    const [alertPopup, setAlertPopup] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Success')

    const [tableHeader, setTableHeader] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const [selectedDropItem, setSelectedDropItem] = useState("ALL");
    const [checkedItems, setCheckedItems] = useState([]);

    const [investigationTableHead, setInvestigationTableHead] = useState([]);

    const [showManageColumn, setShowManageColumn] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowManageColumn(false);
    });
    const [show, setShow] = useState(false);
    const [closeShow, setCloseShow] = useState(false);
    const [detectionStatusCheck, setDetectionStatusCheck] = useState();

    const caseCreate = useSelector((state) => state.createCaseManagement);
    const { success: caseCreateSuccess } = caseCreate;
    const [caseshow, setCaseShow] = useState(false)
    const [case_id, setCase_ID] = useState("");

    const [tenandId, setTenandId] = useState([])
    const [showButton, setShowButton] = useState(false);


    const [detectionClosed, setDetectionClosed] = useState({
        closed_at: "",
        closed_by: "",
        comment: "",
        detection_status: "",
    });

    const [detectionOpen, setDetectionOpen] = useState({
        opened_at: "",
        opened_by: "",
        comment: "",
        detection_status: "",
    });

    const [closureCategory, setClosureCategory] = useState([])
    const [externalInv, setExternalInv] = useState([])

    // const [manageDatas, setManageDatas] = useState([
    //     { text: "Analyst Action", value: "analyst_action", isSelected: true },
    //     { text: "Description", value: "description", isSelected: true },
    //     { text: "Destination Ip", value: "dest_ip", isSelected: true },
    //     { text: "Detection Name", value: "detection_name", isSelected: true },
    //     { text: "Detection Severity", value: "detection_severity", isSelected: true },
    //     { text: "Detection Status", value: "detection_status", isSelected: true },
    //     { text: "From Address", value: "from_address", isSelected: true },
    //     { text: "Receiver Address", value: "receiver_address", isSelected: true },
    //     { text: "Group Id", value: "grp_id", isSelected: true },
    //     { text: "Host", value: "host", isSelected: true },
    //     { text: "Hunt Detection Id", value: "hunt_detection_id", isSelected: true },
    //     { text: "Hunt Type", value: "hunt_type", isSelected: true },
    //     { text: "Investigation Status", value: "investigation_status", isSelected: true },
    //     { text: "Playbook Id", value: "playbook_id", isSelected: true },
    //     { text: "Repetition Count", value: "repetition_count", isSelected: true },
    //     { text: "Subject", value: "subject", isSelected: true },
    //     { text: "Tactic Name", value: "tactic_name", isSelected: true },
    //     { text: "Technique Id", value: "technique_id", isSelected: true },
    //     { text: "Technique Name", value: "technique_name", isSelected: true },
    //     { text: "URL", value: "url", isSelected: true },
    //     { text: "User", value: "user", isSelected: true },
    //     { text: "User Domain", value: "user_domain", isSelected: true },

    // ])


    const classificationTypes = {
        "improbable": { "title": "Improbable", description: "Describes an event with a very low chance of happening, often due to robust security measures." },
        "unlikely": { "title": "Unlikely", description: "Refers to a situation with low odds of occurring, indicating a threat that’s not expected to happen frequently." },
        "benign true positive": { "title": "Benign True Positive", description: "Occurs when a security system correctly identifies a potential threat but later deems it harmless upon analysis." },
        "possible true positive": { "title": "Possible True Positive", description: "Signifies a valid detection where a security system identifies a potential threat requiring further investigation or action." },
    };

    useEffect(() => {
        let data = {
            "query": {
                "match_all": {}
            }
        }
        fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
            var licence = []
            hits?.map((item) => {
                licence.push(item?._source.tenant_id)
            })
            setTenandId(licence[0])
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [])

    useEffect(() => {
        getTableData()
            .then(({ data }) => {
                let head = [];
                Object.entries(data).map((item) => {
                    head.push({ label: item[0] });
                });
                setTableHead(head);

                // table more fields
                let checked = Object.entries(data).find((item) => item[0] === "Common");
                setCheckBox(checked[1]);

                let temp = checked[1]

                temp.map((item) => {
                    item.isSelected = false
                })

                var columns = data["Common"].map(v => ({ ...v, isSelected: false }));

                var tableColumns = {};
                var tempColumns = [...columns];


                for (var key in data) {
                    if (key !== 'Common') {
                        // console.log(key, "key ===");
                        tableColumns[key] = columns.map(v => ({ ...v }));
                        data[key].forEach((x) => {
                            tempColumns.some((y, index) => {
                                if (y.value === "user") {
                                    y.value = "user_name"
                                } else if (y.value === "host") {
                                    y.value = "host_name"
                                }
                                if (y.value === x.value) {
                                    tableColumns[key][index].isSelected = true;
                                    return true;
                                }
                                return false;
                            });
                        });
                    }
                }

                let storedData = localStorage.getItem('investigateCheckedTableColumns')

                if (storedData) {
                    setTableColumns(JSON.parse(storedData))
                    setTableHeader(JSON.parse(storedData)["ALL"])
                } else {
                    setTableColumns(tableColumns)
                    setTableHeader(tableColumns["ALL"])
                }

                setTableColumns(tableColumns)
                setTableHeader(tableColumns["ALL"])

                localStorage.setItem("investigateTableColumns", JSON.stringify(tableColumns))
                const selectedDataForTable = Object.entries(data).find(
                    (item) => item[0] === selectedDropItem
                );

                setInvestigationTableHead(selectedDataForTable[1]);
                // console.log("setInvestigationTableHead", selectedDataForTable[1])
                setInvestigationTableHead(selectedDataForTable[1]);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 6000);

        return () => { clearTimeout(timer); }// Clear the timer if the component unmounts
    }, []);

    console.log(showButton);

    const handleOpenModal = (display, status) => {
        setCloseShow(display);
        setDetectionStatusCheck(status)
    };

    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const handleDetailsClick = (index) => {
        if (expandedItems.includes(index)) {
            setExpandedItems(expandedItems.filter((i) => i !== index));
        } else {
            setExpandedItems([...expandedItems, index]);
        }
    };
    const isInList = (item) => {
        let myList = ['host_name', 'user_domain', 'user_name', 'dest_ip', 'source_ip', 'process_hash', 'process_name', 'process_hash_sha1', 'hash_sha256', 'user', 'client_ip', 'file_name', 'hash_sha1'];

        return myList.includes(item);
    };
    const expandAllCards = () => {
        const allCardsIndexes = indicatorsData.map((item, index) => index);
        setExpandedItems(allCardsIndexes);
        setIsAllItemsExpanded(true)
    };

    const closeAllCards = () => {
        setExpandedItems([]);
        setIsAllItemsExpanded(false)
    };

    const handleToggleChange = (e) => {
        e.target.checked === true ? expandAllCards() : closeAllCards()
    }


    function handleCheckboxChange(e) {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }

        let index = tableColumns[selectedDropItem].findIndex(x => x.value === value)


        tableColumns[selectedDropItem][index].isSelected = !tableColumns[selectedDropItem][index].isSelected
        setTableColumns(tableColumns)
        localStorage.setItem("ManageColumn.investigate.groupedview", JSON.stringify(tableColumns))
    }


    const handleInvestigationOpen = (tenandID, id, status) => {
        if (status !== "closed") {
            const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
            fpostC(`${baseURL}/cybot_proxy/${tenandID}/_update/${id}`, data).then((res) => {
                console.log("OPEN RSE", res)
            })
        }
    }

    const handleInputChange = (value) => {
        // setDetectionClosed(e.target.value)
        // setDetectionOpen(e.target.value)
        setDetectionClosed({
            comment: value
        })
        setDetectionOpen({
            comment: value
        })
    }

    const handleCloseSelectedDetections = (e) => {
        e.preventDefault();

        if (detectionStatusCheck === 'closed') {

            indicatorsData?.map(async (item) => {
                let data = { "doc": { "comment": detectionClosed?.comment, "external_investigation": externalInv, "detection_status": "closed" } }

                try {
                    await fpostC(`${baseURL}/cybot_proxy/thp_enriched_observables_${tenandId}/_update/${item._id}`, data);
                    setCloseShow(false)
                    setAlertMessage("Incident Closed")
                    setAlertPopup(true);
                    groupedInvestigationData?.map((group) => {
                        let data = { "doc": { "comment": detectionClosed?.comment, "external_investigation": externalInv, "closing_tag": closureCategory, "detection_status": "closed" } }
                        try {
                            fpostC(`${baseURL}/cybot_proxy/thp_group_${tenandId}/_update/${group._id}`, data);
                        } catch (err) {
                            console.error('Error', err);
                        }
                    })
                } catch (error) {
                    console.error('Error', error);
                    setAlertMessage("Failed")
                    setAlertPopup(true);
                }

            })
        } else {
            indicatorsData?.map(async (item) => {
                let data = { "doc": { "comment": detectionOpen?.comment, "detection_status": "open" } }

                try {
                    await fpostC(`${baseURL}/cybot_proxy/thp_enriched_observables_${tenandId}/_update/${item._id}`, data);
                    setCloseShow(false)
                    setAlertMessage("Incident Open")
                    setAlertPopup(true);
                    groupedInvestigationData?.map((group) => {
                        let data = { "doc": { "comment": detectionOpen?.comment, "closing_tag": "", "opened_by": localStorage.getItem("loggedUser"), "detection_status": "open" } }
                        try {
                            fpostC(`${baseURL}/cybot_proxy/thp_group_${tenandId}/_update/${group._id}`, data);
                        } catch (err) {
                            console.error('Error', err);
                        }
                    })
                } catch (error) {
                    console.error('Error', error);
                    setAlertMessage("Failed")
                    setAlertPopup(true);
                }

            })
        }
    };
    const [redirectItem, setRedirectItem] = useState('');
    const [redirectFieldName, setRedirectFieldName] = useState('');

    const [inputValue, setInputValue] = useState('');
    const [checkPermisionModalVisibility, setCheckPermisionModalVisibility] = useState(false)
    const handleCykitInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const hostname = window.location.hostname
    const [isOnPrem, setIsOnPrem] = useState(false)
    useEffect(() => {
      if(hostname.endsWith('knpc.com')){
        setIsOnPrem(true)
      }},[])
    const handleCheckPermision = (value) => {
        setCheckPermisionModalVisibility(true)
        const search = value;
        setInputValue(search)

        setRedirectItem(inputValue)
    };

    const redirectToCykit = () => {
        let start = moment(groupedInvestigationData[0]?._source?.detection_timestamp).subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
        let end = moment(groupedInvestigationData[0]?._source?.detection_timestamp).add(20, 'minutes').format("yyyy-MM-DDTHH:mm");
        const url = `/search/app/data-explorer/discover/#?_q=(filters:!(),query:(language:kuery,query:'${inputValue}'))&_a=(discover:(columns:!(log_source,action,sipv4,dipv4,user,targetuser),isDirty:!t,sort:!()),metadata:(indexPattern:'${indexId}',view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${start}',to:'${end}'))`
        window.open(url, '_blank');
        // navigate("/hunter/cykit", { state: { search: inputValue, dateFilter: groupedInvestigationData[0]?._source?.detection_timestamp } });
    }

    useEffect(() => {
        setGroupDetailsLoader(true)
        setIndicatorsDataLoader(true)

        groupedInvestigation(groupId ? groupId : id).then(({ data: { hits: { hits } } }) => {
            hits && setGroupedInvestigationData(hits)
            setGroupDetailsLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setGroupDetailsLoader(false)
        })

        falsePositive(groupId ? groupId : id).then(({ data: { hits: { hits } } }) => {
            hits && setIndicatorsData(hits)
            setIndicatorsDataLoader(false)
        }).catch((err) => {
            setIndicatorsDataLoader(false)
            console.log("Error", err)
        })
    }, [groupId, alertPopup])


    const createStateForNav = (item) => {
        return (
            <Link
                style={{ marginLeft: "20px" }}
                to={`/investigate/summary/${item._source.hunt_detection_id}`} state={{ sourceList: item._source, index_id: item }}
                onClick={() => handleInvestigationOpen(item._index, item?._id, item?._source?.detection_status)}
            >
                <TableViewMore Icon={Desktop} dataToggle={"Investigation Summary"} dataPlacement={"bottom"} />
            </Link>
        )
    }


    // case
    useEffect(() => {



        if (caseCreateSuccess === true) {
            setCaseShow(false)
            toastsSuccess("Case created successfully")

        }
        // setTimeout(() => {
        //   dispatch(resetReduxData())
        // }, 2000);


    }, [caseCreateSuccess])
    // Add Case
    const handleCreate = () => {
        setCaseShow(true);
    };



    // close invsetigation with comment 
    function handleCloseModal() {
        setCloseShow(false)
    }

    const handleClosePopup = () => {
        setAlertPopup(false);
    };

    function renderModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={(e) => handleCloseSelectedDetections(e)}>
                    <div className="row">
                        {/* <div>
                            <h6>Technique Name</h6>
                            <span>{state?.sourceList.technique_name}</span>
                        </div> */}
                        {/* <div>
                            <h6>Detection Name</h6>
                            <span>{selectedDetectionDetails?._source?.detection_name}</span>
                        </div> */}
                        {detectionStatusCheck === 'closed' &&
                            <>
                                <div className="form-group mt-2">
                                    <label className="control-label">Closure code</label>
                                    <select
                                        className="form-select input-lg"
                                        name="severity"
                                        onChange={(e) => setClosureCategory(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        <option value="True Positive - Confirmed Suspicious Activity">True Positive - Confirmed Suspicious Activity</option>
                                        <option value="Benign Positive - Validated">Benign Positive - Validated</option>
                                        <option value="Benign Positive - Expected">Benign Positive - Expected</option>
                                        <option value="False Positive - Data Error">False Positive - Data Error</option>
                                        <option value="False Positive - Incorrect Alert Logic">False Positive - Incorrect Alert Logic</option>

                                    </select>

                                </div>

                                <div className="form-group mt-2">
                                    <label className="control-label">External Investigation</label>
                                    <select
                                        className="form-select input-lg"
                                        name="closing_tag"
                                        onChange={(e) => setExternalInv(e.target.value)}
                                        // onChange={(e) => changeSeverity(e, defSev)}
                                        // value={severity}
                                        required
                                    >
                                        <option value="">Select</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>

                                    </select>

                                </div>
                            </>
                        }

                        <div className="form-group d-flex">
                            <div className="col-md-12 col-xs-12 p-1 mb-5">
                                <label className="control-label">Comments</label>
                                {detectionStatusCheck === 'closed' ?
                                    <ReactQuill
                                        value={detectionClosed.comment}
                                        onChange={(value) => handleInputChange(value)}
                                    />
                                    // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed?.comment} onChange={handleInputChange} required ></textarea>
                                    :
                                    <ReactQuill
                                        value={detectionOpen.comment}
                                        onChange={(value) => handleInputChange(value)}
                                    />
                                    // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen?.comment} onChange={handleInputChange} required ></textarea>
                                }
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        <div className="form-group d-flex justify-content-end">
                            {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
                            <Button className="btn-primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Create</button>
              <button type="button" className="btn btn-secondary">Close</button> */}
                </form>
            </div>
        )
    }

    return (
        <div
            className="col-12 p-4 position-relative rounded-3 flex-column"
            style={styles.section1}
        >
            {/* <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                style={styles.filterWrap}
            >
                <div className="position-relative">
                    <Form>
                        <Form.Check
                            className="mb-0"
                            type="switch"
                            id="toggle_detections_switch"
                            label={isAllItemsExpanded ? "Collapse all" : "Expand all"}
                            onChange={handleToggleChange}
                        />
                    </Form>
                </div>


            </div> */}
            <div className="detections col-12 h-100">
                <div className="col-12 d-flex flex-row gap-3 h-100">
                    <div className="col-4 p-3 border rounded-3" style={styles.contentWrap}>
                        {groupDetailsLoader ? (
                            <div className="d-flex flex-fill">
                                <SingleLoader />
                            </div>
                        ) : (
                            <div className="d-flex gap-3 flex-column flex-fill rounded-3">

                                <div className="col-12 rounded-3 deletion-file-container">
                                    {/* <h5>Grouped Investigation</h5> */}
                                    {console.log(groupedInvestigationData[0])}
                                    {groupedInvestigationData.length > 0 && (
                                        <div class="col-12">
                                            <div className='col-12 p-2'>
                                                <h5>About the Incident</h5>
                                            </div>

                                            <div className="modal-row-item">
                                                <span className="modal-label col-3">Incident ID</span>
                                                <span className="modal-text">{groupedInvestigationData[0]?._source?.grp_id}</span>
                                            </div>
                                            {(groupedInvestigationData[0]?._source?.conclusions && groupedInvestigationData[0]?._source?.conclusions["Alert Name"]) &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Incident name</span>
                                                    <span class="modal-text" dangerouslySetInnerHTML={{ __html: groupedInvestigationData[0]?._source?.conclusions["Alert Name"] }} />
                                                </div>
                                            }
                                            <div className="modal-row-item">
                                                <span className="modal-label col-3">Status</span>
                                                {/* <span>{groupedInvestigationData[0]?._source?.detection_status}</span> */}

                                                <span className="modal-text">
                                                    {(groupedInvestigationData[0]?._source?.detection_status).toLowerCase() == "new" && (
                                                        <Badge bg="warning" className="p-2">Open</Badge>
                                                    )}
                                                    {(groupedInvestigationData[0]?._source?.detection_status).toLowerCase() == "closed" && (
                                                        <Badge bg="success" className="p-2">Closed</Badge>
                                                    )}
                                                    {(groupedInvestigationData[0]?._source?.detection_status).toLowerCase() == "open" && (
                                                        <Badge bg="warning" className="p-2">Open</Badge>
                                                    )}
                                                </span>
                                            </div>
                                            {(groupedInvestigationData[0]?._source?.comment) &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Comment</span>
                                                    {/* <span class="modal-text">{groupedInvestigationData[0]?._source?.comment}</span> */}
                                                    <span className="alert alert-warning px-2 py-0 mb-0  break-word" dangerouslySetInnerHTML={{ __html: groupedInvestigationData[0]?._source?.comment }} ></span>
                                                </div>
                                            }

                                            {(groupedInvestigationData[0]?._source?.closing_tag) &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Closure code</span>
                                                    <span class="modal-text">{groupedInvestigationData[0]?._source?.closing_tag}</span>
                                                </div>
                                            }
                                            <div className="modal-row-item">
                                                <span className="modal-label col-3">Date</span>
                                                <span className="modal-text">{getDateFromTimestamp(groupedInvestigationData[0]?._source?.detection_timestamp)}</span>
                                            </div>
                                            <div className="modal-row-item">
                                                <span className="modal-label col-3">Time</span>
                                                <span className="modal-text">{getTimeFromTimestamp(groupedInvestigationData[0]?._source?.detection_timestamp)}</span>
                                            </div>

                                            {groupedInvestigationData[0]?._source?.detailed_description &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Incident<br />summary</span>
                                                    <span className="modal-text">{rawHTML(groupedInvestigationData[0]?._source?.detailed_description)}</span>
                                                </div>
                                            }
                                            {groupedInvestigationData[0]?._source?.detection_severity &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Classification</span>
                                                    <span>
                                                        {(groupedInvestigationData[0]?._source?.threat_classification) === "Improbable" && (
                                                            <>
                                                                <Badge className="p-2" bg="success">{`${groupedInvestigationData[0]?._source?.threat_classification}`}</Badge>
                                                                <CustomToolTip
                                                                    content={<Info size={20} />}
                                                                    dataPlacement={"bottom"}
                                                                    dataToggle={
                                                                        <span>{classificationTypes["improbable"].description}</span>
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.threat_classification) === "Unlikely" && (
                                                            <div className="position-relative">
                                                                <Badge className="p-2" bg="warning">{`${groupedInvestigationData[0]?._source?.threat_classification} `}</Badge>
                                                                <CustomToolTip
                                                                    content={<Info size={20} />}
                                                                    dataPlacement={"bottom"}
                                                                    dataToggle={
                                                                        <span>{classificationTypes["unlikely"].description}</span>
                                                                    } />
                                                            </div>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.threat_classification) === "Benign True Positive" && (
                                                            <>
                                                                <Badge className="p-2" bg="danger">{`${groupedInvestigationData[0]?._source?.threat_classification}`}</Badge>
                                                                <CustomToolTip
                                                                    content={<Info size={20} />}
                                                                    dataPlacement={"bottom"}
                                                                    dataToggle={
                                                                        <span>{classificationTypes["benign true positive"].description}</span>
                                                                    } />
                                                            </>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.threat_classification) === "Possible True Positive" && (
                                                            <>
                                                                <Badge className="p-2" bg="danger">{`${groupedInvestigationData[0]?._source?.threat_classification}`}</Badge>
                                                                <CustomToolTip
                                                                    content={<Info size={20} />}
                                                                    dataPlacement={"bottom"}
                                                                    dataToggle={
                                                                        <span>{classificationTypes["possible true positive"].description}</span>
                                                                    } />
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                            {groupedInvestigationData[0]?._source?.detection_severity &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Severity</span>
                                                    <span>
                                                        {(groupedInvestigationData[0]?._source?.detection_severity) === "Critical" && (
                                                            <>
                                                                <Badge className="p-2" bg="danger">{`${groupedInvestigationData[0]?._source?.detection_severity}`}</Badge>
                                                            </>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.detection_severity) === "High" && (
                                                            <div className="position-relative">
                                                                <Badge className="p-2" bg="danger">{`${groupedInvestigationData[0]?._source?.detection_severity} `}</Badge>
                                                            </div>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.detection_severity) === "Medium" && (
                                                            <>
                                                                <Badge className="p-2" bg="warning">{`${groupedInvestigationData[0]?._source?.detection_severity}`}</Badge>
                                                            </>
                                                        )}
                                                        {(groupedInvestigationData[0]?._source?.detection_severity) === "Low" && (
                                                            <>
                                                                <Badge className="p-2" bg="success">{`${groupedInvestigationData[0]?._source?.detection_severity}`}</Badge>
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            }

                                            {groupedInvestigationData[0]?._source?.detection_severity_value &&
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">Threat Score</span>
                                                    <div className='col-9 position-relative'>
                                                        <ProgressBar striped variant={"danger"}
                                                            now={groupedInvestigationData[0]?._source?.detection_severity_value} />
                                                        <label className="position-absolute"
                                                            style={{ top: "2px", left: "46%", fontSize: "10px" }}>
                                                            {groupedInvestigationData[0]?._source?.detection_severity_value + " /100"}
                                                        </label>
                                                    </div>
                                                </div>
                                            }

                                            <div className='col-12'>
                                                {groupedInvestigationData[0]?._source?.tag &&
                                                    <div className="modal-row-item">
                                                        <span className="modal-label col-3">Tags</span>
                                                        <div className="d-flex flex-wrap gap-2">
                                                            {groupedInvestigationData[0]?._source?.tag?.map((item) => (
                                                                <Badge bg="secondary" className="p-2">{item}</Badge>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {groupedInvestigationData[0]?._source?.same_group &&
                                                <div class="modal-row-item">
                                                    <span className="modal-label col-3">Same Group</span>
                                                    {typeof groupedInvestigationData[0]?._source?.same_group === "string" ? (
                                                        <span style={{ padding: "0.2rem 0.7rem", backgroundColor: globalStyles.greyDark3, color: "white", borderRadius: "4px" }} onClick={() => setGroupId(groupedInvestigationData[0]?._source?.same_group)}>{groupedInvestigationData[0]?._source?.same_group}</span>
                                                    ) : (
                                                        <div className="d-flex flex-wrap gap-2">
                                                            {groupedInvestigationData[0]?._source?.same_group?.map((item) => (
                                                                <Badge bg="secondary" className="p-2">{item}</Badge>
                                                            ))
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {groupedInvestigationData[0]?._source?.common_observables && (
                                                <>
                                                    <div className='col-12 mt-3 mx-2'>
                                                        <h5>Detection Observables</h5>
                                                    </div>
                                                    {/* Common Observables */}
                                                    <div className='col-12'>
                                                        {Object.entries(groupedInvestigationData[0]?._source?.common_observables).map(([key, value]) => (
                                                            // <div class="col-12 text-break">
                                                            <div class="modal-row-item flex-column">
                                                                <span class="modal-label">{key}</span>
                                                                {/* <span class="modal-text" style={{ color: "red" }}>{value}</span> */}
                                                                <div className='d-flex gap-2' >
                                                                    <span class="alert alert-warning px-2 py-0 mb-0  break-word">{isJSONObject(value) ? Object.values(value)?.[0] : value}</span>
                                                                    {isOnPrem &&isInList(key) && <MagnifyingGlass size={16} onClick={() => { handleCheckPermision(isJSONObject(value) ? Object.values(value)?.[0] : value) }} />}
                                                                </div>
                                                            </div>
                                                            // </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                            {groupedInvestigationData[0]?._source?.conclusions && (
                                                <div className='col-12 mt-3 mx-2'>
                                                    <h5>Conclusion</h5>
                                                    {/* {item?._source?.common_observables && (
                                                            <span className="modal-text">{rawHTML(item?._source?.conclusion)}</span>
                                                        )} */}

                                                    {/* {console.log("item?._source?.conclusions", item?._source?.conclusions)} */}

                                                    {groupedInvestigationData[0]?._source?.conclusions && Object.entries(groupedInvestigationData[0]?._source?.conclusions).map(([key, value]) => (
                                                        // <div class="col-12 text-break">
                                                        <>
                                                            {(key != "Threat_Score" && key != "Severity level") &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">{key}</span>
                                                                    <span className="modal-text" dangerouslySetInnerHTML={{ __html: value }} />
                                                                </div>
                                                            }
                                                        </>
                                                        // </div>
                                                    ))}

                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>


                    <div
                        className="d-flex flex-fill position-relative p-3 border rounded-3"
                        style={styles.section2}
                    >
                        {indicatorsDataLoader ? (
                            <div className="col-12">
                                <TableLoader rowCount={15} />
                            </div>
                        ) : (
                            <div className='col-12 position-relative'>
                                <div
                                    className="d-flex flex-row align-items-center justify-content-end ml-auto"
                                    style={styles.filterWrap}
                                >
                                    <div className="position-relative d-flex gap-2">
                                        {showButton ?
                                            <IncidentDownload groupedInvestigationData={groupedInvestigationData} tableHeader={tableHeader} indicatorsData={indicatorsData} />
                                            : <Button variant="light" disabled>Loading...</Button>}
                                        <Button
                                            variant="light"
                                            ref={ref}
                                            onClick={() => setShowManageColumn(!showManageColumn)}
                                        >
                                            Manage Columns
                                        </Button>
                                        <Button
                                            variant="light"
                                            onClick={(e) => handleCreate()}
                                        >
                                            Create Case
                                        </Button>

                                        {groupedInvestigationData[0]?._source?.detection_status !== "closed" && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleOpenModal(true, 'closed')}
                                            // disabled={indicatorsData?.map((item) => item?._source?.detection_status === "closed")}
                                            >
                                                Close Incident
                                            </Button>
                                        )}

                                        {groupedInvestigationData[0]?._source?.detection_status !== "open" && (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleOpenModal(true, 'open')}
                                            // disabled={indicatorsData?.map((item) => item?._source?.detection_status === "closed")}
                                            >
                                                Open Incident
                                            </Button>
                                        )}

                                        {showManageColumn && (
                                            <div
                                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    zIndex: "111",
                                                    minWidth: "20rem",
                                                    maxHeight: "50vh",
                                                    overflow: "auto"
                                                }}
                                            >
                                                <div className="table-fields">
                                                    {tableHeader?.map((item) => (
                                                        <div className="d-flex align-items-center py-2 gap-2">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value={item.value}
                                                                    onChange={handleCheckboxChange}
                                                                    checked={item.isSelected ? true : false}
                                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                                />
                                                                <label className="form-check-label" for="flexCheckDefault">
                                                                    {item.text}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>

                                {/* {indicatorsData.length || indicatorsData.length !== 0 ? (
                                            <ul className="timeline d-flex flex-fill gap-3 flex-column pb-4" style={{ top: "4rem" }}>
                                                {indicatorsData.map((item, index) => (
                                                    <li
                                                        className="d-flex flex-row gap-3 justify-content-center position-relative"
                                                        style={styles.detectionItem}

                                                    >
                                                        <span className="dateLabel" style={{ left: 0, top: 0 }}>
                                                            {getTimeFromTimestamp(item?._source?.detection_timestamp)}
                                                        </span>
                                                        <div className="d-flex col-12 gap-3 flex-column px-3">
                                                            <p className="timeline-item p-3 rounded-3 cursor-pointer" style={{ position: "relative" }} onClick={() => handleDetailsClick(index)}>
                                                                {rawHTML(item?._source?.description)}
                                                                <span>
                                                                    {expandedItems.includes(index) && (
                                                                        <div className="p-3 bg-light card mt-2">
                                                                            {Object.entries(item?._source).map(([key, value]) => (
                                                                                key !== "field" && (
                                                                                    // <div key={key} className="col-12 text-break">
                                                                                    <div className="modal-row-item">
                                                                                        <span className="modal-label col-3">{key}</span>
                                                                                        <span className="modal-text text-break">{value}</span>
                                                                                    </div>
                                                                                    // </div>
                                                                                )
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </p>

                                                            <Link
                                                                style={{ marginLeft: "20px", position: "absolute", right: "-15px", top: "20px" }}
                                                                onClick={() => { setExpClickedNodeLog(item?._source?.id); setExpCloseLog(false) }}
                                                            >
                                                                <TableViewMore Icon={FileSearch} dataToggle={"View Raw Log"} dataPlacement={"bottom"} />
                                                            </Link>

                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )} */}
                                <div className='col-12 d-flex flex-column rounded-3 bg-white position-relative'>
                                    <Tabs
                                        defaultActiveKey="Indicators"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="Indicators"
                                            title={
                                                <React.Fragment>
                                                    Detections
                                                    <Badge className="ml-2" bg="info">
                                                        {indicatorsData.length}
                                                    </Badge>
                                                </React.Fragment>
                                            }
                                        >
                                            <div className="col-12" style={styles.tableWrap}>
                                                {false ? (
                                                    <TableLoader rowCount={30} />
                                                ) : (
                                                    indicatorsData.length !== 0 ? (
                                                        <Table borderless hover>
                                                            <thead className="fixed-head">
                                                                <tr>
                                                                    {/* <th>Description</th> */}
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    {tableHeader.map((item) => (
                                                                        item.value != "detection_timestamp" &&
                                                                        item.value != "source_description" &&
                                                                        // item.value != "detection_severity" &&
                                                                        item.isSelected === true &&
                                                                        <th>{item.text}</th>
                                                                    ))}
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {indicatorsData?.map((item, index) => (
                                                                    <tr>

                                                                        {/* <td>
                                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                            <span className="position-relative">
                                                                                                {rawHTML(item?._source?.source_description)}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td> */}
                                                                        {/* {console.log("item", item)} */}
                                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.detection_timestamp)}</td>
                                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.detection_timestamp)}</td>
                                                                        {tableHeader?.map(
                                                                            (head) =>
                                                                                head.isSelected === true &&
                                                                                head.value != "detection_timestamp" &&
                                                                                head.value != "source_description" &&
                                                                                // head.value != "detection_severity" &&
                                                                                (
                                                                                    <td>
                                                                                        {head.value === "detection_status" && (
                                                                                            <span>
                                                                                                {item._source[head.value] == "new" && (
                                                                                                    <Badge bg="info" className="p-2">New</Badge>
                                                                                                )}
                                                                                                {item._source[head.value] == "closed" && (
                                                                                                    <Badge bg="success" className="p-2">Closed</Badge>
                                                                                                )}
                                                                                                {item._source[head.value] == "open" && (
                                                                                                    <Badge bg="warning" className="p-2">Open</Badge>
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                        {head.value === "detection_severity" && (
                                                                                            <span>
                                                                                                <span>
                                                                                                    {item._source[head.value] == "Critical" && (
                                                                                                        <Badge bg="danger" className="p-2">Critical</Badge>
                                                                                                    )}
                                                                                                    {item._source[head.value] == "High" && (
                                                                                                        <Badge bg="danger" className="p-2">High</Badge>
                                                                                                    )}
                                                                                                    {item._source[head.value] == "Medium" && (
                                                                                                        <Badge bg="warning" className="p-2">Medium</Badge>
                                                                                                    )}
                                                                                                    {item._source[head.value] == "Low" && (
                                                                                                        <Badge bg="success" className="p-2">Low</Badge>
                                                                                                    )}
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                        {head.value === 'threat_score' && (
                                                                                            <span>
                                                                                                {parseFloat(item?._source?.threat_score).toFixed(2)}
                                                                                            </span>
                                                                                        )}
                                                                                        {head.value !== "detection_severity" && head.value !== "detection_status" && head.value !== 'threat_score' && (
                                                                                            <span>{item._source[head?.value]}</span>
                                                                                        )}
                                                                                    </td>
                                                                                )
                                                                        )}
                                                                        <td className="text-right">
                                                                            <div className='d-flex gap-3'>
                                                                                {createStateForNav(item)}

                                                                                <Link
                                                                                    onClick={() => { setExpClickedNode(item?._id); setExpClose(false) }}
                                                                                >
                                                                                    <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                                                </Link>


                                                                                <Link
                                                                                    onClick={() => { setExpClickedNodeLog(item?._source?.id); setExpClickedIndex(item?._source?.index); setExpCloseLog(false) }}
                                                                                >
                                                                                    <TableViewMore Icon={FileSearch} dataToggle={"View Raw Log"} dataPlacement={"bottom"} />
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>

            {alertPopup && (
                <AlertBox
                    setAlertPopup={setAlertPopup}
                    alertPopup={alertPopup}
                    message={alertMessage}
                    close={handleClosePopup}
                    setOutTime={5000}
                    bgColor={"lightGreen"}
                />
            )}

            {
                closeShow && (
                    <CustomModal
                        title={detectionStatusCheck === "closed" ? "Close incident" : "Open Incident"}
                        ShowOrHide={closeShow}
                        bodyData={renderModal}
                        handleClose={handleCloseModal}
                        modalSize={"md"}
                        footer={false}
                        downloadBtn={false}
                        noBodyPadding={true}
                        overflowHidden={true}
                        modalPosition={"right"}
                        outsideClickDisabled={true}

                    ></CustomModal>
                )
            }

            {
                caseshow && (
                    <EditModal
                        setShow={setCaseShow}
                        show={caseshow}
                        autoHeight={false}
                        caseidEdit={case_id}
                        setCase_ID={setCase_ID}
                        module='inv-grouped'
                        preModule='INV-INC'
                        idd={id}
                        id={groupedInvestigationData[0]?._source?.conclusions["Alert Name"]}
                        description_={groupedInvestigationData[0]?._source?.detailed_description}
                        severity_={groupedInvestigationData[0]?._source?.detection_severity}
                    />
                )
            }
            <Modal show={checkPermisionModalVisibility} onHide={() => setCheckPermisionModalVisibility(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to proceed?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h6>You’re about to search the below keyword across the logs in cykit for hunter</h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                        <span>Keyword</span>
                        <input
                            style={{ marginTop: '4px' }}
                            type="text"
                            value={inputValue}
                            onChange={handleCykitInputChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCheckPermisionModalVisibility(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={redirectToCykit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={localStorage.getItem("CYBOT_URL")} pattern={`cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`} req={fpostC} />
            <ExpandedDocument title={"Raw Log"} clickedNode={expclickedNodeLog} close={expcloseLog} setClick={setExpClickedNodeLog} url={localStorage.getItem("DATALAKE_URL")} pattern={expClickedIndex !== undefined ? `${expClickedIndex}/_doc/${expclickedNodeLog}` : 'logs-*,winlogbeat*/_search?track_total_hits=true'} req={expClickedIndex !== undefined ? fgetCy : fpostCy} />
        </div >



    )

}





export default GroupedInvestigationView