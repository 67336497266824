import React, { useEffect, useState } from "react";
import {
  Form
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";

import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { useParams } from 'react-router-dom';

import { fpostCy } from "../../../axios/axios";

import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const IndexHealth = () => {
  const datalakeUrl = localStorage.getItem("DATALAKE_URL")
  const [timeFrame, setTimeFrame] = useState("1h");
  const [graphPreData, setGraphPreData] = useState([]);
  const { index_name } = useParams();
  const dayFormat = {
    unit: 'day',
    unitStepSize: 1,
    displayFormats: {
      'millisecond': 'MMM DD',
      'second': 'MMM DD',
      'minute': 'MMM DD',
      'hour': 'MMM DD',
    }
  }


  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
      //   tooltip: {
      //     callbacks: {
      //       label: (tooltipItem) => {
      //         const start = moment(JSON.parse(tooltipItem.formattedValue)[0]).format("HH:mm");
      //         const day = moment(tooltipItem.raw.x).format('ddd')
      //         const end = moment(JSON.parse(tooltipItem.formattedValue)[1]).format("HH:mm");
      //         return `${day} Start Time: ${start}\nEnd Time: ${end}`;
      //       },
      //       title: (tooltipItem) => {

      //         const day = moment(tooltipItem[0].label).format('ddd')
      //         return `${tooltipItem[0].label} ${day}`;
      //       }
      //     }
      //   }
    },
    scales: {
      xAxis: {
        // The axis for this scale is determined from the first letter of the id as `'x'`
        // It is recommended to specify `position` and / or `axis` explicitly.
        type: 'time',
        time: timeFrame === "1d" ? dayFormat : {}
      }
      //   x: {
      //     type: 'time',
      //     suggestedMin: "00:00",
      //     suggestedMax: "23:59",
      //     time: {
      //       parser: 'dd:HH:mm',
      //       unit: 'hour',
      //       stepSize: 0.2,
      //       displayFormats: {
      //         'minute': 'dd:HH:mm',
      //         'hour': 'dd:HH:mm'
      //       }
      //     },
      //     ticks: {
      //       source: 'auto',
      //       maxRotation: 0,
      //       autoSkip: true
      //     }
      //   }
    },

  };
  const graphData = {
    datasets: [
      {
        label: "Ingested Log Count",
        data: graphPreData,
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.1
      },
    ],

  };
  useEffect(() => {
    let body = {
      "size": 0,
      "query": {
        "range": {
          "@timestamp": {
            "gte": timeFrame === "1d" ? "now-7d" : "now-1d",
            "lte": "now"
          }
        }
      },
      "aggs": {
        "docs_over_time": {
          "date_histogram": {
            "field": "@timestamp",      // Replace with your timestamp field
            "calendar_interval": timeFrame, // Adjust the interval as needed
            "format": "yyyy-MM-dd:HH:mm:ssZ"
          },
          "aggs": {
            "total_docs": {
              "value_count": {
                "field": "_index"  // You can use any field here
              }
            }
          }
        }
      }
    }
    fpostCy(`${datalakeUrl}/${index_name}*/_search`, body).then(({ data }) => {
      setGraphPreData(data.aggregations.docs_over_time.buckets.map(item => { return { x: moment(item.key_as_string), y: item.doc_count } }))

    }).catch((err) => {
      console.log("Error", err)
    })
  }, [timeFrame,])

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <div
        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
      >
      </div>

      <div className="detections col-12">
        <div className="d-flex flex-row justify-content-between">
          <h4>Log Ingestion Health of {index_name}*</h4>
          <Form.Select
            style={{ "width": "100px" }}
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.currentTarget.value)}
          >
            <option value="1h">24 Hours</option>
            <option value="1d">7 days </option>
          </Form.Select>
        </div>
        <div className="col-12" style={styles.tableWrap}>
          {graphData.length !== 0 ? (
            <Line options={graphOptions} data={graphData} />
          ) : (
            <NoDataFound errorText={"No data Found"} />
          )}
        </div>

      </div>


    </div>
  );
};

export default IndexHealth;
