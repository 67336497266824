import moment from "moment";
import { unparse } from "papaparse";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomToolTip from "../components/ToolTip/CustomToolTip";

let edr = localStorage.getItem("EDR_TYPE");

export function timestampConvert(timestamp, format) {
  return moment(timestamp).format(format);
}
export function uebaUsualTimeConvert(timestamp) {
  const parsedTime = moment(`${timestamp}:00 PM`, 'h:mm');
  return parsedTime
  // return moment(timestamp, "hh").format("LT");
}
export function getTimeFromTimestamp(timestamp) {
  return moment(timestamp).format("hh:mm:ss A");
}
export function getDateFromTimestamp(timestamp) {

  return moment(timestamp).format("DD MMM YYYY");
}
export function getUtc(time){
  return moment(time).utc().toISOString();
}

export function getLocal(time){
  return time
}

export function getTimeDifferenceFromTimestamp(timestamp) {
  const localTimestamp = moment
    .utc(timestamp)
    .local()
    .format("YYYY-MM-DD hh:mm:ss A");
  let currentTime = moment();
  const timeDifference = moment.duration(currentTime.diff(localTimestamp));
  const timeInHours = Math.round(timeDifference.asHours());

  return timeInHours;
}

export function formatDate(date) {
  return moment(date.split("/").reverse().join("-")).format("DD MMM YYYY");
}
export function dateFormat(date) {
  return moment(date.split("-").reverse().join("-")).format("DD MMM YYYY");
}
export function unixTimestampToDate(timestamp) {
  // Unix timestamp is in seconds
  // JavaScript Date object expects milliseconds, so we convert
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript
  const day = date.getDate();

  // Format as YYYY-MM-DD
  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  return formattedDate;
}
export function numberToLetter(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
export function numberToText(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + " Thousand";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + " Million";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + " Billion";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + " Trillion";
}
export function mdyt(timestamp) {
  return moment(timestamp)
    .tz("UTC")
    .tz("America/New_York")
    .format("MMMM Do YYYY, h:mm:ss a");
}

// regex

export function escapeHtmlChars(data, pattern) {
  return data?.replace(pattern, "");
}

export function removeHtmlChars(data) {

  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  return data ? data?.replace(htmlRegexG, "") : null;
}



export function rawHTML(text) {
  return <p className="mb-0" dangerouslySetInnerHTML={{ __html: text }}></p>;
}

export function getLastWordFromString(x) {
  return x?.split(" ").pop();
}

export const genstrut = (field) => {
  if (field.type === "contains") {
    const must = {
      wildcard: {},
    };
    const wildval = {
      value: `*${field.selectedValue}*`,
      case_insensitive: true,
    };
    must.wildcard[field.column.value] = wildval;

    return must;
  }
  if (field.type === "not contains") {
    const must = {
      wildcard: {},
    };
    const wildval = {
      value: `*${field.selectedValue}*`,
      case_insensitive: true,
    };
    must.wildcard[field.column.value] = wildval;
    return must;
  } else if (field.type === "is") {
    var must = {
      term: {},
    };
    if (field.column.value === "severity" || field.column.value === "detection_status") {
      var match = {
        match: {
          [field.column.value]: field.selectedValue,
        },
      };
      return match;
    }
    must.term[field.column.value] = field.selectedValue;
    if (field.column.value === "user.name") {
      var must = {
        match: {
          "user.name": field.selectedValue,
        },
      };
    }
    if (field.column.value === "event.original") {
      var must = {
        query_string: {
          query: `*${field.selectedValue}*`,
        },
      };
    }
    if (edr === "elastic" && field?.isGlobal) {
      var must = {
        multi_match: {
          "type": "best_fields",
          "query": field.selectedValue,
          "lenient": true
        }
      };
      return must;
    } else if (edr === "atp" && field?.isGlobal) {
      var must = {
        multi_match: {
            "type": "best_fields",
            "query": field.selectedValue,
            "lenient": true
        }
      };
      return must;
    }
    return must;
  } else if (field.type === "is not") {
    const must = {
      term: {},
    };
    must.term[field.column.value] = field.selectedValue;
    if (field.column.value === "severity" || field.column.value === "detection_status") {
      var match = {
        match: {
          [field.column.value]: field.selectedValue,
        },
      };
      return match;
    }
    return must;
  }
};
export const numfields = ["process.pid", "destination.port", "source.port"];
export const getProcessListQuery = (
  fieldList,
  date,
  count,
  sort,
  page,
  index_type,
  is_anomaly
) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [],
      },
    },
  };
  if (fieldList?.length > 0) {
    var data = {
      query: {
        bool: {
          must: [],
          filter: [],
          must_not: [],
          should: [],
        },
      },
    };
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }
    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);
        console.log("test1", distItem);
        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }
  var sortQ = [
    {
      "@timestamp": {
        order: sort,
      }
    }
  ];
  if (index_type?.length > 0) {
    if (edr === "elastic") {
      const mustCat = {
        term: {
          "event.category": index_type,
        },
      };

      data.query.bool.must.push(mustCat);
    } else if (edr === "atp") {
      const mustCat = {
        term: {
          category: index_type,
        },
      };

      data.query.bool.must.push(mustCat);
    }
  }

  data.size = count;
  data.from = page;
  data.track_total_hits = true;
  data.sort = sortQ;

  if (date) {

    if (date?.from) {
      var range = {
        range: {
          "@timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "@timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "@timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
    //   if(!date?.to && !date?.from)
    //  {
    //     var range = {
    //       range: {
    //         "@timestamp": {
    //           gte: "now-1d/d",
    //           lte: "now",
    //         },
    //       },
    //     };
    //     data.query.bool.filter.push(range);
    //   }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: "now-1d/d",
  //         lte: "now",
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }
  if (is_anomaly && is_anomaly === true) {
    var fil = {
      match_phrase: {
        Result: "-1",
      },
    };
    data.query.bool.must.push(fil);
  }

  return data;
};

export const dateDMY = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}/${month}/${year}`;
  return currentDate;
};

export const uebaLabelList = [
  {
    value: "Logon detected outside of usual time for host",
    label: "Logon detected outside of usual time for host",
  },
  {
    value: "Logon detected outside of usual time for user",
    label: "Logon detected outside of usual time for user",
  },
  {
    value: "Logon  detected to unusual host for user",
    label: "Logon detected to unusual host for user",
  },
  {
    value: "File activities detected outside of usual time for user",
    label: "File activities detected outside of usual time for user",
  },
  {
    value: "Management activities detected outside of usual time for user",
    label: "Management activities detected outside of usual time for user",
  },
  {
    value:
      "Unusually high number of file modification activities detected for user",
    label:
      "Unusually high number of file modification activities detected for user",
  },
  {
    value: "Unusually high number of management activities detected for user",
    label: "Unusually high number of management activities detected for user",
  },
  {
    value:
      "Unusually high number of file deletion activities detected for user",
    label:
      "Unusually high number of file deletion activities detected for user",
  },
  {
    value:
      "Unusually high number of account lockout activity detected for domain",
    label:
      "Unusually high number of account lockout activity detected for domain",
  },
  {
    value: "Unusually high number of file activities detected for user",
    label: "Unusually high number of file activities detected for user",
  },
  {
    value: "Unusually high number of authentication failures detected for host",
    label: "Unusually high number of authentication failures detected for host",
  },
  {
    value: "Unusually high number of authentication failures detected for user",
    label: "Unusually high number of authentication failures detected for user",
  },
  {
    value: "Internal network traffic detected outside of usual time for host",
    label: "Internal network traffic detected outside of usual time for host",
  },
  {
    value: "Internal network traffic detected outside of usual time for user",
    label: "Internal network traffic detected outside of usual time for user",
  },
  {
    value: "External network traffic detected outside of usual time for host",
    label: "External network traffic detected outside of usual time for host",
  },
  {
    value: "External network traffic detected outside of usual time for user",
    label: "External network traffic detected outside of usual time for user",
  },

  {
    value: "Office 365 Authentication failure at unusual time",
    label: "Office 365 Authentication failure at unusual time",
  },

  {
    value: "Unusually high number of Office 365 Authentication failures",
    label: "Unusually high number of Office 365 Authentication failures",
  },

  {
    value: "Office 365 Authentication Success at unusual time",
    label: "Office 365 Authentication Success at unusual time",
  },

  {
    value: "Unusually high number of file preview activities",
    label: "Unusually high number of file preview activities",
  },

  {
    value: "File downloaded from Onedrive at unusual time",
    label: "File downloaded from Onedrive at unusual time",
  },

  {
    value: "Unusually high number of file downloaded from Onedrive",
    label: "Unusually high number of file downloaded from Onedrive",
  },

  {
    value: "File preview activities on Onedrive detected outside of usual time",
    label: "File preview activities on Onedrive detected outside of usual time",
  },

  {
    value: "Unusually high number of email activities detected",
    label: "Unusually high number of email activities detected",
  },

  {
    value: "Email activities detected outside of usual time",
    label: "Email activities detected outside of usual time",
  },

  // {
  //   value: "Internal network transfer detected outside of usual time for user",
  //   label: "Internal network transfer detected outside of usual time for user",
  // },
  // {
  //   value: "External network transfer detected outside of usual time for user",
  //   label: "External network transfer detected outside of usual time for user",
  // },
  // {
  //   value: "Unusually high number of logon failures detected for host",
  //   label: "Unusually high number of logon failures detected for host",
  // },
  // {
  //   value: "Internal network transfer detected outside of usual time for host",
  //   label: "Internal network transfer detected outside of usual time for host",
  // },
  // {
  //   value: "External network transfer detected outside of usual time for host",
  //   label: "External network transfer detected outside of usual time for host",
  // },
  // {
  //   value: "Unusually high number of logon failures detected for user",
  //   label: "Unusually high number of logon failures detected for user",
  // },

  {
    value: "Unusual services",
    label: "Unusual services",
  },

  {
    value: "Geolocation change detected",
    label: "Geolocation change detected",
  },
];

export const exportCSVFile = (headers, items, fileTitle) => {
  fileTitle = fileTitle ?? "Exported";

  let csvHeader = unparse({
    fields: [...headers.map((item) => item.name)],
    data: [],
  });

  let csvVal = unparse(items, {
    header: false,
    columns: [...headers.map((item) => item.name)],
  });

  let csv = csvHeader + csvVal;

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
export const isEmptyObject = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const genstrutTimeline = (field) => {
  if (field.type === "contains") {
    const must = {
      wildcard: {},
    };
    const wildval = {
      value: `*${field.selectedValue}*`,
      case_insensitive: true,
    };
    must.wildcard[field.column.value] = wildval;

    if (field.column.value === "user.name") {
      var mustq = {
        bool: {
          should: [
            {
              wildcard: {
                "user.name": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "winlog.event_data.TargetUserName": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "winlog.event_data.SubjectUserNamee": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                UserId: {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.from.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.receiver.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      };
      return mustq;
    }
    return must;
  }
  if (field.type === "not contains") {
    const must = {
      wildcard: {},
    };
    const wildval = {
      value: `*${field.selectedValue}*`,
      case_insensitive: true,
    };
    must.wildcard[field.column.value] = wildval;
    if (field.column.value === "user.name") {
      var mustq = {
        bool: {
          should: [
            {
              wildcard: {
                "user.name": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "winlog.event_data.TargetUserName": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "winlog.event_data.SubjectUserNamee": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                UserId: {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.from.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.receiver.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      };
      return mustq;
    }

    return must;
  } else if (field.type === "is") {
    var must = {
      term: {},
    };
    must.term[field.column.value] = field.selectedValue;
    if (field.column.value === "user.name") {
      var must = {
        bool: {
          should: [
            { term: { "user.name": { value: field.selectedValue } } },
            {
              wildcard: {
                message: {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                UserId: {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.from.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.receiver.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      };
      return must;
    }

    return must;
  } else if (field.type === "is not") {
    var must = {
      term: {},
    };
    must.term[field.column.value] = field.selectedValue;
    if (field.column.value === "user.name") {
      var must = {
        bool: {
          should: [
            {
              wildcard: {
                "user.name": {
                  value: field.selectedValue,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                message: {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.from.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
            {
              wildcard: {
                "email.receiver.address": {
                  value: `*${field.selectedValue}*`,
                  case_insensitive: true,
                },
              },
            },
          ],
          minimum_should_match: 1,
        },
      };
      return must;
    }
    return must;
  }
};
const getTypeCat = (type) => {
  var index_type = "";
  if (edr === "atp") {
    if (type === "process") {
      index_type = "AdvancedHunting-DeviceProcessEvents";
    } else if (type === "file") {
      index_type = "AdvancedHunting-DeviceFileEvents";
    } else if (type === "network") {
      index_type = "AdvancedHunting-DeviceNetworkEvents";
    } else if (type === "registry") {
      index_type = "AdvancedHunting-DeviceRegistryEvents";
    } else if (type === "authentication") {
      index_type = "AdvancedHunting-DeviceLogonEvents";
    } else {
      index_type = "";
    }
  } else if (edr === "elastic") {
    if (type === "process") {
      index_type = "process";
    } else if (type === "file") {
      index_type = "file";
    } else if (type === "network") {
      index_type = "network";
    } else if (type === "registry") {
      index_type = "registry";
    } else if (type === "authentication") {
      index_type = ["authentication", "iam"];
      return index_type
    } else {
      index_type = "";
    }
  }
  return type;
};

const getEventCode = (type) => {
  if (type === "Account") {
    var codes = [
      4720, 4722, 4723, 4724, 4725, 4726, 4727, 4728, 4729, 4730, 4731, 4732,
      4733, 4734, 4735, 4737, 4738, 4739, 4740, 4741, 4742, 4743, 4744, 4745,
      4746, 4747, 4748, 4749, 4750, 4751, 4752, 4753, 4754, 4755, 4756, 4757,
      4758, 4759, 4760, 4761, 4762, 4763, 4764, 4765, 4766, 4767, 4780, 4781,
      4782, 4783, 4784, 4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793,
      4794, 4797, 4798, 4799, 5376, 5377,
    ];
    return codes;
  } else if (type === "Logonoff") {
    var codes = [
      4624, 4625, 4626, 4627, 4634, 4646, 4647, 4648, 4649, 4650, 4651, 4652,
      4653, 4654, 4655, 4672, 4675, 4778, 4779, 4800, 4801, 4802, 4803, 4964,
      4976, 4977, 4978, 4979, 4980, 4981, 4982, 4983, 4984, 5378, 5451, 5452,
      5453, 5632, 5633, 6272, 6273, 6274, 6275, 6276, 6277, 6278, 6279, 6280,
    ];
    return codes;
  } else if (type === "Objectaccess") {
    var codes = [
      4656, 4657, 4658, 4659, 4660, 4661, 4663, 4664, 4665, 4666, 4667, 4668,
      4670, 4671, 4690, 4691, 4698, 4699, 4700, 4701, 4702, 4818, 4868, 4869,
      4870, 4871, 4872, 4873, 4874, 4875, 4876, 4877, 4878, 4879, 4880, 4881,
      4882, 4883, 4884, 4885, 4886, 4887, 4888, 4889, 4890, 4891, 4892, 4893,
      4894, 4895, 4896, 4897, 4898, 4899, 4900, 4985, 5031, 5120, 5140, 5142,
      5143, 5144, 5145, 5148, 5149, 5150, 5151, 5152, 5153, 5154, 5155, 5156,
      5157, 5158, 5159, 5168, 5888, 5889,
    ];
    return codes;
  }
};

export const getTimeLineQuery = (
  fieldList,
  date,
  count,
  sort,
  page,
  index_type,
  is_anomaly,
  is_unified,
  showSystem,
  selectedData,
  kqlQuery
) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          }
        ],
        should: [],
      },
    },
  };
  if (fieldList?.length > 0) {
    var data = {
      query: {
        bool: {
          must: [],
          filter: [],
          must_not: [
            {
              match_phrase: {
                cybSourceCountry: "%{[geoip][geo][country_name]}",
              },
            }
          ],
          should: [],
        },
      },
    };
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }
    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);
        console.log("test1", distItem);
        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrutTimeline(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrutTimeline(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrutTimeline(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrutTimeline(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (index === 0) {
            var must = genstrutTimeline(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrutTimeline(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrutTimeline(field);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let sindex = 0;
          let index = 0;
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrutTimeline(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrutTimeline(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (index === 0) {
            var must = genstrutTimeline(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrutTimeline(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }
  var sortQ = [
    {
      "@timestamp": {
        order: sort,
        unmapped_type: "boolean",
      },
    },
  ];

  data.size = count;
  data.from = page;
  data.track_total_hits = true;
  data.sort = sortQ;
  if (!isEmptyObject(date)) {
    if (date?.from) {
      var range = {
        range: {
          "@timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "@timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "@timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: "now-1d/d",
  //         lte: "now",
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }
  if (is_anomaly && is_anomaly === true) {
    var fil = {
      match_phrase: {
        Result: "-1",
      },
    };
    data.query.bool.must.push(fil);
  }
  const indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
  const INVESTIGATE_CYAD = localStorage.getItem("INVESTIGATE_CYAD");
  let pattern1 = indexPattern.replace(/\/_search/, "");
  let pattern2 = INVESTIGATE_CYAD?.slice(1);
  let INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365")
    ? localStorage.getItem("INVESTIGATE_o365")
    : "/logs-o365*";
  let pattern3 = INVESTIGATE_o365?.slice(1);
  let INVESTIGATE_CYEMAIL = localStorage.getItem("INVESTIGATE_CYEMAIL");
  let pattern4 = INVESTIGATE_CYEMAIL?.slice(1);

  if (
    selectedData &&
    selectedData.length > 0 &&
    selectedData.filter((item) => item.isRoot).length < 4
  ) {
    var fil = {
      bool: {
        must: [],
        should: [],
      },
    };

    selectedData.map((item) => {
      if ((item.value === "edr" && item.isRoot) || item.root === "edr") {
        if (selectedData.filter((item) => item.root === "edr").length === 0) {
          if (pattern1.includes(',')) {
            var pat = [];
            pattern1.split(',').map((item) => {
              pat.push({
                wildcard: {
                  _index: item,
                },
              })
            })
            fil.bool.should.push(...pat);
          }
          else {
            var pat = {
              wildcard: {
                _index: pattern1,
              },
            };
            fil.bool.should.push(pat);
          }
        }
        if (selectedData.filter((item) => item.root === "edr").length > 0) {
          var fil1 = {
            bool: {
              must: [],
              should: [],
            },
          };
          if (pattern1.includes(',')) {
            var pat = [];
            pattern1.split(',').map((item) => {
              pat.push({
                wildcard: {
                  _index: item,
                },
              })
            })
            fil1.bool.should.push(...pat);
          }
          else {
            var pat = {
              wildcard: {
                _index: pattern1,
              },
            };
            fil1.bool.must.push(pat);
          }
          var edritems = [
            "process",
            "file",
            "registry",
            "network",
            "authentication",
          ];
          var edrCat = [];
          selectedData
            .filter(
              (item) =>
                (item.root === "edr" && item.parent === "edr") ||
                (item.root === "edr" && edritems.includes(item.parent))
            )
            .map((item) => {
              if (item.parent === "edr") {
                if (typeof getTypeCat(item.value) === 'object') {
                  edrCat.push(...getTypeCat(item.value))
                }
                else {
                  edrCat.push(getTypeCat(item.value))

                }
              }
            });
          if (item.parent === "edr") {
            if (edr === "elastic") {
              var pat = {
                terms: {
                  "event.category": edrCat,
                },
              };
            } else if (edr === "atp") {
              var pat = {
                terms: {
                  "event.category": edrCat,
                },
              };
            }
            fil1.bool.must.push(pat);
          }
          var actionlist = [];
          selectedData
            .filter(
              (itemt) => itemt.root === "edr" && edritems.includes(itemt.parent)
            )
            .map((item) => actionlist.push(item.value));

          if (actionlist.length > 0) {
            var pat2 = {
              terms: {
                "event.action": actionlist,
              },
            };
            fil1.bool.must.push(pat2);
          }
          fil.bool.should.push(fil1);
        }
      } else if ((item.value === "ad" && item.isRoot) || item.root === "ad") {
        if (selectedData.filter((item) => item.root === "ad").length === 0) {
          var pat = {
            wildcard: {
              _index: pattern2,
            },
          };
          fil.bool.should.push(pat);
        }
        var AdEventCode = [];
        if (selectedData.filter((item) => item.root === "ad").length > 0) {
          var fil2 = {
            bool: {
              must: [],
              should: [],
            },
          };
          selectedData
            .filter((item) => item.root === "ad")
            .map((item) => {
              AdEventCode.push(...getEventCode(item.value));
            });
          var pat = {
            terms: {
              "event.code": AdEventCode,
            },
          };
          fil2.bool.must.push(pat);

          var pat = {
            wildcard: {
              _index: pattern2,
            },
          };
          fil2.bool.must.push(pat);

          fil.bool.should.push(fil2);
        }
      }

      if ((item.value === "o365" && item.isRoot) || item.root === "o365") {
        if (selectedData.filter((item) => item.root === "o365").length === 0) {
          var o365basePattern = {
            bool: {
              must: [],
              should: [],
            },
          };
          var pat = {
            wildcard: {
              _index: pattern3,
            },
          };
          var patterm = {
            terms: {
              Workload: [
                "AzureActiveDirectory",
                "OneDrive",
                "Exchange",
                "SharePoint",
              ],
            },
          };
          o365basePattern.bool.must.push(pat);
          o365basePattern.bool.must.push(patterm);
          fil.bool.should.push(o365basePattern);
        }
        var workloadType = [];

        if (selectedData.filter((item) => item.root === "o365").length > 0) {
          var fil2 = {
            bool: {
              must: [],
              should: [],
            },
          };
          var pat = {
            wildcard: {
              _index: pattern3,
            },
          };
          fil2.bool.must.push(pat);
          selectedData
            .filter((item) => item.root === "o365")
            .map((item) => {
              workloadType.push(item.value);
            });
          var pat2 = {
            terms: {
              Workload: workloadType,
            },
          };
          fil2.bool.must.push(pat2);

          fil.bool.should.push(fil2);
        }
      }
      if ((item.value === "email" && item.isRoot) || item.root === "email") {
        if (selectedData.filter((item) => item.root === "email").length === 0) {
          var pat = {
            wildcard: {
              _index: pattern4,
            },
          };
          fil.bool.should.push(pat);
        }
        if (selectedData.filter((item) => item.root === "email").length > 0) {
          var fil2 = {
            bool: {
              must: [],
              should: [],
            },
          };
          var pat = {
            wildcard: {
              _index: pattern4,
            },
          };
          var emailStatus = [];
          fil2.bool.must.push(pat);
          selectedData
            .filter((item) => item.root === "email")
            .map((item) => {
              emailStatus.push(item.value);
            });
          var pat = {
            terms: {
              "delivery.action": emailStatus,
            },
          };
          fil2.bool.must.push(pat);
          fil.bool.should.push(fil2);
        }
      }
    });

    data.query.bool.must.push(fil);
  }

  if (showSystem) {
    const combinedSystem = {
      bool: {
        must: [],
        should: [
          {
            terms: {
              "user.domain": ["NT AUTHORITY", "nt authority"],
            },
          },
          {
            term: {
              "file.extension": "tmp",
            },
          },
          {
            wildcard: {
              "winlog.event_data.TargetUserName": {
                value: `*system*`,
                case_insensitive: true,
              },
            },
          },
          {
            wildcard: {
              "winlog.event_data.SubjectUserNamee": {
                value: `*system*`,
                case_insensitive: true,
              },
            },
          },
          {
            wildcard: {
              "winlog.event_data.SubjectUserName": "*$",
            },
          },
          {
            term: {
              UserId: "SHAREPOINTsystem",
            },
          },
          {
            terms: {
              "user.name": ["SYSTEM", "LOCAL SERVICE", "NETWORK SERVICE", "system", "local service"],
            },
          },
        ],
      },
    };
    data.query.bool.must_not.push(combinedSystem);
  }
  if (kqlQuery?.length > 0) {
    const kql = {
      "query_string": {
        "query": kqlQuery,
        "default_field": "*"
      }
    }
    data.query.bool.must.push(kql)
  }
  return data;
};

export function splitJSONIntoGroupsAuto(input) {
  const totalSize = input.length;
  const groupSize = 2; // Set the group size to 2

  const groups = [];
  let startIndex = 0;

  while (startIndex < totalSize) {
    groups.push(input.slice(startIndex, startIndex + groupSize));
    startIndex += groupSize;
  }

  return groups;

}



export function convertUTCTimeToLocal(utcDateString) {

  // // Create a Date object from the UTC string
  const utcDate = new Date(utcDateString);


  // // Get the time zone offset in minutes
  const timezoneOffset = utcDate.getTimezoneOffset();

  // // Calculate the local time by subtracting the offset
  const localDate = new Date(utcDate.getTime() - (timezoneOffset * 60 * 1000));

  return moment(localDate).format("hh:mm:ss A");

}



// ueba

export const uebaCykitTimelineItem = (log_source, item) => {
  let timelineData = ''
  let event = ''

  const timelineTooltip = (
    <Tooltip placement="bottom" id="tooltip" >
      <div className="text-justify ms-2">Testt</div>
      <ul className="text-justify">
        <li className="">System Users</li>
        <li>NT Authoriy Domain AD Users</li>
        <li>Temporary Files</li>
        <li>Sharepoint System Users</li>
      </ul>
    </Tooltip>
  )

  if (log_source === 'EDR') {
    switch (item.event_action) {
      // file
      case "deletion":
        event = 'deleted'
        break;
      case "creation":
        event = 'created'
        break;
      case "overwrite":
        event = 'overwrited'
        break;
      case "rename":
        event = 'renamed'
        break;
      // process
      case "end":
        event = 'ended'
        break;
      case "start":
        event = 'started'
        break;
      case "fork":
        event = 'forked'
        break;
      // network
      case "connection_attempted":
        event = 'Connection Attempted'
        break;
      case "lookup_result":
        event = 'Lookup Result'
        break;
      case "disconnect_received":
        event = 'Disconnect Received'
        break;
      case "lookup_requested":
        event = 'Lookup Requested'
        break;
      // registry
      case "modification":
        event = 'Modification'
        break;
      case "query":
        event = 'Query'
        break;
      // Authentication
      case "logon-failed":
        event = 'Logon failed'
        break;
      default:
        event = item?.event_action

    }
    timelineData = `
    <div>The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on ${<CustomToolTip content={<Badge bg="success" className="p-2">Closed</Badge>} dataPlacement={"bottom"} dataToggle={
        <div className="d-flex flex-column justify-content-start">
          <span>Closed by:</span>
        </div>
      } />}</div>
    <div>
    <span>Process ID <strong>${item?.process_id}</strong></span>
    <span>Command <strong>${item?.command}</strong></span>
    <span>File <strong>${item?.file_path}</strong></span>
    </div>
    `
    //  if(type === 'process'){
    //   timelineData = `The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on <strong>${item?.host_name}</strong>`
    //  }
    //  if(type === 'file'){
    //   timelineData = `The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on <strong>${item?.host_name}</strong>`
    //  }
    //  if(type === 'network'){
    //   timelineData = `The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on <strong>${item?.host_name}</strong>`
    //  }
    //  if(type === 'registry'){
    //   timelineData = `The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on <strong>${item?.host_name}</strong>`
    //  }
    //  if(type === 'authentication'){
    //   timelineData = `The user <strong>${item?.user_name}</strong> ${event} ${item?.event_category} ${item?.process_name} on <strong>${item?.host_name}</strong>`
    //  }
  } else if (log_source === 'AD') {

  } else if (log_source === '') {

  } else if (log_source === '') {

  } else {

  }

  return rawHTML(timelineData)
}

// replacewithspace

export const replaceWithSpace = (inputString) => {
  return inputString?.replace(/_/g, ' ')
}

export function isJSONObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}



export function splitStringByLength(str, chunkLength) {
  // Check if the provided chunk length is valid
  if (chunkLength <= 0) {
    throw new Error("Chunk length must be greater than 0");
  }

  // Use a loop to iterate over the string in chunks
  var result = [];
  for (var i = 0; i < str.length; i += chunkLength) {
    result.push(str.substring(i, i + chunkLength));
  }

  return result;
}