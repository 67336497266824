import globalStyles from "../../../constants/globalStyles";

const styles = {
  section1: {
    // width: "70%",
    // height: "100%",
    // backgroundColor: "white",
    // borderRadius: "10px 10px 0 0",
    height: "calc(100vh - 8rem)",
    paddingRight: "1rem",
    overflow: "auto"
  },
  section2: {
    // width: "30%",
    // backgroundColor: "white",
    // borderRadius: "10px 10px 0 0",
  },
  // count tile styles
  overviewTile: {
    // backgroundColor: "#f1f1f1",
    backgroundColor: "#DBEEFF",
    // height: "100px",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    // minHeight: "100px",
    // justifyContent: "space-between",
  },
  overviewIcon: {
    width: "50px",
    height: "50px",
    fontSize: "28px",
    backgroundColor: "#47A8FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    color: "#FFFFFF",
  },
  overviewTileOrange: {
    backgroundColor: globalStyles.orangeLite1,
  },
  overviewIconOrange: {
    backgroundColor: globalStyles.orangeDark1,
  },
  overviewTilePurple: {
    backgroundColor: globalStyles.purpleLite1,
  },
  overviewIconPurple: {
    backgroundColor: globalStyles.purpleDark1,
  },
  overviewTileGreen: {
    backgroundColor: globalStyles.greenLite1,
  },
  overviewIconGreen: {
    backgroundColor: globalStyles.greenDark1,
  },
  overviewTileYellow: {
    backgroundColor: globalStyles.yellowLite1,
  },
  overviewIconYellow: {
    backgroundColor: globalStyles.yellowDark1,
  },
  overviewTileRed: {
    backgroundColor: globalStyles.redLite1,
  },
  overviewIconRed: {
    backgroundColor: globalStyles.red,
  },
  detectionCardUser: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.9rem",
  },
  userDetails: {
    display: "flex",
    gap: "10px",
  },
  userPic: {
    width: "50px",
    height: "50px",
    backgroundColor: "yellow",
    borderRadius: "50%",
  },
  userText: {
    lineHeight: "1",
    maxWidth: "220px",
  },
  recentDetectionsWrap: {
    height: "calc(100vh - 15.5rem)",
    // height: "400px",
    overflow: "auto",
    paddingRight: "1rem",
  },
  sectionWrap1: {
    height: "350px",
    overflow: "auto",
    paddingRight: "1rem",
  },
  cartWrap1: {
    // height: "300px",
    overflow: "auto",
    paddingRight: "1rem",
  },
  detectionItem: {},

  tile: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "#f1f1f1",
    backgroundColor: globalStyles.greyLite1,
    color: "#000000",
    textDecoration: "none"
  },
  profilePic: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    backgroundColor: globalStyles.greyDark1,
    color: globalStyles.white,
    borderRadius: "50px",
    // border: "1px solid",
    // borderColor: globalStyles.greyDark1
  },
  filterWrap: {
    position: "sticky",
    top: 0,
    zIndex: 2
  }
};

export default styles;
