
  export const fields = {
    event: [
      {
        name: "Event Date & Time",
        value: "item._source.attribute_timestamp",
        // value: getTimeFromTimestamp(
          // "parseInt(item._source.attribute_timestamp) * 1000"
        
      },
      {
        name: "Event Name",
        value: "item._source.event_name",
      },
      {
        name: "Feed Name",
        value: "item._source.feed_name",
      },
      {
        name: "Severity",
        value: "item._source.severity",
      },
      {
        name: "IOC Type",
        value: "item._source.attribute_type",
      },
      {
        name: "IOC Value",
        value: "item._source.attribute_value",
      }
    ],
    edr: [
        {
            name: "ID",
            value: "item?._id",
        },
        {
            name: "Device",
            value: "item?._source?.host?.hostname",
        },
        {
            name: "Timestamp",
            value: "item?._source?.['@timestamp']",
        },
    ]
  };
  