import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
  useMemo,
} from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  useNodesInitialized,
  useReactFlow,
  Background,
  Controls,
  getIncomers,
  // useStore,
  getOutgoers,
  isNode,
  getConnectedEdges,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import graphlib from "graphlib";
import { fpostCy } from "../../axios/axios";
import CustomNodeProcess from "./CustomNodes/CustomNodeProcess";
// import Loader from "../Loader";
import { getProcessQuery } from "../../Queries/CykitQuery";
import { shallow } from "zustand/shallow";
import useStore from "./Store/store";
import SideBar from "./SideBar";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpinnerLoader from "../Loader/SpinnerLoader";

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  setCollapseNodes: state.setCollapseNodes,
  setCollapseEdges: state.setCollapseEdges,
  refresh: state.refresh,
  expandedNode: state.expandedNode,
  setExpandedNode: state.setExpandedNode,
});

let baseURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");

const dagreGraph = new graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 0, y: 0 };

const edgeType = "smoothstep";
const initialNodes = [];
const initialEdges = [];
const nodeWidth = 180;
const nodeHeight = 40;
const edr = localStorage.getItem("EDR_TYPE");

const getLayoutedElements = (nodes, edges, direction = "RL") => {
  const isHorizontal = direction === "RL";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "right" : "bottom";
    node.sourcePosition = isHorizontal ? "left" : "top";
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2 + 800,
      y: nodeWithPosition.y - nodeHeight / 2 + 400,
    };
    console.log("parent node");
    console.log(node);
    return node;
  });
  console.log("layout changed");
  console.log(nodes);
  console.log(edges);
  return { nodes, edges };
};

const nodeTypes = {
  CustomNodeProcess: CustomNodeProcess,
};

const Flow = (props) => {
  const proOptions = { hideAttribution: true };

  const nodesInitialized = useNodesInitialized();
  const {
    zoomIn,
    fitView,
    getNodes,
    getEdges,
    getNode,
    setViewport,
    deleteElements,
  } = useReactFlow();
  const fitViewOptions = { padding: 0.5 };
  const [startTimeRange, setStartTimeRange] = useState(null);
  const [endTimeRange, setEndTimeRange] = useState(null);
  const [hostNode, setHostNode] = useState();
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    setNodes,
    setEdges,
    setCollapseNodes,
    setCollapseEdges,
    refresh,
    expandedNode,
    setExpandedNode,
  } = useStore(selector, shallow);

  const [clickedNode, setClickedNode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isclosed, setIsClosed] = useState(false);
  // const [expandedNode, setExpandedNode] = useState()

  const [currentParentPage, setCurrentPartentPage] = useState(0);
  const [currentParentSize, setCurrentPartentSize] = useState();
  const [parentData, setParentData] = useState([]);
  const [direction, setDirection] = useState("");
  const [fdir, setFdir] = useState(false);
  // const dimensions = useStore((store) => ({ width: store.width, height: store.height }));

  const onClick = (node, direction) => {
    setIsLoading(true);
    console.log("pop2", node);
    const dirNode = { ...node, direction: direction };
    setExpandedNode(dirNode);
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsClosed(true);
    }
  };

  const onCollapse = (node, direction) => {
    if (direction === "forward") {
      console.log("test", nodes);
      const nodeById = getNode(node.id);
      const inner = getIncomers(nodeById, getNodes(), getEdges());
      const outer = getOutgoers(nodeById, getNodes(), getEdges());
      const initEdge = getConnectedEdges([nodeById], getEdges());
      var delNodes = inner;
      var deledges = initEdge;

      for (var i = 0; i < delNodes.length; i++) {
        const inc = getIncomers(delNodes[i], getNodes(), getEdges());
        const ine = getConnectedEdges([delNodes[i]], getEdges());

        if (inc.length > 1) {
          inc.forEach((innode) => delNodes.push(innode)); // append each incoming node
        } else if (inc.length === 1) {
          delNodes.push(inc[0]); // append the single incoming node
          deledges.push(ine); // append the incoming edge
        }
      }
      // remove outgoing edge from list
      const newDelEdges = deledges.filter((object1) => {
        return !outer.some((object2) => {
          return object1.target === object2.id;
        });
      });

      setCollapseNodes(delNodes);

      setCollapseEdges(newDelEdges);
    } else if (direction === "backward") {
      const nodeById = getNode(node.id);
      const inner = getIncomers(nodeById, getNodes(), getEdges());
      const outer = getOutgoers(nodeById, getNodes(), getEdges());
      const initEdge = getConnectedEdges([nodeById], getEdges());
      var delNodes = outer;
      var deledges = initEdge;

      for (var i = 0; i < delNodes.length; i++) {
        const inc = getOutgoers(delNodes[i], getNodes(), getEdges());
        const ine = getConnectedEdges([delNodes[i]], getEdges());

        if (inc.length > 1) {
          inc.forEach((innode) => delNodes.push(innode)); // append each incoming node
        } else if (inc.length === 1) {
          delNodes.push(inc[0]); // append the single incoming node
          deledges.push(ine); // append the incoming edge
        }
      }
      // remove outgoing edge from list
      const newDelEdges = deledges.filter((object1) => {
        return !outer.some((object2) => {
          return object1.source !== object2.id;
        });
      });

      setCollapseNodes(delNodes);
      setCollapseEdges(newDelEdges);
    }
  };
  // useEffect(()=>{
  //   if (typeof window !== 'undefined') {

  //   const connection = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({
  //     name: "Form fields",
  //   });
  //   connection?.init(useStore.getState());

  //   let isUpdateFromDevtools = false;
  //   connection?.subscribe((evt) => {
  //     if (evt.type === "DISPATCH") {
  //       const newState = JSON.parse(evt.state);
  //       isUpdateFromDevtools = true;
  //       useStore.setState(newState);
  //       isUpdateFromDevtools = false;
  //     }
  //   });

  //   useStore.subscribe((newState) => {
  //     if (!isUpdateFromDevtools) {
  //       connection?.send("State", newState);
  //     }
  //   });
  // }

  // },[])
  useEffect(() => {
    setIsLoading(true)
    if (props.host !== undefined) {
      if (edr === "atp") {
        console.log("test" + props.host);
        const test = {
          id: "1a",
          type: "CustomNodeProcess",
          nodetype: "process",
          sourcePosition: "right",
          data: {
            label: props.processData._source.process.name,
            host_name: props.processData._source.host.name,
            pid: props.processData._source.process.pid,
            p_pid: props.processData._source.process.parent.pid,
            onClick: onClick,
            onCollapse: onCollapse,
            nodetype: "process",
            id: `1a`,
            doc_id: props.processData._id,
            p_creation_time: cleanDataProcess(props.processData._source),
            parent_creation_time: cleanDataParent(props.processData._source),
            category: props.processData._source.category,
            host_name: props.processData._source.host.name,
          },
          position,
        };
        setNodes([...nodes, test]);
        zoomIn(4);
        var dirNode = { ...test.data, direction: "forward" };
        setFdir(true);
        setExpandedNode(dirNode);
        console.log("pop1");
      } else if (edr === "elastic") {
        console.log("test" + props.host);
        const test = {
          id: "1a",
          type: "CustomNodeProcess",
          nodetype: "process",
          sourcePosition: "right",
          data: {
            label: props.processData._source.process.name,
            host_name: props.processData._source.host.name,
            pid: props.processData._source.process.pid,
            onClick: onClick,
            onCollapse: onCollapse,
            nodetype: "process",
            id: `1a`,
            doc_id: props.processData._id,
            category: props.processData._source.event?.category,
            process_id: props.processData._source.process?.entity_id,
            parents: props.processData._source.process?.Ext?.ancestry,
          },
          position,
        };
        setNodes([...nodes, test]);
        zoomIn(4);
        var dirNode = { ...test.data, direction: "forward" };
        setFdir(true);
        setExpandedNode(dirNode);
        console.log("pop1");
      }
    }
  }, [props.host]);

  useEffect(() => {
    setIsLoading(true)
    if (nodesInitialized) {
      const { nodes: layoutedNode, edges: layoutedEdge } = getLayoutedElements(
        nodes,
        edges
      );

      // setIsLoading(true);

      setNodes([...layoutedNode]);
      setEdges([...layoutedEdge]);
      zoomIn(4);

      // setViewport({ x: (dimensions.width / 2) - 20, y: 350, zoom: 2 })
      // fitView(fitViewOptions)
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }

  }, [nodesInitialized]);

  const cleanDataParent = (data) => {
    if (
      data.initiating &&
      data.initiating.processparent &&
      data.initiating.processparent.creationTime
    ) {
      var time = data.initiating.processparent.creationTime;
    } else if (
      data.Initiating &&
      data.Initiating.processparent &&
      data.Initiating.processparent.creationTime
    ) {
      var time = data.Initiating.processparent.creationTime;
    } else if (
      data.properties &&
      data.properties.InitiatingProcessParentCreationTime
    ) {
      var time = data.properties.InitiatingProcessParentCreationTime;
    }
    return time;
  };

  const cleanDataProcess = (data) => {
    if (data.initiating && data.initiating.processcreationtime) {
      var time = data.initiating.processcreationtime;
    } else if (
      data.properties &&
      data.properties.InitiatingProcessCreationTime
    ) {
      var time = data.properties.InitiatingProcessCreationTime;
    }
    return time;
  };

  useEffect(() => {
    setIsLoading(true)
    if (expandedNode !== undefined) {
      const node = expandedNode;
      const data = getProcessQuery(node, node.direction);
      if (node.direction === "forward") {
        fpostCy(`${baseURL}/${indexPattern}`, data).then((response) => {
          console.log(response.data);
          const newNodes = [];
          const newEdges = [];
          response?.data?.aggregations?.unique_field_name?.buckets &&
            response.data.aggregations.unique_field_name.buckets.map(
              (val, key) => {
                if (
                  !nodes.some(
                    (el) =>
                      el.id === val.all_fields.hits.hits[0]._source.process.pid
                  )
                ) {
                  if (edr === "atp") {
                    var parentProcessNode = {
                      id: `${val.all_fields.hits.hits[0]._source.process.pid}_${key}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val.all_fields.hits.hits[0]._source.process.name,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        pid: val.all_fields.hits.hits[0]._source.process.pid,
                        p_pid:
                          val.all_fields.hits.hits[0]._source.process.parent
                            .pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val.all_fields.hits.hits[0]._source.process.pid}_${key}`,
                        doc_id: val.all_fields.hits.hits[0]._id,
                        p_creation_time: cleanDataProcess(
                          val.all_fields.hits.hits[0]._source
                        ),
                        parent_creation_time: cleanDataParent(
                          val.all_fields.hits.hits[0]._source
                        ),
                        category: val.all_fields.hits.hits[0]._source.category,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                      },
                      position,
                      sourcePosition: "right",
                      targetPosition: "left",
                    };
                  } else if (edr === "elastic") {
                    var parentProcessNode = {
                      id: `${val.all_fields.hits.hits[0]._source.process.pid}_${key}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val.all_fields.hits.hits[0]._source.process.name,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        pid: val.all_fields.hits.hits[0]._source.process.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val.all_fields.hits.hits[0]._source.process.pid}_${key}`,
                        doc_id: val.all_fields.hits.hits[0]._id,
                        category:
                          val.all_fields.hits.hits[0]._source.event.category,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        process_id:
                          val.all_fields.hits.hits[0]._source.process.entity_id,
                        parents:
                          val.all_fields.hits.hits[0]._source.process.Ext
                            .ancestry,
                      },
                      position,
                      sourcePosition: "right",
                      targetPosition: "left",
                    };
                  }
                  const parentEdge = {
                    id: `e_${val.all_fields.hits.hits[0]._source.process.pid}_${node.id
                      }_${Math.random()}`,
                    source: `${val.all_fields.hits.hits[0]._source.process.pid}_${key}`,
                    target: `${node.id}`,
                    type: edgeType,
                    animated: true,
                  };
                  console.log("process:", parentProcessNode);

                  newNodes.push(parentProcessNode);
                  newEdges.push(parentEdge);
                } else {
                  if (edr === "atp") {
                    const parentProcessNode = {
                      id: `${val.all_fields.hits.hits[0]._source.process.pid}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val.all_fields.hits.hits[0]._source.process.name,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        pid: val.all_fields.hits.hits[0]._source.process.pid,
                        p_pid:
                          val.all_fields.hits.hits[0]._source.process.parent
                            .pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val.all_fields.hits.hits[0]._source.process.pid}`,
                        doc_id: val.all_fields.hits.hits[0]._id,
                        p_creation_time: cleanDataProcess(
                          val.all_fields.hits.hits[0]._source
                        ),
                        parent_creation_time: cleanDataParent(
                          val.all_fields.hits.hits[0]._source
                        ),
                        category: val.all_fields.hits.hits[0]._source.category,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  } else if (edr === "elastic") {
                    const parentProcessNode = {
                      id: `${val.all_fields.hits.hits[0]._source.process.pid}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val.all_fields.hits.hits[0]._source.process.name,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        pid: val.all_fields.hits.hits[0]._source.process.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val.all_fields.hits.hits[0]._source.process.pid}`,
                        doc_id: val.all_fields.hits.hits[0]._id,
                        category:
                          val.all_fields.hits.hits[0]._source.event.category,
                        host_name:
                          val.all_fields.hits.hits[0]._source.host.name,
                        process_id:
                          val.all_fields.hits.hits[0]._source.process.entity_id,
                        parents:
                          val.all_fields.hits.hits[0]._source.process.Ext
                            .ancestry,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  }

                  const parentEdge = {
                    id: `e_${val.all_fields.hits.hits[0]._source.process.pid}_${node.id
                      }_${Math.random()}`,
                    source: `${val.all_fields.hits.hits[0]._source.process.pid}`,
                    target: `${node.id}`,
                    type: edgeType,
                  };
                  console.log("process1:", parentProcessNode);
                  console.log("edge1:", parentEdge);

                  newNodes.push(parentProcessNode);
                  newEdges.push(parentEdge);
                }
              }
            );
          if (!fdir) {
            onClick(node, "backward");
          }

          setNodes([...nodes, ...newNodes]);
          setEdges([...edges, ...newEdges]);
        });
      }
    }
    setIsLoading(true)

    if (expandedNode !== undefined) {
      const node = expandedNode;

      const data = getProcessQuery(node, node.direction);

      if (node.direction === "backward") {
        if (
          (node?.p_pid !== 0 && edr === "atp") ||
          (node?.parents?.length > 0 && edr === "elastic")
        ) {
          fpostCy(`${baseURL}/${indexPattern}`, data).then((response) => {
            response.data.hits.hits &&
              response.data.hits.hits.slice(0, 1).map((val, key) => {
                if (!nodes.some((el) => el.id === val._source.process.pid)) {
                  if (edr === "atp") {
                    var parentProcessNode = {
                      id: `${val._source.process.pid}_${key}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val._source.process.name,
                        host_name: val._source.host.name,
                        pid: val._source.process.pid,
                        p_pid: val._source.process.parent.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val._source.process.pid}_${key}`,
                        doc_id: val._id,
                        p_creation_time: cleanDataProcess(val._source),
                        parent_creation_time: cleanDataParent(val._source),
                        category: val._source.category,
                        host_name: val._source.host.name,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  } else if (edr === "elastic") {
                    var parentProcessNode = {
                      id: `${val._source.process.pid}_${key}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val._source.process.name,
                        host_name: val._source.host.name,
                        pid: val._source.process.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val._source.process.pid}_${key}`,
                        doc_id: val._id,
                        category: val._source.category,
                        host_name: val._source.host.name,
                        process_id: val._source.process.entity_id,
                        parents: val._source.process.Ext.ancestry,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  }
                  const parentEdge = {
                    id: `e_${val._source.process.pid}_${node.id
                      }_${Math.random()}`,
                    source: `${node.id}`,
                    target: `${val._source.process.pid}_${key}`,
                    type: edgeType,
                    animated: true,
                  };
                  console.log("process:", parentProcessNode);

                  setNodes([...nodes, parentProcessNode]);
                  setEdges([...edges, parentEdge]);
                } else {
                  if (edr === "atp") {
                    var parentProcessNode = {
                      id: `${val._source.process.pid}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val._source.process.name,
                        host_name: val._source.host.name,
                        pid: val._source.process.pid,
                        p_pid: val._source.process.parent.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val._source.process.pid}`,
                        doc_id: val._id,
                        p_creation_time: cleanDataProcess(val._source),
                        parent_creation_time: cleanDataParent(val._source),
                        category: val._source.category,
                        host_name: val._source.host.name,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  } else if (edr === "elastic") {
                    var parentProcessNode = {
                      id: `${val._source.process.pid}`,
                      type: "CustomNodeProcess",
                      nodetype: "process",
                      data: {
                        label: val._source.process.name,
                        host_name: val._source.host.name,
                        pid: val._source.process.pid,
                        onClick: onClick,
                        onCollapse: onCollapse,
                        nodetype: "process",
                        id: `${val._source.process.pid}`,
                        doc_id: val._id,
                        category: val._source.category,
                        host_name: val._source.host.name,
                        process_id: val._source.process.entity_id,
                        parents: val._source.process?.Ext?.ancestry,
                      },
                      position,
                      sourcePosition: "left",
                      targetPosition: "right",
                    };
                  }

                  const parentEdge = {
                    id: `e_${val._source.process.pid}_${node.id
                      }_${Math.random()}`,
                    source: `${node.id}`,
                    target: `${val._source.process.pid}`,
                    type: edgeType,
                  };
                  console.log("process1:", parentProcessNode);
                  console.log("edge1:", parentEdge);

                  setNodes([...nodes, parentProcessNode]);
                  setEdges([...edges, parentEdge]);
                }
              });
          });
        }
      }
    }

    // if (node !== undefined) {
    //   if (node.nodetype == "pagination_node") {
    //     console.log("item", parentData)
    //     const plow = currentParentPage * 4
    //     const phigh = plow + 4

    //     const data = parentData
    //     data
    //       .slice(plow, phigh)
    //       .map((val) => {
    //         const value = { parent_process: val.key, pid: val.host.hits.hits[0]._source.process.parent.pid };
    //         const parentProcessNode = {
    //           id: `${val.host.hits.hits[0]._source.process.parent.pid}`,
    //           type: 'CustomNodeProcess',
    //           nodetype: "parent_process",
    //           data: {
    //             label: val.key, pid: val.host.hits.hits[0]._source.process.parent.pid,host_name: val.host.hits.hits[0]._source.host.name, p_pid: val.host.hits.hits[0]._source.process.parent.pid, onClick: onClick, onCollapse: onCollapse, nodetype: "process", id: `${val.host.hits.hits[0]._source.process.parent.pid}`, doc_id: val.host.hits.hits[0]._id, p_hash: val.host.hits.hits[0]._source.process.parent.hash.sha1
    //           },
    //           position,
    //           sourcePosition: "left",
    //           targetPosition: "right",
    //         };
    //         console.log("node")
    //         console.log(parentProcessNode)

    //         const parentEdge = {
    //           id: `e_${val.host.hits.hits[0]._source.process.parent.pid}_1`,
    //           source: `${val.host.hits.hits[0]._source.process.parent.pid}`,
    //           target: "1a",
    //           type: edgeType,
    //           animated: true,
    //         };
    //         setNodes( [...nodes, parentProcessNode]);
    //         setEdges([...edges, parentEdge]);

    //       })
    //     setNodes(()=>{
    //       const newNodes = nodes.filter(object1 => {
    //         return object1.nodetype !== "pagination_node"
    //       })
    //       return newNodes
    //     })

    //     setEdges(() => {
    //       const newEdges = edges.filter(object1 => {
    //         if (!object1.id.includes("1_page")) {
    //           return object1
    //         }
    //       });
    //       return newEdges
    //     })
    //     setCurrentPartentPage(state => state + 1)
    //     const pageNode = {
    //       id: `${currentParentPage}_1a`,
    //       type: 'CustomNodePagination',
    //       nodetype: "pagination_node",
    //       data: { onClick: onClick, nodetype: "pagination_node" },
    //       position,
    //       sourcePosition: "left",
    //       targetPosition: "right",
    //     }
    //     const pageEdge = {
    //       id: `e_${currentParentPage}_1`,
    //       source: `${currentParentPage}_1a`,
    //       target: "1a",
    //       type: edgeType,
    //       animated: true,
    //     };
    //     setNodes([...nodes, pageNode]);
    //     setEdges([...edges, pageEdge]);

    //   }
    // }


  }, [expandedNode]);

  return (
    <div
      className="flow"
      style={{ height: "calc(100vh - 8.5rem)", width: "1560px" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        // fitView
        attributionPosition="bottom-left"
      >
        <Controls />
        <Background color="#cc" />
      </ReactFlow>

      <div className="controls">
        <SideBar close={isclosed} closefn={setIsClosed} />
      </div>
      <Button
        variant="link"
        className="position-absolute top-0 left-5 bg-white"
        size="lg"
      >
        <Link
          color="light"
          to="/hunter/cykit"
          onClick={() => {
            refresh();
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>
        </Link>
      </Button>
      <div>
        <SpinnerLoader isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Flow;
