import React, { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import CustomModal from "../../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Button, Form } from "react-bootstrap";

import {
  getExpectedOutputCustomInv,
  customInvestigation,
  getInvCatalogueDetails,
  getInvCatalogueDetailsQuery
} from "../../../../Methods/RuleStudioMethods";
import { dateDMY, removeHtmlChars, splitStringByLength } from "../../../../Utils/Utils";

import CustomInvInformation from "../../CustomInvInformation";
import Loader from "../../../Loader/Loader";
import Modules from "./Modules";
import CountBasedInputs from "./CountBasedInputs";



const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


export default function
  CustomMasterModal({
    setCustomShow,
    customShow,
    setInvId,
    invIdEdit,
    setSubmitLoader,
    submitLoader
  }) {
  const dispatch = useDispatch();

  const [masterId, setMasterId] = useState("INV-");
  const [investigateName, setInvestigateName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [scheduleVal, setScheduleVal] = useState("");
  const [suppress, setSuppress] = useState("");
  const [description, setDescription] = useState("");
  const [detailsDescription, setDetailDescription] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [query, setQuery] = useState();
  const [notify, setNotify] = useState(['']);
  const [severity, setSeverity] = useState('');
  const [techId, setTechId] = useState("");
  const [techName, setTechName] = useState("");
  const [tacticName, setTacticName] = useState("");
  const [subTech, setSubTech] = useState("");
  const [inv, setInv] = useState("INV-")
  const [inv_created, setInvCreated] = useState(dateDMY(new Date()))
  const [inv_last_edited, setInvLastEdited] = useState(dateDMY(new Date()))

  const [correlationInput1, setCorrelationInput1] = useState("")
  const [correlationInput2, setCorrelationInput2] = useState("")

  const [isHunter, setIsHunter] = useState("")


  const [inputValue, setInputValue] = useState('');
  const [isValidJson, setIsValidJson] = useState(true);


  // const [otherInformation, setOtherInformation] = useState([
  //   {
  //     information: "",
  //     value: "",
  //   },
  // ]);

  const [countBasedInputList, setCountBasedINputList] = useState([
    {
      value: "",

    },
  ]);




  const [selectedLogCategory, setLogCategory] = useState([]);
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);

  const [modules, setModules] = useState([]);

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;

  const detailsCustomRsIn = useSelector((state) => state.detailsCustomRsIn);
  const { catalogue, loading: loading } = detailsCustomRsIn;

  const detailsCustomQueryRsIn = useSelector((state) => state.detailsCustomQueryRsIn);
  const { catalogueQuery } = detailsCustomQueryRsIn;

  const createMasterRsInv = useSelector((state) => state.createMasterRsInv);
  const { success, error } = createMasterRsInv;



  const setDataInEditList = (severity, schedule) => {


    const logData = catalogue?.log?.map((data) => {
      return { "value": data, 'label': data }
    })

    let expOutData = catalogueQuery?.data?.extract_values_from_query_output?.map((data) => {
      return { "value": data, 'label': data }
    })




    let queryData
    if (catalogue?.count_based === true) {
      queryData = catalogueQuery?.data?.query_1
    } else {
      queryData = catalogueQuery?.data?.query ? catalogueQuery?.data?.query : catalogueQuery?.data?.result_out_2

    }


    delete queryData?.extract_values_from_query_output

    // console.log(JSON.stringify(removeHtmlChars(catalogueQuery)))



    if (catalogue?.hunter === true) {

      setIsHunter("hunter")

    } else if (catalogue?.count_based === true) {

      setIsHunter("count_based")
      setCorrelationInput1(catalogueQuery?.data?.inputs?.[0]?.input_1)
      setCorrelationInput2(catalogueQuery?.data?.inputs?.[1]?.input_2)
    } else {
      setIsHunter("")
    }

    // Set schedule value
    let scheduleData = catalogue?.schedule;
    let scheduleLength = scheduleData?.length

    if (scheduleData) {
      const scheduleDataList = splitStringByLength(scheduleData, scheduleLength - 1)


      setSchedule(scheduleDataList[1])
      setScheduleVal(scheduleDataList[0])
    }




    setMasterId(invIdEdit === '' ? '' : catalogue?.inv_id);

    setInvestigateName(invIdEdit === '' ? '' : catalogue?.inv_name);

    // setSchedule(invIdEdit === '' ? '' : catalogue?.schedule);
    setSuppress(invIdEdit === '' ? '' : catalogue?.suppress);
    setDescription(invIdEdit === '' ? '' : catalogue?.Description);
    setDetailDescription(invIdEdit === '' ? '' : catalogue?.detailed_description);
    // setConclusion(invIdEdit === '' ? '' : catalogue?.detailed_description);
    // setConclusion(invIdEdit === '' ? '' : catalogue?.conclution? catalogue?.conclution: catalogue?.mitigation );
    setConclusion(invIdEdit === '' ? '' : catalogue?.mitigation);
    // {value: "Firewall", label: "Firewall"}
    setLogCategory(invIdEdit === '' ? '' : logData)
    setExpectedOutput(invIdEdit === '' ? '' : expOutData)
    setQuery(invIdEdit === '' ? '' : JSON.stringify(queryData))
    setModules(invIdEdit === '' ? [] : catalogue?.modules)
    setNotify(invIdEdit === '' || catalogue?.notify === "undefined" || [] ? "NULL" : catalogue?.notify);
    setSeverity(invIdEdit === '' ? '' : catalogue?.severity)
    setTechId(invIdEdit === '' ? '' : catalogue?.technique_id);
    setTacticName(invIdEdit === '' ? '' : catalogue?.tactic_name);
    setTechName(invIdEdit === '' ? '' : catalogue?.technique_name);
    setSubTech(invIdEdit === '' ? '' : catalogue?.subTech);
    // setIsHunter(invIdEdit === '' ? '' : catalogue?.hunter || catalogue?.count_based);
    setInvCreated(invIdEdit === '' ? '' : catalogue?.inv_created);
    setInvLastEdited(invIdEdit === '' ? '' : dateDMY(new Date()));
    // setOtherInformation(invIdEdit === '' ? [{
    //   information: "",
    //   value: "",
    // }] : catalogue.otherInformation)
  };



  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());

    if (
      (invIdEdit || Object.keys(catalogue).length === 0) &&
      invIdEdit != catalogue?.inv_id && invIdEdit != ''
    ) {
      dispatch(getInvCatalogueDetails(invIdEdit));
      dispatch(getInvCatalogueDetailsQuery(invIdEdit))
    }

    if (invIdEdit != '') {
      setDataInEditList(severity, schedule);
    } else {
      setMasterId("INV-");
      setInvestigateName("");
      setSchedule("");
      setSuppress("");
      setDescription("");
      setDetailDescription("");
      setConclusion("");
      setQuery();
      setNotify([]);
      setSeverity('');
      setLogCategory('');
      setTechId("");
      setTechName("");
      setTacticName("");
      setSubTech("");
      // setOtherInformation([{
      //   information: "",
      //   value: "",
      // }])
    }

  }, [invIdEdit, catalogue, customShow, catalogueQuery]);


  // OTHER INFORMATION
  // const addMore = () => {
  //   let obj = {
  //     information: "",
  //     value: "",
  //   };
  //   setOtherInformation([...otherInformation, obj]);
  // };

  // let otherInformationDiv = otherInformation?.map((data, index) => (
  //   <CustomInvInformation
  //     otherInformation={otherInformation}
  //     setOtherInformation={setOtherInformation}
  //     index={index}
  //     data={data}
  //     addMore={addMore}
  //   />
  // ));



  // COUNT BASED INPUT

  const addMoreCountBasedInput = () => {

    let obj = {
      value: "",
    };
    setCountBasedINputList([...countBasedInputList, obj]);
  };

  let countBasedDiv = countBasedInputList?.map((data, index) => (
    <CountBasedInputs
      index={index}
      add={addMoreCountBasedInput}
      setData={setCountBasedINputList}
      list={countBasedInputList}
    />
  ));



  const expectedOutput = output?.expected_values?.map((output) => {
    return { value: output.value, label: output.text };
  });

  const logCategoryList = output?.log_category?.map((output) => {
    return { value: output.value, label: output.text };
  });


  const handleMultiLog = (selectedLogCategory) => {
    setLogCategory(selectedLogCategory);

  };

  const handleMultiChangeOutPut = (selectedExpectedOutput) => {
    setExpectedOutput(selectedExpectedOutput);


  };






  const onSubmit = (e) => {

    e.preventDefault();

    if (window.confirm("Are you sure you want to save changes?")) {

      setSubmitLoader(true)
      let extract_values_from_query_output = selectedExpectedOutput?.map((data) => {
        return data.value

      })



      let date = dateDMY(new Date())

      let dataDes = {
        Description: description,
        // otherInformation,
        mitigation: conclusion,
        conclution: "",
        detailed_description: detailsDescription,
        inv_id: masterId,
        inv_name: investigateName,
        log: selectedLogCategory.map((data) => data.value),
        schedule: scheduleVal + schedule,
        suppress,
        modules,
        inv_created: inv_created,
        inv_last_edited: inv_last_edited,
        // notify,
        severity,
        technique_id: techId,
        tactic_name: tacticName,
        technique_name: techName,
        // subTech

      };



      let inputQuery = [{
        input_1: correlationInput1
      }, {
        input_2: correlationInput2
      }]

      let dataQuery = {}

      // if (isHunter === 'hunter') {
      //   dataDes.hunter = true
      //   dataQuery.extract_values_from_query_output = extract_values_from_query_output
      //   // dataQuery.query = query
      //   //  dataQuery = `"result_out_2": ${query}`; // Prefix "result_out_2" for count_based=false
      //    // Create the final output object
      //      const outputObject = {
      //                result_out_2: query,
      //            };

      //         // Convert the output object to a JSON string
      //                 dataQuery = JSON.stringify(outputObject, null, 2);

      //      }
      // if (isHunter === 'count_based') {
      //   dataDes.count_based = true
      //   dataQuery.inputs = inputQuery
      //   // dataQuery.query_1 = query
      //   // dataQuery = `{"query_1" :${query}}`; // Prefix "query_1" for count_based=true
      //   const outputObject = {
      //     query_1: query,
      // };
      //                       dataQuery = JSON.stringify(outputObject, null, 2);



      if (isHunter === 'count_based') {
        dataDes.count_based = true
        dataQuery.inputs = inputQuery
        dataQuery.query_1 = JSON.parse(query)


      }


      if (isHunter === 'hunter') {
        dataDes.hunter = true
        dataQuery.extract_values_from_query_output = extract_values_from_query_output
        dataQuery.result_out_2 = JSON.parse(query)
      }

      if (isHunter === '') {
        dataQuery.result_out_2 = JSON.parse(query)
        dataQuery.extract_values_from_query_output = extract_values_from_query_output
      }







      dispatch(customInvestigation(dataDes, dataQuery, masterId))


    }
  }

  const handleNotify = (value) => {
    const newValues = value.split(','); // Assuming the values are comma-separated
    setNotify(newValues);
  }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: selectedLogCategory?.length === 0 ? 'red' : "#198754", // Set the border color here
      // Additional styles
    }),
  };

  const handleFirstSection = (val) => {
    setIsHunter(val)
  }



  const handleResetIsHunter = () => {
    setIsHunter(''); // Reset to the default value
  };
  const handleQuery = (val) => {
    // setQuery(val.replace(/\s/g, "").replace(/"/g, '"'))


    // setQuery(val.replace(/\s/g, "").replace(/\\"/g, '"').replace(/\\/g, ''));
    // setQuery(val.replace(/\s/g, "").replace(/"/g, "'"))


    //  setQuery(val.replace(/\s/g, ""))
    // setQuery(val.includes(" ") ? val : val.replace(/\s/g, ""));
     setQuery(val.replace(/[^\S ]/g, ""))

    setInputValue(val);

    // try {
    //   JSON.parse(val);
    //   setIsValidJson(true);
    // } catch (error) {
    //   setIsValidJson(false);
    // }
  }


  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
        <div className="px-3">
          <span className="was-validated">
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Investigate ID <span className="text-danger">*</span></label>
                    <input type="text" className={invIdEdit?.length === 0 ? `form-control is-invalid` : 'form-control  '} id="validationTextarea" name="masterId"
                      value={masterId}
                      placeholder="Investigate ID" required readOnly={invIdEdit ? true : false} onChange={(e) => setMasterId(e.target.value)} />

                  </div>
                </div> <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="" className="form-label">Investigate name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea"
                      placeholder="Investigate name" required name="investigateName"
                      value={investigateName}
                      onChange={(e) => setInvestigateName(e.target.value)} />

                  </div>
                </div></div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Log category <span className="text-danger">*</span></label>
                    <Select
                      isMulti
                      options={logCategoryList}
                      value={selectedLogCategory}
                      onChange={handleMultiLog}
                      styles={customStyles}
                    />

                  </div>
                </div>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label className="form-label">Schedule </label>

                    <div className="mb-3">

                      <div className="row">
                        <div className="col-4">
                          <input
                            type="number"
                            className="form-control  "
                            placeholder="Val"
                            name="scheduleVal"
                            value={scheduleVal}
                            onChange={(e) => setScheduleVal(e.target.value)}
                            required
                          />


                        </div>
                        <div className="col-8">
                          <select className="form-select" aria-label="select example" value={schedule}
                            name="schedule"
                            onChange={(e) => setSchedule(e.target.value)}
                            required>
                            <option value="">Select Schedule</option>
                            <option value="m">Minutes</option>
                            <option value="h">Hour</option>
                            <option value="d">Day</option>

                          </select>
                        </div>
                      </div>

                      {/* }  */}
                    </div>

                  </div>
                </div>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Suppress<span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea" value={suppress}
                      name="suppress"
                      onChange={(e) => setSuppress(e.target.value)}
                      placeholder="SUPPRESS " required />

                  </div>
                </div>
              </div></div>



            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>

                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Severity<span className="text-danger">*</span></label>

                    <div className="mb-3">

                      <select
                        className="form-select"
                        required aria-label="select example" value={severity}
                        name="severity"
                        onChange={(e) => setSeverity(e.target.value)}>
                        <option value="">Select Severity </option>
                        {output?.severity?.map((data, index) => (
                          <option value={data.value} key={index}>{data.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12 p-1">



                  <div className="mb-3 mt-4">

                    <div className="d-flex">
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="hunter"
                          // defaultChecked={catalogue?.hunter}
                          checked={isHunter === 'hunter' ? true : false}
                          onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Hunter
                      </div>
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="count_based"
                          defaultChecked={catalogue?.count_based}
                          checked={isHunter === 'count_based' ? true : false}
                          onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Count Based
                      </div>
                      <div className="p-2 cursor-pointer">
                        <span onClick={handleResetIsHunter}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                          <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                          <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                        </svg></span>
                      </div>

                    </div>



                  </div>

                </div>


              </div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Technique_id</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="techId"
                    value={techId}
                    onChange={(e) => setTechId(e.target.value)}
                    placeholder="TECHNIQUE ID "
                  />
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Tactic_name</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="tacticName"
                    value={tacticName}
                    onChange={(e) => setTacticName(e.target.value)}
                    placeholder="TACTIC NAME "
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Technique_name</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="techName"
                    value={techName}
                    onChange={(e) => setTechName(e.target.value)}
                    placeholder="TECHNIQUE NAME"
                  />
                </div>
                {/* <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Sub_Technique</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="subTech"
                    value={subTech}
                    onChange={(e) => setSubTech(e.target.value)}
                    placeholder="SUB TECHNIQUE "
                  />
                </div> */}
              </div>
            </div>


            <hr />
            {/* <div className="row mt-4">{otherInformationDiv}</div> */}
            <hr />
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Description *</label>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Detailed description *</label>
                  <ReactQuill
                    theme="snow"
                    value={detailsDescription}
                    name="detailsDescription"
                    onChange={setDetailDescription}
                  />
                </div>
              </div>
            </div>
            <div
                    style={{ marginLeft: '10px' }} // Adjust the value as needed
                ></div>
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Mitigation *</label>
                  <ReactQuill
                    theme="snow"
                    value={conclusion}
                    name="conclusion"
                    onChange={setConclusion}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Query *</label>
                  {/* <ReactQuill
                    theme="snow"
                    value={query}
                    name="query"
                    onChange={setQuery}
                  /> */}
                  <textarea value={query} onChange={(e) => handleQuery(e.target.value)} rows={7} className="form-control"></textarea>
                </div>

              </div>
            </div>
            {/* {!isValidJson && <p style={{ color: 'red' }}>Invalid JSON</p>} */}

            {isHunter === 'correlation' &&
              <div className="d-flex">

                <div className="ml-auto p-2 viewMoreText">+ Add more</div>
              </div>
            }

            {isHunter === 'count_based' &&
              // countBasedDiv
              <>
                <div className="form-group d-flex">
                  <div className="col-11 col-xs-12 p-1">
                    {" "}
                    <label className="control-label">Input 1  </label>
                    <input
                      type="text"
                      className="form-control input-lg form-control-style"
                      name="Input"
                      value={correlationInput1}
                      onChange={(e) => setCorrelationInput1(e.target.value)}
                      placeholder="Enter Input 1 here"
                    />
                  </div>


                </div>
                <div className="form-group d-flex">
                  <div className="col-11 col-xs-12 p-1">
                    {" "}
                    <label className="control-label">Input 2  </label>
                    <input
                      type="text"
                      className="form-control input-lg form-control-style"
                      name="Input"
                      value={correlationInput2}
                      onChange={(e) => setCorrelationInput2(e.target.value)}
                      placeholder="Enter Input 2 here"
                    />
                  </div>


                </div>
              </>
            }
            {/* <div className="row mt-2">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Notify *</label>
                  <input type="text"
                    className="form-control input-lg form-control-style"
                    value={notify}
                    name="notify"
                    onChange={(e) => handleNotify(e.target.value)}
                    placeholder="Notify " />
                </div>

              </div>
            </div> */}
            {isHunter != 'count_based' &&
              <div div className="row mt-3">
                <div className="form-group " style={{ display: "flex" }}>
                  <div className="col-md-12 col-xs-12 p-1">
                    {" "}
                    <label className="control-label">Expected output *</label>
                    <Select
                      isMulti
                      options={expectedOutput}
                      value={selectedExpectedOutput}
                      onChange={handleMultiChangeOutPut}
                    />
                  </div>
                </div>
              </div>
            }
            <label className="control-label mt-3">Modules *</label>
            {output?.module?.map((data, index) => (
              <Modules
                data={data}
                expectedOutput={expectedOutput}
                setModules={setModules}
                modules={modules}
                key={index}

              />
            ))}


            <div className="row mt-3 py-3" style={styles.footerWrap}>
              {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
              <div className="form-group d-flex justify-content-end">
                <Button className="btn-secondary" onClick={() => handleClose()}>Cancel</Button> &nbsp;
                <button type="submit" className="btn btn-primary" disabled={submitLoader === true ? true : false} >
                  {submitLoader === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                    <span className="sr-only"> </span>
                  </div></> : 'Save'}
                </button>
              </div>
            </div >
          </span>
        </div >

      )
      }
    </Form >
  );

  const handleClose = () => {
    if (window.confirm("Do you want to close?") === true) {
      setCustomShow(false);
      setInvId("");

    }
  };


  return (
    <>
      {" "}
      {customShow === true && (
        <CustomModal
          title={invIdEdit != '' ? 'Edit investigate' : `Custom investigate`}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          noBodyPadding={true}
          overflowHidden={false}
          modalSize={'lg'}
          modalPosition={"right"}
          outsideClickDisabled={true}

        />
      )}
    </>
  );
}