import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { exportCSVFile } from "../../../../Utils/Utils";
import moment from "moment";

const AlertExport = ({ filter, dateFilter, getDetectionTableData, isOneStep, isAlerted }) => {
    const [loader, setLoader] = useState(false);

    const momentobj = moment()

    const getFields = () => {
        var fields = [
            {
                name: "Alert",
                value: "item._source?.com_name",
            },
            {
                name: "Host",
                value: "item._source?.host_name",
            },
            // {
            //   name: "Alerted",
            //   value: "item._source?.alerted",
            // },
            {
                name: "User",
                value: "item._source?.user_name",
            },
            {
                name: "ISO 27001",
                value: "item._source?.ISO_27001",
            },
            {
                name: "NIST",
                value: "item._source?.NIST",
            },
            {
                name: "PCI DSS",
                value: "item._source?.PCI_DSS",
            },
            {
                name: "Detection Status",
                value: "item?._source?.detection_status",
            },
            {
                name: "DATE",
                value: "`${momentobj.utc(item?._source?.detection_timestamp).format('YYYY-MM-DD HH:mm:ss')}`",
            },
        ];
        return fields;
    };
    const convertdata = (fields, data) => {
        var convertedData = [];
        data.map((item) => {
            var dynamicJSON = {};

            for (const field of fields) {
                const { name, value } = field;
                // var test = "val."+value
                // if(test.includes(".@"))
                // {
                //     var new1 = test.split(".@")
                //     test= new1[0]+`['@${new1[1]}']`
                // }
                const value1 = eval(value);

                dynamicJSON[name] = value1;
            }
            convertedData.push(dynamicJSON);
        });
        return convertedData;
    };
    const csvExport = async () => {
        setLoader(true);
        var size = 1000;
        var data = await getDetectionTableData(size, "desc", 0);
        var data3 = data.data.hits.hits;
        var count = data.data.hits.total.value;
        var iter = 1000;
        while (count > 1000 && iter <= count) {
            var tempdata = await getDetectionTableData(size, "desc", iter);
            data3.push(...tempdata.data.hits.hits);
            iter += size;
        }
        var fields = getFields();
        var filename = "Compliance" + "_" + moment().toISOString();
        var data1 = convertdata(fields, data3);
        exportCSVFile(fields, data1, filename);
        setLoader(false);
    };

    return (
        <div className="">
            <DropdownButton
                variant="light"
                title={loader ? "Downloading" : "Export as"}
                disabled={loader}
            >
                <Dropdown.Item
                    onClick={() => {
                        csvExport();
                    }}
                >
                    CSV
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};

export default AlertExport;
