import React from 'react'
import Loader from "../Loader/Loader";
import { TimelineLoader } from '../Loader/TimelineLoader';
import { ListDashes } from 'phosphor-react';
import globalStyles from '../../constants/globalStyles';




export default function CaseActivities({ activitiesList, getDateFromTimestamp, convertUTCTimeToLocal, activityDivLoad }) {
    return (
        <>
            {activityDivLoad === true ? <TimelineLoader count={2} /> : activitiesList.length === 0 ? <div>No Activities found</div> :
                <div class="col-12 position-relative">
                    <ul className="timeline-2 text-black">
                        {/* <div className='timeline-2 d-flex col-12 gap-3 flex-column pb-4'> */}
                        {activitiesList?.map((data, index) => (
                            <li className="col-12" key={index}>
                                <p className="timeline-item rounded-3 cursor-pointer position-relative">
                                    <div className='d-flex flex-row gap-3'>
                                        <span className='tile-icon rounded-5 bg-dark'><ListDashes size={24} color={globalStyles.white} /></span>
                                        <div className="p-3 rounded-3 cursor-pointer position-relative timeline-item-content-wrap d-flex flex-column gap-2">
                                            <h6 className="mb-3 ">{data.note}</h6>
                                            <div className='d-flex gap-3 col-12'>
                                                <div className="text-left d-flex justify-content-start modal-label modal-label-sm event-date">{getDateFromTimestamp(data?.activity_time)}</div>
                                                <div className="text-left d-flex justify-content-start modal-label modal-label-sm event-time">{convertUTCTimeToLocal(data?.activity_time)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                            </li>
                        ))}
                        {/* </div> */}
                    </ul >
                </div>
            }</>
    )
}
