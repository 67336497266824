
import coverImage from "../../assets/images/home-cover-1.jpg";
import globalStyles from "../../constants/globalStyles";
const styles = {
  mainContainer: {
    height: "100vh",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    // gap: "20px",
    // backgroundColor: "rgb(22, 23, 27)"
    backgroundColor: "#FFFFFF",
  },
  topnav: {
    // height: "60px",
  },
  logo: {
    height: "30px",
    alignSelf: "flex-start",
  },
  mainWrap: {
    backgroundImage: "url(" + coverImage + ")",
    backgroundSize: "cover",
    backgroundPosition: "bottom"
  },
  sidebar: {
    // backgroundColor: "rgba(255,255,255,0.05)",
    // backgroundImage: "url(" + coverImage + ")",
    // backgroundSize: "cover",
    // backgroundPosition: "center",
  },
  contentWrap: {
    paddingRight: "0px !important",
  },
  tileWrap: {
    // alignContent: "baseline",
    justifyContent: "center",
    placeContent: "baseline",
  },
  title: {
    // color: "#FFFFFF",
  },
  tile: {
    padding: "1.5rem",
    // width: "22.83%",
    // width: "31.86%",
    width: "120px",
    height: "120px",
    // width: "140px",
    // height: "140px",
    display: "inline-flex",
    // flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "stretch",
    alignItems: "center",
    borderRadius: "10px",
    textDecoration: "none",
    color: "#000000",
    // border: "1px solid #DDDDDD",
    // backdropBilter: "blur(2px)",
    backgroundColor: "rgba(255,255,255,1)",
    transition: ".3s"
  },
  tileHover: {
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  tileImage: {
    // width: "70%",
    width: "50px"
    // marginLeft: "auto"
  },
  tileTextWrap: {
    marginTop: "1.5em",
  },
  tileTitle: {
    fontWeight: "600",
    opacity: "0.7",
    textAlign: "center",
    // fontSize: "14px"
  },
  tileDescription: {
    display: "none",
  },
  copyright: {
    textAlign: "right",
    bottom: "0px",
    bottom: "0px",
    position: "absolute",
    right: "0",
    width: "100%"
  },
  profileMenu: {
    backgroundColor: "#FFFFFF",
    right: 0,
    padding: "20px",
    zIndex: 4,
  },
};

export default styles;
