import React from "react";
import Button from "react-bootstrap/Button";
import { Modal, Spinner } from "react-bootstrap";

const DialogBox = ({
    title,
    ShowOrHide,
    handleClose,
    onConfirm,
    isLoading
}) => {

    return (
        <Modal
            show={ShowOrHide}
            onHide={handleClose}
            size={"sm"}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={"modal-auto-height"}
            scrollable={true}
            centered
            data-backdrop="static"
            className="ignore-onclickoutside"
        >
            <Modal.Header >
                <button type="button" className="btn-close" onClick={() => handleClose()}  ></button>
            </Modal.Header>

            <Modal.Body className="p-2">
                <div className="d-flex flex-column align-items-center gap-3">
                    {title}
                    <div className="d-flex gap-4">
                        <Button variant="secondary" onClick={handleClose}>No</Button>
                        <Button variant="danger" onClick={onConfirm}>
                            {isLoading ?
                                <Spinner animation="border" role="status" size="sm" />
                                : "Yes"}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DialogBox;
