const styles = {
    section1: {
      height: "calc(100vh - 15em)",
      // height: "calc(85vh - 11em)",
      paddingRight: "1rem",
      overflow: "auto",
    },
    overviewWrap: {
      height: "calc(100vh - 8em)",
      overflow: "auto",
      // paddingRight: "1rem"
    },
    overviewTile: {
      // backgroundColor: "#f1f1f1",
    },
    tile: {
      // backgroundColor: "#f1f1f1",
      cursor: "pointer",
    },
    tableWrap: {
      height: "calc(100vh - 16em)",
      overflow: "auto"
    },
    paginationWrap: {
      alignItems: "center",
      justifyContent: "flex-end",
      height: "50px",
      // backgroundColor: "#CCCCCC",
      position: "absolute",
      bottom: "1em",
      right: "0px",
    },
  };
  
  // const headerStyles = {
  //   // Regular styles
  
  //   display: "none",
  
  //   // Print-specific styles
  //   "@media print": {
  //     display: "none",
  //   },
  // };
  
  // export default { styles, headerStyles };
  export default styles;
  