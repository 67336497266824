import React, { useCallback, useEffect, useState } from 'react'
import styles from "./DeepDarkWeb.styles";
import SearchDeepDark from '../../../components/Search/SearchDeepDark';
import { fget } from '../../../axios/axios';
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import { Link } from 'react-router-dom';
import { SingleLoader } from '../../../components/Loader/SingleTileLoader';
import { getDateFromTimestamp, getTimeDifferenceFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils';
import globalStyles from '../../../constants/globalStyles';
import DotLoader from '../../../components/Loader/DotLoader';
import ImageViewerComponent from '../../../components/ImageViewer/ImageViewerComponent';
import TableViewMore from '../../../components/ViewMoreBtn/TableViewMore';
import { Warning } from 'phosphor-react';



const DeepDarkWeb = () => {



    const [deepDarkSearch, setDeepDarkSearch] = useState('')
    const [detailsPageKeyword, setDetailsPageKeyword] = useState('')
    const [detailsPageData, setDetailsPageData] = useState([])
    const [loader, setLoader] = useState(true)

    const [isFullScreen, setisFullScreen] = useState(false)
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")
    const handleSearchResult = (val, e, action) => {

        if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
            setDeepDarkSearch(val);
        }
    };

    useEffect(() => {
        setDetailsPageData([])
        if (detailsPageKeyword) {
            setLoader(true)
            fget(`${apiBaseUrl}/ti/tor-intel?keyword=${detailsPageKeyword}`).then(({ data }) => {
                if (data) {
                    const sortedData = [...data]?.sort((a, b) => new Date(b?.timestamp)?.getTime() - new Date(a?.timestamp)?.getTime());
                    setDetailsPageData(sortedData);
                  }
                else{
                    setDetailsPageData([]);
                }
                setisFullScreen(false)
                setLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setLoader(false)
            })
        }
    }, [detailsPageKeyword])

    useEffect(() => {
        fget(`${apiBaseUrl}/ti/tor-intel/keywords`).then(({ data }) => {
            data && data.length > 0 && setDetailsPageKeyword(data[0]?.keyword)
            setLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setLoader(false)
        })
    }, [])




    return (
        <>
            <div
                className="d-flex flex-fill flex-column p-0 rounded-3 bg-white gap-3"
                style={styles.overviewWrap}
            >
                <SearchDeepDark changeFunction={handleSearchResult} setDetailsPageKeyword={setDetailsPageKeyword} detailsPageKeyword={detailsPageKeyword} setisFullScreen={setisFullScreen} setLoader={setLoader} loader={loader} />
            </div>
            <div className="col-8 p-4 rounded-3 bg-white position-relative">
                <h4>Results</h4>
                <div className='col-12 d-flex flex-column gap-3 overflow-auto' style={{ paddingRight: "1rem", height: "calc(100% - 2rem)" }}>
                    {loader ? (
                        <SingleLoader height={"calc(100vh - 11.1rem)"} />
                    ) : (
                        <>
                            {detailsPageData?.length > 0 ? (
                                <>
                                    <span style={{ fontSize: "1.3rem", fontStyle: "italic", fontWeight: "500" }}>{`Found ${detailsPageData?.length} results for "${detailsPageKeyword}"`}</span>
                                    {detailsPageData?.map((item, index) => (
                                        <div className='col-12 d-flex gap-3 p-3 rounded-3' style={{ backgroundColor: globalStyles.greyLite2 }}>
                                            {item?.image ? (
                                                <div className='col-3'>
                                                    {/* <img src={item?.image} alt="" className='w-100 rounded-3' onClick={() => setisFullScreen(!isFullScreen)} /> */}
                                                    {/* image viewer component */}
                                                    <ImageViewerComponent image={item?.image} imageIndex={index} />
                                                </div>
                                            ) : (
                                                <div className='col-3 d-flex justify-content-center align-items-center'>
                                                    <span>no preview available</span>
                                                </div>
                                            )}

                                            <div className='flex-fill d-flex flex-column'>
                                                <div className="modal-row-item">
                                                    <span className='modal-label col-3'>Keyword found</span>
                                                    <span className='modal-text'><strong>{item?.keyword_found}</strong></span>
                                                </div>
                                                <div className="modal-row-item">
                                                    <span className='modal-label col-3'>Date</span>
                                                    <span className='modal-text'>{getDateFromTimestamp(item?.timestamp)}</span>
                                                </div>
                                                <div className="modal-row-item">
                                                    <span className='modal-label col-3'>Time</span>
                                                    <span className='modal-text'>{getTimeFromTimestamp(item?.timestamp)}</span>
                                                </div>
                                                <div className="modal-row-item">
                                                    <span className='modal-label col-3 d-flex align-items-center gap-3'>
                                                        Website
                                                        <TableViewMore Icon={Warning} dataToggle={"Visiting onion links can expose you to potentially illegal or harmful content. Exercise caution and ensure you are adhering to ethical and legal guidelines before proceeding."} dataPlacement={"bottom"} color={globalStyles.orangeDark1} />
                                                    </span>
                                                    <span className='text-break'>{item?.site}</span>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </>
                            ) : (
                                <>
                                    {/* <DotLoader text={"Processing"} /> */}
                                    <NoDataFound text={"No data Found"} />
                                </>
                            )}
                        </>
                    )}
                </div>

            </div>
        </>
    )
}

export default DeepDarkWeb