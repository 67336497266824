import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { fget, fpost } from "../../axios/axios";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./Analytics.styles";
import SecurityAnalytics from "./SecurityAnalytics/SecurityAnalytics";
import Management from "./Management/Management";
import Ciso from "./Ciso/Ciso";



const Analytics = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();

  const [dataLakeUName, setDataLakeUName] = useState('');
  const [dataLakePassword, setDataLakePassword] = useState('');
  const [data, setData] = useState();
  const [dateFilter, setDateFilter] = useState();

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);



  useEffect(() => {
    getSystemVar();

  }, [])


  useEffect(() => {
    if (currentPath === "securityanalytics") {
      setTitle("Security Analytics")
      setBreadcrumb({
        "module": "analytics",
        "path": [{ "title": "Analytics", "url": "/analytics/securityanalytics" }, { "title": "Security Analytics", "url": "/analytics/securityanalytics" },]
      });
    }
    else if (currentPath === "management") {
      setTitle("Analytics Management")
      setBreadcrumb({
        "module": "analytics",
        "path": [{ "title": "Analytics", "url": "/analytics/securityanalytics" }, { "title": "Analytics Management", "url": "/analytics/management" },]
      });
    } else {
      setTitle("CISO")
      setBreadcrumb({
        "module": "analytics",
        "path": [{ "title": "Analytics", "url": "/analytics/securityanalytics" }, { "title": "CISO", "url": "/analytics/ciso" },]
      });
    }
  }, [currentPath]);


  const getSystemVar = async () => {
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    try {
      const response = await fget(
        // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
        `${apiBaseUrl}/core/systemvars`
      );

      setData(response?.data);
      setDataLakeUName(response?.data?.datalakeuname)
      setDataLakePassword(response?.data?.datalakepass)


    } catch (error) {
      console.log("getSystemVar error in settings", error);
    }
  };





  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"analytics"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          setDateFilter={setDateFilter}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "securityanalytics" ? (
            <SecurityAnalytics data={data} uName={dataLakeUName} password={dataLakePassword} />
          ) : currentPath === "management" ? (
            <Management data={data} uName={dataLakeUName} password={dataLakePassword} />
          ) :
            <Ciso data={data} uName={dataLakeUName} password={dataLakePassword} />}
        </div>
      </div>
    </div>
  );
};

export default Analytics;



