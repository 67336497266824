import { Minus, Plus, X } from "phosphor-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Tab, Tabs, Spinner } from "react-bootstrap";
import { fget, fpost } from "../../axios/axios";
import styles from "./Settings.styles";

const Authentication = () => {
  const [selectedTab, setSelectedTab] = useState("Datalake");
  const [systemVar, setSystemVar] = useState({});
  const [variableDivList, setVariableDivList] = useState([]);
  const [id, setId] = useState(1);
  const [updatingVar, setUpdatingVar] = useState(false);
  const [dlfieldType, setDlFiledType] = useState("password");
  const [cyfieldType, setCyFiledType] = useState("password");
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    console.log("Home", process.env.REACT_APP_SSO_URL);
    getSystemVar();
  }, []);
  const apiBaseUrl =localStorage.getItem("apiBaseUrl")

  const getSystemVar = async () => {
    try {
      const response = await fget(
        // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
        `${apiBaseUrl}/core/systemvars`
      );
      console.log("systemvar", response.data);
      setSystemVar(response.data);
      // setVariablesArray(response.data?.variables);
      setVariableDivList(
        response.data?.variables.map((item, index) => {
          return { ...item, id: index + item.name };
        })
      );
    } catch (error) {
      console.log("getSystemVar error in settings", error);
    }
    setIsLoading(false)

  };

  const updateSystemVar = async (tab) => {
    setUpdatingVar(true);
    try {
      if (tab === "datalake") {
        console.log("datalake tab");
        var data = {
          ...systemVar,
          variables: variableDivList.map((item) => {
            return { name: item.name, value: item.value };
          }),
        };
      } else {
        var data = systemVar;
        console.log("cybot tab");
      }

      const response = await fpost(
        `${apiBaseUrl}/core/systemvars`,
        data
      );
      setUpdatingVar(false);
    } catch (error) {
      setUpdatingVar(false);

      console.log("getSystemVar error in settings", error);
    }
  };

  const addVariable = () => {
    let newItem = [...variableDivList, { name: "", value: "", id, new: true }];
    setId(id + 1);
    setVariableDivList(newItem);
    console.log("addvar", newItem);
    // setSystemVarArray(newItem);
  };

  const removeVariable = (id) => {
    let newItem = variableDivList.filter((item) => item.id !== id);
    setVariableDivList(newItem);
    console.log("removevar", newItem);
  };

  const editExistingVarValue = (e, index, item) => {
    let temp = {
      name: variableDivList[index].name,
      value: e.target.value,
      id: variableDivList[index].id,
    };

    let updatedList = variableDivList.map((i) => {
      if (i.id === item.id) {
        return temp;
      } else {
        return i;
      }
    });
    console.log("updatevar", updatedList);
    setVariableDivList(updatedList);
  };

  const editVarName = (e, index, item) => {
    let temp = {
      name: e.target.value,
      value: variableDivList[index].value,
      id: variableDivList[index].id,
      new: true,
    };
    console.log("check", e.target.value, temp);

    let updatedList = variableDivList.map((i) => {
      if (i.id === item.id) {
        return temp;
      } else {
        return i;
      }
    });
    console.log("editVarName", updatedList);
    setVariableDivList(updatedList);
  };

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      {IsLoading?<div style={{ position: "absolute", top: "50%", left: "50%" }}><Spinner /></div>:<Tabs
        defaultActiveKey="datalake"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="datalake" title="Datalake">
          <div className="col-7" style={styles.tableWrap}>
            {(systemVar.variables.filter((item) => (item.name === "is_updated_datalake_proxy" && (item.value === true||item.value === "true"))).length===0)&&<Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Datalake name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Datalake name"
                className=""
                value={systemVar.datalakeuname}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, datalakeuname: e.target.value })
                }
              />
            </Form.Group>}
            {(systemVar.variables.filter((item) => (item.name === "is_updated_datalake_proxy" && (item.value === true||item.value === "true"))).length===0)&&<Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Datalake password</Form.Label>
              <Form.Control
                type={dlfieldType}
                placeholder="Datalake password"
                className=""
                value={systemVar.datalakepass}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, datalakepass: e.target.value })
                }
              />
              <div className="d-flex">
                <input
                  type="checkbox"
                  id="dlfieldpass"
                  className="mt-1"
                  onChange={(e) => {
                    if (dlfieldType === "password") {
                      setDlFiledType("text");
                    } else {
                      setDlFiledType("password");
                    }
                  }}
                />
                <div
                  style={{
                    marginTop: "3px",
                    marginLeft: "3px",
                  }}
                >
                  <small>Show Password</small>
                </div>
              </div>

              {/* <Form.Check // prettier-ignore
                type="checkbox"
                id="showpass"
                label="Show Password"
                className="ml-2"
                onChange={(e) => {
                  if (fieldType === "password") {
                    setFiledType("text");
                  } else {
                    setFiledType("password");
                  }
                }}
              /> */}
            </Form.Group>}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Datalake url</Form.Label>
              <Form.Control
                type="text"
                placeholder="Datalake url"
                className=""
                value={systemVar.datalakeurl}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, datalakeurl: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>EDR Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="EDR type"
                className=""
                value={systemVar.edr_type}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, edr_type: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cykit Edr Index Pattern</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cykit Edr Index Pattern"
                className=""
                value={systemVar.cybot_cykit_index_pattern}
                onChange={(e) =>
                  setSystemVar({
                    ...systemVar,
                    cybot_cykit_index_pattern: e.target.value,
                  })
                }
              />
            </Form.Group>

            <div className="col-12 d-flex flex-column">
              <Button
                variant="primary"
                className="col-2 d-flex flex-row align-items-center justify-content-center mb-2"
                onClick={addVariable}
              >
                Add Variables
              </Button>
              {variableDivList?.map((item, index) => (
                <div
                  className="col-12 d-flex flex-row gap-2 mb-2"
                  key={item.id}
                >
                  {!item.new ? (
                    <div className="col-5">
                      <label>{item.name}</label>
                    </div>
                  ) : (
                    <input
                      className="form-control"
                      value={item.name}
                      onChange={(e) => editVarName(e, index, item)}
                    />
                  )}
                  <input
                    className="form-control"
                    value={item.value}
                    onChange={(e) => editExistingVarValue(e, index, item)}
                  />
                  <Button
                    variant="danger"
                    className="col-1 d-flex flex-row align-items-center justify-content-center"
                    onClick={() => removeVariable(item.id)}
                  >
                    <Minus />
                  </Button>
                </div>
              ))}
            </div>

            <Button
              variant="dark"
              className=" d-flex flex-row align-items-center justify-content-center"
              onClick={() => updateSystemVar("datalake")}
            >
              {updatingVar ? "Saving..." : "Save"}
            </Button>
          </div>
        </Tab>
        <Tab eventKey="cybot" title="Cybot">
          <div className="col-3" style={styles.tebleWrap}>
            {(systemVar.variables.filter((item) => (item.name === "is_updated_proxy" && (item.value === true||item.value === "true"))).length===0)&&<Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cybot name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cybot name"
                className=""
                value={systemVar.cybotuname}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, cybotuname: e.target.value })
                }
              />
            </Form.Group>}
            {(systemVar.variables.filter((item) => (item.name === "is_updated_proxy" && (item.value === true||item.value === "true"))).length===0)&&<Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cybot password</Form.Label>
              <Form.Control
                type={cyfieldType}
                placeholder="Cybot password"
                className=""
                value={systemVar.cybotpass}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, cybotpass: e.target.value })
                }
              />
              <div className="d-flex">
                <input
                  type="checkbox"
                  id="cyfieldpass"
                  className="mt-1"
                  onChange={(e) => {
                    if (cyfieldType === "password") {
                      setCyFiledType("text");
                    } else {
                      setCyFiledType("password");
                    }
                  }}
                />
                <div
                  style={{
                    marginTop: "3px",
                    marginLeft: "3px",
                  }}
                >
                  <small>Show Password</small>
                </div>
              </div>
            </Form.Group>}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Cybot url</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cybot url"
                className=""
                value={systemVar.cyboturl}
                onChange={(e) =>
                  setSystemVar({ ...systemVar, cyboturl: e.target.value })
                }
              />
            </Form.Group>

            <Button
              variant="dark"
              className=" d-flex flex-row align-items-center justify-content-center"
              onClick={() => updateSystemVar("cybot")}
            >
              {updatingVar ? "Saving..." : "Save"}
            </Button>
          </div>
        </Tab>
      </Tabs>}
    </div>
  );

  return (
    <div style={{ backgroundColor: "white", flex: 1, padding: "10px" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <div
          style={{
            backgroundColor: "grey",
            padding: "10px",
            borderRadius: 7,
            marginBottom: "10px",
          }}
          onClick={() => setSelectedTab("Datalake")}
        >
          Datalake
        </div>
        <div
          style={{
            backgroundColor: "grey",
            padding: "10px",
            borderRadius: 7,
            marginBottom: "10px",
          }}
          onClick={() => setSelectedTab("Cybot")}
        >
          Cybot
        </div>
      </div>
      {selectedTab === "Datalake" ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>Datalake Name</label>
            <input />
            <label>Datalake Password</label>
            <input />
            <label>Datalake Url</label>
            <input />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "grey",
                padding: "10px",
                borderRadius: 7,
                marginBottom: "10px",
                width: "200px",
              }}
              onClick={addVariable}
            >
              Add Variables +
            </div>
            {variableDivList?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                  gap: "10px",
                }}
                key={item.id}
              >
                <label>test value</label>
                <input style={{ height: "30px" }} />
                <div
                  style={{
                    backgroundColor: "grey",
                    padding: "3px",
                    borderRadius: 7,
                    marginBottom: "10px",
                    // height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    textAlign: "center",
                    alignSelf: "center",
                    width: "30px",
                  }}
                  onClick={() => removeVariable(item.id)}
                >
                  x
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Authentication;
