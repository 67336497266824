import {
  RT_HUNT_ADD_CATALOGUE_REQUEST,
  RT_HUNT_ADD_CATALOGUE_SUCCESS,
  RT_HUNT_ADD_CATALOGUE_FAIL,
  RT_HUNT_REMOVE_CATALOGUE_REQUEST,
  RT_HUNT_REMOVE_CATALOGUE_SUCCESS,
  RT_HUNT_REMOVE_CATALOGUE_FAIL,
  RT_HUNT_LIST_MASTER_REQUEST,
  RT_HUNT_LIST_MASTER_SUCCESS,
  RT_HUNT_LIST_MASTER_FAIL,
  RT_HUNT_LIST_CAT_REQUEST,
  RT_HUNT_LIST_CAT_SUCCESS,
  RT_HUNT_LIST_CAT_FAIL,
  RT_HUNT_LIST_CUSTOM_MASTER_REQUEST,
  RT_HUNT_LIST_CUSTOM_SUCCESS,
  RT_HUNT_LIST_CUSTOM_FAIL,
  RT_HUNT_CUSTOM_MASTER_REQUEST,
  RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE,
  RT_HUNT_CUSTOM_MASTER_SUCCESS,
  RT_HUNT_CUSTOM_MASTER_FAIL,
  RT_HUNT_CAT_SINGLE_LIST_REQUEST,
  RT_HUNT_CAT_SINGLE_LIST_SUCCESS,
  RT_HUNT_CAT_SINGLE_LIST_FAIL,
  RT_HUNT_CUSTOM_SELECT_REQUEST,
  RT_HUNT_CUSTOM_SELECT_SUCCESS,
  RT_HUNT_CUSTOM_SELECT_FAIL,
} from "../../reduxConstants/ruleStudio/HunterConstant";

const initialState = {
  master: [],
  deleteSuccess: false,
  deletedId: ""
}

// HUNTER
export const listMasterRsHunt = (state = initialState, action) => {
  switch (action.type) {
    case RT_HUNT_LIST_MASTER_REQUEST:
      return { ...state, loading: true, master: [] };
    case RT_HUNT_LIST_MASTER_SUCCESS: {
      return { ...state, loading: false, master: action.payload.data };
    }
    case RT_HUNT_LIST_MASTER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RT_HUNT_REMOVE_CATALOGUE_REQUEST:
      return { ...state, loading: true };
    case RT_HUNT_REMOVE_CATALOGUE_SUCCESS:
      return {
        ...state, master: [
          ...state.master.slice(0, action.index),
          ...state.master.slice(action.index + 1)
        ], loading: false, deleteSuccess: true, deletedId: action.id
      };
    case RT_HUNT_REMOVE_CATALOGUE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// HUNTER
export const addToCatalogueRsHunt = (state = {}, action) => {
  switch (action.type) {
    case RT_HUNT_ADD_CATALOGUE_REQUEST:
      return { loading: true };
    case RT_HUNT_ADD_CATALOGUE_SUCCESS:
      return { loading: false, success: true, address_: action.payload };
    case RT_HUNT_ADD_CATALOGUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// HUNTER
// export const removeFromCatalogueRsHunt = (state = {}, action) => {
//   switch (action.type) {
//     case RT_HUNT_REMOVE_CATALOGUE_REQUEST:
//       return { ...state, loading: true };
//     case RT_HUNT_REMOVE_CATALOGUE_SUCCESS:
//       return {
//         ...state, master: [
//           ...state.master.slice(0, action.index),
//           ...state.master.slice(action.index + 1)
//         ], loading: false, success: true, deletedId: action.id
//       };
//     case RT_HUNT_REMOVE_CATALOGUE_FAIL:
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// HUNTER
export const listMasterCustomRsHunt = (state = { list: [] }, action) => {
  switch (action.type) {
    case RT_HUNT_LIST_CUSTOM_MASTER_REQUEST:
      return { loading: true, list: [] };
    case RT_HUNT_LIST_CUSTOM_SUCCESS: {
      return { loading: false, list: action.payload.data };
    }
    case RT_HUNT_LIST_CUSTOM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// HUNTER
export const createInvestigateRsHunt = (state = {}, action) => {
  switch (action.type) {
    case RT_HUNT_CUSTOM_MASTER_REQUEST:
      return { loading: true };
    case RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE:
      return { success: false };
    case RT_HUNT_CUSTOM_MASTER_SUCCESS:
      return { loading: false, success: true, master: action.payload };
    case RT_HUNT_CUSTOM_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// HUNTER
export const detailsCustomInvestigateRsHunt = (
  state = { catalogue: {} },
  action
) => {
  switch (action.type) {
    case RT_HUNT_CAT_SINGLE_LIST_REQUEST:
      return { ...state, loading: true };
    case RT_HUNT_CAT_SINGLE_LIST_SUCCESS:
      return { loading: false, catalogue: action.payload.data };
    case RT_HUNT_CAT_SINGLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CATALOGUE
export const listCatalogueRsCtHunt = (state = { catalogue: [] }, action) => {
  switch (action.type) {
    case RT_HUNT_LIST_CAT_REQUEST:
      return { loading: true, catalogue: [] };
    case RT_HUNT_LIST_CAT_SUCCESS: {
      return { loading: false, catalogue: action.payload.data };
    }
    case RT_HUNT_LIST_CAT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// MASTER
export const listCustomSelectHunterRs = (state = { list: [] }, action) => {
  switch (action.type) {
    case RT_HUNT_CUSTOM_SELECT_REQUEST:
      return { loading: true, list: [] };
    case RT_HUNT_CUSTOM_SELECT_SUCCESS: {
      return { loading: false, list: action.payload.data };
    }
    case RT_HUNT_CUSTOM_SELECT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
