import React, { useEffect, useState } from "react";

import {
  fget,
  fpostParams,
  fpost,
  fpostRs,
  fdeleteParams,
} from "../axios/axios";

import {
  RT_INV_ADD_CATALOGUE_REQUEST,
  RT_INV_ADD_CATALOGUE_SUCCESS,
  RT_INV_ADD_CATALOGUE_FAIL,
  RT_INV_REMOVE_CATALOGUE_REQUEST,
  RT_INV_REMOVE_CATALOGUE_SUCCESS,
  RT_INV_REMOVE_CATALOGUE_FAIL,
  RT_INV_LIST_MASTER_REQUEST,
  RT_INV_LIST_MASTER_SUCCESS,
  RT_INV_LIST_MASTER_FAIL,
  RT_INV_LIST_CAT_REQUEST,
  RT_INV_LIST_CAT_SUCCESS,
  RT_INV_LIST_CAT_FAIL,
  RT_INV_LIST_CUSTOM_MASTER_REQUEST,
  RT_INV_LIST_CUSTOM_SUCCESS,
  RT_INV_LIST_CUSTOM_FAIL,
  RT_INV_CUSTOM_MASTER_SUCCESS_FALSE,
  RT_INV_CUSTOM_MASTER_REQUEST,
  RT_INV_MASTER_SUCCESS,
  RT_INV_MASTER_FAIL,
  RT_INV_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
  RT_INV_MASTER_SINGLE_LIST_SUCCESS,
  RT_INV_MASTER_SINGLE_LIST_FAIL,
  RT_INV_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
  RT_INV_MASTER_SINGLE_LIST_QUERY_SUCCESS,
  RT_INV_MASTER_SINGLE_LIST_QUERY_FAIL,
} from "../reduxConstants/ruleStudio/InvestigationConstant";

import {
  RT_HUNT_ADD_CATALOGUE_REQUEST,
  RT_HUNT_ADD_CATALOGUE_SUCCESS,
  RT_HUNT_ADD_CATALOGUE_FAIL,
  RT_HUNT_REMOVE_CATALOGUE_REQUEST,
  RT_HUNT_REMOVE_CATALOGUE_SUCCESS,
  RT_HUNT_REMOVE_CATALOGUE_FAIL,
  RT_HUNT_LIST_MASTER_REQUEST,
  RT_HUNT_LIST_MASTER_SUCCESS,
  RT_HUNT_LIST_MASTER_FAIL,
  RT_HUNT_LIST_CAT_REQUEST,
  RT_HUNT_LIST_CAT_SUCCESS,
  RT_HUNT_LIST_CAT_FAIL,
  RT_HUNT_LIST_CUSTOM_MASTER_REQUEST,
  RT_HUNT_LIST_CUSTOM_SUCCESS,
  RT_HUNT_LIST_CUSTOM_FAIL,
  RT_HUNT_CUSTOM_MASTER_REQUEST,
  RT_HUNT_CUSTOM_MASTER_SUCCESS,
  RT_HUNT_CUSTOM_MASTER_FAIL,
  RT_HUNT_CAT_SINGLE_LIST_REQUEST,
  RT_HUNT_CAT_SINGLE_LIST_SUCCESS,
  RT_HUNT_CAT_SINGLE_LIST_FAIL,
  RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE,
} from "../reduxConstants/ruleStudio/HunterConstant";

import {
  RT_COMPLIANCE_LIST_CAT_REQUEST,
  RT_COMPLIANCE_LIST_CAT_SUCCESS,
  RT_COMPLIANCE_LIST_CAT_FAIL,
  COMPLIANCE_REMOVE_CATALOGUE_REQUEST,
  COMPLIANCE_REMOVE_CATALOGUE_SUCCESS,
  COMPLIANCE_REMOVE_CATALOGUE_FAIL,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS,
  RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL,
  RT_COMPLIANCE_CUSTOM_MASTER_REQUEST,
  RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS,
  RT_COMPLIANCE_CUSTOM_MASTER_FAIL,
  RT_COMP_LIST_MASTER_REQUEST,
  RT_COMP_LIST_MASTER_SUCCESS,
  RT_COMP_LIST_MASTER_FAIL,
  RT_COMP_ADD_CATALOGUE_REQUEST,
  RT_COMP_ADD_CATALOGUE_SUCCESS,
  RT_COMP_ADD_CATALOGUE_FAIL,
  RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE,
  RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
  RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS,
  RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL,
  RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
  RT_COMP_MASTER_SINGLE_LIST_SUCCESS,
  RT_COMP_MASTER_SINGLE_LIST_FAIL
} from "../reduxConstants/ruleStudio/ComplianceConstants";

import {
  RT_UEBA_ADD_CATALOGUE_REQUEST,
  RT_UEBA_ADD_CATALOGUE_SUCCESS,
  RT_UEBA_ADD_CATALOGUE_FAIL,
  RT_UEBA_REMOVE_CATALOGUE_REQUEST,
  RT_UEBA_REMOVE_CATALOGUE_SUCCESS,
  RT_UEBA_REMOVE_CATALOGUE_FAIL,
  RT_UEBA_LIST_MASTER_REQUEST,
  RT_UEBA_LIST_MASTER_SUCCESS,
  RT_UEBA_LIST_MASTER_FAIL,
  RT_UEBA_LIST_CAT_REQUEST,
  RT_UEBA_LIST_CAT_SUCCESS,
  RT_UEBA_LIST_CAT_FAIL,
  RT_UEBA_LIST_CUSTOM_MASTER_REQUEST,
  RT_UEBA_LIST_CUSTOM_SUCCESS,
  RT_UEBA_LIST_CUSTOM_FAIL,
  RT_UEBA_CUSTOM_MASTER_REQUEST,
  RT_UEBA_MASTER_SUCCESS,
  RT_UEBA_MASTER_FAIL,
  RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
  RT_UEBA_MASTER_SINGLE_LIST_SUCCESS,
  RT_UEBA_MASTER_SINGLE_LIST_FAIL,
  RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
  RT_UEBA_MASTER_SINGLE_LIST_QUERY_SUCCESS,
  RT_UEBA_MASTER_SINGLE_LIST_QUERY_FAIL,
  RT_UEBA_CUSTOM_MASTER_SUCCESS_FALSE,
} from "../reduxConstants/ruleStudio/UebaConstants";

import {
  RT_COR_LIST_CAT_REQUEST,
  RT_COR_LIST_CAT_SUCCESS,
  RT_COR_LIST_CAT_FAIL,
  RT_COR_ADD_CATALOGUE_REQUEST,
  RT_COR_ADD_CATALOGUE_SUCCESS,
  RT_COR_ADD_CATALOGUE_FAIL,
  RT_COR_REMOVE_CATALOGUE_REQUEST,
  RT_COR_REMOVE_CATALOGUE_SUCCESS,
  RT_COR_REMOVE_CATALOGUE_FAIL,
  RT_COR_LIST_MASTER_REQUEST,
  RT_COR_LIST_MASTER_SUCCESS,
  RT_COR_LIST_MASTER_FAIL,
  RT_COR_LIST_CUSTOM_MASTER_REQUEST,
  RT_COR_LIST_CUSTOM_SUCCESS,
  RT_COR_LIST_CUSTOM_FAIL,
  RT_COR_CUSTOM_MASTER_REQUEST,
  RT_COR_MASTER_SUCCESS,
  RT_COR_MASTER_FAIL,
  RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
  RT_COR_MASTER_SINGLE_LIST_SUCCESS,
  RT_COR_MASTER_SINGLE_LIST_FAIL,
  RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
  RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS,
  RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL,
  RT_COR_CUSTOM_MASTER_SUCCESS_FALSE

} from "../reduxConstants/ruleStudio/CorrelationConstants";

import { TreeStructure } from "phosphor-react";

// const apiSsoDataUrl = "https://analyticsapi.active-bytes.com/hunt/getcatalogue?type=master"
const apiBaseUrl = localStorage.getItem("apiBaseUrl")
// INVESTIGATION CATALOGUE LIST
export const getInvCatalogueTenant = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_LIST_CAT_REQUEST });

    // fget(`https://analyticsapi.active-bytes.com/getcatalogue?type=tenant`).then(
    fget(
      `${apiBaseUrl}/investigate/catalogue?type=tenant`
    ).then((res) => {
      dispatch({
        type: RT_INV_LIST_CAT_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_INV_LIST_CAT_FAIL,
      payload: error,
    });
  }
};

// INVESTIGATION MASTER list

export function getInvCatalogueMaster(sort) {
  try {


    const data = fget(
      `${apiBaseUrl}/investigate/catalogue?type=master&sort=${sort}`
    )

    return data
  } catch (error) {
    console.log(error);

  }
};

export const setLoadingFalseInv = () => async (dispatch) => {
  dispatch({ type: RT_INV_CUSTOM_MASTER_SUCCESS_FALSE });
};

// INVESTIGATION refresh
export function refreshTenantCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/refresh/tenantcatalogue`
      `${apiBaseUrl}/investigate/refresh/tenant`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

export function refreshMasterInv() {
  try {
    const data = fget(
      `${apiBaseUrl}/investigate/refresh/master`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

// INVESTIGATION - Add to catelogue
export const addToOrganizationCatalogue = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_ADD_CATALOGUE_REQUEST });

    fpostParams(
      // `https://analyticsapi.active-bytes.com/addalerttocollection/?invid=${id}`
      ` ${apiBaseUrl}/investigate/collection?invid=${id}`
    ).then((res) => {
      refreshMasterInv();
      dispatch({
        type: RT_INV_ADD_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_INV_ADD_CATALOGUE_FAIL,
      payload: error,
    });
  }
};

// INVESTIGATION CUSTOM
export const customInvestigation =
  (dataDes, dataQuery, invId) => async (dispatch) => {
    try {
      dispatch({ type: RT_INV_CUSTOM_MASTER_REQUEST });

      fpostRs(
        `${apiBaseUrl}/investigate/alertinfo?invid=${invId}&type=desc&masterkey=na`,
        dataDes
      ).then((res) => {
        fpostRs(
          `${apiBaseUrl}/investigate/alertinfo?invid=${invId}&type=query&masterkey=na`,
          dataQuery
        );

        refreshTenantCatalogue();
        dispatch({
          type: RT_INV_MASTER_SUCCESS,
          payload: res,
        });
        console.log(res.status);
        return res;
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: RT_INV_MASTER_FAIL,
        payload: error,
      });
      return error;
    }
  };

// INVESTIGATION CATALOGUE DETAILS
export const getInvCatalogueDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_CUSTOM_MASTER_SINGLE_LIST_REQUEST });

    fget(
      `${apiBaseUrl}/investigate/alertinfo?invid=${id}&type=desc`
    ).then((res) => {
      dispatch({
        type: RT_INV_MASTER_SINGLE_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    dispatch({
      type: RT_INV_MASTER_SINGLE_LIST_FAIL,
      payload: error,
    });
  }
};

// INVESTIGATION CATALOGUE DETAILS QUERY
export const getInvCatalogueDetailsQuery = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST });

    fget(
      `${apiBaseUrl}/investigate/alertinfo?invid=${id}&type=query`
    ).then((res) => {
      dispatch({
        type: RT_INV_MASTER_SINGLE_LIST_QUERY_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_INV_MASTER_SINGLE_LIST_QUERY_FAIL,
      payload: error,
    });
  }
};

// INVESTIGATION CATALOGUE LIST
export const getExpectedOutputCustomInv = () => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_LIST_CUSTOM_MASTER_REQUEST });

    fget(
      `${apiBaseUrl}/investigate/modulesinfo`
    ).then((res) => {
      dispatch({
        type: RT_INV_LIST_CUSTOM_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_INV_LIST_CUSTOM_FAIL,
      payload: error,
    });
  }
};

export const removeFromHuntCatalogue = (id, index) => async (dispatch) => {
  try {
    dispatch({ type: RT_HUNT_REMOVE_CATALOGUE_REQUEST });
    console.log("request-remove");
    fdeleteParams(
      ` ${apiBaseUrl}/hunter/collection?huntid=${id}`
    ).then((res) => {
      //  refreshHuntTenantCatalogue();

      dispatch({
        type: RT_HUNT_REMOVE_CATALOGUE_SUCCESS,
        index: index,
        id: id,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_REMOVE_CATALOGUE_FAIL,
      payload: error,
    });
  }
};
// HUNTER CATALOGUE DETAILS
export function getHunterCatalogueDetails(id) {
  try {


    const data = fget(
      `${apiBaseUrl}/hunter/hunt/info?huntid=${id}`
    )
    return data;
  } catch (error) {
    console.log(error);

  }
};

export function refreshHuntTenantCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `${apiBaseUrl}/hunter/refresh/tenant`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export function refreshHuntMasterCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `${apiBaseUrl}/hunter/refresh/master`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

// HUNTER
export const addToOrganizationCatalogueHunt = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_HUNT_ADD_CATALOGUE_REQUEST });

    fpostParams(
      `${apiBaseUrl}/hunter/collection?huntid=${id}`
    ).then((res) => {
      // refreshHuntTenantCatalogue();
      dispatch({
        type: RT_HUNT_ADD_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_ADD_CATALOGUE_FAIL,
      payload: error,
    });
  }
};

// HUNTER
export const removeFromCatalogue = (id, index) => async (dispatch) => {
  try {
    dispatch({ type: RT_INV_REMOVE_CATALOGUE_REQUEST });

    fdeleteParams(
      `${apiBaseUrl}/investigate/collection?invid=${id}`
    ).then((res) => {
      if (res.status === 200) {
        refreshTenantCatalogue();
        dispatch({
          type: RT_INV_REMOVE_CATALOGUE_SUCCESS,
          index: index,
          id: id,
        });
      }
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_INV_REMOVE_CATALOGUE_FAIL,
      payload: error,
    });
  }
};

// HUNTER CATALOGUE LIST
export const getHuntCatalogueTenant = () => async (dispatch) => {
  try {
    dispatch({ type: RT_HUNT_LIST_CAT_REQUEST });

    fget(
      `${apiBaseUrl}/hunter/catalogue?type=tenant`
    ).then((res) => {
      dispatch({
        type: RT_HUNT_LIST_CAT_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_LIST_CAT_FAIL,
      payload: error,
    });
  }
};

// HUNTER MASTER LIST
export const getCatalogueMaster = () => async (dispatch) => {
  try {
    dispatch({ type: RT_HUNT_LIST_MASTER_REQUEST });

    fget(
      `${apiBaseUrl}/hunter/catalogue?type=master`
    ).then((res) => {
      dispatch({
        type: RT_HUNT_LIST_MASTER_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_LIST_MASTER_FAIL,
      payload: error,
    });
  }
};

// HUNTER CUSTOM SELECT LIST
export const getHunterSelectList = () => async (dispatch) => {
  try {
    dispatch({ type: RT_HUNT_LIST_CUSTOM_MASTER_REQUEST });

    fget(
      `${apiBaseUrl}/hunter/catalogue?type=tenant`
    ).then((res) => {
      refreshTenantCatalogue();
      dispatch({
        type: RT_HUNT_LIST_CUSTOM_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_LIST_CUSTOM_FAIL,
      payload: error,
    });
  }
};

// CREATE CUSTOM HUNT

export const createCustomHunt = (body, id) => async (dispatch) => {
  console.log("Inside create custom Hunt");

  try {
    dispatch({ type: RT_HUNT_CUSTOM_MASTER_REQUEST });

    fpost(
      `${apiBaseUrl}/hunter/hunt/info?huntid=${id}&masterkey=na`,
      body
    ).then((res) => {
      dispatch({
        type: RT_HUNT_CUSTOM_MASTER_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_HUNT_CUSTOM_MASTER_FAIL,
      payload: error,
    });
  }
};

export const setLoadingFalseHunter = () => async (dispatch) => {
  dispatch({ type: RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE });
};

// Compliance CATALOGUE LIST
export const getComplianceCatalogueTenant = () => async (dispatch) => {
  try {
    dispatch({ type: RT_COMPLIANCE_LIST_CAT_REQUEST });

    fget(
      `${apiBaseUrl}/compliance/alerts/catalogue?type=tenant`
    ).then((res) => {

      refreshTenantCatalogueCompliance();
      dispatch({
        type: RT_COMPLIANCE_LIST_CAT_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMPLIANCE_LIST_CAT_FAIL,
      payload: error,
    });
  }
};

// COMPLIANCE MASTER LIST
export const getCatalogueMasterCompliance = () => async (dispatch) => {
  try {
    console.log();
    dispatch({ type: RT_COMP_LIST_MASTER_REQUEST });

    fget(
      `${apiBaseUrl}/compliance/alerts/catalogue?type=master`
    ).then((res) => {


      refreshMasterCatalogueCompliance();
      dispatch({
        type: RT_COMP_LIST_MASTER_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMP_LIST_MASTER_FAIL,
      payload: error,
    });
  }
};

// Delete from Catalogue

export const removeFromComplianceCatalogue = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPLIANCE_REMOVE_CATALOGUE_REQUEST });

    fdeleteParams(
      `${apiBaseUrl}/compliance/alerts/collection?comid=${id}`
    ).then((res) => {
      refreshTenantCatalogueCompliance();
      dispatch({
        type: COMPLIANCE_REMOVE_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: COMPLIANCE_REMOVE_CATALOGUE_FAIL,
      payload: error,
    });
  }
};

// COMPLIANCE CUSTOM SELECT LIST
export const getComplianceSelectList = () => async (dispatch) => {
  try {
    dispatch({ type: RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST });

    fget(
      `${apiBaseUrl}/compliance/alerts/catalogue?type=tenant`
    ).then((res) => {
      refreshTenantCatalogueCompliance();
      dispatch({
        type: RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL,
      payload: error,
    });
  }
};

// Compliance CUSTOM ADD TO COLLOCTION
export const customCompliance = (data) => async (dispatch) => {
  try {
    dispatch({ type: RT_COMPLIANCE_CUSTOM_MASTER_REQUEST });

    fpost(
      `${apiBaseUrl}/compliance/alerts?comid=${data.com_id}&masterkey=na`,
      data
    ).then((res) => {
      refreshTenantCatalogueCompliance();
      console.log(res.data)
      dispatch({
        type: RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS,
        payload: res.data,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMPLIANCE_CUSTOM_MASTER_FAIL,
      payload: error,
    });
  }
};


// Compliance CATALOGUE DETAILS
export const getCompCatalogueDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST });
    console.log("inside");
    fget(
      `${apiBaseUrl}/compliance/alerts?comid=${id}&type=desc`
    ).then((res) => {
      dispatch({
        type: RT_COMP_MASTER_SINGLE_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    dispatch({
      type: RT_COMP_MASTER_SINGLE_LIST_FAIL,
      payload: error,
    });
  }
};

// compliance CATALOGUE DETAILS QUERY
export const getCompCatalogueDetailsQuery = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST });

    fget(
      `${apiBaseUrl}/compliance/alerts?comid=${id}&type=query`
    ).then((res) => {
      dispatch({
        type: RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL,
      payload: error,
    });
  }
};

// COMPLIANCE tenent refresh
export function refreshTenantCatalogueCompliance() {
  try {
    const data = fget(
      `${apiBaseUrl}/compliance/alerts/refresh/tenant`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

// COMPLIANCE Master Refresh
export function refreshMasterCatalogueCompliance() {
  try {
    const data = fget(
      `${apiBaseUrl}/compliance/alerts/refresh/master`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const setLoadingFalseComp = () => async (dispatch) => {
  dispatch({ type: RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE });
};

// COMPLIANCE ADD TO CAT
export const addToOrganizationCatalogueComp = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COMP_ADD_CATALOGUE_REQUEST });

    fpostParams(
      `${apiBaseUrl}/compliance/alerts/collection?comid=${id}`
    ).then((res) => {
      console.log(res);

      refreshMasterCatalogueCompliance();
      dispatch({
        type: RT_COMP_ADD_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COMP_ADD_CATALOGUE_FAIL,
      payload: error,
    });
  }
};


// CORRELATION CATALOGUE LIST
export const getCorCatalogueTenant = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_LIST_CAT_REQUEST });


    fget(
      `https://api-gw1.active-bytes.com/api/v2/cac/catalogue?type=tenant`
    ).then((res) => {
      dispatch({
        type: RT_COR_LIST_CAT_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COR_LIST_CAT_FAIL,
      payload: error,
    });
  }
};


// CORRELATION - Add to catelogue
export const addToOrganizationCatalogueCor = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_ADD_CATALOGUE_REQUEST });

    fpostParams(
      // `https://analyticsapi.active-bytes.com/addalerttocollection/?invid=${id}`
      ` https://api-gw1.active-bytes.com/api/v2/cac/collection?cacid=${id}`
    ).then((res) => {
      refreshMasterInv();
      dispatch({
        type: RT_COR_ADD_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COR_ADD_CATALOGUE_FAIL,
      payload: error,
    });
  }
};


// CORRELATION - REMOVE 
export const removeFromCatalogueCor = (id, index) => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_REMOVE_CATALOGUE_REQUEST });

    fdeleteParams(
      `https://api-gw1.active-bytes.com/api/v2/cac/collection?cacid=${id}`
    ).then((res) => {

      if (res.status === 200) {
        refreshTenantCatalogue()
        dispatch({
          type: RT_COR_REMOVE_CATALOGUE_SUCCESS,
          index: index,
          id: id
        });
      }

    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COR_REMOVE_CATALOGUE_FAIL,
      payload: error,
    });
  }
};

// Correlation MASTER list
export function getCorCatalogueMaster(sort) {
  try {


    const data = fget(
      `${apiBaseUrl}/cac/catalogue?type=master&sort=${sort}`
    )

    return data
  } catch (error) {
    console.log(error);

  }
};



// INVESTIGATION CATALOGUE LIST
export const getExpectedOutputCustomCor = () => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_LIST_CUSTOM_MASTER_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/cac/modulesinfo`
    ).then((res) => {
      dispatch({
        type: RT_COR_LIST_CUSTOM_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COR_LIST_CUSTOM_FAIL,
      payload: error,
    });
  }
};
// CORRELATION tenant refresh
export function refreshTenantCatalogueCac() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/refresh/tenantcatalogue`
      `${apiBaseUrl}/cac/refresh/tenant`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

// CORRELATION CUSTOM
export const customCorrelation =
  (dataDes, dataQuery, cacId) => async (dispatch) => {
    try {
      dispatch({ type: RT_COR_CUSTOM_MASTER_REQUEST });

      fpostRs(
        `https://api-gw1.active-bytes.com/api/v2/cac/alerts?cacid=${cacId}&type=desc&masterkey=none`,
        dataDes
      ).then((res) => {
        fpostRs(
          `https://api-gw1.active-bytes.com/api/v2/cac/alerts?cacid=${cacId}&query=desc&masterkey=none`,
          dataQuery
        )

        refreshTenantCatalogueCac();
        dispatch({
          type: RT_COR_MASTER_SUCCESS,
          payload: res,
        });
        console.log(res.status);
        return res;
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: RT_COR_MASTER_FAIL,
        payload: error,
      });
      return error;
    }
  };

// CORRELATION CATALOGUE DETAILS
export const getCorCatalogueDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/cac/alerts?cacid=${id}&type=desc`
    ).then((res) => {
      dispatch({
        type: RT_COR_MASTER_SINGLE_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    dispatch({
      type: RT_COR_MASTER_SINGLE_LIST_FAIL,
      payload: error,
    });
  }
};
// INVESTIGATION CATALOGUE DETAILS QUERY
export const getCorCatalogueDetailsQuery = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/cac/alerts?cacid=${id}&type=query`
    ).then((res) => {
      dispatch({
        type: RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL,
      payload: error,
    });
  }
};


// Correlation refresh tenent
export function refreshCorTenantCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `https://api-gw1.active-bytes.com/api/v2/cac/refresh/tenant`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}


// Correlation refresh master

export function refreshCorMasterCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `https://api-gw1.active-bytes.com/api/v2/cac/refresh/master`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Correlation 
export const setLoadingFalseCor = () => async (dispatch) => {
  dispatch({ type: RT_COR_CUSTOM_MASTER_SUCCESS_FALSE });
};


// UEBA CATALOGUE LIST
export const getUebaCatalogueTenant = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_LIST_CAT_REQUEST });

    // fget(`https://analyticsapi.active-bytes.com/getcatalogue?type=tenant`).then(
    fget(
      `https://api-gw1.active-bytes.com/api/v2/ueba/catalogue?type=tenant`
    ).then((res) => {
      dispatch({
        type: RT_UEBA_LIST_CAT_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_UEBA_LIST_CAT_FAIL,
      payload: error,
    });
  }
};


// ueba- Add to catelogue
export const addToOrganizationCatalogueUeba = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_ADD_CATALOGUE_REQUEST });

    fpostParams(
      // `https://analyticsapi.active-bytes.com/addalerttocollection/?invid=${id}`
      ` https://api-gw1.active-bytes.com/api/v2/ueba/collection?uebaid=${id}`
    ).then((res) => {
      refreshMasterInv();
      dispatch({
        type: RT_UEBA_ADD_CATALOGUE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_UEBA_ADD_CATALOGUE_FAIL,
      payload: error,
    });
  }
};
export function removeFromCatalogueUeba(id) {
  try {
    return fdeleteParams(
      `https://api-gw1.active-bytes.com/api/v2/ueba/collection?uebaid=${id}`
    )
    // return data;
  } catch (error) {
    console.log(error);
  }
}

// Ueba - REMOVE 
// export const removeFromCatalogueUeba = (id, index) => async (dispatch) => {
//   try {
//     dispatch({ type: RT_UEBA_REMOVE_CATALOGUE_REQUEST });

//     fdeleteParams(
//       `https://api-gw1.active-bytes.com/api/v2/ueba/collection?uebaid=${id}`
//     ).then((res) => {

//       if (res.status === 200) {
//         refreshUebaTenantCatalogue()
//         dispatch({
//           type: RT_UEBA_REMOVE_CATALOGUE_SUCCESS,
//           index: index,
//           id: id
//         });
//       }

//     });
//   } catch (error) {
//     console.log(error);
//     dispatch({
//       type: RT_UEBA_REMOVE_CATALOGUE_FAIL,
//       payload: error,
//     });
//   }
// };

// ueba MASTER list
export const getUebaCatalogueMaster = (sort) => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_LIST_MASTER_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/ueba/catalogue?type=master&sort=${sort}`
    ).then((res) => {

      dispatch({
        type: RT_UEBA_LIST_MASTER_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_UEBA_LIST_MASTER_FAIL,
      payload: error,
    });
  }
};


// Ueba CATALOGUE LIST
export const getExpectedOutputCustomUeba = () => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_LIST_CUSTOM_MASTER_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/ueba/modulesinfo`
    ).then((res) => {
      dispatch({
        type: RT_UEBA_LIST_CUSTOM_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_UEBA_LIST_CUSTOM_FAIL,
      payload: error,
    });
  }
};

//UebaCUSTOM
export const customUeba =
  (dataDes, uebaId) => async (dispatch) => {
    try {
      dispatch({ type: RT_UEBA_CUSTOM_MASTER_REQUEST });

      fpostRs(
        `https://api-gw1.active-bytes.com/api/v2/ueba/alerts?uebaid=${uebaId}&masterkey=na`,
        dataDes
      ).then((res) => {


        refreshUebaTenantCatalogue();
        dispatch({
          type: RT_UEBA_MASTER_SUCCESS,
          payload: res,
        });
        console.log(res.status);
        return res;
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: RT_UEBA_MASTER_FAIL,
        payload: error,
      });
      return error;
    }
  };

// Ueba CATALOGUE DETAILS
export const getUebaCatalogueDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/ueba/alerts?uebaid=${id}`
    ).then((res) => {
      dispatch({
        type: RT_UEBA_MASTER_SINGLE_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    dispatch({
      type: RT_UEBA_MASTER_SINGLE_LIST_FAIL,
      payload: error,
    });
  }
};
// Ueba CATALOGUE DETAILS QUERY
export const getUebaCatalogueDetailsQuery = (id) => async (dispatch) => {
  try {
    dispatch({ type: RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST });

    fget(
      `https://api-gw1.active-bytes.com/api/v2/ueba/alertinfo?uebaid=${id}&type=query`
    ).then((res) => {
      dispatch({
        type: RT_UEBA_MASTER_SINGLE_LIST_QUERY_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: RT_UEBA_MASTER_SINGLE_LIST_QUERY_FAIL,
      payload: error,
    });
  }
};


// Ueba refresh tenent
export function refreshUebaTenantCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `https://api-gw1.active-bytes.com/api/v2/ueba/refresh/tenant`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}


// Ueba refresh master

export function refreshUebaMasterCatalogue() {
  try {
    const data = fget(
      // `https://analyticsapi.active-bytes.com/hunt/refreshtenant`
      `https://api-gw1.active-bytes.com/api/v2/ueba/refresh/master`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}


export const setLoadingFalseUeba = () => async (dispatch) => {
  dispatch({ type: RT_UEBA_CUSTOM_MASTER_SUCCESS_FALSE });
};