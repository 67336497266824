import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { faker } from '@faker-js/faker';
import { ToastContainer, toast } from 'react-toastify';
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  detectedAnomalies,
  anomaliesDataCountBased,
  anomaliesDataTimeBased,
  getDetectionSummary,
  createExceptions,
  getLicenceInfo,
  o365AuditLog,
  o365MessageLog,
  getO365AuditTime,
  getO365MessageTime,
  getUebaMap,
  getProcessCount,
  getProcessCountThree,
  getProcessCountFour,
  getProcessCountTwo,
  getDetectionDetailsFromGroupId,
  getDetectionOverviewDetails,
} from "../../Methods/UebaMethods.js";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import * as htmlToImage from 'html-to-image';
import { Bar, Chart, Radar } from "react-chartjs-2";
import moment, { utc } from "moment/moment.js";

import styles from "./UebaSummary.styles.js";
import globalStyles from "../../constants/globalStyles.js";
import { escapeHtmlChars, getDateFromTimestamp, getTimeFromTimestamp, rawHTML, timestampConvert, removeHtmlChars } from "../../Utils/Utils.js";
import { CardLoader, OverViewLoader } from "../Loader/CardLoader.jsx";
import BarChartLoader from "../Loader/BarChartLoader.jsx";
import TableViewMore from '../ViewMoreBtn/TableViewMore.jsx';
import { OverlayTrigger, Tooltip as BootstrapTooltip, Table, Badge, Spinner } from 'react-bootstrap';
import { CaretDown, CaretUp, ClipboardText, Copy, CursorText, FilePlus, Files, FileSearch, FileText, FileX, Info, SignIn, SignOut } from "phosphor-react";
import { Tabs, Tab, Form, Button, DropdownButton, Dropdown } from "react-bootstrap";
import CustomModal from "../CustomModal/CustomModal.jsx";
import axios from "../../axios";
import { uebaLabel } from "../../constants/UebaSummary.js";
import NoDataFound from "../NoDataFount/NoDataFound.jsx";
import 'chartjs-adapter-moment';
import AlertBox from "../AlertBox/AlertBox.jsx";
// import EditModal from "../../../components/CaseManagement/EditModal";  
import EditModal from "../CaseManagement/EditModal.jsx";
import { anomaliesDataList } from './AnomaliesList.js'
import PlainCardLoader from '../../components/Loader/PlainCardLoader';
import { fgetCy, fpostC, fpostCy } from "../../axios/axios.js";
import { toastsSuccess, toastsDanger } from "../../components/Toster/Toster";
import UebaDetectionExport from "../PDF/UEBA/UebaDetectionExport.jsx";
import { Chart as GeoChart } from "react-google-charts";
import ReactQuill from "react-quill";
import ExpandedDocument from "../ExpandedDocument/ExpandedDocument.js";



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);


const UebaSummary = () => {

  const { group_id } = useParams()


  // const stateData = useLocation();

  const [detectionData, setDetectionDate] = useState([])
  const [detectionOverviewDescription, setDetectionOverviewDescription] = useState('')


  const divRefImage = useRef(null);
  const geoRefImage = useRef(null);
  const barRefImage = useRef(null);

  let baseURL = localStorage.getItem("CYBOT_URL")
  const [expclickedNodeLog, setExpClickedNodeLog] = useState("")
  const [expClickedIndex, setExpClickedIndex] = useState("")
  const [expcloseLog, setExpCloseLog] = useState(false)
  const module = 'ueba'
  const tab = 'detection'

  const [licenceTenantId, setLicenceTenantId] = useState()

  const [labels, setLabels] = useState([]);
  const [detection, setDetection] = useState([]);

  const [timelineLabel, setTimelineLabel] = useState([]);
  const [timelineData, setTimelineData] = useState([]);

  const [detectionSummaryDetails, setDetectionSummaryDetails] = useState([]);
  // overview 
  const [detectionOverview, setDetectionOverview] = useState([]);
  const [detectionOverviewLoader, setDetectionOverviewLoader] = useState(true);


  const [showDetails, setShowDetails] = useState(false);

  const [cardLoading, setCardLoading] = useState(true);
  const [barChartLoading, setBarChartLoading] = useState(true);

  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);

  // Add Case
  const [caseshow, setCaseShow] = useState(false)
  const [case_id, setCase_ID] = useState("");

  // geo map
  const [countryChart, setCountryChart] = useState([])
  const [countryLoader, setCountryLoader] = useState(true)

  const [countryLabel, setCountryLabel] = useState([])
  const [countryData, setCountryData] = useState([])

  // bar chart

  const [barGraphLoader, setBarGraphLoader] = useState(true)

  const [countryDetails, setCountryDetails] = useState([])

  // set alert
  const [alertPopup, setAlertPopup] = useState(false)

  // case modal

  const [alertCasePopup, setAlertCasePopup] = useState(false)

  // ueba 29
  const [processCountLoader, setProcessCountLoader] = useState(true)
  const [labelsData, setLabelsData] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  // 3
  const [labelsTwoData, setLabelsTwoData] = useState([]);
  const [dataTwoChart, setDataTwoChart] = useState([]);

  // 3
  const [labelsThreeData, setLabelsThreeData] = useState([]);
  const [dataThreeChart, setDataThreeChart] = useState([]);

  // 4

  const [labelsFourData, setLabelsFourData] = useState([]);
  const [dataFourChart, setDataFourChart] = useState([]);


  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;

  // active tab

  const [activeTab, setActiveTab] = useState('overview')


  //  blog
  const [imageBlob, setImageBlob] = useState(null);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const [detectionClosed, setDetectionClosed] = useState({
    closed_at: "",
    closed_by: "",
    comment: "",
    detection_status: "",
  });

  const [detectionOpen, setDetectionOpen] = useState({
    opened_at: "",
    opened_by: "",
    comment: "",
    detection_status: "",
  });

  const [closeAlertPopup, setCloseAlertPopup] = useState(false)
  const [closeAlertMessage, setCloseAlertMessage] = useState('')


  const processOne = {
    labels: labelsData,
    datasets: [
      {
        label: detectionData?._source?.process_name,
        data: dataChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  const processTwo = {
    labels: labelsTwoData,
    datasets: [
      {
        label: detectionData?._source?.process_name,
        data: dataTwoChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  const processThree = {
    labels: labelsThreeData,
    datasets: [
      {
        label: detectionData?._source?.process_name,
        data: dataThreeChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  const processFour = {
    labels: labelsFourData,
    datasets: [
      {
        label: detectionData?._source?.process_name,
        data: dataFourChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  // radar chart 


  const [exceptionFormData, setExceptionFormData] = useState({
    created_at: "",
    created_by: "",
    user_name: "",
    host_name: "",
    comment: "",
    ueba_id: "",
    detection_name: "",
    tenant_id: "",
    expiry_date: ""
  });

  let isUser = true;
  // const [isUser, setIsUser] = useState(true)
  //   let {
  //     state: {
  //       data: { _source },
  //     },
  //   } = useLocation();

  // bar chart

  const GenRecentDays = (detection_time) => {
    const detection_date = moment(detection_time).format("YYYY-MM-DD")

    const startDate = moment(detection_date).subtract(7, 'days').format("YYYY-MM-DD");
    const endDate = moment(detection_date).add(2, 'days').format("YYYY-MM-DD");; // create a moment object from the timestamp

    const dates = []; // initialize an empty array to store the dates

    // loop through each day between the start and end dates
    for (let date = moment(startDate); date.isBefore(endDate); date.add(1, 'day')) {
      dates.push(date.clone().toDate()); // add the date to the array as a JavaScript Date object
    }
    const newDate = dates?.map(obj => moment(obj).format('YYYY-MM-DD'))

    return newDate
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "User activity summary",
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: 'YYYY-MM-DD',
          unit: 'day'
        },
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true
        }
      }
    }
  };

  const UEBA_003Options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Host activity summary",
      },
    },
  };

  // radar chart 


  const RadarData = {
    labels: labelsData?.map((item) => item),
    datasets: [
      {
        label: 'process count for organization',
        data: dataChart?.map((item) => item),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const data = {
    datasets: [
      {
        label: uebaLabel?.filter(obj => obj?.ueba_id === detectionData?._source?.ueba_id)[0]?.label_green,
        data: detection?.filter(obj => obj.result === 1).filter(obj => GenRecentDays(detectionData?._source.detection_timestamp).includes(obj.x)),
        backgroundColor: globalStyles.green,
        barThickness: 10,
        borderRadius: 5,
        borderSkipped: 'bottom',
      },
      {
        label: uebaLabel?.filter(obj => obj?.ueba_id === detectionData?._source?.ueba_id)[0]?.label_red,
        data: detection?.filter(obj => obj.result === -1).filter(obj => GenRecentDays(detectionData?._source.detection_timestamp).includes(obj.x)),
        backgroundColor: globalStyles.red,
        barThickness: 10,
        borderRadius: 5,
        borderSkipped: 'bottom',
      }
    ],
  };

  const UEBA_003Data = {
    datasets: [
      {
        label: uebaLabel?.filter(obj => obj.ueba_id === detectionData?._source?.ueba_id)[0]?.label_green,
        data: detection?.filter(obj => obj?.y > 5),
        backgroundColor: globalStyles.green,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: 'bottom',
      },
      {
        label: uebaLabel?.filter(obj => obj.ueba_id === detectionData?._source?.ueba_id)[0]?.label_red,
        data: detection?.filter(obj => obj?.y <= 5),
        backgroundColor: globalStyles.red,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: 'bottom',
      }
    ],
  };

  // stack bar chart
  const timeGraphOptions = {
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const start = moment(JSON.parse(tooltipItem.formattedValue)[0]).format("HH:mm");
            const day = moment(tooltipItem.raw.x).format('ddd')
            const end = moment(JSON.parse(tooltipItem.formattedValue)[1]).format("HH:mm");
            return `${day} Start Time: ${start}\nEnd Time: ${end}`;
          },
          title: (tooltipItem) => {

            const day = moment(tooltipItem[0].label).format('ddd')
            return `${tooltipItem[0].label} ${day}`;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          parser: 'YYYY-MM-DD',
          unit: 'day'
        },
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true
        }
      },
      y: {
        type: 'time',
        suggestedMin: "00:00",
        suggestedMax: "23:59",
        time: {
          parser: 'HH:mm',
          unit: 'hour',
          stepSize: 0.5,
          displayFormats: {
            'minute': 'HH:mm',
            'hour': 'HH:mm'
          }
        },
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true
        }
      }
    },

  };


  const timeGraphData = {
    datasets: [
      {
        label: uebaLabel?.filter(obj => obj.ueba_id === detectionData?._source?.ueba_id)[0]?.label_green,
        data: timelineData?.filter(obj => GenRecentDays(detectionData?._source.detection_timestamp).includes(obj.x)).filter((item) => item.result === 1),
        backgroundColor: globalStyles.green,
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
      {
        label: uebaLabel.filter(obj => obj.ueba_id === detectionData?._source?.ueba_id)[0]?.label_red,
        data: timelineData.filter(obj => GenRecentDays(detectionData?._source.detection_timestamp).includes(obj.x)).filter((item) => item.result === -1),
        backgroundColor: globalStyles.red,
        categoryPercentage: 1.0,
        barPercentage: 1.0,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  useEffect(() => {
    if (timelineData.length > 0) {
      setBarGraphLoader(false)
    }
  }, [timelineData])


  // open detection status

  const handleDetectionOpen = (tenandId, id) => {
    const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
    fpostC(`${baseURL}/cybot_proxy/${tenandId}/_update/${id}`, data).then((res) => {
      // console.log("OPEN RSE :", res)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  // get tenandId

  useEffect(() => {
    if (detectionData?._source?.detection_status !== "closed") {
      handleDetectionOpen(licenceTenantId, detectionData?._id)
    }
  }, [])

  console.log(imageBlob, 'imageBlob')

  // case
  useEffect(() => {

    if (caseCreateSuccess === true) {

      setCaseShow(false)
      setAlertCasePopup(true)
      // toastsSuccess("Case created successfully")

    }
    // setTimeout(() => {
    //   dispatch(resetReduxData())
    // }, 2000);


  }, [caseCreateSuccess])
  const rawLog = (item) => {
    setExpClickedNodeLog(item?.details?.datalake?._id);
    setExpClickedIndex(item?.details?.datalake?._index);
    setExpCloseLog(false)
  }
  useEffect(() => {

    getDetectionDetailsFromGroupId(group_id).then((res) => {
      let data = res?.data?.hits?.hits
      setDetectionDate(data[0])
    }).catch((err) => {
      console.log("Error", err)
    })

    getDetectionOverviewDetails(group_id).then((res) => {
      let data = res?.data?.hits?.hits
      setDetectionOverviewDescription(data?.[0]._source?.description)
      setDetectionOverview(data?.[0])
      setDetectionOverviewLoader(false)
    }).catch((err) => {
      console.log("Error", err)
      setDetectionOverviewLoader(false)
    })
  }, [group_id, closeAlertPopup])

  useEffect(() => {
    setBarGraphLoader(true)
    // detectedAnomalies(_source).then((res) => {
    //     alert("SXXXX")
    //     console.log("Finalyy ?", res)
    // }).catch((err) => {
    //     alert("failed")
    // })

    getLicenceInfo().then((res) => {
      let hits = res?.data?.hits?.hits
      hits?.map((item) => setLicenceTenantId(item?._source.tenant_id))
    })



    if (detectionData?._source?.type == "count based") {

      if (detectionData?._source?.ueba_id === "UEBA-003") {
        anomaliesDataCountBased(detectionData?._source).then(({ data: { aggregations: { 2: { buckets } } } }) => {
          let label = [];
          let data = [];

          buckets &&
            buckets?.map((item) => {
              label.push(
                // moment.utc(item._source.detection_timestamp).format("MMMM DD")
              );
              data.push({
                x: item.key,
                y: item.doc_count,
                // result: item._source.result
              });

              setLabels(label);
            });
          setDetection(data);
          setBarGraphLoader(false)
        })
      }

      if (detectionData?._source?.ueba_id === "UEBA-025" || detectionData?._source?.ueba_id === "UEBA-027" || detectionData?._source?.ueba_id === "UEBA-031") {
        o365AuditLog(detectionData?._source?.ueba_id, detectionData?._source?.UserId, detectionData?._source?.ClientIP).then(({
          data: {
            hits: { hits },
          },
        }) => {
          let label = [];
          let data = [];
          hits &&
            hits?.map((item) => {
              label.push(
                moment.utc(item._source.detection_timestamp).format("MMMM DD")
              );
              data.push({
                x: moment.utc(item._source.detection_timestamp).format("YYYY-MM-DD"),
                y: item._source.count,
                result: item._source.result
              });

              setLabels(label);
            })

          setDetection(data);
          setBarGraphLoader(false)
        })
      }

      if (detectionData?._source?.ueba_id === "UEBA-033") {
        o365MessageLog(detectionData?._source?.ueba_id, detectionData?._source?.email_receiver_address, detectionData?._source?.email_from_address).then(({
          data: {
            hits: { hits },
          },
        }) => {
          let label = [];
          let data = [];
          hits &&
            hits?.map((item) => {
              label.push(
                moment.utc(item._source.detection_timestamp).format("MMMM DD")
              );
              data.push({
                x: moment.utc(item._source.detection_timestamp).format("YYYY-MM-DD"),
                y: item._source.count,
                result: item._source.result
              });

              setLabels(label);
            });

          setDetection(data);
          setBarGraphLoader(false)
        }).catch((err) => {
          console.log("Error", err)
        })
      }

      if (detectionData?._source?.ueba_id === "UEBA-029") {
        getProcessCount(detectionData?._source?.process_name, detectionData?._source?.detection_timestamp).then(({ data: { aggregations: { date: { buckets } } } }) => {
          let label = [];
          let data = [];

          if (buckets.length > 0) {
            buckets?.map((item) => {
              label.push(timestampConvert(item?.key_as_string, 'dddd'));
              data.push(item?.doc_count);
              setLabelsData(label)
              setDataChart(data)
            })
          }
          setProcessCountLoader(false)

          // }).catch((err) => {
          //   console.log("Error", err)
          // })
          // console.log("DATA @@@@ :", res) timestampConvert
        }).catch((err) => {
          console.log("Error", err)
          setProcessCountLoader(false)
        })
      }


      if (detectionData?._source?.ueba_id === "UEBA-029") {
        getProcessCount(detectionData?._source?.process_name, detectionData?._source?.detection_timestamp).then(({ data: { aggregations: { date: { buckets } } } }) => {
          let label = [];
          let data = [];

          if (buckets.length > 0) {
            buckets?.map((item) => {
              label.push(timestampConvert(item?.key_as_string, 'dddd'));
              data.push(item?.doc_count);
              setLabelsData(label)
              setDataChart(data)
            })
          }
          setProcessCountLoader(false)

          // }).catch((err) => {
          //   console.log("Error", err)
          // })
          // console.log("DATA @@@@ :", res) timestampConvert
        }).catch((err) => {
          console.log("Error", err)
          setProcessCountLoader(false)
        })

        getProcessCountTwo(detectionData?._source?.process_name).then(({ data: { aggregations: { process: { buckets } } } }) => {
          let label = [];
          let data = [];

          if (buckets.length > 0) {
            buckets?.map((item) => {
              label.push(item?.key);
              data.push(item?.doc_count);
              setLabelsTwoData(label)
              setDataTwoChart(data)
            })
          }
        }).catch((err) => {
          alert("Error")
        })

        getProcessCountThree(detectionData?._source?.process_name).then(({ data: { aggregations: { user: { buckets } } } }) => {
          let label = [];
          let data = [];

          if (buckets.length > 0) {
            buckets?.map((item) => {
              label.push(item?.key);
              data.push(item?.doc_count);
              setLabelsThreeData(label)
              setDataThreeChart(data)
            })
          }
        }).catch((err) => {
          alert("Error")
        })

        getProcessCountFour(detectionData?._source?.process_name, detectionData?._source?.user_name).then(({ data: { aggregations: { date: { buckets } } } }) => {
          let label = [];
          let data = [];

          if (buckets.length > 0) {
            buckets?.map((item) => {
              label.push(timestampConvert(item?.key_as_string, 'dddd'));
              data.push(item?.doc_count);
              setLabelsFourData(label)
              setDataFourChart(data)
            })
          }
        }).catch((err) => {
          alert("Error")
        })


        // getUebaMap(detectionData?._source?.user_name, detectionData?._source?.host_name, detectionData?._source?.ueba_id).then(({ data: { hits: { hits } } }) => {

        //   let label = [];
        //   let data = [];

        //   if (hits.length > 0) {
        //     hits?.map((item) => {
        //       label.push(item?._source?.process_name);
        //       data.push(item?._source?.threshold);
        //       setLabelsData(label)
        //       setDataChart(data)
        //     })
        //   }

        // }).catch((err) => {
        //   console.log("Error", err)
        // })

      }

      if (detectionData?._source?.ueba_id === "UEBA-008" || detectionData?._source?.ueba_id === "UEBA-009" || detectionData?._source?.ueba_id === "UEBA-010" || detectionData?._source?.ueba_id === "UEBA-006" || detectionData?._source?.ueba_id === "UEBA-011" || detectionData?._source?.ueba_id === "UEBA-012" || detectionData?._source?.ueba_id === "UEBA-013") {
        anomaliesDataCountBased(detectionData?._source)
          .then(
            ({
              data: {
                hits: { hits },
              },
            }) => {
              let label = [];
              let data = [];
              hits &&
                hits?.map((item) => {
                  label.push(
                    moment.utc(item._source.detection_timestamp).format("MMMM DD")
                  );
                  data.push({
                    x: moment.utc(item._source.detection_timestamp).format("YYYY-MM-DD"),
                    y: item._source.count,
                    result: item._source.result
                  });

                  setLabels(label);
                });

              setDetection(data);
              setBarGraphLoader(false)
            }
          )
          .catch((err) => {
            console.log("Error", err);
            setBarGraphLoader(false)
          });
      }




    } else {

      if (detectionData?._source?.ueba_id === "UEBA-023" || detectionData?._source?.ueba_id === "UEBA-026" || detectionData?._source?.ueba_id === "UEBA-030" || detectionData?._source?.ueba_id === "UEBA-032") {
        getO365AuditTime(detectionData?._source?.ueba_id, detectionData?._source?.UserId, detectionData?._source?.ClientIP).then(({ data: { hits: { hits } } }) => {
          let label = [];
          let data = [];

          hits &&
            hits?.map((item) => {
              data.push({
                x: moment(item._source.detection_timestamp).format("YYYY-MM-DD"),
                y: [
                  moment(item._source.first_activity).format("HH:mm"),
                  moment(item._source.last_activity).format("HH:mm"),
                ],
                result: item._source.result
              })



            });
          setTimelineData(data);
          setBarGraphLoader(false)
        })
      } else if (detectionData?._source?.ueba_id === "UEBA-034") {
        getO365MessageTime(detectionData?._source?.ueba_id, detectionData?._source?.email_from_address, detectionData?._source?.email_receiver_address).then(({ data: { hits: { hits } } }) => {
          let label = [];
          let data = [];

          hits &&
            hits?.map((item) => {
              data.push({
                x: moment(item._source.detection_timestamp).format("YYYY-MM-DD"),
                y: [
                  moment(item._source.first_activity).format("HH:mm"),
                  moment(item._source.last_activity).format("HH:mm"),
                ],
                result: item._source.result
              })



            });
          setTimelineData(data);
          setBarGraphLoader(false)
        })
      }
      else if (detectionData?._source?.ueba_id === "UEBA-028") {

        getUebaMap(detectionData?._source?.user_name, detectionData?._source?.host_name, detectionData?._source?.ueba_id, detectionData?._source?.detection_timestamp).then((res) => {

          let hits = res?.data?.hits?.hits
          let countryList = res?.data?.aggregations?.country?.buckets

          let label = [];
          let data = [];


          hits &&
            hits?.map((item) => {
              label.push(item?._source?.country === "" ? "--" : item?._source?.country);
              data.push(item?._source?.count);
              // setCountryDetails(hits)
            });
          setCountryLabel(label)
          setCountryData(data)
          let geoData = [];
          // let locations = label

          countryList.forEach((location) => {
            geoData.push([location?.key])
          })
          setCountryChart([[''], ...geoData])
          setCountryLoader(false)

        }).catch((err) => {
          console.log("Error", err)
          setCountryLoader(false)
        })


      }
      else {
        anomaliesDataTimeBased(detectionData?._source)
          .then(
            ({
              data: {
                hits: { hits },
              },
            }) => {
              let label = [];
              let data = [];

              hits &&
                hits?.map((item) => {
                  data.push({
                    x: moment(item._source.detection_timestamp).utc().format("YYYY-MM-DD"),
                    y: [
                      moment(item._source.first_activity).utc().format("HH:mm"),
                      moment(item._source.last_activity).utc().format("HH:mm"),
                    ],
                    result: item._source.result
                  })



                });
              setTimelineData(data);

            }
          )
          .catch((err) => {
            console.log("Error", err)

          });

      }


    }

    // const { _source } = detectionData;


    detectionData?._source?.group_id &&
      detectionData?._source?.type &&
      getDetectionSummary(detectionData?._source?.group_id, detectionData?._source?.type).then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          let newDescription = [];

          hits?.map((item) => {
            const {
              detection_timestamp,
              user_name,
              host_name,
              ueba_id,
              event_action,
              target_user,
              user_domain,
              detection_name,
              process_name,
              host_ip,
              host_mac,
              file_name,
              UserId,
              ClientIP,
              email_from_address,
              email_receiver_address,
              SourceFileName,
              country,
              datalake,
            } = item._source;

            let descriptionSummary = rawHTML(setDescriptionSummary(
              ueba_id,
              user_name,
              host_name,
              detection_timestamp,
              event_action,
              target_user,
              user_domain,
              file_name,
              UserId,
              ClientIP,
              email_from_address,
              email_receiver_address,
              SourceFileName,
              process_name,
              country,
              datalake,
            ))

            newDescription.push({
              descriptionSummary: descriptionSummary,
              detection_timestamp: detection_timestamp,
              details: item._source,
            });
          });

          setDetectionSummaryDetails(newDescription);
          setCardLoading(false)
        }
      ).catch((err) => {
        console.log("Error", err)
        setCardLoading(false)
      })


  }, [detectionData]);

  useEffect(() => {

    // setShowDownloadButton(false)
    const timeoutId = setTimeout(() => {
      if (detectionData?._source?.ueba_id === "UEBA-028") {
        convertToBlob("geo")
      } else if (detectionData?._source?.ueba_id === "UEBA-029") {
        convertToBlob("radar")
      } else {
        convertToBlob("bar")
      }

    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [UEBA_003Data, data, timeGraphData])

  const convertToBlob = (type) => {
    setImageBlob(null)
    if (type === "geo") {
      htmlToImage.toBlob(geoRefImage.current).then((blob) => {
        setImageBlob(blob);
        setShowDownloadButton(true)
      });
    } else if (type === "radar") {
      htmlToImage.toBlob(divRefImage.current).then((blob) => {
        setImageBlob(blob);
        setShowDownloadButton(true)
      });
    } else if (type === "bar") {
      htmlToImage.toBlob(barRefImage.current).then((blob) => {
        setImageBlob(blob);
        setShowDownloadButton(true)
      });
    }

  };


  // tooltip
  const renderTooltip = (text) => (
    <BootstrapTooltip id="tooltip">{text}</BootstrapTooltip>
  );

  const setDescriptionSummary = (
    uebaId,
    user_name,
    host_name,
    detection_timestamp,
    event_action,
    target_user,
    user_domain,
    file_name,
    UserId,
    ClientIP,
    email_from_address,
    email_receiver_address,
    SourceFileName,
    process_name,
    country
  ) => {
    let description = "";
    switch (uebaId) {
      case "UEBA-001": {
        description = `The user <b>${user_name}</b> accessed the host <b>${host_name}</b>  for the time period  <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> compared to the host's usual logon activity time. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-002": {
        description = `The user <b>${user_name}</b> logged on to the host <b>${host_name}</b>  for the time period <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> compared to the user's usual logon activity time. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-003": {
        description = `The user <b>${user_name}</b> attempted a logon from an unusual host <b>${host_name}</b> for the time period <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b>, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-004": {
        description = `A file activity <b>${event_action}</b> was performed on file <b>${file_name}</b> by the user </b>${user_name}</b> on the host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is an abnormal activity based on user's usual behaviour. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-005": {
        description = `The user <b>${user_name}</b> performed a user management activity <b>${event_action}</b> on user <b>${target_user}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-006": {
        description = `A file named <b>${file_name}</b> has been modified by the user <b>${user_name}</b> on the host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is an abnormal activity based on user's usual behaviour.Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-008": {
        description = `The user <b>${user_name}</b> performed a <b>(${event_action})</b> action which comes under user management activity over user <b>${target_user}</b> on host <b>${host_name}</b> at </b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-009": {
        description = `A file named <b>${file_name}</b> has been deleted by the user <b>${user_name}</b> from the host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is an abnormal activity based on user's usual behaviour. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-010": {
        description = `An account lockout was found for the user <b>${user_name}</b> on host <b>${host_name}</b> in the domain <b>${user_domain}</b> which varies from the usually observed number for the (24h) time period , hence this was deemed as an anomaly. `;
        break;
      }

      case "UEBA-011": {
        description = `The user <b>${user_name}</b> performed a <b>(${event_action})</b> action on host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is more than the usual threshold. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-012": {
        description = `A  logon failure attempt was found for the user <b>${user_name}</b> on host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular host, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-013": {
        description = `A  logon failure attempt was found for the user <b>${user_name}</b> on host ${host_name} at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-014": {
        description = `The user <b>${user_name}</b> performed an internal network traffic activity on  host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual time observed for this particular host. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-015": {
        description = `The user <b>${user_name}</b> performed an internal network traffic activity on  host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-016": {
        description = `The user <b>${user_name}</b> performed an external network traffic activity via internet  on  host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual time observed for this particular host. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-017": {
        description = `The user <b>${user_name}</b> performed an external network traffic activity via internet on  host <b>${host_name}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-023": {
        description = `An authentication failure event occurred for the user <b>${UserId}</b> on clientIP <b>${ClientIP}</b> for the time period <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> compared to the user's usual time. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-025": {
        description = `A  logon failure event was found for the user <b>${UserId}</b> on host <b>${ClientIP}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-026": {
        description = `An office 365 authentication success event occurred for the user <b>${UserId}</b> on clientIP <b>${ClientIP}</b> for the time period <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> compared to the user's usual time. Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-027": {
        description = `A file preview activity was found for the user <b>${UserId}</b> on host <b>${ClientIP}</b> at <b>${moment(detection_timestamp).utc().utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-028": {
        description = `The user <b>${user_name}</b> logged in from <b>${host_name}</b> from country <b>${country}</b> at <b>${moment.utc(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b>`;
        break;
      }

      case "UEBA-029": {
        description = `The user <b>${user_name}</b> logged from <b>${host_name}</b> has executed an uncommon process <b>${process_name}</b> at <b>${moment.utc(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b>`;
        break;
      }

      case "UEBA-030": {
        description = `A file named <b>${SourceFileName}</b> was downloaded by the user <b>${UserId}</b> from Ondrive on the client IP <b>${ClientIP}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is an abnormal activity based on user's usual behaviour.Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-031": {
        description = `A file downloaded from Onedrive was found for the user <b>${UserId}</b> on host <b>${ClientIP}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular user, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-032": {
        description = `A file named ${SourceFileName} was previewed by the user <b>${UserId}</b> on the client IP <b>${ClientIP}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is an abnormal activity based on user's usual behaviour.Hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-033": {
        description = `An email was sent from  <b>${email_from_address}</b> to <b>${email_receiver_address}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular email id, hence this was deemed as an anomaly.`;
        break;
      }

      case "UEBA-034": {
        description = `An email was sent from  <b>${email_from_address}</b> to <b>${email_receiver_address}</b> at <b>${moment(detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</b> which is outside the usual pattern observed for this particular email id, hence this was deemed as an anomaly.`;
        break;
      }

      default: {
        description = "No Anomaly Detections";
        break;
      }
    }

    return description;
  };
  const [expandedItems, setExpandedItems] = useState([]);
  const [isAllItemsExpanded, setIsAllItemsExpanded] = useState(false);

  const handleDetailsClick = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((i) => i !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const expandAllCards = () => {
    const allCardsIndexes = detectionSummaryDetails?.map((item, index) => index);
    setExpandedItems(allCardsIndexes);
    setIsAllItemsExpanded(true)
  };

  const closeAllCards = () => {
    setExpandedItems([]);
    setIsAllItemsExpanded(false)
  };

  const handleToggleChange = (e) => {
    e.target.checked === true ? expandAllCards() : closeAllCards()
  }
  // Add Case
  const handleCreate = () => {
    setCaseShow(true);
  };


  const optionsTest = {
    plugins: {
      title: {
        display: true,
        text: 'Data',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const dataTest = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels?.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(255, 99, 132)',
      },

    ],
  };

  let label_list = [{
    "ueba_id": "UEBA-001",
    "playbook_name": "Logon detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-002",
    "playbook_name": "Logon detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-003",
    "playbook_name": "Logon detected to unusual host for user"
  },
  {
    "ueba_id": "UEBA-004",
    "playbook_name": "File activities detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-005",
    "playbook_name": "Management activities detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-006",
    "playbook_name": "Unusually high number of file modification activities detected for user"
  },
  {
    "ueba_id": "UEBA-008",
    "playbook_name": "Unusually high number of management activities detected for user "
  },
  {
    "ueba_id": "UEBA-009",
    "playbook_name": "Unusually high number of file deletion activities detected for user "
  },
  {
    "ueba_id": "UEBA-010",
    "playbook_name": "Unusually high number of account lockout activity detected for domain"
  },
  {
    "ueba_id": "UEBA-011",
    "playbook_name": "Unusually high number of file activities detected for user "
  },
  {
    "ueba_id": "UEBA-012",
    "playbook_name": "Unusually high number of logon failures detected for host"
  },
  {
    "ueba_id": "UEBA-013",
    "playbook_name": "Unusually high number of logon failures detected for user "
  },
  {
    "ueba_id": "UEBA-014",
    "playbook_name": "Internal network  traffic detected outside of usual time for host "
  },
  {
    "ueba_id": "UEBA-015",
    "playbook_name": "Internal network  traffic detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-016",
    "playbook_name": "External network  traffic detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-017",
    "playbook_name": "External network  traffic detected outside of usual time for user"
  }];


  const [exceptionData, setExceptionData] = useState([])
  const userRef = useRef()
  const hostRef = useRef()

  const handleOpenModal = (display, data) => {
    console.log(data)
    setExceptionData(data)
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
    setCommentModalShow(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExceptionFormData({
      ...exceptionFormData,
      [name]: value,
    })

  }
  const location = useLocation();

  const FilterUpdate = (temp, type, date) => {
    let storedFilter = JSON.parse(localStorage.getItem("filters"));
    if (type === "ueba") {
      let temp2 = {
        ...storedFilter,
        ueba: {
          detection: {
            fields: [temp]
          },
        }
      };
      localStorage.setItem("filters", JSON.stringify(temp2));
      if (location.pathname === "/ueba/detection") {
        window.location.reload();
      }
    }
    else if (type === "cykit") {
      const from = moment.utc(date).startOf('day').toISOString()
      const to = moment.utc(date).endOf('day').toISOString()
      let temp2 = {
        ...storedFilter,
        ueba: {
          cykit: {
            fields: [temp],
            date: {
              from: from,
              to: to
            }
          }
        }
      };
      localStorage.setItem("filters", JSON.stringify(temp2));

    }

  }

  const getTenantId = () => {
    const token = localStorage.getItem("auth");
    if (token?.length > 0) {
      const tokenSplit = token.split(".");
      const payload = JSON.parse(atob(tokenSplit[1]));
      const fullgrp = payload['cognito:groups'][0]
      const tenantid = fullgrp.split("_rw")[0];
      return tenantid
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormValues = {
        ...exceptionFormData,
      }
      const data = {
        // created_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        created_by: localStorage.getItem("loggedUser"),
        ueba_id: detectionData?._source?.ueba_id,
        detection_name: detectionData?._source?.detection_name,
        // tenant_id: getTenantId(),
        tenant_id: licenceTenantId,
        comment: exceptionFormData.comment,
        user_name: userRef.current.value,
        host_name: hostRef.current.value,
        expiry_date: moment(exceptionFormData.expiry_date).format('DD-MM-YYYY')
      }
      const response = await fpostC(`${baseURL}/cybot_proxy/ueba_exceptions_${licenceTenantId}/_doc`, data);
      setExceptionFormData("")
      handleCloseModal(false)
      setAlertPopup(true)
    } catch (err) {
      console.log("Error", err)
    }



    // createExceptions(exceptionFormData).then((res) => {
    //   alert("sx")
    //   console.log("exception : : :  ", res)
    // }).catch((err) => {
    //   alert("Failed")
    // })
  }

  const handleSelectedTab = (key) => {
    // console.log("KEY", key)
    if (key === "overview") {
      setActiveTab("overview")

    }
    else if (key === "anomalies") {
      setActiveTab("anomalies")
    }
  }

  const [commentModalShow, setCommentModalShow] = useState(false)

  const handleOpenCommentModal = (display, status) => {
    setCommentModalShow(display);
  };

  function renderModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleSubmit}>
          {/* <div className="form-group">
            <label className="control-label">UEBA ID</label>

            <DropdownButton id="dropdown-basic-button" title="UEBA ID">
              {label_list?.map((item) => (
                <Dropdown.Item value={exceptionFormData.detection_name} onClick={(e) => handleInputChange(e)} >{item.playbook_name}</Dropdown.Item>
              ))
              }
            </DropdownButton>
          </div> */}

          <div className="row">
            <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Detection Name</label>
                <input type="text" className="form-control" id="username" placeholder="Enter username" name="user_name" value={exceptionData?.detection_name} onChange={handleInputChange} disabled />
              </div>
              <div className="d-flex">
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">User name</label>
                  <input type="text" className="form-control" id="username" placeholder="Enter username" name="user_name" ref={userRef} onChange={handleInputChange} defaultValue={exceptionData.user_name} />
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">Host name</label>
                  <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="host_name" ref={hostRef} onChange={handleInputChange} defaultValue={exceptionData.host_name} />
                </div>
              </div>
              <div className="d-flex">
                {/* <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">Expiry Date</label>
                  <input type="text" className="form-control" id="username" placeholder="DD MM YYYY" name="user_name" value={exceptionData.expiry_date} onChange={handleInputChange} defaultValue={exceptionData.expiry_date} />
                </div> */}
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">Expiry Date</label>
                  <input type="date" className="form-control" id="username" placeholder="DD MM YYYY" name="expiry_date" value={exceptionData.expiry_date} onChange={handleInputChange} required />
                  {/* <ReactInputMask
                    mask="99 99 9999"
                    maskChar=" "
                    placeholder="DD MM YYYY"
                    onChange={handleInputChange}
                  /> */}
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">UEBA ID</label>
                  <input type="text" class="form-control" id="ueba_id" placeholder="Enter Ueba ID" name="ueba_id" onChange={handleInputChange} defaultValue={exceptionData.ueba_id} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                <textarea className="form-control" id="comment" rows="3" name="comment" value={exceptionFormData.comment} onChange={handleInputChange} ></textarea>
              </div>
            </div>
          </div>


          <div className="row mt-3 py-3" style={styles.footerWrap}>
            {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
            <div className="form-group d-flex justify-content-end">
              {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
              <Button className="btn-primary" type="submit">
                Create
              </Button>
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">Create</button>
          <button type="button" className="btn btn-secondary">Close</button> */}
        </form>
      </div>
    )
  }

  // close or open 

  // comment close

  const handleCommentModal = (display, status) => {
    setCommentModalShow(display);
  };

  const handledetectionStatus = async (e) => {
    e.preventDefault();


    if (detectionOverview?._source?.detection_status === 'open') {
      try {
        const data = { "doc": { "comment": detectionClosed?.comment, "closing_tag": detectionClosed?.comment, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString, "detection_status": "closed" } }

        const response = await fpostC(`${baseURL}/cybot_proxy/${detectionOverview?._index}/_update/${detectionOverview?._id}`, data);
        setDetectionClosed('')
        setCommentModalShow(false)
        setCloseAlertPopup(true)
        setCloseAlertMessage('Detection Closed')
        // setClosedCheck(!closedCheck)

      } catch (err) {
        console.log("Error", err)
      }
    } else {
      // setClosureCategory([])
      try {
        const data = { "doc": { "comment": detectionOpen?.comment, "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }

        const response = await fpostC(`${baseURL}/cybot_proxy/${detectionOverview?._index}/_update/${detectionOverview?._id}`, data);
        setDetectionClosed('')
        setCommentModalShow(false)
        setCloseAlertPopup(true)
        setCloseAlertMessage('Detection Opened')
        // setClosedCheck(!closedCheck)
      } catch (err) {
        console.log("Error", err)
      }
    }

  }

  const handleCommentInputChange = (value) => {
    // console.log("e.target.value", e.target.value)
    setDetectionClosed({
      comment: value
    })
    setDetectionOpen({
      comment: value
    })
  }


  function renderCommentModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handledetectionStatus}>
          <div className="row">
            {/* <div>
                        <h6>Technique Name</h6>
                        <span>{state?.sourceList.technique_name}</span>
                    </div> */}
            {/* <div>
              <h6>Detection Name</h6>
              <span>{selectedDetectionDetails?._source?.detection_name}</span>
            </div> */}
            {/* {detectionStatusCheck === 'closed' &&
                        <div className="form-group mt-2">
                            <label className="control-label">Closure code</label>
                            <select
                                className="form-select input-lg"
                                name="closing_tag"
                                onChange={(e) => setClosureCategory(e.target.value)}
                                // onChange={(e) => changeSeverity(e, defSev)}
                                // value={severity}
                                required
                            >
                                <option value="">Select Category</option>
                                <option value="True Positive - Confirmed Suspicious Activity">True Positive - Confirmed Suspicious Activity</option>
                                <option value="Benign Positive - Validated">Benign Positive - Validated</option>
                                <option value="Benign Positive - Expected">Benign Positive - Expected</option>
                                <option value="False Positive - Data Error">False Positive - Data Error</option>
                                <option value="False Positive - Incorrect Alert Logic">False Positive - Incorrect Alert Logic</option>

                            </select>

                        </div>
                    } */}

            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                {detectionOverview?._source?.detection_status === 'closed' ?
                  <ReactQuill
                    value={detectionClosed.comment}
                    onChange={(value) => handleCommentInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed.comment} onChange={handleCommentInputChange} required ></textarea>
                  :
                  <ReactQuill
                    value={detectionOpen.comment}
                    onChange={(value) => handleCommentInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen.comment} onChange={handleCommentInputChange} required ></textarea>
                }
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xs-12 p-1 mb-5"></div>
          <div className="row mt-3 py-3" style={styles.footerWrap}>
            {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
            <div className="form-group d-flex justify-content-end">
              {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
              <Button className="btn-primary" type="submit">
                Submit
              </Button>
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">Create</button>
          <button type="button" className="btn btn-secondary">Close</button> */}
        </form>
      </div>
    )
  }

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <div
        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
        style={styles.filterWrap}
      >
        {/* <div className="multipleToggleButtonWrapp">
            <div className="col-4 active">All</div>
            <div className="col-4">Users</div>
            <div className="col-4">Hosts</div>
          </div> */}

        {
          activeTab === 'anomalies' && (
            <div className="position-relative">
              <Form>
                <Form.Check
                  className="mb-0"
                  type="switch"
                  id="toggle_detections_switch"
                  label={isAllItemsExpanded ? "Collapse all" : "Expand all"}
                  onChange={handleToggleChange}
                />
              </Form>
            </div>
          )
        }

        {detectionOverview?._source?.detection_status === "closed" ?
          <Button
            variant="light"
            onClick={() => handleCommentModal(true, 'open')}
          // disabled={detectionOverview?._source?.detection_status === "closed"}
          >
            Open Detection
          </Button> : detectionOverview?._source?.detection_status === "open" || detectionOverview?._source?.detection_status === "New" ?
            <Button
              variant="light"
              onClick={() => handleCommentModal(true, 'closed')}
            // disabled={detectionOverview?._source?.detection_status === "closed"}
            >
              Close Detection
            </Button> : null
        }


        <div className="header-actions-wrap">
          <div className="d-flex btn-wrap mx-2 gap-2">
            <button className="btn btn-light"
              onClick={(e) => handleCreate()}
            >Create Case
            </button>

            {showDownloadButton === true ? (
              <PDFDownloadLink document={<UebaDetectionExport detectionData={detectionData} anomaliesData={detectionSummaryDetails} imageBlob={imageBlob} detectionOverview={detectionOverview} />} fileName="ueba-detections-summary">
                {({ blob, url, loading, error }) => (
                  <Button className='btn btn-light'>
                    Download
                  </Button>
                )}
              </PDFDownloadLink>
            ) : (
              <PDFDownloadLink document={<UebaDetectionExport detectionData={detectionData} anomaliesData={detectionSummaryDetails} imageBlob={imageBlob} detectionOverview={detectionOverview} />} fileName="ueba-detections-summary">
                {/* {({ blob, url, loading, error }) => ( */}
                <Button className='btn btn-light' disabled >
                  {/* {console.log(loading)} */}
                  Download
                </Button>
                {/* // )} */}
              </PDFDownloadLink>
            )}
            <button className="btn btn-primary"
              onClick={() => handleOpenModal(true, detectionData?._source)}
            >Add Exceptions
            </button>
          </div>
        </div>



      </div>
      <div className="detections col-12">

        <Tabs
          defaultActiveKey="overview"
          id="ueba-summary"
          className="mb-3"
          activeKey={activeTab}
          onSelect={handleSelectedTab}
        >
          <Tab eventKey="overview" title="Overview">
            <div className="col-12 d-flex flex-row gap-3">
              {detectionOverviewLoader ? (
                // <PlainCardLoader width="200px" />
                <OverViewLoader />
              ) : (
                <div className="col-4 mb-3 p-3 border rounded-3" style={styles.contentWrap}>
                  <div className="modal-row-item">
                    <span className="modal-label col-3">
                      {detectionOverview?._source?.UserId ? 'User ID' : detectionOverview?._source?.email_receiver_address ? 'Receiver Address' : 'User'}
                    </span>

                    {detectionOverview?._source?.user_name && (
                      <span className="modal-text text-break">{detectionOverview?._source?.user_name}</span>
                    )}
                    {detectionOverview?._source?.UserId && (
                      <span className="modal-text text-break">{detectionOverview?._source?.UserId}</span>
                    )}

                    {detectionOverview?._source?.email_receiver_address && (
                      <span className="modal-text text-break">{detectionOverview?._source?.email_receiver_address}</span>
                    )}


                    <div className="d-flex flex-row gap-2 mx-3">
                      <Link className="viewMoreText" to="/ueba/detection" onClick={() => {
                        FilterUpdate(
                          {
                            column: { label: "User Name", value: "user_name" },
                            type: "is",
                            selectedValue: detectionOverview?._source?.user_name,
                            from: "",
                            to: "",
                            isinline: true

                          }, "ueba"
                        )
                      }}>All detections</Link>
                      <Link className="viewMoreText" to="/ueba/activities" onClick={() => {
                        FilterUpdate(
                          {
                            column: { label: "User Name", value: "user.name" },
                            type: "is",
                            selectedValue: detectionOverview?._source?.user_name,
                            from: "",
                            to: "",
                            isinline: true

                          }, "cykit",
                          detectionOverview?._source?.detection_timestamp
                        )
                      }}>All activities</Link>
                    </div>
                  </div>
                  <div className="modal-row-item">
                    <span className="modal-label col-3">
                      {detectionOverview?._source?.ClientIP ? 'Client IP' : detectionOverview?._source?.email_from_address ? 'sender Address' : 'Host'}
                    </span>
                    {detectionOverview?._source?.host_name && (
                      <span className="modal-text text-break">{detectionOverview?._source?.host_name}</span>
                    )}
                    {detectionOverview?._source?.ClientIP && (
                      <span className="modal-text text-break">{detectionOverview?._source?.ClientIP}</span>
                    )}
                    {detectionOverview?._source?.email_from_address && (
                      <span className="modal-text text-break">{detectionOverview?._source?.email_from_address}</span>
                    )}


                    <div className="d-flex flex-row gap-2 mx-3">
                      <Link className="viewMoreText" to="/ueba/detection" onClick={() => {
                        FilterUpdate(
                          {
                            column: { label: "Host", value: "host_name" },
                            type: "is",
                            selectedValue: detectionOverview?._source?.host_name,
                            from: "",
                            to: "",
                            isinline: true

                          }, "ueba"
                        )
                      }}> All detections
                      </Link>
                      <Link className="viewMoreText" to="/ueba/activities" onClick={() => {
                        FilterUpdate(
                          {
                            column: { label: "Device", value: "host.name" },
                            type: "is",
                            selectedValue: detectionOverview?._source?.host_name,
                            from: "",
                            to: "",
                            isinline: true

                          }, "cykit",
                          detectionOverview?._source?.detection_timestamp
                        )
                      }}>All activities</Link>
                    </div>
                  </div>

                  {/* {console.log("detectionData?._source", detectionData?._source)} */}

                  <div className="modal-row-item">
                    <span className="modal-label col-3">Detection Time</span>
                    <span className="modal-text text-danger">{moment(detectionOverview?._source?.detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</span>
                    {/* <span className="modal-text">{console.log("GET DETECCTT TIME :", detectionData?._source)}</span> */}
                  </div>

                  {/* detection id */}
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Detection ID</span>
                    <span className="modal-text">{detectionOverview?._source?.group_id}</span>
                  </div>

                  {/* detection Name */}
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Detection Name</span>
                    <span className="modal-text">{detectionOverview?._source?.detection_name}</span>
                  </div>

                  {/* description */}
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Description</span>
                    <span className="modal-text">{escapeHtmlChars(detectionOverview?._source?.description, /<\/?b>/gi)}</span>
                    {/* <span className="modal-text">{detectionData._source?.description}</span> */}
                  </div>

                  {/* detection status */}
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Detection Status</span>
                    <span>

                      {(detectionOverview?._source?.detection_status)?.toLowerCase() === "new" && (
                        <Badge bg="warning" className="p-2">Open</Badge>
                      )}
                      {(detectionOverview?._source?.detection_status)?.toLowerCase() === "closed" && (
                        <div className="position-relative">
                          <Badge bg="success" className="p-2">Closed</Badge>
                        </div>
                      )}
                      {(detectionOverview?._source?.detection_status)?.toLowerCase() === "open" && (
                        <Badge bg="warning" className="p-2">Open</Badge>
                      )}
                    </span>
                  </div>

                  {detectionOverview?._source?.comment &&
                    <div className="modal-row-item">
                      <span className="modal-label col-3">Comment</span>
                      {/* <span className="modal-text">{detectionOverview?._source?.comment}</span> */}
                      <span className="alert alert-warning px-2 py-0 mb-0  break-word" dangerouslySetInnerHTML={{ __html: detectionOverview?._source?.comment }} ></span>
                    </div>
                  }



                  {/* severity */}
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Severity</span>
                    <span>
                      {detectionOverview?._source?.severity == "Critical" && (
                        <Badge bg="danger" className="p-2">Critical</Badge>
                      )}
                      {detectionOverview?._source?.severity == "High" && (
                        <Badge bg="danger" className="p-2">High</Badge>
                      )}
                      {detectionOverview?._source?.severity == "Medium" && (
                        <Badge bg="warning" className="p-2">Medium</Badge>
                      )}
                      {detectionOverview?._source?.severity == "Low" && (
                        <Badge bg="success" className="p-2">Low</Badge>
                      )}
                    </span>
                  </div>

                  {/* closed at and closed by */}
                  {/* {
                    detectionOverview?._source?.closed_by && (
                      <div className="modal-row-item">
                        <span className="modal-label col-3">Closed By</span>
                        <span className="modal-text">{detectionOverview?._source?.closed_by}</span>
                      </div>
                    )
                  } */}

                  {(detectionOverview?._source?.detection_status)?.toLowerCase() === "closed" ? (
                    detectionOverview?._source?.closed_by && (
                      <div className="modal-row-item">
                        <span className="modal-label col-3">Closed By</span>
                        <span className="modal-text">{detectionOverview?._source?.closed_by}</span>
                      </div>
                    )
                  ) :
                    ((detectionOverview?._source?.detection_status)?.toLowerCase() === "open" && (
                      <div className="modal-row-item">
                        <span className="modal-label col-3">Opened By</span>
                        <span className="modal-text">{detectionOverview?._source?.opened_by}</span>
                      </div>
                    ))
                  }


                  {detectionOverview?._source?.closed_at && (
                    <div className="modal-row-item">
                      <span className="modal-label col-3">Closed At</span>
                      <span className="modal-text">{timestampConvert(detectionOverview?._source?.closed_at, 'MMMM Do YYYY, h:mm:ss a')}</span>
                    </div>
                  )}


                  {detectionData?._source?.description?.includes("file") &&
                    <div className="modal-row-item mt-3">
                      {/* <span className="modal-label col-3"></span> */}
                      <div className="d-flex flex-column flex-fill">

                        <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                          <div className="flex-fill rounded-3 p-3 alert alert-success" style={styles.overviewTile}>
                            <div>
                              <h5>{detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "creation"
                              ).length ? detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "creation"
                              ).length : "--"}</h5>
                              <span>Creation</span>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3 alert alert-danger" style={styles.overviewTile}>
                            <div>
                              <h5>{detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "deletion"
                              ).length ? detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "deletion"
                              ).length : "--"}</h5>
                              <span>Deletion</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                          <div className="flex-fill rounded-3 p-3 alert alert-primary" style={styles.overviewTile}>
                            <div>
                              <h5>{detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "modification"
                              ).length ? detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "modification"
                              ).length : "--"}</h5>
                              <span>Modification</span>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3 alert alert-warning" style={styles.overviewTile}>
                            <div>
                              <h5>{detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "rename"
                              ).length ? detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "rename"
                              ).length : "--"}</h5>
                              <span>Rename</span>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3 alert alert-info" style={styles.overviewTile}>
                            <div>
                              <h5>{detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "overwrite"
                              ).length ? detectionSummaryDetails.filter(
                                item => item?.details?.event_action === "overwrite"
                              ).length : "--"}</h5>
                              <span>Overwrite</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* <div className="event-actions d-flex gap-2 justify-content-end">
<div className="creationn">
  <div className="alert alert-success rounded-circle tile-icon">
    {detectionSummaryDetails.filter(
      item => item?.details?.event_action === "creation"
    ).length}
  </div>
</div>

<div className="deletionn">
  <div className="alert alert-danger rounded-circle tile-icon">
    {detectionSummaryDetails.filter(
      item => item?.details?.event_action === "deletion"
    ).length}
  </div>
</div>

<div className="creationn">
  <div className="alert alert-warning rounded-circle tile-icon">
    {detectionSummaryDetails.filter(
      item => item?.details?.event_action === "rename"
    ).length}
  </div>
</div>

<div className="creationn">
  <div className="alert alert-info rounded-circle tile-icon">
    {detectionSummaryDetails.filter(
      item => item?.details?.event_action === "overwrite"
    ).length}
  </div>
</div>
</div> */}
                </div>
              )}


              <div className="flex-fill" style={styles.contentWrap}>
                <div className="card p-3 rounded-3">
                  {detectionData?._source?.ueba_id === "UEBA-028" ? (
                    <>
                      {
                        countryLoader ? (<PlainCardLoader width="200px" />) : (
                          <div ref={geoRefImage}>
                            {countryLabel?.length || countryData?.length !== 0 ? (
                              // <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} ChartTitle={"Source Country"} />
                              <span className="modal-text">
                                {/* {console.log(detectionData._source?.ueba_id === "UEBA-028" && detectionData._source.country)} */}
                                <GeoChart
                                  chartEvents={[
                                    {
                                      eventName: "select",
                                      callback: ({ chartWrapper }) => {
                                        const chart = chartWrapper.getChart();
                                        const selection = chart.getSelection();
                                        if (selection.length === 0) return;
                                        const region = countryChart[selection[0].row + 1];
                                        // MapClick("cybSourceCountry", "Source Country", region)
                                      },
                                    },
                                  ]}
                                  chartType="GeoChart"
                                  width="100%"
                                  height="300px"
                                  data={countryChart}

                                />
                              </span>
                            ) : (
                              <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )
                            }
                          </div>
                        )}
                    </>
                  ) : detectionData?._source?.ueba_id === "UEBA-029" ? (
                    <div className="row">
                      <h5>{`Variation of process(${detectionData?._source?.process_name}) count for organization`}</h5>
                      <div className="col-md-12 d-flex justify-content-center">
                        <div className="col-md-6">
                          {processCountLoader ? (<PlainCardLoader />) : (
                            <>
                              {labelsData.length > 0 || dataChart.length > 0 ? (
                                // <Doughnut data={processOne} />
                                <div ref={divRefImage}>
                                  <Radar data={RadarData} />
                                </div>
                              ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                  <NoDataFound errorText={"No data found"} />
                                </div>
                              )}
                            </>
                          )}

                        </div>
                        {/* <div className="col-md-6">
                      <h5>Process distribution in a week for organization</h5>
                      {labelsTwoData.length > 0 || dataTwoChart.length > 0 ? (
                        <Doughnut data={processTwo} />
                      ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                          <NoDataFound errorText={"No data found"} />
                        </div>
                      )}
                    </div> */}
                      </div>
                      {/* <div className="col-md-12 d-flex">
                    <div className="col-md-6">
                      <h5>{`Visualisation of Users executing process(${detectionData._source?.process_name})`}</h5>
                      {labelsThreeData.length > 0 || dataThreeChart.length > 0 ? (
                        <Doughnut data={processThree} />
                      ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                          <NoDataFound errorText={"No data found"} />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <h5>{`Process(${detectionData._source?.process_name}) counts distribution of user(${detectionData._source?.user_name})`}</h5>
                      {labelsFourData.length > 0 || dataFourChart.length > 0 ? (
                        <Doughnut data={processFour} />
                      ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                          <NoDataFound errorText={"No data found"} />
                        </div>
                      )}
                    </div>
                  </div> */}
                    </div>
                  ) : (
                    <div className="card p-3 rounded-3" ref={barRefImage}>
                      {barGraphLoader ? (
                        <PlainCardLoader width="200px" />
                      ) : (
                        <>
                          {detectionData?._source?.ueba_id === "UEBA-003" && detectionData?._source?.type == "count based" ?
                            <div ref={barRefImage}>
                              <Bar options={UEBA_003Options} data={UEBA_003Data} />
                            </div>
                            :
                            <div ref={barRefImage}>
                              {detectionData?._source?.type == "count based" ?
                                <Bar options={options} data={data} />
                                :
                                <Bar options={timeGraphOptions} data={timeGraphData} />
                              }
                            </div>
                          }
                        </>
                      )}

                    </div>
                  )}
                </div>

                {detection?.length > 0 && !detectionData?._source?.ueba_id === "UEBA-029" &&
                  <div className="card p-3 rounded-3 mt-3">
                    <Table className="table table-borderless table-hover">
                      <thead className="fixed-head">
                        <tr>
                          <th>Date</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detection?.map((item, index) => (
                          <tr>
                            <td className="font-monospace">{moment(item?.x).format("DD-MM-YYYY")}</td>
                            <td className="font-monospace">{item?.y}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                }
              </div>



              {/* <div className="card p-3 rounded-3">
              {detectionData._source?.ueba_id === "UEBA-003" && detectionData._source?.type == "count based" ?
                <Bar options={UEBA_003Options} data={UEBA_003Data} /> :
                <>
                  {detectionData._source?.type == "count based" ?
                    <Bar options={options} data={data} /> :
                    <Bar options={timeGraphOptions} data={timeGraphData} />
                  }
                </>
              }
            </div> */}

            </div>
          </Tab>

          <Tab eventKey="anomalies" title="Anomalies Detected">
            <div className="col-12" style={styles.contentWrap}>
              {/* <div className="border-bottom sticky-toolbar">
                <Form>
                  <Form.Check
                    type="switch"
                    id="toggle_detections_switch"
                    label={isAllItemsExpanded ? "Collapse all" : "Expand all"}
                    onChange={handleToggleChange}
                  />
                </Form>
              </div> */}
              <div className="mt-4">
                {/* <h5>Anomalies detected</h5> */}
                {cardLoading ? (
                  <CardLoader />
                ) : (
                  <div className="col-12 d-flex flex-column gap-3">
                    {detectionSummaryDetails.length !== 0 ? (

                      <>
                        {detectionSummaryDetails?.map((item, index) => (
                          <div className="card p-3 flex-row gap-3">
                            <div>
                              {item?.details?.event_action === "creation" ? (
                                // <div style={{ padding: "0.2rem 0.1rem", width: "fit-content", color: "#19a81b" }}>
                                //   <TableViewMore Icon={FilePlus} size={28} />
                                // </div>
                                <div className="alert alert-success rounded-circle tile-icon">
                                  <FilePlus size={20} />
                                </div>

                              ) : item?.details.event_action === "deletion" ? (
                                <div className="alert alert-danger rounded-circle tile-icon">
                                  <FileX size={20} />
                                </div>
                              ) : item?.details.event_action === "rename" ? (
                                <div className="alert alert-warning rounded-circle tile-icon">
                                  <FileText size={20} />
                                </div>
                              ) : item?.details.event_action === "overwrite" ? (
                                <div className="alert alert-info rounded-circle tile-icon">
                                  <Files size={20} />
                                </div>
                              ) : item?.details.event_action === "logged-in" ? (
                                <div className="alert alert-success rounded-circle tile-icon">
                                  <SignIn size={20} />
                                </div>
                              ) : item?.details.event_action === "logged-out" ? (
                                <div className="alert alert-error rounded-circle tile-icon">
                                  <SignOut size={20} />
                                </div>
                              ) :
                                <div className="alert alert-secondary rounded-circle tile-icon">
                                  <Files size={20} />
                                </div>}
                            </div>
                            <div className="d-flex flex-fill flex-row">
                              <div>
                                {/* <span style={{ fontWeight: 500 }}>{getDateFromTimestamp(item.detection_timestamp) + " at " + getTimeFromTimestamp(item.detection_timestamp)}</span> */}
                                <span style={{ fontWeight: 500 }}>{`${moment.utc(item.detection_timestamp).format('YYYY-MM-DD HH:mm:ss')}`}</span>
                                <p>{item.descriptionSummary}</p>
                                {item?.details?.event_category === "network" && (
                                  <>
                                    <p>Event : <b>{item?.details?.event_action}</b> from src - <b>{item?.details?.source_ip}</b> : <b>{item?.details?.source_port}</b> to dest - <b>{item?.details?.dest_ip}</b> : <b>{item?.details?.dest_port}</b></p>
                                  </>
                                )}

                                {item?.details?.event_category === "file" && (
                                  <>
                                    <p>Full file path : <b>{item.details.file_path}</b> </p>
                                  </>
                                )}

                                {/* anomalies detection List */}
                                {expandedItems.includes(index) && (
                                  <div className="p-3 bg-light card mt-2">
                                    {anomaliesDataList?.map((ListItem) => (
                                      ListItem.key in item.details && (
                                        <>
                                          <div className="modal-row-item" key={ListItem.key}>
                                            <span className="modal-label col-3">{ListItem?.label}</span>
                                            {/* <span className="modal-text" style={{ width: "450px" }}>{ListItem?.label === 'Detection date' ? getDateFromTimestamp(item?.details[ListItem?.key]) : ListItem?.label === 'Detection time' ? getTimeFromTimestamp(item?.details[ListItem?.key]) : item?.details[ListItem?.key]}</span> */}
                                            <span className="modal-text" style={{ width: "450px" }}>
                                              {ListItem?.label === 'Detection date' ? getDateFromTimestamp(item?.details[ListItem?.key]) :
                                                ListItem?.label === 'Detection time' ? getTimeFromTimestamp(moment(item?.details[ListItem?.key]).utc().format('YYYY-MM-DD HH:mm:ss')) :
                                                  item?.details[ListItem?.key]
                                              }
                                            </span>
                                          </div>
                                        </>
                                      )
                                    ))}
                                    {/* datalake */}

                                    {item?.details?.datalake?._id &&
                                      <div className="modal-row-item" key="datalake">
                                        <span className="modal-label col-3">Datalake ID</span>
                                        <span className="modal-text" style={{ width: "450px" }}>{item?.details?.datalake?._id}</span>
                                      </div>
                                    }
                                  </div>
                                )}
                              </div>


                            </div>
                            <Link onClick={() => rawLog(item)}>
                              <FileSearch size={23} />
                            </Link>

                            <Link onClick={() => handleDetailsClick(index)}>
                              {/* <TableViewMore Icon={Info} /> */}
                              <TableViewMore Icon={!expandedItems.includes(index) > 0 ? CaretDown : CaretUp} size={24} dataPlacement={"bottom"} />
                            </Link>
                          </div>
                        ))}
                      </>
                    ) : (
                      <NoDataFound errorText={"No data found"} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Tab>

        </Tabs>

      </div >

      {
        show && (
          <CustomModal
            title={"Add exception"}
            ShowOrHide={show}
            bodyData={renderModal}
            handleClose={handleCloseModal}
            footer={false}
            modalSize={"md"}
            downloadBtn={false}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
            minimizeBtn={true}
          ></CustomModal>
        )
      }
      {
        commentModalShow && (
          <CustomModal
            title={detectionOverview?._source?.detection_status === 'closed' ? "Open Detection" : detectionOverview?._source?.detection_status === 'New' ? "Close Detection" : "Close Detection"}
            ShowOrHide={commentModalShow}
            bodyData={renderCommentModal}
            handleClose={handleCloseModal}
            modalSize={"md"}
            footer={false}
            downloadBtn={false}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }

      {
        caseshow && (
          <EditModal
            setShow={setCaseShow}
            show={caseshow}
            autoHeight={false}
            caseidEdit={case_id}
            setCase_ID={setCase_ID}
            id={detectionData?._source?.detection_name}
            idd={detectionData?._source?.group_id}
            module={module}
            tab={tab}
            preModule={"UEBA"}
            description_={removeHtmlChars(detectionData._source.description)}
            severity_={detectionData?._source?.severity}


          />
        )
      }
      {/* {
        alertPopup && (
          <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={"Exception Added"} bgColor={"lightGreen"} />
        )
      } */}
      {
        alertPopup && (
          <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={"Exception Added"} bgColor={"lightGreen"} />
        )
      }

      {alertCasePopup && (
        <AlertBox setAlertPopup={setAlertCasePopup} alertPopup={alertCasePopup} message={"Case created successfully"} bgColor={"lightGreen"} />
      )}

      {
        closeAlertPopup && (
          <AlertBox setAlertPopup={setCloseAlertPopup} alertPopup={closeAlertPopup} message={closeAlertMessage} bgColor={"lightGreen"} />
        )
      }
      <ExpandedDocument title={"Raw Log"} clickedNode={expclickedNodeLog} close={expcloseLog} setClick={setExpClickedNodeLog} url={localStorage.getItem("DATALAKE_URL")} pattern={expClickedIndex !== undefined ? `${expClickedIndex}/_doc/${expclickedNodeLog}` : 'logs-*,winlogbeat*/_search?track_total_hits=true'} req={expClickedIndex !== undefined ? fgetCy : fpostCy} />

    </div >
  );
};

export default UebaSummary;
