import React, { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import CustomModal from "../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Button, Form } from "react-bootstrap";

import {
  getExpectedOutputCustomUeba,
  customUeba,
  getUebaCatalogueDetails,
  getUebaCatalogueDetailsQuery,
  getExpectedOutputCustomInv,

} from "../../../Methods/RuleStudioMethods";
import { dateDMY, removeHtmlChars } from "../../../Utils/Utils";

import CustomUebaInformation from "./CustomUebaInformation";
import Loader from "../../Loader/Loader";
// import Modules from "../Investigation/Custom/Modules";
// import CountBasedInputs from "../Investigation/Custom/CountBasedInputs";



const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


export default function
  CustomUeba({
    setCustomShow,
    customShow,
    setUebaId,
    uebaIdEdit,
    setSubmitLoader,
    submitLoader
  }) {
  const dispatch = useDispatch();

  const [masterId, setMasterId] = useState("UEBA-");
  const [uebaName, setUebaName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [scheduleValue, setScheduleValue] = useState("");
  const [description, setDescription] = useState("");
  const [query, setQuery] = useState();
  const [type, setType] = useState(['']);
  const [severity, setSeverity] = useState('');
  const [subCategory, setSubCategory] = useState("");
  const [threshold, setThreshold] = useState("");
  const [groupBy, setGroupBy] = useState("");

  const [inv_created, setInvCreated] = useState(dateDMY(new Date()))
  const [inv_last_edited, setInvLastEdited] = useState(dateDMY(new Date()))

  const [isHunter, setIsHunter] = useState("")
  const [isCorrelation, setIsCorrelation] = useState("")

  const [inputValue, setInputValue] = useState('');
  const [isValidJson, setIsValidJson] = useState(true);

  const [index, setIndex] = useState('');


  const [selectedLogCategory, setLogCategory] = useState([]);
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);

  const [modules, setModules] = useState([]);

  // const listMasterCustomRsUeba = useSelector((state) => state.listMasterCustomRsUeba);
  // const { output } = listMasterCustomRsUeba;

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;

  const detailsCustomUebaRsUeba = useSelector((state) => state.detailsCustomUebaRsUeba);
  const { catalogue, loading: loading } = detailsCustomUebaRsUeba;

  const detailsCustomUebaQueryRsUeba = useSelector((state) => state.detailsCustomUebaQueryRsUeba);
  const { catalogueQuery } = detailsCustomUebaQueryRsUeba;

  const createUebaRsUeba = useSelector((state) => state.createUebaRsUeba);
  const { success, error } = createUebaRsUeba;


  const setDataInEditList = (severity, schedule) => {


    const logData = catalogue?.log?.map((data) => {
      return { "value": data, 'label': data }
    })

    let expOutData = catalogueQuery?.data?.extract_values_from_query_output?.map((data) => {
      return { "value": data, 'label': data }
    })


    const queryData = catalogue?.query
    delete queryData?.extract_values_from_query_output

    console.log(parseInt(catalogue?.schedule))
    let scheduleTime = parseInt(catalogue?.schedule)
    let schedule_ = catalogue?.schedule?.replace(scheduleTime, '')


    setMasterId(uebaIdEdit === '' ? '' : catalogue?.UEBA_id);

    setUebaName(uebaIdEdit === '' ? '' : catalogue?.UEBA_name);

    setSchedule(uebaIdEdit === '' ? '' : schedule_);
    setScheduleValue(uebaIdEdit === '' ? '' : scheduleTime);

    setSeverity(uebaIdEdit === '' ? '' : catalogue?.severity)
    setDescription(uebaIdEdit === '' ? '' : catalogue?.Description);

    setLogCategory(uebaIdEdit === '' ? '' : logData)
    setQuery(uebaIdEdit === '' ? '' : JSON.stringify(queryData))
    setType(uebaIdEdit === '' ? [] : catalogue?.type)
    setSubCategory(uebaIdEdit === '' || catalogue?.sub_category);
    setThreshold(uebaIdEdit === '' ? '' : catalogue?.threshold);
    setGroupBy(uebaIdEdit === '' ? '' : catalogue?.group_by);
    setInvCreated(uebaIdEdit === '' ? '' : catalogue?.inv_created);
    setInvLastEdited(uebaIdEdit === '' ? '' : dateDMY(new Date()));
    setIndex(uebaIdEdit === '' ? '' : catalogue?.index);


  };

  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());


    if (
      (uebaIdEdit || Object.keys(catalogue).length === 0) &&
      uebaIdEdit != catalogue?.UEBA_id && uebaIdEdit != ''
    ) {
      dispatch(getUebaCatalogueDetails(uebaIdEdit));

    }

    if (uebaIdEdit != '') {
      setDataInEditList(severity, schedule);
    } else {
      setMasterId("UEBA-");
      setUebaName("");
      setSchedule("");
      setType("");
      setDescription("");
      setSubCategory("");
      setThreshold("");
      setQuery();
      setGroupBy([]);
      setSeverity('');
      setLogCategory('');
      setIndex("");

    }

  }, [uebaIdEdit, catalogue, customShow, catalogueQuery]);





  const expectedOutput = output?.expected_values?.map((output) => {
    return { value: output.value, label: output.text };
  });

  const logCategoryList = output?.log_category?.map((output) => {
    return { value: output.value, label: output.text };
  });


  const handleMultiLog = (selectedLogCategory) => {
    setLogCategory(selectedLogCategory);

  };

  const handleMultiChangeOutPut = (selectedExpectedOutput) => {
    setExpectedOutput(selectedExpectedOutput);


  };




  
  
   

  const onSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to save changes?")) {
      setSubmitLoader(true)

      let extract_values_from_query_output = selectedExpectedOutput.map((data) => {
        return data.value

      })



      let date = dateDMY(new Date())

      let dataDes = {
        Description: description,
        UEBA_id: masterId,
        UEBA_name: uebaName,
        log: selectedLogCategory.map((data) => data.value),
        schedule: scheduleValue + schedule,
        type,
        severity,
        group_by: groupBy,
        threshold,
        sub_category: subCategory,
        inv_created: inv_created,
        inv_last_edited: inv_last_edited,
        // query: query.replace(/(\r\n|\n|\r|\\n|\\)/g, '').replace(/"/g, "'"),
        query: query,
        index
      };






      dispatch(customUeba(dataDes, masterId))

    }

  }




  // const handleNotify = (value) => {
  //   const newValues = value.split(','); // Assuming the values are comma-separated
  //   setNotify(newValues);
  // }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: selectedLogCategory?.length === 0 ? 'red' : "#198754", // Set the border color here
      // Additional styles
    }),
  };

  // const handleFirstSection = (val) => {
  //   setIsHunter(val)
  // }

  const handleIndex = (val) => {
    setIndex(val)
  }

  const handleQuery = (val) => {

    setQuery(val.replace(/\s/g, "").replace(/"/g, "'"))
    setInputValue(val);
  
      // try {
      //   JSON.parse(val);
      //   setIsValidJson(true);
      // } catch (error) {
      //   setIsValidJson(false);
      // }
  }


  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
        <div className="px-3">
          <span className="was-validated">
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">UEBA ID <span className="text-danger">*</span></label>
                    <input type="text" className={uebaIdEdit.length === 0 ? `form-control is-invalid` : 'form-control  '} id="validationTextarea" name="masterId"
                      value={masterId}
                      placeholder="UEBA ID" required readOnly={uebaIdEdit ? true : false} onChange={(e) => setMasterId(e.target.value)} />

                  </div>
                </div> <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="" className="form-label">UEBA name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea"
                      placeholder="Ueba name" required name="uebaName"
                      value={uebaName}
                      onChange={(e) => setUebaName(e.target.value)} />

                  </div>
                </div></div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-4 col-xs-12 p-3">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Log category <span className="text-danger">*</span></label>
                    <Select
                      isMulti
                      options={logCategoryList}
                      value={selectedLogCategory}
                      onChange={handleMultiLog}
                      styles={customStyles}
                    />

                  </div>
                </div> <div className="col-md-4 col-xs-12 p-3">
                  <div className="row mb-3" style={{ border: '1px solid rgba(0, 0, 0, 0.05)', }}>
                    <div className="col-md-6 col-xs-12  "> <label for="validationTextarea" className="form-label">Schedule<span className="text-danger">*</span></label>

                      <select className="form-select" required aria-label="select example" value={schedule}
                        name="schedule"
                        onChange={(e) => setSchedule(e.target.value)}>
                        <option value="">Select </option>
                        <option value="m">Minutes</option>
                        <option value="h">Hour</option>
                        <option value="d">Day</option>
                      </select> </div>
                    <div className="col-md-6 col-xs-12  pt-1">
                      <label className="control-label">&nbsp;</label>
                      <input
                        type="number"
                        className="form-control input-lg form-control-style"
                        name="scheduleValue"
                        value={scheduleValue}
                        onChange={(e) => setScheduleValue(e.target.value)}
                        placeholder=" Value "
                      /></div>
                  </div>




                  {/* 
                       
                      {/* <select className="form-select" required aria-label="select example" value={schedule}
                        name="schedule"
                        onChange={(e) => setSchedule(e.target.value)}>
                        <option value="">Select Schedule</option>
                        {output?.schedule?.map((data, index) => (
                          <option value={data.value} key={index}>{data.text}</option>
                        ))}

                      </select> */}



                </div>
                <div className="col-md-4 col-xs-12 p-3">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Severity<span className="text-danger">*</span></label>
                    <select className="form-select" required aria-label="select example" value={severity}
                      name="severity"
                      onChange={(e) => setSeverity(e.target.value)}>
                      <option value="">Select Severity </option>
                      {output?.severity?.map((data, index) => (
                        <option value={data.value} key={index}>{data.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div></div>



            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>


                {/* <div className="col-md-4 col-xs-12 p-1">



                  <div className="mb-3 mt-4">

                    <div className="d-flex">
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="hunter" onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Hunter
                      </div>
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="ueba" onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        UEBA
                      </div>

                    </div>



                  </div>

                </div>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3 mt-4">

                    <div className="d-flex">
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="second_section" value='correlation' onChange={(e) => handleSecondSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Correlation
                      </div>
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="second_section" value='count_based' onChange={(e) => handleSecondSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Count Based
                      </div>

                    </div>


                  </div>
                </div> */}

              </div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Type</label>
                  {/* <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    placeholder="Type "
                  /> */}
                  <select className="form-select" required aria-label="select example" value={type}
                    name="type"
                    onChange={(e) => setType(e.target.value)}>
                    <option value="">Select Type </option>
                    {output?.ueba_type?.map((data, index) => (
                      <option value={data} key={index}>{data}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Threshold</label>
                  <input
                    type="number"
                    className="form-control input-lg form-control-style"
                    name="threshold"
                    value={type === "Behaviour Based" ? 0 : threshold}
                    disabled={type === "Behaviour Based"}
                    onChange={(e) => setThreshold(e.target.value)}
                    placeholder="Threshold"
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Sub Category</label>
                  {/* <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="subCategory"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    placeholder="Sub Category"
                  /> */}
                  <select className="form-select" required aria-label="select example" value={subCategory}
                    name="subCategory"
                    onChange={(e) => setSubCategory(e.target.value)}>
                    <option value="">Select </option>
                    {output?.['ueba_sub-category']?.map((data, index) => (
                      <option value={data} key={index}>{data}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Group By</label>
                  <select className="form-select" name="groupBy"
                    value={groupBy}
                    onChange={(e) => setGroupBy(e.target.value)}>
                    <option value="">Select </option>
                    {output?.group_by?.map((data, index) => (
                      <option value={data} key={index}>{data}</option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="groupBy"
                    value={groupBy}
                    onChange={(e) => setGroupBy(e.target.value)}
                    placeholder="GroupBy"
                  /> */}
                </div>
              </div>
            </div>





            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Description *</label>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </div>
              </div>
            </div>


            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Query *</label>
                  {/* <ReactQuill
                    theme="snow"
                    value={query}
                    name="query"
                    onChange={setQuery}
                  /> */}
                  <textarea value={query} onChange={(e) => handleQuery(e.target.value)} rows={7} className="form-control"></textarea>
                </div>
              </div>
            </div>
            {/* {isCorrelation === 'correlation' &&
              <div className="d-flex">

                <div className="ml-auto p-2 viewMoreText">+ Add more</div>
              </div>
            }

            {isCorrelation === 'count_based' &&
              countBasedDiv
            } */}
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Index *</label>
                
                  <textarea value={index} onChange={(e) => handleIndex(e.target.value)} rows={2} className="form-control"></textarea>
                </div>
              </div>
            </div>

            
            {/* <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Expected output *</label>
                  <Select
                    isMulti
                    options={expectedOutput}
                    value={selectedExpectedOutput}
                    onChange={handleMultiChangeOutPut}
                  />
                </div>
              </div>
            </div>
            <label className="control-label mt-3">Modules *</label>
            {output?.module?.map((data, index) => (
              <Modules
                data={data}
                expectedOutput={expectedOutput}
                setModules={setModules}
                modules={modules}
                key={index}

              />
            ))} */}

 
            <div className="row mt-3 py-3" style={styles.footerWrap}>
              <div className="form-group d-flex justify-content-end">
              {/* {!isValidJson && <p style={{ color: 'red' }}>Invalid JSON</p>} */}
              </div>
              <div className="form-group d-flex justify-content-end">
             
                <Button className="btn-secondary" onClick={() => handleClose()}>Cancel</Button> &nbsp;
                <button type="submit" className="btn btn-primary" disabled={submitLoader === true ? true : false} >
                  {submitLoader === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                    <span className="sr-only"> </span>
                  </div></> : 'Save'}
                </button>
              </div>
            </div >
          </span>
        </div >

      )
      }
    </Form >
  );

  const handleClose = () => {


    if (window.confirm("Do you want to close?") === true) {
      setCustomShow(false);
      setUebaId("");

    }
  };




  return (
    <>
      {" "}
      {customShow === true && (
        <CustomModal
          title={uebaIdEdit != '' ? 'Edit UEBA' : `Custom UEBA`}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          noBodyPadding={true}
          modalSize={'lg'}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}

        />
      )}
    </>
  );
}
