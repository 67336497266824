import React, { useEffect } from "react";
import styles from "../Activebytes.styles";
import { Link } from "react-router-dom";
import LoaderList from "../../../../components/Loader/LoaderList";
import { Badge } from "react-bootstrap";
import { convertTimestamp } from "../../../../Methods/TiMethods";
import { Calendar } from "phosphor-react";
import NoDataFound from "../../../../components/NoDataFount/NoDataFound";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableViewMore from "../.././../../components/ViewMoreBtn/TableViewMore";


export default function SecurityEvents({
  securityEvents,
  handleOpenModal,
  secEventsLoading,
  setActiveTab,
  tabToggle,
  timeoutLoader,
  secData,
  secTotalCount

}) {





  return (
    <div className="col-12" style={styles.section1}>
      {secTotalCount === true || timeoutLoader === true ? (
        <LoaderList />
      ) : (
        secData?.length === 0 ? <NoDataFound /> :
          secData?.map(({ _source }) => (
            <div
              className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card ti-card"
              style={styles.tile}
            // onClick={() => handleOpenModal(_source, true, "securityEvents")}
            >
              <h6>{_source.event_name}</h6>
              <div>{_source.event_description}</div>


              <div className="d-flex flex-row gap-3 mt-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span>Severity</span>
                  {_source.severity == "Critical" && (
                    // <div className="p-2 m-0 alert alert-danger">Critical</div>
                    <Badge bg="danger" className="p-2">Critical</Badge>
                  )}
                  {_source.severity == "High" && (
                    // <div className="p-2 m-0 alert alert-danger">High</div>
                    <Badge bg="danger" className="p-2">High</Badge>
                  )}
                  {_source.severity == "Medium" && (
                    // <div className="p-2 m-0 alert alert-warning">Medium</div>
                    <Badge bg="warning" className="p-2">Medium</Badge>
                  )}
                  {_source.severity == "Low" && (
                    // <div className="p-2 m-0 alert alert-success">Low</div>
                    <Badge bg="success" className="p-2">Low</Badge>
                  )}
                </div>
                {/* {_source.severity}</div> */}
                <div className="d-flex flex-row align-items-center gap-2">
                  <span>TLP</span>
                  {/* <div className="p-2 my-0 mx-2 alert alert-secondary">{_source.tlp}</div> */}
                  <Badge bg="secondary" className="p-2">{_source.tlp}</Badge>
                </div>

                <div className="d-flex flex-row gap-2 align-items-center">
                  <Calendar size={18} />
                  <h6 className="m-0">{convertTimestamp(_source.attribute_timestamp)}</h6>
                </div>
                <div className="ml-auto p-2">
                  <Link onClick={() => handleOpenModal(_source, true, "securityEvents")}>
                    <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                  </Link>
                  {/* <div>{_source.severity}</div> */}
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  );
}