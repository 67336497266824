import React from 'react'
import Skeleton from 'react-loading-skeleton'

const HunterLoader = () => {
    return (
        <div className="loader-container col-12 d-flex flex-row gap-3">
            <div className="left col-3">
                <Skeleton className='rounded-3' style={{ height: "calc(100vh - 8.5rem)" }} />
            </div>
            <div className="left col-4 flex-fill">
                <Skeleton className='rounded-3' style={{ height: "calc(100vh - 8.5rem)" }} />
            </div>
            <div className="left col-5">
                <Skeleton className='rounded-3' style={{ height: "calc(100vh - 8.5rem)" }} />
            </div>
        </div>
    )
}

export default HunterLoader