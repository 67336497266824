import React, { useEffect, useState } from 'react'

import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../../Utils/Utils";
import { Chart } from "react-google-charts";
import Header from '../Header';

const CustomText = ({ children }) => (
    <Text
        renderLine={(text, styleProps) => (
            <Text key={`${text}-${styleProps.lineIndex}`} {...styleProps}>
                {text}
                {'\n'} {/* Add line break here */}
            </Text>
        )}
    >
        {children}
    </Text>
);


export default function DetailCommunity({ detail, tableData, imageBlob }) {

    const [imageSrc, setImageSrc] = useState(null);




    // Define the styles for the table
    const styles = {
        page: { padding: "20px" },
        container: { flexDirection: "row", marginTop: "5px" },
        column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
        column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
        comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
        link: { padding: "2px", marginTop: "3px", },
        table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
        tableRow: { margin: 'auto', flexDirection: 'row' },
        tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
        tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
        tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
        tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
        imageDiv: { justifyContent: 'center', },
        image: {
            width: '80%', // Adjust the width as needed
            height: 'auto', // Maintain aspect ratio
            justifyContent: 'center',
            marginLeft: "10%",
            marginTop: "10px"
        },
    };


    useEffect(() => {
        if (imageBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(imageBlob);
        }
    }, [imageBlob]);






    return (
        <Document>
            <Page style={styles.page}>
                <Header data={detail} />

                <View style={styles.container}>


                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Event name</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.event_name}</Text>
                    </View>
                </View>

                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Severity</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        {detail?.severity?.toLowerCase() == "critical" && (
                            <Text>Critical</Text>
                        )}
                        {detail?.severity?.toLowerCase() == "high" && (
                            <Text>High</Text>
                        )}
                        {detail?.severity?.toLowerCase() == "medium" && (
                            <Text>Medium</Text>
                        )}
                        {detail?.severity?.toLowerCase() == "low" && (
                            <Text>Low</Text>
                        )}

                    </View>
                </View>

                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Event ID</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.event_id}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Feed</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.feed_name}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.event_description}</Text>
                    </View>
                </View>
                {/* {detail?.reference_link && ( */}
                {/* <View style={styles.container}> */}
                {/* <View style={styles.column1}> */}
                {/* Content for Column 1 */}
                {/* <Text>Reference</Text> */}
                {/* </View> */}
                {/* <View style={styles.column2}> */}
                {/* Content for Column 2 */}
                {/* <a href={detail?.reference_link} target="_blank"> */}
                {/* Click Here */}
                {/* </a> */}
                {/* </View> */}
                {/* </View> */}
                {/* )} */}


                {/* <View style={styles.container}>                  
                    <View style={styles.imageDiv}>
                    <Image src={imageSrc} style={styles.image} />
                    </View>
                </View> */}

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableHeader}>IOC Value</Text>
                        <Text style={styles.tableHeader1}>IOC Type</Text>
                    </View>

                    {tableData.map((data) => (
                        <View style={styles.tableRow} key={data._source.attribute_id}>
                            <View style={styles.tableCell}>
                                <CustomText>
                                    {data._source.attribute_value}                                    </CustomText>
                            </View>
                            <View style={styles.tableCell1}>
                                <CustomText>
                                    {data._source.attribute_type}                                    </CustomText>
                            </View>
                        </View>
                    ))}

                </View>






            </Page>
        </Document>
    )
}