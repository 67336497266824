import React, { useState, useEffect, useRef } from 'react'
import { getCyTableData, getWidgetsData, getTotalCounts, getCounts } from '../../../../Methods/CyKitInvestigate'
// import { } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore';
import moment from 'moment';
// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
const INVESTIGATE_CYWAF = localStorage.getItem("INVESTIGATE_CYWAF");

const CyWAF = () => {

    // Loader
    // loaders
    const [eventCateLoader, setEventCateLoader] = useState(true)
    const [eventTypeLoader, setEventTypeLoader] = useState(true)
    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)
    const [destinationCountryLoader, setDestinationCountryLoader] = useState(true)
    const [loggedUserLoader, setLoggedUserLoader] = useState(true)
    const [actionLoader, setActionLoader] = useState(true)

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    const [totalCount, setTotalCount] = useState('')
    const [sourceIpCount, setSourceIpCount] = useState()
    const [sourcePortCount, setSourcePortCount] = useState()
    const [uniqueWebsiteCount, setUniqueWebsiteCount] = useState()
    const [destinationIpCount, setDestinationIpCount] = useState()
    const [tableData, setTableData] = useState([])


    const [eventCateLabel, setEventCateLabel] = useState([]);
    const [eventCateData, setEventCateData] = useState([]);

    // event type
    const [eventTypeLabel, setEventTypeLabel] = useState([])
    const [eventTypeData, setEventTypeData] = useState([])

    // action
    const [actionLabel, setActionLabel] = useState([])
    const [actionData, setActionData] = useState([])

    // event type
    const [loggedUserLabel, setLoggedUserLabel] = useState([])
    const [loggedUserData, setLoggedUserData] = useState([])

    // source country
    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [sourceCountryData, setSourceCountryData] = useState([])

    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])
    const [geoDestinationChart, setDestinationChart] = useState([])

    // destination country
    const [destinationCountryLabel, setDestinationCountryLabel] = useState([])
    const [destinationCountryData, setDestinationCountryData] = useState([])
    const SourcechartRef = useRef();
    const DestCreatedchartRef = useRef();
    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        to: moment().format("yyyy-MM-DDTHH:mm"),
        f: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        e: moment().format("yyyy-MM-DDTHH:mm"),
        range: 60
    });
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const [loadTableData, setLoadTableData] = useState(true)

    const [doc, setDoc] = useState(null)
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);
    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [manageDatas, setManageDatas] = useState([
        { text: "Log Source", value: "log_source", isSelected: true },
        { text: "Source IP", value: "sipv4", isSelected: true },
        { text: "Destination IP", value: "dipv4", isSelected: true },
        { text: "Action", value: "action", isSelected: true },
        { text: "Status", value: "httpstatus", isSelected: true },
        { text: "Command", value: "command", isSelected: true },
        { text: "Website", value: "domain", isSelected: true },
        { text: "URL", value: "url", isSelected: true },
        { text: "Status", value: "status", },
        { text: "Method", value: "method", isSelected: true },
        { text: "Event Type", value: "threatname", },
    ])

    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.waf') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.waf")))
            }
        })
    }, [manageDatas])

    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    // const SipChart = {
    //     labels: Labels,
    //     datasets: [
    //         {
    //             label: "Community Feeds",
    //             data: dataChart,
    //             backgroundColor: [
    //                 "rgba(255, 99, 132, 0.8)",
    //                 "rgba(54, 162, 235, 0.8)",
    //                 "rgba(255, 206, 86, 0.8)",
    //                 "rgba(75, 192, 192, 0.8)",
    //                 "rgba(153, 102, 255, 0.8)",
    //                 "rgba(255, 159, 64, 0.8)",
    //             ],
    //             borderColor: [
    //                 "rgba(255, 99, 132, 1)",
    //                 "rgba(54, 162, 235, 1)",
    //                 "rgba(255, 206, 86, 1)",
    //                 "rgba(75, 192, 192, 1)",
    //                 "rgba(153, 102, 255, 1)",
    //                 "rgba(255, 159, 64, 1)",
    //             ],
    //             borderWidth: 0,
    //             hoverOffset: 0,
    //         },
    //     ],
    // };

    // const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    //     plugins: {
    //         legend: {
    //             display: false,
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             // text: 'Chart.js Bar Chart',
    //         },
    //     },
    //     scales: {
    //         y: {
    //             beginAtZero: true,
    //             grid: {
    //                 display: false, // disable y axis inside grid lines
    //             },
    //         },
    //         x: {
    //             grid: {
    //                 display: false, // disable x axis inside grid lines
    //             },
    //         },
    //     },
    // };

    // const data = {
    //     labels: eventCateLabel,
    //     datasets: [
    //         {
    //             label: 'Event Category',
    //             data: eventCateData,
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         },
    //     ],
    // };

    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-waf'

    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            setFilter(currentPageFilter);

            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    useEffect(() => {
        setEventCateLoader(true)
        setEventTypeLoader(true)
        setSourceCountryLoader(true)
        setDestinationCountryLoader(true)
        setLoggedUserLoader(true)
        setLoadTableData(true)
        setActionLoader(true)
        getTotalCounts(filter, dateFilter).then(({ data: { hits } }) => {
            hits.total.value ? setTotalCount(hits.total.value) : setTotalCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })


        // counts

        getCounts("sipv4", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            value ? setSourceIpCount(value) : setSourceIpCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("sport", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            value ? setSourcePortCount(value) : setSourcePortCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("dipv4", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            value ? setDestinationIpCount(value) : setDestinationIpCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("dname", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            // console.log('unique web  counts :', sum_other_doc_count)
            value ? setUniqueWebsiteCount(value) : setUniqueWebsiteCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        // graph
        // cyb Event category (Top 10)
        // getWidgetsData("status", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);

        //         });

        //     }
        //     else {
        //         setEventCateLabel([]);
        //         setEventCateData([]);
        //     }
        //     setEventCateLoader(false)

        // }).catch((err) => {
        //     console.log("Error", err)
        //     setEventCateLoader(false)
        // })

        // cyb Event category (Least 10)
        getWidgetsData("attack_type", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setEventCateLabel(label);
                    setEventCateData(data);
                });
                setEventCateLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb Event Type (Top 10)
        getWidgetsData("threatname", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setEventTypeLabel(label)
                    setEventTypeData(data)
                });
            }
            else {
                setEventTypeLabel([])
                setEventTypeData([])
            }
            setEventTypeLoader(false)

        }).catch((err) => {
            console.log("Error", err)
            setEventTypeLoader(false)
        })

        // cyb Event Type (Least 10)
        // getWidgetsData("cybEventType", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             // setLabels(label);
        //             // setDataChart(data);
        //         });
        //         setEventTypeLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setEventTypeLoader(false)
        // })

        // cyb Event Type (Least 10)
        getWidgetsData("action", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setActionLabel(label);
                    setActionData(data);
                });
                setActionLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
            setActionLoader(false)
        })

        // cyb user login ( Top 10 )
        // getWidgetsData("login", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             setLoggedUserLabel(label)
        //             setLoggedUserData(data)
        //         });
        //         setLoggedUserLoader(false)
        //     }
        //     else {
        //         setLoggedUserLabel([])
        //         setLoggedUserData([])
        //         setLoggedUserLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setLoggedUserLoader(false)
        // })

        // cyb user login ( Least 10 )
        // getWidgetsData("login", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             // setLabels(label);
        //             // setDataChart(data);
        //         });
        //         setLoggedUserLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setLoggedUserLoader(false)
        // })

        // cyb source country ( Top 10 )
        getWidgetsData("cybSourceCountry", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setSourceCountryLabel(label)
                    setSourceCountryData(data)
                });
            }
            else {
                setSourceCountryLabel([])
                setSourceCountryData([])
            }
            let geoData = [];
            let locations = label
            locations.forEach((location) => {
                geoData.push([location])
            })

            setGeoSourceChart(geoData)
            setSourceCountryLoader(false)

        }).catch((err) => {
            setSourceCountryLoader(false)
            console.log("Error", err)
        })

        // cyb source country ( Least 10 )
        getWidgetsData("cybSourceCountry", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    // setLabels(label);
                    // setDataChart(data);
                });
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb destination country ( Top 10 )
        getWidgetsData("cybDestinationCountry", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setDestinationCountryLabel(label)
                    setDestinationCountryData(data)
                });

            }
            else {
                setDestinationCountryLabel([])
                setDestinationCountryData([])
            }
            let geoData = [];
            let locations = label
            locations.forEach((location) => {
                geoData.push([location])
            })

            setDestinationChart(geoData)
            setDestinationCountryLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setDestinationCountryLoader(false)

        })

        // cyb destination country ( Least 10 )
        // getWidgetsData("cybDestinationCountry", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             // setLabels(label);
        //             // setDataChart(data);
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })


        // getSourceIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     // buckets && setSipUniqueCount(buckets)

        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "Undefined" : item.key);
        //             data.push(item.doc_count);
        //             setLabels(label);
        //             setDataChart(data);
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })


        // getSourcePortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setSourcePortCount(sum_other_doc_count)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        // getDestinationPortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setDestinationPortCount(sum_other_doc_count)
        //     console.log("RESS new :", sum_other_doc_count)
        // }).catch((err) => {
        //     alert("Errrrr")
        // })

        // getDestinationIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setDestinationIpCount(sum_other_doc_count)
        //     console.log("DIPPP :", sum_other_doc_count)
        // }).catch((err) => {
        //     alert("faildeeesds")
        // })

        // setCurrentPageCount((page - 1) * perPage + perPage);
        if (!count?.length && !count2) {
            getCyTableData(100, "desc", (page - 1) * perPage, filter, dateFilter).then(({ data: { hits } }) => {
                setTotalPageCount(hits.total.value)
                hits.hits ? setTableData(hits.hits) : setTableData([])
                setLoadTableData(false)
            }).catch((err) => {
                setLoadTableData(false)
                console.log("Error", err)
            })
        }
    }, [page, perPage, filter, dateFilter])
    const numfields = [
        'process.pid', 'destination.port', 'source.port', 'event.code', 'agent.hostname', "winlog.event_data.SubjectUserName", "sport", "dport"
    ]
    const getProcessListQuery = (fieldList, date, count, sort, page) => {
        var data = {
            query: {
                bool: {
                    must: [],
                    filter: []
                }
            },
        };
        if (fieldList?.length > 0) {
            let counts = []
            for (let i = 0; i < fieldList.length; i++) {
                const item = fieldList[i];

                // If the column value exists, increment the count
                if (item.column && item.column.value) {
                    const columnName = item.column.value;
                    counts[columnName] = counts[columnName] || {
                        name: columnName,
                        count: 0,

                        iscount: 0,
                        notcount: 0
                    };
                    counts[columnName].count++;

                    // If the type value exists, increment the corresponding count
                    if (item.type === "is") {
                        counts[columnName].iscount++;
                    } else if (item.type === "is not") {
                        counts[columnName].notcount++;
                    }
                }
            }
            let dist = Object.values(counts)
            for (const field of fieldList) {
                const must = {
                    match: {}
                }
                if (field.type === "is") {
                    var distItem = dist.find(val => val.name === field.column.value)
                    console.log("test1", distItem)
                    if ((distItem && distItem.count === 1 && distItem.iscount === 1)) {
                        console.log("test", dist)
                        const must = {
                            wildcard: {}
                        }
                        if (field?.isinline || field.column.value === "event.original" || numfields.includes(field.column.value)) {

                            if (field?.isinline && !numfields.includes(field.column.value)) {
                                must.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                            else if (field.column.value === "event.original") {
                                must.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                            else if (numfields.includes(field.column.value)) {

                                const must = {
                                    match: {}
                                }
                                must.match[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                        }

                        else {
                            const wildval = {
                                "value": `*${field.selectedValue}*`,
                                "case_insensitive": true
                            }
                            must.wildcard[field.column.value] = wildval
                            data.query.bool.must.push(must);
                        }

                    }
                    else if ((distItem && distItem.count > 1 && distItem.iscount === 1)) {
                        console.log("test", dist)
                        if (field?.isinline || field.column.value === "event.original" || numfields.includes(field.column.value)) {
                            if (field?.isinline) {
                                must.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                            else if (field.column.value === "event.original") {
                                must.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                            else if (numfields.includes(field.column.value)) {
                                const must = {
                                    match: {}
                                }
                                must.match[field.column.value] = field.selectedValue
                                data.query.bool.must.push(must);
                            }
                        }
                        else {
                            const must = {
                                wildcard: {}
                            }
                            const wildval = {
                                "value": `*${field.selectedValue}*`,
                                "case_insensitive": true
                            }
                            must.wildcard[field.column.value] = wildval
                            data.query.bool.must.push(must);
                        }

                    }
                    else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
                        const current = data.query.bool.must.find(val => val.name)
                        let sindex = 0
                        let index = 0
                        if (!numfields.includes(field.column.value)) {
                            for (let i = 0; i < data.query.bool.must.length; i++) {
                                for (let j = 0; j < data.query.bool.must[i].bool.should.length; j++) {
                                    if (Object.keys(data.query.bool.must[i].bool.should[j].wildcard)[0] === field.column.value) {
                                        sindex = i + 1
                                    }
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < data.query.bool.must.length; i++) {
                                for (let j = 0; j < data.query.bool.must[i].bool.should.length; j++) {
                                    if (Object.keys(data.query.bool.must[i].bool.should[j].match)[0] === field.column.value) {
                                        index = i + 1
                                    }
                                }
                            }
                        }
                        const shouldn = {
                            bool: {
                                should: [
                                ]
                            }
                        }

                        if (sindex > 0) {
                            if (field?.isinline || field.column.value === "event.original") {
                                const should = {
                                    wildcard: {}
                                }
                                should.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must[sindex - 1].bool.should.push(should);
                            }
                            else {
                                const should = {
                                    wildcard: {}
                                }
                                const wildval = {
                                    "value": `*${field.selectedValue}*`,
                                    "case_insensitive": true
                                }
                                should.wildcard[field.column.value] = wildval
                                data.query.bool.must[sindex - 1].bool.should.push(should);
                            }
                        }
                        else if (index > 0) {
                            const should = {
                                match: {}
                            }
                            should.match[field.column.value] = field.selectedValue
                            data.query.bool.must[index - 1].bool.should.push(should);
                        }
                        else if (index === 0 && numfields.includes(field.column.value)) {
                            const should = {
                                match: {}
                            }
                            should.match[field.column.value] = field.selectedValue
                            shouldn.bool.should.push(should)
                            data.query.bool.must.push(shouldn);
                        }
                        else if (sindex === 0 && !numfields.includes(field.column.value)) {
                            if (field?.isinline || field.column.value === "event.original") {
                                const should = {
                                    wildcard: {}
                                }
                                should.wildcard[field.column.value] = field.selectedValue
                                shouldn.bool.should.push(should)
                                data.query.bool.must.push(shouldn);
                            }
                            else {
                                const should = {
                                    wildcard: {}
                                }
                                const wildval = {
                                    "value": `*${field.selectedValue}*`,
                                    "case_insensitive": true
                                }
                                should.wildcard[field.column.value] = wildval
                                shouldn.bool.should.push(should)
                                data.query.bool.must.push(shouldn);
                            }
                        }
                    }
                }
                else if (field.type === "is not") {
                    const must_not = {
                        wildcard: {}
                    }

                    var distItem = dist.find(val => val.name === field.column.value)
                    console.log("test1", distItem)
                    if ((distItem && distItem.count === 1 && distItem.notcount === 1)) {
                        console.log("test", dist)
                        const must_not = {
                            wildcard: {}
                        }
                        if (field?.isinline || field.column.value === "event.original" || numfields.includes(field.column.value)) {
                            if (field?.isinline && !numfields.includes(field.column.value)) {
                                must_not.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must_not.push(must);
                            }
                            else if (field.column.value === "event.original") {
                                must_not.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must_not.push(must);
                            }
                            else if (numfields.includes(field.column.value)) {
                                const must = {
                                    match: {}
                                }
                                must.match[field.column.value] = field.selectedValue
                                data.query.bool.must_not.push(must);
                            }
                        }
                    }
                    else if ((distItem && distItem.count > 1 && distItem.notcount === 1)) {
                        console.log("test", dist)
                        const must = {
                            wildcard: {}
                        }
                        if (field?.isinline || field.column.value === "event.original") {
                            must.wildcard[field.column.value] = field.selectedValue
                            data.query.bool.must_not.push(must);
                        }
                        else {
                            const wildval = {
                                "value": `*${field.selectedValue}*`,
                                "case_insensitive": true
                            }
                            must.wildcard[field.column.value] = wildval
                            data.query.bool.must_not.push(must);
                        }

                    }
                    else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
                        let sindex = 0
                        let index = 0
                        if (!numfields.includes(field.column.value)) {
                            for (let i = 0; i < data.query.bool.must_not.length; i++) {
                                for (let j = 0; j < data.query.bool.must_not[i].bool.should.length; j++) {
                                    if (Object.keys(data.query.bool.must_not[i].bool.should[j].wildcard)[0] === field.column.value) {
                                        sindex = i + 1
                                    }
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < data.query.bool.must_not.length; i++) {
                                for (let j = 0; j < data.query.bool.must_not[i].bool.should.length; j++) {
                                    if (Object.keys(data.query.bool.must_not[i].bool.should[j].match)[0] === field.column.value) {
                                        index = i + 1
                                    }
                                }
                            }
                        }
                        const shouldn = {
                            bool: {
                                should: [
                                ]
                            }
                        }

                        if (sindex > 0) {
                            if (field?.isinline || field.column.value === "event.original") {
                                const should = {
                                    wildcard: {}
                                }
                                should.wildcard[field.column.value] = field.selectedValue
                                data.query.bool.must_not[sindex - 1].bool.should.push(should);
                            }
                            else {
                                const should = {
                                    wildcard: {}
                                }
                                const wildval = {
                                    "value": `*${field.selectedValue}*`,
                                    "case_insensitive": true
                                }
                                should.wildcard[field.column.value] = wildval
                                data.query.bool.must_not[sindex - 1].bool.should.push(should);
                            }
                        }
                        else if (index > 0) {
                            const should = {
                                match: {}
                            }
                            should.match[field.column.value] = field.selectedValue
                            data.query.bool.must_not[index - 1].bool.should.push(should);
                        }
                        else if (index === 0 && numfields.includes(field.column.value)) {
                            const should = {
                                match: {}
                            }
                            should.match[field.column.value] = field.selectedValue
                            shouldn.bool.should.push(should)
                            data.query.bool.must_not.push(shouldn);
                        }
                        else if (sindex === 0 && !numfields.includes(field.column.value)) {
                            if (field?.isinline || field.column.value === "event.original") {
                                const should = {
                                    wildcard: {}
                                }
                                should.wildcard[field.column.value] = field.selectedValue
                                shouldn.bool.should.push(should)
                                data.query.bool.must_not.push(shouldn);
                            }
                            else {
                                const should = {
                                    wildcard: {}
                                }
                                const wildval = {
                                    "value": `*${field.selectedValue}*`,
                                    "case_insensitive": true
                                }
                                should.wildcard[field.column.value] = wildval
                                shouldn.bool.should.push(should)
                                data.query.bool.must_not.push(shouldn);
                            }
                        }
                    }

                }

            }
        }

        data.track_total_hits = true

        if (date?.from) {
            var range = {
                range: {
                    "@timestamp": {
                        gte: date.from
                    }
                }
            };
            if (date?.from && date?.to) {
                var range = {
                    range: {
                        "@timestamp": {
                            lte: date.to,
                            gte: date.from
                        }
                    }
                };
            }
            data.query.bool.filter.push(range);
        }
        if (date?.to && !date?.from) {
            var range = {
                range: {
                    "@timestamp": {
                        lte: date.to,
                    }
                }
            };
            data.query.bool.filter.push(range);
        }

        var sortQ = [
            {
                "@timestamp": {
                    "order": sort,
                    "unmapped_type": "boolean",
                },
            },
        ]
        data.size = count
        data.from = page
        data.track_total_hits = true
        data.sort = sortQ

        return data
    };

    async function getCyTableData2(filter, date) {
        let data = getProcessListQuery(filter, date);
        console.log("FILTEREDQUERY", data);

        return await fpostDl(
            `${localStorage.getItem("DATALAKE_URL")}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
            data
        );
    }

    const getFilteredTable = (filter, date = {}) => {
        setLoadTableData(true);

        if (filter || date?.to) {
            getCyTableData(100, "desc", (page - 1) * perPage, filter, date).then(({ data: { hits: { hits } } }) => {
                hits ? setTableData(hits) : setTableData([])
                setLoadTableData(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }
        else {
            getCyTableData(100, "desc", (page - 1) * perPage, filter, date).then(({ data: { hits: { hits } } }) => {
                hits ? setTableData(hits) : setTableData([])
                setLoadTableData(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }
    }
    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value,
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }

        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.waf", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">

            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >

                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "dipv4", label: "Destination IP" },
                                { value: "sipv4", label: "Source IP" },
                                { value: "sport", label: "Source Port" },
                                { value: "dport", label: "Destination Port" },
                                { value: "cybSourceCountry", label: "Source Country", isWildCard: true },
                                { value: "cybDestCountry", label: "Destination Country", isWildCard: true },
                                { value: "attack_type", label: "Attack Type", isWildCard: true },
                                { value: "threatname", label: "Event Type", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyTableData2} type={"waf"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}



                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}


            </div>

            {/* table datas */}
            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className='col-12 d-flex flex-column'>
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                                <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{totalCount ? totalCount : "--"}</h4>
                                                <span>Total Count</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{sourceIpCount ? sourceIpCount : "--"}</h4>
                                                <span>Unique Source IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{sourcePortCount ? sourcePortCount : "--"}</h4>
                                                <span>Unique Source Ports</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{uniqueWebsiteCount ? uniqueWebsiteCount : "--"}</h4>
                                                <span>Unique Websites</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border">
                                            <h5>Attack Type</h5>
                                            {eventCateLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {eventCateData.length || eventCateLabel.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={eventCateLabel} ChartData={eventCateData} ChartTitle={"Attack Type"} module={module} tab={tab} field={"attack_type"} label={"Attack Type"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>

                                        <div className="flex-fill p-4 rounded-3 border" >
                                            <h5>Event Type</h5>
                                            {eventTypeLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {eventTypeLabel.length || eventTypeData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={eventTypeLabel} ChartData={eventTypeData} ChartTitle={"Event Type"} module={module} tab={tab} field={"threatname"} label={"Event Type"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border">
                                            <h5>Source Country</h5>
                                            {sourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {sourceCountryLabel.length || sourceCountryData.length !== 0 ? (
                                                        // <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} ChartTitle={"Source Country"} />
                                                        <span className="modal-text">
                                                            <Chart
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 0) return;
                                                                            const region = geoSourceChart[selection[0].row + 1];
                                                                            MapClick("cybSourceCountry", "Source Country", region)
                                                                        },
                                                                    },
                                                                ]}
                                                                chartType="GeoChart"
                                                                width="100%"
                                                                height="300px"
                                                                data={geoSourceChart}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>

                                        <div className="flex-fill p-4 rounded-3 border" >
                                            {/* <h5>Destination Country</h5>
                                            {destinationCountryLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {destinationCountryLabel.length || destinationCountryData.length !== 0 ? (
                                                        // <BarGraph ChartLabel={destinationCountryLabel} ChartData={destinationCountryData} ChartTitle={"Destination Country"} />
                                                        <span className="modal-text">
                                                            <Chart
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 0) return;
                                                                            const region = geoDestinationChart[selection[0].row + 1];
                                                                            MapClick("cybDestCountry", "Destination Country", region)
                                                                        },
                                                                    },
                                                                ]}
                                                                chartType="GeoChart"
                                                                width="100%"
                                                                height="300px"
                                                                data={geoDestinationChart}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )} */}
                                            <h5>Action</h5>
                                            {actionLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {actionLabel.length || actionLabel.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={actionLabel} ChartData={actionData} ChartTitle={"Action"} module={module} tab={tab} field={"action"} label={"Action"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                    </div>

                                    {/* <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border" >
                                            <h5>Logged Users</h5>
                                            {loggedUserLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {loggedUserLabel.length || loggedUserData.length !== 0 ? (
                                                        <div className='col-12'>
                                                            <BarGraph ChartLabel={loggedUserLabel} ChartData={loggedUserData} ChartTitle={"Logged Users"} module={module} tab={tab} field={"login"} label={"User Name"} setfilter={setFilter} filter={filter} />
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="" style={styles.tableWrap}>
                                {loadTableData === true ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    tableData.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item.text}</th>
                                                    ))}
                                                    {/* <th>Website</th>
                                                    <th>Url</th>
                                                    <th>Status</th>
                                                    <th>Method</th>
                                                    <th>Threat</th> */}



                                                    {/* <th>Event Type</th>
                                                <th>Destination IP</th>
                                                <th>Destination Port</th>
                                                <th>Destination Country</th>
                                                <th>Source IP</th>
                                                <th>Source Port</th>
                                                <th>Source Country</th>
                                                <th>Bytes In</th>
                                                <th>Bytes Out</th> */}
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }
                                                    >
                                                        {/* <td className="font-monospace">{item._source?.dipv4}</td> */}
                                                        <td className="font-monospace">{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className="font-monospace">{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "domain" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item._source?.domain}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "domain",
                                                                                            label: "Website",
                                                                                        }}
                                                                                        value={item?._source?.domain}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "sipv4" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item._source?.sipv4}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "sipv4",
                                                                                            label: "Source IP",
                                                                                        }}
                                                                                        value={item?._source?.sipv4}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "action" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item._source?.action}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "action",
                                                                                            label: "Action",
                                                                                        }}
                                                                                        value={item?._source?.action}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "url" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item._source?.url}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "url",
                                                                                            label: "URL",
                                                                                        }}
                                                                                        value={item?._source?.url}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            )
                                                                : data.value === "log_source" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.log_source}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "log_source",
                                                                                                label: "Log Source",
                                                                                            }}
                                                                                            value={item?._source?.log_source}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                )
                                                                    : data.value === "dipv4" && data.isSelected === true ? (
                                                                        <td>
                                                                            <span className="font-monospace">
                                                                                {item._source?.dipv4}
                                                                                {showInlineFilter.show &&
                                                                                    showInlineFilter.index === index && (
                                                                                        <div className="inline-filter-wrap">
                                                                                            <InlineFilter
                                                                                                filter={filter}
                                                                                                setFilter={setFilter}
                                                                                                column={{
                                                                                                    value: "dipv4",
                                                                                                    label: "Destination IP",
                                                                                                }}
                                                                                                value={item?._source?.dipv4}
                                                                                                getFilteredTable={getFilteredTable}
                                                                                                module={module}
                                                                                                tab={tab}
                                                                                                dateFilter={dateFilter}
                                                                                                setPage={setPage}

                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                            </span>
                                                                        </td>
                                                                    ) :
                                                                        // : data.value === "status" && data.isSelected === true ? (
                                                                        // <td
                                                                        //     style={{ display: "flex", flexDirection: "row" }}
                                                                        // >
                                                                        //     <span className="font-monospace">
                                                                        //         {item?._source?.status}
                                                                        //         {showInlineFilter.show &&
                                                                        //             showInlineFilter.index === index && (
                                                                        //                 <div className="inline-filter-wrap">
                                                                        //                     <InlineFilter
                                                                        //                         filter={filter}
                                                                        //                         setFilter={setFilter}
                                                                        //                         column={{
                                                                        //                             value: "status",
                                                                        //                             label: "Status",
                                                                        //                         }}
                                                                        //                         value={item?._source.status}
                                                                        //                         getFilteredTable={getFilteredTable}
                                                                        //                         module={module}
                                                                        //                         tab={tab}
                                                                        //                         dateFilter={dateFilter}
                                                                        //                         setPage={setPage}

                                                                        //                     />
                                                                        //                 </div>
                                                                        //             )}
                                                                        //     </span>
                                                                        // </td>
                                                                        // ): 
                                                                        data.value === "httpstatus" && data.isSelected === true ? (
                                                                            <td
                                                                                style={{ display: "flex", flexDirection: "row" }}
                                                                            >
                                                                                <span className="font-monospace">
                                                                                    {item?._source?.httpstatus}
                                                                                    {showInlineFilter.show &&
                                                                                        showInlineFilter.index === index && (
                                                                                            <div className="inline-filter-wrap">
                                                                                                <InlineFilter
                                                                                                    filter={filter}
                                                                                                    setFilter={setFilter}
                                                                                                    column={{
                                                                                                        value: "httpstatus",
                                                                                                        label: "Status",
                                                                                                    }}
                                                                                                    value={item?._source?.httpstatus}
                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                    module={module}
                                                                                                    tab={tab}
                                                                                                    dateFilter={dateFilter}
                                                                                                    setPage={setPage}

                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </span>
                                                                            </td>
                                                                        ) : data.value === "command" && data.isSelected === true ? (
                                                                            <td className="font-monospace">
                                                                                <span className="font-monospace">
                                                                                    {item._source?.command}
                                                                                    {showInlineFilter.show &&
                                                                                        showInlineFilter.index === index && (
                                                                                            <div className="inline-filter-wrap">
                                                                                                <InlineFilter
                                                                                                    filter={filter}
                                                                                                    setFilter={setFilter}
                                                                                                    column={{
                                                                                                        value: "command",
                                                                                                        label: "Command",
                                                                                                    }}
                                                                                                    value={item?._source?.command}
                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                    module={module}
                                                                                                    tab={tab}
                                                                                                    dateFilter={dateFilter}
                                                                                                    setPage={setPage}

                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </span>
                                                                            </td>
                                                                        ) : data.value === "method" && data.isSelected === true ? (
                                                                            <td className="font-monospace">
                                                                                <span className="font-monospace">
                                                                                    {item._source?.method}
                                                                                    {showInlineFilter.show &&
                                                                                        showInlineFilter.index === index && (
                                                                                            <div className="inline-filter-wrap">
                                                                                                <InlineFilter
                                                                                                    filter={filter}
                                                                                                    setFilter={setFilter}
                                                                                                    column={{
                                                                                                        value: "method",
                                                                                                        label: "Method",
                                                                                                    }}
                                                                                                    value={item?._source?.method}
                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                    module={module}
                                                                                                    tab={tab}
                                                                                                    dateFilter={dateFilter}
                                                                                                    setPage={setPage}

                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </span>
                                                                            </td>
                                                                        ) : data.value === "threatname" && data.isSelected === true ? (
                                                                            <td className="font-monospace">
                                                                                <span className="font-monospace">
                                                                                    {item._source?.threatname}
                                                                                    {showInlineFilter.show &&
                                                                                        showInlineFilter.index === index && (
                                                                                            <div className="inline-filter-wrap">
                                                                                                <InlineFilter
                                                                                                    filter={filter}
                                                                                                    setFilter={setFilter}
                                                                                                    column={{
                                                                                                        value: "threatname",
                                                                                                        label: "Threat",
                                                                                                    }}
                                                                                                    value={item?._source?.threatname}
                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                    module={module}
                                                                                                    tab={tab}
                                                                                                    dateFilter={dateFilter}
                                                                                                    setPage={setPage}

                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </span>
                                                                            </td>
                                                                        ) : (<></>)
                                                        ))}

                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                            </a>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument doc={doc} setDoc={setDoc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CYWAF}/_search?track_total_hits=true`} req={fpostDl} />

        </div>
    )
}

export default CyWAF