import React from 'react'
import { Document, Page, View, Text, Image, } from "@react-pdf/renderer";
import Header from '../Header';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';


const styles = {
    page: { padding: "20px" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
    tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    imageDiv: { justifyContent: 'center', },
    setBorder: { border: "1px solid black" },
    image: {
        width: '80%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "16px", fontWeight: "600", padding: "5px" },
    subTitle: { fontSize: "13px", fontWeight: "600", padding: "5px" }
};

const InvestigationSummaryExport = ({ detectionObservableDynamic, detectionObservable, investigationConclusions, ClosedComment, imageBlob, investigationDescription }) => {


    const [imageSrc, setImageSrc] = useState(null);
    const [tableDetails, setTableDetails] = useState([]);

    useEffect(() => {
        if (imageBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(imageBlob);
        }
    }, [imageBlob]);

    useEffect(() => {
        setTableDetails(investigationDescription.filter((item) => item.label !== undefined).map((item) => item))
    }, [investigationDescription])

    // const textListReplace = (htmlContent) => {

    //     // return htmlContent?.replace(/<(ol|ul)>/g, '<View>')?.replace(/<\/(ol|ul)>/g, '</View>')?.replace(/<(ol|ul)>/g, '<View>')?.replace(/<\/(ol|ul)>/g, '</View>')?.replace(/<li>/g, '<Text>')?.replace(/<\/li>/g, '</Text>');
    //     return htmlContent?.replace(/<p>|<\/p>|<br>/gi, '');
    // }

    const textListReplace = (input) => {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(input, 'text/html').body.textContent;
        return decodedString;

    }
    const removeHtmlTags = (text) => {
        return text?.replace(/<[^>]*>/g, '');
    };


    // console.log(ClosedComment,'i');

    return (
        <Document>
            <Page style={styles.page}>
                <Header />

                <Text style={styles.title}>Detection Details</Text>

                {investigationConclusions && (
                    <View>
                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Detection Name</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{investigationConclusions["Alert Name"]}</Text>
                            </View>
                        </View>

                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Detection ID</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{detectionObservable[0]?._source?.hunt_detection_id}</Text>
                            </View>
                        </View>
                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Date&Time</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{detectionObservable[0]?._source?.current_timestamp}</Text>
                            </View>
                        </View>


                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Rulebook</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{detectionObservable[0]?._source?.playbook_id}</Text>
                            </View>
                        </View>


                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}> Description</Text>
                            </View>

                            <View style={styles.column2}>
                                <Text>
                                    {textListReplace(investigationConclusions["Alert Description"])}
                                </Text>
                            </View>
                        </View>

                    </View>
                )}

                {investigationConclusions && (
                    <View>
                        <View>

                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Detection Source</Text>
                                </View>

                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["Alert Source"]}</Text>
                                </View>
                            </View>



                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Mitre Attack Tactics</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["MITRE ATTACK TACTICS"]}</Text>
                                </View>
                            </View>



                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Mitre Attack Techniques</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["MITRE ATTACK TECHNIQUES"]}</Text>
                                </View>
                            </View>



                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Threat Score</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{parseFloat(investigationConclusions["Threat_Score"]).toFixed(2)}</Text>
                                </View>
                            </View>





                            {investigationConclusions["Severity level"] === "Low" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Severity</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["Severity level"]}</Text>
                                    </View>
                                </View>
                            )}

                            {investigationConclusions["Severity level"] === "Medium" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Severity</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["Severity level"]}</Text>
                                    </View>
                                </View>
                            )}

                            {investigationConclusions["Severity level"] === "High" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Severity</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["Severity level"]}</Text>
                                    </View>
                                </View>
                            )}

                            {investigationConclusions["Severity level"] === "Critical" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Severity</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["Severity level"]}</Text>
                                    </View>
                                </View>
                            )}


                            {investigationConclusions["threat_classification"] === "Improbable" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Threat Classification</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["threat_classification"]}</Text>
                                    </View>
                                </View>
                            )}

                            {investigationConclusions["threat_classification"] === "Unlikely" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Threat Classification</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["threat_classification"]}</Text>
                                    </View>
                                </View>
                            )}

                            {investigationConclusions["threat_classification"] === "Benign True Positive" && (
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Threat Classification</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{investigationConclusions["threat_classification"]}</Text>
                                    </View>
                                </View>
                            )}
                        </View >
                        {investigationConclusions && (
                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Alert Description</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{textListReplace(investigationConclusions["Alert Description"])}</Text>
                                </View>
                            </View>
                        )}

                        {(investigationConclusions["IP Reputation"] || investigationConclusions["IP Reputation"] === 0) && (

                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>IP Reputation</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["IP Reputation"]}</Text>
                                </View>
                            </View>

                        )}

                        {investigationConclusions["Hash Reputation"] && (
                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Hash Reputation</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["Hash Reputation"]}</Text>
                                </View>
                            </View>

                        )}

                        {investigationConclusions["URL Reputation"] && (
                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>URL Reputation</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{investigationConclusions["URL Reputation"]}</Text>
                                </View>
                            </View>
                        )}

                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Next Steps</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{textListReplace(investigationConclusions["Next Steps"])}</Text>
                            </View>
                        </View>

                        <View style={styles.container}>
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>Recommendations and actions</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{textListReplace(investigationConclusions["Recommendations and actions"])}</Text>
                            </View>
                        </View>
                    </View>
                )
                }
            </Page>

            <Page style={styles.page}>
                <View>
                    <Text style={styles.title}>Detection Observables</Text>
                </View>

                {detectionObservableDynamic?.map((fieldName, index) => (
                    <View>
                        {detectionObservable?.map((item, index) => (
                            <View>
                                {item?._source?.observables[fieldName] != "No URL" &&
                                    <View style={styles.container}>
                                        <View style={styles.column2}>
                                            <Text style={styles.subTitle}>{fieldName}</Text>
                                        </View>

                                        <View style={styles.column2}>
                                            <Text>{item?._source?.observables[fieldName]}</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        ))}
                    </View>
                ))}


                {detectionObservable?.map(({ _source }) => (
                    <View>
                        <View>
                            {_source?.user_name &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>User name</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{_source?.user_name}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.host_name &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Host name</Text>
                                    </View>

                                    <View style={styles.column2}>
                                        <Text>{_source?.host_name}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.detection_timestamp &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Detection timestamp</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{_source?.detection_timestamp}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.source_ip &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Source IP</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{_source?.source_ip}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.dest_ip &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Destination IP</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{_source?.dest_ip}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_name &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process name</Text>
                                    </View>

                                    <View style={styles.column2}>
                                        <Text>{_source?.process_name}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_pid &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process PID</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>{_source?.process_pid}</Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_commandline &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process commandline</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>
                                            {_source?.process_commandline}
                                        </Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_parent_exec &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process Parent Exec</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>
                                            {_source?.process_parent_exec}
                                        </Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_parent_commandline &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process parent Commandline</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        <Text>
                                            {_source?.process_parent_commandline}
                                        </Text>
                                    </View>
                                </View>
                            }
                            {_source?.process_parent_name &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process parent Name</Text>
                                    </View>

                                    <View style={styles.column2}>
                                        <Text>
                                            {_source?.process_parent_name}
                                        </Text>
                                    </View>
                                </View>
                            }

                            {_source?.process_signer &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Process parent Signer</Text>
                                    </View>

                                    <View style={styles.column2}>
                                        <Text>
                                            {_source?.process_signer}
                                        </Text>
                                    </View>
                                </View>
                            }

                            <View style={styles.container}>
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Process hash</Text>
                                </View>

                                <View style={styles.column2}>
                                    <Text>{_source?.process_hash}</Text>
                                </View>
                            </View>

                            {ClosedComment &&
                                <View style={styles.container}>
                                    <View style={styles.column2}>
                                        <Text style={styles.subTitle}>Comment</Text>
                                    </View>

                                    <View style={styles.column2}>
                                        <Text>
                                            {removeHtmlTags(ClosedComment)}
                                        </Text>
                                    </View>
                                </View>
                            }
                        </View>

                    </View>
                ))}

            </Page>

            <Page style={styles.page}>
                {imageSrc && (
                    <View style={{ border: "2px solid #960000", padding: "16px", margin: "16px" }}>
                        <View>
                            <Text style={styles.title}>Investigation Summary</Text>
                        </View>

                        <View style={styles.container}>
                            <Image src={imageSrc} style={styles.image} />
                        </View>
                    </View>
                )}

                {tableDetails && (
                    <View>
                        {tableDetails?.map((item, index) => (
                            <>
                                <View style={styles.container} break={index > 1} wrap={false}>
                                    <View style={styles.column2}>
                                        <View>
                                            <Text style={styles.subTitle}>{item?.label} - {item?.data}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View>
                                    <View>
                                        <View style={styles.column2}>
                                            <Text style={{ marginTop: "20px" }}>{item?.desc}</Text>
                                        </View>
                                    </View>
                                </View>

                                {/* <View>
                                    <View style={{ marginTop: "10px" }}>
                                        <View style={styles.column2}>
                                            <Text>{item?.label}.pdf</Text>
                                        </View>
                                    </View>
                                </View> */}
                            </>
                        ))}
                    </View>
                )}

            </Page>


        </Document>
    )
}

export default InvestigationSummaryExport