import React, { useEffect, useState } from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../Loader/Loader";
import 'react-toastify/dist/ReactToastify.css';

import { getHunterSelectList, createCustomHunt, getHunterCatalogueDetails, getInvCatalogueTenant } from "../../../../Methods/RuleStudioMethods";
import CustomHuntInformation from "./CustomHuntInformation";
import { Button, Form } from "react-bootstrap";





const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


const CustomMasterHunterModal = ({
  setCustomShow, customShow, setHunterIdEdit,
  huntIdEdit, setSubmitLoader, submitLoader }) => {
  const dispatch = useDispatch();

  const [selectedList, setList] = useState([]);
  const [catalogue, setCatalogue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hunterId, setHunterID] = useState("HUNT-");
  const [hunt, setHunt] = useState("HUNT--")
  const [hunterName, setHunterName] = useState("");
  const [description, setDescription] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");

  const [notify, setNotify] = useState("");
  const [steps, setSteps] = useState([
    {
      name: "",
      description: "",
      inv_playbook: [],
    },
  ]);

  console.log(steps)
  // const detailsCustomRsHunt = useSelector((state) => state.detailsCustomRsHunt);
  // const { catalogue, loading: loading } = detailsCustomRsHunt;






  const setDataInEditList = () => {

    setHunterID(huntIdEdit === '' ? '' : catalogue?.hunt_id);
    setHunterName(huntIdEdit === '' ? '' : catalogue?.hunt_name);
    setDescription(huntIdEdit === '' ? '' : catalogue?.Description);
    setDetailedDescription(huntIdEdit === '' ? '' : catalogue?.detailed_description);
    setNotify(huntIdEdit === '' ? '' : catalogue?.notify);
    // setSteps(huntIdEdit === '' ? [{
    //   name: "",
    //   description: "",
    //   inv_playbook: [],
    // },] : catalogue?.steps);

  };





  useEffect(() => {
    dispatch(getHunterSelectList());
    dispatch(getInvCatalogueTenant());

    if (
      (huntIdEdit || Object.keys(catalogue).length === 0) &&
      huntIdEdit != catalogue?.hunt_id && huntIdEdit != ''
    ) {
      setLoading(true)
      getHunterCatalogueDetails(huntIdEdit).then((res) => {

        const stepsData = res?.data?.steps
        setCatalogue(res?.data)


        setSteps(stepsData);
        setLoading(false)
      })
    }

    if (huntIdEdit != "") {
      setDataInEditList();
    } else {
      setHunterID("HUNT-");
      setHunterName("");
      setDescription("");
      setDetailedDescription("");
      setNotify("")
    }
  }, [huntIdEdit, catalogue, customShow]);


  const updateSteps = (updatedSteps) => {
    // Add stepNumber property to each step object
    const stepsWithStepNumber = updatedSteps.map((step, index) => ({
      step: index + 1, ...step,
      // Assuming step numbering starts from 1
    }));

    setSteps(stepsWithStepNumber);
  };






  const addMore = () => {
    let obj = {
      name: "",
      description: "",
      inv_playbook: [],
    };
    setSteps([...steps, obj]);
  };

  let stepsDiv = steps?.map((data, index) => (
    <CustomHuntInformation
      steps={steps}
      setSteps={updateSteps}
      index={index}
      data={data}
      addMore={addMore}
    />
  ));



  const onSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save changes?")) {
      setSubmitLoader(true)
      let data = {
        hunt_id: hunterId,
        hunt_name: hunterName,
        Description: description,
        detailed_description: detailedDescription,
        notify,
        steps

      };
      let id = hunterId

      dispatch(createCustomHunt(data, id));
    }



  };



  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
        <span className="was-validated">
          <div className="px-3">
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Hunt ID  <span className="text-danger">*</span></label>
                    {/* <input type="text" 
                    className="form-control is-invalid"
                      name="hunterId"
                      value={hunterId}
                      placeholder="HUNT ID "
                      id=  "validationTextarea"
                      readOnly={huntIdEdit ? true & "validationTextarea"  : false}
                      onChange={(e) => setHunterID(e.target.value)}
                    /> */}

                    <input type="text"
                      className={huntIdEdit.length === 0 ? `form-control is-invalid` : 'form-control  '}
                      id="validationTextarea" name="hunterId"
                      value={hunterId}
                      placeholder="HUNT ID "
                      required readOnly={huntIdEdit ? true : false}
                      onChange={(e) => setHunterID(e.target.value)} />


                  </div>
                </div> <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Hunt Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea"
                      placeholder="Hunt Name " required name="hunterName"
                      value={hunterName}
                      onChange={(e) => setHunterName(e.target.value)}
                    />

                  </div>
                </div></div></div>
            <div className="row mt-1">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Description<span className="text-danger">*</span></label>

                    <textarea
                      type="text"
                      rows="5"
                      className="form-control input-lg is-invalid"
                      id="validationTextarea"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description Here"
                      row={6}
                      required
                    />

                  </div>
                </div></div></div>

            <div className="row mt-1">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Detailed Description <span className="text-danger">*</span></label>

                  <ReactQuill
                    theme="snow"
                    value={detailedDescription}
                    onChange={setDetailedDescription}
                  />
                </div>
              </div>
            </div>
            <div
                    style={{ marginLeft: '10px' }} // Adjust the value as needed
                ></div>

            <div className="row mt-3">
              <div className="form-group">
                <label for="validationTextarea" className="form-label">Notify<span className="text-danger">*</span></label>

                <div>
                  <textarea
                    type="text"
                    rows="5"
                    className="form-control input-lg s-invalid"
                    name="notify"
                    value={notify}
                    onChange={(e) => setNotify(e.target.value)}
                    placeholder="Notify"
                    row={2}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">{stepsDiv}</div>

            <div className="row mt-3 py-3" style={styles.footerWrap}>
              <div className="form-group">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button className="btn btn-secondary" onClick={() => handleClose()}>
                    Cancel
                  </Button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" disabled={submitLoader === true ? true : false}   >
                    {submitLoader === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                      <span className="sr-only"> </span>
                    </div></> : 'Save'}
                  </button>

                </div>
              </div>
            </div>
          </div >
        </span>
      )}
    </Form>
  );
  const handleClose = () => {
    if (window.confirm("Do you want to close?") == true) {
      setCustomShow(false);
      setHunterIdEdit("");
    }

  };




  return (
    <>
      {" "}
      {customShow === true && (
        <CustomModal
          title={huntIdEdit != '' ? 'Edit Hunter' : `Custom Hunter`}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          modalSize={'lg'}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}{" "}
    </>
  );
};

export default CustomMasterHunterModal;