import React, { useState, useEffect } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData } from '../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils';
import styles from './CykitInvestigation.styles';
import BarGraph from '../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../axios/axios';
import FilterList from '../../../components/FilterList/FilterList';
import InlineFilter from '../../../components/InlineFilter/InlineFilter';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CykitInvestigation = () => {

  const [totalCount, setTotalCount] = useState('')
  const [sourceIpCount, setSourceIpCount] = useState([])
  const [sourcePortCount, setSourcePortCount] = useState()
  const [destinationPortCount, setDestinationPortCount] = useState()
  const [destinationIpCount, setDestinationIpCount] = useState()
  const [tableData, setTableData] = useState([])

  const [tableViewEnabled, setTableViewEnabled] = useState(false)

  const [eventCateLabel, setEventCateLabel] = useState([]);
  const [eventCateData, setEventCateData] = useState([]);

  // event type
  const [eventTypeLabel, setEventTypeLabel] = useState([])
  const [eventTypeData, setEventTypeData] = useState([])

  // event type
  const [loggedUserLabel, setLoggedUserLabel] = useState([])
  const [loggedUserData, setLoggedUserData] = useState([])

  // source country
  const [sourceCountryLabel, setSourceCountryLabel] = useState([])
  const [sourceCountryData, setSourceCountryData] = useState([])

  // geo chart 
  const [geoSourceChart, setGeoSourceChart] = useState([])
  const [geoDestinationChart, setDestinationChart] = useState([])

  // destination country
  const [destinationCountryLabel, setDestinationCountryLabel] = useState([])
  const [destinationCountryData, setDestinationCountryData] = useState([])

  // states for filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [showInlineFilter, setShowInlineFilter] = useState({
    show: false,
    index: 0,
  });

  // console.log("LABELL :", eventCateLabel)
  const [loadTableData, setLoadTableData] = useState(true)

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
  });

  // const SipChart = {
  //     labels: Labels,
  //     datasets: [
  //         {
  //             label: "Community Feeds",
  //             data: dataChart,
  //             backgroundColor: [
  //                 "rgba(255, 99, 132, 0.8)",
  //                 "rgba(54, 162, 235, 0.8)",
  //                 "rgba(255, 206, 86, 0.8)",
  //                 "rgba(75, 192, 192, 0.8)",
  //                 "rgba(153, 102, 255, 0.8)",
  //                 "rgba(255, 159, 64, 0.8)",
  //             ],
  //             borderColor: [
  //                 "rgba(255, 99, 132, 1)",
  //                 "rgba(54, 162, 235, 1)",
  //                 "rgba(255, 206, 86, 1)",
  //                 "rgba(75, 192, 192, 1)",
  //                 "rgba(153, 102, 255, 1)",
  //                 "rgba(255, 159, 64, 1)",
  //             ],
  //             borderWidth: 0,
  //             hoverOffset: 0,
  //         },
  //     ],
  // };

  // const options = {
  //     responsive: true,
  //     plugins: {
  //         legend: {
  //             display: false,
  //             position: 'top',
  //         },
  //         title: {
  //             display: true,
  //             // text: 'Chart.js Bar Chart',
  //         },
  //     },
  //     scales: {
  //         y: {
  //             beginAtZero: true,
  //             grid: {
  //                 display: false, // disable y axis inside grid lines
  //             },
  //         },
  //         x: {
  //             grid: {
  //                 display: false, // disable x axis inside grid lines
  //             },
  //         },
  //     },
  // };

  // const data = {
  //     labels: eventCateLabel,
  //     datasets: [
  //         {
  //             label: 'Event Category',
  //             data: eventCateData,
  //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //         },
  //     ],
  // };

  // for filter
  let count = [];
  let count2;
  const module = 'investigation'
  const tab = 'cykit'

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filters"));
    const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
    const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
    console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
    if (currentPageFilter?.length || currentPageDateFilter?.to) {
      count = currentPageFilter;
      count2 = currentPageDateFilter?.to
      setDateFilter(currentPageDateFilter)
      setFilter(currentPageFilter);

      getFilteredTable(currentPageFilter, currentPageDateFilter);
    }
  }, []);

  useEffect(() => {
    getTotalCounts().then(({ data: { hits } }) => {
      hits.total.value && setTotalCount(hits.total.value)
    }).catch((err) => {
      console.log("Error", err)
    })


    // counts

    getCounts("sipv4").then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
      sum_other_doc_count && setSourceIpCount(sum_other_doc_count)
    }).catch((err) => {
      console.log("Error", err)
    })

    getCounts("sport").then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
      sum_other_doc_count && setSourcePortCount(sum_other_doc_count)
    }).catch((err) => {
      console.log("Error", err)
    })

    getCounts("dipv4").then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
      sum_other_doc_count && setDestinationIpCount(sum_other_doc_count)
    }).catch((err) => {
      console.log("Error", err)
    })

    getCounts("dport").then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
      sum_other_doc_count && setDestinationPortCount(sum_other_doc_count)
    }).catch((err) => {
      console.log("Error", err)
    })

    // graph
    // cyb Event category (Top 10)
    getWidgetsData("cybEventCategory", 10, "asc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          setEventCateLabel(label);
          setEventCateData(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb Event category (Least 10)
    getWidgetsData("cybEventCategory", 10, "desc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          // setLabels(label);
          // setDataChart(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb Event Type (Top 10)
    getWidgetsData("cybEventType", 10, "asc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          setEventTypeLabel(label)
          setEventTypeData(data)
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb Event Type (Least 10)
    getWidgetsData("cybEventType", 10, "desc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          // setLabels(label);
          // setDataChart(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb user login ( Top 10 )
    getWidgetsData("login", 10, "asc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          setLoggedUserLabel(label)
          setLoggedUserData(data)
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb user login ( Least 10 )
    getWidgetsData("login", 10, "desc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          // setLabels(label);
          // setDataChart(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb source country ( Top 10 )
    getWidgetsData("cybSourceCountry", 10, "asc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          setSourceCountryLabel(label)
          setSourceCountryData(data)
        });
      }
      let geoData = [];
      let locations = label
      locations.forEach((location) => {
        geoData.push([location])
      })

      setGeoSourceChart(geoData)

    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb source country ( Least 10 )
    getWidgetsData("cybSourceCountry", 10, "desc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          // setLabels(label);
          // setDataChart(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb destination country ( Top 10 )
    getWidgetsData("cybDestCountry", 10, "asc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          setDestinationCountryLabel(label)
          setDestinationCountryData(data)
        });
      }
      let geoData = [];
      let locations = label
      locations.forEach((location) => {
        geoData.push([location])
      })

      setDestinationChart(geoData)
    }).catch((err) => {
      console.log("Error", err)
    })

    // cyb destination country ( Least 10 )
    getWidgetsData("cybDestCountry", 10, "desc").then(({ data: { aggregations: { 2: { buckets } } } }) => {
      let label = [];
      let data = [];
      if (buckets.length > 0) {
        buckets.map((item) => {
          label.push(item.key === "" ? "--" : item.key);
          data.push(item.doc_count);
          // setLabels(label);
          // setDataChart(data);
        });
      }
    }).catch((err) => {
      console.log("Error", err)
    })


    // getSourceIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
    //     // buckets && setSipUniqueCount(buckets)

    //     let label = [];
    //     let data = [];
    //     if (buckets.length > 0) {
    //         buckets.map((item) => {
    //             label.push(item.key === "" ? "Undefined" : item.key);
    //             data.push(item.doc_count);
    //             setLabels(label);
    //             setDataChart(data);
    //         });
    //     }
    // }).catch((err) => {
    //     console.log("Error", err)
    // })


    // getSourcePortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
    //     sum_other_doc_count && setSourcePortCount(sum_other_doc_count)
    // }).catch((err) => {
    //     console.log("Error", err)
    // })

    // getDestinationPortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
    //     sum_other_doc_count && setDestinationPortCount(sum_other_doc_count)
    //     console.log("RESS new :", sum_other_doc_count)
    // }).catch((err) => {
    //     alert("Errrrr")
    // })

    // getDestinationIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
    //     sum_other_doc_count && setDestinationIpCount(sum_other_doc_count)
    //     console.log("DIPPP :", sum_other_doc_count)
    // }).catch((err) => {
    //     alert("faildeeesds")
    // })

    if (!count?.length && !count2) {
      getCyTableData().then(({ data: { hits: { hits } } }) => {
        hits && setTableData(hits)
        setLoadTableData(false)
      }).catch((err) => {
        console.log("Error", err)
      })
    }
  }, [])

  const getProcessListQuery = (fieldList, date, count, sort, page) => {
    var data = {
      query: {
        bool: {
          must: [],
          filter: []
        }
      },
    };
    if (fieldList.length > 0) {
      var data = {
        query: {
          bool: {
            must: [],
            filter: [],
            must_not: [],
            should: []
          }
        }
      };
      let counts = []
      for (let i = 0; i < fieldList.length; i++) {
        const item = fieldList[i];

        // If the column value exists, increment the count
        if (item.column && item.column.value) {
          const columnName = item.column.value;
          counts[columnName] = counts[columnName] || {
            name: columnName,
            count: 0,

            iscount: 0,
            notcount: 0
          };
          counts[columnName].count++;

          // If the type value exists, increment the corresponding count
          if (item.type === "is") {
            counts[columnName].iscount++;
          } else if (item.type === "is not") {
            counts[columnName].notcount++;
          }
        }
      }
      let dist = Object.values(counts)
      for (const field of fieldList) {
        const must = {
          term: {}
        }
        if (field.type === "is") {
          var distItem = dist.find(val => val.name === field.column.value)
          console.log("test1", distItem)
          if ((distItem && distItem.count === 1 && distItem.iscount === 1)) {
            console.log("test", dist)
            const must = {
              wildcard: {}
            }
            if (field?.isinline) {
              must.wildcard[field.column.value] = field.selectedValue
              data.query.bool.must.push(must);
            }
            else {
              const wildval = {
                "value": `*${field.selectedValue}*`,
                "case_insensitive": true
              }
              must.wildcard[field.column.value] = wildval
              data.query.bool.must.push(must);
            }

          }
          else if ((distItem && distItem.count > 1 && distItem.iscount === 1)) {
            console.log("test", dist)
            if (field?.isinline) {
              const must = {
                wildcard: {}
              }
              must.wildcard[field.column.value] = field.selectedValue
              data.query.bool.must.push(must);
            }
            else {
              const must = {
                wildcard: {}
              }
              const wildval = {
                "value": `*${field.selectedValue}*`,
                "case_insensitive": true
              }
              must.wildcard[field.column.value] = wildval
              data.query.bool.must.push(must);
            }

          }
          else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
            const current = data.query.bool.must.find(val => val.name)
            let index = 0
            for (let i = 0; i < data.query.bool.must.length; i++) {

              for (let j = 0; j < data.query.bool.must[i].bool.should.length; j++) {
                if (Object.keys(data.query.bool.must[i].bool.should[j].wildcard)[0] === field.column.value) {
                  index = i + 1
                }
              }
            }
            const shouldn = {
              bool: {
                should: [
                ]
              }
            }

            if (index > 0) {
              if (field?.isinline) {
                const should = {
                  wildcard: {}
                }
                should.wildcard[field.column.value] = field.selectedValue
                data.query.bool.must[index - 1].bool.should.push(should);
              }
              else {
                const should = {
                  wildcard: {}
                }
                const wildval = {
                  "value": `*${field.selectedValue}*`,
                  "case_insensitive": true
                }
                should.wildcard[field.column.value] = wildval
                data.query.bool.must[index - 1].bool.should.push(should);
              }
            }
            else {
              if (field?.isinline) {
                const should = {
                  wildcard: {}
                }
                should.wildcard[field.column.value] = field.selectedValue
                shouldn.bool.should.push(should)
                data.query.bool.must.push(shouldn);
              }
              else {
                const should = {
                  wildcard: {}
                }
                const wildval = {
                  "value": `*${field.selectedValue}*`,
                  "case_insensitive": true
                }
                should.wildcard[field.column.value] = wildval
                shouldn.bool.should.push(should)
                data.query.bool.must.push(shouldn);
              }
            }
          }
        }
        else if (field.type === "is not") {
          const must_not = {
            wildcard: {}
          }

          var distItem = dist.find(val => val.name === field.column.value)
          console.log("test1", distItem)
          if ((distItem && distItem.count === 1 && distItem.notcount === 1)) {
            console.log("test", dist)
            const must_not = {
              wildcard: {}
            }
            if (field?.isinline) {
              must_not.wildcard[field.column.value] = field.selectedValue
              data.query.bool.must_not.push(must_not);
            }
            else {
              const wildval = {
                "value": `*${field.selectedValue}*`,
                "case_insensitive": true
              }
              must_not.wildcard[field.column.value] = wildval
              data.query.bool.must_not.push(must_not);
            }

          }
          else if ((distItem && distItem.count > 1 && distItem.notcount === 1)) {
            console.log("test", dist)
            const must = {
              wildcard: {}
            }
            if (field?.isinline) {
              must.wildcard[field.column.value] = field.selectedValue
              data.query.bool.must_not.push(must);
            }
            else {
              const wildval = {
                "value": `*${field.selectedValue}*`,
                "case_insensitive": true
              }
              must.wildcard[field.column.value] = wildval
              data.query.bool.must_not.push(must);
            }

          }
          else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
            let index = 0
            for (let i = 0; i < data.query.bool.must_not.length; i++) {
              for (let j = 0; j < data.query.bool.must_not[i].bool.should.length; j++) {
                if (Object.keys(data.query.bool.must_not[i].bool.should[j].wildcard)[0] === field.column.value) {
                  index = i + 1
                }
              }
            }
            const shouldn = {
              bool: {
                should: [
                ]
              }
            }

            if (index > 0) {
              const should = {
                wildcard: {}
              }
              if (field?.isinline) {
                should.wildcard[field.column.value] = field.selectedValue
                data.query.bool.must_not[index - 1].bool.should.push(should);
              }
              else {
                const wildval = {
                  "value": `*${field.selectedValue}*`,
                  "case_insensitive": true
                }
                should.wildcard[field.column.value] = wildval
                data.query.bool.must_not[index - 1].bool.should.push(should);
              }

            }
            else {
              const should = {
                wildcard: {}
              }
              if (field?.isinline) {
                should.wildcard[field.column.value] = field.selectedValue
                shouldn.bool.should.push(should)
                data.query.bool.must_not.push(shouldn);
              }
              else {
                const wildval = {
                  "value": `*${field.selectedValue}*`,
                  "case_insensitive": true
                }
                should.wildcard[field.column.value] = wildval
                shouldn.bool.should.push(should)
                data.query.bool.must_not.push(shouldn);
              }

            }
          }
        }

      }
    }
    var sortQ = [
      {
        "detection_timestamp": {
          "order": sort,
          "unmapped_type": "boolean",
        },
      },
    ]
    data.size = count
    data.from = page
    data.track_total_hits = true
    data.sort = sortQ

    if (date.from) {
      var range = {
        range: {
          "detection_timestamp": {
            gte: date.from
          }
        }
      };
      if (date.from && date.to) {
        var range = {
          range: {
            "detection_timestamp": {
              lte: date.to,
              gte: date.from
            }
          }
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date.to && !date.from) {
      var range = {
        range: {
          "detection_timestamp": {
            lte: date.to,
          }
        }
      };
      data.query.bool.filter.push(range);
    }

    return data
  };

  async function getCyTableData2(filter, date) {
    let data = getProcessListQuery(filter, date);
    console.log("FILTEREDQUERY", data);

    return await fpostDl(
      `${localStorage.getItem("DATALAKE_URL")}/.ds-logs-fw*/_search?track_total_hits=true`,
      data
    );
  }

  const getFilteredTable = (filter, date = {}) => {
    setLoadTableData(true);

    if (filter || date?.to) {
      getCyTableData2(filter, date).then(({ data: { hits: { hits } } }) => {
        hits && setTableData(hits)
        setLoadTableData(false)
      }).catch((err) => {
        console.log("Error", err)
      })
    }
    else {
      getCyTableData().then(({ data: { hits: { hits } } }) => {
        hits && setTableData(hits)
        setLoadTableData(false)
      }).catch((err) => {
        console.log("Error", err)
      })
    }
  }

  return (
    <div className="col-12 position-relative">
      <div
        className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
        style={styles.filterWrap}
      >
        {tableViewEnabled &&
          <div className="position-relative mr-2">
            <Button
              // className="position-relative"
              ref={ref}
              onClick={() => setShowAddFilter(!showAddFilter)}
            >
              Add filter
            </Button>
            {showAddFilter && (
              <AddFilter
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setShowAddFilter={setShowAddFilter}
                getFilteredTable={getFilteredTable}
                columnValues={[
                  { value: "dipv4", label: "Device IP" },
                  { value: "sipv4", label: "Source IP" },
                ]}
                module={module}
                tab={tab}
              />
            )}
          </div>
        }
        <div className="d-flex gap-3" onClick={() => setTableViewEnabled(!tableViewEnabled)}>
          {tableViewEnabled ?
            <span className="cursor-pointer" data-toggle="tooltip" data-placement="bottom" title="Dashboard view">
              <Monitor size={20} />
            </span>
            :
            <span className="cursor-pointer" data-toggle="tooltip" data-placement="bottom" title="Tabular view">
              <TableIcon size={20} />
            </span>
          }

        </div>

      </div>

      {/* table datas */}
      <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

        <Tabs
          defaultActiveKey="firewall"
          id="uncontrolled-tab-example"
          className="mb-3"
        // onSelect={(e) => setActiveTab(e)}
        >
          <Tab eventKey="firewall" title="Firewall">
            <div className='col-12 d-flex flex-column'>

              {!tableViewEnabled &&
                <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                  <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                      <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                        <div>
                          <h4>{totalCount ? totalCount : "--"}</h4>
                          <span>Total count</span>
                        </div>
                      </div>
                      <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                        <div>
                          <h4>{sourceIpCount ? sourceIpCount : "--"}</h4>
                          <span>Unique Source IPs</span>
                        </div>
                      </div>
                      <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                        <div>
                          <h4>{sourcePortCount ? sourcePortCount : "--"}</h4>
                          <span>Unique Source Ports</span>
                        </div>
                      </div>
                      <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                        <div>
                          <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                          <span>Unique Destination IPs</span>
                        </div>
                      </div>
                      <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                        <div>
                          <h4>{destinationPortCount ? destinationPortCount : "--"}</h4>
                          <span>Unique Destination Ports</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                      <div className="col-6 p-4 rounded-3 border">
                        <h5>Event Category</h5>
                        {eventCateData.length || eventCateLabel.length !== 0 ? (
                          <BarGraph ChartLabel={eventCateLabel} ChartData={eventCateData} ChartTitle={"Event Category"} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>

                      <div className="flex-fill p-4 rounded-3 border" >
                        <h5>Event Type</h5>
                        {eventTypeLabel.length || eventTypeData.length !== 0 ? (
                          <BarGraph ChartLabel={eventTypeLabel} ChartData={eventTypeData} ChartTitle={"Event Type"} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                      <div className="col-6 p-4 rounded-3 border">
                        <h5>Source Country</h5>
                        {sourceCountryLabel.length || sourceCountryData.length !== 0 ? (
                          // <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} ChartTitle={"Source Country"} />
                          <span className="modal-text">
                            <Chart
                              chartEvents={[
                                {
                                  eventName: "select",
                                  callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart();
                                    const selection = chart.getSelection();
                                    if (selection.length === 0) return;
                                    const region = geoSourceChart[selection[0].row + 1];

                                  },
                                },
                              ]}
                              chartType="GeoChart"
                              width="100%"
                              height="300px"
                              data={geoSourceChart}
                            />
                          </span>
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>

                      <div className="flex-fill p-4 rounded-3 border" >
                        <h5>Destination Country</h5>
                        {destinationCountryLabel.length || destinationCountryData.length !== 0 ? (
                          // <BarGraph ChartLabel={destinationCountryLabel} ChartData={destinationCountryData} ChartTitle={"Destination Country"} />
                          <span className="modal-text">
                            <Chart
                              chartEvents={[
                                {
                                  eventName: "select",
                                  callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart();
                                    const selection = chart.getSelection();
                                    if (selection.length === 0) return;
                                    const region = geoDestinationChart[selection[0].row + 1];

                                  },
                                },
                              ]}
                              chartType="GeoChart"
                              width="100%"
                              height="300px"
                              data={geoDestinationChart}
                            />
                          </span>
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                      <div className="col-6 p-4 rounded-3 border" >
                        <h5>Logged Users</h5>
                        {loggedUserLabel.length || loggedUserData.length !== 0 ? (
                          <BarGraph ChartLabel={loggedUserLabel} ChartData={loggedUserData} ChartTitle={"Logged Users"} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }

              {tableViewEnabled &&
                <div className="col-12">
                  <FilterList
                    filter={filter}
                    setFilter={setFilter}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    getFilteredTable={getFilteredTable}
                    module={module}
                    tab={tab}
                  />
                  <div className="px-3" style={styles.tableWrap}>
                    {loadTableData === true ? (
                      <TableLoader rowCount={30} />
                    ) : (
                      <Table borderless hover>
                        <thead className="fixed-head">
                          <tr>
                            <th>Event Category</th>
                            <th>Event Type</th>
                            <th>Destination IP</th>
                            <th>Destination Port</th>
                            <th>Destination Country</th>
                            <th>Source IP</th>
                            <th>Source Port</th>
                            <th>Source Country</th>
                            <th>Date</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.length !== 0 ? (
                            <>
                              {tableData?.map((item, index) => (
                                <tr
                                  onMouseOver={() =>
                                    setShowInlineFilter({ show: true, index: index })
                                  }
                                  onMouseOut={() =>
                                    setShowInlineFilter({ show: false, index: index })
                                  }
                                >
                                  {/* <td className="font-monospace">{item._source?.dipv4}</td> */}
                                  <td className="font-monospace">{item._source?.cybEventCategory}</td>
                                  <td className="font-monospace">{item._source?.cybEventType}</td>
                                  <td
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    <span className="position-relative font-monospace">
                                      {item?._source?.dipv4}
                                      {showInlineFilter.show &&
                                        showInlineFilter.index === index && (
                                          <div className="inline-filter-wrap">
                                            <InlineFilter
                                              filter={filter}
                                              setFilter={setFilter}
                                              column={{
                                                value: "dipv4",
                                                label: "Device IP",
                                              }}
                                              value={item?._source.dipv4}
                                              getFilteredTable={getFilteredTable}
                                              module={module}
                                              tab={tab}
                                              dateFilter={dateFilter}

                                            />
                                          </div>
                                        )}
                                    </span>
                                  </td>
                                  <td className="font-monospace">{item._source?.dport}</td>
                                  <td className="font-monospace">{item._source?.cybDestCountry}</td>
                                  {/* <td className="font-monospace">{item._source?.sipv4}</td> */}
                                  <td
                                    style={{ display: "flex", flexDirection: "row" }}
                                  >
                                    <span className="position-relative font-monospace">
                                      {item?._source?.sipv4}
                                      {showInlineFilter.show &&
                                        showInlineFilter.index === index && (
                                          <div className="inline-filter-wrap">
                                            <InlineFilter
                                              filter={filter}
                                              setFilter={setFilter}
                                              column={{
                                                value: "sipv4",
                                                label: "Source IP",
                                              }}
                                              value={item?._source.sipv4}
                                              getFilteredTable={getFilteredTable}
                                              module={module}
                                              tab={tab}
                                              dateFilter={dateFilter}

                                            />
                                          </div>
                                        )}
                                    </span>
                                  </td>
                                  <td className="font-monospace">{item._source?.sport}</td>
                                  <td className="font-monospace">{item._source?.cybSourceCountry}</td>
                                  <td className="font-monospace">{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                  <td className="font-monospace">{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              }
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default CykitInvestigation