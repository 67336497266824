import CustomModal from "../../components/CustomModal/CustomModal";
import { Amplify, Auth } from "aws-amplify";
import { useState } from "react";
import { toast } from "react-toastify";
import { Form,Col,Row, Button,Alert } from "react-bootstrap";
import { Info } from "phosphor-react";
import CustomToolTip from "../ToolTip/CustomToolTip";
const hostname = window.location.hostname


if (
    hostname.endsWith('.active-bytes.com') ||
    hostname === 'active-bytes.com' ||
    hostname === 'localhost'
  ) {
    var amplifyConfig = {
      Auth: {
        userPoolId: process.env.REACT_APP_USER_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
      },
    }
  }
  else {
    var amplifyConfig = {
      Auth: {
        userPoolId: process.env.REACT_APP_USER_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN,
  
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        } 
      },
    }
  }
  
 Amplify.configure(amplifyConfig);
const ChangePassword = ({showChangePass, setShowChangePass})=>{
    const [currentPassword,setCurrentPassword] = useState("");
    const [newPassword,setNewPassword] = useState("");
    const [confirmNewPassword,setConfirmNewPassword] = useState("");
    const regexPattern = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z])(?=.*[a-z]).{12,}$/;;
    function validatePassword(password) {
      // Check if the password is at least 12 characters long.
      if (password.length < 12) {
        return false;
      }
    
      // Check if the password contains at least one number.
      if (!/\d/.test(password)) {
        return false;
      }
    
      // Check if the password contains at least one special character.
      if (!/[^a-zA-Z0-9]/.test(password)) {
        return false;
      }
    
      // Check if the password contains at least one uppercase letter.
      if (!/[A-Z]/.test(password)) {
        return false;
      }
    
      // Check if the password contains at least one lowercase letter.
      if (!/[a-z]/.test(password)) {
        return false;
      }
    
      // If all of the requirements are met, return true.
      return true;
    }
    
    const handleChangeSubmit = async(e)=>{
        e.preventDefault();
    
        if (newPassword !== confirmNewPassword) {
          toast.error('New passwords do not match.'
          , {
            position: "top-center",
            autoClose: 4000,

          });
        }else if(newPassword !== confirmNewPassword){
          toast.error('New passwords do not match.'
          , {
              position: "top-center",
              autoClose: 4000,

            });
        }
        else if(!validatePassword(newPassword)){
            toast.error('New Password Should satisfy Password Policy.'
          , {
              position: "top-center",
              autoClose: 4000,

            });
        }
        else {
          try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, currentPassword, newPassword).then((res) => {
              console.log(res);
              toast.success("Password Changed Successfully", {
                position: "top-center",
                autoClose: 4000,

              })
            }).catch((err,data) => {
              if(err?.message==="Incorrect username or password.")
              {
                toast.error("Current User Password is Incorrect", {
                  position: "top-center",
                  autoClose: 4000,
                })
              }
              else{
                toast.error(err.message, {
                  position: "top-center",
                  autoClose: 4000,

                })
              }
              
              console.log("Error", err)
            })
            } catch(err) {
            console.log(err);
          }

        }
      }
      const checkNewPassword = ()=>{
        var val= false

        if(newPassword?.length>0){
          if(validatePassword(newPassword)&&(currentPassword!==newPassword)){
            val= false
        }
        else{
          val= true
        }
      }
        else{
          val= ""
        }
        return val
      }
      const checkConfirmPassword = ()=>{
        var val= false
        if(confirmNewPassword?.length>0){
          if(validatePassword(confirmNewPassword)&&(confirmNewPassword===newPassword)){
            val= false
        }
        else{
          val= true
        }
      }
        else{
          val= ""
        }
        
        return val
      }
      function renderModal(){
        return <Form className="p-4" onSubmit={handleChangeSubmit}>
        <Form.Group  className="mb-3" controlId="formPlaintextPassword">
            <Form.Label >
            Current Password:
            </Form.Label>
            <Form.Control required type="password" placeholder="Password" onChange={(e)=>setCurrentPassword(e.target.value)} />
      </Form.Group>
        <Form.Group  className="mb-3" controlId="formPlaintextPassword">
            <Form.Label >
            New Password:<CustomToolTip content={<Info size={16} />} dataPlacement={"bottom"}
                                dataToggle={
                                    <div className="d-flex flex-column justify-text">
                                                <span>Password Minimum Length Should be 12</span>
                                                <span>Contains at least 1 number</span>
                                                <span>Contains at least 1 special character</span>
                                                <span>Contains at least 1 lowercase letter</span>
                                                <span>Contains at least 1 uppercase letter</span>
                                    </div>
                                } /> 
            </Form.Label>
            <Form.Control required type="password" placeholder="Password" minLength={12} onChange={(e)=>setNewPassword(e.target.value)} isInvalid={newPassword.length===0?"":checkNewPassword()} isValid={newPassword.length===0?"":!checkNewPassword()}/>
            <Form.Control.Feedback type="invalid">
                {!validatePassword(newPassword)?<ul>
                  <li>Minimum Password Length should be 12 </li> 
                  <li>Contains at least 1 number </li> 
                  <li>Contains at least 1 special character </li> 
                  <li>Contains at least 1 uppercase letter </li> 
                 <li>Contains at least 1 lowercase letter"</li> 
                 </ul>:!(currentPassword!==newPassword)?"Current password and New Password Cant be Same":""}
            </Form.Control.Feedback>
      </Form.Group>
      
        <Form.Group className="mb-3" controlId="formPlaintextPassword">
            <Form.Label >
            Confirm New Password:
            </Form.Label>
            <Form.Control required type="password" placeholder="Password" minLength={12} onChange={(e)=>setConfirmNewPassword(e.target.value)} isValid={confirmNewPassword.length===0?"":!checkConfirmPassword()} isInvalid={confirmNewPassword.length===0?"":checkConfirmPassword()}/>
            <Form.Control.Feedback type="invalid">
                {!validatePassword(confirmNewPassword)?
                <ul>
                  <li>Minimum Password Length should be 12 </li> 
                  <li>Contains at least 1 number </li> 
                  <li>Contains at least 1 special character </li> 
                  <li>Contains at least 1 uppercase letter </li> 
                 <li>Contains at least 1 lowercase letter"</li> 
                 </ul>:(confirmNewPassword!==newPassword)?"Password Should be Same":""}
            </Form.Control.Feedback>
      </Form.Group>
        <div className="d-flex justify-content-end">
            <Button type="submit" >Update</Button>
        </div>
      </Form>
    }
        function handleCloseModal() {
            setShowChangePass(false);
        }
    return(
        <CustomModal 
            title={"Change Password"}
            ShowOrHide={showChangePass}
            bodyData={renderModal}
            handleClose={handleCloseModal}
            footer={false}
            noBodyPadding={true}
            modalSize="md"
            autoHeight={true}
        />
    )
}
export default ChangePassword