import React from 'react'
import Skeleton from 'react-loading-skeleton'
import '../../index.css';

const PieChartLoader = () => {
    return (
        <div className="pie-chart-loader">
          <Skeleton circle={true} height={300} width={300} />
          {/* You can customize the size and appearance of the circle based on your design */}
        </div>
      );
}

export default PieChartLoader