import React, { useEffect, useState, useRef } from "react";

import * as htmlToImage from 'html-to-image';
import { useDispatch, useSelector } from "react-redux";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { ArrowLeft, ArrowRight } from "phosphor-react";
import { Button, Form, input } from "react-bootstrap";
import {
  getSecurityEvents,
  getSecurityEventsDirect,
  getVulnerCount,
  convertTimestamp,
  convertTimestampVulEx,
  getHeadlines,
  getHeadlinesDirect,
  getVulnerabilities,
  getVulnerabilitiesDirect,
  getVulnerabilitesEx,
  getVulnerabilitesExDirect

} from "../../../Methods/TiMethods";
import "../../../index.css";
// import headerStyles from "./Activebytes.styles";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import {
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from "../../../Utils/Utils";
import moment from "moment";
import PaginationTi from "../../../components/Pagination/PaginationTi";
import HeadLines from "./Tabs/HeadLines";
import Vulnerabilities from "./Tabs/Vulnerabilities";
import VulnerabilitiesExploit from "./Tabs/VulnerabilitiesExploit";
import SecurityEvents from "./Tabs/SecurityEvents";
import Severity from "../../../components/Severity/Severity";

import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { useReactToPrint } from "react-to-print";
import ReactPDF from '@react-pdf/renderer';

import { saveAs } from 'file-saver';


import cybotLogoBlack from "../../../assets/images/cybot_logo_black.png";

import '../Ti.css';


import SearchTi from "../../../components/Search/SearchTi";
import HeadlinesSearch from "../../../components/Search/TI/HeadlinesSearch";
import VulnerabilitiesSearch from "../../../components/Search/TI/VulnerabilitiesSearch";
import VulExploitSearch from "../../../components/Search/TI/VulExploitSearch";
import SecurityEventsSearch from "../../../components/Search/TI/SecurityEventsSearch";


import DetailPageSe from "../../../components/PDF/Ti/DetailPageSe";
import DetailPageVul from "../../../components/PDF/Ti/DetailPageVul";

import EditModal from "../../../components/CaseManagement/EditModal";
import AlertBox from "../../../components/AlertBox/AlertBox";



// init geo chart
// export const geoData = [
//   // ["country"],
// ];

const Activebytes = ({
  selectedDateFilter,
  setSelectedDateFilter,
  setActiveTab,
  activeTab,
  setSearchVal,
  searchVal,
  globalSearch,
  setGlobalSearch,
  vulnerPage,
  setVulnerPage,
  vulnerExPage,
  setVulnerExPage,
  securityPage,
  setSecurityPage,
  headlinesPage,
  setHeadlinesPage,
  setVulnerabilitiesFilter,
  vulnerabilitiesFilter,
  vulnerabilityExploitFilter,
  setVulnerabilityExploitFilter,
  securityEvFilter,
  setSecurityEvFilter,
  sevChange,
  setSevChange,
  headlinesSearchQuery,
  setHeadlinesSearchQuery,
  VulnerSearchQuery,
  setVulnerSearchQuery,
  VulnerExpSearchQuery,
  setVulnerExpSearchQuery,
  securityEventsSearchQuery,
  setSecurityEventsSearchQuery,
  setIsApplyGlobalSearch,
  isApplyGlobalSearch
}) => {
  const dispatch = useDispatch();
  const divRefSecImage = useRef(null);

  const Headlineslist = useSelector((state) => state.Headlineslist);
  const {
    headlines,
    loading: headLoading,
    totalCount: headTotalCount,
  } = Headlineslist;


  const VulnerabilitiesList = useSelector((state) => state.VulnerabilitiesList);
  const { vulCounts } = VulnerabilitiesList;


  const VulnerabilitiesEXlist = useSelector(
    (state) => state.VulnerabilitiesEXlist
  );
  const {
    exVulCounts
  } = VulnerabilitiesEXlist;

  const SecurityEventsList = useSelector((state) => state.SecurityEventsList);
  const {
    securityEvents,
    secCounts,
    loading: secEventsLoading,
  } = SecurityEventsList;

  const componentPDF = useRef(null);

  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;


  const [headlineLocalSearchQuery, setHeadlineLocalSearchQuery] = useState('');
  const [vulnerabilitiesLocalSearchQuery, setVulnerabilitiesLocalSearchQuery] = useState('');
  const [vulnerabilitiesExLocalSearchQuery, setVulnerabilitiesExLocalSearchQuery] = useState('');
  const [securityEventsLocalSearchQuery, setSecurityEventsLocalSearchQuery] = useState('');


  const [vulnerabilitiesData, setVulnerabilitiesData] = useState([]);
  const [vulnerabilitiesExData, setVulnerabilitiesEXData] = useState([]);
  const [securityEventsData, setsecurityEventsData] = useState([]);

  const [vulnerabilitiesCount, setVulnerabilitiesCount] = useState(0);
  const [vulnerabilitiesExCount, setVulnerabilitiesExCount] = useState();
  const [securityCount, setSecurityCount] = useState();

  const [vulnerSeverityCount, setVulnerSeverityCount] = useState([]);
  const [securitySeverityCounts, setSecuritySeverityCounts] = useState([]);
  const [vulnerExSeverityCount, setVulnerExSeverityCount] = useState([]);





  // const [newheadlines, setHeadlines] = useState([]);
  //  const [time, setTime] = useState([]);
  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  // const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [prevCount, setPrevCount] = useState();

  // modal states
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState([]);

  // pagination

  // const [vulnerPage, setVulnerPage] = useState(1);
  const [VulnerPerPage, setVulnerPerPage] = useState(100);
  const [vulnerPageCount, setVulnerPageCount] = useState(VulnerPerPage);
  const [VulnerPrevCount, setVulnerPrevCount] = useState();

  // const [vulnerExPage, setVulnerExPage] = useState(1);
  const [VulnerExPerPage, setVulnerExPerPage] = useState(100);
  const [vulnerExPageCount, setVulnerExPageCount] = useState(VulnerExPerPage);
  const [VulnerExPrevCount, setVulnerExPrevCount] = useState();
  const [VulnerExCount, setVulnerExCount] = useState(0);

  // const [securityPage, setSecurityPage] = useState(1);
  const [securityPerPage, setSecurityPerPage] = useState(100);
  const [securityPageCount, setSecurityPageCount] = useState(securityPerPage);
  const [securityPrevCount, setSecurityPrevCount] = useState();

  // const [headlinesPage, setHeadlinesPage] = useState(1);
  const [headlinesPerPage, setHeadlinesPerPage] = useState(100);
  const [eventName, setEventName] = useState("");
  const [headlinesPageCount, setHeadlinesPageCount] =
    useState(headlinesPerPage);
  const [headlinesPrevCount, setHeadlinesPrevCount] = useState();

  const [imageBlob, setImageBlob] = useState(null);
  const [showDownloadButton, setShowDownloadButton] = useState(true);
  const [query, setQuery] = useState("");



  const [isClearHeadlineLocalSearch, setIsClearHeadlineLocalSearch] = useState(false);
  const [isClearVlLocalSearch, setIsClearVlLocalSearch] = useState(false);
  const [isClearVlExpLocalSearch, setIsClearVlExpLocalSearch] = useState(false);
  const [isClearSecLocalSearch, setIsClearSecLocalSearch] = useState(false);

  const [headlinesData, setHeadlinesData] = useState([]);
  const [headlinesTotalCount, setHeadlinesTotalCount] = useState(0);

  const [vulData, setVulData] = useState([]);
  const [VulTotalCount, setVulTotalCount] = useState(0);

  const [vulExpData, setVulExpData] = useState([]);
  const [VulExpTotalCount, setVulExpTotalCount] = useState(0);


  const [secData, setSecData] = useState([]);
  const [secTotalCount, setSecTotalCount] = useState(0);


  const [timeoutLoader, setTimeoutLoader] = useState(false);

  const currentTimestamp = Date.now();

  // Add Case
  const [caseShow, setCaseShow] = useState(false)
  const [case_id, setCase_ID] = useState("");

  const module = 'ti'
  const tab = 'security_events'

  // case modal

  const [alertCasePopup, setAlertCasePopup] = useState(false)


  const convertToBlob = () => {

    htmlToImage.toBlob(divRefSecImage.current).then((blob) => {

      setImageBlob(blob);
      setShowDownloadButton(true)
    });
  };


  const FormatURL = Array.from(new Set(showMore?.reference_link));

  useEffect(() => {
    console.log("GEODATA:",geoData);

    setShowDownloadButton(false)
    const timeoutId = setTimeout(() => {
      // Code to be executed after the timeout
      if (show === true) {
        convertToBlob()
      }
    }, 1000); // Timeout duration in milliseconds (2 seconds)

    return () => {
      clearTimeout(timeoutId); // Clean up the timeout on component unmount
    };

  }, [showMore])



  useEffect(() => {
    if (query === "") {
      setQuery(globalSearch);
    }
    else {
      setQuery(query);
    }
  }, [query])

  useEffect(() => {

    if (vulnerabilitiesFilter) {
      setVulnerPage(1)
    }
    if (vulnerabilityExploitFilter) {
      setVulnerExPage(1);
    }
    if (securityEvFilter) {
      setSecurityPage(1)
    }

  }, [vulnerabilitiesFilter, vulnerabilityExploitFilter, securityEvFilter])


  useEffect(() => {

    if (sevChange === true) {
      setVulnerabilityExploitFilter("")
      setSecurityEvFilter("")
      setVulnerabilitiesFilter("")

    }
  }, [sevChange])

  const setFilterData = (filter) => {
    let filterData = "";
    if (filter === "1 Day") {
      filterData = "now-1d/d";
    } else if (filter === "7 Days") {
      filterData = "now-7d/d";
    } else if (filter === "30 Days") {
      filterData = "now-30d/d";
    } else {
      filterData = "now-1y/d";
    }

    return filterData;
  };


  console.log(headlineLocalSearchQuery)

  // Headlines
  const getHeadlinesData = (searchData) => {

    setHeadlineLocalSearchQuery(searchData)

    let sevFilter = setFilterData(selectedDateFilter);

    getHeadlinesDirect(headlinesPerPage,
      "desc",
      (headlinesPage - 1) * headlinesPerPage,
      searchData,
      sevFilter).then((res) => {
        setHeadlinesTotalCount(res?.data?.hits?.total?.value)
        setHeadlinesData(res?.data?.hits?.hits)
      })
  }



  useEffect(() => {


    getHeadlinesData(headlinesSearchQuery)




  }, [globalSearch, selectedDateFilter, headlinesPage, isClearHeadlineLocalSearch]);




  // getVulnerabilities 
  const getVulnerData = (searchData) => {

    let sevFilter = setFilterData(selectedDateFilter);

    setVulnerabilitiesLocalSearchQuery(searchData)




    getVulnerabilitiesDirect(VulnerPerPage,
      "desc",
      (vulnerPage - 1) * VulnerPerPage,
      vulnerabilitiesFilter,
      searchData,
      sevFilter).then((res) => {

        setVulTotalCount(res?.data?.hits?.total?.value)
        setVulData(res?.data?.hits?.hits)
      })

  }
  useEffect(() => {
    // setTimeout(() => {

    getVulnerData(VulnerSearchQuery)
    // }, 2000);


  }, [globalSearch, selectedDateFilter, vulnerPage, isClearVlLocalSearch, vulnerabilitiesFilter]);


  // getVulnerabilities Exploit
  const getVulnerExpData = (searchData) => {
    let sevFilter = setFilterData(selectedDateFilter);

    setVulnerabilitiesExLocalSearchQuery(searchData)
    // setIsClearVlExpLocalSearch(searchData)

    getVulnerabilitesExDirect(
      VulnerExPerPage,
      "desc",
      (vulnerExPage - 1) * VulnerExPerPage,
      vulnerabilityExploitFilter,
      searchData,
      sevFilter
    ).then((res) => {
      setVulExpTotalCount(res?.data?.hits?.total?.value)
      setVulExpData(res?.data?.hits?.hits)
    })

  }
  useEffect(() => {

    getVulnerExpData(VulnerExpSearchQuery)



  }, [globalSearch, selectedDateFilter, vulnerExPage, vulnerabilityExploitFilter, isClearVlExpLocalSearch]);


  // Security Events
  const getSecEventsData = (searchData) => {
    let sevFilter = setFilterData(selectedDateFilter);
    setSecurityEventsLocalSearchQuery(searchData)

    getSecurityEventsDirect(
      securityPerPage,
      "desc",
      (securityPage - 1) * securityPerPage,
      securityEvFilter,
      searchData,
      sevFilter
    ).then((res) => {
      setSecTotalCount(res?.data?.hits?.total?.value)
      setSecData(res?.data?.hits?.hits)
    })

  }
  useEffect(() => {
    //   setTimeout(() => {
    getSecEventsData(securityEventsSearchQuery)
    //   }, 2000);


  }, [globalSearch, selectedDateFilter, securityEvFilter, securityPage, isClearSecLocalSearch])



  useEffect(() => {
    setTimeoutLoader(true)

    setTimeout(() => {


      getSecEventsData(securityEventsSearchQuery)
      getHeadlinesData(headlinesSearchQuery)
      getVulnerData(VulnerSearchQuery)
      getVulnerExpData(VulnerExpSearchQuery)
      setTimeoutLoader(false)
    }, 2000);



  }, [isApplyGlobalSearch, headlinesSearchQuery, headlinesSearchQuery, VulnerSearchQuery, VulnerExpSearchQuery, securityEventsSearchQuery])


  // case
  useEffect(() => {

    if (caseCreateSuccess === true) {

      setCaseShow(false)
      setAlertCasePopup(true)

    }

  }, [caseCreateSuccess])



  const setCounts = () => {
    setSecuritySeverityCounts({
      low: securityEvents.filter((item) => item._source.severity === "Low")
        .length,
      medium: securityEvents.filter(
        (item) => item._source.severity === "Medium"
      ).length,
      high: securityEvents.filter((item) => item._source.severity === "High")
        .length,
      critical: securityEvents.filter(
        (item) => item._source.severity === "Critical"
      ).length,
    });
  };




  // Add Case
  const handleCreate = () => {
   
    setCaseShow(true);
    console.log(caseShow);
  };





  const handleOpenModal = (data, display, eveName) => {
    // google geo chart data
    // data?.location && geoData.push(data?.location && [data?.location].map((item) => [item]))
    

    setShowMore(data); 
    setShow(display);
    setEventName(eveName);
  };

  function handleCloseModal() {
    setShow(false);
  }


  // let geoData = [];
  // geoData.push(['XX'])
  // let locations = showMore?.location?.split(" ,");
  // locations?.forEach((location) => {
  //   geoData.push([location]);
  // });
  let geoData = [];
  let locations = showMore?.location?.split(" ,");

if (locations && locations.length === 1) {
  locations = locations[0].split(",");
}

locations?.forEach((location) => {
  geoData.push([location.trim()]); 
});



 
  const renderModal = () => {

    if (eventName === "vulnerabilities") {
      let baseSeverity = showMore?.cve?.metrics?.cvssMetricV31 && showMore?.cve?.metrics?.cvssMetricV31[0]?.cvssData?.baseSeverity
      return (
        <>
          <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">
            {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
            <PDFDownloadLink document={<DetailPageVul showMore={showMore} baseSeverity={baseSeverity} />} fileName={`vulnerabilities-${currentTimestamp}.pdf`} type="button">
              {({ blob, url, loading, error }) =>
                (<button className="form-control  btn btn-primary">Download PDF</button>)
              }
            </PDFDownloadLink>
          </div>
          <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
            <div id="printable_header"  >
              <div className="d-flex">
                <div className="p-3"><img className="logo-pdf-header" src={cybotLogoBlack} /></div>
              </div>
              <hr />
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">CVE ID</span>
              <span className="modal-text">{showMore.cve?.id}</span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Severity</span>
              <span className="modal-text">

                {baseSeverity == "CRITICAL" && (
                  <div className="p-2 m-0 alert alert-danger">Critical</div>
                )}
                {baseSeverity == "HIGH" && (
                  <div className="p-2 m-0 alert alert-danger">High</div>
                )}
                {baseSeverity == "MEDIUM" && (
                  <div className="p-2 m-0 alert alert-warning">Medium</div>
                )}
                {baseSeverity == "LOW" && (
                  <div className="p-2 m-0 alert alert-success">Low</div>
                )}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Date</span>
              <span className="modal-text">
                {getDateFromTimestamp(showMore?.cve?.published)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Time</span>
              <span className="modal-text">
                {getTimeFromTimestamp(showMore?.cve?.published)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Description</span>
              <span className="modal-text">
                {showMore.cve?.descriptions[0].value}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Vulnurability status</span>
              <span className="modal-text">{showMore.cve?.vulnStatus}</span>
            </div>
            {showMore?.cve?.references?.length != 0 ? (
              <div className="modal-row-item">
                <span className="modal-label col-3">Reference links</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {showMore?.cve?.references?.map((item) => (
                    <Link to={item.url} target="_blank" style={{ color: "blue" }}>
                      {item.url}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <a href={showMore.cve?.references.map((item) => item)}>Link</a> */}
          </div>
        </>
      );
    } else {

      // geoData.push(['TZ'], ['IN'])
      return (
        
        <div className="col-12">
          <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">
            {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
            {/* {showDownloadButton === true ? */}
            <button className="btn btn-light"
                onClick={(e) => handleCreate()}
                 >Create Case
            </button>
            {console.log(showMore,"ihu")}
              &nbsp;&nbsp;&nbsp;

            <PDFDownloadLink document={<DetailPageSe showMore={showMore} geoData={geoData} imageBlob={imageBlob} />} fileName={`security-events-${currentTimestamp}.pdf`}>
              {({ blob, url, loading, error }) =>
                (<button className="form-control  btn btn-primary">Download PDF</button>)
              }
            </PDFDownloadLink>
            {/* : <button className="btn btn-primary" disabled>Export as PDF</button> */}
            {/* } */}
          </div>
          {console.log(showMore,"i")}
          <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
            <div className="col-12" id="printable_header"  >
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="">
                  <img className="logo-pdf-header" src={cybotLogoBlack} />
                </div>
                <div className="d-flex flex-column gap-2">
                  <span>
                    Date - <strong>{getDateFromTimestamp(new Date())}</strong>
                  </span>
                  <span>
                    Time - <strong>{getTimeFromTimestamp(new Date())}</strong>
                  </span>
                </div>
              </div>
              <hr />
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Event name</span>
              <span className="modal-text">{showMore?.event_name}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Event description</span>
              <span className="modal-text">{showMore?.event_description}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Severity</span>
              <span className="modal-text">
                {showMore?.severity == "Critical" && (
                  <div className="p-2 m-0 alert alert-danger">Critical</div>
                )}
                {showMore?.severity == "High" && (
                  <div className="p-2 m-0 alert alert-danger">High</div>
                )}
                {showMore?.severity == "Medium" && (
                  <div className="p-2 m-0 alert alert-warning">Medium</div>
                )}
                {showMore?.severity == "Low" && (
                  <div className="p-2 m-0 alert alert-success">Low</div>
                )}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">TLP</span>
              <span className="modal-text">{showMore?.tlp}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert date</span>
              <span className="modal-text">{getDateFromTimestamp()}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert time</span>
              <span className="modal-text">{getTimeFromTimestamp()}</span>
            </div>
            {/* <div className="modal-row-item">
            <span className="modal-label col-3">Comments</span>
            <span className="modal-text">{showMore?.comments?.map((item) => item)}</span>
          </div> */}

            <div className="modal-row-item">
              <span className="modal-label col-3">Impact region</span>
              <span className="modal-text" ref={divRefSecImage}>
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geoData[selection[0].row + 1];
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="300px"
                  data={geoData}
                />
              </span>
            </div>

            {showMore?.comments?.length > 0 && (
              <div className="modal-row-item">
                <span className="modal-label col-3">Comments</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {showMore?.comments?.map((item) => (
                    <p className="p-3 rounded card">{item}</p>
                  ))}
                </div>
              </div>
            )}
            {FormatURL?.length > 0 && (
              <div className="modal-row-item">
                <span className="modal-label col-3">Reference links</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {FormatURL?.map((item) => (
                    <Link to={item} target="_blank"
                      style={{
                        marginBottom: "14px"
                      }}>
                      {item}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {/* <p>comments :{showMore?.comments?.map((item) => item)}</p> */}
            {/* <p>impact region :{showMore?.location}</p> */}
          </div>
          {alertCasePopup && (
              <AlertBox setAlertPopup={setAlertCasePopup} alertPopup={alertCasePopup} message={"Case created successfully"} bgColor={"lightGreen"} />
            )}

      {caseShow && (
        <EditModal
          setShow={setCaseShow}
          show={caseShow}
          autoHeight={false}
          caseidEdit={case_id}
          setCase_ID={setCase_ID}
          id={showMore?.event_name}
          idd={showMore?.event_name}
          module={module}
          tab={tab}
          preModule={"TI_SEC"}
          description_={showMore?.event_description}
          severity_={showMore?.severity}
        />
      )
    }

        </div>
      );
    }
  };





  const handleSearchQueryVulnerability = (val, e, action) => {
    setVulnerPage(1)
    setIsApplyGlobalSearch(false)
    setVulnerabilitiesLocalSearchQuery(val)

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      // setQuery(val);
      setVulnerSearchQuery(val)
      getVulnerData(val)
      setIsClearVlLocalSearch(true)

    }
  };



  const handleSearchQueryExploit = (val, e, action) => {

    setVulnerExPage(1)
    setIsApplyGlobalSearch(false)
    setVulnerabilitiesExLocalSearchQuery(val)

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      // setQuery(val);
      setVulnerExpSearchQuery(val)
      getVulnerExpData(val)
      setIsClearVlExpLocalSearch(true)

    }
  };

  const handleSearchQuerySecurityEV = (val, e, action) => {
    setSecurityPage(1)
    setIsApplyGlobalSearch(false)
    setSecurityEventsLocalSearchQuery(val)
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      // setQuery(val);
      setSecurityEventsSearchQuery(val)
      getSecEventsData(val)
      setIsClearSecLocalSearch(true)

    }

  }


  const handleSearchQueryHeadLines = (val, e, action) => {

    setIsApplyGlobalSearch(false)
    setHeadlinesPage(1)
    setHeadlineLocalSearchQuery(val)

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      // setQuery(val);
      setHeadlinesSearchQuery(val)
      getHeadlinesData(val)
      setIsClearHeadlineLocalSearch(true)


    }
  };



  const handleTabSelect = (tabName) => {
    setActiveTab(tabName);
  };


  return (

    <>


      <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">
        <Tabs
          id="dynamic-tab-switching"
          activeKey={activeTab}
          onSelect={handleTabSelect}
          defaultActiveKey='headlines'
        >
          <Tab eventKey="headlines" title={<React.Fragment>
            Headlines
            <Badge className="ml-2" bg="info">
              {headlinesTotalCount ? headlinesTotalCount : 0}
            </Badge>
          </React.Fragment>}>
            <HeadLines headlines={headlinesData} headLoading={headLoading} timeoutLoader={timeoutLoader} />
            <div className="d-flex pagination-wrap">
              {" "}
              <div className="mx-3">
                <HeadlinesSearch changeFunction={handleSearchQueryHeadLines} setIsApplyGlobalSearch={setIsApplyGlobalSearch} isApplyGlobalSearch={isApplyGlobalSearch} searchData={globalSearch} searchFieldData={headlinesSearchQuery} module="ti" setSearchQuery={setHeadlinesSearchQuery} headlineLocalSearchQuery={headlineLocalSearchQuery} setHeadlineLocalSearchQuery={setHeadlineLocalSearchQuery} />
              </div>{" "}
              <div className="mx-3">
                <PaginationTi
                  page={headlinesPage}
                  setPage={setHeadlinesPage}
                  perPage={headlinesPerPage}
                  currentPageCount={vulnerExPageCount}
                  totalDataCounts={headlinesTotalCount}
                />
              </div>{" "}
            </div>
          </Tab>
          <Tab eventKey="vulnerabilities" title={
            <React.Fragment>
              Vulnerabilities
              <Badge className="ml-2" bg="info">
                {VulTotalCount ? VulTotalCount : 0}
              </Badge>
            </React.Fragment>
          }>
            <Vulnerabilities
              handleOpenModal={handleOpenModal}
              vulnerabilitiesFilter={vulnerabilitiesFilter}
              vulnerabilitiesSearch={query ? query : globalSearch}
              setVulnerabilitiesCount={setVulnerabilitiesCount}
              vulnerPage={vulnerPage}
              VulnerPerPage={VulnerPerPage}
              selectedDateFilter={selectedDateFilter}
              setFilterData={setFilterData}
              timeoutLoader={timeoutLoader}
              vulData={vulData}
              VulTotalCount={VulTotalCount}
            />

            <div className="d-flex pagination-wrap">
              <Severity
                filter={vulnerabilitiesFilter}
                setFilter={setVulnerabilitiesFilter}
                count={" "}
                setPage={setVulnerPage}
                setSevChange={setSevChange}
              />
              <div className="mx-2">
                <VulnerabilitiesSearch isApplyGlobalSearch={isApplyGlobalSearch}
                  setIsApplyGlobalSearch={setIsApplyGlobalSearch}
                  changeFunction={handleSearchQueryVulnerability}
                  searchData={globalSearch} searchFieldData={VulnerSearchQuery}
                  module="ti" setSearchQuery={setVulnerSearchQuery}
                  vulnerabilitiesLocalSearchQuery={vulnerabilitiesLocalSearchQuery}
                  setVulnerabilitiesLocalSearchQuery={setVulnerabilitiesLocalSearchQuery} />
              </div>

              <PaginationTi
                page={vulnerPage}
                setPage={setVulnerPage}
                perPage={VulnerPerPage}
                currentPageCount={vulnerPageCount}
                totalDataCounts={VulTotalCount}
              />
            </div>
          </Tab>
          <Tab eventKey="vulnerabilities_exploit" title={
            <React.Fragment>
              Vulnerabilities Exploit
              <Badge className="ml-2" bg="info">
                {VulExpTotalCount ? VulExpTotalCount : 0}
              </Badge>
            </React.Fragment>
          }>
            <VulnerabilitiesExploit
              handleOpenModal={handleOpenModal}
              vulnerabilityExploitFilter={vulnerabilityExploitFilter}
              setVulnerExSeverityCount={setVulnerExSeverityCount}
              setVulnerExCount={setVulnerExCount}
              vulnerExPage={vulnerExPage}
              VulnerExPerPage={VulnerExPerPage}
              vulnerabilityExploitSearch={query ? query : globalSearch}
              setFilterData={setFilterData}
              selectedDateFilter={selectedDateFilter}
              timeoutLoader={timeoutLoader}
              vulExpData={vulExpData}
              VulExpTotalCount={VulExpTotalCount}
            />
            <div className="d-flex pagination-wrap">
              {" "}
              <Severity
                filter={vulnerabilityExploitFilter}
                setFilter={setVulnerabilityExploitFilter}
                count={" "}
                setPage={setVulnerExPage}
                setSevChange={setSevChange}

              />
              <div className="mx-2">
                <VulExploitSearch isApplyGlobalSearch={isApplyGlobalSearch}
                  setIsApplyGlobalSearch={setIsApplyGlobalSearch}
                  changeFunction={handleSearchQueryExploit}
                  searchData={globalSearch}
                  searchFieldData={VulnerExpSearchQuery} module="ti"
                  setSearchQuery={setVulnerExpSearchQuery}
                  vulnerabilitiesExLocalSearchQuery={vulnerabilitiesExLocalSearchQuery}
                  setVulnerabilitiesExLocalSearchQuery={setVulnerabilitiesExLocalSearchQuery}
                />
              </div>

              <PaginationTi
                page={vulnerExPage}
                setPage={setVulnerExPage}
                perPage={VulnerExPerPage}
                currentPageCount={VulnerExPerPage}
                totalDataCounts={VulExpTotalCount}
              />{" "}
            </div>
          </Tab>
          <Tab eventKey="security_events" title={
            <React.Fragment>
              Security Events
              <Badge className="ml-2" bg="info">
                {secTotalCount ? secTotalCount : 0}
              </Badge>
            </React.Fragment>
          }>
            <SecurityEvents
              securityEvents={securityEvents}
              handleOpenModal={handleOpenModal}
              secEventsLoading={secEventsLoading}
              timeoutLoader={timeoutLoader}
              secData={secData}
              secTotalCount={secTotalCount}
            />
            <div className="d-flex pagination-wrap">
              {" "}
              <Severity
                filter={securityEvFilter}
                setFilter={setSecurityEvFilter}
                count={" "}
                setPage={setSecurityPage}
                setSevChange={setSevChange}

              />
              <div className="mx-2">
                <SecurityEventsSearch isApplyGlobalSearch={isApplyGlobalSearch}
                  setIsApplyGlobalSearch={setIsApplyGlobalSearch}
                  changeFunction={handleSearchQuerySecurityEV}
                  searchData={globalSearch} searchFieldData={securityEventsSearchQuery}
                  module="ti" setSearchQuery={setSecurityEventsSearchQuery}
                  securityEventsLocalSearchQuery={securityEventsLocalSearchQuery}
                  setSecurityEventsLocalSearchQuery={setSecurityEventsLocalSearchQuery}
                />
              </div>{" "}

              <PaginationTi
                page={securityPage}
                setPage={setSecurityPage}
                perPage={securityPerPage}
                currentPageCount={securityPageCount}
                totalDataCounts={secTotalCount}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      {show && (
        <CustomModal
          title={"Event Details"}
          ShowOrHide={show}
          modalSize={"lg"}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}
    </>
    // </>
  );

};

export default Activebytes;