import React from "react";
import Skeleton from "react-loading-skeleton";


const styles = {
  itemWrap: {
    height: "100px",
    borderLeft: "1px solid #ddd",
    marginLeft: "4px",
    marginRight: "14px",
  },
  dateTile: {
    width: "80px",
    height: "80px",
    marginRight: "10px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  dateTileLg: {
    width: "20%",
    height: "80px",
    marginRight: "10px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  contentTile: {
    width: "80%",
    height: "80px",
    borderRadius: "10px",
    overflow: "hidden",
  }
};
const TimelineLoader = ({ count = 8 }) => {

  return (
    <div className="col-12">{[...Array(count)].map((e, i) => {
      return <div className="single-skelt" style={{ display: "flex" }} key={"timeline_loader_item_" + i}>
        <div style={styles.dateTile} className="rounded-3">
          <Skeleton width={"100%"} height={"100%"} />
        </div>
        <div style={styles.itemWrap}></div>
        <div style={styles.contentTile}>
          <Skeleton width={"100%"} height={"100%"} />
        </div>
      </div>
    })}
    </div>
  );
};

const TimelineLoaderLg = () => {
  return (
    <div className="col-12">{[...Array(10)].map((e, i) => {
      return <div className="single-skelt" style={{ display: "flex" }} key={"timeline_loader_lg_item_" + i}>
        <div style={styles.dateTileLg} className="rounded-3">
          <Skeleton width={"100%"} height={"100%"} />
        </div>
        <div style={styles.itemWrap}></div>
        <div style={styles.contentTile}>
          <Skeleton width={"100%"} height={"100%"} />
        </div>
      </div>
    })}
    </div>
  )
};

export { TimelineLoader, TimelineLoaderLg };
