import {
  Binoculars,
  ChartPieSlice,
  Detective,
  Faders,
  FileSearch,
  GearSix,
  List,
  ListDashes,
  NewspaperClipping,
  Robot,
  RssSimple,
  SquaresFour,
  UserList,
  UsersFour,
  WarningOctagon,
  CaretDown,
  CaretUp,
  ShieldStar,
  GlobeHemisphereWest,
  BookmarkSimple,
  Fingerprint,
  BellRinging,
  IdentificationBadge,
  CalendarBlank,
  Heartbeat,
  Users,
  ListBullets,
  LinkSimple,
  Stack
} from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.styles";
import cybotLogo from "../../assets/images/cybotlogo.svg";
import cybotLogoSquare from "../../assets/images/logo-square.png";
import { ToastContainer } from 'react-toastify';

import { fget, fpost } from "../../axios/axios";
import globalStyles from "../../constants/globalStyles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Sidebar = ({ module, settingsMenu, setSettingsMenu }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const [currentHash, setCurrentHash] = useState("");

  const [permission, setPermission] = useState(JSON.parse(localStorage.getItem("accessVars")));
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  // const getPermission = async () => {
  //   try {
  //     const response = await fget(
  //       // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
  //       `${apiBaseUrl}/core/userpermissions`
  //     );

  //     setPermission(response?.data);

  //   } catch (error) { }
  // };
  // useMemo(() => {
  //   getPermission();
  // }, []);
  const hostname = window.location.hostname

  useEffect(() => {
    const currentLocation = location.pathname.split("/")[2];
    setCurrentHash(location.hash)
    setCurrentPath(currentLocation);
  }, [location])
  // sub menu

  const [showMenu, setShowMenu] = useState(false);
  const [show365Menu, setShow365Menu] = useState(false);
  const [showMenuRuleBooks, setShowMenuRuleBooks] = useState(false);

  const [showMenuDashboard, setShowMenuDashboard] = useState(false);

  console.log('localStorage.getItem', localStorage.getItem("isSidebarCollapsed"))
  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isSidebarCollapsed')) ? JSON.parse(localStorage.getItem('isSidebarCollapsed')) : true)


  useEffect(() => {
    // localStorage.getItem("isSidebarCollapsed") ? localStorage.getItem("isSidebarCollapsed") : true
    const x = JSON.parse(localStorage.getItem('isSidebarCollapsed'));
    console.log('x = ', x)
    if (x) {
      setIsCollapsed(x);
    }
    else {
      setIsCollapsed(false)
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('isSidebarCollapsed', isCollapsed)
  }, [isCollapsed])

  const getPermission = async () => {
    try {
      const response = await fget(
        // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
        `https://api-gw1.active-bytes.com/api/v2/core/userpermissions`
      );

      setPermission(response?.data);
    } catch (error) { }
  };

  // const handleSidebarToggle = () => {
  //   console.log('handleSidebarToggle')
  //   console.log('isCollapsed', isCollapsed)
  //   setIsCollapsed(!isCollapsed)
  //   localStorage.setItem("isSidebarCollapsed", isCollapsed);
  // };


  const checkInvestigate = () => {
    var path = ""
    for (const item of permission?.modules?.investigate?.child) {
      if (item.name === "cykit-firewall" && item.read === true) {
        path = "firewall";
        break;
      } if (item.name === "cykit-waf" && item.read === true) {
        path = "waf";
        break;
      } if (item.name === "cykit-active_directory" && item.read === true) {
        path = "active_directory";
        break;
      } if (item.name === "cykit-atp_alerts" && item.read === true) {
        path = "atp_alerts";
        break;
      } if (item.name === "cykit-email" && item.read === true) {
        path = "email";
        break;
      } if (item.name === "cykit-office" && item.read === true) {
        path = "o365_active_directory";
        break;
      }
    }
    return path
  }
  function Submenu({ props }) {

    return (
      <ul className="d-flex flex-column p-0 submenu mb-0">
        {props?.map((item) => (
          (item.show && <li>
            <OverlayTrigger placement="bottom" overlay={setTooltip(item?.name)}>
              <Link
                to={item.link}
                className="p-3 d-flex gap-3"
                // style={styles.menuItem}

                style={
                  currentPath === item.path
                    ? styles.activeMenuItem
                    : styles.menuItem
                }
                onClick={item?.name === "Office 365" ? handleClick365 : ""}

              // style={
              //   currentPath === "securityanalytics" ? styles.activeMenuItem : styles.menuItem
              // }
              >
                <label className="px-2" style={{ fontSize: "14px" }}>
                  {/* {item?.name !== 'Office 365' && item?.name.charAt(0)} */}
                  {item?.name.charAt(0)}
                </label>
                {item?.name === "Windows Events ( AD, Security , File Share )" ?
                  <span>Windows Events <br />( AD, Security , File Share )</span>
                  : <span>{item?.name}</span>}
                {item?.name === "Office 365" ? (
                  <p>
                    <CaretDown style={styles.arrowDown} />
                  </p>
                ) : (
                  ""
                )}
              </Link>
            </OverlayTrigger>
          </li>)
        ))}
      </ul>
    );
  }



  const handleClick = () => {
    setShowMenu(!showMenu);
    setShow365Menu(false);
  };

  // office 365 dropdown

  function O365SubMenu({ props }) {
    return (
      <ul className="d-flex flex-column p-0 overflow-auto submenu">
        {props?.map((item) => (
          <li>
            <OverlayTrigger placement="bottom" overlay={setTooltip(item?.name)}>
              <Link
                to={item.link}
                className="p-3 d-flex gap-3"
                // style={styles.menuItem}
                style={
                  currentPath === item.path
                    ? styles.activeMenuItem
                    : styles.menuItem
                }
              // onClick={() => window.location.href(item.link)}

              // style={
              //   currentPath === "securityanalytics" ? styles.activeMenuItem : styles.menuItem
              // }
              >
                <label className="px-2" style={{ fontSize: "14px" }}>
                  {item?.name.charAt(0)}
                </label>
                <span>{item?.name}</span>
              </Link>
            </OverlayTrigger>
          </li>
        ))}
      </ul>
    );
  }

  const handleClick365 = () => {
    setShow365Menu(!show365Menu);
  };

  // tooltip
  const setTooltip = (tooltipText) => {
    return (
      <Tooltip placement="right" id="tooltip" >
        <div className="text-justify ms-2">{tooltipText}</div>
      </Tooltip>
    )
  }

  // const data = module
  return (
    <div style={styles.sidebar} className={isCollapsed ? "col-2 d-flex flex-column px-3 sidebar collapsed" : "col-2 d-flex flex-column px-3 sidebar"}>
      <Link to={`/`}
        onClick={() => window.location.href = `/`}
      >
        <img
          src={isCollapsed ? cybotLogoSquare : cybotLogo}
          alt="Cybot Logo"
          style={styles.logo}
          className="mt-4 mb-5"
        />
      </Link>

      {/* sidebar menus */}
      {/* <div style={styles.menuList}> */}

      {module == "settings" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/authentication`}
            onClick={() => window.location.href = `/${module}/authentication`}
            className="p-3"
            style={
              currentPath === "authentication"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Authentication')}>
              <Fingerprint style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Authentication
            </span>
          </Link>
          <Link
            to={`/${module}/exception`}
            onClick={() => window.location.href = `/${module}/exception`}
            className="p-3"
            style={
              currentPath === "exception"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Exceptions')}>
              <ListBullets style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Exceptions
            </span>
          </Link>
          <Link
            to={`/${module}/integrations`}
            onClick={() => window.location.href = `/${module}/integrations`}
            className="p-3"
            style={
              currentPath === "integrations"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Integrations')}>
              <LinkSimple style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Integrations
            </span>
          </Link>
          {/* <Link
            to={`/${module}/notifications`}
            onClick={() => window.location.href(`/${module}/notifications`)}
            className="p-3"
            style={
              currentPath === "notifications"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Notifications')}>
              <BellRinging style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Notifications
            </span>
          </Link> */}
          <Link
            to={`/${module}/licenceinfo`}
            onClick={() => window.location.href = `/${module}/licenceinfo`}
            className="p-3"
            style={
              currentPath === "licenceinfo"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Licence Info')}>
              <IdentificationBadge style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Licence Info
            </span>
          </Link>
          <Link
            to={`/${module}/dateformat`}
            onClick={() => window.location.href = `/${module}/dateformat`}
            className="p-3"
            style={
              currentPath === "dateformat"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Date Format')}>
              <CalendarBlank style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Date Format
            </span>
          </Link>
          <Link
            to={`/${module}/health`}
            onClick={() => window.location.href = `/${module}/health`}
            className="p-3"
            style={
              currentPath === "health"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Log Ingestion Health')}>
              <Heartbeat style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Log Ingestion Health
            </span>
          </Link>
          {permission?.user_type === "super_admin" && <Link
            to={`/${module}/users`}
            onClick={() => window.location.href = `/${module}/users`}
            className="p-3"
            style={
              currentPath === "users"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Users')}>
              <Users style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Users
            </span>
          </Link>}
          {(permission?.user_type === "super_admin" && !hostname.endsWith('knpc.com')) && <Link
            to={`/${module}/logs`}
            onClick={() => window.location.href = `/${module}/logs`}
            className="p-3"
            style={
              currentPath === "logs"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Logs')}>
              <Stack style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>
              Logs
            </span>
          </Link>}
          {/* <Link
            to={`/${module}/health`}
            onClick={() => window.location.href(`/${module}/health`)}
            className="p-3"
            style={
              currentPath === "health"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            Log Ingestion Health
          </Link>
          {permission?.user_type==="super_admin"&&<Link
            to={`/${module}/users`}
            onClick={() => window.location.href(`/${module}/users`)}
            className="p-3"
            style={
              currentPath === "users"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            Users
          </Link>} */}
          {/* <Link
            to={`/${module}/filters`}
            className="p-3"
            style={
              currentPath === "filters"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            Filters
          </Link> */}
        </div>
      )}

      {module == "ueba" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/dashboard`}
            onClick={() => window.location.href = `/${module}/dashboard`}
            className="p-3"
            style={
              currentPath === "dashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Dashboard')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span>
          </Link>
          <Link
            to={`/${module}/detection`}
            onClick={() => window.location.href = `/${module}/detection`}
            className="p-3"
            style={
              currentPath === "detection"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Detections')}>
              <FileSearch style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Detections</span>
          </Link>
          <Link
            to={`/${module}/activities`}
            onClick={() => window.location.href = `/${module}/activities`}
            className="p-3"
            style={
              currentPath === "activities"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('CyKit - Manual Analysis')}>
              <UserList style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>CyKit - Manual Analysis</span>
          </Link>
          {/* <Link
            to={`/${module}/asset-discovery`}
            onClick={() => window.location.href(`/${module}/asset-discovery`)}
            className="p-3"
            style={
              currentPath === "asset-discovery"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <UsersFour style={styles.sidemenuIcon} />
            Asset Discovery
          </Link>

          <Link
            to={`/${module}/watchlist`}
            className="p-3"
            style={
              currentPath === "watchlist"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <BookmarkSimple style={styles.sidemenuIcon} />
            Watchlist
          </Link> */}
          <div></div>
        </div>
      )}

      {/* analytics sidebar start */}
      {module === "analytics" && (
        <div className="col-12 d-flex flex-column">
          {permission?.modules?.analytics?.child?.filter((item) => item.name === "security" && item.read === true).length > 0 && <Link
            to={`/${module}/securityanalytics`}
            onClick={() => window.location.href = `/${module}/securityanalytics`}
            className="p-3"
            style={
              currentPath === "securityanalytics"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Security Analytics')}>
              <ChartPieSlice style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Security Analytics</span>
          </Link>}

          {permission?.modules?.analytics?.child?.filter((item) => item.name === "ciso" && item.read === true).length > 0 && (
            <Link
              to={`/${module}/ciso`}
              onClick={() => window.location.href = `/${module}/ciso`}
              className="p-3"
              style={
                currentPath === "ciso"
                  ? styles.activeMenuItem
                  : styles.menuItem
              }
            >
              <OverlayTrigger placement="bottom" overlay={setTooltip('CISO')}>
                <ShieldStar style={styles.sidemenuIcon} />
              </OverlayTrigger>
              <span>CISO</span>
            </Link>
          )}

          {permission?.modules?.analytics?.child?.filter((item) => item.name === "mngmt" && item.read === true).length > 0 && (
            <Link
              to={`/${module}/management`}
              onClick={() => window.location.href = `/${module}/management`}
              className="p-3"
              style={
                currentPath === "management"
                  ? styles.activeMenuItem
                  : styles.menuItem
              }
            >
              <OverlayTrigger placement="bottom" overlay={setTooltip('Management')}>
                <Faders style={styles.sidemenuIcon} />
              </OverlayTrigger>
              <span>Management</span>
            </Link>
          )}

          <div></div>
        </div>
      )}

      {/* analytics Sidebar end */}

      {/* RS sidebar start */}
      {module === "rs" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/investigation`}
            onClick={() => { setShowMenuRuleBooks(!showMenuRuleBooks) }}
            className="p-3"
            style={

              styles.menuItem
            }

          >
            <OverlayTrigger placement="bottom" overlay={() => setTooltip('Rule Books')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Rule Books</span>
            <p>
              {showMenuRuleBooks ? (
                <CaretUp style={styles.arrowDown} />
              ) : (
                <CaretDown style={styles.arrowDown} />
              )}
            </p>
          </Link>
          {showMenuRuleBooks && (
            <div className="submenu">
              {permission?.modules?.rulesstudio?.child?.filter((item) => item.name === "investigate" && item.read === true).length > 0 &&
                <OverlayTrigger placement="bottom" overlay={setTooltip('Investigate')}>



                  <Link
                    to={`/${module}/investigation`}
                    // onClick={() => window.location.href(`/${module}/investigation`)}
                    className="p-3 d-flex gap-3"
                    style={
                      currentPath === "investigation"
                        ? styles.activeMenuItem
                        : styles.menuItem
                    }
                  >
                    <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      I
                    </label>
                    <span>Investigate</span>
                  </Link>
                </OverlayTrigger>
              }
              {permission?.modules?.rulesstudio?.child?.filter((item) => item.name === "hunter" && item.read === true).length > 0 &&
                <OverlayTrigger placement="bottom" overlay={setTooltip('Hunter')}>

                  <Link
                    to={`/${module}/hunter`}
                    // onClick={() => window.location.href(`/${module}/hunter`)}
                    className="p-3 d-flex gap-3"
                    style={
                      currentPath === "hunter" ? styles.activeMenuItem : styles.menuItem
                    }
                  >
                    <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      H
                    </label>
                    <span>Hunter</span>
                  </Link>
                </OverlayTrigger>

              }

              {permission?.modules?.rulesstudio?.child?.filter((item) => item.name === "compliance" && item.read === true).length > 0 &&
                <OverlayTrigger placement="bottom" overlay={setTooltip('Compliance')}>


                  <Link
                    to={`/${module}/compliance`}
                    // onClick={() => window.location.href(`/${module}/compliance`)}
                    className="p-3 d-flex gap-3"
                    style={
                      currentPath === "compliance"
                        ? styles.activeMenuItem
                        : styles.menuItem
                    }
                  >
                    <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      C
                    </label>
                    <span>Compliance</span>
                  </Link>
                </OverlayTrigger>
              }

              {permission?.modules?.rulesstudio?.child?.filter((item) => item.name === "ueba" && item.read === true).length > 0 &&
                <OverlayTrigger placement="bottom" overlay={setTooltip('UEBA')}>

                  <Link
                    to={`/${module}/rsueba`}
                    // onClick={() => window.location.href(`/${module}/rsueba`)}
                    className="p-3 d-flex gap-3"
                    style={
                      currentPath === "rsueba"
                        ? styles.activeMenuItem
                        : styles.menuItem
                    }
                  >
                    <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      U
                    </label>
                    <span>UEBA</span>
                  </Link>
                </OverlayTrigger>
              }


              {/* <Link
                to={`/${module}/rscorrelation`}
                // onClick={() => window.location.href(`/${module}/rsueba`)}
                  className="p-3 d-flex gap-3"
                style={
                  currentPath === "rscorrelation"
                    ? styles.activeMenuItem
                    : styles.menuItem
                }
              >
                <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                  C
                </label>
                <span>Correlation</span>
              </Link> */}
            </div>
          )}
        </div>
      )}

      {/* RS Sidebar end */}

      {/* INV sidebar start */}
      {module == "investigate" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/dashboard`}
            onClick={() => window.location.href = `/${module}/dashboard`}
            className="p-3"
            style={
              currentPath === "dashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Dashboard')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span>
          </Link>
          <Link
            to={`/${module}/cybot_soc`}
            onClick={() => window.location.href = `/${module}/cybot_soc`}
            className="p-3"
            style={
              currentPath === "cybot_soc"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Cybot SOC')}>
              <Binoculars style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Cybot SOC</span>
          </Link>
          {/* <Link
            to={`/${module}/correlation`}
            className="p-3"
            style={
              currentPath === "correlation"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <WarningOctagon style={styles.sidemenuIcon} />
            Correlation Studio
          </Link> */}
          {permission?.modules?.investigate?.child && <Link
            to={`/${module}/${checkInvestigate()}`}
            className="p-3"
            style={
              currentPath === "cykit" ? styles.activeMenuItem : styles.menuItem
            }
            onClick={() => { handleClick() }}
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('CyKit')}>
              <UserList style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>CyKit</span>

            <p>
              {showMenu ? (
                <CaretUp style={styles.arrowDown} />
              ) : (
                <CaretDown style={styles.arrowDown} />
              )}
            </p>
          </Link>}
          {showMenu && (
            <Submenu
              props={[
                {
                  name: "Firewall",
                  link: "/investigate/firewall",
                  path: "firewall",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-firewall" && item.read === true).length > 0 ? true : false
                },

                {
                  name: "Windows Events ( AD, Security , File Share )",
                  link: "/investigate/active_directory",
                  path: "active_directory",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-ad" && item.read === true).length > 0 ? true : false

                },
                {
                  name: "Email", link: "/investigate/email", path: "email",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-email" && item.read === true).length > 0 ? true : false
                },
                {
                  name: "WAF", link: "/investigate/waf", path: "waf",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-waf" && item.read === true).length > 0 ? true : false
                },
                {
                  name: "ATP Alerts", link: "/investigate/atp_alerts", path: "atp_alerts",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-atp_alerts" && item.read === true).length > 0 ? true : false
                },
                { name: "Email", link: "/investigate/email", path: "email" },
                {
                  name: "Software",
                  link: "/investigate/software",
                  path: "software",
                  show: true
                },
                {
                  name: "Vulnerabilities",
                  link: "/investigate/vulnerabilities",
                  path: "vulnerabilities",
                  show: true
                },
                // { name: "WAF", link: "/investigate/waf", path: "waf" },
                // { name: "ATP Alerts", link: "/investigate/atp_alerts", path: "atp_alerts" },
                {
                  name: "Office 365",
                  link: "/investigate/o365_active_directory",
                  path: "",
                  show: permission?.modules?.investigate?.child?.filter((item) => item.name === "cykit-office" && item.read === true).length > 0 ? true : false
                },
              ]}
            />
          )}
          {/* <Link
            to={`/${module}/o365_active_directory`}
            className="p-3"
            style={
              currentPath === "o365_active_directory"
                ? styles.activeMenuItem
                : styles.menuItem
            }
            onClick={handleClick365}
          >
            <UserList style={styles.sidemenuIcon} />
            Office 365
            {show365Menu ? <CaretUp style={styles.arrowDown} /> : <CaretDown style={styles.arrowDown} />}

          </Link> */}
          {show365Menu && (
            <O365SubMenu
              props={[
                {
                  name: "Active Directory",
                  link: "/investigate/o365_active_directory",
                  path: "o365_active_directory",
                },
                {
                  name: "One Drive",
                  link: "/investigate/o365_one_drive",
                  path: "o365_one_drive",
                },
                {
                  name: "Exchange",
                  link: "/investigate/o365_exchange",
                  path: "o365_exchange",
                },
              ]}
            />
          )}
          {/* "Firewall", "Active Directory", "Linux", "Email", "Waf" */}
          {/* { name: "Linux", link: '/investigate/linux' } */}
          <div></div>
        </div>
      )}

      {/* INV Sidebar end */}

      {/* INV Sidebar end */}

      {/* hunter sidebar start */}
      {module == "hunter" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/detections`}
            onClick={() => window.location.href = `/${module}/detections`}
            className="p-3"
            style={
              currentPath === "detections"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Detections')}>
              <Detective style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Detections</span>
          </Link>
          <Link
            to={`/${module}/cykit`}
            onClick={() => window.location.href = `/${module}/cykit`}
            className="p-3"
            style={
              currentPath === "cykit"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('CyKit')}>
              <UserList style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>CyKit</span>
          </Link>
          {permission?.modules?.hunter?.child?.filter((item) => item.name === "search" && item.read === true).length > 0 && <Link
            // to={`/search/app/data-explorer/discover`}
            onClick={() => window.open('/search/app/data-explorer/discover')}
            className="p-3"
            style={
              currentPath === "search"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Advanced Search')}>
              <UserList style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Advanced Search</span>
          </Link>}
          {permission?.modules?.hunter?.child?.filter((item) => item.name === "corelation" && item.read === true).length > 0 && <Link
            // to={`/search/app/data-explorer/discover`}
            onClick={() => window.open('/hunter/corelation')}
            className="p-3"
            style={
              currentPath === "search"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Correlation Studio')}>
              <UserList style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Correlation Studio</span>
          </Link>}
          <div></div>
        </div>
      )}

      {/* hunter Sidebar end */}

      {/* compliance sidebar start */}
      {module == "compliance" && (
        <div className="col-12 d-flex flex-column">
 {/* dash start */}
          <Link
            to={`/${module}/maindashboard`}
            onClick={() => window.location.href(`/${module}/maindashboard`)}
            className="p-3"
            style={
              currentPath === "maindashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Dashboard')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span>
          </Link>
{/* dash end */}

          {/* {permission?.modules?.compliance?.child?.filter((item) => item.name === "maindashboard" && item.read === true).length > 0 && <Link
            to={`/${module}/maindashboard`}
            onClick={() => window.location.href(`/${module}/maindashboard`)}
            className="p-3"
            style={
              currentPath === "maindashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Dashboard')}>
              <Faders style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span> 
       </Link>} */}


          {permission?.modules?.compliance?.child?.filter((item) => item.name === "controlview" && item.read === true).length > 0 && <Link
            to={`/${module}/controlview`}
            onClick={() => window.location.href = `/${module}/controlview`}
            className="p-3"
            style={
              currentPath === "controlview"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Control View')}>
              <Faders style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Control View</span>
          </Link>}
          {permission?.modules?.compliance?.child?.filter((item) => item.name === "alerts" && item.read === true).length > 0 && <Link
            to={`/${module}/alerts`}
            onClick={() => window.location.href = `/${module}/alerts`}
            className="p-3"
            style={
              currentPath === "alerts" ? styles.activeMenuItem : styles.menuItem
            }
          >

            <OverlayTrigger placement="bottom" overlay={setTooltip('Alerts')}>
              <WarningOctagon style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Alerts</span>
          </Link>}



          {permission?.modules?.compliance?.child?.filter((item) => item.name === "dashboard" && item.read === true).length > 0 && <Link
            // to={`/${module}/dashboard`}
            onClick={() => localStorage.getItem("COM_UI_URL",false)?window.open(localStorage.getItem("COM_UI_URL"), '_blank'):window.location.href = `/${module}/dashboard`}
            className="p-3"
            style={
              currentPath === "dashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Compliance Dashboards')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Compliance Dashboards</span>
          </Link>}
          <div></div>
        </div>
      )}

      {/* management dashboard sidebar start */}
      {module == "management" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/dashboard`}
            onClick={() => window.location.href = `/${module}/dashboard`}
            className="p-3"
            style={
              currentPath === "dashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('Dashboard')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span>
          </Link>


          <div></div>
        </div>
      )}
      {module == "threat_landscape" && (
        <div className="col-12 d-flex flex-column">
          <Link
            to={`/${module}/dashboard`}
            // onClick={() => window.location.href(`/${module}/dashboard`)}
            onClick={() => { setShowMenuDashboard(!showMenuDashboard) }}

            className="p-3"
            style={
              currentPath === "dashboard"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <OverlayTrigger placement="bottom" overlay={setTooltip('')}>
              <SquaresFour style={styles.sidemenuIcon} />
            </OverlayTrigger>
            <span>Dashboard</span>
            <p>
              {showMenuDashboard ? (
                <CaretUp style={styles.arrowDown} />
              ) : (
                <CaretDown style={styles.arrowDown} />
              )}
            </p>
          </Link>
          {showMenuDashboard && (
            <div className="submenu">
              <OverlayTrigger placement="bottom" overlay={setTooltip('')}>
                <Link
                  to={`/${module}/dashboard#etl`}
                  onClick={() => window.location.hash = `etl`}
                  className="p-3 d-flex gap-3"
                  style={
                    (currentHash === "#etl" || currentHash === "")
                      ? styles.activeMenuItem
                      : styles.menuItem
                  }
                >
                  {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      E
                    </label> */}
                  <span className="px-4 mr-3">External Threat Landscape</span>
                </Link>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" overlay={setTooltip('')}>

                <Link
                  to={`/${module}/dashboard#wafts`}
                  onClick={() => window.location.hash = `wafts`}
                  className="p-3 d-flex gap-3"
                  style={
                    currentHash === "#wafts" ? styles.activeMenuItem : styles.menuItem
                  }
                >
                  {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      W
                    </label> */}
                  <span className="px-4 mr-3">Web Application Firewall Traffic Summary</span>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={setTooltip('')}>

                <Link
                  to={`/${module}/dashboard#oauth`}
                  onClick={() => window.location.hash = `oauth`}
                  className="p-3 d-flex gap-3"
                  style={
                    currentHash === "#oauth" ? styles.activeMenuItem : styles.menuItem
                  }
                >
                  {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      W
                    </label> */}
                  <span className="px-4 mr-3">Successful Authentications from Outside Kuwait</span>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={setTooltip('')}>


                <Link
                  to={`/${module}/dashboard#email`}
                  onClick={() => window.location.hash = `email`}
                  className="p-3 d-flex gap-3"
                  style={
                    currentHash === "#email"
                      ? styles.activeMenuItem
                      : styles.menuItem
                  }
                >
                  {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      E
                    </label> */}
                  <span className="px-4 mr-3">Email</span>
                </Link>
              </OverlayTrigger>


              <OverlayTrigger placement="bottom" overlay={setTooltip('')}>

                <Link
                  to={`/${module}/dashboard#itl`}
                  onClick={() => window.location.hash = `itl`}
                  className="p-3 d-flex gap-3"
                  style={
                    currentHash === "#itl"
                      ? styles.activeMenuItem
                      : styles.menuItem
                  }
                >
                  {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      I
                    </label> */}
                  <span className="px-4 mr-3">Internal Threat landscape</span>
                </Link>
              </OverlayTrigger>

              {/* <OverlayTrigger placement="bottom" overlay={setTooltip('UEBA')}> */}

              <Link
                to={`/${module}/dashboard#wproxy`}
                onClick={() => window.location.hash = `proxy`}
                className="p-3 d-flex gap-3"
                style={
                  currentHash === "#wproxy"
                    ? styles.activeMenuItem
                    : styles.menuItem
                }
              >
                {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                    W
                  </label> */}
                <span className="px-4 mr-3">Web Access Summary of Users</span>
              </Link>
              {/* </OverlayTrigger> */}
              {/* <OverlayTrigger placement="bottom" overlay={setTooltip('UEBA')}> */}

              <Link
                to={`/${module}/dashboard#o365`}
                onClick={() => window.location.hash = `o365`}
              className="p-3 d-flex gap-3"
              style={
                currentHash === "#o365"
                  ? styles.activeMenuItem
                  : styles.menuItem
              }
                >
              {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                    T
                  </label> */}
              <span className="px-4 mr-3" >Threat Detections from Workstations</span>
            </Link>
                {/* </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={setTooltip('UEBA')}> */}

          <Link
            to={`/${module}/dashboard#software`}
            onClick={() => window.location.hash=`software`}
            className="p-3 d-flex gap-3"
            style={
              currentHash === "#software"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                      S
                    </label> */}
            <span className="px-4 mr-3">Installed Software Summary</span>
          </Link>
          {/* </OverlayTrigger>
              <OverlayTrigger placement="bottom" overlay={setTooltip('UEBA')}> */}

          <Link
            to={`/${module}/dashboard#vulns`}
            onClick={() => window.location.hash=`vulns`}
            className="p-3 d-flex gap-3"
            style={
              currentHash === "#vulns"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            {/* <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                    V
                  </label> */}
            <span className="px-4 mr-3">Vulnerabilities</span>
          </Link>
          {/* </OverlayTrigger> */}
          {/* <Link
                to={`/${module}/rscorrelation`}
                // onClick={() => window.location.href(`/${module}/rsueba`)}
                  className="p-3 d-flex gap-3"
                style={
                  currentPath === "rscorrelation"
                    ? styles.activeMenuItem
                    : styles.menuItem
                }
              >
                <label className="px-2 mr-3" style={{ fontSize: "14px" }}>
                  C
                </label>
                <span>Correlation</span>
              </Link> */}
        </div>
      )}
      <div></div>
    </div>
  )
}
{/* hunter sidebar start */ }
{
  module == "case" && (
    <div className="col-12 d-flex flex-column">
      <Link
        to={`/${module}/management`}
        onClick={() => window.location.href=`/${module}/management`}
        className="p-3"
        style={
          currentPath === "management"
            ? styles.activeMenuItem
            : styles.menuItem
        }
      >
        <OverlayTrigger placement="bottom" overlay={setTooltip('Case Management')}>
          <Detective style={styles.sidemenuIcon} />
        </OverlayTrigger>
        <span>Case Management</span>
      </Link>
      <div></div>
    </div>
  )
}

{/* hunter Sidebar end */ }

{/* compliance Sidebar end */ }

{
  module == "ti" && (
    <div className="col-12 d-flex flex-column">
      {/* <Link
            to={`/${module}/headlines`}
            className="p-3"
            style={
              currentPath === "headlines"
                ? styles.activeMenuItem
                : styles.menuItem
            }
          >
            <NewspaperClipping style={styles.sidemenuIcon} />
            Headlines
          </Link> */}
      <Link
        to={`/${module}/activebytes`}
        // onClick={() => window.location.href(`/${module}/activebytes`)}
        className="p-3"
        style={
          currentPath === "activebytes"
            ? styles.activeMenuItem
            : styles.menuItem
        }
      >
        <OverlayTrigger placement="bottom" overlay={setTooltip('Threat Events')}>
          <ListDashes style={styles.sidemenuIcon} />
        </OverlayTrigger>
        <span>Threat Events</span>
      </Link>
      <Link
        to={`/${module}/community`}
        // onClick={() => window.location.href(`/${module}/community`)}
        className="p-3"
        style={
          currentPath === "community"
            ? styles.activeMenuItem
            : styles.menuItem
        }
      >
        <OverlayTrigger placement="bottom" overlay={setTooltip('Indicators & Feeds')}>
          <RssSimple style={styles.sidemenuIcon} />
        </OverlayTrigger>
        <span>Indicators & Feeds</span>
      </Link>
      <Link
        to={`/${module}/deep_dark_web`}
        // onClick={() => window.location.href(`/${module}/deep_dark_web`)}
        className="p-3"
        style={
          currentPath === "deep_dark_web"
            ? styles.activeMenuItem
            : styles.menuItem
        }
      >
        <OverlayTrigger placement="bottom" overlay={setTooltip('Deep & Dark Web')}>
          <GlobeHemisphereWest style={styles.sidemenuIcon} />
        </OverlayTrigger>
        <span>Deep & Dark Web</span>
      </Link>
      <Link
        to={`/${module}/cykit`}
        // onClick={() => window.location.href(`/${module}/cykit`)}
        className="p-3"
        style={
          currentPath === "cykit"
            ? styles.activeMenuItem
            : styles.menuItem
        }>
        <OverlayTrigger placement="bottom" overlay={setTooltip('CyKit')}>
          <FileSearch style={styles.sidemenuIcon} />
        </OverlayTrigger>
        <span>CyKit</span>

      </Link>

      <div></div>
    </div>
  )
}   <ToastContainer />

      <div className="col-12 d-flex flex-column sidebar-toggle-btn p-3 justify-content-center cursor-pointer">
        <span onClick={() => setIsCollapsed(!isCollapsed)}><List style={styles.sidemenuIcon} color={globalStyles.white} /></span>
      </div>
    </div >
  );
};

export default Sidebar;