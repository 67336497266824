import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getProcessList } from "../../../../../Methods/UebaMethods";
import { exportCSVFile } from "../../../../../Utils/Utils";
import moment from "moment";

import {
  firewallFields,
  activeDirectoryFields,
  emailFields,
  wafFields,
  officeActiveDirectory,
  officeOneDrive,
  officeExchange,
} from "./fields";
function getTimeFromTimestamp(timestamp) {
  return moment(timestamp).format("DD MMM YYYY, hh:mm:ss A");
}
function getDateFromTimestamp(timestamp) {
  return moment(timestamp).format("DD MMM YYYY");
}
let edr = localStorage.getItem("EDR_TYPE");


const CykitINVExport = ({
  tabledata,
  filter,
  type,
  dateFilter,
  fields,
  columns,
}) => {
  const [loader, setLoader] = useState(false);

  const getFields = (type) => {
    if (type === "firewall") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value: `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
    } else if (type === "activeDirectory") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value: `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
      // var fields = activeDirectoryFields[edr]
    } else if (type === "email") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value:
                item.value === "email.from.address" ||
                item.value === "email.receiver.address" ||
                item.value === "delivery.action"
                  ? `item?._source["${item.value}"]`
                  : `item?._source?.${item.value}`,
            });
        });

      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      // debugger;
      var fields = filterExportData;
      //   var fields = emailFields[edr];
    } else if (type === "waf") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value: `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
      //   var fields = wafFields[edr];
    } else if (type === "atp_alert") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value: `item?._source?.${item?.value}`,
            });
        });
      var fields = filterExportData;
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      // var fields = activeDirectoryFields[edr]
    } else if (type === "365ad") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value:
                item.value === "DeviceProperties.OS" ||
                item.value === "ExtendedProperties.RequestType" ||
                item.value === "DeviceProperties.BrowserType"
                  ? `item?._source["${item.value}"]`
                  : `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
      //   var fields = officeActiveDirectory[edr];
    } else if (type === "365oneDrive") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value: `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
      //   var fields = officeOneDrive[edr];
    } else if (type === "365Exchange") {
      var filterExportData = [];
      columns &&
        columns.filter((item) => {
          item.isSelected === true &&
            filterExportData.push({
              name: item.text,
              value:
                item.value === "Item.Attachments" ||
                item.value === "Item.Subject" ||
                item.value === "Folder.Path"
                  ? `item?._source["${item.value}"]`
                  : `item?._source?.${item.value}`,
            });
        });
      filterExportData.push({
        name: "Date & time",
        value: `item?._source?.["@timestamp"]`,
      });
      var fields = filterExportData;
      //   var fields = officeExchange[edr];
    }
    return fields;
  };
  const convertdata = (fields, data) => {
    var convertedData = [];
    data?.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {
        // debugger;
        const { name, value } = field;
        // var test = "val."+value
        // if(test.includes(".@"))
        // {
        //     var new1 = test.split(".@")
        //     test= new1[0]+`['@${new1[1]}']`
        // }
        if (value != `item?._source?.["@timestamp"]`) {
          var value1 = eval(value);
        } else {
          var value1 = getTimeFromTimestamp(eval(value));
        }

        dynamicJSON[name] = value1;
      }
      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };
  const csvExport = async () => {
    setLoader(true);
    var size = 1000;
    var newType = type === "timeline" ? "" : type;
    if (type === "firewall") {
      var data = await tabledata(filter, dateFilter, size);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, iter);
        data3.push(...tempdata.data.hits.hits);

        iter += size;
      }
    } else if (type === "activeDirectory") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "email") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "waf") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "atp_alert") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "365ad") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "365oneDrive") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    } else if (type === "365Exchange") {
      var data = await tabledata(filter, dateFilter, size, "desc", 0);
      var data3 = data.data.hits.hits;
      var count = 10000;
      var iter = 1000;
      while (count > 1000 && iter < count) {
        var tempdata = await tabledata(filter, dateFilter, size, "desc", iter);
        data3.push(...tempdata.data.hits.hits);
        iter += size;
      }
    }
    var fields = getFields(type);
    var filename = type + "_" + moment().toISOString();

     // Convert UTC timestamps to a human-readable format
  console.log(data3);
  data3.forEach((item) => {
    if (item._source.detection_timestamp) {
      item._source.detection_timestamp = moment(item._source.detection_timestamp).format('DD MMM YYYY, hh:mm:ss A');
    }
  });
    var data1 = convertdata(fields, data3);
    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default CykitINVExport;
