import React from "react";
import styles from "./Settings.styles";

const Settings = () => {
  return (
    <>
      <div style={styles.section1}>Settings</div>
      <div style={styles.section2}>CONTENT2</div>
    </>
  );
};

export default Settings;
