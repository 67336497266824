import moment from "moment";
import { genstrut, getLocal } from "../Utils/Utils";

let edr = localStorage.getItem("EDR_TYPE");
const today = moment().utc().format("yyyy-MM-DDTHH:mm");

const CustomeDateFormat = (date) => {
  const startTimestamp = date.split('-')?.[0]
  const endTimestamp = date.split('-')?.[1]

  const startTime = moment(startTimestamp, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  const endTime = moment(endTimestamp, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  const startTimeZFormat = startTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
  const endTimeZFormat = endTime.format("YYYY-MM-DDTHH:mm:ss[Z]");

  return [startTimeZFormat, endTimeZFormat]

}

const findDateDiff = (date) => {
  console.log('DATE BEFORE GAPP :', date)
  let daysGap = ''
  if (date !== '1 Day' || date !== '7 Days' || date !== '30 Days' || date !== '1 Year') {
    const startTimestamp = date.split('-')?.[0]
    const endTimestamp = date.split('-')?.[1]

    const startTime = moment(startTimestamp, "ddd MMM DD YYYY HH:mm:ss [GMT]Z");
    const endTime = moment(endTimestamp, "ddd MMM DD YYYY HH:mm:ss [GMT]Z");

    daysGap = endTime.diff(startTime, 'days');
  } else {
    daysGap = date
  }

  return daysGap

  console.log('GAPPPPP :', daysGap)
}

const checkFilter = (date) => {
  if (date === '1 Day') {
    return '1d'
  } else if (date === '7 Days') {
    return '7d'
  } else if (date === '30 Days') {
    return '30d'
  } else if (date === '1 Year') {
    return '1y'
  } else {
    return date
  }
}

const timeFilter = () => {
  let filter = localStorage.getItem("filter")

  var number = 0

  if (filter === "1 Year") {
    number = 365
  } else if (filter === "30 Days") {
    number = 30
  } else if (filter === "1 Day") {
    number = 1
  } else {
    number = 7
  }
  const endDate = moment().utc()
    .subtract(number, "days")
    ?.format("yyyy-MM-DDTHH:mm");

  return endDate
}

export const getMostAnomalousUsersQuery = (count) => {
  return {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              result: "-1",
            },
          },
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "user_name",
          order: {
            _count: "desc",
          },
          size: count,
        },
        aggs: {
          result: {
            terms: {
              field: "result",
            },
          },
        },
      },
    },
  };
};

export const getMostAnomalousHostQuery = (count) => {
  return {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              result: "-1",
            },
          },
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "host_name",
          order: {
            _count: "desc",
          },
          size: count,
        },
        aggs: {
          result: {
            terms: {
              field: "result",
            },
          },
        },
      },
    },
  };
};

export const getCountOfInspectedQuery = () => {
  return {
    size: 10,
    track_total_hits: true,
    query: {
      bool: {
        should: [{ exists: { field: "result" } }],
        minimum_should_match: 1,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
        ],
      },
    },
    aggs: { events_scanned: { sum: { field: "count" } } },
  };
};

// old count of users and hosts query

// export const getCountOfQuery = async (field_name)=> {
//   return {
//     size: 0,
//     query: {
//       match_phrase: {
//         result: -1
//       }
//     },
//     aggs: {
//       unique_users: {
//         cardinality: {
//           field: "user_name"
//         }
//       }
//     }
//   }
// }

export const getCountOfQuery = (field_name) => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "filter": [
          {
            "range": {
              "detection_timestamp": {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
              },
            }
          },
          {
            "match_phrase": {
              "result": -1
            }
          }
        ]
      }
    },
    "aggs": {
      "item_count": {
        "cardinality": {
          "field": field_name
        }
      }
    }
  }
};



export const getCountOfAnomaliesQuery = () => {
  return {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },

        ],

        should: [
          {
            match_phrase: {
              result: -1,
            },
          },
        ],

        minimum_should_match: 1,

        must_not: [],
      },
    },
  };
};

export const getDetectionsQuery = (count, sort, page) => {
  return {
    from: 0,
    size: 100,

    query: {
      bool: {
        should: [],

        minimum_should_match: 0,

        filter: [
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },

          {
            match_phrase: {
              result: "-1",
            },
          },
        ],
      },
    },

    sort: [
      {
        detection_timestamp: {
          order: "desc",

          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const getSeverityCountsQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "filter": [
          {
            "range": {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
              },
            }
          },
          {
            "match_phrase": {
              "result": "-1"
            }
          }
        ]
      }
    },
    "aggs": {
      "severity": {
        "terms": {
          "field": "severity.keyword",
          "size": 4
        }
      }
    }
  }
}
const getDiff = (selectedDate) => {
  if(!localStorage.getItem("isCustomTrend",false)){
    return selectedDate === '6 hour' || selectedDate === '1 Day'
              ? "1h"
              : (findDateDiff(selectedDate) >= 1 && findDateDiff(selectedDate) <= 7) || selectedDate === '7 Days'
                ? "1d"
                : (findDateDiff(selectedDate) >= 8 && findDateDiff(selectedDate) < 31) || selectedDate === '30 Days'
                  ? "1w" : findDateDiff(selectedDate) >= 31 || selectedDate === '1 Year' ? '1M'
                    : "1M"
  }
  else{
     let gte = localStorage.getItem("gte")
     let lte = localStorage.getItem("lte")
      let diff = moment(lte).diff(moment(gte), 'days')
      
      return diff === 0
              ? "1h"
              : (diff >= 1 && diff <= 7) || diff === '7 Days'
                ? "1d"
                : (diff >= 8 && diff < 31) || diff === '30 Days'
                  ? "1w" : diff >= 31 || diff === '1 Year' ? '1M'
                    : "1M"
  }
}
export const getDetectionTrendsQuery = (selectedDate) => {
  console.log('selectedDATE selectedDate selectedDate :', findDateDiff(selectedDate))
  return {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              result: "-1",
            },
          },
          {
            range: {
              detection_timestamp: {
                gte: localStorage.getItem("gte")
                  ? getLocal(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getLocal(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      2: {
        date_histogram: {
          field: "detection_timestamp",
          calendar_interval: getDiff(selectedDate),
          // calendar_interval: "1M",
          min_doc_count: 1,
        },
      },
    },
  };
};

export const getNumberOfEventScannedQuery = () => {
  return {
    size: 10,
    track_total_hits: true,
    query: {
      bool: {
        should: [
          {
            exists: {
              field: "result",
            },
          },
        ],
        minimum_should_match: 1,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-30d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    aggs: {
      events_scanned: { sum: { field: "count" } },
    },
  };
};

export const getUserDetectionQuery = () => {
  return {
    from: 0,
    size: 1000,
    track_total_hits: true,
    query: {
      bool: {
        should: [],
        minimum_should_match: 0,
        filter: [
          { range: { detection_timestamp: { gte: "now-365d", lte: "now" } } },
          {
            bool: {
              should: [{ match_phrase: { user_name: "ASUS" } }],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
    sort: [
      { detection_timestamp: { order: "desc", unmapped_type: "boolean" } },
    ],
  };
};

export const getAllActivitiesQuery = (size) => {
  return {
    from: 0,
    size: size,
    query: {
      bool: {
        must: [],
        filter: [
          { bool: { should: [], minimum_should_match: 1 } },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: "now-30d",
                lte: "now",
              },
            },
          },
          { bool: { should: [], minimum_should_match: 1 } },
        ],
        should: [],
        must_not: [],
      },
    },
    sort: [{ "@timestamp": { order: "desc", unmapped_type: "boolean" } }],
  };
};

// export const getDetectedAnomaliesCountBased = (data) => {
//   alert("COUNT");
//   return {
//     size: 10000,
//     query: {
//       bool: {
//         should: [
//           {
//             match_phrase: {
//               type: "count based",
//             },
//           },
//           {
//             match_phrase: {
//               group_id: data?.group_id,
//             },
//           },
//         ],
//         minimum_should_match: 2,
//         filter: [
//           {
//             range: {
//               detection_timestamp: { gte: "now-365d", lte: "now" },
//             },
//           },
//         ],
//       },
//     },
//     sort: [
//       {
//         detection_timestamp: {
//           order: "asc",
//           unmapped_type: "boolean",
//         },
//       },
//     ],
//   };
// };

// export const getDetectionAnomaliesTimeBased = (data) => {
//   alert("TIME");
//   return {
//     size: 10000,
//     query: {
//       bool: {
//         should: [
//           {
//             match_phrase: {
//               type: "time based",
//             },
//           },
//           {
//             match_phrase: {
//               group_id: data?.group_id,
//             },
//           },
//         ],
//         minimum_should_match: 2,
//         filter: [
//           {
//             range: {
//               detection_timestamp: { gte: "now-365d", lte: "now" },
//             },
//           },
//         ],
//       },
//     },
//     sort: [
//       {
//         detection_timestamp: {
//           order: "asc",
//           unmapped_type: "boolean",
//         },
//       },
//     ],
//   };
// };

export const Ueba10 = (ueba_id, user_filter, host_filter) => {
  return {
    size: 10000,
    query: {
      bool: {
        should: [
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          // {
          //   "match_phrase": {
          //     "user_domain": this.detection_summary?.user_domain
          //   }
          // },
          user_filter,
          host_filter,
        ],
        minimum_should_match: 3,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-3650d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: "asc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const Ueba003 = (ueba_id, user_filter, timestamp) => {
  var low = moment(timestamp).subtract(30, "days").toISOString();
  var high = moment(timestamp).toISOString();
  return {
    size: 0,

    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          user_filter,
          {
            range: {
              detection_timestamp: {
                gte: low,
                lte: high,
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },

    aggs: {
      2: {
        terms: {
          field: "host_name",

          order: {
            _count: "desc",
          },

          size: 1000,
        },
      },
    },
  };
  // return {
  //   size: 10000,
  //   query: {
  //     bool: {
  //       should: [
  //         {
  //           match_phrase: {
  //             ueba_id: ueba_id,
  //           },
  //         },
  //         user_filter,
  //       ],
  //       minimum_should_match: 3,
  //       filter: [
  //         {
  //           range: {
  //             detection_timestamp: {
  //               gte: "now-30d",
  //               lte: "now",
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  //   sort: [
  //     {
  //       detection_timestamp: {
  //         order: "asc",
  //         unmapped_type: "boolean",
  //       },
  //     },
  //   ],
  //   "aggs": { "2": { "terms": { "field": "host_name", "order": { "_count": "desc" }, "size": 1000 } } }
  // };
};

export const Ueba012 = (ueba_id, host_filter) => {
  return {
    size: 10000,
    query: {
      bool: {
        should: [
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          host_filter,
        ],
        minimum_should_match: 2,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-3650d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: "asc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const finalData = (ueba_id, user_filter, host_filter) => {
  return {
    size: 10000,
    query: {
      bool: {
        should: [
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          user_filter,
          host_filter,
        ],
        minimum_should_match: 3,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-3650d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: "asc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const o365AuditLogQuery = (ueba_id, userId_filter, ClientIp_filter) => {
  return {
    size: 30,
    query: {
      bool: {
        should: [
          { match_phrase: { ueba_id: ueba_id } },
          { match_phrase: { UserId: userId_filter } },
          { match_phrase: { ClientIP: ClientIp_filter } },
        ],
        minimum_should_match: 3,
        filter: [
          { range: { detection_timestamp: { gte: "now-30d", lte: "now" } } },
        ],
      },
    },
    sort: [{ detection_timestamp: { order: "asc", unmapped_type: "boolean" } }],
  };
};

export const o365MessageLogQuery = (
  ueba_id,
  email_receiver_address,
  email_from_address
) => {
  return {
    size: 30,
    query: {
      bool: {
        should: [
          { match_phrase: { ueba_id: ueba_id } },
          { match_phrase: { email_receiver_address: email_receiver_address } },
          { match_phrase: { email_from_address: email_from_address } },
        ],
        minimum_should_match: 3,
        filter: [
          { range: { detection_timestamp: { gte: "now-30d", lte: "now" } } },
        ],
      },
    },
    sort: [{ detection_timestamp: { order: "asc", unmapped_type: "boolean" } }],
  };
};

// timebase query

export const anomaliesDataTimeBasedQuery = (
  selectedData,
  ueba_users_with_host,
  shouldList
) => {
  var data = {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-3650d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [{ first_activity: { order: "asc", unmapped_type: "boolean" } }],
  };

  if (ueba_users_with_host.includes(selectedData?.ueba_id)) {
    var must = [
      { match_phrase: { host_name: selectedData.host_name } },
      { match_phrase: { user_name: selectedData.user_name } },
      { match_phrase: { ueba_id: selectedData.ueba_id } },
    ];
    data.query.bool.must.push(...must);
  } else {
    var must = [
      { match_phrase: { host_name: selectedData.host_name } },
      { match_phrase: { ueba_id: selectedData.ueba_id } },
    ];
    data.query.bool.must.push(...must);
  }

  return data;
};

export const getUsersListQuery = (searchTerm, field_name) => {
  return {
    size: 0,
    query: {
      bool: {
        should: [
          {
            query_string: {
              fields: [field_name],
              query: `${searchTerm}*`,
            },
          },
        ],
        minimum_should_match: 1,
        filter: [
          {
            range: {
              "@timestamp": {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: field_name,
          size: 10000,
        },
      },
    },
  };
};

// detection summary page details

export const getO365AuditTimeQuery = (ueba_id, user_id, Client_iP) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              UserId: user_id,
            },
          },
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          {
            match_phrase: {
              ClientIP: Client_iP,
            },
          },
        ],
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-30d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        first_activity: {
          order: "asc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};
export const getO365MessageTimeQuery = (
  ueba_id,
  email_from_address,
  email_receiver_address
) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              email_from_address: email_from_address,
            },
          },
          {
            match_phrase: {
              ueba_id: ueba_id,
            },
          },
          {
            match_phrase: {
              email_receiver_address: email_receiver_address,
            },
          },
        ],
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-30d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        first_activity: {
          order: "asc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const getDetectionSummaryQuery = (groupId, type) => {
  return {
    size: 10000,
    query: {
      bool: {
        should: [
          { match_phrase: { type: type } },
          { match_phrase: { group_id: groupId } },
        ],
        minimum_should_match: 2,
        filter: [
          { range: { detection_timestamp: { gte: "now-365d", lte: "now+3h" } } },
        ],
      },
    },
    sort: [{ detection_timestamp: { order: "asc", unmapped_type: "boolean" } }],
  };
};

// grouped id ( investigation grouped)
export const groupedSummayQuery = (group_id) => {
  return {
    size: 100,
    query: { bool: { filter: [{ match_phrase: { group_id: group_id } }] } },
  };
};

const filterlist = [
  {
    name: "Trusted IP",
    fieldname: "source.trusted_ip",
    field: "source.ip.keyword",
    list: ["192.168.1.1", "127.0.0.1"],
  },
];

// UEBA-028 UEBA-029

export const getUebaMapQuery = (userName, hostName, uebaId, time) => {
  return {
    size: 10,
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              user_name: userName,
            },
          },
          {
            match_phrase: {
              host_name: hostName,
            },
          },
          {
            match_phrase: {
              ueba_id: uebaId,
            },
          },
          {
            range: {
              detection_timestamp: {
                gte: moment(time).subtract(7, "days").toISOString(), // detection time - 1d
                lte: moment(time).toISOString(), // detection time
              },
            },
          },
        ],
      },
    },
    "aggs": {
      "country": {
        "terms": {
          "field": "country",
          "size": 100,
          "order": {
            "max_timestamp": "desc"
          }
        },
        "aggs": {
          "max_timestamp": {
            "max": {
              "field": "time"
            }
          }
        }
      }
    }
  };
};

export const getProcessCountQuery = (processName, time) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_phrase: { process_name: processName } },
          {
            range: {
              detection_timestamp: {
                gte: moment(time).subtract(7, "days").toISOString(), // detection time - 1d
                lte: moment(time).toISOString(), // detection time
              },
            },
          },
        ],
      },
    },
    aggs: {
      date: {
        date_histogram: {
          field: "detection_timestamp",
          calendar_interval: "1d",
          min_doc_count: 1,
        },
      },
    },
  };
};

export const getProcessCountTwoQuery = (processName) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { range: { detection_timestamp: { gte: "now-7d", lte: "now" } } },
        ],
      },
    },
    aggs: {
      process: { terms: { field: "process_name", order: { _count: "desc" } } },
    },
  };
};

export const getProcessCountThreeQuery = (processName) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_phrase: { process_name: processName } },
          { range: { detection_timestamp: { gte: "now-30d", lte: "now" } } },
        ],
      },
    },
    aggs: {
      user: { terms: { field: "user_name", order: { _count: "desc" } } },
    },
  };
};

export const getProcessCountFourQuery = (processName, userName) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_phrase: { process_name: processName } },
          { match_phrase: { user_name: userName } },
          { range: { detection_timestamp: { gte: "now-7d", lte: "now" } } },
        ],
      },
    },
    aggs: {
      date: {
        date_histogram: {
          field: "detection_timestamp",
          calendar_interval: "1d",
          min_doc_count: 1,
        },
        aggs: {
          user: {
            terms: { field: "user_name", order: { _count: "desc" } },
            aggs: {
              process: {
                terms: { field: "process_name", order: { _count: "desc" } },
              },
            },
          },
        },
      },
    },
  };
};
