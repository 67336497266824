import React, { useEffect, useState,useRef } from "react";
import { Pie,Bar  } from 'react-chartjs-2';
import "../../../index.css";
import styles from "./MainDashboard.styles";
import { Button, Form,Table, Tab, Tabs,Badge } from "react-bootstrap";
import iso from "../../../assets/compliance/compliance-iso27001.json";
import nist from "../../../assets/compliance/compliance-NIST.json";
import pci from "../../../assets/compliance/compliance-PCI-DSS.json";
import { fget, fpost } from "../../../axios/axios";
import { CaretDown, CaretRight, ArrowClockwise, ArrowsOutSimple, Pencil } from "phosphor-react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { 
  getIso,getNist,getCompName,getPci,getHostName,getUserName
 } from "../../../Methods/ComplianceMethods";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableLoader from '../../../components/Loader/TableLoader'
import PieChartLoader from "../../../components/Loader/PieChartLoader";
import PlainCardLoader from "../../../components/Loader/PlainCardLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import RecentTab from "../../../components/RecentTab/RecentTab";
import moment from 'moment';
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as htmlToImage from 'html-to-image';
import ReactApexCharts from 'react-apexcharts';


import { FaTruckMedical } from "react-icons/fa6";
import AlertsDoc from "../../../components/PDF/Compliance/AlertsDoc";

const Alerts = ({ selectedDateFilter, setActiveSubTab,activeSubTab }) => {
  const [selectedFilter, setSelectedFilter] = useState("ISO-27001");
  const [selectedData, setSelectedData] = useState({});
  const [annexData, setAnnexData] = useState({});
  const [annexBData, setAnnexBData] = useState({});
  const [showInfoDown, setShowInfoDown] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [editHeading, setEditHeading] = useState(false);
  const [getInfoData, setGetInfoData] = useState({});
  const [showIndex, setShowIndex] = useState([]);
  const [moreInfoText, setMoreInfoText] = useState();
  const [loading, setLoading] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [status, setStatus] = useState("");
  const [savedFormattedId, setSavedFormattedId] = useState("");
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [activeTab, setActiveTab] = useState("main");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  const [tableLoading, setTableLoading] = useState(false);
  const [pieLoading, setPieLoading] = useState(true);
  const [isoData,setIsoData] =useState([]);
  const [nistData,setNistData] =useState([]);
  const [pciData,setPciData] =useState([]);
  const [comUserData,setComUserData] =useState([])
  const [hostUserData,setHostUserData] =useState([])
  const [userData,setUserData] =useState([])

  const [recentCompLoader, setRecentCompLoader] = useState(true)
  const [stat, setStat] = useState("")
  const [totalProportion, setTotalProportion] = useState(0);
  const [newTotalProportion, setNewTotalProportion] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState(localStorage.getItem("activeTab.compliance") ? localStorage.getItem("activeTab.compliance") : "OVERVIEW")

  const [showDownloadButton, setShowDownloadButton] = useState(false)

  // imageblob
  const isoRefImage = useRef(null);
  const nistRefImage = useRef(null);
  const pciRefImage = useRef(null);
  const cardRefImage = useRef(null);

 

  const [imageBlob, setImageBlob] = useState({
      iso: null,
      nist: null,
      pci: null,  
      card:null  
  });

  const convertToBlob = () => {
    setImageBlob({
      iso: null,
      nist: null,
      pci: null, 
      card:null
    })

    // ISO image
    htmlToImage.toBlob(isoRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            iso: blob,
        }));
    });

    // nist
    htmlToImage.toBlob(nistRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            nist: blob,
        }));
    });

    // pci dss
    htmlToImage.toBlob(pciRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            pci: blob,
        }));
    }); 
    
     // card
     htmlToImage.toBlob(cardRefImage.current).then((blob) => {

      setImageBlob((prevState) => ({
          ...prevState,
          card: blob,
      }));
  }); 
};




  useEffect(() => {
    setRecentCompLoader(true)
    setPieLoading(true)
    setTableLoading(false)
            
        let filter=setFilterData(selectedDateFilter)

            // if (selectedFilter === "PCI-DSS" || selectedFilter === "ISO-22301") setActiveTab("annex");
            // else setActiveTab("main");

            const viewName = selectedFilter.replace('-', '').toLowerCase()
            getSummary(viewName);
            getIso(filter).then((res)=>{
              setIsoData(res.data.aggregations.source_ip.buckets)
              
            })
            getNist(filter).then((res)=>{            
              setNistData(res.data.aggregations.source_ip.buckets)
            })
            getPci(filter).then((res)=>{
              setPciData(res.data.aggregations.source_ip.buckets)
            })
            getCompName(filter).then((res)=>{
               setComUserData(res.data.aggregations.com_name_values.buckets)
               setRecentCompLoader(false)
            })
            getHostName(filter).then((res)=>{
              // console.log("host:",res.data.aggregations);
              setHostUserData(res.data.aggregations. host_name_values.buckets)           
            })
            getUserName(filter).then((res)=>{
              // console.log("user:",res.data.aggregations);
              setUserData(res.data.aggregations. user_name_values.buckets)           
              

            })
  }, [selectedFilter, selectedDateFilter]);

  useEffect(()=>{
    // console.log("isoData",isoData);
    // console.log("nistData",nistData);
    // console.log("pciData",pciData);
  },[isoData,nistData,pciData])

  useEffect(() => {
    setShowDownloadButton(false)
    if (pieLoading === true && hostUserData!= null && comUserData != null && userData != null ) {
        setTimeout(() => {
            convertToBlob()
            setShowDownloadButton(true)
        }, 20000)
    }
}, [pieLoading,
  hostUserData,
  comUserData,
  userData])

//  Pie chart loading and container styles
const containerStyle = {
  position: 'relative',
  marginLeft: '80px',
  width: '400px', // Set the width of the container
  height: '400px', // Set the height of the container
  display: "flex",
    flexDirection: "column",
    marginTop:"50px",
   boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Add box shadow
};

  const setFilterData = (selectedDateFilter) => {
    let filterData = "";
    if (selectedDateFilter === "1 Day") {
      filterData = "now-1d/d";
    } else if (selectedDateFilter === "7 Days") {
      filterData = "now-7d/d";
    } else if (selectedDateFilter === "30 Days") {
      filterData = "now-30d/d";
    } else {
      filterData = "now-1y/d";
    }

    return filterData;
  };


  const getSummary = async (item) => {
    setIsMainLoading(true)
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/summary?std=${item}`
      );
      // console.log(response,"response");
      if(response){
        setTableLoading(true)
        setPieLoading(false)
      } else{
        setTableLoading(false)
        setPieLoading(true)
      }
      // console.log("SUMMARY", response, typeof response?.data);
      setSelectedData(typeof response?.data === "object" ? response?.data : {})
      setAnnexData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex" || item.category === "annex-a") : {})
      setAnnexBData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex-b") : {})
     

      setIsMainLoading(false)

    } catch (error) {
      setIsMainLoading(false)
      console.log("summary error", error);
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
    }
  };

// Data set-ISO
const detectionIso =[];
const chartData =[];
const filteredDataIso =[];
isoData?.map((item) => {
  detectionIso.push(item?.key)
  chartData.push(item?.dest_port?.buckets)
})

const groupedData = {};

chartData.forEach(dataset => {
  dataset.forEach(item => {
    const name = item.key;
    const data = item.doc_count;

    if (groupedData[name]) {
      groupedData[name].push(data);
    } else {
      groupedData[name] = [data];
    }
  })
})

const resultObjects = Object.entries(groupedData).map(([name, data]) => ({
  name,
  data,
}));

filteredDataIso.push(...resultObjects);
  
  const colors = [
    'rgba(255, 99, 132, 0.8)',
    'rgba(54, 162, 235, 0.8)',
    'rgba(255, 205, 86, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 159, 64, 0.8)'
  ];

  
  
  
  const isoOption = {
    series: filteredDataIso?.map((item, index) => ({
      name: item.name, // Assuming each item has a name property
      data: item.data, // Assuming each item has a data property
      color: colors[index % colors.length] // Assigning color from the colors array
    })),
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '20px',
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                colors: colors // Set the color for data labels
              },
            },
          },
        },
      },
      stroke: { width: 0 },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: '12px',
          colors: colors // Set the color for data labels
        }
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: detectionIso,
        labels: {
          style: { colors: colors } // Set the color for x-axis labels
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          show: true,
          style: { colors: colors }, // Set the color for y-axis labels
          formatter: function (val) {
            return val;
          }
        }
      },
      tooltip: {
        enabled: true,
        theme: 'light',
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      fill: {
        opacity: 1,
        colors: colors // Set the color for bars
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '13px',
        labels: { colors: colors }, // Set the color for legend labels
        itemMargin: {
          vertical: 4,
          horizontal: 10
        },
        markers: {
          width: 12,
          height: 12,
          radius: 10,
          offsetY: 1,
          offsetX: -4
        }
      },
    },
  };
  



// Data set-NIST
      const detectionNist =[];
      const chartDataNist =[];
      const filteredDataNist =[];
      nistData?.map((item) => {
        detectionNist.push(item?.key)
        chartDataNist.push(item?.dest_port?.buckets)
      })

      const groupedDataNist= {};

      chartDataNist.forEach(dataset => {
        dataset.forEach(item => {
          const name = item.key;
          const data = item.doc_count;

          if (groupedDataNist[name]) {
            groupedDataNist[name].push(data);
          } else {
            groupedDataNist[name] = [data];
          }
        })
      })

      const resultObjectsNist = Object.entries(groupedDataNist).map(([name, data]) => ({
        name,
        data,
      }));

      filteredDataNist.push(...resultObjectsNist);
               
    // const nistOption = {
    //     series: filteredDataNist?.map(item => item),
    //     options: {
    //       chart: {
    //         type: 'bar',
    //         height: 350,
    //         stacked: true,
    //         toolbar: { show: false },
    //       },
    //       plotOptions: {
    //         bar: {
    //           horizontal: true,
    //           barHeight: '20px',
    //           dataLabels: {
    //             total: {
    //               enabled: true,
    //               offsetX: 0,
    //               style: {
    //                 fontSize: '13px',
    //                 fontWeight: 900,
    //               },
    //             },
    //           },
    //         },
    //       },
    //       stroke: { width: 0 },
    //       dataLabels: {
    //         enabled: true,
    //         formatter: function (val) {
    //           return val;
    //         },
    //         style: {
    //           fontSize: '12px',
    //           colors: colors
    //         }
    //       },
    //       title: {
    //         text: '',
    //       },
    //       xaxis: {
    //         categories: detectionNist,
    //         labels: {
    //           style: { colors: colors }
    //         },
    //       },
    //       yaxis: {
    //         title: {
    //           text: undefined,
    //         },
    //         labels: {
    //           show: true,
    //           style: { colors: colors },
    //           formatter: function (val) {
    //             return val;
    //           }
    //         }
    //       },
    //       tooltip: {
    //         enabled: true,
    //         theme: 'light',
    //         x: {
    //           format: 'dd/MM/yy HH:mm'
    //         },
    //       },
    //       fill: {
    //         opacity: 1,
    //         colors: colors
    //       },
    //       legend: {
    //         position: 'bottom',
    //         horizontalAlign: 'center',
    //         fontSize: '13px',
    //         labels: { colors: colors },
    //         itemMargin: {
    //           vertical: 4,
    //           horizontal: 10
    //         },
    //         markers: {
    //           width: 12,
    //           height: 12,
    //           radius: 10,
    //           offsetY: 1,
    //           offsetX: -4
    //         }
    //       },
    //     },
    //   };

      const nistOption = {
        series: filteredDataNist?.map((item, index) => ({
          name: item.name, // Assuming each item has a name property
          data: item.data, // Assuming each item has a data property
          color: colors[index % colors.length] // Assigning color from the colors array
        })),
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: { show: false },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '20px',
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900,
                    colors: colors // Set the color for data labels
                  },
                },
              },
            },
          },
          stroke: { width: 0 },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            style: {
              fontSize: '12px',
              colors: colors // Set the color for data labels
            }
          },
          title: {
            text: '',
          },
          xaxis: {
            categories: detectionNist,
            labels: {
              style: { colors: colors } // Set the color for x-axis labels
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
            labels: {
              show: true,
              style: { colors: colors }, // Set the color for y-axis labels
              formatter: function (val) {
                return val;
              }
            }
          },
          tooltip: {
            enabled: true,
            theme: 'light',
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          fill: {
            opacity: 1,
            colors: colors // Set the color for bars
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '13px',
            labels: { colors: colors }, // Set the color for legend labels
            itemMargin: {
              vertical: 4,
              horizontal: 10
            },
            markers: {
              width: 12,
              height: 12,
              radius: 10,
              offsetY: 1,
              offsetX: -4
            }
          },
        },
      };

      

      // Data set-PCI
      const detectionPci =[];
      const chartDataPci =[];
      const filteredDataPci =[];
      pciData?.map((item) => {
        detectionPci.push(item?.key)
        chartDataPci.push(item?.dest_port?.buckets)
      })

      const groupedDataPci = {};

      chartDataPci.forEach(dataset => {
        dataset.forEach(item => {
          const name = item.key;
          const data = item.doc_count;

          if (groupedDataPci[name]) {
            groupedDataPci[name].push(data);
          } else {
            groupedDataPci[name] = [data];
          }
        })
      })

      const resultObjectsPci = Object.entries(groupedDataPci).map(([name, data]) => ({
        name,
        data,
      }));

      filteredDataPci.push(...resultObjectsPci);

    

    const pciOption = {
      series: filteredDataPci?.map((item, index) => ({
        name: item.name, // Assuming each item has a name property
        data: item.data, // Assuming each item has a data property
        color: colors[index % colors.length] // Assigning color from the colors array
      })),
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20px',
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                  colors: colors // Set the color for data labels
                },
              },
            },
          },
        },
        stroke: { width: 0 },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: '12px',
            colors: colors // Set the color for data labels
          }
        },
        title: {
          text: '',
        },
        xaxis: {
          categories: detectionPci,
          labels: {
            style: { colors: colors } // Set the color for x-axis labels
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            style: { colors: colors }, // Set the color for y-axis labels
            formatter: function (val) {
              return val;
            }
          }
        },
        tooltip: {
          enabled: true,
          theme: 'light',
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        fill: {
          opacity: 1,
          colors: colors // Set the color for bars
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '13px',
          labels: { colors: colors }, // Set the color for legend labels
          itemMargin: {
            vertical: 4,
            horizontal: 10
          },
          markers: {
            width: 12,
            height: 12,
            radius: 10,
            offsetY: 1,
            offsetX: -4
          }
        },
      },
    };
 

  return (
    <>
                <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                    <div className='col-12 flex-fill border rounded-3 p-4 mb-0 bg-white'>
                                {/* <h2>Threat Landscape {(showDate) && `(${moment().format("DD-MM-YYYY")})`}</h2> */}
                                {showDownloadButton ?
                            <div
                                className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                            >
                                <PDFDownloadLink document={<AlertsDoc imageBlob={imageBlob}  />} fileName="compliance-alert-dashboard">
                                    {({ blob, url, loading, error }) =>
                                        <Button className='btn btn-primary'>Download</Button>
                                    }
                                </PDFDownloadLink>
                                
                            </div>
                            :
                            <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">

                                <PDFDownloadLink document={<AlertsDoc imageBlob={imageBlob}  />} fileName="compliance-alert-dashboard">
                                    {({ blob, url, loading, error }) =>
                                        <Button className='btn btn-primary' disabled>
                                            Download <Spinner animation="border" role="status" size="sm" />
                                        </Button>

                                    }
                                </PDFDownloadLink>
                            </div>
                        }

                                <h2>Alerts</h2>

                                <p className='mb-0' style={styles.paraGraph}>They are proactive security guardians, serving as a vigilant sentinel, providing a real-time, panoramic view of the cybersecurity landscape. Drawing from a diverse suite of security solutions, including Antivirus (AV), Endpoint Detection and Response (EDR), Firewall, Intrusion Prevention System (IPS), Network Detection and Response (NDR), and Vulnerability Scanners, offering an integrated perspective of the security posture.</p>
                                {/* <Link className='btn btn-dark mt-3' to={"/ti/activebytes"} style={{ color: globalStyles.white }}>View all</Link> */}
                               </div>
                             <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white position-relative" style={{ maxHeight: '400px', overflowY: 'hidden' }} >
                            <div ref={isoRefImage} className="col-4 p-4 rounded-3 border" >
                                <h5>Top Failed ISO 27001 Requirements</h5>
                                {/* { isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : */}
                                  { pieLoading ? (
                                        // Display loader while loading
                                        <PlainCardLoader width="200px" />
                                    ) :  isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : (
                                        // Display the actual pie chart once data is loaded
                                        // <Bar data={IsoPieData} options={options}  />
                                       <ReactApexCharts options={isoOption.options} 
                                        series={isoOption.series} type="bar" height={350} />
                                      
                                    )}                            
                            </div>

                            <div ref={nistRefImage} className="col-4 p-4 rounded-3 border" >
                                <h5>Top Failed NIST Requirements</h5>
                                {/* { nistData.length === 0 ? <NoDataFound errorText={"No data found"} /> : */}
                                 { pieLoading ? (
                                        // Display loader while loading
                                        <PlainCardLoader width="200px" />
                                    ) : nistData.length === 0 ? <NoDataFound errorText={"No data found"} />:(
                                        // Display the actual pie chart once data is loaded
                                        // <Bar data={NistPieData} options={options} />
                                        <ReactApexCharts options={nistOption.options} 
                                        series={nistOption.series} type="bar" height={350} />
                                    )}
                            </div>
                            <div ref={pciRefImage} className="flex-fill p-4 rounded-3 border" >
                                <h5>Top Failed PCI DSS Requirements</h5>
                                {/* { pciData.length === 0 ? <NoDataFound errorText={"No data found"} /> : */}
                                 { pieLoading ? (
                                        // Display loader while loading
                                        <PlainCardLoader width="200px" />
                                    ) :pciData.length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                        // Display the actual pie chart once data is loaded
                                        <ReactApexCharts options={pciOption.options} 
                                        series={pciOption.series} type="bar" height={350} />
                                    )}
                            </div>
                        </div>

                        <div ref={cardRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                             <div className="col-12 gap-3 d-flex flex-row" >
                             {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                      <RecentTab
                                          tabName={" Alert "}
                                          mostAnomalouseList={comUserData}
                                          type={"comp"}
                                          selectedField={'compUser'}
                                          FieldLabel={'CompUser'}
                                          loader={recentCompLoader}                             
                                      />
                                    }
                               {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                <RecentTab
                                    tabName={"Host Name"}
                                    mostAnomalouseList={hostUserData}
                                    type={"comp"}
                                    selectedField={'compUser'}
                                    FieldLabel={'CompUser'}
                                    loader={recentCompLoader}
                                />
                                  }
                                {userData===null ? <NoDataFound errorText={"No data found"} /> :
                                <RecentTab
                                    tabName={"User Name"}
                                    mostAnomalouseList={userData}
                                    type={"comp"}
                                    selectedField={'compUser'}
                                    FieldLabel={'CompUser'}
                                    loader={recentCompLoader}
                                />
                                }
                                </div>
                            </div>
                            </div>

                                            
                 
                 
                <ToastContainer /> 
       
</div>
         
     
   
    </>
  );
};

export default Alerts;


