import React, { useState, useEffect, useRef, useMemo } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData, getSoftwareList, getUniqueVendorCount, getUniqueSoftwareCount, getUniqueVendorList, getHostList, getUniqueVulnsCount, getUniqueAssetsCount, getUniqueVulnsWithExploits, getVulnsBySeverityQuery, getVulnsWithExploitListQuery, getUniqueHostWithRiskScoreQuery, getVulnsBySeverityFor7Query, getVulnsBySeverityFor30Query, getVulnsBySeverityFor90Query, getvulnsOverTime, getvulnsPerHostListQuery, getvulnsListQuery } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import styleVul from '../../../ManagementDashboard/Threatlandscape/ThreatLandscape.styles'
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import { isEmptyObject } from '../../../../Utils/Utils';
import { getFirwallTableQuery } from '../../../../Queries/CyKitInvestigateQuery';
import { totalUniqueSoftwareCountsQuery } from '../../../../Queries/InvestigationQuery';
import globalStyles from '../../../../constants/globalStyles';
import SearchBar from '../../../../components/Search/ManagementDashboard/SearchBar';
import ThreatLandScapeSideBarVulns from '../../../../components/ThreatLandScapeSideBar/ThreatLandScapeSideBarVulns';
// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
const INVESTIGATE_CY_VULNS = localStorage.getItem("INVESTIGATE_CY_VULNS");

const CyVulnsCykit = ({ vulnerabilitiesTwoRefImage, vulnerabilitiesRefImage, scanName }) => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);


    const [mostUsedSoftwareLoader, setMostUsedSoftwareLoader] = useState(true)


    const [totalUniqueAssetCount, settotalUniqueAssetCount] = useState(0)
    const [totalVulnsCount, settotalVulnsCount] = useState(0)
    const [totalVulnsWithExploit, setTotalVulnsWithExploit] = useState(0);
    const [vulnbySeverityLabel, setVulnbySeverityLabel] = useState([])
    const [vulnbySeverityData, setVulnbySeverityData] = useState([])
    const [vulnbySeverityDataLoader, setVulnbySeverityDataLoader] = useState(true)
    const [vulnHostScorelabel, setVulnHostScorelabel] = useState([])
    const [vulnHostScoreData, setVulnHostScoreData] = useState([])
    const [vulnAgeLoader, setVulnAgeLoader] = useState(true)
    const [vulnAge7, setVulnAge7] = useState([])
    const [vulnAge30, setVulnAge30] = useState([])
    const [vulnAge90, setVulnAge90] = useState([])
    const [totalVulnsWithExploitList, setTotalVulnsWithExploitList] = useState([]);
    const [vulnsList, setVulnsList] = useState([]);
    const [vulnsPerHostList, setVulnsPerHostList] = useState([]);
    const [vulnHostScoreLoader, setVulnHostScoreLoader] = useState(true);


    const [moderateGraphData, setModerateGraphData] = useState({})
    const [criticalGraphData, setCriticalGraphData] = useState({})
    const [severeGraphData, setSevereGraphData] = useState({})

    const [vulnOverTimeLoader, setVulnOverTimeLoader] = useState(true)

    // source country

    const [vulnOverTime, setVulnOverTime] = useState([
        [
        ]
    ])

    // geo chart 
    const [title, setTitle] = useState();
    const [type, setType] = useState();

    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState('');

    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)


    // console.log("LABELL :", eventCateLabel)
    const [loadTableData, setLoadTableData] = useState(true)



    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    // search filter
    const [searchVulnerQuery, setSearchVulnerQuery] = useState()
    const [filteredListData, setFilteredListData] = useState([])
    const [vulnerLoader, setVulnerLoader] = useState(true)


    const [searchVulnerHostQuery, setSearchVulnerHostQuery] = useState()
    const [filteredVulnerHostListData, setFilteredVulnerHostListData] = useState([])
    const [vulnerHostLoader, setVulnerHostLoader] = useState(true)


    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false)

    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclickedNodeDoc, setexpclickedNodeDoc] = useState()

    const [expclose, setExpClose] = useState(false)



    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-software'


    const getPercentage = (numbers) => {
        var total = numbers.reduce((sum, num) => sum + num, 0);
        var percentages = numbers.map(number => (number / total) * 100);
        return percentages
    }

    const days=7

    useEffect(() => {
        // manageDatas.map((item) => {
        //     item.isSelected = false
        // })

        setLoadTableData(true)
        setVulnerLoader(true)
        setVulnerHostLoader(true)
        setSourceCountryLoader(true)
        setVulnOverTimeLoader(true)
        setVulnbySeverityDataLoader(true)
        setVulnHostScoreLoader(true)
        setVulnAgeLoader(true)
        
        // getUniqueVulnsCount(scanName,days).then(({ data: { aggregations } }) => {
        //     settotalVulnsCount(aggregations?.unique_vulns?.value)
        //     setMostUsedSoftwareLoader(false)

        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getUniqueAssetsCount(scanName,days).then(({ data: { aggregations } }) => {
        //     settotalUniqueAssetCount(aggregations?.unique_assets?.value)

        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        // getUniqueVulnsWithExploits(scanName,days).then(({ data: { aggregations } }) => {
        //     setTotalVulnsWithExploit(aggregations?.unique_vulns?.value)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityQuery(scanName,days).then(({ data: { aggregations } }) => {
        //     let label = [];
        //     let data = [];

        //     let getPercentageData = []
        //     aggregations?.unique_vulns.buckets?.map((data) => {
        //         getPercentageData.push(data?.vulnerability_count?.value)
        //     })
        //     let percentageArr = getPercentage(getPercentageData)
        //     if (aggregations?.unique_vulns.buckets
        //         .length > 0) {
        //         aggregations?.unique_vulns.buckets
        //             .map((item, i) => {
        //                 label.push(item.key === "" ? "--" : `${item.key} - ${item?.vulnerability_count?.value} (${percentageArr[i].toFixed(2)} %)`);
        //                 data.push(item?.vulnerability_count?.value);
        //                 setVulnbySeverityLabel(label)
        //                 setVulnbySeverityData(data)
        //             });
        //         setVulnbySeverityDataLoader(false)
        //     }
        //     else {
        //         setVulnbySeverityLabel(label)
        //         setVulnbySeverityData(data)
        //         setVulnbySeverityDataLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor7Query(scanName).then(({ data: { aggregations } }) => {
        //     setVulnAge7(aggregations?.unique_vulnerability_titles?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor30Query(scanName).then(({ data: { aggregations } }) => {
        //     setVulnAge30(aggregations?.unique_vulnerability_titles?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor90Query(scanName).then(({ data: { aggregations } }) => {
        //     setVulnAge90(aggregations?.unique_vulnerability_titles?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsWithExploitListQuery(scanName,days).then(({ data: { aggregations } }) => {
        //     setTotalVulnsWithExploitList(aggregations?.vulnerability_title?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getUniqueHostWithRiskScoreQuery(scanName, days).then(({ data: { aggregations } }) => {
        //     let label = [];
        //     let data = [];

        //     if (aggregations?.vulnerability_title.buckets
        //         .length > 0) {
        //         aggregations?.vulnerability_title.buckets
        //             .map((item) => {
        //                 label.push(item.key === "" ? "--" : item.key);
        //                 data.push(item?.max_riskScore?.value);
        //                 setVulnHostScorelabel(label)
        //                 setVulnHostScoreData(data)
        //             });
        //         setVulnHostScoreLoader(false)
        //     }
        //     else {
        //         setVulnHostScorelabel(label)
        //         setVulnHostScoreData(data)
        //         setVulnHostScoreLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getvulnsOverTime(scanName).then(({ data: { aggregations } }) => {
        //     const moderateData = { labels: [], data: [] };
        //     const severeData = { labels: [], data: [] };
        //     const criticalData = { labels: [], data: [] };
        //     if (aggregations?.vulnerabilities_over_time?.buckets
        //         .length > 0) {
        //         const moderateData = { labels: [], data: [] };
        //         const severeData = { labels: [], data: [] };
        //         const criticalData = { labels: [], data: [] };

        //         aggregations?.vulnerabilities_over_time?.buckets.forEach(entry => {
        //             const date = entry.key_as_string;
        //             entry.severity_buckets.buckets.forEach(bucket => {
        //                 const severity = bucket.key;
        //                 const count = bucket.vulnerability_count.value;
        //                 if (severity === 'Moderate') {
        //                     moderateData.labels.push(date);
        //                     moderateData.data.push(count);
        //                 } else if (severity === 'Severe') {
        //                     severeData.labels.push(date);
        //                     severeData.data.push(count);
        //                 }
        //                 else if (severity === 'Critical') {
        //                     criticalData.labels.push(date);
        //                     criticalData.data.push(count);
        //                 }
        //             });
        //         });
        //         setModerateGraphData(moderateData)
        //         setCriticalGraphData(criticalData)
        //         setSevereGraphData(severeData)
        //         setVulnOverTimeLoader(false)
        //     }
        //     else {
        //         setModerateGraphData(moderateData)
        //         setCriticalGraphData(criticalData)
        //         setSevereGraphData(severeData)
        //         setVulnOverTimeLoader(false)

        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        getvulnsPerHostListQuery().then(({ data: { aggregations } }) => {
            setVulnsPerHostList(aggregations?.unique_vendors?.buckets)
            setFilteredVulnerHostListData(aggregations?.unique_vendors?.buckets)
            setVulnerHostLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })
        getvulnsListQuery().then(({ data: { aggregations } }) => {
            const sortedBuckets = aggregations?.unique_vendors?.buckets.sort((a, b) => {
                const aValue = getSeverityScore(a?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
                const bValue = getSeverityScore(b?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
                return (isNaN(bValue) ? 0 : bValue) - (isNaN(aValue) ? 0 : aValue);
            });
            setVulnsList(sortedBuckets)
            
            setFilteredListData(sortedBuckets)
            setVulnerLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [page, filter, dateFilter])
    function getSeverityScore(severity) {
        switch (severity) {
          case 'Critical':
            return 5;
          case 'High':
            return 4;
          case 'Medium':
            return 3;
          case 'Low':
            return 2;
          case 'Info':
            return 1;
          default:
            return 0;
        }
      }
      
    useEffect(() => {
        if (searchVulnerQuery?.length === 0) {
            setVulnerLoader(true)
            getvulnsListQuery().then(({ data: { aggregations } }) => {
                const sortedBuckets = aggregations?.unique_vendors?.buckets.sort((a, b) => {
                    const aValue = getSeverityScore(a?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
                    const bValue = getSeverityScore(b?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
                    return (isNaN(bValue) ? 0 : bValue) - (isNaN(aValue) ? 0 : aValue);
                });
                setFilteredListData(sortedBuckets)
                setVulnerLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setVulnerLoader(false)
            })
        }
    }, [searchVulnerQuery,])

    useEffect(() => {
        if (searchVulnerHostQuery?.length === 0) {
            setVulnerHostLoader(true)
            getvulnsPerHostListQuery().then(({ data: { aggregations } }) => {
                setFilteredVulnerHostListData(aggregations?.unique_vendors?.buckets)
                setVulnerHostLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setVulnerHostLoader(false)
            })
        }
    }, [searchVulnerHostQuery])



    const severityChartData = {
        "labels": moderateGraphData?.labels,
        "datasets": [
            {
                "label": "Moderate",
                "data": moderateGraphData?.data,
                backgroundColor: 'rgba(255,165,0,0.4)',
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            },
            {
                "label": "Severe",
                "data": severeGraphData?.data,
                backgroundColor: 'rgba(255,0,0,0.4)',
                borderWidth: 1, // Change this l
                hoverOffset: 0,
            },
            {
                "label": "Critical",
                "data": criticalGraphData?.data,
                backgroundColor: globalStyles.red,
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            }
        ]
    };

    const vulnbySeverity = {
        labels: vulnbySeverityLabel,
        datasets: [
            {
                label: "",
                data: vulnbySeverityData,
                backgroundColor: [
                    // "rgba(255, 99, 132, 0.8)",
                    // "rgba(54, 162, 235, 0.8)",
                    // "rgba(255, 206, 86, 0.8)",
                    // "rgba(75, 192, 192, 0.8)",
                    // "rgba(153, 102, 255, 0.8)",
                    // "rgba(255, 159, 64, 0.8)",
                    'rgba(255,0,0,0.4)',
                    'rgba(255,165,0,0.4)',
                    globalStyles.red,
                ],
                borderColor: [
                    // "rgba(255, 99, 132, 1)",
                    // "rgba(54, 162, 235, 1)",
                    // "rgba(255, 206, 86, 1)",
                    // "rgba(75, 192, 192, 1)",
                    // "rgba(153, 102, 255, 1)",
                    // "rgba(255, 159, 64, 1)",
                    'rgba(255,0,0,0.4)',
                    'rgba(255,165,0,0.4)',
                    globalStyles.red,
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    return (
        <div className="col-12 ">



            {/* table datas */}
            <div className="col-12 d-flex flex-column rounded-3 bg-white p-3 position-relative">

                {/* <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                // onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview"> */}
                <div className='col-12 d-flex flex-column'>
                    <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                        <div className="d-flex flex-column gap-3" style={styles.subSection2}>
                    
                            <div ref={vulnerabilitiesTwoRefImage}>
                                {vulnsList?.length !== 0 && vulnsPerHostList?.length !== 0 &&
                                    <div className="" >
                                        {/* <div className='col-12 flex-fill border rounded-3 p-4 my-4'>
                                            <p>A place to see all vulnerabilities, and Find list of hosts which has a specific vulnerability, or list all vulnerabilities on a host</p>
                                        </div> */}
                                        <div className='d-flex flex-column col-10 py-4'>
                                            <h2>Vulnerability Details Analyzer</h2>
                                        </div>
                                        <div className='col-12 flex-fill border rounded-3 p-4 mb-2'>
                                            <p style={styleVul.paraGraph}>A place to see all vulnerabilities, and Find list of hosts which has a specific vulnerability, or list all vulnerabilities on a host</p>
                                        </div>
                                        <div className="col-12 d-flex flex-row flex-fill p-0 rounded-3 bg-white gap-3">
                                            {vulnsList?.length !== 0 &&
                                                <div className="col-8 p-4 rounded-3 border flex-fill" >

                                                    <div className="d-flex flex-row justify-content-between">
                                                        <h5>Vulnerabilities</h5>
                                                        <SearchBar data={vulnsList} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={setFilteredListData} type={"vulner"} />
                                                    </div>

                                                    <div className='mt-3 custom-scrollbar' style={styles.tableWrapNw}>
                                                        {vulnerLoader ? (
                                                            <div className="col-6">
                                                                <TableLoader rowCount={25} />
                                                            </div>
                                                        ) : filteredListData?.length !== 0 ? (
                                                            <Table borderless hover>
                                                                <thead className="fixed-head">
                                                                    <tr>
                                                                        <th>Vulnerability</th>
                                                                        <th>Score</th>
                                                                        <th>Severity</th>
                                                                        <th>Host Count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredListData?.map((item) => (
                                                                        <tr onClick={() => { setExpClickedNode(item?.key); setExpClose(false); setTitle(`Hosts With: ${item.key}`); setType("vulns"); setexpclickedNodeDoc(item?.doc?.hits?.hits?.[0]) }}>
                                                                            <td>
                                                                                {item?.key}
                                                                            </td>
                                                                            <td>
                                                                                {item?.doc?.hits?.hits?.[0]?._source?.vulnerability_riskScore}
                                                                            </td>
                                                                            <td>
                                                                                {item?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity}
                                                                            </td>
                                                                            <td>
                                                                                {item?.vulns_count?.value}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <NoDataFound errorText={"No data Found"} />
                                                        )}
                                                    </div>

                                                </div>
                                            }

                                            {vulnsPerHostList?.length !== 0 &&
                                                <div className="col-4 p-4 rounded-3 border flex-fill">
                                                    <div className='d-flex flex-row justify-content-between'>
                                                        <h5>Host</h5>
                                                        <div class="search-component input-group" style={{ "width": "auto" }}>
                                                            <SearchBar data={vulnsPerHostList} searchQuery={searchVulnerHostQuery} setSearchQuery={setSearchVulnerHostQuery} setFilteredListData={setFilteredVulnerHostListData} type={"vulnerHost"} />
                                                        </div>
                                                    </div>
                                                    <div className='mt-3 custom-scrollbar' style={styles.tableWrapNw}>
                                                        {vulnerHostLoader ? (
                                                            <div className="col-12">
                                                                <TableLoader rowCount={25} />
                                                            </div>
                                                        ) : filteredVulnerHostListData?.length !== 0 ? (
                                                            <Table borderless hover>
                                                                <thead className="fixed-head">
                                                                    <tr>
                                                                        <th>Host Name</th>
                                                                        <th>Vulnerabilites Found</th>
                                                                        {/* <th>OS</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredVulnerHostListData?.map((item) => (
                                                                        <tr onClick={() => { setExpClickedNode(item?.key); setExpClose(false); setTitle(`Vulnerabilities in Host : ${item.key}`); setType("host") }}>
                                                                            <td>
                                                                                {item?.key}
                                                                            </td>
                                                                            <td>
                                                                                {item?.vulns_count?.value}
                                                                            </td>
                                                                            {/* <td>
                                                                                {item?.doc?.hits?.hits?.[0]?._source?.os}
                                                                            </td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <NoDataFound errorText={"No data Found"} />
                                                        )}
                                                    </div>

                                                </div>
                                            }

                                        </div>
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
                {/* </Tab>
                    <Tab eventKey="table" title="Details"> */}



                {/* </Tab>
                </Tabs> */}
            </div>
            <ThreatLandScapeSideBarVulns clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`} req={fpostDl} title={title} type={type} expclickedNodeDoc={expclickedNodeDoc} />

        </div >
    )
}

export default CyVulnsCykit 