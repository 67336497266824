export const uebaLabel = [
  {
    ueba_id: "UEBA-001",
    label_green: "Logon detected inside of usual time for host",
    label_red: "Logon detected outside of usual time for host",
    type: "Host",
  },
  {
    ueba_id: "UEBA-002",
    label_green: "Logon detected inside of usual time for user",
    label_red: "Logon detected outside of usual time for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-003",
    label_green: "Logon detected to usual host",
    label_red: "Logon detected to unusual host",
    type: "User",
  },
  {
    ueba_id: "UEBA-004",
    label_green: "Usual file activity time for user",
    label_red: "Unusual file activity time for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-005",
    label_green: "Usual user management activity time",
    label_red: "Unusual user management activity time",
    type: "User",
  },
  {
    ueba_id: "UEBA-006",
    label_green: "Usual file modification count for user",
    label_red: "Unusual file modification count for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-008",
    label_green: "Usual user management activity count",
    label_red: "Unusual user management activity count",
    type: "User",
  },
  {
    ueba_id: "UEBA-009",
    label_green: "Usual file deletion count for user",
    label_red: "Unusual file deletion count for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-010",
    label_green: "Usual lockout count by domain",
    label_red: "Unusual lockout count by domain",
    type: "UserDomain",
  },
  {
    ueba_id: "UEBA-011",
    label_green: "Usual file activity count for user",
    label_red: "Unusual file activity count for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-012",
    label_green: "Usual logon failure count for host",
    label_red: "Unusual logon failure count for host",
    type: "Host",
  },
  {
    ueba_id: "UEBA-013",
    label_green: "Usual logon failure count for user",
    label_red: "Unusual logon failure count for user",
    type: "User",
  },
  {
    ueba_id: "UEBA-014",
    label_green: "Usual internal network traffic from host",
    label_red: "Unusual internal network traffic from host",
    type: "Host",
  },
  {
    ueba_id: "UEBA-015",
    label_green: "Usual internal network traffic from user",
    label_red: "Unusual internal network traffic from user",
    type: "User",
  },
  {
    ueba_id: "UEBA-016",
    label_green: "Usual external network traffic from host",
    label_red: "Unusual external network traffic from host",
    type: "Host",
  },
  {
    ueba_id: "UEBA-017",
    label_green: "Usual external network traffic from user",
    label_red: "Unusual external network traffic from user",
    type: "User",
  },

  {
    ueba_id: "UEBA-023",
    label_green: "Authentication failure detected inside of usual time",
    label_red: "Authentication failure detected outside of usual time",
  },

  {
    ueba_id: "UEBA-025",
    label_green: "Usual logon failure count",
    label_red: "Unusual logon failure count",
  },

  {
    ueba_id: "UEBA-026",
    label_green:
      "Office 365 authentication Success detected inside of usual time",
    label_red:
      "Office 365 authentication Success detected outside of usual time",
  },

  {
    ueba_id: "UEBA-027",
    label_green: "Usual file preview count",
    label_red: "Unusual file preview count",
  },

  {
    ueba_id: "UEBA-030",
    label_green: "file download from ondrive inside of usual time ",
    label_red: "file download from ondrive outside of usual time",
  },

  {
    ueba_id: "UEBA-031",
    label_green: "Usual file download from ondrive count",
    label_red: "Unusual file download from ondrive count",
  },

  {
    ueba_id: "UEBA-032",
    label_green: "file preview inside of usual time",
    label_red: "file preview outside of usual time",
  },

  {
    ueba_id: "UEBA-033",
    label_green: "Usual email activity count",
    label_red: "Unusual email activity count",
  },

  {
    ueba_id: "UEBA-034",
    label_green: "Usual email activity time",
    label_red: "Unusual email activity time",
  },
];
