import React, { useEffect, useState } from "react";
import {
  Form,
  Table,
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import globalStyles from '../../../constants/globalStyles';
import { Link } from "react-router-dom";

import { fgetCy, fpostCy } from "../../../axios/axios";
import { SingleLoader, SingleTileLoader } from "../../../components/Loader/SingleTileLoader";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import { PencilSimple, X } from "phosphor-react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const Health = () => {
  const datalakeUrl = localStorage.getItem("DATALAKE_URL")

  const [timeFrame, setTimeFrame] = useState("1h");
  const [tableData, setTableData] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [graphPreData, setGraphPreData] = useState([]);

  const [activeKeyword, setActiveKeyword] = useState(null)

  const dayFormat = {
    unit: 'day',
    unitStepSize: 1,
    displayFormats: {
      'millisecond': 'MMM DD',
      'second': 'MMM DD',
      'minute': 'MMM DD',
      'hour': 'MMM DD',
    }
  }

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
    },
    scales: {
      xAxis: {
        type: 'time',
        time: timeFrame === "1d" ? dayFormat : {}
      }
    },

  };
  const graphData = {
    datasets: [
      {
        label: "Ingested Log Count",
        data: graphPreData,
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.1
      },
    ],

  };
  function parseElasticsearchOutput(output) {
    const lines = output.split('\n');
    const headers = ['health', 'status', 'index', 'uuid', 'pri', 'rep', 'docs.count', 'docs.deleted', 'store.size', 'pri.store.size'];

    const data = lines.map(line => {
        const values = line.split(/\s+/);
        let obj = {};
        headers.forEach((header, i) => {
            obj[header] = values[i];
        });
        return obj;
    });

    return JSON.stringify(data, null, 2);
}
  useEffect(() => {
    setTableIsLoading(true)
    fgetCy(`${datalakeUrl}/_cat/indices`).then(({ data }) => {
      data = JSON.parse(parseElasticsearchOutput(data))
      
      const indexNames = data.map(index => index.index);
      
      const filteredStrings = indexNames.filter(index => index?.includes("logs") || index?.includes("winlogbeat"));

      // Create a Set to store distinct base index names
      const distinctBaseIndexNames = new Set();
      const datePattern = /\d{4}\.\d{2}\.\d{2}(-\d{6})?-?/g;
      // Remove date and trailing numbers, and add to the Set
      filteredStrings.forEach(index => {
        let baseIndex = index.replace(datePattern, ''); // Remove date and trailing hyphen
        baseIndex = baseIndex.replace(/-$/, ''); // Remove trailing hyphen
        distinctBaseIndexNames.add(baseIndex);
      });
      // Convert Set back to an array
      const resultStrings = Array.from(distinctBaseIndexNames);
      const index_names = resultStrings.map(item => { return { "name": item } })
      setTableData(index_names)
      setTableIsLoading(false)
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])

  useEffect(() => {
    let body = {
      "size": 0,
      "query": {
        "range": {
          "@timestamp": {
            "gte": timeFrame === "1d" ? "now-7d" : "now-1d",
            "lte": "now"
          }
        }
      },
      "aggs": {
        "docs_over_time": {
          "date_histogram": {
            "field": "@timestamp",      // Replace with your timestamp field
            "calendar_interval": timeFrame, // Adjust the interval as needed
            "format": "yyyy-MM-dd:HH:mm:ssZ"
          },
          "aggs": {
            "total_docs": {
              "value_count": {
                "field": "_index"  // You can use any field here
              }
            }
          }
        }
      }
    }
    fpostCy(`${datalakeUrl}/${activeKeyword}*/_search`, body).then(({ data }) => {
      setGraphPreData(data?.aggregations?.docs_over_time?.buckets?.map(item => { return { x: moment(item.key_as_string), y: item.doc_count } }))

    }).catch((err) => {
      console.log("Error", err)
    })
  }, [timeFrame, activeKeyword])

  return (
    // <div
    //   className="col-12 p-4 position-relative rounded-3"
    //   style={styles.section1}
    // >
    //   <div
    //     className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
    //     style={styles.filterWrap}
    //   >
    //   </div>

    //   <div className="detections col-12">
    //     <div className="col-12" style={styles.tableWrap}>
    //       {tableIsLoading ? (
    //         <div className="col-12">
    //           <TableLoader rowCount={25} />
    //         </div>
    //       ) : tableData.length !== 0 ? (
    //         <Table borderless hover>
    //           <thead className="fixed-head">
    //             <tr>
    //               <th>Index</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tableData.map((item) => (
    //               <tr>
    //                 <td>
    //                   <Link to={`/settings/index-health/${item.name}`} style={{ textDecoration: 'none', color: '#000000' }}>
    //                     {item.name}
    //                   </Link>
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </Table>
    //       ) : (
    //         <NoDataFound errorText={"No data Found"} />
    //       )}
    //     </div>

    //   </div>

    // </div>

    <>
      <div
        className="d-flex flex-fill flex-column p-0 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >

        <div className='ignore-onclickoutside d-flex flex-column col-12 p-4 overflow-auto' style={{}}>
          <h4 className="px-2">Indexes</h4>
          <ul className='col-12 p-0 m-0 d-flex flex-column gap-3'>
            {tableIsLoading ? (
              <SingleTileLoader width="100%" count="13" />
            ) : (
              <>
                {!tableData ? (
                  <>
                    <NoDataFound errorText={"No data found"} />
                  </>
                ) : (
                  <div style={styles.contentWrap}>
                    {
                      tableData.map((item) => (
                        <li className='p-3 rounded cursor-pointer list-item d-flex align-items-center'
                          style={{
                            backgroundColor:
                              item?.name === activeKeyword
                                ? globalStyles.yellowLite1
                                : globalStyles.white,
                            listStyle: "none"
                          }}

                          onClick={() => {
                            setActiveKeyword(item?.name);
                          }}
                        >
                          <div className='col-10 d-flex flex-column'>
                            <span>
                              {item?.name}
                            </span>
                          </div>
                        </li>
                      ))
                    }
                  </div>
                )}
              </>
            )}
          </ul>
        </div>

      </div>
      <div className="col-8 p-4 rounded-3 bg-white position-relative">
        <div className="d-flex flex-row justify-content-between">
          <h4>Log Ingestion Health of {activeKeyword}*</h4>
          <Form.Select
            style={{ "width": "100px" }}
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.currentTarget.value)}
          >
            <option value="1h">24 Hours</option>
            <option value="1d">7 days </option>
          </Form.Select>
        </div>
        <div className='col-12 d-flex flex-column gap-3 overflow-auto' style={{ paddingRight: "1rem", height: "calc(100% - 10rem)" }}>
          {activeKeyword ? (
            <>
              {graphData.length !== 0 ? (
                <Line options={graphOptions} data={graphData} />
              ) : (
                <NoDataFound errorText={"No data Found"} />
              )}
            </>
          ) : (
            <span style={{ fontSize: "1.5rem", color: "red", fontWeight: "600", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>Select Log</span>
          )}
        </div>

      </div>
    </>
  );
};

export default Health;
