import React, { useEffect, useState } from "react";
import {
  Form,
  Tab,
  Table,
  Tabs,
  FormControl, // Import FormControl for the search bar
} from "react-bootstrap";
import styles from "./Settings.styles";
import TableLoader from "../../components/Loader/TableLoader";
import NoDataFound from "../../components/NoDataFount/NoDataFound";
import globalStyles from '../../constants/globalStyles';
import { Link } from "react-router-dom";

import { fget, fgetCy, fpostCy } from "../../axios/axios";
import { SingleLoader, SingleTileLoader } from "../../components/Loader/SingleTileLoader";
import TableViewMore from "../../components/ViewMoreBtn/TableViewMore";
import { CheckCircle, X } from "phosphor-react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const Logs = () => {
  const datalakeUrl = localStorage.getItem("DATALAKE_URL")
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
 
  const [timeFrame, setTimeFrame] = useState("1h");
  const [tableData, setTableData] = useState([]);
  const [authData, setAuthData] = useState([]);
  const [accessData, setAccessData] = useState([]);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [authTableIsLoading, setAuthTableIsLoading] = useState(false);
  const [accessTableIsLoading, setAccessTableIsLoading] = useState(false);

  const [graphPreData, setGraphPreData] = useState([]);
  const [activeTab, setActiveTab] = useState("auth");

  const [activeKeyword, setActiveKeyword] = useState(null)

  const [searchKeyword, setSearchKeyword] = useState(""); // State for the search bar

  const dayFormat = {
    unit: 'day',
    unitStepSize: 1,
    displayFormats: {
      'millisecond': 'MMM DD',
      'second': 'MMM DD',
      'minute': 'MMM DD',
      'hour': 'MMM DD',
    }
  }

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
    },
    scales: {
      xAxis: {
        type: 'time',
        time: timeFrame === "1d" ? dayFormat : {}
      }
    },

  };
  const graphData = {
    datasets: [
      {
        label: "Ingested Log Count",
        data: graphPreData,
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.1
      },
    ],

  };

  useEffect(() => {
    setTableIsLoading(true)
    fget(`${apiBaseUrl}/core/user/w-status`).then(({ data }) => {
        setTableData(data)
        setTableIsLoading(false)
      }).catch((err) => {
        console.log("Error", err)
      })
  }, [])

  useEffect(() => {
    setAuthTableIsLoading(true)
    fget(`${apiBaseUrl}/core/user/logs/auth?user=${activeKeyword}`).then(({ data }) => {
        setAuthData(data)
        setAuthTableIsLoading(false)
      }).catch((err) => {
        console.log("Error", err)
      })
  }, [activeKeyword])

  useEffect(() => {
    setAccessTableIsLoading(true)
    fget(`${apiBaseUrl}/core/user/logs/access?user=${activeKeyword}`).then(({ data }) => {
        setAccessData(data)
        setAccessTableIsLoading(false)
      }).catch((err) => {
        console.log("Error", err)
      })
  }, [activeKeyword])

  useEffect(() => {
    let body = {
      "size": 0,
      "query": {
        "range": {
          "@timestamp": {
            "gte": timeFrame === "1d" ? "now-7d" : "now-1d",
            "lte": "now"
          }
        }
      },
      "aggs": {
        "docs_over_time": {
          "date_histogram": {
            "field": "@timestamp",      // Replace with your timestamp field
            "calendar_interval": timeFrame, // Adjust the interval as needed
            "format": "yyyy-MM-dd:HH:mm:ssZ"
          },
          "aggs": {
            "total_docs": {
              "value_count": {
                "field": "_index"  // You can use any field here
              }
            }
          }
        }
      }
    }
    fpostCy(`${datalakeUrl}/${activeKeyword}*/_search`, body).then(({ data }) => {
      setGraphPreData(data?.aggregations?.docs_over_time?.buckets?.map(item => { return { x: moment(item.key_as_string), y: item.doc_count } }))

    }).catch((err) => {
      console.log("Error", err)
    })
  }, [timeFrame, activeKeyword])
  const modules = {
    "core": "Core",
    "ti" : "Threat Intelligence",
    "cac" : "Orchestrator - CAC",
    "Compliance" : "Compliance",
    "hunter" : "Orchestrator - Hunter",
    "ueba" : "Orchestrator - UEBA",
  }
  function getModuleFromUrl(url, useragent) {
    const match = url.match(/\/api\/v2\/([^\/]+)\//);
    if (match) {
        if(url==="/api/v2/core/userpermissions" && useragent?.includes("python-requests")){
            return "Datalake";
        }
        else{
            const module = modules[match[1]];
            return module;
        }
    } else {
        return null;
    }
}
  return (
    // <div
    //   className="col-12 p-4 position-relative rounded-3"
    //   style={styles.section1}
    // >
    //   <div
    //     className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
    //     style={styles.filterWrap}
    //   >
    //   </div>

    //   <div className="detections col-12">
    //     <div className="col-12" style={styles.tableWrap}>
    //       {tableIsLoading ? (
    //         <div className="col-12">
    //           <TableLoader rowCount={25} />
    //         </div>
    //       ) : tableData.length !== 0 ? (
    //         <Table borderless hover>
    //           <thead className="fixed-head">
    //             <tr>
    //               <th>Index</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tableData.map((item) => (
    //               <tr>
    //                 <td>
    //                   <Link to={`/settings/index-health/${item.name}`} style={{ textDecoration: 'none', color: '#000000' }}>
    //                     {item.name}
    //                   </Link>
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </Table>
    //       ) : (
    //         <NoDataFound errorText={"No data Found"} />
    //       )}
    //     </div>

    //   </div>

    // </div>

    <>
    <div
      className="d-flex flex-fill flex-column p-0 rounded-3 bg-white gap-3"
      style={styles.overviewWrap}
    >
      <div className='ignore-onclickoutside d-flex flex-column col-12 p-4 overflow-auto'>
        <h4 className="px-2">Users</h4>

        {/* Search Bar */}
        <FormControl
          type="text"
          placeholder="Search user..."
          className="mb-3"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />

        <ul className='col-12 p-0 m-0 d-flex flex-column gap-3'>
          {tableIsLoading ? (
            <SingleTileLoader width="100%" count="13" />
          ) : (
            <>
              {!tableData ? (
                <>
                  <NoDataFound errorText={"No data found"} />
                </>
              ) : (
                <div style={styles.contentWrap}>
                  {
                    tableData
                      .filter(item => item.user_name.toLowerCase().includes(searchKeyword.toLowerCase()))
                      .map((item) => (
                        <li
                          className='p-3 rounded cursor-pointer list-item d-flex align-items-center'
                          style={{
                            backgroundColor:
                              item?.user_name === activeKeyword
                                ? globalStyles.yellowLite1
                                : globalStyles.white,
                            listStyle: "none"
                          }}
                          onClick={() => {
                            setActiveKeyword(item?.user_name);
                          }}
                        >
                          <div className='col-10 d-flex flex-column'>
                            <span>
                              {item?.user_name}
                            </span>
                          </div>
                        </li>
                      ))
                  }
                </div>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
      <div className="col-8 p-4 rounded-3 bg-white position-relative">
        {/* <div className="d-flex flex-row justify-content-between">
          <h4>Logs of User - {activeKeyword}</h4>
          <Form.Select
            style={{ "width": "100px" }}
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.currentTarget.value)}
          >
            <option value="auth">Auth Logs</option>
            <option value="access">Access Logs </option>
          </Form.Select>
        </div> */}
        <div className='col-12 d-flex flex-column gap-3 overflow-auto' style={{ paddingRight: "1rem", height: "calc(100% - 1rem)" }}>
          {activeKeyword ? (
            <>
                <div className="detections col-12">
                    <Tabs
                        defaultActiveKey="auth"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={activeTab}
                        onSelect={(e) => setActiveTab(e)}
                    >
                        <Tab
                        eventKey="auth"
                        // title="Process"
                        title={
                            <React.Fragment>
                            Auth Logs
                            </React.Fragment>
                        }
                        >
                            <div className="col-12" style={styles.tableWrap}>
                                {authTableIsLoading ? (
                                    <div className="col-12">
                                    <TableLoader rowCount={25} />
                                    </div>
                                ) : authData?.length !== 0 ? (
                                    <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                        <th>Time</th>
                                        <th>IP</th>
                                        <th>Device</th>
                                        <th>Country</th>
                                        <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {authData?.map((item) => (
                                        <tr>
                                            <td >
                                            {getDateFromTimestamp(item?.CreationDate)} {getTimeFromTimestamp(item?.CreationDate)}
                                            </td> 
                                            <td>
                                            {item?.EventContextData?.IpAddress}
                                            </td>
                                            <td>
                                            {item?.EventContextData?.DeviceName}
                                            </td>
                                            <td>
                                            {item?.EventContextData?.Country}
                                            </td>
                                            <td>
                                            {item?.EventResponse === "Pass"? <CheckCircle size={16} color={globalStyles.green} /> : <X size={16} />}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </Table>
                                ) : (
                                    <NoDataFound errorText={"No data Found"} />
                                )}
                                </div>
                        </Tab>

                        <Tab
                        eventKey="access"
                        // title="Process"
                        title={
                            <React.Fragment>
                            Access Logs
                            </React.Fragment>
                        }
                        >
                            <div className="col-12" style={styles.tableWrap}>
                                {accessTableIsLoading ? (
                                    <div className="col-12">
                                    <TableLoader rowCount={25} />
                                    </div>
                                ) : accessData?.length !== 0 ? (
                                    <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                        <th>Time</th>
                                        <th>IP</th>
                                        <th>User Agent</th>
                                        <th>Status</th>
                                        <th>Module</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accessData?.map((item) => (
                                        <tr>
                                            <td >
                                            {getDateFromTimestamp(item?.timestamp)} {getTimeFromTimestamp(item?.timestamp)}
                                            </td>
                                            <td>
                                            {item?.ip}
                                            </td>
                                            <td>
                                            {item?.userAgent}
                                            </td>
                                            <td>
                                            {item?.status}
                                            </td>
                                            <td>
                                            {getModuleFromUrl(item?.resourcePath,item?.userAgent)}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </Table>
                                ) : (
                                    <NoDataFound errorText={"No data Found"} />
                                )}
                                </div>
                        </Tab>
                        
                    </Tabs>
                    </div>
            </>
          ) : (
            <span style={{ fontSize: "1.5rem", color: "red", fontWeight: "600", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>Select User</span>
          )}
        </div>

      </div>
    </>
  );
};

export default Logs;
