import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Home.styles";
import { fget, fgetC, fgetD, fpost, fpostC, fpostD } from "../../axios/axios";
import cybotLogo from "../../assets/images/cybotlogo.svg";
import investigateIcon from "../../assets/icons/investigateIcon.png";
import hunterIcon from "../../assets/icons/hunter.png";
import complianceIcon from "../../assets/icons/compliance.png";
import ruleStudioIcon from "../../assets/icons/rule-studio.png";
import analyticsIcon from "../../assets/icons/analytics.png";
import uebaIcon from "../../assets/icons/ueba.png";
import tiIcon from "../../assets/icons/threat-inteligence.png";
import managementDashboardIcon from "../../assets/icons/management-dashboard.png";
import threatLandscapeIcon from "../../assets/icons/warning.png"
import settingsIcon from "../../assets/icons/settings.png";
import caseManagementIcon from "../../assets/icons/case-management.png";
import { cybotLogin, getSystemVar } from "../../Methods/Methods";
import { State } from "../../Context";
import useOnclickOutside from "react-cool-onclickoutside";
import userIcon from "../../assets/images/user-icon.png";
import { SquareCardLoader } from "../../components/Loader/CardLoader";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

const Home = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { setIsLoggedIn } = useContext(State);
  const [accessModule, setAccessModule] = useState([])
  const [showChangePass, setShowChangePass] = useState(false);
  const analyticsMode = localStorage.getItem("ANALYTICS_MODE")
  const [analyticsUrl, setAnalyticsUrl] = useState(localStorage.getItem("analytics-ui-url"))
  const isOnPrem = localStorage.getItem("is_onprem", false)

  const navigate = useNavigate();
  const ref = useOnclickOutside(() => {
    setShowProfileMenu(false);
  });

  // loader
  const [loader, setLoader] = useState(true)

  // follow this method to call api
  useEffect(() => {
    loadData()
  }, []);
  const loadData = async () => {
    await licenseInfo();
    setTimeout(setLoader(false), 2000)
  }
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")

  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/", { replace: true });
  };

  const licenseInfo = async () => {
    let accessVars = JSON.parse(localStorage.getItem("accessVars"));
    if (accessVars) {
      await setAccessModule(accessVars.modules)
    }
    else {
      let accessCheckData = await fget(
        `${apiBaseUrl}/core/userpermissions`
      )
      await localStorage.setItem("accessVars", JSON.stringify(accessCheckData.data))
      await setAccessModule(accessCheckData.data.modules)
    }
    setLoader(false)
    // let { data: { analytics, compliance, hunter, investigate, rulesstudio, rulesstudioadmin, ti, ueba } } = accessCheckData
  }

  // const getSystemVar = async () => {
  //   try {
  //     const response = await fget(
  //       // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
  //       `${process.env.REACT_APP_SSO_URL_V2}/core/systemvars`
  //       // "https://api-gw1.active-bytes.com/api/v2/core/systemvars"
  //     );



  //     if (response.data.datalakeuname && response.data.datalakepass) {
  //       // localStorage.setItem("ACCESS_TOKEN", "Basic" + btoa(`${data.datalakeuname}:${data.datalakepass}`))
  //       await localStorage.setItem(
  //         "DATALAKE_ACCESS_TOKEN",
  //         `Basic ${btoa(
  //           `${response.data.datalakeuname}:${response.data.datalakepass}`
  //         )}`
  //       );
  //     }
  //     if (response.data.datalakeurl) {
  //       localStorage.setItem("DATALAKE_URL", response.data.datalakeurl);
  //     }
  //     if (response.data.cyboturl) {
  //       localStorage.setItem("CYBOT_URL", response.data.cyboturl);
  //     }
  //     if (response.data.datalakeuname && response.data.datalakepass) {
  //       localStorage.setItem("DatalakeUname", response.data.datalakeuname);
  //       localStorage.setItem("DatalakePass", response.data.datalakepass);
  //     }
  //     if (response.data.edr_type) {
  //       localStorage.setItem("EDR_TYPE", response.data.edr_type);
  //     }
  //     if (response.data.cybot_cykit_index_pattern) {
  //       localStorage.setItem("CYBOT_CYKIT_INDEX_PATTERN", response.data.cybot_cykit_index_pattern);
  //     }
  //     if (response.data.cybot_cykit_url) {
  //       localStorage.setItem("CYBOT_CYKIT_URL", response.data.cybot_cykit_url);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_firewall").length > 0) {
  //       localStorage.setItem("INVESTIGATE_CYF", response.data.variables.filter((item) => item.name === "investigate_cykit_firewall")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_ad").length > 0) {
  //       localStorage.setItem("INVESTIGATE_CYAD", response.data.variables.filter((item) => item.name === "investigate_cykit_ad")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_email").length > 0) {
  //       localStorage.setItem("INVESTIGATE_CYEMAIL", response.data.variables.filter((item) => item.name === "investigate_cykit_email")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_waf").length > 0) {
  //       localStorage.setItem("INVESTIGATE_CYWAF", response.data.variables.filter((item) => item.name === "investigate_cykit_waf")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_atp_alerts").length > 0) {
  //       localStorage.setItem("INVESTIGATE_CY_ATP_ALERTS", response.data.variables.filter((item) => item.name === "investigate_cykit_atp_alerts")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "investigate_cykit_o365").length > 0) {
  //       localStorage.setItem("INVESTIGATE_o365", response.data.variables.filter((item) => item.name === "investigate_cykit_o365")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "cykit_combined_pattern").length > 0) {
  //       localStorage.setItem("CYBOT_CYKIT_COMBINED_PATTERN", response.data.variables.filter((item) => item.name === "cykit_combined_pattern")[0].value);
  //     }
  //     if (response.data.variables.filter((item) => item.name === "is_unified").length > 0) {
  //       localStorage.setItem("CYBOT_IS_UNIFIED", response.data.variables.filter((item) => item.name === "is_unified")[0].value);
  //     }
  //     if (
  //       response.data.cybotuname &&
  //       response.data.cybotpass &&
  //       response.data.cyboturl
  //     ) {

  // localStorage.setItem("CM_ACCESS_TOKEN", btoa(`${response.data.cybotuname}:${response.data.cybotpass}`));

  //       localStorage.setItem("CM_ACCESS_TOKEN", btoa(`${response.data.cybotuname}:${response.data.cybotpass}`));

  //       try {
  //         let userData = {
  //           username: response.data.cybotuname,
  //           password: response.data.cybotpass,
  //         };

  //         const response2 = await fpost(
  //           `${response.data.cyboturl}/authenticate`,
  //           userData
  //         );


  //         response2.data.user_token &&
  //           localStorage.setItem(
  //             "CYBOT_ACCESS_TOKEN",
  //             response2.data.user_token
  //           );

  //       } catch (error) {
  //         console.log("authenticate error", error);
  //       }
  //     }

  //     // cybotLogin(userData, response.datacyboturl)
  //     //   .then(({ data: { user_token } }) => {
  //     //     user_token &&
  //     //       localStorage.setItem("CYBOT_ACCESS_TOKEN", response.data.user_token);
  //     //   })
  //     //   .catch((err) => {
  //     //     // alert("Error", err);
  //     //     console.log("error", err);
  //     //   });
  //   } catch (error) {
  //     console.log("home error", error);
  //   }

  //   setLoader(false)

  // };

  return (
    <div
      className="d-flex flex-column overflow-hidden"
      style={styles.mainContainer}
    >
      {/* <div className="container-fluid" style={styles.topnav}>
        <div className="px-1">
          <img
            src={cybotLogo}
            alt="Cybot Logo"
            style={styles.logo}
            className="mt-4 mb-2 px-2"
          />
        </div>
      </div> */}
      <div className="container-fluid h-100 d-flex flex-row p-0" style={styles.mainWrap}>
        {/* <div className="d-flex col-12 p-0 flex-row h-100"> */}
        <div
          className="col-8 d-flex gap-4 px-2 flex-wrap rounded-0 h-100"
          style={styles.sidebar}
        >
          <div className="px-2">
            <img
              src={cybotLogo}
              alt="Cybot Logo"
              style={styles.logo}
              className="mt-4 mb-2 px-2"
            />
          </div>
        </div>
        <div
          className="col-4 h-100 d-flex justify-content-between flex-column"
          style={styles.contentWrap}
        >
          {/* Logout and profile */}
          <div className="position-relative d-flex justify-content-end m-3 px-2 py-2">
            <div
              className="d-flex flex-row align-items-center gap-2 cursor-pointer mx-3 py-1"
              ref={ref}
              onClick={() => {
                setShowProfileMenu(!showProfileMenu);
              }}
            >
              <div
                className="rounded-5 my-2"
                type="button"
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: "#72bcc7",
                  color: "#FFFFFF",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex"
                }}
                ref={ref}
                onClick={() => setShowProfileMenu(!showProfileMenu)}
              >
                {/* <img className="w-100" src={userIcon} /> */}
                <h5 className="text-uppercase m-0">{localStorage.getItem("userLetter")}</h5>
              </div>
              <h6 className="text-capitalize m-0" style={{ color: "#FFFFFF" }}>{localStorage.getItem("loggedUser")}</h6>
            </div>

            {showProfileMenu && (
              <div
                className="position-absolute rounded-3 d-flex flex-column flex-wrap gap-3 mt-5 shadow-sm ignore-onclickoutside"
                style={styles.profileMenu}
              >
                {!isOnPrem && <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowChangePass(true)}
                >
                  Change Password
                </div>}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
          {/*  */}
          <div className="d-flex gap-4 h-100 p-4 overflow-auto align-items-center justify-content-center flex-column">
            {loader ? (
              <SquareCardLoader />
            ) : (
              <div className="d-flex flex-wrap gap-4 overflow-auto" style={styles.tileWrap}>
                {(accessModule?.ueba?.read === "true" || accessModule?.ueba?.read === true) &&
                  <Link to="/ueba/dashboard" className="col-3 home-icon-tile" onClick={() => window.location.href = "/ueba/dashboard"}
                    style={styles.tile}>
                    <img src={uebaIcon} className="px-2" style={styles.tileImage} />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        UEBA
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.ti?.read === "true" || accessModule?.ti?.read === true) &&
                  <Link to="/ti/activebytes" className="col-3 home-icon-tile"
                    onClick={() => window.location.href = "/ti/activebytes"}
                    style={styles.tile}>
                    <img src={tiIcon} className="px-2" style={styles.tileImage} />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Threat Intelligence
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.analytics?.read === "true" || accessModule?.analytics?.read === true) &&
                  <Link className="col-3"
                    to={analyticsMode === "tab" ? "" : "/analytics/securityanalytics"}
                    onClick={() => analyticsMode === "tab" ? window.open(analyticsUrl, "_blank") : window.location.href = "/analytics/securityanalytics"}
                    style={styles.tile}>
                    <img
                      src={analyticsIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Analytics
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.hunter?.read === "true" || accessModule?.hunter?.read === true) &&
                  <Link
                    to="/hunter/detections"
                    onClick={() => window.location.href = "/hunter/detections"}
                    className="col-3 home-icon-tile"
                    style={styles.tile}
                  >
                    <img
                      src={hunterIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Hunter
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.investigate?.read === "true" || accessModule?.investigate?.read === true) &&
                  <Link to="/investigate/dashboard" className="col-3 home-icon-tile"
                    onClick={() => window.location.href = "/investigate/dashboard"}
                    style={styles.tile}>
                    {/* <img
                src="https://cybotdev.active-bytes.com/assets/images/InvsetigateIcon.png"
                className="px-2"
                style={styles.tileImage} /> */}
                    <img
                      src={investigateIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Investigate
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.rulesstudio?.read === "true" || accessModule?.rulesstudio?.read === true) &&
                  <Link
                    to="/rs/investigation"
                    onClick={() => window.location.href = "/rs/investigation"}
                    className="col-3 home-icon-tile"
                    style={styles.tile}
                  >
                    <img
                      src={ruleStudioIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Orchestrator
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.compliance?.read === "true" || accessModule?.compliance?.read === true) &&
                  <Link
                    to="/compliance/maindashboard"
                    onClick={() => window.location.href("/compliance/maindashboard")}
                    //  onClick={() => window.location.href="/compliance/controlview"}

                    //  to="/compliance/controlview"
                    className="col-3 home-icon-tile"
                    style={styles.tile}
                  >
                    <img
                      src={complianceIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Compliance
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>
                }

                {(accessModule?.management_dash?.read === "true" || accessModule?.management_dash?.read === true) &&
                  <Link
                    to="/management/dashboard"
                    onClick={() => window.location.href = "/management/dashboard"}
                    className="col-3 home-icon-tile"
                    style={styles.tile}
                  >
                    <img
                      src={managementDashboardIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Managment Dashboard
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>}

                {(accessModule?.threat_landscape?.read === "true" || accessModule?.threat_landscape?.read === true) && <Link
                  to="/threat_landscape/dashboard"
                  onClick={() => window.location.href = "/threat_landscape/dashboard"}
                  className="col-3 home-icon-tile"
                  style={styles.tile}
                >
                  <img
                    src={threatLandscapeIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <div style={styles.tileTextWrap}>
                    <h6 className="" style={styles.tileTitle}>
                      Threat Landscape
                    </h6>
                    <p style={styles.tileDescription}>
                      Track anomalous activities in the environment
                    </p>
                  </div>
                </Link>}

                {(accessModule?.["casemgmt"]?.read === "true" || accessModule?.["casemgmt"]?.read === true) &&
                  <Link
                    to="/case/management"
                    onClick={() => window.location.href = "/case/management"}
                    className="col-3 home-icon-tile"
                    style={styles.tile}
                  >
                    {console.log("case")}
                    <img
                      src={caseManagementIcon}
                      className="px-2"
                      style={styles.tileImage}
                    />
                    <div style={styles.tileTextWrap}>
                      <h6 className="" style={styles.tileTitle}>
                        Case Management
                      </h6>
                      <p style={styles.tileDescription}>
                        Track anomalous activities in the environment
                      </p>
                    </div>
                  </Link>}

              </div>
            )}
          </div>
          {/* </div> */}
        </div>
        {showChangePass && (
          <ChangePassword showChangePass={showChangePass} setShowChangePass={setShowChangePass} />
        )}
        <div className="text-center p-4" style={styles.copyright}>
          <div className="d-flex flex-column">
            <span style={{ color: "#FFFFFF" }}>
              © 2023 ActiveBytes®. All Rights Reserved.
            </span>
            <span style={{ color: "#FFFFFF" }}>
              Version 2.5 Build 3452
            </span>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Home;