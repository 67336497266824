import {
  getCyEmailTableQuery,
  getCyLinuxTableQuery,
  getMostCommonSRAQuery,
  getMostPerformedQuery,
  getUniqueCountsQuery,
  getUniqueSRCountsQuery,
  getFireWallTrafficQuery,
  getOffice365AdCountsQuery,
  getSourceCountyQuery,
  getDBOQuery,
  getUserIdQuery,
  getCyO365AdTableQuery,
  getOffice365ODCountsQuery,
  getOneDriveSourceCountyQuery,
  getOneDriveQuery,
  getOneDriveOPUQuery,
  getCyO365OdTableQuery,
  getOffice365ExchangeCountsQuery,
  getExchangeSourceCountyQuery,
  getExchangeOUQuery,
  getCy365ExchangeTableQuery,
  getUniqueHDCountsQuery,
  getHDQuery,
  getCyATPAlertsTableQuery,
  getCYUnifiedTableQuery,
  getUniqueDetectionTypeCountsQuery,
} from "../Queries/CyKitInvestigateQuery";
import {
  TotalCountsQuery,
  WafTableDataQuery,
  getCountsQuery,
  getWidgetsQuery,
  tableDataQuery,
} from "../Queries/InvestigationQuery";
import { fpostC, fpostDl } from "../axios/axios";

const baseURL = localStorage.getItem("CYBOT_URL");
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYEMAIL = localStorage.getItem("INVESTIGATE_CYEMAIL");
const HUNTER_CYKIT_LOG_PATTERN = localStorage.getItem("HUNTER_CYKIT_LOG_PATTERN");

const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
const INVESTIGATE_CYWAF = localStorage.getItem("INVESTIGATE_CYWAF");
const INVESTIGATE_CY_ATP_ALERTS = localStorage.getItem(
  "INVESTIGATE_CY_ATP_ALERTS"
);
const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365")
  ? localStorage.getItem("INVESTIGATE_o365")
  : "/logs-o365*";

// cykit Linux

export async function getUniqueCounts(field_name) {
  let data = getUniqueCountsQuery(field_name);

  return await fpostDl(
    `${datalakeURL}/filebeat*/_search?track_total_hits=true`,
    data
  );
}

export async function getMostPerformed(field_name) {
  let data = getMostPerformedQuery(field_name);

  return await fpostDl(
    `${datalakeURL}/filebeat*/_search?track_total_hits=true`,
    data
  );
}

export async function getCyLinuxTable() {
  let data = getCyLinuxTableQuery();

  return await fpostDl(
    `${datalakeURL}/filebeat*/_search?track_total_hits=true`,
    data
  );
}

// cykit Email

export async function getUniqueSRCounts(field_name, filter, date) {
  let data = getUniqueSRCountsQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYEMAIL}/_search?track_total_hits=true`,
    data
  );
}

export async function getMostCommonSRA(field_name, filter, date) {
  let data = getMostCommonSRAQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYEMAIL}/_search?track_total_hits=true`,
    data
  );
}

export async function getCyEmailTable(filter, date, size, sort, page) {
  let data = getCyEmailTableQuery(filter, date, size, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYEMAIL}/_search?track_total_hits=true`,
    data
  );
}

export async function getCYUnifiedTable(filter, date, size, sort, page) {
  let data = getCYUnifiedTableQuery(filter, date, size, sort, page);

  return await fpostDl(
    `${datalakeURL}${HUNTER_CYKIT_LOG_PATTERN}/_search?track_total_hits=true`,
    data
  );
}

export async function getFireWallTraffic(date) {
  let data = getFireWallTrafficQuery(date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYF}/_search?track_total_hits=true`,
    data
  );
}

// cykit waf

export async function getTotalCounts(filter, date) {
  let data = TotalCountsQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
    data
  );
}

export async function getCounts(field_name, filter, date) {
  let data = getCountsQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
    data
  );
}

export async function getWidgetsData(
  field_name,
  count,
  sort,
  filter,
  date,
  dateRange
) {
  let data = getWidgetsQuery(field_name, count, sort, filter, date, dateRange);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
    data
  );
}

export async function getCyTableData(size, sort, page, filter, date) {
  let data = WafTableDataQuery(size, sort, page, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
    data
  );
}

// cykit ATP Alerts

export async function getUniqueHDCounts(field_name, filter, dateFilter) {
  let data = getUniqueHDCountsQuery(field_name, filter, dateFilter);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_ATP_ALERTS}/_search?track_total_hits=true`,
    data
  );
}

export async function getUniqueDetectionTypeCounts(field_name, filter, dateFilter) {
  let data = getUniqueDetectionTypeCountsQuery(field_name, filter, dateFilter);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_ATP_ALERTS}/_search?track_total_hits=true`,
    data
  );
}

export async function getHD(field_name, filter, dateFilter, isGraph) {
  let data = getHDQuery(field_name, filter, dateFilter, isGraph);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_ATP_ALERTS}/_search?track_total_hits=true`,
    data
  );
}

// table
export async function getCyATPAlertsTable(filter, date, size, order, page) {
  let data = getCyATPAlertsTableQuery(filter, date, size, order, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_ATP_ALERTS}/_search?track_total_hits=true`,
    data
  );
}

// 0ffice 365 ////////////////////////////////
// O 365 ad

export async function getOffice365AdCounts(field_name, filter, dateFilter) {
  let data = getOffice365AdCountsQuery(field_name, filter, dateFilter);
  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// graph

export async function getSourceCounty(filter, date) {
  let data = getSourceCountyQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// get " D " device os , " B " browser type ,  " O " operation

export async function getDBO(field_name, filter, date) {
  let data = getDBOQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// user id

export async function getUserId(filter, date) {
  let data = getUserIdQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// table

export async function getCyO365AdTable(filter, date, size, sort, page) {
  let data = getCyO365AdTableQuery(filter, date, size, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// 0ffice 365 ////////////////////////////////
// O 365 one drive

export async function getOffice365ODCounts(field_name, filter, date) {
  let data = getOffice365ODCountsQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export async function getOneDriveSourceCounty(filter, date) {
  let data = getOneDriveSourceCountyQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export async function getOneDriveOPU(
  field_name,
  matchPhrase,
  filter,
  dateFilter
) {
  let platForm = {
    match_phrase: {
      Platform: "NotSpecified",
    },
  };

  let data;
  if (matchPhrase) {
    data = getOneDriveOPUQuery(field_name, platForm, filter, dateFilter);
  } else {
    data = getOneDriveOPUQuery(field_name, "", filter, dateFilter);
  }

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// table

export async function getCyO365OdTable(filter, date, size, sort, page) {
  let data = getCyO365OdTableQuery(filter, date, size, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

// 0ffice 365 ////////////////////////////////
// O 365 Exchange

export async function getOffice365ExchangeCounts(
  field_name,
  filter,
  dateFilter
) {
  let data = getOffice365ExchangeCountsQuery(field_name, filter, dateFilter);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export async function getExchangeSourceCounty(filter, date) {
  let data = getExchangeSourceCountyQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export async function getExchangeOU(field_name, filter, date) {
  let data = getExchangeOUQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export async function getCy365ExchangeTable(filter, date, count, sort, page) {
  let data = getCy365ExchangeTableQuery(filter, date, count, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}
