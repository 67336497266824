// Headlines
export const TI_HEADLINES_LIST_REQUEST = "TI_HEADLINES_LIST_REQUEST";
export const TI_HEADLINES_LIST_SUCCESS = "TI_HEADLINES_LIST_SUCCESS";
export const TI_HEADLINES_LIST_FAIL = "TI_HEADLINES_LIST_FAIL";

export const TI_VULNERABILITIES_LIST_REQUEST = "TI_VULNERABILITIES_LIST_REQUEST";
export const TI_VULNERABILITIES_LIST_SUCCESS = "TI_VULNERABILITIES_LIST_SUCCESS";
export const TI_VULNERABILITIES_LIST_FAIL = "TI_VULNERABILITIES_LIST_FAIL";

export const TI_VULEX_LIST_REQUEST = "TI_VULEX_LIST_REQUEST";
export const TI_VULEX_LIST_SUCCESS = "TI_VULEX_LIST_SUCCESS";
export const TI_VULEX_LIST_FAIL = "TI_VULEX_LIST_FAIL";

export const TI_SECURITYEVENTS_LIST_REQUEST = "TI_SECURITYEVENTS_LIST_REQUEST";
export const TI_SECURITYEVENTS_LIST_SUCCESS = "TI_SECURITYEVENTS_LIST_SUCCESS";
export const TI_SECURITYEVENTS_LIST_FAIL = "TI_SECURITYEVENTS_LIST_FAIL";
