import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import ReactJson from "react-json-view";

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./ExpandedDocument.styles";
import TableLoader from "../Loader/TableLoader";
import NoDataFound from "../NoDataFount/NoDataFound";
import SearchBar from "../Search/ManagementDashboard/SearchBar";

export default function ThreatLandScapeSideBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const [softwareHostData, setSoftwareHostData] = useState([])
  const [hostCount, setHostCount] = useState(0)

  const [hostData, sethostData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    if (props.clickedNode !== undefined && props.clickedNode !== "") {
      var pattern = props?.pattern
      if (pattern?.charAt(0) === '/') {
        // If it does, remove the first character
        pattern = pattern?.slice(1);
      }
      setIsOpen(true);
      if (props.type === "host") {
        var data = JSON.stringify({
          "query": {
            "bool": {
                    must:[
                      {
                        "match": {
                          "hostname": props.clickedNode
                        }
            
                      }
                    ],
                    must_not:[
                      {
                        "match":{
                          "software_product" : ""
                          }
                        }
                    ]
            }
          },
          "size": 0,
          "aggs": {
            "unique_software": {
              "terms": {
                "field": "software_product",
                "size": 10000
              },
              "aggs": {
                "host_count": {
                  "cardinality": {
                    "field": "hostname"
                  }
                },
                "doc": {
                  "top_hits": {
                    "size": 1
                  }
                }
              }
            }
          }
        });
      }
      else {
        var data = JSON.stringify({
          "query": {
            "bool": {
                    must:[
                      {
                        "match": {
                          "software_product": props.clickedNode
                        }
            
                      }
                    ],
                    must_not:[
                      {
                        "match":{
                          "software_product" : ""
                          }
                        }
                    ]
            }
          },
          "size": 0,
          "aggs": {
            "unique_software": {
              "terms": {
                "field": "hostname",
                "size": 10000
              },
              "aggs": {
                "doc": {
                  "top_hits": {
                    "size": 1
                  }
                }
              }
            }
          }
        });
      }
      var count = 0
      props.req(
        `${props.url}/${pattern}`,
        data).then((response) => {
          // props.parentData(response.data)
          console.log(response.data);
          response?.data?.aggregations?.unique_software?.buckets && setSoftwareHostData(response?.data?.aggregations?.unique_software?.buckets);
          count = response?.data?.aggregations?.unique_software?.buckets?.length + response?.data?.aggregations?.unique_software?.sum_other_doc_count
          setHostCount(count)
          response?.data?.aggregations?.unique_software?.buckets && sethostData(response?.data?.aggregations?.unique_software?.buckets);
          setIsLoading(false)

        })
      // setIsLoading(false)

    }
  }, [props.clickedNode]);
  const [searchVulnerQuery, setSearchVulnerQuery] = useState()

  useEffect(() => {
    if (props.close === true) {
      setIsOpen(false)
      props.setClick('')
    }
  }, [props.close])



  const handleClose = () => {
    props.setClick('')
    setIsLoading(true)
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal mt-2"
              id="offcanvasRightLabel"
            >
              {props.title ? props.title : "Expanded Document"}
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar" style={styles.tableWrap}>

            {isLoading ?
              <div style={{ "position": "fixed", "right": "16%", "top": "55%" }}><Spinner animation="border" role="status" /></div> :
              <div className="py-0" style={styles.sidebarContentWrap}>

                {/* <h5>Least Installed Softwarex</h5> */}
                {props.type === "host" ? <div className=''  >
                  <div className="d-flex flex-row justify-content-between">
                    <div className="modal-row-item mb-2" >
                      <span className="modal-text me-3" style={{ fontSize: "12px" }}>Total Softwares Installed On Host: </span>
                      <span className="modal-text" style={{ fontSize: "12px" }}>
                        {hostCount}
                      </span>
                    </div>
                    <div className="d-flex justify-content-end mb-2" >
                      <SearchBar data={softwareHostData} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={sethostData} type={"Software"} />
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="col-6">
                      <TableLoader rowCount={25} />
                    </div>
                  ) : hostData?.length !== 0 ? (
                    <>

                      <Table borderless hover style={styles.sidebarContentWrap}>
                        <thead className="fixed-head">
                          <tr>
                            <th>Software</th>
                            <th>Version</th>
                            {/* <th className="text-center">Is SSO User</th>
                                                        <th>Status</th>
                                                        <th className="text-right">Manage</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {hostData?.map((item) => (
                            <tr >
                              <td>
                                {item?.key}
                              </td>
                              
                              <td>
                                {item?.doc?.hits?.hits?.[0]?._source?.software_version}
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <NoDataFound errorText={"No data Found"} />
                  )}
                </div> :
                  <div className='' >
                    <div className="d-flex flex-row justify-content-between">
                      <div className="modal-row-item mb-2" >
                        <span className="modal-text me-3" style={{ fontSize: "12px" }}>Total Hosts : </span>
                        <span className="modal-text" style={{ fontSize: "12px" }}>
                          {hostCount}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end mb-2">
                        <SearchBar data={softwareHostData} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={sethostData} type={"SoftHost"} />
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="col-12">
                        <TableLoader rowCount={25} />
                      </div>
                    ) : hostData?.length !== 0 ? (
                      <>

                        <Table borderless hover style={styles.sidebarContentWrap}>
                          <thead className="fixed-head" >
                            <tr>
                              <th>Host Name</th>
                              {/* <th>OS</th> */}

                              {/* <th>Softwares Installed</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {hostData?.map((item) => (
                              <tr>
                                <td>
                                  {item?.key}
                                </td>
                                {/* <td>
                                  {item?.doc?.hits?.hits?.[0]?._source?.os}
                                </td> */}
                                {/* <td>
                                                                            {item?.software_count?.value}
                                                                        </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <NoDataFound errorText={"No data Found"} />
                    )}
                  </div>}
                {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
              </div>}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}