import globalStyles from "../../../constants/globalStyles";

const styles = {
  section1: {
    height: "calc(100vh - 18em)",
    // height: "calc(85vh - 11em)",
    paddingRight: "1rem",
    overflow: "auto"
  },
  filterWrap: {
    position: "absolute",
    right: "1.5rem",
    top: "14px",
    zIndex: 3
  },
  tableWrap: {
    height: "calc(100vh - 15em)",
    overflow: "auto",
  },
  tableWrapNw: {
    height: "calc(100vh - 28em)",
    overflow: "auto",
  },
  tableWrapSm: {
    height: "300px",
    overflow: "auto",
  },
  subSection1: {
    height: "calc(100vh - 15em)",
    paddingRight: "1rem",
    overflow: "auto",
  },
  overviewWrap: {
    height: "calc(100vh - 8em)",
    overflow: "auto",
    // paddingRight: "1rem"
  },// count tile styles
  overviewTile: {
    // backgroundColor: "#f1f1f1",
    backgroundColor: "#DBEEFF",
    // height: "100px",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    // justifyContent: "space-between",
  },
  overviewIcon: {
    width: "50px",
    height: "50px",
    fontSize: "28px",
    backgroundColor: "#47A8FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    color: "#FFFFFF",
  },
  overviewTileOrange: {
    backgroundColor: globalStyles.orangeLite1,
  },
  overviewIconOrange: {
    backgroundColor: globalStyles.orangeDark1,
  },
  overviewTilePurple: {
    backgroundColor: globalStyles.purpleLite1,
  },
  overviewIconPurple: {
    backgroundColor: globalStyles.purpleDark1,
  },
  overviewTileGreen: {
    backgroundColor: globalStyles.greenLite1,
  },
  overviewIconGreen: {
    backgroundColor: globalStyles.greenDark1,
  },
  tile: {
    backgroundColor: "#f1f1f1",
  },
  paginationWrap: {
    alignItems: "center",
    justifyContent: "flex-end",
    height: "50px",
    // backgroundColor: "#CCCCCC",
    position: "absolute",
    bottom: "1em",
    right: "0px"
  },
  huntStep: {
    width: "30px",
    height: "30px",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    marginBottom: "0px"
  },
  label: {
    fontSize: 16,
  }
};

export default styles;
