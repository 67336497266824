import React, { useEffect, useState , useRef } from "react";
import { Pie  } from 'react-chartjs-2';
import "../../../index.css";
import styles from "./MainDashboard.styles";
import { Button, Form,Table, Tab, Tabs,Badge } from "react-bootstrap";
import iso from "../../../assets/compliance/compliance-iso27001.json";
import nist from "../../../assets/compliance/compliance-NIST.json";
import pci from "../../../assets/compliance/compliance-PCI-DSS.json";
import { fget, fpost } from "../../../axios/axios";
import { CaretDown, CaretRight, ArrowClockwise, ArrowsOutSimple, Pencil } from "phosphor-react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { 
  getIso,getNist,getCompName,getPci,getHostName,getUserName
 } from "../../../Methods/ComplianceMethods";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import TableLoader from '../../../components/Loader/TableLoader'
import PieChartLoader from "../../../components/Loader/PieChartLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import RecentTab from "../../../components/RecentTab/RecentTab";
import moment from 'moment';

import * as htmlToImage from 'html-to-image';
import { PDFDownloadLink } from "@react-pdf/renderer";
import OverviewIso27001 from "../../../components/PDF/Compliance/OverviewIso27001";
import PlainCardLoader from "../../../components/Loader/PlainCardLoader";
import ReactApexCharts from 'react-apexcharts';



import { FaTruckMedical } from "react-icons/fa6";

const Overview = ({ selectedDateFilter, setActiveSubTab,activeSubTab }) => {
  const [selectedFilter, setSelectedFilter] = useState("ISO-27001");
  const [selectedData, setSelectedData] = useState({});
  const [annexData, setAnnexData] = useState({});
  const [annexBData, setAnnexBData] = useState({});
  const [showInfoDown, setShowInfoDown] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [editHeading, setEditHeading] = useState(false);
  const [getInfoData, setGetInfoData] = useState({});
  const [showIndex, setShowIndex] = useState([]);
  const [moreInfoText, setMoreInfoText] = useState();
  const [loading, setLoading] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [status, setStatus] = useState("");
  const [savedFormattedId, setSavedFormattedId] = useState("");
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [activeTab, setActiveTab] = useState("main");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  const [tableLoading, setTableLoading] = useState(false);
  const [pieLoading, setPieLoading] = useState(true);
  const [isoData,setIsoData] =useState([]);
  const [nistData,setNistData] =useState([]);
  const [pciData,setPciData] =useState([]);
  const [comUserData,setComUserData] =useState([])
  const [hostUserData,setHostUserData] =useState([])
  const [userData,setUserData] =useState([])

  const [recentCompLoader, setRecentCompLoader] = useState(true)
  const [stat, setStat] = useState("")
  const [totalProportion, setTotalProportion] = useState(0);
  const [newTotalProportion, setNewTotalProportion] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState(localStorage.getItem("activeTab.compliance") ? localStorage.getItem("activeTab.compliance") : "OVERVIEW")

// Expanded table
const [itemsByStatusState, setItemsByStatusState] = useState({});
const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  // table percentagedata
  const [totalCount, setTotalCount] = useState(0);
  const [countt,setCountt]= useState(0)
  const [statusPercentages, setStatusPercentages] = useState({
    Unknown: 0,
    Nonexistent: 0,
    Initial: 0,
    Limited: 0,
    Defined: 0,
    Managed: 0,
    Optimised: 0,
    "NotApplicable": 0
});

  // Download pdf
  const [showDownloadButton, setShowDownloadButton] = useState(false)

  // imageblob
  const tableIso27RefImage = useRef(null);
  const pieIso27RefImage = useRef(null);
  const tableIso22RefImage = useRef(null);
  const pieIso22RefImage = useRef(null);
  const tableNistRefImage = useRef(null);
  const pieNistRefImage = useRef(null);
  const tablePciRefImage = useRef(null);
  const piePciRefImage = useRef(null);
  const tableIsaRefImage = useRef(null);
  const pieIsaRefImage = useRef(null);

  const isoRefImage = useRef(null);
  const cardIsoRefImage = useRef(null);
  const cardNistRefImage = useRef(null);
  const cardPciRefImage = useRef(null);
  const nistRefImage = useRef(null);
  const pciRefImage = useRef(null);
  const cardIso22RefImage = useRef(null);
  const cardIsaRefImage = useRef(null);




  const [imageBlob, setImageBlob] = useState({
    tableIso27: null,
    pieIso27: null,
    iso:null,
    tableIso22: null,
    pieIso22: null,
    tableNist: null,
    pieNist: null,
    tablePci: null,
    piePci: null,
    tableIsa: null,
    pieIsa: null,
    cardIso:null ,
    cardIso22:null ,
    cardIsa:null ,
    cardNist:null ,
    cardPci:null ,
    nist: null,
    pci: null,
     
});

const convertToBlob = () => {
  setImageBlob({
    tableIso27: null,
    pieIso27: null,
    iso:null,
    tableIso22: null,
    pieIso22: null,
    tableNist: null,
    pieNist: null,
    tablePci: null,
    piePci: null,
    tableIsa: null,
    pieIsa: null,
    cardIso:null ,
    cardIso22:null ,
    cardIsa:null ,
    cardNist:null ,
    cardPci:null ,
    nist: null,
    pci: null,
   
  })

      // ISO 27001 table image
      htmlToImage.toBlob(tableIso27RefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            tableIso27: blob,
        }));
    });
    // ISO 27001 pie image
      htmlToImage.toBlob(pieIso27RefImage.current).then((blob) => {

          setImageBlob((prevState) => ({
              ...prevState,
              pieIso27: blob,
          }));
      });

       // ISO image
        htmlToImage.toBlob(isoRefImage.current).then((blob) => {

          setImageBlob((prevState) => ({
              ...prevState,
              iso: blob,
          }));
      });


      // ISO 22301 table image
      htmlToImage.toBlob(tableIso22RefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            tableIso22: blob,
        }));
    });
    // ISO 22301 pie image
    htmlToImage.toBlob(pieIso22RefImage.current).then((blob) => {

      setImageBlob((prevState) => ({
          ...prevState,
          pieIso22: blob,
      }));
  });

     // Nist table image
     htmlToImage.toBlob(tableNistRefImage.current).then((blob) => {

      setImageBlob((prevState) => ({
          ...prevState,
          tableNist: blob,
      }));
  });
  // Nist pie image
      htmlToImage.toBlob(pieNistRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            pieNist: blob,
        }));
    });

    // Pci table image
    htmlToImage.toBlob(tablePciRefImage.current).then((blob) => {

      setImageBlob((prevState) => ({
          ...prevState,
          tablePci: blob,
      }));
  });
  // Pci pie image
      htmlToImage.toBlob(piePciRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            piePci: blob,
        }));
    });

    // Isa table image
    htmlToImage.toBlob(tableIsaRefImage.current).then((blob) => {

      setImageBlob((prevState) => ({
          ...prevState,
          tableIsa: blob,
      }));
  });
  // Isa pie image
      htmlToImage.toBlob(pieIsaRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            pieIsa: blob,
        }));
    });

          // IsoCard view
          htmlToImage.toBlob(cardIsoRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                cardIso: blob,
            }));
        }); 
        // NistCard view
        htmlToImage.toBlob(cardNistRefImage.current).then((blob) => {

          setImageBlob((prevState) => ({
              ...prevState,
              cardNist: blob,
          }));
      }); 

      // PciCard view
      htmlToImage.toBlob(cardPciRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            cardPci: blob,
        }));
      }); 

      // ISO22301 Card view
      htmlToImage.toBlob(cardIso22RefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            cardIso22: blob,
        }));
      }); 

      // PciCard view
      htmlToImage.toBlob(cardIsaRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            cardIsa: blob,
        }));
      }); 


      // nist
      htmlToImage.toBlob(nistRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            nist: blob,
        }));
    });

    // pci dss
    htmlToImage.toBlob(pciRefImage.current).then((blob) => {

        setImageBlob((prevState) => ({
            ...prevState,
            pci: blob,
        }));
    });    

}


  useEffect(() => {
    setRecentCompLoader(true)
    setPieLoading(true)
    setTableLoading(false)
            // console.log(selectedDateFilter);
        let filter=setFilterData(selectedDateFilter)

            // if (selectedFilter === "PCI-DSS" || selectedFilter === "ISO-22301") setActiveTab("annex");
            // else setActiveTab("main");
            const viewName = activeSubTab.replace('-', '').toLowerCase()
            getSummary(viewName);
            // const viewName = selectedFilter.replace('-', '').toLowerCase()
            // getSummary(viewName);
            getIso(filter).then((res)=>{
              setIsoData(res.data.aggregations.source_ip.buckets)
            })
            console.log("ISO:",isoData);

            getNist(filter).then((res)=>{            
              setNistData(res.data.aggregations.source_ip.buckets)
            })
            getPci(filter).then((res)=>{
              setPciData(res.data.aggregations.source_ip.buckets)
            })
            getCompName(filter).then((res)=>{
               setComUserData(res.data.aggregations.com_name_values.buckets)
               setRecentCompLoader(false)
            })
            getHostName(filter).then((res)=>{
              console.log("host:",res.data.aggregations);
              setHostUserData(res.data.aggregations. host_name_values.buckets)           
            })
            getUserName(filter).then((res)=>{
              console.log("user:",res.data.aggregations);
              setUserData(res.data.aggregations. user_name_values.buckets)           
              

            })
  }, [selectedFilter, selectedDateFilter,activeSubTab]);

  useEffect(() => {
    setShowDownloadButton(false)
    if (pieLoading === true && tableLoading === false  ) {
        setTimeout(() => {
            convertToBlob()
            setShowDownloadButton(true)
        }, 10000)
    }
}, [pieLoading,
  tableLoading])

  // table percentage data
  useEffect(() => {
    let count = 0;

    annexData[0]?.parent?.forEach((item) => {
        item.child.forEach((item1) => {
            item1.child.forEach((item2) => {
                if (item2.status !== null) {
                    count++;
                }
            });
        });
    });

    setTotalCount(count);
    console.log("totalCount",totalCount);
}, [annexData]); 

// Expantion table
          useEffect(() => {
            const itemsByStatus = {};
            // Iterate through the nested structure and collect items by status
            annexData[0]?.parent?.forEach((item) => {
              item.child.forEach((item1) => {
                item1.child.forEach((item2) => {
                  if (item2.status !== null) {
                    // Check if the status already exists in the object, if not, create an empty array for it
                    if (!itemsByStatus.hasOwnProperty(item2.status)) {
                      itemsByStatus[item2.status] = [];
                    }
                    // Push the item into the array corresponding to its status
                    itemsByStatus[item2.status].push(item2);
                  }
                });
              });
            });
            // Update the state with itemsByStatus
            setItemsByStatusState(itemsByStatus);
          }, [annexData]);

useEffect(()=>{
  console.log(itemsByStatusState);
})

// seperate counts for percentage
useEffect(() => {
  let totalCount = 0;
  const counts = {
    unconformity: 0,
      Nonexistent: 0,
      Initial: 0,
      Limited: 0,
      Defined: 0,
      conformity: 0,
      Optimised: 0,
      NotApplicable: 0
  };

          // Calculate total count and counts for each category
          annexData[0]?.parent?.forEach((item) => {
              item.child.forEach((item1) => {
                  item1.child.forEach((item2) => {
                      if (item2.status !== null) {
                          totalCount++;
                          counts[item2.status]++;
                      }
                  });
              });
          });
         
          // Calculate percentages for each category
                const percentages = {};
                const countss ={}
                Object.entries(counts).forEach(([status, count]) => {
                    percentages[status] = Math.round((count / totalCount) * 100);
                    countss[status] =count;
                    
                });

                setStatusPercentages(percentages);
                setCountt(countss)
      }, [annexData]); 

//  Pie chart loading and container styles
const containerStyle = {
  position: 'relative',
  marginLeft: '80px',
  width: '400px', // Set the width of the container
  height: '400px', // Set the height of the container
  display: "flex",
    flexDirection: "column",
    marginTop:"50px",
   boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Add box shadow
};

  const setFilterData = (selectedDateFilter) => {
    let filterData = "";
    if (selectedDateFilter === "1 Day") {
      filterData = "now-1d/d";
    } else if (selectedDateFilter === "7 Days") {
      filterData = "now-7d/d";
    } else if (selectedDateFilter === "30 Days") {
      filterData = "now-30d/d";
    } else {
      filterData = "now-1y/d";
    }

    return filterData;
  };


  const getSummary = async (item) => {
    setIsMainLoading(true)
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/summary?std=${item}`
      );
      // console.log(response,"response");
      if(response){
        setTableLoading(true)
        setPieLoading(false)
      } else{
        setTableLoading(false)
        setPieLoading(true)
      }
      // console.log("SUMMARY", response, typeof response?.data);
      setSelectedData(typeof response?.data === "object" ? response?.data : {})
      setAnnexData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex" || item.category === "annex-a") : {})
      setAnnexBData(typeof response?.data === "object" ? response?.data?.filter(item => item.category === "annex-b") : {})
     

      setIsMainLoading(false)

    } catch (error) {
      setIsMainLoading(false)
      console.log("summary error", error);
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
    }
  };
  

  const getInfo = async (id) => {
    setLoading(true);
    // getcomplianceinfo?std=iso27001&control=4.2
    let temp = id?.split("-");
    let temp2 = temp[0]?.toLowerCase();
    let temp3 = temp[1];
    let formattedId = temp2 + temp3;
    // let formattedId = temp2 + temp[1];
    setSavedFormattedId(formattedId);
    // console.log("formattedId", formattedId);
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/info?std=${formattedId}`
        // "https://api-gw1.active-bytes.com/api/v2/compliance/info?std=iso27001&control=A.5"
      );
      // console.log("info RESPONSE", response);
      setGetInfoData(response.data)
      if (response.data?.response_richtext)
        setMoreInfoText(response.data?.response_richtext);
      else setMoreInfoText(null);
      setStatus(response.data?.status);
      setLoading(false);
    } catch (error) {
      // setShowMoreInfo(false);
      setLoading(false);
      console.log("info error");
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
    }
  };

  const doRefresh = async () => {
    setIsMainLoading(true)
    let temp = selectedFilter.split("-");
    let temp2 = temp[0]?.toLowerCase();
    let temp3 = temp[1]?.toLowerCase();
    let formattedId = temp2 + temp3;
    try {
      const response = await fget(
        `${apiBaseUrl}/compliance/refresh?std=${formattedId}`
      );
      // console.log("refresh", response);
      if (response) {
        const viewName = selectedFilter.replace('-', '').toLowerCase()
        getSummary(viewName);
      }

    } catch (error) {
      setIsMainLoading(false)
      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
      console.log("refresh error", error);
    }


  };

  const setComplianceStatus = async (value) => {
    setIsUpdating(true);
    // console.log("savedFormattedId", savedFormattedId);
    let temp = savedFormattedId?.split("&control=");
    console.log("temp", temp);

    let data;
    if (value === "text") {
      data = {
        response_richtext: moreInfoText,
        status: getInfoData?.status,
        clause: getInfoData?.clause,
        description: getInfoData?.description
      };
      // data = getInfoData
    } else {
      data = {
        std: temp[0],
        control: temp[1],
      };
    }

    try {
      const response = await fpost(
        `${apiBaseUrl}/compliance/info?std=${savedFormattedId}`,
        data
      );
      // console.log("info RESPONSE", response);
      setIsUpdating(false);
      toast.success("Updated!", {
        position: "bottom-right",
      });
    } catch (error) {
      setIsUpdating(false);

      toast.error("Oops, some error occured", {
        position: "bottom-right",
      });
      console.log("save/update error");
    }
  };

 // Use a Set to keep track of unique status values
 const uniqueStatusSet = new Set();
 const itemOccurrences = {};

  // Iterate through the nested structure and count occurrences
  annexData[0]?.parent?.forEach((item) =>
    item.child.forEach((item1) =>
      item1.child.forEach((item2) => {
        // Check if the status is not null
        if (item2.status) {
 
          // Update or initialize the occurrence count for the status
          itemOccurrences[item2.status] = (itemOccurrences[item2.status] || 0) + 1;
         
        }
       
      })
    )
  );

  // Calculate the total number of items
  const totalItems = Object.values(itemOccurrences).reduce((sum, count) => sum + count, 0);
   console.log("status labels",Object.values(statusPercentages));
  const itemLabel = [
                    (Object.keys(statusPercentages).includes("unconformity")) ||  (Object.keys(statusPercentages).includes("unknown")) ? "Unknown" : null,
                    (Object.keys(statusPercentages).includes("Nonexistent")) ?  "Nonexistent" : null,
                    (Object.keys(statusPercentages).includes("Initial")) ? "Initial" :null,
                    (Object.keys(statusPercentages).includes("Limited")) ? "Limited": null,
                    (Object.keys(statusPercentages).includes("Defined")) ? "Defined" :null,
                    (Object.keys(statusPercentages).includes("conformity")) || (Object.keys(statusPercentages).includes("Managed")) ? "Managed" :null,
                    (Object.keys(statusPercentages).includes("Optimised")) ? "Optimised":null,
                    (Object.keys(statusPercentages).includes("NotApplicable")) ? "NotApplicable" : null,
                     
                  ].filter(label => label !== null);
                  const keysWithPercentageSymbol = Object.values(statusPercentages).map(values => values + '%');
                  console.log("status",keysWithPercentageSymbol);

  // Prepare data for the pie chart
  const pieChartData = {
    labels: itemLabel,
    datasets: [
      {
        data: Object.values(statusPercentages),
        backgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
        hoverBackgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
      },
    ],

  };
  
  
   

  // Prepare ISO pie chart data
  const isoLabels = isoData.map((bucket) => bucket.key);
  const isoPieData = isoData.map((bucket) => bucket.doc_count);
  {console.log("pieData")}
  const IsoPieData = {  
    labels: isoLabels,
    datasets: [
      {
        data: isoPieData,
        backgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
        hoverBackgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
      },
    ],
  }

  // Prepare Nist pie chart data
  const nistLabels = nistData.map((bucket) => bucket.key);
  const nistPieData = nistData.map((bucket) => bucket.doc_count);
  // {console.log("pieData")}
  const NistPieData = {  
    labels: nistLabels,
    datasets: [
      {
        data: nistPieData,
        backgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
        hoverBackgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
        ],
      },
    ],
  }


   // Prepare Nist pie chart data
   const pciLabels = pciData.map((bucket) => bucket.key);
   const pciPieData = pciData.map((bucket) => bucket.doc_count);
   // {console.log("pieData")}
   const PciPieData = {  
     datasets: [
       {
         data: pciPieData,
         backgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
         ],
         hoverBackgroundColor: [
          'rgba(200, 100, 100, 1)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(100, 255, 100, 1)',,
          'rgba(0, 0, 255, 1)' ,
          'rgba(255, 255, 0, 1)' ,
          'rgba(0, 255, 255, 1)' ,
         ],
       },
     ],
     labels: pciLabels,
   }


   const options = {
    maintainAspectRatio: false, // Set to false to allow manual adjustment of width and height
    responsive: true,
    width: 250, // Set to the width of the container
    height: 250, // Set to the height of the container
    plugins: {
        legend: {
            position: 'bottom', // Set the legend position to 'bottom'
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.label || '';
                    if (label) {
                        label += ': ';
                    }
                    const value = context.formattedValue || '';
                    label += value + '%';
                    return label;
                }
            }
        }
    },
};


// Data set-ISO bar graph
          const detectionIso =[];
          const chartData =[];
          const filteredDataIso =[];
          isoData?.map((item) => {
            detectionIso.push(item?.key)
            chartData.push(item?.dest_port?.buckets)
          })

          const groupedData = {};

          chartData.forEach(dataset => {
            dataset.forEach(item => {
              const name = item.key;
              const data = item.doc_count;

              if (groupedData[name]) {
                groupedData[name].push(data);
              } else {
                groupedData[name] = [data];
              }
            })
          })

          const resultObjects = Object.entries(groupedData).map(([name, data]) => ({
            name,
            data,
          }));

          filteredDataIso.push(...resultObjects);
            
            const colors = [
              'rgba(255, 99, 132, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(255, 205, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(255, 159, 64, 0.8)',
              'rgba(200, 100, 100, 1)',
              'rgba(0, 255, 0, 1)',
              'rgba(0, 0, 255, 1)' ,
              'rgba(255, 255, 0, 1)' ,
              'rgba(0, 255, 255, 1)' ,
            ]           
            const isoOption = {
              series: filteredDataIso?.map((item, index) => ({
                name: item.name, // Assuming each item has a name property
                data: item.data, // Assuming each item has a data property
                color: colors[index % colors.length] // Assigning color from the colors array
              })),
              options: {
                chart: {
                  type: 'bar',
                  height: 350,
                  stacked: true,
                  toolbar: { show: false },
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    barHeight: '20px',
                    dataLabels: {
                      position: 'center',
                      total: {
                        enabled: false,
                        offsetX: 0,
                        style: {
                          fontSize: '13px',
                          fontWeight: 900,
                          colors: colors // Set the color for data labels
                        },
                      },
                    },
                  },
                },
                stroke: { width: 0 },
                dataLabels: {
                  enabled: true,
                  formatter: function (val) {
                    return val;
                  },
                  style: {
                    fontSize: '12px',
                    colors: colors // Set the color for data labels
                  }
                },
                title: {
                  text: '',
                },
                xaxis: {
                  categories: detectionIso,
                  labels: {
                    // style: { colors: colors } // Set the color for x-axis labels
                  },
                },
                yaxis: {
                  title: {
                    text: undefined,
                  },
                  labels: {
                    show: true,
                    // style: { colors: colors }, // Set the color for y-axis labels
                    formatter: function (val) {
                      return val;
                    }
                  }
                },
                tooltip: {
                  enabled: true,
                  theme: 'light',
                  x: {
                    format: 'dd/MM/yy HH:mm'
                  },
                },
                fill: {
                  opacity: 1,
                  colors: colors // Set the color for bars
                },
                legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
                  fontSize: '13px',
                  labels: { colors: colors }, // Set the color for legend labels
                  itemMargin: {
                    vertical: 4,
                    horizontal: 10
                  },
                  markers: {
                    width: 12,
                    height: 12,
                    radius: 10,
                    offsetY: 1,
                    offsetX: -4
                  }
                },
              },
            };

        
// Data set-NIST
      const detectionNist =[];
      const chartDataNist =[];
      const filteredDataNist =[];
      nistData?.map((item) => {
        detectionNist.push(item?.key)
        chartDataNist.push(item?.dest_port?.buckets)
      })

      const groupedDataNist= {};

      chartDataNist.forEach(dataset => {
        dataset.forEach(item => {
          const name = item.key;
          const data = item.doc_count;

          if (groupedDataNist[name]) {
            groupedDataNist[name].push(data);
          } else {
            groupedDataNist[name] = [data];
          }
        })
      })

      const resultObjectsNist = Object.entries(groupedDataNist).map(([name, data]) => ({
        name,
        data,
      }));

      filteredDataNist.push(...resultObjectsNist);
  
      const nistOption = {
        series: filteredDataNist?.map((item, index) => ({
          name: item.name, // Assuming each item has a name property
          data: item.data, // Assuming each item has a data property
          color: colors[index % colors.length] // Assigning color from the colors array
        })),
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: { show: false },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '20px',
              dataLabels: {
                total: {
                  enabled: false,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900,
                    colors: colors // Set the color for data labels
                  },
                },
              },
            },
          },
          stroke: { width: 0 },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            style: {
              fontSize: '12px',
              colors: colors // Set the color for data labels
            }
          },
          title: {
            text: '',
          },
          xaxis: {
            categories: detectionNist,
            labels: {
              style: { colors: colors } // Set the color for x-axis labels
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
            labels: {
              show: true,
              style: { colors: colors }, // Set the color for y-axis labels
              formatter: function (val) {
                return val;
              }
            }
          },
          tooltip: {
            enabled: true,
            theme: 'light',
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          fill: {
            opacity: 1,
            colors: colors // Set the color for bars
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '13px',
            labels: { colors: colors }, // Set the color for legend labels
            itemMargin: {
              vertical: 4,
              horizontal: 10
            },
            markers: {
              width: 12,
              height: 12,
              radius: 10,
              offsetY: 1,
              offsetX: -4
            }
          },
        },
      };

      

      // Data set-PCI
      const detectionPci =[];
      const chartDataPci =[];
      const filteredDataPci =[];
      pciData?.map((item) => {
        detectionPci.push(item?.key)
        chartDataPci.push(item?.dest_port?.buckets)
      })

      const groupedDataPci = {};

      chartDataPci.forEach(dataset => {
        dataset.forEach(item => {
          const name = item.key;
          const data = item.doc_count;

          if (groupedDataPci[name]) {
            groupedDataPci[name].push(data);
          } else {
            groupedDataPci[name] = [data];
          }
        })
      })

      const resultObjectsPci = Object.entries(groupedDataPci).map(([name, data]) => ({
        name,
        data,
      }));

      filteredDataPci.push(...resultObjectsPci);

    

    const pciOption = {
      series: filteredDataPci?.map((item, index) => ({
        name: item.name, // Assuming each item has a name property
        data: item.data, // Assuming each item has a data property
        color: colors[index % colors.length] // Assigning color from the colors array
      })),
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '20px',
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                  colors: colors // Set the color for data labels
                },
              },
            },
          },
        },
        stroke: { width: 0 },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: '12px',
            colors: colors // Set the color for data labels
          }
        },
        title: {
          text: '',
        },
        xaxis: {
          categories: detectionPci,
          labels: {
            style: { colors: colors } // Set the color for x-axis labels
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            style: { colors: colors }, // Set the color for y-axis labels
            formatter: function (val) {
              return val;
            }
          }
        },
        tooltip: {
          enabled: true,
          theme: 'light',
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        fill: {
          opacity: 1,
          colors: colors // Set the color for bars
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '13px',
          labels: { colors: colors }, // Set the color for legend labels
          itemMargin: {
            vertical: 4,
            horizontal: 10
          },
          markers: {
            width: 12,
            height: 12,
            radius: 10,
            offsetY: 1,
            offsetX: -4
          }
        },
      },
    };
            

  const desiredStatusValues = ["Unknown", "Nonexistent", "Initial", "Limited", "Defined", "Managed", "Optimised", "NotApplicable"];
 
  function handleTabSelect(key) {
    localStorage.setItem("activeTab.hunter", key)

    if (localStorage.getItem("activeTab.hunter") === "ALERTS") {
      setActiveMainTab("ALERTS")
    }
     else {
      setActiveMainTab("OVERVIEW")
    }
  }

  // expanded table
  const handleRowClick = (index, status) => {
    // Toggle expandedRowIndex
    setExpandedRowIndex(index === expandedRowIndex ? null : index);
  };


  function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'unknown':
            return 'rgba(200, 100, 100, 1)';
        case 'nonexistent':
            return 'rgba(54, 162, 235, 0.8)';
        case 'initial':
            return 'rgba(255, 205, 86, 0.8)';
        case 'limited':
            return 'rgba(75, 192, 192, 0.8)';
        case 'defined':
            return 'rgba(153, 102, 255, 0.8)';
        case 'managed':
            return 'rgba(255, 159, 64, 0.8)';
        case 'optimised':
            return 'rgba(255, 99, 132, 0.8)';
        default:
            return 'rgba(100, 255, 100, 1)';
    }
}



  return (
    <>
       <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
              {showDownloadButton ?
                <div
                    className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                >
                    <PDFDownloadLink document={<OverviewIso27001 imageBlob={imageBlob} tab={activeSubTab}  />}  fileName={`compliance-${activeSubTab}-dashboard.pdf`}>
                        {({ blob, url, loading, error }) =>
                            <Button className='btn btn-primary'>Download</Button>
                        }
                    </PDFDownloadLink>
                    
                </div>
                :
                <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">

                    <PDFDownloadLink document={<OverviewIso27001 imageBlob={imageBlob} tab={activeSubTab} />}  fileName={`compliance-${activeSubTab}-dashboard.pdf`}>
                        {({ blob, url, loading, error }) =>
                            <Button className='btn btn-primary' disabled>
                                Download <Spinner animation="border" role="status" size="sm" />
                            </Button>

                        }
                    </PDFDownloadLink>
                </div>
               }
          <div className='col-12 flex-fill border rounded-3 p-4 mb-0 bg-white' style={{overflowY:"auto"}}>
                {/* <h2>Threat Landscape {(showDate) && `(${moment().format("DD-MM-YYYY")})`}</h2> */}
                
            <h2>Compliance Dashboard</h2>

            <p className='mb-0' style={styles.paraGraph}>Compliance dashboards in cybersecurity provide comprehensive visualizations and analytics to track adherence to regulatory standards such as ISO 27001, NIST, PCI-DSS etc. These dashboards offer real-time insights into the organization's compliance status, highlighting areas of non-compliance and enabling proactive risk management strategies. By centralizing compliance data and metrics, these dashboards facilitate streamlined reporting and decision-making processes for cybersecurity professionals.</p>
            {/* <Link className='btn btn-dark mt-3' to={"/ti/activebytes"} style={{ color: globalStyles.white }}>View all</Link> */}
            </div>          
              <Tabs
                  activeKey={activeSubTab}
                  onSelect={(k) => setActiveSubTab(k)}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  >
                  {/*iso7001  */}
                    <Tab eventKey="ISO-27001"
                          title={
                            <React.Fragment>
                              ISO-27001
                            </React.Fragment>
                          }
                          
                        >
              
                            <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                      

                      <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white ">
                          <div ref={tableIso27RefImage} className="col-6 p-4 rounded-3 border">
                              {/* <h5>Event Category</h5> */}
                              {tableLoading ===false ?(
                                      <TableLoader rowCount={5} />
                                      ):(
                                        <Table borderless hover className="text-center">
                                        <thead className="fixed-head">
                                            <tr>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Status Definition</th>
                                                <th className="text-center">Proportion of information security controls</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {desiredStatusValues.map((status, index) => {
                                                let progressValue = '';
                            
                                                switch (status.toLowerCase()) {
                                                    case 'conformity':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'unconformity':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    case 'nonexistent':
                                                        progressValue = 'Complete lack of recognisable policy, procedure, control etc.';
                                                        break;
                                                    case 'initial':
                                                        progressValue =
                                                            "Development has barely started and will require significant work to fulfill the requirements";
                                                        break;
                                                    case 'limited':
                                                        progressValue = 'Progressing nicely but not yet complete';
                                                        break;
                                                    case 'defined':
                                                        progressValue =
                                                            "Development is more or less complete although detail is lacking and/or it's not yet implemented, enforced and actively supported by top management";
                                                        break;
                                                    case 'managed':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'optimised':
                                                        progressValue =
                                                            "The requirement is fully satisfied, is operating fully as expected, is being actively monitored and improved, and there's substantial evidence to prove all that to the auditors";
                                                        break;
                                                    case 'unknown':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    default:
                                                        progressValue = 'ALL requirements in the main body of ISO/IEC 27001 are mandatory IF your ISMS is to be certified. Otherwise, management can ignore them.';
                                                        break;
                                                }
                            
                                                // Initialize a variable to store the percentage value for the current status
                                                let percentageValue = 0;
                                                let countValue =0;
                            
                                                // Calculate the percentage value for the current status
                                                switch (status.toLowerCase()) {
                                                    case 'unknown':
                                                        // For "unknown" status, use the percentage value of "unconformity"
                                                        percentageValue = statusPercentages.unconformity;
                                                        break;
                                                    case 'managed':
                                                        // For "managed" status, use the percentage value of "conformity"
                                                        percentageValue = statusPercentages.conformity;
                                                        break;
                                                    default:
                                                        // For other statuses, use the corresponding percentage value
                                                        percentageValue = statusPercentages[status.toLowerCase()];
                                                        break;
                                                }
                                                  // Calculate the count value for the current status
                                                  switch (status.toLowerCase()) {
                                                  case 'unknown':
                                                      // For "unknown" status, use the percentage value of "unconformity"
                                                      countValue = countt.unconformity;
                                                      break;
                                                  case 'managed':
                                                      // For "managed" status, use the percentage value of "conformity"
                                                      countValue = countt.conformity;
                                                      break;
                                                  default:
                                                      // For other statuses, use the corresponding percentage value
                                                      countValue = countt[status.toLowerCase()];
                                                      break;
                                              }
                            
                                                  // Debugging
                                                  // console.log("Status:", status);
                                                  // console.log("Items by status state:", itemsByStatusState);
                                                // Display the table row with the percentage value for the current status
                                                return (
                                            //       <tr key={`desired-status-${index}`}>
                                            //           <td className="text-right ">{status}</td>
                                            //           <td className="text-left !important">{progressValue}</td>
                                            //           <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                            //       </tr>
                                            //     ); 
                                            // })}
                                            <React.Fragment key={`desired-status-${index}`}>
                                                    <tr onClick={() => handleRowClick(index)}>
                                                          <td>
                                                              <span style={{backgroundColor: getStatusColor(status), padding: '5px', borderRadius: '5px', color: '#fff'}}>
                                                                  {status}
                                                              </span>
                                                          </td>
                                                          <td>{progressValue}</td>
                                                          <td>
                                                              {percentageValue ? percentageValue : 0}% ,&nbsp;
                                                              {countValue ? countValue : 0}&nbsp;&nbsp;Controls
                                                          </td>
                                                      </tr>


                                                    {/* Render expanded row below the clicked row */}
                                                    {expandedRowIndex === index && (
                                                    <tr>
                                                    <td colSpan="3">
                                                      {/* Display IDs corresponding to the status */}
                                                      
                                                      {
                                                            itemsByStatusState[status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status] ? (
                                                              <div style={{ backgroundColor:'lightgray' }}>
                                                              {/* <div style={{ backgroundColor: '#FFAE62' }}> */}
                                                                Control Id's - 
                                                                {
                                                                  itemsByStatusState[
                                                                    status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status
                                                                  ].map((item, idIndex) => item.id).join(', ')
                                                                }
                                                              </div>
                                                            ) : (
                                                              <div>No data</div>
                                                            )
                                                          }
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              })}
                                        </tbody>
                                         </Table>
                                  ) } 
                          </div>

                          <div ref={pieIso27RefImage} className="flex-fill p-4 rounded-3 border" >
                              <h5>Compliance Performance </h5>
                                  <div className='col-12'>
                                    {/* {console.log(Object.values(itemOccurrences))} */}
                                    {/* {annexData===null ? <NoDataFound errorText={"No data found"} /> : */}
                                    {      pieLoading ? (
                                              // Display loader while loading
                                              <PieChartLoader />
                                          ) : Object.values(itemOccurrences).length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                              // Display the actual pie chart once data is loaded
                                              <Pie data={pieChartData} options={options} />
                                          )}                                                                                                                                                       
                                  </div>                            
                          </div>                        
                      </div>  
                          <div ref={isoRefImage} className="row col-12 p-4 rounded-3 border" >
                              <h5>Top Failed ISO 27001 Requirements</h5>
                              {/* { isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : */}
                                { pieLoading ? (
                                      // Display loader while loading
                                      <PlainCardLoader width="200px" />
                                  ) :  isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : (
                                      // Display the actual pie chart once data is loaded
                                      // <Bar data={IsoPieData} options={options}  />
                                      <ReactApexCharts options={isoOption.options} 
                                      series={isoOption.series} type="bar" height={350} />
                                    
                                  )}                            
                          </div>  
                          <div ref={cardIsoRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                            <div className="col-12 gap-3 d-flex flex-row" >
                            {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={" Alert "}
                                        mostAnomalouseList={comUserData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}                             
                                    />
                                  }
                              {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"Host Name"}
                                  mostAnomalouseList={hostUserData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                                }
                              {userData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"User Name"}
                                  mostAnomalouseList={userData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                              }
                              </div>
                          </div>                                 
                        </div>
                    </Tab>

                  {/*iso22301  */}
                    <Tab eventKey="ISO-22301" 
                          title={
                            <React.Fragment>
                              ISO-22301
                            </React.Fragment>
                          }
                        >
                          
                    <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                      

                      <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                          <div ref={tableIso22RefImage} className="col-6 p-4 rounded-3 border">
                              {/* <h5>Event Category</h5> */}
                              {tableLoading ===false ?(
                                      <TableLoader rowCount={5} />
                                      ):(
                                        <Table borderless hover className="text-center">
                                        <thead className="fixed-head">
                                            <tr>
                                                <th>Status</th>
                                                <th>Progress</th>
                                                <th>Proportion of information security controls</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {desiredStatusValues.map((status, index) => {
                                                let progressValue = '';
                            
                                                switch (status.toLowerCase()) {
                                                    case 'conformity':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'unconformity':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    case 'nonexistent':
                                                        progressValue = 'Complete lack of recognisable policy, procedure, control etc.';
                                                        break;
                                                    case 'initial':
                                                        progressValue =
                                                            "Development has barely started and will require significant work to fulfill the requirements";
                                                        break;
                                                    case 'limited':
                                                        progressValue = 'Progressing nicely but not yet complete';
                                                        break;
                                                    case 'defined':
                                                        progressValue =
                                                            "Development is more or less complete although detail is lacking and/or it's not yet implemented, enforced and actively supported by top management";
                                                        break;
                                                    case 'managed':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'optimised':
                                                        progressValue =
                                                            "The requirement is fully satisfied, is operating fully as expected, is being actively monitored and improved, and there's substantial evidence to prove all that to the auditors";
                                                        break;
                                                    case 'unknown':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    default:
                                                        progressValue = 'ALL requirements in the main body of ISO/IEC 27001 are mandatory IF your ISMS is to be certified. Otherwise, management can ignore them.';
                                                        break;
                                                }
                            
                                                // Initialize a variable to store the percentage value for the current status
                                                let percentageValue = 0;
                                                let countValue =0;
                            
                                                // Calculate the percentage value for the current status
                                                switch (status.toLowerCase()) {
                                                    case 'unknown':
                                                        // For "unknown" status, use the percentage value of "unconformity"
                                                        percentageValue = statusPercentages.unconformity;
                                                        break;
                                                    case 'managed':
                                                        // For "managed" status, use the percentage value of "conformity"
                                                        percentageValue = statusPercentages.conformity;
                                                        break;
                                                    default:
                                                        // For other statuses, use the corresponding percentage value
                                                        percentageValue = statusPercentages[status.toLowerCase()];
                                                        break;
                                                }

                                                // Calculate the count value for the current status
                                                switch (status.toLowerCase()) {
                                                  case 'unknown':
                                                      // For "unknown" status, use the percentage value of "unconformity"
                                                      countValue = countt.unconformity;
                                                      break;
                                                  case 'managed':
                                                      // For "managed" status, use the percentage value of "conformity"
                                                      countValue = countt.conformity;
                                                      break;
                                                  default:
                                                      // For other statuses, use the corresponding percentage value
                                                      countValue = countt[status.toLowerCase()];
                                                      break;
                                              }
                            
                                                // Display the table row with the percentage value for the current status
                                                // return (
                                                //   <tr key={`desired-status-${index}`}>
                                                //       <td className="text-right ">{status}</td>
                                                //       <td className="text-left !important">{progressValue}</td>
                                                //       <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                //   </tr>
                                                // ); 
                                                return (
                                                  //       <tr key={`desired-status-${index}`}>
                                                  //           <td className="text-right ">{status}</td>
                                                  //           <td className="text-left !important">{progressValue}</td>
                                                  //           <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                  //       </tr>
                                                  //     ); 
                                                  // })}
                                                  <React.Fragment key={`desired-status-${index}`}>
                                                           <tr onClick={() => handleRowClick(index)}>
                                                              <td>
                                                                  <span style={{backgroundColor: getStatusColor(status), padding: '5px', borderRadius: '5px', color: '#fff'}}>
                                                                      {status}
                                                                  </span>
                                                              </td>
                                                              <td>{progressValue}</td>
                                                              <td>
                                                                  {percentageValue ? percentageValue : 0}% ,&nbsp;
                                                                  {countValue ? countValue : 0}&nbsp;&nbsp;Controls
                                                              </td>
                                                           </tr>

                                                          {/* Render expanded row below the clicked row */}
                                                          {expandedRowIndex === index && (
                                                          <tr>
                                                          <td colSpan="3">
                                                            {/* Display IDs corresponding to the status */}
                                                            
                                                            {
                                                                  itemsByStatusState[status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status] ? (
                                                                    <div style={{ backgroundColor:'lightgray' }}>
                                                                    {/* <div style={{ backgroundColor: '#FFAE62' }}> */}
                                                                      Control Id's - 
                                                                      {
                                                                        itemsByStatusState[
                                                                          status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status
                                                                        ].map((item, idIndex) => item.id).join(', ')
                                                                      }
                                                                    </div>
                                                                  ) : (
                                                                    <div>No data</div>
                                                                  )
                                                                }
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </React.Fragment>
                                                      );
                                            })}
                                        </tbody>
                                    </Table> 
                                  ) } 
                          </div>

                          <div ref={pieIso22RefImage} className="flex-fill p-4 rounded-3 border" >
                              <h5>Compliance Performance</h5>
                                  <div className='col-12'>
                                    {/* {console.log(Object.values(itemOccurrences))} */}
                                    {/* {annexData===null ? <NoDataFound errorText={"No data found"} /> : */}
                                    {      pieLoading ? (
                                              // Display loader while loading
                                              <PieChartLoader />
                                          ) : Object.values(itemOccurrences).length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                              // Display the actual pie chart once data is loaded
                                              <Pie data={pieChartData} options={options} />
                                          )}                                       
                                    
                                    
                                    
                                  </div>                            
                          </div>
                      </div>
                      {/* <div  className="row col-12 p-4 rounded-3 border" >
                              <h5>Top Failed ISO 27001 Requirements</h5>
                              {/* { isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : */}
                                {/* { pieLoading ? (
                                      // Display loader while loading
                                      <PlainCardLoader width="200px" />
                                  ) :  isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : (
                                      // Display the actual pie chart once data is loaded
                                      // <Bar data={IsoPieData} options={options}  />
                                      <ReactApexCharts options={isoOption.options} 
                                      series={isoOption.series} type="bar" height={350} />
                                    
                                  )}                             */}
                      {/* </div>   */} 
                          <div ref={cardIso22RefImage}  className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                            <div className="col-12 gap-3 d-flex flex-row" >
                            {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={" Alert "}
                                        mostAnomalouseList={comUserData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}                             
                                    />
                                  }
                              {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"Host Name"}
                                  mostAnomalouseList={hostUserData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                                }
                              {userData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"User Name"}
                                  mostAnomalouseList={userData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                              }
                              </div>
                          </div> 
                    </div>
                      
                    </Tab>

                  {/* NIST */}
                    <Tab eventKey="NIST"
                          title={
                            <React.Fragment>
                              NIST
                            </React.Fragment>
                          }
                        >                           
                    <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                      <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                          <div ref={tableNistRefImage} className="col-6 p-4 rounded-3 border">
                              {/* <h5>Event Category</h5> */}
                              {tableLoading ===false ?(
                                      <TableLoader rowCount={5} />
                                      ):(
                                        <Table borderless hover className="text-center">
                                        <thead className="fixed-head">
                                            <tr>
                                                <th>Status</th>
                                                <th>Progress</th>
                                                <th>Proportion of information security controls</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {desiredStatusValues.map((status, index) => {
                                                let progressValue = '';
                            
                                                switch (status.toLowerCase()) {
                                                    case 'conformity':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'unconformity':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    case 'nonexistent':
                                                        progressValue = 'Complete lack of recognisable policy, procedure, control etc.';
                                                        break;
                                                    case 'initial':
                                                        progressValue =
                                                            "Development has barely started and will require significant work to fulfill the requirements";
                                                        break;
                                                    case 'limited':
                                                        progressValue = 'Progressing nicely but not yet complete';
                                                        break;
                                                    case 'defined':
                                                        progressValue =
                                                            "Development is more or less complete although detail is lacking and/or it's not yet implemented, enforced and actively supported by top management";
                                                        break;
                                                    case 'managed':
                                                        progressValue =
                                                            'Development is complete, the process/control has been implemented and recently started operating';
                                                        break;
                                                    case 'optimised':
                                                        progressValue =
                                                            "The requirement is fully satisfied, is operating fully as expected, is being actively monitored and improved, and there's substantial evidence to prove all that to the auditors";
                                                        break;
                                                    case 'unknown':
                                                        progressValue = "Hasn't even been checked yet";
                                                        break;
                                                    default:
                                                        progressValue = 'ALL requirements in the main body of ISO/IEC 27001 are mandatory IF your ISMS is to be certified. Otherwise, management can ignore them.';
                                                        break;
                                                }
                            
                                                // Initialize a variable to store the percentage value for the current status
                                                let percentageValue = 0;
                                                let countValue =0;
                            
                                                // Calculate the percentage value for the current status
                                                switch (status.toLowerCase()) {
                                                    case 'unknown':
                                                        // For "unknown" status, use the percentage value of "unconformity"
                                                        percentageValue = statusPercentages.unconformity;
                                                        break;
                                                    case 'managed':
                                                        // For "managed" status, use the percentage value of "conformity"
                                                        percentageValue = statusPercentages.conformity;
                                                        break;
                                                    default:
                                                        // For other statuses, use the corresponding percentage value
                                                        percentageValue = statusPercentages[status.toLowerCase()];
                                                        break;
                                                }
                                                 // Calculate the count value for the current status
                                                 switch (status.toLowerCase()) {
                                                  case 'unknown':
                                                      // For "unknown" status, use the percentage value of "unconformity"
                                                      countValue = countt.unconformity;
                                                      break;
                                                  case 'managed':
                                                      // For "managed" status, use the percentage value of "conformity"
                                                      countValue = countt.conformity;
                                                      break;
                                                  default:
                                                      // For other statuses, use the corresponding percentage value
                                                      countValue = countt[status.toLowerCase()];
                                                      break;
                                              }
                            
                                                // Display the table row with the percentage value for the current status
                                                // return (
                                                //   <tr key={`desired-status-${index}`}>
                                                //       <td className="text-right ">{status}</td>
                                                //       <td className="text-left !important">{progressValue}</td>
                                                //       <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                //   </tr>
                                                // ); 
                                                return (
                                                  //       <tr key={`desired-status-${index}`}>
                                                  //           <td className="text-right ">{status}</td>
                                                  //           <td className="text-left !important">{progressValue}</td>
                                                  //           <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                  //       </tr>
                                                  //     ); 
                                                  // })}
                                                  <React.Fragment key={`desired-status-${index}`}>

                                                      <tr onClick={() => handleRowClick(index)}>
                                                            <td>
                                                                <span style={{backgroundColor: getStatusColor(status), padding: '5px', borderRadius: '5px', color: '#fff'}}>
                                                                    {status}
                                                                </span>
                                                            </td>
                                                            <td>{progressValue}</td>
                                                            <td>
                                                                {percentageValue ? percentageValue : 0}% ,&nbsp;
                                                                {countValue ? countValue : 0}&nbsp;&nbsp;Controls
                                                            </td>
                                                      </tr>

                                                          {/* Render expanded row below the clicked row */}
                                                          {expandedRowIndex === index && (
                                                          <tr>
                                                          <td colSpan="3">
                                                            {/* Display IDs corresponding to the status */}
                                                            
                                                            {
                                                                  itemsByStatusState[status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status] ? (
                                                                    <div style={{ backgroundColor:'lightgray' }}>
                                                                    {/* <div style={{ backgroundColor: '#FFAE62' }}> */}
                                                                      Control Id's - 
                                                                      {
                                                                        itemsByStatusState[
                                                                          status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status
                                                                        ].map((item, idIndex) => item.id).join(', ')
                                                                      }
                                                                    </div>
                                                                  ) : (
                                                                    <div>No data</div>
                                                                  )
                                                                }
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </React.Fragment>
                                                      );
                                            })}
                                        </tbody>
                                    </Table> 
                                  ) } 
                          </div>

                          <div ref={pieNistRefImage} className="flex-fill p-4 rounded-3 border" >
                              <h5>Compliance Performance</h5>
                                  <div className='col-12'>
                                    {/* {console.log(Object.values(itemOccurrences))} */}
                                    {/* {annexData===null ? <NoDataFound errorText={"No data found"} /> : */}
                                    {      pieLoading ? (
                                              // Display loader while loading
                                              <PieChartLoader />
                                          ) : Object.values(itemOccurrences).length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                              // Display the actual pie chart once data is loaded
                                              <Pie data={pieChartData} options={options} />
                                          )}                                       
                                    
                                    
                                    
                                  </div>                            
                          </div>
                      </div>   
                      <div ref={nistRefImage} className="row col-12 p-4 rounded-3 border" >
                                <h5>Top Failed NIST Requirements</h5>
                                {/* { nistData.length === 0 ? <NoDataFound errorText={"No data found"} /> : */}
                                 { pieLoading ? (
                                        // Display loader while loading
                                        <PlainCardLoader width="200px" />
                                    ) : nistData.length === 0 ? <NoDataFound errorText={"No data found"} />:(
                                        // Display the actual pie chart once data is loaded
                                        // <Bar data={NistPieData} options={options} />
                                        <ReactApexCharts options={nistOption.options} 
                                        series={nistOption.series} type="bar" height={350} />
                                    )}
                      </div>  
                      <div ref={cardNistRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                            <div className="col-12 gap-3 d-flex flex-row" >
                            {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={" Alert "}
                                        mostAnomalouseList={comUserData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}                             
                                    />
                                  }
                              {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"Host Name"}
                                  mostAnomalouseList={hostUserData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                                }
                              {userData===null ? <NoDataFound errorText={"No data found"} /> :
                              <RecentTab
                                  tabName={"User Name"}
                                  mostAnomalouseList={userData}
                                  type={"comp"}
                                  selectedField={'compUser'}
                                  FieldLabel={'CompUser'}
                                  loader={recentCompLoader}
                              />
                              }
                              </div>
                      </div>                                          
                    </div>
                      
                    </Tab>

                  {/*PCI-dss  */}
                    <Tab eventKey="PCI-DSS"
                            title={
                            <React.Fragment>
                            PCI-DSS
                            </React.Fragment>
                        }
                        >
                                
                          <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                  <div ref={tablePciRefImage} className="col-6 p-4 rounded-3 border">
                                    {/* <h5>Event Category</h5> */}
                                    {tableLoading ===false ?(
                                            <TableLoader rowCount={5} />
                                            ):(
                                              <Table borderless hover className="text-center">
                                              <thead className="fixed-head">
                                                  <tr>
                                                      <th>Status</th>
                                                      <th>Progress</th>
                                                      <th>Proportion of information security controls</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {desiredStatusValues.map((status, index) => {
                                                      let progressValue = '';
                                  
                                                      switch (status.toLowerCase()) {
                                                          case 'conformity':
                                                              progressValue =
                                                                  'Development is complete, the process/control has been implemented and recently started operating';
                                                              break;
                                                          case 'unconformity':
                                                              progressValue = "Hasn't even been checked yet";
                                                              break;
                                                          case 'nonexistent':
                                                              progressValue = 'Complete lack of recognisable policy, procedure, control etc.';
                                                              break;
                                                          case 'initial':
                                                              progressValue =
                                                                  "Development has barely started and will require significant work to fulfill the requirements";
                                                              break;
                                                          case 'limited':
                                                              progressValue = 'Progressing nicely but not yet complete';
                                                              break;
                                                          case 'defined':
                                                              progressValue =
                                                                  "Development is more or less complete although detail is lacking and/or it's not yet implemented, enforced and actively supported by top management";
                                                              break;
                                                          case 'managed':
                                                              progressValue =
                                                                  'Development is complete, the process/control has been implemented and recently started operating';
                                                              break;
                                                          case 'optimised':
                                                              progressValue =
                                                                  "The requirement is fully satisfied, is operating fully as expected, is being actively monitored and improved, and there's substantial evidence to prove all that to the auditors";
                                                              break;
                                                          case 'unknown':
                                                              progressValue = "Hasn't even been checked yet";
                                                              break;
                                                          default:
                                                              progressValue = 'ALL requirements in the main body of ISO/IEC 27001 are mandatory IF your ISMS is to be certified. Otherwise, management can ignore them.';
                                                              break;
                                                      }
                                  
                                                      // Initialize a variable to store the percentage value for the current status
                                                      let percentageValue = 0;
                                                      let countValue =0;
                                  
                                                      // Calculate the percentage value for the current status
                                                      switch (status.toLowerCase()) {
                                                          case 'unknown':
                                                              // For "unknown" status, use the percentage value of "unconformity"
                                                              percentageValue = statusPercentages.unconformity;
                                                              break;
                                                          case 'managed':
                                                              // For "managed" status, use the percentage value of "conformity"
                                                              percentageValue = statusPercentages.conformity;
                                                              break;
                                                          default:
                                                              // For other statuses, use the corresponding percentage value
                                                              percentageValue = statusPercentages[status.toLowerCase()];
                                                              break;
                                                      }

                                                      // Calculate the count value for the current status
                                                  switch (status.toLowerCase()) {
                                                    case 'unknown':
                                                        // For "unknown" status, use the percentage value of "unconformity"
                                                        countValue = countt.unconformity;
                                                        break;
                                                    case 'managed':
                                                        // For "managed" status, use the percentage value of "conformity"
                                                        countValue = countt.conformity;
                                                        break;
                                                    default:
                                                        // For other statuses, use the corresponding percentage value
                                                        countValue = countt[status.toLowerCase()];
                                                        break;
                                                }
                                  
                                                      // Display the table row with the percentage value for the current status
                                                      // return (
                                                      //   <tr key={`desired-status-${index}`}>
                                                      //       <td className="text-right ">{status}</td>
                                                      //       <td className="text-left !important">{progressValue}</td>
                                                      //       <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                      //   </tr>
                                                      // ); 
                                                      return (
                                                        //       <tr key={`desired-status-${index}`}>
                                                        //           <td className="text-right ">{status}</td>
                                                        //           <td className="text-left !important">{progressValue}</td>
                                                        //           <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                        //       </tr>
                                                        //     ); 
                                                        // })}
                                                        <React.Fragment key={`desired-status-${index}`}>
                                                          <tr onClick={() => handleRowClick(index)}>
                                                              <td>
                                                                  <span style={{backgroundColor: getStatusColor(status), padding: '5px', borderRadius: '5px', color: '#fff'}}>
                                                                      {status}
                                                                  </span>
                                                              </td>
                                                              <td>{progressValue}</td>
                                                              <td>
                                                                  {percentageValue ? percentageValue : 0}% ,&nbsp;
                                                                  {countValue ? countValue : 0}&nbsp;&nbsp;Controls
                                                              </td>
                                                          </tr>

                                                                {/* Render expanded row below the clicked row */}
                                                                {expandedRowIndex === index && (
                                                                <tr>
                                                                <td colSpan="3">
                                                                  {/* Display IDs corresponding to the status */}
                                                                
                                                                  {
                                                                        itemsByStatusState[status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status] ? (
                                                                          <div style={{ backgroundColor:'lightgray' }}>
                                                                          {/* <div style={{ backgroundColor: '#FFAE62' }}> */}
                                                                            Control Id's - 
                                                                            {
                                                                              itemsByStatusState[
                                                                                status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status
                                                                              ].map((item, idIndex) => item.id).join(', ')
                                                                            }
                                                                          </div>
                                                                        ) : (
                                                                          <div>No data</div>
                                                                        )
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                              </React.Fragment>
                                                            );
                                                  })}
                                              </tbody>
                                          </Table> 
                                        ) } 
                                  </div>

                                <div ref={piePciRefImage} className="flex-fill p-4 rounded-3 border" >
                                    <h5>Compliance Performance</h5>
                                        <div className='col-12'>
                                          {/* {console.log(Object.values(itemOccurrences))} */}
                                          {/* {annexData===null ? <NoDataFound errorText={"No data found"} /> : */}
                                          {      pieLoading ? (
                                                    // Display loader while loading
                                                    <PieChartLoader />
                                                ) : Object.values(itemOccurrences).length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                                    // Display the actual pie chart once data is loaded
                                                    <Pie data={pieChartData} options={options} />
                                                )}                                       
                                          
                                          
                                          
                                        </div>                            
                                </div>
                            </div> 
                            <div ref={pciRefImage} className=" row col-12 flex-fill p-4 rounded-3 border" >
                                <h5>Top Failed PCI DSS Requirements</h5>
                                {/* { pciData.length === 0 ? <NoDataFound errorText={"No data found"} /> : */}
                                 { pieLoading ? (
                                        // Display loader while loading
                                        <PlainCardLoader width="200px" />
                                    ) :pciData.length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                        // Display the actual pie chart once data is loaded
                                        <ReactApexCharts options={pciOption.options} 
                                        series={pciOption.series} type="bar" height={350} />
                                    )}
                            </div>
                                <div ref={cardPciRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                                <div className="col-12 gap-3 d-flex flex-row" >
                                {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                          <RecentTab
                                              tabName={" Alert "}
                                              mostAnomalouseList={comUserData}
                                              type={"comp"}
                                              selectedField={'compUser'}
                                              FieldLabel={'CompUser'}
                                              loader={recentCompLoader}                             
                                          />
                                        }
                                  {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={"Host Name"}
                                        mostAnomalouseList={hostUserData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}
                                    />
                                      }
                                    {userData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={"User Name"}
                                        mostAnomalouseList={userData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}
                                    />
                                    }
                                    </div>
                                </div>                  
                          </div>
                    </Tab>

                  {/*ISA-62443  */}
                    <Tab eventKey="ISA-62443"
                        title={
                            <React.Fragment>
                            ISA-62443
                            </React.Fragment>
                        }
                      >                                
                          <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                <div ref={tableIsaRefImage} className="col-6 p-4 rounded-3 border">
                                    {/* <h5>Event Category</h5> */}
                                    {tableLoading ===false ?(
                                            <TableLoader rowCount={5} />
                                            ):(
                                              <Table borderless hover className="text-center">
                                              <thead className="fixed-head">
                                                  <tr>
                                                      <th>Status</th>
                                                      <th>Progress</th>
                                                      <th>Proportion of information security controls</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {desiredStatusValues.map((status, index) => {
                                                      let progressValue = '';
                                  
                                                      switch (status.toLowerCase()) {
                                                          case 'conformity':
                                                              progressValue =
                                                                  'Development is complete, the process/control has been implemented and recently started operating';
                                                              break;
                                                          case 'unconformity':
                                                              progressValue = "Hasn't even been checked yet";
                                                              break;
                                                          case 'nonexistent':
                                                              progressValue = 'Complete lack of recognisable policy, procedure, control etc.';
                                                              break;
                                                          case 'initial':
                                                              progressValue =
                                                                  "Development has barely started and will require significant work to fulfill the requirements";
                                                              break;
                                                          case 'limited':
                                                              progressValue = 'Progressing nicely but not yet complete';
                                                              break;
                                                          case 'defined':
                                                              progressValue =
                                                                  "Development is more or less complete although detail is lacking and/or it's not yet implemented, enforced and actively supported by top management";
                                                              break;
                                                          case 'managed':
                                                              progressValue =
                                                                  'Development is complete, the process/control has been implemented and recently started operating';
                                                              break;
                                                          case 'optimised':
                                                              progressValue =
                                                                  "The requirement is fully satisfied, is operating fully as expected, is being actively monitored and improved, and there's substantial evidence to prove all that to the auditors";
                                                              break;
                                                          case 'unknown':
                                                              progressValue = "Hasn't even been checked yet";
                                                              break;
                                                          default:
                                                              progressValue = 'ALL requirements in the main body of ISO/IEC 27001 are mandatory IF your ISMS is to be certified. Otherwise, management can ignore them.';
                                                              break;
                                                      }
                                  
                                                      // Initialize a variable to store the percentage value for the current status
                                                      let percentageValue = 0;
                                                      let countValue =0;
                                  
                                                      // Calculate the percentage value for the current status
                                                      switch (status.toLowerCase()) {
                                                          case 'unknown':
                                                              // For "unknown" status, use the percentage value of "unconformity"
                                                              percentageValue = statusPercentages.unconformity;
                                                              break;
                                                          case 'managed':
                                                              // For "managed" status, use the percentage value of "conformity"
                                                              percentageValue = statusPercentages.conformity;
                                                              break;
                                                          default:
                                                              // For other statuses, use the corresponding percentage value
                                                              percentageValue = statusPercentages[status.toLowerCase()];
                                                              break;
                                                      }

                                                      // Calculate the count value for the current status
                                                  switch (status.toLowerCase()) {
                                                    case 'unknown':
                                                        // For "unknown" status, use the percentage value of "unconformity"
                                                        countValue = countt.unconformity;
                                                        break;
                                                    case 'managed':
                                                        // For "managed" status, use the percentage value of "conformity"
                                                        countValue = countt.conformity;
                                                        break;
                                                    default:
                                                        // For other statuses, use the corresponding percentage value
                                                        countValue = countt[status.toLowerCase()];
                                                        break;
                                                }
                              
                                  
                                                      // Display the table row with the percentage value for the current status
                                                      // return (
                                                      //   <tr key={`desired-status-${index}`}>
                                                      //       <td className="text-right ">{status}</td>
                                                      //       <td className="text-left !important">{progressValue}</td>
                                                      //       <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                      //   </tr>
                                                      // ); 
                                                      return (
                                                        //       <tr key={`desired-status-${index}`}>
                                                        //           <td className="text-right ">{status}</td>
                                                        //           <td className="text-left !important">{progressValue}</td>
                                                        //           <td className="text-left !important">{percentageValue ? percentageValue : 0}%</td>
                                                        //       </tr>
                                                        //     ); 
                                                        // })}
                                                        <React.Fragment key={`desired-status-${index}`}>
                                                          <tr onClick={() => handleRowClick(index)}>
                                                              <td>
                                                                  <span style={{backgroundColor: getStatusColor(status), padding: '5px', borderRadius: '5px', color: '#fff'}}>
                                                                      {status}
                                                                  </span>
                                                              </td>
                                                              <td>{progressValue}</td>
                                                              <td>
                                                                  {percentageValue ? percentageValue : 0}% ,&nbsp;
                                                                  {countValue ? countValue : 0}&nbsp;&nbsp;Controls
                                                              </td>
                                                           </tr>
                                                                {/* Render expanded row below the clicked row */}
                                                                {expandedRowIndex === index && (
                                                                <tr>
                                                                <td colSpan="3">
                                                                  {/* Display IDs corresponding to the status */}
                                                                
                                                                  {
                                                                        itemsByStatusState[status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status] ? (
                                                                          <div style={{ backgroundColor:'lightgray' }}>
                                                                          {/* <div style={{ backgroundColor: '#FFAE62' }}> */}
                                                                            Control Id's - 
                                                                            {
                                                                              itemsByStatusState[
                                                                                status === 'Managed' ? 'conformity' : status === 'Unknown' ? 'unconformity' : status
                                                                              ].map((item, idIndex) => item.id).join(', ')
                                                                            }
                                                                          </div>
                                                                        ) : (
                                                                          <div>No data</div>
                                                                        )
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                              </React.Fragment>
                                                            );
                                                  })}
                                              </tbody>
                                          </Table>  
                                        ) } 
                                </div>

                                <div ref={pieIsaRefImage} className="flex-fill p-4 rounded-3 border" >
                                    <h5>Compliance metrics</h5>
                                        <div className='col-12'>
                                          {/* {console.log(Object.values(itemOccurrences))} */}
                                          {/* {annexData===null ? <NoDataFound errorText={"No data found"} /> : */}
                                          {      pieLoading ? (
                                                    // Display loader while loading
                                                    <PieChartLoader />
                                                ) : Object.values(itemOccurrences).length === 0 ? <NoDataFound errorText={"No data found"} /> : (
                                                    // Display the actual pie chart once data is loaded
                                                    <Pie data={pieChartData} options={options} />
                                                )}                                       
                                          
                                          
                                          
                                        </div>                            
                                </div>
                            </div>
                            {/* <div  className="row col-12 p-4 rounded-3 border" >
                                    <h5>Top Failed ISO 27001 Requirements</h5>
                                    {/* { isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : */}
                                      {/* { pieLoading ? (
                                            // Display loader while loading
                                            <PlainCardLoader width="200px" />
                                        ) :  isoData.length === 0 ?<NoDataFound errorText={"No data found"} /> : (
                                            // Display the actual pie chart once data is loaded
                                            // <Bar data={IsoPieData} options={options}  />
                                          <ReactApexCharts options={isoOption.options} 
                                            series={isoOption.series} type="bar" height={350} />
                                          
                                        )}                             */}
                            {/* </div>   */} 
                            <div ref={cardIsaRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-5">
                                <div className="col-12 gap-3 d-flex flex-row" >
                                {comUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                          <RecentTab
                                              tabName={" Alert "}
                                              mostAnomalouseList={comUserData}
                                              type={"comp"}
                                              selectedField={'compUser'}
                                              FieldLabel={'CompUser'}
                                              loader={recentCompLoader}                             
                                          />
                                        }
                                  {hostUserData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={"Host Name"}
                                        mostAnomalouseList={hostUserData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}
                                    />
                                      }
                                    {userData===null ? <NoDataFound errorText={"No data found"} /> :
                                    <RecentTab
                                        tabName={"User Name"}
                                        mostAnomalouseList={userData}
                                        type={"comp"}
                                        selectedField={'compUser'}
                                        FieldLabel={'CompUser'}
                                        loader={recentCompLoader}
                                    />
                                    }
                                    </div>
                            </div> 
                          </div>                           
                    </Tab>
                </Tabs>                 
            <ToastContainer />        
        </div>  
    </>
  );
};

export default Overview;

