import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { convertUTCTimeToLocal, exportCSVFile } from "../../../Utils/Utils";
import moment from "moment";

function getTimeFromTimestamp(timestamp) {
  return moment.unix(timestamp).format('DD-MM-YYYY hh:mm A');
}

const CaseManagementExport = ({
  caseManagementTableData,
  filter,
  dateFilter,
}) => {
  const [loader, setLoader] = useState(false);
  
  const getFields = () => {
    return [
      { name: "Case Id", value: "case_id" },
      { name: "Case Title", value: "case_title" },
      { name: "Created On", value: "case_created" },
      { name: "Category", value: "category" },
      { name: "State", value: "state" },
      { name: "Severity", value: "severity" },
      { name: "Assigned To", value: "assigned_to" },
    ];
  };

  const convertdata = (fields, data) => {
    return data.map((item) => {
      const dynamicJSON = {};

      fields.forEach(({ name, value }) => {
        let fieldValue;
        if (value !== "case_created") {
          fieldValue = item._source[value];
        } else {
          const utcTime = item._source[value];
          const localTime = convertUTCTimeToLocal(utcTime);
          const time = moment(localTime).format('hh:mm:ss A'); // Format both date and time
          const date = moment.utc(utcTime).format('DD-MM-YYYY');
          // Combine both date and time
          fieldValue = `${date} , ${localTime}`;
          console.log("Formatted Date and Time:", localTime);
        }
        dynamicJSON[name] = fieldValue;
      });

      return dynamicJSON;
    });
  };

  const csvExport = async () => {
    setLoader(true);
    const size = 1000;
    let data = await caseManagementTableData(filter, dateFilter, 0, size);
    let data3 = data.data.hits.hits;
    const count = data.data.hits.total.value;
    let iter = 1000;

    while (count > 1000 && iter <= count) {
      const tempdata = await caseManagementTableData(filter, dateFilter, iter, size);
      data3.push(...tempdata.data.hits.hits);
      iter += size;
    }

    const fields = getFields();
    const filename = `case_${moment().format('DD-MM-YYYY_hh-mm-A')}`;
    const data1 = convertdata(fields, data3);
    console.log("output...",data1);
    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item onClick={csvExport}>
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default CaseManagementExport;
