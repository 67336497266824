import React, { useEffect, useState } from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../Loader/Loader";
import 'react-toastify/dist/ReactToastify.css';

import { getHunterSelectList, createCustomHunt, getHunterCatalogueDetails, getInvCatalogueTenant } from "../../../../Methods/RuleStudioMethods";
import CustomHuntInformation from "./CustomHuntInformation";
import { Button, Form } from "react-bootstrap";





const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


const HuntDetail = ({
  setDetailShow, detailShow, setHunterIdEdit,
  huntIdEdit, setSubmitLoader, submitLoader }) => {
  const dispatch = useDispatch();
  

  const [selectedList, setList] = useState([]);
  const [catalogue, setCatalogue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hunterId, setHunterID] = useState("HUNT-");
  const [hunt, setHunt] = useState("HUNT--")
  const [hunterName, setHunterName] = useState("");
  const [description, setDescription] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");

  const [notify, setNotify] = useState("");
  const [steps, setSteps] = useState([
    {
      name: "",
      description: "",
      inv_playbook: [],
    },
  ]);

  console.log(steps)
  // const detailsCustomRsHunt = useSelector((state) => state.detailsCustomRsHunt);
  // const { catalogue, loading: loading } = detailsCustomRsHunt;






  const setDataInEditList = () => {

    setHunterID(huntIdEdit === '' ? '' : catalogue?.hunt_id);
    setHunterName(huntIdEdit === '' ? '' : catalogue?.hunt_name);
    setDescription(huntIdEdit === '' ? '' : catalogue?.Description);
    setDetailedDescription(huntIdEdit === '' ? '' : catalogue?.detailed_description);
    setNotify(huntIdEdit === '' ? '' : catalogue?.notify);
    // setSteps(huntIdEdit === '' ? [{
    //   name: "",
    //   description: "",
    //   inv_playbook: [],
    // },] : catalogue?.steps);

  };





  useEffect(() => {
    dispatch(getHunterSelectList());
    dispatch(getInvCatalogueTenant());

    if (
      (huntIdEdit || Object.keys(catalogue).length === 0) &&
      huntIdEdit != catalogue?.hunt_id && huntIdEdit != ''
    ) {
      setLoading(true)
      getHunterCatalogueDetails(huntIdEdit).then((res) => {

        const stepsData = res?.data?.steps
        setCatalogue(res?.data)


        setSteps(stepsData);
        setLoading(false)
      })
    }

    if (huntIdEdit != "") {
      setDataInEditList();
    } else {
      setHunterID("HUNT-");
      setHunterName("");
      setDescription("");
      setDetailedDescription("");
      setNotify("")
    }
  }, [huntIdEdit, catalogue, detailShow]);


  const updateSteps = (updatedSteps) => {
    // Add stepNumber property to each step object
    const stepsWithStepNumber = updatedSteps.map((step, index) => ({
      step: index + 1, ...step,
      // Assuming step numbering starts from 1
    }));

    setSteps(stepsWithStepNumber);
  };






  const addMore = () => {
    let obj = {
      name: "",
      description: "",
      inv_playbook: [],
    };
    setSteps([...steps, obj]);
  };

  let stepsDiv = steps?.map((data, index) => (
    <CustomHuntInformation
      steps={steps}
      setSteps={updateSteps}
      index={index}
      data={data}
      addMore={addMore}
    />
  ));



  const onSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to save changes?")) {
      setSubmitLoader(true)
      let data = {
        hunt_id: hunterId,
        hunt_name: hunterName,
        Description: description,
        detailed_description: detailedDescription,
        notify,
        steps

      };
      let id = hunterId

      dispatch(createCustomHunt(data, id));
    }



  };

  const removeHtmlTags = (text) => {
    return text?.replace(/<[^>]*>/g, '');
};


  const renderModal = () => (
    <>
    {loading === true ? (
      <div style={{ width: '300px' }}>
        <Loader />
      </div>
    ) : (
      <span className="was-validated">
             <div className="modal-row-item">
                <span className="modal-label col-2">Hunt ID</span>
                {console.log(hunterId)}
                <span className="modal-text ">{hunterId}</span>
              </div>
              <div className="modal-row-item">
                <span className="modal-label col-2">Hunt Name </span>
                <span className="modal-text ">{hunterName}</span>
              </div>

              {description &&(
              <div className="modal-row-item">
                <span className="modal-label col-2">Description </span>
                <span className="modal-text "> {removeHtmlTags(description)}</span>
              </div>
              )}

              {detailedDescription &&(
              <div className="modal-row-item">
                <span className="modal-label col-2">Detailed Description </span>
                <span className="modal-text ">{removeHtmlTags(detailedDescription)}</span>
              </div>
                )}

              {notify &&(
              <div className="modal-row-item">
                <span className="modal-label col-2">Notify </span>
                <span className="modal-text ">{notify}</span>
              </div>
              )}

              {steps &&(
              <div className="modal-row-item">
              <span className="modal-label col-2">Steps</span>
              <span className="modal-text">
                  {Array.isArray(steps) ? (
                      steps.map((data, index) => (
                          <React.Fragment key={index}>
                              {index > 0 && <br />} {/* Add a line break if not the first step */}
                              <strong>Step {index + 1}</strong>: {data.name}
                          </React.Fragment>
                      ))
                  ) : (
                      <React.Fragment>
                          Step 1: {steps?.name}
                      </React.Fragment>
                  )}
              </span>
          </div>
              )}
                  


         
            
      </span>
    )}
  </>
  );
  const handleClose = () => {
      setDetailShow(false);
      setHunterIdEdit("");
    

  };




  return (
    <>
      {" "}
      {detailShow === true && (
        <CustomModal
          title={'Detail Hunter'}
          ShowOrHide={detailShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          modalSize={'lg'}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}{" "}
    </>
  );
};

export default HuntDetail;