import React, { useState } from 'react';
// import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from "react-router-dom";
import SearchModal from "../../components/CustomModal/SearchModal";





function SearchBar() {
  const [query, setQuery] = useState('');
  const [customShow, setCustomShow] = useState(false);
  const [show, setShow] = useState(false);







  //   function handleCloseModal() {
  //     setShow(false);
  //   }

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }
  const handleCustom = () => {

    setCustomShow(true);


  };




  function handleClose() {
    setCustomShow(false);
  }

  function renderModal() {
    return (

      <div>
        <div className="col-12 d-flex flex-column p-4 mb-4 rounded-3" style={{ backgroundColor: "rgb(241, 241, 241)", cursor: "pointer " }} ><h6 className="">'No Pineapple' Hacking Campaign Reveals North Korean Toolkit</h6><span style={{ fontSize: "0.8rem" }} >Feb 2, 23, 3:07:58 PM</span></div>
        <div className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card" style={{ backgroundColor: "rgb(241, 241, 241)", cursor: "pointer" }}  ><div className="d-flex flex-row"><div className="d-flex flex-column"><span>CVE ID</span><h6>CVE-2022-36816</h6></div><div className="d-flex flex-column mx-3"><span>Published on</span><h6>01 Jan 2023 at 01:15:10 AM</h6></div><div className="d-flex flex-column"><span>Severity</span><h6></h6></div></div><div>To maintain compliance with CNA rules, we have rejected this CVE record because it has not been used.</div></div>
        <div className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card" style={{ backgroundColor: "rgb(241, 241, 241)", cursor: "pointer" }}><h6>Accellion FTA OS Command Injection Vulnerability</h6><div>Accellion FTA 9_12_370 and earlier is affected by OS command execution via a crafted POST request to various admin endpoints.</div><div>Accellion</div><div>Critical</div><div>Nov 3, 21, 5:30:00 AM</div></div>
        <div className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card" style={{ backgroundColor: "rgb(241, 241, 241)", cursor: "pointer" }}><h6>Amazon Acknowledges Sharing Ring Data With Police Without Informing Users</h6><div>Amazon has provided US law enforcement agencies with data from Ring video doorbells nearly a dozen times since the start of 2022. While Amazon’s policy states that police may not view recordings without the explicit permission of the devices’ owners, that policy is superseded by subpoenas and emergency requests. Amazon confirmed that they had shared Ring footage in a letter responding to questions posed by US Senator Ed Markey (D-Massachusetts).</div><div>High</div><div>white</div></div>
      </div>




    )
  }



  return (
    <>
      <form onSubmit={handleSubmit}>
        <input type="text" style={{ marginLeft: "-310px", position: "absolute" }} value={query} onChange={handleInputChange} />
        <Link style={{ color: "white" }} onClick={() => handleCustom()}>
          {/* <AiOutlineSearch style={{ color:"black", position:"relative", marginLeft:"-190px" }} type="submit"></AiOutlineSearch> */}

        </Link>
      </form>







      {customShow === true && (
        <SearchModal
          title={""}
          ShowOrHide={setCustomShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
        />
      )}

    </>




  );
}






export default SearchBar;


