import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import Correlation from "./Correlation/Correlation";
import styles from "./InvOverview.styles";
import Dashboard from "./Dashboard/Dashboard";
import Investigations from "./Investigation/Investigation";
import CykitInvestigation from "./Cykit/CykitInvestigation";
import InvestigationSummary from './InvestigationSummary/InvestigationSummary';
import CyFirewall from "./InvestigationSummary/CyDashboards/CyFirewall";
import CyActiveDirectory from "./InvestigationSummary/CyDashboards/CyActiveDirectory";
import CyLinux from "./InvestigationSummary/CyDashboards/CyLinux";
import CyEmail from "./InvestigationSummary/CyDashboards/CyEmail";
import CySoftware from "./InvestigationSummary/CyDashboards/CySoftware";
import CyVulns from "./InvestigationSummary/CyDashboards/CyVulns";
import CyWAF from "./InvestigationSummary/CyDashboards/CyWAF";
import Office365ActiveDirectory from "./InvestigationSummary/CyDashboards/Office365/Office365ActiveDirectory";
import Office365OneDrive from "./InvestigationSummary/CyDashboards/Office365/Office365OneDrive";
import Office365Exchange from "./InvestigationSummary/CyDashboards/Office365/Office365Exchange";
import ATPAlert from "./InvestigationSummary/CyDashboards/ATPAlert";
import GroupedInvestigationView from "./GroupedInvestigationView/GroupedInvestigationView";
import ThreatLandscape from "../ManagementDashboard/Threatlandscape/ThreatLandscape";
import CySoftwareCykit from "./InvestigationSummary/CyDashboards/CySoftwareCykit";
import CyVulnsCykit from "./InvestigationSummary/CyDashboards/CyVulnsCykit";

const InvOverview = () => {
  const location = useLocation();
  const params = useParams();
  let navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  // const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const filterSet = () => {
    if (localStorage.getItem("filter")) {
      return localStorage.getItem("filter")
    }
    else {
      let selectedDate;

      switch ("1 Day") {
        case "1 Day":
          selectedDate = 1;
          break;
        case "7 Days":
          selectedDate = 7;
          break;
        case "30 Days":
          selectedDate = 30;
          break;
        case "1 Year":
          selectedDate = 365;
          break;
        default:
      }

      let current = new Date();
      let startD = new Date(
        current.getTime() - selectedDate * 24 * 60 * 60 * 1000
      )
        .toISOString()
        
      let endD = current.toISOString()

      localStorage.setItem("gte", startD);
      localStorage.setItem("lte", endD);
      localStorage.setItem("filter", "1 Day");

      return "1 Day"

    }
  }
  const [selectedDateFilter, setSelectedDateFilter] = useState(filterSet())
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();

  const previousPageInfo = JSON.parse(localStorage.getItem("historyPreviousPage"));


  const [inv, setInv] = useState(true);

  // states for filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [showDate, setShowDate] = useState(true);



  // console.log("location", location)
  // console.log("params", params)
  // console.log("navigate", navigate)

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);


  var path = null
  if (currentPath === "summary") {
    let incidentId = previousPageInfo?.params?.id
    // console.log("incidentId", incidentId)
    // console.log("previousPageInfo", previousPageInfo)
    path = previousPageInfo?.pathname?.includes("investigate/grouped_investigation") ?
      [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Cybot SOC - Incidents", "url": "/investigate/cybot_soc" }, { "title": incidentId, "url": "/investigate/grouped_investigation/" + incidentId }, { "title": params.hunt_detection_id, "url": "" }] :
      [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Cybot SOC - Investigated Detections", "url": "/investigate/cybot_soc" }, { "title": params.hunt_detection_id, "url": "" }]
    localStorage.setItem("historyPreviousPage", null);
    // console.log("path", path)
  }

  useEffect(() => {
    if (currentPath === "dashboard") {
      setTitle("Investigation Dashboard")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Dashboard", "url": "/investigate/dashboard" },]
      });
    }
    else if (currentPath === "cybot_soc") {
      setTitle("Investigations")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Cybot SOC", "url": "/investigate/cybot_soc" },]
      });
    }
    else if (currentPath === "summary") {
      setTitle("Investigation Summary")
      setBreadcrumb({
        "module": "investigate",
        "path": path
        // "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Detections", "url": "/investigate/investigation" }, { "title": params.hunt_detection_id, "url": "" },]
      });
    }
    else if (currentPath === "grouped_investigation") {
      setTitle("Incidents")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "Cybot SOC - Incidents", "url": "/investigate/cybot_soc" }, { "title": params.id, "url": "" },]
      });
    }
    else if (currentPath === "cykit") {
      setTitle("CyKit")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Firewall", "url": "/investigate/firewall" },]
      });
    }
    else if (currentPath === "firewall") {
      setTitle("CyKit - Firewall")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Firewall", "url": "/investigate/firewall" },]
      });
    }
    else if (currentPath === "active_directory") {
      setTitle("CyKit - Windows Events ( AD, Security , File Share )")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Windows Events ( AD, Security , File Share )", "url": "/investigate/active_directory" },]
      });
    }
    else if (currentPath === "email") {
      setTitle("CyKit - Email")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Email", "url": "/investigate/email" },]
      });
    }
    else if (currentPath === "waf") {
      setTitle("CyKit - WAF")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - WAF", "url": "/investigate/waf" },]
      });
    }
    else if (currentPath === "atp_alerts") {
      setTitle("CyKit - ATP Alerts")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - ATP Alerts", "url": "/investigate/atp_alerts" },]
      });
    }
    else if (currentPath === "o365_active_directory") {
      setTitle("CyKit - Office 365 Active Directory")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Office 365 AD", "url": "/investigate/o365_active_directory" },]
      });
    }
    else if (currentPath === "o365_one_drive") {
      setTitle("CyKit - Office 365 One Drive")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Office 365 One Drive", "url": "/investigate/o365_one_drive" },]
      });
    }
    else if (currentPath === "o365_exchange") {
      setTitle("CyKit - Office 365 Exchange")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Office 365 Exchange", "url": "/investigate/o365_exchange" },]
      });
    }
    else if (currentPath === "software") {
      setTitle("CyKit - Software")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Software", "url": "/investigate/software" },]
      });
    }
    else if (currentPath === "vulnerabilities") {
      setTitle("CyKit - Vulnerabilities")
      setBreadcrumb({
        "module": "investigate",
        "path": [{ "title": "Investigate", "url": "/investigate/dashboard" }, { "title": "CyKit - Vulnerabilities", "url": "/investigate/vulnerabilities" },]
      });
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"investigate"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          inv={inv}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          isNewFilterEnabled={true}
          filter={filter}
          setFilter={setFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          setShowAddFilter={setShowAddFilter}
          showAddFilter={showAddFilter}
          isFilterEnabled={currentPath === "dashboard"}
          tab={['main',"main-group","main-atten","cykit-firewall","cykit-email","cykit-ad","cykit-waf","cykit-atp-alerts","cykit-o365ad","cykit-o365ex","cykit-o365onedrive"]}
          module={"investigation"}

        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "dashboard" ? (
            <Dashboard
              selectedDateFilter={selectedDateFilter}
              filter={filter}
              setFilter={setFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              setShowAddFilter={setShowAddFilter}
            />
          ) : currentPath === "cybot_soc" ? (
            <Investigations selectedDateFilter={selectedDateFilter} />
          ) : currentPath === "summary" ? (
            <InvestigationSummary />
          )
            // : currentPath === "cykit" ? (
            //   <CykitInvestigation />
            // )
            : currentPath === "grouped_investigation" ? (
              <GroupedInvestigationView />
            )
              : currentPath === "correlation" ? (
                <Correlation selectedDateFilter={selectedDateFilter} />
              ) : currentPath === "firewall" || currentPath === "cykit" ? (
                <CyFirewall />
              ) : currentPath === "active_directory" ? (
                <CyActiveDirectory />
              ) : currentPath === "email" ? (
                <CyEmail />
              ) : currentPath === "linux" ? (
                <CyLinux />
              ) : currentPath === "waf" ? (
                <CyWAF />
              ) : currentPath === "atp_alerts" ? (
                <ATPAlert />
              ) : currentPath === "o365_active_directory" ? (
                <Office365ActiveDirectory />
              ) : currentPath === "o365_one_drive" ? (
                <Office365OneDrive />
              ) : currentPath === "o365_exchange" ? (
                <Office365Exchange />
              ) : currentPath === "software" ? (
                <CySoftwareCykit />) : currentPath === "vulnerabilities" ? (
                  <CyVulnsCykit />) : null}
        </div>
      </div>
    </div>
  );
};

export default InvOverview;
