import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";

import {
  getInvCatalogueMaster,
  getInvCatalogueTenant,
  addToOrganizationCatalogue,
  removeFromCatalogue,
  refreshTenantCatalogue,
  refreshMasterInv,
  setLoadingFalseInv
} from "../../../Methods/RuleStudioMethods";
import styles from "./Investigation.styles";
import Card from "react-bootstrap/Card";


import CustomModal from "../../../components/CustomModal/CustomModal";
import { Button, Form, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
// import Custom from "../../../components/CustomModal/RSCustomModal";
import { toastsSuccess, toastsDanger, toastsWarning } from "../../../components/Toster/Toster";

import MasterCardInv from "../../../components/RuleStudio/MasterCardInv";
import LoaderList from "../../../components/Loader/LoaderList";
import CustomMasterModal from "../../../components/RuleStudio/Investigation/Custom/CustomMasterModal";
import { Pencil, PencilSimple, X, ArrowRight } from "phosphor-react";
import {
  escapeHtmlChars, 
  formatDate,
  getDateFromTimestamp,
  rawHTML,   
  removeHtmlChars,
  dateFormat,

} from "../../../Utils/Utils.js";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import RS_Filter from "../../../components/RuleStudio/RS_Filter";
import SearchTi from "../../../components/Search/SearchTi";
import { ToastContainer, toast } from 'react-toastify';
import InvDetailModal from "../../../components/RuleStudio/Investigation/InvDetailModal.jsx"


const Investigation = () => {
  const dispatch = useDispatch();

  const ruleStudioAddToCat = useSelector((state) => state.ruleStudioAddToCat);
  const { success: catalogueAddSuccess, loading } = ruleStudioAddToCat;

  const listMasterRsIVnV = useSelector((state) => state.listMasterRsIVnV);
  const { master, } = listMasterRsIVnV;

  const listCatRsInv = useSelector((state) => state.listCatRsInv);
  const { catalogues: catalogue, listLoading: catLoading, deleteSuccess, deletedId } = listCatRsInv;

  // const removeCatRsInv = useSelector((state) => state.removeCatRsInv);
  // const { success, error } = removeCatRsInv;

  const createMasterRsInv = useSelector((state) => state.createMasterRsInv);
  const { success: createSuccess, error: createError } = createMasterRsInv;




  const [show, setShow] = useState(false);


  const [invId, setInvId] = useState("");
  const [masterFilterAll, setMasterFilterAll] = useState(false);


  const [filteredDataMaster, setFilteredDataMaster] = useState([]);
  const [filteredDataCatalogue, setFilteredDataCatalogue] = useState([]);
  const [customShow, setCustomShow] = useState(false);

  const [refresh, setRefresh] = useState(false)
  const [add, setAdd] = useState(false);
  const [cat, setCat] = useState(true);
  const [data, setData] = useState("")


  const [searchQueryCatalogue, setSearchQueryCatalogue] = useState("");
  const [catalogueDataFinal, setCatalogueDataFinal] = useState([]);

  const [searchQueryMaster, setSearchQueryMaster] = useState("");
  const [filterQueryMaster, setFilterQueryMaster] = useState("All");
  const [masterDataFinal, setMasterDataFinal] = useState([]);
  const [masterSort, setMasterSort] = useState("asc");
  const [ORGFilter, setORGFilter] = useState("");
  const [excludedElements, setExcludedElements] = useState([]);
  const [masterFilterData, setMasterFilterData] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [allMasterData, setAllMasterData] = useState([]);
  const [toggleViewAll, setToggleViewAll] = useState(false);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [masterLoading, setMasterLoading] = useState(false);

  // Detail page view
  const [detailShow, setDetailShow] = useState(false);







  const getFilteredItems = (state) => {

    if (state.length > 0) {
      let data = [];

      state?.map((val, index) => {

        if (!data.includes(val.log[0])) {
          data.push(val.log[0])
        }
      })

      return data
    }


  }

  const sevFilterListTelnet = useSelector((state) => getFilteredItems(state.listCatRsInv.catalogues));




  const filterCatalogue = (val) => {

    let filteredItems

    let search = val != undefined ? val : searchQueryCatalogue



    if (ORGFilter === 'All') {
      filteredItems = catalogue?.filter((item) =>

        item.inv_id.toLowerCase().includes(search.toLowerCase()) ||
        item.inv_name.toLowerCase().includes(search.toLowerCase()) ||
        item.Description.toLowerCase().includes(search.toLowerCase())
      )
    } else {
      filteredItems = catalogue?.filter((item) =>
        (item.inv_id.toLowerCase().includes(search.toLowerCase()) ||
          item.inv_name.toLowerCase().includes(search.toLowerCase()) ||
          item.Description.toLowerCase().includes(search.toLowerCase())
        )
        && (item.log[0]?.toLowerCase().includes(ORGFilter.toLowerCase()))


      )
    }


    setCatalogueDataFinal(filteredItems)

  }

  const searchDataListCatalogue = () => {

    if (searchQueryCatalogue===""||searchQueryCatalogue!="") {
      let filteredItems = catalogue?.filter((item) =>

        item.inv_id.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
        item.inv_name.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
        item.Description.toLowerCase().includes(searchQueryCatalogue.toLowerCase())
      )
      return filteredItems
    }



  }




  useEffect(() => {


    if (searchQueryCatalogue || ORGFilter || deletedId) {

      filterCatalogue()
      filterDataCatalogue(searchQueryCatalogue);
    } else {

      dispatch(getInvCatalogueTenant());
      setInvId("");
    }



  }, [searchQueryCatalogue, dispatch, ORGFilter, deletedId]);


  useEffect(() => {
    if (catalogueAddSuccess === true) {
      toastsSuccess("Data added successfully")
      toastsWarning("It might take a while for the data to be updated !")
    }
  }, [catalogueAddSuccess])



  // Refresh
  useEffect(() => {
    refreshTenantCatalogue()
    refreshMasterInv()
  }, [])




  //  Toasts
  useEffect(() => {



    if (createSuccess === true) {

      toastsSuccess("Data saved successfully")
      toastsWarning("It might take a while for the data to be updated !")
      setSubmitLoader(false)
      setCustomShow(false)
      dispatch(setLoadingFalseInv())
    }

  }, [createSuccess])



  if (catalogue?.length > 0 && catalogueDataFinal.length === 0 && searchQueryCatalogue === '' && ORGFilter === '') {
    filterCatalogue()
  }




  const handleOpenModal = (display) => {
    setCat(false);
    setShow(display);
  };



  function handleClose() {
    setMasterFilterAll(false);
    setCat(true);
    setShow(false);
  }

  // close for detail page
  function handleCloseDetail  () {
      setDetailShow(false);
      setInvId("");
    }
 



  const filterDataCatalogue = (query) => {

    const filtered = filteredDataCatalogue?.filter((item) => {
      return (
        item[0].toLowerCase().includes(query.toLowerCase()) ||
        item[1].toLowerCase().includes(query.toLowerCase()) ||
        item[2].toLowerCase().includes(query.toLowerCase())
      );
    });


    setFilteredDataCatalogue(filtered);
  };

  const AddToCatalogue = (id) => {

    setAdd(true);

    if (window.confirm("Are you sure?")) {
      dispatch(addToOrganizationCatalogue(id));
    }
  };

  const deleteFromCatalogue = (id, index) => {
    if (window.confirm("Delete the item?")) {
      toastsDanger("Deleted successfully")
      toastsWarning("It might take a while for the data to be updated !")

      dispatch(removeFromCatalogue(id, index));

      if (deleteSuccess === true || deleteSuccess === undefined) {
        refreshTenantCatalogue().then((res) => {
          if (res.status === 200) {
            dispatch(getInvCatalogueTenant())
          }
        })
      }
    }
  };



  const handleRefresh = (status = false) => {

    setRefresh(true)

    if (refresh === true || status === true) {
      refreshMasterInv().then((res) => {

        if (res.status === 200) {
          setRefresh(false)
        }
      })
    }


  };

  const handleCustom = (display) => {
    setInvId("");
    setCustomShow(display);
  };

  const handleEdit = (invId) => {

    setInvId(invId);
    setCustomShow(true);
  };

   // detail modal
   const handleModal = (item) => {
    setInvId(item.inv_id);
    setDetailShow(true);
  };

  // const handleModal = (tenandID, id, status) => {
  //   if (status !== "closed") {
  //     const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
  //     fpostC(`${baseURL}/cybot_proxy/${tenandID}/_update/${id}`, data).then((res) => {
  //       console.log("OPEN RSE", res)
  //     })
  //   }
  // }

  const handleSearch = (val, e, action) => {
    console.log(val)
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryCatalogue(val)
      filterCatalogue(val)

    }
  };




  // master data load when change search
  const handleSearchMaster = (val, e, action) => {
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryMaster(val)
      getMasterDataFn(val, masterSort, toggleViewAll, filterQueryMaster)
    }
  };




  // master data load when change sort
  useEffect(() => {
    if (show === true && masterSort) {
      getMasterDataFn(searchQueryMaster, masterSort, toggleViewAll, filterQueryMaster)
    }
  }, [dispatch, masterSort, show])


  // master data load when change toggle
  const handleToggleChange = (val) => {

    const tog = !toggleViewAll;

    setToggleViewAll(!toggleViewAll);

    getMasterDataFn(searchQueryMaster, masterSort, tog, filterQueryMaster)

  }

  //  master data load when change filter
  useEffect(() => {

    getMasterDataFn(searchQueryMaster, masterSort, toggleViewAll, filterQueryMaster)
  }, [filterQueryMaster])


  const filterMasterData = (data, search, toggle, filter) => {
    let filteredItems

    if (filter === 'All') {

      filteredItems = data?.filter((item) =>

        item.inv_id.toLowerCase().includes(search.toLowerCase()) ||
        item.inv_name.toLowerCase().includes(search.toLowerCase()) ||
        item.Description.toLowerCase().includes(search.toLowerCase())
      )
    } else {

      filteredItems = data.filter((item) =>
        (item.inv_id.toLowerCase().includes(search.toLowerCase()) ||
          item.inv_name.toLowerCase().includes(search.toLowerCase()) ||
          item.Description.toLowerCase().includes(search.toLowerCase())
        )
        && (item.log[0].toLowerCase().includes(filter.toLowerCase()))


      )

    }

    if (toggle === false) {
      filteredItems = filteredItems?.filter(item1 => !catalogue?.find(item2 => item1.inv_id === item2.inv_id));
    }


    setCommonData(filteredItems)
  }


  const getMasterDataFn = (search, sort, toggle, filter) => {



    setMasterLoading(true)
    getInvCatalogueMaster(sort).then((res) => {

      if (res.status === 200) {
        setMasterLoading(false)
        setAllMasterData(res.data)
        filterMasterData(res.data, search, toggle, filter)

      }

    })
  }

  const searchDataListMaster = () => {
    if (searchQueryMaster===""||searchQueryMaster!="") {


      let filteredItems = allMasterData?.filter((item) =>

        item.inv_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
        item.inv_name.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
        item.Description.toLowerCase().includes(searchQueryMaster.toLowerCase())
      )


      if (toggleViewAll === false) {
        filteredItems = filteredItems?.filter(item1 => !catalogue?.find(item2 => item1.inv_id === item2.inv_id));
      }


      return filteredItems

    }
  }






  function renderModal() {
    return (
      <div className="px-3">
        <div className="mb-3 py-2 sticky-top">
          {/* <h5>Master Catalogue</h5> */}
          <div className="row">
            <div className="col-12 d-flex py-2 gap-2">
              <div className="col-3">
                <SearchTi changeFunction={handleSearchMaster} module='rulestudio' />

              </div>
              <div className="col-3">
                <div className="form-group">

                  <RS_Filter
                    filter={filterQueryMaster}
                    setFilter={setFilterQueryMaster}
                    count={commonData.length}
                    allCount={commonData.length}
                    cData={cat === true ? master : commonData}
                    data={getFilteredItems(allMasterData)}
                    dataList={commonData}
                    masterFilterAll={masterFilterAll}
                    cat={cat}
                    comData={data}
                    searchData={searchDataListMaster()}
                  />



                </div>
              </div>

              <div className="flex-fill d-flex flex-row align-items-center gap-2">
                <span className="w-25 text-right">Sort by</span>
                <select className="form-select" onChange={(e) => setMasterSort(e.target.value)}>
                  <option value="asc">Oldest</option>
                  <option value="desc">Newest</option>

                </select>
              </div>
              <div className="d-flex flex-row align-items-center">
                <Form>
                  <Form.Check
                    type="switch"
                    id="toggle_view_switch"
                    label="View All"
                    defaultChecked={toggleViewAll}
                    onChange={(e) => handleToggleChange(e.target.value)}
                  />
                </Form>
              </div>
              <div>
                <Link style={{ color: "white" }} onClick={() => handleRefresh(true)}>
                  <button className="btn btn-primary">Refresh</button>
                </Link>
              </div>
            </div>
          </div>
        </div>



        {masterLoading == true || refresh === true ? (
          <LoaderList />
        ) : (

          <div className="col-12" style={styles.section1}>

            {commonData?.length === 0 ? <NoDataFound /> :
              <div className="d-flex flex-column gap-3">
                {commonData?.map((item, index) => {

                  const filter = catalogue?.some(
                    (data) => data.inv_id.toLowerCase() === item.inv_id.toLowerCase()
                  );

                  return (
                    <MasterCardInv
                      item={item}
                      filter={filter}
                      AddToCatalogue={AddToCatalogue}
                      loading={loading}
                      index={index}
                    />
                  );
                })}
              </div>
            }
          </div>
        )
        }
      </div >
    );
  }

  // detail view
  function renderModalDetail() {
    console.log(invId);
    <InvDetailModal         
          setInvId={setInvId}
          invIdEdit={invId}
          submitLoader={submitLoader}
          setSubmitLoader={setSubmitLoader}         
      />
  }



  return (
    <div className="col-12 rounded-3 p-4" style={styles.section1}>
      <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
        <h5 className="mb-0">Organization Catalogue</h5>

        <div className="d-flex">
          <div className="form-group mx-2">
            <SearchTi changeFunction={handleSearch} module='rulestudio' />
            {/* <Form.Control
              type="text"
              placeholder="search"
              className=""
              style={{ width: "" }}
              onChange={(e) => setSearchQueryCatalogue(e.target.value)}
            /> */}
          </div>
          <div className="form-group">
            <RS_Filter
              filter={ORGFilter}
              setFilter={setORGFilter}
              count={catalogueDataFinal.length}
              cData={catalogue}
              data={sevFilterListTelnet}
              dataList={catalogueDataFinal}
              allCount={catalogueDataFinal.length}
              cat={cat}
              searchData={searchDataListCatalogue()}
            />


          </div>
          <Link
            className="mx-2"
            style={{ color: "white" }}
            onClick={() => handleOpenModal(true)}
          >
            <button className="btn btn-primary">Add from master</button>
          </Link>
          <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
            <button className="btn btn-light">Create Custom</button>
          </Link>

        </div>
      </div>

      {/* <LoaderGrid /> */}
      <div style={styles.mainWrap}>
        {catLoading === true ? (
          <TableLoader rowCount={15} />
        ) : (
          catalogueDataFinal.length === 0 ? <NoDataFound /> :
            <div className="d-flex flex-row flex-wrap py-3">
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>ID</th>
                    <th>Alert name</th>
                    <th>Description</th>
                    <th>Severity</th>
                    <th> Created on</th>
                    <th> Updated on</th>
                    <th>  </th>
                    {/* <th> created by</th>
                    <th> Category</th> */}
                  </tr>
                </thead>
                <tbody>

                  {catalogueDataFinal.map((item, index) => {
                    return (
                      <tr key={"rs_inv_" + index}>
                        <td className="align-middle">
                          {item.inv_id}
                        </td>
                        <td className="align-middle">
                          {item.inv_name}
                        </td>
                        <td className="align-middle">
                          {removeHtmlChars(item.Description)}
                        </td>
                        <td className="align-middle">
                          {item.severity == "Critical" && (
                            // <div className="p-2 m-0 alert alert-danger">Critical</div>
                            <Badge bg="danger" className="p-2">Critical</Badge>
                          )}
                          {item.severity == "High" && (
                            // <div className="p-2 m-0 alert alert-danger">High</div>
                            <Badge bg="danger" className="p-2">High</Badge>
                          )}
                          {item.severity == "Medium" && (
                            // <div className="p-2 m-0 alert alert-warning">Medium</div>
                            <Badge bg="warning" className="p-2">Medium</Badge>
                          )}
                          {item.severity == "Low" && (
                            // <div className="p-2 m-0 alert alert-success">Low</div>
                            <Badge bg="success" className="p-2">Low</Badge>
                          )}
                        </td>
                        <td className="align-middle">
                          {item?.inv_created && formatDate(item?.inv_created)}
                        </td>
                        <td className="align-middle">
                          {item?.inv_last_edited && formatDate(item?.inv_last_edited)}
                        </td>
                        <td className="align-middle text-center">
                          <div className="d-flex flex-row gap-3 justify-content-center">
                            <i
                              className="cursor-pointer"
                              onClick={(e) => handleEdit(item.inv_id)}
                            >
                              <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />
                            </i>
                            <i className="cursor-pointer" onClick={(e) => deleteFromCatalogue(item.inv_id, index)}>
                              <TableViewMore Icon={X} dataToggle={"Delete"} dataPlacement={"bottom"} />
                            </i>
                            <Link
                              // to={{
                              //   pathname: `/rs/summary/${item.inv_id}`,
                              //   state: { sourceList: item._source, index_id: item }
                              // }}
                              onClick={() => handleModal(item)}
                            >
                            <TableViewMore Icon={ArrowRight} dataToggle={"View details"} dataPlacement={"bottom"} />
                          </Link> 

                          </div> 



                        </td>
                      </tr>
                      // <Card className="col-4 p-2 border-0">
                      //   <Card.Body className="border p-4 rounded-3">
                      //     <Card.Title>{item.inv_id}</Card.Title>
                      //     <Card.Subtitle className="mb-2 text-muted">
                      //       {item.inv_name}
                      //     </Card.Subtitle>
                      //     <Card.Text>
                      //       {" "}
                      //       <div className="d-flex">
                      //         <div className="mr-auto">
                      //           <p>{removeHtmlChars(item.Description)}</p>
                      //           <div className="col-12 d-flex flex-row gap-3">
                      //             <span>Created on <strong>{item?.inv_created && formatDate(item?.inv_created)}</strong></span>
                      //             <span>Updated on <strong>{item?.inv_last_edited && formatDate(item?.inv_last_edited)}</strong></span>
                      //           </div>
                      //         </div>
                      //         <div className="flex-row gap-2 btn-wrap">
                      //           <button
                      //             className="btn btn-secondary"
                      //             onClick={(e) => handleEdit(item.inv_id)}
                      //           >
                      //             <PencilSimple />
                      //           </button>
                      //           <button
                      //             className="btn btn-danger"
                      //             onClick={(e) => deleteFromCatalogue(item.inv_id, index)}
                      //           >
                      //             <X />
                      //           </button>
                      //         </div>
                      //       </div>
                      //     </Card.Text>
                      //   </Card.Body>
                      // </Card>
                    );
                  })}
                </tbody>
              </Table>
            </div>

        )}
      </div>
      <ToastContainer />
      {
        show === true && (
          <CustomModal
            title={`Master Catalogue`}
            ShowOrHide={show}
            bodyData={renderModal}
            handleClose={handleClose}
            footer={false}
            noBodyPadding={true}
            modalSize={'lg'}
            overflowHidden={false}
            modalPosition={"right"}
            outsideClickDisabled={true}
          />
        )
      }

      <CustomMasterModal
        setCustomShow={setCustomShow}
        customShow={customShow}
        setInvId={setInvId}
        invIdEdit={invId}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
      />
      
  {console.log(detailShow)}
          {/* <InvDetailModal 
          setDetailShow={setDetailShow}
          detailShow={detailShow}
          setInvId={setInvId}
          invIdEdit={invId}
          submitLoader={submitLoader}
          setSubmitLoader={setSubmitLoader}         
      /> */}

      {detailShow && (
        <InvDetailModal 
        setDetailShow={setDetailShow}
        detailShow={detailShow}
        setInvId={setInvId}
        invIdEdit1={invId}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}         
    />
        // <CustomModal
        //   title={ 'Detail investigate'}
        //   ShowOrHide={detailShow}
        //   bodyData={renderModalDetail}
        //   handleClose={handleCloseDetail}
        //   overflowHidden={false}
          // modalSize={"xl"}
          // footer={false}
          // downloadBtn={false}
          // noBodyPadding
          // hasStickyTab

        // />
        )}
     
    </div >
  );
};
export default Investigation;