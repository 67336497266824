import React from 'react'
import { Button } from "react-bootstrap";

export default function CountBasedInputs({ index, add, setData, list }) {



    const removeItem = () => {
        setData([
            ...list.slice(0, index),
            ...list.slice(index + 1),
        ]);
    };

    // const updateData = (value, key) => {
    //     if (key === "value") {
    //         setValue(value);
    //     } else {
    //         setInformation(value);
    //     }
    //     let newArr = [...otherInformation];

    //     newArr[index][key] = value;

    //     setOtherInformation(newArr);
    // };

    return (
        <div className="form-group d-flex">
            <div className="col-11 col-xs-12 p-1">
                {" "}
                <label className="control-label">Input {index + 1}  </label>
                <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="Input"
                    // value={input}
                    // onChange={(e) => updateData(e.target.value, "information")}
                    placeholder="Enter Input here"
                />
            </div>

            <div className="col-md-1 col-xs-12 p-1 mt-auto">
                {index === 0 ? (
                    <Button className="btn-secondary" onClick={add} >
                        +
                    </Button>
                ) : (
                    <Button className="btn-danger" onClick={removeItem}>
                        -
                    </Button>
                )}
            </div>
        </div>
    )
}
