import React, { useEffect, useRef, useState } from 'react'
import { getCyO365AdTable, getDBO, getOffice365AdCounts, getSourceCounty, getUserId } from '../../../../Methods/CyKitInvestigate'
import NoDataFound from '../../../../components/NoDataFount/NoDataFound'
import { ArrowsOutSimple } from 'phosphor-react'
import { Tab, Table, Tabs, Button } from 'react-bootstrap'
import TableLoader from '../../../../components/Loader/TableLoader'
import Pagination from '../../../../components/Pagination/PaginationTi'
import styles from '../CykitHunter.styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import BarGraph from '../../../../components/Charts/BarGraph';
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader'
import useOnclickOutside from 'react-cool-onclickoutside';
import AddFilter from '../../../../components/AddFilter/AddFilter'
import FilterList from '../../../../components/FilterList/FilterList'
import InlineFilter from '../../../../components/InlineFilter/InlineFilter'
import CykitINVExport from '../../../Investigate/InvestigationSummary/CyDashboards/Export'
import { fpostDl } from '../../../../axios/axios'
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument'
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore'
import CustomToolTip from '../../../../components/ToolTip/CustomToolTip'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";

const Office365ActiveDirectory = ({ globalSearch, dateFilter, setDateFilter, azureAdPageCount, setAzureAdPageCount, init }) => {

    // loader
    const [loadTableData, setLoadTableData] = useState(true)
    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)
    const [DeviceOsLoader, setDeviceOsLoader] = useState(true)
    const [browserTypeLoader, setBrowserTypeLoader] = useState(true)
    const [operationLoader, setOperationLoader] = useState(true)
    const [userIdLoader, setUserIdLoader] = useState(true)

    const [sourceCountryCount, setSourceCountryCount] = useState([])
    const [devicePropertiesCount, setDevicePropertiesCount] = useState([])
    const [userIdCount, setUserIdCount] = useState([])

    // graph
    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [sourceCountryData, setSourceCountryData] = useState([])

    const [devicePropertiesLabel, setDevicePropertiesLabel] = useState([])
    const [devicePropertiesData, setDevicePropertiesData] = useState([])

    const [browserTypeLabel, setBrowserTypeLabel] = useState([])
    const [browserTypeData, setBrowserTypeData] = useState([])

    const [operationLabel, setOperationLabel] = useState([])
    const [operationData, setOperationData] = useState([])

    const [userIdLabel, setUserIdLabel] = useState([])
    const [userIdData, setUserIdData] = useState([])

    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])
    const O365UserTypes = {
        "0": {
            "Value": 0,
            "MemberName": "Regular",
            "Description": "A regular user."
        },
        "1": {
            "Value": 1,
            "MemberName": "Reserved",
            "Description": "A reserved user."
        },
        "2": {
            "Value": 2,
            "MemberName": "Admin",
            "Description": "An administrator."
        },
        "3": {
            "Value": 3,
            "MemberName": "DcAdmin",
            "Description": "A Microsoft datacenter operator."
        },
        "4": {
            "Value": 4,
            "MemberName": "System",
            "Description": "A system account."
        },
        "5": {
            "Value": 5,
            "MemberName": "Application",
            "Description": "An application."
        },
        "6": {
            "Value": 6,
            "MemberName": "ServicePrincipal",
            "Description": "A service principal."
        },
        "7": {
            "Value": 7,
            "MemberName": "CustomPolicy",
            "Description": "A custom policy."
        },
        "8": {
            "Value": 8,
            "MemberName": "SystemPolicy",
            "Description": "A system policy."
        }
    }

    // table
    const [tableData, setTableData] = useState([])

    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    //filter
    const [filter, setFilter] = useState([]);
    // const [dateFilter, setDateFilter] = useState({});

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    const [manageDatas, setManageDatas] = useState([
        { text: "Client IP", value: "ClientIP", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "User Id", value: "UserId", isSelected: true },
        { text: "Device OS", value: "DeviceProperties.OS", isSelected: true },
        { text: "Operation", value: "Operation", isSelected: true },
        { text: "Result Status", value: "ResultStatus", isSelected: true },
        { text: "Requested Type", value: "ExtendedProperties.RequestType", isSelected: true },
        { text: "Browser Type", value: "DeviceProperties.BrowserType", isSelected: true },
    ])


    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.office.ad') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.office.ad")))
            }
        })
    }, [manageDatas])
    useEffect(() => {
        if (dateFilter !== undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], date: dateFilter } },
            };
            setPage(1)
            localStorage.setItem("filters", JSON.stringify(temp2));
        }
    }, [dateFilter])
    useEffect(() => {
        if (globalSearch === '' && init === true) {
            console.log()
        }
        else if (globalSearch === '' && init === false) {
            console.log(filter)
            var newfilter = filter.filter(item => item?.isGlobal != true)

            setFilter(
                newfilter
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setPage(1)
            // let temp2 = {
            //     ...storedFilter,
            //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter] } },
            //   };

            //   localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable(newfilter, dateFilter)
        }
        else {
            var filterNew = [{
                "column": {
                    "label": "Raw Search",
                    "value": "raw.global",
                    "isWildCard": null,
                    "isNumber": null
                },
                "type": "is",
                "selectedValue": globalSearch,
                "from": "",
                "to": "",
                "isInline": false,
                "isGlobal": true
            }]
            var newfilter = filter.filter(item => item?.isGlobal != true)
            setFilter((filter) => [...newfilter, ...filterNew]
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter,...filterNew] } },
              };

              localStorage.setItem("filters", JSON.stringify(temp2));
            setPage(1)
            getFilteredTable([...newfilter, ...filterNew], dateFilter)
        }
    }, [globalSearch])
    const module = 'hunter'
    const tab = 'cykit-o365ad'
    let count = [];
    let count2;
    const DoughnutBrowserTypeRef = useRef()
    const DoughnutOperationRef = useRef()
    const DoughnutUserIDRef = useRef()
    const datalakeURL = localStorage.getItem("DATALAKE_URL");
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    const [doc, setDoc] = useState(null)

    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            if (currentPageFilter !== undefined) {
                setFilter(currentPageFilter);
            }
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    const browserType = {
        labels: browserTypeLabel,
        datasets: [
            {
                label: "",
                data: browserTypeData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };


    const operationType = {
        labels: operationLabel,
        datasets: [
            {
                label: "",
                data: operationData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };


    const donutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: "start",
                fullSize: true,
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    // useBorderRadius: true,
                    // borderRadius: 15,
                    padding: 10,
                }
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };


    const userIdType = {
        labels: userIdLabel,
        datasets: [
            {
                label: "",
                data: userIdData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const getFilteredTable = async (filter, date = {}) => {
        if (filter || date?.to) {
            await getCyO365AdTable(filter, date, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {

                if (hits.hits.length > 0) {
                    setTableData(hits.hits)
                    setLoadTableData(false)
                    setAzureAdPageCount(hits.total.value)
                }
                else {
                    setTableData([])
                    setLoadTableData(false)
                    setAzureAdPageCount(0)
                }
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
                setAzureAdPageCount(0)


            })
        }
        else {
            await getCyO365AdTable("", "", 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTableData(hits.hits)
                setLoadTableData(false)
                setAzureAdPageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
                setAzureAdPageCount(0)

            })
        }
    }

    useEffect(() => {
        // getcounts


        // table
        if (!count?.length && !count2 && !(globalSearch?.length>0 && init === true)) {

        setLoadTableData(true)
        
        getCyO365AdTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            if (hits.hits.length > 0) {
                setTableData(hits.hits)
                setLoadTableData(false)
                setAzureAdPageCount(hits.total.value)
            }
            else {
                setTableData([])
                setLoadTableData(false)
                setAzureAdPageCount(0)
            }

        }).catch((err) => {
            console.log("Error", err)
            setLoadTableData(false)
        })
    }
    }, [filter, dateFilter, page, perPage])

    const DoughnutClick = (event, ref, data, field, label) => {
        var activeBars = getElementAtEvent(ref.current, event);
        if (activeBars.length > 0) {
            var clickedDatasetIndex = activeBars[0].datasetIndex;
            var clickedElementIndex = activeBars[0].index;
            var clickedLabel = data.labels[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if (module && tab && field && label) {
                var temp = {
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: clickedLabel,
                    from: "",
                    to: "",
                    isinline: false
                }
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                let temp2 = {
                    ...storedFilter,
                    [module]: {
                        [tab]: {
                            fields: [temp]
                        }
                    }
                };
                localStorage.setItem("filters", JSON.stringify(temp2));
                setFilter([...filter, temp])
            }
        }
    }
    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value,
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    // const MapClick = (field, label, value) => {
    //     if (field && label && value) {
    //         var temp = {
    //             column: { label: label, value: field },
    //             type: "is",
    //             selectedValue: value,
    //             from: "",
    //             to: "",
    //             isinline: false
    //         }
    //         let storedFilter = JSON.parse(localStorage.getItem("filters"));
    //         let temp2 = {
    //             ...storedFilter,
    //             [module]: {
    //                 [tab]: {
    //                     fields: [temp]
    //                 }
    //             }
    //         };
    //         localStorage.setItem("filters", JSON.stringify(temp2));
    //         setFilter([...filter, temp])
    //     }
    // }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.office.ad", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={{
                    position: "absolute",
                    right: "0",
                    top: "-48px",
                    zIndex: 3
                }}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "ClientIP", label: "Client IP" },
                                { value: "UserId", label: "User ID" },
                                { value: "cybSourceCountry", label: "Source Country", isWildCard: true },
                                { value: "DeviceProperties.OS", label: "Device OS", isWildCard: true },
                                { value: "Operation", label: "Operation", isWildCard: true },
                                { value: "ResultStatus", label: "Result Status", isWildCard: true },
                                { value: "ExtendedProperties.RequestType", label: "Requested Type", isWildCard: true },
                                { value: "DeviceProperties.BrowserType", label: "Browser Type", isWildCard: true }
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>
                <div className="position-relative">
                    <Button
                        variant="light"
                        ref={ref}
                        onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                    >
                        Manage columns
                    </Button>
                    {showManageColumn && (
                        <div
                            className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                            style={{
                                position: "absolute",
                                right: 0,
                                zIndex: "111",
                                minWidth: "20rem",
                                maxHeight: "50vh",
                                overflow: "auto"
                            }}
                        >
                            <div className="table-fields">
                                {manageDatas?.map((item) => (
                                    <div className="d-flex align-items-center py-2 gap-2">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={item.isSelected ? true : false}
                                            // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {item.text}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="position-relative ms-2">
                    <CykitINVExport tabledata={getCyO365AdTable} type={"365ad"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    currentPageCount={currentPageCount}
                    totalDataCounts={azureAdPageCount}
                />



            </div>

            <div className="col-12 d-flex flex-column rounded-3 bg-white position-relative">
                <div className="col-12">


                    <div className="" style={styles.tableWrap}>
                        <FilterList
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            getFilteredTable={getFilteredTable}
                            module={module}
                            tab={tab}
                        />
                        {loadTableData ? (
                            <TableLoader rowCount={30} />
                        ) : (
                            tableData?.length && tableData?.length !== 0 ? (
                                <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                            <th>Date</th>
                                            <th>Time</th>
                                            {manageDatas.map((item) => (
                                                item.isSelected === true &&
                                                <th>{item.text}</th>
                                            ))}
                                            {/* <th>Client IP</th>
                                                    <th>User ID</th>
                                                    <th>Source Country</th>
                                                    <th>Device OS</th>
                                                    <th>Operation</th>
                                                    <th>Result Status</th>
                                                    <th>Resquested Type</th>
                                                    <th>Browser Type</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {/* <tbody>
                                        {tableData?.map((item, index) => (
                                            <tr
                                                onMouseOver={() =>
                                                    setShowInlineFilter({ show: true, index: index })
                                                }
                                                onMouseOut={() =>
                                                    setShowInlineFilter({ show: false, index: index })
                                                }>
                                                <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                {manageDatas?.map((data) => (
                                                    data.value === "ClientIP" && data.isSelected === true ? (
                                                        <td>{item?._source?.ClientIP}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ClientIP",
                                                                                    label: "Client IP",
                                                                                }}
                                                                                value={item?._source?.ClientIP}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "UserId" && data.isSelected === true ? (
                                                        <td>
                                                            {item?._source?.UserId}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "UserId",
                                                                                    label: "User Id",
                                                                                }}
                                                                                value={item?._source?.UserId}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                        <td>{item?._source?.cybSourceCountry}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "cybSourceCountry",
                                                                                    label: "Source Country",
                                                                                }}
                                                                                value={item?._source?.cybSourceCountry}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "DeviceProperties.OS" && data.isSelected === true ? (
                                                        <td>{item?._source?.["DeviceProperties.OS"]}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "DeviceProperties.OS",
                                                                                    label: "Device OS",
                                                                                }}
                                                                                value={item?._source?.["DeviceProperties.OS"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "Operation" && data.isSelected === true ? (
                                                        <td>{item?._source?.Operation}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "Operation",
                                                                                    label: "Operation",
                                                                                }}
                                                                                value={item?._source?.Operation}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "ResultStatus" && data.isSelected === true ? (
                                                        <td>{item?._source?.ResultStatus}
                                                            <span className="font-monospace">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ResultStatus",
                                                                                    label: "Result Status",
                                                                                }}
                                                                                value={item?._source?.ResultStatus}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "ExtendedProperties.RequestType" && data.isSelected === true ? (
                                                        <td>{item?._source?.["ExtendedProperties.RequestType"]}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "ExtendedProperties.RequestType",
                                                                                    label: "Resquested Type",
                                                                                }}
                                                                                value={item?._source?.["ExtendedProperties.RequestType"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "DeviceProperties.BrowserType" && data.isSelected === true ? (
                                                        <td>{item?._source?.["DeviceProperties.BrowserType"]}
                                                            <span className="font-monospace">

                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "DeviceProperties.BrowserType",
                                                                                    label: "Browser Type",
                                                                                }}
                                                                                value={item?._source?.["DeviceProperties.BrowserType"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : (<></>)
                                                ))}
                                                <td>
                                                    <a onClick={() => { setExpClickedNode(item._id); setExpClose(false) }} style={{ marginLeft: "20px" }}>
                                                        {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                        {/*<TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> */}
<tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "ClientIP" && data.isSelected === true ? (
                                                                <td>{item?._source?.ClientIP}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ClientIP",
                                                                                            label: "Client IP",
                                                                                        }}
                                                                                        value={item?._source?.ClientIP}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "UserId" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{item?._source?.UserId}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div className="d-flex flex-column text-left">
                                                                                {/* {item?.item?.UserType && */}
                                                                                <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                    <span>Account type - </span>
                                                                                    <span><strong>{O365UserTypes[item?._source?.UserType]?.MemberName}</strong></span>
                                                                                </div>
                                                                                {/* } */}
                                                                            </div>
                                                                        } />
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "UserId",
                                                                                            label: "User Id",
                                                                                        }}
                                                                                        value={item?._source?.UserId}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                                <td>{item?._source?.cybSourceCountry}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "cybSourceCountry",
                                                                                            label: "Source Country",
                                                                                        }}
                                                                                        value={item?._source?.cybSourceCountry}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "DeviceProperties.OS" && data.isSelected === true ? (
                                                                <td>{item?._source?.["DeviceProperties.OS"]}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "DeviceProperties.OS",
                                                                                            label: "Device OS",
                                                                                        }}
                                                                                        value={item?._source?.["DeviceProperties.OS"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Operation" && data.isSelected === true ? (
                                                                <td>{item?._source?.Operation}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Operation",
                                                                                            label: "Operation",
                                                                                        }}
                                                                                        value={item?._source?.Operation}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ResultStatus" && data.isSelected === true ? (
                                                                <td>{item?._source?.ResultStatus}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ResultStatus",
                                                                                            label: "Result Status",
                                                                                        }}
                                                                                        value={item?._source?.ResultStatus}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ExtendedProperties.RequestType" && data.isSelected === true ? (
                                                                <td>{item?._source?.["ExtendedProperties.RequestType"]}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ExtendedProperties.RequestType",
                                                                                            label: "Resquested Type",
                                                                                        }}
                                                                                        value={item?._source?.["ExtendedProperties.RequestType"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "DeviceProperties.BrowserType" && data.isSelected === true ? (
                                                                <td>{item?._source?.["DeviceProperties.BrowserType"]}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "DeviceProperties.BrowserType",
                                                                                            label: "Browser Type",
                                                                                        }}
                                                                                        value={item?._source?.["DeviceProperties.BrowserType"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : (<></>)
                                                        ))}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                </Table>
                            ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                    <NoDataFound errorText={"No data found"} />
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <ExpandedDocument clickedNode={expclickedNode} setDoc={setDoc} doc={doc} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_o365}/_search`} req={fpostDl} />
        </div>
    )
}

export default Office365ActiveDirectory