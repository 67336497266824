import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import Dashboard from "./Dashboard/Dashboard";
import Detection from "./Detection/Detection";
import styles from "./UebaOverview.styles";
import Settings from "./Settings/Settings";
import Activities from "./Activities/Activities";
import CykitGraph from "./Cykit/CykitGraph";
import UebaSummary from "../../components/UEBA/UebaSummary";
import UserProfiling from "./UserProfiling/UserProfiling";
import WatchList from "./WatchList/WatchList";

const UebaOverview = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [dateFilter, setDateFilter] = useState({});
  const filterSet = () => {
    if (localStorage.getItem("filter")) {
      return localStorage.getItem("filter")
    }
    else {
      let selectedDate;

      switch ("1 Day") {
        case "1 Day":
          selectedDate = 1;
          break;
        case "7 Days":
          selectedDate = 7;
          break;
        case "30 Days":
          selectedDate = 30;
          break;
        case "1 Year":
          selectedDate = 365;
          break;
        default:
      }

      let current = new Date();
      let startD = new Date(
        current.getTime() - selectedDate * 24 * 60 * 60 * 1000
      )
        .toISOString()
        
      let endD = current.toISOString()

      localStorage.setItem("gte", startD);
      localStorage.setItem("lte", endD);
      localStorage.setItem("filter", "1 Day");

      return "1 Day"

    }
  }
  // const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const [selectedDateFilter, setSelectedDateFilter] = useState(filterSet())
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  useEffect(() => {
    if (currentPath === "dashboard") {
      setTitle("UEBA Dashboard")
      setBreadcrumb({
        "module": "ueba",
        // "path": [{ "title": "UEBA Dashboard", "url": "/ueba/dashboard" }]
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "Dashboard", "url": "/ueba/dashboard" },]
      });
    }
    if (currentPath === "detection") {
      setTitle("Detections")
      setBreadcrumb({
        "module": "ueba",
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "Detections", "url": "/ueba/detection" },]
      });
    }
    if (currentPath === "activities") {
      setTitle("CyKit - Manual Analysis")
      setBreadcrumb({
        "module": "ueba",
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "CyKit - Manual Analysis", "url": "/ueba/activities" },]
      });
      // setTitle(<React.Fragment>
      //   <div className="d-flex gap-2">
      //     <span>UEBA</span>
      //     <span>/</span>
      //     <span>CyKit - Manual Analysis</span>
      //   </div>
      // </React.Fragment>);
    }
    if (currentPath === "summary") {
      setTitle("Summary")
      setBreadcrumb({
        "module": "ueba",
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "Detections", "url": "/ueba/detection" }, { "title": "Detection Summary", "url": "/ueba/summary" }]
      });
    }
    if (currentPath === "asset-discovery") {
      setTitle("Asset Discovery ")
      setBreadcrumb({
        "module": "ueba",
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "Asset Discovery ", "url": "/ueba/asset-discovery" },]
      });
    }
    if (currentPath === "watchlist") {
      setTitle("Watchlist")
      setBreadcrumb({
        "module": "ueba",
        "path": [{ "title": "UEBA", "url": "/ueba/dashboard" }, { "title": "Watchlist", "url": "/ueba/watchlist" },]
      });
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"ueba"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          isFilterEnabled={currentPath === "dashboard"}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          tab={['detection',"cykit"]}
          module={"ueba"}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "dashboard" ? (
            <Dashboard
              selectedDateFilter={selectedDateFilter}
              setSelectedDateFilter={setSelectedDateFilter}
            />
          ) : currentPath === "detection" ? (
            <Detection
              selectedDateFilter={selectedDateFilter}
              setSelectedDateFilter={setSelectedDateFilter}
            />
          ) : currentPath === "summary" ? (
            <UebaSummary />
          ) : currentPath === "settings" ? (
            <Settings
              selectedDateFilter={selectedDateFilter}
              setSelectedDateFilter={setSelectedDateFilter}
            />
          ) :
            currentPath === "activities" ? (
              <Activities />
            ) :
              // currentPath === "asset-discovery" ? (
              //   <UserProfiling />
              // ) : currentPath === "watchlist" ? (
              //   <WatchList />
              // ) :
              null}
        </div>
      </div>
    </div>
  );
};

export default UebaOverview;
