import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData, getSoftwareList, getUniqueVendorCount, getUniqueSoftwareCount, getUniqueVendorList, getHostList, getleastHost10query, getInstalledVendor, getSoftwareTable, getUniqueAssetsCount, getUniqueAssetsSoftCount, getLatestScan, getLatestScanSoftware } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import stylesSoft from '../../../ManagementDashboard/Threatlandscape/ThreatLandscape.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
// import FilterList from '../../../../components/FilterList/FilterList';
// import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
// import { ArrowsOutSimple } from "phosphor-react";
// import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
// import Pagination from '../../../../components/Pagination/Pagination'
// import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
// import CykitINVExport from './Export';
// import { isEmptyObject } from '../../../../Utils/Utils';
// import { getFirwallTableQuery } from '../../../../Queries/CyKitInvestigateQuery';
import { totalUniqueSoftwareCountsQuery } from '../../../../Queries/InvestigationQuery';
import ThreatLandScapeSideBar from '../../../../components/ThreatLandScapeSideBar/ThreatLandScapeSideBar';
import SearchBar from '../../../../components/Search/ManagementDashboard/SearchBar';

import * as htmlToImage from 'html-to-image';
import Spinner from "react-bootstrap/Spinner";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SoftwareSummaryDoc from '../../../../components/PDF/managementDashboard/SoftwareSummary';
import { State } from '../../../../Context';


// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CY_SOFTWARE = localStorage.getItem("INVESTIGATE_CY_SOFTWARE");

const CySoftware = ({ vendorsRefImage,
    softwareSectionRefImage, scanName,
    scanDataLoader, setShowDownloadButton, showDownloadButton, refsoft, setImageBlobVuln, setLoaderList, scanNames, loaderList

}) => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);

    const [totalCount, setTotalCount] = useState('')
    const [mostUsedSoftware, setMostUsedSoftware] = useState([

    ])
    const [mostUsedSoftwareLoader, setMostUsedSoftwareLoader] = useState(true)

    const [leastUsedSoftware, setLeastUsedSoftware] = useState([])
    const [leastUsedSoftwareLoader, setLeastUsedSoftwareLoader] = useState(true)

    const [uniqueVendorsLabel, setUniqueVendorsLabel] = useState([])
    const [uniqueVendorsData, setUniqueVendorsData] = useState([])

    const [leastInstalledSoftware, setLeastInstalledSoftware] = useState(0)

    const [expclickedNodeDoc, setexpclickedNodeDoc] = useState()

    const [uniqueVenderCount, setUniqueVenderCount] = useState(0)
    const [uniqueSoftwareCount, setUniqueSoftwareCount] = useState(0)
    const [uniqueVenderLoader, setUniqueVenderLoader] = useState(true)
    const [softwareHostListLoader, setSoftwareHostListLoader] = useState(true)
    const [uniqueHostLoader, setUniqueHostLoader] = useState(true)
    const [leastVendorLoader, setLeastVendorLoader] = useState(true)
    const [mostVendorLoader, setMostVendorLoader] = useState(true)
    const [leastUsedVendor, setleastUsedVendor] = useState([])
    const [mostUsedVendor, setmostUsedVendor] = useState([])

    const [softwareInstalledonLeastHostCount, setsoftwareInstalledonLeastHostCount] = useState(0)

    // source country
    const [uniqueHostList, setUniqueHostList] = useState([

    ])
    const [softwareHostList, setSoftwareHostList] = useState([

    ])

    // geo chart 

    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState('');

    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)

    // search filter
    const [searchSoftwareQuery, setSearchSoftwareQuery] = useState()
    const [filteredListData, setFilteredListData] = useState([])

    const [searchSoftwareHostQuery, setSearchSoftwareHostQuery] = useState()
    const [filteredHostListData, setFilteredHostListData] = useState([])
    const [totalUniqueAssetCount, settotalUniqueAssetCount] = useState(0)
    // console.log("LABELL :", eventCateLabel)
    const [loadTableData, setLoadTableData] = useState(true)


    const [isTableTabSelected, setIsTableTabSelected] = useState(false);
    const [lastDate, setLastDate] = useState(null)

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [title, setTitle] = useState();
    const [type, setType] = useState();

    const [manageDatas, setManageDatas] = useState([
        { text: "Event Category", value: "cybEventCategory", isSelected: true },
        { text: "Event Type", value: "cybEventType", isSelected: true },
        { text: "Destination IP", value: "dipv4", isSelected: true },
        { text: "Destination Port", value: "dport", isSelected: true },
        { text: "Destination Country", value: "cybDestCountry", isSelected: true },
        { text: "Source IP", value: "sipv4", isSelected: true },
        { text: "Source Port", value: "sport", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "Bytes In", value: "bytesin", isSelected: false },
        { text: "Bytes Out", value: "bytesout", isSelected: false },
        { value: "severity", text: "Severity", isSelected: false },
        { value: "type", text: "Type", isSelected: false },
        { value: "subtype", text: "Subtype", isSelected: false },
        { value: "objectname", text: "Object Name", isSelected: false },
        { value: "url", text: "URL", isSelected: false },
        { value: "action", text: "Action", isSelected: false },
        { value: "dstregion", text: "Destination Region", isSelected: false },
        { value: "devname", text: "Dev Name", isSelected: false },
        { value: "srchwvendor", text: "Vendor", isSelected: false },
        { value: "osname", text: "OS", isSelected: false },
        { value: "srcswversion", text: "OS Version", isSelected: false },


    ])



    useMemo(() => {
        getLatestScanSoftware(scanName).then(({ data }) => {

            setLastDate(data?.hits?.hits?.[0]?._source?.["@timestamp"])
        }).catch((err) => {

        })
    }, [])

    const softwareRefImage = useRef(null);
    const [imageBlob, setImageBlob] = useState(null);

    const convertToBlob = () => {
        htmlToImage.toBlob(softwareRefImage.current).then((blob) => {
            setImageBlob(blob);
            setImageBlobVuln((prevState) => ({
                ...prevState,
                vendors: blob
            }))
        }).catch((error) => {
            console.error('Error converting to blob:', error);
        });
    };
    useEffect(() => {
        console.log("imageeeeeeeee", imageBlob);
        if (scanDataLoader === false) {
            if (loaderList?.length === scanNames?.length + 1 && loaderList?.length !== 0) {
                setTimeout(() => {
                    convertToBlob()
                }, 2000)
            }
            // setTimeout(()=>{
            //     convertToBlob()
            // },10000)
        }
    })


    useEffect(() => {
        let storedData = localStorage.getItem('ManageColumn.investigate.cykit.firewall')

        if (storedData) {
            setManageDatas(JSON.parse(storedData))
        } else {
            setManageDatas(manageDatas)
        }

    }, [manageDatas])



    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false)

    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    useEffect(() => {
        if (!mostUsedSoftwareLoader && !leastUsedSoftwareLoader) {
            setLoaderList((loader) => [...loader, "software"])
        }
    }, [mostUsedSoftwareLoader, leastUsedSoftwareLoader]
    )

    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-software'

    const { uniqueHost, setUniqueHost } = useContext(State);


    const days = 90
    useEffect(() => {
        settotalUniqueAssetCount(uniqueHost)
    }, [uniqueHost])
    useEffect(() => {
        // manageDatas.map((item) => {
        //     item.isSelected = false
        // })
        if (lastDate != undefined) {
            setMostUsedSoftwareLoader(true)
            setLeastUsedSoftwareLoader(true)
            setUniqueVenderLoader(true)
            setLoadTableData(true)
            setUniqueHostLoader(true)
            setSourceCountryLoader(true)
            setSoftwareHostListLoader(true)
            setLeastVendorLoader(true)
            setMostVendorLoader(true)

            // getInstalledVendor("desc").then(({ data: { aggregations } }) => {
            //     setleastUsedVendor(aggregations?.software_count?.buckets)
            //     setLeastVendorLoader(false)

            // }).catch((err) => {
            //     console.log("Error", err)
            // })
            // getUniqueAssetsSoftCount(scanName,days).then(({ data: { aggregations } }) => {
            //     settotalUniqueAssetCount(aggregations?.unique_assets?.value)

            // }).catch((err) => {
            //     console.log("Error", err)
            // })

            // getInstalledVendor("asc").then(({ data: { aggregations } }) => {
            //     setmostUsedVendor(aggregations?.software_count?.buckets)
            //     setMostVendorLoader(false)

            // }).catch((err) => {
            //     console.log("Error", err)
            // })
            getSoftwareList("desc", scanName, days).then(({ data: { aggregations } }) => {
                setMostUsedSoftware(aggregations?.software_count?.buckets)
                setMostUsedSoftwareLoader(false)

            }).catch((err) => {
                console.log("Error", err)
            })
            getSoftwareList("asc", scanName, days).then(({ data: { aggregations } }) => {
                setLeastUsedSoftware(aggregations?.software_count?.buckets)
                setLeastUsedSoftwareLoader(false)

            }).catch((err) => {
                console.log("Error", err)
            })

            // getUniqueVendorCount().then(({ data: { aggregations } }) => {
            //     setUniqueVenderCount(aggregations?.unique_vendors?.value)
            // }).catch((err) => {
            //     console.log("Error", err)
            // })

            getUniqueSoftwareCount(scanName, days).then(({ data: { aggregations } }) => {
                setUniqueSoftwareCount(aggregations?.unique_software?.value)
            }).catch((err) => {
                console.log("Error", err)
            })
            getleastHost10query(scanName, days).then(({ data: { aggregations } }) => {
                setsoftwareInstalledonLeastHostCount(aggregations?.hosts?.buckets?.length)
            }).catch((err) => {
                console.log("Error", err)
            })
            // getUniqueVendorList().then(({ data: { aggregations } }) => {
            //     let label = [];
            //     let data = [];

            //     if (aggregations?.unique_vendors?.buckets
            //         .length > 0) {
            //         aggregations?.unique_vendors?.buckets
            //             .map((item) => {
            //                 label.push(item.key === "" ? "--" : item.key);
            //                 data.push(item?.unique_software?.value);
            //                 setUniqueVendorsLabel(label)
            //                 setUniqueVendorsData(data)
            //             });
            //         setUniqueVenderLoader(false)
            //     }
            //     else {
            //         setUniqueVendorsLabel(label)
            //         setUniqueVendorsData(data)
            //         setUniqueVenderLoader(false)
            //     }
            // }).catch((err) => {
            //     console.log("Error", err)
            // })
            // getSoftwareTable().then(({ data: { aggregations } }) => {
            //     setSoftwareHostList(aggregations?.unique_software?.buckets)
            //     setFilteredListData(aggregations?.unique_software?.buckets)
            //     setSoftwareHostListLoader(false)
            // }).catch((err) => {
            //     console.log("Error", err)
            // })
            // getHostList().then(({ data: { aggregations } }) => {
            //     setUniqueHostList(aggregations?.unique_hosts?.buckets)
            //     setFilteredHostListData(aggregations?.unique_hosts?.buckets)
            //     setUniqueHostLoader(false)
            // }).catch((err) => {
            //     console.log("Error", err)
            // })
        }
    }, [lastDate])

    useEffect(() => {
        if (searchSoftwareQuery?.length === 0) {
            setSoftwareHostListLoader(true)
            getSoftwareTable().then(({ data: { aggregations } }) => {
                setSoftwareHostList(aggregations?.unique_software?.buckets)
                setFilteredListData(aggregations?.unique_software?.buckets)
                setSoftwareHostListLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }

    }, [searchSoftwareQuery])

    useEffect(() => {
        if (searchSoftwareHostQuery?.length === 0) {
            setUniqueHostLoader(true)
            getHostList().then(({ data: { aggregations } }) => {
                setUniqueHostList(aggregations?.unique_hosts?.buckets)
                setFilteredHostListData(aggregations?.unique_hosts?.buckets)
                setUniqueHostLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }

    }, [searchSoftwareHostQuery])


    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value[0],
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.firewall", JSON.stringify(manageDatas))
    }




    return (
        <>


            <div className="col-12 position-relative" >
                <div className="d-flex flex-row align-items-center justify-content-end ml-auto col-7">
                    {imageBlob ? (
                        <PDFDownloadLink document={<SoftwareSummaryDoc imageBlob={imageBlob} />} fileName={scanName !== "All" ? `InstalledSoftware-${scanName}.pdf` : `InstalledSoftware-Overview.pdf`}>
                            {({ blob, url, loading, error }) =>
                                <Button className='btn btn-primary'>Download</Button>
                            }
                        </PDFDownloadLink>
                    ) :
                        // <PDFDownloadLink document={<SoftwareSummaryDoc imageBlob={imageBlob} />} fileName={`InstalledSoftware-Summary.pdf`}>
                        //         {({ blob, url, loading, error }) =>
                        <Button className='btn btn-light' disabled>
                            Download <Spinner animation="border" role="status" size="sm" />
                        </Button>
                        //     }
                        // </PDFDownloadLink>
                    }
                </div>

                <div
                    className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                    style={styles.filterWrap}
                >


                    {/* {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )} */}

                </div>
                <div ref={refsoft}>
                    {/* table datas */}
                    <div ref={softwareRefImage} className="col-12 d-flex flex-column rounded-3 bg-white p-3 position-relative">
                        {/* 
                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview"> */}
                        <div className='col-12 d-flex flex-column'>
                            <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 gap-3">
                                <div ref={softwareRefImage} className="d-flex flex-column gap-3" style={styles.subSection2}>
                                    <div ref={vendorsRefImage}>
                                        {/* <div className='d-flex flex-column col-10 py-4'>
                                    <h2>Installed Software Summary </h2>
                                </div> */}
                                        {scanName === "All" && <div className='d-flex gap-3'>
                                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 mb-3">
                                                <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                                                    <div>
                                                        <h4 style={styles.label}>Total Unique Software</h4>
                                                        <span>{uniqueSoftwareCount ? uniqueSoftwareCount : "--"}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                                                    <div>
                                                        <h4 style={styles.label}>Total Unique Assets</h4>
                                                        <span>{totalUniqueAssetCount ? totalUniqueAssetCount : "--"}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{uniqueVenderCount ? uniqueVenderCount : "--"}</h4>
                                                <span style={styles.label}>Total Unique Vendors</span>
                                            </div>
                                        </div> */}
                                                {/* <div className="col-4 rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{softwareInstalledonLeastHostCount ? softwareInstalledonLeastHostCount : "--"}</h4>
                                                <span style={styles.label}>Least installed Software (installed on less than 10 Hosts)</span>
                                            </div>
                                        </div> */}
                                                {/*<div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationPortCount ? destinationPortCount : "--"}</h4>
                                                <span>Unique Destination Ports</span>
                                            </div>
                                        </div> */}

                                            </div>
                                            {/* <div className="col-8 p-4 rounded-3 border">
                                        <h5>Unique Software Count From Vendors</h5>
                                        {uniqueVenderLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {uniqueVendorsData.length || uniqueVendorsData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={uniqueVendorsLabel} ChartData={uniqueVendorsData} ChartTitle={"Vendors"} module={module} tab={tab} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div> */}
                                        </div>}
                                        <div className='d-flex flex-row gap-2 mb-3'>


                                            {/* {leastUsedVendor?.length !== 0 &&
                                        <div className="col-6 p-4 rounded-3 border flex-fill" >
                                            <h5>Most Installed Software Vendors</h5>
                                            <div className='mt-3 custom-scrollbar' style={styles.tableWrapSm}>
                                                {leastVendorLoader ? (
                                                    <div className="col-6">
                                                        <TableLoader rowCount={25} />
                                                    </div>
                                                ) : leastUsedVendor?.length !== 0 ? (
                                                    <Table borderless hover>
                                                        <thead className="fixed-head">
                                                            <tr>
                                                                <th>Vendor</th>
                                                                <th>Software Count</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {leastUsedVendor?.map((item) => (
                                                                <tr>
                                                                    <td>
                                                                        {item?.key}
                                                                    </td>
                                                                    <td>
                                                                        {item?.host_count?.value}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <NoDataFound errorText={"No data Found"} />
                                                )}
                                            </div>
                                        </div>
                                    } */}

                                            {/* {mostUsedVendor?.length !== 0 &&
                                        <div className="col-6 p-4 rounded-3 border flex-fill" >
                                            <h5>Least Installed Software Vendors</h5>
                                            <div className='mt-3 custom-scrollbar' style={styles.tableWrapSm}>
                                                {mostVendorLoader ? (
                                                    <div className="col-6">
                                                        <TableLoader rowCount={25} />
                                                    </div>
                                                ) : mostUsedVendor?.length !== 0 ? (
                                                    <Table borderless hover>
                                                        <thead className="fixed-head">
                                                            <tr>
                                                                <th>Vendor</th>
                                                                <th>Software Count</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mostUsedVendor?.map((item) => (
                                                                <tr>
                                                                    <td>
                                                                        {item?.key}
                                                                    </td>
                                                                    <td>
                                                                        {item?.host_count?.value}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <NoDataFound errorText={"No data Found"} />
                                                )}
                                            </div>
                                        </div>
                                    } */}
                                        </div>
                                        <div className='d-flex flex-row gap-2'>

                                            {leastUsedSoftware?.length !== 0 &&
                                                <div className="col-6 p-4 rounded-3 border flex-fill" >
                                                    <h5>Least Installed Software</h5>
                                                    <div className='mt-3 custom-scrollbar' >
                                                        {leastUsedSoftwareLoader ? (
                                                            <div className="col-6">
                                                                <TableLoader rowCount={25} />
                                                            </div>
                                                        ) : leastUsedSoftware?.length !== 0 ? (
                                                            <Table borderless hover>
                                                                <thead className="fixed-head">
                                                                    <tr>
                                                                        <th>Software</th>
                                                                        <th>Host Count</th>
                                                                        {/* <th className="text-center">Is SSO User</th>
                                                        <th>Status</th>
                                                        <th className="text-right">Manage</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {leastUsedSoftware?.map((item) => (
                                                                        <tr>
                                                                            <td>
                                                                                {item?.key}
                                                                            </td>
                                                                            <td>
                                                                                {item?.host_count?.value}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <NoDataFound errorText={"No data Found"} />
                                                        )}
                                                    </div>
                                                </div>
                                            }

                                            {mostUsedSoftware?.length !== 0 &&
                                                <div className="col-6 p-4 rounded-3 border flex-fill mb-3">
                                                    <h5>Most Installed Software</h5>

                                                    <div className='mt-3 col-12 custom-scrollbar' >
                                                        {mostUsedSoftwareLoader ? (
                                                            <div className="col-12">
                                                                <TableLoader rowCount={25} />
                                                            </div>
                                                        ) : mostUsedSoftware?.length !== 0 ? (
                                                            <Table borderless hover>
                                                                <thead className="fixed-head">
                                                                    <tr>
                                                                        <th>Software</th>
                                                                        <th>Host Count</th>
                                                                        {/* <th className="text-center">Is SSO User</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Manage</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {mostUsedSoftware?.map((item) => (
                                                                        <tr>
                                                                            <td>
                                                                                {item?.key}
                                                                            </td>
                                                                            <td>
                                                                                {item?.host_count?.value}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <NoDataFound errorText={"No data Found"} />
                                                        )}
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </Tab>
                    <Tab eventKey="table" title="Details"> */}




                        {/* </Tab> 
                </Tabs> */}
                    </div>
                </div>

                <ThreatLandScapeSideBar clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`} req={fpostDl} title={title} type={type} />

            </div >
        </>
    )
}

export default CySoftware