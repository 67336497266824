export const processfields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Process ID",
      value: "item._source?.process?.pid",
    },
    {
      name: "Timestamp",
      value: "item._source?.['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.name",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Command",
      value: "item._source?.process?.command_line",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Process ID",
      value: "item._source?.process?.pid",
    },
    {
      name: "Timestamp",
      value: "item?._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item?._source?.host?.os?.name",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Command",
      value: "item._source?.process?.command_line",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
  ],
};

export const networkfields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Destination IP",
      value: "item._source?.destination?.address",
    },
    {
      name: "Destination",
      value: "item._source?.destination?.geo?.region_name",
    },
    {
      name: "Destination Port",
      value: "item._source?.destination?.port",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item?._source?.host?.os?.name",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Source IP",
      value: "item._source?.source?.ip",
    },
    {
      name: "Source Port",
      value: "item._source?.source?.port",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.full",
    },
    {
      name: "Host Name",
      value: "item._source?.host.name",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Destination URL",
      value: "item._source?.destination?.url",
    },
    {
      name: "Destination Port",
      value: "item._source?.destination?.port",
    },
  ],
};

export const filefields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "File Path",
      value: "item._source.file.path",
    },
    {
      name: "File Size",
      value: "item._source.file.size",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item?._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item?._source?.host?.os?.name",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Destination Port",
      value: "item._source.file.name",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source.host.os.name",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Host Name",
      value: "item._source.host.name",
    },
    {
      name: "File Name",
      value: "item._source?.file?.name",
    },
    {
      name: "File Path",
      value: "item._source?.file?.path",
    },
    {
      name: "File Size",
      value: "item._source?.file?.size",
    },
  ],
};

export const registryfields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Registry Key",
      value: "item._source.registry.key",
    },
    {
      name: "Registry Path",
      value: "item._source?.registry?.path",
    },
    {
      name: "Registry Value Name",
      value: "item._source?.registry?.value?.name",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.full",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Registry Key",
      value: "item._source?.registry?.key",
    },
    {
      name: "Registry Value Name",
      value: "item._source.registry.value.name",
    },
    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.full",
    },
    {
      name: "Host Name",
      value: "item._source.host.name",
    },
    {
      name: "File Path",
      value: "item._source?.file?.path",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
  ],
};

export const timelinefields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Process Path",
      value: "item?._source?.winlog?.event_data?.ProcessName",
    },
    {
      name: "Process ID",
      value: "item._source?.winlog?.event_data?.ProcessId",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "Event Category",
      value: "item._source?.event?.category?.[0]",
    },
    {
      name: "OS",
      value: "item._source.host.os?.name",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Command",
      value: "item._source?.process?.command_line",
    },
    {
      name: "Subject User",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "Subject Domain",
      value: "item._source?.winlog?.event_data?.TargetDomainName",
    },
    {
      name: "Logon Type",
      value: "item._source?.winlog?.event_data?.LogonType",
    },
    {
      name: "Event code",
      value: "item._source?.event?.code",
    },
    {
      name: "Source IP",
      value: 'item?.item?.ClientIP'
    },
    {
      name: "platform",
      value: 'item?._source?.host?.os?.platform'
    },
    {
      name: "File path",
      value: 'item?._source?.file?.path'
    },
    {
      name: "File name",
      value: 'item?._source?.file?.name'
    },
    {
      name: "Source Country",
      value: 'item?._source?.cybSourceCountry'
    },
    {
      name: "Browser",
      value: 'item?.item?.BrowserName'
    },
    {
      name: "Source IP",
      value: 'item?._source?.geoip?.ip'
    },
    {
      name: "Subject",
      value: "item._source?.subject",
    },
    {
      name: "Receiver Address",
      value: "item._source?.['email.receiver.address']",
    },
    {
      name: "Sender Address",
      value: "item._source?.['email.from.address']",
    },
    {
      name: "delivery Action",
      value: "item._source?.['delivery.action']",
    },
  ],
  atp:[
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Process ID",
      value: "item._source.process?.pid",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "Logon Type",
      value: "item._source?.winlog?.event_data?.LogonType",
    },
    {
      name: "Event Category",
      value: "item._source?.event?.category",
    },
    {
      name: "Account",
      value: "item._source?.UserId",
    },
    {
      name: "O365 Event Action",
      value: "item._source?.Workload",
    },
    {
      name: "Source Country",
      value: "item._source?.geoip?.geo?.country_name",
    },
    {
      name: "OS",
      value: " item._source.host?.os?.name",
    },
    {
      name: "Subject User",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "Host Name",
      value: "item._source.host.name",
    },
    {
      name: "Subject Domain",
      value: "item._source?.winlog?.event_data?.SubjectDomainName",
    },
    {
      name: "Command",
      value: "item._source.process?.command_line",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
      value2: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
      value2: "item._source?.winlog?.event_data?.TargetDomainName", 
      
    },
    {
      name: "Source IP",
      value: 'item?._source.ClientIP'
    },
    {
      name: "Operation",
      value: "item._source?.Operation",
    },
    {
      name: "Outcome",
      value: "item._source.event?.outcome",
    },
    {
      name: "platform",
      value: 'item?._source?.Platform'
    },
    {
      name: "Process Id",
      value: "item._source?.winlog?.event_data?.ProcessId",
    },
    {
      name: "Event code",
      value: "item._source?.event?.code",
    },
    {
      name: "O365 Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Browser",
      value: 'item?._source.BrowserName'
    },
    {
      name: "Subject",
      value: "item._source?.subject",
    },
    {
      name: "Receiver Address",
      value: "item._source?.['email.receiver.address']",
    },
    {
      name: "Sender Address",
      value: "item._source?.['email.from.address']",
    },
    {
      name: "delivery Action",
      value: "item._source?.['delivery.action']",
    },
  ],
};


export const authenticationfields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Outcome",
      value: "item._source.event?.outcome",
    },
    {
      name: "Process Name",
      value: "item?._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.full",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Outcome",
      value: "item._source?.event?.outcome",
    },

    {
      name: "Process Name",
      value: "item._source?.process?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.full",
    },
    {
      name: "Host Name",
      value: "item._source.host.name",
    },
    {
      name: "User Name",
      value: "item._source?.user?.name",
    },
    {
      name: "User Domain",
      value: "item._source?.user?.domain",
    },
  ],
};


// ad

export const azureAdFields = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "User Name",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "User Domain",
      value: "item._source?.winlog?.event_data?.TargetDomainName",
    },
    {
      name: "Logon Type",
      value: "item._source?.winlog?.event_data?.LogonType",
    },
    {
      name: "Event code",
      value: "item._source?.event?.code",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Outcome",
      value: "item._source.event?.outcome",
    },
    {
      name: "Process Id",
      value: "item._source?.winlog?.event_data?.ProcessId",
    },
    {
      name: "Process Name",
      value: "item._source?.winlog?.event_data?.ProcessName",
    },
    {
      name: "Subject User",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "Subject Domain",
      value: "item._source?.winlog?.event_data?.SubjectDomainName",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.name",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "User Name",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "User Domain",
      value: "item._source?.winlog?.event_data?.TargetDomainName",
    },
    {
      name: "Logon Type",
      value: "item._source?.winlog?.event_data?.LogonType",
    },
    {
      name: "Event code",
      value: "item._source?.event?.code",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Outcome",
      value: "item._source.event?.outcome",
    },
    {
      name: "Process Id",
      value: "item._source?.winlog?.event_data?.ProcessId",
    },
    {
      name: "Process Name",
      value: "item._source?.winlog?.event_data?.ProcessName",
    },
    {
      name: "Subject User",
      value: "item._source?.winlog?.event_data?.SubjectUserName",
    },
    {
      name: "Subject Domain",
      value: "item._source?.winlog?.event_data?.SubjectDomainName",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "Event Action",
      value: "item?._source?.event?.action",
    },
    {
      name: "OS",
      value: "item._source?.host?.os?.name",
    },
  ],
};

// office

export const office365 = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Account",
      value: "item._source?.UserId",
    },
    {
      name: "Source IP",
      value: "item._source?.geoip?.ip",
    },
    {
      name: "Source Country",
      value: "item._source?.geoip?.geo?.country_name",
    },
    {
      name: "Browser",
      value: "item._source?.['DeviceProperties.BrowserType']",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "OS",
      value: "item._source?.['DeviceProperties.OS']",
    },
    {
      name: "Operation",
      value: "item._source?.Operation",
    },
    {
      name: "Event Action",
      value: "item._source?.Workload",
    },


  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Account",
      value: "item._source?.UserId",
    },
    {
      name: "Source IP",
      value: "item._source?.geoip?.ip",
    },
    {
      name: "Source Country",
      value: "item._source?.geoip?.geo?.country_name",
    },
    {
      name: "Browser",
      value: "item._source?.['DeviceProperties.BrowserType']",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
    {
      name: "OS",
      value: "item._source?.['DeviceProperties.OS']",
    },
    {
      name: "Operation",
      value: "item._source?.Operation",
    },
    {
      name: "Event Action",
      value: "item._source?.Workload",
    },


  ],
};

// mail trace

export const mailTrace = {
  elastic: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Subject",
      value: "item._source?.subject",
    },
    {
      name: "Receiver Address",
      value: "item._source?.['email.receiver.address']",
    },
    {
      name: "Sender Address",
      value: "item._source?.['email.from.address']",
    },
    {
      name: "delivery Action",
      value: "item._source?.['delivery.action']",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
  ],
  atp: [
    {
      name: "ID",
      value: "item._id",
    },
    {
      name: "Subject",
      value: "item._source?.subject",
    },
    {
      name: "Receiver Address",
      value: "item._source?.['email.receiver.address']",
    },
    {
      name: "Sender Address",
      value: "item._source?.['email.from.address']",
    },
    {
      name: "delivery Action",
      value: "item._source?.['delivery.action']",
    },
    {
      name: "Host Name",
      value: "item._source?.host?.name",
    },
    {
      name: "Timestamp",
      value: "item._source['@timestamp']",
    },
  ],
};


// Function to append a unique identifier to each field name
function appendUniqueIdentifier(fields) {
  const uniqueFields = new Set();
  const result = [];

  fields.forEach(field => {
      let fieldName = field.name;
      let count = 1;

      // If the field name already exists, append a unique identifier
      while (uniqueFields.has(fieldName)) {
          fieldName = `${field.name}_${count}`;
          count++;
      }

      // Add the field name to the set of unique fields
      uniqueFields.add(fieldName);
      result.push({ name: fieldName, type: field.type });
  });

  return result;
}

// Combine fields from different sources and ensure uniqueness
export const combinedFields = {
  elastic: appendUniqueIdentifier([
      ...timelinefields.elastic,
      ...azureAdFields.elastic,
      ...office365.elastic,
      ...mailTrace.elastic,
  ]),
  atp: appendUniqueIdentifier([
      ...timelinefields.atp,
      ...azureAdFields.atp,
      ...office365.atp,
      ...mailTrace.atp,
  ]),
};
