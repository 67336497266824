import React, { useState, useEffect } from 'react'
import styles from "./Ciso.styles"
import { Button } from 'react-bootstrap';
import { ArrowsOutSimple } from "phosphor-react";
const Ciso = ({ data, uName, password }) => {


    const [url, setUrl] = useState('');
    const [expand, setExpand] = useState(true);


    useEffect(() => {


        handleIframeLoad()
        setDetails()
    }, [data])


    const setDetails = () => {
        let data_ = data?.variables.find((data) => data.name === 'analytics-ciso-url')
        setUrl(data_?.value);
    }


    console.log(url)
    const handleIframeLoad = () => {
        const token_ = btoa(`${uName}:${password}`); // encode a string




        const iframe = document.getElementsByTagName('iframe')[0];
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        const iframeHeaders = { Authorization: `Basic ${token_}` };
        console.log(iframeHeaders)
        Object.keys(iframeHeaders).forEach(headerName => {
            iframeDocument.defaultView?.fetch?.('/', { headers: { [headerName]: iframeHeaders[headerName] } });
        });
    };
    return (
        <>
           {expand? <>

            <iframe width="100%" height="100%" src={url} onLoad={handleIframeLoad}></iframe>
            <Button variant="light" style={styles.buttonright} onClick={()=>setExpand(false)}> <ArrowsOutSimple size={18} /></Button>

            </>
            :
            <>

            <iframe width="100%" height="100%" src={url} style={styles.fullscreen} onLoad={handleIframeLoad}></iframe>
            <Button variant="light" style={styles.buttonrightfullscreen} onClick={()=>setExpand(true)}> <ArrowsOutSimple size={18} /></Button>

            </>}
        </>
    )
}

export default Ciso
