// CASE MANAGEMENT-- COMMENT  --- LIST
export const CM_COMM_LIST_REQUEST = "CM_COMM_LIST_REQUEST";
export const CM_COMM_LIST_SUCCESS = "CM_COMM_LIST_SUCCESS";
export const CM_COMM_LIST_FAIL = "CM_COMM_LIST_FAIL";


// CASE MANAGEMENT-- COMMENT  --- CREATE
export const CM_COMM_CREATE_REQUEST = "CM_COMM_CREATE_REQUEST";
export const CM_COMM_CREATE_SUCCESS = "CM_COMM_CREATE_SUCCESS";
export const CM_COMM_CREATE_FAIL = "CM_COMM_CREATE_FAIL";


// CASE MANAGEMENT-- COMMENT  --- DELETE
export const CM_COMM_DELETE_REQUEST = "CM_COMM_DELETE_REQUEST";
export const CM_COMM_DELETE_SUCCESS = "CM_COMM_DELETE_SUCCESS";
export const CM_COMM_DELETE_FAIL = "CM_COMM_DELETE_FAIL";