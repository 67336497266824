import { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import {
  useReactFlow,
  getIncomers,
  // useStore,
  getOutgoers,
  isNode,
  getConnectedEdges,
} from "reactflow";
import { getProcessQuery } from '../../../Queries/CykitQuery';
import { fpostCy } from '../../../axios/axios';
import { shallow } from 'zustand/shallow';
import useStore from '../Store/store';
import { getMultiQuery } from '../../../Queries/CykitQuery';
import { File, Funnel, Gear, Globe, Key, MagnifyingGlass, Notebook } from 'phosphor-react';
import globalStyles from '../../../constants/globalStyles';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils';
import { getFieldAggsData, getLogSourceData } from '../Methods';
import { Badge } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  setClick: state.setClick,
  setClicked: state.setClicked,
  setCollapseNodes: state.setCollapseNodes,
  setCollapseEdges: state.setCollapseEdges,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  searchType: state.searchType,
  keyword: state.keyword,
});
const handleStyle = { left: 10 };

let baseURL = localStorage.getItem("DATALAKE_URL");;
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

function CustomNodeProcess({ data, isConnectable }) {
  const { getNodes, getEdges, getNode } = useReactFlow();
  const [expand, setExpand] = useState(false)
  const [lexpand, setLExpand] = useState(false)
  const [show, setShow] = useState(true)
  const [lshow, setLShow] = useState(true)
  const [inner, setInner] = useState([])
  const [outer, setOuter] = useState([])
  const [fileCount, setFileCount] = useState(0)
  const [registryCount, setRegistryCount] = useState(0)
  const [networkCount, setNetworkCount] = useState(0)
  const nodeById = getNode(data.id)
  const { nodes, edges, setClick, setClicked, setCollapseNodes, setCollapseEdges, setEdges, setNodes, keyword, searchType } = useStore(selector, shallow);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [file, setFile] = useState()
  const [preload, setPreload] = useState(true)
  const [registry, setRegistry] = useState()
  const [fieldData, setFieldData] = useState()
  const [logSourceCount, setLogSourceCount] = useState(0)
  const [logSourceData, setLogSourceData] = useState([])
  const edgeType = "smoothstep";
  const position = { x: 0, y: 0 };
  useEffect(() => {
    const inner = (getIncomers(nodeById, getNodes(), getEdges()))
    if (inner?.length > 0 && preload) {
      setExpand(true)
    }

    const outer = (getOutgoers(nodeById, getNodes(), getEdges()))
    if (outer?.length > 0 && preload) {
      setLExpand(true)
    }

  }, [nodes])

  useEffect(() => {
    const Postdata = getProcessQuery(data, "forward")
    setLShow(data.id === '1a' ? false : true)
  }, [])

  function handleMouseEnter() {
    setIsMenuOpen(true);
  }

  function handleMouseLeave() {
    setIsMenuOpen(false);
  }
  useEffect(() => {
    if (data.id !== '1a' && data?.id?.includes("1a")) {
      setLShow(false)
      // getFieldData(data?.value)
    }
    if (data.id === '1a' || data.type === 'field') {
      setLShow(false)
      
      getLogSource(keyword,[...data?.filter, ...data?.initFilter], data?.dateFilter, searchType)

    }
    if (data.type === 'log_source') {
      setLShow(false)
      setShow(false)

    }
  }, [])

  const getFieldData = async (field, filter, dateFilter) => {
    const data = await getFieldAggsData(field, filter, dateFilter)
    setFieldData(data?.data?.aggregations?.field?.buckets)
  }
  const getLogSource = async (keyword, filter, dateFilter, searchType) => {
    const res = await getLogSourceData(keyword, filter, dateFilter, searchType)
    setLogSourceData(res?.data?.aggregations?.field?.buckets)
    setLogSourceCount(res?.data?.aggregations?.field?.buckets?.length)
  }
  const onCollapse = (node, direction) => {
    if (direction === "forward") {
      console.log("test", nodes);
      const nodeById = getNode(node.id);
      const inner = getIncomers(nodeById, getNodes(), getEdges());
      const outer = getOutgoers(nodeById, getNodes(), getEdges());
      const initEdge = getConnectedEdges([nodeById], getEdges());
      var delNodes = inner;
      var deledges = initEdge;

      for (var i = 0; i < delNodes.length; i++) {
        const inc = getIncomers(delNodes[i], getNodes(), getEdges());
        const ine = getConnectedEdges([delNodes[i]], getEdges());

        if (inc.length > 1) {
          inc.forEach((innode) => delNodes.push(innode)); // append each incoming node
        } else if (inc.length === 1) {
          delNodes.push(inc[0]); // append the single incoming node
          deledges.push(ine); // append the incoming edge
        }
      }
      // remove outgoing edge from list
      const newDelEdges = deledges.filter((object1) => {
        return !outer.some((object2) => {
          return object1.target === object2.id;
        });
      });

      setCollapseNodes(delNodes);

      setCollapseEdges(newDelEdges);
    } else if (direction === "backward") {
      const nodeById = getNode(node.id);
      const inner = getIncomers(nodeById, getNodes(), getEdges());
      const outer = getOutgoers(nodeById, getNodes(), getEdges());
      const initEdge = getConnectedEdges([nodeById], getEdges());
      var delNodes = outer;
      var deledges = initEdge;

      for (var i = 0; i < delNodes.length; i++) {
        const inc = getOutgoers(delNodes[i], getNodes(), getEdges());
        const ine = getConnectedEdges([delNodes[i]], getEdges());

        if (inc.length > 1) {
          inc.forEach((innode) => delNodes.push(innode)); // append each incoming node
        } else if (inc.length === 1) {
          delNodes.push(inc[0]); // append the single incoming node
          deledges.push(ine); // append the incoming edge
        }
      }
      // remove outgoing edge from list
      const newDelEdges = deledges.filter((object1) => {
        return !outer.some((object2) => {
          return object1.source !== object2.id;
        });
      });

      setCollapseNodes(delNodes);
      setCollapseEdges(newDelEdges);
    }
  };
  const nodeClick = () => {
    if (data?.id === '1a' || data?.type === "field") {
      let newNodes = [];
      let newEdges = [];

      var fieldList = logSourceData?.map(item => item.key);
      fieldList?.map((field, key) => {
        const uid = uuidv4();

        var parentProcessNode = {
          id: `${data.id}_${uid}`,
          type: "CustomNodeProcess",
          nodetype: "log_source",
          data: {
            label: field,
            onCollapse: onCollapse,
            nodetype: "log_source",
            count: logSourceData?.[key]?.doc_count,
            initFilter: [...data?.initFilter],
            id: `${data.id}_${uid}`,
            type: 'log_source',
            initFilter: [],
            filter: data?.filter,
            dateFilter: data?.dateFilter,
            value: field,
            field: "",
            logSource: logSourceData,

            // doc_id: val.all_fields.hits.hits[0]._id,
            // category: val.all_fields.hits.hits[0]._source.category,
            searchType: data.searchType,
          },
          position,
          sourcePosition: "right",
          targetPosition: "left",
        };

        const parentEdge = {
          id: `e_${data.id
            }_${uid}`,
          source: `${data.id}_${uid}`,
          target: `${data.id}`,
          type: edgeType,
          animated: true,
        };
        console.log("process:", parentProcessNode);

        newNodes.push(parentProcessNode);
        newEdges.push(parentEdge);
      });
      setNodes([...nodes, ...newNodes]);
      setEdges([...edges, ...newEdges]);
    }
    else if (data?.id === 'log_source') {
      let newNodes = [];
      let newEdges = [];
      var fieldList = logSourceData?.map(item => item.key);
      fieldList?.map((field, key) => {
        const uid = uuidv4();

        var parentProcessNode = {
          id: `${data.id}_${uid}`,
          type: "CustomNodeProcess",
          nodetype: "field",
          data: {
            label: field,
            onCollapse: onCollapse,
            nodetype: "",
            count: logSourceData?.[key]?.doc_count,
            initFilter: [...data?.initFilter],
            id: `${data.id}_${uid}`,
            type: 'log_source',
            initFilter: [],
            filter: data?.filter,
            dateFilter: data?.dateFilter,
            value: field,
            field: "",
            logSource: logSourceData,
            // doc_id: val.all_fields.hits.hits[0]._id,
            // category: val.all_fields.hits.hits[0]._source.category,
            searchType: data.searchType,
          },
          position,
          sourcePosition: "right",
          targetPosition: "left",
        };

        const parentEdge = {
          id: `e_${data.id
            }_${uid}`,
          source: `${data.id}_${uid}`,
          target: `${data.id}`,
          type: edgeType,
          animated: true,
        };
        console.log("process:", parentProcessNode);

        newNodes.push(parentProcessNode);
        newEdges.push(parentEdge);
      });
      setNodes([...nodes, ...newNodes]);
      setEdges([...edges, ...newEdges]);
    }
  }
  const getsubLabel = () => {
    if (data.id === '1a' || data.type === "field") {
      return `Log Sources: ${logSourceCount}`
    }
    else if (data.id !== '1a' && data?.id?.includes("1a")) {
      return `Logs Found: ${data?.count}`
    }
    else {
      return `Common Fields: ${fieldData?.length}`
    }
  }
  // const date = new Date(data.p_creation_time);
  // const newTime = date.toLocaleString();
  const GetIcon = () => {
    if (data.type === 'keyword') {
      return <MagnifyingGlass size={24} color={globalStyles.white} />
    }
    if (data.type === 'log_source') {
      return <Notebook size={24} color={globalStyles.white} />
    }
    if (data.type === 'field') {
      if(data?.filterType==="is not")
      {
        return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={globalStyles.white} viewBox="0 0 256 256"><path d="M227.82,66.76A16,16,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.73-72.32ZM40,56h0Zm106.19,74.59A8,8,0,0,0,144,136v58.66L112,216V136a8,8,0,0,0-2.16-5.46L40,56H216Zm99.49,79.81a8,8,0,0,1-11.32,11.32L216,203.32l-18.34,18.35a8,8,0,0,1-11.31-11.32L204.69,192l-18.34-18.35a8,8,0,0,1,11.31-11.31L216,180.69l18.34-18.34a8,8,0,0,1,11.32,11.31L227.31,192Z"/></svg>

      }
      else{
        return <Funnel size={24} color={globalStyles.white} />

      }
    }
  }
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "80px"
    }}>
      <div className="custom-node p-2" style={{
        borderRadius: "50%",
        background: globalStyles.greyDark1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "29px",
        height: "28px",
      }}>

        <Handle type="target" className="handleRight" position={Position.Right} style={{
          marginLeft: '2px', marginTop: data?.type==='field'||data?.type==='log_source'?'-7px':'-3px'
        }} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
        <GetIcon />
        <Handle type="source" className="handleLeft" position={Position.Left} style={{
          postion: "absolute",
          marginLeft: '2px', marginTop: data?.type==='field'||data?.type==='log_source'?'-7px':'-3px'
        }} />
      </div>

      {lshow ? (lexpand ?
        <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"
        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setLExpand(false); setPreload(false); onCollapse(data, "backward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12" /></svg>
        : <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"

        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setLExpand(true); setPreload(false); data.onClick(data, "backward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>
      ) :
        <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px",
          visibility: 'hidden'

        }} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>}
      {show && (expand ?
        <svg style={{
          marginLeft: "30px",
          marginTop: "-8px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"
        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setExpand(false); setPreload(false); onCollapse(data, "forward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12" /></svg>
        : <svg style={{
          marginLeft: "30px",
          marginTop: "-8px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"

        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setExpand(true); setPreload(false); nodeClick() }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>
      )}
      <div style={{
        fontSize: '10px',
        paddingTop: '8px',
        justifyContent: "center",

      }} >
        <div onClick={() => { setClick(data.label); setClicked(data) }} className='d-flex flex-column gap-2'>
          <div className='text-center'>
            {data.label}
          </div>
          <div>
            {getsubLabel() && <div style={{
              backgroundColor: 'black',
              color: 'white',
              fontSize: '0.55em',
              padding: '2px 4px',
              borderRadius: '4px',
              textAlign: "center"
            }}>
              {getsubLabel()}
            </div>}
          </div>
        </div>
      </div>
      <div style={{
        fontSize: '10px',
        color: globalStyles.greyDark3
      }} >
        {/* {data.keyword} */}
      </div>

    </div>
  );
}

export default CustomNodeProcess;