import React, { useEffect, useState } from "react";
import "../../../index.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDateFromTimestamp, getTimeFromTimestamp, rawHTML } from '../../../Utils/Utils';
import { ToastContainer, toast } from 'react-toastify';
import RecentTab from "../../../components/RecentTab/RecentTab";
import globalStyles from "../../../constants/globalStyles";
import Table from "react-bootstrap/Table";
import styles from "./Detections.styles";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { Badge, Button, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { TimelineLoader } from "../../../components/Loader/TimelineLoader"
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';
import { ImCross } from "react-icons/im";
import 'bootstrap/dist/css/bootstrap.min.css';

import jsPDF from "jspdf";

import "jspdf-autotable";
// import { CheckSquare } from 'react-phosphor-icons';
import { ArrowRight, DownloadSimple, Info, CheckSquare, X, XSquare } from "phosphor-react";
import {
  getTotalHuntDetectedCount,
  getTotalHuntAlertCount,
  getSeverityCounts,
  getDetectionTableData,
  getDetectionTimelineDatas,
  getHuntStatus,
  getDetectionDetails
} from "../../../Methods/HunterMethods";
import {
  getInvCatalogueTenant,
  getInvCatalogueMaster

} from "../../../Methods/RuleStudioMethods";
import HunterDetection from "../../../components/DetailsPage/HunterDetection";
import Pagination from "../../../components/Pagination/Pagination";
import XLfileConverter from "../../../components/XlTable/XLfileConverter";
import TableLoader from "../../../components/Loader/TableLoader";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import HunterExport from "../Export";
import EditModal from "../../../components/CaseManagement/EditModal";
import { Desktop } from 'phosphor-react';
import AlertBox from "../../../components/AlertBox/AlertBox";
import moment from "moment";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";


const Detections = ({ selectedDateFilter }) => {
  const dispatch = useDispatch();

  const [isHuntDetected, setIsHuntDetected] = useState(false)

  const [totalDetectionCount, setTotalDetectionCount] = useState();
  const [totalHuntAlertCount, setTotalHuntAlertCount] = useState();
  const [severityCount, setSeverityCount] = useState([]);

  const [detectionData, setDetectionData] = useState([]);
  const [detectionDataError, setDetectionDataError] = useState([]);

  const [loader, setLoader] = useState(true)

  // const [stepsData, setStepsData] = useState([])
  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);
  const [caseshow, setCaseShow] = useState(false)

  const [alertCasePopup, setAlertCasePopup] = useState(false)


  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [prevPageCount, setPrevPageCount] = useState();

  // drop down

  const [isOneStep, setIsOneStep] = useState(false)
  const [allStep, setAllStep] = useState(true)
  const [selectedValue, setSelectedValue] = useState(null);
  const [isAlerted, setIsAlerted] = useState(false)

  const [loadingTable, setLoadingTable] = useState(true)

  const [case_id, setCase_ID] = useState("");

  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;

  const [id, setHuntId] = useState("");
  const [status, setStatus] = useState(null);

  const [detectionTimelineData, setDetectionTimelineData] = useState([]);
  const [detectionDetails, setDetectionDetails] = useState();



  // new Popup
  const [cybotStatus, setCybotStatus] = useState(false)
  const [datalakeStatus, setDataLakeStatus] = useState(false)
  const [statusLoader, setStatusLoader] = useState(true)
  const [statusData, setStatusData] = useState([])
  const [isStatus, setIsStatus] = useState(false);
  // const [invData, setInvData] = useState([]);
  const [invCount, setInvCount] = useState(0);
  const [stepCount, setStepCount] = useState(0);


  const [timelneLoader, setTimelneLoader] = useState(true);

  const listCatRsInv = useSelector((state) => state.listCatRsInv);
  const { catalogues: invData, listLoading: catLoading, deleteSuccess, deletedId } = listCatRsInv;






  const listItemStyle = {
    listStyleType: 'none', // Remove default list styling
  };

  const checkmarkStyle = {
    // color: globalStyles.green,
    marginRight: '0.5em', // Adjust spacing between the checkmark and the text
  };

  //   const GreenCheckbox = styled(CheckSquare)`
  //   color: green;
  // `;


  useEffect(() => {
    getDetectionTableData(page, "desc", (page - 1) * perPage)
      .then(
        (res) => {
          let hits = res?.data?.hits?.hits
          hits.map((item) =>
          (item._source.hunt_id
          )
          )
          // console.log(id);
        })
      .catch((err) => {
        console.log("Error", err);
      });


  }, [])

  useEffect(() => {
    setTimelneLoader(true)
    getHuntStatus(id)
      .then(
        (res) => {
          let hits = res?.data?.hits?.hits
          setStatusData(hits)
          setTimelneLoader(false)
          // console.log("status",statusData);
          // statusData.map((item)=>setHuntId(item._source.hunt_id))
        }
      );
  }, [id])

  // inv data fetch
  useEffect(() => {
    dispatch(getInvCatalogueTenant());
    //     getInvCatalogueMaster("asc").then((res) => {

    //   if (res) {
    //     setInvData(res.data)
    //     //  invData.map((item)=>{
    //     //   console.log(item.inv_id);
    //     //  })  
    //   }
    // })
    console.log(invData);

  }, [])



  useEffect(() => {
    getTotalHuntDetectedCount()
      .then(
        (res) => {
          let total = res?.data?.hits?.total
          total?.value && setTotalDetectionCount(total?.value);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    getTotalHuntAlertCount()
      .then(
        (res) => {
          let total = res?.data?.hits?.total
          total?.value && setTotalHuntAlertCount(total?.value);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    getSeverityCounts()
      .then(
        (res) => {
          let buckets = res?.data?.aggregations?.['2'].buckets
          buckets &&
            buckets?.map((item) => {
              let severityCount = {
                low: 0,
                medium: 0,
                high: 0,
              };

              if (item.key === "Low") {
                severityCount.low = item.doc_count;
              } else if (item.key === "Medium") {
                severityCount.medium = item.doc_count;
              } else {
                severityCount.high = item.doc_count;
              }

              setSeverityCount(severityCount);
            });
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    getDetectionTableData(page, "desc", (page - 1) * perPage)
      .then(
        (res) => {
          if (!res?.isError) {
            let hits = res.data.hits
            setCurrentPageCount((page - 1) * perPage + perPage);
            setPrevPageCount((page - 1) * perPage + 1);
            hits?.hits.forEach((element) => {

              // console.log("Each elemets :", element);

            });

            // hits?.hits.forEach(element => {
            //   for(let i = 1; i<= element._source.total_steps; i++){
            //     stepsCount.push(i)
            //   }
            //   setStepsData(stepsCount)
            // });
            setDetectionData(hits?.hits.reverse());
            setLoadingTable(false)
            // console.log("hits", hits);
          } else {
            setDetectionDataError(res)
            setLoadingTable(false)
          }
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setLoadingTable(false)
      });
  }, [selectedDateFilter]);


  // case
  useEffect(() => {
    const toasts = () => toast("Case created successfully");



    if (caseCreateSuccess === true) {
      // console.log(caseCreateSuccess)
      setCaseShow(false)
      setAlertCasePopup(true)
      // toasts()

    }
  }, [caseCreateSuccess])

  useEffect(() => {
    let count = 0;
    statusData?.forEach((item, index) => {
      item._source.playbook_id.forEach((item1) => {
        // Count occurrences where item2.inv_id is equal to item1
        count += invData.filter(item2 => item2.inv_id === item1).length;
      });
    });
    setInvCount(count);
    setStepCount(statusData?.length)
  }, [statusData]);


  useEffect(() => {

    getDetectionDetails(id)
      .then((res) => {
        let data = res?.data
        setDetectionDetails([data]);
        //  console.log("detail",detectionDetails);
      })
      .catch((err) => {
        // console.log("Error", err);
      });




    getDetectionTimelineDatas(id)
      .then(
        (res) => {
          let hits = res?.data?.hits?.hits
          setDetectionTimelineData(hits);
          // console.log("detail",detectionTimelineData);

        }
      )
      .catch((err) => {
        // console.log("Error", err);
      });

  }, [id])

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [["Hunt Name", "Steps", "New Detections", "Severity"]],

      body: detectionData.map((item) => [
        item._source.hunt_name,
        // stepsData,
        item._source.new_detections,
        // item._source.alerted,
        item._source.detection_severity,
      ]),
    });
    // download the pdf document
    doc.save("Hunter detections.pdf");
  };

  const handleCreate = () => {
    setCaseShow(true);
  };

  const calculateSteps = (length, items) => {
    // console.log('calculateSteps = ', length);
    // console.log('items = ', items);
    let x = [];
    for (let i = 1; i <= length; i++) {
      // items &&
      items.findIndex((x) => x == i) >= 0 ? x.push(true) : x.push(false);
    }

    // console.log("x = ", x);
    return x;
  };

  const handleOpenModal = (data, display) => {
    setDetailpage(data);
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
  }
  function renderModal() {
    return <HunterDetection id={detailpage._source.hunt_id} />;
  }

  function renderModalStatus() {
    return (
      //       <div  >
      //         { detectionData?.map((item) => 

      //       <div className="d-flex flex-column">

      //           <div className="d-flex gap-2">
      //             <span><strong>Status of {item._source.hunt_id}</strong></span>
      //           </div>
      //           <br></br>
      //           <div className="d-flex gap-2">
      //             <span><strong>Name</strong></span>
      //             <span >
      //               {item._source.hunt_name?item._source.hunt_name:"" }
      //               </span>
      //           </div>
      //           <div className="d-flex gap-2">
      //             <span><strong>Description</strong></span>
      //             {/* style={{ color: item._source.hunt_name ? globalStyles.green : globalStyles.red }} */}
      //             <span >
      //            This hunt has <strong> {stepCount } </strong> steps containg <strong>{invCount }</strong>  alerts which are currently monitored. 

      //               </span>
      //           </div>
      //           <div className="d-flex gap-2">
      //             <span><strong>Started Time</strong></span>
      //             <span >
      //               {item?._source.hunt_started ? item?._source.hunt_started : ""}
      //               </span>
      //           </div>
      //           <div className="d-flex gap-2">
      //             <span><strong>Last Check Time</strong></span>
      //             <span >
      //               {statusData.length > 0 && (
      //                <p>{new Date(statusData[0]._source.timestamp).toLocaleString()}</p>
      //               )}
      //               </span>
      //           </div>
      //           <div className="d-flex gap-2">

      //           <span>
      //           {statusData?.map((item, index) => (
      //             <p key={index} >
      //               <strong> Step-{index + 1}: 
      //                {detectionDetails!=null? (detectionDetails[0].steps[index].name):""}
      //                </strong>

      //                <div className="d-flex gap-2">
      // <span>
      // {item?._source?.playbook_id?.map((item1) => (
      // <div key={item1}>
      // <ul style={{ ...listItemStyle, listStyleType: 'none', paddingLeft: '10px' }}>
      // {invData.map((item2) => (
      // <li key={item2.inv_id}>
      // {item1 === item2.inv_id && (
      // <div>
      // <span style={checkmarkStyle}>&#10003;</span>
      // {item2.inv_name}
      // </div>
      // )}
      // </li>
      // ))}
      // </ul>
      // </div>
      // ))}
      // </span>
      // </div>
      //           </p>
      //           ))}
      //         </span>

      //           </div>
      //         {/* <button onClick={handleStatusClose}>Close</button> */}
      //       </div>
      //         )}      
      //       </div>
      <div className="flex-fill d-flex flex-column gap-3 overflow-auto border rounded-3">
        {detectionData?.map((detectionItem) => (
          detectionItem._source?.hunt_id === id && (
            <div className="flex-fill p-4 rounded-3 bg-white">
              <div className="d-flex gap-2 ">
                <span className="col-2"><strong>ID:</strong></span>
                <span className="col-10">
                  {detectionItem._source.hunt_id}
                </span>
              </div>

              <div className="d-flex gap-2 ">
                <span className="col-2"><strong>Name:</strong></span>
                <span className="col-10">
                  {detectionItem._source.hunt_name ? detectionItem._source.hunt_name : ""}
                </span>
              </div>

              <div className="d-flex gap-2">
                <span className="col-2"><strong>Started Date:</strong></span>
                <span className="col-10" >
                  {detectionItem?._source.hunt_started ? detectionItem?._source.hunt_started : ""}
                </span>
              </div>

              <div className="d-flex gap-2">
                <span className="col-2"><strong>Description:</strong></span>
                <span className="col-10" >
                  {`This hunt has ${statusData?.length} steps containg ${invCount}  alerts which are currently monitored.`}
                </span>
              </div>

              <div className="d-flex gap-2">
                <span className="col-2"><strong>Last Check Time:</strong></span>
                <span className="col-10" >
                  {statusData?.length > 0 && (
                    <>
                      {/* <p>{(new Date(statusData?.[0]._source.timestamp).getDate() + '').padStart(2, '0')}-{(new Date(statusData?.[0]._source.timestamp).getMonth() + 1 + '').padStart(2, '0')}-{new Date(statusData?.[0]._source.timestamp).getFullYear()} &nbsp;{new Date(statusData?.[0]._source.timestamp).toLocaleTimeString()}</p> */}
                      <p>{getDateFromTimestamp(statusData?.[0]._source.timestamp) + "," + getTimeFromTimestamp(statusData?.[0]._source.timestamp)}</p>
                    </>
                    // <p>{statusData[0]._source.timestamp}</p>
                    //  <p>{new Date(statusData[0]._source.timestamp).toLocaleString()}</p>
                  )}
                </span>
              </div>

              <div
                className="d-flex col-12 position-relative"
                style={styles.recentDetectionsWrap}
              >
                {timelneLoader ? (
                  <div className="col-12">
                    <TimelineLoader />
                  </div>
                ) : (
                  <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                    {statusData?.map((item, index) => (
                      <li
                        className="d-flex flex-row gap-3 justify-content-center position-relative"
                        style={styles.detectionItem}

                      >
                        {invData?.some((item2) => item?._source?.playbook_id.includes(item2?.inv_id)) && (
                          <span
                            key={index}
                            className="dateLabel"
                            style={{
                              left: 0,
                              top: 0,
                              // minHeight: detectionDetails != null && detectionDetails[0] != null ? `${(detectionDetails[0].steps[index].name.length + 1) * 2}px` : 'auto',
                              minHeight: detectionDetails?.[0]?.steps?.[index]?.name != null
                                ? `${(detectionDetails?.[0].steps[index].name.length + 1) * 2}px`
                                : 'auto',
                              display: 'flex',
                              flexDirection: 'column', // Display items in a column
                            }}
                          >
                            {/* {invData?.map((inv) => (
                            <p>Tests</p>
                          ))} */}
                            <span style={{ fontWeight: '800' }}>
                              Step-{index + 1}:
                            </span>
                            {detectionDetails &&
                              <span style={{ fontSize: '0.9rem' }}>
                                {detectionDetails?.[0]?.steps?.[index]?.name}
                              </span>
                            }
                          </span>
                        )}


                        {invData?.some((item2) => item?._source?.playbook_id.includes(item2?.inv_id)) && (
                          <div className="d-flex col-12 gap-3 flex-column px-3">
                            {item?._source?.playbook_id.length > 0 && (
                              <>
                                {item?._source?.playbook_id?.map((item1) => (
                                  // code, eliminating no value steps
                                  //   <>
                                  //   {invData.map((item2) => (
                                  //       (item1 === item2.inv_id) && (
                                  //         item2.inv_id ? (
                                  //           <p key={item1} className="timeline-item p-3 rounded-3 alert alert-danger cursor-pointer mb-2">
                                  //             <ul style={{ ...listItemStyle, listStyleType: 'none', paddingLeft: '10px' }}>
                                  //               <li key={item2.inv_id}>
                                  //                 <span style={{ ...checkmarkStyle, display: 'flex', alignItems: 'center' }}>
                                  //                   <GreenCheckbox />&nbsp;&nbsp;&nbsp; {rawHTML(item2.inv_name)}
                                  //                 </span>
                                  //               </li>
                                  //             </ul>
                                  //             {/* {rawHTML(item._source.description)} */}
                                  //           </p>
                                  //         ) : (
                                  //           <ImCross key={item1} className="timeline-item p-3 rounded-3 alert alert-danger cursor-pointer mb-2" />
                                  //         )
                                  //       )
                                  //     ))}
                                  // </>
                                  <p key={item1} className={`p-3 rounded-3 alert ${invData?.find((item2) => item1 === item2.inv_id) && "alert-secondary"}`}>
                                    <ul style={{ listStyleType: 'none', paddingLeft: '10px' }}>
                                      {invData?.some((item2) => item1 === item2?.inv_id) && (
                                        <li style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                                          <CheckSquare size={25} />
                                          <span style={{ ...checkmarkStyle, display: 'flex', alignItems: 'center', maxWidth: '80%' }}>
                                            {rawHTML(invData?.find((item2) => item1 === item2?.inv_id)?.inv_name)}
                                          </span>
                                        </li>
                                      )
                                        // : (
                                        //   <li style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                                        //     <XSquare size={25} />
                                        //     <span style={{ ...checkmarkStyle, display: 'flex', alignItems: 'center' }}>
                                        //       {"No Data Found"}
                                        //     </span>
                                        //   </li>
                                        // )
                                      }
                                    </ul>
                                  </p>

                                ))}
                              </>
                            )
                              // : (
                              //   <p className="timeline-item p-3 rounded-3 alert alert-danger cursor-pointer">
                              //     <span style={{ ...checkmarkStyle, display: 'flex', alignItems: 'center' }}>
                              //       <XSquare size={25} />&nbsp;&nbsp;&nbsp; {"No Data Found"}
                              //     </span>
                              //   </p>
                              // )
                            }

                          </div>
                        )}

                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )
        ))}
      </div >
    );
  }




  // filter


  const showAllDetection = () => {
    getDetectionTableData(page, "desc", (page - 1) * perPage)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          setDetectionData(hits)
          setIsHuntDetected(true)
        })
  };

  const huntDetected = () => {
    let detectedHuntData = detectionData?.filter((item) => item._source.status === "Investigated")
    setDetectionData(detectedHuntData)
    setIsHuntDetected(false)
  };

  const handleToggleChange = (e) => {
    e.target.checked === true ? huntDetected() : showAllDetection()
  }

  const handleSelect = (e) => {
    setLoadingTable(true)
    setSelectedValue(e === "allHunt" ? "All Hunt" : e === "oneStep" ? "One Step" : "Steps Met");
    if (e === "allHunt") {
      // setIsOneStep(false)
      getDetectionTableData(page, "desc", (page - 1) * perPage)
        .then(
          ({
            data: {
              hits: { hits },
            },
          }) => {
            setDetectionData(hits.reverse());
            setIsOneStep(false)
            setIsAlerted(false)
            setLoadingTable(false)
          }
        )

    } else if (e === "oneStep") {
      detectionData?.map((item) => {
        if (item?._source?.steps_triggred.length > 0) {
          setIsAlerted(false)
        }
      })
      setTimeout(() => {
        setLoadingTable(false)
      }, 1000);
      setIsOneStep(true)
    } else if (e === "alerted") {
      setIsOneStep(false)
      let alerted = detectionData?.filter((item) => item._source.detection_severity?.toLowerCase() === 'high')
      setIsAlerted(true)
      setTimeout(() => {
        setLoadingTable(false)
      }, 500);
    }
  }

  // status

  // const buttonStyle = {
  //   backgroundColor: 'green',
  //   color: 'white',
  //   padding: '10px 20px',
  //   borderRadius: '5px',
  //   border: 'none',
  //   cursor: 'pointer',
  // };
  // status

  const buttonStyle = {
    backgroundColor: 'transparent', // Set background color to transparent
    color: 'blue', // Set the text color
    padding: '0', // Remove padding
    borderRadius: '50%', // Make it a circle by setting border-radius to 50%
    border: 'none',
    cursor: 'pointer',
    width: '40px', // Set a fixed width for the circular button
    height: '40px', // Set a fixed height for the circular button
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const boxStyle = {
    display: isStatus ? 'block' : 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '10px',
    backgroundColor: 'white', // Change to white background
    border: '1px solid gray',
    borderRadius: '5px',
    width: '300px', // Adjust width as needed
    maxWidth: '90vw', // Set maximum width to 90% of the viewport width
    height: 'auto', // or specify a fixed height if needed
    overflow: 'auto', // add overflow property to enable scrolling if the content overflows
  };


  const handleStatusButton = (huntId) => {
    setHuntId(huntId)
    setIsStatus(true);
  };

  const handleStatusClose = () => {
    setIsStatus(false);
    setInvCount(0);

  };

  return (
    <>
      {/* <div
        className="d-flex flex-column p-4 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >
        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <h4>Overview</h4>
          <p>
            ActiveBytes threat feeds contain rich information about threat
            events, vulnerabilities, and exploits along with recent news
            headlines, from curated sources. This can help in keeping up to date
            with the latest developments in the cyber-space.
          </p>
        </div>

        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <div className="col-6">
            <span>Total Hunt Detected</span>
            <p>{totalDetectionCount}</p>
          </div>
          <div className="col-6">
            <span>Hunt Alert</span>
            <p>{totalHuntAlertCount}</p>
          </div>
        </div>

        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <div className="d-flex flex-row gap-2">
            <div className="d-flex flex-column align-items-center justify-content-center w-33 mb-0 alert alert-info">
              <span className="tile-label">Low</span>
              <h5 className="mb-0">{severityCount.low}</h5>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center w-33 mb-0 alert alert-warning">
              <span className="tile-label">Medium</span>
              <h5 className="mb-0">{severityCount.medium}</h5>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center w-33 mb-0 alert alert-danger">
              <span className="tile-label">High</span>
              <h5 className="mb-0">{severityCount.high}</h5>
            </div>
          </div>
        </div>
      </div> */}

      <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">
        <div className="col-12 d-flex flex-row pb-4 border-bottom mb-3" style={styles.filterWrap}>
          <div className="col-5 d-flex flex-row align-items-center">
            <h4 className="mb-0">Detections</h4>
          </div>



          <div className="d-flex flex-row align-items-center ml-auto col-7 justify-content-end">
            <div className="position-relative mx-2">
              <HunterExport getDetectionTableData={getDetectionTableData} isOneStep={isOneStep} isAlerted={isAlerted} />
            </div>

            {/* <Button
                className="ml-2 btn btn-light"
                onClick={(e) => handleCreate()}
              >
                New Case
              </Button> */}
            <div className="sticky-toolbar">
              {/* <Form>
                <Form.Check
                  type="switch"
                  id="toggle_detections_switch"
                  label={isHuntDetected ? "Detected Hunts" : "All Hunts"}
                  onChange={handleToggleChange}
                />
              </Form> */}
              <DropdownButton
                className="rounded-3"
                style={{ backgroundColor: "#F6F7FB" }}
                title={selectedValue ? selectedValue : "All Hunt"}
                variant=""
                onSelect={handleSelect}
              >
                <Dropdown.Item eventKey="allHunt">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.greyDark3,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>All Hunt</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="oneStep">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.yellowDark1,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>One Step</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="alerted">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.red,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>Steps Met</span>
                  </div></Dropdown.Item>
              </DropdownButton>
            </div>

            <Dropdown className="mx-2">
              {/* <Dropdown.Toggle variant="success" id="export">
                Export as
              </Dropdown.Toggle> */}

              <Dropdown.Menu>
                <Dropdown.Item>
                  <XLfileConverter
                    tableData={detectionData}
                    filename="Community Events"
                    sheetname="Sheet 1"
                  />
                </Dropdown.Item>

                <Dropdown.Item>
                  <span className="px-2" onClick={() => downloadPDF()}>
                    Save PDF
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Pagination
              page={page}
              setPage={setPage}
              perPage={perPage}
              currentPageCount={currentPageCount}
              totalDataCounts={selectedValue == 'One Step' ? detectionData?.filter((item) => {
                if (item?._source?.steps_triggred.length > 0) {
                  return item
                }
              })?.length : selectedValue === 'Steps Met' ? detectionData?.filter((item) => item?._source.detection_severity?.toLowerCase() === 'high')?.length : detectionData?.length}
            />
          </div>
        </div>

        <div className="col-12" style={styles.tableWrap}>
          {loadingTable ? (
            <TableLoader rowCount={18} />
          ) : (
            <>
              {
                <Table borderless hover>
                  <thead className="fixed-head">
                    <tr>
                      <th>Hunt Name</th>
                      <th>Steps</th>
                      <th>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="tooltip-auto-width" id="tooltip-bottom">
                                <span>New Detections Since Last Acknowledgement</span>
                              </Tooltip>
                            }
                          >
                            
                              <span>New Detections</span>
                         </OverlayTrigger>
                        </th>
                      {/* <th>Alerted</th> */}
                      <th>Severity</th>
                      <th className="text-right">Last Execution Time</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isOneStep ? (detectionData?.filter((item) => item?._source?.steps_triggred.length > 0).length > 0 && !detectionDataError?.isError ? detectionData.map((item, i) => (
                      (isOneStep && item?._source?.steps_triggred.length > 0) &&
                      <tr>
                        <td>{item._source.hunt_name}</td>
                        {/* <td>{item.steps_triggred}</td> */}
                        <td>
                          <div className="d-flex flex-row gap-1">
                            {calculateSteps(
                              item._source.total_steps,
                              item._source.steps_triggred
                            ).map((step, index) =>
                              step ? (
                                <div
                                  className="alert alert-danger"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              ) : (
                                <div
                                  className="alert alert-success"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              )
                            )}
                          </div>
                        </td>
                        {/* <td>
                  {() => {
                    for (let i = 1; i <= item._source.total_steps; i++) {
                      item._source.steps_triggred &&
                        item._source.steps_triggred.findIndex(x => x == i) >= 0 ? <div>111</div> : <div>000</div>
                    }
                  }}
                </td> */}
                        <td className="font-monospace">{item._source.new_detections}</td>
                        <td>{item._source.alerted}</td>
                        {/* <td>{item._source.detection_severity}</td> */}
                        <td className="align-middle">
                          {item._source.detection_severity === "high" && (
                            <Badge bg="danger" className="p-2">High</Badge>
                          )}
                          {item._source.detection_severity === "medium" && (
                            <Badge bg="warning" className="p-2">Medium</Badge>
                          )}
                          {item._source.detection_severity === "low" && (
                            <Badge bg="success" className="p-2">Low</Badge>
                          )}
                        </td>
                        <td className="font-monospace text-right">
                          {/* {item?._source.timestamp} */}
                          {item?._source.timestamp ? moment(item?._source.timestamp).format('DD-MM-YYYY HH:mm:ss') : item?._source.timestamp}
                        </td>
                        <td className="text-center">
                          {/* <Link
                      // to={`/hunter/detections/${item._source.hunt_id}`}
                      onClick={() => handleOpenModal(item, true)}
                    >
                      View
                    </Link> */}


                          <Link
                            to={`/hunter/detection/${item._source.hunt_id}`}
                          // onClick={() => handleOpenModal(item, true)}
                          >
                            <TableViewMore Icon={ArrowRight} dataToggle={"Details"} dataPlacement={"bottom"} />
                          </Link>
                        </td>
                      </tr>
                    )) : detectionDataError.isError ? (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} errorData={detectionDataError} />
                      </div>
                    ) : (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} />
                      </div>
                    )) : isAlerted ? (detectionData?.filter((item) => item._source.detection_severity?.toLowerCase() === 'high').length > 0 && !detectionDataError?.isError ? detectionData.map((item, i) => (
                      (isAlerted && item._source.detection_severity?.toLowerCase() === 'high') &&
                      <tr>
                        <td>{item._source.hunt_name}</td>
                        {/* <td>{item.steps_triggred}</td> */}
                        <td>
                          <div className="d-flex flex-row gap-1">
                            {calculateSteps(
                              item._source.total_steps,
                              item._source.steps_triggred
                            ).map((step, index) =>
                              step ? (
                                <div
                                  className="alert alert-danger"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              ) : (
                                <div
                                  className="alert alert-success"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              )
                            )}
                          </div>
                        </td>
                        {/* <td>
                  {() => {
                    for (let i = 1; i <= item._source.total_steps; i++) {
                      item._source.steps_triggred &&
                        item._source.steps_triggred.findIndex(x => x == i) >= 0 ? <div>111</div> : <div>000</div>
                    }
                  }}
                </td> */}
                        <td className="font-monospace">{item._source.new_detections}</td>
                        {/* <td>{item._source.alerted}</td> */}
                        {/* <td>{item._source.detection_severity}</td> */}
                        <td className="align-middle">
                          {item._source.detection_severity === "high" && (
                            <Badge bg="danger" className="p-2">High</Badge>
                          )}
                          {item._source.detection_severity === "medium" && (
                            <Badge bg="warning" className="p-2">Medium</Badge>
                          )}
                          {item._source.detection_severity === "low" && (
                            <Badge bg="success" className="p-2">Low</Badge>
                          )}
                        </td>
                        <td className="font-monospace text-right">
                          {/* {item?._source.timestamp} */}
                          {item?._source.timestamp ? moment(item?._source.timestamp).format('DD-MM-YYYY HH:mm:ss') : item?._source.timestamp}
                        </td>
                        <td className="text-center">
                          {/* <Link
                      // to={`/hunter/detections/${item._source.hunt_id}`}
                      onClick={() => handleOpenModal(item, true)}
                    >
                      View
                    </Link> */}

                          <Link
                            to={`/hunter/detection/${item._source.hunt_id}`}
                          // onClick={() => handleOpenModal(item, true)}
                          >
                            <TableViewMore Icon={ArrowRight} dataToggle={"Details"} dataPlacement={"bottom"} />
                          </Link>
                        </td>
                      </tr>
                    )) : detectionDataError.isError ? (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} errorData={detectionDataError} />
                      </div>
                    ) : (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} />
                      </div>
                    )) : (detectionData?.length > 0 && !detectionDataError?.isError ? detectionData?.map((item, i) => (
                      <tr>
                        <td>{item?._source.hunt_name}</td>
                        {/* <td>{item.steps_triggred}</td> */}
                        <td>
                          <div className="d-flex flex-row gap-1">
                            {calculateSteps(
                              item._source.total_steps,
                              item._source.steps_triggred
                            ).map((step, index) =>
                              step ? (
                                <div
                                  className="alert alert-danger"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              ) : (
                                <div
                                  className="alert alert-success"
                                  style={styles.huntStep}
                                >
                                  {index + 1}
                                </div>
                              )
                            )}
                          </div>
                        </td>
                        {/* <td>
                  {() => {
                    for (let i = 1; i <= item._source.total_steps; i++) {
                      item._source.steps_triggred &&
                        item._source.steps_triggred.findIndex(x => x == i) >= 0 ? <div>111</div> : <div>000</div>
                    }
                  }}
                </td> */}
                        <td className="font-monospace">{item._source.new_detections}</td>
                        {/* <td>{item._source.alerted}</td> */}
                        {/* <td>{item._source.detection_severity}</td> */}
                        <td className="align-middle">
                          {item._source.detection_severity === "high" && (
                            <Badge bg="danger" className="p-2">High</Badge>
                          )}
                          {item._source.detection_severity === "medium" && (
                            <Badge bg="warning" className="p-2">Medium</Badge>
                          )}
                          {item._source.detection_severity === "low" && (
                            <Badge bg="success" className="p-2">Low</Badge>
                          )}
                        </td>
                        <td className="font-monospace text-right">
                          {/* {item?._source.hunt_started} */}
                          {item?._source.timestamp && moment(item?._source.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                        </td>
                        <td className="text-center">
                          {/* <Link
                      // to={`/hunter/detections/${item._source.hunt_id}`}
                      onClick={() => handleOpenModal(item, true)}
                    >
                      View
                    </Link> */}

                          <Link
                            to={`/hunter/detection/${item._source.hunt_id}`}
                          // onClick={() => handleOpenModal(item, true)}
                          >
                            <TableViewMore Icon={ArrowRight} dataToggle={"Details"} dataPlacement={"bottom"} />
                          </Link>

                          &nbsp;&nbsp;
                          <Link onClick={(e) => handleStatusButton(item._source.hunt_id)}>
                            <TableViewMore Icon={Desktop} dataToggle={"View Status"} dataPlacement={"bottom"} />
                          </Link>
                        </td>




                      </tr>
                    )) : detectionDataError.isError ? (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} errorData={detectionDataError} />
                      </div>
                    ) : (
                      <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                        <NoDataFound errorText={"No data found"} />
                      </div>
                    ))}
                  </tbody>
                </Table>
              }
            </>
          )}
        </div>
      </div>

      {alertCasePopup && (
        <AlertBox setAlertPopup={setAlertCasePopup} alertPopup={alertCasePopup} message={"Case created successfully"} bgColor={"lightGreen"} />
      )}

      {show && (
        <CustomModal
          title={"Detection details"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize="fullscreen"
          noBodyPadding
        ></CustomModal>
      )}

      {isStatus && (
        <CustomModal
          title={"Hunt Execution Status"}
          ShowOrHide={isStatus}
          bodyData={renderModalStatus}
          handleClose={handleStatusClose}
          footer={false}
          modalSize="lg"
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
          noBodyPadding
        ></CustomModal>
      )}

      {caseshow && (
        <EditModal
          setShow={setCaseShow}
          show={caseshow}
          autoHeight={false}
          caseidEdit={case_id}
          setCase_ID={setCase_ID}
        />
      )
      }
    </>
  );
};

export default Detections;