import React, { useCallback, useMemo, useState } from "react";
import styles from "./Activities.styles";
import {
  Form,
  Tab,
  Tabs,
  Table,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import ActiveDirectory from "./ActiveDirectory";
import OfficeMerge from "./Office365/OfficeMerge";

import SearchHunter from "../../../components/Search/SearchHunter";
import EDRMerge from "./EDR/EDRMerge";
import { useLocation } from 'react-router-dom';
import CYUnifiedLogs from "./AllLogs";
import moment from 'moment';

const UnifiedCykit = ({ selectedDateFilter }) => {
  const [globalSearch, setGlobalSearch] = useState("");
  const [init, setInit] = useState(true);
  const [dateFilter, setDateFilter] = useState();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("process");

  const [activeMainTab, setActiveMainTab] = useState(localStorage.getItem("activeTab.hunter") ? localStorage.getItem("activeTab.hunter") : "EDR")
  const searchParams = location.state?.search;
  const searchDate = location.state?.dateFilter;
  const [searchQuery, setSearchQuery] = useState('');

  const active = location.state?.data;
  useMemo(() => {
    if (active !== undefined) {
      setActiveTab(active)
    }
    if (searchParams !== undefined) {



      let start = moment(searchDate).subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
      let end = moment(searchDate).add(20, 'minutes').format("yyyy-MM-DDTHH:mm");
      let dateTemp = {
        from: start,
        to: end,
        f: start,
        e: end,
        range: 60,
      };
      setInit(true)
      setGlobalSearch(searchParams)
      setSearchQuery(searchParams)
      setDateFilter(dateTemp)

      console.log("searchParams", searchParams)
    }


  }, [])
  const handleSearchQuery = (val, e, action) => {

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setGlobalSearch(val);
      setInit(false)
    }
  };

  function handleTabSelect(key) {
    localStorage.setItem("activeTab.hunter", key)

    if (localStorage.getItem("activeTab.hunter") === "AD") {
      setActiveMainTab("AD")
    }
    else if (localStorage.getItem("activeTab.hunter") === "o365") {
      setActiveMainTab("o365")
    }
    else if (localStorage.getItem("activeTab.hunter") === "ALL") {
      setActiveMainTab("ALL")
    }
    else {
      setActiveMainTab("EDR")
    }
  }
  const fileBeatActive = localStorage.getItem("HUNTER_CYKIT_FILEBEAT_ENABLED",false)
  return (
    <>
      <div
        className="col-12 p-4 position-relative rounded-3 w-100"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
          style={styles.filterWrap}
        >


        </div>
        {/* <div className="d-flex justify-content-center position-absolute col-6"> */}
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
          style={styles.filterWrap}
        >
          <SearchHunter changeFunction={handleSearchQuery} setSearchQuery={setSearchQuery} searchQuery={searchQuery} />

          {/* <MagnifyingGlass
              style={{ fontSize: "24px" }}
              onClick={() => handleCustom(true)}
              ref={ref}
              type="button"
            /> */}
        </div>
        <div className="detections col-12">
          <Tabs
            defaultActiveKey="EDR"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={activeMainTab}
            onSelect={handleTabSelect}
          // onSelect={(e) => setActiveTab(e)}
          >


            <Tab
              eventKey="EDR"
              // title="Process"
              title={
                <React.Fragment>
                  EDR
                </React.Fragment>
              }
            >
              <EDRMerge globalSearch={globalSearch} init={init} dateFilter={dateFilter} setDateFilter={setDateFilter} activeTab={activeTab} setActiveTab={setActiveTab} />
            </Tab>

            <Tab
              eventKey="AD"
              // title="Process"
              title={
                <React.Fragment>
                  Windows Events ( AD, Security , File Share )
                </React.Fragment>
              }
            >
              <ActiveDirectory globalSearch={globalSearch} init={init} dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </Tab>
            <Tab
              eventKey="o365"
              // title="Process"
              title={
                <React.Fragment>
                  Office 365
                </React.Fragment>
              }
            >
              <OfficeMerge globalSearch={globalSearch} init={init} dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </Tab>
            {fileBeatActive&&<Tab
              eventKey="ALL"
              // title="Process"
              title={
                <React.Fragment>
                  Filebeat
                </React.Fragment>
              }
            >
              <CYUnifiedLogs globalSearch={globalSearch} init={init} dateFilter={dateFilter} setDateFilter={setDateFilter} activeTab={activeTab} setActiveTab={setActiveTab} />
            </Tab>}
          </Tabs>
        </div>

      </div>
    </>
  );
};

export default UnifiedCykit;