import React from 'react'
import { PDFDownloadLink, Document, Page, View, Text, Image, } from "@react-pdf/renderer";
import { Badge, Button, Table } from 'react-bootstrap';
import { Info } from 'phosphor-react';
import { getDateFromTimestamp, getTimeFromTimestamp, isJSONObject, rawHTML } from '../../../Utils/Utils';
import Header from '../Header';


const CustomText = ({ children }) => (
    <Text
        renderLine={(text, styleProps) => (
            <Text key={`${text}-${styleProps.lineIndex}`} {...styleProps}>
                {text}
                {'\n'} {/* Add line break here */}
            </Text>
        )}
    >
        {children}
    </Text>
);


const styles = {
    page: { padding: "20px" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { width: '25%', fontSize: "9px", padding: 5 },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '25%', fontSize: "10px" },
    tableHeader: { width: '25%', backgroundColor: '#eaeaea', fontSize: "9px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    imageDiv: { justifyContent: 'center', },
    setBorder: { border: "1px solid black" },
    image: {
        width: '80%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "14px", fontWeight: "600", padding: "5px" }
};


const removeHtmlChars = (input) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(input, 'text/html').body.textContent;
    return decodedString;

}

const brakeLines = (val) => {

    const perRowString = 10
    const stringLength = val?.length;
    const rows = Math.ceil(stringLength / perRowString)


    const data = [];
    for (let i = 0; i < rows; i++) {
        data.push(<Text>{val.slice(i * perRowString, (i + 1) * perRowString)}</Text>);
    }
    return data;

}
const IncidentDownload = ({ tableHeader, indicatorsData, groupedInvestigationData }) => {

    const MyDocument = () => (
        <Document>
            <Page style={{ padding: "20px" }}>
                <Header />

                {groupedInvestigationData?.map((item) => (
                    <View>
                        <View>
                            <Text style={styles.title}>About the Incident</Text>
                        </View>
                        {item?._source?.grp_id &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text className="modal-label col-4">Incident ID</Text>
                                </View>

                                <View style={styles.column2}>
                                    <Text className="modal-text">{item?._source?.grp_id}</Text>
                                </View>
                            </View>
                        }
                        {/* {item?._source?.inv_id &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text className="modal-label col-4">Investigation ID</Text>
                                </View>

                                <View style={styles.column2}>
                                    <Text className="modal-text">{item?._source?.inv_id}</Text>
                                </View>
                            </View>
                        } */}
                        {item?._source?.detection_name &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text className="modal-label col-4">Incident Name</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text className="modal-text">{item?._source?.conclusions["Alert Name"]}</Text>
                                </View>
                            </View>
                        }

                        <View style={styles.container}>
                            <View style={styles.column1}>
                                <Text>Date</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{getDateFromTimestamp(item?._source?.detection_timestamp)}</Text>
                            </View>
                        </View>
                        <View style={styles.container}>
                            <View style={styles.column1}>
                                <Text>Time</Text>
                            </View>
                            <View style={styles.column2}>
                                <Text>{getTimeFromTimestamp(item?._source?.detection_timestamp)}</Text>
                            </View>
                        </View>

                        {item?._source?.detailed_description &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Incident Summary</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{item?._source?.detailed_description}</Text>
                                </View>
                            </View>
                        }

                        {item?._source?.threat_classification &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Classification</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{item?._source?.threat_classification}</Text>
                                </View>
                            </View>
                        }
                        {item?._source?.detection_severity &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Severity</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{item?._source?.detection_severity}</Text>
                                </View>
                            </View>
                        }

                        {groupedInvestigationData[0]?._source?.detection_status &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Status</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{groupedInvestigationData[0]?._source?.detection_status}</Text>
                                </View>
                            </View>
                        }

                        {groupedInvestigationData[0]?._source?.comment &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Closure note</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{groupedInvestigationData[0]?._source?.comment}</Text>
                                </View>
                            </View>
                        }

                        {item?._source?.detection_severity_value &&
                            <View style={styles.container}>
                                <View style={styles.column1}>
                                    <Text>Threat Score</Text>
                                </View>
                                <View style={styles.column2}>
                                    <Text>{item?._source?.detection_severity_value}</Text>
                                </View>
                            </View>
                        }

                        <View>
                            {item?._source?.tag &&
                                <View style={styles.container}>
                                    <View style={styles.column1}>
                                        <Text>Tags</Text>
                                    </View>
                                    <View style={styles.column2}>
                                        {item?._source?.tag?.map((item) => (
                                            <Text>{item}</Text>
                                        ))}
                                    </View>
                                </View>
                            }
                        </View>

                        {/* {item?._source?.same_group &&
                            <View className="modal-row-item">
                                <Text className="modal-label col-3">Same Group</Text>
                                {typeof item?._source?.same_group === "string" ? (
                                    <Text>{item?._source?.same_group}</Text>
                                ) : (
                                    <View className="d-flex flex-wrap gap-2">
                                        {item?._source?.same_group?.map((item) => (
                                            <Badge bg="secondary" className="p-2">{item}</Badge>
                                        ))
                                        }
                                    </View>
                                )}
                            </View>
                        } */}

                        {item?._source?.common_observables && (
                            <View style={{ marginTop: "20px" }}>
                                <View className='col-12 mt-3'>
                                    <Text style={styles.title}>Detection Observables</Text>
                                </View>
                                {/* Common Observables */}
                                <View>
                                    {Object.entries(item?._source?.common_observables).map(([key, value]) => (
                                        // <div className="col-12 text-break">
                                        <View style={styles.container}>
                                            <View style={styles.column1}>
                                                <Text>{key}</Text>
                                            </View>
                                            <View style={styles.column2}>
                                                <Text>{isJSONObject(value) ? Object.values(value)?.[0] : value}</Text>
                                            </View>

                                            {/* <span className="modal-text" style={{ color: "red" }}>{value}</span> */}

                                        </View>
                                        // </div>
                                    ))}
                                </View>
                            </View>
                        )}


                        {item?._source?.conclusions && (
                            <View className='col-12 mt-3' style={{ marginTop: "20px" }}>
                                <Text style={styles.title}>Conclusion</Text>
                                {Object.entries(item?._source?.conclusions).map(([key, value]) => (
                                    // <div class="col-12 text-break">
                                    <>
                                        {(key != "Threat_Score" && key != "Severity level") &&
                                            <View style={styles.container}>
                                                <View style={styles.column1}>
                                                    <Text>{key}</Text>
                                                </View>
                                                <View style={styles.column2}>
                                                    {/* <Text dangerouslySetInnerHTML={{ __html: value }} /> */}
                                                    <Text>{removeHtmlChars(value)}</Text>
                                                </View>
                                            </View>
                                        }
                                    </>
                                    // </div>
                                ))}

                            </View>
                        )}
                        <View style={styles.table}>
                            <View style={styles.tableRow} >
                                <Text style={styles.tableHeader}>Date & Time</Text>

                                {tableHeader.map((item, index) => (
                                    item.value != "detection_timestamp" &&
                                    item.value != "source_description" &&
                                    item.value != "hunt_detection_id" &&
                                    item.value != "playbook_id" &&
                                    item.value != "technique_name" &&
                                    // item.value != "detection_severity" &&
                                    item.value != "threat_score" &&
                                    item.value != "tactic_name" &&

                                    
                                    item.isSelected === true &&
                                    <Text style={styles.tableHeader}>{item.text}</Text>
                                ))}
                                {/* <Text style={styles.tableHeader}></Text> */}
                            </View>

                            {indicatorsData?.map((item, index) => (
                                <View style={styles.tableRow} key={item.key}>
                                    <Text style={styles.tableCell} className='font-monospace'>{getDateFromTimestamp(item._source?.detection_timestamp)} {getTimeFromTimestamp(item._source?.detection_timestamp)}</Text>

                                    {tableHeader?.map(
                                        (head) =>
                                            head.isSelected === true &&
                                            head.value != "detection_timestamp" &&
                                            head.value != "source_description" &&
                                            head.value != "hunt_detection_id" &&
                                            head.value != "playbook_id" &&
                                            head.value != "technique_name" &&
                                            head.value != "threat_score" &&
                                            head.value != "tactic_name" &&


                                            // head.value != "detection_severity" &&

                                            (
                                                <Text style={styles.tableCell} className='font-monospace'>
                                                    {brakeLines(item._source[head.value])}
                                                </Text>

                                            )
                                    )}

                                </View>
                            ))}

                        </View>

                        {/* {tableDatas.map((item) => (
          <View key={item.key}>
            <Text>
              {
                item[3].buckets[0][4].buckets[0][5].buckets[0][1].hits.hits[0]
                  ._source.attribute_timestamp
              }
            </Text>
            <Text>{item.key}</Text>
            <Text>{item[3].buckets[0].key}</Text> */}


                    </View>
                ))}

            </Page>
        </Document>
    );

    return (
        <PDFDownloadLink document={<MyDocument />} fileName={`investigate-incident-${groupedInvestigationData[0]?._source?.detection_timestamp}.pdf`}>
            {({ blob, url, loading, error }) =>
                <Button className='btn btn-light'>Download</Button>
            }
        </PDFDownloadLink>
    )
}

export default IncidentDownload