import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogueMasterCompliance,
  addToOrganizationCatalogueComp,
  refreshMasterCatalogueCompliance
} from "../../../Methods/RuleStudioMethods";
import LoaderList from "../../../components/Loader/LoaderList";
import CustomModal from "../../../components/CustomModal/CustomModal";

import styles from "./Compliance.styles";
import MasterCard from "../../../components/RuleStudio/MasterCard";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import SearchTi from "../../../components/Search/SearchTi";

import { Button, Form, input } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MasterModal({
  show,
  handleCloseModal,
  catalogueData,
  commonData,
  setCommonData,
  toggleViewAll,
  setToggleViewAll,
  catalogue


  // setSearchQueryMaster,
}) {
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false)
  const [masterFilterAll, setMasterFilterAll] = useState(false);



  const AddToCatalogue = (id) => {
    setAdd(true)
    if (window.confirm("Add the item?")) {
      dispatch(addToOrganizationCatalogueComp(id));
    }
  };




  const [searchQueryMaster, setSearchQueryMaster] = useState("");
  const [IfNoData, setIfNoData] = useState(false);

  const [filteredDataMaster, setFilteredDataMaster] = useState([]);

  const listCatalogueMasterCompliance = useSelector((state) => state.listCatalogueMasterCompliance);
  const { master, loading: masterLoading } = listCatalogueMasterCompliance;

  const addToCatalogueRsComp = useSelector((state) => state.addToCatalogueRsComp);
  const { success: catalogueAddSuccess, loading } = addToCatalogueRsComp;


  const [refresh, setRefresh] = useState(false)




  useEffect(() => {


    dispatch(getCatalogueMasterCompliance());
    ;
    filterDataMaster(searchQueryMaster);

  }, []);






  const handleSearchQueryMaster = (val, e, action) => {
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryMaster(val);

      filterDataMaster(val);
    }
  };


  const filterDataMaster = (query) => {
    if (query===""||query!="") {
      const filtered = master?.filter((item) => {
        return item?.com_id?.toLowerCase().includes(query?.toLowerCase()) || item?.com_name?.toLowerCase().includes(query.toLowerCase()) || item?.com_description?.toLowerCase().includes(query.toLowerCase());
      });

      setFilteredDataMaster(filtered);
    }

  };

  let masterData =
    filteredDataMaster.length === 0 && searchQueryMaster === '' ? master : filteredDataMaster;

  // new one
  useEffect(() => {

    setMasterData()

  }, [toggleViewAll, masterData, show, commonData])

  const setMasterData = () => {

    if (toggleViewAll === false) {
      const array = masterData?.filter(item1 => !catalogue?.find(item2 => item1?.com_id === item2?.com_id));
      setCommonData(array)
    }

    if (toggleViewAll === true) {
      setCommonData(masterData)
    }

  }

  const handleToggleChange = (e) => {
    toggleViewAll === false ? setToggleViewAll(true) : setToggleViewAll(false)

    IfNoData === true ? setIfNoData(true) : setIfNoData(false)
    masterFilterAll === false ? setMasterFilterAll(true) : setMasterFilterAll(false)

  }





  const handleRefresh = (status = false) => {

    setRefresh(true)

    if (refresh === true || status === true) {
      refreshMasterCatalogueCompliance().then((res) => {

        if (res.status === 200) {
          setRefresh(false)
        }
      })
    }


  };






  function renderModal() {

    return (

      <div className="px-3">
        <div className="mb-3 py-2 sticky-top">
          <div className="col-12 d-flex flex-row">
            <div className="col-4" style={{ marginRight: "auto" }}>
              {/* <h5>Master Catalogue</h5> */}
              <SearchTi changeFunction={handleSearchQueryMaster} module='rulestudio' />


            </div >


            <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 mx-2">
              <span> Total Count -</span>
              <h6 className="mb-0">{commonData && commonData.length}</h6>
            </div>
            <div className="col-2 d-flex  align-items-center">
              <Form>
                <Form.Check
                  type="switch"
                  id="toggle_view_switch"
                  label="View All"
                  defaultChecked={toggleViewAll}
                  onChange={handleToggleChange}
                />
              </Form>
            </div>
            <div className="">
              <Link style={{ color: "white" }} onClick={() => handleRefresh(true)}>
                <button className="btn btn-primary">Refresh</button>
              </Link>
            </div>
          </div >
        </div>



        {masterLoading === true || refresh === true ? (
          <LoaderList />
        ) : (





          <div className="col-12" style={styles.section1}>

            {/* {commonData?.length === 0 ||IfNoData === false ?  <NoDataFound /> : ""},
          {commonData?.length != 0 ? setIfNoData === true :"" } */}

            {commonData && commonData?.length === 0 || IfNoData === true || masterData?.length === 0 ? <NoDataFound /> : ""}
            {commonData && commonData?.length != 0 ? setIfNoData === false : ""}




            <div className="d-flex flex-column gap-3">
              {commonData?.map((item, index) => {
                const filter = catalogue?.some(
                  (data) => data?.com_id?.toLowerCase() === item?.com_id?.toLowerCase()
                )






                return (
                  <MasterCard
                    item={item}
                    filter={filter}
                    AddToCatalogue={AddToCatalogue}
                    loading={loading}
                    index={index}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  // const AddToCatalogue = (id) => {
  //   if (window.confirm("Add the item?")) {
  //     //   dispatch(addToOrganizationCatalogueHunt(id));
  //   }
  // };

  return (
    <>
      {show === true && (
        <CustomModal
          title={" Master Catalogue"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding={true}
          modalSize={'lg'}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}{" "}
    </>
  );
}

