const styles = {
  section1: {
    width: "70%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
  },
  section2: {
    width: "30%",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
  },
};

export default styles;
