import moment from "moment";
export const getVulnerabilitiesExQuery = (
  count,
  sort,
  page,
  severity,
  search,
  timeFrame
) => {
  var data = {
    from: page,

    size: count,

    query: {
      bool: {
        must: [],

        filter: [
          {
            match_phrase: {
              feed_name:
                "Activebytes Innovations - Threat Intelligence Services",
            },
          },

          { match_phrase: { event_category: "Exploit" } },
        ],

        should: [],

        must_not: [],
      },
    },
  };

  if (severity?.length > 0) {
    var severityquery = {
      wildcard: {
        severity: {
          value: severity,
          case_insensitive: true,
        },
      },
    };

    data.query.bool.filter.push(severityquery);
  }

  if (search?.length > 0) {
    var searchquery = [
      {
        "wildcard": {
          "event_name": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
      {
        "wildcard": {
          "event_description": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
    ]

    // var searchquery = {
    //   query_string: {
    //     query: `*${search}*`,

    //     fields: ["*"],
    //   },
    // };

    data.query.bool.should.push(...searchquery);
    data.query.bool.minimum_should_match = 1
  }
  var sortQ = [
    {
      attribute_timestamp: {
        order: sort,
      },
    },
  ];
  data.sort = sortQ;

  if (timeFrame?.length > 0) {
    var range = {
      range: {
        attribute_timestamp: {
          gte: timeFrame,
        },
      },
    };
    data.query.bool.filter.push(range);
  }

  return data;
};

export const getVulnerabilitiesQuery = (
  count,
  sort,
  page,
  severity,
  search,
  timeFrame
) => {
  var data = {
    from: page,

    size: count,
    track_total_hits: true,
    query: {
      bool: {
        must: [],

        filter: [
          {
            exists: {
              field: "cve.metrics.cvssMetricV31.cvssData.baseSeverity",
            },
          }
        ],

        should: [],

        must_not: [],
      },
    },
  };

  if (severity?.length > 0) {
    const severityList = ["LOW", "HIGH", "MEDIUM", "CRITICAL"]
    const mustNotList = severityList.filter(item => item.toLowerCase() !== severity.toLowerCase())
    var severityquery = {
      wildcard: {
        "cve.metrics.cvssMetricV31.cvssData.baseSeverity": {
          value: severity,
          case_insensitive: true,
        },
      },
    };

    data.query.bool.filter.push(severityquery);
    mustNotList && mustNotList.map(severity => {
      const mustNotQuery = {
        "wildcard": {
          "cve.metrics.cvssMetricV31.cvssData.baseSeverity": {
            "value": severity,
            "case_insensitive": true
          }
        }
      }
      data.query.bool.must_not.push(mustNotQuery);
    }
    )
  }

  if (search?.length > 0) {
    var searchquery = [
      {
        "wildcard": {
          "cve.id": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
      {
        "wildcard": {
          "cve.descriptions.value": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
    ]

    // var searchquery = {
    //   query_string: {
    //     query: `*${search}*`,

    //     fields: ["*"],
    //   },
    // };

    data.query.bool.should.push(...searchquery);
    data.query.bool.minimum_should_match = 1
  }
  var sortQ = [
    {
      "cve.published": {
        order: sort,
      },
    },
  ];
  data.sort = sortQ;


  var range = {
    range: {
      "cve.published": {
        gte: localStorage.getItem("gte") ? localStorage.getItem("gte") : 'now-7d',
        lte: localStorage.getItem("lte") ? localStorage.getItem("lte") : 'now'
      },
    },
  };
  data.query.bool.filter.push(range);

  var must_not = {
    match: {
      "cve.vulnStatus": "Deferred",
    },
  };
  data.query.bool.must_not.push(must_not);

  return data;
};

// export const getSecurityEventsQuery = (count, sort, page) => {
//   return {
//     from: page,
//     size: count,
//     query: {
//       bool: {
//         must: [],
//         filter: [
//           { match_all: {} },
//           {
//             match_phrase: {
//               feed_name:
//                 "Activebytes Innovations - Threat Intelligence Services",
//             },
//           },
//           { match_phrase: { event_category: "Event" } },
//           {
//             range: {
//               attribute_timestamp: {
//                 gte: localStorage.getItem("gte"),
//                 lte: localStorage.getItem("lte"),
//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//         should: [],
//         must_not: [],
//       },
//     },
//   };
// };
export const getSecurityEventsQuery = (
  count,
  sort,
  page,
  severity,
  search,
  timeFrame
) => {
  var data = {
    from: page,

    size: count,

    query: {
      bool: {
        must: [],

        filter: [
          { match_all: {} },

          {
            match_phrase: {
              feed_name:
                "Activebytes Innovations - Threat Intelligence Services",
            },
          },

          { match_phrase: { event_category: "Event" } },
        ],
        should: [],
        must_not: [],
      },
    },
  };

  if (severity?.length > 0) {
    var severityquery = {
      wildcard: {
        severity: {
          value: severity,
          case_insensitive: true,
        },
      },
    };

    data.query.bool.filter.push(severityquery);
  }

  if (search?.length > 0) {
    var searchquery = [
      {
        "wildcard": {
          "event_name": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
      {
        "wildcard": {
          "event_description": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
    ]

    data.query.bool.should.push(...searchquery);
    data.query.bool.minimum_should_match = 1
  }
  var sortQ = [
    {
      attribute_timestamp: {
        order: sort,
      },
    },
  ];
  data.sort = sortQ;
  if (timeFrame?.length > 0) {
    var range = {
      range: {
        attribute_timestamp: {
          gte: timeFrame,
        },
      },
    };
    data.query.bool.filter.push(range);
  }
  return data;
};

export const getHeadlinesQuery = (count, sort, page, search, timeFrame) => {
  var data = {
    from: page,
    size: count,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "CTI",
            },
          },
          { match_phrase: { event_category: "News" } },
        ],
        should: [],
        must_not: [],
      },
    },
    sort: [{ attribute_timestamp: { order: sort } }],
  };

  if (search?.length > 0) {
    var searchquery = {
      wildcard: {
        event_name: {
          value: `*${search}*`,
          case_insensitive: true,
        },
      },
    };

    data.query.bool.filter.push(searchquery);
  }
  if (timeFrame?.length > 0) {
    var range = {
      range: {
        attribute_timestamp: {
          gte: timeFrame,
        },
      },
    };
    data.query.bool.filter.push(range);
  }
  return data;
};

export const getCommunityFeedQuery = (timeFrame,
  severity,
  search,
  searchType,
  useThirdParty) => {

  var data = {
    size: 0,

    query: {
      bool: {
        must: [],

        filter: [
          {
            "term": {
              "first": true
            }
          },

          {
            range: {
              attribute_timestamp: {
                gte: localStorage.getItem("gte"),
                lte: localStorage.getItem("lte"),
                format: "strict_date_optional_time",
              },
            },
          },
        ],

        should: [],

        must_not: [
          {
            match_phrase: {
              feed_name:
                "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          {
            match_phrase: {
              feed_name: "CTI",
            },
          },
          {
            match: {
              "source_name": "Microsoft 365 Defender Incidents"
            }
          }
          // {
          //   match_phrase: {
          //     feed_name: "OTX",
          //   },
          // },
        ],
      },
    },

    aggs: {
      2: {
        terms: {
          field: useThirdParty ? "source_name" : "feed_name",

          order: {
            _count: "desc",
          },

          size: 5,
        },
      },
    },
  }
  if (severity?.length > 0) {
    var severityquery = {
      wildcard: {
        severity: {
          value: severity,
          case_insensitive: true,
        },
      },
    };
    data.query.bool.filter.push(severityquery);
  }
  if (searchType === "name" || searchType === undefined) {
    var distinct = {
      match_phrase: {
        first: true
      }
    }
    data.query.bool.must.push(distinct);
  }
  if (search?.length > 0) {
    var searchquery = [
      {
        "wildcard": {
          "event_name": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
      {
        "wildcard": {
          "event_description": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
      {
        "wildcard": {
          "attribute_value": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      },
    ]

    // var searchquery = {
    //   query_string: {
    //     query: `*${search}*`,

    //     fields: ["*"],
    //   },
    // };

    data.query.bool.should.push(...searchquery);
    data.query.bool.minimum_should_match = 1
  }
  return data
};

export const getAttributeFeedQuery = () => {
  return {
    aggs: {
      2: {
        terms: {
          field: "attribute_category",
          order: {
            _count: "desc",
          },
          size: 5,
        },
      },
    },
    size: 0,
    stored_fields: ["*"],
    script_fields: {
      "Event drilldown": {
        script: {
          source: "doc['event_id'].value",
          lang: "painless",
        },
      },
    },
    docvalue_fields: [
      {
        field: "attribute_timestamp",
        format: "date_time",
      },
    ],
    _source: {
      excludes: [],
    },
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            range: {
              attribute_timestamp: {
                gte: localStorage.getItem("gte"),
                lte: localStorage.getItem("lte"),
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const getTopCharts = () => {
  var data = {
    size: 0,
    aggs: {
      2: {
        terms: { field: "event_name", order: { _key: "desc" }, size: 6 },
        aggs: {
          3: {
            terms: { field: "feed_name", order: { _key: "desc" }, size: 5 },
            aggs: {
              4: {
                terms: {
                  field: "event_id",
                  order: { _key: "desc" },
                  size: 5,
                },
                aggs: {
                  5: {
                    terms: {
                      field: "severity",
                      order: { _key: "desc" },
                      size: 5,
                    },
                    aggs: {
                      1: {
                        top_hits: {
                          docvalue_fields: [
                            {
                              field: "attribute_timestamp",
                              format: "date_time",
                            },
                          ],
                          _source: "attribute_timestamp",
                          size: 1,
                          sort: [{ attribute_timestamp: { order: "desc" } }],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          {
            range: {
              attribute_timestamp: {
                gte: localStorage.getItem("gte"),
                lte: localStorage.getItem("lte"),
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              feed_name:
                "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { feed_name: "CTI" } },
        ],
      },
    },
  };
  return data;
};

// details page query
// community

export const indicatorsTableQuery = (id) => {
  return {
    size: 1500,
    query: {
      bool: {
        must: [],
        filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      2: {
        terms: {
          field: "attribute_type",
          order: {
            _count: "desc",
          },
          size: 100,
        },
      },
    },
  };
};

export const communityEventsDetailQuery = (id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
        should: [],
        must_not: [],
      },
    },
  };
};

export const communityEventsListQuery = (
  page,
  size,
  sort,
  severity,
  search,
  timeFrame,
  searchType
) => {
  var data = {
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "OTX",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "CIRCL",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "THREATQ",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        filter: [],
        should: [],
        must_not: [
          {
            match: {
              "source_name": "Microsoft 365 Defender Incidents"
            }
          }
        ],
      },
    },
    from: page,
    size: size,
    track_total_hits: true,
  };
  if (severity?.length > 0) {
    var severityquery = {
      wildcard: {
        severity: {
          value: severity,
          case_insensitive: true,
        },
      },
    };
    data.query.bool.filter.push(severityquery);
  }
  if (searchType === "name" || searchType === undefined) {
    var distinct = {
      match_phrase: {
        first: true
      }
    }
    data.query.bool.must.push(distinct);
  }
  if (search?.length > 0) {
    if (searchType === "name" || searchType === undefined) {
      var searchquery = [
        {
          "wildcard": {
            "event_name": {
              "value": `*${search}*`,
              "case_insensitive": true
            }
          }
        },
        {
          "wildcard": {
            "event_description": {
              "value": `*${search}*`,
              "case_insensitive": true
            }
          }
        },
      ]
      data.query.bool.should.push(...searchquery);
      data.query.bool.minimum_should_match = 1
    }
    else {
      var searchquery = {
        "wildcard": {
          "attribute_value": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      }
      data.query.bool.must.push(searchquery);
      if (searchType === "ipv4") {
        var attribute = {
          "terms": {
            "attribute_type": ["domain", "IPv4"]
          }
        }
        data.query.bool.must.push(attribute);

      }
      else {
        var attribute = {
          "wildcard": {
            "attribute_type": {
              "value": `FileHash*`,
            }
          }
        }
        data.query.bool.must.push(attribute);


      }
    }

    // var searchquery = {
    //   query_string: {
    //     query: `*${search}*`,

    //     fields: ["*"],
    //   },
    // };


  }
  var sortQ = [
    {
      attribute_timestamp: {
        order: sort,
      },
    },
  ];
  data.sort = sortQ;
  if (timeFrame?.length > 0) {
    var range = {
      range: {
        attribute_timestamp: {
          // gte: timeFrame,
          gte: localStorage.getItem("gte"),
          lte: localStorage.getItem("lte"),
          format: "strict_date_optional_time",

        },
      },
    };
    data.query.bool.filter.push(range);
  }
  return data;
};


export const getIocQuery = (
  from,
  to,
  ioctype,
  size = 10000
) => {
  var data = {
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "OTX",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "CIRCL",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          feed_name: "THREATQ",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        filter: [],
        should: [],
        must_not: [
          {
            match: {
              "source_name": "Microsoft 365 Defender Incidents"
            }
          }
        ],
      },
    },
    size: size,
    track_total_hits: true,
  };
  if (ioctype !== undefined && ioctype.value !== "all") {
    var iocquery = {
      "term": {
        "attribute_type": ioctype.value
      }
    }


    data.query.bool.must.push(iocquery);
  }
  var sortQ = [
    {
      attribute_timestamp: {
        order: "desc",
      },
    },
  ];
  data.sort = sortQ;

  var range = {
    range: {
      "attribute_timestamp": {
        lte: moment(to).format("X"),
        gte: moment(from).format("X"),
      },
    },
  };
  data.query.bool.filter.push(range);

  return data;
};




export const scanEdrQuery = (from, to, iocvalue) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [],
        should: [],
      },
    },
    size: 10000,
    track_total_hits: true
  };

  var range = {
    range: {
      "@timestamp": {
        lte: moment(to).toISOString(),
        gte: moment(from).toISOString(),
      },
    },
  };
  data.query.bool.filter.push(range);
  if (iocvalue) {
    let ioclist = iocvalue.map(item => {
      return {
        "query_string": {
          "query": `*${item?._source?.attribute_value}*`,
        }
      }
    })
    data.query.bool.should.push(...ioclist)
    data.query.bool.minimum_should_match = 1
  }
  return data
}
export const scanAdQuery = (from, to, iocvalue) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [],
        should: [],
      },
    },
    size: 10000,
    track_total_hits: true
  };

  var range = {
    range: {
      "@timestamp": {
        lte: moment(to).toISOString(),
        gte: moment(from).toISOString(),
      },
    },
  };
  data.query.bool.filter.push(range);
  if (iocvalue) {
    let ioclist = iocvalue.map(item => {
      return {
        "query_string": {
          "query": `*${item?._source?.attribute_value}*`,
        }
      }
    })
    data.query.bool.should.push(...ioclist)
    data.query.bool.minimum_should_match = 1

  }
  return data
}
export const scanOfficeQuery = (from, to, iocvalue) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [],
        should: [],
      },
    },
    size: 10000,
    track_total_hits: true
  };

  var range = {
    range: {
      "@timestamp": {
        lte: moment(to).toISOString(),
        gte: moment(from).toISOString(),
      },
    },
  };
  data.query.bool.filter.push(range);
  if (iocvalue) {
    let ioclist = iocvalue.map(item => {
      return {
        "query_string": {
          "query": `*${item?._source?.attribute_value}*`,
        }
      }
    })
    data.query.bool.should.push(...ioclist)
    data.query.bool.minimum_should_match = 1

  }
  return data
}