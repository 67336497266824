import React, { useEffect } from "react";
import styles from "../Activebytes.styles";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import LoaderList from "../../../../components/Loader/LoaderList";
import {
  getDateFromTimestamp, getTimeFromTimestamp
} from "../../../../Utils/Utils";
import {
  getVulnerabilities
} from "../../../../Methods/TiMethods";
import { Calendar, CalendarBlank } from "phosphor-react";
import NoDataFound from "../../../../components/NoDataFount/NoDataFound";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableViewMore from "../.././../../components/ViewMoreBtn/TableViewMore";


export default function Vulnerabilities({
  vulnerPage,
  VulnerPerPage,
  handleOpenModal,
  vulnerabilitiesSearch,
  vulnerabilitiesFilter,
  setVulnerabilitiesCount,
  selectedDateFilter,
  setFilterData,
  setActiveTab,
  tabToggle,
  activeTab,
  timeoutLoader,
  vulData,
  VulTotalCount
}) {
  const dispatch = useDispatch();
  const VulnerabilitiesList = useSelector((state) => state.VulnerabilitiesList);
  const {
    vulnerabilities,
    vulCounts,
    loading: vulLoading,
  } = VulnerabilitiesList;



  if (VulTotalCount != undefined && VulTotalCount != 0) {
    setVulnerabilitiesCount(VulTotalCount)
  }

  // useEffect(() => {

  //     let sevFilter = setFilterData(selectedDateFilter);



  //   dispatch(
  //     getVulnerabilities(
  //       VulnerPerPage, 
  //       "desc", 
  //       (vulnerPage - 1) * VulnerPerPage, 
  //       vulnerabilitiesFilter,
  //        vulnerabilitiesSearch, 
  //        sevFilter));
  //      }, [vulnerabilitiesFilter, dispatch, vulnerPage, VulnerPerPage, selectedDateFilter])






  return (
    <div className="col-12" style={styles.section1}>
      {VulTotalCount === true || timeoutLoader === true ? (
        <LoaderList />
      ) : (
        vulData?.length === 0 ? <NoDataFound /> :
          vulData?.map((data) => {

            let sev = data?._source?.cve?.metrics?.cvssMetricV31 && data?._source?.cve?.metrics?.cvssMetricV31[0]?.cvssData?.baseSeverity

            return <div
              className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card ti-card"
              style={styles.tile}
            // onClick={() => handleOpenModal(data?._source, true, "vulnerabilities")}
            >
              <div className="d-flex flex-row">
                <div className="d-flex flex-row gap-2 align-items-center">
                  <span>CVE ID </span>
                  <h6 className="mb-0">{'#' + data?._source?.cve?.id}</h6>
                </div>



              </div>

              <div>{data?._source?.cve?.descriptions[0]?.value}</div>

              <div className="d-flex flex-row gap-3 my-2 align-items-center">
                {/* <div className="d-flex flex-row gap-2">
                  <span>Vendor -</span>
                  <div><strong>Oracle</strong></div>
                </div> */}
                {sev &&
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span>Severity</span>

                    {sev === "CRITICAL" && (
                      // <div className="p-2 m-0 alert alert-danger">Critical</div>
                      <Badge bg="danger" className="p-2">Critical</Badge>
                    )}
                    {sev === "HIGH" && (
                      // <div className="p-2 m-0 alert alert-danger">High</div>
                      <Badge bg="danger" className="p-2">High</Badge>
                    )}
                    {sev === "MEDIUM" && (
                      // <div className="p-2 m-0 alert alert-warning">Medium</div>
                      <Badge bg="warning" className="p-2">Medium</Badge>
                    )}
                    {sev == "LOW" && (
                      // <div className="p-2 m-0 alert alert-success">Low</div>
                      <Badge bg="success" className="p-2">Low</Badge>
                    )}
                  </div>
                }
                <div className="d-flex flex-row gap-2 align-items-center">
                  {/* <span>Published on - </span> */}
                  <Calendar size={18} />
                  <h6 className="m-0">{getDateFromTimestamp(data?._source?.cve?.published)}</h6>
                </div>
                <div className="ml-auto p-2">
                  <Link onClick={() => handleOpenModal(data?._source, true, "vulnerabilities")}>
                    <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                  </Link>
                  {/* <div>{_source.severity}</div> */}
                </div>
              </div>
            </div>
          })
      )
      }
    </div >
  );
}