const styles = {
  section1: {
    height: "calc(100vh - 6.5rem)",
    paddingRight: "1rem",
    overflow: "auto"
  },
  section2: {
    // width: "30%",
    height: "calc(100vh - 6.5rem)",
    paddingRight: "1rem",
    overflow: "auto",
  },
  section2Sm: {
    // width: "30%",
    height: "calc(100vh - 6rem)",
    paddingRight: "1rem",
    overflow: "auto",
  },
};

export default styles;
