import { filterGen, filterGenHunt } from "./InvestigationQuery";

export const getTotalHuntDetectedQuery = () => {
  return {
    size: 0,
    query: { bool: {} },
    aggs: {
      2: {
        terms: { field: "hunt_id", order: { _count: "desc" }, size: 10000 },
      },
    },
  };
};

export const getHuntStatusQuery = (hunt_id) => {
  return {
    "size": 100,
    "query": {
      "bool":
        { "filter": [{ "match_phrase": { "hunt_id": hunt_id } }] }
    }
  };
};

export const getTotalHuntAlertQuery = () => {
  return {
    size: 0,
    query: { bool: { filter: [{ match_phrase: { alerted: "yes" } }] } },
    aggs: {
      2: {
        terms: { field: "hunt_id", order: { _count: "desc" }, size: 10000 },
      },
    },
  };
};

export const getSeverityCountsQuery = () => {
  return {
    size: 0,
    query: { bool: { filter: [{ match_all: {} }] } },
    aggs: {
      2: {
        terms: {
          field: "detection_severity",
          order: { _count: "desc" },
          size: 10000,
        },
      },
    },
  };
};

export const getDetectionDataTableQuery = (count, order, page) => {
  return {
    from: page,
    size: 100,
    query: { bool: { filter: [{ match_all: {} }] } },
  };
};

export const getDetectionTimelineQuery = (id) => {
  return {
    size: 1000,
    query: {
      bool: {
        filter: [
          { match_phrase: { hunt_id: id } },
          {
            range: {
              detection_timestamp: {
                gte: "now-3000d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
  };
};

export const getExecutionQuery = (id) => {
  return { "size": 11, "query": { "bool": { "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "hunt_id": id } }], "should": [], "must_not": [] } } }
}

export const groupedDetectionDetailsQuery = (group_id, type) => {
  // return {
  //   size: 1000,
  //   query: { bool: { filter: [{ match_phrase: { group_id: group_id } }] } },
  // };

  if (type === "hunter") {
    return {
      size: 1000,
      query: { bool: { filter: [{ match_phrase: { group_id: group_id } }] } },
      "sort": [{ "detection_timestamp": { "order": "desc", "unmapped_type": "boolean" } }]
    };
  } else {
    return {
      size: 1000,
      query: { bool: { filter: [{ match_phrase: { grp_id: group_id } }] } },
      "sort": [{ "detection_timestamp": { "order": "desc", "unmapped_type": "boolean" } }]
    };
  }
};


export const getShowAllTimeQuery = (hunt_id) => {
  return {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [
          {
            terms: {
              playbook_id:
                hunt_id


            }
          }, {
            match_phrase: {
              investigation_status: "Investigated"
            }
          }
        ]
      }
    }
  }
};


export const getShowDateQuery = (hunt_id, filter, date, count, sort, page) => {
  let huntIdValue = Array.isArray(hunt_id) ? [...hunt_id] : [hunt_id];
  var data = {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [
          {
            terms: {
              playbook_id: huntIdValue
            }
          }, {
            match_phrase: {
              investigation_status: "Investigated"
            }
          }
        ],
        filter: []
      }
    }
  }
  return filterGenHunt(data, filter, date, sort);
};

export const getShowStatusQuery = (hunt_id, filter, date, count, sort, page) => {
  var data = {
    from: page,
    size: count,
    query: {
      bool: {
        must: [
          {
            terms: {
              playbook_id: [
                hunt_id
              ]
            }
          },
          {
            match_phrase: {
              investigation_status: "Investigated"
            }
          },
          {
            match_phrase: {
              detection_status: "closed"
            }
          },
          {
            range: {
              detection_timestamp: {
                gte: "now-3y",
                lte: "now",
                format: "strict_date_optional_time"
              }
            }
          }
        ]
      }
    }
  }
  return filterGen(data, filter, date)
};


export const getTotalCountQuery = (count, sizee) => {
  return {
    from: 0,
    size: sizee,
    query: {
      bool: {
        must: [{
          terms: {
            playbook_id:
              count
          }
        },
        {
          match_phrase: {
            investigation_status: "Investigated"
          }
        }
        ]
      },
    },
  };
};


export const getAckCountQuery = (count, gte, lte, sizee) => {
  console.log("acktime",lte,gte)
  let data = {
    from: 0,
    size: sizee,
    query: {
      bool: {
        must: [
          {
            terms: {
              playbook_id:
                count

            }
          },
          {
            match_phrase: {
              investigation_status: "Investigated"
            }
          }
        ],
        filter: [
          
        ]
      }
    },
    track_total_hits: true,
    sort: [
      {
        detection_timestamp: {
          order: "desc",
          unmapped_type: "boolean"
        }
      }
    ]
  }
  if (gte&&lte){
    data.query.bool.filter.push({
      range: {
        detection_timestamp: {
          gte: gte,
          lte: lte,
          format: "strict_date_optional_time"
        }
      }
    })
  }
  return data
}
