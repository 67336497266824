// MASTER
export const RT_HUNT_ADD_CATALOGUE_REQUEST = "RT_HUNT_ADD_CATALOGUE_REQUEST";
export const RT_HUNT_ADD_CATALOGUE_SUCCESS = "RT_HUNT_ADD_CATALOGUE_SUCCESS";
export const RT_HUNT_ADD_CATALOGUE_FAIL = "RT_HUNT_ADD_CATALOGUE_FAIL";

export const RT_HUNT_REMOVE_CATALOGUE_REQUEST =
  "RT_HUNT_REMOVE_CATALOGUE_REQUEST";
export const RT_HUNT_REMOVE_CATALOGUE_SUCCESS =
  "RT_HUNT_REMOVE_CATALOGUE_SUCCESS";
export const RT_HUNT_REMOVE_CATALOGUE_FAIL = "RT_HUNT_REMOVE_CATALOGUE_FAIL";

export const RT_HUNT_LIST_MASTER_REQUEST = "RT_HUNT_LIST_MASTER_REQUEST";
export const RT_HUNT_LIST_MASTER_SUCCESS = "RT_HUNT_LIST_MASTER_SUCCESS";
export const RT_HUNT_LIST_MASTER_FAIL = "RT_HUNT_REMOVE_MASTER_FAIL";

export const RT_HUNT_LIST_CUSTOM_MASTER_REQUEST =
  "RT_HUNT_LIST_CUSTOM_MASTER_REQUEST";
export const RT_HUNT_LIST_CUSTOM_SUCCESS = "RT_HUNT_LIST_CUSTOM_SUCCESS";
export const RT_HUNT_LIST_CUSTOM_FAIL = "RT_HUNT_REMOVE_CUSTOM_FAIL";

export const RT_HUNT_CUSTOM_MASTER_REQUEST = "RT_HUNT_CUSTOM_MASTER_REQUEST";
export const RT_HUNT_CUSTOM_MASTER_SUCCESS = "RT_HUNT_CUSTOM_MASTER_SUCCESS";
export const RT_HUNT_CUSTOM_MASTER_FAIL = "RT_HUNT_CUSTOM_MASTER_FAIL";

export const RT_HUNT_CUSTOM_MASTER_SINGLE_LIST_REQUEST =
  "RT_HUNT_CUSTOM_MASTER_SINGLE_LIST_REQUEST";
export const RT_HUNT_MASTER_SINGLE_LIST_SUCCESS =
  "RT_HUNT_MASTER_SINGLE_LIST_SUCCESS";
export const RT_HUNT_MASTER_SINGLE_LIST_FAIL =
  "RT_HUNT_MASTER_SINGLE_LIST_FAIL";

export const RT_HUNT_CUSTOM_SELECT_REQUEST = "RT_HUNT_CUSTOM_SELECT_REQUEST";
export const RT_HUNT_CUSTOM_SELECT_SUCCESS = "RT_HUNT_CUSTOM_SELECT_SUCCESS";
export const RT_HUNT_CUSTOM_SELECT_FAIL = "RT_HUNT_CUSTOM_SELECT_FAIL";
export const RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE =
  "RT_HUNT_CUSTOM_MASTER_SUCCESS_FALSE";

// CATALOGUE
export const RT_HUNT_LIST_CAT_REQUEST = "RT_HUNT_LIST_CAT_REQUEST";
export const RT_HUNT_LIST_CAT_SUCCESS = "RT_HUNT_LIST_CAT_SUCCESS";
export const RT_HUNT_LIST_CAT_FAIL = "RT_HUNT_LIST_CAT_FAIL";

export const RT_HUNT_CAT_SINGLE_LIST_REQUEST =
  "RT_HUNT_CAT_SINGLE_LIST_REQUEST";
export const RT_HUNT_CAT_SINGLE_LIST_SUCCESS =
  "RT_HUNT_CAT_SINGLE_LIST_SUCCESS";
export const RT_HUNT_CAT_SINGLE_LIST_FAIL = "RT_HUNT_CAT_SINGLE_LIST_FAIL";
