const styles = {
    section1: {
      width: "100%",
      backgroundColor: "white",
    },
    mainWrap: {
      height: "calc(100vh - 14rem)",
      overflow: "auto",
      paddingRight: "1rem",
      marginLeft: "-0.5rem"
    }
  };
  
  export default styles;