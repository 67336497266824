import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData, getSoftwareList, getUniqueVendorCount, getUniqueSoftwareCount, getUniqueVendorList, getHostList, getleastHost10query, getInstalledVendor, getSoftwareTable, getUniqueAssetsCount, getUniqueAssetsSoftCount, getScanNameSoftware, getScanName } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import * as htmlToImage from 'html-to-image';
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import stylesSoft from '../../../ManagementDashboard/Threatlandscape/ThreatLandscape.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import { isEmptyObject } from '../../../../Utils/Utils';
import { getFirwallTableQuery } from '../../../../Queries/CyKitInvestigateQuery';
import { totalUniqueSoftwareCountsQuery } from '../../../../Queries/InvestigationQuery';
import ThreatLandScapeSideBar from '../../../../components/ThreatLandScapeSideBar/ThreatLandScapeSideBar';
import SearchBar from '../../../../components/Search/ManagementDashboard/SearchBar';
import CySoftware from './CySoftware';
import { Link } from 'react-router-dom';

// Pdfdownload installed software summary
import { PDFDownloadLink } from "@react-pdf/renderer";
import SoftwareSummaryDoc from '../../../../components/PDF/managementDashboard/SoftwareSummary';
import Spinner from "react-bootstrap/Spinner";
// import { image } from 'html2canvas/dist/types/css/types/image';

import { State } from '../../../../Context';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CY_SOFTWARE = localStorage.getItem("INVESTIGATE_CY_SOFTWARE");

const CySoftwareTabs = ({ vendorsRefImage, softwareSectionRefImage, setImageBlobVuln, }) => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);

    const [totalCount, setTotalCount] = useState('')
    const [mostUsedSoftware, setMostUsedSoftware] = useState([

    ])
    const [mostUsedSoftwareLoader, setMostUsedSoftwareLoader] = useState(true)

    const [leastUsedSoftware, setLeastUsedSoftware] = useState([])
    const [leastUsedSoftwareLoader, setLeastUsedSoftwareLoader] = useState(true)

    const [uniqueVendorsLabel, setUniqueVendorsLabel] = useState([])
    const [uniqueVendorsData, setUniqueVendorsData] = useState([])

    const [leastInstalledSoftware, setLeastInstalledSoftware] = useState(0)

    const [expclickedNodeDoc, setexpclickedNodeDoc] = useState()

    const [uniqueVenderCount, setUniqueVenderCount] = useState(0)
    const [uniqueSoftwareCount, setUniqueSoftwareCount] = useState(0)
    const [uniqueVenderLoader, setUniqueVenderLoader] = useState(true)
    const [softwareHostListLoader, setSoftwareHostListLoader] = useState(true)
    const [uniqueHostLoader, setUniqueHostLoader] = useState(true)
    const [leastVendorLoader, setLeastVendorLoader] = useState(true)
    const [mostVendorLoader, setMostVendorLoader] = useState(true)
    const [leastUsedVendor, setleastUsedVendor] = useState([])
    const [mostUsedVendor, setmostUsedVendor] = useState([])

    const [softwareInstalledonLeastHostCount, setsoftwareInstalledonLeastHostCount] = useState(0)

    // source country
    const [uniqueHostList, setUniqueHostList] = useState([

    ])
    const [softwareHostList, setSoftwareHostList] = useState([

    ])
    const [loaderList, setLoaderList] = useState([])
    // geo chart 

    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState('');

    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)

    // search filter
    const [searchSoftwareQuery, setSearchSoftwareQuery] = useState()
    const [filteredListData, setFilteredListData] = useState([])
    const [scanDataLoader, setScanDataLoader] = useState(true)

    const [searchSoftwareHostQuery, setSearchSoftwareHostQuery] = useState()
    const [filteredHostListData, setFilteredHostListData] = useState([])
    const [totalUniqueAssetCount, settotalUniqueAssetCount] = useState(0)
    // console.log("LABELL :", eventCateLabel)
    const [loadTableData, setLoadTableData] = useState(true)

    const [scanNames, setScanNames] = useState([]);

    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [title, setTitle] = useState();
    const [type, setType] = useState();

    const [manageDatas, setManageDatas] = useState([
        { text: "Event Category", value: "cybEventCategory", isSelected: true },
        { text: "Event Type", value: "cybEventType", isSelected: true },
        { text: "Destination IP", value: "dipv4", isSelected: true },
        { text: "Destination Port", value: "dport", isSelected: true },
        { text: "Destination Country", value: "cybDestCountry", isSelected: true },
        { text: "Source IP", value: "sipv4", isSelected: true },
        { text: "Source Port", value: "sport", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "Bytes In", value: "bytesin", isSelected: false },
        { text: "Bytes Out", value: "bytesout", isSelected: false },
        { value: "severity", text: "Severity", isSelected: false },
        { value: "type", text: "Type", isSelected: false },
        { value: "subtype", text: "Subtype", isSelected: false },
        { value: "objectname", text: "Object Name", isSelected: false },
        { value: "url", text: "URL", isSelected: false },
        { value: "action", text: "Action", isSelected: false },
        { value: "dstregion", text: "Destination Region", isSelected: false },
        { value: "devname", text: "Dev Name", isSelected: false },
        { value: "srchwvendor", text: "Vendor", isSelected: false },
        { value: "osname", text: "OS", isSelected: false },
        { value: "srcswversion", text: "OS Version", isSelected: false },


    ])


       // Download pdf
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    // PDF-installed Software Summary
    const softwareRefImage = useRef(null);
    const [imageBlob, setImageBlob] = useState(null);

    const convertToBlob = () => {
        htmlToImage.toBlob(softwareRefImage.current).then((blob) => {
            setImageBlob(blob);
        }).catch((error) => {
            console.error('Error converting to blob:', error);
        });
    };


    // useEffect(() => {
    //   // Assigning additional ref to the vendorsRefImage
    //   if (vendorsRefImage.current) {
    //     softwareRefImage.current = vendorsRefImage.current;
    //   }
    //   console.log("softwareRefImage",softwareRefImage);
    // }, []);

    // useEffect(()=>{
    //     console.log("imageeeeeeeee",imageBlob);
    //     if(scanDataLoader === false){
    //         setTimeout(()=>{
    //             convertToBlob()
    //         },10000)
    //     }
    // })



    useEffect(() => {
        let storedData = localStorage.getItem('ManageColumn.investigate.cykit.firewall')

        if (storedData) {
            setManageDatas(JSON.parse(storedData))
        } else {
            setManageDatas(manageDatas)
        }

    }, [manageDatas])



    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false)

    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)



    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-software'





    useEffect(() => {
        // manageDatas.map((item) => {
        //     item.isSelected = false
        // })
        setMostUsedSoftwareLoader(true)
        setLeastUsedSoftwareLoader(true)
        setUniqueVenderLoader(true)
        setLoadTableData(true)
        setUniqueHostLoader(true)
        setSourceCountryLoader(true)
        setSoftwareHostListLoader(true)
        setLeastVendorLoader(true)
        setMostVendorLoader(true)
        setScanDataLoader(true)

        
        getScanName().then(({ data: { aggregations } }) => {
            setScanNames(aggregations?.unique_scan_name?.buckets)
            setScanDataLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [page, filter, dateFilter])

    useEffect(() => {
        if (searchSoftwareQuery?.length === 0) {
            setSoftwareHostListLoader(true)
            getSoftwareTable().then(({ data: { aggregations } }) => {
                setSoftwareHostList(aggregations?.unique_software?.buckets)
                setFilteredListData(aggregations?.unique_software?.buckets)
                setSoftwareHostListLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }

    }, [searchSoftwareQuery])

    useEffect(() => {
        if (searchSoftwareHostQuery?.length === 0) {
            setUniqueHostLoader(true)
            getHostList().then(({ data: { aggregations } }) => {
                setUniqueHostList(aggregations?.unique_hosts?.buckets)
                setFilteredHostListData(aggregations?.unique_hosts?.buckets)
                setUniqueHostLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        }

    }, [searchSoftwareHostQuery])


    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value[0],
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.firewall", JSON.stringify(manageDatas))
    }


    return (
        <div className="col-12 position-relative">

            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >


                {/* {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )} */}

            </div>

            {/* table datas */}
            <div className="col-12 d-flex flex-column rounded-3 bg-white p-3 position-relative">
                {/* 
                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview"> */}
                <div className='col-12 d-flex flex-column'>
                    <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 gap-3">
                        <div  className="d-flex flex-column gap-3" style={styles.subSection2}>
                            <div >
                                <div className='d-flex flex-row justify-content-between col-12 py-4'>
                                    <h2>Installed Software Summary </h2>
                                    <div className='d-flex '>
                                    <div>
                                        <Link to="/investigate/software" target="_blank" className="btn btn-light">Detailed Analyzer</Link>
                                    </div>
                                  

                                    {/* {showDownloadButton ?
                                                <div
                                                    className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                                                >
                                                    <PDFDownloadLink document={<SoftwareSummaryDoc imageBlob={imageBlob}  />}  fileName={`InstalledSoftware-Summary.pdf`}>
                                                        {({ blob, url, loading, error }) =>
                                                            <Button className='btn btn-primary'>Download</Button>
                                                        }
                                                    </PDFDownloadLink>
                                                    
                                                </div>
                                                :
                                                <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">

                                                        <PDFDownloadLink document={<SoftwareSummaryDoc imageBlob={imageBlob}  />}  fileName={`InstalledSoftware-Summary.pdf`}>
                                                        {({ blob, url, loading, error }) =>
                                                            <Button className='btn btn-primary' disabled>
                                                                Download <Spinner animation="border" role="status" size="sm" />
                                                            </Button>

                                                        }
                                                    </PDFDownloadLink>
                                                </div>
                                            } */}


                                    </div>
                                </div>
                                <div className='d-flex gap-3'>
                                    <div className="col-12 d-flex flex-column gap-3 p-0 rounded-3 mb-3">
                                        
                                        {/*<div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationPortCount ? destinationPortCount : "--"}</h4>
                                                <span>Unique Destination Ports</span>
                                            </div>
                                        </div> */}
                                {scanDataLoader? (<PlainCardLoader width="800px" />) :
                                   (scanNames?.length>0?
                                    (<Tabs
                                        // defaultActiveKey="dashboard"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    // onSelect={(e) => setActiveTab(e)}
                                    >   <Tab eventKey="All" title="Overview">
                                            <div className="col-12">
                                                <CySoftware scanName="All" 
                                                refsoft={vendorsRefImage}
                                                imageBlob={imageBlob} 
                                                scanDataLoader={scanDataLoader}
                                                setImageBlob={setImageBlob} 
                                                showDownloadButton={showDownloadButton}
                                                setImageBlobVuln={setImageBlobVuln}
                                                setShowDownloadButton={setShowDownloadButton}
                                                loaderList={loaderList}
                                                scanNames={scanNames}
                                                setLoaderList={setLoaderList}
                                                />
                                            </div>
                                        </Tab>
                                        {scanNames?.map((item)=>
                                        <Tab eventKey={item?.key} title={item?.key}>
                                            <div className="col-12">
                                                <CySoftware scanName={item?.key} 
                                                imageBlob={imageBlob}
                                                 scanDataLoader={scanDataLoader}
                                                 setImageBlob={setImageBlob} 
                                                 showDownloadButton={showDownloadButton}
                                                 setShowDownloadButton={setShowDownloadButton}
                                                 loaderList={loaderList}
                                                 setLoaderList={setLoaderList}

                                                 
                                                 />
                                            </div>
                                        </Tab>)} 
                                    </Tabs>):
                                    <NoDataFound errorText={"No data Found"} />)
                                }
                                    </div>
                                    {/* <div className="col-8 p-4 rounded-3 border">
                                        <h5>Unique Software Count From Vendors</h5>
                                        {uniqueVenderLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {uniqueVendorsData.length || uniqueVendorsData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={uniqueVendorsLabel} ChartData={uniqueVendorsData} ChartTitle={"Vendors"} module={module} tab={tab} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Tab>
                    <Tab eventKey="table" title="Details"> */}




                {/* </Tab> 
                </Tabs> */}
            </div>
            <ThreatLandScapeSideBar clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`} req={fpostDl} title={title} type={type} />

        </div >
    )
}

export default CySoftwareTabs