import React from "react";
import { ArrowLeft, ArrowRight, X } from "phosphor-react";
import globalStyles from "../../constants/globalStyles";

const Pagination = ({
  page,
  setPage,
  perPage,
  currentPageCount,
  dataLength,
  totalDataCounts = "",
  isRelative
}) => {

  return (
    <div className={isRelative?"d-flex pagination-wrap pagination-wrap-relative mx-2" :"d-flex pagination-wrap mx-2"} >
      {/* <div className="mx-3">
        <select className="form-control form-select">
          <option>All</option>
          <option>Low</option>
          <option>Medium</option>
          <option>High</option>
          <option>Critical</option>
        </select>
      </div> */}
      <nav aria-label="Page navigation example" className="">
        <ul className="pagination mb-0">
          <li
            onClick={() => setPage(page - 1)}
            // className="page-item"
            className={page <= 1 ? "disabled page-item" : "page-item"}
            disabled={page <= 0 && setPage(1)}
          >
            <a
              className="page-link d-flex justify-content-center align-items-center h-100"
              href="#"
            >
              <ArrowLeft />
            </a>
          </li>

          <div className="active-count d-flex flex-row px-3 gap-1 align-items-center">
            {totalDataCounts > 0 ?
              <>
                <span
                  style={{
                    fontWeight: "600",
                    display: "flex",
                  }}
                >
                      {(page - 1) * perPage + 1} - {totalDataCounts / perPage > page ? currentPageCount * page : totalDataCounts}
                </span>

                <span style={{ color: globalStyles.greyDark3 }}>of {totalDataCounts}</span>
              </>
              : <span style={{ color: globalStyles.greyDark3 }}>No data</span>}
          </div>


          <li
            // onClick={() => setPage(page + 1)}
            onClick={totalDataCounts / perPage > page ? () => setPage(page + 1) : null}
            // className={perPage > dataLength ? "disabled " : ""}
            className={currentPageCount > totalDataCounts ? "disabled" : ""}
          // disabled={currentPageCount > totalDataCounts}
          >
            <a
              className="page-link d-flex justify-content-center align-items-center h-100"
              href="#"
            >
              <ArrowRight />
            </a>
          </li>


        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
