import React, { useEffect, useState, useRef } from 'react'
import { getAccoundCounts, getAccoundDatas, getActiveDirectoryTable, getAuthUserChart, getCountsAD, getEventCount, getFileActivity, getGroupManagementAction, getUserManagementAction } from '../../../Methods/InvestigationMethods'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Badge, Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp, numberToLetter } from '../../../Utils/Utils'
import styles from './CykitHunter.styles';
import AddFilter from '../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../axios/axios';
import FilterList from '../../../components/FilterList/FilterList';
import InlineFilter from '../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../components/Pagination/Pagination';
import CykitINVExport from '../../Investigate/InvestigationSummary/CyDashboards/Export';
import TableViewMore from '../../../components/ViewMoreBtn/TableViewMore';
import moment from 'moment';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");

const ActiveDirectory = ({ globalSearch, init, dateFilter, setDateFilter }) => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    const [filter, setFilter] = useState([]);
    // const [dateFilter, setDateFilter] = useState();

    const [showAddFilter, setShowAddFilter] = useState(false);
    const [doc, setDoc] = useState(null)
    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    const [manageDatas, setManageDatas] = useState([
        { text: "Host Name", value: "host?.name", isSelected: true },
        { text: "Event Action", value: "event?.action", isSelected: true },
        { text: "Event Code", value: "event?.code", isSelected: true },
        { text: "Event Outcome", value: "event?.outcome", isSelected: true },
        { text: "Subject Account", value: "winlog?.event_data?.SubjectUserName", isSelected: true },
        { text: "Target Account", value: "winlog.event_data?.TargetUserName", isSelected: true },
        { text: "Share Name", value: "winlog.event_data?.ShareName", isSelected: true },
        { text: "Target File", value: "winlog.event_data?.RelativeTargetName", isSelected: true },
    ])

    useEffect(() => {

        let storedData = localStorage.getItem("ManageColumn.investigate.cykit.ad")

        if (storedData) {
            setManageDatas(JSON.parse(storedData))
        } else {
            setManageDatas(manageDatas)
        }
    }, [manageDatas])

    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });

    // tab
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);



    // loader
    const [loadTableData, setLoadTableData] = useState(true)

    const [authFailLoader, setAuthFailLoader] = useState(true)
    const [authSuccessLoader, setAuthSuccessLoader] = useState(true)
    const [accCreatedLoader, setAccCreatedLoader] = useState(true)
    const [accDeletedLoader, setAccDeletedLoader] = useState(true)
    const [grpManagementActionLoader, setGrpManagementActionLoader] = useState(true)
    const [fileAddedLoader, setFileAddedLoader] = useState(true)
    const [fileAccessedLoader, setFileAccessedLoader] = useState(true)
    const [fileModifiedLoader, setFileModifiedLoader] = useState(true)
    const [fileDeletedLoader, setFileDeletedLoader] = useState(true)

    const [userCount, setUserCount] = useState()
    const [hostCount, setHostCount] = useState()
    const [eventCount, setEventCount] = useState()
    const [accountCreatedCount, setAccountCreatedCount] = useState()
    const [accountDeletedCount, setAccountDeletedCount] = useState()

    // chart
    const [authFailLabel, setAuthFailLabel] = useState([])
    const [authFailData, setAuthFailData] = useState([])

    const [authSuccessLabel, setAuthSuccessLabel] = useState([])
    const [authSuccessData, setAuthSuccessData] = useState([])

    const [userManagementLabel, setUserManagementLabel] = useState([])
    const [userManagementData, setUserManagementData] = useState([])

    const [groupManagementLabel, setGroupManagementLabel] = useState([])
    const [groupManagementData, setGroupManagementData] = useState([])


    const [accoundCreatedData, setAccoundCreatedData] = useState([])
    const [accoundDeletedData, setAccoundDeletedData] = useState([])
    const DoughnutGroupchartRef = useRef();
    const DoughnutCreatedchartRef = useRef();
    const DoughnutDeletedchartRef = useRef();
    // table data
    const [activeDirectoryTableData, setActiveDirectoryTableData] = useState([])


    // dougnut
    const [Labels, setLabels] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    const [accoundCreatedDataLabels, setAccoundCreatedDataLabels] = useState([]);
    const [accoundCreatedDataChart, setAccoundCreatedDataChart] = useState([]);

    const [accoundDeletedDataLabels, setAccoundDeletedDataLabels] = useState([]);
    const [accoundDeletedDataChart, setAccoundDeletedDataChart] = useState([]);
    let count = [];
    let count2;
    const module = 'hunter'
    const tab = 'cykit-ad'
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    // file share
    const [fileAccessedLabel, setFileAccessedLabel] = useState([])
    const [fileAccessedData, setFileAccessedData] = useState([])
    const [fileAddedLabel, setFileAddedLabel] = useState([])
    const [fileAddedData, setFileAddedData] = useState([])
    const [fileModifiedLabel, setFileModifiedLabel] = useState([])
    const [fileModifiedData, setFileModifiedData] = useState([])
    const [fileDeletedLabel, setFileDeletedLabel] = useState([])
    const [fileDeletedData, setFileDeletedData] = useState([])

    useEffect(() => {

        if (dateFilter !== undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], date: dateFilter } },
            };
            setPage(1)
            localStorage.setItem("filters", JSON.stringify(temp2));
        }
        if (dateFilter === undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            // if(!(storedFilter?.[module]?.[tab]?.date?.to) && !(storedFilter?.[module]?.[tab]?.date?.from))
            // {
            //     let start = moment().subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
            //     let end = moment().format("yyyy-MM-DDTHH:mm");
            //     let dateTemp = {
            //         from: start,
            //         to: end,
            //         f: start,
            //         e: end,
            //         range: 60,
            //     };
            //     setDateFilter(dateTemp)

            //     let temp2 = {
            //         ...storedFilter,
            //         [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab],date:dateTemp } },
            //     };
            //     localStorage.setItem("filters", JSON.stringify(temp2));
            // }
        }
    }, [dateFilter])
    useEffect(() => {
        if (globalSearch === '' && init === true) {
            console.log()
        }
        else if (globalSearch === '' && init === false) {
            console.log(filter)
            var newfilter = filter.filter(item => item?.isGlobal != true)

            setFilter(
                newfilter
            )
            setPage(1)
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            // let temp2 = {
            //     ...storedFilter,
            //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter] } },
            //   };

            //   localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable(newfilter, dateFilter)
        }
        else {
            var filterNew = [{
                "column": {
                    "label": "Raw Search",
                    "value": "raw.global",
                    "isWildCard": null,
                    "isNumber": null
                },
                "type": "is",
                "selectedValue": globalSearch,
                "from": "",
                "to": "",
                "isInline": false,
                "isGlobal": true
            }]
            var newfilter = filter.filter(item => item?.isGlobal != true)
            setFilter((filter) => [...newfilter, ...filterNew]
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setPage(1)
            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter, ...filterNew] } },
            };

            localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable([...newfilter, ...filterNew], dateFilter)
        }
    }, [globalSearch])

    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            if (currentPageFilter !== undefined) {
                setFilter(currentPageFilter);
            }
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    const groupedManagement = {
        labels: groupManagementLabel,
        datasets: [
            {
                label: "",
                data: groupManagementData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const accountCreated = {
        labels: accoundCreatedDataLabels,
        datasets: [
            {
                label: "",
                data: accoundCreatedDataChart,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const accountDeleted = {
        labels: accoundDeletedDataLabels,
        datasets: [
            {
                label: "",
                data: accoundDeletedDataChart,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const donutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };


    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    const getFilteredTable = (filter, date = {}) => {
        if (filter || date?.to) {
            getActiveDirectoryTable(filter, date).then(({ data: { hits: { hits } } }) => {
                // console.log("table data :", hits)
                setActiveDirectoryTableData(hits)
                setLoadTableData(false)
                // setAccoundDeletedData(res)
            }).catch((err) => {
                setActiveDirectoryTableData([])
                setLoadTableData(false)
                setTotalPageCount(0)

            })
        }
        else {
            getActiveDirectoryTable().then(({ data: { hits: { hits } } }) => {
                // console.log("table data :", hits)
                setActiveDirectoryTableData(hits)
                setLoadTableData(false)

                // setAccoundDeletedData(res)
            }).catch((err) => {
                setActiveDirectoryTableData([])
                setTotalPageCount(0)

                setLoadTableData(false)

            })
        }
    }

    useEffect(() => {


        // getActiveDirectoryTable data 

        // setCurrentPageCount((page - 1) * perPage + perPage);
        if (!count?.length && !count2 && !(globalSearch?.length > 0 && init === true)) {
            setLoadTableData(true)

            getActiveDirectoryTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTotalPageCount(hits.total.value)
                // console.log("table data :", hits)
                setActiveDirectoryTableData(hits.hits)
                setLoadTableData(false)
                // setAccoundDeletedData(res)
            }).catch((err) => {
                setTotalPageCount(0)
                setActiveDirectoryTableData([])
                setLoadTableData(false)

            })
        }
        // if (!count?.length && !count2) {
        //     getActiveDirectoryTable().then(({ data: { hits: { hits } } }) => {
        //         setActiveDirectoryTableData(false)
        //     }).catch((err) => {
        //         console.log("Error", err)
        //     })
        // }



    }, [page, perPage, filter, dateFilter])


    const DoughnutClick = (event, ref, data, field, label) => {
        var activeBars = getElementAtEvent(ref.current, event);
        if (activeBars.length > 0) {
            var clickedDatasetIndex = activeBars[0].datasetIndex;
            var clickedElementIndex = activeBars[0].index;
            var clickedLabel = data.labels[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if (module && tab && field && label) {
                var temp = {
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: clickedLabel,
                    from: "",
                    to: "",
                    isinline: false
                }
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                let temp2 = {
                    ...storedFilter,
                    [module]: {
                        [tab]: {
                            fields: [temp]
                        }
                    }
                };
                localStorage.setItem("filters", JSON.stringify(temp2));
                setFilter([...filter, temp])
            }
        }
    }

    function handleTabSelect(key) {
        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.ad", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={{
                    position: "absolute",
                    right: "1.5rem",
                    zIndex: 3
                }}
            >

                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "host?.name", label: "Host Name", isWildCard: true },
                                { value: "event?.action", label: "Event Action", isWildCard: true },
                                { value: "event?.code", label: "Event Code" },
                                { value: "winlog?.event_data?.SubjectUserName", label: "Subject Account", isWildCard: true },
                                { value: "winlog.event_data?.TargetUserName", label: "Target Account", isWildCard: true },
                                { value: "winlog.event_data?.RelativeTargetName", label: "Target File", isWildCard: true },
                                { value: "winlog.event_data?.ShareName", label: "Share", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                <div className="position-relative">
                    <Button
                        variant="light"
                        // className="mx-2"
                        ref={ref}
                        onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                    >
                        Manage columns
                    </Button>
                    {showManageColumn && (
                        <div
                            className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                            style={{
                                position: "absolute",
                                right: 0,
                                zIndex: "111",
                                minWidth: "20rem",
                                maxHeight: "50vh",
                                overflow: "auto"
                            }}
                        >
                            <div className="table-fields">
                                {manageDatas?.map((item) => (
                                    <div className="d-flex align-items-center py-2 gap-2">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={item.isSelected ? true : false}
                                            // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {item.text}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>



                <div className="position-relative ms-2">
                    <CykitINVExport tabledata={getActiveDirectoryTable} type={"activeDirectory"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                </div>




                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    currentPageCount={currentPageCount}
                    totalDataCounts={totalPageCount}
                    isRelative={true}
                />


            </div>

            <div className="col-12 d-flex flex-column  rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="table"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="table"
                        title={
                            <React.Fragment>
                                Windows Events ( AD, Security , File Share )
                                <Badge className="ml-2" bg="info">
                                    {numberToLetter(totalPageCount)}
                                </Badge>
                            </React.Fragment>
                        }
                    >
                        <div className="col-12">

                            <div className="" style={styles.tableWrap}>
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    getFilteredTable={getFilteredTable}
                                    module={module}
                                    tab={tab}
                                />
                                {loadTableData ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    activeDirectoryTableData.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item.text}</th>
                                                    ))}
                                                    {/* <th>Host Name</th>
                                                    <th>Event Action</th>
                                                    <th>Event Code</th>
                                                    <th>Subject Account</th>
                                                    <th>Target Account</th>
                                                    <th>Share</th>
                                                    <th>Target File</th>
                                                    */}
                                                    <th> </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeDirectoryTableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "host?.name" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source?.host?.name}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "host?.name",
                                                                                            label: "Host Name",
                                                                                        }}
                                                                                        value={item?._source?.host?.name}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "event?.action" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.event?.action}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "event?.action",
                                                                                            label: "Event Action",
                                                                                        }}
                                                                                        value={item?._source.event?.action}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "event?.code" && data.isSelected === true ? (
                                                                <td className='font-monospace'>
                                                                    <span className="font-monospace">
                                                                        {item?._source.event?.code}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "event?.code",
                                                                                            label: "Event Code",
                                                                                        }}
                                                                                        value={item?._source.event?.code}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog?.event_data?.SubjectUserName" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source?.winlog?.event_data?.SubjectUserName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog?.event_data?.SubjectUserName",
                                                                                            label: "Subject Account",
                                                                                        }}
                                                                                        value={item?._source?.winlog?.event_data?.SubjectUserName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data?.TargetUserName" && data.isSelected === true ? (
                                                                <td className='text-break'>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.TargetUserName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data?.TargetUserName",
                                                                                            label: "Target Account",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.TargetUserName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data?.ShareName" && data.isSelected === true ? (
                                                                <td className='text-break'>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.ShareName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data?.ShareName",
                                                                                            label: "Share Name",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.ShareName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "winlog.event_data?.RelativeTargetName" && data.isSelected === true ? (
                                                                <td className='text-break'>
                                                                    <span className="font-monospace">
                                                                        {item?._source.winlog.event_data?.RelativeTargetName}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "winlog.event_data?.RelativeTargetName",
                                                                                            label: "Target File",
                                                                                        }}
                                                                                        value={item?._source.winlog.event_data?.RelativeTargetName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "event?.outcome" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        {item?._source.event?.outcome}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "event?.outcome",
                                                                                            label: "Event Outcome",
                                                                                        }}
                                                                                        value={item?._source?.event?.outcome}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            )
                                                                : (<></>)
                                                        ))}


                                                        {/* <td>{item?._source.winlog.event_data?.ShareName}</td> */}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                                {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg> */}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument setDoc={setDoc} doc={doc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${localStorage.getItem("INVESTIGATE_CYAD")}/_search`} req={fpostDl} />

        </div>
    )
}

export default ActiveDirectory