import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Loader.css";

const LoaderListModal = () => {
    return (
        <div className="h-100">
            <div className="col-12 d-flex flex-row gap-4 mt-4">
                <div className="col">
                    <Skeleton className="rounded-3" style={{ height: "70px" }} />
                </div>
            </div>
            <div className="col-12 d-flex flex-row gap-4 mt-4">
                <div className="col">
                    <Skeleton className="rounded-3" style={{ height: "70px" }} />
                </div>
            </div>
            <div className="col-12 d-flex flex-row gap-4 mt-4">
                <div className="col">
                    <Skeleton className="rounded-3" style={{ height: "70px" }} />
                </div>
            </div>

        </div>
    );
};

export default LoaderListModal;
