import React, { useState, useEffect } from "react";
import Toast from 'react-bootstrap/Toast';
import CustomModal from "../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Button, Form } from "react-bootstrap";

import {
  getExpectedOutputCustomInv,
  customCorrelation,
  getCorCatalogueDetails,
  getCorCatalogueDetailsQuery
} from "../../../Methods/RuleStudioMethods";
import { dateDMY, removeHtmlChars } from "../../../Utils/Utils";

import CustomInvInformation from "../CustomInvInformation";
import Loader from "../../Loader/Loader";
import Modules from "../Investigation/Custom/Modules";
import CountBasedInputs from "../Investigation/Custom/CountBasedInputs";



const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


export default function
  CustomCorrelation({
    setCustomShow,
    customShow,
    setCorId,
    corIdEdit,
    setSubmitLoader,
    submitLoader
  }) {
  const dispatch = useDispatch();

  const [masterId, setMasterId] = useState("COC-");
  const [correlationName, setCorrelationName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [suppress, setSuppress] = useState("");
  const [description, setDescription] = useState("");
  const [correlation, setCorrelation] = useState(false);
  const [mitigation, setMitigation] = useState("");
  const [query, setQuery] = useState();
  const [notify, setNotify] = useState(['']);
  const [severity, setSeverity] = useState('');
  const [techId, setTechId] = useState("");
  const [techName, setTechName] = useState("");
  const [tacticName, setTacticName] = useState("");
  const [subTech, setSubTech] = useState("");
  const [inv, setInv] = useState("COR-")
  const [cac_created, setCacCreated] = useState(dateDMY(new Date()))
  const [cac_last_edited, setCacLastEdited] = useState(dateDMY(new Date()))

  const [isHunter, setIsHunter] = useState("")
  const [isCorrelation, setIsCorrelation] = useState("")


  const [otherInformation, setOtherInformation] = useState([
    {
      information: "",
      value: "",
    },
  ]);

  const [countBasedInputList, setCountBasedINputList] = useState([
    {
      value: "",

    },
  ]);




  const [selectedLogCategory, setLogCategory] = useState([]);
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);

  const [modules, setModules] = useState([]);

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;



  const detailsCustomCorrelationRsCor = useSelector((state) => state.detailsCustomCorrelationRsCor);
  const { catalogue, loading: loading } = detailsCustomCorrelationRsCor;

  const detailsCustomCorrelationQueryRsCor = useSelector((state) => state.detailsCustomCorrelationQueryRsCor);
  const { catalogueQuery } = detailsCustomCorrelationQueryRsCor;

  const createCorrelationRsCor = useSelector((state) => state.createCorrelationRsCor);
  const { success, error } = createCorrelationRsCor;



  const setDataInEditList = (severity, schedule) => {


    const logData = catalogue?.log?.map((data) => {
      return { "value": data, 'label': data }
    })

    let expOutData = catalogueQuery?.data?.extract_values_from_query_output?.map((data) => {
      return { "value": data, 'label': data }
    })


    const queryData = catalogueQuery?.data
    delete queryData?.extract_values_from_query_output




    setMasterId(corIdEdit === '' ? '' : catalogue?.cac_id);
    setCorrelationName(corIdEdit === '' ? '' : catalogue?.cac_name);
    setSchedule(corIdEdit === '' ? '' : catalogue?.schedule);
    setSuppress(corIdEdit === '' ? '' : catalogue?.suppress);
    setDescription(corIdEdit === '' ? '' : catalogue?.Description);
    setModules(corIdEdit === '' ? [] : catalogue?.modules)
    setMitigation(corIdEdit === '' ? '' : catalogue?.mitigation);
    setSeverity(corIdEdit === '' ? '' : catalogue?.severity);
    setCorrelation(corIdEdit === '' ? '' : catalogue?.correlation);
    setCacCreated(corIdEdit === '' ? '' : catalogue?.cac_created);
    setCacLastEdited(corIdEdit === '' ? '' : dateDMY(new Date()));
  }


  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());

    if (
      (corIdEdit || Object.keys(catalogue).length === 0) &&
      corIdEdit != catalogue?.cac_id && corIdEdit != ''
    ) {
      dispatch(getCorCatalogueDetails(corIdEdit));
      dispatch(getCorCatalogueDetailsQuery(corIdEdit))
    }

    if (corIdEdit != '') {
      setDataInEditList(severity, schedule);
    } else {
      setMasterId("COR-");
      setCorrelationName("");
      setSchedule("");
      setSuppress("");
      setDescription("");
      setMitigation("");
      setQuery();
      setNotify([]);
      setSeverity('');
      setLogCategory('');
      setTechId("");
      setTechName("");
      setTacticName("");
      setSubTech("");
      setOtherInformation([{
        information: "",
        value: "",
      }])
    }

  }, [corIdEdit, catalogue, customShow, catalogueQuery]);


  // OTHER INFORMATION
  const addMore = () => {
    let obj = {
      information: "",
      value: "",
    };
    setOtherInformation([...otherInformation, obj]);
  };

  let otherInformationDiv = otherInformation?.map((data, index) => (
    <CustomInvInformation
      otherInformation={otherInformation}
      setOtherInformation={setOtherInformation}
      index={index}
      data={data}
      addMore={addMore}
    />
  ));



  // COUNT BASED INPUT

  const addMoreCountBasedInput = () => {

    let obj = {
      value: "",
    };

    setCountBasedINputList([...countBasedInputList, obj]);
  };

  let countBasedDiv = countBasedInputList?.map((data, index) => (
    <CountBasedInputs
      index={index}
      add={addMoreCountBasedInput}
      setData={setCountBasedINputList}
      list={countBasedInputList}
    />
  ));



  const expectedOutput = output?.expected_values?.map((output) => {
    return { value: output.value, label: output.text };
  });

  const logCategoryList = output?.log_category?.map((output) => {
    return { value: output.value, label: output.text };
  });


  const handleMultiLog = (selectedLogCategory) => {
    setLogCategory(selectedLogCategory);

  };

  const handleMultiChangeOutPut = (selectedExpectedOutput) => {
    setExpectedOutput(selectedExpectedOutput);


  };




  const onSubmit = (e) => {

    e.preventDefault();
    if (window.confirm("Are you sure you want to save changes?")) {

      setSubmitLoader(true)

      let extract_values_from_query_output = selectedExpectedOutput.map((data) => {
        return data.value

      })



      let date = dateDMY(new Date())

      let dataDes = {
        cac_id: masterId,
        cac_name: correlationName,
        schedule,
        correlation: true,
        suppress,
        severity,
        Description: description,
        mitigation,
        modules,
        technique_id: techId,
        tactic_name: tacticName,
        technique_name: techName,
        subTech,
        cac_created: cac_created,
        cac_last_edited: cac_last_edited,


      };

      let dataQuery = {

        query: JSON.stringify(removeHtmlChars(query))
      }


      dispatch(customCorrelation(dataDes, dataQuery, masterId))


    }
  }




  const handleNotify = (value) => {
    const newValues = value.split(','); // Assuming the values are comma-separated
    setNotify(newValues);
  }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: selectedLogCategory?.length === 0 ? 'red' : "#198754", // Set the border color here
      // Additional styles
    }),
  };

  const handleFirstSection = (val) => {
    setIsHunter(val)
  }

  const handleSecondSection = (val) => {
    setIsCorrelation(val)
  }


  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="px-3">
          <span className="was-validated">
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Correlation ID <span className="text-danger">*</span></label>
                    <input type="text" className={corIdEdit.length === 0 ? `form-control is-invalid` : 'form-control  '} id="validationTextarea" name="masterId"
                      value={masterId}
                      placeholder="Correlation ID" required readOnly={corIdEdit ? true : false} onChange={(e) => setMasterId(e.target.value)} />

                  </div>
                </div> <div className="col-md-6 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="" className="form-label">Correlation name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea"
                      placeholder="Correlation name" required name="correlation"
                      value={correlationName}
                      onChange={(e) => setCorrelationName(e.target.value)} />

                  </div>
                </div></div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Log category <span className="text-danger">*</span></label>
                    <Select
                      isMulti
                      options={logCategoryList}
                      value={selectedLogCategory}
                      onChange={handleMultiLog}
                      styles={customStyles}
                    />

                  </div>
                </div> <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Schedule<span className="text-danger">*</span></label>

                    <div className="mb-3">
                      {/*  
                      {schedule? 
                      <select className="form-select" required aria-label="select example" value={schedule}
                        name="schedule"
                        onChange={(e) => setSchedule(e.target.value)}>
                        <option value="">{schedule}</option>
                        <option value="Active">Active</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                      </select>
                      : */}
                      <select className="form-select" required aria-label="select example" value={schedule}
                        name="schedule"
                        onChange={(e) => setSchedule(e.target.value)}>
                        <option value="">Select Schedule</option>
                        {output?.schedule?.map((data, index) => (
                          <option value={data.value} key={index}>{data.text}</option>
                        ))}

                      </select>
                      {/* }  */}
                    </div>

                  </div>
                </div>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Suppress<span className="text-danger">*</span></label>
                    <input type="text" className="form-control is-invalid" id="validationTextarea" value={suppress}
                      name="suppress"
                      onChange={(e) => setSuppress(e.target.value)}
                      placeholder="SUPPRESS " required />

                  </div>
                </div>
              </div></div>



            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>

                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3">
                    <label for="validationTextarea" className="form-label">Severity<span className="text-danger">*</span></label>

                    <div className="mb-3">

                      <select className="form-select" required aria-label="select example" value={severity}
                        name="severity"
                        onChange={(e) => setSeverity(e.target.value)}>
                        <option value="">Select Severity </option>
                        {output?.severity?.map((data, index) => (
                          <option value={data.value} key={index}>{data.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-xs-12 p-1">



                  <div className="mb-3 mt-4">

                    <div className="d-flex">
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="hunter" onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Hunter
                      </div>
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="first_section" value="ueba" onChange={(e) => handleFirstSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        UEBA
                      </div>

                    </div>



                  </div>

                </div>
                <div className="col-md-4 col-xs-12 p-1">
                  <div className="mb-3 mt-4">

                    <div className="d-flex">
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="second_section" value='correlation' onChange={(e) => handleSecondSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Correlation
                      </div>
                      <div className="p-2">  <Form.Check type="checkbox" id="check-api-checkbox">
                        <Form.Check.Input type="radio" name="second_section" value='count_based' onChange={(e) => handleSecondSection(e.target.value)} />

                      </Form.Check></div>
                      <div className="p-2 cursor-pointer">
                        Count Based
                      </div>

                    </div>


                  </div>
                </div> */}

              </div></div>


            <div className="row  ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Technique_id</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="techId"
                    value={techId}
                    onChange={(e) => setTechId(e.target.value)}
                    placeholder="TECHNIQUE ID "
                  />
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Tactic_name</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="tacticName"
                    value={tacticName}
                    onChange={(e) => setTacticName(e.target.value)}
                    placeholder="TACTIC NAME "
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Technique_name</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="techName"
                    value={techName}
                    onChange={(e) => setTechName(e.target.value)}
                    placeholder="TECHNIQUE NAME"
                  />
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Sub_Technique</label>
                  <input
                    type="text"
                    className="form-control input-lg form-control-style"
                    name="subTech"
                    value={subTech}
                    onChange={(e) => setSubTech(e.target.value)}
                    placeholder="SUB TECHNIQUE "
                  />
                </div>
              </div>
            </div>


            <hr />
            {/* <div className="row mt-4">{otherInformationDiv}</div>
            <hr /> */}
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Description *</label>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '10px' }} ></div>
            <div className="row mt-3">
              {/* <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Detailed description *</label>
                  <ReactQuill
                    theme="snow"
                    value={detailsDescription}
                    name="detailsDescription"
                    onChange={setDetailDescription}
                  />
                </div>
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Mitigation *</label>
                  <ReactQuill
                    theme="snow"
                    value={removeHtmlChars(mitigation)}
                    name="mitigation"
                    onChange={setMitigation}
                  />
                </div>
              </div>
            </div>
            <div
                    style={{ marginLeft: '10px' }} // Adjust the value as needed
                ></div>
            <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label"  >Query *</label>
                  <ReactQuill
                    theme="snow"
                    value={query}
                    name="query"
                    onChange={setQuery}
                  />
                </div>
              </div>
            </div>
            <div
                    style={{ marginLeft: '10px' }} // Adjust the value as needed
                ></div>
            {/* <div className="row mt-2">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Notify *</label>
                  <input type="text"
                    className="form-control input-lg form-control-style"
                    value={notify}
                    name="notify"
                    onChange={(e) => handleNotify(e.target.value)}
                    placeholder="Notify " />
                </div>
              </div>
            </div> */}
            {/* <div className="row mt-3">
              <div className="form-group " style={{ display: "flex" }}>
                <div className="col-md-12 col-xs-12 p-1">
                  {" "}
                  <label className="control-label">Expected output *</label>
                  <Select
                    isMulti
                    options={expectedOutput}
                    value={selectedExpectedOutput}
                    onChange={handleMultiChangeOutPut}
                  />
                </div>
              </div>
            </div> */}
            <label className="control-label mt-3">Modules *</label>
            {output?.module?.map((data, index) => (
              <Modules
                data={data}
                expectedOutput={expectedOutput}
                setModules={setModules}
                modules={modules}
                key={index}

              />
            ))}


            <div className="row mt-3 py-3" style={styles.footerWrap}>
              {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
              <div className="form-group d-flex justify-content-end">
                <Button className="btn-secondary" onClick={() => handleClose()}>Cancel</Button> &nbsp;
                <button type="submit" className="btn btn-primary" disabled={submitLoader === true ? true : false} >
                  {submitLoader === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                    <span className="sr-only"> </span>
                  </div></> : 'Save'}
                </button>
              </div>
            </div >
          </span>
        </div >

      )
      }
    </Form >
  );

  const handleClose = () => {
    window.confirm("Do you want to save ?")

    setCustomShow(false);
    setCorId("");
  };

  const handleSave = () => {
  }



  return (
    <>
      {" "}
      {customShow === true && (
        <CustomModal
          title={corIdEdit != '' ? 'Edit Correlation' : `Custom Correlation`}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          noBodyPadding={true}

        />
      )}
    </>
  );
}
