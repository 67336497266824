import React, { useEffect, useState } from 'react'
import { getCyEmailTable, getMostCommonSRA, getUniqueSRCounts } from '../../../../Methods/CyKitInvestigate'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Badge, Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../CykitHunter.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination';
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from '../../../Investigate/InvestigationSummary/CyDashboards/Export';
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore';
import moment from 'moment';
// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const datalakeURL = localStorage.getItem("DATALAKE_URL");

const CyEmail = ({ globalSearch, dateFilter, setDateFilter, emailPageCount, setEmailPageCount, init }) => {

    // loader
    const [commonSenderLoader, setCommonSenderLoader] = useState(true)
    const [commonReceiverLoader, setCommonReceiverLoader] = useState(true)

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    // filters
    const [filter, setFilter] = useState([]);
    // const [dateFilter, setDateFilter] = useState({});

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });
    const [doc, setDoc] = useState(null)
    // loader
    const [loadTableData, setLoadTableData] = useState(true)

    // counts
    const [uniqueSenderCount, setUniqueSenderCount] = useState()
    const [uniqueRecevierCount, setUniqueRecevierCount] = useState()

    // graph
    const [mostCommonSenderLabel, setMostCommonSenderLabel] = useState([])
    const [mostCommonSenderData, setMostCommonSenderData] = useState([])

    const [mostCommonRecevierLabel, setmostCommonRecevierLabel] = useState([])
    const [mostCommonRecevierData, setmostCommonRecevierData] = useState([])

    const [mostEventActionLabel, setMostEventActionLabel] = useState([])
    const [mostEventActionData, setMostEventActionData] = useState([])

    // table
    const [tableData, setTableData] = useState([])
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    // tab
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    const [manageDatas, setManageDatas] = useState([
        { text: "Sender Address", value: "email.from.address", isSelected: true },
        { text: "Receiver Address", value: "email.receiver.address", isSelected: true },
        { text: "Subject", value: "subject", isSelected: true },
        { text: "Status", value: "delivery.action", isSelected: true },
    ])
    function isJSONObject(obj) {
        return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
    }
    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.email') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.email")))
            }
        })
    }, [manageDatas])

    // useEffect(() => {
    //     const storedFilter = JSON.parse(localStorage.getItem("filters"));
    //     const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
    //     const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
    //     console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
    //     if (currentPageFilter?.length || currentPageDateFilter?.to) {
    //         count = currentPageFilter;
    //         count2 = currentPageDateFilter?.to
    //         setDateFilter(currentPageDateFilter)
    //         setFilter(currentPageFilter);

    //         getFilteredTable(currentPageFilter, currentPageDateFilter);
    //     }
    // }, []);
    const module = 'hunter'
    const tab = 'cykit-email'
    let count = [];
    let count2;
    useEffect(() => {
        if (dateFilter !== undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], date: dateFilter } },
            };
            setPage(1)
            localStorage.setItem("filters", JSON.stringify(temp2));
        }
        if (dateFilter === undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            // if(!(storedFilter?.[module]?.[tab]?.date?.to) && !(storedFilter?.[module]?.[tab]?.date?.from))
            // // {
            // //     let start = moment().subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
            // //     let end = moment().format("yyyy-MM-DDTHH:mm");
            // //     let dateTemp = {
            // //         from: start,
            // //         to: end,
            // //         f: start,
            // //         e: end,
            // //         range: 60,
            // //     };
            // //     setDateFilter(dateTemp)

            // //     let temp2 = {
            // //         ...storedFilter,
            // //         [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab],date:dateTemp } },
            // //     };
            // //     localStorage.setItem("filters", JSON.stringify(temp2));
            // // }
        }
    }, [dateFilter])
    useEffect(() => {
        if (globalSearch === '' && init === true) {
            console.log()
        }
        else if (globalSearch === '' && init === false) {
            console.log(filter)
            var newfilter = filter.filter(item => item?.isGlobal != true)

            setFilter(
                newfilter
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setPage(1)
            // let temp2 = {
            //     ...storedFilter,
            //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter] } },
            //   };

            //   localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable(newfilter, dateFilter)
        }
        else {
            var filterNew = [{
                "column": {
                    "label": "Raw Search",
                    "value": "raw.global",
                    "isWildCard": null,
                    "isNumber": null
                },
                "type": "is",
                "selectedValue": globalSearch,
                "from": "",
                "to": "",
                "isInline": false,
                "isGlobal": true
            }]
            var newfilter = filter.filter(item => item?.isGlobal != true)
            setFilter((filter) => [...newfilter, ...filterNew]
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setPage(1)
            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter,...filterNew] } },
              };

              localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable([...newfilter, ...filterNew], dateFilter)
        }
    }, [globalSearch])
    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            if (currentPageFilter !== undefined) {
                setFilter(currentPageFilter);
            }
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    const getFilteredTable = (filter, date = {}) => {
        setLoadTableData(true)
        if (filter || date?.to) {
            getCyEmailTable(filter, date, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                console.log("getCyEmailTable ", hits)
                setTableData(hits.hits)
                setEmailPageCount(hits.total.value)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setEmailPageCount(0)

                setLoadTableData(false)
            })
        }
        else {
            getCyEmailTable().then(({ data: { hits } }) => {
                console.log("getCyEmailTable ", hits)
                setTableData(hits.hits)
                setEmailPageCount(hits.total.value)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setEmailPageCount(0)

                setLoadTableData(false)
            })
        }
    }

    useEffect(() => {

        //  "email.from.address"


        // "email.from.address"


        // table data
        // setCurrentPageCount((page - 1) * perPage + perPage);
        if (!count?.length && !count2 && !(globalSearch?.length>0 && init === true)) {
            setLoadTableData(true)

        getCyEmailTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            setTotalPageCount(hits.total.value)
            console.log("getCyEmailTable 14141 ", hits.total.value)
            setTableData(hits.hits)
            setEmailPageCount(hits.total.value)
            setLoadTableData(false)
        }).catch((err) => {
            setTableData([])
            setLoadTableData(false)
        })
    }
    }, [page, perPage, filter, dateFilter])

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value } = e.target;
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.email", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={{
                    position: "absolute",
                    right: "0",
                    top: "-48px",
                    zIndex: 3
                }}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "email.from.address", label: "Sender Address", isWildCard: true },
                                { value: "email.receiver.address", label: "Recevier Address", isWildCard: true },
                                { value: "delivery.action", label: "Status", isWildCard: true },
                                { value: "subject", label: "Subject", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>


                <div className="position-relative">
                    <Button
                        variant="light"
                        ref={ref}
                        onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                    >
                        Manage columns
                    </Button>
                    {showManageColumn && (
                        <div
                            className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                            style={{
                                position: "absolute",
                                right: 0,
                                zIndex: "111",
                                minWidth: "20rem",
                                maxHeight: "50vh",
                                overflow: "auto"
                            }}
                        >
                            <div className="table-fields">
                                {manageDatas?.map((item) => (
                                    <div className="d-flex align-items-center py-2 gap-2">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.value}
                                                onChange={handleCheckboxChange}
                                                checked={item.isSelected ? true : false}
                                            // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                {item.text}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>


                <div className="position-relative ms-2">
                    <CykitINVExport tabledata={getCyEmailTable} type={"email"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                </div>



                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    currentPageCount={currentPageCount}
                    totalDataCounts={emailPageCount}
                    isRelative={true}
                />


            </div>

            <div className="col-12 d-flex flex-column rounded-3 bg-white position-relative">

                <div className="col-12">

                    <div className="" style={styles.tableWrap}>
                        <FilterList
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            getFilteredTable={getFilteredTable}
                            module={module}
                            tab={tab}
                        />
                        {loadTableData ? (
                            <TableLoader rowCount={30} />
                        ) : (
                            tableData.length !== 0 ? (
                                <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                            <th>Date</th>
                                            <th>Time</th>
                                            {manageDatas.map((item) => (
                                                item.isSelected === true &&
                                                <th>{item.text}</th>
                                            ))}
                                            {/* <th>Sender Address</th>
                                                    <th>Recevier Address</th>
                                                    <th>Subject</th>
                                                    <th>Status</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData?.map((item, index) => (
                                            <tr
                                                onMouseOver={() =>
                                                    setShowInlineFilter({ show: true, index: index })
                                                }
                                                onMouseOut={() =>
                                                    setShowInlineFilter({ show: false, index: index })
                                                }>
                                                <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                {manageDatas?.map((data) => (
                                                    data.value === "email.from.address" && data.isSelected === true ? (
                                                        <td className='text-break'>
                                                            <span className="font-monospace text-break">
                                                                {item?._source["email.from.address"]}
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "email.from.address",
                                                                                    label: "Sender Address",
                                                                                }}
                                                                                value={item?._source["email.from.address"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "email.receiver.address" && data.isSelected === true ? (
                                                        <td className='text-break'>{item?._source["email.receiver.address"]}
                                                            <span className="font-monospace text-break">
                                                                {showInlineFilter.show &&
                                                                    showInlineFilter.index === index && (
                                                                        <div className="inline-filter-wrap">
                                                                            <InlineFilter
                                                                                filter={filter}
                                                                                setFilter={setFilter}
                                                                                column={{
                                                                                    value: "email.receiver.address",
                                                                                    label: "Receiver Address",
                                                                                }}
                                                                                value={item?._source["email.receiver.address"]}
                                                                                getFilteredTable={getFilteredTable}
                                                                                module={module}
                                                                                tab={tab}
                                                                                dateFilter={dateFilter}
                                                                                setPage={setPage}

                                                                            />
                                                                        </div>
                                                                    )}
                                                            </span>
                                                        </td>
                                                    ) : data.value === "subject" && data.isSelected === true ? (
                                                        <td className='col-3 text-break'>{isJSONObject(item?._source?.subject) ? item?._source?.subject?.["#text"] : item?._source?.subject}</td>
                                                    ) : data.value === "delivery.action" && data.isSelected === true ? (
                                                        <td>
                                                            {/* {console.log(item?._source)} */}
                                                            <span>
                                                                {item?._source["delivery.action"] == "Delivered" && (
                                                                    <Badge bg="success" className="p-2">Delivered</Badge>
                                                                )}
                                                                {item?._source["delivery.action"] == "Quarantined" && (
                                                                    <Badge bg="warning" className="p-2">Quarantined</Badge>
                                                                )}
                                                                {item?._source["delivery.action"] == "Failed" && (
                                                                    <Badge bg="danger" className="p-2">Failed</Badge>
                                                                )}
                                                                {(item?._source["delivery.action"] != "Delivered" && item?._source["delivery.action"] != "Failed" && item?._source["delivery.action"] != "Quarantined") && (
                                                                    <Badge bg="secondary" className="p-2 text-capitalize">{item?._source["delivery.action"]}</Badge>
                                                                )}
                                                            </span>
                                                            {/* {item?._source["delivery.action"]} */}
                                                        </td>
                                                    ) : (<></>)
                                                ))}

                                                <td>
                                                    <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                        {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                        <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                    <NoDataFound errorText={"No data found"} />
                                </div>
                            )
                        )}
                    </div>
                </div>

            </div>
            <ExpandedDocument clickedNode={expclickedNode} setDoc={setDoc} doc={doc} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${localStorage.getItem("INVESTIGATE_CYEMAIL")}/_search`} req={fpostDl} />
        </div>
    )
}

export default CyEmail