import {
  TI_HEADLINES_LIST_REQUEST,
  TI_HEADLINES_LIST_SUCCESS,
  TI_HEADLINES_LIST_FAIL,
  TI_VULNERABILITIES_LIST_REQUEST,
  TI_VULNERABILITIES_LIST_SUCCESS,
  TI_VULNERABILITIES_LIST_FAIL,
  TI_VULEX_LIST_REQUEST,
  TI_VULEX_LIST_SUCCESS,
  TI_VULEX_LIST_FAIL,
  TI_SECURITYEVENTS_LIST_REQUEST,
  TI_SECURITYEVENTS_LIST_SUCCESS,
  TI_SECURITYEVENTS_LIST_FAIL,
} from "../../reduxConstants/TI/activeBytesConstant";

// HEADLINES
export const Headlineslist = (
  state = { headlines: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case TI_HEADLINES_LIST_REQUEST:
      return { loading: true, headlines: [] };
    case TI_HEADLINES_LIST_SUCCESS: {
      return {
        loading: false,
        headlines: action.payload.data.hits.hits,
        
        totalCount: action.payload.data.hits.total.value,
      };
    }
    case TI_HEADLINES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// vulnerabilities
export const Vulnerabilitieslist = (
  state = { vulnerabilities: [] },
  action
) => {
  switch (action.type) {
    case TI_VULNERABILITIES_LIST_REQUEST:
      return { loading: true, vulnerabilities: [] };
    case TI_VULNERABILITIES_LIST_SUCCESS: {
      return {
        loading: false,
        vulnerabilities: action.payload.data.hits.hits,
        vulCounts: action.payload.data.hits.total.value,
      };
    }
    case TI_VULNERABILITIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// exploit vulnerabilities
export const VulnerabilitiesEXlist = (
  state = { exVulnerabilities: [] },
  action
) => {
  switch (action.type) {
    case TI_VULEX_LIST_REQUEST:
      return { loading: true, exVulnerabilities: [] };
    case TI_VULEX_LIST_SUCCESS: {
      return {
        loading: false,
        exVulnerabilities: action.payload.data.hits.hits,
        exVulCounts: action.payload.data.hits.total.value,
      };
    }
    case TI_VULEX_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Security Events
export const SecurityEventsList = (state = { securityEvents: [] }, action) => {
  switch (action.type) {
    case TI_SECURITYEVENTS_LIST_REQUEST:
      return { loading: true, securityEvents: [] };
    case TI_SECURITYEVENTS_LIST_SUCCESS: {
      return {
        loading: false,
        securityEvents: action.payload.data.hits.hits,
        secCounts: action.payload.data.hits.total.value,
      };
    }
    case TI_SECURITYEVENTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};