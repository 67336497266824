import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { exportCSVFile } from "../../../../Utils/Utils";
import { fields as eventfields } from "./fields";

import moment from "moment";
function getTimeFromTimestamp(timestamp) {
  return moment.unix(timestamp).format('MMMM DD, YYYY HH:mm:ss');
}
const TICykitExport = ({ iocs,edr,ad,office,logType,type }) => {
  const [loader, setLoader] = useState(false);
  
  const convertdata = (fields, data) => {
    var convertedData = [];

    data.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {
        console.log(field);

        const { name, value } = field;

        
        if((value!==`item._source.attribute_timestamp`)&&(value!=="item._source?.['@timestamp']"))
         {
           var value1 = eval(value);
          }
        else{
          var value1 = getTimeFromTimestamp(eval(value))
        }
        dynamicJSON[name] = value1;
      }

      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };

  const csvExport = async () => {
    setLoader(true);
    if(type==="ioc")
    {
        var fields = eventfields.event;
        var filename = "ioc" + "_" + moment().toISOString();
        var dataIoc = convertdata(fields, iocs);

        exportCSVFile(fields, dataIoc, filename);
        setLoader(false);
    }
    else if(type==="data"){
        if(logType==="all"){
            var fieldsEdr = eventfields.edr;
            var filenameEdr = "edr" + "_" + moment().toISOString();
            var dataEdr = convertdata(fieldsEdr, edr);
            var fieldsOffice = eventfields.edr;
            var filenameOffice = "office365" + "_" + moment().toISOString();
            var dataOffice = convertdata(fieldsOffice, office);
            var fields = eventfields.edr;
            var filenameAd = "ad" + "_" + moment().toISOString();
            var dataAd = convertdata(fields, ad);
            
            exportCSVFile(fields, dataEdr, filenameEdr);
            exportCSVFile(fields, dataOffice, filenameOffice);
            exportCSVFile(fields, dataAd, filenameAd);
            setLoader(false);
        }
        else if(logType==="edr"){
            var fields = eventfields.edr;
            var filenameEdr = "edr" + "_" + moment().toISOString();
            var dataEdr = convertdata(fields, edr);
    
            exportCSVFile(fields, dataEdr, filenameEdr);
            setLoader(false);
        }
        else if(logType==="ad"){
            var fields = eventfields.edr;
            var filenameAd = "ad" + "_" + moment().toISOString();
            var dataAd = convertdata(fields, ad);
            exportCSVFile(fields, dataAd, filenameAd);
            setLoader(false);
        }
        else if(logType==="o365"){
            var fields = eventfields.edr;
            var filenameOffice = "office365" + "_" + moment().toISOString();
            var dataOffice = convertdata(fields, office);
    
            exportCSVFile(fields, dataOffice, filenameOffice);
            setLoader(false);
        }
    }
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default TICykitExport;
