import React, { useEffect, useState } from "react";
import "../../../index.css";
import { Button, Form,Table, Tab, Tabs,Badge } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import Overview from "./Overview";
import Alerts from "./Alerts";



const MainDashboard = ({ selectedDateFilter }) => {
  
  const [activeTab, setActiveTab] = useState("OVERVIEW");
  const [activeSubTab, setActiveSubTab] = useState("ISO-27001");



  return (
    <>
      <div className="col-12 position-relative" style={{  overflowY:"auto" }}>
      

          {/* table datas */}
          <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative mt-2 ">             
                <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                {/* <Tabs
                      activeKey={activeTab}
                      onSelect={(k) => setActiveTab(k)}
                      id="uncontrolled-tab-example"
                      className="mb-3 mt-2"
                    >
                     <Tab
                            eventKey="OVERVIEW"
                            // title="Process"
                            title={
                              <React.Fragment>
                                OVERVIEW
                              </React.Fragment>
                            }
                          > */}

                    <Overview selectedDateFilter={selectedDateFilter} 
                    activeSubTab={activeSubTab} setActiveSubTab={setActiveSubTab} />
                        
                      {/* </Tab>
                      <Tab
                            eventKey="ALERT"
                            // title="Process"
                            title={
                              <React.Fragment>
                                ALERTS
                              </React.Fragment>
                            }
                          >
                            <Alerts selectedDateFilter={selectedDateFilter} 
                            activeSubTab={activeSubTab} setActiveSubTab={setActiveSubTab}/>
                            
                          </Tab>
                 </Tabs> */}
                    </div>
            </div>
      <ToastContainer />
      </div>
    </>
  );
};

export default MainDashboard;


