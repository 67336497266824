import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ArrowLeft, ArrowRight, ComputerTower, Funnel, Info, User } from "phosphor-react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import styles from "./Detection.styles";
import { convertTimestamp } from "../../../Methods/TiMethods";
import {
  getDetections,
  getUsersList,
  getDetectionSummary,
} from "../../../Methods/UebaMethods";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  getLastWordFromString,
  getTimeFromTimestamp,
  uebaUsualTimeConvert,
} from "../../../Utils/Utils.js";
import EditModal from "../../../components/CaseManagement/EditModal";
import { Form, Tab, Tabs, Table, Button, Badge } from "react-bootstrap";
import { timestampConvert, rawHTML } from "../../../Utils/Utils";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import UebaSummary from "../../../components/UEBA/UebaSummary";
import globalStyles from "../../../constants/globalStyles";
import AddFilter from "../../../components/AddFilter/AddFilter";
import TableLoader from "../../../components/Loader/TableLoader";
import {
  TimelineLoader,
  TimelineLoaderLg,
} from "../../../components/Loader/TimelineLoader";
import useOnclickOutside from "react-cool-onclickoutside";
import { fpostC } from "../../../axios/axios";
import FilterList from "../../../components/FilterList/FilterList";
import InlineFilter from "../../../components/InlineFilter/InlineFilter";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import Pagination from "../../../components/Pagination/Pagination";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import moment from "moment";
import UEBAExport from "../../../components/UEBA/Export";
// import Select from "react-select";
import { genstrut } from "../../../Utils/Utils.js";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument";
import { ArrowsOutSimple } from "phosphor-react";
import axios from "axios";
import AlertBox from "../../../components/AlertBox/AlertBox";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";
import ReactQuill from "react-quill";
const animatedComponents = makeAnimated();


const Detection = ({ selectedDateFilter }) => {

  let baseURL = localStorage.getItem("CYBOT_URL")

  const [detectionData, setDetectionData] = useState([]);
  const [detectionDataError, setDetectionDataError] = useState([]);

  const [alertMessage, setAlertMessage] = useState('Success')

  // console.log("detectionData detectionData ===", detectionData)
  const [detectionTimelineDatas, setDetectionTimelineDatas] = useState([]);
  const [detectionTimelineError, setDetectionTimelineError] = useState([]);

  const [summaryDetails, setSummaryDetails] = useState();

  // loading

  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [timelineIsLoading, setTimelineIsLoading] = useState(true);

  // states for filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});

  const [showInlineFilter, setShowInlineFilter] = useState({
    show: false,
    index: 0,
  });

  // show closed detection details 
  const [showClosedDetails, setShowClosedDetails] = useState({
    visible: false,
    index: 0
  })

  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [totalPageCount, setTotalPageCount] = useState()

  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);
  const [expclickedNode, setExpClickedNode] = useState("")
  const [expclose, setExpClose] = useState(false)

  // close detection check
  const [closeDetectionCheck, setCloseDetectionCheck] = useState([]);
  const [closeRefresh, setCloseRefresh] = useState(true)

  // set alert
  const [alertPopup, setAlertPopup] = useState(false)

  // Add Case
  const [caseshow, setCaseShow] = useState(false)
  const [case_id, setCase_ID] = useState("");

  // comment state
  const [commentModalVisible, setCommentModalVisible] = useState(false)
  const [detectionStatusCheck, setDetectionStatusCheck] = useState('')

  const [detectionClosed, setDetectionClosed] = useState({
    closed_at: "",
    closed_by: "",
    comment: "",
    detection_status: "",
  });

  const [detectionOpen, setDetectionOpen] = useState({
    opened_at: "",
    opened_by: "",
    comment: "",
    detection_status: "",
  });

  const [init, setInit] = useState(false)
  // active tab
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab.ueba") ? localStorage.getItem("activeTab.ueba") : "detections")
  const [isTableTabSelected, setIsTableTabSelected] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);


  const [activeTableRow, setActiveTableRow] = useState()

  const handleRowClick = (id) => {
    setActiveTableRow(id);
  };

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
    setShowManageColumn(false)
  });

  function handleSelectChange(e) {
    setPerPage(e.target.value);
  }
  const detectionTimeline = detectionTimelineDatas.map((item) => {
    return Object.entries(item);
  });


  // filter
  const [defaultColumnValues, setDefaultColumnValues] = useState([
    { label: "Alert ID", value: "ueba_id", isWildCard: true },
    { label: "Detection Timestamp", value: "detection_timestamp", isWildCard: true },
    { label: "Detection ID", value: "group_id", isWildCard: true },
    { label: "Detection Name ", value: "detection_name", isWildCard: true },
    { label: "Severity", value: "severity", isWildCard: true },
    { label: "Status", value: "detection_status", isWildCard: true },
    // { label: "Count", value: "threshold", isWildCard: true },
    { label: "Count", value: "total", isNumber: true },
    { label: "Host", value: "host_name", isWildCard: true },
    { label: "Username ", value: "user_name", isWildCard: true },
    { label: "User ID", value: "UserId", isWildCard: true },
    { label: "Domain", value: "user_domain", isWildCard: true },
    { value: "process_name", label: "Process Name", isWildCard: true },
    { value: "event_action", label: "Event Action ", isWildCard: true },
    { value: "event_outcome", label: "Event Outcome", isWildCard: true },
    { value: "event_category", label: "Event Category", isWildCard: true },
    { value: "file_name", label: "File Name", isWildCard: true },
    { value: "file_path", label: "File Path", isWildCard: true },
    { value: "target_user", label: "Target Username", isWildCard: true },
    { value: "source_ip", label: "Source IP", isWildCard: true },
    { value: "source_port", label: "Source Port", isWildCard: true },
    { value: "dest_ip", label: "Destination IP", isWildCard: true },
    { value: "dest_port", label: "Destination Port", isWildCard: true },
    { value: "ClientIP", label: "Client IP ", isWildCard: true },
    { value: "SourceFileName", label: "Source Filename", isWildCard: true },
    { value: "Workload", label: "Workload", isWildCard: true },
    { value: "Operation", label: "Operation", isWildCard: true },
    { value: "ItemType", label: "Item Type", isWildCard: true },
    { value: "EventSource", label: "Event Source", isWildCard: true },
    { value: "cybSourceCountry", label: "Source Country Name", isWildCard: true },
    { value: "email_receiver_address", label: "Receiver Address ", isWildCard: true },
    { value: "email_from_address", label: "Sender Address ", isWildCard: true },
    { value: "subject", label: "Subject", isWildCard: true },
    { value: "delivery_action", label: "Delivery Action ", isWildCard: true },
    { value: "country.keyword", label: "Country", isWildCard: true },
    { value: "first_hour", label: "First Hour", isWildCard: true },
    { value: "last_hour", label: "Last Hour", isWildCard: true },
  ])





  // manage column
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [manageDatas, setManageDatas] = useState([
    { text: "Alert ID", value: "ueba_id", isSelected: false },
    { text: "Detection Timestamp", value: "detection_timestamp", isSelected: false },
    { text: "Detection ID", value: "group_id", isSelected: true },
    { text: "Detection Name ", value: "detection_name", isSelected: true },
    { text: "Severity", value: "severity", isSelected: true },
    { text: "Status", value: "detection_status", isSelected: true },
    { text: "Opened By", value: "opened_by", isSelected: true },
    { text: "Count", value: "threshold", isSelected: true },
    { text: "Host", value: "host_name", isSelected: true },
    { text: "Username ", value: "user_name", isSelected: true },
    { text: "User ID", value: "UserId", isSelected: false },
    { text: "Domain", value: "user_domain", isSelected: false },
    { value: "process_name", text: "Process Name", isSelected: false },
    { value: "event_action", text: "Event Action ", isSelected: false },
    { value: "event_outcome", text: "Event Outcome", isSelected: false },
    { value: "event_category", text: "Event Category", isSelected: false },
    { value: "file_name", text: "File Name", isSelected: false },
    { value: "file_path", text: "File Path", isSelected: false },
    { value: "target_user", text: "Target Username", isSelected: false },
    { value: "source_ip", text: "Source IP", isSelected: false },
    { value: "source_port", text: "Source Port", isSelected: false },
    { value: "dest_ip", text: "Destination IP", isSelected: false },
    { value: "dest_port", text: "Destination Port", isSelected: false },
    { value: "ClientIP", text: "Client IP ", isSelected: false },
    { value: "SourceFileName", text: "Source Filename", isSelected: false },
    { value: "Workload", text: "Workload", isSelected: false },
    { value: "Operation", text: "Operation", isSelected: false },
    { value: "ItemType", text: "Item Type", isSelected: false },
    { value: "EventSource", text: "Event Source", isSelected: false },
    { value: "cybSourceCountry", text: "Source Country Name", isSelected: false },
    { value: "email_receiver_address", text: "Receiver Address ", isSelected: false },
    { value: "email_from_address", text: "Sender Address ", isSelected: false },
    { value: "subject", text: "Subject", isSelected: false },
    { value: "delivery_action", text: "Delivery Action ", isSelected: false },
    { value: "country", text: "Country", isSelected: false },


  ])


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const active = queryParams.get('active');
  const severity = queryParams.get('severity');

  const DateFilter = queryParams.get('date');




  useEffect(() => {
    let storedData = localStorage.getItem('ManageColumn.ueba')

    if (storedData) {
      setManageDatas(JSON.parse(storedData))
    } else {
      setManageDatas(manageDatas)
    }

  }, [manageDatas])


  const handleInvestigationOpen = (tenandID, id, status) => {
    if (status !== "closed") {
      const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
      fpostC(`${baseURL}/cybot_proxy/${tenandID}/_update/${id}`, data).then((res) => {

      })
    }
  }

  // console.log("DDDD", getDetectionData().map((item)=> item[0][0]._source.description))

  // detectionTimeline.map((item)=>{
  //   const entries = Object.entries(item);
  //   for (const [key, value] of entries) {
  //     console.log("TIMELINE", value[0])
  //   }

  // })

  // for filter
  var count = [];
  var count2 = "test";
  const module = 'ueba'
  const tab = 'detection'



  useEffect(() => {
    // setCurrentPageCount((page - 1) * perPage + perPage);
    setTableIsLoading(true);
    setTimelineIsLoading(true)

    if ((!count.length && !count2) || count2 === "test") {

      getDetections2(filter, dateFilter, perPage, "desc", (page - 1) * perPage)
        .then(
          (res) => {
            if (!res.isError) {
              let hits = res.data.hits
              setTotalPageCount(hits?.total?.value)
              hits?.hits?.map(
                (item) =>
                (item._source.time = getTimeFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              hits?.hits?.map(
                (item) =>
                (item._source.date = getDateFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              setDetectionData(hits?.hits);
              setTableIsLoading(false);
              setTimelineIsLoading(false)
            } else {
              setDetectionDataError(res)
              setTableIsLoading(false);
              setTimelineIsLoading(false)
            }
            console.log("test1")
          }
        )
        .catch((err) => {
          setTableIsLoading(false);
          // setTimelineIsLoading(false)
          console.log("Error", err);
        });
      getDetections2(filter, dateFilter, perPage, "desc", (page - 1) * perPage)
        .then(
          (res) => {
            // setTotalPageCount(hits.total.value)
            if (!res.isError) {
              let hits = res?.data?.hits
              hits?.hits?.map(
                (item) =>
                (item._source.detection_timestamp = getDateFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              let groups = Object.create(null);
              let result;

              hits?.hits?.forEach((element) => {
                groups[element._source.detection_timestamp] =
                  groups[element._source.detection_timestamp] || [];
                groups[element._source.detection_timestamp].push(element);
              });

              result = Object.keys(groups)?.map((k) => {
                let temp = {};
                temp[k] = groups[k];
                return temp;
              });
              setDetectionTimelineDatas(result);
              setTimelineIsLoading(false);
              console.log("test2")

            } else {
              setDetectionTimelineError(res)
              setTimelineIsLoading(false);
            }
          }
        )
        .catch((err) => {
          console.log("Error", err);
        });
    }


  }, [page, perPage, dateFilter, filter, closeRefresh]);

  const getProcessListQuery = (fieldList, date, count, sort, page) => {
    var data = {
      query: {
        bool: {
          must: [],
          filter: [],
          must_not: [],
          should: [],
        },
      },
    }
    if (fieldList?.length > 0) {
      var data = {
        query: {
          bool: {
            must: [],
            filter: [],
            must_not: [],
            should: [],
          },
        },
      };
      let counts = [];
      for (let i = 0; i < fieldList.length; i++) {
        const item = fieldList[i];

        // If the column value exists, increment the count
        if (item.column && item.column.value) {
          const columnName = item.column.value;
          counts[columnName] = counts[columnName] || {
            name: columnName,
            count: 0,

            iscount: 0,
            notcount: 0,
          };
          counts[columnName].count++;

          // If the type value exists, increment the corresponding count
          if (item.type === "is" || item.type === "contains") {
            counts[columnName].iscount++;
          } else if (item.type === "is not" || item.type === "not contains") {
            counts[columnName].notcount++;
          }
        }
      }
      let dist = Object.values(counts);
      for (const field of fieldList) {
        if (field.type === "is" || field.type === "contains") {
          var distItem = dist.find(val => val.name === field.column.value)
          console.log("test1", distItem)
          if ((distItem && distItem.count === 1 && distItem.iscount === 1)) {
            var must = genstrut(field)
            data.query.bool.must.push(must);
          }
          else if ((distItem && distItem.count > 1 && distItem.iscount === 1)) {
            var must = genstrut(field)
            data.query.bool.must.push(must);
          }
          else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
            let sindex = 0;
            let index = 0;
            let srindex = 0;
            let smultindex = 0;
            let matchindex = 0;

            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.wildcard
                  )[0] === field.column.value
                ) {
                  sindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.query_string
                  )[0] === "query"
                ) {
                  srindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.multi_match
                  )[0] === "query"
                ) {
                  smultindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  matchindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.term &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.term
                  )[0] === field.column.value
                ) {
                  index = i + 1;
                }
              }
            }

            const shouldn = {
              bool: {
                should: [],
              },
            };

            if (sindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[sindex - 1].bool.should.push(must);
            } else if (index > 0) {
              var must = genstrut(field);
              data.query.bool.must[index - 1].bool.should.push(must);
            } else if (srindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[srindex - 1].bool.should.push(must);
            }
            else if (smultindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[smultindex - 1].bool.should.push(must);
            }
            else if (matchindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[matchindex - 1].bool.should.push(must);
            }
            else if (index === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            } else if (sindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            } else if (srindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
            else if (smultindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
            else if (matchindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
          }
        }
        else if (field.type === "is not" || field.type === "not contains") {
          const must_not = {
            wildcard: {}
          }

          var distItem = dist.find(val => val.name === field.column.value)

          if ((distItem && distItem.count === 1 && distItem.notcount === 1)) {
            console.log("test", dist)

            var must = genstrut(field)
            data.query.bool.must_not.push(must);
          }
          else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
            var must = genstrut(field);
            data.query.bool.must_not.push(must);
          }
          else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
            let index = 0;
            let sindex = 0;
            let srindex = 0;
            let smultindex = 0;
            let matchindex = 0;
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                  )[0] === field.column.value
                ) {
                  index = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                  )[0] === "query"
                ) {
                  srindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                  )[0] === "query"
                ) {
                  smultindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  matchindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  sindex = i + 1;
                }
              }
            }

            const shouldn = {
              bool: {
                should: [],
              },
            };

            if (sindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[sindex - 1].bool.should.push(must);
            } else if (index > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[index - 1].bool.should.push(must);
            } else if (srindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[srindex - 1].bool.should.push(must);
            }
            else if (smultindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[smultindex - 1].bool.should.push(must);
            }
            else if (matchindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[matchindex - 1].bool.should.push(must);
            }
            else if (index === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            } else if (sindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            } else if (srindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
            else if (smultindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
            else if (matchindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
          }

        }

      }
    }
    var sortQ = [
      {
        "detection_timestamp": {
          order: sort,
          unmapped_type: "boolean",
        },
      },
    ];

    data.size = count;
    data.from = page;
    data.track_total_hits = true;
    data.sort = sortQ;


    if (date) {
      if (date?.from) {
        var range = {
          range: {
            "detection_timestamp": {
              gte: date.from,
            },
          },
        };
        if (date?.from && date?.to) {
          var range = {
            range: {
              "detection_timestamp": {
                lte: date.to,
                gte: date.from,
              },
            },
          };
        }
        data.query.bool.filter.push(range);
      }
      if (date?.to && !date?.from) {
        var range = {
          range: {
            "detection_timestamp": {
              lte: date.to,
            },
          },
        };
        data.query.bool.filter.push(range);
      }
    }

    var fil = {
      match_phrase: {
        result: "-1",
      },
    }
    data.query.bool.must.push(fil)

    return data;

  }
  async function getDetections2(filter, date, count, sort, page) {
    let data = getProcessListQuery(filter, date, count, sort, page);

    return await fpostC(
      `${localStorage.getItem(
        "CYBOT_URL"
      )}/cybot_proxy/ueba_group_details*/_search`,
      data
    );
  }

  const getFilteredTable = (filter, date = {}) => {
    setPage(1)
    setTableIsLoading(true);
    if (filter || date?.to) {
      getDetections2(filter, date, perPage, "desc", (page - 1) * perPage)
        .then(
          ({
            data: {
              hits,
            },
          }) => {
            hits?.hits?.map(
              (item) =>
              (item._source.time = getTimeFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            hits?.hits?.map(
              (item) =>
              (item._source.date = getDateFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            if (hits?.hits?.length > 0) {
              setDetectionData(hits?.hits);
              setTotalPageCount(hits?.total?.value)
            }
            else {
              setDetectionData([]);
              setTotalPageCount(0)

            }
            console.log("test3")

            setTableIsLoading(false);
          }
        )
        .catch((err) => {
          setTableIsLoading(false);
          setDetectionData([]);
          setTotalPageCount(0)

          console.log("Error", err);
        });

      getDetections2(filter, date, perPage, "desc", (page - 1) * perPage)
        .then(
          (res) => {
            // setTotalPageCount(hits.total.value)
            if (!res.isError) {
              let hits = res?.data?.hits
              hits?.hits?.map(
                (item) =>
                (item._source.detection_timestamp = getDateFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              let groups = Object.create(null);
              let result;

              hits?.hits?.forEach((element) => {
                groups[element._source.detection_timestamp] =
                  groups[element._source.detection_timestamp] || [];
                groups[element._source.detection_timestamp].push(element);
              });

              result = Object.keys(groups)?.map((k) => {
                let temp = {};
                temp[k] = groups[k];
                return temp;
              });
              if (result?.length > 0) {
                setDetectionTimelineDatas(result);
              }
              else {
                setDetectionTimelineDatas([]);
              }
              console.log("test4")

              setTimelineIsLoading(false);
            } else {
              setDetectionTimelineError(res)
              setDetectionTimelineDatas([]);

              setTimelineIsLoading(false);
            }
          }
        )
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      getDetections2(filter, dateFilter, perPage, "desc", (page - 1) * perPage)
        .then(
          ({
            data: {
              hits
            },
          }) => {
            hits?.hits?.map(
              (item) =>
              (item._source.time = getTimeFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            hits?.hits?.map(
              (item) =>
              (item._source.date = getDateFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            if (hits?.hits?.length > 0) {
              setDetectionData(hits?.hits);
              setTotalPageCount(hits?.total?.value)

            }
            else {
              setDetectionData([]);
              setTotalPageCount(0)

            }
            console.log("test5")

            setTableIsLoading(false);
            console.log("API OF FILTER", hits);
          }
        )
        .catch((err) => {
          setTableIsLoading(false);
          console.log("Error", err);
          setDetectionData([]);
          setTotalPageCount(0)
        });

      getDetections2(filter, dateFilter, perPage, "desc", (page - 1) * perPage)
        .then(
          (res) => {
            // setTotalPageCount(hits.total.value)
            if (!res.isError) {
              let hits = res?.data?.hits
              hits?.hits?.map(
                (item) =>
                (item._source.detection_timestamp = getDateFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              let groups = Object.create(null);
              let result;

              hits?.hits?.forEach((element) => {
                groups[element._source.detection_timestamp] =
                  groups[element._source.detection_timestamp] || [];
                groups[element._source.detection_timestamp].push(element);
              });

              result = Object.keys(groups)?.map((k) => {
                let temp = {};
                temp[k] = groups[k];
                return temp;
              });
              if (result?.length > 0) {
                setDetectionTimelineDatas(result);
              }
              else {
                setDetectionTimelineDatas([]);
              }
              setTimelineIsLoading(false);
            } else {
              setDetectionTimelineError(res)
              setDetectionTimelineDatas([]);
              setTimelineIsLoading(false);
            }
            console.log("test6")

          }
        )
        .catch((err) => {
          console.log("Error", err);
          setDetectionTimelineDatas([]);
          setTimelineIsLoading(false);

        });
    }
  };
  useMemo(() => {
    if (active !== undefined && active !== null) {
      setActiveTab(active)
    }
    if (severity !== undefined && severity !== null) {

      var number = 0
      var type ;
      if (DateFilter === "1 Year") {
        number = 365
        type = "days"
      } else if (DateFilter === "30 Days") {
        number = 30
        type = "days"
      } 
      else if (DateFilter === "30 Days") {
        number = 30
        type = "days"
      }else if (DateFilter === "6 hour") {
        number = 6
        type = "hours"
      } else if (DateFilter === "1 hour") {
        number = 1
        type = "hours"
      } 
      else if (DateFilter === "30 minutes") {
        number = 30
        type = "minutes"
      } 
      else if (DateFilter === "1 Day") {
        number = 1
      } else {
        type="days"
        number = 7
      }
      const finalFilterDate = moment().utc()
        .subtract(number, type)
        ?.format("yyyy-MM-DDTHH:mm");

      let filterList = [{
        "column": {
          "label": "Severity",
          "value": "severity",
          "isWildCard": "true",
          "isNumber": null
        },
        "type": "is",
        "selectedValue": severity,
        "from": "",
        "to": "",
        "isInline": false
      }]

      setFilter(filterList)
      let storedFilter = JSON.parse(localStorage.getItem("filters"));

      let currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
      getFilteredTable(filterList, currentPageDateFilter);

      // let dateTemp = {
      //   from: finalFilterDate,
      //   to: moment().utc().format("yyyy-MM-DDTHH:mm"),
      //   f: finalFilterDate,
      //   e: moment().utc().format("yyyy-MM-DDTHH:mm"),
      //   range: number,
      // };
      // setDateFilter(dateTemp)

      // let dateTemp = {
      //   from: finalFilterDate,
      //   to: moment().utc().format("yyyy-MM-DDTHH:mm"),
      //   f: finalFilterDate,
      //   e: moment().utc().format("yyyy-MM-DDTHH:mm"),
      //   range: number,
      // };
      // setDateFilter(dateTemp)
    }




  }, [])
  // search function
  const loadOptions = (searchedValue, callback) => {
    if (searchedValue && searchedValue.length >= 2) {
      getUsersList(searchedValue, "user.name")
        .then(({ data }) => {
          const options = data.aggregations[1].buckets.map((bucket) => ({
            value: bucket.key,
            label: bucket.key,
          }));
          callback(options);
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };
  useMemo(() => {
    if (!init) {
      const storedFilter = JSON.parse(localStorage.getItem("filters"));
      const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
      const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
      console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)

      if ((currentPageFilter?.length || currentPageDateFilter?.to)) {
        count = currentPageFilter;
        count2 = currentPageDateFilter?.to

        setDateFilter(currentPageDateFilter)
        if (severity === undefined || severity === null) {
          console.log("test9")
          setFilter(currentPageFilter);

          getFilteredTable(currentPageFilter, currentPageDateFilter);

        }
      }
      else {
        count2 = null
      }
      setInit(true)
    }
  }, []);

  // // Add Case
  // const handleCreate = () => {
  //   setCaseShow(true);
  // };

  // detection  open
  const handleDetectionOpen = (tenandId, id) => {
    const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
    fpostC(`${baseURL}/cybot_proxy/${tenandId}/_update/${id}`, data).then((res) => {
      console.log("OPEN RSE :", res)
    }).catch((err) => {
      console.log("Error", err)
    })
  }

  const handleOnChange = (selectedData) => {
    console.log("Selected data :", selectedData);
  };

  const handleOpenModal = (data, display, tenandId, id) => {
    if (data._source?.detection_status !== "closed") {
      handleDetectionOpen(tenandId, id)
    }

    setDetailpage(data);
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
    setCommentModalVisible(false)
  }

  // const handleClosePopup = () => {
  //   setCloseShow(false);
  // };

  function renderModal() {
    return <UebaSummary detectionData={detailpage} />
  }

  // useMemo(() => {
  //   if (state && state.data !== undefined) {
  //     setFilter([...filter, state.data])
  //     setDemo(true)
  //     getFilteredTable([...filter, state.data])
  //   }
  // }, [state])

  // close detection check

  const handleCloseDetecion = (id, tenandId, isChecked, status, name) => {
    if (isChecked) {
      setCloseDetectionCheck(prevcloseDetectionCheck => [...prevcloseDetectionCheck, { id: id, tenandId: tenandId, status: status }]);
    } else {
      setCloseDetectionCheck(prevcloseDetectionCheck => prevcloseDetectionCheck.filter(item => item.id !== id));
    }
  }

  const handleCloseSelectedDetections = async (e) => {
    e.preventDefault();

    if (detectionStatusCheck === 'closed') {
      closeDetectionCheck.map(async (item) => {

        let data = { "doc": { "comment": detectionClosed?.comment, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString(), "detection_status": "closed" } }

        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCommentModalVisible(false)
          setAlertMessage("Detections Closed")
          setAlertPopup(true);
        } catch (error) {
          setCommentModalVisible(false)
          console.error('Error', error);
          // setCloseDetectionCheck([])
        }
      })
    } else {
      closeDetectionCheck.map(async (item) => {

        const data = { "doc": { "comment": detectionOpen?.comment, "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }

        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCommentModalVisible(false)
          setAlertMessage("Detections Open")
          setAlertPopup(true);
        } catch (error) {
          setCommentModalVisible(false)
          console.error('Error', error);
          // setCloseDetectionCheck([])
        }
      })
    }


  };

  function handleTabSelect(key) {
    localStorage.setItem("activeTab.ueba", key)


    if (localStorage.getItem("activeTab.ueba") === "detections") {
      setActiveTab("detections")
      setIsTableTabSelected(true)
    }
    else if (localStorage.getItem("activeTab.ueba") === "timeline") {
      setActiveTab("timeline")
      setIsTableTabSelected(false)
    }
    else {
      setIsTableTabSelected(false)
    }
  }

  const handleCheckboxChange = (e) => {
    let { value, checked } = e.target;
    if (checked) {
      setCheckedItems([...checkedItems, { text: value, value: value }]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item.value !== value));
    }
    let index = manageDatas.findIndex(x => x.value === value)

    manageDatas[index].isSelected = !manageDatas[index].isSelected
    setManageDatas(manageDatas)
    localStorage.setItem('ManageColumn.ueba', JSON.stringify(manageDatas))
  }

  // comment modal

  const handleOpenCommentModal = (display, status) => {
    setCommentModalVisible(display);
    setDetectionStatusCheck(status)
  };

  const handleInputChange = (value) => {
    // setDetectionClosed(e.target.value)
    // setDetectionOpen(e.target.value)
    setDetectionClosed({
      comment: value
    })
    setDetectionOpen({
      comment: value
    })
  }

  function renderCommentModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleCloseSelectedDetections}>
          <div className="row">
            {/* <div>
                        <h6>Technique Name</h6>
                        <span>{state?.sourceList.technique_name}</span>
                    </div> */}
            {/* <div>
              <h6>Detection Name</h6>
            </div> */}
            {/* {detectionStatusCheck === 'closed' &&
              <div className="form-group mt-2">
                <label className="control-label">Closure code</label>
                <select
                  className="form-select input-lg"
                  name="severity"
                  onChange={(e) => setClosureCategory(e.target.value)}
                  // onChange={(e) => changeSeverity(e, defSev)}
                  // value={severity}
                  required
                >
                  <option value="">Select Category</option>
                  <option value="True Positive - Confirmed Suspicious Activity">True Positive - Confirmed Suspicious Activity</option>
                  <option value="Benign Positive - Validated">Benign Positive - Validated</option>
                  <option value="Benign Positive - Expected">Benign Positive - Expected</option>
                  <option value="False Positive - Data Error">False Positive - Data Error</option>
                  <option value="False Positive - Incorrect Alert Logic">False Positive - Incorrect Alert Logic</option>

                </select>

              </div>
            } */}

            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                {detectionStatusCheck === 'closed' ?
                  <ReactQuill
                    value={detectionClosed.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed.comment} onChange={handleInputChange} required ></textarea>
                  :
                  <ReactQuill
                    value={detectionOpen.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen.comment} onChange={handleInputChange} required ></textarea>
                }
              </div>
            </div>
          </div>
          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-primary" type="submit">
                Submit
              </Button>
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">Create</button>
          <button type="button" className="btn btn-secondary">Close</button> */}
        </form>
      </div>
    )
  }



  return (
    <>
      <div
        className="col-12 p-4 position-relative rounded-3"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
          style={styles.filterWrap}
        >
          {/* <div className="multipleToggleButtonWrapp">
            <div className="col-4 active">All</div>
            <div className="col-4">Users</div>
            <div className="col-4">Hosts</div>
          </div> */}

          {/* <div className="d-flex flex-row align-items-center col-3">
            <Form.Select onChange={handleSelectChange}>
              <option>50</option>
              <option>100</option>
              <option>150</option>
            </Form.Select>
          </div> */}

          {/* <Form.Control type="text" placeholder="Search..." className="mx-3" /> */}
          {/* search */}
          {/* <Select options={options} className="w-100 px-3 h-100" /> */}

          {/* <AsyncSelect
            className="w-100 px-3"
            loadOptions={loadOptions}
            isMulti
            onChange={handleOnChange}
          /> */}


          {/* {console.log("CHECKKKK !@# :", closeDetectionCheck)}

          {closeDetectionCheck.length > 0 &&
            <div className="close-detection">
              <button className="btn btn-secondary mr-2" disabled={closeDetectionCheck.length === 0} onClick={() => handleOpenCommentModal(true, 'closed')}>Close Detections</button>
            </div>
          }


          {closeDetectionCheck.length > 0 &&
            <div className="close-detection">
              <button className="btn btn-secondary mr-2" disabled={closeDetectionCheck.length === 0} onClick={() => handleOpenCommentModal(true, 'open')}>Open Detections</button>
            </div>
          } */}


          {/* close open btn */}

          {closeDetectionCheck.length > 0 &&
            <>
              {closeDetectionCheck.some((item) => item.status === 'closed') && closeDetectionCheck.some((item) => item.status === 'open') ?
                <></>
                : closeDetectionCheck.some((item) => item.status === 'closed') ?
                  <div className="close-detection">
                    <button
                      className="btn btn-secondary"
                      // disabled={closeDetectionCheck.length === 0} 
                      onClick={() => handleOpenCommentModal(true, 'open')}>Open</button>
                  </div>
                  : (
                    <div className="close-detection">
                      <button className="btn btn-secondary"
                        // disabled={closeDetectionCheck.length === 0} 
                        onClick={() => handleOpenCommentModal(true, 'closed')}>Close </button>
                    </div>
                  )
              }
            </>
          }



          <div className="position-relative">
            <Link
              className="mx-2"
              style={{ color: "white" }}

            >
              {/* <button className="btn btn-primary" onClick={(e) => handleCreate()}>Add Case</button> */}
            </Link>
            <Button
              ref={ref}
              onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
            >
              Add filter
            </Button>
            {/* <Button
              className="btn-light rounded-circle p-0"
              style={{ width: "30px", height: "30px" }}
              ref={ref}
              onClick={() => setShowAddFilter(!showAddFilter)}
              tooltip="sdfd"
              dataToggle={"Add filter"}
              dataPlacement={"bottom"}
            >
              <Funnel size={18} color={globalStyles.greyDark2} />
            </Button> */}
            {showAddFilter && (
              <AddFilter
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setShowAddFilter={setShowAddFilter}
                getFilteredTable={getFilteredTable}
                setPage={setPage}
                columnValues={defaultColumnValues}
                // columnValues={[
                //   { value: "detection_name", label: "Detection Name" },
                //   { value: "user_name", label: "User Name", isWildCard: true },
                //   { value: "host_name", label: "Host", isWildCard: true },
                //   { value: "ClientIP", label: "Client IP", isWildCard: true },
                //   { value: "UserId", label: "User ID", isWildCard: true },
                //   { value: "email_receiver_address", label: "Receiver Address", isWildCard: true },
                //   { value: "email_from_address", label: "Sender Address", isWildCard: true },
                //   { value: "detection_status", label: "Status" },
                //   // { value: "dest_ip", label: "Destination IP", isWildCard: true },
                //   // { value: "host_ip", label: "Source IP", isWildCard: true },
                //   { value: "severity", label: "Severity", isWildCard: true },
                //   { value: "group_id", label: "Detection ID", isWildCard: true },
                // ]}
                module={module}
                tab={tab}
              />
            )}
          </div>

          {/* manage column */}

          <div className="position-relative">
            <Button
              variant="light"
              className="mx-2"
              ref={ref}
              onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
            >
              Manage columns
            </Button>
            {showManageColumn && (
              <div
                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: "111",
                  minWidth: "20rem",
                  maxHeight: "50vh",
                  overflow: "auto"
                }}
              >
                <div className="table-fields">
                  {manageDatas?.map((item) => (
                    <div className="d-flex align-items-center py-2 gap-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.value}
                          onChange={handleCheckboxChange}
                          checked={item.isSelected ? true : false}
                        // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                        />
                        <label className="form-check-label" for="flexCheckDefault">
                          {item.text}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>


          <div className="position-relative ms-2">
            <UEBAExport getDetections2={getDetections2} filter={filter} dateFilter={dateFilter} />
          </div>
          {/* 
          {console.log('currentPageCount -', currentPageCount)}
          {console.log('totalPageCount -', totalPageCount)} */}
          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            currentPageCount={currentPageCount}
            totalDataCounts={totalPageCount}

          // dataLength={}
          />

        </div>
        <div className="detections col-12">
          <Tabs
            defaultActiveKey="detections"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="detections" title="Detections">
              {/* {closeDetectionCheck.length > 0 && (
                <div className="my-2" style={{ fontSize: "1rem", padding: "0.7rem", backgroundColor: "#111", color: "#fff", borderRadius: "6px", width: "fit-content", display: "flex", alignItems: "center" }}>
                  <span>Selected</span>
                  <span style={{ marginLeft: "10px", fontWeight: "600" }}>{closeDetectionCheck.length}</span>
                </div>
              )} */}
              {/* <div className="col-12" style={styles.section1}> */}
              <div style={styles.tableWrap}>
                <FilterList
                  filter={filter}
                  setFilter={setFilter}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTable}
                  module={module}
                  tab={tab}

                />
                {tableIsLoading ? (
                  <TableLoader rowCount={25} />
                ) : (
                  <>
                    {detectionData?.length !== 0 && !detectionDataError?.isError ? (
                      <Table borderless hover>
                        <thead className="fixed-head">
                          <tr>
                            <th className="auto-min-width"></th>
                            <th className="text-right">Date</th>
                            <th className="text-right">Time</th>
                            {manageDatas.map((item) => (
                              item.isSelected === true &&
                              <th>{item.text}</th>
                            ))}
                            {/* <th>Detection ID</th>
                            <th>Detection name</th>
                            <th className="auto-min-width">Severity</th>
                            <th className="auto-min-width">Status</th>
                            <th className="text-right auto-min-width">Count</th>
                            <th>{detectionData[0]?._source?.UserId ? 'User ID ' : detectionData[0]?._source?.email_receiver_address ? 'Receiver Address' : 'Host'}</th>
                            <th>{detectionData[0]?._source?.ClientIP ? 'Client IP' : detectionData[0]?._source?.email_from_address ? 'Sender Address' : 'User'}</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {detectionData.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => handleRowClick(item._id)}
                              className={activeTableRow === item._id ? 'active_table_row' : ''}
                              onMouseOver={() =>
                                setShowInlineFilter({ show: true, index: index })
                              }
                              onMouseOut={() =>
                                setShowInlineFilter({ show: false, index: index })
                              }>
                              <td className="auto-min-width">
                                <input className="form-check-input" type="checkbox" name="close"
                                  // disabled={item?._source?.detection_status === "closed"} 
                                  id={item?._source?.detection_status} onChange={e => handleCloseDetecion(item?._id, item?._index, e.target.checked, item?._source?.detection_status)} />
                              </td>
                              <td className="font-monospace text-right">
                                {moment(item?._source?.detection_timestamp).utc().format('DD MMM YYYY')}
                              </td>
                              <td className="font-monospace text-right" style={{ minWidth: 300 }}>
                                {item._source.type === "time based" ? (
                                  <>
                                    <span style={{ color: globalStyles.red }}>
                                      {moment(item?._source?.first_timestamp).utc().format('HH:mm:ss')} - {moment(item?._source?.last_timestamp).utc().format('HH:mm:ss')}
                                      {/* {moment.utc(item._source.first_timestamp).format('YYYY-MM-DD HH:mm:ss')} - {moment.utc(item._source.last_timestamp).format('YYYY-MM-DD HH:mm:ss')} */}
                                    </span>
                                    {item?._source?.first_hour >= 0 && item?._source?.last_hour >= 0 &&
                                      <p style={{ color: globalStyles.greyDark3, fontSize: "10px" }}>
                                        {/* {"Usual - " + timestampConvert(item?._source?.first_hour, 'HH:mm:ss')} - {timestampConvert(item?._source?.last_hour, 'HH:mm:ss')} */}
                                        {"Usual - " + uebaUsualTimeConvert(item?._source?.first_hour).format('HH:mm:ss')} - {uebaUsualTimeConvert(item?._source?.last_hour).format('HH:mm:ss')}
                                        {/* {"Usual - " + moment.utc(item?._source?.first_hour).format('YYYY-MM-DD HH:mm:ss')} - {moment.utc(item?._source?.last_hour).format('YYYY-MM-DD HH:mm:ss')} */}
                                      </p>
                                    }
                                  </>
                                ) : (
                                  <span>{moment(item._source.detection_timestamp).utc().format('HH:mm:ss')}</span>
                                )}
                              </td>
                              {manageDatas?.map((data) => (
                                data.value === "group_id" && data.isSelected === true ? (
                                  <td>
                                    {item?._source?.group_id}
                                  </td>
                                ) : data.value === "detection_name" && data.isSelected === true ? (
                                  <td>
                                    {item?._source?.detection_name}
                                    {showInlineFilter.show &&
                                      showInlineFilter.index === index && (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "detection_name",
                                              label: "Detection Name",
                                            }}
                                            value={item?._source.detection_name}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )}
                                  </td>
                                ) : data.value === "ueba_id" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source.ueba_id}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "ueba_id",
                                              label: "Host",
                                            }}
                                            value={item?._source.ueba_id}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "severity" && data.isSelected === true ? (
                                  <td className="auto-min-width">
                                    <span className="position-relative">
                                      <span>
                                        {item._source?.severity == "Critical" && (
                                          <Badge bg="danger" className="p-2">Critical</Badge>
                                        )}
                                        {item._source?.severity == "High" && (
                                          <Badge bg="danger" className="p-2">High</Badge>
                                        )}
                                        {item._source?.severity == "Medium" && (
                                          <Badge bg="warning" className="p-2">Medium</Badge>
                                        )}
                                        {item._source?.severity == "Low" && (
                                          <Badge bg="success" className="p-2">Low</Badge>
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                ) : data.value === "detection_status" && data.isSelected === true ? (
                                  <td className="auto-min-width">
                                    <span>
                                      {(item?._source?.detection_status)?.toLowerCase() === "new" && (
                                        <Badge bg="info" className="p-2">New</Badge>
                                      )}
                                      {(item?._source?.detection_status)?.toLowerCase() === "closed" && (
                                        <div className="position-relative">
                                          {/* <Badge
                                        onMouseOver={() => setShowClosedDetails({ visible: true, index: index })}
                                        onMouseOut={() => setShowClosedDetails({ visible: false, index: index })}
                                        bg="success"
                                        className="p-2">Closed</Badge>
                                      {showClosedDetails.visible && showClosedDetails.index === index && (
                                        <span className="shadow-sm p-1 bg-white rounded position-absolute"
                                          style={{ left: "50%", transform: "translateX(-50%)", bottom: "-25px", textAlign: "center", whiteSpace: "nowrap", color: item._source?.closed_by || item._source?.closed_at !== undefined ? "black" : "red" }}>
                                          {`${item._source?.closed_by !== undefined ? item._source?.closed_by : "__"} - ${item._source?.closed_at !== undefined ? timestampConvert(item._source?.closed_at, 'MMMM Do YYYY, h:mm:ss a') : "__"} `}
                                        </span>
                                      )} */}
                                          <CustomToolTip content={<Badge bg="success" className="p-2">Closed</Badge>} dataPlacement={"bottom"} dataToggle={
                                            <div className="d-flex flex-column justify-content-start">
                                              <span>Closed by: <strong>{item._source?.closed_by !== undefined ? item._source?.closed_by : "--"}</strong></span>
                                              <span>Closed on: <strong>{item._source?.closed_at !== undefined ? getDateFromTimestamp(item._source?.closed_at) : "--"}</strong></span>
                                              <span>Closed at: <strong>{item._source?.closed_at !== undefined ? getTimeFromTimestamp(item._source?.closed_at) : "--"}</strong></span>
                                            </div>
                                          } />
                                        </div>
                                      )}
                                      {(item?._source?.detection_status)?.toLowerCase() === "open" && (
                                        <Badge bg="warning" className="p-2">Open</Badge>
                                      )}
                                    </span>
                                  </td>
                                ) : data.value === "opened_by" && data.isSelected === true ? (
                                  <td className="font-monospace  auto-min-width">
                                    <span>
                                      {item?._source?.opened_by &&
                                        <CustomToolTip content={
                                          <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                            {(item?._source?.opened_by).charAt(0)}
                                          </span>} dataPlacement={"bottom"}
                                          dataToggle={
                                            <div className="d-flex flex-column text-left">
                                              <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Opened by - </span>
                                                <span><strong>{item?._source?.opened_by}</strong></span>
                                              </div>
                                              <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Opened on - </span>
                                                <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                              </div>
                                              <div className='d-flex flex-row gap-2 tooltip-row'>
                                                <span>Opened at - </span>
                                                <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                              </div>
                                            </div>
                                          } />
                                      }
                                    </span>
                                  </td>
                                ) : data.value === "threshold" && data.isSelected === true ? (
                                  <td className="font-monospace text-right auto-min-width">
                                    {item._source.type === "count based" ? (
                                      <>
                                        <span className="d-flex gap-2 justify-content-end" style={{ color: globalStyles.red }}>
                                          {item._source.count}
                                          {/* <span style={{ color: globalStyles.greyDark3, fontSize: "10px" }}>
                                      {"(Usual - " + item._source.threshold + ")"}
                                    </span> */}
                                        </span>

                                        <span style={{ color: globalStyles.greyDark3, fontSize: "10px" }}>
                                          {"Threshold - " + item._source.threshold}
                                        </span>
                                      </>
                                    ) : (
                                      <span>{item._source.count}</span>
                                    )}
                                  </td>
                                ) : data.value === "host_name" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source.host_name}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "host_name",
                                              label: "Host",
                                            }}
                                            value={item?._source.host_name}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "user_name" && data.isSelected === true ? (
                                  <td>
                                    <div className="d-flex gap-4 align-items-center">
                                      {/* <span className="table-row-icon rounded-circle">
                                      {getLastWordFromString(item?._source?.detection_name) === "user" ?
                                        <User size={14} /> :
                                        <ComputerTower size={14} />
                                      }
                                    </span> */}
                                      <span>

                                        {item._source.user_name}
                                        {showInlineFilter.show && showInlineFilter.index === index &&
                                          (
                                            <div className="inline-filter-wrap">
                                              <InlineFilter
                                                filter={filter}
                                                setFilter={setFilter}
                                                column={{
                                                  value: "user_name",
                                                  label: "Username",
                                                }}
                                                value={item?._source.user_name}
                                                getFilteredTable={getFilteredTable}
                                                module={module}
                                                tab={tab}
                                                dateFilter={dateFilter}
                                                setPage={setPage}

                                              />
                                            </div>
                                          )
                                        }
                                      </span>
                                    </div>
                                  </td>
                                ) : data.value === "UserId" && data.isSelected === true ? (
                                  <td>
                                    <div className="d-flex gap-4 align-items-center">
                                      {/* <span className="table-row-icon rounded-circle">
                                      {getLastWordFromString(item?._source?.detection_name) === "user" ?
                                        <User size={14} /> :
                                        <ComputerTower size={14} />
                                      }
                                    </span> */}
                                      <span>

                                        {item._source.UserId}
                                        {showInlineFilter.show && showInlineFilter.index === index &&
                                          (
                                            <div className="inline-filter-wrap">
                                              <InlineFilter
                                                filter={filter}
                                                setFilter={setFilter}
                                                column={{
                                                  value: "UserId",
                                                  label: "User ID",
                                                }}
                                                value={item?._source.UserId}
                                                getFilteredTable={getFilteredTable}
                                                module={module}
                                                tab={tab}
                                                dateFilter={dateFilter}
                                                setPage={setPage}

                                              />
                                            </div>
                                          )
                                        }
                                      </span>
                                    </div>
                                  </td>
                                ) : data.value === "email_receiver_address" && data.isSelected === true ? (
                                  <td className="text-break">
                                    <div className="d-flex gap-4 align-items-center">
                                      <span>
                                        {item._source?.email_receiver_address}
                                        {showInlineFilter.show && showInlineFilter.index === index &&
                                          (
                                            <div className="inline-filter-wrap">
                                              <InlineFilter
                                                filter={filter}
                                                setFilter={setFilter}
                                                column={{
                                                  value: "email_receiver_address",
                                                  label: "Receiver Address",
                                                }}
                                                value={item?._source?.email_receiver_address}
                                                getFilteredTable={getFilteredTable}
                                                module={module}
                                                tab={tab}
                                                dateFilter={dateFilter}
                                                setPage={setPage}

                                              />
                                            </div>
                                          )
                                        }
                                      </span>
                                    </div>
                                  </td>
                                ) : data.value === "ClientIP" && data.isSelected === true ? (
                                  <td>

                                    {item._source?.ClientIP}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "ClientIP",
                                              label: "Client IP",
                                            }}
                                            value={item?._source?.ClientIP}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "email_from_address" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.email_from_address}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "email_from_address",
                                              label: "Sender Address",
                                            }}
                                            value={item?._source?.email_from_address}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "user_domain" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.user_domain}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "user_domain",
                                              label: "Domain",
                                            }}
                                            value={item?._source?.user_domain}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "process_name" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.process_name}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "process_name",
                                              label: "Process Name",
                                            }}
                                            value={item?._source?.process_name}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "event_action" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.event_action}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "event_action",
                                              label: "Event Action",
                                            }}
                                            value={item?._source?.event_action}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "event_outcome" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.event_outcome}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "event_outcome",
                                              label: "Event Outcome",
                                            }}
                                            value={item?._source?.event_outcome}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "event_category" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.event_category}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "event_category",
                                              label: "Event Category",
                                            }}
                                            value={item?._source?.event_category}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "file_name" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.file_name}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "file_name",
                                              label: "File Name",
                                            }}
                                            value={item?._source?.file_name}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "file_path" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.file_path}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "file_path",
                                              label: "File Path",
                                            }}
                                            value={item?._source?.file_path}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "target_user" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.target_user}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "target_user",
                                              label: "Target Username",
                                            }}
                                            value={item?._source?.target_user}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "source_ip" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.source_ip}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "source_ip",
                                              label: "Source IP",
                                            }}
                                            value={item?._source?.source_ip}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "source_port" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.source_port}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "source_port",
                                              label: "Source Port",
                                            }}
                                            value={item?._source?.source_port}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "dest_ip" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.dest_ip}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "dest_ip",
                                              label: "Destination IP",
                                            }}
                                            value={item?._source?.dest_ip}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "dest_port" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.dest_port}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "dest_port",
                                              label: "Destination Port",
                                            }}
                                            value={item?._source?.dest_port}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "SourceFileName" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.SourceFileName}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "SourceFileName",
                                              label: "Source Filename",
                                            }}
                                            value={item?._source?.SourceFileName}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "Workload" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.Workload}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "Workload",
                                              label: "Workload",
                                            }}
                                            value={item?._source?.Workload}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "Operation" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.Operation}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "Operation",
                                              label: "Operation",
                                            }}
                                            value={item?._source?.Operation}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "ItemType" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.ItemType}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "ItemType",
                                              label: "Item Type",
                                            }}
                                            value={item?._source?.ItemType}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "EventSource" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.EventSource}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "EventSource",
                                              label: "Event Source",
                                            }}
                                            value={item?._source?.EventSource}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.cybSourceCountry}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "cybSourceCountry",
                                              label: "Source Country Name",
                                            }}
                                            value={item?._source?.cybSourceCountry}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "subject" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.subject}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "subject",
                                              label: "Subject",
                                            }}
                                            value={item?._source?.subject}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "delivery_action" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.delivery_action}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "delivery_action",
                                              label: "Delivery Action",
                                            }}
                                            value={item?._source?.delivery_action}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "country" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {item._source?.country}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "country.keyword",
                                              label: "Country",
                                            }}
                                            value={item?._source?.country}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : data.value === "detection_timestamp" && data.isSelected === true ? (
                                  <td className="text-break">
                                    {moment(item._source?.detection_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}
                                    {showInlineFilter.show && showInlineFilter.index === index &&
                                      (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "detection_timestamp",
                                              label: "Detection Timestamp",
                                            }}
                                            value={item?._source?.detection_timestamp}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )
                                    }
                                  </td>
                                ) : (<></>)
                              ))}

                              <td className="auto-min-width text-center">
                                {/* <a onClick={() => { setExpClickedNode(item._id); setExpClose(false) }} style={{ marginLeft: "20px" }}>
                                  <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                </a> */}
                                <Link onClick={() => { setExpClickedNode(item._id); setExpClose(false) }}>
                                  <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                </Link>
                                {/* <Link onClick={() => handleOpenModal(item, true, item._index, item?._id)} style={{ marginLeft: "20px" }}>
                                  <TableViewMore Icon={ArrowRight} dataToggle={"Detection Summary"} dataPlacement={"bottom"} />
                                </Link> */}
                                <Link to={`/ueba/summary/${item?._source?.group_id}`} state={{ data: item }} style={{ marginLeft: "20px" }}
                                  onClick={() => { handleInvestigationOpen(item._index, item?._id, item?._source?.detection_status) }}
                                >
                                  <TableViewMore Icon={ArrowRight} dataToggle={"Detection Summary"} dataPlacement={"bottom"} />
                                </Link>
                              </td>

                              {/* <td>
                            <Link to="/ueba/summary" state={{ detectionData: detailpage }}>
                              <TableViewMore Icon={Info} />
                            </Link>
                          </td> */}
                            </tr >
                          ))}
                        </tbody >
                      </Table >
                    ) : (
                      <>
                        {detectionDataError.isError ? (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} errorData={detectionDataError} />
                          </div>
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </>
                    )}
                  </>

                )}
              </div >
            </Tab >

            <Tab eventKey="timeline" title="Timeline">
              <div className="col-12" style={styles.contentWrap}>
                <div
                  className="col-12 position-relative h-100"
                  style={styles.recentDetectionsWrap}
                >
                  {timelineIsLoading ? (
                    <TimelineLoader />
                  ) : (
                    <>
                      {detectionTimeline.length !== 0 && !detectionTimelineError?.isError ? (
                        <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                          {detectionTimeline.map((item) => (
                            <li
                              className="d-flex flex-row gap-3 justify-content-center position-relative"
                              style={styles.detectionItem}
                            >
                              <span
                                className="dateLabel"
                                style={{ left: 0, top: 0 }}
                              >
                                {item[0][0]}
                              </span>
                              <div className="d-flex col-12 gap-3 flex-column px-3">
                                {item[0][1].map((item) => (
                                  <Link style={{ textDecoration: 'none', color: globalStyles.black }} to={`/ueba/summary/${item?._source?.group_id}`} state={{ data: item }}>
                                    <p className="timeline-item p-3 rounded-3 cursor-pointer"
                                      onClick={() => handleOpenModal(item, true)}
                                    >
                                      {/* {escapeHtmlChars(
                                item._source.description,
                                /(<\/?b>)/g
                              )} */}
                                      {rawHTML(item._source.description)}
                                    </p>
                                  </Link>
                                ))}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          {detectionTimelineError.isError ? (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} errorData={detectionTimelineError} />
                            </div>
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </>
                      )}
                    </>

                  )}
                </div>

                {/* old */}
                {/* {detectionData.map(({ sort, _source: { description, detection_timestamp } }) => (
                  <div className="single-detection">
                    <span style={{ color: "orange" }}>{detection_timestamp}</span>
                    <p style={{ fontWeight: "500" }}>{escapeHtmlChars(description, /(<\/?b>)/g)}</p>
                  </div>
                ))
                } */}
              </div>
              {/* <div className="px-4 d-flex" style={styles.paginationWrap}>
                <nav aria-label="Page navigation example">
                  <ul className="pagination mb-0">
                    <li
                      onClick={() => setPage(page - 1)}
                      className="page-item"
                      disabled={page <= 0 && setPage(1)}
                    >
                      <a className="page-link" href="#">
                        Previous
                      </a>
                    </li>

                    <li onClick={() => setPage(page + 1)} className="page-item">
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </Tab>
          </Tabs >

        </div >
        {/* <div className="detections col-12">
          {detectionData.map(({ sort, _source: { description } }) => (
            <div className="single-detection">
              <span style={{ color: "orange" }}>{sort}</span>
              <p style={{ fontWeight: "500" }}>{escapeHtmlChars(description, /(<\/?b>)/g)}</p>
            </div>
          ))
          }
        </div> */}
        < ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={localStorage.getItem("CYBOT_URL")} pattern={`cybot_proxy/ueba_group_details*/_search?track_total_hits=true`} req={fpostC} />

      </div >

      {show && (
        <CustomModal
          title={"Detection summary"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          modalSize={"xl"}
          footer={false}
          downloadBtn={false}
          noBodyPadding
          hasStickyTab
        ></CustomModal>
      )
      }

      {caseshow && (
        <EditModal
          setShow={setCaseShow}
          show={caseshow}
          autoHeight={false}
          caseidEdit={case_id}
          setCase_ID={setCase_ID}
          module={module}
          tab={tab}
          description_="as"
        />
      )
      }

      {/* close open modal */}

      {
        commentModalVisible && (
          <CustomModal
            title={detectionStatusCheck === 'closed' ? "Close Investigations" : "Open Investigations"}
            ShowOrHide={commentModalVisible}
            bodyData={renderCommentModal}
            handleClose={handleCloseModal}
            modalSize={"md"}
            footer={false}
            downloadBtn={false}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }

      {alertPopup && (
        <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={alertMessage} bgColor={"lightGreen"} />
      )}
    </>
  );
};

export default Detection;
