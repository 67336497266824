export default {
  "AccessInvitationAccepted": "The recipient of an invitation to view or edit a shared file (or folder) has accessed the shared file by clicking on the link in the invitation.",
  "AccessInvitationCreated": "User sends an invitation to another person (inside or outside their organization) to view or edit a shared file or folder on a SharePoint or OneDrive for Business site. The details of the event entry identifies the name of the file that was shared, the user the invitation was sent to, and the type of the sharing permission selected by the person who sent the invitation.",
  "AccessInvitationExpired": "An invitation sent to an external user expires. By default, an invitation sent to a user outside of your organization expires after 7 days if the invitation isn't accepted.",
  "AccessInvitationRevoked": "The site administrator or owner of a site or document in SharePoint or OneDrive for Business withdraws an invitation that was sent to a user outside your organization. An invitation can be withdrawn only before it's accepted.",
  "AccessInvitationUpdated": "The user who created and sent an invitation to another person to view or edit a shared file (or folder) on a SharePoint or OneDrive for Business site resends the invitation.",
  "AccessRequestApproved": "The site administrator or owner of a site or document in SharePoint or OneDrive for Business approves a user request to access the site or document.",
  "AccessRequestCreated": "User requests access to a site or document in SharePoint or OneDrive for Business that they don't have permission to access.",
  "AccessRequestRejected": "The site administrator or owner of a site or document in SharePoint declines a user request to access the site or document.",
  "ActivationEnabled": "Users can browser-enable form templates that don't contain form code, require full trust, enable rendering on a mobile device, or use a data connection managed by a server administrator.",
  "AdministratorAddedToTermStore": "Term store administrator added.",
  "AdministratorDeletedFromTermStore": "Term store administrator deleted.",
  "AllowGroupCreationSet": "Site administrator or owner adds a permission level to a SharePoint or OneDrive for Business site that allows a user assigned that permission to create a group for that site.",
  "AppCatalogCreated": "App catalog created to make custom business apps available for your SharePoint Environment.",
  "AuditPolicyRemoved": "Document LifeCycle Policy has been removed for a site collection.",
  "AuditPolicyUpdate": "Document LifeCycle Policy has been updated for a site collection.",
  "AzureStreamingEnabledSet": "A video portal owner has allowed video streaming from Azure.",
  "CollaborationTypeModified": "The type of collaboration allowed on sites (for example, intranet, extranet, or public) has been modified.",
  "ConnectedSiteSettingModified": "User has either created, modified, or deleted the link between a project and a project site or the user modifies the synchronization setting on the link in Project Web App.",
  "CreateSSOApplication": "Target application created in Secure store service.",
  "CustomFieldOrLookupTableCreated": "User created a custom field or lookup table/item in Project Web App.",
  "CustomFieldOrLookupTableDeleted": "User deleted a custom field or lookup table/item in Project Web App.",
  "CustomFieldOrLookupTableModified": "User modified a custom field or lookup table/item in Project Web App.",
  "CustomizeExemptUsers": "Global administrator customized the list of exempt user agents in SharePoint admin center.",
  "DefaultLanguageChangedInTermStore": "Language setting changed in the terminology store.",
  "DelegateModified": "User created or modified a security delegate in Project Web App.",
  "DelegateRemoved": "User deleted a security delegate in Project Web App.",
  "DeleteSSOApplication": "An SSO application was deleted.",
  "eDiscoveryHoldApplied": "An In-Place Hold was placed on a content source. In-Place Holds are managed by using an eDiscovery site collection (such as the eDiscovery Center) in SharePoint.",
  "eDiscoveryHoldRemoved": "An In-Place Hold was removed from a content source. In-Place Holds are managed by using an eDiscovery site collection (such as the eDiscovery Center) in SharePoint.",
  "eDiscoverySearchPerformed": "An eDiscovery search was performed using an eDiscovery site collection in SharePoint.",
  "EngagementAccepted": "User accepts a resource engagement in Project Web App.",
  "EngagementModified": "User modifies a resource engagement in Project Web App.",
  "EngagementRejected": "User rejects a resource engagement in Project Web App.",
  "EnterpriseCalendarModified": "User copies, modifies, or deletes an enterprise calendar in Project Web App.",
  "EntityDeleted": "User deletes a timesheet in Project Web App.",
  "EntityForceCheckedIn": "User forces a check-in on a calendar, custom field, or lookup table in Project Web App.",
  "ExemptUserAgentSet": "Global administrator adds a user agent to the list of exempt user agents in the SharePoint admin center.",
  "FileAccessed": "User or system account accesses a file on a SharePoint or OneDrive for Business site.",
  "FileCheckOutDiscarded": "User discards (or undos) a checked out file.",
  "FileCheckedIn": "User checks in a document that they checked out from a SharePoint or OneDrive for Business document library.",
  "FileCheckedOut": "User checks out a document located in a SharePoint or OneDrive for Business document library.",
  "FileCopied": "User copies a document from a SharePoint or OneDrive for Business site.",
  "FileDeleted": "User deletes a document from a SharePoint or OneDrive for Business site.",
  "FileDeletedFirstStageRecycleBin": "User deletes a file from the recycle bin on a SharePoint or OneDrive for Business site.",
  "FileDeletedSecondStageRecycleBin": "User deletes a file from the second-stage recycle bin on a SharePoint or OneDrive for Business site.",
  "FileDownloaded": "User downloads a document from a SharePoint or OneDrive for Business site.",
  "FileFetched": "This event has been replaced by the FileAccessed event, and has been deprecated.",
  "FileModified": "User or system account modifies the content or the properties of a document located on a SharePoint or OneDrive for Business site.",
  "FileMoved": "User moves a document from its current location on a SharePoint or OneDrive for Business site to a new location.",
  "FilePreviewed": "User previews a document on a SharePoint or OneDrive for Business site.",
  "FileRecycled": "User moves a document into the SharePoint or OneDrive Recycle Bin.",
  "FileRenamed": "User renames a document on a SharePoint or OneDrive for Business site.",
  "FileRestored": "User restores a document from the recycle bin of a SharePoint or OneDrive for Business site.",
  "FileSyncDownloadedFull": "User downloads a file to their computer from a SharePoint document library or OneDrive for Business using OneDrive sync app (OneDrive.exe).",
  "FileSyncDownloadedPartial": "This event has been deprecated along with the old OneDrive for Business sync app (Groove.exe).",
  "FileSyncUploadedFull": "User uploads a new file or changes to a file in SharePoint document library or OneDrive for Business using OneDrive sync app (OneDrive.exe).",
  "FileSyncUploadedPartial": "This event has been deprecated along with the old OneDrive for Business sync app (Groove.exe).",
  "FileUploaded": "User uploads a document to a folder on a SharePoint or OneDrive for Business site.",
  "FileViewed": "This event has been replaced by the FileAccessed event, and has been deprecated.",
  "FolderCopied": "User copies a folder from a SharePoint or OneDrive for Business site to another location in SharePoint or OneDrive for Business.",
  "FolderCreated": "User creates a folder on a SharePoint or OneDrive for Business site.",
  "FolderDeleted": "User deletes a folder from a SharePoint or OneDrive for Business site.",
  "FolderDeletedFirstStageRecycleBin": "User deletes a folder from the recycle bin on a SharePoint or OneDrive for Business site.",
  "FolderDeletedSecondStageRecycleBin": "User deletes a folder from the second-stage recycle bin on a SharePoint or OneDrive for Business site.",
  "FolderModified": "User modifies a folder on a SharePoint or OneDrive for Business site. This event includes folder metadata changes, such as tags and properties.",
  "FolderMoved": "User moves a folder from a SharePoint or OneDrive for Business site.",
  "FolderRecycled": "User moves a folder into the SharePoint or OneDrive Recycle Bin.",
  "FolderRenamed": "User renames a folder on a SharePoint or OneDrive for Business site.",
  "FolderRestored": "User restores a folder from the Recycle Bin on a SharePoint or OneDrive for Business site.",
  "GroupAdded": "Site administrator or owner creates a group for a SharePoint or OneDrive for Business site.",
  "GroupRemoved": "User deletes a group from a SharePoint or OneDrive for Business site.",
  "GroupUpdated": "Site administrator or owner changes the settings of a group for a SharePoint or OneDrive for Business site.",
  "LanguageAddedToTermStore": "Language added to the terminology store.",
  "LanguageRemovedFromTermStore": "Language removed from the terminology store.",
  "LegacyWorkflowEnabledSet": "Site administrator or owner adds the SharePoint Workflow Task content type to the site.",
  "LookAndFeelModified": "User modifies a quick launch, gantt chart formats, or group formats. Or the user creates, modifies, or deletes a view in Project Web App.",
  "ManagedSyncClientAllowed": "User successfully establishes a sync relationship with a SharePoint or OneDrive for Business site.",
  "MaxQuotaModified": "The maximum quota for a site has been modified.",
  "MaxResourceUsageModified": "The maximum allowable resource usage for a site has been modified.",
  "MySitePublicEnabledSet": "The flag enabling users to have public MySites has been set by the SharePoint administrator.",
  "NewsFeedEnabledSet": "Site administrator or owner enables RSS feeds for a SharePoint or OneDrive for Business site.",
  "ODBNextUXSettings": "New UI for OneDrive for Business has been enabled.",
  "OfficeOnDemandSet": "Site administrator enables Office on Demand, which lets users access the latest version of Office desktop applications.",
  "PageViewed": "User views a page on a SharePoint site or OneDrive for Business site.",
  "PeopleResultsScopeSet": "Site administrator creates or changes the result source for People Searches for a SharePoint site.",
  "PermissionSyncSettingModified": "User modifies the project permission sync settings in Project Web App.",
  "PermissionTemplateModified": "User creates, modifies, or deletes a permissions template in Project Web App.",
  "PortfolioDataAccessed": "User accesses portfolio content (driver library, driver prioritization, portfolio analyses) in Project Web App.",
  "PortfolioDataModified": "User creates, modifies, or deletes portfolio data (driver library, driver prioritization, portfolio analyses) in Project Web App.",
  "PreviewModeEnabledSet": "Site administrator enables document preview for a SharePoint site.",
  "ProjectAccessed": "User accesses project content in Project Web App.",
  "ProjectCheckedIn": "User checks in a project that they checked out from a Project Web App.",
  "ProjectCheckedOut": "User checks out a project located in a Project Web App.",
  "ProjectCreated": "User creates a project in Project Web App.",
  "ProjectDeleted": "User deletes a project in Project Web App.",
  "ProjectForceCheckedIn": "User forces a check-in on a project in Project Web App.",
  "ProjectModified": "User modifies a project in Project Web App.",
  "ProjectPublished": "User publishes a project in Project Web App.",
  "ProjectWorkflowRestarted": "User restarts a workflow in Project Web App.",
  "PWASettingsAccessed": "User accesses the Project Web App settings via CSOM.",
  "PWASettingsModified": "User modifies the a Project Web App configuration.",
  "QueueJobStateModified": "User cancels or restarts a queue job in Project Web App.",
  "QuotaWarningEnabledModified": "Storage quota warning modified.",
  "RenderingEnabled": "Browser-enabled form templates will be rendered by InfoPath forms services.",
  "ReportingAccessed": "User accessed the reporting endpoint in Project Web App.",
  "ReportingSettingModified": "User modifies the reporting configuration in Project Web App.",
  "ResourceAccessed": "User accesses an enterprise resource content in Project Web App.",
  "ResourceCheckedIn": "User checks in an enterprise resource that they checked out from Project Web App.",
  "ResourceCheckedOut": "User checks out an enterprise resource located in Project Web App.",
  "ResourceCreated": "User creates an enterprise resource in Project Web App.",
  "ResourceDeleted": "User deletes an enterprise resource in Project Web App.",
  "ResourceForceCheckedIn": "User forces a check-in of an enterprise resource in Project Web App.",
  "ResourceModified": "User modifies an enterprise resource in Project Web App.",
  "ResourcePlanCheckedInOrOut": "User checks in or out a resource plan in Project Web App.",
  "ResourcePlanModified": "User modifies a resource plan in Project Web App.",
  "ResourcePlanPublished": "User publishes a resource plan in Project Web App.",
  "ResourceRedacted": "User redacts an enterprise resource removing all personal information in Project Web App.",
  "ResourceWarningEnabledModified": "Resource quota warning modified.",
  "SSOGroupCredentialsSet": "Group credentials set in Secure store service.",
  "SSOUserCredentialsSet": "User credentials set in Secure store service.",
  "SearchCenterUrlSet": "Search center URL set.",
  "SearchQueryPerformed": "A user performs a search query in a SharePoint or OneDrive for Business site. This event is logged when a user enters a search query and initiates a search operation to retrieve results.",
  "SecondaryMySiteOwnerSet": "A user has added a secondary owner to their MySite.",
  "SecurityCategoryModified": "User creates, modifies, or deletes a security category in Project Web App.",
  "SecurityGroupModified": "User creates, modifies, or deletes a security group in Project Web App.",
  "SendToConnectionAdded": "Global administrator creates a new Send To connection on the Records management page in the SharePoint admin center.",
  "SendToConnectionRemoved": "Global administrator deletes a Send To connection on the Records management page in the SharePoint admin center.",
  "SharedLinkCreated": "User creates a link to a shared file in SharePoint or OneDrive for Business. This link can be sent to other people to give them access to the file.",
  "SharedLinkDisabled": "User disables (permanently) a link that was created to share a file.",
  "SharingInvitationAccepted": "User accepts an invitation to share a file or folder. This event is logged when a user shares a file with other users.",
  "SharingRevoked": "User unshares a file or folder that was previously shared with other users. This event is logged when a user stops sharing a file with other users.",
  "SharingSet": "User shares a file or folder located in SharePoint or OneDrive for Business with another user inside their organization.",
  "SiteAdminChangeRequest": "User requests to be added as a site collection administrator for a SharePoint site collection. Site collection administrators have full control permissions for the site collection and all subsites.",
  "SiteCollectionAdminAdded": "Site collection administrator or owner adds a person as a site collection administrator for a SharePoint or OneDrive for Business site. Site collection administrators have full control permissions for the site collection and all subsites.",
  "SiteCollectionCreated": "Global administrator creates a new site collection in your SharePoint organization.",
  "SiteRenamed": "Site administrator or owner renames a SharePoint or OneDrive for Business site.",
  "StatusReportModified": "User creates, modifies, or deletes a status report in Project Web App.",
  "SyncGetChanges": "User clicks Sync in the action tray on in SharePoint or OneDrive for Business to synchronize any changes to file in a document library to their computer.",
  "TaskStatusAccessed": "User accesses the status of one or more tasks in Project Web App.",
  "TaskStatusApproved": "User approves a status update of one or more tasks in Project Web App.",
  "TaskStatusRejected": "User rejects a status update of one or more tasks in Project Web App.",
  "TaskStatusSaved": "User saves a status update of one or more tasks in Project Web App.",
  "TaskStatusSubmitted": "User submits a status update of one or more tasks in Project Web App.",
  "TimesheetAccessed": "User accesses a timesheet in Project Web App.",
  "TimesheetApproved": "User approves timesheet in Project Web App.",
  "TimesheetRejected": "User rejects a timesheet in Project Web App.",
  "TimesheetSaved": "User saves a timesheet in Project Web App.",
  "TimesheetSubmitted": "User submits a status timesheet in Project Web App.",
  "UnmanagedSyncClientBlocked": "User tries to establish a sync relationship with a SharePoint or OneDrive for Business site from a computer that isn't a member of your organization's domain or is a member of a domain that hasn't been added to the list of domains (called the safe recipients list) that can access document libraries in your organization.",
  "UpdateSSOApplication": "Target application updated in Secure store service.",
  "UserAddedToGroup": "Site administrator or owner adds a person to a group on a SharePoint or OneDrive for Business site. Adding a person to a group grants the user the permissions that were assigned to the group.",
  "UserRemovedFromGroup": "Site administrator or owner removes a person from a group on a SharePoint or OneDrive for Business site. After the person is removed, they no longer are granted the permissions that were assigned to the group.",
  "WorkflowModified": "User creates, modifies, or deletes an Enterprise Project Type or Workflow phases or stages in Project Web App."
}
