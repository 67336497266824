import { useState, useEffect } from "react";
import { Button, Form, OverlayTrigger, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap";
import styles from "./AddFilter.style";
import { PlusCircle, Trash, PencilSimple, Info } from "phosphor-react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { uebaLabelList } from "../../Utils/Utils";
import moment from "moment";
import CustomModal from "../CustomModal/CustomModal";
import { fdeleteParams, fget, fpost } from "../../axios/axios";
import globalStyles from "../../constants/globalStyles";
import TableViewMore from "../ViewMoreBtn/TableViewMore";
import DialogBox from "../DialogBox/DialogBox";
import { filterDetails } from "../../constants/filterDetails";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'


const AddFilter = ({
  filter,
  setFilter,
  dateFilter,
  setDateFilter,
  setShowAddFilter,
  columnValues,
  getFilteredTable,
  module,
  tab,
  setPage
}) => {
  const [column, setColumn] = useState({});
  const [type, setType] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [addFilterList, setAddFilterList] = useState([]);
  const [startDate, setStartDate] = useState(dateFilter?.f);
  const [endDate, setEndDate] = useState(dateFilter?.e);
  const [sDate, setSDate] = useState(dateFilter?.from);
  const [eDate, setEDate] = useState(dateFilter?.to);
  const [selectedDateTab, setSelectedDateTab] = useState(dateFilter?.range);
  const [openModal, setOpenModal] = useState(false);
  const [isCustomFilterLoading, setIsCustomFilterLoading] = useState(false);
  const [customFilterList, setCustomFilterList] = useState([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState({});
  const [isCybotFilterLoading, setIsCybotFilterLoading] = useState(false);
  const [cybotFilterList, setCybotFilterList] = useState([]);
  const [selectedCybotFilter, setSelectedCybotFilter] = useState({});
  const [showOptions, setShowOption] = useState({
    show: false,
    index: 0,
  });
  // edit filter states
  const [showEditOption, setShowEditOption] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const today = moment().format("yyyy-MM-DDTHH:mm");
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  const tabList = (module) => {
    if (module === "investigation") {
      return ["cykit", "detection", 'main', "main-group", "main-atten", "cykit-firewall", "cykit-email", "cykit-ad", "cykit-waf", "cykit-atp-alerts", "cykit-o365ad", "cykit-o365ex", "cykit-o365onedrive"]
    } else if (module === "ueba") {
      return ["cykit", "detection", 'main', "main-group", "main-atten", "cykit-firewall", "cykit-email", "cykit-ad", "cykit-waf", "cykit-atp-alerts", "cykit-o365ad", "cykit-o365ex", "cykit-o365onedrive"]
    }
    else {
      return tab
    }
  }
  useEffect(() => {
    if (module === "investigation" && tab === "cybot_soc") {
      getDateRange(1, "days")

    }
  }, [1, "days"])

  useEffect(() => {
    if (column?.isWildCard) {
      if (column.value === "event.original") {
        setTypeList([
          { value: "contains", label: "Contains" },
          { value: "not contains", label: "Not Contains" }
        ]);
      }
      else {
        setTypeList([
          { value: "contains", label: "Contains" },
          { value: "not contains", label: "Not Contains" },
          { value: "is", label: "Is" },
          { value: "is not", label: "Is Not" },
        ]);
      }
    } else if (column?.isNumber) {
      setTypeList([
        { value: "gt", label: "Greater than" },
        { value: "lt", label: "Less than" },
        { value: "is", label: "Is" },
        { value: "is not", label: "Is Not" },
      ]);
    } else if (column?.value === "date" || column === "ip") {
      let temp = [
        ...typeList,
        { value: "gt", label: "Greater than" },
        { value: "lt", label: "Less than" },
        { value: "range", label: "In Range" },
      ];
      setTypeList(temp);
    } else
      setTypeList([
        { value: "is", label: "Is" },
        { value: "is not", label: "Is Not" },
      ]);
    setSelectedValue("");
  }, [column]);

  const getCustomFilters = async () => {
    setIsCustomFilterLoading(true);
    try {
      const response = await fget(
        `${apiBaseUrl}/filter/tenant`
      );
      // console.log(
      //   "response",
      //   response?.data?.filter(
      //     (item) => item?.module === module && item?.tab === tab
      //   )
      // );
      const customFilter = response?.data?.filter(
        (item) => item?.module === module && item?.tab === tab
      )
      setCustomFilterList(customFilter);
      setSelectedCustomFilter(customFilter?.length ? customFilter[0] : {});
      setIsCustomFilterLoading(false);
    } catch (error) {
      console.log("get customfilter error", error);

      setIsCustomFilterLoading(false);
    }
  };

  const handleCap = (str) => {
    setSelectedValue(str.charAt(0).toUpperCase() + str.slice(1))
  }

  const getCybotFilters = async () => {
    setIsCybotFilterLoading(true);
    try {
      const response = await fget(
        `${apiBaseUrl}/filter/master`
      );
      console.log(
        "cybot res",
        response?.data?.filter(
          (item) => item?.module === module && item?.tab === tab
        )
      );
      const cybotFilter = response?.data?.filter(
        (item) => item?.module === module && item?.tab === tab
      )
      setCybotFilterList(cybotFilter);
      setSelectedCybotFilter(cybotFilter?.length ? cybotFilter[0] : {});
      setIsCybotFilterLoading(false);
    } catch (error) {
      console.log("get cybotFilter error", error);
      setIsCybotFilterLoading(false);
    }
  };
  const pageReset = () => {
    if (setPage !== undefined) {
      setPage(1)
    }
  }
  const classificationList = [
    {
      "label": "Improbable",
      "value": "Improbable"
    },
    {
      "label": "Unlikely",
      "value": "Unlikely"
    },
    {
      "label": "Benign True Positive",
      "value": "Benign True Positive"
    },
    {
      "label": "Possible True Positive",
      "value": "Possible True Positive"
    },
  ]

  const severityList = [
    {
      "label": "Low",
      "value": "Low"
    },
    {
      "label": "Medium",
      "value": "Medium"
    },
    {
      "label": "High",
      "value": "High"
    },
    {
      "label": "Critical",
      "value": "Critical"
    },
  ]

  const statusList = [
    {
      "label": "Open",
      "value": "open"
    },
    {
      "label": "Closed",
      "value": "closed"
    },
    {
      "label": "New",
      "value": "new"
    },
  ]
  const getTimeText = () => {
    switch (selectedDateTab) {
      case 1:
        return "1 Day"
      case 7:
        return "7 Days"
      case 30:
        return "30 Days"
      case 365:
        return "1 Year"
      case 5:
        return "5 minutes"
      case 60:
        return "1 hour"
      case 1800:
        return "30 minutes"
      case 6:
        return "6 hour"
      default:
        return ""
    }
  }
  const applyFilter = (customFilter) => {


    let storedFilter = JSON.parse(localStorage.getItem("filters"));

    const isExisting = filter?.filter(
      (item) => item.selectedValue === selectedValue
    );

    // console.log("check", filter, isExisting);

    if (customFilter === "customFilter" || customFilter === "cybotFilter") {
      console.log("LOADED", selectedCustomFilter?.filter);

      const temp = customFilter === "customFilter" ? selectedCustomFilter?.filter : selectedCybotFilter?.filter
      console.log("temp", temp);

      setFilter(temp);
      let temp2 = {
        ...storedFilter,
        [module]: {
          ...storedFilter?.[module],
          [tab]: {
            ...storedFilter?.[module]?.[tab],
            fields: [...temp],
          },
        },
      };

      localStorage.setItem("filters", JSON.stringify(temp2));

      // const applyStateChanges = () => {
      // setIsCustomFilterApplyLoading(false);
      // setShowAddFilter(false);
      // setOpenModal(false);
      // };

      if (dateFilter?.to) {
        getFilteredTable(temp, dateFilter);
      } else {
        getFilteredTable(temp);
      }

      setShowAddFilter(false);
      setOpenModal(false);
    } else {
      // none selected
      if (
        !eDate &&
        !Object.values(column).length &&
        !type &&
        !selectedValue &&
        !addFilterList.length
      ) {
        console.log("none selected");
      }

      // all selected
      else if (
        eDate &&
        Object.values(column).length &&
        type &&
        selectedValue &&
        addFilterList.length
      ) {
        let temp = [];
        if (isExisting.length) {
          console.log(" exist");
          temp = [...filter, ...addFilterList];
        } else {
          console.log("non Exist");
          temp = [
            ...filter,
            ...addFilterList,
            {
              column: column,
              type: type,
              selectedValue: selectedValue,
              from: "",
              to: "",
              isInline: false,
            },
          ];
        }

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let dateTemp = {
          from: sDate,
          to: eDate,
          f: startDate,
          e: endDate,
          range: selectedDateTab,
        };
        setDateFilter(dateTemp);
        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { date: dateTemp, fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        getFilteredTable(temp, dateTemp);
        setShowAddFilter(false);
      }
      // date and field only selected
      else if (
        eDate &&
        Object.values(column).length &&
        type &&
        selectedValue &&
        !addFilterList.length
      ) {
        let temp = [];
        if (isExisting.length) {
          console.log(" exist");
          temp = [...filter];
        } else {
          console.log("non Exist");
          temp = [
            ...filter,
            {
              column: column,
              type: type,
              selectedValue: selectedValue,
              from: "",
              to: "",
              isInline: false,
            },
          ];
        }

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let dateTemp = {
          from: sDate,
          to: eDate,
          f: startDate,
          e: endDate,
          range: selectedDateTab,
        };
        setDateFilter(dateTemp);
        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { date: dateTemp, fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        getFilteredTable(temp, dateTemp);
        setShowAddFilter(false);
      }
      // date and list only selected
      else if (
        (eDate && !Object.values(column).length) ||
        !type ||
        (!selectedValue && addFilterList.length)
      ) {
        let temp = [...filter, ...addFilterList];

        setFilter(temp);
        pageReset()
        console.log("temp3", temp);

        let dateTemp = {
          from: sDate,
          to: eDate,
          f: startDate,
          e: endDate,
          range: selectedDateTab,
        };
        setDateFilter(dateTemp);
        let tabs = tabList(module)
        const moduleList = ["investigation","ueba","hunter"]
        
        let temp2 = moduleList.reduce((acc, module) => ({
          ...acc,
          [module]: {
            ...storedFilter?.[module],
            ...Array.isArray(tabs) ? tabs.reduce((acc, t) => ({ ...acc, [t]: { date: dateTemp, fields: [...temp] } }), {}) : { [tabs]: { date: dateTemp, fields: [...temp] } },
          },
        }), storedFilter);
         
        let filterText = getTimeText()
        localStorage.setItem("lte", eDate)
        localStorage.setItem("gte", sDate)
        localStorage.setItem("filter", filterText)
        localStorage.setItem("filters", JSON.stringify(temp2));

        getFilteredTable(temp, dateTemp);
        setShowAddFilter(false);
      }
      // field and list only selected
      else if (
        !eDate &&
        Object.values(column).length &&
        type &&
        selectedValue &&
        addFilterList.length
      ) {
        let temp = [];
        if (isExisting.length) {
          console.log(" exist");
          temp = [...filter, ...addFilterList];
        } else {
          console.log("non Exist");
          temp = [
            ...filter,
            ...addFilterList,
            {
              column: column,
              type: type,
              selectedValue: selectedValue,
              from: "",
              to: "",
              isInline: false,
            },
          ];
        }

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { ...storedFilter?.[module]?.[tab], fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        if (dateFilter?.to) {
          getFilteredTable(temp, dateFilter);
        } else {
          getFilteredTable(temp);
        }
        setShowAddFilter(false);
      }
      // only date selected
      else if (
        eDate &&
        (!Object.values(column).length || !type || !selectedValue) &&
        !addFilterList.length
      ) {
        let temp = [...filter];

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let dateTemp = {
          from: sDate,
          to: eDate,
          f: startDate,
          e: endDate,
          range: selectedDateTab,
        };
        setDateFilter(dateTemp);
        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { date: dateTemp, fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        getFilteredTable(temp, dateTemp);
        setShowAddFilter(false);
      }
      // only list selected
      else if (
        !eDate &&
        (!Object.values(column).length || !type || !selectedValue) &&
        addFilterList.length
      ) {
        let temp = [...filter, ...addFilterList];

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { ...storedFilter?.[module]?.[tab], fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        if (dateFilter?.to) {
          getFilteredTable(temp, dateFilter);
        } else {
          getFilteredTable(temp);
        }

        setShowAddFilter(false);
      }
      // only field is selected
      else if (
        !eDate &&
        Object.values(column).length &&
        type &&
        selectedValue &&
        !addFilterList.length
      ) {
        let temp = [];
        if (isExisting.length) {
          console.log(" exist");
          temp = [...filter];
        } else {
          // console.log("non Exist");
          temp = [
            ...filter,
            {
              column: column,
              type: type,
              selectedValue: selectedValue,
              from: "",
              to: "",
              isInline: false,
            },
          ];
        }

        setFilter(temp);
        pageReset()
        console.log("temp", temp);

        let temp2 = {
          ...storedFilter,
          [module]: {
            ...storedFilter?.[module],
            [tab]: { ...storedFilter?.[module]?.[tab], fields: [...temp] },
          },
        };

        localStorage.setItem("filters", JSON.stringify(temp2));

        if (dateFilter?.to) {
          getFilteredTable(temp, dateFilter);
        } else {
          getFilteredTable(temp);
        }
        setShowAddFilter(false);
      } else {
        console.log("hello");
      }
    }
  };

  const addFilter = () => {
    //
    const isExisting = filter?.filter(
      (item) => item.selectedValue === selectedValue
    );
    const isExisting2 = addFilterList?.filter(
      (item) => item.selectedValue === selectedValue
    );
    console.log("isExisting", !isExisting.length);
    console.log("isExisting2", !isExisting2.length);

    if (!isExisting.length) {
      if (!isExisting2.length) {
        //
        console.log(column, type, selectedValue);
        if (column && type && selectedValue) {
          let temp = [
            ...addFilterList,
            {
              column: column,
              type: type,
              selectedValue: selectedValue,
              from: "",
              to: "",
              isInline: false,
            },
          ];
          setAddFilterList(temp);

          let temp2 = {
            label: "",
            value: "",
          };
          setColumn(temp2);
          setType("");
          setSelectedValue("");
        }
        //
      }
    }
  };

  const editFilter = async () => {
    setIsEditLoading(true)
    const data = {
      id: selectedCustomFilter?.id,
      name: title || selectedCustomFilter?.name || "",
      module: selectedCustomFilter?.module,
      tab: selectedCustomFilter?.tab,
      description: desc || selectedCustomFilter?.description || "",
      filter: selectedCustomFilter?.filter,
    };
    try {
      const response = await fpost(
        `${apiBaseUrl}/filter/tenant`,
        data
      );
      console.log("response", response);

      try {
        await fget(
          `${apiBaseUrl}/filter/tenant/refresh`
        );
        setIsEditLoading(false)
        setShowEditOption(false)
        getCustomFilters()
      } catch (error) {
        console.log("refresh error", error);
      }
    } catch (error) {
      console.log("update error", error);
    }

  };

  const deleteFilter = async () => {
    setIsDeleteLoading(true)
    console.log("selec", selectedCustomFilter?.id, selectedCustomFilter);
    try {
      const response = await fdeleteParams(
        `${apiBaseUrl}/filter/tenant?id=${selectedCustomFilter?.id}`,
      );
      console.log("response", response);

      try {
        await fget(
          `${apiBaseUrl}/filter/tenant/refresh`
        );
        setIsDeleteLoading(false)
        setOpenDialogBox(false)
        getCustomFilters()
      } catch (error) {
        console.log("refresh error", error);
      }
    } catch (error) {
      console.log("update error", error);
    }
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      width: "100px",
      minHeight: "28px",
    }),
    option: (styles) => ({
      ...styles,
    }),
    menu: (styles) => ({
      ...styles,
      width: "200px",
    }),
  };

  // useEffect(() => {
  //   getDateRange(1, "days")
  //   }, [1, "days"])

  const getDateRange = (number, type) => {
    setSelectedDateTab(number);
    // const today = moment();
    // setEndDate(today);
    // setEDate(today.format("yyyy-MM-DD"));
    setEndDate(today);
    setEDate(today);
    if (type === "hours") {
      const lastNumberDate = moment()
        .subtract(number, "hours")
        ?.format("yyyy-MM-DDTHH:mm");
      setStartDate(lastNumberDate);
      setSDate(lastNumberDate);
    }
    else if (type === "seconds") {
      const lastNumberDate = moment()
        .subtract(number, "seconds")
        ?.format("yyyy-MM-DDTHH:mm");
      setStartDate(lastNumberDate);
      setSDate(lastNumberDate);
    }
    else if (type === "minutes") {
      const lastNumberDate = moment()
        .subtract(number, "minutes")
        ?.format("yyyy-MM-DDTHH:mm");
      setStartDate(lastNumberDate);
      setSDate(lastNumberDate);
    }
    else {
      // const lastNumberDate = moment().subtract(number, "days")
      // setStartDate(lastNumberDate);
      // setSDate(lastNumberDate.format("yyyy-MM-DD"));
      const lastNumberDate = moment()
        .subtract(number, "days")
        ?.format("yyyy-MM-DDTHH:mm");
      setStartDate(lastNumberDate);
      setSDate(lastNumberDate);
    }
  };

  const renderModal = () => (
    <div className="ignore-onclickoutside p-3">
      <Tabs
        defaultActiveKey="custom"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="custom" title="Custom">
          {isCustomFilterLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              {customFilterList?.length ? (
                <div className="col-12">
                  <div className="d-flex flex-row">
                    <div
                      className="d-flex col-12 flex-column gap-3"
                      style={{ flex: 1, height: "400px", overflow: "auto", paddingRight: "1rem" }}
                    >
                      {customFilterList?.map((item, index) => (
                        <div
                          className="d-flex flex-column p-3 rounded"
                          style={{
                            backgroundColor:
                              item?.id === selectedCustomFilter?.id
                                ? globalStyles.yellowLite1
                                : globalStyles.greyLite1,
                            position: 'relative'
                          }}
                          type="button"
                          onClick={() => {
                            setSelectedCustomFilter(item)
                            setShowEditOption(false)
                          }
                          }
                          onMouseOver={() =>
                            setShowOption({ show: true, index: index })
                          }
                          onMouseOut={() =>
                            setShowOption({ show: false, index: index })
                          }
                        >
                          <h6>{item.name}</h6>
                          <p className="mb-0">{item.description}</p>
                          {showOptions.show && showOptions.index === index &&
                            <div style={{ position: 'absolute', right: 5, top: 5 }}>
                              <PencilSimple
                                style={{
                                  padding: '5px'
                                }}
                                size={22}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedCustomFilter(item)
                                  setShowEditOption(true)
                                }}
                              />
                              <Trash
                                style={{
                                  padding: '5px'
                                }}
                                size={22}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedCustomFilter(item)
                                  setOpenDialogBox(true)
                                }}
                              />
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                    <div
                      className="d-flex flex-column p-2 px-4 justify-content-between"
                      style={{ flex: 2 }}
                    >
                      <div className="d-flex flex-column">
                        <span className="text-muted">Title</span>
                        {showEditOption ?
                          <Form.Control
                            type="text"
                            defaultValue={selectedCustomFilter?.name || ""}
                            className="mb-4"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          :
                          <span className="text-dark mb-4">
                            {selectedCustomFilter?.name || ""}
                          </span>
                        }

                        <span className="text-muted">Description</span>
                        {showEditOption ?
                          <Form.Control
                            as="textarea"
                            rows={3}
                            defaultValue={selectedCustomFilter?.description || ""}
                            className="mt-3"
                            onChange={(e) => setDesc(e.target.value)}
                          />

                          :
                          <span className="text-dark mb-4">
                            {selectedCustomFilter?.description || ""}
                          </span>
                        }

                        <span className="text-muted">Module</span>
                        <span className="text-dark mb-4">
                          {selectedCustomFilter?.module || ""}
                        </span>
                        <span className="text-muted">Filters Applied</span>
                        {selectedCustomFilter?.filter?.map((item, index) => (
                          <span>
                            {item?.column?.label} <strong>{item?.type}</strong> {item?.selectedValue}
                          </span>
                        ))}

                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse" style={styles.footerWrap}>
                    <Button
                      style={{
                        width: "100px",
                      }}
                      onClick={() => {
                        showEditOption ? editFilter() : applyFilter("customFilter")
                      }}
                    >
                      {showEditOption ? isEditLoading ?
                        <Spinner animation="border" role="status" size="sm" />
                        :
                        "Save" : "Apply"}
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  No saved filters found.
                </div>
              )}
            </>
          )}
        </Tab>
        <Tab eventKey="cybot" title="Cybot">
          {isCybotFilterLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              {cybotFilterList?.length ? (
                <div className="col-12">
                  <div className="d-flex flex-row">
                    <div
                      className="d-flex col-12 flex-column gap-3"
                      style={{ flex: 1, height: "400px", overflow: "auto", paddingRight: "1rem" }}
                    >
                      {cybotFilterList?.map((item) => (
                        <div
                          className="d-flex flex-column p-3 rounded"
                          style={{
                            backgroundColor:
                              item?.id === selectedCybotFilter?.id
                                ? globalStyles.yellowLite1
                                : globalStyles.greyLite1,
                          }}
                          type="button"
                          onClick={() => setSelectedCybotFilter(item)}
                        >
                          <h6>{item.name}</h6>
                          <p className="mb-0">{item.description}</p>
                        </div>
                      ))}
                    </div>
                    <div
                      className="d-flex flex-column p-2 px-4 justify-content-between"
                      style={{ flex: 2 }}
                    >
                      <div className="d-flex flex-column">
                        <span className="text-muted">Title</span>
                        <span className="text-dark mb-4">
                          {selectedCybotFilter.name || ""}
                        </span>

                        <span className="text-muted">Description</span>
                        <span className="text-dark mb-4">
                          {selectedCybotFilter.description || ""}
                        </span>

                        <span className="text-muted">Module</span>
                        <span className="text-dark mb-4">
                          {selectedCybotFilter.module || ""}
                        </span>
                        <span className="text-muted">Filters Applied</span>
                        {selectedCybotFilter?.filter?.map((item, index) => (
                          <span>
                            {item?.column?.label} <strong>{item?.type}</strong> {item?.selectedValue}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <Button
                      style={{
                        width: "100px",
                      }}
                      onClick={() => {
                        applyFilter("cybotFilter");
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  No saved filters found.
                </div>
              )}
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );

  return (
    <div
      className="position-absolute mt-3 shadow bg-white p-3 rounded-3 ignore-onclickoutside"
      style={styles.filterMenu}
    >
      <div className="d-flex flex-column mb-3">
        <div className="d-flex flex-row gap-2 text-center mb-2">
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 5 ? "grey" : "",
            }}
            onClick={() => getDateRange(5, "minutes")}
          >
            5 Minutes
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 1800 ? "grey" : "",
            }}
            onClick={() => getDateRange(1800, "seconds")}
          >
            30 Minutes
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 60 ? "grey" : "",
            }}
            onClick={() => getDateRange(60, "minutes")}
          >
            1 Hours
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 6 ? "grey" : "",
            }}
            onClick={() => getDateRange(6, "hours")}
          >
            6 Hours
          </span>
        </div>
        <div className="d-flex flex-row gap-2 text-center">
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 1 ? "grey" : "",
            }}
            onClick={() => getDateRange(1)}
          >
            1 Day
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 7 ? "grey" : "",
            }}
            onClick={() => getDateRange(7)}
          >
            7 Days
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 30 ? "grey" : "",
            }}
            onClick={() => getDateRange(30)}
          >
            30 Days
          </span>
          <span
            className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
            style={{
              backgroundColor: selectedDateTab === 365 ? "grey" : "",
            }}
            onClick={() => getDateRange(365)}
          >
            1 Year
          </span>
        </div>
      </div>
      <div className="d-flex flex-column mb-3">
        <h6>Timeframe</h6>
        <div className="d-flex flex-row gap-3 form-group">
          {/* <DatePicker
            selected={startDate ? new Date(startDate) : startDate}
            onChange={(date) => {
              const formattedDate = moment(date).format("yyyy-MM-DD");
              setStartDate(date);
              setSDate(formattedDate);
              setSelectedDateTab();
            }}
            maxDate={new Date()}
            placeholderText="From"
          /> */}
          {/* <label for="session-date">Start date:</label> */}
          <DatePicker
            showTimeSelect
            className="form-control"
            placeholderText="dd/mm/yyyy,--:--"
            minDate={new Date(0, 0, 0, 12, 30)}
            maxDate={new Date()}
            // minTime={new Date(0, 0, 0, 0, 0)}
            // maxTime={new Date(0, 0, 0, 23, 59)}
            timeIntervals={10}
            selected={sDate ? new Date(sDate) : ""}
            onChange={(e) => {
              console.log(e);
              setStartDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
              setSDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
            }}
            dateFormat="MMMM d, yyyy h:mmaa"
          />
          {/* <input
            className="form-control w-50"
            type="datetime-local"
            id="datetime"
            name="datetime"
            max={today}
            value={sDate ? sDate : ""}
            onChange={(e) => {
              console.log(e);
              setStartDate(e.target.value);
              setSDate(e.target.value);
            }}
          /> */}
          {/* <DatePicker
            selected={endDate ? new Date(endDate) : endDate}
            onChange={(date) => {
              const formattedDate = moment(date).format("yyyy-MM-DD");
              setEndDate(date);
              setEDate(formattedDate);
              setSelectedDateTab();
            }}
            minDate={startDate ? new Date(startDate) : startDate}
            maxDate={new Date()}
            placeholderText="To"
          /> */}
          <DatePicker
            showTimeSelect
            className="form-control"
            placeholderText="dd/mm/yyyy,--:--"
            // minTime={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
            // maxTime={new Date()}
            minDate={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
            maxDate={new Date()}
            timeIntervals={10}

            // startDate={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
            selected={eDate ? new Date(eDate) : ""}
            onChange={(e) => {
              console.log(e);
              setEndDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
              setEDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
            }}
            dateFormat="MMMM d, yyyy h:mmaa"
          />
          {/* <input
            className="form-control w-50"
            type="datetime-local"
            id="datetime"
            name="datetime"
            min={sDate ? sDate : ""}
            max={today}
            value={eDate ? eDate : ""}
            onChange={(e) => {
              console.log(e.target.value);
              setEndDate(e.target.value);
              setEDate(e.target.value);
            }}
          /> */}
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex gap-1">
          <h6>Filters</h6>
          {filterDetails?.[module]?.[tab] && <OverlayTrigger placement={"bottom"} rootClose trigger={["click"]}
            overlay={
              <Tooltip className='tooltip-auto-width' id={`tooltip-bottom`} style={{ "maxWidth": "500px", maxHeight: "600px", overflow: "auto" }} >
                <div>
                  <ul>
                    {filterDetails?.[module]?.[tab]?.map(item => <li className="text-justify mb-1"> <div><strong>{item.name}</strong><span> - </span><span>{item.description}</span>
                    </div>  </li>)}
                  </ul>
                </div>
              </Tooltip>
            }>
            <Info size={14} color="#6d3c3c" />

          </OverlayTrigger>}
        </div>
        <div className="d-flex flex-row gap-3">
          <Form.Select
            style={{ width: "100px" }}
            value={column.value}
            onChange={(e) => {
              let temp = {
                label: e.target.options[e.target.selectedIndex].text,
                value: e.currentTarget.value,
                isWildCard:
                  e.target.options[e.target.selectedIndex].getAttribute(
                    "data-isWildCard"
                  ),
                isNumber:
                  e.target.options[e.target.selectedIndex].getAttribute(
                    "data-isNumber"
                  ),
              };
              // console.log("TEMP", temp);
              setColumn(temp);
              setType(temp?.isWildCard ? "contains" : "is");
            }}
          >
            <option >Select</option>
            {columnValues
              ?.sort((a, b) => a.label.localeCompare(b.label))
              ?.map((item) => (
                <option
                  value={item.value}
                  data-isWildCard={item.isWildCard}
                  data-isNumber={item.isNumber}
                >
                  {item.label}
                </option>
              ))}
          </Form.Select>
          <Form.Select
            style={{ width: "100px" }}
            value={type}
            onChange={(e) => setType(e.currentTarget.value)}
          >
            <option >Select</option>
            {typeList?.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Form.Select>
          {column.value === "detection_name" && module === "ueba" ? (
            <Select
              styles={customStyles}
              options={uebaLabelList}
              onChange={(e) => setSelectedValue(e.label)}
            />
          ) : column.value === "threat_classification" && module === "investigation" ? (
            <Select
              styles={customStyles}
              options={classificationList}
              onChange={(e) => setSelectedValue(e.label)}
            />) : column.value === "severity" && module === "ueba" ? (
              <Select
                styles={customStyles}
                options={severityList}
                onChange={(e) => setSelectedValue(e.value)}
              />
            ) : column.value === "detection_severity" && module === "investigation" ? (
              <Select
                styles={customStyles}
                options={severityList}
                onChange={(e) => setSelectedValue(e.label)}
              />
            ) : column.value === "detection_status" && (module === "investigation" || module === "ueba" || module === "hunter") ? (
              <Select
                styles={customStyles}
                options={statusList}
                onChange={(e) => setSelectedValue(e.value)}
              />
            ) : column.value === "country.keyword" && module === "ueba" ? (
              <Form.Control
                style={{ width: "100px" }}
                type="text"
                placeholder="Type here..."
                value={selectedValue}
                onChange={(e) => handleCap(e.currentTarget.value)}
              />
            ) : (
            <Form.Control
              style={{ width: "100px" }}
              type="text"
              placeholder="Type here..."
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.currentTarget.value)}
            />
          )}
          <div>
            {/* <PlusCircle
              size={25}
              weight="bold"
              type="button"
              onClick={addFilter}
            /> */}
            <TableViewMore Icon={PlusCircle} size={25} dataToggle={"Add"} dataPlacement={"bottom"} onClick={addFilter} />
          </div>
        </div>
      </div>

      {addFilterList.length > 0 &&
        addFilterList?.map((item) => (
          <div className="d-flex flex-row gap-1 p-2">
            <span>{item.column?.label}</span>
            <span className="px-2 rounded-1 text-white bg-info">
              {item.type}
            </span>
            <span>{item.selectedValue}</span>
          </div>
        ))}
      <div className="d-flex flex-row gap-2 justify-content-between">
        <Button
          variant="light"
          onClick={() => {
            setOpenModal(true);
            getCustomFilters();
            getCybotFilters()
          }}
        >
          Load Filters
        </Button>
        <div className="d-flex flex-row gap-2" >
          <Button
            variant="dark"
            onClick={() => {
              setAddFilterList([]);
              setStartDate();
              setSDate("");
              setEndDate();
              setEDate("");
              setColumn({ value: "", label: "" });
              setType("");
              setSelectedValue("");
            }}
          >
            Clear All
          </Button>
          <Button onClick={applyFilter}>Apply</Button>
        </div>
      </div>
      <CustomModal
        title={"Load Filters"}
        ShowOrHide={openModal}
        bodyData={renderModal}
        handleClose={() => setOpenModal(false)}
        footer={false}
        modalSize="lg"
        autoHeight={true}
      />
      <DialogBox
        title={"Are you sure you want to delete this filter?"}
        ShowOrHide={openDialogBox}
        handleClose={() =>
          setOpenDialogBox(false)
        }
        onConfirm={deleteFilter}
        isLoading={isDeleteLoading}
      />
    </div>
  );
};

export default AddFilter;