import React, { useCallback, useEffect, useState } from "react";
import styles from "../Activities.styles";
import {
  Form,
  Tab,
  Tabs,
  Table,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import CyEmail from "./CyEmail";
import Office365OneDrive from "./Office365OneDrive";
import Office365Exchange from "./Office365Exchange";
import Office365ActiveDirectory from "./Office365ActiveDirectory";
import { numberToLetter } from "../../../../Utils/Utils";
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

const OfficeMerge = ({ selectedDateFilter, globalSearch, init, dateFilter, setDateFilter }) => {
  // const [dateFilter, setDateFilter] = useState();

  const [azureAdPageCount, setAzureAdPageCount] = useState(0)
  const [exchangePageCount, setExchangePageCount] = useState(0)
  const [oneDrivePageCount, setOneDrivePageCount] = useState(0)
  const [emailPageCount, setEmailPageCount] = useState(0)

  return (
    <>
      <div
        className="col-12  position-relative rounded-3 w-100"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
          style={styles.filterWrap}
        >


        </div>

        <div className="detections col-12">
          <Tabs
            defaultActiveKey="ad"
            id="uncontrolled-tab-example"
            className="mb-3"
          // onSelect={(e) => setActiveTab(e)}
          >

            <Tab
              eventKey="ad"
              // title="Process"
              title={
                <React.Fragment>
                  Azure AD
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(azureAdPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Office365ActiveDirectory globalSearch={globalSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} azureAdPageCount={azureAdPageCount} setAzureAdPageCount={setAzureAdPageCount} init={init} />
            </Tab>

            <Tab
              eventKey="exchange"
              // title="Process"
              title={
                <React.Fragment>
                  Exchange
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(exchangePageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Office365Exchange globalSearch={globalSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} exchangePageCount={exchangePageCount} setExchangePageCount={setExchangePageCount} init={init} />
            </Tab>
            <Tab
              eventKey="onedrive"
              // title="Process"
              title={
                <React.Fragment>
                  One Drive
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(oneDrivePageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <Office365OneDrive globalSearch={globalSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} oneDrivePageCount={oneDrivePageCount} setOneDrivePageCount={setOneDrivePageCount} init={init} />
            </Tab>
            <Tab
              eventKey="email"
              // title="Process"
              title={
                <React.Fragment>
                  Email
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(emailPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <CyEmail globalSearch={globalSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} emailPageCount={emailPageCount} setEmailPageCount={setEmailPageCount} init={init} />
            </Tab>

          </Tabs>
        </div>

      </div>
    </>
  );
};

export default OfficeMerge;