import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDateFromTimestamp } from "../../Utils/Utils";
import CustomModal from "../../components/CustomModal/CustomModal";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { toastsSuccess, toastsDanger, toastsWarning } from "../Toster/Toster";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Eye, Plus, Trash, Minus } from "phosphor-react";
import TableViewMore from "../ViewMoreBtn/TableViewMore";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";


import { Button, Form } from "react-bootstrap";


import Loader from "../Loader/Loader";
import {
  createCaseManagement, singleListView, listUser,
  editCaseManagement, caseManagementTableData, createCaseAttached,
  categoryList
}
  from "../../Methods/CaseManagementMethods"
import AttachedId from "./AttachedId";






const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }
};


export default function EditModal({
  setShow,
  show,
  caseidEdit,
  setCase_ID,
  handleActivity,
  id,
  module,
  tab,
  idd,
  preModule,
  description_,
  attachmentList,
  isExisting,
  severity_
}) {

  const setDataInEditList = () => {

    let cat = { value: case_?.category, label: case_?.category }


    setAttachedCaseIds(caseidEdit === '' ? [""] : case_?.attached_cases)

    setTitle(caseidEdit === '' ? '' : case_?.case_title);
    setDescription(caseidEdit === '' ? '' : case_?.case_description);
    setCaseName(caseidEdit === '' ? '' : case_?.case_id);
    setCaseID(caseidEdit === '' ? '' : case_?.case_id);
    setSeverity(caseidEdit === '' ? '' : case_?.severity);
    setCategory(caseidEdit === '' ? '' : cat);
    setState(caseidEdit === '' ? '' : case_?.state);
    setAssigned_to(caseidEdit === '' ? '' : case_?.assigned_to);
    setCreate_Date(caseidEdit === '' ? '' : case_?.case_created);
    setClosed_Date(caseidEdit === '' ? '' : case_?.case_closed);
  }






  const dispatch = useDispatch();


  const [case_id, setCaseID] = useState("");
  const [severity, setSeverity] = useState("");
  const [category, setCategory] = useState("");
  const [state_, setState] = useState("open");
  const [Assigned_to, setAssigned_to] = useState("");
  const [case_created, setCreate_Date] = useState("");
  const [case_closed, setClosed_Date] = useState("");
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [name, setCaseName] = useState("");
  const [isSubmit, setIfSubmit] = useState(false);

  const [categoriesList, setCategoriesList] = useState([]);

  const [newCat, setNewCat] = useState("");
  const [defSev, setDefSev] = useState("");
  const [defCat, setDefCat] = useState("");
  const [defAssign, setDefAssign] = useState("");
  const [defDesp, setDefDesp] = useState("");

  const [radioValue, setRadioValue] = useState('1');
  const [caseList, setCaseList] = useState([]);

  const [attachment, setAttachment] = useState();

  const [attachedCaseIds, setAttachedCaseIds] = useState([""]);

  const [eventInCategory, setEventInCategory] = useState({});
  const [eventInAssignTo, setEventInAssignTo] = useState({});
  const [eventInSeverity, setEventInSeverity] = useState({});


  const radios = [
    { name: 'Create Case', value: '1' },
    { name: 'Add to Existing case', value: '2' },

  ];



  const caseData = useSelector((state) => state.singleListView);
  const { case: case_, loading } = caseData;



  useEffect(() => {

    if (caseidEdit) {
      dispatch(singleListView(caseidEdit));
    }

    listUser().then((res) => {

      setUsers(res.data)
    })

    getCaseList()
  }, [])

  useEffect(() => {
    if (caseidEdit) {
      setDataInEditList()
    } else {
      setTitle(id ? id : "");
      setDescription(description_ ? description_ : "");
      setCaseName("");
      setCaseID("");
      setSeverity(severity_ ? severity_.toLowerCase() : "");
      setCategory("");
      setState("");
      setAssigned_to("");
      setCreate_Date("");
      setClosed_Date("");
    }
  }, [case_])

  useEffect(() => {
    categoryList().then((res) => {

      const expectedOutput = res.data.categories?.map((output) => {
        return { value: output, label: output };
      });
      setCategoriesList(expectedOutput)
    })
  }, [])




  const onSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to save changes?")) {
        setIfSubmit(true);

        // Prepare the data object with the selected or newly created category
        const data = {
            severity,
            category: category?.value,  // Make sure to use category.value here
            state: "Open",
            assigned_to: Assigned_to,
            case_created,
            case_closed: "FALSE",
            title: title,
            description,
            attached_cases: attachedCaseIds
        };

        if (module !== 'casemanagement') {
            if (attachmentList && attachmentList.length > 0) {
                data.attached_cases = attachmentList;
            } else {
                data.attached_cases = attachedCaseIds;
            }
        }

        if (caseidEdit === '') {
            // Create new case
            dispatch(createCaseManagement(data));
        } else {
            // Edit existing case
            data.case_id = case_id;
            dispatch(editCaseManagement(data));

            // Handle any changes in category, assigned_to, or severity
            if (eventInCategory.case_id) {
                handleActivity(eventInCategory.case_id, eventInCategory.task, eventInCategory.newVal, eventInCategory.oldVal);
            }
            if (eventInAssignTo.case_id) handleActivity(eventInAssignTo.case_id, eventInAssignTo.task, eventInAssignTo.newVal, eventInAssignTo.oldVal);
            if (eventInSeverity.case_id) handleActivity(eventInSeverity.case_id, eventInSeverity.task, eventInSeverity.newVal, eventInSeverity.oldVal);
        }

        setShow(false);
    }
};









  // function to edit Assigned to
  const changeCat = (e, oldAssign) => {
    setCategory(e.target.value)
  }


  const getCaseList = () => {
    caseManagementTableData([], {}, 0, 50, '').then(({ data: { hits } }) => {



      setCaseList(hits?.hits?.map((list) => {
        return { value: list?._source?.case_id, label: `${list?._source?.case_id} (${list?._source?.case_title})` }
      }))

    }).catch((err) => {
      console.log("Error", err)
    })
  }


  // const handleChangeSelect = (options) => {

  //   setAttachment(options?.value)
  // }

  const handleChangeSelect = (e) => {
    const selectedValue = e.target.value;
    setAttachment(selectedValue);
    console.log("attachment", selectedValue);
  }


  const handleCategoryChange = (newValue, actionMeta) => {
    setCategory(newValue);
    
    // If the case is being edited and the category changes, record the change event
    if (caseidEdit && case_?.category !== newValue?.value) {
      setEventInCategory({
        case_id,
        task: "Category",
        newVal: newValue?.value,
        oldVal: case_?.category,
      });
    } else {
      setEventInCategory({});
    }
  };
  
  


  const handleCategory = (e) => {

    setCategory(e.target.value)
    if (caseidEdit && case_?.category != e.target.value) {
      setEventInCategory({ case_id, task: "Category", newVal: e.target.value, oldVal: case_?.category })
      // handleActivity(case_id, "Category", category.value, case_?.category)
    } else {
      setEventInCategory({})
    }
  }

  // function to edit Assigned to
  const changeAssign = (e, oldAssign) => {
    setAssigned_to(e.target.value)
    if (caseidEdit && case_?.assigned_to != e.target.value) {
      setEventInAssignTo({ case_id, task: "Assigned to", newVal: e.target.value, oldVal: oldAssign })

      // handleActivity(case_id, "Assigned to", e.target.value, oldAssign)
    } else {
      setEventInAssignTo({})
    }
  }

  // function to edit severity
  const changeSeverity = (e, sev) => {
    setSeverity(e.target.value)
    if (caseidEdit) {
      setEventInSeverity({ case_id, task: "Severity", newVal: e.target.value, oldVal: sev })
    } else {
      setEventInSeverity({})
    }

  }


  const handleCloseModal = () => {

    if (window.confirm("Do you want to Close ?") === true) {
      setShow(false);
      setCase_ID('')
    }
  };

  // let attachedCaseIdsDiv = 

  const addAttachmentId = () => {

    // if (attachedCase === "") {
    //     setIsAttachedEmpty(true)
    // } else {
    setAttachedCaseIds([...attachedCaseIds, ""])
    // }

  }



  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <Loader />
      ) : (
        <div className="px-3">
          {module != "casemanagement" ?
            <div className="d-flex justify-content-start">

              <div className="p-2 bd-highlight">    <ButtonGroup className="mt-2">
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              </div>
            </div> : ""
          }

          {radioValue === "1" ?
            <>
              <div className="row mt-1">
                <div className="form-group " style={{ display: "flex" }}>
                  {case_id != "" ?
                    <div className="col-md-6 col-xs-12 p-1">
                      {" "}
                      {/* <label className="control-label">CASE ID - {case_id}</label> */}
                      <span className="modal-label col-3">Case ID - </span>
                      <span className="modal-text">
                        {case_id}
                      </span>


                    </div> : ""}
                </div>
              </div>
              <div className="row mt-3">
                <div className="form-group d-flex flex-column">

                  <div className="col-12 p-1">

                    {" "}
                    <label className="control-label">Title *</label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="title"
                      placeholder="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      required
                    />
                  </div>

                  <div className="col-12 p-1">
                    {" "}
                    <label className="control-label">Description *</label>

                    <textarea
                      rows="5"
                      className="form-control input-lg"
                      name="description"
                      placeholder="Description..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      required
                    ></textarea>
                  </div>



                </div>
              </div>
              <div className="row">
                <div className="form-group " style={{ display: "flex" }}>

                <div className="col-md-12 col-xs-12 p-1">
  <label className="control-label">Category *</label>
  <CreatableSelect
    isClearable
    options={categoriesList}
    onChange={handleCategoryChange}
    value={category}
    required
  />
</div>
                </div>
                <div>
                  <div className="form-group " style={{ display: "flex" }}>
                    <div className="col-md-12 col-xs-12 p-1">
                      {" "}
                      <label className="control-label">Assign To *</label>
                      {setDefAssign(Assigned_to)}
                      <select className="form-select input-lg" name="assignTo"
                        // onChange={(e) => setAssigned_to(e.target.value)}
                        onChange={(e) => changeAssign(e, defAssign)}

                        value={Assigned_to} required>
                        <option value="">Select User</option>
                        {users?.map((data, index) => (
                          <option value={data.user_name} key={index}>{data.user_name}</option>
                        ))}
                        <option></option>
                      </select>
                    </div>    </div></div>
                <div>
                  <div className="form-group " style={{ display: "flex" }}>
                    <div className="col-md-12 col-xs-12 p-1">

                      {" "}
                      <label className="control-label">Severity *</label>
                      {setDefSev(case_?.severity)}
                      <select
                        className="form-select input-lg"
                        name="severity"
                        // onChange={(e) => setSeverity(e.target.value)}
                        onChange={(e) => changeSeverity(e, defSev)}
                        value={severity}
                        required
                      >
                        <option value="">Select severity</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                        <option value="critical">Critical</option>

                      </select>

                    </div>     </div>
                </div>
              </div>

              <div className="row">

                <div class="d-flex bd-highlight">
                  <div class="p-2 w-100 bd-highlight">  <h6>Related Events</h6></div>
                  <div class="p-2 flex-shrink-1 bd-highlight"> <span className="modal-label col-1 font-weight-600">
                    {module === 'casemanagement' &&

                      <span className="mx-2 cursor-pointer" onClick={addAttachmentId}>   <TableViewMore Icon={Plus} dataToggle={"Add Attachment"} dataPlacement={"bottom"} /></span>

                    }


                  </span></div>
                </div>
              </div>
              {/* {attachedCaseIdsDiv} */}
              {attachedCaseIds?.map((data, index) => (
                <AttachedId
                  index={index}
                  attachedCaseIds={attachedCaseIds}
                  setAttachedCaseIds={setAttachedCaseIds}
                  data={data}
                  module={module}
                  preModule={preModule}
                  attachmentList={attachmentList}
                  detectionId={idd}
                  caseidEdit={caseidEdit}
                />
              ))}
              {/* 
              {attachedCaseIds?.map((data) => (
                <div>{data}</div>
              ))} */}
            </>

            :
            <div className="col-12" style={{ minHeight: "280px" }}>
              <div className="form-group" style={{ display: "flex" }}>

                {/* <Select
                    className="  form-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="color"
                    options={caseList}
                    isClearable={true}
                    onChange={handleChangeSelect}
                  /> */}
                <select
                  className="form-select"
                  name="color"
                  value={attachment}
                  onChange={(e) => handleChangeSelect(e)}
                >
                  <option value="">Select Attachment</option>
                  {caseList?.map((data, index) => (
                    <option value={data.value} key={index}>{data.label}</option>
                  ))}
                </select>
              </div>
              <div></div>
              <div class="d-flex bd-highlight">
                <div class="p-2 w-100 bd-highlight">  <h6>Related Events</h6></div>
                <div class="p-2 flex-shrink-1 bd-highlight"> <span className="modal-label col-1 font-weight-600">
                  {module === 'casemanagement' &&

                    <span className="mx-2 cursor-pointer" onClick={addAttachmentId}>   <TableViewMore Icon={Plus} dataToggle={"Add Attachment"} dataPlacement={"bottom"} /></span>

                  }


                </span></div>
              </div>
              {attachedCaseIds?.map((data, index) => (
                <AttachedId
                  index={index}
                  attachedCaseIds={attachedCaseIds}
                  setAttachedCaseIds={setAttachedCaseIds}
                  data={data}
                  module={module}
                  preModule={preModule}
                  attachmentList={attachmentList}
                  detectionId={idd}
                  caseidEdit={caseidEdit}
                />
              ))}

              {/* {attachedCaseIdsDiv} */}
            </div>
          }




          <div className="row mt-3 py-3" style={styles.footerWrap}>
            {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-secondary" onClick={handleCloseModal}>Cancel</Button> &nbsp;
              <Button className="btn-primary" type="submit" disabled={isSubmit === true ? true : false}    >
                {isSubmit === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                  <span className="sr-only"> </span>
                </div></> : "Save"}
              </Button>
            </div>
          </div >





        </div >

      )
      }
    </Form >
  );


  return (
    <>
      {" "}
      {show === true && (
        <CustomModal
          title={caseidEdit != '' ? `Edit Case` : `Create Case`}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding={true}
          // modalSize="md"
          // autoHeight={radioValue === '1' ? true : false}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        />
      )}
    </>
  );
}