import React, { useEffect, useState } from 'react'
import { getCyEmailTable, getMostCommonSRA, getUniqueSRCounts } from '../../../../Methods/CyKitInvestigate'
import CustomToolTip from '../../../../components/ToolTip/CustomToolTip';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Badge, Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination';
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import TableViewMore from '../../../../components/ViewMoreBtn/TableViewMore';
import { isJSONObject } from '../../../../Utils/Utils';
import moment from 'moment';
// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const datalakeURL = localStorage.getItem("DATALAKE_URL");

const CyEmail = () => {

    // loader
    const [commonSenderLoader, setCommonSenderLoader] = useState(true)
    const [commonReceiverLoader, setCommonReceiverLoader] = useState(true)
    const [doc, setDoc] = useState(null)
    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    // filters
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        to: moment().format("yyyy-MM-DDTHH:mm"),
        f: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        e: moment().format("yyyy-MM-DDTHH:mm"),
        range: 60
    });

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    // loader
    const [loadTableData, setLoadTableData] = useState(true)

    // counts
    const [uniqueSenderCount, setUniqueSenderCount] = useState()
    const [uniqueRecevierCount, setUniqueRecevierCount] = useState()

    // graph
    const [mostCommonSenderLabel, setMostCommonSenderLabel] = useState([])
    const [mostCommonSenderData, setMostCommonSenderData] = useState([])

    const [mostCommonRecevierLabel, setmostCommonRecevierLabel] = useState([])
    const [mostCommonRecevierData, setmostCommonRecevierData] = useState([])

    const [mostEventActionLabel, setMostEventActionLabel] = useState([])
    const [mostEventActionData, setMostEventActionData] = useState([])

    // table
    const [tableData, setTableData] = useState([])
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)

    // tab
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [manageDatas, setManageDatas] = useState([
        { text: "Sender Address", value: "email.from.address", isSelected: true },
        { text: "Receiver Address", value: "email.receiver.address", isSelected: true },
        { text: "Subject", value: "subject", isSelected: true },
        { text: "Status", value: "delivery.action", isSelected: true },
    ])

    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.email') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.email")))
            }
        })
    }, [manageDatas])
    const EmailDeliveryActions = {
        "Delivered": {
            "Action": "Deliver",
            "Description": "Indicates that the message was successfully delivered to the recipient's mailbox."
        },
        "Failed": {
            "Action": "Fail",
            "Description": "Indicates that the message wasn't delivered."
        },
        "Expanded": {
            "Action": "Expand",
            "Description": "Related to distribution groups. When a message is sent to a group, it's 'expanded' to show all individual recipients."
        },
        "Redirected": {
            "Action": "Redirect",
            "Description": "Indicates that the message was rerouted to a different destination, possibly due to mail flow rules."
        },
        "Resolved": {
            "Action": "Resolve",
            "Description": "Indicates that the email has been validated with mail flow rules that require a decision about whether to allow or block the message."
        },
        "Deleted": {
            "Action": "Delete",
            "Description": "The message was deleted by a transport/mail flow rule."
        },
        "Quarantined": {
            "Action": "Quarantine",
            "Description": "The message was moved to quarantine, usually due to matching a malware or spam policy."
        }
    };
    // useEffect(() => {
    //     const storedFilter = JSON.parse(localStorage.getItem("filters"));
    //     const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
    //     const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
    //     console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
    //     if (currentPageFilter?.length || currentPageDateFilter?.to) {
    //         count = currentPageFilter;
    //         count2 = currentPageDateFilter?.to
    //         setDateFilter(currentPageDateFilter)
    //         setFilter(currentPageFilter);

    //         getFilteredTable(currentPageFilter, currentPageDateFilter);
    //     }
    // }, []);
    const module = 'investigation'
    const tab = 'cykit-email'
    let count = [];
    let count2;
    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            setFilter(currentPageFilter);
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    const getFilteredTable = (filter, date = {}) => {
        setLoadTableData(true)
        if (filter || date?.to) {
            getCyEmailTable(filter, date, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                console.log("getCyEmailTable ", hits)
                setTotalPageCount(hits.total.value)
                setTableData(hits.hits)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)

            })
        }
        else {
            getCyEmailTable().then(({ data: { hits } }) => {
                console.log("getCyEmailTable ", hits)
                setTotalPageCount(hits.total.value)
                setTableData(hits.hits)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
            })
        }
    }

    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };


    useEffect(() => {
        setLoadTableData(true)
        setCommonSenderLoader(true)
        setCommonReceiverLoader(true)
        //  "email.from.address"
        getUniqueSRCounts("email.from.address", filter, dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            console.log("email.from.address", unique_users.value)
            unique_users.value && setUniqueSenderCount(unique_users.value)
        }).catch((err) => {

        })

        // "email.receiver.address"

        getUniqueSRCounts("email.receiver.address", filter, dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            console.log("email.receiver.address", unique_users.value)
            unique_users.value && setUniqueRecevierCount(unique_users.value)
        }).catch((err) => {

        })

        // "email.from.address"

        getMostCommonSRA("email.from.address", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            console.log("email.from.address GRAPH", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {

                    label.push(item.key === "" ? "--" : item.key);
                    // label.push(truncatedLabel);
                    data.push(item.doc_count);
                    setMostCommonSenderLabel(label);
                    setMostCommonSenderData(data);
                });
                setCommonSenderLoader(false)
            }
            else {
                setmostCommonRecevierLabel([]);
                setmostCommonRecevierData([]);
                setCommonSenderLoader(false)
            }
        }).catch((err) => {

        })

        // "email.receiver.address"
        console.log("date", dateFilter)
        getMostCommonSRA("email.receiver.address", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            console.log("email.receiver.address GRAPH", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    // let truncatedLabel = item.key === "" ? "--" : (item.key.length > 40 ? item.key.substr(0, 40) + '...' : item.key);
                    label.push(item.key === "" ? "--" : item.key);
                    // label.push(truncatedLabel);
                    data.push(item.doc_count);
                    setmostCommonRecevierLabel(label);
                    setmostCommonRecevierData(data);
                });
                setCommonReceiverLoader(false)
            }
            else {
                setmostCommonRecevierLabel([]);
                setmostCommonRecevierData([]);
                setCommonReceiverLoader(false)

            }
        }).catch((err) => {

        })

        //  "event.action"

        // getMostCommonSRA("event.category", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     console.log("event.action GRAPH", buckets)
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             setMostEventActionLabel(label);
        //             setMostEventActionData(data);
        //         });
        //     }
        // }).catch((err) => {

        // })

        // table data
        // setCurrentPageCount((page - 1) * perPage + perPage);
        getCyEmailTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            setTotalPageCount(hits.total.value)
            setTableData(hits.hits)
            setLoadTableData(false)
        }).catch((err) => {
            setTableData([])
            setLoadTableData(false)
        })

    }, [page, filter, dateFilter])

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }

        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.email", JSON.stringify(manageDatas))
    }

    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "email.from.address", label: "Sender Address", isWildCard: true },
                                { value: "email.receiver.address", label: "Recevier Address", isWildCard: true },
                                { value: "delivery.action", label: "Status", isWildCard: true },
                                { value: "subject", label: "Subject", isWildCard: true },
                                { value: "event.original", label: "Raw Field", isWildCard: true },
                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>


                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyEmailTable} type={"email"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}



                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}


            </div>

            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{uniqueSenderCount ? uniqueSenderCount : "--"}</h4>
                                            <span>No of unique Sender </span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{uniqueRecevierCount ? uniqueRecevierCount : "--"}</h4>
                                            <span>No of unique Recevier</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>Most Common Senders</h5>
                                        {commonSenderLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {mostCommonSenderLabel.length || mostCommonSenderData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={mostCommonSenderLabel} ChartData={mostCommonSenderData} module={module} tab={tab} field={"email.from.address"} label={"Sender Address"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>Most Common Receivers</h5>
                                        {commonReceiverLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {mostCommonRecevierLabel.length || mostCommonRecevierData.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={mostCommonRecevierLabel} ChartData={mostCommonRecevierData} module={module} tab={tab} field={"email.receiver.address"} label={"Recevier Address"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>

                                {/* <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>Most event actions</h5>
                                        {mostEventActionLabel.length || mostEventActionData.length !== 0 ? (
                                            <BarGraph ChartLabel={mostEventActionLabel} ChartData={mostEventActionData} module={module} tab={tab} field={"event.category"} label={"Event Category"} setfilter={setFilter} filter={filter} />
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">

                            <div className="" style={styles.tableWrap}>
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    getFilteredTable={getFilteredTable}
                                    module={module}
                                    tab={tab}
                                />
                                {loadTableData ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    tableData.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item.text}</th>
                                                    ))}
                                                    {/* <th>Sender Address</th>
                                                    <th>Recevier Address</th>
                                                    <th>Subject</th>
                                                    <th>Status</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "email.from.address" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace text-break">
                                                                        {item?._source["email.from.address"]}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "email.from.address",
                                                                                            label: "Sender Address",
                                                                                        }}
                                                                                        value={item?._source["email.from.address"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "email.receiver.address" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace text-break">
                                                                        {item?._source["email.receiver.address"]}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "email.receiver.address",
                                                                                            label: "Receiver Address",
                                                                                        }}
                                                                                        value={item?._source["email.receiver.address"]}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "subject" && data.isSelected === true ? (
                                                                <td className='col-3 text-break'>{isJSONObject(item?._source?.subject) ? item?._source?.subject?.["#text"] : item?._source?.subject}</td>
                                                            ) : data.value === "delivery.action" && data.isSelected === true ? (
                                                                <td>
                                                                    {/* {console.log(item?._source)} */}
                                                                    <span>

                                                                        <CustomToolTip content={<div>{item?._source["delivery.action"] == "Delivered" && (
                                                                            <Badge bg="success" className="p-2">Delivered</Badge>
                                                                        )}
                                                                            {item?._source["delivery.action"] == "Quarantined" && (
                                                                                <Badge bg="warning" className="p-2">Quarantined</Badge>
                                                                            )}
                                                                            {item?._source["delivery.action"] == "Failed" && (
                                                                                <Badge bg="danger" className="p-2">Failed</Badge>
                                                                            )}
                                                                            {(item?._source["delivery.action"] != "Delivered" && item?._source["delivery.action"] != "Failed" && item?._source["delivery.action"] != "Quarantined") && (
                                                                                <Badge bg="secondary" className="p-2 text-capitalize">{item?._source["delivery.action"]}</Badge>
                                                                            )}</div>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    {EmailDeliveryActions[item?._source["delivery.action"]] &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            {/* <span>Description - </span> */}
                                                                                            <span><strong>{EmailDeliveryActions[item?._source["delivery.action"]]?.Description}</strong></span>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            } />
                                                                    </span>
                                                                    {/* {item?._source["delivery.action"]} */}
                                                                </td>
                                                            ) : (<></>)
                                                        ))}

                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument doc={doc} setDoc={setDoc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${localStorage.getItem("INVESTIGATE_CYEMAIL")}/_search?track_total_hits=true`} req={fpostDl} />
        </div>
    )
}

export default CyEmail
