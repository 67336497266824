import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

const styles = {
    contentTile: {
        width: "100%",
        height: "350px",
        // borderRadius: "10px",
        overflow: "hidden",
    }
};

const RecentTabLoader = ({ count }) => {

    const [skeletonArray, setSkeletonArray] = useState([]);

    useEffect(() => {
        let skeletons = []
        for (let i = 0; i <= count; i++) {
            skeletons.push(
                // <div key={i} className="d-flex col-12 gap-3 align-items-center">
                <Skeleton width={"100%"} height={"57px"} className='mb-3 rounded-3' key={"recent_tab_loader_" + i} />
                // </div>
            );
        }
        setSkeletonArray(skeletons);
    }, [])

    return (
        <div style={styles.contentTile}>
            {skeletonArray}
        </div>
    )
}

export default RecentTabLoader