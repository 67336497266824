const styles = {
  sidebar: {
    backgroundColor: "#16171B",
    // height: "100vh",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginLeft: "30px",
  },
  menuItem: {
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    color: "#FFFFFF",
    padding: "15px 20px",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  activeMenuItem: {
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    color: "#FFFFFF",
    backgroundColor: "#292A2C",
    padding: "15px 20px",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  sidemenuIcon: {
    marginRight: "10px",
    fontSize: "24px",
  },
  logo: {
    height: "30px",
    alignSelf: "flex-start",
    maxWidth: "100%",
    paddingLeft: "0.5rem"
  },
  arrowDown: {
    marginLeft: "auto",
    fontSize: "18px",
    // backgroundColor: "orange",
    borderRadius: "0.2rem",
    // color: "black",
  },
};

export default styles;
