import moment from "moment";
import { getUtc, isEmptyObject } from "../Utils/Utils";
// import { genstrut } from "../Utils/Utils";
const today = moment().utc().format("yyyy-MM-DDTHH:mm");

const timeFilter = () => {
  let filter = localStorage.getItem("filter")

  var number = 0

  if (filter === "1 Year") {
    number = 365
  } else if (filter === "30 Days") {
    number = 30
  } else if (filter === "1 Day") {
    number = 1
  } else {
    number = 7
  }
  const endDate = moment().utc()
    .subtract(number, "days")
    ?.format("yyyy-MM-DDTHH:mm");

  return endDate
}

export const mostAnomalusQuery = (field_name, count) => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "filter": [
          {
            "match_phrase": {
              "investigation_status": "Investigated"
            }
          },
          {
            "range": {
              "detection_timestamp": {
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              },
            }
          }
        ],
        "must_not": [
          {
            "term": {
              "user": "No user_name found"
            }
          },
          {
            "term": {
              "host": "No host_name found"
            }
          },
          {
            "term": {
              "user": ""
            }
          },
          {
            "term": {
              "host": ""
            }
          }
        ]
      }
    },
    "aggs": {
      "2": {
        "terms": {
          "field": field_name,
          "order": {
            "_count": "desc"
          },
          "size": count
        }
      }
    }
  }
  // return {
  //   size: 0,
  //   query: {
  //     bool: {
  //       filter: [
  //         { match_all: {} },
  //         {
  //           range: {
  //             current_timestamp: {
  //               gte: "now-365d",
  //               lte: "now",
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  //   aggs: { 2: { terms: { field: field_name, order: { _count: "desc" } } } },
  // };
};

export const getSeverityCountQuery = () => {
  return {

    "size": 0,

    "query": {

      "bool": {

        "filter": [

          {

            "match_all": {}

          },

          {

            "match_phrase": {

              "investigation_status": "Investigated"

            }

          },

          {
            "range": {
              "detection_timestamp": {
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
                format: "strict_date_optional_time",
              }

            }

          }

        ]

      }

    },

    "aggs": {

      "2": {

        "terms": {

          "field": "detection_severity",

          "order": {

            "_count": "desc"

          }

        }

      }

    }

  }
  // return {
  //   size: 0,
  //   query: {
  //     bool: {
  //       filter: [
  //         { match_all: {} },
  //         {
  //           range: {
  //             current_timestamp: {
  //               gte: "now-365d",
  //               lte: "now",
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  //   aggs: {
  //     2: { terms: { field: "detection_severity", order: { _count: "desc" } } },
  //   },
  // };
};

export const getTotalInvestigationCoutsQuery = () => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_all: {} },
          {
            range: {
              current_timestamp: {
                gte: localStorage.getItem("gte")
                  ? timeFilter()
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? today
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: { terms: { field: "_id", order: { _count: "desc" }, size: 10000 } },
    },
  };
};

export const getGroupedInvestigationCountsQuery = () => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_all: {} },
          {
            range: {
              current_timestamp: {
                gte: localStorage.getItem("gte")
                  ? timeFilter()
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? today
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: { terms: { field: "_id", order: { _count: "desc" }, size: 10000 } },
    },
  };
};

export const getInvestigationTypeQuery = () => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          { match_all: {} },
          {
            range: {
              current_timestamp: {
                gte: localStorage.getItem("gte")
                  ? timeFilter()
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? today
                  : "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: {
        terms: { field: "tactic_name", size: 10000, order: { _count: "desc" } },
      },
    },
  };
};

// correlation studio

export const mostCommonDetectionQuery = (selectedOption) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: selectedOption,
          },
          {
            range: {
              current_timestamp: {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: "detection_name",
          order: {
            _count: "desc",
          },
          size: 5,
        },
      },
    },
  };
};

// export const leastCommonDetectionsQuery = () => {
//   return {
//     "size": 0,
//     "query": {
//       "bool": {
//         "filter": [{
//           "match_all": {}
//         }, {
//           "match_phrase": "spacex"
//         }, {
//           "range": {
//             "current_timestamp": {
//               "gte": "now-365d",
//               "lte": "now",
//               "format": "strict_date_optional_time"
//             }
//           }
//         }]
//       }
//     },
//     "aggs": {
//       "2": {
//         "terms": {
//           "field": "detection_name",
//           "order": {
//             "_count": "asc"
//           },
//           "size": 5
//         }
//       }
//     }
//   }
// }

// drop down query ( EDR )

// export const getLogCategory = (type) => {
//   return {
//     from: 100,

//     size: 1000,

//     query: {
//       bool: {
//         filter: [
//           {
//             match_phrase: {
//               log_category: type,
//             },
//           },

//           {
//             match_phrase: {
//               investigation_status: "Investigated",
//             },
//           },

//           {
//             range: {
//               current_timestamp: {
//                 gte: "now-1000d",

//                 lte: "now",

//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//       },
//     },
//     sort: [
//       {
//         detection_timestamp: {
//           order: "desc",

//           unmapped_type: "boolean",
//         },
//       },
//     ],
//   };
// };

export const getTacticsDetectedQuery = (selectedOption) => {
  return {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: selectedOption,
          },
          {
            range: {
              detection_timestamp: {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      2: {
        date_histogram: {
          field: "detection_timestamp",
          calendar_interval: "1d",
          time_zone: "Asia/Kolkata",
          min_doc_count: 1,
        },
        aggs: {
          3: {
            terms: {
              field: "tactic_name",
              order: {
                _count: "desc",
              },
              size: 1000,
            },
          },
        },
      },
    },
  };
};

export const getUsersQuery = (field_name) => {
  return {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
          {
            range: {
              detection_timestamp: {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 10000,
        },
      },
    },
  };
};

export const highPriorityDetectionTableQuery = () => {
  return {
    size: 1000,
    query: {
      bool: {
        filter: [
          { match_all: {} },
          {
            range: {
              current_timestamp: {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
  };
};

// new queryss

export const getInvestigationQuery = (count, sort, page, isinv) => {
  var data = {
    from: page,
    size: count,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },

        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: sort,
          unmapped_type: "boolean",
        },
      },
    ],
  };
  if (!isinv) {
    var add = {
      range: {
        detection_timestamp: {
          gte: localStorage.getItem("gte") ? getUtc(localStorage.getItem("gte")) : "now-7d/d",
          lte: localStorage.getItem("lte") ? getUtc(localStorage.getItem("lte")) : "now",
          format: "strict_date_optional_time",
        },
      },
    }
    data.query.bool.filter.push(add)
  }
  return data
};

export const getInvestigationGroupedQuery = (count, sort, page, filter, date) => {
  var data = {
    from: page,
    size: count,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
        ],
        must: [],
        should: [],
        must_not: []
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: sort,
          unmapped_type: "boolean",
        },
      },
    ],
  };
  return filterGenInv(data, filter, date)
};

export const getInvestigationAttentionQuery = (count, sort, page, filter, date) => {
  var data = {
    from: page,
    size: count,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
        ],
        must: [
          {
            bool: {
              should: [
                {
                  "match": {
                    "threat_classification": "Benign True Positive"
                  }
                },
                {
                  "match": {
                    "threat_classification": "Possible True Positive"
                  }
                }

              ],
              minimum_should_match: 1
            }
          }
        ],
        should: [],
        must_not: []
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: sort,
          unmapped_type: "boolean",
        },
      },
    ],
  };
  return filterGenInv(data, filter, date)
};


export const getInvestigationTableQuery = (count, sort, page) => {
  // return {
  //   from: page,
  //   size: count,
  //   query: {
  //     bool: {
  //       filter: [
  //         {
  //           match_all: {},
  //         },
  //         {
  //           range: {
  //             current_timestamp: {
  //               gte: "now-300d",
  //               lte: "now",
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  //   sort: [
  //     {
  //       detection_timestamp: {
  //         order: sort,
  //         unmapped_type: "boolean",
  //       },
  //     },
  //   ],
  // };
  return {
    from: page,
    size: count,
    query: {
      bool: {
        filter: [
          {
            match_phrase: {
              investigation_status: "Investigated",
            },
          },

          {
            range: {
              current_timestamp: {
                gte: "now-300d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: sort,
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const getInlineFilterQuery = (filter_field) => {
  return {
    from: 0,
    size: 1000,
    query: {
      bool: {
        filter: [
          {
            match_phrase: filter_field,
          },
          {
            range: {
              current_timestamp: {
                gte: "now-300d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: "desc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

export const getInlineFilterMustNotQuery = () => {
  return {
    from: 0,
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              current_timestamp: {
                gte: "now-300d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              user_domain: "Lenovo",
            },
          },
        ],
      },
    },
    sort: [
      {
        detection_timestamp: {
          order: "desc",
          unmapped_type: "boolean",
        },
      },
    ],
  };
};

// Cykit querys

// counts
export const TotalCountsQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        filter: [{ match_all: {} }],
        must: [],
        must_not: [],
        should: [],
      },
    },
  };
  // if (isEmptyObject(date)) {
  //   var datefilter = {
  //     range: {
  //       "@timestamp": {
  //         gte: "now-1h/h",
  //         lte: "now",
  //         format: "strict_date_optional_time",
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(datefilter);
  // }
  return filterGen(data, filter, date);
};

export const getCountsQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
        ],
        must: [],
        should: [],
        must_not: [],
      },
    },

    aggs: {
      2: {
        cardinality: {
          field: `${field_name}`,
        },
      },
    },
  };
  // if (isEmptyObject(date)) {
  //   var datefilter = {
  //     range: {
  //       "@timestamp": {
  //         gte: "now-1h/h",
  //         lte: "now",
  //         format: "strict_date_optional_time",
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(datefilter);
  // }
  return filterGen(data, filter, date);
};

export const getWidgetsQuery = (
  field_name,
  count,
  sort,
  filter,
  date,
  dateRange
) => {

  var data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
        ],
        must: [],
        must_not: [],
        should: [],
      },
    },

    aggs: {
      2: {
        terms: {
          field: `${field_name}`,
          size: count,
          order: {
            _count: sort,
          },
        },
      },
    },
  };
  if (dateRange) {
    let dateObj = {
      range: {
        "@timestamp": {
          gte: localStorage.getItem("gte")
            ? getUtc(localStorage.getItem("gte"))
            : "now-7d",
          lte: localStorage.getItem("lte")
            ? getUtc(localStorage.getItem("lte"))
            : "now",
        },
      },
    }
    data.query.bool.filter.push(dateObj)
  }
  // if (isEmptyObject(date)) {
  //   var datefilter = {
  //     range: {
  //       "@timestamp": {
  //         gte: moment.utc().subtract(1, 'hour').toISOString(),
  //         lte: moment().toISOString(),
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(datefilter);
  // }
  return filterGen(data, filter, date);
};

export const getRecentFileOperationsQuery = (size, date) => {
  return {
    query: {
      bool: {
        must: [{ term: { "event.category": "file" } }],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
        ],
      },
    },
    size: size,
    from: 0,
    track_total_hits: true,
    sort: [{ "@timestamp": { order: "desc", unmapped_type: "boolean" } }],
    aggs: {
      unique_count: {
        terms: {
          field: "event.action",
        },
      },
    },
  };
};

// recent authenticate management dashh

export const getRecentAuthManagementDashQuery = (size, date) => {
  return {
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: date,
                lte: "now",
              },
            },
          },
          {
            match_phrase: {
              "event.code": "4624",
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};

// account url management dashboard

export const getAccountCreatedQuery = (size, date) => {
  return {
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            match_phrase: {
              "event.code": "4720",
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};
export const getAccountChangedQuery = (size, date) => {
  return {
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            match_phrase: {
              "event.code": "4738",
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};

export const getAccountLockedQuery = (size, date) => {
  return {
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            match_phrase: {
              "event.code": "4740",
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};

// account deleted / disabled management dashboard

export const getAccountDeletedQuery = (size, date) => {
  return {
    from: 0,
    track_total_hits: true,
    size: size,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": "4726",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4725",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};

export const getAccountActivityQuery = (size, date) => {
  return {
    from: 0,
    track_total_hits: true,
    size: size,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": "4726",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4725",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4720",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4723",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4724",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4740",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4738",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          }
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
    sort: [
      {
        "@timestamp": {
          order: "desc",
        },
      },
    ],
  };
};

// account pass change management dashhh

export const getAccountPassQuery = (size, date) => {
  return {
    from: 0,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: localStorage.getItem("gte")
                  ? getUtc(localStorage.getItem("gte"))
                  : "now-7d",
                lte: localStorage.getItem("lte")
                  ? getUtc(localStorage.getItem("lte"))
                  : "now",
              },
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": "4723",
                  },
                },
                {
                  match_phrase: {
                    "event.code": "4724",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
  };
};

// active directory

export const getCountsAdQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [],
        should: [],
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getEventCountsQuery = (filter, date) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4731",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4732",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4733",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4734",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4735",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4737",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4764",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4727",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4728",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4729",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4730",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4754",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4755",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4756",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4757",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4758",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4799",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4749",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4750",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4751",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4752",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4753",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4759",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4760",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4761",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4762",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4763",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4744",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4745",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4746",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4748",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4720",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4722",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4723",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4724",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4726",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4725",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4738",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4740",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4799",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4738",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4781",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4724",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4720",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4722",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4726",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4742",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4725",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4723",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4741",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: "now-100d",
                lte: "now",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return filterGen(data, filter, date);
};

// account create coutns
export const getAccoundCountsQuery = (event_code, filter, date) => {
  var data = {
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": event_code,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          // {
          //   range: {
          //     "@timestamp": {
          //       format: "strict_date_optional_time",
          //       gte: "now-100d",
          //       lte: "now",
          //     },
          //   },
          // },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return filterGen(data, filter, date);
};

//  active auth chart data

export const AuthUserChartQuery = (event_code, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              "event.code": event_code,
            },
          },
          // {
          //   range: {
          //     "@timestamp": {
          //       gte: "now-1d",
          //       lte: "now",
          //       format: "strict_date_optional_time",
          //     },
          //   },
          // },
        ],
        should: [],
        must_not: [],
      },
    },

    aggs: {
      1: {
        terms: {
          field: "winlog.event_data.TargetUserName",
          order: {
            _count: "desc",
          },
          size: 20,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const userManagementActionQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        should: [],
        must_not: [],
        filter: [
          {
            bool: {
              should: [
                {
                  multi_match: {
                    type: "phrase",
                    query: "4799",
                    lenient: true,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4738",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4781",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4724",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4720",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4722",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4726",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4742",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4725",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4723",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4741",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            range: {
              "@timestamp": {
                gte: "now-100d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: "event.action",
          order: {
            _count: "desc",
          },
        },
      },
    },
  };

  return filterGen(data, filter, date);
};
// accounts created data

export const getAccoundDataQuery = (event_code, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        should: [],
        must_not: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": event_code,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          // {
          //   range: {
          //     "@timestamp": {
          //       gte: "now-1000d",
          //       lte: "now",
          //       format: "strict_date_optional_time",
          //     },
          //   },
          // },
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: "winlog.event_data.SubjectUserName",
          order: {
            _count: "desc",
          },
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const groupManagementActionQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4731",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4732",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4733",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4734",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4735",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4737",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4764",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4727",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4728",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4729",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4730",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4754",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4755",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4756",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4757",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4758",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4749",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4750",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4751",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4752",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4753",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4759",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4760",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4761",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4762",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4763",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4744",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4745",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4746",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event.code": "4748",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            range: {
              "@timestamp": {
                gte: "now-100d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: "event.action",
          order: {
            _count: "desc",
          },
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const numfields = [
  "process.pid",
  "destination.port",
  "source.port",
  "event.code",
  "agent.hostname",
  "winlog.event_data.SubjectUserName",
  "email.from.address",
  "email.receiver.address",
];
export const getActiveDirectoryTableQuery = (
  filter,
  date,
  size,
  sort,
  page
) => {
  var data = {
    from: page,
    size: size,
    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        filter: [
          // {
          //   range: {
          //     "@timestamp": {
          //       gte: "now-100d",
          //       lte: "now",
          //       format: "strict_date_optional_time",
          //     },
          //   },
          // },
          // {
          //   exists: {
          //     field: "winlog.event_data.SubjectUserName",
          //   },
          // },
          {
            exists: {
              field: "event.action",
            },
          },
          {
            exists: {
              field: "event.code",
            },
          },
        ],
      },
    },
    sort: {
      "@timestamp": {
        order: sort,
      },
    },
  };

  return filterGen(data, filter, date, "ad");
};

//account modified counts

// export const sourceIPQuery = () => {
//   return {
//     size: 0,
//     query: {
//       bool: {
//         filter: [
//           {
//             match_all: {},
//           },

//           {
//             range: {
//               "@timestamp": {
//                 gte: "2023-04-01T00:00:00.000Z",
//                 lte: "now",
//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//       },
//     },

//     aggs: {
//       2: {
//         terms: {
//           field: "sipv4.keyword",
//           size: 20,
//           order: {
//             _count: "desc",
//           },
//         },
//       },
//     },
//   };
// };

// // No of unique Source Ports

// export const sourcePortsQuery = () => {
//   return {
//     size: 0,
//     query: {
//       bool: {
//         filter: [
//           {
//             match_all: {},
//           },

//           {
//             range: {
//               "@timestamp": {
//                 gte: "2023-04-01T00:00:00.000Z",
//                 lte: "now",
//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//       },
//     },

//     aggs: {
//       2: {
//         terms: {
//           field: "sport.keyword",
//           size: 20,
//           order: {
//             _count: "desc",
//           },
//         },
//       },
//     },
//   };
// };

// // No of unique Destination IP

// export const destinationPortsQuery = () => {
//   return {
//     size: 0,
//     query: {
//       bool: {
//         filter: [
//           {
//             match_all: {},
//           },

//           {
//             range: {
//               "@timestamp": {
//                 gte: "2023-04-01T00:00:00.000Z",
//                 lte: "now",
//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//       },
//     },

//     aggs: {
//       2: {
//         terms: {
//           field: "dport.keyword",
//           size: 20,
//           order: {
//             _count: "desc",
//           },
//         },
//       },
//     },
//   };
// };

// // no of destination ip

// export const destinationIpQuery = () => {
//   return {
//     size: 0,
//     query: {
//       bool: {
//         filter: [
//           {
//             match_all: {},
//           },

//           {
//             range: {
//               "@timestamp": {
//                 gte: "2023-04-01T00:00:00.000Z",
//                 lte: "now",
//                 format: "strict_date_optional_time",
//               },
//             },
//           },
//         ],
//       },
//     },

//     aggs: {
//       2: {
//         terms: {
//           field: "dipv4.keyword",
//           size: 20,
//           order: {
//             _count: "desc",
//           },
//         },
//       },
//     },
//   };
// };

export const tableDataQuery = (size, sort, page) => {
  return {
    from: page,
    size: size,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
          // {
          //   range: {
          //     "@timestamp": {
          //       gte: "now-1h/h",
          //       lte: "now",
          //       format: "strict_date_optional_time",
          //     },
          //   },
          // },
        ],
      },
    },
    sort: {
      "@timestamp": {
        order: sort,
      },
    },
  };
};

// waf table data
export const WafTableDataQuery = (size, sort, page, filter, date) => {
  var data = {
    from: page,
    size: size,
    query: {
      bool: {
        filter: [
          {
            match_all: {},
          },
        ],
        must: [],
        should: [],
        must_not: []
      },
    },
    sort: {
      "@timestamp": {
        order: sort,
      },
    },
  };
  return filterGen(data, filter, date)
};

// repetition query

export const repetitionsQuery = (
  host,
  hash,
  user,
  dest_ip,
  src_ip,
  detection_name
) => {

  // return {
  //   query: {
  //     bool: {
  //       must: [],
  //       filter: [
  //         // { match_phrase: { host: "JUNE" } },
  //         // { match_phrase: { hash: "2a40173302513b119de147ee43e07189" } },
  //         // { match_phrase: { ip: "No IP" } },
  //         // { match_phrase: { user: "LOCAL SERVICE" } },
  //         { match_phrase: { host: host } },
  //         { match_phrase: { hash: hash } },
  //         { match_phrase: { ip: ip } },
  //         { match_phrase: { user: user } },
  //         {
  //           range: {
  //             current_timestamp: {
  //               gte: "now-365d",
  //               lte: "now",
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //       ],
  //       should: [],
  //       must_not: [],
  //     },
  //   },
  // };
  var data = {
    size: 100,
    query: {
      bool: {
        must: [],
        filter: [],
        should: [],
        must_not: [],
      },
    },
    sort: [
      { detection_timestamp: { order: "desc", unmapped_type: "boolean" } },
    ],
  };

  if (host) {
    data.query.bool.filter.push({ match_phrase: { host: host } });
  }

  if (hash) {
    data.query.bool.filter.push({ match_phrase: { process_hash: hash } });
  }

  if (user) {
    data.query.bool.filter.push({ match_phrase: { user: user } });
  }

  if (src_ip) {
    data.query.bool.filter.push({ match_phrase: { source_ip: src_ip } });
  }

  if (dest_ip) {
    data.query.bool.filter.push({ match_phrase: { dest_ip: dest_ip } });
  }

  if (detection_name) {
    data.query.bool.filter.push({ match_phrase: { detection_name: detection_name } });
  }


  return data;
};

// investigation dashboard query

export const detectedObservablesQuery = (id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_all: {},
          },
          {
            match_phrase: {
              hunt_detection_id: id,
            },
          },
          // {
          //   range: {
          //     current_timestamp: {
          //       gte: "now-365d",
          //       lte: "now",
          //       format: "strict_date_optional_time",
          //     },
          //   },
          // },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const recentFileQuery = (userName, hunt_detection_id, page) => {
  return {
    from: page,
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      {
                        bool: {
                          should: [
                            {
                              match_phrase: {
                                hunt_detection_id: hunt_detection_id,
                              },
                            },
                          ],
                          minimum_should_match: 1,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const processRunByDetectionQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [{ exists: { field: "process_name" } }],
              minimum_should_match: 1,
            },
          },
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const UncommonProcessesRunDetectedQuery = (hunt_detection_id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [{ exists: { field: "uncommon_process_name" } }],
              minimum_should_match: 1,
            },
          },
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const recentAuthQuery = (hunt_detection_id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
          {
            range: {
              timestamp: {
                gte: "now-3650d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const hostLoggedByUserQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
    sort: [
      { "timestamp": "desc" }
    ]
  };
};


// widgets

export const reputationQuery = (hunt_detection_id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export const reputationIPQuery = (hunt_detection_id) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          {
            exists: {
              field: "vt_harmless",
            },
          },

          {
            match_phrase: {
              hunt_detection_id: hunt_detection_id,
            },
          },
        ],

        should: [],

        must_not: [],
      },
    },
  };
};

// Traffic from other hosts to detected IP

export const trafficDetectedIpQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// waf widgets

// WAF Traffic from the IP

export const wafTrafficFromIpQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// waf Threat Detection of the IP – Inbound

export const wafTreatDetectioIpInboundQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// waf Username attempted by the IP

export const wafUserAttemptedByIpQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// Recent Authentications by the user ( AD )

export const recentAuthByUserAdQuery = (hunt_detection_id) => {
  return {
    size: 10000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// Recent Authentications in Host ( AD )

export const recentAuthByHostAdQuery = (hunt_detection_id) => {
  return {
    size: 10000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// User Management Activity By User ( AD )

export const userManagementActivityByUserAdQuery = (hunt_detection_id) => {
  return {
    size: 10000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// firewall
export const fireWallPublicIpInfoOSINTQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};
// Bytes transferred Between IPs
export const bytesTransferredBetweenIpQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// Threat Detection of the IP - inbound
export const threatDetectionIpInboundQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// Threat Detection of the IP - Outbound
export const threatDetectionIpOutboundQuery = (hunt_detection_id) => {
  return {
    size: 1100,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};
export const filterGenInv = (data, fieldList, date, mod) => {
  var data = data;
  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains" || item.type === "gt" || item.type === "lt") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }

    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains" || field.type === "gt" || field.type === "lt") {
        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let srangeindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.range &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.range
                )[0] === field.column.value
              ) {
                srangeindex = i + 1;
              }
            }
          }
          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (srangeindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srangeindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (srangeindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }

  data.track_total_hits = true;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          "detection_timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "detection_timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "detection_timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: moment.utc().subtract(1, 'hour').toISOString(),
  //         lte: moment.utc().toISOString(),
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }

  return data;
};
export const filterGen = (data, fieldList, date, mod) => {
  var data = data;
  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }

    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }

  data.track_total_hits = true;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          "@timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "@timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "@timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: moment.utc().subtract(1, 'hour').toISOString(),
  //         lte: moment.utc().toISOString(),
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }

  return data;
};
export const filterGenHunt = (data, fieldList, date, sort, mod) => {
  var data = data;

  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }

    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }

  var sortQ = [
    {
      detection_timestamp: {
        order: sort,
        unmapped_type: "boolean",
      },
    },
  ];

  data.track_total_hits = true;
  data.sort = sortQ;

  data.track_total_hits = true;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          "detection_timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "detection_timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }

      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "detection_timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: moment.utc().subtract(1, 'hour').toISOString(),
  //         lte: moment.utc().toISOString(),
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }

  return data;
};
export const filterGenCase = (data, fieldList, date, mod) => {
  var data = data;
  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }

    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field, mod);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }

  data.track_total_hits = true;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          "case_created": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "case_created": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "case_created": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: moment.utc().subtract(1, 'hour').toISOString(),
  //         lte: moment.utc().toISOString(),
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }

  return data;
};

export const getFileActivityQuery = (code, filter, date) => {
  var data = {
    from: 0,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "event.code": code,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.SubjectUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              should: [
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "SYSTEM",
                  },
                },
                {
                  match_phrase: {
                    "winlog.event_data.TargetUserName": "*$*",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "host.name",
          order: {
            _count: "desc",
          },
          size: 20,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};
const genstrut = (field, mod) => {
  if (field.type === "contains") {
    const must = {
      wildcard: {},
    };
    if ((field.column.value === "case_title") && field.selectedValue.includes(' ')) {
      let match = {
        match: {
          [field.column.value]: field.selectedValue
        }
      }
      return match
    }
    if (field.column.value === "event.original") {
      var input = field.selectedValue.replace(/[\t\n]/g, '')
      const specialCharsPattern = /[+\-&&||!{}[\]^"~*?:\\/]/;
      if (specialCharsPattern.test(input)) {
        var mustq = {
          query_string: {
            query: `\"${input}\"`,
          },
        };
      } else {
        var mustq = {
          query_string: {
            query: `*${input}*`,
          },
        }
      }

      return mustq;
    }
    var input = field.selectedValue.replace(/[\t\n]/g, '')
    const specialCharsPattern = /[+\-&&||!{}[\]^"~*?:\\/]/;
    if (specialCharsPattern.test(input) && (field.column.value === "host")) {
      var wildval = {
        value: `${input}`,
        case_insensitive: true,
      };
      must.wildcard[`${field.column.value}.keyword`] = wildval;
    } else {
      var wildval = {
        value: `*${field.selectedValue}*`,
        case_insensitive: true,
      }
      must.wildcard[field.column.value] = wildval;
    }


    return must;
  }
  if (field.type === "not contains") {
    var must = {
      wildcard: {},
    };
    const wildval = {
      value: `*${field.selectedValue}*`,
      case_insensitive: true,
    };
    must.wildcard[field.column.value] = wildval;
    return must;
  } else if (field.type === "is") {
    var must = {
      term: {},
    };
    must.term[field.column.value] = field.selectedValue;
    if (field.column.value === "threat_classification") {
      var must = {
        match: {},
      };
      must.match[field.column.value] = field.selectedValue;
      return must;
    }
    else if (field.column.value === "tag") {
      var must = {
        match: {},
      };
      must.match[field.column.value] = field.selectedValue;
      return must;
    }
    // if (field?.isGlobal && mod === "ad") {
    //   var must = {
    //     wildcard: {
    //       query_string: {
    //         query: `*${field.selectedValue}*`,
    //         case_insensitive: true,
    //       },
    //     },
    //   };
    //   return must;
    // } 
    if (field?.isGlobal) {
      var input = field.selectedValue.replace(/[\t\n]/g, '')
      var must = {
        "multi_match": {
          "type": "best_fields",
          "query": input,
          "lenient": true
        }
      }
      return must;
    }
    return must;
  } else if (field.type === "is not") {
    const must = {
      match: {},
    };
    must.match[field.column.value] = field.selectedValue;
    return must;
  }
  else if (field.type === "gt") {

    const range = {
      range: {
      }
    }
    const strut = {
      [field.column.value]: {
        "gt": field.selectedValue
      }
    }
    range.range = strut;
    return range
  }
  else if (field.type === "lt") {
    const range = {
      range: {
      }
    }
    const strut = {
      [field.column.value]: {
        "lt": field.selectedValue
      }
    }
    range.range = strut;
    return range
  }
};

export const invFilterGenerate = (data, fieldList, date, sort) => {
  var data = data;
  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains" || item.type === "gt" || item.type === "lt") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }
    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains" || field.type === "gt" || field.type === "lt") {
        var distItem = dist.find((val) => val.name === field.column.value);
        console.log("test1", distItem);
        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let srangeindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.range &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.range
                )[0] === field.column.value
              ) {
                srangeindex = i + 1;
              }
            }
          }
          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (srangeindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srangeindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (srangeindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        } else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field,);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }
  var sortQ = [
    {
      detection_timestamp: {
        order: sort,
        unmapped_type: "boolean",
      },
    },
  ];

  data.track_total_hits = true;
  data.sort = sortQ;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          detection_timestamp: {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            detection_timestamp: {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          detection_timestamp: {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }

  return data;
};

export const logFilterQuery = (log, filter, date, count, page) => {
  var data = {
    from: page,

    size: count,

    query: {
      bool: {
        must: [],

        filter: [
          {
            match: {
              investigation_status: "Investigated",
            },
          },
          {
            match: {
              log_category: log,
            },
          }
        ],

        should: [],

        must_not: [
        ],
      },
    },
  };
  return invFilterGenerate(data, filter, date, "desc");
};

// new widgetss

export const getMailsSendToEmailIdQuery = (hunt_detection_id) => {
  return {
    size: 10000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// Mails send from the email id

export const getMailsSendFromEmailIdQuery = (hunt_detection_id) => {
  return {
    size: 10000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// TI widgets

export const getTiWidgetsQuery = (field_name) => {
  return {
    query: {
      bool: {
        must: [],
        filter: [
          {
            multi_match: {
              query: field_name,
              type: "cross_fields",
              fields: "*",
              operator: "and",
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// new grouped view

// export const groupedInvestigationQuery = (group_id) => {
//   return {
//     size: 1000,
//     query: { bool: { filter: [{ match_phrase: { grp_id: group_id } }] } },
//   };
// };
export const groupedInvestigationQuery = (group_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        filter: [{ match_phrase: { grp_id: group_id } }]
      }
    },
    sort: [
      { "detection_timestamp": "desc" } // Assuming the timestamp field is named "@timestamp"
    ]
  };
};


// get investigation details with hunt id

export const getDetailsWithHuntDetectionIdQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}


// recentAuthIn0365Query

export const recentAuthInO365Query = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}


// Recent Operations in SharePoint by User

export const recentOperationSharepointUserQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}

// Recent Operations in OneDrive

export const recentOperationOnedriveQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}

// Recent Operations in AzureAD

export const recentOperationAzureAdQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}

// Recent Operations in Exchange

export const recentOperationExchangeQuery = (hunt_detection_id) => {
  return {
    size: 1000,
    query: {
      bool: {
        must: [],
        filter: [
          { match_all: {} },
          { match_phrase: { hunt_detection_id: hunt_detection_id } },
        ],
        should: [],
        must_not: [],
      },
    },
  };
}

export const SoftwareListQuery = (sort, scanName, day) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [

          {
            "range": {
              "@timestamp": {
                "gte": `now-${day}d/d`,
                "lte": "now/d"
              }
            }
          }
        ],
        "must_not": [
          {
            "wildcard": {
              "software_product": "KB*"
            }
          },
          {
            "match": {
              "software_product": "test"
            }
          },
          {
            "wildcard": {
              "software_product": "*SQL_PRODUCT_SHORT_NAME*"
            }
          },
          {
            "wildcard": {
              "software_product": "*&gt;*"
            }
          },
          {
            "match": {
              "software_product": ""
            }
          }
        ]
      }
    },
    "aggs": {
      "software_count": {
        "terms": {
          "field": "software_product",
          "size": 10,
          "order": {
            "host_count": sort
          }
        },
        "aggs": {
          "host_count": {
            "cardinality": {
              "field": "hostname"
            }
          }
        }
      }
    }
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data;
};

export const totalUniqueVendorCountsQuery = () => {
  return {
    "size": 0,
    "aggs": {
      "unique_vendors": {
        "cardinality": {
          "field": "software_vendor"
        }
      }
    }
  }
}

export const totalUniqueSoftwareCountsQuery = (scanName, days) => {
  var data = {
    "query": {
      "bool": {
        "must": [
          {
            "range": {
              "@timestamp": {
                "gte": `now-${days}d/d`,
                "lte": "now/d"
              }
            }
          }
        ],
        must_not: [
          {
            "match": {
              "software_product": "test"
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "unique_software": {
        "cardinality": {
          "field": "software_product"
        }
      }
    }
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const totalUniqueVendorListQuery = () => {
  return {
    "size": 0,
    "aggs": {
      "unique_vendors": {
        "terms": {
          "field": "software_vendor",
          "size": 10
        },
        "aggs": {
          "unique_software": {
            "cardinality": {
              "field": "software_product"
            }
          }
        }
      }
    }
  }
}


export const softwareTableQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        must_not: [
          {
            "match": {
              "software_product": "test"
            }
          },
          {
            "match": {
              "software_product": ""
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_software": {
        "terms": {
          "field": "software_product",
          "size": 10000
        },
        "aggs": {
          "host_count": {
            "cardinality": {
              "field": "hostname"
            }
          },
          "doc": {
            "top_hits": {
              "size": 1
            }
          }
        }
      }
    }
  }
}

export const getHostListQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        must_not: [
          {
            "match": {
              "software_product": "test"
            }
          },
          {
            "match": {
              "software_product": ""
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_hosts": {
        "terms": {
          "field": "hostname",
          "size": 10000
        },
        "aggs": {
          "software_count": {
            "cardinality": {
              "field": "software_product"
            }
          },
          "doc": {
            "top_hits": {
              "size": 1
            }
          }
        }
      }
    }
  }
}

export const uniqueVulnsCountQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        must: [],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vulns": {
        "cardinality": {
          "field": "vulnerability_title"
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const uniqueAssetsCountQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          // {
          //   "range":{
          //     "@timestamp" : {
          //         "gte": `now-${range}d/d`,  
          //         "lte": "now/d"           
          //        }
          //     }
          //   }
        ]
      }
    },
    "aggs": {
      "unique_assets": {
        "cardinality": {
          "field": "uuid"
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const uniqueAssetsSoftCountQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          // {
          //   "range":{
          //     "@timestamp" : {
          //         "gte": `now-${range}d/d`,  
          //         "lte": "now/d"           
          //        }
          //     }
          //   }
        ]
      }
    },
    "aggs": {
      "unique_assets": {
        "cardinality": {
          "field": "ip"
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}
export const uniqueVulnsWithExploitsQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          // {
          //   "range":{
          //     "@timestamp" : {
          //         "gte": `now-${range}d/d`,  
          //         "lte": "now/d"           
          //        }
          //     }
          //   },

          {
            "range": {
              "vulnerability_exploits": {
                "gt": 0
              }
            }
          }
        ],

        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vulns": {
        "cardinality": {
          "field": "ip"
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const uniqueHostWithRiskScoreQuery = (scanName, range, lastDate) => {
  var data = {
    "query": {
      "bool": {
        "must": [
          //       {
          // "range":{
          //   "@timestamp" : {
          //     "gte": `now-${range}d/d`,  
          //     "lte": "now/d"           
          //      }
          //   }
          // }
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "size": 0,
    "aggs": {
      "vulnerability_title": {
        "terms": {
          "field": "ip",
          "size": 10,
          "order": {
            "max_riskScore": "desc"
          }
        },
        "aggs": {
          "max_riskScore": {
            "max": {
              "field": "vulnerability_riskScore"
            }
          }
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const vulnsWithExploitListQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          // {
          //   "range":{
          //     "@timestamp" : {
          //       "gte": `now-${range}d/d`,  
          //       "lte": "now/d"           
          //        }
          //     }
          //   },
          {
            "range": {
              "vulnerability_exploits": {
                "gt": 0
              }
            }
          }
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "vulnerability_title": {
        "terms": {
          "field": "vulnerability_title",
          "size": 10
        },
        "aggs": {
          "unique_asset": {
            "cardinality": {
              "field": "ip"
            }
          },
          "vulnerability_title_sort": {
            "bucket_sort": {
              "sort": [
                {
                  "unique_asset": {
                    "order": "desc"
                  }
                }
              ],
              "size": 10
            }
          },
          "doc": {
            "top_hits": {
              "size": 1
            }
          }
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const vulnsBySeverityQuery = (scanName, range, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          //       {
          // "range":{
          //   "@timestamp" : {
          //     "gte": `now-${range}d/d`,  
          //     "lte": "now/d"           
          //      }
          //   }
          // }

        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vulns": {
        "terms": {
          "field": "vulnerability_severity",
          "size": 10
        },
        "aggs": {
          "vulnerability_count": {
            "cardinality": {
              "field": "vulnerability_title"
            }
          }
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${range}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}



export const vulnsSeverityAgeQuery = (days, scanName, lastDate) => {
  var data = {
    "size": 10,
    "query": {
      "bool": {
        "must": [
          {
            "range": {
              "vulnerability_since": {
                "gte": `now-${days}d/d`,
                "lte": "now/d"
              }
            }
          }
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vulnerability_titles": {
        "terms": {
          "field": "vulnerability_severity",
          "size": 10
        },
        "aggs": {
          "vulnerability_severity": {
            "cardinality": {
              "field": "vulnerability_title"
            }
          }
        }
      }
    }
  }
  // if(lastDate!==undefined){
  //   let startOfDay = moment(lastDate).startOf('day').toISOString();
  //   let endOfDay = moment(lastDate).endOf('day').toISOString();

  //   let dateRange = {
  //     "range":{
  //       "@timestamp" : {
  //           "gte": startOfDay,  
  //           "lte": endOfDay           
  //          }
  //       }
  //     }
  //     data.query.bool.must.push(dateRange)
  // }
  // else{
  //   let dateRange = {
  //     "range":{
  //       "@timestamp" : {
  //           "gte": `now-${days}d/d`,  
  //           "lte": "now/d"           
  //          }
  //       }
  //     }
  //     data.query.bool.must.push(dateRange)
  // }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const vulnsSeverityAge90Query = (scanName) => {
  var data = {
    "size": 10,
    "query": {
      "bool": {
        "must": [
          {
            "range": {
              "vulnerability_since": {
                "lte": "now-90d/d"
              }
            }
          }
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vulnerability_titles": {
        "terms": {
          "field": "vulnerability_severity",
          "size": 10
        },
        "aggs": {
          "vulnerability_severity": {
            "cardinality": {
              "field": "vulnerability_title"
            }
          }
        }
      }
    }
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}
export const vulnsOverTimeQuery = (scanName) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "vulnerabilities_over_time": {
        "date_histogram": {
          "field": "@timestamp",
          "interval": "day",
          "format": "yyyy-MM-dd",
          "min_doc_count": 0
        },
        "aggs": {
          "severity_buckets": {
            "terms": {
              "field": "vulnerability_severity"
            },
            "aggs": {
              "vulnerability_count": {
                "cardinality": {
                  "field": "vulnerability_title"
                }
              }
            }
          }
        }
      }
    }
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}
export const vulnsPerHostListQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "must": [
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vendors": {
        "terms": {
          "field": "hostname",
          "size": 10000
        },
        "aggs": {
          "vulns_count": {
            "cardinality": {
              "field": "vulnerability_title"
            }
          },
          "doc": {
            "top_hits": {
              "size": 1
            }
          }
        }
      }
    }
  }
}

export const vulnsListQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "must": [
        ],
        must_not: [
          {
            "match": {
              "vulnerability_title": "test"
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_vendors": {
        "terms": {
          "field": "vulnerability_title",
          "size": 10000
        },
        "aggs": {
          "vulns_count": {
            "cardinality": {
              "field": "hostname"
            }
          },
          "doc": {
            "top_hits": {
              "size": 1
            }
          }
        }
      }
    }
  }
}


export const scanNameQuery = () => {
  return {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "range": {
              "@timestamp": {
                "gte": "now-24h/h"
              }
            }
          }
        ]
      }
    },
    "aggs": {
      "unique_scan_name": {
        "terms": {
          "field": "respository",
          "size": 40
        }
      }
    }
  }
}


export const firewallThreatLandscapeQuery = () => {
  const startOfDay = moment().startOf('day');
  const startofDayUTC = startOfDay.utc().toISOString()
  return {
    "query": {
      "bool": {
        "must": [
          //       {
          // "range":{
          //   "@timestamp": {
          //       "gte": startofDayUTC,  
          //       "lte": "now"            }
          //   }
          // }
        ]
      }
    },
    "sort": [
      { "@timestamp": "desc" }
    ]
  }
}


export const leastHost10query = (scanName, day, lastDate) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [

        ]
      }
    },
    "aggs": {
      "hosts": {
        "terms": {
          "field": "software_product",
          "size": 10,
          "order": {
            "_count": "asc"
          }
        },
        "aggs": {
          "software_count": {
            "terms": {
              "field": "hostname",
              "size": 10,
              "order": {
                "_count": "asc"
              }
            },
            "aggs": {
              "least_installed_count": {
                "bucket_selector": {
                  "buckets_path": {
                    "count": "_count"
                  },
                  "script": "params.count < 10"
                }
              }
            }
          }
        }
      }
    }
  }
  if (lastDate !== undefined) {
    let startOfDay = "now-24h/h";
    let endOfDay = "now/h";

    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": startOfDay,
          "lte": endOfDay
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  else {
    let dateRange = {
      "range": {
        "@timestamp": {
          "gte": `now-${day}d/d`,
          "lte": "now/d"
        }
      }
    }
    data.query.bool.must.push(dateRange)
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}

export const InstalledVendor = (sort) => {
  var data = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
        ]
      }
    },
    "aggs": {
      "software_count": {
        "terms": {
          "field": "software_vendor",
          "size": 10,
          "order": {
            "host_count": sort
          }
        },
        "aggs": {
          "host_count": {
            "cardinality": {
              "field": "software_product"
            }
          }
        }
      }
    }
  }
  return data;
};


export const latestScanQuery = (scanName) => {
  var data = {
    "size": 10,
    "query": {
      "bool": {
        "must": [
        ]
      }
    },
    "sort": [
      {
        "@timestamp": {
          "order": "desc"
        }
      }
    ]
  }
  if (scanName && scanName !== "All") {
    data.query.bool.must.push({
      "match": {
        "respository": scanName
      }
    })
  }
  return data
}