import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const CardLoader = () => {
    return (
        <>
            <div className="card-body">
                <h5>
                    <Skeleton width={140} />
                </h5>
                <p>
                    <Skeleton count={3} />
                </p>
                <span>
                    <Skeleton width={50} />
                </span>
            </div>

            <div className="card-body">
                <h5>
                    <Skeleton width={140} />
                </h5>
                <p>
                    <Skeleton count={3} />
                </p>
                <span>
                    <Skeleton width={50} />
                </span>
            </div>

            <div className="card-body">
                <h5>
                    <Skeleton width={140} />
                </h5>
                <p>
                    <Skeleton count={3} />
                </p>
                <span>
                    <Skeleton width={50} />
                </span>
            </div>
        </>
    )
}

export const SmallCardLoader = () => {
    return (
        <div className="card-body d-flex gap-3 align-items-center">
            <span>
                <Skeleton width={50} height={50} />
            </span>
            <span>
                <Skeleton width={50} height={10} />
                <Skeleton width={90} height={10} />
            </span>
        </div>
    )
}


export const SquareCardLoader = () => {
    return (
        <div className="card-body d-flex gap-3 align-items-center">
            <span style={{ overflow: "hidden", display: "flex", flexWrap: "wrap", gap: "20px" }}>
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
                <Skeleton style={{ borderRadius: "10px" }} width={140} height={140} />
            </span>
        </div>
    )
}

export const OverViewLoader = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="card-body">
                <p>
                    <Skeleton count={2} />
                </p>
                <p>
                    <Skeleton count={2} />
                </p>

                <p>
                    <Skeleton count={3} />
                </p>

                <p>
                    <Skeleton count={2} />
                </p>

                <div style={{ display: 'flex', columnGap: 20 }}>
                    <Skeleton style={{ borderRadius: "10px", flex: 1, height: 70, minWidth: 200 }} />
                    <Skeleton style={{ borderRadius: "10px", flex: 1, height: 70, minWidth: 200 }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                    <Skeleton style={{ borderRadius: "10px" }} width={130} height={70} />
                    <Skeleton style={{ borderRadius: "10px" }} width={130} height={70} />
                    <Skeleton style={{ borderRadius: "10px" }} width={130} height={70} />
                </div>
            </div>
        </div>
    )
}