import React, { useEffect, useState } from 'react'
import { Document, Page, View, Text, Image, } from "@react-pdf/renderer";
import Header from '../Header';

const styles = {
    page: { padding: "20px", fontFamily: "Noto Sans Arabic" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "50%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "50%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
    tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    imageDiv: { justifyContent: 'center', },
    setBorder: { border: "1px solid black" },
    image: {
        width: '40%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "14px", fontWeight: "600", padding: "5px", width: 600 },
    subTitle: { fontSize: "10px", padding: "5px", width: 550, lineHeight: 2 },
    countsContainer: { width: '50%' },
    headerText: { fontSize: "24px", marginBottom: 10 },
    subHeaderText: { fontSize: "16px", marginTop: 20, marginBottom: 10 }
};

const ManagementDashbaordExport = ({ imageBlob }) => {
console.log("image blob",imageBlob);
    const { sectionOne, webFirewall, sectionTwo,
         internalFirewall, proxy, o365, vendors, software,
          vulnerabilities, vulnerabilitiesTwo, threatLandscape, } = imageBlob

    const [sectionOneImageSrc, setSectionOneImageSrc] = useState(null);
    const [webFirewallImageSrc, setWebFirewallImageSrc] = useState(null);
    const [threatLandscapeImageSrc, setThreatLandscapeImageSrc] = useState(null);
    const [sectionTwoImageSrc, setSectionTwoImageSrc] = useState(null);
    const [internalFirewallImageSrc, setInternalFirewallImageSrc] = useState(null);
    const [proxyImageSrc, setProxyImageSrc] = useState(null);
    const [o365ImageSrc, setO365ImageSrc] = useState(null);
    const [vendorImageSrc, setVendorImageSrc] = useState(null);
    const [softwareImageSrc, setSoftwareImageSrc] = useState(null);

    const [vulnerabilitiesImageSrc, setVulnerabilitiesImageSrc] = useState(null);
    const [VulnerabilitiesTwoImageSrc, setVulnerabilitiesTwoImageSrc] = useState(null);

    useEffect(() => {
        console.log("inside management");
        const loadImages = async () => {
            const loadAndSetImage = async (image, setImage) => {
                if (image instanceof Blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(image);
                }
            };
            await loadAndSetImage(sectionOne, setSectionOneImageSrc);
            await loadAndSetImage(webFirewall, setWebFirewallImageSrc);
            await loadAndSetImage(sectionTwo, setSectionTwoImageSrc);
            await loadAndSetImage(internalFirewall, setInternalFirewallImageSrc);
            await loadAndSetImage(proxy, setProxyImageSrc);
            await loadAndSetImage(o365, setO365ImageSrc);
            await loadAndSetImage(vendors, setVendorImageSrc);
            // await loadAndSetImage(software, setSoftwareImageSrc);
            await loadAndSetImage(vulnerabilities, setVulnerabilitiesImageSrc);
            // await loadAndSetImage(vulnerabilitiesTwo, setVulnerabilitiesTwoImageSrc);
            await loadAndSetImage(threatLandscape, setThreatLandscapeImageSrc);
        };

        loadImages();

        // if (sectionOne) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setSectionOneImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(sectionOne);
        // }
        // if (webFirewall) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setWebFirewallImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(webFirewall);
        // }

        // if (threatLandscape) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setThreatLandscapeImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(threatLandscape);
        // }


        // if (sectionTwo) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setSectionTwoImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(sectionTwo);
        // }

        // if (internalFirewall) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setInternalFirewallImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(internalFirewall);
        // }

        // if (proxy) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setProxyImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(proxy);
        // }

        // if (o365) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setO365ImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(o365);
        // }

        // if (vendors) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setVendorImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(vendors);
        // }

        // if (software) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setSoftwareImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(software);
        // }

        // if (vulnerabilities) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setVulnerabilitiesImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(vulnerabilities);
        // }
        // if (vulnerabilitiesTwo) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setVulnerabilitiesTwoImageSrc(reader.result);
        //     };
        //     reader.readAsDataURL(vulnerabilitiesTwo);
        // }
    })

    return (
        <Document>
            <Page style={{ padding: 10 }}>
                <Header />
                <View>
                    <Text style={styles.headerText}>Threat Landscape</Text>
                    <Text style={styles.subTitle}>This dashboard offers a panoramic view, seamlessly integrating data from various security solutions
                        deployed across the organization, including Antivirus (AV), Endpoint Detection and Response (EDR),
                        Firewall, Intrusion Prevention System (IPS), Network Detection and Response (NDR), and Vulnerability
                        Scanners. This integrated approach provides a comprehensive overview of security posture, offering realtime insights into the orchestration of traffic attacks and vulnerabilities. Uncover emerging threats, track
                        potential risks, and fortify your defences with a unified and informed strategy.
                    </Text>
                </View>
                {/* section one */}
                <View>
                    <Text style={styles.subHeaderText}>
                        THREAT LANDSCAPE FROM INTERNET
                    </Text>
                </View>
                <View>
                    <Image source={sectionOneImageSrc} />
                </View>  
                </Page>             
                <Page style={{ padding: 10 }}>
                <View>
                    <Image source={webFirewallImageSrc} />
                </View>
                </Page>
                <Page style={{ padding: 10 }}>
                <View>
                    <Image source={threatLandscapeImageSrc} />
                </View>
                 </Page> 
                 <Page style={{ padding: 10 }}>
                <View>
                    <Text style={styles.subHeaderText}>
                        Email
                    </Text>
                    <Text style={styles.subTitle}>The email activity section of the dashboard provides a summary of the organization's email-related information and activity. This section is crucial for monitoring and analyzing email communications within the organization to ensure security, compliance, and operational efficiency.</Text>
                </View>
                <View>
                    <Image source={sectionTwoImageSrc} />
                </View>
                </Page>

               {/* two */}

                {/* <View>
                    <Text style={styles.subHeaderText}> 
                        INTERNAL THREAT LANDSCAPE
                    </Text>
                </View>  */}
             <Page style={{ padding: 10 }}>
                <View>
                    <Image source={internalFirewallImageSrc} />
                </View>
                </Page>
                <Page style={{ padding: 10 }}>
                <View>
                    <Image source={proxyImageSrc} />
                </View>
                </Page>
                <Page style={{ padding: 10 }}>
                <View>
                    <Image source={o365ImageSrc} />
                </View>
                </Page>
                <Page style={{ padding: 10 }}>

                 {/* three */}
                <View>
                    <Text style={styles.subHeaderText}>
                        Installed Software Summary
                    </Text>
                    <Image source={vendorImageSrc} />
                </View>
                </Page>
                {/* <View>
                    <Image source={softwareImageSrc} />
                </View> */}
 
                 {/* four */}
                 <Page style={{ padding: 10 }}>
                <View> 
                    <Text style={styles.subHeaderText}>
                        Vulnerabilities
                    </Text>
                    <Image source={vulnerabilitiesImageSrc} />
                </View>
                {/* <View>
                    <Image source={VulnerabilitiesTwoImageSrc} />
                </View>  */}
            </Page> 

        </Document>
    )
}

export default ManagementDashbaordExport