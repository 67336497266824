
import React, { useState, useEffect } from 'react'
import { fget, fpost } from "../../../axios/axios";
import styles from "./Dashboard.styles"
import { Button } from 'react-bootstrap';
import { ArrowsOutSimple } from "phosphor-react";
function Dashboard() {


  const [url, setUrl] = useState('');
  const [uName, setUname] = useState('');
  const [password, setPassword] = useState('');
  const [expand, setExpand] = useState(true);
  const apiBaseUrl =localStorage.getItem("apiBaseUrl")

  useEffect(() => {


    handleIframeLoad()

    console.log("Home", process.env.REACT_APP_SSO_URL);
    getSystemVar();
  }, [])

  const getSystemVar = async () => {
    try {
      const response = await fget(
        // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
        `${apiBaseUrl}/core/systemvars`
      );

      let data = response?.data?.variables.find((data) => data.name === 'compliance-ui-url')

      setUrl(data?.value);
      setUname(response?.data?.datalakeuname)
      setPassword(response?.data?.datalakepass)
      // setVariablesArray(response.data?.variables);

    } catch (error) {
      console.log("getSystemVar error in settings", error);
    }
  };
  console.log(url)

  const handleIframeLoad = () => {
    const token_ = btoa(`${uName}:${password}`); // encode a string




    const iframe = document.getElementsByTagName('iframe')[0];
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    const iframeHeaders = { Authorization: `Basic ${token_}` };
    console.log(iframeHeaders)
    Object.keys(iframeHeaders).forEach(headerName => {
      iframeDocument.defaultView?.fetch?.('/', { headers: { [headerName]: iframeHeaders[headerName] } });
    });
  };
  return (
    <>
    {expand ?
      <>
      <iframe width="100%" height="100%" src={url} onLoad={handleIframeLoad}></iframe>
      <Button variant="light" style={styles.buttonright} onClick={()=>setExpand(false)}> <ArrowsOutSimple size={18} /></Button>
    </>
    :
    <>
    <iframe width="100%" height="100%" src={url} style={styles.fullscreen}  onLoad={handleIframeLoad}></iframe>
    <Button variant="light" style={styles.buttonrightfullscreen} onClick={()=>setExpand(true)}> <ArrowsOutSimple size={18} /></Button>
  </>
  }
    </>
  )
}

export default Dashboard