import React, { useEffect, useState } from "react";
import styles from "../Activebytes.styles";
import { convertTimestamp,convertTimestampVulEx } from "../../../../Methods/TiMethods";
import { Link } from "react-router-dom";
import LoaderList from "../../../../components/Loader/LoaderList";
import {
  getVulnerabilitesEx,
} from "../../../../Methods/TiMethods";
import DetailPage from "./DetailPage";
import NoDataFound from "../../../../components/NoDataFount/NoDataFound";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableViewMore from "../.././../../components/ViewMoreBtn/TableViewMore";

import { useDispatch, useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { Calendar } from "phosphor-react";

export default function VulnerabilitiesExploit({
  vulnerabilityExploitFilter,
  setVulnerExSeverityCount,
  setVulnerExCount,
  vulnerExPage,
  VulnerExPerPage,
  vulnerabilityExploitSearch,
  setFilterData,
  selectedDateFilter,
  setActiveTab,
  timeoutLoader,
  vulExpData,
  VulExpTotalCount

}) {
  const dispatch = useDispatch();

  const VulnerabilitiesEXlist = useSelector(
    (state) => state.VulnerabilitiesEXlist
  );
  const {
    exVulnerabilities,
    exVulCounts,
    loading: exVulLoading,
  } = VulnerabilitiesEXlist;

  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState();


  if (VulExpTotalCount != undefined && VulExpTotalCount != 0) {

    setVulnerExCount(VulExpTotalCount)
  }
  // useEffect(() => {
  //   let sevFilter = setFilterData(selectedDateFilter)


  //   dispatch(getVulnerabilitesEx(
  //     VulnerExPerPage,
  //     "desc",
  //     (vulnerExPage - 1) * VulnerExPerPage,
  //     vulnerabilityExploitFilter,
  //     vulnerabilityExploitSearch,
  //     sevFilter));

  //   setVulnerExSeverityCount({
  //     low: exVulnerabilities.filter((item) => item._source.severity === "Low").length,
  //     medium: exVulnerabilities.filter((item) => item._source.severity === "Medium")
  //       .length,
  //     high: exVulnerabilities.filter((item) => item._source.severity === "High")
  //       .length,
  //     critical: exVulnerabilities.filter(
  //       (item) => item._source.severity === "Critical"
  //     ).length,
  //   });


  // }, [vulnerabilityExploitFilter, dispatch, vulnerExPage, VulnerExPerPage, vulnerabilityExploitSearch, selectedDateFilter])




  const handleOpenModal = (data) => {
    setShowDetail(true)
    setDetail(data)
  }
  const handleCloseModal = () => {
    setShowDetail(false)
  }

  return (
    <div className="col-12" style={styles.section1}>
      {exVulLoading === true || timeoutLoader == true ? (
        <LoaderList />
      ) : (
        vulExpData?.length === 0 ? <NoDataFound /> :
          vulExpData?.map(({ _source }) => (
            <div
              className="col-12 d-flex flex-column p-4 mb-4 rounded-3 card ti-card"
              style={styles.tile}
            // onClick={() => handleOpenModal(_source)}
            >
              <h6>{_source.event_name}</h6>
              <div>{_source.event_description}</div>
              <div className="d-flex flex-row gap-3 mt-3 align-items-center">
                <div className="d-flex flex-row gap-2">
                  <span>Vendor - </span>
                  <div><strong>{_source.vendor}</strong></div>
                </div>
                <div className="d-flex flex-row align-items-center gap-2">
                  <span>Severity</span>
                  {_source.severity == "Critical" && (
                    // <div className="p-2 m-0 alert alert-danger">Critical</div>
                    <Badge bg="danger" className="p-2">Critical</Badge>
                  )}
                  {_source.severity == "High" && (
                    // <div className="p-2 m-0 alert alert-danger">High</div>
                    <Badge bg="danger" className="p-2">High</Badge>
                  )}
                  {_source.severity == "Medium" && (
                    // <div className="p-2 m-0 alert alert-warning">Medium</div>
                    <Badge bg="warning" className="p-2">Medium</Badge>
                  )}
                  {_source.severity == "Low" && (
                    // <div className="p-2 m-0 alert alert-success">Low</div>
                    <Badge bg="success" className="p-2">Low</Badge>
                  )}
                </div>
                <div className="d-flex flex-row gap-2 align-items-center">
                  <Calendar size={18} />
                  <h6 className="m-0">{convertTimestampVulEx(_source.attribute_timestamp)}</h6>
                </div>

                <div className="ml-auto p-2">
                  <Link onClick={() => handleOpenModal(_source)}>
                    <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                  </Link>
                  {/* <div>{_source.severity}</div> */}
                </div>
              </div>
            </div>
          )) 
      )}
      {showDetail && (
        <DetailPage detail={detail} showDetail={showDetail} handleCloseModal={handleCloseModal} event="exploit" />
      )}
    </div>
  );
}