import {
    CM_COMM_LIST_REQUEST,
    CM_COMM_LIST_SUCCESS,
    CM_COMM_LIST_FAIL,
    CM_COMM_CREATE_REQUEST,
    CM_COMM_CREATE_SUCCESS,
    CM_COMM_CREATE_FAIL,
    CM_COMM_DELETE_REQUEST,
    CM_COMM_DELETE_SUCCESS,
    CM_COMM_DELETE_FAIL
} from "../../reduxConstants/caseManagement/CommentConstants"


// CASE MANAGEMENT COMMENT LIST
export const listCommentCm = (state = {}, action) => {
    switch (action.type) {
      case CM_COMM_LIST_REQUEST:
        return { loading: true };
      case CM_COMM_LIST_SUCCESS:
        return { loading: false, success: true, address_: action.payload };
      case CM_COMM_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


  // CASE MANAGEMENT COMMENT CREATE
export const createCommentCm = (state = {}, action) => {
    switch (action.type) {
      case CM_COMM_CREATE_REQUEST:
        return { loading: true };
      case CM_COMM_CREATE_SUCCESS:
        return { loading: false, success: true, address_: action.payload };
      case CM_COMM_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


    // CASE MANAGEMENT COMMENT DELETE
export const deleteCommentCm = (state = {}, action) => {
    switch (action.type) {
      case CM_COMM_DELETE_REQUEST:
        return { loading: true };
      case CM_COMM_DELETE_SUCCESS:
        return { loading: false, success: true, address_: action.payload };
      case CM_COMM_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };