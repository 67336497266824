import React from "react";
import * as XLSX from "xlsx";

const XLfileConverter = ({ tableData, filename, sheetname }) => {
  const handleXlDownload = () => {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(tableData);
    XLSX.utils.book_append_sheet(workbook, sheet, sheetname);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <span className="fw-bold" onClick={handleXlDownload}>
      Excel
    </span>
  );
};

export default XLfileConverter;
