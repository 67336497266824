import React, { useEffect, useState } from "react";
import { getGroupedDetectionDetails, getShowAllTime } from "../../Methods/HunterMethods";
import { TimelineLoader } from "../Loader/TimelineLoader";
import styles from "../../pages/UEBA/Detection/Detection.styles";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";
import { Link } from "react-router-dom";
import { Desktop } from "phosphor-react";
import { Badge, Button, Form, Dropdown } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NoDataFound from '../NoDataFount/NoDataFound';
import FilterList from '../FilterList/FilterList';


const GroupedDetections = ({ pageDetails, toggle, toggleStep, filter,
  setFilter,
  dateFilter,
  setDateFilter,
  setShowAddFilter,
  timelineIsLoading, setTimelineIsLoading,
  setTableData, tableData, stepDetails, setStepDetails,
  getFilteredTable,
  module,
  tab,
  detectionDisplay,
  setDetectionDisplay }) => {
  console.log("toggleStep :", toggleStep)
  const { group_id } = pageDetails;
  console.log("GRP ID :", group_id)

  console.log('stepDetails stepDetails stepDetailsss :', stepDetails)


  const [groupedDetails, setGroupedDetails] = useState([]);
  // const [stepDetails, setStepDetails] = useState([]);



  // useEffect(() => {
  //   setTimelineIsLoading(true)
  //   console.log("timelineIsloading", timelineIsLoading);

  //   if (toggle === true) {
  //     const allItems = toggleStep.map(item => item);

  //       getShowAllTime(allItems)
  //         .then((res) => {
  //           setStepDetails(res.data.hits.hits);
  //           console.log("hhh", stepDetails);
  //         })
  //         .catch((error) => {
  //           console.error('Error:', error);
  //         });
  //     setTimelineIsLoading(false)
  //   }

  //   if(toggle ===false){
  //     getGroupedDetectionDetails(group_id, "hunter")
  //     .then(
  //       ({ 
  //         data: {
  //           hits: { hits },
  //         }, 
  //       }) => {

  //         let groupedInv = []
  //         hits.map((item) => {
  //           groupedInv.push({
  //             host: item?._source?.host,
  //             user_name: item?._source?.user,
  //             detection_name: item?._source?.detection_name,
  //             detection_id: item?._source?.playbook_id,
  //             destination_ip: item?._source?.dest_ip,
  //             source_ip: item?._source?.source_ip,
  //             file: item?._source?.file,
  //             hash: item?._source?.hash,
  //             url: item?._source?.url,
  //             timestamp: item?._source?.detection_timestamp,
  //             hunt_detection_id: item?._source?.hunt_detection_id,

  //           })
  //           // groupDetailsDatas.push(item._source,);
  //         });
  //         setGroupedDetails(groupedInv);
  //         setTimelineIsLoading(false)
  //       }
  //     )
  //     .catch((err) => {
  //       console.log("Error", err);
  //     });
  //   }


  // }, [group_id, toggle , toggleStep]);

  useEffect(() => {
    setTimelineIsLoading(true);
    getGroupedDetectionDetails(group_id, "hunter")
      .then(({ data: { hits: { hits } } }) => {
        const groupedInv = hits.map((item) => ({
          host: item?._source?.host,
          user_name: item?._source?.user,
          detection_name: item?._source?.detection_name,
          detection_id: item?._source?.playbook_id,
          destination_ip: item?._source?.dest_ip,
          source_ip: item?._source?.source_ip,
          file: item?._source?.file,
          hash: item?._source?.hash,
          url: item?._source?.url,
          timestamp: item?._source?.detection_timestamp,
          hunt_detection_id: item?._source?.hunt_detection_id,
        }));
        setGroupedDetails(groupedInv);
        setTimelineIsLoading(false); // Set loading to false after data is fetched
      })
      .catch((err) => {
        console.log("Error", err);
        setTimelineIsLoading(false); // Set loading to false even if there's an error
      });

  }, [group_id, toggle, toggleStep]);



  return (
    <div className="col-12" style={styles.contentWrap}>
      <div className="d-flex col-12 position-relative" style={styles.recentDetectionsWrap}>
        {timelineIsLoading ? (
          <TimelineLoader />
        ) : Object.keys(dateFilter).length !== 0 ? (
          stepDetails?.length ? (
            <div>
              <FilterList
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                getFilteredTable={getFilteredTable}
                module={module}
                tab={tab}
              />
              <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                {stepDetails.map((item, index) => (
                  <li key={index} className="d-flex flex-row gap-3 justify-content-center position-relative" style={styles.detectionItem}>
                    <span className="dateLabel">{getDateFromTimestamp(item._source.detection_timestamp)}</span>
                    <div className="d-flex col-12 gap-3 flex-column px-3">
                      <p className="timeline-item p-3 rounded-3">
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Name</span>
                          <span className="modal-text">{item?._source.detection_name}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Status</span>
                          <span className="modal-text">{item?._source.detection_status}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Severity</span>
                          <span className="modal-text">{item?._source.detection_severity}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Alert ID</span>
                          <span className="modal-text">{item?._source.playbook_id}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection ID</span>
                          <span className="modal-text">{item?._source.hunt_detection_id}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection time</span>
                          <span className="modal-text">{getTimeFromTimestamp(item?._source.detection_timestamp)}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="ml-auto">
                            <Link
                              style={{ color: "grey" }}
                              to={`/investigate/summary/${item.hunt_detection_id}`}
                              state={{ sourceList: groupedDetails[0] }}
                            >
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Investigation Summary
                                  </Tooltip>
                                }
                              >
                                <Desktop style={{ color: "#666666" }} size={18} />
                              </OverlayTrigger>
                            </Link>
                          </span>
                        </span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <FilterList
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                getFilteredTable={getFilteredTable}
                module={module}
                tab={tab}
              />
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <NoDataFound errorText={"No data found"} />
              </div>
            </div>
          )
        ) 
        : detectionDisplay=== true ?  (
          

          stepDetails?.length ? (
            <div>
              <FilterList
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                getFilteredTable={getFilteredTable}
                module={module}
                tab={tab}
              />
              <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                {stepDetails.map((item, index) => (
                  <li key={index} className="d-flex flex-row gap-3 justify-content-center position-relative" style={styles.detectionItem}>
                    <span className="dateLabel">{getDateFromTimestamp(item._source.detection_timestamp)}</span>
                    <div className="d-flex col-12 gap-3 flex-column px-3">
                      <p className="timeline-item p-3 rounded-3">
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Name</span>
                          <span className="modal-text">{item?._source.detection_name}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Status</span>
                          <span className="modal-text">{item?._source.detection_status}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection Severity</span>
                          <span className="modal-text">{item?._source.detection_severity}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Alert ID</span>
                          <span className="modal-text">{item?._source.playbook_id}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection ID</span>
                          <span className="modal-text">{item?._source.hunt_detection_id}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="modal-label col-4">Detection time</span>
                          <span className="modal-text">{getTimeFromTimestamp(item?._source.detection_timestamp)}</span>
                        </span>
                        <span className="modal-row-item">
                          <span className="ml-auto">
                            <Link
                              style={{ color: "grey" }}
                              to={`/investigate/summary/${item.hunt_detection_id}`}
                              state={{ sourceList: groupedDetails[0] }}
                            >
                              <OverlayTrigger
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Investigation Summary
                                  </Tooltip>
                                }
                              >
                                <Desktop style={{ color: "#666666" }} size={18} />
                              </OverlayTrigger>
                            </Link>
                          </span>
                        </span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <FilterList
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                getFilteredTable={getFilteredTable}
                module={module}
                tab={tab}
              />
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <NoDataFound errorText={"No data found"} />
              </div>
            </div>
          )
        ) :
        (
          <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
            {groupedDetails.map((item, index) => (
              <li key={index} className="d-flex flex-row gap-3 justify-content-center position-relative" style={styles.detectionItem}>
                <span className="dateLabel">{getDateFromTimestamp(item.timestamp)}</span>
                <div className="d-flex col-12 gap-3 flex-column px-3">
                  <p className="timeline-item p-3 rounded-3">
                    <span className="modal-row-item">
                      <span className="modal-label col-4">Detection Name</span>
                      <span className="modal-text">{item?.detection_name}</span>
                    </span>
                    {item?.host !== 'No host_name found' && (
                      <span className="modal-row-item">
                        <span className="modal-label col-4">Host name</span>
                        <span className="modal-text">{item?.host}</span>
                      </span>
                    )}

                    {item?.user_name !== 'No user_name found' && (
                      <span className="modal-row-item">
                        <span className="modal-label col-4">Username</span>
                        <span className="modal-text">{item?.user_name}</span>
                      </span>
                    )}

                    {item?.source_ip !== undefined && item?.source_ip !== 'No source ip found' && (
                      <span className="modal-row-item">
                        <span className="modal-label col-4">Source IP</span>
                        <span className="modal-text">{item?.source_ip}</span>
                      </span>
                    )}

                    {item?.dest_ip !== undefined && item?.dest_ip !== 'No dest_ip found' && (
                      <span className="modal-row-item">
                        <span className="modal-label col-4">Destination IP</span>
                        <span className="modal-text">{item?.dest_ip}</span>
                      </span>
                    )}


                    <span className="modal-row-item">
                      <span className="modal-label col-4">ID</span>
                      <span className="modal-text">{item?.playbook_id || item?.detection_id}</span>
                    </span>
                    <span className="modal-row-item">
                      <span className="modal-label col-4">Detection time</span>
                      <span className="modal-text">{getTimeFromTimestamp(item?.timestamp)}</span>
                    </span>
                    <span className="modal-row-item">
                      <span className="ml-auto">
                        <Link
                          style={{ color: "grey" }}
                          to={`/investigate/summary/${item?.hunt_detection_id}`}
                          state={{ sourceList: groupedDetails[0] }}
                        >
                          <OverlayTrigger
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Investigation Summary
                              </Tooltip>
                            }
                          >
                            <Desktop style={{ color: "#666666" }} size={18} />
                          </OverlayTrigger>
                        </Link>
                      </span>
                    </span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

};

export default GroupedDetections;
