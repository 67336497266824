const styles = {
    fullscreen :{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      },
      buttonright:{
        position: "absolute",
        top: 98,
        right: 31,
        width: "36px",
        zIndex: 100
      },
      buttonrightfullscreen:{
        position: "absolute",
        top: 10,
        right: 31,
        width: "36px",
        zIndex: 100
      }
  };
  
  export default styles;