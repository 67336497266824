const styles = {
  filterMenu: {
    // right: 0
  },
  // footerWrap: {
  //   position: "sticky",
  //   bottom: 0,
  //   display: "flex",
  //   alignItems: "center",
  //   // justifyContent: "flex-end",
  //   background: "#FFFFFF"
  // }


};

export default styles;
