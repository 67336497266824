import React, { useEffect, useState } from "react";
import { getAlertQuery, getIsoQuery, getCompNameQuery, getHostNameQuery, getNistQuery, getPciQuery, getUserNameQuery } from "../Queries/ComplianceQuery";
import { fpostDl, fget, fpostC } from "../axios/axios";

// const baseURL = localStorage.getItem("DATALAKE_URL");
const baseURL = localStorage.getItem("CYBOT_URL");
const apiBaseUrl = localStorage.getItem("apiBaseUrl")

export async function getAlerts(filter, date, count, page) {
  let data = getAlertQuery(filter, date, count, page);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}
// iso
export async function getIso(filter) {
  let data = getIsoQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}
// nist
export async function getNist(filter) {
  let data = getNistQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}
// pci
export async function getPci(filter) {
  let data = getPciQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}
// com name
export async function getCompName(filter) {
  let data = getCompNameQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}
// host name
export async function getHostName(filter) {
  let data = getHostNameQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}

// User name
export async function getUserName(filter) {
  let data = getUserNameQuery(filter);
  console.log(data);

  return await fpostC(
    `${baseURL}/cybot_proxy/com_observable*/_search?track_total_hits=true`,
    data
  );
}

export function getControlView(std) {
  return fget(
    // `https://analyticsapi.active-bytes.com/getcomplianceinfo?std=iso27001&control=4.1`
    `${apiBaseUrl}/compliance/info?std=iso27001&control=4.1`
  );
}

export async function closeAlert(id, index) {
  let data = {
    doc: {
      detection_status: "close",
    },
  };
  return await fpostC(`${baseURL}/cybot_proxy/${index}/_update/${id}`, data);
}

export async function getCloseAlert(tenandID, id) {
  const data = {
    doc: {
      opened_by: localStorage.getItem("loggedUser"),
      opened_at: new Date().toISOString(),
      detection_status: "open",
    },
  };

  return await fpostC(`${baseURL}/cybot_proxy/${tenandID}/_update/${id}`, data);
}
