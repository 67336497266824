import React, { useEffect, useState, useRef } from 'react'
import { getCyO365OdTable, getOffice365ODCounts, getOneDriveOPU, getOneDriveSourceCounty } from '../../../../../Methods/CyKitInvestigate'
import NoDataFound from '../../../../../components/NoDataFount/NoDataFound'
import { ArrowsOutSimple } from 'phosphor-react'
import { Tab, Table, Tabs, Button } from 'react-bootstrap'
import TableLoader from '../../../../../components/Loader/TableLoader'
import Pagination from '../../../../../components/Pagination/PaginationTi'
import styles from '../../../Cykit/CykitInvestigation.styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import BarGraph from '../../../../../components/Charts/BarGraph';
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../../Utils/Utils'
import PlainCardLoader from '../../../../../components/Loader/PlainCardLoader'
import useOnclickOutside from 'react-cool-onclickoutside';
import AddFilter from '../../../../../components/AddFilter/AddFilter'
import FilterList from '../../../../../components/FilterList/FilterList'
import InlineFilter from '../../../../../components/InlineFilter/InlineFilter'
import CykitINVExport from '../Export'
import { fpostDl } from '../../../../../axios/axios'
import ExpandedDocument from '../../../../../components/ExpandedDocument/ExpandedDocument'
import TableViewMore from '../../../../../components/ViewMoreBtn/TableViewMore'
import CustomToolTip from '../../../../../components/ToolTip/CustomToolTip'
import SharePointAuditOperation from '../../../../../constants/SharePointAuditOperation'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";


const Office365OneDrive = () => {
    const [doc, setDoc] = useState(null)

    // loader
    const [loadTableData, setLoadTableData] = useState(true)

    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)
    const [operationLoader, setOperationLoader] = useState(true)
    const [userIdLoader, setUserIdLoader] = useState(true)
    const [platformLoader, setPlatformLoader] = useState(true)

    const [sourceCountryCount, setSourceCountryCount] = useState([])
    const [uniqueUsersCount, setUniqueUsersCount] = useState([])

    const [platformLabel, setPlatformLabel] = useState([])
    const [platformData, setPlatformData] = useState([])

    const [operationLabel, setOperationLabel] = useState([])
    const [operationData, setOperationData] = useState([])

    const [userIdLabel, setUserIdLabel] = useState([])
    const [userIdData, setUserIdData] = useState([])

    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [sourceCountryData, setSourceCountryData] = useState([])

    // table
    const [tableData, setTableData] = useState([])

    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])

    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });
    //filter
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({});

    const [showAddFilter, setShowAddFilter] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()
    const datalakeURL = localStorage.getItem("DATALAKE_URL");
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    const DoughnutPlatformRef = useRef()
    const DoughnutUserIDRef = useRef()

    const [isTableTabSelected, setIsTableTabSelected] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    const [manageDatas, setManageDatas] = useState([
        { text: "Client IP", value: "ClientIP", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "User ID", value: "UserId", isSelected: true },
        { text: "Operation", value: "Operation", isSelected: true },
        { text: "Event Source", value: "EventSource", isSelected: true },
        { text: "Item Type", value: "ItemType", isSelected: true },
        { text: "Source File Name", value: "SourceFileName", isSelected: true },
        { text: "URL", value: "ObjectId", isSelected: true },
        { text: "Platform", value: "Platform", isSelected: true },
        { text: "User Agent", value: "UserAgent", isSelected: true },
        { text: "Authentication", value: "AuthenticationType", isSelected: true },

    ])
    const O365UserTypes = {
        "0": {
            "Value": 0,
            "MemberName": "Regular",
            "Description": "A regular user."
        },
        "1": {
            "Value": 1,
            "MemberName": "Reserved",
            "Description": "A reserved user."
        },
        "2": {
            "Value": 2,
            "MemberName": "Admin",
            "Description": "An administrator."
        },
        "3": {
            "Value": 3,
            "MemberName": "DcAdmin",
            "Description": "A Microsoft datacenter operator."
        },
        "4": {
            "Value": 4,
            "MemberName": "System",
            "Description": "A system account."
        },
        "5": {
            "Value": 5,
            "MemberName": "Application",
            "Description": "An application."
        },
        "6": {
            "Value": 6,
            "MemberName": "ServicePrincipal",
            "Description": "A service principal."
        },
        "7": {
            "Value": 7,
            "MemberName": "CustomPolicy",
            "Description": "A custom policy."
        },
        "8": {
            "Value": 8,
            "MemberName": "SystemPolicy",
            "Description": "A system policy."
        }
    }

    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.office.oneDrive') {
                setManageDatas(JSON.parse(localStorage.getItem("ManageColumn.investigate.cykit.office.oneDrive")))
            }
        })
    }, [manageDatas])

    const module = 'investigation'
    const tab = 'cykit-o365onedrive'
    var count = [];
    var count2;
    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)
            setFilter(currentPageFilter);
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);


    const platformType = {
        labels: platformLabel,
        datasets: [
            {
                label: "",
                data: platformData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const platformTypeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };

    const userType = {
        labels: userIdLabel,
        datasets: [
            {
                label: "",
                data: userIdData,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    const userTypeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };


    useEffect(() => {
        setLoadTableData(true)
        setSourceCountryLoader(true)
        setOperationLoader(true)
        setUserIdLoader(true)
        setPlatformLoader(true)
        // getcounts
        getOffice365ODCounts("cybSourceCountry", filter, dateFilter).then(({ data: { aggregations: { unique_users: { value } } } }) => {
            value ? setSourceCountryCount(value) : setSourceCountryCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })

        getOffice365ODCounts("UserId", filter, dateFilter).then(({ data: { aggregations: { unique_users: { value } } } }) => {
            value ? setUniqueUsersCount(value) : setUniqueUsersCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })


        // graph
        // source country

        getOneDriveSourceCounty(filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setSourceCountryLabel(label);
                    setSourceCountryData(data);
                });
            }
            let geoData = [];
            let locations = label
            locations.forEach((location) => {
                geoData.push([location])
            })
            geoData = [["test"], ...geoData]
            setGeoSourceChart(geoData)
            setSourceCountryLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setSourceCountryLoader(false)
        })

        getOneDriveOPU("Operation", false, filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setOperationLabel(label);
                    setOperationData(data);
                });
                setOperationLoader(false)
            }
            else {
                setOperationLabel([]);
                setOperationData([]);
                setOperationLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
            setOperationLoader(false)
        })

        getOneDriveOPU("Platform", true, filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setPlatformLabel(label);
                    setPlatformData(data);
                });
                setPlatformLoader(false)
            }
            else {
                setPlatformLabel([]);
                setPlatformData([]);
                setPlatformLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
            setPlatformLoader(false)
        })

        getOneDriveOPU("UserId", false, filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            console.log("user iddd :", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setUserIdLabel(label);
                    setUserIdData(data);
                });
                setUserIdLoader(false)
            }
            else {
                setUserIdLabel([]);
                setUserIdData([]);
                setUserIdLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
            setUserIdLoader(false)
        })

        // table

        getCyO365OdTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {

            setTableData(hits.hits)
            setLoadTableData(false)
            setTotalPageCount(hits.total.value)

        }).catch((err) => {
            console.log("Error", err)
            setTableData([])
            setLoadTableData(false)
        })

    }, [perPage, page, filter, dateFilter])

    useEffect(() => {
        setLoadTableData(true)
    }, [page, perPage])

    const getFilteredTable = async (filter, date = {}) => {
        setLoadTableData(true)
        if (filter || date?.to) {
            await getCyO365OdTable(filter, date, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTableData(hits.hits)
                setLoadTableData(false)
                console.log("t1", hits.hits)
                setTotalPageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])

                setLoadTableData(false)

            })
        }
        else {
            await getCyO365OdTable("", "", 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTableData(hits.hits)
                setLoadTableData(false)
                setTotalPageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])

                setLoadTableData(false)

            })
        }
    }
    const MapClick = (field, label, value) => {
        if (field && label && value) {

            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value[0],
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    const DoughnutClick = (event, ref, data, field, label) => {
        var activeBars = getElementAtEvent(ref.current, event);
        if (activeBars.length > 0) {
            var clickedDatasetIndex = activeBars[0].datasetIndex;
            var clickedElementIndex = activeBars[0].index;
            var clickedLabel = data.labels[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if (module && tab && field && label) {
                var temp = {
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: clickedLabel,
                    from: "",
                    to: "",
                    isinline: false
                }
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                let temp2 = {
                    ...storedFilter,
                    [module]: {
                        [tab]: {
                            fields: [temp]
                        }
                    }
                };
                localStorage.setItem("filters", JSON.stringify(temp2));
                setFilter([...filter, temp])
            }
        }
    }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;
        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }
        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.office.oneDrive", JSON.stringify(manageDatas))
    }


    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "ClientIP", label: "Client IP" },
                                { value: "UserId", label: "User ID" },
                                { value: "cybSourceCountry", label: "Source Country", isWildCard: true },
                                { value: "Item.Operation", label: "Operation", isWildCard: true },
                                { value: "EventSource", label: "Event Source", isWildCard: true },
                                { value: "ItemType", label: "Item Type", isWildCard: true },
                                { value: "SourceFileName", label: "Source File Name", isWildCard: true },
                                { value: "ObjectId", label: "URL", isWildCard: true },
                                { value: "Platform", label: "Platform", isWildCard: true },
                                { value: "UserAgent", label: "User Agent", isWildCard: true },
                                { value: "AuthenticationType", label: "Authentication", isWildCard: true },
                                { value: "event.original", label: "Raw Field", isWildCard: true },

                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyO365OdTable} type={"365oneDrive"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}



                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}


            </div>

            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />

                            <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{sourceCountryCount ? sourceCountryCount : "--"}</h4>
                                            <span>No of Unique Source Country </span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{uniqueUsersCount ? uniqueUsersCount : "--"}</h4>
                                            <span>No of unique Users</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>Source Country </h5>
                                        {sourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                                            <>
                                                <span className="modal-text">
                                                    <Chart
                                                        chartEvents={[
                                                            {
                                                                eventName: "select",
                                                                callback: ({ chartWrapper }) => {
                                                                    const chart = chartWrapper.getChart();
                                                                    const selection = chart.getSelection();
                                                                    if (selection.length === 0) return;
                                                                    const region = geoSourceChart[selection[0].row + 1];
                                                                    MapClick("cybSourceCountry", "Source Country", region)
                                                                },
                                                            },
                                                        ]}
                                                        chartType="GeoChart"
                                                        width="100%"
                                                        height="300px"
                                                        data={geoSourceChart}
                                                    />
                                                </span>
                                            </>
                                        )}
                                        {/* {sourceCountryLabel.length || sourceCountryData.length !== 0 ? (
                                            <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} module={module} field={"email.from.address"} label={"Sender Address"} />
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )} */}

                                    </div>

                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>Operations</h5>
                                        {operationLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {operationLabel.length || operationData.length !== 0 ? (
                                                    <BarGraph ChartLabel={operationLabel} ChartData={operationData} module={module} tab={tab} field={"Operation"} label={"Operation"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>




                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 p-4 rounded-3 border" >
                                        <h5>Platform</h5>
                                        {platformLoader ? (<PlainCardLoader width="200px" />) : (
                                            <>
                                                {platformLabel.length || platformData.length !== 0 ? (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "350px", }}>
                                                        <Doughnut data={platformType} options={platformTypeOptions} ref={DoughnutPlatformRef} onClick={(event) => { DoughnutClick(event, DoughnutPlatformRef, platformType, "Platform", "Platform") }} />
                                                    </div>
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </div>

                                    <div className="flex-fill p-4 rounded-3 border" >
                                        <h5>User ID</h5>
                                        {userIdLoader ? (<PlainCardLoader width="200px" />) : (
                                            <>
                                                {userIdLabel.length || userIdData.length !== 0 ? (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "350px", }}>
                                                        <Doughnut data={userType} options={userTypeOptions} ref={DoughnutUserIDRef} onClick={(event) => { DoughnutClick(event, DoughnutUserIDRef, userType, "UserId", "User ID") }} />
                                                    </div>
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </div>

                                </div>

                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">


                            <div className="" style={styles.tableWrap}>
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    getFilteredTable={getFilteredTable}
                                    module={module}
                                    tab={tab}
                                />
                                {loadTableData ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    tableData?.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item.text}</th>
                                                    ))}
                                                    {/* <th>Client IP</th>
                                                    <th>User ID</th>
                                                    <th>Source Country</th>
                                                    <th>Operation</th> */}
                                                    {/* <th>BrowserName</th> */}
                                                    {/* <th>Event Source</th>
                                                    <th>Item Type</th>
                                                    <th>Source File Name</th>
                                                    <th>URL</th>
                                                    <th>Platform</th>
                                                    <th>User Agent</th>
                                                    <th>Authentication</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        {manageDatas?.map((data) => (
                                                            data.value === "ClientIP" && data.isSelected === true ? (
                                                                <td>{item?._source?.ClientIP}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ClientIP",
                                                                                            label: "Client IP",
                                                                                        }}
                                                                                        value={item?._source?.ClientIP}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "UserId" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{item?._source?.UserId?.length > 30 ? item?._source?.UserId?.substring(0, 30) + "..." : item?._source?.UserId}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div style={styles.tooltipWrap}>
                                                                                {item?._source?.UserId}

                                                                            </div>
                                                                        }>

                                                                    </CustomToolTip>
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "UserId",
                                                                                            label: "User ID",
                                                                                        }}
                                                                                        value={item?._source?.UserId}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                                <td>{item?._source?.cybSourceCountry}
                                                                    <span className="font-monospace">
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "cybSourceCountry",
                                                                                            label: "Source Country",
                                                                                        }}
                                                                                        value={item?._source?.cybSourceCountry}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Operation" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        <CustomToolTip content={<span>{item?._source?.Operation}</span>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                        <span>Description - </span>
                                                                                        <span><strong>{SharePointAuditOperation[item?._source?.Operation]}</strong></span>
                                                                                    </div>
                                                                                    {item?.item?.["Item.ParentFolder.Path"] &&
                                                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                                                            <span>Path - </span>
                                                                                            <span><strong>{item?._source?.["Item.ParentFolder.Path"]}</strong></span>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            } />
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Operation",
                                                                                            label: "Operation",
                                                                                        }}
                                                                                        value={item?._source?.Operation}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "EventSource" && data.isSelected === true ? (
                                                                <td>{item?._source?.EventSource}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "EventSource",
                                                                                            label: "Event Source",
                                                                                        }}
                                                                                        value={item?._source?.EventSource}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ItemType" && data.isSelected === true ? (
                                                                <td>{item?._source?.ItemType}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ItemType",
                                                                                            label: "Item Type",
                                                                                        }}
                                                                                        value={item?._source?.ItemType}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "SourceFileName" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="font-monospace">
                                                                        <CustomToolTip content={<span>{item?._source?.SourceFileName?.length > 30 ? item?._source?.SourceFileName.substring(0, 30) + "..." : item?._source?.SourceFileName}</span>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    {item?._source?.SourceFileName}
                                                                                </div>
                                                                            } />
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "SourceFileName",
                                                                                            label: "Source File Name",
                                                                                        }}
                                                                                        value={item?._source?.SourceFileName}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "ObjectId" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="position-relative font-monospace ">
                                                                        <CustomToolTip content={<span>{item?._source?.ObjectId?.length > 50 ? item?._source?.ObjectId.substring(0, 50) + "..." : item?._source?.ObjectId}</span>} dataPlacement={"bottom"}
                                                                            dataToggle={
                                                                                <div className="d-flex flex-column text-left" style={styles.tooltipWrap}>
                                                                                    {item?._source?.ObjectId}
                                                                                </div>
                                                                            } />
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "ObjectId",
                                                                                            label: "URL",
                                                                                        }}
                                                                                        value={item?._source?.ObjectId}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "Platform" && data.isSelected === true ? (
                                                                <td>{item?._source?.Platform}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "Platform",
                                                                                            label: "Platform",
                                                                                        }}
                                                                                        value={item?._source?.Platform}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "UserAgent" && data.isSelected === true ? (
                                                                <td>
                                                                    <CustomToolTip content={<span>{item?._source?.UserAgent?.length > 30 ? item?._source?.UserAgent?.substring(0, 30) + "..." : item?._source?.UserAgent}</span>} dataPlacement={"bottom"}
                                                                        dataToggle={
                                                                            <div style={styles.tooltipWrap}>
                                                                                {item?._source?.UserAgent}

                                                                            </div>
                                                                        }></CustomToolTip>
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "UserAgent",
                                                                                            label: "User Agent",
                                                                                        }}
                                                                                        value={item?._source?.UserAgent}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : data.value === "AuthenticationType" && data.isSelected === true ? (
                                                                <td>{item?._source?.AuthenticationType}
                                                                    <span className="font-monospace">

                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "AuthenticationType",
                                                                                            label: "Authentication",
                                                                                        }}
                                                                                        value={item?._source?.AuthenticationType}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) : (<></>)
                                                        ))}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false);setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                {/* <ArrowsOutSimple size={18} style={{ color: "#666666" }} /> */}
                                                                <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument doc={doc} setDoc={setDoc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_o365}/_search?track_total_hits=true`} req={fpostDl} />
        </div>
    )
}

export default Office365OneDrive