import React, { useEffect, useState } from "react";

import {
  fget,
  fgetUTA,
  fpostRs,
  fPostUTA,
  fgetC,
  fDelUTA,
  fPostCm,
} from "../axios/axios";

import {
  CM_LIST_REQUEST,
  CM_LIST_SUCCESS,
  CM_LIST_FAIL,
  CM_CREATE_REQUEST,
  CM_CREATE_SUCCESS,
  CM_CREATE_FAIL,
  CM_CREATE_RESET,
  CM_EDIT_REQUEST,
  CM_EDIT_SUCCESS,
  CM_EDIT_RESET,
  CM_EDIT_FAIL,
  CM_DELETE_REQUEST,
  CM_DELETE_SUCCESS,
  CM_DELETE_FAIL,
  CM_DELETE_RESET,
  CM_SINGLE_LIST_REQUEST,
  CM_SINGLE_LIST_SUCCESS,
  CM_SINGLE_LIST_FAIL,
} from "../reduxConstants/caseManagement/CaseManagementConstants";

import {
  CM_COMM_LIST_REQUEST,
  CM_COMM_LIST_SUCCESS,
  CM_COMM_LIST_FAIL,
  CM_COMM_CREATE_REQUEST,
  CM_COMM_CREATE_SUCCESS,
  CM_COMM_CREATE_FAIL,
  CM_COMM_DELETE_REQUEST,
  CM_COMM_DELETE_SUCCESS,
  CM_COMM_DELETE_FAIL,
} from "../reduxConstants/caseManagement/CommentConstants";
import { async } from "q";
import { filterGen, filterGenCase } from "../Queries/InvestigationQuery";

// CASE MANAGEMENT --- LIST
const apiBaseUrl = localStorage.getItem("CASE_MANAGEMENT_URL")
const apiCaseBaseUrl = localStorage.getItem("apiBaseUrl")

export const listCaseManagement = (page, state) => async (dispatch) => {
  try {
    dispatch({ type: CM_LIST_REQUEST });

    fgetUTA(
      `${apiBaseUrl}/case/list?page=${page}&&state=${state}`
    ).then((res) => {
      dispatch({
        type: CM_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_LIST_FAIL,
      payload: error,
    });
  }
};

// CASE MANAGEMENT --- CREATE

export const createCaseManagement = (data) => async (dispatch) => {
  try {
    dispatch({ type: CM_CREATE_REQUEST });

    fPostUTA(`${apiBaseUrl}/case/create`, data).then(
      (res) => {


        dispatch({
          type: CM_CREATE_SUCCESS,
          payload: res,
        });
      }
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_CREATE_FAIL,
      payload: error,
    });
  }
};

// CASE MANAGEMENT --- EDIT

export const editCaseManagement = (data) => async (dispatch) => {
  try {
    dispatch({ type: CM_EDIT_REQUEST });

    fpostRs(`${apiBaseUrl}/case/update`, data).then(
      (res) => {
        dispatch({
          type: CM_EDIT_SUCCESS,
          payload: res,
        });
      }
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_EDIT_FAIL,
      payload: error,
    });
  }
};

// CASE MANAGEMENT --- CLOSE
export async function closeCaseManagement(id) {
  let data = { case_id: id };
  return await fPostUTA(
    `${apiBaseUrl}/case/close`,
    data
  );
}

// CASE MANAGEMENT --- REOPEN
export async function reOpenCaseManagement(id) {
  let data = { case_id: id };
  return await fPostUTA(
    `${apiBaseUrl}/case/reopen`,
    data
  );
}



// CASE MANAGEMENT --- Delete

export const deleteCaseManagement = (id) => async (dispatch) => {
  try {
    dispatch({ type: CM_DELETE_REQUEST });

    fDelUTA(
      `${apiBaseUrl}/case/delete?id=${id}`
    ).then((res) => {
      dispatch({
        type: CM_DELETE_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_DELETE_FAIL,
      payload: error,
    });
  }
};

//   single - LIST -View

export const singleListView = (id) => async (dispatch) => {
  try {
    dispatch({ type: CM_SINGLE_LIST_REQUEST });
    fgetUTA(`${apiBaseUrl}/case/view?id=${id}`).then(
      (res) => {
        dispatch({
          type: CM_SINGLE_LIST_SUCCESS,
          payload: res,
        });
      }
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_SINGLE_LIST_FAIL,
      payload: error,
    });
  }
};

export async function singleListViewCase(id) {
  return await fgetUTA(
    `${apiBaseUrl}/case/view?id=${id}`
  ).then((res) => {
    console.log(res)
    return res?.status
  }).catch((error) => {

    return error?.response?.status
  })
}


// ************New API CAlls

//   single - category list view

export async function categoryList() {
  return await fgetUTA(
    `${apiBaseUrl}/case/categories`
  );
}

//   single - Activity list POST

export async function activityPost(caseid, activity) {
  let data = { case_id: caseid, activity };
  return await fpostRs(
    `${apiBaseUrl}/case/activity`,
    data
  );
}

export async function createCaseAttached(caseId, attachedCaseIds) {

  let data = { case_id: caseId, attached_case_ids: attachedCaseIds };


  return await fpostRs(
    `${apiBaseUrl}/case/attached_cases/add`,
    data
  );
}

export async function getAttachedCases(id) {
  return await fgetUTA(
    `${apiBaseUrl}/case/attached_cases/list?case_id=${id}`
  );
}

export async function removeCaseAttached(caseId, attachedCaseId) {
  let data = { case_id: caseId, attached_case_id: attachedCaseId };
  return await fpostRs(
    `${apiBaseUrl}/case/attached_cases/delete`,
    data
  );
}

//   single - Activity list GET

export async function activityGet(id) {
  return await fgetUTA(
    `${apiBaseUrl}/case/activity/list?case_id=${id}`
  );
}

//   COMMENT - LIST

export const listCommentCm = (id) => async (dispatch) => {
  try {
    dispatch({ type: CM_COMM_LIST_REQUEST });

    // fget(`https://analyticsapi.active-bytes.com/getcatalogue?type=tenant`).then(
    fget().then((res) => {
      // `https://api-gw1.active-bytes.com/api/v2/investigate/catalogue?type=tenant`
      dispatch({
        type: CM_COMM_LIST_SUCCESS,
        payload: res,
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CM_COMM_LIST_FAIL,
      payload: error,
    });
  }
};

//   COMMENT - CREATE

export async function editCommentCm(data) {
  try {

    console.log(data)
    fPostUTA(`${apiBaseUrl}/case/comment/edit`, data).then(
      (res) => {
        console.log(res)
      }
    );
  } catch (error) {
    console.log(error);

  }
};

export const createCommentCm = (data) => async (dispatch) => {
  try {
    dispatch({ type: CM_COMM_CREATE_REQUEST });

    fPostUTA(`${apiBaseUrl}/case/comment`, data).then(
      (res) => {
        dispatch({
          type: CM_COMM_CREATE_SUCCESS,
          payload: res,
        });
      }
    );
  } catch (error) {

    dispatch({
      type: CM_COMM_CREATE_FAIL,
      payload: error,
    });
  }
};

//   COMMENT - DELETE

export async function deleteCommentCm(caseId, commentId) {
  try {

    let obj = { case_id: caseId, comment_id: commentId }

    return await fPostUTA(`${apiBaseUrl}/case/comment/delete`, obj);
  } catch (error) {
    console.log(error);

  }
};


// RESET REDUX DATA

export const resetReduxData = () => (dispatch) => {
  dispatch({ type: CM_CREATE_RESET });
  dispatch({ type: CM_EDIT_RESET });
  dispatch({ type: CM_DELETE_RESET });

};

export async function listUser() {
  return await fget(`${apiCaseBaseUrl}/core/users`);
}

export async function caseManagementTableData(
  filter,
  dateFilter,
  page,
  perPage,
  search
) {

  let data = {
    from: page,
    size: perPage,
    query: {
      bool: {
        must: [],
        filter: [],
        should: [],
        must_not: [],
      },
    },
    sort: [
      {
        "case_created": {
          "order": "desc",
          "unmapped_type": "boolean",
        },
      },
    ]
  };
  if (search?.length > 0) {

    var searchquery =
      [
        {
          "wildcard": {
            "case_id": {
              "value": `*${search}*`,
              "case_insensitive": true
            }
          }
        },
        {
          "wildcard": {
            "attached_cases": {
              "value": `*${search}*`,
              "case_insensitive": true
            }
          }
        }
      ]
    if (search.includes(' ')) {
      let match = {
        "match": {
          "case_title": {
            "query": `${search}`,
            "operator": "AND"
          }
        }
      }
      searchquery.push(match)
    }
    else {
      let match = {
        "wildcard": {
          "case_title": {
            "value": `*${search}*`,
            "case_insensitive": true
          }
        }
      }
      searchquery.push(match)
    }
    data.query.bool.should.push(...searchquery);
    data.query.bool["minimum_should_match"] = 1
  }
  let query = filterGenCase(data, filter, dateFilter);

  return await fPostCm(
    `${apiBaseUrl}/cybot_proxy/case_management_index/_search`,
    query
  );
}
