import axios from "./index";

export const fget = async (url) => {
  const res = await axios.get(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fgetC = async (url) => {
  const res = await axios.get(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      "Authorization": (localStorage.getItem("IS_UPDATED_PROXY", false) === true || localStorage.getItem("IS_UPDATED_PROXY", false) === "true") ? "Bearer " + localStorage.getItem("auth") : ''

    },
  });
  return res;
};

export const fgetUTA = async (url) => {
  const res = await axios.get(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      // "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fDelUTA = async (url) => {
  const res = await axios.delete(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      // "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fPostUTA = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      // "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fpost = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};
export const fput = async (url, data) => {
  const res = await axios.put(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};
export const fpostRs = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fpostParams = async (url) => {
  const res = await axios.post(
    `${url}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("auth"),
      },
    }
  );
  return res;
};

export const fdeleteParams = async (url) => {
  const res = await axios.delete(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fpostC = async (url, data) => {
  let res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      "Authorization": (localStorage.getItem("IS_UPDATED_PROXY", false) === true || localStorage.getItem("IS_UPDATED_PROXY", false) === "true") ? "Bearer " + localStorage.getItem("auth") : ''
    },
  })
  if (res?.status < 200 || res?.status > 226) {
    res.isError = true
  } else {
    res.isError = false
  }
  return res;
};
export const fdeleteC = async (url) => {
  const res = await axios.delete(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      "Authorization": (localStorage.getItem("IS_UPDATED_PROXY", false) === true || localStorage.getItem("IS_UPDATED_PROXY", false) === "true") ? "Bearer " + localStorage.getItem("auth") : ''
    },
  });
  return res;
};

export const fpostCy = async (url, data) => {
  let res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: (localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === true || localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === "true") ? "Bearer " + localStorage.getItem("auth") : ("Basic " +
        btoa(
          localStorage.getItem("DatalakeUname") +
          ":" +
          localStorage.getItem("DatalakePass")
        )),
    },
  });
  if (res?.status < 200 || res?.status > 226) {
    res.isError = true
  } else {
    res.isError = false
  }
  return res;
};

export const fgetCy = async (url) => {
  const res = await axios.get(`${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        (localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === true || localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === 'true') ? "Bearer " + localStorage.getItem("auth") : "Basic " +
          btoa(
            localStorage.getItem("DatalakeUname") +
            ":" +
            localStorage.getItem("DatalakePass")
          ),
    },
  });
  return res;
};

export const fPostCm = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      // "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  });
  return res;
};

export const fpostD = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: (localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === true || localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === 'true') ? "Bearer " + localStorage.getItem("auth") : localStorage.getItem("DATALAKE_ACCESS_TOKEN"),
    },
  });
  return res;
};

export const fpostDl = async (url, data) => {
  const res = await axios.post(`${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: (localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === true || localStorage.getItem("IS_UPDATED_DATALAKE_PROXY", false) === "true") ? "Bearer " + localStorage.getItem("auth") :
        "Basic " +
        btoa(
          localStorage.getItem("DatalakeUname") +
          ":" +
          localStorage.getItem("DatalakePass")
        ),
    },
  });
  return res;
};
