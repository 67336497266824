// MASTER
export const RT_UEBA_ADD_CATALOGUE_REQUEST = "RT_UEBA_ADD_CATALOGUE_REQUEST";
export const RT_UEBA_ADD_CATALOGUE_SUCCESS = "RT_UEBA_ADD_CATALOGUE_SUCCESS";
export const RT_UEBA_ADD_CATALOGUE_FAIL = "RT_UEBA_ADD_CATALOGUE_FAIL";

export const RT_UEBA_REMOVE_CATALOGUE_REQUEST =
    "RT_UEBA_REMOVE_CATALOGUE_REQUEST";
export const RT_UEBA_REMOVE_CATALOGUE_SUCCESS =
    "RT_UEBA_REMOVE_CATALOGUE_SUCCESS";
export const RT_UEBA_REMOVE_CATALOGUE_FAIL = "RT_UEBA_REMOVE_CATALOGUE_FAIL";

export const RT_UEBA_LIST_MASTER_REQUEST = "RT_UEBA_LIST_MASTER_REQUEST";
export const RT_UEBA_LIST_MASTER_SUCCESS = "RT_UEBA_LIST_MASTER_SUCCESS";
export const RT_UEBA_LIST_MASTER_FAIL = "RT_UEBA_REMOVE_MASTER_FAIL";

export const RT_UEBA_LIST_CUSTOM_MASTER_REQUEST =
    "RT_UEBA_LIST_CUSTOM_MASTER_REQUEST";
export const RT_UEBA_LIST_CUSTOM_SUCCESS = "RT_UEBA_LIST_CUSTOM_SUCCESS";
export const RT_UEBA_LIST_CUSTOM_FAIL = "RT_UEBA_REMOVE_CUSTOM_FAIL";

export const RT_UEBA_CUSTOM_MASTER_REQUEST = "RT_UEBA_CUSTOM_MASTER_REQUEST";
export const RT_UEBA_MASTER_SUCCESS = "RT_UEBA_MASTER_SUCCESS";
export const RT_UEBA_MASTER_FAIL = "RT_UEBA_MASTER_FAIL";
export const RT_UEBA_CUSTOM_MASTER_SUCCESS_FALSE =
    "RT_UEBA_CUSTOM_MASTER_SUCCESS_FALSE";

export const RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_REQUEST =
    "RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_REQUEST";
export const RT_UEBA_MASTER_SINGLE_LIST_SUCCESS =
    "RT_UEBA_MASTER_SINGLE_LIST_SUCCESS";
export const RT_UEBA_MASTER_SINGLE_LIST_FAIL = "RT_UEBA_MASTER_SINGLE_LIST_FAIL";

export const RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST =
    "RT_UEBA_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST";
export const RT_UEBA_MASTER_SINGLE_LIST_QUERY_SUCCESS =
    "RT_UEBA_MASTER_SINGLE_LIST_QUERY_SUCCESS";
export const RT_UEBA_MASTER_SINGLE_LIST_QUERY_FAIL =
    "RT_UEBA_MASTER_SINGLE_LIST_QUERY_FAIL";

// CATALOGUE
export const RT_UEBA_LIST_CAT_REQUEST = "RT_UEBA_LIST_CAT_REQUEST";
export const RT_UEBA_LIST_CAT_SUCCESS = "RT_UEBA_LIST_CAT_SUCCESS";
export const RT_UEBA_LIST_CAT_FAIL = "RT_UEBA_REMOVE_CAT_FAIL";
