import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import ReactJson from "react-json-view";

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./JsonEditor.styles";


export default function JsonEditor(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [isLoading, setIsLoading] = useState(false)

 useEffect(()=>{
    if(props.close){
        setIsOpen(true)
    }
    else{
        setIsOpen(false)
    }
 },[props.close])
  
  const handleClose = () => {
    props.setClick(false)
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal"
              id="offcanvasRightLabel"
            >
                Edit Permissions
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar">
            <Tabs defaultActiveKey="json" aria-label="Tabs with underline">
              <Tab eventKey="json" title="JSON" >
              {isLoading?
              <div style={{ "position": "fixed", "right": "16%", "top": "55%" }}><Spinner animation="border" role="status" /></div>:
                <div className="py-3" style={styles.sidebarContentWrap}>

                  {props.data ? <ReactJson className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={props.data} collapsed={false} onEdit={(updatedData)=>props.setData(updatedData?.updated_src)} onAdd={false} onDelete={false} enableClipboard={false} onSelect={false} displayDataTypes={false} displayObjectSize={false} />
                    : <div>
                      No Data Found about the process
                    </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>}
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}