import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { toastsSuccess, toastsDanger } from "../../../components/Toster/Toster";
import * as htmlToImage from 'html-to-image';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import { Link, useLocation, useParams } from "react-router-dom";
import {
    getCybotDashboardDetails,
    detectedObservables,
    recentFileCreation,
    recentFileDeletion,
    processRunByDetectionUser,
    processRunByDetectionHost,
    UncommonProcessesRunDetectedUser,
    recentAuthHost,
    hashReputationVirusTotal,
    hashReputationAlienVault,
    hashReputationThreatScore,
    hashReputationWidgets,
    ipReputationAlienVault,
    ipReputationVirusTotal,
    ipReputationThreatScore,
    ipReputationWidgets,
    urlReputationVirusTotal,
    urlReputationAlienVault,
    urlReputationThreatScore,
    hostsLoggedByUser,
    trafficHostDetectedIpSummary,
    trafficHostDetectedIpDetailed,
    fireWallTrafficFromIpSummary,
    fireWallTrafficFromIpDetailed,
    fireWallTrafficToIpSummary,
    fireWallTrafficToIpDetailed,
    wafTrafficFromIpSummary,
    wafTrafficFromIpDetailed,
    wafThreatDetectedIp,
    wafUserAttemptIp,
    getPlaybookList,
    getRespondList,
    fireWallPublicIpInfoOSINT,
    bytesTransferredBetweenIp,
    threatDetectionIpInbound,
    threatDetectionIpOutbound,
    urlReputationWidgets,
    getMailsSendToEmailId,
    getMailsSendFromEmailId,
    getWafTrafficFromIp,
    wafTreatDetectioIpInbound,
    wafUserAttemptedByIp,
    recentAuthByUserAd,
    recentAuthByHostAd,
    userManagementActivityByUserAd,
    getTiWidgets,
    getDetailsWithHuntDetectionId,
    getRecentAuthInO365Clientip,
    getRecentAuthInO365UserId,
    getRecentOperationSharepointUserId,
    getRecentOperationSharepointUserClientip,
    getRecentOperationOnedriveUserId,
    getRecentOperationOnedriveClientIp,
    getRecentOperationAzureAdUserId,
    getRecentOperationAzureAdClientIp,
    getRecentOperationExchangeUserId,
    getRecentOperationExchangeClientIp,
} from "../../../Methods/InvestigationMethods";
import { getDateFromTimestamp, getTimeFromTimestamp, rawHTML, removeHtmlChars, replaceWithSpace } from "../../../Utils/Utils";
import DataTable from "../../../components/Investigation/DataTable";
import VirusTotalSummary from "../../../components/Investigation/VirusTotalSummary";
import GaugeChart from "react-gauge-chart";
import { Badge, Button, Dropdown, DropdownButton, Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import styles from "./InvestigationSummary.styles";
import TableLoader from '../../../components/Loader/TableLoader';
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import CustomModal from "../../../components/CustomModal/CustomModal";
import axios from "../../../axios";
import Select from 'react-select';
import AlertBox from "../../../components/AlertBox/AlertBox";
import { SingleLoader, SingleTileLoader } from "../../../components/Loader/SingleTileLoader";
import { fget, fgetCy, fpostC, fpostCy } from "../../../axios/axios";
import { async } from "q";
import { ArrowCircleDown, ArrowCircleUp, CaretDown, CaretUp, Desktop, Info, MagnifyingGlass } from "phosphor-react";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import EditModal from "../../../components/CaseManagement/EditModal";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument";
import InvestigationSummaryExport from "../../../components/PDF/Investigate/InvestigationSummaryExport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SmallTileLoader from "../../../components/Loader/SmallTileLoader";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";
import ReactQuill from "react-quill";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InvDetailModal from "../../../components/RuleStudio/Investigation/InvDetailModal";
import Investigation from "../../RuleStudio/Investigation/Investigation";
import { useNavigate } from "react-router-dom";
import moment from "moment";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const InvestigationSum = ({ investigationData }) => {
    const navigate = useNavigate();

    const module = 'investigate'
    const tab = 'investigations'
    let baseURL = localStorage.getItem("CYBOT_URL")
    //   const {
    //     _source: { user_name, playbook_id, hunt_detection_id, process_name },
    //   } = investigationData;

    const { hunt_detection_id } = useParams()


    //Rulebook-Navigation
    const [invId, setInvId] = useState("")
    const [detailShow, setDetailShow] = useState(false);


    // console.log('investigationData ====', state.index_id)

    // loader
    const [sideBarLoader, setSideBarLoader] = useState(true)
    const [loader, setLoader] = useState(true)


    // close checking state 
    const [closedCheck, setClosedCheck] = useState(false)
    const [detectionStatus, setDetectionStatus] = useState('')


    const [investigationDescription, setInvestigationDescription] = useState([]);

    // console.log("about descriptio :", investigationDescription)
    const [detectionObservable, setDetectionObservable] = useState([]);
    const [detectionObservableDynamic, setDetectionObservableDynamic] = useState([]);

    // console.log("check dynamicc :", detectionObservableDynamic)
    // console.log("check detectionObservable :", detectionObservable)

    const [recentFileData, setRecentFileData] = useState([]);
    const [deletionFileData, setDeletionFileData] = useState([]);
    const [recentAuthHostDetails, setRecentAuthHostDetails] = useState([]);

    const [hostLoggedByUserData, setHostLoggedByUserData] = useState([]);

    // modal
    const [show, setShow] = useState(false);
    const [exceptionShow, setExceptionShow] = useState(false)
    const [respondShow, setRespondShow] = useState(false)

    // Add Case
    const [caseshow, setCaseShow] = useState(false)
    const [case_id, setCase_ID] = useState("");

    const [playBookList, setPlayBookList] = useState([])

    const [respondList, setRespondList] = useState([])
    const [respondParamsCount, setRespondParamsCount] = useState([])

    // console.log("PLAY BOOOK NEW RESP STATE :", playBookList.map((item) => item[0]))

    const [alertPopup, setAlertPopup] = useState(false)
    const [closeAlertPopup, setCloseAlertPopup] = useState(false)
    const [closeAlertMessage, setCloseAlertMessage] = useState('')
    const [addRespondSuccessAlertPopup, setAddRespondSuccessAlertPopup] = useState(false)
    const [addRespondFailedAlertPopup, setAddRespondFailedAlertPopup] = useState(false)
    const [name, setName] = useState("");
    const [idd, setIdd] = useState("");


    const [inputValue, setInputValue] = useState('');
    const [checkPermisionModalVisibility, setCheckPermisionModalVisibility] = useState(false)

    const [detectionClosed, setDetectionClosed] = useState({
        closed_at: "",
        closed_by: "",
        comment: "",
        detection_status: "",
    });

    const [detectionOpen, setDetectionOpen] = useState({
        opened_at: "",
        opened_by: "",
        comment: "",
        detection_status: "",
    });

    const [exceptionFormData, setExceptionFormData] = useState({
        process_executable: '',
        user_name: '',
        process_hash: '',
        playbook_id: '',
        parent_process_name: '',
        host_name: '',
        parent_process_executable: '',
        source_ip: '',
        url: '',
        process_name: '',
        dest_ip: '',
        comment: ''
    })

    const [conclutionLoader, setConclutionLoader] = useState(true)
    const [conclutionData, setConclutionData] = useState('')



    const [trafficHostDetectionFromSummary, setTrafficHostDetectionFromSummary] =
        useState([]);

    const [processRunDetectionUserData, setProcessRunDetectionUserData] =
        useState([]);
    const [processRunDetectionHostData, setProcessRunDetectionHostData] =
        useState([]);

    const [uncommonProcessData, setUncommonProcessData] = useState([]);

    // traffic
    const [trafficHostDetailedData, setTrafficHostDetailedData] = useState([]);

    // firewall
    const [firewallTrafficFromSummaryData, setFirewallTrafficFromSummaryData] =
        useState([]);

    const [firewallTrafficFromDetailedData, setFirewallTrafficFromDetailedData] =
        useState([]);

    const [firewallTrafficToSummaryData, setFirewallTrafficToSummaryData] =
        useState([]);

    const [firewallTrafficToDetailedData, setFirewallTrafficToDetailedData] =
        useState([]);

    const [fireWallPublicIpInfoOSINTData, setFireWallPublicIpInfoOSINTData] = useState([])

    const [bytesTrasferredBetweenIpData, setBytesTrasferredBetweenIpData] = useState([])

    const [threatDetectionIpInboundData, setThreatDetectionIpInboundData] = useState([])

    const [threatDetectionIpOutboundData, setThreatDetectionIpOutboundData] = useState([])

    const [getMailsSendToEmailIdData, setGetMailsSendToEmailIdData] = useState([])

    const [getMailsSendFromEmailIdData, setGetMailsSendFromEmailIdData] = useState([])


    // waff

    const [wafTrafficFromSummaryData, setWafTrafficFromSummaryData] = useState(
        []
    );
    const [wafTrafficFromDetailedData, setWafTrafficFromDetailedData] = useState(
        []
    );
    const [wafThreatDetectedIpData, setWafThreatDetectedIpData] = useState([]);
    const [wafUserAttemptedIpData, setWafUserAttemptedIpData] = useState([]);

    const [wafTrafficFromIpData, setWafTrafficFromIpData] = useState([]);

    const [wafTreatDetectioIpInboundData, setWafTreatDetectioIpInboundData] = useState([]);

    const [wafUserAttemptedByIpData, setWafUserAttemptedByIpData] = useState([]);

    // Ad

    const [recentAuthByUserAdData, setRecentAuthByUserAdData] = useState([]);
    const [recentAuthByHostAdData, setRecentAuthByHostAdData] = useState([]);
    const [userManagementActivityByUserAdData, setUserManagementActivityByUserAdData] = useState([]);

    // loader table

    const [recentAuthLoad, SetRecentAuthLoad] = useState(true)
    const [recentCreationLoad, setRecentCreationLoad] = useState(true)
    const [recentDeletionLoad, setRecentDeletionLoad] = useState(true)
    const [proDetectUserLoad, setProDetectUserLoad] = useState(true)
    const [proDetectHostLoad, setProDetectHostLoad] = useState(true)
    const [unProDetectUserLoad, setUnProDetectUserLoad] = useState(true)
    const [hostLoggedUserLoad, setHostLoggedUserLoad] = useState(true)
    const [trafficFromHostLoad, setTrafficFromHostLoad] = useState(true)
    const [firewallFromIpLoad, setFirewallFromIpLoad] = useState(true)
    const [firewallToIpLoad, setFirewallToIpLoad] = useState(true)
    const [publicIpInfoOSINTLoad, setpublicIpInfoOSINTLoad] = useState(true)
    const [bytesTrasferredBetweenIpLoad, setBytesTrasferredBetweenIpLoad] = useState(true)
    const [threatDetectionIpInboundLoad, setThreatDetectionIpInboundLoad] = useState(true)
    const [threatDetectionIpOutboundLoad, setThreatDetectionIpOutboundLoad] = useState(true)
    const [getMailsSendToEmailIdLoad, setGetMailsSendToEmailIdLoad] = useState(true)
    const [getMailsSendFromEmailIdLoad, setGetMailsSendFromEmailIdLoad] = useState(true)


    // Ad
    const [userManagementActivityByUserAdLoad, setUserManagementActivityByUserAdLoad] = useState(true)
    const [recentAuthByHostAdLoad, setRecentAuthByHostAdLoad] = useState(true)
    const [recentAuthByUserAdLoad, setRecentAuthByUserAdLoad] = useState(true)

    const [wafFromIpLoad, setWafFromIpLoad] = useState(true)
    const [wafThreatIpLoad, setWafThreatIpLoad] = useState(true)
    const [wafUserAttemptedIpLoad, setWafUserAttemptedIpLoad] = useState(true)

    const [wafTrafficFromIpLoad, setWafTrafficFromIpLoad] = useState(true)
    const [wafTreatDetectioIpInboundLoad, setWafTreatDetectioIpInboundLoad] = useState(true)
    const [wafUserAttemptedByIpLoad, setWafUserAttemptedByIpLoad] = useState(true)

    // widgets States
    const [hashVirusTotalData, setHashVirusTotalData] = useState([]);
    const [hashAvData, setHashAvData] = useState([]);
    const [hashTsData, setHashTsData] = useState([]);


    // Ip

    const [ipVirusData, setIpVirusData] = useState([]);
    const [ipAvData, setIpAvData] = useState([]);
    const [ipTsData, setIpTsData] = useState([]);

    // URL

    const [urlVirusData, setUrlVirusData] = useState([]);
    const [urlAvData, setUrlAvData] = useState([]);
    const [urlTsData, setUrlTsData] = useState([]);
    const indexId = localStorage.getItem("HUNTER_INDEX_ALL_INDEX_ID")

    // Tenand ID

    const [tenandId, setTenandId] = useState()


    // Ti widgets

    const [tiUrlData, setTiUrlData] = useState([])
    const [tiHashData, setTiHashData] = useState([])
    const [tiIpData, setTiIpData] = useState([])

    const [tiIpLoader, setTiIpLoader] = useState(true)
    const [tiHashLoader, setTiHashLoader] = useState(true)
    const [tiUrlLoader, setTiUrlLoader] = useState(true)

    // respond
    const [inputValues, setInputValues] = useState([]);
    const [selectedApi, setSelectedApi] = useState([]);
    const [respondDataList, setRespondDataList] = useState([]);


    const [expclickedNodeLog, setExpClickedNodeLog] = useState("")
    // const [expClickedIndex, setExpClickedIndex] = useState("")
    const [expcloseLog, setExpCloseLog] = useState(false)
    const [rawLogId, setRawLogId] = useState("")
    const [rawLogIndex, setRawLogIndex] = useState("")
    const [investigationConclusions, setInvestigationConclusions] = useState([])
    const [investigationConclusionLoader, setInvestigationConclusionLoader] = useState(true)
    const [showMoreAboutInv, setShowMoreAboutInv] = useState(false)


    const caseCreate = useSelector((state) => state.createCaseManagement);
    const { success: caseCreateSuccess } = caseCreate;

    const [selectedDetectionDetails, setSelectedDetectionDetails] = useState([])


    const divRefImage = useRef(null);

    const [imageBlob, setImageBlob] = useState(null);
    const [showDownloadButton, setShowDownloadButton] = useState(true);

    const [showButton, setShowButton] = useState(false);

    // comment close
    const [closedComment, setClosedComment] = useState([])
    const [closureCategory, setClosureCategory] = useState([])

    const [externalInv, setExternalInv] = useState([])

    // comment open
    const [detectionStatusCheck, setDetectionStatusCheck] = useState('')


    // New widgets
    const [recentAuthInO365ClientData, setRecentAuthInO365ClientData] = useState([]);
    const [recentAuthInO365ClientLoader, setRecentAuthInO365ClientLoader] = useState(true);

    const [recentAuthInO365UserData, setRecentAuthInO365UserData] = useState([]);
    const [recentAuthInO365UserLoader, setRecentAuthInO365UserLoader] = useState(true);

    const [recentOperationSharePointUserData, setRecentOperationSharePointUserData] = useState([]);
    const [recentOperationSharePointUserLoader, setRecentOperationSharePointUserLoader] = useState(true);

    const [recentOperationSharePointClientData, setRecentOperationSharePointClientData] = useState([]);
    const [recentOperationSharePointClientLoader, setRecentOperationSharePointClientLoader] = useState(true);

    const [recentOperationOnedriveUserData, setRecentOperationOnedriveUserData] = useState([]);
    const [recentOperationOnedriveUserLoader, setRecentOperationOnedriveUserLoader] = useState(true);

    const [recentOperationOnedriveClientData, setRecentOperationOnedriveClientData] = useState([]);
    const [recentOperationOnedriveClientLoader, setRecentOperationOnedriveClientLoader] = useState(true);

    const [recentOperationAzureAdUserData, setRecentOperationAzureAdUserData] = useState([]);
    const [recentOperationAzureAdUserLoader, setRecentOperationAzureAdUserLoader] = useState(true);

    const [recentOperationAzureAdClientData, setRecentOperationAzureAdClientData] = useState([]);
    const [recentOperationAzureAdClientLoader, setRecentOperationAzureAdClientLoader] = useState(true);

    const [recentOperationExchangeUserData, setRecentOperationExchangeUserData] = useState([]);
    const [recentOperationExchangeUserLoader, setRecentOperationExchangeUserLoader] = useState(true);

    const [recentOperationExchangeClientData, setRecentOperationExchangeClientData] = useState([]);
    const [recentOperationExchangeClientLoader, setRecentOperationExchangeClientLoader] = useState(true);
    const hostname = window.location.hostname
    const [isOnPrem, setIsOnPrem] = useState(false)
    useEffect(() => {
      if(hostname.endsWith('knpc.com')){
        setIsOnPrem(true)
      }},[])
    const isInList = (item) => {
        let myList = ['host_name', 'user_domain', 'user_name', 'dest_ip', 'source_ip', 'process_hash', 'process_name', 'process_hash_sha1', 'hash_sha256', 'user', 'client_ip', 'file_name', 'hash_sha1'];

        return myList.includes(item);
    };

    // widgets chart 

    const graphData = [
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_info_on_host") && obj.auth_info_on_host === 'true').length > 0 && {
            label: "Recent Authentication in Host", data: recentAuthHostDetails.length, desc: "An investigation in Data lake was carried out to examine recent authentication activities on the observed host. The displayed results encompass activity from 24 hours before and after the detection, providing acomprehensive analysis of potential security threats. Examination of authentication logs can reveal signs like atypical login patterns, unauthorized access efforts, or potentially compromised user accounts.For instance, a rapid surge in login attempts originating from multiple IP addresses within a short time frame may indicate a potential brute force attack or a coordinated security breach attempt."
        },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_creations") && obj.recent_file_creations === 'true').length > 0 && { label: "Recent File Creations", data: recentFileData.length, desc: "An investigation was conducted in the Data lake to pinpoint recent file deletions associated with the suspected user on the affected host. Our analysis focused on a window of 24 hours preceding and 24 hours following the detection event. Examining recent file deletions constitutes a pivotal stage in investigations aimed at uncovering potential security breaches or illicit actions. This evaluation serves to ascertain whether unauthorized users, processes, or malware have eradicated essential files, thereby assisting in prompt response and the mitigation of potential risks. The identification of unusual file deletions may indicate various scenarios, such as an adversary attempting to conceal their tracks, the presence of ransomware activity, or the involvement of an insider or rogue employee." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_deletions") && obj.recent_file_deletions === 'true').length > 0 && { label: "Recent File Deletions", data: deletionFileData.length, desc: "An investigation was conducted in the Data lake to identify recent file creations by the suspected user on the affected host. The analysis window encompasses a period starting 24 hours prior to the detection event and ending 24 hours afterward. Inspecting recently generated files on the host can provide insights into any illicit or malevolent actions. Should there be a proliferation of unfamiliar or questionable files in a brief time span, it could be indicative of a potential security breach, malware installation, or the establishment of a backdoor. A comprehensive analysis of these files, including their file hash and file path, can unveil the characteristics and objectives of the attack, facilitating the implementation of suitable defensive actions." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("user_run_processes") && obj.user_run_processes === 'true').length > 0 && { label: "Processes Run by Detected User", data: processRunDetectionUserData?.length, desc: "An investigation was conducted to identify the processes on the host during the detection, listed with their execution frequencies 24 hours before and after the event. The scrutiny of processes executed by an identified user yields valuable information regarding potential malicious activities or compromised accounts. This investigation is pivotal for comprehending a user's actions and plays a crucial role in swiftly recognizing and addressing security threats." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("host_run_processes") && obj.host_run_processes === 'true').length > 0 && { label: "Processes Run by Detected Host", data: processRunDetectionHostData?.length, desc: "An investigation was performed to ascertain the processes executed on the identified host. The panel below displays a comprehensive list of these processes, their execution frequency, and the investigation span, which encompasses 24 hours before and after the detection. Examining the processes initiated by a detected host allows for the detection of potential malicious activities or compromised systems. This analysis offers essential insights into the host's actions, contributing to the rapid identification and mitigation of security threats." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("uncommon_processes_VT") && obj.uncommon_processes_VT === 'true').length > 0 && { label: "Uncommon Processes Run by Detected User", data: uncommonProcessData.length, },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_logged_into_by_user") && obj.other_hosts_logged_into_by_user === 'true').length > 0 && { label: "Hosts Logged into by User", data: hostLoggedByUserData?.length, desc: "The investigation's objective is to uncover additional host logins by the user. The provided list displays the hosts they accessed, starting from 24 hours before the detection event. This examination provides valuable insights into the user's activities and highlights potential security risks associated with their login patterns. These log provides insights into a user's activities across different systems. To identify potential security issues, analysts examine login frequency, unusual login locations, anomalous actions, failed login attempts, and session durations." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_visited_ip") && obj.other_hosts_visited_ip === 'true').length > 0 && { label: "Traffic from Other Host to Detected IP", data: trafficHostDetailedData?.length, desc: "An investigation was conducted to determine whether the observed IP address had been accessed by other hosts on the network in the last 24 hours. This examination aids in identifying potential malicious connections and understanding the extent and impact of the detected IP. Analyzing inbound traffic directed at a specific IP address is vital for security analysts. It helps them determine if the IP is part of a significant attack, like a DDoS attempt. Analysts identify such threats by scrutinizing patterns like sudden traffic spikes, unconventional traffic origins, and recognizable attack signatures. This analysis enables them to understand the threat's nature and develop suitable defense strategies to safeguard the network." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.traffic_from_ip_on_firewall === 'true').length > 0 && {
            label: "Traffic From the Detected IP", data: firewallTrafficFromDetailedData?.length, desc: "An investigation within the Data Lake was conducted to examine traffic originating from the identified IP address within a 24-hour window. Monitoring outbound traffic originating from the identified IP address is crucial for assessing if it is a potential source of suspicious or malicious activity.The analysis of outbound traffic can unveil indications of compromised systems, malware infections, or command- and - control communication.This information enables security teams to take swift response measures."
        },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("firewall_byte_transferred_between_ip") && obj.firewall_byte_transferred_between_ip === 'true').length > 0 && { label: "Bytes Transferred between IPs", data: bytesTrasferredBetweenIpData?.length, desc: "This investigation examines data exchange volumes between specified source and destination IPs over the past 24 hours to detect potential network anomalies or suspicious data transmissions. Identify irregularities by comparing with historical data, analyzing protocols, inspecting data content, monitoring endpoint and user behavior, considering geographic information, evaluating transfer rates, and studying traffic patterns. Unusual patterns may indicate security incidents, such as data theft, prompting further investigation and response." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_outbound") && obj.l7fw_threat_detection_on_ip_outbound === 'true').length > 0 && { label: "Threats Detected from the Destination IP", data: threatDetectionIpOutboundData?.length, desc: "This investigation centers on security threats flagged from the designated destination IP address within the last 24 hours. By pinpointing threats detected at the destination IP, it becomes possible to evaluate which internal resources or systems may be under attack from malicious activities. This proactive monitoring of threats at the destination IP enables swift identification of potential vulnerabilities or compromised systems, facilitating prompt attention and mitigation efforts." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_inbound") && obj.l7fw_threat_detection_on_ip_inbound === 'true').length > 0 && { label: "Threats Detected From the Source IP", data: threatDetectionIpInboundData?.length, desc: "This investigation focuses on security threats identified from the identified source IP address within the last 24 hours. Identifying threats originating from specific source IPs is crucial for pinpointing the origins of attacks and ongoing security risks. This information empowers proactive threat mitigation by enabling the blocking or isolation of malicious sources, thereby reducing potential damage and enhancing overall security." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_to_ip_on_firewall") && obj.traffic_to_ip_on_firewall === 'true').length > 0 && { label: "Traffic to the Detected IP", data: firewallTrafficToDetailedData?.length, desc: "An investigation within the Data Lake was conducted to examine traffic towards the identified IP address within a 24-hour window. Monitoring outbound traffic originating from the identified IP address is crucial for assessing if it is a potential source of suspicious or malicious activity. The analysis of outbound traffic can unveil indications of compromised systems, malware infections, or command-and-control communication. This information enables security teams to take swift response measures." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_user_attempt_by_ip") && obj.l7fw_user_attempt_by_ip === 'true').length > 0 && { label: "Username Attendend by the IP", data: wafUserAttemptedByIpData?.length, desc: "Linking usernames with specific IP addresses plays a vital role in pinpointing the origins of potentially malicious activities. This association facilitates the comprehension of user activity patterns and assists in the investigation of any suspicious behavior connected to particular IP addresses." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_waf") && obj.traffic_from_ip_on_waf === 'true').length > 0 && { label: "WAF from IP", data: wafTrafficFromDetailedData?.length, desc: "Detailed automated investigation by CYBOT™ about the Host & User which executed the suspected activity" },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_threat_detection_on_ip") && obj.waf_threat_detection_on_ip === 'true').length > 0 && { label: "WAF Threat Detected IP", data: wafThreatDetectedIpData?.length, desc: "This investigation focuses on identifying threats detected by the Web Application Firewall (WAF) associated with the identified IP address within the last 24 hours. The goal is to pinpoint potential malicious sources attempting to exploit web applications. By promptly detecting and addressing threats originating from specific IPs, security teams can implement immediate blocking or mitigation measures to uphold web application security." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_user_attempt_by_ip") && obj.waf_user_attempt_by_ip === 'true').length > 0 && { label: "WAF Username Attempted by the IP", data: wafUserAttemptedIpData?.length, desc: "In this investigation, we examine whether the identified IP address made attempts to access specific usernames in the Web Application Firewall (WAF) within the last 24 hours. The objective is to detect any username-related activities from specific IPs, which can signal potential credential stuffing or brute force attacks. Keeping an eye on these attempts is crucial for preventing unauthorized access and safeguarding sensitive data." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_to") && obj.mail_send_to === 'true').length > 0 && { label: "Mails Send by the", data: getMailsSendToEmailIdData?.length, desc: "This investigation centers on the examination of emails sent by an identified email recipient in the past 24 hours. The primary aim is to analyze their communication patterns, with the potential to uncover any participation in suspicious activities. Through the scrutiny of these emails, the widget can aid in identifying whether the user is receiving or forwarding malicious content, ultimately assisting in the detection and containment of potential threats." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_from") && obj.mail_send_from === 'true').length > 0 && { label: "Mails Send by the", data: getMailsSendFromEmailIdData?.length, desc: "In this investigation, the focus is on analyzing emails sent by an identified email sender in the past 24 hours The primary goal is to identify and investigate potential phishing or malicious email campaigns. By singling out and scrutinizing emails from this sender, it enables the analysis of their content and attachments, ultimately aiding in the detection and mitigation of email-based threats." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_user_management_activity") && obj.ad_user_management_activity === 'true').length > 0 && { label: "User Management Activity By User", data: userManagementActivityByUserAdData?.length, desc: "This investigation focuses on monitoring user management activities initiated by a specific user within the last 24 hours. The purpose is to keep tabs on privilege changes and potential misuse of user accounts. User management activity analysis by a security analyst can reveal several threats, including unauthorized access, privilege escalation, insider threats, account compromises, data exfiltration, policy violations, social engineering attempts, account lockouts, security misconfigurations, privilege abuse, questionable account creation, and inactive account risks. This monitoring helps safeguard against various security vulnerabilities and potential malicious actions." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_by_user") && obj.ad_recent_authentication_by_user === 'true').length > 0 && { label: "Recent Authentications by the user", data: recentAuthByUserAdData?.length, desc: "This investigation focuses on reviewing all authentication events by a particular user over the past 30 days. Monitoring recent authentications by a user is crucial for identifying various security threats, including credential compromise, brute force attacks, account hijacking, insider threats, phishing attacks, shared credentials, account lockouts, unusual device or IP activity, unusual access times, third-party application risks, credential stuffing, password policy violations, and unauthorized account creations. This proactive monitoring helps detect and respond to security risks promptly, strengthening overall security." },
        investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_in_host") && obj.ad_recent_authentication_in_host === 'true').length > 0 && {
            label: "Recent Authentications in Host", data: recentAuthByHostAdData?.length, desc: "This investigation focuses on all authentication events on the detected host during the past 30 days. Analysing recent authentications on a host machine helps identify various security threats, including unauthorized access, brute force attacks, account compromise, account hijacking, insider threats, phishing attacks, shared credentials, account lockouts, unusual device or IP activity, unusual access times, credential stuffing, weak passwords, third- party application risks, and unauthorized account creations.This proactive investigation enhances security by detecting and responding to potential threats promptly."
        }
    ]

    const filteredGraphData = graphData?.filter(item => item !== false);

    const labels = filteredGraphData?.map(item => item?.label);
    const dataValues = filteredGraphData?.map(item => item?.data);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };


    const data = {
        labels: labels,
        datasets: [
            {
                label: '',
                data: dataValues,
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                // borderWidth: 1,
                borderWidth: 0,
                hoverOffset: 0,
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: 'bottom',
            }
        ],
    };

    function removeProtocol(url) {
        return url?.replace(/^https?:\/\//i, '');
    }

    useEffect(() => {
        convertToBlob()
    }, [data])

    const convertToBlob = () => {
        setImageBlob(null)
        htmlToImage.toBlob(divRefImage.current).then((blob) => {
            setImageBlob(blob);
            setShowDownloadButton(true)
        });
    };


    useMemo(() => {
        if (hunt_detection_id !== undefined) {
            getDetailsWithHuntDetectionId(hunt_detection_id).then(({ data: { hits } }) => {
                hits?.hits && setSelectedDetectionDetails(hits?.hits[0])
            }).catch((err) => {
                console.log("Error", err)
            })
        }
    }, [hunt_detection_id])

    useEffect(() => {
        setInvId(detectionObservable[0]?._source?.playbook_id)

    }, [detectionObservable])

    useEffect(() => {
        // get respond data list
        getRespondList().then(({ data: { hits: { hits } } }) => {
            let respond = []
            let data = []

            hits?.map((item) => {
                respond.push({ label: item?._source?.api_name })
                data.push({ name: item?._source?.api_name, count: item?._source?.count })
            })
            setRespondList(respond)
            setRespondDataList(data)

        }).catch((err) => {
            console.log("Error", err)
        })



    }, [])

    useEffect(() => {
        setInvestigationConclusionLoader(true)
        // detection close checking
        isClosedCheck()
    }, [closedCheck, selectedDetectionDetails?._id])

    const isClosedCheck = () => {
        fpostC(`${localStorage.getItem("CYBOT_URL")}/cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`, JSON.stringify({
            query: {
                bool: {
                    must: {
                        term: {
                            _id: selectedDetectionDetails._id
                        },
                    },
                },
            },
        })).then((res) => {
            setDetectionStatus(res.data?.hits?.hits[0]?._source?.detection_status)
            setRawLogId(res.data?.hits?.hits[0]?._source?.id)
            setRawLogIndex(res.data?.hits?.hits[0]?._source?.index)
            if (res.data?.hits?.hits[0]?._source?.detection_status !== 'open') {
                setClosureCategory(res.data?.hits?.hits[0]?._source?.closing_tag)
            }

            setClosedComment(res.data?.hits?.hits[0]?._source.comment)
            if (res.data?.hits?.hits[0]?._source?.conclusions) {
                setInvestigationConclusions(res.data?.hits?.hits[0]?._source?.conclusions)
                setInvestigationConclusionLoader(false)
            }
            // setInvestigationConclusionLoader(false)

        })
    }

    useEffect(() => {
        setClosedComment('')
        // licence

        let data = {
            "query": {
                "match_all": {}
            }
        }
        fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
            hits.map((item) => {
                setTenandId(item._source.tenant_id)
            })
        })

        // get playbook list for dropdown

        getPlaybookList().then(({ data }) => {
            let playList = []
            data?.map((item) => {
                playList.push({ label: item[0] + item[1] })
            })
            setPlayBookList(playList)
        }).catch((err) => {
            console.log("Error", err)
        })

        let observableData;
        detectedObservables(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    if (hits[0]?._source?.observables) {
                        setDetectionObservableDynamic(Object.keys(hits[0]?._source?.observables) || {})
                    }
                    setDetectionObservable(hits)
                    observableData = hits
                    setSideBarLoader(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setSideBarLoader(false)
            });
        // if (selectedDetectionDetails?._source?.detection_id !== undefined && selectedDetectionDetails?._source?.playbook_id !== undefined) {
        getCybotDashboardDetails(selectedDetectionDetails?._source?.detection_id ? selectedDetectionDetails?._source?.detection_id : selectedDetectionDetails?._source?.playbook_id)
            .then(({ data }) => {
                // console.log(data);
                setName(data.inv_name)
                setIdd(data.inv_id)
                data && setInvestigationDescription(data);
                setLoader(false)
                let username = observableData?.[0]?._source?.observables?.user_name
                let hostname = observableData?.[0]?._source?.observables?.host_name
                let dest_ip = observableData?.[0]?._source?.observables?.dest_ip
                let destination_country_name = observableData?.[0]?._source?.observables?.destination_country_name
                let source_country_name = observableData?.[0]?._source?.observables?.source_country_name
                let source_ip = observableData?.[0]?._source?.observables?.source_ip
                let from_address = observableData?.[0]?._source?.observables?.from_address
                let receiver_address = observableData?.[0]?._source?.observables?.receiver_address
                let timestamp = getTimeFromTimestamp(observableData?.[0]?._source?.detection_timestamp)
                let updatedText = data?.conclution
                    ?.replace('/*user_name*/', `<strong>${username}</strong>`)
                    ?.replace('/*host_name*/', `<strong>${hostname}</strong>`)
                    ?.replace('/*detection_timestamp*/', `<strong>${timestamp}</strong>`)
                    ?.replace('/*receiver_address*/', `<strong>${receiver_address}</strong>`)
                    ?.replace('/*from_address*/', `<strong>${from_address}</strong>`)
                    ?.replace('/*dest_ip*/', `<strong>${dest_ip}</strong>`)
                    ?.replace('/*source_country_name*/', `<strong>${source_country_name}</strong>`)
                    ?.replace('/*source_ip*/', `<strong>${source_ip}</strong>`)
                    ?.replace('/*destination_country_name*/', `<strong>${destination_country_name}</strong>`);


                // .replace('/*user_name*/', ` <strong>${username}</strong>`).replace('/*host_name*/', `<strong>${hostname}</strong>`).replace('/*detection_timestamp*/', `<strong>${timestamp}</strong>`);
                setConclutionData(updatedText)
                setConclutionLoader(false)
            })
            .catch((err) => {
                console.log("Error", err);
                setLoader(false)
                setConclutionLoader(false)
            });

        // }
        recentFileCreation(selectedDetectionDetails?._source?.user_name, hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let recentTableData = [];
                    hits.map((item) => {
                        recentTableData.push({
                            date: getDateFromTimestamp(item._source?.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            event_category: item?._source?.event_category,
                            action: item?._source?.action,
                            host_name: item?._source?.host_name,
                            user_domain: item?._source?.user_domain,
                            user_name: item?._source?.user_name,
                            file_name: item._source.file_name,
                            file_extension: item?._source?.file_extension,
                            file_path: item?._source?.file_path,
                            file_hash_value: item?._source?.file_hash_value,
                            initiating_process_name: item?._source?.initiating_process_name,
                            process_path: item?._source?.process_path,
                            process_commandline: item?._source?.process_commandline,
                            initiating_process_hash: item?._source?.initiating_process_hash,
                            parent_process_name: item?._source?.parent_process_name,
                        });
                    });
                    setRecentFileData(recentTableData);
                    setRecentCreationLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setRecentCreationLoad(false)
            });

        recentFileDeletion(selectedDetectionDetails?._source?.user_name ? selectedDetectionDetails?._source?.user_name : selectedDetectionDetails?._source?.user, hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let deletionTableData = [];
                    hits.map((item) => {
                        deletionTableData.push({
                            date: getDateFromTimestamp(item._source?.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            event_category: item?._source?.event_category,
                            action: item?._source?.action,
                            host_name: item?._source?.host_name,
                            user_domain: item?._source?.user_domain,
                            user_name: item?._source?.user_name,
                            file_name: item._source.file_name,
                            file_extension: item?._source?.file_extension,
                            file_path: item?._source?.file_path,
                            file_hash_value: item?._source?.file_hash_value,
                            initiating_process_name: item?._source?.initiating_process_name,
                            initiating_process_hash: item?._source?.initiating_process_hash,
                            parent_process_name: item?._source?.parent_process_name,
                        });
                    });
                    setDeletionFileData(deletionTableData);
                    setRecentDeletionLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setRecentDeletionLoad(false)
            });

        processRunByDetectionUser(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let processRunByUser = [];
                    hits.map((item) => {
                        processRunByUser.push({
                            // detection_timestamp: item._source.detection_timestamp,
                            date: getDateFromTimestamp(item._source?.detection_timestamp),
                            time: getTimeFromTimestamp(item._source?.detection_timestamp),
                            host_name: item._source?.host_name,
                            user_domain: item._source?.user_domain,
                            user_name: item._source?.user_name,
                            user_email: item._source?.user_email,
                            event_category: item._source?.event_category,
                            action_type: item._source?.action_type,
                            file_name: item._source?.file_name,
                            file_path: item._source?.file_path,
                            file_hash_value: item._source?.file_hash_value,
                            process_name: item._source.process_name,
                            process_executable: item._source?.process_executable,
                            process_hash: item._source?.process_hash,
                            process_commandline: item._source?.process_commandline,
                            commandline: item._source?.commandline,
                            process_path: item._source?.process_path,
                            parent_process: item._source?.parent_process
                        });
                    });
                    setProcessRunDetectionUserData(processRunByUser);
                    setProDetectUserLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setProDetectUserLoad(false)
            });

        processRunByDetectionHost(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let processRunByHost = [];
                    hits.map((item) => {
                        processRunByHost.push({
                            // detection_timestamp: item._source.detection_timestamp,
                            date: getDateFromTimestamp(item._source?.detection_timestamp),
                            time: getTimeFromTimestamp(item._source?.detection_timestamp),
                            host_name: item._source?.host_name,
                            user_domain: item._source?.user_domain,
                            user_name: item._source?.user_name,
                            user_email: item._source?.user_email,
                            event_category: item._source?.event_category,
                            action_type: item._source?.action_type,
                            file_name: item._source?.file_name,
                            file_path: item._source?.file_path,
                            file_hash_value: item._source?.file_hash_value,
                            process_name: item._source.process_name,
                            process_executable: item._source?.process_executable,
                            process_hash: item._source?.process_hash,
                            process_commandline: item._source?.process_commandline,
                            commandline: item._source?.commandline,
                            process_path: item._source?.process_path,
                            parent_process: item._source?.parent_process
                        });
                    });
                    setProcessRunDetectionHostData(processRunByHost);
                    setProDetectHostLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setProDetectHostLoad(false)
            });

        UncommonProcessesRunDetectedUser(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let uncommonProcessData = [];
                    hits.map((item) => {
                        uncommonProcessData.push({
                            uncommon_process_vt_score: item._source.uncommon_process_vt_score,
                            uncommon_process_name: item._source.uncommon_process_name,
                            uncommon_process_hash: item._source.uncommon_process_hash,
                            detection_timestamp: item._source?.detection_timestamp,
                        });
                    });
                    setUncommonProcessData(uncommonProcessData);
                    setUnProDetectUserLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setUnProDetectUserLoad(false)
            });

        recentAuthHost(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let recentAuthDetails = [];
                    hits.map((item) => {
                        recentAuthDetails.push({
                            // timestamp: getTimeFromTimestamp(item._source.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            date: getDateFromTimestamp(item._source?.timestamp),
                            event_category: item._source?.event_category,
                            host_name: item._source?.host_name,
                            user_domain: item._source?.user_domain,
                            user_name: item._source?.user_name,
                            logon_session_type: item._source?.logon_session_type,
                            action: item._source?.action,
                            event_outcome: item._source?.event_outcome,
                            network_protocol: item._source?.network_protocol,
                            failure_reason: item._source?.failure_reason,
                            process_name: item._source?.process_name,
                            remote_device_name: item._source?.remote_device_name,
                            // action_type: item._source?.action_type,
                        });
                    });
                    // hits.map((item) => {
                    //     item._source.time: getTimeFromTimestamp(item._source.timestamp),
                    //     item._source.date: getDateFromTimestamp(item._source.user_name)
                    // });
                    // console.log('setRecentAuthHostDetails', hits)
                    setRecentAuthHostDetails(recentAuthDetails);
                    SetRecentAuthLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                SetRecentAuthLoad(false)
            });

        hostsLoggedByUser(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let hostLoggedUserData = [];
                    hits.map((item) => {
                        hostLoggedUserData.push({
                            date: getDateFromTimestamp(item._source?.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            event_category: item._source?.event_category,
                            host_name: item._source?.host_name,
                            user_domain: item._source?.user_domain,
                            user_name: item._source?.user_name,
                            logon_type: item._source?.logon_type,
                            event_action: item._source?.event_action,
                            event_outcome: item._source?.event_outcome,
                            network_protocol: item._source?.network_protocol,
                            failure_reason: item._source?.failure_reason,
                            process_name: item._source?.process_name,
                            remote_device_name: item._source?.remote_device_name,
                            // process_path: item._source?.process_path,
                            // process_commandline: item._source?.process_commandline,
                            // process_hash: item._source?.process_hash,
                            // process_parent_name: item._source?.process_parent_name,
                        });
                    });
                    setHostLoggedByUserData(hostLoggedUserData);
                    setHostLoggedUserLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
                setHostLoggedUserLoad(false)
            });

        //   traffic
        // Traffic from the IP

        trafficHostDetectedIpSummary(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let trafficHostDetectionSummary = [];
                    hits.map((item) => {
                        trafficHostDetectionSummary.push({
                            host_name: item._source.host_name,
                            count: item._source.count,
                        });
                    });
                    setTrafficHostDetectionFromSummary(trafficHostDetectionSummary);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        trafficHostDetectedIpDetailed(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let trafficHostDetectedDetailedData = [];
                    hits.map((item) => {
                        trafficHostDetectedDetailedData.push({
                            date: getDateFromTimestamp(item._source.timestamp),
                            time: getTimeFromTimestamp(item._source.timestamp),
                            host_name: item._source.host_name,


                            user_name: item._source.user_name,
                            process_name: item._source.process_name,
                            dest_port: item._source.dest_port,
                            timestamp: item._source.timestamp,

                        });
                    });
                    setTrafficHostDetailedData(trafficHostDetectedDetailedData);
                    setTrafficFromHostLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        //   Firewall
        // traffic from ip
        fireWallTrafficFromIpSummary(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let firewallTrafficSummaryData = [];
                    hits.map((item) => {
                        firewallTrafficSummaryData.push({
                            dest_ip: item._source.dest_ip,
                            dest_port: item._source.dest_port,
                            dest_country: item._source.dest_country,
                            count: item._source.count,
                        });
                    });
                    setFirewallTrafficFromSummaryData(firewallTrafficSummaryData);

                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        fireWallTrafficFromIpDetailed(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let firewallTrafficDetailedData = [];
                    hits.map((item) => {
                        firewallTrafficDetailedData.push({
                            date: getDateFromTimestamp(item._source?.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            source_ip: item._source?.source_ip,
                            source_port: item._source?.source_port,
                            dest_ip: item._source.dest_ip,
                            dest_port: item._source.dest_port,
                            srcname: item._source?.srcname,
                            dstname: item._source?.dstname,
                            action: item._source?.action,
                            event_type: item._source?.event_type,
                            tag2: item._source?.tag2,
                            url: item._source?.url,
                            user_name: item._source?.user_name,
                            service: item._source?.service,
                            type: item._source?.type,
                            source_country_name: item._source?.source_country_name,
                            destination_country_name: item._source?.destination_country_name,
                        });
                    });
                    setFirewallTrafficFromDetailedData(firewallTrafficDetailedData);
                    setFirewallFromIpLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        // fireWallPublicIpInfoOSINT(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
        //     let fWPublicIpInfoOSINT = [];
        //     hits.map((item) => item?._source.result.map((data) => {
        //         fWPublicIpInfoOSINT.push({
        //             CIDR: data?.CIDR,
        //             NetHandle: data?.NetHandle,
        //             NetName: data?.NetName,
        //             NetRange: data?.NetRange,
        //             NetType: data?.NetType,
        //             Organization: data?.Organization,
        //             OriginAS: data?.OriginAS,
        //             Parent: data?.Parent,
        //             RegDate: data?.RegDate,
        //             Updated: data?.Updated,
        //             Address: data?.Address,
        //             City: data?.City,
        //             Country: data?.Country,
        //             OrgAbuseEmail: data?.OrgAbuseEmail,
        //             OrgAbuseHandle: data?.OrgAbuseHandle,
        //             OrgAbuseName: data?.OrgAbuseName,
        //             OrgAbusePhone: data?.OrgAbusePhone,
        //             OrgAbuseRef: data?.OrgAbuseRef,
        //             OrgId: data?.OrgId,
        //             OrgName: data?.OrgName,
        //             OrgTechEmail: data?.OrgTechEmail,
        //             OrgTechHandle: data?.OrgTechHandle,
        //             OrgTechName: data?.OrgTechName,
        //             OrgTechPhone: data?.OrgTechPhone,
        //             OrgTechRef: data?.OrgTechRef,
        //             PostalCode: data?.PostalCode,
        //             ReferralServer: data?.ReferralServer,
        //             RegDate: data?.RegDate,
        //             StateProv: data?.StateProv,
        //             Updated: data?.Updated,
        //         });
        //     }))
        //     setFireWallPublicIpInfoOSINTData(fWPublicIpInfoOSINT)
        //     setpublicIpInfoOSINTLoad(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        fireWallPublicIpInfoOSINT(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            hits && setFireWallPublicIpInfoOSINTData(hits[0]?._source?.result)
            setpublicIpInfoOSINTLoad(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        bytesTransferredBetweenIp(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            let bTBetweenIp = [];
            hits?.map((item) => {
                bTBetweenIp.push({
                    source_ip: item._source?.source_ip,
                    source_port: item._source?.source_port,
                    dest_ip: item._source?.dest_ip,
                    dest_port: item._source?.dest_port,
                    Protocol: item._source?.Protocol,
                    byte_in: item._source?.byte_in,
                    byte_out: item._source?.byte_out,
                });
            });

            setBytesTrasferredBetweenIpData(bTBetweenIp)
            setBytesTrasferredBetweenIpLoad(false)
        }).catch((err) => {
            console.log("Error", err)
            setBytesTrasferredBetweenIpLoad(false)
        })

        threatDetectionIpInbound(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            let IpInbound = [];
            hits?.map((item) => {
                IpInbound.push({
                    date: getDateFromTimestamp(item._source?.timestamp),
                    time: getTimeFromTimestamp(item._source?.timestamp),
                    attack: item._source?.attack,
                    level: item._source?.level,
                    action: item._source?.action,
                    source_ip: item._source?.source_ip,
                    source_port: item._source?.source_port,
                    dest_ip: item._source?.dest_ip,
                    dest_port: item._source?.dest_port,
                    event_type: item._source?.event_type,
                    Reference_url: item._source?.["Reference url"],
                });
            });
            setThreatDetectionIpInboundData(IpInbound)
            setThreatDetectionIpInboundLoad(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        threatDetectionIpOutbound(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            let IpOutbound = [];
            hits?.map((item) => {
                IpOutbound.push({
                    date: getDateFromTimestamp(item._source?.timestamp),
                    time: getTimeFromTimestamp(item._source?.timestamp),
                    attack: item._source?.attack,
                    level: item._source?.level,
                    action: item._source?.action,
                    source_ip: item._source?.source_ip,
                    source_port: item._source?.source_port,
                    dest_ip: item._source?.dest_ip,
                    dest_port: item._source?.dest_port,
                    event_type: item._source?.event_type,
                    Reference_url: item._source?.["Reference url"],
                });
            });
            setThreatDetectionIpOutboundData(IpOutbound)
            setThreatDetectionIpOutboundLoad(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // new widgets
        getMailsSendToEmailId(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            let mailsSentToEmail = [];
            hits.map((item) => {
                mailsSentToEmail.push({
                    date: getDateFromTimestamp(item._source?.timestamp),
                    time: getTimeFromTimestamp(item._source?.timestamp),
                    direction: item._source?.direction,
                    org_level_action: item._source?.org_level_action,
                    subject: item._source?.subject,
                    email_from_address: item._source?.from_address,
                    email_receiver_address: item._source?.receiver_address,
                    delivery_action: item._source?.action,
                    delivery_location: item._source?.delivery_location,
                    sender_name: item._source?.sender_name,
                    properties_SenderFromDomain: item._source?.properties_SenderFromDomain,
                    sender_ipv4: item._source?.sender_ipv4,
                    url_count: item._source?.url_count,
                    threat_name: item._source?.threat_name,
                    threat_type: item._source?.threat_type,
                    properties_AuthenticationDetails: item._source?.properties_AuthenticationDetails,
                    properties_LatestDeliveryAction: item._source?.properties_LatestDeliveryAction,
                    properties_LatestDeliveryLocation: item._source?.properties_LatestDeliveryLocation,
                });
            });
            setGetMailsSendToEmailIdData(mailsSentToEmail)
            setGetMailsSendToEmailIdLoad(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // Mails send from the email id
        getMailsSendFromEmailId(hunt_detection_id).then(({ data: { hits: { hits } } }) => {
            let mailsSentFromEmail = [];
            hits.map((item) => {
                mailsSentFromEmail.push({
                    date: getDateFromTimestamp(item._source?.timestamp),
                    time: getTimeFromTimestamp(item._source?.timestamp),
                    direction: item._source?.direction,
                    org_level_action: item._source?.org_level_action,
                    subject: item._source?.subject,
                    email_from_address: item._source?.from_address,
                    email_receiver_address: item._source?.receiver_address,
                    delivery_action: item._source?.action,
                    delivery_location: item._source?.delivery_location,
                    sender_name: item._source?.sender_name,
                    properties_SenderFromDomain: item._source?.properties_SenderFromDomain,
                    sender_ipv4: item._source?.sender_ipv4,
                    url_count: item._source?.url_count,
                    threat_name: item._source?.threat_name,
                    threat_type: item._source?.threat_type,
                    properties_AuthenticationDetails: item._source?.properties_AuthenticationDetails,
                    properties_LatestDeliveryAction: item._source?.properties_LatestDeliveryAction,
                    properties_LatestDeliveryLocation: item._source?.properties_LatestDeliveryLocation,
                });
            });
            hits && setGetMailsSendFromEmailIdData(mailsSentFromEmail)
            setGetMailsSendFromEmailIdLoad(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // traffic to the ip
        fireWallTrafficToIpSummary(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let firewallTrafficToSummary = [];
                    hits.map((item) => {
                        firewallTrafficToSummary.push({
                            source_ip: item._source.source_ip,
                            source_port: item._source.source_port,
                            source_country: item._source.source_country,
                            count: item._source.count,
                        });
                    });
                    setFirewallTrafficToSummaryData(firewallTrafficToSummary);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        fireWallTrafficToIpDetailed(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let firewallTrafficToDetailed = [];
                    hits.map((item) => {
                        firewallTrafficToDetailed.push({
                            date: getDateFromTimestamp(item._source?.timestamp),
                            time: getTimeFromTimestamp(item._source?.timestamp),
                            source_ip: item._source?.source_ip,
                            source_port: item._source?.source_port,
                            dest_ip: item._source.dest_ip,
                            dest_port: item._source.dest_port,
                            srcname: item._source?.srcname,
                            dstname: item._source?.dstname,
                            action: item._source?.action,
                            event_type: item._source?.event_type,
                            tag2: item._source?.tag2,
                            url: item._source?.url,
                            user_name: item._source?.user_name,
                            service: item._source?.service,
                            type: item._source?.type,
                            source_country_name: item._source?.source_country_name,
                            destination_country_name: item._source?.destination_country_name,
                        });
                    });
                    setFirewallTrafficToDetailedData(firewallTrafficToDetailed);
                    setFirewallToIpLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        //   WAF
        // traffic from ip

        wafTrafficFromIpSummary(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let wafTrafficFromSummary = [];
                    hits.map((item) => {
                        wafTrafficFromSummary.push({
                            url: item._source.url,
                            count: item._source.count,
                        });
                    });
                    setWafTrafficFromSummaryData(wafTrafficFromSummary);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        wafTrafficFromIpDetailed(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let wafTrafficToSummary = [];
                    hits.map((item) => {
                        wafTrafficToSummary.push({
                            dest_ip: item._source.dest_ip,
                            dest_port: item._source.dest_port,
                            url: item._source.url,
                            timestamp: item._source.timestamp,
                            date: getDateFromTimestamp(item._source.timestamp),
                            time: getTimeFromTimestamp(item._source.timestamp),
                        });
                    });
                    setWafTrafficFromDetailedData(wafTrafficToSummary);
                    setWafFromIpLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        // waf threat detected on ip

        wafThreatDetectedIp(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let wafThreatDetected = [];
                    hits.map((item) => {
                        wafThreatDetected.push({
                            dest_ip: item._source.dest_ip,
                            dest_port: item._source.dest_port,
                            threat_name: item._source?.threat_name,
                            threat: item._source?.threat,
                            timestamp: item._source.timestamp,
                            date: getDateFromTimestamp(item._source.timestamp),
                            time: getTimeFromTimestamp(item._source.timestamp),
                        });
                    });
                    setWafThreatDetectedIpData(wafThreatDetected);
                    setWafThreatIpLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        wafUserAttemptIp(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let wafUserAttempted = [];
                    hits.map((item) => {
                        wafUserAttempted.push({
                            dest_ip: item._source.dest_ip,
                            user_name: item._source.user_name,
                            // url: item._source?.url,
                            timestamp: item._source.timestamp,
                            date: getDateFromTimestamp(item._source.timestamp),
                            time: getTimeFromTimestamp(item._source.timestamp),
                        });
                    });
                    setWafUserAttemptedIpData(wafUserAttempted);
                    setWafUserAttemptedIpLoad(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });


        // waf Traffic from the IP

        getWafTrafficFromIp(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let getWafTrafficFromIpArr = [];
                hits.map((item) => {
                    getWafTrafficFromIpArr.push({
                        dest_ip: item._source.dest_ip,
                        dest_port: item._source.dest_port,
                        url: item._source?.url,
                        timestamp: item._source.timestamp,
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                    });
                });
                setWafTrafficFromIpData(getWafTrafficFromIpArr);
                setWafTrafficFromIpLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setWafTrafficFromIpLoad(false)
        })

        // waf Threat Detection of the IP – Inbound

        wafTreatDetectioIpInbound(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let wafTreatDetectioIpInboundArr = [];
                hits.map((item) => {
                    wafTreatDetectioIpInboundArr.push({
                        dest_ip: item._source.dest_ip,
                        dest_port: item._source.dest_port,
                        url: item._source?.url,
                        timestamp: item._source.timestamp,
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                    });
                });
                setWafTreatDetectioIpInboundData(wafTreatDetectioIpInboundArr);
                setWafTreatDetectioIpInboundLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setWafTreatDetectioIpInboundLoad(false)
        })

        // waf Username attempted by the IP 

        wafUserAttemptedByIp(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let wafUserAttemptedByIpArr = [];
                hits.map((item) => {
                    wafUserAttemptedByIpArr.push({
                        dest_ip: item._source.dest_ip,
                        user_name: item._source.user_name,
                        dest_port: item._source.dest_port,
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                    });
                });
                setWafUserAttemptedByIpData(wafUserAttemptedByIpArr);
                setWafUserAttemptedByIpLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setWafTreatDetectioIpInboundLoad(false)
        })

        // active directory AD

        recentAuthByUserAd(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let recentAuthByUserAdArr = [];
                hits.map((item) => {
                    recentAuthByUserAdArr.push({
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                        user_name: item._source?.user_name,
                        event_category: item._source?.event_category,
                        event_action: item._source?.event_action,
                        event_code: item._source?.event_code,
                        event_outcome: item._source?.event_outcome,
                        event_type: item._source?.event_type,
                        logon_type: item._source?.logon_type,
                        host_name: item._source?.host_name,
                        host_os_name: item._source?.host_os_name,
                        source_ip: item._source?.source_ip,
                        source_domain: item._source?.source_domain,
                        source_port: item._source?.source_port,
                        failure_status: item._source?.failure_status,
                        failure_sub_status: item._source?.failure_sub_status,
                        failure_reason: item._source?.failure_reason,
                        authentication_package_name: item._source?.authentication_package_name,
                    });
                });
                setRecentAuthByUserAdData(recentAuthByUserAdArr);
                setRecentAuthByUserAdLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setRecentAuthByUserAdLoad(false)
        })


        recentAuthByHostAd(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let recentAuthByHostAdArr = [];
                hits.map((item) => {
                    recentAuthByHostAdArr.push({
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                        user_name: item._source?.user_name,
                        event_category: item._source?.event_category,
                        event_action: item._source?.event_action,
                        event_code: item._source?.event_code,
                        event_outcome: item._source?.event_outcome,
                        event_type: item._source?.event_type,
                        logon_type: item._source?.logon_type,
                        host_name: item._source?.host_name,
                        host_os_name: item._source?.host_os_name,
                        source_ip: item._source?.source_ip,
                        source_domain: item._source?.source_domain,
                        source_port: item._source?.source_port,
                        failure_status: item._source?.failure_status,
                        failure_sub_status: item._source?.failure_sub_status,
                        failure_reason: item._source?.failure_reason,
                        authentication_package_name: item._source?.authentication_package_name,
                    });
                });
                setRecentAuthByHostAdData(recentAuthByHostAdArr);
                setRecentAuthByHostAdLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setRecentAuthByHostAdLoad(false)

        })

        // User Management Activity By User ( AD )

        userManagementActivityByUserAd(hunt_detection_id).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let userManagementActivityByUserAdArr = [];
                hits.map((item) => {
                    userManagementActivityByUserAdArr.push({
                        date: getDateFromTimestamp(item._source.timestamp),
                        time: getTimeFromTimestamp(item._source.timestamp),
                        event_action: item._source?.event_action,
                        event_category: item._source?.event_category,
                        event_code: item._source?.event_code,
                        event_outcome: item._source?.event_outcome,
                        event_type: item._source?.event_type,
                        host_name: item._source?.host_name,
                        host_os_name: item._source?.host_os_name,
                        host_id: item._source?.host_id,
                        host_ip: item._source?.host_ip,
                        host_mac: item._source?.host_mac,
                        log_level: item._source?.log_level,
                        message: item._source?.message,
                        related_user: item._source?.related_user,
                        user_domain: item._source?.user_domain,
                        user_id: item._source?.user_id,
                        user_name: item._source?.user_name,
                        user_target_domain: item._source?.user_target_domain,
                        user_target_id: item._source?.user_target_id,
                        user_target_name: item._source?.user_target_name,
                    });
                });
                setUserManagementActivityByUserAdData(userManagementActivityByUserAdArr);
                setUserManagementActivityByUserAdLoad(false)
            }
        ).catch((err) => {
            console.log("Error", err)
            setUserManagementActivityByUserAdLoad(false)
        })

        // widgets
        // Hash Reputation

        // hashReputationVirusTotal(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let hashVirus = [];
        //             hits.map((item) => {
        //                 hashVirus.push({
        //                     name: item._source.hash,
        //                     malicious: item._source.vt_malicious,
        //                     suspicious: item._source.vt_suspicious,
        //                     harmless: item._source.vt_harmless,
        //                 });
        //             });
        //             setHashVirusTotalData(hashVirus);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // hashReputationAlienVault(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let hashAV = [];
        //             hits.map((item) => {
        //                 hashAV.push({ av_score: item._source.hash_av_score });
        //             });
        //             setHashAvData(hashAV);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // hashReputationThreatScore(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let hashTs = [];
        //             hits.map((item) => {
        //                 hashTs.push({ threat_score: item._source.hash_threat_score });
        //             });
        //             setHashTsData(hashTs);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        hashReputationWidgets(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let hashTs = [];
                    let hashAV = [];
                    let hashVirus = [];
                    hits.map((item) => {
                        hashVirus.push({
                            name: item._source.hash,
                            malicious: item._source.vt_malicious,
                            suspicious: item._source.vt_suspicious,
                            harmless: item._source.vt_harmless,
                        });
                        hashAV.push({ av_score: item._source.hash_av_score });
                        hashTs.push({ threat_score: item._source.hash_threat_score });
                    });
                    if (hashVirus[0]?.name) {
                        getTiWidgets(hashVirus[0]?.name).then(({ data: { hits: { hits } } }) => {
                            let tiHash = []
                            hits?.map((item) => {
                                tiHash.push({
                                    event_name: item?._source?.event_name,
                                    severity: item?._source?.severity,
                                    event_description: item?._source?.event_description
                                })
                            })
                            setTiHashData(tiHash)
                            setTiHashLoader(false)
                        }).catch((err) => {
                            console.log("Error", err)
                            setTiHashLoader(false)
                        })
                    }
                    setHashVirusTotalData(hashVirus);
                    setHashAvData(hashAV);
                    setHashTsData(hashTs);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        // IP Reputation

        // ipReputationVirusTotal(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let ipVirus = [];
        //             hits.map((item) => {
        //                 ipVirus.push({
        //                     name: item._source.ip,
        //                     malicious: item._source.vt_malicious,
        //                     suspicious: item._source.vt_suspicious,
        //                     harmless: item._source.vt_harmless,
        //                 });
        //             });
        //             setIpVirusData(ipVirus);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // ipReputationAlienVault(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let ipAv = [];
        //             hits.map((item) => {
        //                 ipAv.push({ av_score: item._source.ip_av_score });
        //             });
        //             setIpAvData(ipAv);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // ipReputationThreatScore(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let ipTs = [];
        //             hits?.map((item) => {
        //                 ipTs.push({ threat_score: item._source?.ip_threat_score });
        //             });
        //             setIpTsData(ipTs);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        ipReputationWidgets(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let ipVirus = [];
                    let ipAv = [];
                    let ipTs = [];
                    hits?.map((item) => {
                        ipVirus.push({
                            name: item._source.ip,
                            malicious: item._source.vt_malicious,
                            suspicious: item._source.vt_suspicious,
                            harmless: item._source.vt_harmless,
                        });
                        ipAv.push({ av_score: item._source.ip_av_score });
                        ipTs.push({ threat_score: item._source?.ip_threat_score });
                    });
                    if (ipVirus[0]?.name) {
                        getTiWidgets(ipVirus[0]?.name).then(({ data: { hits: { hits } } }) => {
                            let TiIp = []
                            hits?.map((item) => {
                                TiIp.push({
                                    event_name: item?._source?.event_name,
                                    severity: item?._source?.severity,
                                    event_description: item?._source?.event_description
                                })
                            })
                            setTiIpData(TiIp)
                            setTiIpLoader(false)
                        }).catch((err) => {
                            console.log("Error", err)
                            setTiIpLoader(false)
                        })
                    }
                    setIpVirusData(ipVirus);
                    setIpAvData(ipAv);
                    setIpTsData(ipTs);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        // URL Reputation

        // urlReputationVirusTotal(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let urlVirus = [];
        //             hits?.map((item) => {
        //                 urlVirus.push({
        //                     name: item._source.url,
        //                     malicious: item._source.vt_malicious,
        //                     suspicious: item._source.vt_suspicious,
        //                     harmless: item._source.vt_harmless,
        //                 });
        //                 setUrlVirusData(urlVirus);
        //             });
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // urlReputationAlienVault(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let urlAv = [];
        //             hits.map((item) => {
        //                 urlAv.push({ av_score: item._source.url_av_score });
        //             });

        //             setUrlAvData(urlAv);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        // urlReputationThreatScore(hunt_detection_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             let urlTs = [];
        //             hits?.map((item) => {
        //                 urlTs.push({ threat_score: item._source.url_threat_score });
        //             });

        //             setUrlTsData(urlTs);
        //         }
        //     )
        //     .catch((err) => {
        //         console.log("Error", err);
        //     });

        urlReputationWidgets(hunt_detection_id)
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    let urlVirus = [];
                    let urlAv = [];
                    let urlTs = [];
                    hits?.map((item) => {
                        urlVirus.push({
                            name: item._source.url,
                            malicious: item._source.vt_malicious,
                            suspicious: item._source.vt_suspicious,
                            harmless: item._source.vt_harmless,
                        });
                        urlAv.push({ av_score: item._source.url_av_score });
                        urlTs.push({ threat_score: item._source.url_threat_score });
                    });
                    if (urlVirus[0]?.name) {
                        getTiWidgets(urlVirus[0]?.name).then(({ data: { hits: { hits } } }) => {
                            let TiUrl = []
                            hits?.map((item) => {
                                TiUrl.push({
                                    event_name: item?._source?.event_name,
                                    severity: item?._source?.severity,
                                    event_description: item?._source?.event_description
                                })
                            })
                            setTiUrlData(TiUrl)
                            setTiUrlLoader(false)
                        }).catch((err) => {
                            console.log("Error", err)
                            setTiUrlLoader(false)
                        })
                    }
                    setUrlVirusData(urlVirus);
                    setUrlAvData(urlAv);
                    setUrlTsData(urlTs);
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });


        //  getRecentAuthIn0365Clientip

        getRecentAuthInO365Clientip(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentAuthInO365ClientArr = [];
            data.map((item) => {
                recentAuthInO365ClientArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    UserType: item?._source?.UserType,
                    ClientIP: item?._source?.ClientIP,
                    request_type: item?._source?.request_type,
                    result_status: item?._source?.result_status,
                    source_country: item?._source?.source_country,
                    AccountDisplayName: item?._source?.AccountDisplayName,
                    device_os: item?._source?.device_os,
                    BrowserType: item?._source?.BrowserType,
                    compliant_and_managed: item?._source?.compliant_and_managed,
                    TrustType: item?._source?.TrustType,
                    UserAgent: item?._source?.UserAgent,
                    KeepMeSignedIn: item?._source?.KeepMeSignedIn,
                    UserAuthenticationMethod: item?._source?.UserAuthenticationMethod,
                });
            });
            setRecentAuthInO365ClientData(recentAuthInO365ClientArr);
            setRecentAuthInO365ClientLoader(false)

        }).catch((err) => {
            console.log("Error", err)
            setRecentAuthInO365ClientLoader(false)
        })

        //  getRecentAuthIn0365 User Id

        getRecentAuthInO365UserId(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentAuthInO365UserArr = [];
            data.map((item) => {
                recentAuthInO365UserArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    UserType: item?._source?.UserType,
                    ClientIP: item?._source?.ClientIP,
                    request_type: item?._source?.request_type,
                    result_status: item?._source?.result_status,
                    source_country: item?._source?.source_country,
                    AccountDisplayName: item?._source?.AccountDisplayName,
                    device_os: item?._source?.device_os,
                    BrowserType: item?._source?.BrowserType,
                    compliant_and_managed: item?._source?.compliant_and_managed,
                    TrustType: item?._source?.TrustType,
                    UserAgent: item?._source?.UserAgent,
                    KeepMeSignedIn: item?._source?.KeepMeSignedIn,
                    UserAuthenticationMethod: item?._source?.UserAuthenticationMethod,
                });
            });
            setRecentAuthInO365UserData(recentAuthInO365UserArr);
            setRecentAuthInO365UserLoader(false)

        }).catch((err) => {
            console.log("Error", err)
            setRecentAuthInO365UserLoader(false)
        })

        //  getRecentOperationSharepointUserId User Id

        getRecentOperationSharepointUserId(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationSharePointUserArr = [];
            data.map((item) => {
                recentOperationSharePointUserArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    ClientIP: item?._source?.ClientIP,
                    ClientAppName: item?._source?.ClientAppName,
                    ObjectId: item?._source?.ObjectId,
                    ItemType: item?._source?.ItemType,
                    SourceFileName: item?._source?.SourceFileName,
                    SourceFileExtension: item?._source?.SourceFileExtension,
                    SiteUrl: item?._source?.SiteUrl,
                    SourceRelativeUrl: item?._source?.SourceRelativeUrl,
                    AuthenticationType: item?._source?.AuthenticationType,
                    DeviceDisplayName: item?._source?.DeviceDisplayName,
                    IsManagedDevice: item?._source?.IsManagedDevice,
                    Platform: item?._source?.Platform,
                    UserAgent: item?._source?.UserAgent,
                    source_country: item?._source?.source_country,
                });
            });
            setRecentOperationSharePointUserData(recentOperationSharePointUserArr);
            setRecentOperationSharePointUserLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setRecentOperationSharePointUserLoader(false)
        })

        //  getRecentOperationSharepointUserId client ip

        getRecentOperationSharepointUserClientip(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationSharePointClientArr = [];
            data.map((item) => {
                recentOperationSharePointClientArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    ClientIP: item?._source?.ClientIP,
                    ClientAppName: item?._source?.ClientAppName,
                    ObjectId: item?._source?.ObjectId,
                    ItemType: item?._source?.ItemType,
                    SourceFileName: item?._source?.SourceFileName,
                    SourceFileExtension: item?._source?.SourceFileExtension,
                    SiteUrl: item?._source?.SiteUrl,
                    SourceRelativeUrl: item?._source?.SourceRelativeUrl,
                    AuthenticationType: item?._source?.AuthenticationType,
                    DeviceDisplayName: item?._source?.DeviceDisplayName,
                    IsManagedDevice: item?._source?.IsManagedDevice,
                    Platform: item?._source?.Platform,
                    UserAgent: item?._source?.UserAgent,
                    source_country: item?._source?.source_country,
                });
            });
            setRecentOperationSharePointClientData(recentOperationSharePointClientArr);
            setRecentOperationSharePointClientLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setRecentOperationSharePointClientLoader(false)
        })

        // getRecentOperationOnedrive UserId

        getRecentOperationOnedriveUserId(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationOnedriveUserArr = [];
            data.map((item) => {
                recentOperationOnedriveUserArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    ClientIP: item?._source?.ClientIP,
                    ClientAppName: item?._source?.ClientAppName,
                    ObjectId: item?._source?.ObjectId,
                    ItemType: item?._source?.ItemType,
                    SourceFileName: item?._source?.SourceFileName,
                    SourceFileExtension: item?._source?.SourceFileExtension,
                    SiteUrl: item?._source?.SiteUrl,
                    SourceRelativeUrl: item?._source?.SourceRelativeUrl,
                    AuthenticationType: item?._source?.AuthenticationType,
                    DeviceDisplayName: item?._source?.DeviceDisplayName,
                    IsManagedDevice: item?._source?.IsManagedDevice,
                    Platform: item?._source?.Platform,
                    UserAgent: item?._source?.UserAgent,
                    source_country: item?._source?.source_country,
                });
            });
            setRecentOperationOnedriveUserData(recentOperationOnedriveUserArr);
            setRecentOperationOnedriveUserLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setRecentOperationOnedriveUserLoader(false)
        })

        // getRecentOperationOnedrive ClientIp

        getRecentOperationOnedriveClientIp(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationOnedriveClientArr = [];
            data.map((item) => {
                recentOperationOnedriveClientArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    ClientIP: item?._source?.ClientIP,
                    ClientAppName: item?._source?.ClientAppName,
                    ObjectId: item?._source?.ObjectId,
                    ItemType: item?._source?.ItemType,
                    SourceFileName: item?._source?.SourceFileName,
                    SourceFileExtension: item?._source?.SourceFileExtension,
                    SiteUrl: item?._source?.SiteUrl,
                    SourceRelativeUrl: item?._source?.SourceRelativeUrl,
                    AuthenticationType: item?._source?.AuthenticationType,
                    DeviceDisplayName: item?._source?.DeviceDisplayName,
                    IsManagedDevice: item?._source?.IsManagedDevice,
                    Platform: item?._source?.Platform,
                    UserAgent: item?._source?.UserAgent,
                    source_country: item?._source?.source_country,
                });
            });
            setRecentOperationOnedriveClientData(recentOperationOnedriveClientArr);
            setRecentOperationOnedriveClientLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // getRecentOperationAzureAd UserId

        getRecentOperationAzureAdUserId(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationAzureAdUserArr = [];
            data.map((item) => {
                recentOperationAzureAdUserArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    extendedAuditEventCategory: item?._source?.extendedAuditEventCategory,
                    UserType: item?._source?.UserType,
                    result_status: item?._source?.result_status,
                    source_country: item?._source?.source_country,
                    additionalDetails: item?._source?.additionalDetails,
                });
            });
            setRecentOperationAzureAdUserData(recentOperationAzureAdUserArr);
            setRecentOperationAzureAdUserLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // getRecentOperationAzureAd ClientIp

        getRecentOperationAzureAdClientIp(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationAzureAdClientArr = [];
            data.map((item) => {
                recentOperationAzureAdClientArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    UserId: item?._source?.UserId,
                    extendedAuditEventCategory: item?._source?.extendedAuditEventCategory,
                    UserType: item?._source?.UserType,
                    result_status: item?._source?.result_status,
                    source_country: item?._source?.source_country,
                    additionalDetails: item?._source?.additionalDetails,
                });
            });
            setRecentOperationAzureAdClientData(recentOperationAzureAdClientArr);
            setRecentOperationAzureAdClientLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // getRecentOperationExchange UserId

        getRecentOperationExchangeUserId(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationExchangeUserArr = [];
            data.map((item) => {
                recentOperationExchangeUserArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    MailboxOwnerUPN: item?._source?.MailboxOwnerUPN,
                    Subject: item?._source?.Subject,
                    Attachments: item?._source?.Attachments,
                    ParentFolder: item?._source?.ParentFolder,
                    ClientIPAddress: item?._source?.ClientIPAddress,
                    ClientInfoString: item?._source?.ClientInfoString,
                    ExternalAccess: item?._source?.ExternalAccess,
                    LogonType: item?._source?.LogonType,
                });
            });
            setRecentOperationExchangeUserData(recentOperationExchangeUserArr);
            setRecentOperationExchangeUserLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        // getRecentOperationExchange ClientIp

        getRecentOperationExchangeClientIp(hunt_detection_id).then((res) => {
            let data = res?.data?.hits?.hits

            let recentOperationExchangeClientArr = [];
            data.map((item) => {
                recentOperationExchangeClientArr.push({
                    time: getTimeFromTimestamp(item._source.timestamp),
                    date: getDateFromTimestamp(item._source.timestamp),
                    Operation: item?._source?.Operation,
                    MailboxOwnerUPN: item?._source?.MailboxOwnerUPN,
                    Subject: item?._source?.Subject,
                    Attachments: item?._source?.Attachments,
                    ParentFolder: item?._source?.ParentFolder,
                    ClientIPAddress: item?._source?.ClientIPAddress,
                    ClientInfoString: item?._source?.ClientInfoString,
                    ExternalAccess: item?._source?.ExternalAccess,
                    LogonType: item?._source?.LogonType,
                });
            });
            setRecentOperationExchangeClientData(recentOperationExchangeClientArr);
            setRecentOperationExchangeClientLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

    }, [selectedDetectionDetails]);


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 18000);

        return () => { clearTimeout(timer); }// Clear the timer if the component unmounts
    }, []);

    // useEffect(() => {
    //     let data = { size: 10000, query: { match_all: {} } };
    //     fpostC(`${baseURL}/cybot_proxy/thp_observables*/_search`, data).then((res) => {
    //         console.log("GET CLOSURE IDD :", res)
    //     })
    // }, [])


    // case
    useEffect(() => {
        if (caseCreateSuccess === true) {
            setCaseShow(false)
            toastsSuccess("Case created successfully")

        }
        // setTimeout(() => {
        //   dispatch(resetReduxData())
        // }, 2000);


    }, [caseCreateSuccess])

    const handleOpenModal = (display, status) => {
        setShow(display);
        setDetectionStatusCheck(status)
    };

    // Add Case
    const handleCreate = () => {
        setCaseShow(true);
    };

    const [exceptionData, setExceptionData] = useState([])

    const handleOpenExceptionModal = (display, data) => {
        setExceptionShow(display)
        setExceptionData(data?._source)
    }

    function handleCloseModal() {
        setShow(false);
        setRespondShow(false)
        setExceptionShow(false)
    }


    const handleOpenRespondModal = (display) => {
        setRespondShow(display)
    }


    const handleInputChange = (value) => {
        // console.log("e.target.value", e.target.value)
        setDetectionClosed({
            comment: value,
        })
        // setDetectionOpen(e.target.value)
        setDetectionOpen({
            comment: value
        })
    }

    const handleClose = async (e) => {
        e.preventDefault();


        if (detectionStatusCheck === 'closed') {
            try {
                const data = { "doc": { "comment": detectionClosed?.comment, "closing_tag": closureCategory, "external_investigation": externalInv, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString, "detection_status": "closed" } }

                const response = await fpostC(`${baseURL}/cybot_proxy/${selectedDetectionDetails?._source?.tenendID ? selectedDetectionDetails?._source?.tenendID : selectedDetectionDetails?._index}/_update/${selectedDetectionDetails?._id}`, data);
                setDetectionClosed('')
                setShow(false)
                setCloseAlertPopup(true)
                setCloseAlertMessage('Investigation Closed')
                setClosedCheck(!closedCheck)
            } catch (err) {
                console.log("Error", err)
            }
        } else {
            // setClosureCategory([])
            try {
                const data = { "doc": { "comment": detectionOpen?.comment, "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }

                const response = await fpostC(`${baseURL}/cybot_proxy/${selectedDetectionDetails?._source?.tenendID ? selectedDetectionDetails?._source?.tenendID : selectedDetectionDetails?._index}/_update/${selectedDetectionDetails?._id}`, data);
                setDetectionClosed('')
                setShow(false)
                setCloseAlertPopup(true)
                setCloseAlertMessage('Investigation Opened')
                setClosedCheck(!closedCheck)
            } catch (err) {
                console.log("Error", err)
            }
        }

    }

    const createBodyData = (body) => {
        var z = body;
        inputValues.forEach(item => {
            // console.log('item.key = ' + item.key + ' | ' + 'item.value', '"' + item.value + '"')
            // z = x.replace(item.key.toString(), '"' + item.value.toString() + '"')
            z = z.replace(item.key, '"' + item.value + '"')
        })

        return z
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        getRespondList().then(({ data: { hits: { hits } } }) => {
            hits?.map(async (item, index) => {
                // let token = localStorage.getItem("auth")
                // let headers = JSON.parse(item?._source?.header)
                // headers["Authorization"] = `Bearer ${token}`
                if (selectedApi.label === item?._source?.api_name) {

                    // console.log("inputValues item item item : ", item?._source?.method)
                    if (item?._source?.method === 'post') {
                        await fpostC(`${item?._source?.url}`, createBodyData(item?._source?.body)).then((res) => {
                            setAddRespondSuccessAlertPopup(true)
                            setRespondShow(false)

                        }).catch((err) => {
                            console.log("Error", err)
                            setAddRespondFailedAlertPopup(true)
                            // setRespondShow(false)
                        })
                    } else {
                        await fget(`${item?._source?.url}`, createBodyData(item?._source?.body)).then((res) => {
                            setAddRespondSuccessAlertPopup(true)
                            setRespondShow(false)

                        }).catch((err) => {
                            console.log("Error", err)
                            setAddRespondFailedAlertPopup(true)
                            // setRespondShow(false)
                        })
                    }
                }
            })

        }).catch((err) => {
            console.log("Error", err)
        })

    }


    // exception submit fn

    const handleExceptionInputChange = (e) => {
        const { name, value } = e.target;
        setExceptionFormData({
            ...exceptionFormData,
            [name]: value
        })
    }

    const handleonDropDownChange = (selectedVal) => {
        setExceptionFormData({ ...exceptionFormData, playbook_id: selectedVal.label })
    }

    const handleOnRespondChange = (selectedVal) => {
        setSelectedApi(selectedVal)
        let paramCount = respondDataList.filter((item) => item.name === selectedVal.label);
        setRespondParamsCount(paramCount[0]?.count)
        // getRespondList().then(({ data: { hits: { hits } } }) => {
        //     let paramList = ''
        //     hits?.map((item) => {
        //         if (selectedVal.label === item?._source?.api_name) {
        //             paramList = item?._source?.count
        //         }
        //     })
        // })

    }


    const handleToChangeParams = (selectedVal) => {
        console.log("Selected VAll :", selectedVal)
    }

    const handleDropItem = () => {
        var dropItems = [];

        for (const item of hashVirusTotalData) {
            if (item.hasOwnProperty("name")) {
                dropItems.push({ label: item.name, value: item.name });
            }
        }

        for (const item of ipVirusData) {
            if (item.hasOwnProperty("name")) {
                dropItems.push({ label: item.name, value: item.name });
            }
        }

        for (const item of urlVirusData) {
            if (item.hasOwnProperty("name")) {
                dropItems.push({ label: item.name, value: item.name });
            }
        }

        return dropItems;
    };

    // add params ( Respond )
    const handleChangeParams = (selectedOption, i) => {
        // console.log("i ==", selectedOption)
        const updatedValues = [...inputValues];
        let index = updatedValues.findIndex(x => x.key === ("#Param" + i))
        if (index >= 0) {
            updatedValues[index].value = selectedOption.value
        }
        else {
            updatedValues.push({ key: "#Param" + i, value: selectedOption?.value })
        }
        // const updatedValues = [...inputValues];
        // updatedValues[i] = e.target.value;
        setInputValues(updatedValues);


    }

    // console.log(investigationConclusions?.["Alert Name"])
    // console.log(investigationConclusions?.["Alert Description"])
    const renderInputFields = () => {

        var dropItems = handleDropItem()

        const inputs = [];
        for (let i = 0; i < respondParamsCount; i++) {
            inputs.push(
                <div>
                    <label>{"#Param" + (i + 1)}</label>
                    {/* <input
                        className="form-control"
                        style={{ padding: "0.4rem", marginBottom: "10px" }}
                        key={i}
                        placeholder="Add Params"
                        onChange={(e) => handleChangeParams(e, i + 1)}
                        required
                    /> */}
                    <Select
                        className="w-100"
                        options={dropItems}
                        // onChange={handleToChangeParams}
                        onChange={(e) => handleChangeParams(e, i + 1)}
                        required
                    />

                </div>
            );
        }
        return inputs;
    };

    const handleExceptionSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedFormValues = {
                ...exceptionFormData
            }
            const data = { "playbook_name": exceptionFormData.playbook_name, "comment": exceptionFormData.comment, "playbook_id": selectedDetectionDetails?._source?.playbook_id, "user_name": selectedDetectionDetails?._source?.user_name, "host_name": selectedDetectionDetails?._source?.host_name, "source_ip": exceptionFormData.source_ip, "process_name": selectedDetectionDetails?._source?.process_name, "process_executable": exceptionFormData.process_executable, "process_hash": exceptionFormData.process_hash, "parent_process_name": exceptionFormData.parent_process_name, "parent_process_executable": exceptionFormData.parent_process_executable, "url": exceptionFormData.url, "dest_ip": exceptionFormData.dest_ip, "tenant_id": selectedDetectionDetails?._index, "created_by": localStorage.getItem("loggedUser"), "created_at": new Date().toISOString() }
            const response = await fpostC(`${baseURL}/cybot_proxy/thp_exceptions_${tenandId}/_doc`, data,);
            setExceptionFormData("")
            setExceptionShow(false)
            setAlertPopup(true)
        } catch (err) {
            console.log("Error", err)
        }

    }

    function renderExceptionModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={handleExceptionSubmit}>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Process Executable</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter process executable" name="process_executable" value={exceptionData?.observables?.process_exec} onChange={handleExceptionInputChange} />
                            </div>
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">User name</label>
                                <input type="text" className="form-control" id="hostname" placeholder="Enter user name" name="user_name" value={exceptionData?.observables?.user_name} onChange={handleExceptionInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Process Hash</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter Process Hash" name="process_hash" value={exceptionData?.observables?.process_hash} onChange={handleExceptionInputChange} />
                            </div>
                            {/* <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Playbook Id</label>
                                <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="playbook_id" value={exceptionFormData.playbook_id} onChange={handleExceptionInputChange} />
                            </div> */}
                            <div className="col-6 p-1">
                                <label className="control-label">Rulebook</label>
                                {/* <Select
                                    className="w-100"
                                    options={playBookList}
                                    onChange={handleonDropDownChange}
                                    defaultValue={state?.sourceList?.playbook_id}
                                /> */}
                                <input type="text" className="form-control" id="username" placeholder="Enter Process Hash" name="process_hash" value={selectedDetectionDetails?._source?.playbook_id} onChange={handleExceptionInputChange} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Parent Process Name</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter Process Name" name="parent_process_name" value={exceptionData?.observables?.process_parent_name} onChange={handleExceptionInputChange} />
                            </div>
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Host Name</label>
                                <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="host_name" value={exceptionData?.observables?.host_name} onChange={handleExceptionInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Parent Process Executable</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter Parent Process Executable" name="parent_process_executable" value={exceptionData?.observables?.process_parent_exec} onChange={handleExceptionInputChange} />
                            </div>
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Source IP</label>
                                <input type="text" className="form-control" id="hostname" placeholder="Enter Source IP" name="source_ip" value={exceptionData?.observables?.source_ip} onChange={handleExceptionInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">URL</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter URL" name="url" value={exceptionData?.observables?.url} onChange={handleExceptionInputChange} />
                            </div>
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Process Name</label>
                                <input type="text" className="form-control" id="hostname" placeholder="Enter Process Name" name="process_name" value={exceptionData?.observables?.process_name} onChange={handleExceptionInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-6 col-xs-12 p-1">
                                <label className="control-label">Destination IP</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter Destination IP" name="dest_ip" value={exceptionData?.observables?.dest_ip} onChange={handleExceptionInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group d-flex">
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label">Comments</label>
                                <textarea className="form-control" id="comment" rows="3" name="comment" value={exceptionFormData.comment} onChange={handleExceptionInputChange} required ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
                        <div className="form-group d-flex justify-content-end">
                            {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
                            <Button className="btn-primary" type="submit">
                                Create
                            </Button>
                        </div>
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Create</button>
              <button type="button" className="btn btn-secondary">Close</button> */}
                </form>
            </div>
        )
    }

    const checkitemscontains = (list) => {
        return investigationDescription?.modules?.filter(obj => list.some(obj1 => obj.hasOwnProperty(obj1) && obj[obj1] === 'true')).length > 0 ? true : false
    }
    const user_host_items = [
        "recent_file_creations",
        "recent_file_deletions",
        "uncommon_processes_VT",
        "other_hosts_logged_into_by_user",
        "auth_info_on_host",
        "other_hosts_visited_ip",
        "user_run_processes",
        "host_run_processes",
    ]

    const waf_items = [
        "traffic_from_ip_on_waf",
        "waf_threat_detection_on_ip",
        "waf_user_attempt_by_ip",
    ]

    const ad_items = [
        "ad_recent_authentication_by_user",
        "ad_user_management_activity",
        "ad_recent_authentication_in_host",
    ]

    const o365_items = [
        "auth_in_o365_userid",
        "auth_in_o365_clientip",

        "sharepoint_in_o365_userid",
        "sharepoint_in_o365_clientip",

        "onedrive_in_o365_userid",
        "onedrive_in_o365_clientip",

        "azure_ad_in_o365_userid",
        "azure_ad_in_o365_clientip",

        "exchange_in_o365_userid",
        "exchange_in_o365_clientip",
    ]

    const firewall_items = [
        "traffic_to_ip_on_firewall",
        "traffic_from_ip_on_firewall",
    ]
    const email_items = [
        "mail_send_to",
        "mail_send_from",
    ]
    // detail modal
    const handleModalRule = (item) => {
        setInvId(item);
        console.log("Item", invId);

        setDetailShow(true);
    };

    function renderPermision() {
        return (
            <div className="col-12 p-3 h-100">
                <h1>Test Data Modal</h1>
                <button>yes</button>
                <button>no</button>
            </div>
        )
    }
    // exception modal 
    function renderModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={handleClose}>
                    <div className="row">
                        {/* <div>
                            <h6>Technique Name</h6>
                            <span>{state?.sourceList.technique_name}</span>
                        </div> */}
                        <div>
                            <h6>Detection Name</h6>
                            <span>{selectedDetectionDetails?._source?.detection_name}</span>
                        </div>
                        {detectionStatusCheck === 'closed' &&
                            <>

                                <div className="form-group mt-2">
                                    <label className="control-label">Closure code</label>
                                    <select
                                        className="form-select input-lg"
                                        name="closing_tag"
                                        onChange={(e) => setClosureCategory(e.target.value)}
                                        // onChange={(e) => changeSeverity(e, defSev)}
                                        // value={severity}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        <option value="True Positive - Confirmed Suspicious Activity">True Positive - Confirmed Suspicious Activity</option>
                                        <option value="Benign Positive - Validated">Benign Positive - Validated</option>
                                        <option value="Benign Positive - Expected">Benign Positive - Expected</option>
                                        <option value="False Positive - Data Error">False Positive - Data Error</option>
                                        <option value="False Positive - Incorrect Alert Logic">False Positive - Incorrect Alert Logic</option>

                                    </select>

                                </div>


                                <div className="form-group mt-2">
                                    <label className="control-label">External Investigation</label>
                                    <select
                                        className="form-select input-lg"
                                        name="closing_tag"
                                        onChange={(e) => setExternalInv(e.target.value)}
                                        // onChange={(e) => changeSeverity(e, defSev)}
                                        // value={severity}
                                        required
                                    >
                                        <option value="">Select</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>

                                    </select>

                                </div>
                            </>
                        }

                        <div className="form-group d-flex">
                            <div className="col-md-12 col-xs-12 p-1" style={{ marginBottom: '50px' }}>
                                <label className="control-label">Comments</label>
                                {detectionStatusCheck === 'closed' ?
                                    <ReactQuill
                                        value={detectionClosed.comment}
                                        onChange={(value) => handleInputChange(value)}
                                    />
                                    // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed.comment} onChange={handleInputChange} required ></textarea>
                                    :
                                    <ReactQuill
                                        value={detectionOpen.comment}
                                        onChange={(value) => handleInputChange(value)}
                                    />
                                    // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen.comment} onChange={handleInputChange} required ></textarea>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        <div className="form-group d-flex justify-content-end">
                            {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
                            <Button className="btn-primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Create</button>
              <button type="button" className="btn btn-secondary">Close</button> */}
                </form>
            </div>
        )
    }


    // Respond modal 
    function renderRespondModal() {
        return (
            <div className="col-12 p-3 h-100">
                <form className="h-100" onSubmit={(e) => handleSubmit(e, respondList)}>
                    <div className="row">
                        <Select
                            className="w-100"
                            options={respondList}
                            onChange={handleOnRespondChange}
                        />
                    </div>
                    <div className="row mt-2">
                        {renderInputFields()}
                    </div>
                    {/* <div className="row">
                        <Select
                            className="w-100"
                            options={respondParamsCount}
                            onChange={handleOnRespondChange}
                        />
                    </div> */}
                    <div className="row mt-3 py-3" style={styles.footerWrap}>
                        {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
                        <div className="form-group d-flex justify-content-end">
                            {/* <Button className="btn-secondary">Close</Button> &nbsp; */}
                            <Button className="btn-primary" type="submit">
                                Respond
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }


    const [redirectItem, setRedirectItem] = useState('');
    const [redirectFieldName, setRedirectFieldName] = useState('');

    const handleCykitInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleCheckPermision = (item, fieldName) => {
        setCheckPermisionModalVisibility(true)
        const search = item?._source?.observables[fieldName];
        setInputValue(search)
        const dateFilter = detectionObservable[0]._source.detection_timestamp;

        setRedirectItem(inputValue)
        setRedirectFieldName(dateFilter)

    };

    const redirectToCykit = () => {
        let start = moment(redirectFieldName).subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
        let end = moment(redirectFieldName).add(20, 'minutes').format("yyyy-MM-DDTHH:mm");
        const url = `/search/app/data-explorer/discover/#?_q=(filters:!(),query:(language:kuery,query:'${inputValue}'))&_a=(discover:(columns:!(log_source,action,sipv4,dipv4,user,targetuser),isDirty:!t,sort:!()),metadata:(indexPattern:'${indexId}',view:discover))&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${start}',to:'${end}'))`
        window.open(url, '_blank');
        // navigate("/hunter/cykit", { state: { search: inputValue, dateFilter: redirectFieldName } });
    }

    return (
        <div className="col-12 gap-3 d-flex flex-column bg-grey">

            <div className="col-12 gap-3 d-flex flex-row bg-grey">
                <div
                    className="col-3 d-flex flex-column observable-datas gap-3 rounded-3"
                    style={styles.section1}
                >

                    <div className="col-12 rounded-3 p-4 bg-white">
                        <h5 className="px-2">Detection Observables</h5>
                        {/* {console.log("detectionObservable", detectionObservable)} */}
                        {sideBarLoader ? (
                            <SingleTileLoader width="100%" count="13" />
                        ) : (
                            <>
                                {/* <div className="col-12 text-break">
                                                        <div className="modal-row-item flex-column">
                                                            <span className="modal-label">Detection Name</span>

                                                                <span className="modal-text col-12">
                                                                    {detectionObservable?.[0]?._source?.detection_name}
                                                                </span>
                                                        </div>
                                </div> */}
                                {detectionObservable.length === 0 && detectionObservableDynamic.length === 0 ? (
                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                        <NoDataFound errorText={"No data found"} />
                                    </div>
                                ) : (
                                    <>
                                        {/* {console.log("detectionObservableDynamic", detectionObservable)} */}
                                        {/* {investigationConclusions && (
                                            <>
                                                {investigationConclusions["Alert Name"] &&
                                                    <div className="modal-row-item flex-column">
                                                        <span className="modal-label">Alert Name</span>
                                                        <span className="modal-text col-12">{investigationConclusions["Alert Name"]}</span>
                                                    </div>
                                                }
                                            </>
                                        )} */}

                                        {detectionObservable[0]._source.detection_timestamp &&
                                            <div className="modal-row-item flex-column">
                                                <span className="modal-label">Date</span>
                                                <span className="alert alert-warning px-2 py-0 mb-0  break-word">{getDateFromTimestamp(detectionObservable[0]._source.detection_timestamp)}</span>

                                            </div>
                                        }
                                        {detectionObservable[0]._source.detection_timestamp &&
                                            <div className="modal-row-item flex-column">
                                                <span className="modal-label">Time</span>
                                                <span className="alert alert-warning px-2 py-0 mb-0  break-word">{getTimeFromTimestamp(detectionObservable[0]._source.detection_timestamp)}</span>

                                            </div>
                                        }

                                        {
                                            detectionObservableDynamic.length !== 0 ? (
                                                <>
                                                    {/* {investigationConclusions && (
                                                        <>
                                                            {investigationConclusions["Alert Name"] &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Alert Name</span>
                                                                    <span className="modal-text col-12">{investigationConclusions["Alert Name"]}</span>
                                                                </div>
                                                            }
                                                        </>
                                                    )} */}
                                                    {detectionObservableDynamic?.map((fieldName, index) => (
                                                        <div className="col-12 text-break">
                                                            {detectionObservable?.map((item, index) => (
                                                                <>
                                                                    {item?._source?.observables[fieldName] != "No URL" &&
                                                                        <div className="modal-row-item flex-column">
                                                                            <span className="modal-label">{replaceWithSpace(fieldName)}</span>
                                                                            <div className="d-flex gap-2">
                                                                                <span className="alert alert-warning px-2 py-0 mb-0  break-word">{item?._source?.observables[fieldName]}</span>
                                                                                {isOnPrem&&isInList(fieldName) && <MagnifyingGlass size={16}
                                                                                    // onClick={() => { navigate("/hunter/cykit", { state: { search: item?._source?.observables[fieldName], dateFilter: detectionObservable[0]._source.detection_timestamp } }) }}
                                                                                    onClick={() => handleCheckPermision(item, fieldName)}
                                                                                />}

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                detectionObservable?.map(({ _source }) => (
                                                    <>
                                                        <div className="col-12 text-break">
                                                            {_source?.user_name &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">User name</span>
                                                                    <span className="modal-text col-12">{_source?.user_name}</span>
                                                                </div>
                                                            }
                                                            {_source?.host_name &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Host name</span>
                                                                    <span className="modal-text col-12">{_source?.host_name}</span>
                                                                </div>
                                                            }
                                                            {_source?.detection_timestamp &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Detection timestamp</span>
                                                                    <span className="modal-text col-12">
                                                                        {_source?.detection_timestamp}
                                                                    </span>
                                                                </div>
                                                            }
                                                            {_source?.source_ip &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Source IP</span>
                                                                    <span className="modal-text col-12">{_source?.source_ip}</span>
                                                                </div>
                                                            }
                                                            {_source?.dest_ip &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Destination IP</span>
                                                                    <span className="modal-text col-12">{_source?.dest_ip}</span>
                                                                </div>
                                                            }
                                                            {_source?.process_name &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process name</span>
                                                                    <span className="modal-text col-12">{_source?.process_name}</span>
                                                                </div>
                                                            }
                                                            {_source?.process_pid &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process PID</span>
                                                                    <span className="modal-text col-12">{_source?.process_pid}</span>
                                                                </div>
                                                            }
                                                            {_source?.process_commandline &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process commandline</span>
                                                                    <span className="modal-text col-12">
                                                                        {_source?.process_commandline}
                                                                    </span>
                                                                </div>
                                                            }
                                                            {_source?.process_parent_exec &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process Parent Exec</span>
                                                                    <span className="modal-text col-12 col-12">
                                                                        {_source?.process_parent_exec}
                                                                    </span>
                                                                </div>
                                                            }
                                                            {_source?.process_parent_commandline &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process parent Commandline</span>
                                                                    <span className="modal-text col-12 col-12">
                                                                        {_source?.process_parent_commandline}
                                                                    </span>
                                                                </div>
                                                            }
                                                            {_source?.process_parent_name &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process parent Name</span>
                                                                    <span className="modal-text col-12 col-12">
                                                                        {_source?.process_parent_name}
                                                                    </span>
                                                                </div>
                                                            }

                                                            {_source?.process_signer &&
                                                                <div className="modal-row-item flex-column">
                                                                    <span className="modal-label">Process parent Signer</span>
                                                                    <span className="modal-text col-12 col-12">
                                                                        {_source?.process_signer}
                                                                    </span>
                                                                </div>
                                                            }

                                                            <div className="modal-row-item flex-column">
                                                                <span className="modal-label">Process hash</span>
                                                                <span className="modal-text col-12 col-12">{_source?.process_hash}</span>
                                                            </div>
                                                        </div>

                                                    </>
                                                ))
                                            )
                                        }

                                        {closureCategory?.length > 0 && (

                                            <div className="modal-row-item flex-column">
                                                <span className="modal-label">Closure code</span>
                                                <span className="alert alert-warning px-2 py-0 mb-0  break-word">{closureCategory}</span>
                                            </div>
                                        )}

                                        {closedComment?.length > 0 && (

                                            <div className="modal-row-item flex-column">
                                                <span className="modal-label">Comment</span>
                                                {/* <span className="alert alert-warning px-2 py-0 mb-0  break-word">{closedComment}</span> */}
                                                <span className="alert alert-warning px-2 py-0 mb-0  break-word" dangerouslySetInnerHTML={{ __html: closedComment }} ></span>
                                            </div>
                                        )}
                                        {externalInv.length > 0 &&
                                            <div className="modal-row-item flex-column">
                                                <span className="modal-label">External Investigation</span>
                                                {/* <span className="alert alert-warning px-2 py-0 mb-0  break-word">{closedComment}</span> */}
                                                <span className="alert alert-warning px-2 py-0 mb-0 break-word">{externalInv}</span>
                                            </div>
                                        }
                                    </>
                                )}

                            </>

                        )}
                    </div>

                    {/* <div className="col-12 rounded-3 p-4 bg-white">
                        {conclutionLoader ? (<SingleTileLoader width="100%" count="1" />) : (
                            <>
                                <h5>Conclusion</h5>
                                {detectionObservable[0]?._source.hasOwnProperty("conclusions") ? (
                                    detectionObservable.length > 0 ? (
                                        detectionObservable?.map((item) => (
                                            item?._source?.conclusions && Object.entries(item?._source?.conclusions).map(([key, value]) => (
                                                <div className="modal-row-item">
                                                    <span className="modal-label col-3">{key}</span>
                                                    <span className="modal-text" dangerouslySetInnerHTML={{ __html: value }} />
                                                </div>
                                            ))
                                        ))
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                ) : (
                                    <>
                                        {conclutionData ? (
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: conclutionData }} />
                                            </div>
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div> */}

                    <div className="col-12 rounded-3 p-4 bg-white">
                        {!investigationConclusions && (
                            <>
                                {/* {investigationDescription?.detaild_description && <p className="col-12 text-break">{rawHTML(investigationDescription?.detaild_description)}</p>} */}
                                {investigationDescription?.detailed_description && <p className="col-12 text-break">{rawHTML(investigationDescription?.detailed_description)}</p>}
                                <p className="col-12 text-break">{rawHTML(investigationDescription?.detaild_description)}</p>
                            </>
                        )}
                    </div>
                </div>

                {loader ? (
                    <div className="d-flex flex-fill">
                        <SingleLoader />
                    </div>
                ) : (
                    <div
                        className="d-flex gap-3 flex-column flex-fill rounded-3"
                        style={styles.section2}
                    >
                        <div className="col-12 rounded-3 p-3 bg-white sticky-header" style={{ zIndex: 10 }}>
                            <div className="d-flex">
                                <div className="d-flex ml-auto gap-2">
                                    {/* <Button
                                        variant="light"
                                        onClick={() => { setExpClickedNodeLog(rawLogId); setExpCloseLog(false) }}
                                    >
                                        View Raw Log
                                    </Button> */}
                                    {showButton === true &&
                                        <PDFDownloadLink document={<InvestigationSummaryExport detectionObservableDynamic={detectionObservableDynamic} detectionObservable={detectionObservable} investigationConclusions={investigationConclusions} ClosedComment={closedComment} imageBlob={imageBlob} investigationDescription={graphData} />} fileName="inv-detections-summary">
                                            {({ blob, url, loading, error }) =>
                                                <Button className='btn btn-light'>Download PDF</Button>
                                            }
                                        </PDFDownloadLink>}

                                    {/* linked incidents */}
                                    {selectedDetectionDetails?._source?.grp_id !== undefined && (
                                        <Button
                                            variant="light"
                                        >
                                            <CustomToolTip content={
                                                <Link
                                                    to={`/investigate/grouped_investigation/${selectedDetectionDetails?._source?.grp_id}`}
                                                    style={{ color: "black", textDecoration: "none" }}
                                                >
                                                    Linked Incidents
                                                </Link>
                                            } dataPlacement={"bottom"}
                                                dataToggle={
                                                    <div style={styles.tooltipWrap}>
                                                        Click here to see the linked incidents
                                                    </div>
                                                }>

                                            </CustomToolTip>

                                        </Button>
                                    )}

                                    {rawLogId ?
                                        <Button
                                            variant="light"
                                            onClick={() => { setExpClickedNodeLog(rawLogId); setExpCloseLog(false) }}
                                        >
                                            Raw Log
                                        </Button>

                                        :

                                        <CustomToolTip content={
                                            <Button
                                                variant="light"
                                                style={{ opacity: 0.5, cursor: "default" }}
                                            >
                                                Raw Log
                                            </Button>
                                        } dataPlacement={"bottom"}
                                            dataToggle={
                                                <div className="d-flex flex-column text-left">
                                                    <div className='d-flex flex-row gap-2 tooltip-row'>
                                                        <span><strong>Count-based detections are derived from the analysis of multiple logs or documents. We would recommend you to check the raw logs in Cykit by searching for detection observables</strong></span>
                                                    </div>

                                                </div>
                                            } />

                                    }


                                    {detectionStatus === "closed" ?
                                        <Button
                                            variant="light"
                                            onClick={() => handleOpenModal(true, 'open')}
                                        // disabled={detectionStatus === "closed"}
                                        >
                                            Open Detection
                                        </Button> : detectionStatus === "open" ?
                                            <Button
                                                variant="light"
                                                onClick={() => handleOpenModal(true, 'closed')}
                                            // disabled={detectionStatus === "closed"}
                                            >
                                                Close Detection
                                            </Button> : null
                                    }
                                    {/* <Button
                                        variant="primary"
                                        onClick={(e) => handleCreate()}
                                    >
                                        Add Case
                                    </Button> */}


                                    {/* <Button
                                        variant="light"
                                        onClick={(e) => handleCreate()}
                                    >
                                        Create Case
                                    </Button> */}
                                    {/* 
                                 {wafFromIpLoad && 
                                    wafThreatIpLoad && 
                                    wafUserAttemptedIpLoad && 
                                    userManagementActivityByUserAdLoad &&
                                    recentAuthByHostAdLoad &&
                                    recentAuthByUserAdLoad &&
                                    recentAuthLoad &&
                                    recentCreationLoad &&
                                    recentDeletionLoad &&
                                    proDetectUserLoad &&
                                    proDetectHostLoad &&
                                    unProDetectUserLoad &&
                                    hostLoggedUserLoad &&
                                    trafficFromHostLoad && firewallFromIpLoad &&
                                    firewallToIpLoad && bytesTrasferredBetweenIpLoad &&
                                    threatDetectionIpInboundLoad && threatDetectionIpOutboundLoad &&
                                    getMailsSendToEmailIdLoad && getMailsSendFromEmailIdLoad ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Please Wait until Loading..</Tooltip>}
                                        >
                                            <span>
                                                <Button variant="light" disabled>
                                                    Create Case
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                    ) :  */}
                                    {showButton === true && (
                                        <Button
                                            variant="light"
                                            onClick={(e) => handleCreate()}
                                        >
                                            Create Case
                                        </Button>
                                    )}


                                    <Button
                                        variant="light"
                                        onClick={() => handleOpenExceptionModal(true, detectionObservable[0])}
                                    >
                                        Add Exceptions
                                    </Button>

                                    <Button
                                        variant="primary"
                                        onClick={() => handleOpenRespondModal(true)}
                                    >
                                        Respond
                                    </Button>



                                </div>
                            </div>
                        </div>

                        {investigationConclusionLoader ? (
                            <div style={{ height: "200px" }}>
                                <SmallTileLoader />
                            </div>
                        ) : (
                            <div className="d-flex gap-3 flex-row col-12">
                                {/* <div style={{height:"200px", overflow:"scroll"}}>
                            {investigationDescription?.Description &&
                                <div className="col-12 rounded-3 p-4 bg-white">
                                    <div className="col-12 text-break">
                                    </div>
                                    <h5>About the Investigation</h5> */}
                                {/* {investigationDescription?.detaild_description && <p className="col-12 text-break">{rawHTML(investigationDescription?.detaild_description)}</p>}
                            {investigationDescription?.detailed_description && <p className="col-12 text-break">{rawHTML(investigationDescription?.detailed_description)}</p>} */}

                                {/* <p className="col-12 text-break">{rawHTML(investigationDescription?.detaild_description)}</p> */}

                                {/* {!investigationConclusions ? (
                                        <>
                                            <div className="modal-row-item flex-column px-0 py-3">
                                                <span className="modal-label">Detection Name</span>
                                                <span className="modal-text col-12">
                                                    {detectionObservable?.[0]?._source?.detection_name}
                                                </span>
                                            </div>
                                            <p className="col-12 text-break">{removeHtmlChars(investigationDescription?.Description)}</p>
                                        </>
                                    ) : (
                                        <>
                                            {Object.entries(investigationConclusions).map(([key, value]) => (
                                                <>
                                                    <div className="modal-row-item flex-column">
                                                        <span className="modal-label">{key}</span>
                                                        <span className="modal-text col-12">{rawHTML(value)}</span>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}

                                </div>
                            }
                            </div> */}
                                <div className="flex-fill rounded-3 p-4 recent-file-container bg-white">
                                    <div className="d-flex flex-column">
                                        <h5>Detection Details</h5>

                                        {/* {console.log("investigationConclusions", investigationConclusions)} */}

                                        {investigationConclusions && (
                                            <div className="col-12 mb-3">
                                                <div className="d-flex col-12 gap-3">
                                                    {investigationConclusions["Alert Name"] && (
                                                        <div className="flex-fill alert alert-info mb-0 flex-column text-center">
                                                            <span>Detection Name</span>
                                                            <h5>
                                                                {investigationConclusions["Alert Name"]}
                                                            </h5>
                                                        </div>
                                                    )}
                                                    {detectionObservable[0]?._source?.hunt_detection_id && (
                                                        <div className="flex-fill alert alert-secondary mb-0 flex-column text-center">
                                                            <span>Detection ID</span>
                                                            <h5>
                                                                {detectionObservable[0]?._source?.hunt_detection_id}
                                                            </h5>
                                                        </div>
                                                    )}
                                                    {detectionObservable[0]?._source?.playbook_id && (
                                                        <div className="flex-fill alert alert-secondary mb-0 flex-column text-center">
                                                            <span>Rulebook</span>
                                                            <h5>
                                                                {/* Wrap the rulebook ID in a Link component */}
                                                                <Link
                                                                    // to="/rs/investigation"
                                                                    onClick={() => handleModalRule(detectionObservable[0]?._source?.playbook_id)}>
                                                                    {detectionObservable[0]?._source?.playbook_id}
                                                                </Link>
                                                            </h5>
                                                        </div>)}
                                                </div>
                                            </div>
                                        )}


                                    </div >
                                    <div className="d-flex flex-column gap-3 flex-fill">
                                        {/* <h6 className="mb-0">Virus Total</h6> */}
                                        {investigationConclusions ? (
                                            <>
                                                <div className="d-flex flex-row gap-3 mb-0">
                                                    {/* <div className="flex-fill alert alert-info mb-0 text-center">
                                                    <span>Alert Name</span>
                                                    <h5>{investigationConclusions["Alert Name"]}</h5>
                                                </div> */}
                                                    <div className="flex-fill alert alert-secondary mb-0 text-center">
                                                        <span>Detection Source</span>
                                                        <h5>{investigationConclusions["Alert Source"]}</h5>
                                                    </div>


                                                    <div className="flex-fill alert alert-secondary mb-0 text-center">
                                                        <span>Mitre Attack Tactics</span>
                                                        <h5>{investigationConclusions["MITRE ATTACK TACTICS"]}</h5>
                                                    </div>
                                                    <div className="flex-fill alert alert-secondary mb-0 text-center">
                                                        <span>Mitre Attack Techniques</span>
                                                        <h5>{investigationConclusions["MITRE ATTACK TECHNIQUES"]}</h5>
                                                    </div>
                                                    <div className="flex-fill alert alert-secondary mb-0 text-center">
                                                        <span>Threat Score</span>
                                                        <h5>{rawHTML(parseFloat(investigationConclusions["Threat_Score"]).toFixed(2))}</h5>
                                                    </div>


                                                    {investigationConclusions["Severity level"] === "Low" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-success">
                                                            <span>Severity</span>
                                                            <h5>{investigationConclusions["Severity level"]}</h5>
                                                        </div>
                                                    )}

                                                    {investigationConclusions["Severity level"] === "Medium" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-info">
                                                            <span>Severity</span>
                                                            <h5>{investigationConclusions["Severity level"]}</h5>
                                                        </div>
                                                    )}

                                                    {investigationConclusions["Severity level"] === "High" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-warning">
                                                            <span>Severity</span>
                                                            <h5>{investigationConclusions["Severity level"]}</h5>
                                                        </div>
                                                    )}

                                                    {investigationConclusions["Severity level"] === "Critical" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-danger">

                                                            <span>Severity</span>
                                                            <h5>{investigationConclusions["Severity level"]}</h5>
                                                        </div>
                                                    )}


                                                    {investigationConclusions["threat_classification"] === "Improbable" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-success">
                                                            <span>Threat Classification</span>
                                                            <h5>{investigationConclusions["threat_classification"]}</h5>
                                                        </div>
                                                    )}

                                                    {investigationConclusions["threat_classification"] === "Unlikely" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-info">
                                                            <span>Threat Classification</span>
                                                            <h5>{investigationConclusions["threat_classification"]}</h5>
                                                        </div>
                                                    )}

                                                    {investigationConclusions["threat_classification"] === "Benign True Positive" && (
                                                        <div className="flex-fill alert mb-0 text-center alert-warning">
                                                            <span>Threat Classification</span>
                                                            <h5>{investigationConclusions["threat_classification"]}</h5>
                                                        </div>
                                                    )}

                                                    {/* {investigationConclusions["threat_classification"] === "Improbable" && (
                                                    <div className="flex-fill alert mb-0 text-center bg-danger text-white">
                                                        <span>Threat Classification</span>
                                                        <h5>{investigationConclusions["threat_classification"]}</h5>
                                                    </div>
                                                )} */}

                                                    {/* <div className="flex-fill alert mb-0 text-center"
                                                    style={{
                                                        background: investigationConclusions["threat_classification"] === "Improbable" ? "#D0E7DD" :
                                                            investigationConclusions["threat_classification"] === "Unlikely" ? "#ECC24A" :
                                                                investigationConclusions["threat_classification"] === "Benign True Positive" ? "#FF6632" : "#EF534F"
                                                    }}
                                                >
                                                    <span>Threat Classification</span>
                                                    <h5>{investigationConclusions["threat_classification"]}</h5>
                                                </div> */}
                                                    {/* <div className="flex-fill alert alert-success mb-0 text-center">
                                                <h5>{investigationConclusions["MITRE ATTACK TACTICS"]}</h5>
                                                <span>MITRE ATTACK TACTICS</span>
                                            </div>
                                            <div className="flex-fill alert alert-success mb-0 text-center">
                                                <h5>{investigationConclusions["MITRE ATTACK TECHNIQUES"]}</h5>
                                                <span>MITRE ATTACK TECHNIQUES</span>
                                            </div> */}
                                                </div>
                                                {showMoreAboutInv && (
                                                    <>
                                                        {/* {console.log("investigationConclusions", investigationConclusions)} */}
                                                        {investigationConclusions && (
                                                            <div className="modal-row-item flex-column p-0">
                                                                <span className="modal-label">Description</span>
                                                                <span className="modal-text col-12 col-12">
                                                                    {rawHTML(investigationConclusions["Alert Description"])}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {/* <div className="modal-row-item flex-column">
                                                        <span className="modal-label">Incident Response</span>
                                                        <span className="modal-text col-12">{investigationConclusions["Incident Response"]}</span>
                                                    </div> */}

                                                        {/* {(investigationConclusions["IP Reputation"] || investigationConclusions["IP Reputation"] === 0) && (
                                                            <div className="modal-row-item flex-column">
                                                                <span className="modal-label">IP Reputation</span>
                                                                <span className="modal-text col-12">{investigationConclusions["IP Reputation"]}</span>
                                                            </div>
                                                        )} */}

                                                        {investigationConclusions["Hash Reputation"] && (
                                                            <div className="modal-row-item flex-column">
                                                                <span className="modal-label">Hash Reputation</span>
                                                                <span className="modal-text col-12">{investigationConclusions["Hash Reputation"]}</span>
                                                            </div>
                                                        )}

                                                        {investigationConclusions["URL Reputation"] && (
                                                            <div className="modal-row-item flex-column">
                                                                <span className="modal-label">URL Reputation</span>
                                                                <span className="modal-text col-12">{investigationConclusions["URL Reputation"]}</span>
                                                            </div>
                                                        )}

                                                        {/* <div className="modal-row-item flex-column">
                                                        <span className="modal-label">Threat Score</span>
                                                        <span className="modal-text col-12">{rawHTML(investigationConclusions["Threat_Score"])}</span>
                                                    </div> */}

                                                        <div className="modal-row-item flex-column">
                                                            <span className="modal-label">Next Steps</span>
                                                            <span className="modal-text col-12">{rawHTML(investigationConclusions["Next Steps"])}</span>
                                                        </div>
                                                        <div className="modal-row-item flex-column">
                                                            <span className="modal-label">Recommendations and actions</span>
                                                            <span className="modal-text col-12">{rawHTML(investigationConclusions["Recommendations and actions"])}</span>
                                                        </div>
                                                    </>
                                                )

                                                }
                                                <span style={{ display: "flex", justifyContent: "center", color: "#808080", cursor: "pointer" }} onClick={() => setShowMoreAboutInv(!showMoreAboutInv)}>
                                                    {/* {!showMoreAboutInv ? "Expand" : "collapse"} */}
                                                    <TableViewMore Icon={!showMoreAboutInv ? CaretDown : CaretUp} size={24} dataPlacement={"bottom"} />
                                                </span>
                                            </>
                                        ) : (
                                            <div className="col-12 d-flex flex-fill"><NoDataFound /></div>
                                        )
                                        }
                                    </div >
                                </div >
                            </div >
                        )}


                        {((investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("hash_enrichment") && obj.hash_enrichment === 'true').length > 0 && hashVirusTotalData.length > 0) ||
                            (investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("url_enrichment") && obj.url_enrichment === 'true').length > 0 && urlVirusData.length > 0) ||
                            (investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ip_enrichment") && obj.ip_enrichment === 'true').length > 0 && ipVirusData.length > 0)) &&
                            <div className="flex-fill rounded-3 p-4 recent-file-container bg-white">
                                <h4 className="px-4 py-2">Threat Intelligence Details</h4>

                                <div className="d-flex gap-3 flex-row col-12 border rounded-3">
                                    {(investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("hash_enrichment") && obj.hash_enrichment === 'true').length > 0 && hashVirusTotalData.length > 0) &&
                                        <div className="flex-fill rounded-3 p-4 recent-file-container bg-white">
                                            <div className="d-flex flex-column">
                                                <h5>Investigating the hash</h5>
                                                <p>
                                                    Comprehensive Automated Investigation Conducted by CYBOT™ for the Observed Suspicious Hash. Click the 'i' icon to view the results.
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-3 flex-fill">
                                                <h6 className="mb-0">Virus Total</h6>
                                                {hashVirusTotalData.length > 0 ?
                                                    <>
                                                        <div className="d-flex flex-column gap-3">
                                                            <VirusTotalSummary data={hashVirusTotalData} />
                                                        </div>
                                                        <div className="d-flex flex-row align-items-center justify-content-center flex-column gap-3 position-relative">
                                                            <div
                                                                className="col-12 d-flex align-items-center justify-content-center alert border mb-0"
                                                            // style={{ height: "180px", width: "calc(100% - 2rem)" }}
                                                            // style={{ height: "240px", width: "240px" }}
                                                            >
                                                                <GaugeChart
                                                                    id="gauge-chart2"
                                                                    nrOfLevels={20}
                                                                    arcPadding={0.02}
                                                                    cornerRadius={2}
                                                                    percent={hashTsData[0]?.threat_score / 100}
                                                                    formatTextValue={(value) => value}
                                                                    textColor={"#000000"}
                                                                    style={{ width: "240px", height: "160px" }}
                                                                    animate={false}
                                                                />
                                                            </div>
                                                            <div className="position-absolute" style={{ bottom: "30px" }}>
                                                                <span>Final Score</span>
                                                            </div>
                                                            <div className="position-absolute top-0 end-0 m-2">
                                                                <Link to={`https://www.virustotal.com/gui/file/${hashVirusTotalData[0].name}`} target="_blank">
                                                                    {/* <Info size={28} color="#f00505" style={{ cursor: "pointer" }} /> */}
                                                                    <TableViewMore Icon={Info} size={24} dataToggle={"Click here to see the latest reputation"} dataPlacement={"bottom"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <div className="col-12 d-flex flex-fill"><NoDataFound /></div>
                                                }
                                                <div className="d-flex flex-column alert alert-info mb-0">
                                                    <span>AlienVault-OTX</span>
                                                    <h5>{hashAvData[0]?.av_score || hashAvData[0]?.av_score === 0 ? hashAvData[0]?.av_score : "--"}</h5>
                                                </div>
                                            </div>
                                        </div>}

                                    {(investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("url_enrichment") && obj.url_enrichment === 'true').length > 0 && urlVirusData.length > 0) &&
                                        <div className="d-flex flex-column flex-fill rounded-3 p-4 recent-file-container bg-white">
                                            <div className="d-flex flex-column">
                                                <h5>Investigating the URL</h5>
                                                <p>
                                                    Comprehensive Automated Investigation Conducted by CYBOT™ for the Observed Suspicious URL. Click the 'i' icon to view the results.
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-3 flex-fill">
                                                <h6 className="mb-0">Virus Total</h6>
                                                {urlVirusData.length > 0 ?
                                                    <>
                                                        <div className="d-flex flex-row gap-3">
                                                            <VirusTotalSummary data={urlVirusData} />
                                                        </div>
                                                        <div className="d-flex flex-row justify-content-center gap-3 position-relative">
                                                            <div
                                                                className="col-12 d-flex align-items-center justify-content-center alert border mb-0"
                                                            // style={{ height: "180px", width: "calc(100% - 2rem)" }}
                                                            // style={{ height: "240px", width: "240px" }}
                                                            >
                                                                <GaugeChart
                                                                    id="gauge-chart2"
                                                                    nrOfLevels={20}
                                                                    arcPadding={0.02}
                                                                    cornerRadius={2}
                                                                    percent={urlTsData[0]?.threat_score / 100}
                                                                    formatTextValue={(value) => value}
                                                                    textColor={"#000000"}
                                                                    style={{ width: "240px", height: "160px" }}
                                                                    animate={false}
                                                                />
                                                            </div>
                                                            <div className="position-absolute" style={{ bottom: "30px" }}>
                                                                <span>Final Score</span>
                                                            </div>
                                                            <div className="position-absolute top-0 end-0 m-2">
                                                                <Link to={`https://www.virustotal.com/gui/domain/${removeProtocol(urlVirusData[0].name)}`} target="_blank">
                                                                    {/* <Info size={28} color="#f00505" style={{ cursor: "pointer" }} /> */}
                                                                    <TableViewMore Icon={Info} size={24} dataToggle={"Click here to see the latest reputation"} dataPlacement={"bottom"} />
                                                                </Link>

                                                                <Link to={`https://www.urlvoid.com/scan/${removeProtocol(urlVirusData[0].name)}`} target="_blank">
                                                                    {/* <Info size={28} color="#f00505" style={{ cursor: "pointer" }} /> */}
                                                                    <TableViewMore Icon={Info} size={24} dataToggle={"Click here to check the URL Reputation in URL Void"} dataPlacement={"bottom"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <div className="col-12 d-flex flex-fill"><NoDataFound /></div>
                                                }
                                                <div className="d-flex flex-column alert alert-info mb-0">
                                                    <span>AlienVault-OTX</span>
                                                    <h5>{urlAvData[0]?.av_score || urlAvData[0]?.av_score === 0 ? urlAvData[0]?.av_score : "--"}</h5>
                                                </div>
                                            </div>
                                        </div>}
                                    {(investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ip_enrichment") && obj.ip_enrichment === 'true').length > 0 && ipVirusData.length > 0) &&
                                        <div className="d-flex flex-column flex-fill rounded-3 p-4 recent-file-container bg-white">
                                            <div className="d-flex flex-column">
                                                <h5>Investigating the IP</h5>
                                                <p>
                                                    Comprehensive Automated Investigation Conducted by CYBOT™ for the Observed Suspicious IP. Click the 'i' icon to view the results.
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column gap-3 flex-fill">
                                                <h6 className="mb-0">Virus Total</h6>
                                                {ipVirusData.length > 0 ?
                                                    <>
                                                        <div className="d-flex flex-row gap-3">
                                                            <VirusTotalSummary data={ipVirusData} />
                                                        </div>
                                                        <div className="d-flex flex-row justify-content-center gap-3 position-relative">
                                                            <div
                                                                className="col-12 d-flex align-items-center justify-content-center alert border mb-0"
                                                            // style={{ height: "180px", width: "calc(100% - 2rem)" }}
                                                            // style={{ height: "240px", width: "240px" }}
                                                            >
                                                                <GaugeChart
                                                                    id="gauge-chart2"
                                                                    nrOfLevels={20}
                                                                    arcPadding={0.02}
                                                                    cornerRadius={2}
                                                                    percent={ipTsData[0]?.threat_score / 100}
                                                                    formatTextValue={(value) => value}
                                                                    textColor={"#000000"}
                                                                    style={{ width: "240px", height: "160px" }}
                                                                    animate={false}
                                                                />
                                                            </div>
                                                            <div className="position-absolute" style={{ bottom: "30px" }}>
                                                                <span>Final Score</span>
                                                            </div>
                                                            <div className="position-absolute top-0 end-0 m-2">
                                                                <Link to={`https://www.virustotal.com/gui/ip-address/${ipVirusData[0].name}`} target="_blank">
                                                                    {/* <Info size={28} color="#f00505" style={{ cursor: "pointer" }} /> */}
                                                                    <TableViewMore Icon={Info} size={24} dataToggle={"Click here to see the latest reputation"} dataPlacement={"bottom"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <div className="col-12 d-flex flex-fill"><NoDataFound /></div>
                                                }
                                                <div className="d-flex flex-column alert alert-info mb-0">
                                                    <span>AlienVault-OTX</span>
                                                    <h5>{ipAvData[0]?.av_score || ipAvData[0]?.av_score === 0 ? ipAvData[0]?.av_score : "--"}</h5>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        }


                        {/* For TI */}

                        {(tiHashData.length > 0 || tiIpData.length > 0 || tiUrlData.length > 0) ? <div className="d-flex gap-3 flex-row col-12">
                            <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <div className="border-bottom">
                                    <h4>Threat Intelligence Feeds</h4>
                                    <p>
                                        Detailed automated investigation by CYBOT™ about the detected observables
                                        which executed the suspected activity
                                    </p>
                                </div>

                                {tiIpData.length > 0 && (
                                    <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Threat Inteligence IP ({detectionObservable[0]?._source?.observables?.source_ip})</h5>
                                            {tiIpData.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {tiIpData.length}
                                                </Badge>
                                            )}
                                        </div>
                                        {tiIpLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    tiIpData.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                //   { value: "sl_no", label: "Sl No" },
                                                                { value: "event_name", label: "Event Name" },
                                                                { value: "severity", label: "Severity" },
                                                                { value: "event_description", label: "Event Description" },
                                                            ]}
                                                            // link={[{ name: 'dest_ip', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={tiIpData}
                                                            // title={"Firewall from IP"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.description).map(obj => obj.description)}

                                                            pagination={tiIpData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                )}


                                {tiUrlData.length > 0 && (
                                    <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Threat Inteligence URL ({detectionObservable[0]._source?.observables?.url})</h5>
                                            {tiUrlData.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {tiUrlData.length}
                                                </Badge>
                                            )}
                                        </div>
                                        {
                                            tiUrlLoader ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        tiUrlData.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                tableHead={[
                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                    { value: "event_name", label: "Event Name" },
                                                                    { value: "severity", label: "Severity" },
                                                                    { value: "event_description", label: "Event Description" },
                                                                ]}
                                                                // link={[{ name: 'dest_ip', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                                tableData={tiUrlData}
                                                                // title={"Firewall from IP"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.description).map(obj => obj.description)}

                                                                pagination={tiUrlData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )
                                        }
                                    </div >
                                )
                                }


                                {
                                    tiHashData.length > 0 && (
                                        <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>Threat Inteligence Hash ({detectionObservable[0]._source?.observables?.process_hash})</h5>
                                                {tiHashData.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {tiHashData.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            {
                                                tiHashLoader ? (<TableLoader rowCount={4} />) : (
                                                    <div>
                                                        {
                                                            tiHashData.length === 0 ? (
                                                                <NoDataFound errorText={"No data found"} />
                                                            ) : (
                                                                <DataTable
                                                                    tableHead={[
                                                                        //   { value: "sl_no", label: "Sl No" },
                                                                        { value: "event_name", label: "Event Name" },
                                                                        { value: "severity", label: "Severity" },
                                                                        { value: "event_description", label: "Event Description" },
                                                                    ]}
                                                                    // link={[{ name: 'dest_ip', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                                    tableData={tiHashData}
                                                                    // title={"Firewall from IP"}
                                                                    // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.description).map(obj => obj.description)}

                                                                    pagination={tiHashData.length === 0 ? false : true}
                                                                    isFilter={false}
                                                                />
                                                            )}
                                                    </div>
                                                )
                                            }
                                        </div >
                                    )
                                }

                            </div>
                        </div> : <></>}
                        {/* tiUrlData,tiHashData,tiIpData */}


                        {
                            checkitemscontains(user_host_items) && data?.datasets[0]?.data.length > 0 &&
                            <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <h4 className="px-4">Cybot Investigator</h4>
                                <div className="col-12 bg-white border rounded-3 p-3">
                                    <div className="mx-3">
                                        <h4>Investigation Summary</h4>
                                    </div>
                                    {/* <div className="col-12"> */}
                                    <div className="col-12 chart_wrap_md" ref={divRefImage}>
                                        <Bar options={options} data={data} />
                                    </div>
                                    {/* </div> */}
                                </div>


                                <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white border">
                                    <div className="border-bottom">
                                        <h4>Investigation on Host & User</h4>
                                        <p>
                                            Detailed automated investigation by CYBOT™ about the Host & User
                                            which executed the suspected activity
                                        </p>
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        {recentAuthHostDetails.length > 0 && (
                                            <>
                                                {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_info_on_host") && obj.auth_info_on_host === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border recent-auth-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Recent Authentication in Host ({detectionObservable[0]?._source?.observables?.host_name}) </h5>
                                                        {recentAuthHostDetails.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {recentAuthHostDetails.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        An investigation in Data lake was carried out to examine recent authentication activities on the observed host.
                                                        The displayed results encompass activity from 24 hours before and after the detection, providing a
                                                        comprehensive analysis of potential security threats.
                                                        Examination of authentication logs can reveal signs like atypical login patterns, unauthorized access efforts, or
                                                        potentially compromised user accounts. For instance, a rapid surge in login attempts originating from multiple
                                                        IP addresses within a short time frame may indicate a potential brute force attack or a coordinated security
                                                        breach attempt.

                                                    </p>
                                                    {recentAuthLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                recentAuthHostDetails.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={["Sl No", "User Name", "Timestamp"]}
                                                                        tableHead={[
                                                                            //   { value: "sl_no", label: "Sl No" },
                                                                            { value: "date", label: "Date", isMonospace: true },
                                                                            { value: "time", label: "Time", isMonospace: true },
                                                                            { value: "event_category", label: "Event Category" },
                                                                            { value: "host_name", label: "Host Name" },
                                                                            { value: "user_name", label: "User Name" },
                                                                            { value: "remote_device_name", label: "Remote device name" },
                                                                            { value: "logon_session_type", label: "Logon session type" },
                                                                            { value: "action", label: "Event action" },
                                                                            { value: "event_outcome", label: "Event outcome" },
                                                                            { value: "action_type", label: "Action type" },
                                                                            { value: "network_protocol", label: "Network protocol" },
                                                                            { value: "failure_reason", label: "Failure reason" },
                                                                            { value: "file_hash", label: "File Hash(Virus Total)" },

                                                                            // { value: "timestamp", label: "Timestamp" },
                                                                        ]}
                                                                        tableData={recentAuthHostDetails}
                                                                        title={"Recent Authentication in Host "}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_info_on_host") && obj.description).map(obj => obj?.description)}

                                                                        pagination={[true]}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>

                                                    )}
                                                </div>}
                                            </>
                                        )}

                                        {recentFileData.length > 0 && (
                                            <>
                                                {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_creations") && obj.recent_file_creations === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border recent-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Recent File Creations</h5>
                                                        {recentFileData.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {recentFileData.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        An investigation was conducted in the Data lake to identify recent file creations by the suspected user on the
                                                        affected host. The analysis window encompasses a period starting 24 hours prior to the detection event and
                                                        ending 24 hours afterward.
                                                        Inspecting recently generated files on the host can provide insights into any illicit or malevolent actions. Should
                                                        there be a proliferation of unfamiliar or questionable files in a brief time span, it could be indicative of a
                                                        potential security breach, malware installation, or the establishment of a backdoor. A comprehensive analysis
                                                        of these files, including their file hash and file path, can unveil the characteristics and objectives of the attack,
                                                        facilitating the implementation of suitable defensive actions.
                                                    </p>
                                                    {recentCreationLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                recentFileData.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={[
                                                                        //   "Sl No",
                                                                        //   "User Name",
                                                                        //   "Host Name",
                                                                        //   "File Name",
                                                                        //   "File Path",
                                                                        // ]}
                                                                        tableHead={[
                                                                            //   { value: "sl_no", label: "Sl No" },
                                                                            { value: "date", label: "Date", isMonospace: true },
                                                                            { value: "time", label: "Time", isMonospace: true },
                                                                            { value: "event_category", label: "Event Category" },
                                                                            { value: "action", label: "Event action" },
                                                                            { value: "host_name", label: "Host Name" },
                                                                            { value: "user_domain", label: "User Domain" },
                                                                            { value: "user_name", label: "User Name" },
                                                                            { value: "file_name", label: "File Name" },
                                                                            { value: "file_extension", label: "File Extension" },
                                                                            { value: "file_path", label: "File Path", isLongText: true },
                                                                            { value: "file_hash_value", label: "File hash" },
                                                                            { value: "initiating_process_name", label: "Initiating process name" },
                                                                            { value: "initiating_process_hash", label: "Initiating process hash" },
                                                                            { value: "parent_process_name", label: "parent process name" },
                                                                            { value: "process_path", label: "process path" },
                                                                            { value: "process_commandline", label: "process Commandline" },

                                                                            { value: "file_hash", label: "File Hash(Virus Total)" },
                                                                        ]}
                                                                        tableData={recentFileData}
                                                                        link={[{ name: 'process_hash', url: "https://www.virustotal.com/gui/file/", label: "Process Hash" }, { name: 'file_hash_value', url: "https://www.virustotal.com/gui/file/", label: "File Hash" }]}
                                                                        // link={[{ name: 'process_hash', url: "https://www.virustotal.com/gui/file/" }]}
                                                                        title={"Recent file creations"}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_creations") && obj.description).map(obj => obj?.description)}
                                                                        // description={
                                                                        //     "An investigation was carried out in the datalake to determine the recent file creations performed by the suspected user on the host in which suspicious activity occurred examining time period from 2 hours before to 2 hours after the detection time."
                                                                        // }
                                                                        pagination={recentFileData.length === 0 ? false : true}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>
                                                    )}
                                                </div>}
                                            </>
                                        )}


                                        {deletionFileData.length > 0 && (
                                            <>
                                                {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_deletions") && obj.recent_file_deletions === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Recent File Deletions</h5>
                                                        {deletionFileData.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {deletionFileData.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        An investigation was conducted in the Data lake to pinpoint recent file deletions associated with the suspected user on the
                                                        affected host. Our analysis focused on a window of 24 hours preceding and 24 hours following the detection event.
                                                        Examining recent file deletions constitutes a pivotal stage in investigations aimed at uncovering potential security breaches or
                                                        illicit actions. This evaluation serves to ascertain whether unauthorized users, processes, or malware have eradicated essential
                                                        files, thereby assisting in prompt response and the mitigation of potential risks. The identification of unusual file deletions
                                                        may indicate various scenarios, such as an adversary attempting to conceal their tracks, the presence of ransomware activity,
                                                        or the involvement of an insider or rogue employee.
                                                    </p>
                                                    {recentDeletionLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                deletionFileData.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={[
                                                                        //   "Sl No",
                                                                        //   "User Name",
                                                                        //   "Host Name",
                                                                        //   "File Name",
                                                                        //   "File Path",
                                                                        // ]}
                                                                        tableHead={[
                                                                            //   { value: "sl_no", label: "Sl No" },

                                                                            { value: "date", label: "Date" },
                                                                            { value: "time", label: "Time" },
                                                                            { value: "event_category", label: "Event Category" },
                                                                            { value: "action", label: "Event action" },
                                                                            { value: "host_name", label: "Host Name" },
                                                                            { value: "user_domain", label: "User Domain" },
                                                                            { value: "user_name", label: "User Name" },
                                                                            { value: "file_name", label: "File Name" },
                                                                            { value: "file_extension", label: "File Extension" },
                                                                            { value: "file_path", label: "File Path", isLongText: true },
                                                                            { value: "file_hash_value", label: "File hash value" },
                                                                            { value: "initiating_process_name", label: "Initiating process name" },
                                                                            { value: "initiating_process_hash", label: "Initiating process hash" },
                                                                            { value: "parent_process_name", label: "parent process name" },
                                                                            { value: "process_path", label: "process path" },
                                                                            { value: "process_commandline", label: "process Commandline" },
                                                                            { value: "file_hash", label: "Process Hash(Virus Total)" },


                                                                        ]}
                                                                        tableData={deletionFileData}
                                                                        title={"Recent file deletions"}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("recent_file_deletions") && obj.description).map(obj => obj?.description)}
                                                                        // description={
                                                                        //     "An investigation was performed in the datalake to determine the recent file deletions performed by the suspected user in the host in which suspicious activity occurred examining time range from 2 hours before to 2 hours after the detection time."
                                                                        // }
                                                                        pagination={deletionFileData.length === 0 ? false : true}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>
                                                    )}
                                                </div>}
                                            </>
                                        )}


                                        {processRunDetectionUserData?.length > 0 && (
                                            <>
                                                {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("user_run_processes") && obj.user_run_processes === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Processes Run by Detected User ({detectionObservable[0]?._source?.observables?.user_name}) </h5>
                                                        {processRunDetectionUserData?.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {processRunDetectionUserData?.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        An investigation was conducted to identify the processes on the host during the detection, listed with their
                                                        execution frequencies 24 hours before and after the event.
                                                        The scrutiny of processes executed by an identified user yields valuable information regarding potential
                                                        malicious activities or compromised accounts. This investigation is pivotal for comprehending a user's actions
                                                        and plays a crucial role in swiftly recognizing and addressing security threats.
                                                    </p>
                                                    {
                                                        proDetectUserLoad ? (<TableLoader rowCount={4} />) : (
                                                            <div>
                                                                {
                                                                    processRunDetectionUserData?.length === 0 ? (
                                                                        <NoDataFound errorText={"No data found"} />
                                                                    ) : (
                                                                        <DataTable
                                                                            // tableHead={[
                                                                            //   "Sl No",
                                                                            //   "Detection Timestamp",
                                                                            //   "Process Name",
                                                                            //   "Process Hash",
                                                                            //   "File Path",
                                                                            // ]}
                                                                            tableHead={[
                                                                                //   { value: "sl_no", label: "Sl No" },
                                                                                // { value: "detection_timestamp", label: "Timestamp" },
                                                                                { value: "date", label: "Date", isMonospace: true },
                                                                                { value: "time", label: "Time", isMonospace: true },
                                                                                { value: "host_name", label: "Host Name" },
                                                                                { value: "user_domain", label: "user_domain" },
                                                                                { value: "user_name", label: "User name" },
                                                                                { value: "event_category", label: "Event category" },
                                                                                { value: "action_type", label: "Action type" },
                                                                                { value: "file_name", label: "file name" },
                                                                                { value: "file_path", label: "Path Name", isLongText: true },
                                                                                { value: "file_hash_value", label: "File Hash" },
                                                                                { value: "commandline", label: "Commandline", isMonospace: true, isLongText: true },
                                                                                { value: "process_name", label: "Process name" },
                                                                                { value: "process_executable", label: "Process Executable", isMonospace: true },
                                                                                { value: "process_path", label: "Process Path", isMonospace: true },
                                                                                { value: "process_hash", label: "Process Hash", isMonospace: true },
                                                                                { value: "process_commandline", label: "Process commandline", isMonospace: true, isLongText: true },
                                                                                { value: "parent_process", label: "Parent process name", isMonospace: true },
                                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                            ]}
                                                                            tableData={processRunDetectionUserData}
                                                                            link={[{ name: 'process_hash', url: "https://www.virustotal.com/gui/file/", label: "Process Hash" }, { name: 'file_hash_value', url: "https://www.virustotal.com/gui/file/", label: "File Hash" }]}
                                                                            title={"Processes Run by Detected User"}
                                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("user_run_processes") && obj.description).map(obj => obj?.description)}
                                                                            // description={
                                                                            //     "An investigation was conducted to determine the processes that were run on the detected host around the time of detection. The panel below displays a list of all processes run during that period, along with the frequency of their execution. The investigation covered a time range from 6 hours before the detection time to 6 hours after the detection time"
                                                                            // }
                                                                            pagination={processRunDetectionUserData.length === 0 ? false : true}
                                                                            isFilter={false}
                                                                        />)}
                                                            </div>
                                                        )
                                                    }
                                                </div >}
                                            </>
                                        )}


                                        {processRunDetectionHostData?.length > 0 && (
                                            <>
                                                {
                                                    investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("host_run_processes") && obj.host_run_processes === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                        <div className="header d-flex align-items-center">
                                                            <h5>Processes Run by Detected Host ({detectionObservable[0]?._source?.observables?.host_name}) </h5>
                                                            {/* {console.log('processRunDetectionHostData', processRunDetectionHostData)} */}
                                                            {processRunDetectionHostData?.length !== 0 && (
                                                                <Badge className="ml-2" bg="info">
                                                                    {processRunDetectionHostData?.length}
                                                                </Badge>
                                                            )}
                                                        </div>
                                                        <p>
                                                            An investigation was performed to ascertain the processes executed on the identified host. The panel below
                                                            displays a comprehensive list of these processes, their execution frequency, and the investigation span, which
                                                            encompasses 24 hours before and after the detection.
                                                            Examining the processes initiated by a detected host allows for the detection of potential malicious activities
                                                            or compromised systems. This analysis offers essential insights into the host's actions, contributing to the rapid
                                                            identification and mitigation of security threats.

                                                        </p>
                                                        {
                                                            proDetectHostLoad ? (<TableLoader rowCount={4} />) : (
                                                                <div>
                                                                    {
                                                                        processRunDetectionHostData?.length === 0 ? (
                                                                            <NoDataFound errorText={"No data found"} />
                                                                        ) : (
                                                                            <DataTable
                                                                                // tableHead={[
                                                                                //   "Sl No",
                                                                                //   "Detection Timestamp",
                                                                                //   "Process Name",
                                                                                //   "Process Hash",
                                                                                //   "File Path",
                                                                                // ]}
                                                                                tableHead={[
                                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                                    // { value: "detection_timestamp", label: "Timestamp" },
                                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                                    { value: "host_name", label: "Host Name" },
                                                                                    { value: "user_domain", label: "User Domain" },
                                                                                    { value: "user_name", label: "User Name" },
                                                                                    { value: "event_category", label: "Event Category" },
                                                                                    { value: "action_type", label: "Action Type" },
                                                                                    { value: "file_name", label: "File Name" },
                                                                                    { value: "file_path", label: "Path Name", isLongText: true },
                                                                                    { value: "file_hash_value", label: "File Hash" },
                                                                                    { value: "commandline", label: "Commandline", isMonospace: true, isLongText: true },
                                                                                    { value: "process_name", label: "Process Name" },
                                                                                    { value: "process_executable", label: "Process Executable", isMonospace: true },
                                                                                    { value: "process_path", label: "Process Path", isMonospace: true },
                                                                                    { value: "process_hash", label: "Process Hash", isMonospace: true },
                                                                                    { value: "process_commandline", label: "Process Commandline", isMonospace: true, isLongText: true },
                                                                                    { value: "parent_process", label: "Parent Process Name", isMonospace: true },
                                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                                ]}
                                                                                link={[{ name: 'process_hash', url: "https://www.virustotal.com/gui/file/", label: "Process Hash" }]}
                                                                                tableData={processRunDetectionHostData}
                                                                                title={"Processes Run by Detected Host"}
                                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("host_run_processes") && obj.description).map(obj => obj.description)}
                                                                                // description={
                                                                                //     "An investigation was performed to determine the processes run in the detected host. The below panel shows the list of all processes that were run in the detected host around the time of detection along with the number of times they were run. The investigation covered a time range from 6 hours before the detection time to 6 hours after the detection time."
                                                                                // }
                                                                                pagination={processRunDetectionHostData.length === 0 ? false : true}
                                                                                isFilter={false}
                                                                            />)}
                                                                </div>
                                                            )
                                                        }
                                                    </div >}
                                            </>
                                        )}



                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("uncommon_processes_VT") && obj.uncommon_processes_VT === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                <h5>Uncommon Processes Run by Detected User</h5>
                                                {unProDetectUserLoad ? (<TableLoader rowCount={4} />) : (
                                                    <div>
                                                        {
                                                            uncommonProcessData.length === 0 ? (
                                                                <NoDataFound errorText={"No Data Founted"} />
                                                            ) : (
                                                                <DataTable
                                                                    // tableHead={[
                                                                    //   "Sl No",
                                                                    //   "VirusTotal Score",
                                                                    //   "Process Name",
                                                                    //   "Process Hash",
                                                                    //   "Detection Timestamp",
                                                                    // ]}
                                                                    tableHead={[
                                                                        //   { value: "sl_no", label: "Sl No" },
                                                                        {
                                                                            value: "uncommon_process_vt_score",
                                                                            label: "Uncommon Process Data",
                                                                        },
                                                                        {
                                                                            value: "uncommon_process_name",
                                                                            label: "Uncommon Process Name",
                                                                        },
                                                                        {
                                                                            value: "uncommon_process_hash",
                                                                            label: "Uncommon Process Hash",
                                                                        },
                                                                        { value: "detection_timestamp", label: "Timestamp" },
                                                                    ]}
                                                                    tableData={uncommonProcessData}
                                                                    title={"Uncommon Processes run by detected User"}
                                                                    // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("uncommon_processes_VT") && obj.description).map(obj => obj?.description)}
                                                                    pagination={uncommonProcessData.length === 0 ? false : true}
                                                                    isFilter={false}
                                                                />)}
                                                    </div>
                                                )}
                                            </div>
                                        }

                                        {hostLoggedByUserData?.length > 0 && (
                                            <>
                                                {
                                                    investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_logged_into_by_user") && obj.other_hosts_logged_into_by_user === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                        <div className="header d-flex align-items-center">
                                                            <h5>Hosts Logged into by User ({detectionObservable[0]?._source?.observables?.user_name})</h5>
                                                            {hostLoggedByUserData?.length !== 0 && (
                                                                <Badge className="ml-2" bg="info">
                                                                    {hostLoggedByUserData?.length}
                                                                </Badge>
                                                            )}
                                                        </div>
                                                        <p>
                                                            The investigation's objective is to uncover additional host logins by the user. The provided list displays the
                                                            hosts they accessed, starting from 24 hours before the detection event. This examination provides valuable
                                                            insights into the user's activities and highlights potential security risks associated with their login patterns.
                                                            These log provides insights into a user's activities across different systems. To identify potential security issues, analysts
                                                            examine login frequency, unusual login locations, anomalous actions, failed login attempts, and session durations.
                                                        </p>
                                                        {
                                                            hostLoggedUserLoad ? (<TableLoader rowCount={4} />) : (
                                                                <div>
                                                                    {/* {console.log('hostLoggedByUserData', hostLoggedByUserData)} */}
                                                                    {
                                                                        hostLoggedByUserData?.length === 0 ? (
                                                                            <NoDataFound errorText={"No data found"} />
                                                                        ) : (
                                                                            <DataTable
                                                                                // tableHead={["Sl No", "Host Name", "Count"]}
                                                                                tableHead={[
                                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                                    { value: "event_category", label: "Event category" },
                                                                                    { value: "event_action", label: "Event action" },
                                                                                    { value: "host_name", label: "Host name" },
                                                                                    { value: "user_domain", label: "User domain" },
                                                                                    { value: "event_outcome", label: "Event outcome" },
                                                                                    { value: "logon_type", label: "Logon session type" },
                                                                                    { value: "network_protocol", label: "Network protocol" },
                                                                                    { value: "failure_reason", label: "Failure Reason" },
                                                                                    { value: "process_name", label: "Process name" },
                                                                                    { value: "remote_device_name", label: "Remote Device Name" },
                                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                                    // { value: "process_path", label: "Process path", isLongText: true },
                                                                                    // { value: "process_commandline", label: "Process commandline", isLongText: true },
                                                                                    // { value: "process_hash", label: "Process hash" },
                                                                                    // { value: "process_parent_name", label: "Process parent name" },
                                                                                ]}
                                                                                tableData={hostLoggedByUserData}
                                                                                title={"Hosts logged into by user"}
                                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_logged_into_by_user") && obj.description).map(obj => obj?.description)}
                                                                                // description={
                                                                                //     "The investigation aims to determine if the user had logged into any additional hosts, and the panel below displays the list of other hosts that were accessed by the user. The investigation examined a time range starting from 12 hours before the detection time"
                                                                                // }
                                                                                pagination={hostLoggedByUserData.length === 0 ? false : true}
                                                                                isFilter={false}
                                                                            />
                                                                        )}
                                                                </div>
                                                            )
                                                        }
                                                    </div >}
                                            </>
                                        )}


                                        {/* <div className="col-12 rounded-3 p-4 border deletion-file-container"> */}
                                        {/* <h5>Traffic from host to IP summary</h5> */}
                                        {/* <DataTable */}
                                        {/* // tableHead={["Sl No", "Host Name", "Count"]}
                tableHead={[
                  //   { value: "sl_no", label: "Sl No" },
                  { value: "host_name", label: "Host Name" },
                  { value: "count", label: "Count" },
                ]}
                tableData={trafficHostDetectionFromSummary}
                title={"Traffic from host to IP"}
                description={
                  ""
                }
                pagination={true}
                isFilter={false}
              />
            </div> */}

                                        {trafficHostDetailedData?.length > 0 && (
                                            <>
                                                {
                                                    investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_visited_ip") && obj.other_hosts_visited_ip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                        <div className="header d-flex align-items-center">
                                                            <h5>Traffic from Other Host to Detected IP</h5>
                                                            {trafficHostDetailedData?.length !== 0 && (
                                                                <Badge className="ml-2" bg="info">
                                                                    {trafficHostDetailedData?.length}
                                                                </Badge>
                                                            )}
                                                        </div>
                                                        <p>
                                                            An investigation was conducted to determine whether the observed IP address had been accessed by other
                                                            hosts on the network in the last 24 hours. This examination aids in identifying potential malicious connections
                                                            and understanding the extent and impact of the detected IP.
                                                            Analyzing inbound traffic directed at a specific IP address is vital for security analysts. It helps them determine
                                                            if the IP is part of a significant attack, like a DDoS attempt. Analysts identify such threats by scrutinizing
                                                            patterns like sudden traffic spikes, unconventional traffic origins, and recognizable attack signatures. This
                                                            analysis enables them to understand the threat's nature and develop suitable defense strategies to safeguard
                                                            the network.
                                                        </p>
                                                        {trafficFromHostLoad ? (<TableLoader rowCount={4} />) : (
                                                            <div>
                                                                {
                                                                    trafficHostDetailedData?.length === 0 ? (
                                                                        <NoDataFound errorText={"No data found"} />
                                                                    ) : (
                                                                        <DataTable
                                                                            // tableHead={[
                                                                            //   "Sl No",
                                                                            //   "Host Name",
                                                                            //   "User Name",
                                                                            //   "Process Name",
                                                                            //   "Timestamp",
                                                                            //   "Destination Port",
                                                                            // ]}
                                                                            tableHead={[
                                                                                //   { value: "sl_no", label: "Sl No" },
                                                                                { value: "host_name", label: "Host Name" },
                                                                                { value: "user_name", label: "User Name" },
                                                                                { value: "process_name", label: "Process Name" },
                                                                                { value: "dest_port", label: "Destination Port", isMonospace: true },
                                                                                // { value: "timestamp", label: "Timestamp" },
                                                                                { value: "date", label: "Date", isMonospace: true },
                                                                                { value: "time", label: "Time", isMonospace: true },
                                                                            ]}
                                                                            tableData={trafficHostDetailedData}
                                                                            title={"Traffic from other host to detected IP"}
                                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("other_hosts_visited_ip") && obj.description).map(obj => obj.description)}
                                                                            pagination={trafficHostDetailedData.length === 0 ? false : true}
                                                                            isFilter={false}
                                                                        />)}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </>
                                        )}


                                    </div >
                                </div >
                            </div>
                        }



                        {
                            checkitemscontains(firewall_items) && <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <div className="border-bottom">
                                    <h4>Investigation on Firewall</h4>
                                    <p>
                                        Detailed automated investigation by CYBOT™ about the detected source and destination IP
                                        which executed the suspected activity
                                    </p>
                                </div>

                                {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "dest_ip", label: "Destination IP" },
                { value: "dest_port", label: "Destination Port" },
                { value: "dest_country", label: "Destination Country" },
                { value: "count", label: "Count" },
              ]}
              tableData={firewallTrafficFromSummaryData}
              title={"Firewall from IP summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={false}
            />
          </div> */}

                                {firewallTrafficFromDetailedData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.traffic_from_ip_on_firewall === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>Traffic From the Detected IP ({detectionObservable[0]?._source?.observables?.source_ip})</h5>
                                                {firewallTrafficFromDetailedData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {firewallTrafficFromDetailedData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                An investigation within the Data Lake was conducted to examine traffic originating from the identified IP
                                                address within a 24-hour window.
                                                Monitoring outbound traffic originating from the identified IP address is crucial for assessing if it is a potential
                                                source of suspicious or malicious activity. The analysis of outbound traffic can unveil indications of
                                                compromised systems, malware infections, or command-and-control communication. This information enables
                                                security teams to take swift response measures.

                                            </p>
                                            {
                                                firewallFromIpLoad ? (<TableLoader rowCount={4} />) : (
                                                    <div>
                                                        {
                                                            firewallTrafficFromDetailedData?.length === 0 ? (
                                                                <NoDataFound errorText={"No data found"} />
                                                            ) : (
                                                                <DataTable
                                                                    // tableHead={[
                                                                    //   "Sl No",
                                                                    //   "Destination IP",
                                                                    //   "Destination Port",
                                                                    //   "Destination Country",
                                                                    //   "Timestamp",
                                                                    // ]}
                                                                    tableHead={[
                                                                        //   { value: "sl_no", label: "Sl No" },
                                                                        { value: "date", label: "Date", isMonospace: true },
                                                                        { value: "time", label: "Time", isMonospace: true },
                                                                        { value: "source_ip", label: "Source IP", isMonospace: true },
                                                                        { value: "source_port", label: "Source Port", isMonospace: true },
                                                                        { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                        { value: "dest_port", label: "Destination Port", isMonospace: true },
                                                                        { value: "srcname", label: "Source name", isMonospace: true },
                                                                        { value: "dstname", label: "Destination name", isMonospace: true },
                                                                        { value: "action", label: "Action", isMonospace: true },
                                                                        { value: "tag2", label: "Status", isMonospace: true },
                                                                        { value: "url", label: "URL", isMonospace: true },
                                                                        { value: "user_name", label: "User name", isMonospace: true },
                                                                        { value: "service", label: "Service", isMonospace: true },
                                                                        { value: "type", label: "Type", isMonospace: true },
                                                                        { value: "source_country_name", label: "Source country", isMonospace: true },
                                                                        { value: "destination_country_name", label: "Destination Country" },
                                                                        { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                    ]}
                                                                    link={[{ name: 'source_ip', url: "https://www.virustotal.com/gui/ip-address/", label: "Source IP" }]}
                                                                    tableData={firewallTrafficFromDetailedData}
                                                                    title={"Traffic From the Detected IP"}
                                                                    // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_firewall") && obj.description).map(obj => obj.description)}

                                                                    pagination={firewallTrafficFromDetailedData.length === 0 ? false : true}
                                                                    isFilter={false}
                                                                />
                                                            )}
                                                    </div>
                                                )
                                            }
                                        </div >}
                                    </>
                                )}



                                {bytesTrasferredBetweenIpData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("firewall_byte_transferred_between_ip") && obj.firewall_byte_transferred_between_ip === 'true').length > 0 && (
                                                <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Bytes Transferred between IPs</h5>
                                                        {bytesTrasferredBetweenIpData?.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {bytesTrasferredBetweenIpData?.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        This investigation examines data exchange volumes between specified source and destination IPs over the past
                                                        24 hours to detect potential network anomalies or suspicious data transmissions.
                                                        Identify irregularities by comparing with historical data, analyzing protocols, inspecting data content,
                                                        monitoring endpoint and user behavior, considering geographic information, evaluating transfer rates, and
                                                        studying traffic patterns. Unusual patterns may indicate security incidents, such as data theft, prompting
                                                        further investigation and response.
                                                    </p>
                                                    {bytesTrasferredBetweenIpLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                bytesTrasferredBetweenIpData?.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={[
                                                                        //   "Sl No",
                                                                        //   "Destination IP",
                                                                        //   "Destination Port",
                                                                        //   "Destination Country",
                                                                        //   "Timestamp",
                                                                        // ]}
                                                                        tableHead={[
                                                                            { value: "source_ip", label: "Source IP" },
                                                                            { value: "source_port", label: "Source Port" },
                                                                            { value: "dest_ip", label: "Destination IP" },
                                                                            { value: "dest_port", label: "Destination Port" },
                                                                            { value: "Protocol", label: "Protocol" },
                                                                            { value: "byte_in", label: "Byte In" },
                                                                            { value: "byte_out", label: "Byte Out" },
                                                                        ]}
                                                                        tableData={bytesTrasferredBetweenIpData}
                                                                        title={"Bytes Transferred between IPs"}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("firewall_byte_transferred_between_ip") && obj.description).map(obj => obj.description)}

                                                                        pagination={bytesTrasferredBetweenIpData.length === 0 ? false : true}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </>
                                )}



                                {threatDetectionIpOutboundData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_outbound") && obj.l7fw_threat_detection_on_ip_outbound === 'true').length > 0 && (
                                                <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Threats Detected from the Destination IP ({detectionObservable[0]?._source?.observables?.dest_ip})</h5>
                                                        {threatDetectionIpOutboundData?.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {threatDetectionIpOutboundData?.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        This investigation centers on security threats flagged from the designated destination IP address within the last
                                                        24 hours.
                                                        By pinpointing threats detected at the destination IP, it becomes possible to evaluate which internal resources
                                                        or systems may be under attack from malicious activities. This proactive monitoring of threats at the
                                                        destination IP enables swift identification of potential vulnerabilities or compromised systems, facilitating
                                                        prompt attention and mitigation efforts.
                                                    </p>
                                                    {
                                                        threatDetectionIpOutboundLoad ? (<TableLoader rowCount={4} />) : (
                                                            <div>
                                                                {
                                                                    threatDetectionIpOutboundData?.length === 0 ? (
                                                                        <NoDataFound errorText={"No data found"} />
                                                                    ) : (
                                                                        <DataTable
                                                                            // tableHead={[
                                                                            //   "Sl No",
                                                                            //   "Destination IP",
                                                                            //   "Destination Port",
                                                                            //   "Destination Country",
                                                                            //   "Timestamp",
                                                                            // ]}
                                                                            tableHead={[
                                                                                { value: "date", label: "Date", isMonospace: true },
                                                                                { value: "time", label: "Time", isMonospace: true },
                                                                                { value: "attack", label: "Attack" },
                                                                                { value: "level", label: "Level" },
                                                                                { value: "action", label: "Action" },
                                                                                { value: "source_ip", label: "Source IP", isMonospace: true },
                                                                                { value: "source_port", label: "Source Port", isMonospace: true },
                                                                                { value: "dest_ip", label: "Destination IP" },
                                                                                { value: "dest_port", label: "Destination Port" },
                                                                                { value: "event_type", label: "Event Type" },
                                                                                { value: "Reference_url", label: "Reference URL" },
                                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                            ]}
                                                                            link={[{ name: 'source_ip', url: "https://www.virustotal.com/gui/ip-address/", label: "Source IP" }]}
                                                                            tableData={threatDetectionIpOutboundData}
                                                                            title={"Threats Detected from the Destination IP"}
                                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_outbound") && obj.description).map(obj => obj.description)}

                                                                            pagination={threatDetectionIpOutboundData.length === 0 ? false : true}
                                                                            isFilter={false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        )
                                                    }
                                                </div >
                                            )
                                        }
                                    </>
                                )}


                                {threatDetectionIpInboundData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_inbound") && obj.l7fw_threat_detection_on_ip_inbound === 'true').length > 0 && (
                                                <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Threats Detected From the Source IP ({detectionObservable[0]?._source?.observables?.source_ip})</h5>
                                                        {threatDetectionIpInboundData?.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {threatDetectionIpInboundData?.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        This investigation focuses on security threats identified from the identified source IP address within the last 24
                                                        hours.
                                                        Identifying threats originating from specific source IPs is crucial for pinpointing the origins of attacks and
                                                        ongoing security risks. This information empowers proactive threat mitigation by enabling the blocking or
                                                        isolation of malicious sources, thereby reducing potential damage and enhancing overall security.

                                                    </p>
                                                    {
                                                        threatDetectionIpInboundLoad ? (<TableLoader rowCount={4} />) : (
                                                            <div>
                                                                {
                                                                    threatDetectionIpInboundData?.length === 0 ? (
                                                                        <NoDataFound errorText={"No data found"} />
                                                                    ) : (
                                                                        <DataTable
                                                                            // tableHead={[
                                                                            //   "Sl No",
                                                                            //   "Destination IP",
                                                                            //   "Destination Port",
                                                                            //   "Destination Country",
                                                                            //   "Timestamp",
                                                                            // ]}
                                                                            tableHead={[
                                                                                { value: "date", label: "Date", isMonospace: true },
                                                                                { value: "time", label: "Time", isMonospace: true },
                                                                                { value: "attack", label: "Attack" },
                                                                                { value: "level", label: "Level" },
                                                                                { value: "action", label: "Action" },
                                                                                { value: "source_ip", label: "Source IP", isMonospace: true },
                                                                                { value: "source_port", label: "Source Port", isMonospace: true },
                                                                                { value: "dest_ip", label: "Destination IP" },
                                                                                { value: "dest_port", label: "Destination Port" },
                                                                                { value: "event_type", label: "Event Type" },
                                                                                { value: "Reference_url", label: "Reference URL" },
                                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                            ]}
                                                                            link={[{ name: 'dest_ip', url: "https://www.virustotal.com/gui/ip-address/", label: "Destination IP" }]}
                                                                            tableData={threatDetectionIpInboundData}
                                                                            title={"Threats Detected From the Source IP"}
                                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_threat_detection_on_ip_inbound") && obj?.description).map(obj => obj.description)}

                                                                            pagination={threatDetectionIpInboundData.length === 0 ? false : true}
                                                                            isFilter={false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        )
                                                    }
                                                </div >
                                            )
                                        }
                                    </>
                                )}


                                {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "source_ip", label: "Source IP" },
                { value: "source_port", label: "Source Port" },
                { value: "source_country", label: "Source Country" },
                { value: "count", label: "Count" },
              ]}
              tableData={firewallTrafficToSummaryData}
              title={"Firewall to IP Summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={false}
            />
          </div> */}

                                {firewallTrafficToDetailedData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_to_ip_on_firewall") && obj.traffic_to_ip_on_firewall === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                <div className="header d-flex align-items-center">
                                                    <h5>Traffic to the Detected IP ({detectionObservable[0]?._source?.observables?.dest_ip}) </h5>
                                                    {firewallTrafficToDetailedData?.length !== 0 && (
                                                        <Badge className="ml-2" bg="info">
                                                            {firewallTrafficToDetailedData?.length}
                                                        </Badge>
                                                    )}
                                                </div>
                                                <p>
                                                    An investigation within the Data Lake was conducted to examine traffic towards the identified IP address
                                                    within a 24-hour window.
                                                    Monitoring outbound traffic originating from the identified IP address is crucial for assessing if it is a potential
                                                    source of suspicious or malicious activity. The analysis of outbound traffic can unveil indications of
                                                    compromised systems, malware infections, or command-and-control communication. This information
                                                    enables security teams to take swift response measures.
                                                </p>
                                                {
                                                    firewallToIpLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                firewallTrafficToDetailedData?.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={[
                                                                        //   "Sl No",
                                                                        //   "Source IP",
                                                                        //   "Source Port",
                                                                        //   "Source Country",
                                                                        //   "Timestamp",
                                                                        // ]}
                                                                        tableHead={[
                                                                            { value: "date", label: "Date", isMonospace: true },
                                                                            { value: "time", label: "Time", isMonospace: true },
                                                                            { value: "source_ip", label: "Source IP", isMonospace: true },
                                                                            { value: "source_port", label: "Source Port", isMonospace: true },
                                                                            { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                            { value: "dest_port", label: "Destination Port", isMonospace: true },
                                                                            { value: "srcname", label: "Source name", isMonospace: true },
                                                                            { value: "dstname", label: "Destination name", isMonospace: true },
                                                                            { value: "action", label: "Action", isMonospace: true },
                                                                            { value: "tag2", label: "Status", isMonospace: true },
                                                                            { value: "url", label: "URL", isMonospace: true },
                                                                            { value: "user_name", label: "User name", isMonospace: true },
                                                                            { value: "service", label: "Service", isMonospace: true },
                                                                            { value: "type", label: "Type", isMonospace: true },
                                                                            { value: "source_country_name", label: "Source country", isMonospace: true },
                                                                            { value: "destination_country_name", label: "Destination Country" },
                                                                            { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                        ]}
                                                                        link={[{ name: 'source_ip', url: "https://www.virustotal.com/gui/ip-address/", label: "Source IP" }]}
                                                                        tableData={firewallTrafficToDetailedData}
                                                                        title={"Traffic to the Detected IP"}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_to_ip_on_firewall") && obj.description).map(obj => obj?.description)}

                                                                        pagination={firewallTrafficToDetailedData.length === 0 ? false : true}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>
                                                    )
                                                }
                                            </div >}
                                    </>
                                )}




                                {fireWallPublicIpInfoOSINTData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("pubilc_info") && obj.pubilc_info === 'true').length > 0 && (
                                                <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <h5>Public IP Info from OSINT </h5>
                                                    <p>
                                                        This investigation involves obtaining supplementary data on public IP addresses utilizing Open-Source
                                                        Intelligence (OSINT) sources, specifically the Whois Python package.
                                                        The purpose is to gather insights into the origin and potential security risks associated with these IP addresses.
                                                    </p>
                                                    {firewallToIpLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            <div className="col-12 rounded-3 p-4 bg-white">
                                                                {sideBarLoader ? (
                                                                    <SingleTileLoader width="100%" count="13" />
                                                                ) : (
                                                                    <>
                                                                        {fireWallPublicIpInfoOSINTData?.map((item, index) => (
                                                                            <>

                                                                                {Object.entries(item).map(([key, value]) => (
                                                                                    <div className="col-12 text-break">
                                                                                        <div className="modal-row-item flex-column">
                                                                                            <span className="modal-label">{key}</span>
                                                                                            <span className="modal-text col-12">{value}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </>

                                                                        ))}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </>
                                )}



                                {/* wafUserAttemptedByIpData */}

                                {wafUserAttemptedByIpData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_user_attempt_by_ip") && obj.l7fw_user_attempt_by_ip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                <div className="header d-flex align-items-center">
                                                    <h5>Username Attendend by the Ip ({detectionObservable[0]?._source?.observables?.source_ip}) </h5>
                                                    {wafUserAttemptedByIpData?.length !== 0 && (
                                                        <Badge className="ml-2" bg="info">
                                                            {wafUserAttemptedByIpData?.length}
                                                        </Badge>
                                                    )}
                                                    <p>
                                                        Linking usernames with specific IP addresses plays a vital role in pinpointing the origins of potentially malicious
                                                        activities. This association facilitates the comprehension of user activity patterns and assists in the
                                                        investigation of any suspicious behavior connected to particular IP addresses.
                                                    </p>
                                                </div>
                                                {firewallToIpLoad ? (<TableLoader rowCount={4} />) : (
                                                    <div>
                                                        {
                                                            wafUserAttemptedByIpData?.length === 0 ? (
                                                                <NoDataFound errorText={"No data found"} />
                                                            ) : (
                                                                <DataTable
                                                                    tableHead={[
                                                                        //   { value: "sl_no", label: "Sl No" },
                                                                        { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                        { value: "user_name", label: "User Name", isMonospace: true },
                                                                        { value: "dest_port", label: "Destination Port" },
                                                                        { value: "date", label: "Date", isMonospace: true },
                                                                        { value: "time", label: "Time", isMonospace: true },
                                                                        { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                    ]}
                                                                    link={[{ name: 'source_ip', url: "https://www.virustotal.com/gui/ip-address/", label: "Source IP" }]}
                                                                    tableData={wafUserAttemptedByIpData}
                                                                    title={"Username Attendend by the Ip "}
                                                                    // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("l7fw_user_attempt_by_ip") && obj.description).map(obj => obj?.description)}

                                                                    pagination={wafUserAttemptedByIpData.length === 0 ? false : true}
                                                                    isFilter={false}
                                                                />
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </>
                                )}



                            </div >}

                        {
                            checkitemscontains(waf_items) && wafTrafficFromDetailedData.length > 0 || wafThreatDetectedIpData.length > 0 || wafUserAttemptedIpData.length > 0 && <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <div className="border-bottom">
                                    <h4>Investigation on WAF</h4>
                                    <p>
                                        Detailed automated investigation by CYBOT™ about the Host & User
                                        which executed the suspected activity
                                    </p>
                                </div>

                                {/* <div className="col-12 rounded-3 p-4 border deletion-file-container">
            <DataTable
              tableHead={[
                { value: "url", label: "URL" },
                { value: "count", label: "Count" },
              ]}
              tableData={wafTrafficFromSummaryData}
              title={"WAF from IP Summary"}
              description={
                ""
              }
              pagination={true}
              isFilter={false}
            />
          </div> */}

                                {wafTrafficFromDetailedData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_waf") && obj.traffic_from_ip_on_waf === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>WAF from IP</h5>
                                                {wafTrafficFromDetailedData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {wafTrafficFromDetailedData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                In this investigation, we examine web application firewall (WAF) events that have originated from the
                                                identified IP address in the last 24 hours.
                                                This analysis is crucial for evaluating the security of web applications. By detecting and studying WAF events,
                                                we can uncover potential vulnerabilities and ongoing attacks targeting web services, enabling timely mitigation
                                                and enhanced security measures.
                                            </p>
                                            {wafFromIpLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        wafTrafficFromDetailedData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}
                                                                tableHead={[
                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                    { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                    { value: "dest_port", label: "Destination Port", isMonospace: true },
                                                                    { value: "url", label: "URL" },
                                                                    // { value: "timestamp", label: "Timestamp" },
                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                link={[{ name: 'url', url: "https://www.virustotal.com/gui/domain/", label: "URL" }]}
                                                                tableData={wafTrafficFromDetailedData}
                                                                title={"WAF from IP"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("traffic_from_ip_on_waf") && obj.description).map(obj => obj?.description)}

                                                                pagination={wafTrafficFromDetailedData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}

                                {wafThreatDetectedIpData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_threat_detection_on_ip") && obj.waf_threat_detection_on_ip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>WAF Threat Detected IP</h5>
                                                {wafThreatDetectedIpData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {wafThreatDetectedIpData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                This investigation focuses on identifying threats detected by the Web Application Firewall (WAF) associated
                                                with the identified IP address within the last 24 hours.
                                                The goal is to pinpoint potential malicious sources attempting to exploit web applications. By promptly
                                                detecting and addressing threats originating from specific IPs, security teams can implement immediate
                                                blocking or mitigation measures to uphold web application security.

                                            </p>
                                            {wafThreatIpLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        wafThreatDetectedIpData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}
                                                                tableHead={[
                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                    { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                    { value: "dest_port", label: "Destination Port", isMonospace: true },
                                                                    { value: "threat_name", label: "Threat Name" },
                                                                    { value: "threat", label: "Threat" },
                                                                    // { value: "timestamp", label: "Timestamp" },
                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                link={[{ name: 'url', url: "https://www.virustotal.com/gui/domain/", label: "URL" }]}
                                                                tableData={wafThreatDetectedIpData}
                                                                title={"WAF Threat Detected IP"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_threat_detection_on_ip") && obj.description).map(obj => obj?.description)}

                                                                pagination={wafThreatDetectedIpData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}




                                {wafUserAttemptedIpData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_user_attempt_by_ip") && obj.waf_user_attempt_by_ip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>WAF Username Attempted by the IP</h5>
                                                {wafUserAttemptedIpData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {wafUserAttemptedIpData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                In this investigation, we examine whether the identified IP address made attempts to access specific
                                                usernames in the Web Application Firewall (WAF) within the last 24 hours.
                                                The objective is to detect any username-related activities from specific IPs, which can signal potential
                                                credential stuffing or brute force attacks. Keeping an eye on these attempts is crucial for preventing
                                                unauthorized access and safeguarding sensitive data.

                                            </p>
                                            {wafUserAttemptedIpLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        wafUserAttemptedIpData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}
                                                                tableHead={[
                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                    { value: "dest_ip", label: "Destination IP", isMonospace: true },
                                                                    { value: "user_name", label: "User Name" },
                                                                    // { value: "url", label: "URL" },
                                                                    // { value: "timestamp", label: "Timestamp" },
                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                tableData={wafUserAttemptedIpData}
                                                                title={"WAF Username Attempted by the IP"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("waf_user_attempt_by_ip") && obj.description).map(obj => obj?.description)}

                                                                pagination={wafUserAttemptedIpData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}



                            </div>
                        }


                        {
                            checkitemscontains(email_items) && <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <div className="border-bottom">
                                    <h4>Investigation on Email</h4>
                                    <p>
                                        Detailed automated investigation by CYBOT™ about the suspicious email activities
                                    </p>
                                </div>
                                {getMailsSendToEmailIdData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_to") && obj.mail_send_to === 'true').length > 0 && (
                                            <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                <div className="header d-flex align-items-center">
                                                    <h5>Emails Sent by the Detected Email Recipient ({detectionObservable[0]?._source?.observables?.receiver_address})</h5>
                                                    {getMailsSendToEmailIdData?.length !== 0 && (
                                                        <Badge className="ml-2" bg="info">
                                                            {getMailsSendToEmailIdData?.length}
                                                        </Badge>
                                                    )}
                                                </div>
                                                <p>
                                                    This investigation centers on the examination of emails sent by an identified email recipient in the past 24
                                                    hours.
                                                    The primary aim is to analyze their communication patterns, with the potential to uncover any participation in
                                                    suspicious activities. Through the scrutiny of these emails, the widget can aid in identifying whether the user is
                                                    receiving or forwarding malicious content, ultimately assisting in the detection and containment of potential
                                                    threats.

                                                </p>
                                                {
                                                    getMailsSendToEmailIdLoad ? (<TableLoader rowCount={4} />) : (
                                                        <div>
                                                            {
                                                                getMailsSendToEmailIdData?.length === 0 ? (
                                                                    <NoDataFound errorText={"No data found"} />
                                                                ) : (
                                                                    <DataTable
                                                                        // tableHead={[
                                                                        //   "Sl No",
                                                                        //   "Destination IP",
                                                                        //   "Destination Port",
                                                                        //   "Destination Country",
                                                                        //   "Timestamp",
                                                                        // ]}
                                                                        tableHead={[
                                                                            //   { value: "sl_no", label: "Sl No" },
                                                                            { value: "date", label: "Date", isMonospace: true },
                                                                            { value: "time", label: "Time", isMonospace: true },
                                                                            { value: "direction", label: "Direction" },
                                                                            { value: "org_level_action", label: "Org level action" },
                                                                            { value: "subject", label: "Subject" },
                                                                            { value: "email_from_address", label: "Sender email address" },
                                                                            { value: "email_receiver_address", label: "Recipient email address" },
                                                                            { value: "delivery_action", label: "Delivery action" },
                                                                            { value: "delivery_location", label: "Delivery location" },
                                                                            { value: "sender_name", label: "Sender name" },
                                                                            { value: "properties_SenderFromDomain", label: "Properties Sender From Domain" },
                                                                            { value: "sender_ipv4", label: "Sender ipv4" },
                                                                            { value: "url_count", label: "URL count" },
                                                                            { value: "threat_name", label: "Threat name" },
                                                                            { value: "threat_type", label: "Threat type" },
                                                                            { value: "properties_AuthenticationDetails", label: "Authentication Details" },
                                                                            { value: "properties_LatestDeliveryAction", label: "Latest Delivery Action" },
                                                                            { value: "properties_LatestDeliveryLocation", label: "Latest Delivery Location" },
                                                                            { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                        ]}
                                                                        tableData={getMailsSendToEmailIdData}
                                                                        title={"Emails Sent by the Detected Email Recipient"}
                                                                        // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_to") && obj.description).map(obj => obj?.description)}

                                                                        pagination={getMailsSendToEmailIdData.length === 0 ? false : true}
                                                                        isFilter={false}
                                                                    />
                                                                )}
                                                        </div>
                                                    )
                                                }
                                            </div >
                                        )
                                        }
                                    </>
                                )}



                                {getMailsSendFromEmailIdData?.length > 0 && (
                                    <>
                                        {
                                            investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_from") && obj.mail_send_from === 'true').length > 0 && (
                                                <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                                    <div className="header d-flex align-items-center">
                                                        <h5>Emails sent by the detected email sender ({detectionObservable[0]?._source?.observables?.from_address})</h5>
                                                        {getMailsSendFromEmailIdData?.length !== 0 && (
                                                            <Badge className="ml-2" bg="info">
                                                                {getMailsSendFromEmailIdData?.length}
                                                            </Badge>
                                                        )}
                                                    </div>
                                                    <p>
                                                        In this investigation, the focus is on analyzing emails sent by an identified email sender in the past 24 hours
                                                        The primary goal is to identify and investigate potential phishing or malicious email campaigns. By singling out
                                                        and scrutinizing emails from this sender, it enables the analysis of their content and attachments, ultimately
                                                        aiding in the detection and mitigation of email-based threats.

                                                    </p>
                                                    {
                                                        getMailsSendFromEmailIdLoad ? (<TableLoader rowCount={4} />) : (
                                                            <div>
                                                                {
                                                                    getMailsSendFromEmailIdData?.length === 0 ? (
                                                                        <NoDataFound errorText={"No data found"} />
                                                                    ) : (
                                                                        <DataTable
                                                                            // tableHead={[
                                                                            //   "Sl No",
                                                                            //   "Destination IP",
                                                                            //   "Destination Port",
                                                                            //   "Destination Country",
                                                                            //   "Timestamp",
                                                                            // ]}
                                                                            tableHead={[
                                                                                { value: "date", label: "Date", isMonospace: true },
                                                                                { value: "time", label: "Time", isMonospace: true },
                                                                                { value: "direction", label: "Direction" },
                                                                                { value: "org_level_action", label: "Org level action" },
                                                                                { value: "subject", label: "Subject" },
                                                                                { value: "email_from_address", label: "Sender email address" },
                                                                                { value: "email_receiver_address", label: "Recipient email address" },
                                                                                { value: "delivery_action", label: "Delivery action" },
                                                                                { value: "delivery_location", label: "Delivery location" },
                                                                                { value: "sender_name", label: "Sender name" },
                                                                                { value: "properties_SenderFromDomain", label: "Properties Sender From Domain" },
                                                                                { value: "sender_ipv4", label: "Sender ipv4" },
                                                                                { value: "url_count", label: "URL count" },
                                                                                { value: "threat_name", label: "Threat name" },
                                                                                { value: "threat_type", label: "Threat type" },
                                                                                { value: "properties_AuthenticationDetails", label: "Authentication Details" },
                                                                                { value: "properties_LatestDeliveryAction", label: "Latest Delivery Action" },
                                                                                { value: "properties_LatestDeliveryLocation", label: "Latest Delivery Location" },
                                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                            ]}
                                                                            tableData={getMailsSendFromEmailIdData}
                                                                            title={"Emails sent by the detected email sender"}
                                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("mail_send_from") && obj.description).map(obj => obj?.description)}

                                                                            pagination={getMailsSendFromEmailIdData.length === 0 ? false : true}
                                                                            isFilter={false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        )
                                                    }
                                                </div >
                                            )
                                        }
                                    </>
                                )}


                            </div >}


                        {/* active directory */}

                        {
                            checkitemscontains(ad_items) && <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                                <div className="border-bottom">
                                    <h4>Investigation on Active Directory</h4>
                                    <p>
                                        Detailed automated investigation by CYBOT™ about the Host & User
                                        which executed the suspected activity
                                    </p>
                                </div>


                                {userManagementActivityByUserAdData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_user_management_activity") && obj.ad_user_management_activity === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>User management activity by the user</h5>
                                                {userManagementActivityByUserAdData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {userManagementActivityByUserAdData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                This investigation focuses on monitoring user management activities initiated by a specific user within the last 24 hours. The
                                                purpose is to keep tabs on privilege changes and potential misuse of user accounts.
                                                User management activity analysis by a security analyst can reveal several threats, including unauthorized access, privilege
                                                escalation, insider threats, account compromises, data exfiltration, policy violations, social engineering attempts, account
                                                lockouts, security misconfigurations, privilege abuse, questionable account creation, and inactive account risks. This
                                                monitoring helps safeguard against various security vulnerabilities and potential malicious actions.

                                            </p>
                                            {userManagementActivityByUserAdLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        userManagementActivityByUserAdData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}

                                                                tableHead={[
                                                                    { value: "date", label: "Date", isMonospace: true },
                                                                    { value: "time", label: "Time", isMonospace: true },
                                                                    { value: "event_action", label: "Event Action" },
                                                                    { value: "event_category", label: "Event Category" },
                                                                    { value: "event_code", label: "Event code" },
                                                                    { value: "event_outcome", label: "Event Outcome" },
                                                                    { value: "event_type", label: "Event Type" },
                                                                    { value: "host_name", label: "Host Name" },
                                                                    { value: "host_os_name", label: "Host OS Name" },
                                                                    { value: "host_id", label: "Host ID" },
                                                                    { value: "host_ip", label: "Host IP" },
                                                                    { value: "host_mac", label: "Host Mac" },
                                                                    { value: "log_level", label: "Log Level" },
                                                                    { value: "message", label: "Message" },
                                                                    { value: "related_user", label: "Related User" },
                                                                    { value: "user_domain", label: "User Domain" },
                                                                    { value: "user_id", label: "User ID" },
                                                                    { value: "user_name", label: "User Name" },
                                                                    { value: "user_target_domain", label: "User Target Domain" },
                                                                    { value: "user_target_id", label: "User Target ID" },
                                                                    { value: "user_target_name", label: "User Target Name" },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/", label: "Target " }]}
                                                                tableData={userManagementActivityByUserAdData}
                                                                title={"User management activity by the user"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_user_management_activity") && obj.description).map(obj => obj?.description)}

                                                                pagination={userManagementActivityByUserAdData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}



                                {recentAuthByUserAdData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_by_user") && obj.ad_recent_authentication_by_user === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>Recent Authentications by the user</h5>
                                                {recentAuthByUserAdData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {recentAuthByUserAdData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                This investigation focuses on reviewing all authentication events by a particular user over the past 30 days.
                                                Monitoring recent authentications by a user is crucial for identifying various security threats, including credential compromise,
                                                brute force attacks, account hijacking, insider threats, phishing attacks, shared credentials, account lockouts, unusual device
                                                or IP activity, unusual access times, third-party application risks, credential stuffing, password policy violations, and
                                                unauthorized account creations. This proactive monitoring helps detect and respond to security risks promptly, strengthening
                                                overall security.

                                            </p>
                                            {recentAuthByUserAdLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        recentAuthByUserAdData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}
                                                                tableHead={[
                                                                    //   { value: "sl_no", label: "Sl No" },
                                                                    { value: "date", label: "Date", isMonospace: false },
                                                                    { value: "time", label: "Time", isMonospace: false },
                                                                    { value: "user_name", label: "Username", isMonospace: false },
                                                                    { value: "event_category", label: "Event Category", isMonospace: false },
                                                                    { value: "event_action", label: "Event Action", isMonospace: false },
                                                                    { value: "event_code", label: "Event Code", isMonospace: false },
                                                                    { value: "event_outcome", label: "Event Outcome", isMonospace: false },
                                                                    { value: "event_type", label: "Event type" },
                                                                    { value: "logon_type", label: "Logon type" },
                                                                    { value: "host_name", label: "host Name", isMonospace: false },
                                                                    { value: "host_os_name", label: "Host OS Name", isMonospace: false },
                                                                    { value: "source_ip", label: "Source IP" },
                                                                    { value: "source_domain", label: "Source Domain" },
                                                                    { value: "source_port", label: "Source Port" },
                                                                    { value: "failure_status", label: "Failure Status" },
                                                                    { value: "failure_sub_status", label: "Failure Sub Status" },
                                                                    { value: "failure_reason", label: "Failure Reason" },
                                                                    { value: "authentication_package_name", label: "Authentication Package Name" },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                                tableData={recentAuthByUserAdData}
                                                                title={"Recent Authentications by the user"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_by_user") && obj.description).map(obj => obj?.description)}

                                                                pagination={recentAuthByUserAdData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}



                                {recentAuthByHostAdData?.length > 0 && (
                                    <>
                                        {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_in_host") && obj.ad_recent_authentication_in_host === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                            <div className="header d-flex align-items-center">
                                                <h5>Recent Authentications in the host machine</h5>
                                                {recentAuthByHostAdData?.length !== 0 && (
                                                    <Badge className="ml-2" bg="info">
                                                        {recentAuthByHostAdData?.length}
                                                    </Badge>
                                                )}
                                            </div>
                                            <p>
                                                This investigation focuses on all authentication events on the detected host during the past 30 days.
                                                Analysing recent authentications on a host machine helps identify various security threats, including unauthorized access,
                                                brute force attacks, account compromise, account hijacking, insider threats, phishing attacks, shared credentials, account
                                                lockouts, unusual device or IP activity, unusual access times, credential stuffing, weak passwords, third-party application risks,
                                                and unauthorized account creations. This proactive investigation enhances security by detecting and responding to potential
                                                threats promptly.

                                            </p>
                                            {recentAuthByHostAdLoad ? (<TableLoader rowCount={4} />) : (
                                                <div>
                                                    {
                                                        recentAuthByHostAdData?.length === 0 ? (
                                                            <NoDataFound errorText={"No data found"} />
                                                        ) : (
                                                            <DataTable
                                                                // tableHead={[
                                                                //   "Sl No",
                                                                //   "Destination IP",
                                                                //   "Destination Port",
                                                                //   "URL",
                                                                //   "Timestamp",
                                                                // ]}
                                                                tableHead={[
                                                                    { value: "date", label: "Date", isMonospace: false },
                                                                    { value: "time", label: "Time", isMonospace: false },
                                                                    { value: "user_name", label: "Username", isMonospace: false },
                                                                    { value: "event_category", label: "Event Category", isMonospace: false },
                                                                    { value: "event_action", label: "Event Action", isMonospace: false },
                                                                    { value: "event_code", label: "Event Code", isMonospace: false },
                                                                    { value: "event_outcome", label: "Event Outcome", isMonospace: false },
                                                                    { value: "event_type", label: "Event type" },
                                                                    { value: "logon_type", label: "Logon type" },
                                                                    { value: "host_name", label: "host Name", isMonospace: false },
                                                                    { value: "host_os_name", label: "Host OS Name", isMonospace: false },
                                                                    { value: "source_ip", label: "Source IP" },
                                                                    { value: "source_domain", label: "Source Domain" },
                                                                    { value: "source_port", label: "Source Port" },
                                                                    { value: "failure_status", label: "Failure Status" },
                                                                    { value: "failure_sub_status", label: "Failure Sub Status" },
                                                                    { value: "failure_reason", label: "Failure Reason" },
                                                                    { value: "authentication_package_name", label: "Authentication Package Name" },
                                                                    { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                                ]}
                                                                // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                                tableData={recentAuthByHostAdData}
                                                                title={"Recent Authentications in the host machine"}
                                                                // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("ad_recent_authentication_in_host") && obj.description).map(obj => obj?.description)}

                                                                pagination={recentAuthByHostAdData.length === 0 ? false : true}
                                                                isFilter={false}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>}
                                    </>
                                )}



                            </div>
                        }

                        {/* o365 */}

                        {checkitemscontains(o365_items) && <div className="col-12 d-flex flex-column gap-3 rounded-3 p-4 recent-file-container bg-white">
                            <div className="border-bottom">
                                <h4>Investigation on Office 365</h4>
                                <p>
                                    Detailed automated investigation by CYBOT™ on the Office 365 which executed the suspected activity
                                </p>
                            </div>

                            {recentAuthInO365ClientData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_in_o365_clientip") && obj.auth_in_o365_clientip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Authentication in O365</h5>
                                            {recentAuthInO365ClientData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentAuthInO365ClientData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        \
                                        <p>
                                            An investigation within the Data Lake was conducted to analyze recent authentication events in the Office 365 environment. This analysis spans a 24-hour duration before and after the detection event. This investigative approach is crucial for detecting potential security threats, such as unusual login patterns, unauthorized access attempts, or compromised user accounts. For instance, a sudden surge in login attempts from multiple IP addresses within a short period could indicate a coordinated security breach attempt.
                                        </p>
                                        {recentAuthInO365ClientLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentAuthInO365ClientData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User ID" },
                                                                { value: "UserType", label: "User Type" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "request_type", label: "Request Type" },
                                                                { value: "result_status", label: "Result Status" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "AccountDisplayName", label: "Account Display Name" },
                                                                { value: "device_os", label: "Device OS" },
                                                                { value: "BrowserType", label: "Browser Type" },
                                                                { value: "compliant_and_managed", label: "Compliant And Managed" },
                                                                { value: "TrustType", label: "Trust Type" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "KeepMeSignedIn", label: "Keep Me SignedIn" },
                                                                { value: "UserAuthenticationMethod", label: "User Authentication Method" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentAuthInO365ClientData}
                                                            title={"Recent Authentication in O365"}
                                                            description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_in_o365_clientip") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentAuthInO365ClientData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {recentAuthInO365UserData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_in_o365_userid") && obj.auth_in_o365_userid === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Authentication in O365</h5>
                                            {recentAuthInO365UserData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentAuthInO365UserData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake was conducted to analyze recent authentication events in the Office 365 environment. This analysis spans a 24-hour duration before and after the detection event. This investigative approach is crucial for detecting potential security threats, such as unusual login patterns, unauthorized access attempts, or compromised user accounts. For instance, a sudden surge in login attempts from multiple IP addresses within a short period could indicate a coordinated security breach attempt.
                                        </p>
                                        {recentAuthInO365UserLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentAuthInO365UserData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User ID" },
                                                                { value: "UserType", label: "User Type" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "request_type", label: "Request Type" },
                                                                { value: "result_status", label: "Result Status" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "AccountDisplayName", label: "Account Display Name" },
                                                                { value: "device_os", label: "Device OS" },
                                                                { value: "BrowserType", label: "Browser Type" },
                                                                { value: "compliant_and_managed", label: "Compliant And Managed" },
                                                                { value: "TrustType", label: "Trust Type" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "KeepMeSignedIn", label: "Keep Me SignedIn" },
                                                                { value: "UserAuthenticationMethod", label: "User Authentication Method" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentAuthInO365UserData}
                                                            title={"Recent Authentication in O365"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("auth_in_o365_userid") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentAuthInO365UserData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}


                            {recentOperationSharePointUserData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("sharepoint_in_o365_userid") && obj.sharepoint_in_o365_userid === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in SharePoint by User</h5>
                                            {recentOperationSharePointUserData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationSharePointUserData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake has been conducted to uncover recent user actions in SharePoint over the past 24 hours, surrounding the detection event. This examination is vital for detecting unusual or unauthorized activities within SharePoint, including file deletions, sharing actions, or changes to access permissions. Identifying these recent operations is instrumental in maintaining data security, preventing data loss, and ensuring compliance.
                                        </p>
                                        {recentOperationSharePointUserLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationSharePointUserData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable

                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "ClientAppName", label: "App Name" },
                                                                { value: "ObjectId", label: "Object Id" },
                                                                { value: "ItemType", label: "Item Type" },
                                                                { value: "SourceFileName", label: "File Name" },
                                                                { value: "SourceFileExtension", label: "File Extension" },
                                                                { value: "SiteUrl", label: "Site Url" },
                                                                { value: "SourceRelativeUrl", label: "Relative Url" },
                                                                { value: "AuthenticationType", label: "Authentication Type" },
                                                                { value: "DeviceDisplayName", label: "Device Display Name" },
                                                                { value: "IsManagedDevice", label: "Managed Device" },
                                                                { value: "Platform", label: "Platform" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationSharePointUserData}
                                                            title={"Recent Operations in SharePoint by User"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("sharepoint_in_o365_userid") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationSharePointUserData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {recentOperationSharePointClientData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("sharepoint_in_o365_clientip") && obj.sharepoint_in_o365_clientip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in SharePoint by User</h5>
                                            {recentOperationSharePointClientData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationSharePointClientData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake has been conducted to uncover recent user actions in SharePoint over the past 24 hours, surrounding the detection event. This examination is vital for detecting unusual or unauthorized activities within SharePoint, including file deletions, sharing actions, or changes to access permissions. Identifying these recent operations is instrumental in maintaining data security, preventing data loss, and ensuring compliance.
                                        </p>
                                        {recentOperationSharePointClientLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationSharePointClientData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "ClientAppName", label: "App Name" },
                                                                { value: "ObjectId", label: "Object Id" },
                                                                { value: "ItemType", label: "Item Type" },
                                                                { value: "SourceFileName", label: "File Name" },
                                                                { value: "SourceFileExtension", label: "File Extension" },
                                                                { value: "SiteUrl", label: "Site Url" },
                                                                { value: "SourceRelativeUrl", label: "Relative Url" },
                                                                { value: "AuthenticationType", label: "Authentication Type" },
                                                                { value: "DeviceDisplayName", label: "Device Display Name" },
                                                                { value: "IsManagedDevice", label: "Managed Device" },
                                                                { value: "Platform", label: "Platform" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationSharePointClientData}
                                                            title={"Recent Operations in SharePoint by User"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("sharepoint_in_o365_clientip") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationSharePointClientData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {/*  */}

                            {recentOperationOnedriveUserData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("onedrive_in_o365_userid") && obj.onedrive_in_o365_userid === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in OneDrive</h5>
                                            {recentOperationOnedriveUserData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationOnedriveUserData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake was conducted to uncover recent operations within the Office 365 OneDrive environment. This analysis offers insights into file movements, access changes, and potential data leaks within individual OneDrive accounts. Monitoring such activities allows for the identification of insider threats, data exposure risks, and compliance violations, ensuring data security and regulatory adherence.
                                        </p>
                                        {recentOperationOnedriveUserLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationOnedriveUserData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable

                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "ClientAppName", label: "App Name" },
                                                                { value: "ObjectId", label: "Object Id" },
                                                                { value: "ItemType", label: "Item Type" },
                                                                { value: "SourceFileName", label: "File Name" },
                                                                { value: "SourceFileExtension", label: "File Extension" },
                                                                { value: "SiteUrl", label: "Site Url" },
                                                                { value: "SourceRelativeUrl", label: "Relative Url" },
                                                                { value: "AuthenticationType", label: "Authentication Type" },
                                                                { value: "DeviceDisplayName", label: "Device Display Name" },
                                                                { value: "IsManagedDevice", label: "Managed Device" },
                                                                { value: "Platform", label: "Platform" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationOnedriveUserData}
                                                            title={"Recent Operations in OneDrive"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("onedrive_in_o365_userid") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationOnedriveUserData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {recentOperationOnedriveClientData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("onedrive_in_o365_clientip") && obj.onedrive_in_o365_clientip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in OneDrive</h5>
                                            {recentOperationOnedriveClientData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationOnedriveClientData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake was conducted to uncover recent operations within the Office 365 OneDrive environment. This analysis offers insights into file movements, access changes, and potential data leaks within individual OneDrive accounts. Monitoring such activities allows for the identification of insider threats, data exposure risks, and compliance violations, ensuring data security and regulatory adherence.
                                        </p>
                                        {recentOperationOnedriveClientLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationOnedriveClientData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "ClientIP", label: "Client IP" },
                                                                { value: "ClientAppName", label: "App Name" },
                                                                { value: "ObjectId", label: "Object Id" },
                                                                { value: "ItemType", label: "Item Type" },
                                                                { value: "SourceFileName", label: "File Name" },
                                                                { value: "SourceFileExtension", label: "File Extension" },
                                                                { value: "SiteUrl", label: "Site Url" },
                                                                { value: "SourceRelativeUrl", label: "Relative Url" },
                                                                { value: "AuthenticationType", label: "Authentication Type" },
                                                                { value: "DeviceDisplayName", label: "Device Display Name" },
                                                                { value: "IsManagedDevice", label: "Managed Device" },
                                                                { value: "Platform", label: "Platform" },
                                                                { value: "UserAgent", label: "User Agent" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationOnedriveClientData}
                                                            title={"Recent Operations in OneDrive"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("onedrive_in_o365_clientip") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationOnedriveClientData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {/*  */}

                            {recentOperationAzureAdUserData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("azure_ad_in_o365_userid") && obj.azure_ad_in_o365_userid === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in AzureAD</h5>
                                            {recentOperationAzureAdUserData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationAzureAdUserData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake has been conducted focusing on recent activities within Azure Active Directory (AzureAD) over the last 24 hours, centered around the detection event. Analyzing these operations is critical for identifying identity-related threats, such as unauthorized access or privilege escalations. This analysis allows us to identify and address potential security vulnerabilities within our AzureAD environment.
                                        </p>
                                        {recentOperationAzureAdUserLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationAzureAdUserData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable

                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "extendedAuditEventCategory", label: "Audit Event Category" },
                                                                { value: "UserType", label: "User Type" },
                                                                { value: "result_status", label: "Result Status" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "additionalDetails", label: "Additional Details" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationAzureAdUserData}
                                                            title={"Recent Operations in AzureAD"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("azure_ad_in_o365_userid") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationAzureAdUserData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {recentOperationAzureAdClientData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("azure_ad_in_o365_clientip") && obj.azure_ad_in_o365_clientip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in AzureAD</h5>
                                            {recentOperationAzureAdClientData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationAzureAdClientData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake has been conducted focusing on recent activities within Azure Active Directory (AzureAD) over the last 24 hours, centered around the detection event. Analyzing these operations is critical for identifying identity-related threats, such as unauthorized access or privilege escalations. This analysis allows us to identify and address potential security vulnerabilities within our AzureAD environment.
                                        </p>
                                        {recentOperationAzureAdClientLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationAzureAdClientData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "UserId", label: "User Id" },
                                                                { value: "extendedAuditEventCategory", label: "Audit Event Category" },
                                                                { value: "UserType", label: "User Type" },
                                                                { value: "result_status", label: "Result Status" },
                                                                { value: "source_country", label: "Source Country" },
                                                                { value: "additionalDetails", label: "Additional Details" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },

                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationAzureAdClientData}
                                                            title={"Recent Operations in AzureAD"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("azure_ad_in_o365_clientip") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationAzureAdClientData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {/*  */}

                            {recentOperationExchangeUserData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("exchange_in_o365_userid") && obj.exchange_in_o365_userid === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in Exchange</h5>
                                            {recentOperationExchangeUserData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationExchangeUserData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake was conducted to analyze recent operations within the Office 365 Exchange environment. This analysis spans a 24-hour duration before and after the detection event. Examining these operations is essential for detecting email-based threats, including phishing attempts, suspicious email forwarding, and unauthorized mailbox access. Analyzing these recent operations enables the safeguarding of email communications, the maintenance of data integrity, and the bolstering of overall organizational security.
                                        </p>
                                        {recentOperationExchangeUserLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationExchangeUserData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "MailboxOwnerUPN", label: "Mail Box Owner UPN" },
                                                                { value: "Subject", label: "Subject" },
                                                                { value: "Attachments", label: "Attachments" },
                                                                { value: "ParentFolder", label: "Parent Folder" },
                                                                { value: "ClientIPAddress", label: "Client IP Address" },
                                                                { value: "host_os_name", label: "Host OS Name" },
                                                                { value: "ClientInfoString", label: "Client Info String" },
                                                                { value: "ExternalAccess", label: "External Access" },
                                                                { value: "LogonType", label: "Logon Type" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },


                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationExchangeUserData}
                                                            title={"Recent Operations in Exchange"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("exchange_in_o365_userid") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationExchangeUserData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}

                            {recentOperationExchangeClientData?.length > 0 && (
                                <>
                                    {investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("exchange_in_o365_clientip") && obj.exchange_in_o365_clientip === 'true').length > 0 && <div className="col-12 rounded-3 p-4 border deletion-file-container">
                                        <div className="header d-flex align-items-center">
                                            <h5>Recent Operations in Exchange</h5>
                                            {recentOperationExchangeClientData?.length !== 0 && (
                                                <Badge className="ml-2" bg="info">
                                                    {recentOperationExchangeClientData?.length}
                                                </Badge>
                                            )}
                                        </div>
                                        <p>
                                            An investigation within the Data Lake was conducted to analyze recent operations within the Office 365 Exchange environment. This analysis spans a 24-hour duration before and after the detection event. Examining these operations is essential for detecting email-based threats, including phishing attempts, suspicious email forwarding, and unauthorized mailbox access. Analyzing these recent operations enables the safeguarding of email communications, the maintenance of data integrity, and the bolstering of overall organizational security.
                                        </p>
                                        {recentOperationExchangeClientLoader ? (<TableLoader rowCount={4} />) : (
                                            <div>
                                                {
                                                    recentOperationExchangeClientData?.length === 0 ? (
                                                        <NoDataFound errorText={"No data found"} />
                                                    ) : (
                                                        <DataTable
                                                            tableHead={[
                                                                { value: "date", label: "Date", isMonospace: true },
                                                                { value: "time", label: "Time", isMonospace: true },
                                                                { value: "Operation", label: "Operation" },
                                                                { value: "MailboxOwnerUPN", label: "Mail Box Owner UPN" },
                                                                { value: "Subject", label: "Subject" },
                                                                { value: "Attachments", label: "Attachments" },
                                                                { value: "ParentFolder", label: "Parent Folder" },
                                                                { value: "ClientIPAddress", label: "Client IP Address" },
                                                                { value: "host_os_name", label: "Host OS Name" },
                                                                { value: "ClientInfoString", label: "Client Info String" },
                                                                { value: "ExternalAccess", label: "External Access" },
                                                                { value: "LogonType", label: "Logon Type" },
                                                                { value: "file_hash", label: "Process Hash(Virus Total)" },


                                                            ]}
                                                            // link={[{ name: 'target_user_name', url: "https://www.virustotal.com/gui/ip-address/" }]}
                                                            tableData={recentOperationExchangeClientData}
                                                            title={"Recent Operations in Exchange"}
                                                            // description={investigationDescription?.modules?.filter(obj => obj.hasOwnProperty("exchange_in_o365_clientip") && obj.description).map(obj => obj?.description)}

                                                            pagination={recentOperationExchangeClientData.length === 0 ? false : true}
                                                            isFilter={false}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>}
                                </>
                            )}


                        </div>}

                    </div >)}
                <ToastContainer />

                {/* permision alert */}
                <Modal show={checkPermisionModalVisibility} onHide={() => setCheckPermisionModalVisibility(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Do you want to proceed?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h6>You’re about to search the below keyword across the logs in cykit for hunter</h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                            <span>Keyword</span>
                            <input
                                style={{ marginTop: '4px' }}
                                type="text"
                                value={inputValue}
                                onChange={handleCykitInputChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCheckPermisionModalVisibility(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={redirectToCykit}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>


                {
                    show && (
                        <CustomModal
                            title={detectionStatusCheck === 'closed' ? "Close Detection" : "Open Detection"}
                            ShowOrHide={show}
                            bodyData={renderModal}
                            handleClose={handleCloseModal}
                            modalSize={"md"}
                            footer={false}
                            downloadBtn={false}
                            noBodyPadding={true}
                            overflowHidden={true}
                            modalPosition={"right"}
                            outsideClickDisabled={true}
                        ></CustomModal>
                    )
                }

                {/* repetation modal */}
                {
                    exceptionShow && (
                        <CustomModal
                            title={"Add Exception"}
                            ShowOrHide={exceptionShow}
                            bodyData={renderExceptionModal}
                            handleClose={handleCloseModal}
                            modalSize={"md"}
                            footer={false}
                            downloadBtn={false}
                            noBodyPadding={true}
                            overflowHidden={true}
                            modalPosition={"right"}
                            outsideClickDisabled={true}
                            minimizeBtn={true}
                        // autoHeight
                        ></CustomModal>
                    )
                }

                {
                    caseshow && (
                        <EditModal
                            setShow={setCaseShow}
                            show={caseshow}
                            autoHeight={true}
                            caseidEdit={case_id}
                            setCase_ID={setCase_ID}
                            id={investigationConclusions?.["Alert Name"]}
                            idd={hunt_detection_id}
                            module={module}
                            tab={tab}
                            preModule={"INV-DET"}
                            description_={removeHtmlChars(investigationConclusions?.["Alert Description"])}
                        />
                    )
                }

                {/* Respond */}
                {
                    respondShow && (
                        <CustomModal
                            title={"Respond"}
                            ShowOrHide={respondShow}
                            bodyData={renderRespondModal}
                            handleClose={handleCloseModal}
                            modalSize={"md"}
                            footer={false}
                            downloadBtn={false}
                            noBodyPadding={true}
                            overflowHidden={true}
                            modalPosition={"right"}
                            outsideClickDisabled={true}
                        ></CustomModal>
                    )
                }
                {/* {console.log("INVID", invId)} */}
                {
                    detailShow && (
                        invId != null && (
                            <InvDetailModal
                                setDetailShow={setDetailShow}
                                detailShow={detailShow}
                                setInvId={setInvId}
                                invIdEdit={invId}
                            />
                        )
                    )}

                {
                    alertPopup && (
                        <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={"Exceptions Added"} bgColor={"lightGreen"} />
                    )
                }

                {/* investigation closed and open alert  */}

                {
                    closeAlertPopup && (
                        <AlertBox setAlertPopup={setCloseAlertPopup} alertPopup={closeAlertPopup} message={closeAlertMessage} bgColor={"lightGreen"} />
                    )
                }

                {
                    addRespondSuccessAlertPopup && (
                        <AlertBox setAlertPopup={setAddRespondSuccessAlertPopup} alertPopup={addRespondSuccessAlertPopup} message={"Respond Added"} bgColor={"lightGreen"} />
                    )
                }

                {
                    addRespondFailedAlertPopup && (
                        <AlertBox setAlertPopup={setAddRespondFailedAlertPopup} alertPopup={addRespondFailedAlertPopup} message={"Respond Failed"} status={"error"} bgColor={"lightGreen"} />
                    )
                }
                <ExpandedDocument title={"Raw Log"} clickedNode={expclickedNodeLog} close={expcloseLog} setClick={setExpClickedNodeLog} url={localStorage.getItem("DATALAKE_URL")} pattern={rawLogIndex !== undefined ? `${rawLogIndex}/_doc/${rawLogId}` : 'logs-*,winlogbeat*/_search?track_total_hits=true'} req={rawLogIndex !== undefined ? fgetCy : fpostCy} index={rawLogIndex} />
            </div >
        </div >
    );
};

export default InvestigationSum;