import {
  mostAnomalusQuery,
  getTotalInvestigationCoutsQuery,
  getSeverityCountQuery,
  getGroupedInvestigationCountsQuery,
  getInvestigationTypeQuery,
  getUsersQuery,
  mostCommonDetectionQuery,
  getTacticsDetectedQuery,
  highPriorityDetectionTableQuery,
  getInvestigationQuery,
  getInvestigationTableQuery,
  getInlineFilterQuery,
  detectedObservablesQuery,
  recentFileQuery,
  processRunByDetectionQuery,
  UncommonProcessesRunDetectedQuery,
  recentAuthQuery,
  reputationQuery,
  trafficDetectedIpQuery,
  hostLoggedByUserQuery,
  repetitionsQuery,
  TotalCountsQuery,
  sourceIPQuery,
  tableDataQuery,
  sourcePortsQuery,
  destinationPortsQuery,
  destinationIpQuery,
  getCountsQuery,
  getWidgetsQuery,
  getCountsAdQuery,
  getEventCountsQuery,
  getAccoundCountsQuery,
  AuthUserChartQuery,
  userManagementActionQuery,
  groupManagementActionQuery,
  getAccoundDataQuery,
  getActiveDirectoryTableQuery,
  getRecentFileOperationsQuery,
  getRecentAuthManagementDashQuery,
  getAccountCreatedQuery,
  getAccountDeletedQuery,
  getAccountPassQuery,
  getAccountActivityQuery,
  getAccountLockedQuery,
  getAccountChangedQuery,
  getFileActivityQuery,
  logFilterQuery,
  fireWallPublicIpInfoOSINTQuery,
  bytesTransferredBetweenIpQuery,
  threatDetectionIpInboundQuery,
  threatDetectionIpOutboundQuery,
  reputationIPQuery,
  getMailsSendToEmailIdQuery,
  getMailsSendFromEmailIdQuery,
  wafTrafficFromIpQuery,
  wafTreatDetectioIpInboundQuery,
  wafUserAttemptedByIpQuery,
  recentAuthByUserAdQuery,
  recentAuthByHostAdQuery,
  userManagementActivityByUserAdQuery,
  getTiWidgetsQuery,
  groupedInvestigationQuery,
  getInvestigationGroupedQuery,
  getDetailsWithHuntDetectionIdQuery,
  getInvestigationAttentionQuery,
  recentAuthInO365Query,
  recentOperationSharepointUserQuery,
  recentOperationOnedriveQuery,
  recentOperationAzureAdQuery,
  recentOperationExchangeQuery,
  SoftwareListQuery,
  totalUniqueVendorCountsQuery,
  totalUniqueSoftwareCountsQuery,
  totalUniqueVendorListQuery,
  softwareTableQuery,
  getHostListQuery,
  uniqueVulnsWithExploitsQuery,
  uniqueHostWithRiskScoreQuery,
  uniqueAssetsCountQuery,
  uniqueVulnsCountQuery,
  vulnsBySeverityQuery,
  vulnsWithExploitListQuery,
  vulnsSeverityAgeQuery,
  vulnsOverTimeQuery,
  vulnsPerHostListQuery,
  vulnsListQuery,
  firewallThreatLandscapeQuery,
  leastHost10query,
  InstalledVendor,
  vulnsSeverityAge90Query,
  scanNameQuery,
  uniqueAssetsSoftCountQuery,
  latestScanQuery
} from "../Queries/InvestigationQuery";
import { fpostC, fpost, fget, fpostDl, fpostCy, fpostD, fgetC } from "../axios/axios";

const baseURL = localStorage.getItem("CYBOT_URL");
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
const INVESTIGATE_CYAD = localStorage.getItem("INVESTIGATE_CYAD");
const INVESTIGATE_CYWAF = localStorage.getItem("INVESTIGATE_CYWAF");
const INVESTIGATE_CY_SOFTWARE = localStorage.getItem("INVESTIGATE_CY_SOFTWARE");
const INVESTIGATE_CY_VULNS = localStorage.getItem("INVESTIGATE_CY_VULNS");
const THREAT_LANDSCAPE_FIREWALL = localStorage.getItem("THREAT_LANDSCAPE_FIREWALL");

let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
const apiBaseUrl = localStorage.getItem("apiBaseUrl")


export async function getDetailsWithHuntDetectionId(hunt_detection_id) {
  let data = getDetailsWithHuntDetectionIdQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`,
    data
  );
}

export async function mostAnomalusDetails(field_name, count) {
  let data = mostAnomalusQuery(field_name, count);

  return await fpostC(
    // `${baseURL}/cybot_proxy/thp_group*/_search?track_total_hits=true`,
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    data
  );
}

// log filter
export async function getLogFilter(log, filter, date, count, page) {
  let data = logFilterQuery(log, filter, date, count, page);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    data
  );
}

export async function getSeverityCounts() {
  let data = getSeverityCountQuery();

  return await fpostC(
    // `${baseURL}/cybot_proxy/thp_group*/_search?track_total_hits=true`,
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    data
  );
}

// L2 severity counts
export async function getL2SeverityCounts() {
  let data = getSeverityCountQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group*/_search?track_total_hits=true`,
    data
  );
}

export async function getTotalInvestigationCouts() {
  let data = getTotalInvestigationCoutsQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables_*/_search?pretty`,
    data
  );
}

export async function getGroupedInvestigationCounts() {
  let data = getGroupedInvestigationCountsQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group*/_search?track_total_hits=true`,
    data
  );
}

export async function getViewDashboardDataTID() {
  let data = getInvestigationTypeQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`,
    data
  );
}

// correlation

export async function getList(field_name) {
  let data = getUsersQuery(field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search`,
    data
  );
}

export async function mostCommonDetection(selectedOption) {
  let data = mostCommonDetectionQuery(selectedOption);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search`,
    data
  );
}

export async function getTacticsDetected(selectedOption) {
  let data = getTacticsDetectedQuery(selectedOption);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search`,
    data
  );
}

export async function highPriorityDetectionTable() {
  let data = highPriorityDetectionTableQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`,
    data
  );
}

// new get detection

export async function getTableData() {
  return await fget(
    `${apiBaseUrl}/investigate/defaults`
  );
}

export async function getInvestigation(tenandId, count, sort, page, isinv) {
  let data = getInvestigationQuery(count, sort, page, isinv);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group_*/_search?track_total_hits=true`,
    data
  );
}
export async function getInvestigationGrouped(tenandId, count, sort, page, filter, date) {
  let data = getInvestigationGroupedQuery(count, sort, page, filter, date);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group_*/_search?track_total_hits=true`,
    data
  );
}
export async function getInvestigationAttention(tenandId, count, sort, page, filter, date) {
  let data = getInvestigationAttentionQuery(count, sort, page, filter, date);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group_*/_search?track_total_hits=true`,
    data
  );
}

export async function getInvestigationTable(count, sort, page) {
  let data = getInvestigationTableQuery(count, sort, page);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    data
  );
}

export async function getInlineFilter(filter_field) {
  let data = getInlineFilterQuery(filter_field);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search?track_total_hits=true`,
    data
  );
}

// repetation dashboard methodds

export async function getRepetitions(host, hash, user, dest_ip, src_ip, detection_name) {
  let data = repetitionsQuery(host, hash, user, dest_ip, src_ip, detection_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search`,
    data
  );
}

// investigation Dashboard Methods

export async function getCybotDashboardDetails(playbook_id) {
  return await fget(
    // `https://analyticsapi.active-bytes.com/invgetalertinfo?invid=${playbook_id}&type=desc`
    `${apiBaseUrl}/investigate/alertinfo?invid=${playbook_id}&type=desc`
  );
}

// get detected observable

export async function detectedObservables(hunt_detection_id) {
  let data = detectedObservablesQuery(hunt_detection_id);

  return await fpostC(`${baseURL}/cybot_proxy/thp_observables*/_search`, data);
}

export async function recentFileCreation(userName, hunt_detection_id, page) {
  let data = recentFileQuery(userName, hunt_detection_id, page);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_recent_file_creations_by_user_in_host*/_search?track_total_hits=true`,
    data
  );
}

export async function recentFileDeletion(userName, hunt_detection_id) {
  let data = recentFileQuery(userName, hunt_detection_id);

  return await fpostC(
    `
  ${baseURL}/cybot_proxy/thp_recent_file_deletions_by_user_in_host*/_search?track_total_hits=true`,
    data
  );
}

export async function processRunByDetectionUser(hunt_detection_id) {
  let data = processRunByDetectionQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_user_ran_processes*/_search?track_total_hits=true`,
    data
  );
}

export async function processRunByDetectionHost(hunt_detection_id) {
  let data = processRunByDetectionQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_host_ran_processes*/_search?track_total_hits=true`,
    data
  );
}

export async function UncommonProcessesRunDetectedUser(hunt_detection_id) {
  let data = UncommonProcessesRunDetectedQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_user_ran_processes*/_search?track_total_hits=true`,
    data
  );
}

export async function hostsLoggedByUser(hunt_detection_id) {
  let data = hostLoggedByUserQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_user_logged_hosts*/_search?track_total_hits=true`,
    data
  );
}

export async function recentAuthHost(hunt_detection_id) {
  let data = recentAuthQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_recent_authentication_info*/_search?track_total_hits=true`,
    data
  );
}

// CYkit methods

export async function getTotalCounts(filter, date) {
  let data = TotalCountsQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYF}/_search?track_total_hits=true`,
    data
  );
}

export async function getSoftwareList(sort, scanName, day) {
  let data = SoftwareListQuery(sort, scanName, day);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getInstalledVendor(sort) {
  let data = InstalledVendor(sort);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}

export async function getUniqueVendorCount() {
  let data = totalUniqueVendorCountsQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueSoftwareCount(scanName, days) {
  let data = totalUniqueSoftwareCountsQuery(scanName, days);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueVendorList() {
  let data = totalUniqueVendorListQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}

export async function getSoftwareTable() {
  let data = softwareTableQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getHostList() {
  let data = getHostListQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueAssetsSoftCount(scanName, days, lastDate) {
  let data = uniqueAssetsSoftCountQuery(scanName, days, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueVulnsCount(scanName, day, lastDate) {
  let data = uniqueVulnsCountQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueAssetsCount(scanName, day, lastDate) {
  let data = uniqueAssetsCountQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueVulnsWithExploits(scanName, day, lastDate) {
  let data = uniqueVulnsWithExploitsQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getVulnsBySeverityQuery(scanName, day, lastDate) {
  let data = vulnsBySeverityQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getVulnsWithExploitListQuery(scanName, day, lastDate) {
  let data = vulnsWithExploitListQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getUniqueHostWithRiskScoreQuery(scanName, day, lastDate) {
  let data = uniqueHostWithRiskScoreQuery(scanName, day, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getVulnsBySeverityFor7Query(scanName) {
  let data = vulnsSeverityAgeQuery(7, scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getVulnsBySeverityFor30Query(scanName) {
  let data = vulnsSeverityAgeQuery(30, scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getVulnsBySeverityFor90Query(scanName) {
  let data = vulnsSeverityAge90Query(scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getvulnsOverTime(scanName) {
  let data = vulnsOverTimeQuery(scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getvulnsPerHostListQuery(scanName) {
  let data = vulnsPerHostListQuery(scanName)

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getvulnsListQuery(scanName) {
  let data = vulnsListQuery(scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getleastHost10query(scanName, days, lastDate) {
  let data = leastHost10query(scanName, days, lastDate);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getScanName() {
  let data = scanNameQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getScanNameSoftware() {
  let data = scanNameQuery();

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
export async function getFirewallThreatLandscape() {
  let data = firewallThreatLandscapeQuery();

  return await fpostDl(
    `${datalakeURL}${THREAT_LANDSCAPE_FIREWALL}/_search?track_total_hits=true`,
    data
  );
}
export async function getFirewallThreatLandscapeFilter(date) {
  return await fgetC(
    `${apiBaseUrl}/aggregate?&start=${date?.from}&end=${date?.to}`,
  );
}
export async function getCounts(field_name, filter, date) {
  let data = getCountsQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYF}/_search?track_total_hits=true`,
    data
  );
}

// active directory

export async function getCountsAD(field_name, filter, date) {
  let data = getCountsAdQuery(field_name, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

export async function getEventCount(filter, date) {
  let data = getEventCountsQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

export async function getAccoundCounts(event_code, filter, date) {
  let data = getAccoundCountsQuery(event_code, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

// active directory graph

export async function getAuthUserChart(event_code, filter, date) {
  let data = AuthUserChartQuery(event_code, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

// user management chart

export async function getUserManagementAction(filter, date) {
  let data = userManagementActionQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

// group management chart

export async function getGroupManagementAction(filter, date) {
  let data = groupManagementActionQuery(filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

//accound created data and deleted data

export async function getAccoundDatas(event_code, filter, date) {
  let data = getAccoundDataQuery(event_code, filter, date);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

// active directory table data

export async function getActiveDirectoryTable(filter, date, size, sort, page) {
  let data = getActiveDirectoryTableQuery(filter, date, size, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

// graph

export async function getWidgetsData(field_name, count, sort, filter, date, dateRange) {
  let data = getWidgetsQuery(field_name, count, sort, filter, date, dateRange);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYF}/_search?track_total_hits=true`,
    data
  );
}

export async function getRecentFileOperations(size, date) {
  let data = getRecentFileOperationsQuery(size, date);

  return fpostCy(
    `${localStorage.getItem("DATALAKE_URL")}/${indexPattern}`,
    data
  );
}

// recent auth manangement
export async function getRecentAuthManagementDash(size, date) {
  let data = getRecentAuthManagementDashQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/.ds-logs*/_search?track_total_hits=true`,
    data
  );
}

// account url management dashboard

export async function getAccountCreated(size, date) {
  let data = getAccountCreatedQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}
// account locked
export async function getAccountLocked(size, date) {
  let data = getAccountLockedQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}
//account changed
export async function getAccountChanged(size, date) {
  let data = getAccountChangedQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}
// account deleted / disabled management dashboard

export async function getAccountDeleted(size, date) {
  let data = getAccountDeletedQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}

// account pass change management dashhh

export async function getAccountPasswordChage(size, date) {
  let data = getAccountPassQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}

export async function userActivity(size, date) {
  let data = getAccountActivityQuery(size, date);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*,logs-winlogevents*/_search?track_total_hits=true`,
    data
  );
}

// user host counts management dashboard

export async function recentCounts(field_name) {
  let data = {
    size: 0,
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/.ds-logs*/_search?track_total_hits=true`,
    data
  );
}

// export async function getSourceIpData() {
//   let data = sourceIPQuery();

//   return await fpostDl(
//     `${datalakeURL}/.ds-logs-fw*/_search?track_total_hits=true`,
//     data
//   );
// }

// export async function getSourcePortsData() {
//   let data = sourcePortsQuery()

//   return await fpostDl(
//     `${datalakeURL}/.ds-logs-fw*/_search?track_total_hits=true`,
//     data
//   )
// }

// export async function getDestinationPortsData() {
//   let data = destinationPortsQuery()

//   return await fpostDl(
//     `${datalakeURL}/.ds-logs-fw*/_search?track_total_hits=true`,
//     data
//   )
// }

// export async function getDestinationIpData() {
//   let data = destinationIpQuery()

//   return await fpostDl(
//     `${datalakeURL}/.ds-logs-fw*/_search?track_total_hits=true`,
//     data
//   )
// }

export async function getCyTableData(size, sort, page) {
  let data = tableDataQuery(size, sort, page);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYF}/_search?track_total_hits=true`,
    data
  );
}

// playbook list

export async function getPlaybookList() {
  return await fget(
    `${apiBaseUrl}/investigate/catalogue?type=tenant`
  );
}
// respond drop down list

export async function getRespondList() {
  let data = { size: 10000, query: { match_all: {} } };

  return await fpostC(`${baseURL}/cybot_proxy/thp_api*/_search`, data);
}
// widgets
// hash reputation

export async function hashReputationVirusTotal(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_virustotal_hash_enrichment*/_search`,
    data
  );
}

export async function hashReputationAlienVault(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_alienvault_hash_enrichment*/_search`,
    data
  );
}

export async function hashReputationThreatScore(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_hash_enrichment*/_search `,
    data
  );
}

export async function hashReputationWidgets(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_hash_enrichment*/_search `,
    data
  );
}

// IP Reputation

export async function ipReputationAlienVault(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_alienvault_ip_enrichment*/_search`,
    data
  );
}

export async function ipReputationVirusTotal(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_virustotal_ip_enrichment*/_search`,
    data
  );
}

export async function ipReputationThreatScore(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_ip_enrichment*/_search`,
    data
  );
}

export async function ipReputationWidgets(hunt_detection_id) {
  let data = reputationIPQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_ip_enrichment*/_search`,
    data
  );
}

// URL Reputation

export async function urlReputationVirusTotal(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_virustotal_url_enrichment*/_search`,
    data
  );
}

export async function urlReputationAlienVault(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_alienvault_url_enrichment*/_search`,
    data
  );
}

export async function urlReputationThreatScore(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_url_enrichment*/_search `,
    data
  );
}

export async function urlReputationWidgets(hunt_detection_id) {
  let data = reputationQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_url_enrichment*/_search `,
    data
  );
}

// Traffic from other hosts to detected IP

export async function trafficHostDetectedIpSummary(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_edr_traffic_from_host_to_ip_summary*/_search?track_total_hits=true `,
    data
  );
}

export async function trafficHostDetectedIpDetailed(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_edr_traffic_from_host_to_ip_detail*/_search?track_total_hits=true `,
    data
  );
}

// firewall

export async function fireWallTrafficFromIpSummary(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_firewall_traffic_from_ip_summary*/_search?track_total_hits=true`,
    data
  );
}

export async function fireWallTrafficFromIpDetailed(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_firewall_traffic_from_ip_detail*/_search?track_total_hits=true`,
    data
  );
}

// Bytes transferred Between IPs
export async function bytesTransferredBetweenIp(hunt_detection_id) {
  let data = bytesTransferredBetweenIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_firewall_byte_transferred_between_ip*/_search?track_total_hits=true`,
    data
  );
}

// Public IP info from OSINT
export async function fireWallPublicIpInfoOSINT(hunt_detection_id) {
  let data = fireWallPublicIpInfoOSINTQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_whois_info*/_search?track_total_hits=true`,
    data
  );
}

// Threat Detection of the IP - inbound
export async function threatDetectionIpInbound(hunt_detection_id) {
  let data = threatDetectionIpInboundQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_l7fw_threat_detection_on_ip_inbound*/_search?track_total_hits=true`,
    data
  );
}

// Threat Detection of the IP - Outbound
export async function threatDetectionIpOutbound(hunt_detection_id) {
  let data = threatDetectionIpOutboundQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_l7fw_threat_detection_on_ip_outbound*/_search?track_total_hits=true`,
    data
  );
}

// traffic to ip

export async function fireWallTrafficToIpSummary(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_firewall_traffic_to_ip_summary*/_search?track_total_hits=true`,
    data
  );
}

export async function fireWallTrafficToIpDetailed(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_firewall_traffic_to_ip_detail*/_search?track_total_hits=true`,
    data
  );
}

// waf

export async function wafTrafficFromIpSummary(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_traffic_from_ip_summary*/_search?track_total_hits=true`,
    data
  );
}

export async function wafTrafficFromIpDetailed(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_traffic_from_ip_detail*/_search?track_total_hits=true`,
    data
  );
}

// waf threat detected on ip

export async function wafThreatDetectedIp(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_threat_detection_on_ip*/_search?track_total_hits=true`,
    data
  );
}

export async function wafUserAttemptIp(hunt_detection_id) {
  let data = trafficDetectedIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_user_attempt_by_ip*/_search?track_total_hits=true`,
    data
  );
}

// waf widget NEWW

export async function getWafTrafficFromIp(hunt_detection_id) {
  let data = wafTrafficFromIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_traffic_from_ip_detail*/_search?track_total_hits=true`,
    data
  );
}

// waf Threat Detection of the IP – Inbound

export async function wafTreatDetectioIpInbound(hunt_detection_id) {
  let data = wafTreatDetectioIpInboundQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_waf_threat_detection_on_ip*/_search?track_total_hits=true`,
    data
  );
}

// waf Username attempted by the IP

export async function wafUserAttemptedByIp(hunt_detection_id) {
  let data = wafUserAttemptedByIpQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_l7fw_user_attempt_by_ip*/_search?track_total_hits=true `,
    data
  );
}

// active directory

export async function recentAuthByUserAd(hunt_detection_id) {
  let data = recentAuthByUserAdQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_ad_recent_auth_in_user*/_search?track_total_hits=true`,
    data
  );
}

export async function recentAuthByHostAd(hunt_detection_id) {
  let data = recentAuthByHostAdQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_ad_recent_auth_in_host*/_search?track_total_hits=true`,
    data
  );
}

// User Management Activity By User ( AD )

export async function userManagementActivityByUserAd(hunt_detection_id) {
  let data = userManagementActivityByUserAdQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_ad_user_management_activity*/_search?track_total_hits=true`,
    data
  );
}

//file share
export async function getFileActivity(code, filter, date) {
  let data = getFileActivityQuery(code, filter, date);

  return fpostDl(
    `${datalakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}

export async function getWidgetsDataWaf(
  field_name,
  count,
  sort,
  filter,
  date,
  dateRange
) {
  let data = getWidgetsQuery(field_name, count, sort, filter, date, dateRange);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CYWAF}/_search?track_total_hits=true`,
    data
  );
}

// new widgets
// Mails send to the email id

export async function getMailsSendToEmailId(hunt_detection_id) {
  let data = getMailsSendToEmailIdQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_mail_send_to*/_search?track_total_hits=true`,
    data
  );
}

// Mails send from the email id

export async function getMailsSendFromEmailId(hunt_detection_id) {
  let data = getMailsSendFromEmailIdQuery(hunt_detection_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_mail_send_from*/_search?track_total_hits=true`,
    data
  );
}

// TI widgets

export async function getTiWidgets(field_name) {
  let data = getTiWidgetsQuery(field_name);

  return await fpost(
    `${apiBaseUrl}/ti/platform/misp_event_data/_search?pretty`,
    data
  );
}

// grouped view new

export async function groupedInvestigation(group_id) {
  let data = groupedInvestigationQuery(group_id);

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_group*/_search?track_total_hits=true`,
    data
  );
}

export async function falsePositive(group_id) {
  let data = groupedInvestigationQuery(group_id);

  // return await fpostC(
  //   `${baseURL}/cybot_proxy/thp_false_positive*/_search`,
  //   data
  // );
  return await fpostC(
    `${baseURL}/cybot_proxy/thp_enriched_observables*/_search`,
    data
  );
}


// recentAuthIn0365Query clientip

export async function getRecentAuthInO365Clientip(hunt_detection_id) {
  let data = recentAuthInO365Query(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_auth_in_o365_clientip*/_search?track_total_hits=true`,
    data
  );
}

// recentAuthIn0365Query userid

export async function getRecentAuthInO365UserId(hunt_detection_id) {
  let data = recentAuthInO365Query(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_auth_in_o365_userid*/_search?track_total_hits=true`,
    data
  );
}


// Recent Operations in SharePoint by User

export async function getRecentOperationSharepointUserId(hunt_detection_id) {
  let data = recentOperationSharepointUserQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_sharepoint_in_o365_userid*/_search?track_total_hits=true`,
    data
  );
}

// Recent Operations in SharePoint by User clientip

export async function getRecentOperationSharepointUserClientip(hunt_detection_id) {
  let data = recentOperationSharepointUserQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_sharepoint_in_o365_clientip*/_search?track_total_hits=true`,
    data
  );
}



// thp_onedrive_in_o365_userid

export async function getRecentOperationOnedriveUserId(hunt_detection_id) {
  let data = recentOperationOnedriveQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_onedrive_in_o365_userid*/_search?track_total_hits=true`,
    data
  );
}

// thp_onedrive_in_o365_clientip

export async function getRecentOperationOnedriveClientIp(hunt_detection_id) {
  let data = recentOperationOnedriveQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_onedrive_in_o365_clientip*/_search?track_total_hits=true`,
    data
  );
}


// getRecentOperationAzureAdUserId

export async function getRecentOperationAzureAdUserId(hunt_detection_id) {
  let data = recentOperationAzureAdQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_azure_ad_in_o365_userid*/_search?track_total_hits=true`,
    data
  );
}

// getRecentOperationAzureAd Client

export async function getRecentOperationAzureAdClientIp(hunt_detection_id) {
  let data = recentOperationAzureAdQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_azure_ad_in_o365_clientip*/_search?track_total_hits=true`,
    data
  );
}

// thp_exchange_in_o365_userid

export async function getRecentOperationExchangeUserId(hunt_detection_id) {
  let data = recentOperationExchangeQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_exchange_in_o365_userid*/_search?track_total_hits=true`,
    data
  );
}

// thp_exchange_in_o365_client ip

export async function getRecentOperationExchangeClientIp(hunt_detection_id) {
  let data = recentOperationExchangeQuery(hunt_detection_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/thp_exchange_in_o365_clientip*/_search?track_total_hits=true`,
    data
  );
}

export async function getLatestScan(scanName) {
  let data = latestScanQuery(scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`,
    data
  );
}
export async function getLatestScanSoftware(scanName) {
  let data = latestScanQuery(scanName);

  return await fpostDl(
    `${datalakeURL}${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`,
    data
  );
}
