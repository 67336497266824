import React, { useRef, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import PlainCardLoader from '../Loader/PlainCardLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarGraphRemove = ({ ChartLabel, ChartData, ChartTitle, module, tab, field, label, setfilter, filter, fieldData,fieldlabel, setFieldData, setFieldLabel }) => {
    const chartRef = useRef();
    const [graphLoader,setGraphLoader] = useState(false)

    const onClick = (event) => {
        setGraphLoader(true)
        var activeBars = getElementAtEvent(chartRef.current, event);
        if (activeBars.length > 0) {
            var clickedDatasetIndex = activeBars[0].datasetIndex;
            var clickedElementIndex = activeBars[0].index;
            var clickedLabel = data.labels[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if ( fieldData && fieldlabel) {
                fieldData.splice(clickedElementIndex, 1)
                fieldlabel.splice(clickedElementIndex, 1)
                setFieldData(fieldData)
                setFieldLabel(fieldlabel)
                setTimeout(() => {
                    setGraphLoader(false)
                }, 1000);
            }
        }
    }
    const options = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: ChartTitle,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // disable y axis inside grid lines
                },
            },
            x: {
                grid: {
                    display: false, // disable x axis inside grid lines
                },
            },
        },
    };

    const data = {
        labels: ChartLabel,
        datasets: [
            {
                label: '',
                data: ChartData,
                // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(67, 72, 180, 0.8)',

                    'rgba(20, 205, 86, 0.8)',
                    'rgba(200, 20, 200, 0.8)',
                    'rgba(75, 192, 40, 0.8)',
                ],
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: 'bottom',
            },
        ],
    };


    return (
        <>
            {
                graphLoader ?
                <PlainCardLoader width="200px" />:
                <Bar options={options} data={data} ref={chartRef} onClick={onClick} />
                }
        </>
    )
}

export default BarGraphRemove