import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./HunterOverview.styles";
import Detections from "./Detections/Detections";
import HunterDetection from "./HunterDetection/HunterDetection";
import CykitGraph from "./Cykit/CykitGraph";
import UnifiedCykit from "./Activities/UnifiedCykit";
import Corelation from "./Corelation";
import { ReactFlowProvider } from "reactflow";

const HunterOverview = () => {
  const location = useLocation();
  const params = useParams();
  let navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [dateFilter, setDateFilter] = useState({});


  console.log("location", location)
  console.log("params", params)
  // console.log("navigate", navigate)

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  useEffect(() => {
    if (currentPath === "detections") {
      setTitle("Hunter Detections")
      setBreadcrumb({
        "module": "hunter",
        "path": [{ "title": "Hunter", "url": "/hunter/detections" }, { "title": "Detections", "url": "/hunter/detections" },]
      });
    }
    if (currentPath === "detection") {
      setTitle("Hunter Detection")
      setBreadcrumb({
        "module": "hunter",
        // "path": [{ "title": "Hunter", "url": "/hunter/detections" }, { "title": "Detections", "url": "/hunter/detections" }, { "title": "Detection", "url": "" },]
        "path": [{ "title": "Hunter", "url": "/hunter/detections" }, { "title": "Detections", "url": "/hunter/detections" }, { "title": params.hunt_id, "url": "" },]
      });
    }
    if (currentPath === "cykit") {
      setTitle("Hunter Cykit")
      setBreadcrumb({
        "module": "hunter",
        "path": [{ "title": "Hunter", "url": "/hunter/cykit" }, { "title": "Cykit", "url": "/hunter/cykit" },]
      });
    }
    if (currentPath === "cygraph") {
      setTitle("Hunter Detection")
      setBreadcrumb({
        "module": "hunter",
        "path": [{ "title": "Hunter", "url": "/hunter/detections" }, { "title": "Cykit", "url": "/hunter/cykit" }, { "title": "Cygraph", "url": "" },]
      });
    }
    if (currentPath === "corelation") {
      setTitle("Correlation Studio")
      setBreadcrumb({
        "module": "hunter",
        "path": [{ "title": "Hunter", "url": "/hunter/detections" }, { "title": "Correlation Studio", "url": "/hunter/corelation" }]
      });
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"hunter"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "detections" ? <Detections selectedDateFilter={selectedDateFilter} />
            : currentPath === "detection" ? <HunterDetection /> : currentPath === "cykit" ? (
              <UnifiedCykit />) :
              currentPath === "cygraph" ? (
                <CykitGraph />
              ) :currentPath === "corelation" ? (
                <Corelation />
              ) : null}
        </div>
      </div>
    </div>
  );
};

export default HunterOverview;
