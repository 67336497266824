import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import { getMultiQuery } from "../../Queries/CykitQuery";
import ReactJson from "react-json-view";
import useStore from "./Store/store";

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./Sidebar.styles";
import { File, Globe, Graph, Key, Minus, Plus, X } from "phosphor-react";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";
import SpinnerLoader from "../Loader/SpinnerLoader";
import NoDataFound from "../NoDataFount/NoDataFound";
import { getFieldAggsData, getLogSourceData } from "./Methods";
import { shallow } from "zustand/shallow";
import CustomToolTip from "../ToolTip/CustomToolTip";
import { v4 as uuidv4 } from 'uuid';

const RegistryCols = [

  {
    name: 'Key',
    selector: row => row._source.registry.key,
    sortable: true,
  },
  {
    name: 'data',
    selector: row => row._source.registry.data,
    sortable: true,
  },
  {
    name: 'hive',
    selector: row => row._source.registry.hive,
    sortable: true,
  },
]
const NetworkCols = [


  {
    name: 'URL',
    selector: row => row._source.destination.url,
    sortable: true,
  },
  {
    name: 'Port',
    selector: row => row._source.destination.port,
    sortable: true,
  }
]
const FileCols = [
  {
    name: 'File name',
    selector: row => row._source.file.name,
    sortable: true,
  },
  {
    name: 'Path',
    selector: row => row._source.file.path,
    sortable: true,
  },
  {
    name: 'action',
    selector: row => row._source.event.action,
    sortable: true,
  }
]
const selector = (state) => ({
  clickedNode: state.clickedNode,
  clicked: state.clicked,
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  searchType: state.searchType,
  keyword: state.keyword,
});
let baseURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

export default function SideBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [node, setNode] = useState();
  const [nodeData, setNodeData] = useState();
  const [processData, setprocessData] = useState();
  const clickedNode = useStore((state) => state.clickedNode);
  const clicked = useStore((state) => state.clicked);
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    keyword,
    searchType,
  } = useStore(selector, shallow);
  const setClick = useStore((state) => state.setClick)
  const [file, setFile] = useState()
  const [registry, setRegistry] = useState()
  const [network, setNetwork] = useState()
  const [fileCount, setFileCount] = useState(0)
  const [registryCount, setRegistryCount] = useState(0)
  const [networkCount, setNetworkCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const sources = localStorage.getItem("CORELATION_SOURCES", [])
  const corfields = JSON.parse(localStorage.getItem("CORELATION_FIELDS", []))

  useEffect(() => {
    if (clickedNode.length > 0) {
      setIsLoading(true)
      if (clicked?.type === "log_source") {
        setIsOpen(true);
      }
      const data = JSON.stringify({
        "query": {
          "range": {
            "@timestamp": {
              "gte": "now-360d/d",
              "lt": "now/d"
            }
          }
        },
        "aggs": {
          "field": {
            "terms": {
              "field": clickedNode,
              "size": 10
            }
          }
        },
        "size": 0
      });

    }
  }, [clickedNode]);

  useEffect(() => {
    if (nodeData) {
      setIsLoading(false)
    }
  }, [nodeData])
  useEffect(() => {
    if (props.close === true) {
      setIsOpen(false)
      setClick('')
      props.closefn(false)
    }
  }, [props.close])



  const handleClose = () => {
    setClick('')
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const DataComponent = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [fieldData, setFieldData] = useState([])
    const [logSourceData, setLogSourceData] = useState([])
    const [dataLoader, setDataLoader] = useState(false)
    const getFieldData = async (field, filter, dateFilter) => {
      const data = await getFieldAggsData(field, filter, dateFilter, keyword, searchType)
      setFieldData(data?.data?.aggregations?.field?.buckets)
      setDataLoader(false)
    }
    const handleChange = (event) => {
      setDataLoader(true)
      getFieldData(event.target.value, [...clicked?.filter, ...clicked?.initFilter], clicked.dateFilter)
      setSelectedOption(event.target.value);
    };
    const edgeType = "smoothstep";
    const position = { x: 0, y: 0 };
    const getLogSource = async (keyword, filter, dateFilter, searchType) => {
      const res = await getLogSourceData(keyword, filter, dateFilter, searchType)
      setLogSourceData(res?.data?.aggregations?.field?.buckets)
    }
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [fields, setFields] = useState(corfields)
    const addFilter = (type, value, selectedOption) => {
      // if(!isFilterApplied) return

      let newNodes = [];
      let newEdges = [];
      var iniFilters = [{
        "column": {
          "label": selectedOption,
          "value": selectedOption,
          "isWildCard": "true",
          "isNumber": null
        },
        "type": type,
        "selectedValue": value,
        "from": "",
        "to": "",
        "isInline": false
      }]
      const uid = uuidv4();
      getLogSource(keyword, [...clicked?.filter, ...clicked?.initFilter, ...iniFilters], clicked?.dateFilter, searchType)
      var parentProcessNode = {
        id: `${clicked.id}_${uid}`,
        type: "CustomNodeProcess",
        nodetype: "field",
        data: {
          label: `${selectedOption}: ${value}`,
          value: value,
          onClick: clicked.onClick,
          initFilter: [...clicked.initFilter, ...iniFilters],
          onCollapse: clicked.onCollapse,
          nodetype: "field",
          id: `${clicked.id}_${uid}`,
          type: 'field',
          field: selectedOption,
          filter: clicked?.filter,
          dateFilter: clicked?.dateFilter,
          logSource: logSourceData,
          filterType: type
        },
        position,
        sourcePosition: "right",
        targetPosition: "left",
      };

      const parentEdge = {
        id: `e_${clicked.id
          }_${uid}`,
        source: `${clicked.id}_${uid}`,
        target: `${clicked.id}`,
        type: edgeType,
        animated: true,
      };
      console.log("process:", parentProcessNode);

      newNodes.push(parentProcessNode);
      newEdges.push(parentEdge);
      setNodes([...nodes, ...newNodes]);
      setEdges([...edges, ...newEdges]);

    }
    if (clicked?.id === "1a" || clicked?.type === "keyword") {
      return (<div>
        <p>{`The Keyword has been found in ${clicked?.logSource?.length}`}</p>
      </div>)
    }
    else if (clicked?.type === "field") {
      return (<div>
        <p>{`The Keyword ${clicked?.value} has been found in ${clicked?.logSource?.length} Log Sources`}</p>
      </div>)

    }
    else if (clicked?.type === "log_source") {
      return <div>
        <div>
          <p>The Keyword has been found log source` <b>{clicked?.label}</b>  <b>{clicked?.logSource?.filter(item => item.key === clicked.label)?.doc_count}</b></p>
        </div>
        <div>
          <label className="col-12">
            Select field to get the common values:
            <select className="form-select" value={selectedOption} onChange={handleChange} style={{ width: '100%' }}>
              <option value="">Select...</option>
              {fields
                ?.filter(item => item.label === clicked.label)?.[0]?.fields?.map(item1 => <option key={item1} value={item1}>{item1}</option>)}
            </select>
          </label>

          {selectedOption && (
            dataLoader ? <div style={{ "position": "absolute", "right": "50%", "top": "55%" }}><Spinner animation="border" role="status" /></div> : (<table className="mt-2">
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Count</th>
                  <th></th>

                </tr>
              </thead>
              <tbody>
                {(fieldData?.length > 0 ? fieldData?.map(item => <tr>
                  <td>{item?.key}</td>
                  <td>{item?.doc_count}</td>
                  <td>
                    {<div className="d-flex gap-2 flex-row">
                      <div onClick={() => { addFilter("is", item?.key, selectedOption) }}>

                        <CustomToolTip content={<Plus size={12} />} dataPlacement={"bottom"}
                          dataToggle={
                            <div className="d-flex flex-column text-left" style={{ textAlign: 'left' }} >
                              <div>Add to Corelation</div>
                            </div>
                          } />

                      </div>
                      <div onClick={() => { addFilter("is not", item?.key, selectedOption) }}>
                        <CustomToolTip content={<Minus size={12} />} dataPlacement={"bottom"}
                          dataToggle={
                            <div className="d-flex flex-column text-left" style={{ textAlign: 'left' }} >
                              <div>Remove From Corelation</div>
                            </div>
                          } />
                      </div>
                      <div onClick={() => { window.open(`/hunter/corelation?&search=${item?.key}`, '_blank') }}>

                        <CustomToolTip content={<Graph size={12} />} dataPlacement={"bottom"}
                          dataToggle={
                            <div className="d-flex flex-column text-left" style={{ textAlign: 'left' }} >
                              <div>Create New Corelation</div>
                            </div>
                          } />
                      </div>
                    </div>}
                  </td>
                </tr>) : <div className="mx-auto"><NoDataFound /></div>)}
              </tbody>
            </table>)
          )}
        </div>
      </div>
    }
  }
  return (
    <>
      {isOpen ? (
        <div
          className="flex shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal"
              id="offcanvasRightLabel"
            >
              Details
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}
          {false ? <div style={{ "position": "absolute", "right": "50%", "top": "55%" }}><Spinner animation="border" role="status" /></div> :

            <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar">

              {/* this not workgin */}
              <div className="py-3" style={styles.sidebarContentWrap}>
                <DataComponent />

              </div>

            </div>}
        </div>
      ) : (
        ""
      )}
    </>
  );
}