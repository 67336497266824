import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlertBox = ({ setAlertPopup, alertPopup, message, status, alertPosition }) => {

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         close();
    //     }, 10000);
    //     return () => clearTimeout(timeout);
    // }, []);

    useEffect(() => {
        if (message !== undefined) {
            status === "error" ? (
                toast.error(message, {
                    position: alertPosition ? toast.POSITION.alertPosition : toast.POSITION.BOTTOM_RIGHT
                })
            ) : (
                toast.success(message, {
                    position: alertPosition ? toast.POSITION.alertPosition : toast.POSITION.BOTTOM_RIGHT
                })
            )

            setTimeout(() => {
                setAlertPopup(!alertPopup)
            }, 5000)
        }
    }, [alertPopup])


    return (
        <ToastContainer position={alertPosition} />
    );
}

export default AlertBox