import React, { useEffect, useState } from 'react'
import { getRepetitions } from '../../Methods/InvestigationMethods'
import { TimelineLoader, TimelineLoaderLg } from '../Loader/TimelineLoader'
// import styles from "../../pages/Investigate/Dashboard/Dashboard.styles";
import styles from "./style.styles";
import InvestigationDashboard from './InvestigationDashboard';
import CustomModal from '../CustomModal/CustomModal';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../Utils/Utils';
import { Link } from 'react-router-dom';
import { getGroupedDetectionDetails } from '../../Methods/HunterMethods';
import NoDataFound from '../NoDataFount/NoDataFound';
import { Button } from 'react-bootstrap';
import TableViewMore from '../ViewMoreBtn/TableViewMore';
import { Desktop } from 'phosphor-react';

const RepetitionDashboard = ({ details, view }) => {

    console.log("GROUPD ID :", details?.grp_id ? details?.grp_id : details._source?.grp_id)

    //  const { process_hash, user_name, host_name, source_ip } = details._source

    // const [repetitionDetails, setRepetitionDetails] = useState([])
    const [repetitionData, setRepetitionData] = useState([])
    console.log("rep DATA :", repetitionData)
    const [timelineIsLoading, setTimelineIsLoading] = useState(true);

    const [show, setShow] = useState(false);
    const [detailPage, setDetailpage] = useState([]);

    // const [fullResponse, setFullResponse] = useState([])


    useEffect(() => {
        // getRepetitions(host_name, process_hash, source_ip, user_name).then((res) => {
        //     console.log("checking reppp :", res)
        // }).catch((err) => {
        //     alert("FAILED REPP")
        // })
        view !== true ? (
            getRepetitions(details._source.host, details._source.process_hash, details._source.user, details._source.dest_ip, details._source.source_ip, details._source.detection_name).then(({ data: { hits: { hits } } }) => {

                let repetitionData = []
                // let repetitionDetails = []
                hits && hits.map((item) => {

                    repetitionData.push({
                        host: item?._source?.host,
                        user_name: item?._source?.user,
                        detection_name: item?._source?.detection_name,
                        detection_id: item?._source?.playbook_id,
                        src_ip: item?._source?.source_ip,
                        dest_ip: item?._source?.dest_ip,
                        ip: item?._source?.ip,
                        file: item?._source?.file,
                        hash: item?._source?.hash,
                        url: item?._source?.url,
                        timestamp: item?._source?.detection_timestamp,
                        hunt_detection_id: item?._source?.hunt_detection_id,
                        investigation_status: item?._source?.investigation_status,
                        _ID: item?._id,
                        tenendID: item?._index,
                        process_hash: item?._source?.process_hash

                    })
                })
                setRepetitionData(repetitionData)
                setTimelineIsLoading(false)
                // setRepetitionDetails(repetitionDetails)
            }).catch((err) => {
                console.log("Error", err)
            })

        ) : (
            getGroupedDetectionDetails(details?.grp_id ? details?.grp_id : details._source?.grp_id).then(({ data: { hits: { hits } } }) => {
                let hitsResponse = []
                let groupedInv = []
                hits.map((item) => {

                    groupedInv.push({
                        host: item?._source?.host,
                        user_name: item?._source?.user,
                        detection_name: item?._source?.detection_name,
                        detection_id: item?._source?.playbook_id,
                        ip: item?._source?.ip,
                        src_ip: item?._source?.source_ip,
                        dest_ip: item?._source?.dest_ip,
                        file: item?._source?.file,
                        hash: item?._source?.hash,
                        url: item?._source?.url,
                        timestamp: item?._source?.detection_timestamp,
                        hunt_detection_id: item?._source?.hunt_detection_id,
                        _ID: item?._id,
                        tenendID: item?._index
                    })

                    // hitsResponse.push({
                    //     playbook_id: item?._source?.playbook_id,
                    //     _id: item?._id,
                    //     _index: item?._index,
                    //     user_name: item?._source?.user,
                    //     host: item?._source?.host,
                    // })
                })
                setRepetitionData(groupedInv)
                setTimelineIsLoading(false)
            }).catch((err) => {
                console.log("Error", err)
            })
        )
    }, [])

    const handleOpenModal = (data, display) => {
        // console.log("MODAL :", data)
        setDetailpage({ _source: data });
        setShow(display);
    };

    function handleCloseModal() {
        setShow(false);
    }

    function renderModal() {
        return <InvestigationDashboard investigationData={detailPage} />;
    }

    return (
        <div className="col-12 gap-3 d-flex flex-column p-3"
            style={styles.section2Sm}>

            <div
                className="col-12 d-flex flex-column observable-datas gap-3 rounded-3 bg-white"
            >
                <div className="col-12 rounded-3 p-3 alert alert-info mb-0">
                    {/* <h4>{view === true ? "Grouped Investigation" : "Repetitions"}</h4> */}
                    {view === true ? (
                        <p className='mb-0'>
                            CYBOT™ Groups the investigations based on many patterns to save the time of an Analyst. It could be similar attack occurring on many systems, same system gets many attacks, or repeated attempts from same attack source, some tactic patterns etc.
                            This helps analysts to see them together and analyze the pattern to act faster.
                        </p>
                    ) : (
                        <p className='mb-0'>This page shows the repetitions of detections which contain identical observables. The identical observables are shown below along with a timeline of the detections (Only the first detection within 24h will be investigated to avoid repetition)</p>
                    )}

                </div>

                <div className="col-12 rounded-3 p-4 border bg-white repetition-details">
                    {repetitionData[0]?.user_name && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">User</span>
                            <span className="modal-text col-12">{repetitionData[0]?.user_name}</span>
                        </span>
                    )}

                    {repetitionData[0]?.host && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Host</span>
                            <span className="modal-text col-12">{repetitionData[0]?.host}</span>
                        </span>
                    )}

                    {repetitionData[0]?.ip && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Host IP</span>
                            <span className="modal-text col-12">{repetitionData[0]?.ip}</span>
                        </span>
                    )}

                    {repetitionData[0]?.file && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">File</span>
                            <span className="modal-text col-12">{repetitionData[0]?.file}</span>
                        </span>
                    )}

                    {repetitionData[0]?.hash && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Hash</span>
                            <span className="modal-text col-12">{repetitionData[0]?.hash}</span>
                        </span>
                    )}
                    {repetitionData[0]?.process_hash && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Hash</span>
                            <span className="modal-text col-12">{repetitionData[0]?.process_hash}</span>
                        </span>
                    )}
                    {repetitionData[0]?.src_ip && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Source IP</span>
                            <span className="modal-text col-12">{repetitionData[0]?.src_ip}</span>
                        </span>
                    )}
                    {repetitionData[0]?.dest_ip && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">Destination IP</span>
                            <span className="modal-text col-12">{repetitionData[0]?.dest_ip}</span>
                        </span>
                    )}
                    {repetitionData[0]?.url && (
                        <span className="modal-row-item flex-column">
                            <span className="modal-label col-12">URL</span>
                            <span className="modal-text col-12">{repetitionData[0]?.url}</span>
                        </span>
                    )}

                </div>
            </div>


            <div
                className="d-flex gap-3 flex-column flex-fill rounded-3 bg-white mb-3"
            >
                {timelineIsLoading ? (
                    <div className="col-12">
                        <TimelineLoaderLg />
                    </div>
                ) : (
                    <div className="d-flex col-12 position-relative">
                        <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                            {repetitionData.map((item, index) => (
                                <li
                                    className="d-flex flex-row gap-3 justify-content-center position-relative"
                                    style={styles.detectionItem}
                                >
                                    {/* <span className="dateLabel" style={{ left: 0, top: 0 }} onClick={() => handleOpenModal(item, true)}>
                                        {getDateFromTimestamp(item?.timestamp)}
                                    </span> */}

                                    <p className="dateLabel" style={{ left: 0, top: 0 }} >
                                        {getDateFromTimestamp(item?.timestamp)}
                                    </p>
                                    {/* {item?.investigation_status === "Investigated" || item?.investigation_status === undefined ?
                                            <Link className="dateLabel" style={{ left: 0, top: 0 }} to={`/investigate/summary/${item.hunt_detection_id}`} state={{ sourceList: repetitionData[index] }} >
                                                {getDateFromTimestamp(item?.timestamp)}
                                            </Link>
                                            : (
                                                <>
                                                    <p className="dateLabel" style={{ left: 0, top: 0 }} >
                                                        {getDateFromTimestamp(item?.timestamp)}
                                                    </p>
                                                </>
                                            )} */}

                                    <div className="d-flex col-12 gap-3 flex-column px-3">
                                        <p className="timeline-item p-3 rounded-3 position-relative">
                                            {item?.host && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Host name</span>
                                                    <span className="modal-text">{item?.host}</span>
                                                </span>
                                            )}

                                            {item?.user_name && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Username</span>
                                                    <span className="modal-text">{item?.user_name}</span>
                                                </span>
                                            )}

                                            {item?.src_ip && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Source IP</span>
                                                    <span className="modal-text">{item?.src_ip}</span>
                                                </span>
                                            )}

                                            {item?.dest_ip && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Destination IP</span>
                                                    <span className="modal-text">{item?.dest_ip}</span>
                                                </span>
                                            )}

                                            {item?.process_hash && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Hash</span>
                                                    <span className="modal-text">{item?.process_hash}</span>
                                                </span>
                                            )}

                                            {item?.detection_name && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Detection name</span>
                                                    <span className="modal-text">{item?.detection_name}</span>
                                                </span>
                                            )}


                                            {item?.detection_id && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Detection ID</span>
                                                    <span className="modal-text">{item?.detection_id}</span>
                                                </span>
                                            )}

                                            {item?.timestamp && (
                                                <span className="modal-row-item flex-column">
                                                    <span className="modal-label">Detection time</span>
                                                    <span className="modal-text">{getTimeFromTimestamp(item?.timestamp)}</span>
                                                </span>
                                            )}

                                            {(item?.investigation_status === "Investigated" || item?.investigation_status === undefined) &&
                                                <Link
                                                    className="position-absolute btn btn-light p-2 rounded-5"
                                                    style={{ right: "1rem", top: "1rem" }}
                                                    to={`/investigate/summary/${item.hunt_detection_id}`}
                                                    state={{ sourceList: repetitionData[index] }}
                                                >
                                                    <TableViewMore Icon={Desktop} dataToggle={"Investigation Summary"} dataPlacement={"bottom"} />
                                                </Link>
                                            }
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                )}
            </div>


            {show && (
                <CustomModal
                    title={"Detection summary"}
                    ShowOrHide={show}
                    bodyData={renderModal}
                    handleClose={handleCloseModal}
                    footer={false}
                    modalSize={"fullscreen"}
                    noBodyPadding={true}
                    overflow={false}
                ></CustomModal>
            )}
        </div>
    )
}

export default RepetitionDashboard