import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getDetectionDetails,
  getDetectionTimelineDatas,
} from "../../Methods/HunterMethods";
import { escapeHtmlChars, rawHTML, removeHtmlChars, timestampConvert } from "../../Utils/Utils";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadSimple, X } from "phosphor-react";
import { decode } from "html-entities";
import CustomModal from "../CustomModal/CustomModal";
import GroupedDetections from "./GroupedDetections";
import styles from "./style.styles";

const HunterDetection = ({ id }) => {
  // const { id } = useParams();

  const [detectionDetails, setDetectionDetails] = useState();
  const [detectionTimelineData, setDetectionTimelineData] = useState([]);

  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);

  useEffect(() => {
    getDetectionDetails(id)
      .then(({ data }) => {
        setDetectionDetails([data]);
      })
      .catch((err) => {
        // console.log("Error", err);
      });

    getDetectionTimelineDatas(id)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          setDetectionTimelineData(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const downloadPDF = () => {
    const doc = new jsPDF();

    // download the pdf document
    doc.save(`${detectionDetails[0]?.hunt_name}`);
  };

  const handleOpenModal = (data, display) => {
    setDetailpage(data);
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
  }

  function handleToClose() {
    setShow(false);
  }

  function renderModal() {
    return <GroupedDetections pageDetails={detailpage} />;
  }

  return (
    <div className="col-12 gap-3 d-flex flex-row bg-grey p-3">

      {detectionDetails?.map((items) => (
        <>
          <div
            className="col-3 d-flex flex-column observable-datas gap-3 rounded-3"
            style={styles.section1}
          >
            {/* <div className="col-12 rounded-3 p-4 border bg-white">
          <h4>Repetitions </h4>
          <p>This page shows the repetitions of detections which contain identical observables. The identical observables are shown below along with a timeline of the detections (Only the first detection within 24h will be investigated to avoid redundancy)</p>
        </div> */}

            <div className="col-12 rounded-3 p-4 border bg-white repetition-details">
              <span className="modal-row-item">
                <span className="modal-label col-3">Hunt name</span>
                <span className="modal-text">{items?.hunt_name}</span>
              </span>
              <span className="modal-row-item">
                <span className="modal-label col-3">Description</span>
                <span className="modal-text">{removeHtmlChars(items?.detailed_description)}</span>
              </span>
            </div>
          </div>

          <div
            className="col-3 d-flex flex-column observable-datas gap-3 rounded-3"
            style={styles.section1}
          >
            {items?.steps.map((item, index) => (
              <>
                {/* <div className='col-3'>
                                        <h6>{'Step ' + item?.step}</h6>
                                    </div> */}
                <div className="col-12 border p-3 rounded-3 bg-white ">
                  <h6 className="px-2">{"Step " + item?.step}</h6>
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Name</span>
                    <span className="modal-text">{item?.name}</span>
                  </div>
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Description</span>
                    <span className="modal-text">
                      {escapeHtmlChars(item?.description, /<\/?p>/g)}
                    </span>
                  </div>
                  <div className="modal-row-item">
                    {detectionTimelineData[index]?._source.step ==
                      item.step ? (
                      <div
                        onClick={() =>
                          handleOpenModal(
                            detectionTimelineData[index]?._source,
                            true
                          )
                        }
                        className="rounded-3 alert alert-danger p-3 mb-0"
                      >
                        <h6>
                          {
                            timestampConvert(detectionTimelineData[index]?._source
                              .detection_timestamp, 'LLL')
                          }
                        </h6>
                        <p className="mb-0">
                          {rawHTML(decode(
                            detectionTimelineData[index]?._source
                              .description
                          ))}
                        </p>
                      </div>
                    ) : (
                      <div className="col-12 text-center rounded-3 alert alert-secondary p-3 mb-0">
                        No data found!
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      ))}


      <div
        className="d-flex gap-3 flex-column flex-fill rounded-3 p-4 bg-white"
        style={styles.section2}
      >
        <div className="d-flex col-12 position-relative">
          {show && (
            <>
              <Link onClick={handleToClose}>
                <X />
              </Link>
              <div style={{ flex: "1" }}>
                <GroupedDetections pageDetails={detailpage} />
              </div>

            </>
          )}
        </div>
      </div>

    </div>
  )

  return (
    <>
      <div className="container d-flex">
        {/* <Button
                    variant="dark"
                    className="col-3 d-flex flex-row align-items-center justify-content-center"
                >
                    <DownloadSimple />
                    <span className="px-2" onClick={() => downloadPDF()}>
                        Save PDF
                    </span>
                </Button> */}
        <div style={{ flex: "1" }}>
          {detectionDetails?.map((items) => (
            // <>
            <Tabs
              defaultActiveKey="info"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="info" title="Info">
                <div className="modal-row-item">
                  <span className="modal-label col-3">Hunt name</span>
                  <span className="modal-text">{items?.hunt_name}</span>
                </div>
                <div className="modal-row-item">
                  <span className="modal-label col-3">Description</span>
                  <span className="modal-text">
                    {removeHtmlChars(items?.detailed_description)}
                  </span>
                </div>
              </Tab>
              {/* <h3>Detections</h3> */}

              <Tab eventKey="detections" title="Detections">
                <div className="d-flex flex-column gap-3 mt-3">
                  {items.steps.map((item, index) => (
                    <>
                      {/* <div className='col-3'>
                                        <h6>{'Step ' + item?.step}</h6>
                                    </div> */}
                      <div className="col-12 border p-3 rounded-3">
                        <h6 className="px-2">{"Step " + item?.step}</h6>
                        <div className="modal-row-item">
                          <span className="modal-label col-3">Name</span>
                          <span className="modal-text">{item?.name}</span>
                        </div>
                        <div className="modal-row-item">
                          <span className="modal-label col-3">Description</span>
                          <span className="modal-text">
                            {escapeHtmlChars(item?.description, /<\/?p>/g)}
                          </span>
                        </div>
                        <div className="modal-row-item">
                          {detectionTimelineData[index]?._source.step ==
                            item.step ? (
                            <div
                              onClick={() =>
                                handleOpenModal(
                                  detectionTimelineData[index]?._source,
                                  true
                                )
                              }
                              className="rounded-3 alert alert-danger p-3 mb-0"
                            >
                              <h6>
                                {
                                  detectionTimelineData[index]?._source
                                    .detection_timestamp
                                }
                              </h6>
                              <p className="mb-0">
                                {decode(
                                  detectionTimelineData[index]?._source
                                    .description
                                )}
                              </p>
                            </div>
                          ) : (
                            <div className="col-12 text-center rounded-3 alert alert-secondary p-3 mb-0">
                              No data found!
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Tab>
            </Tabs>
          ))}
        </div>

        {show && (
          <>
            <Link onClick={handleToClose}>
              <X />
            </Link>
            <div style={{ flex: "1" }}>
              <GroupedDetections pageDetails={detailpage} />
            </div>

          </>
        )}

      </div>

      {/* {show && (
        <CustomModal
          title={"Detection details"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize="lg"
        ></CustomModal>
      )} */}
    </>
  );
};

export default HunterDetection;
