import {
  CM_LIST_REQUEST,
  CM_LIST_SUCCESS,
  CM_LIST_FAIL,
  CM_CREATE_REQUEST,
  CM_CREATE_SUCCESS,
  CM_CREATE_FAIL,
  CM_CREATE_RESET,
  CM_EDIT_REQUEST,
  CM_EDIT_SUCCESS,
  CM_EDIT_FAIL,
  CM_EDIT_RESET,
  CM_DELETE_REQUEST,
  CM_DELETE_SUCCESS,
  CM_DELETE_FAIL,
  CM_DELETE_RESET,
  CM_SINGLE_LIST_REQUEST,
  CM_SINGLE_LIST_SUCCESS,
  CM_SINGLE_LIST_FAIL,
} from "../../reduxConstants/caseManagement/CaseManagementConstants";

// CASE MANAGEMENT LIST
export const listCaseManagement = (state = {}, action) => {
  switch (action.type) {
    case CM_LIST_REQUEST:
      return { loading: true };
    case CM_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        case_: action.payload.data.cases,
      };
    case CM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CASE MANAGEMENT CREATE
export const createCaseManagement = (state = {}, action) => {
  switch (action.type) {
    case CM_CREATE_REQUEST:
      return { loading: true };
    case CM_CREATE_SUCCESS:
      return { loading: false, success: true, case: action.payload };
    case CM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CM_CREATE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// CASE MANAGEMENT EDIT
export const editCaseManagement = (state = {}, action) => {
  switch (action.type) {
    case CM_EDIT_REQUEST:
      return { loading: true };
    case CM_EDIT_SUCCESS:
      return { loading: false, success: true, editData: action.payload };
    case CM_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case CM_EDIT_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// CASE MANAGEMENT DELETE
export const deleteCaseManagement = (state = {}, action) => {
  switch (action.type) {
    case CM_DELETE_REQUEST:
      return { loading: true };
    case CM_DELETE_SUCCESS:
      return { loading: false, success: true, address_: action.payload };
    case CM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CM_DELETE_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// CASE MANAGEMENT SINGLE LIST VIEW
export const singleListView = (state = {}, action) => {
  switch (action.type) {
    case CM_SINGLE_LIST_REQUEST:
      return { loading: true };
    case CM_SINGLE_LIST_SUCCESS:
      return { loading: false, success: true, case: action.payload.data.case };
    case CM_SINGLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
