import React, { useEffect, useState } from 'react'
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver'
import { getDetailsWithHuntDetectionId } from '../../Methods/InvestigationMethods';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../Utils/Utils';
import TableViewMore from '../ViewMoreBtn/TableViewMore';
import { FileArrowDown } from 'phosphor-react';
import { Link } from 'react-router-dom';
const InvestigationDocExport = ({ exportData, fileName, type }) => {
    const [detectionData, setDetectionData] = useState([])

    useEffect(() => {
        setDetectionData([
            {
                "time": exportData?._source?.detection_timestamp,
                "date": exportData?._source?.detection_timestamp,
                "BrowserType": exportData?._source?.BrowserType,
                "ClientIP": exportData?._source?.ClientIP,
                "device_os": exportData?._source?.device_os,
                "result_status": exportData?._source?.result_status,
                "user_id": exportData?._source?.user_id,
                "error_number": exportData?._source?.error_number,
                "operation": exportData?._source?.operation,
                "request_type": exportData?._source?.request_type,
                "source_country": exportData?._source?.source_country,
                "source_ip": exportData?._source?.source_ip,
            }
        ])
    }, [])

    // const removeHtmlTags = (text) => {
    //     return text?.replace(/<[^>]*>/g, '');
    // };


    // useEffect(() => {
    //     getDetailsWithHuntDetectionId(exportData?._source?.hunt_detection_id)
    //         .then(
    //             ({
    //                 data: {
    //                     hits: { hits },
    //                 },
    //             }) => {
    //                 console.log("hits?.[0]?._source?.host_os", hits?.[0]?._source?.host_os)
    //                 setDetectionData([
    //                     {
    //                         Date: getDateFromTimestamp(hits?.[0]?._source?.detection_timestamp),
    //                         Time: getTimeFromTimestamp(hits?.[0]?._source?.detection_timestamp),
    //                         sourceCountry: hits?.[0]?._source?.source_country_name,
    //                         sourceIp: hits?.[0]?._source?.source_ip,
    //                         OS: hits?.[0]?._source?.host_os,
    //                     }
    //                 ])
    //             }
    //         )
    //         .catch((err) => {
    //             console.log("Error", err);
    //         });
    // }, [])
    // console.log(exportData, 'i');

    const generateDocument = () => {
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [
                        createStyledHeader(exportData.type === 'inc' || exportData.type === 'L2' ? 'Incident Name: ' : 'Detection Name: ', true, 24),
                        createStyledParagraph(`${exportData?._source?.detection_name}`, false, 18),

                        createStyledHeader(exportData.type === 'inc' || exportData.type === 'L2' ? 'Incident ID: ' : 'Detection ID: ', true, 24),
                        createStyledParagraph(exportData.type === 'inc' || exportData.type === 'L2' ? `${exportData?._source?.grp_id}` : `${exportData?._source?.hunt_detection_id}`, false, 18),

                        createStyledHeader('Date: ', true, 20),
                        createStyledParagraph(`${getDateFromTimestamp(exportData?._source?.detection_timestamp)}`, false, 18),
                        createStyledHeader('Time: ', true, 20),
                        createStyledParagraph(`${getTimeFromTimestamp(exportData?._source?.detection_timestamp)}`, false, 18),

                        createStyledHeader('Description: ', true, 24),
                        createStyledParagraph(
                            `${String(exportData?._source?.conclusions?.["Alert Description"]).replace(/<[^>]*>/g, '')}`,
                            false,
                            18
                        ),

                        createStyledHeader('Detection Observables: ', true, 24),
                        ...Object.entries(exportData?._source).map(([key, value]) =>
                            key !== 'detection_timestamp' && key !== 'conclusions' && // Exclude timestamp to avoid redundancy
                            createStyledParagraph(`${key}: ${value}`, true, 18)
                        ),

                        createStyledHeader('Recommendations & Actions: ', true, 24),
                        createStyledParagraph(
                            `${String(exportData?._source?.conclusions?.["Recommendations and actions"]).replace(/<[^>]*>/g, '')}`,
                            false,
                            18
                        ),
                        createStyledHeader('• Severity Level: ', true, 20),
                        createStyledParagraph(`${exportData?._source?.conclusions?.["Severity level"]}`, false, 18),
                        createStyledHeader('• Threat Score: ', true, 20),
                        createStyledParagraph(`${parseFloat(exportData?._source?.conclusions?.["Threat_Score"]).toFixed(2)}`, false, 18),
                        // ... Add other relevant sections from exportData as needed
                    ],
                },
            ],
        });

        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${fileName}.docx`);
        });
    };


    // const ListData = (key, value) => {
    //     if (value !== undefined && value !== "Source Country" && value !== "Source IP") {
    //         createStyledParagraph(`${1}. ${key}: ${value}`, false, 18)
    //     } else {
    //         createStyledParagraph(`${1}. ${key}: ${[value]}`, false, 18)
    //     }
    // }

    const createStyledHeader = (text, isBold, fontSize) => {
        return new Paragraph({
            children: [
                new TextRun({
                    text,
                    bold: isBold,
                    font: {
                        name: 'Arial',
                        family: 'sans-serif',
                    },
                    size: fontSize,
                }),
            ],
            spacing: { after: 100 },
        });
    };

    const createStyledParagraph = (text, isBold, fontSize) => {
        return new Paragraph({
            children: [
                new TextRun({
                    text,
                    bold: isBold,
                    font: {
                        name: 'Arial',
                        family: 'sans-serif',
                    },
                    size: fontSize,
                }),
            ],
            spacing: { after: 200 },
        });
    };

    return (
        <>
            {detectionData &&
                <Link onClick={generateDocument}>
                    <TableViewMore Icon={FileArrowDown} dataToggle={"Doc Export"} dataPlacement={"bottom"} />
                </Link>
            }
        </>
    );
};

export default InvestigationDocExport