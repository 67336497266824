import React, { useState, useEffect, useRef, useMemo } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData, getSoftwareList, getUniqueVendorCount, getUniqueSoftwareCount, getUniqueVendorList, getHostList, getUniqueVulnsCount, getUniqueAssetsCount, getUniqueVulnsWithExploits, getVulnsBySeverityQuery, getVulnsWithExploitListQuery, getUniqueHostWithRiskScoreQuery, getVulnsBySeverityFor7Query, getVulnsBySeverityFor30Query, getVulnsBySeverityFor90Query, getvulnsOverTime, getvulnsPerHostListQuery, getvulnsListQuery, getFirewallThreatLandscape, getFirewallThreatLandscapeFilter } from '../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,

} from 'chart.js';
import * as htmlToImage from 'html-to-image';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils'
import styles from './ThreatLandscape.styles';
import BarGraph from '../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon, Webcam } from 'phosphor-react';
import AddFilter from '../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../axios/axios';
import FilterList from '../../../components/FilterList/FilterList';
import InlineFilter from '../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple, ArrowClockwise } from "phosphor-react";
import ExpandedDocument from '../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../components/Loader/PlainCardLoader';
import CykitINVExport from '../../Investigate/InvestigationSummary/CyDashboards/Export';
import { isEmptyObject } from '../../../Utils/Utils';
import { getFirwallTableQuery } from '../../../Queries/CyKitInvestigateQuery';
import { totalUniqueSoftwareCountsQuery } from '../../../Queries/InvestigationQuery';
import { numberToText } from '../../../Utils/Utils';
import CySoftware from '../../Investigate/InvestigationSummary/CyDashboards/CySoftware';
import CyVulns from '../../Investigate/InvestigationSummary/CyDashboards/CyVulns';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ManagementDashbaordExport from '../../../components/PDF/managementDashboard/managementDashbaordExport';
import AddFilterTimeFrame from '../../../components/AddFilterTimeframe/AddFilterTimeframe';
import globalStyles from '../../../constants/globalStyles';
import FilterListDate from '../../../components/FilterListDate/FilterListDate';
import BarGraphRemove from '../../../components/Charts/BarGraphRemove';
import SearchBar from "../../../components/Search/ManagementDashboard/SearchBar";
import moment from 'moment';
import CyVulnsTabs from '../../Investigate/InvestigationSummary/CyDashboards/CyVulnsTabs';
import CySoftwareTabs from '../../Investigate/InvestigationSummary/CyDashboards/CySoftwareTabs';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CY_SOFTWARE = localStorage.getItem("INVESTIGATE_CY_SOFTWARE");

const ThreatLandscape = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);


  const [vulnbySeverityDataLoader, setVulnbySeverityDataLoader] = useState(true)

  const [vulnAgeLoader, setVulnAgeLoader] = useState(true)



  const [moderateGraphData, setModerateGraphData] = useState({})
  const [criticalGraphData, setCriticalGraphData] = useState({})
  const [severeGraphData, setSevereGraphData] = useState({})

  const [vulnOverTimeLoader, setVulnOverTimeLoader] = useState(true)
  const [aggregateData, setAggregateData] = useState()




  // destination country
  const [deniedSourceCountryLabel, setDeniedSourceCountryLabel] = useState([])
  const [deniedSourceCountryData, setDeniedSourceCountryData] = useState([])
  const [allowedSourceCountryLabel, setAllowedSourceCountryLabel] = useState([])
  const [allowedSourceCountryData, setAllowedSourceCountryData] = useState([])
  const [deniedSourceCountryWafLabel, setDeniedSourceCountryWafLabel] = useState([])
  const [deniedSourceCountryWafData, setDeniedSourceCountryWafData] = useState([])
  const [allowedSourceCountryWafLabel, setAllowedSourceCountryWafLabel] = useState([])
  const [allowedSourceCountryWafData, setAllowedSourceCountryWafData] = useState([])
  const [successfullAuthUser, setSuccessfullAuthUser] = useState([])
  const [successfullAuthUserCountry, setSuccessfullAuthUserCountry] = useState([])
  const [successfullAuthCountry, setSuccessfullAuthCountry] = useState([])
  const [totalDelivered, setTotalDelivered] = useState(0)
  const [totalQuarantined, setTotalQuarantined] = useState(0)
  const [totalSpam, setTotalSpam] = useState(0)
  const [totalEmailOthers, setTotalEmailOthers] = useState(0)

  const [totalRejected, setTotalRejected] = useState(0)
  const [emailThreatLabel, setEmailThreatLabel] = useState([])
  const [emailThreatData, setEmailThreatData] = useState([])
  const [totalHttp, setTotalHttp] = useState(0)
  const [totalRDP, setTotalRDP] = useState(0)
  const [totalSSH, setTotalSSH] = useState(0)
  const [totalOther, setTotalOther] = useState(0)
  const [deniedSourceCountryInternalLabel, setDeniedSourceCountryInternalLabel] = useState([])
  const [deniedSourceCountryInternalData, setDeniedSourceCountryInternalData] = useState([])
  const [allowedSourceCountryInternalLabel, setAllowedSourceCountryInternalLabel] = useState([])
  const [allowedSourceCountryInternalData, setAllowedSourceCountryInternalData] = useState([])
  const [allowedTypeInternalLabel, setAllowedTypeInternalLabel] = useState([])
  const [allowedTypeInternalData, setAllowedTypeInternalData] = useState([])
  const [deniedTypeInternalLabel, setDeniedTypeInternalLabel] = useState([])
  const [deniedTypeInternalData, setDeniedTypeInternalData] = useState([])
  const [allowedTypeLabel, setAllowedTypeabel] = useState([])
  const [allowedTypeData, setAllowedTypeData] = useState([])
  const [deniedTypeLabel, setDeniedTypeLabel] = useState([])
  const [deniedTypeData, setDeniedTypeData] = useState([])
  const [attackedSiteLabel, setAttackedSiteLabel] = useState([])
  const [attackedSiteData, setAttackedSiteData] = useState([])
  const [searchVulnerQuery, setSearchVulnerQuery] = useState()

  const [alertSourceLabel, setAlertSourceLabel] = useState([])
  const [alertSourceData, setAlertSourceData] = useState([])
  const [alertSourceLoader, setAlertSourcLoader] = useState(true)

  const [webTrafficData, setWebTrafficData] = useState([])
  const [webTrafficLabel, setWebTrafficLabel] = useState([])

  const [alertUserLabel, setAlertUserLabel] = useState([])
  const [alertUserData, setAlertUserData] = useState([])
  const [alertSeverityLabel, setAlertSeverityLabel] = useState([])
  const [alertSeverityData, setAlertSeverityData] = useState([])
  const [alertCategoryLabel, setAlertCategoryLabel] = useState([])
  const [alertCategoryData, setAlertCategoryData] = useState([])
  const [proxyCategoryLabel, setProxyCategoryLabel] = useState([])
  const [proxyCategoryData, setProxyCategoryData] = useState([])
  const [authListData, setAuthListData] = useState([])

  const [allowedSourceCountryWafLoader, setAllowedSourceCountryWafLoader] = useState(true)
  const [deniedSourceCountryWafLoader, setdeniedSourceCountryWafLoader] = useState(true)
  const [allowedSourceCountryLoader, setAllowedSourceCountryLoader] = useState(true)
  const [deniedSourceCountryLoader, setdeniedSourceCountryLoader] = useState(true)
  const [attackedSiteLoader, setAttackedSiteLoader] = useState(true)
  const [successfullAuthUserLoader, setSuccessfullAuthUserLoader] = useState(true)
  const [successfullAuthCountryLoader, setSuccessfullAuthCountryLoader] = useState(true)
  const [emailThreatLoader, setEmailThreatLoader] = useState(true)
  const [allowedSourceCountryInternalLoader, setAllowedSourceCountryInternalLoader] = useState(true)
  const [deniedSourceCountryInternalLoader, setdeniedSourceCountryInternalLoader] = useState(true)
  const [allowedTypeInternalLoader, setAllowedTypeInternalLoader] = useState(true)
  const [deniedTypeInternalLoader, setdeniedTypeInternalLoader] = useState(true)
  const [allowedTypeLoader, setAllowedTypeLoader] = useState(true)
  const [trafficByTypeLoader, setTrafficByTypeLoader] = useState(true)
  const [deniedTypeLoader, setdeniedTypeLoader] = useState(true)
  const [alertUserLoader, setAlertUserLoader] = useState(true)
  const [webTrafficLoader, setWebTrafficLoader] = useState(true)

  const [alertCategoryLoader, setAlertCategoryLoader] = useState(true)

  const [alertSeverityLoader, setAlertSeverityLoader] = useState(true)

  const [proxyCategoryLoader, setproxyCategoryLoader] = useState(true)

  const [alertedData, setAlertedData] = useState([])
  const [passedData, setPassedData] = useState([])
  const [blockedData, setBlockedData] = useState([])

  const SourcechartRef = useRef();
  const DestCreatedchartRef = useRef();
  // states for filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [showDate, setShowDate] = useState(true);

  const [sourceCountryLoader, setSourceCountryLoader] = useState(true)


  // console.log("LABELL :", eventCateLabel)
  const [loadTableData, setLoadTableData] = useState(true)


  const [isTableTabSelected, setIsTableTabSelected] = useState(false);

  // manage column
  const [showManageColumn, setShowManageColumn] = useState(false);


  const getPercentage = (numbers) => {
    var total = numbers.reduce((sum, num) => sum + num, 0);
    var percentages = numbers.map(number => (number / total) * 100);
    return percentages
  }

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
    setShowManageColumn(false)

  });
  const [expclickedNode, setExpClickedNode] = useState("")
  const [expclose, setExpClose] = useState(false)


  const allowedInternalByType = {
    labels: allowedTypeInternalLabel,
    datasets: [
      {
        label: "",
        data: allowedTypeInternalData,
        backgroundColor: [
          // "rgba(255, 99, 132, 0.8)",
          // "rgba(54, 162, 235, 0.8)",
          // "rgba(255, 206, 86, 0.8)",
          // "rgba(75, 192, 192, 0.8)",
          // "rgba(153, 102, 255, 0.8)",
          "#017D73",
          "#F5A700",
          "#D36086",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const deniedInternalByType = {
    labels: deniedTypeInternalLabel,
    datasets: [
      {
        label: "",
        data: deniedTypeInternalData,
        backgroundColor: [
          // "rgba(255, 99, 132, 0.8)",
          // "rgba(54, 162, 235, 0.8)",
          // "rgba(255, 206, 86, 0.8)",
          // "rgba(75, 192, 192, 0.8)",
          // "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "#d4510b",
          "#e3834f",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };


  const allowedByType = {
    labels: allowedTypeLabel,
    datasets: [
      {
        label: "",
        data: allowedTypeData,
        backgroundColor: [
          // "rgba(255, 99, 132, 0.8)",
          // "rgba(54, 162, 235, 0.8)",
          // "rgba(255, 206, 86, 0.8)",
          // "rgba(75, 192, 192, 0.8)",
          // "rgba(153, 102, 255, 0.8)",
          "#017D73",
          "#F5A700",
          "#D36086",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  const [allow2, deni2] = getPercentage([aggregateData?.total_allowed_traffic, aggregateData?.total_denied_traffic])
  const allowedDenied = {
    labels: [`Allowed - ${aggregateData?.total_allowed_traffic} (${allow2.toFixed(2)}%)`, `Denied - ${aggregateData?.total_denied_traffic} (${deni2.toFixed(2)}%)`],
    datasets: [
      {
        label: "",
        data: [aggregateData?.total_allowed_traffic, aggregateData?.total_denied_traffic],
        backgroundColor: [
          'rgba(255,0,0,0.4)',
          'rgba(255,165,0,0.4)',
        ],
        borderColor: [
          // "rgba(255, 99, 132, 1)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const [deliv, Quarant, Reject, Spam, OtherOp] = getPercentage([totalDelivered, totalQuarantined, totalRejected, totalSpam, totalEmailOthers])

  const emailActions = {
    labels: [`Delivered - ${totalDelivered} (${deliv.toFixed(2)}%)`, `Quarantined - ${totalQuarantined} (${Quarant.toFixed(2)}%)`, `Rejected - ${totalRejected} (${Reject.toFixed(2)}%)`, `Marked as Spam - ${totalSpam} (${Spam.toFixed(2)}%)`, `Other Operational Actions - ${totalEmailOthers} (${OtherOp.toFixed(2)}%)`],
    datasets: [
      {
        label: "",
        data: [totalDelivered, totalQuarantined, totalRejected, totalSpam, totalEmailOthers],
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        // borderColor: [
        //   // "rgba(255, 99, 132, 1)",
        //   // "rgba(54, 162, 235, 1)",
        //   // "rgba(255, 206, 86, 1)",
        //   // "rgba(75, 192, 192, 1)",
        //   // "rgba(153, 102, 255, 1)",
        //   // "rgba(255, 159, 64, 1)",
        // ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const [allow3, deni3] = getPercentage([aggregateData?.internal_firewall_allowed, aggregateData?.internal_firewall_denied])
  const allowedDeniedInternal = {
    labels: [`Allowed - ${aggregateData?.internal_firewall_allowed} (${allow3.toFixed(2)}%)`, `Denied - ${aggregateData?.internal_firewall_denied} (${deni3.toFixed(2)}%)`],
    datasets: [
      {
        label: "",
        data: [aggregateData?.internal_firewall_allowed, aggregateData?.internal_firewall_denied],
        backgroundColor: [
          'rgba(255,0,0,0.4)',
          'rgba(255,165,0,0.4)',
        ],
        borderColor: [
          // "rgba(255, 99, 132, 1)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };


  const [allow, deni] = getPercentage([aggregateData?.allowed_proxy_traffic, aggregateData?.denied_proxy_traffic])
  const allowedDeniedProxy = {
    labels: [`Allowed - ${aggregateData?.allowed_proxy_traffic} (${allow.toFixed(2)}%)`, `Denied - ${aggregateData?.denied_proxy_traffic} (${deni.toFixed(2)}%)`],
    datasets: [
      {
        label: "",
        data: [aggregateData?.allowed_proxy_traffic, aggregateData?.denied_proxy_traffic],
        backgroundColor: [
          'rgba(255,0,0,0.4)',
          'rgba(255,165,0,0.4)',
        ],
        borderColor: [
          // "rgba(255, 99, 132, 1)",
          // "rgba(54, 162, 235, 1)",
          // "rgba(255, 206, 86, 1)",
          // "rgba(75, 192, 192, 1)",
          // "rgba(153, 102, 255, 1)",
          // "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };

  const attackedOptions = {
    responsive: true,
    legend: {
      display: false
    },
    type: "bar"
    //   scales: {
    //     xAxes: [{
    //         stacked: true
    //     }],
    //     yAxes: [{
    //         stacked: true
    //     }]
    // }
  }

  // ///////////

  const deniedByType = {
    labels: deniedTypeLabel,
    datasets: [
      {
        label: "",
        data: deniedTypeData,
        backgroundColor: [
          "rgba(255, 159, 64, 0.8)",
          "#d4510b",
          "#e3834f",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const alertSeverityGraph = {
    labels: alertSeverityLabel,
    datasets: [
      {
        label: "",
        data: alertSeverityData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const webTrafficGraph = {
    labels: webTrafficLabel,
    datasets: [
      {
        label: "",
        data: webTrafficData,
        backgroundColor: [
          "#468B74",
          globalStyles.yellowDark1,
          'red',
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ],
  };
  const barChartoptions = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,

      },
    },
  };
  var pieChartOptions = {
    tooltips: {
      enabled: false
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(2) + "%";
          return percentage;
        },
        color: '#fff',
      }
    }
  };

  // for filter
  let count = [];
  let count2;
  const module = 'management-dashboard'
  const tab = 'threat-landscape'


  const attackedSiteChart = {
    "labels": attackedSiteLabel,
    "datasets": [
      {
        "label": "Alerted",
        "data": alertedData,
        backgroundColor: globalStyles.yellowDark1,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: 'bottom',
      },
      {
        "label": "Blocked",
        "data": blockedData,
        backgroundColor: 'red',
        borderColor: "red",
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: 'bottom',

      },
      {
        "label": "Passed",
        "data": passedData,
        backgroundColor: '#468B74',

      }
    ]
  };


  useEffect(() => {
    // manageDatas.map((item) => {
    //     item.isSelected = false
    // })
    setAllowedSourceCountryLoader(true)
    setdeniedSourceCountryLoader(true)
    setSuccessfullAuthUserLoader(true)
    setAllowedTypeInternalLoader(true)
    setdeniedTypeInternalLoader(true)
    setAllowedTypeLoader(true)
    setdeniedTypeLoader(true)

    getFirewallThreatLandscape(dateFilter).then(({ data }) => {
      setAggregateData(data?.hits?.hits?.[0]?._source)
      // setAllowedTypeLoader(false)
      setTrafficByTypeLoader(false)
      // setAllowedSourceCountryLoader(false)
      // setdeniedSourceCountryLoader(false)
      setSuccessfullAuthUserLoader(false)
    }).catch((err) => {
      console.log("Error", err)
      setTrafficByTypeLoader(false)
    })
  }, [])

  function checkDomainOrIP(text) {
    // Regular expression for matching an IP address
    const ipPattern = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

    // Regular expression for matching a domain test
    const domainPattern = /^([a-zA-Z0-9_-]+\.)*[a-zA-Z]{2,}$/;

    if (ipPattern.test(text)) {
      return false;
    } else if (domainPattern.test(text)) {
      if (text.includes("knpc"))
        return true;
    } else {
      return false;
    }
  }

  const getCategory = (data) => {
    // console.log('WHATTT :', data)
    return JSON?.parse(data)?.[0]
  }
  const donutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        // text: 'Chart.js Bar Chart',
      },
    }
  };

  useEffect(() => {
    setSuccessfullAuthUserLoader(true)
    setSuccessfullAuthCountryLoader(true)
    setEmailThreatLoader(true)
    setAllowedSourceCountryInternalLoader(true)
    setAllowedTypeInternalLoader(true)
    setdeniedTypeInternalLoader(true)
    setAllowedTypeLoader(true)
    setdeniedTypeLoader(true)
    let labelDeniedCountry = [];
    let dataDeniedCountry = [];
    let labelAllowCountry = [];
    let dataAllowCountry = [];
    if (aggregateData?.denied_source_countries?.length > 0) {

      aggregateData?.denied_source_countries?.map((item) => {
        labelDeniedCountry.push(item?.country === "" ? "--" : item?.country);
        dataDeniedCountry.push(item?.count);

      });
      setDeniedSourceCountryLabel(labelDeniedCountry)
      setDeniedSourceCountryData(dataDeniedCountry)
      setdeniedSourceCountryLoader(false)
    }
    else {
      setDeniedSourceCountryLabel(labelDeniedCountry)
      setDeniedSourceCountryData(dataDeniedCountry)
      // setdeniedSourceCountryLoader(false)
    }
    if (aggregateData?.allowed_source_countries?.length > 0) {

      aggregateData?.allowed_source_countries?.map((item) => {
        labelAllowCountry.push(item.country === "" ? "--" : item.country);
        dataAllowCountry.push(item?.count);

      });
      setAllowedSourceCountryLabel(labelAllowCountry)
      setAllowedSourceCountryData(dataAllowCountry)
      setAllowedSourceCountryLoader(false)
    }
    else {
      setAllowedSourceCountryLabel(labelAllowCountry)
      setAllowedSourceCountryData(dataAllowCountry)
      // setAllowedSourceCountryLoader(false)
    }
    var successful_auth_list_label = []
    var successful_auth_list_data = []

    if (aggregateData?.successful_auth?.length > 0) {
      aggregateData?.successful_auth.map((item) => {
        successful_auth_list_label.push(item.country === "" ? "--" : item.country);
        successful_auth_list_data.push(item.doc_count);

      });
      let geoData = [];
      let locations = successful_auth_list_label
      locations.forEach((location) => {

        location.key !== "NA" && geoData.push([location])
      })
      geoData = [[""], ...geoData]

      setSuccessfullAuthCountry(geoData)
      setSuccessfullAuthCountryLoader(false)

    }
    else {
      setSuccessfullAuthCountry([])
      let geoData = []
      geoData = [[""], ...geoData]
      setSuccessfullAuthUserCountry(geoData)
      setSuccessfullAuthCountryLoader(false)

    }
    if (aggregateData?.total_email_action?.length > 0) {
      var otherEmailC = 0
      aggregateData?.total_email_action?.map((item) => {
        if (item.country === "Delivered") {
          setTotalDelivered(item?.count)
        }
        else if (item.country === "Quarantined") {
          setTotalQuarantined(item?.count)

        }
        else if (item.country === "FilteredAsSpam") {
          setTotalSpam(item?.count)
        }
        else if (item.country === "Failed") {
          setTotalRejected(item?.count)
        }
        else {
          otherEmailC += item?.count
        }
      })
      setTotalEmailOthers(otherEmailC)
    }

    var emailThreatsLabel = []
    var emailThreatsData = []

    if (aggregateData?.email_threats?.length > 0) {

      aggregateData?.email_threats?.map((item) => {
        if (item?.country !== "") {
          emailThreatsLabel.push(item?.country === "" ? "--" : item?.country);
          emailThreatsData.push(item?.count);
        }
      });
      setEmailThreatLabel(emailThreatsLabel)
      setEmailThreatData(emailThreatsData)
      setEmailThreatLoader(false)
    }
    else {
      setEmailThreatLabel(emailThreatsLabel)
      setEmailThreatData(emailThreatsData)
      setEmailThreatLoader(false)
    }
    var httpServiceCount = 0
    var otherServiceCount = 0
    var rdpServiceCount = 0
    var sshServiceCount = 0

    if (aggregateData?.internal_firewall_list?.length > 0) {
      aggregateData?.internal_firewall_list?.map((item) => {
        if (item.country === "HTTP" || item.country === "HTTPS") {
          httpServiceCount += item?.count
        }
        else if (item.country === "RDP") {
          rdpServiceCount += item?.count
        }
        else if (item.country === "SSH") {
          sshServiceCount += item?.count
        }
        else {
          otherServiceCount += item?.count
        }
      })
      setTotalHttp(httpServiceCount)
      setTotalOther(otherServiceCount)
      setTotalRDP(rdpServiceCount)
      setTotalSSH(sshServiceCount)
    }
    let labelDeniedCountryInternal = [];
    let dataDeniedCountryInternal = [];
    let labelAllowCountryInternal = [];
    let dataAllowCountryInternal = [];
    if (aggregateData?.denied_internal_traffic_contries?.length > 0) {

      aggregateData?.denied_internal_traffic_contries?.map((item) => {
        if (item.country !== "United") {
          labelDeniedCountryInternal.push(item?.country === "" ? "--" : item?.country);
          dataDeniedCountryInternal.push(item?.count);
        }
      });
      setDeniedSourceCountryInternalLabel(labelDeniedCountryInternal)
      setDeniedSourceCountryInternalData(dataDeniedCountryInternal)
      setdeniedSourceCountryInternalLoader(false)
    }
    else {
      setDeniedSourceCountryInternalLabel(labelDeniedCountryInternal)
      setDeniedSourceCountryInternalData(dataDeniedCountryInternal)
      // setdeniedSourceCountryInternalLoader(false)
    }
    if (aggregateData?.allowed_internal_traffic_contries?.length > 0) {

      aggregateData?.allowed_internal_traffic_contries?.map((item) => {
        if (item.country !== "United") {
          labelAllowCountryInternal.push(item.country === "" ? "--" : item.country);
          dataAllowCountryInternal.push(item?.count);
        }
      });
      setAllowedSourceCountryInternalLabel(labelAllowCountryInternal)
      setAllowedSourceCountryInternalData(dataAllowCountryInternal)
      setAllowedSourceCountryInternalLoader(false)
    }
    else {
      setAllowedSourceCountryInternalLabel(labelAllowCountryInternal)
      setAllowedSourceCountryInternalData(dataAllowCountryInternal)
      setAllowedSourceCountryInternalLoader(false)
    }
    let labelDeniedTypeInternal = [];
    let dataDeniedTypeInternal = [];
    let labelAllowTypeInternal = [];
    let dataAllowTypeInternal = [];
    let labelDeniedType = [];
    let dataDeniedType = [];
    let labelAllowType = [];
    let dataAllowType = [];
    if (aggregateData?.denied_internal_traffic_by_type?.length > 0) {
      let webCountallow = 0
      let otherCountallow = 0
      let dnsCountallow = 0
      aggregateData?.denied_internal_traffic_by_type?.map((item) => {
        if (item.country === "80" || item.country === "443") {
          webCountallow += item?.count
        }
        else if (item?.country === "53") {
          dnsCountallow += item?.count
        }
        else {
          otherCountallow += item?.count
        }

      });

      // get percentage
      let [web, dns, other] = getPercentage([webCountallow, dnsCountallow, otherCountallow])

      labelDeniedTypeInternal.push(`Web - ${webCountallow?.toLocaleString('en-US')} (${web.toFixed(2)}%)`);
      dataDeniedTypeInternal.push(webCountallow);
      labelDeniedTypeInternal.push(`Dns - ${dnsCountallow?.toLocaleString('en-US')} (${dns.toFixed(2)} %)`);
      dataDeniedTypeInternal.push(dnsCountallow);
      labelDeniedTypeInternal.push(`Other - ${otherCountallow?.toLocaleString('en-US')} (${other.toFixed(2)}%)`);
      dataDeniedTypeInternal.push(otherCountallow);
      setDeniedTypeInternalLabel(labelDeniedTypeInternal)
      setDeniedTypeInternalData(dataDeniedTypeInternal)
      setdeniedTypeInternalLoader(false)
      console.log("web", webCountallow)

    }
    else {
      setDeniedTypeInternalLabel(labelDeniedTypeInternal)
      setDeniedTypeInternalData(dataDeniedTypeInternal)
      // setdeniedTypeInternalLoader(false)
    }
    if (aggregateData?.allowed_internal_traffic_by_type?.length > 0) {
      let webCount = 0
      let dnsCount = 0
      let otherCount = 0

      aggregateData?.allowed_internal_traffic_by_type?.map((item) => {
        if (item.country === "80" || item.country === "443") {
          webCount += item?.count
        }
        else if (item?.country === "53") {
          dnsCount += item?.count
        }
        else {
          otherCount += item?.count
        }

      });

      let [web, dns, other] = getPercentage([webCount, dnsCount, otherCount])
      labelAllowTypeInternal.push(`Web - ${webCount?.toLocaleString('en-US')} (${web.toFixed(2)} %)`);
      dataAllowTypeInternal.push(webCount);
      console.log("dns", dnsCount)
      labelAllowTypeInternal.push(`Dns - ${dnsCount?.toLocaleString('en-US')} (${dns.toFixed(2)} %)`);
      dataAllowTypeInternal.push(dnsCount);
      labelAllowTypeInternal.push(`Other - ${otherCount?.toLocaleString('en-US')} (${other.toFixed(2)} %)`);
      dataAllowTypeInternal.push(otherCount);
      console.log("dnsl", labelAllowTypeInternal)

      setAllowedTypeInternalLabel(labelAllowTypeInternal)
      setAllowedTypeInternalData(dataAllowTypeInternal)
      setAllowedTypeInternalLoader(false)
      console.log("web", webCount)
    }
    else {
      setAllowedTypeInternalLabel(labelAllowTypeInternal)
      setAllowedTypeInternalData(dataAllowTypeInternal)
      // setAllowedTypeInternalLoader(false)
    }
    if (aggregateData?.denied_traffic_by_type?.length > 0) {
      let webCount = 0
      let otherCount = 0
      let dnsCount = 0
      aggregateData?.denied_traffic_by_type?.map((item) => {
        if (item.country === "80" || item.country === "443") {
          webCount += item?.count
        }
        else if (item.country === "53") {
          dnsCount += item?.count
        }
        else {
          otherCount += item?.count
        }

      });
      // get percentage
      let [web, dns, other] = getPercentage([webCount, dnsCount, otherCount])

      labelDeniedType.push(`Web - ${webCount?.toLocaleString('en-US')} (${web.toFixed(2)} %)`);
      dataDeniedType.push(webCount);
      labelDeniedType.push(`Dns - ${dnsCount?.toLocaleString('en-US')} (${dns.toFixed(2)} %)`);
      dataDeniedType.push(dnsCount);
      labelDeniedType.push(`Other - ${otherCount?.toLocaleString('en-US')} (${other.toFixed(2)} %)`);
      dataDeniedType.push(otherCount);
      setDeniedTypeLabel(labelDeniedType)
      setDeniedTypeData(dataDeniedType)
      setdeniedTypeLoader(false)
    }
    else {
      setDeniedTypeLabel(labelDeniedType)
      setDeniedTypeData(dataDeniedType)
      // setdeniedTypeLoader(false)
    }
    if (aggregateData?.allowed_traffic_by_type?.length > 0) {
      let webCount = 0
      let otherCount = 0
      let dnsCount = 0

      aggregateData?.allowed_traffic_by_type?.map((item) => {
        if (item.country === "80" || item.country === "443") {
          webCount += item?.count
        }
        else if (item.country === "53") {
          dnsCount += item?.count
        }
        else {
          otherCount += item?.count
        }

      });

      // get percentage
      let [web, dns, other] = getPercentage([webCount, dnsCount, otherCount])

      labelAllowType.push(`Web - ${webCount?.toLocaleString('en-US')} (${web.toFixed(2)} %)`);
      dataAllowType.push(webCount);
      labelAllowType.push(`Dns - ${dnsCount?.toLocaleString('en-US')} (${dns.toFixed(2)} %)`);
      dataAllowType.push(dnsCount);
      labelAllowType.push(`Other - ${otherCount?.toLocaleString('en-US')} (${other.toFixed(2)} %)`);
      dataAllowType.push(otherCount);
      setAllowedTypeabel(labelAllowType)
      setAllowedTypeData(dataAllowType)
      setTimeout(() => {
        setAllowedTypeLoader(false)
      }, 3000)
    }
    else {
      setAllowedTypeabel(labelAllowType)
      setAllowedTypeData(dataAllowType)
      setTimeout(() => {
        setAllowedTypeLoader(false)
      }, 3000)
    }
    var alertCatLabel = []
    var alertCatData = []
    setAlertCategoryLoader(true)
    if (aggregateData?.alert_evidence_by_categories?.length > 0) {

      aggregateData?.alert_evidence_by_categories?.map((item) => {
        alertCatLabel.push(item?.country === "" ? "--" : getCategory(item?.country));
        alertCatData.push(item?.count);

      });
      setAlertCategoryLabel(alertCatLabel)
      setAlertCategoryData(alertCatData)
      setTimeout(() => {
        setAlertCategoryLoader(false)
      }, 3000)
    }
    else {
      setAlertCategoryLabel(alertCatLabel)
      setAlertCategoryData(alertCatData)
      setTimeout(() => {
        setAlertCategoryLoader(false)
      }, 3000)
    }
    var alertSevLabel = []
    var alertSevData = []
    setAlertSeverityLoader(true)

    let getSeverityPercentageData = []
    aggregateData?.alert_evidence_by_severity?.map((data) => {
      getSeverityPercentageData.push(data?.count)
    })
    let severityPercentageArr = getPercentage(getSeverityPercentageData)

    if (aggregateData?.alert_evidence_by_severity?.length > 0) {

      aggregateData?.alert_evidence_by_severity?.map((item, i) => {
        alertSevLabel.push(item?.country === "" ? "--" : `${item?.country} - ${item?.count} (${severityPercentageArr[i].toFixed(2)} %)`);
        alertSevData.push(item?.count);

      });
      setAlertSeverityLabel(alertSevLabel)
      setAlertSeverityData(alertSevData)
      setTimeout(() => {
        setAlertSeverityLoader(false)
      }, 3000)
    }
    else {
      setAlertSeverityLabel(alertSevLabel)
      setAlertSeverityData(alertSevData)
      setTimeout(() => {
        setAlertSeverityLoader(false)
      }, 3000)
    }
    var alertByUserData = []
    var alertByUserLabel = []
    setAlertUserLoader(true)
    if (aggregateData?.alert_evidence_by_user?.length > 0) {
      aggregateData?.alert_evidence_by_user?.map((item) => {
        alertByUserLabel.push(item?.country === "" ? "--" : item?.country);
        alertByUserData.push(item?.count);

      });
      setAlertUserLabel(alertByUserLabel)
      setAlertUserData(alertByUserData)
      setAlertUserLoader(false)
    }
    else {
      setAlertUserLabel(alertByUserLabel)
      setAlertUserData(alertByUserData)
      setAlertUserLoader(false)
    }
    var proxyCatData = []
    var proxyCatLabel = []
    setproxyCategoryLoader(true)
    if (aggregateData?.proxy_traffic_category?.length > 0) {
      aggregateData?.proxy_traffic_category?.map((item) => {
        proxyCatLabel.push(item?.country === "" ? "--" : item?.country);
        proxyCatData.push(item?.count);

      });
      setProxyCategoryLabel(proxyCatLabel)
      setProxyCategoryData(proxyCatData)
      setTimeout(() => {
        setproxyCategoryLoader(false)
      }, [3000])
    }
    else {
      setProxyCategoryLabel(proxyCatLabel)
      setProxyCategoryData(proxyCatData)
      setTimeout(() => {
        setproxyCategoryLoader(false)
      }, [3000])
    }

    var webTrafficActionData = []
    var webTrafficActionLabel = []
    setWebTrafficLoader(true)
    let getPercentageData = []
    aggregateData?.web_traffic_actions?.map((data) => {
      getPercentageData.push(data?.count)
    })
    let percentageArr = getPercentage(getPercentageData)
    if (aggregateData?.web_traffic_actions?.length > 0) {
      let blockedCountallow = 0
      let alertedCountallow = 0
      let passedCountallow = 0
      aggregateData?.web_traffic_actions?.map((item, i) => {
        // webTrafficActionLabel.push(item?.country === "" ? "--" : `${item?.country} - ${item?.count} (${percentageArr[i].toFixed(2)} %)`);
        // webTrafficActionData.push(item?.count);
        if (item.country === "alerted" || item.country === "LOG") {
          alertedCountallow += item?.count
        }
        else if (item?.country === "blocked" || item?.country === "DENY") {
          blockedCountallow += item?.count
        }
        else if (item?.country === "passed") {
          passedCountallow += item?.count
        }
      });


      // get percentage
      let [pass, alert, block] = getPercentage([passedCountallow, alertedCountallow, blockedCountallow])

      webTrafficActionLabel.push(`Passed - ${passedCountallow?.toLocaleString('en-US')} (${pass.toFixed(2)}%)`);
      webTrafficActionData.push(passedCountallow);
      webTrafficActionLabel.push(`Alerted - ${alertedCountallow?.toLocaleString('en-US')} (${alert.toFixed(2)} %)`);
      webTrafficActionData.push(alertedCountallow);
      webTrafficActionLabel.push(`Blocked - ${blockedCountallow?.toLocaleString('en-US')} (${block.toFixed(2)}%)`);
      webTrafficActionData.push(blockedCountallow);
      setWebTrafficLabel(webTrafficActionLabel)
      setWebTrafficData(webTrafficActionData)
      setWebTrafficLoader(false)
    }
    else {
      setWebTrafficLabel(webTrafficActionLabel)
      setWebTrafficData(webTrafficActionData)
      setWebTrafficLoader(false)
    }

    let labelDeniedCountryWaf = [];
    let dataDeniedCountryWaf = [];
    let labelAllowCountryWaf = [];
    let dataAllowCountryWaf = [];
    if (aggregateData?.denied_source_countries_waf?.length > 0) {

      aggregateData?.denied_source_countries_waf?.map((item) => {
        labelDeniedCountryWaf.push(item?.country === "" ? "--" : item?.country);
        dataDeniedCountryWaf.push(item?.count);

      });
      setDeniedSourceCountryWafLabel(labelDeniedCountryWaf)
      setDeniedSourceCountryWafData(dataDeniedCountryWaf)
      setdeniedSourceCountryWafLoader(false)
    }
    else {
      setDeniedSourceCountryWafLabel(labelDeniedCountryWaf)
      setDeniedSourceCountryWafData(dataDeniedCountryWaf)
      // setdeniedSourceCountryWafLoader(false)
    }
    if (aggregateData?.allowed_source_countries_waf?.length > 0) {
      aggregateData?.allowed_source_countries_waf?.map((item) => {
        labelAllowCountryWaf.push(item.country === "" ? "--" : item.country);
        dataAllowCountryWaf.push(item?.count);

      });
      setAllowedSourceCountryWafLabel(labelAllowCountryWaf)
      setAllowedSourceCountryWafData(dataAllowCountryWaf)
      setAllowedSourceCountryWafLoader(false)
    }
    else {
      setAllowedSourceCountryWafLabel(labelAllowCountryWaf)
      setAllowedSourceCountryWafData(dataAllowCountryWaf)
      // setAllowedSourceCountryWafLoader(false)
    }
    let labelAlertSource = [];
    let dataAlertSource = [];
    if (aggregateData?.alert_evidence_by_source?.length > 0) {
      aggregateData?.alert_evidence_by_source?.map((item) => {
        labelAlertSource.push(item.country === "" ? "--" : item.country);
        dataAlertSource.push(item?.count);

      });
      setAlertSourceLabel(labelAlertSource)
      setAlertSourceData(dataAlertSource)
      setAlertSourcLoader(false)
    }
    else {
      setAlertSourceLabel(labelAlertSource)
      setAlertSourceData(dataAlertSource)
      setAlertSourcLoader(false)
    }


    let labelAttackedSites = [];
    let dataAttackedSites = [];
    const alerted = { data: [] };
    const blocked = { data: [] };
    const passed = { data: [] };
    const attackLabels = []

    if (aggregateData?.waf_allow_deny_by_domain?.length > 0) {

      aggregateData?.waf_allow_deny_by_domain?.forEach(entry => {
        if (checkDomainOrIP(entry?.country)) {

          attackLabels.push(entry?.country)

        }
      });
      aggregateData?.baracuda_allowed_sites?.forEach(entry => {
        if (checkDomainOrIP(entry?.country)) {
          attackLabels.push(entry?.country)

        }
      });
      var uniqueList = [...new Set(attackLabels)];
      // aggregateData?.waf_allow_deny_by_domain?.forEach(entry => {
      //     entry.action.buckets.forEach(bucket => {
      //         const severity = bucket.key;
      //         const count = bucket.doc_count;
      //         if (severity === 'alerted') {
      //           alerted.data.push(count);
      //         } else if (severity === 'blocked') {
      //           blocked.data.push(count);
      //         }
      //         else if (severity === 'passed') {
      //           passed.data.push(count);
      //         }
      //         else{
      //           console.log("alerted1",alerted)

      //           alerted.data.push("0");
      //           blocked.data.push("0");
      //           passed.data.push("0");
      //         }
      //     });
      // });
      for (let i = 0; i < uniqueList.length; i++) {
        alerted.data.push(0);
        blocked.data.push(0);
        passed.data.push(0);
      }

      aggregateData?.waf_allow_deny_by_domain?.forEach((entry, index) => {
        entry.action.buckets.forEach(bucket => {
          const severity = bucket.key;
          const count = bucket.doc_count;

          // Update the corresponding value in the array
          if (severity === 'alerted') {
            let alertcount = 0
            aggregateData?.barracude_log_sites?.filter(item => { if (item?.country === uniqueList[index]) { alertcount = alertcount + item?.count } })
            alerted.data[index] = count + alertcount;

          } else if (severity === 'blocked') {
            let blockedcount = 0
            aggregateData?.barracude_denied_sites?.filter(item => { if (item?.country === "knpc.com") { blockedcount = blockedcount + item?.count } })
            blocked.data[index] = count + blockedcount;
          } else if (severity === 'passed') {
            let passedcount = 0
            aggregateData?.baracuda_allowed_sites?.filter(item => { if (item?.country === uniqueList[index]) { passedcount = passedcount + item?.count } })
            passed.data[index] = count + passedcount;
          }
        });
      });
      for (let i = 0; i < uniqueList.length; i++) {
        if (uniqueList[i] === "knpc.com") {
          let alertcount = 0
          aggregateData?.baracuda_log_sites?.filter(item => { if (item?.country === "172.16.35.5") { alertcount = alertcount + item?.count } })
          alerted.data[i] = alerted.data[i] + alertcount;
          let blockedcount = 0
          aggregateData?.barracude_denied_sites?.filter(item => { if (item?.country === "172.16.35.5") { blockedcount = blockedcount + item?.count } })
          blocked.data[i] = blocked.data[i] + blockedcount;
        }
        let passedcount = 0
        aggregateData?.baracuda_allowed_sites?.filter(item => { if (item?.country === uniqueList[i]) { passedcount = passedcount + item?.count } })
        passed.data[i] = passed.data[i] + passedcount;
      }


      console.log("alerted", alerted)
      console.log("blocked", blocked)
      console.log("passed", passed)

      setAlertedData(alerted?.data)
      setBlockedData(blocked?.data)
      setPassedData(passed?.data)
      setAttackedSiteLabel(uniqueList)
      setAttackedSiteLoader(false)
    }
    else {
      setAlertedData(alerted?.data)
      setBlockedData(blocked?.data)
      setPassedData(passed?.data)
      setAttackedSiteLabel(uniqueList)
      setAttackedSiteLoader(false)

    }
    aggregateData?.succesful_auth_list && setAuthListData(aggregateData?.succesful_auth_list)
  }, [aggregateData])


  const severityChartData = {
    "labels": moderateGraphData?.labels,
    "datasets": [
      {
        "label": "Moderate",
        "data": moderateGraphData?.data,
        "borderColor": 'rgba(255,165,0,0.4)',
      },
      {
        "label": "Severe",
        "data": severeGraphData?.data,
        "borderColor": 'rgba(255,0,0,0.4)',
      },
      {
        "label": "Critical",
        "data": criticalGraphData?.data,
        "borderColor": 'rgba(128,0,128,0.4)',
      }
    ]
  };


  // pdf export 
  const sectionOneRefImage = useRef(null);
  const webFirewallRefImage = useRef(null);
  // THREAT LANDSCAPE FROM INTERNET
  const threatLandscapeRefImage = useRef(null);
  const sectionTwoRefImage = useRef(null);
  const internalFirewallRefImage = useRef(null);
  const proxyRefImage = useRef(null);
  const o365RefImage = useRef(null);
  const vendorsRefImage = useRef(null);
  const softwareSectionRefImage = useRef(null);
  const vulnerabilitiesTwoRefImage = useRef(null);
  const vulnerabilitiesRefImage = useRef(null);


  const [downloadBtn, setDownloadBtn] = useState(true)
  const [imageBlob, setImageBlob] = useState({
    sectionOne: null,
    webFirewall: null,
    threatLandscape: null,
    sectionTwo: null,
    internalFirewall: null,
    proxy: null,
    o365: null,
    vendors: null,
    // // software: null,
    vulnerabilities: null,
    // vulnerabilitiesTwo: null
  })

  const convertToBlob = () => {
    setImageBlob({
      sectionOne: null,
      webFirewall: null,
      threatLandscape: null,
      sectionTwo: null,
      internalFirewall: null,
      proxy: null,
      o365: null,
      vendors: null,
      // software: null,
      vulnerabilities: null,
      // vulnerabilitiesTwo: null
    })

    htmlToImage.toBlob(sectionOneRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        sectionOne: blob
      }));
    });
    htmlToImage.toBlob(webFirewallRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        webFirewall: blob
      }));
    });

    htmlToImage.toBlob(threatLandscapeRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        threatLandscape: blob
      }));
    });



    htmlToImage.toBlob(sectionTwoRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        sectionTwo: blob
      }));
    });

    htmlToImage.toBlob(internalFirewallRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        internalFirewall: blob
      }));
    });

    htmlToImage.toBlob(proxyRefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        proxy: blob
      }));
    });

    htmlToImage.toBlob(o365RefImage.current).then((blob) => {
      setImageBlob((prevState) => ({
        ...prevState,
        o365: blob
      }));
    });

    // vendorsRefImage && (
    //   htmlToImage.toBlob(vendorsRefImage.current).then((blob) => {
    //     setImageBlob((prevState) => ({
    //       ...prevState,
    //       vendors: blob
    //     }));
    //   })
    // )

    // // softwareSectionRefImage && (
    // //   htmlToImage.toBlob(softwareSectionRefImage.current).then((blob) => {
    // //     setImageBlob((prevState) => ({
    // //       ...prevState,
    // //       software: blob
    // //     }));
    // //   })
    // // )

    // vulnerabilitiesRefImage && (
    //   htmlToImage.toBlob(vulnerabilitiesRefImage.current).then((blob) => {
    //     setImageBlob((prevState) => ({
    //       ...prevState,
    //       vulnerabilities: blob
    //     }));
    //   })
    // )

    // // vulnerabilitiesTwoRefImage && (
    // //   htmlToImage.toBlob(vulnerabilitiesTwoRefImage.current).then((blob) => {
    // //     setImageBlob((prevState) => ({
    // //       ...prevState,
    // //       vulnerabilitiesTwo: blob
    // //     }));
    // //   })
    // // )

    setTimeout(() => {
      // setDownloadBtn(false)
    }, 2000)
  };

  const getFilteredTable = (filter, date) => {

    setAllowedSourceCountryLoader(true)
    setdeniedSourceCountryLoader(true)
    setSuccessfullAuthUserLoader(true)
    setAllowedTypeInternalLoader(true)
    setdeniedTypeInternalLoader(true)
    setAllowedTypeLoader(true)
    setdeniedTypeLoader(true)
    setAggregateData([])
    if (date !== undefined) {
      setShowDate(false)
      getFirewallThreatLandscapeFilter(date).then(({ data }) => {
        setAggregateData(data)
        setTrafficByTypeLoader(false)
        // setAllowedSourceCountryLoader(false)
        // setdeniedSourceCountryLoader(false)
        setSuccessfullAuthUserLoader(false)
      }).catch((err) => {
        setAllowedSourceCountryLoader(false)
        setdeniedSourceCountryLoader(false)
        setSuccessfullAuthUserLoader(false)
        setAllowedTypeInternalLoader(false)
        setdeniedTypeInternalLoader(false)
        setTrafficByTypeLoader(false)
        setTimeout(() => {
          setAllowedTypeLoader(false)
        }, 3000)
        setdeniedTypeLoader(false)
        console.log("Error", err)
      })
    }
    else {
      setShowDate(true)
      getFirewallThreatLandscape(dateFilter).then(({ data }) => {
        setAggregateData(data?.hits?.hits?.[0]?._source)
        setTrafficByTypeLoader(false)
        // setAllowedSourceCountryLoader(false)
        // setdeniedSourceCountryLoader(false)
        setSuccessfullAuthUserLoader(false)
      }).catch((err) => {
        console.log("Error", err)
      })
    }
  }

  useEffect(() => {
    setDownloadBtn(true)
    if (allowedSourceCountryLoader === false &&
      allowedTypeLoader === false &&
      trafficByTypeLoader === false &&
      deniedTypeLoader === false &&
      deniedSourceCountryLoader === false &&
      allowedSourceCountryWafLoader === false &&
      deniedSourceCountryWafLoader === false &&
      successfullAuthCountryLoader === false &&
      emailThreatLoader === false &&
      allowedSourceCountryInternalLoader === false &&
      allowedTypeInternalLoader === false &&
      deniedTypeInternalLoader === false &&
      deniedSourceCountryInternalLoader === false &&
      proxyCategoryLoader === false &&
      alertUserLoader === false &&
      alertSeverityLoader === false &&
      alertCategoryLoader === false
    ) {
      setTimeout(() => {
        convertToBlob()

      }, 10000)
    }
  }, [allowedSourceCountryLoader,
    allowedTypeLoader,
    deniedTypeLoader,
    deniedSourceCountryLoader,
    allowedSourceCountryWafLoader,
    deniedSourceCountryWafLoader,
    successfullAuthCountryLoader,
    emailThreatLoader,
    allowedSourceCountryInternalLoader,
    allowedTypeInternalLoader,
    deniedTypeInternalLoader,
    deniedSourceCountryInternalLoader,
    proxyCategoryLoader,
    alertUserLoader,
    alertCategoryLoader,
    alertSeverityLoader,
    trafficByTypeLoader])

  const sectionRefs = useRef({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute('id');
            navigate(`#${id}`);
          }
        });
      },
      { threshold: 0.1 }
    );

    const sections = Object.values(sectionRefs.current);
    sections.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);
  return (
    <div className="col-12 position-relative" style={styles.section1}>

      <div
        className="p-3 col-12 flex-wrap gap-2 bg-white rounded-3 mb-3 shadow p-3 rounded"
        style={styles.filterWrap}
      >
        <div className='d-flex gap-2 justify-content-between align-items-center '>


          <div className='ms-2 mt-2'>
            {/* <a style={styles.link} href="#etl">External threat Landscape</a>
            <a style={styles.link}  href="#wafts">Web Application Firewall Traffic Summary</a>
            <a style={styles.link} href="#email">Email</a>
            <a style={styles.link}  href="#itl">Internal threat landscape</a>
            <a style={styles.link}  href="#wproxy"> Web Access Summary of Users</a>
            <a style={styles.link}  href="#o365"> Threat Detections from Workstations </a>
            <a style={styles.link}  href="#software">Softwares</a>
            <a style={styles.link}  href="#vulns">Vulnerabilities</a> */}
            {(location.hash === "" || location.hash === "#etl") ?
              <h3>External Threat Landscape</h3> :
              location.hash === "#wafts" ? <h3>Web Application Firewall Traffic Summary</h3> :
                location.hash === "#oauth" ? <h3>Successful authentications from Outside Kuwait</h3> :
                  location.hash === "#email" ? <h3>Email</h3> :
                    location.hash === "#itl" ? <h3>Internal Threat landscape</h3> :
                      location.hash === "#wproxy" ? <h3>Web Access Summary of Users</h3> :
                        location.hash === "#o365" ? <h3>Threat Detections from Workstations</h3> :
                          location.hash === "#software" ? <h3>Installed Software Summary</h3> :
                            location.hash === "#vulns" ? <h3>Vulnerabilities</h3> : ""
            }
          </div>



          <div className='d-flex align-items-center gap-2'>
            {showDate ? <span className="px-2 py-1" style={styles.link}>
              {`${moment().format("DD-MM-YYYY")}`}
            </span> :
              <div className='mt-2'>
                <FilterListDate
                  filter={filter}
                  setFilter={setFilter}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTable}
                  module={module}
                  tab={tab}
                />
              </div>
            }
            {downloadBtn ?
              (
                <PDFDownloadLink document={<ManagementDashbaordExport imageBlob={imageBlob} />} fileName="management-dashboard-threat_landscape">
                  {({ blob, url, loading, error }) =>
                    <Button className='btn btn-light' disabled>
                      Download <Spinner animation="border" role="status" size="sm" />
                    </Button>
                  }
                </PDFDownloadLink>
              ) : (
                <PDFDownloadLink document={<ManagementDashbaordExport imageBlob={imageBlob} />} fileName="management-dashboard-threat_landscape">
                  {({ blob, url, loading, error }) =>
                    <Button className='btn btn-light'>Download PDF</Button>
                  }
                </PDFDownloadLink>
              )
            }
            <div>
              <Button
                // className="position-relative"
                ref={ref}
                onClick={() => setShowAddFilter(!showAddFilter)}
              >
                Filter
              </Button>
              {showAddFilter && <AddFilterTimeFrame
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setShowAddFilter={setShowAddFilter}
                getFilteredTable={getFilteredTable}
                columnValues={[
                ]}
                module={module}
                tab={tab}
              />}
            </div>
          </div>
        </div>
        {/* {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )} */}

      </div>

      {/* table datas */}
      <div className="col-12 d-flex flex-column rounded-3 position-relative">
        <div className='col-12 d-flex flex-column'>
          <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 gap-3">
            <div className='col-12 flex-fill border rounded-3 p-4 mb-0 bg-white'>
              <h2>Threat Landscape </h2>
              <p className='mb-0' style={styles.paraGraph}>This dashboard offers a panoramic view, seamlessly integrating data from various security solutions
                deployed across the organization, including Antivirus (AV), Endpoint Detection and Response (EDR),
                Firewall, Intrusion Prevention System (IPS), Network Detection and Response (NDR), and Vulnerability
                Scanners. This integrated approach provides a comprehensive overview of security posture, offering realtime insights into the orchestration of traffic attacks and vulnerabilities. Uncover emerging threats, track
                potential risks, and fortify your defences with a unified and informed strategy.</p>
              {/* <Link className='btn btn-dark mt-3' to={"/ti/activebytes"} style={{ color: globalStyles.white }}>View all</Link> */}
            </div>
            <div className="d-flex flex-column rounded-3 gap-3 p-4">

              <div className='d-flex flex-column col-10' ref={(el) => (sectionRefs.current.etl = el)} id='etl'>
                <h3>External Threat Landscape</h3>
              </div>
              <div ref={sectionOneRefImage} className='bg-white p-3'>
                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 mb-3">
                  <div className='col-6 gap-3'>
                    <p className='col-10' style={styles.paraGraph}>Firewall may be configured to either deny or just
                      alert if a threat pattern is matched. This may be
                      later blocked by other security devices such as
                      WAF</p>
                    <div className='d-flex gap-3'>
                      <div className="flex-fill rounded-3 p-3 justify-content-center align-items-center" style={styles.overviewTile}>
                        <div>
                          <h2>Total Traffic</h2>
                          <h4>{(aggregateData?.total_denied_traffic && aggregateData?.total_denied_traffic) ? numberToText(aggregateData?.total_allowed_traffic + aggregateData?.total_denied_traffic) : "--"}</h4>

                        </div>
                      </div>
                      <div className='col-8 d-flex flex-column gap-3'>
                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Allowed</h4>
                            <h5>{aggregateData?.total_allowed_traffic ? numberToText(aggregateData?.total_allowed_traffic) : "--"}</h5>
                          </div>
                        </div>
                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Denied</h4>
                            <h5>{aggregateData?.total_denied_traffic ? numberToText(aggregateData?.total_denied_traffic) : "--"}</h5>

                          </div>
                        </div>
                        {/* <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4>{aggregateData?.total_firewall_rule_based_denied ? numberToText(aggregateData?.total_firewall_rule_based_denied) : "--"}</h4>
                            <span style={styles.label}>Firewall Deny (Rule based) </span>
                          </div>
                        </div> */}
                        {/* <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4>{aggregateData?.total_traffic ? numberToText(aggregateData?.total_traffic - (aggregateData?.total_allowed_traffic + aggregateData?.total_denied_traffic)) : "--"}</h4>
                            <span style={styles.label}>Other Network Operations </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* section 2 */}
                  <div className='col-6'>
                    <div className='d-flex flex-column'>
                      <h3>Classification of Threat Traffic</h3>
                      <p style={styles.paraGraph}>The below is the summary of pattern matches of various attacks
                        observed by perimeter firewall.</p>
                    </div>

                    <div className='col-12 d-flex flex-column gap-3'>
                      <div className='d-flex gap-3'>
                        <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Malware</h4>
                            <h5>{aggregateData?.total_malware_identifed ? numberToText(aggregateData?.total_malware_identifed) : "--"}</h5>
                          </div>
                        </div>
                        <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Attack Pattern</h4>
                            <h5>{aggregateData?.total_attacks ? numberToText(aggregateData?.total_attacks) : "--"}</h5>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex gap-3'>
                        <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Vulnerabilities</h4>
                            <h5>{aggregateData?.total_vulnerabilites ? numberToText(aggregateData?.total_vulnerabilites) : "--"}</h5>
                          </div>
                        </div>
                        <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>No of Alerted Threats</h4>
                            <h5>{aggregateData?.no_of_alerted_threats ? numberToText(aggregateData?.no_of_alerted_threats) : "--"}</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                {/*<div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationPortCount ? destinationPortCount : "--"}</h4>
                                                <span>Unique Destination Ports</span>
                                            </div>
                                        </div> */}

                {/* allowed denied */}

                <div class="d-flex flex-row gap-3 mb-3">
                  <div className="col-4 flex-fill p-4 rounded-3 border">
                    <h5>Traffic By Action</h5>
                    {trafficByTypeLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {allowedTypeData.length || allowedTypeData.length !== 0 ? (
                          <Doughnut options={barChartoptions} ChartLabel={allowedTypeLabel} data={allowedDenied} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>

                  <div className="col-4 flex-fill p-4 rounded-3 border">
                    <h5>Allowed Traffic By Type </h5>
                    {deniedTypeLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {allowedTypeData.length || allowedTypeData.length !== 0 ? (
                          <Doughnut options={barChartoptions} ChartLabel={allowedTypeLabel} data={allowedByType} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-4 p-4 rounded-3 border">
                    <h5>Denied Traffic By Type</h5>
                    {deniedTypeLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {deniedTypeData.length || deniedTypeData.length !== 0 ? (
                          <Doughnut options={barChartoptions} ChartLabel={deniedTypeLabel} data={deniedByType} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
                <div class="d-flex flex-row gap-3 mb-3">

                  <div className="col-6 p-4 rounded-3 border">
                    <div className='d-flex flex-row justify-content-between'>
                      <h5>Top Countries With Allowed Traffic</h5>
                      <button type="button" class="btn btn-light" onClick={
                        () => {
                          setAllowedSourceCountryLoader(true)

                          let labelAllowCountry = [];
                          let dataAllowCountry = [];
                          if (aggregateData?.allowed_source_countries?.length > 0) {

                            aggregateData?.allowed_source_countries?.map((item) => {
                              labelAllowCountry.push(item.country === "" ? "--" : item.country);
                              dataAllowCountry.push(item?.count);

                            });
                            setAllowedSourceCountryLabel(labelAllowCountry)
                            setAllowedSourceCountryData(dataAllowCountry)
                            setTimeout(() => {
                              setAllowedSourceCountryLoader(false)
                            }, 1000);
                          }
                          else {
                            setAllowedSourceCountryLabel(labelAllowCountry)
                            setAllowedSourceCountryData(dataAllowCountry)
                            setAllowedSourceCountryLoader(false)
                          }
                        }
                      }><ArrowClockwise size={20} /></button>

                    </div>
                    {allowedSourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {allowedSourceCountryData.length || allowedSourceCountryData.length !== 0 ? (
                          <BarGraphRemove options={barChartoptions} ChartLabel={allowedSourceCountryLabel} ChartData={allowedSourceCountryData} ChartTitle={""} fieldData={allowedSourceCountryData} fieldlabel={allowedSourceCountryLabel} setFieldData={setAllowedSourceCountryData} setFieldLabel={setAllowedSourceCountryLabel} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                  <div className="col-6 p-4 rounded-3 border">
                    <div className="flex-fill p-4">
                      <div className='d-flex flex-row justify-content-between'>
                        <h5>Top Countries With Denied Traffic</h5>
                        <button type="button" class="btn btn-light" onClick={() => {
                          setdeniedSourceCountryLoader(true)
                          let labelDeniedCountry = []
                          let dataDeniedCountry = []
                          if (aggregateData?.denied_source_countries?.length > 0) {
                            aggregateData?.denied_source_countries?.map((item) => {
                              labelDeniedCountry.push(item?.country === "" ? "--" : item?.country);
                              dataDeniedCountry.push(item?.count);

                            });
                            setDeniedSourceCountryLabel(labelDeniedCountry)
                            setDeniedSourceCountryData(dataDeniedCountry)
                            setTimeout(() => {
                              setdeniedSourceCountryLoader(false)
                            }, 1000);
                          }
                          else {
                            setDeniedSourceCountryLabel(labelDeniedCountry)
                            setDeniedSourceCountryData(dataDeniedCountry)
                            setdeniedSourceCountryLoader(false)

                          }
                        }
                        }><ArrowClockwise size={20} /></button>
                      </div>
                      {deniedSourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {deniedSourceCountryData.length || deniedSourceCountryData.length !== 0 ? (
                            <BarGraphRemove options={barChartoptions} ChartLabel={deniedSourceCountryLabel} ChartData={deniedSourceCountryData} ChartTitle={""} module={module} tab={tab} fieldData={deniedSourceCountryData} fieldlabel={deniedSourceCountryLabel} setFieldData={setDeniedSourceCountryData} setFieldLabel={setDeniedSourceCountryLabel} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>

                </div>

              </div>
              <div ref={webFirewallRefImage} className='bg-white p-3'>


                <div className='col-12 flex-fill border rounded-3 p-4 mb-0' ref={(el) => (sectionRefs.current.wafts = el)} id='wafts'>
                  <p className='mb-0' style={styles.paraGraph}>It is recommended to deny the traffic from countries using explicit rules if the Organization’s Business requirement doesn’t involve activities
                    from such countries , to reduce the threat landscape and potential incidents.</p>
                </div>


                <div className='d-flex flex-column col-10 py-4'>
                  <h3>Web Application Firewall Traffic Summary</h3>
                </div>
                <div class="d-flex flex-row gap-3">
                  <div className="col-6 p-4 rounded-3 border">
                    <h5>Attacked Sites And Actions</h5>
                    {allowedSourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {allowedSourceCountryData.length || allowedSourceCountryData.length !== 0 ? (
                          <Bar options={attackedOptions} ChartLabel={allowedSourceCountryLabel} data={attackedSiteChart} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>

                  <div className="flex-fill p-4 rounded-3 border">
                    <h5>Web Traffic Actions</h5>
                    {webTrafficLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className="col-12">
                        {webTrafficData?.length || webTrafficData.length !== 0 ? (
                          <Doughnut options={donutChartOptions} ChartLabel={webTrafficLabel} data={webTrafficGraph} ChartTitle={"Vulnerbility By Severity"} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div ref={threatLandscapeRefImage} className='bg-white p-3'>
                <div class="d-flex flex-row gap-3 mb-3">
                  <div className="col-6 p-4 rounded-3 border">
                    <div className='d-flex flex-row justify-content-between'>
                      <h5>Top Countries With Web Traffic (Non Malicious)</h5>
                      <button type="button" class="btn btn-light" onClick={() => {
                        setAllowedSourceCountryWafLoader(true)
                        let labelAllowCountryWaf = []
                        let dataAllowCountryWaf = []
                        if (aggregateData?.allowed_source_countries_waf?.length > 0) {
                          aggregateData?.allowed_source_countries_waf?.map((item) => {
                            labelAllowCountryWaf.push(item.country === "" ? "--" : item.country);
                            dataAllowCountryWaf.push(item?.count);

                          });
                          setAllowedSourceCountryWafLabel(labelAllowCountryWaf)
                          setAllowedSourceCountryWafData(dataAllowCountryWaf)
                          setTimeout(() => {
                            setAllowedSourceCountryWafLoader(false)
                          }, 1000);
                        }
                        else {
                          setAllowedSourceCountryWafLabel(labelAllowCountryWaf)
                          setAllowedSourceCountryWafData(dataAllowCountryWaf)
                          setAllowedSourceCountryWafLoader(false)
                        }
                      }}><ArrowClockwise size={20} /></button>
                    </div>
                    {allowedSourceCountryWafLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {allowedSourceCountryWafData.length || allowedSourceCountryWafData.length !== 0 ? (
                          <BarGraphRemove options={barChartoptions} ChartLabel={allowedSourceCountryWafLabel} ChartData={allowedSourceCountryWafData} ChartTitle={""} module={module} tab={tab} fieldData={allowedSourceCountryWafData} fieldlabel={allowedSourceCountryWafLabel} setFieldData={setAllowedSourceCountryWafData} setFieldLabel={setAllowedSourceCountryWafLabel} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                  <div className="flex-fill p-4 rounded-3 border">
                    <div className='d-flex flex-row justify-content-between'>
                      <h5>Top Countries With Web Traffic (Malicious)</h5>
                      <button type="button" class="btn btn-light" onClick={() => {
                        setdeniedSourceCountryWafLoader(true)
                        let labelDeniedCountryWaf = []
                        let dataDeniedCountryWaf = []
                        if (aggregateData?.denied_source_countries_waf?.length > 0) {

                          aggregateData?.denied_source_countries_waf?.map((item) => {
                            labelDeniedCountryWaf.push(item?.country === "" ? "--" : item?.country);
                            dataDeniedCountryWaf.push(item?.count);

                          });
                          setDeniedSourceCountryWafLabel(labelDeniedCountryWaf)
                          setDeniedSourceCountryWafData(dataDeniedCountryWaf)
                          setTimeout(() => {
                            setdeniedSourceCountryWafLoader(false)
                          }, 1000);
                        }
                        else {
                          setDeniedSourceCountryWafLabel(labelDeniedCountryWaf)
                          setDeniedSourceCountryWafData(dataDeniedCountryWaf)
                          setTimeout(() => {
                            setdeniedSourceCountryWafLoader(false)
                          }, 1000);
                        }
                      }}><ArrowClockwise size={20} /></button>
                    </div>
                    {deniedSourceCountryWafLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {deniedSourceCountryWafData.length || deniedSourceCountryWafData.length !== 0 ? (
                          <BarGraphRemove options={barChartoptions} ChartLabel={deniedSourceCountryWafLabel} ChartData={deniedSourceCountryWafData} ChartTitle={""} module={module} tab={tab} fieldData={deniedSourceCountryWafData} fieldlabel={deniedSourceCountryWafLabel} setFieldData={setDeniedSourceCountryWafData} setFieldLabel={setDeniedSourceCountryWafLabel} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>

                <div className='col-12 flex-fill border rounded-3 p-4 mb-4'>
                  <p className='mb-0' style={styles.paraGraph}>Web application Firewall may be configured to either deny or
                    just alert if a threat signature pattern is matched.</p>
                </div>

                <div class="d-flex flex-row gap-3 mb-3 p-4" id="oauth">
                  <div className="col-8 rounded-3 border p-3">
                    <h5>Successful authentications from Outside Kuwait</h5>
                    {successfullAuthCountryLoader ? (<PlainCardLoader width="200px" />) : (
                      <>
                        {successfullAuthCountry?.length || successfullAuthCountry?.length !== 0 ? (
                          // <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} ChartTitle={"Source Country"} />
                          <span className="modal-text">
                            <Chart
                              chartEvents={[
                                {
                                  eventName: "select",
                                  callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart();
                                    const selection = chart.getSelection();
                                    if (selection.length === 0) return;

                                    const region = successfullAuthCountry[selection[0].row + 1];
                                    // MapClick("cybSourceCountry", "Source Country", region)
                                  },
                                },
                              ]}
                              chartType="GeoChart"
                              width="100%"
                              height="500px"
                              data={successfullAuthCountry}
                            />

                            {/* <div style={{ display: 'flex', justifyContent: 'center', gap: 30, flexWrap: 'wrap', marginTop: 30 }}>
                              {successfullAuthCountry?.slice(1)?.map((countryItem) => (
                                <span style={{ fontSize: 18 }}>{countryItem}</span>
                              ))}
                            </div> */}
                          </span>
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </>
                    )}

                  </div>


                  {aggregateData?.succesful_auth_list?.length !== 0 &&
                    <div class="d-flex col-4 flex-row gap-3">
                      <div className="p-4 rounded-3 border flex-fill" >
                        <div className='d-flex flex-row justify-content-between'>

                          <div className='d-flex justify-content-end'>
                            <SearchBar data={aggregateData?.succesful_auth_list} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={setAuthListData} />
                          </div>
                          <div className=''>
                            <Link to="/investigate/o365_active_directory" target="_blank" className="btn btn-light">See More</Link>                          </div>
                        </div>


                        <div className='mt-3 custom-scrollbar' style={styles.tableWrapSmx}>
                          {false ? (
                            <div className="col-6">
                              <TableLoader rowCount={25} />
                            </div>
                          ) : authListData?.length !== 0 ? (
                            <Table borderless hover>
                              <thead className="fixed-head">
                                <tr>
                                  <th>User</th>
                                  <th>Country</th>
                                  <th>Source</th>
                                  {/* <th className="text-center">Is SSO User</th>
                                                  <th>Status</th>
                                                  <th className="text-right">Manage</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {authListData?.map((item) => (
                                  <tr>
                                    <td>
                                      {item?.country}
                                    </td>
                                    <td>
                                      {item?.doc?.hits?.hits?.[0]?._source?.cybSourceCountry}
                                    </td>
                                    <td>
                                      {item?.doc?.hits?.hits?.[0]?._source?.["DeviceProperties.OS"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <NoDataFound errorText={"No data Found"} />
                          )}
                        </div>
                      </div>
                    </div>
                  }


                </div>

              </div>
              {/*  Email */}
              <div className='col-12 d-flex align-items-center justify-content-between bg-white p-3' style={{ marginBottom: "-18px" }} ref={(el) => (sectionRefs.current.email = el)} id='email'>
                <div className='d-flex flex-column col-10'>
                  <h2>Email</h2>
                  <p style={styles.paraGraph}>The email activity section of the dashboard provides a summary of the organization's email-related information and activity. This section is crucial for monitoring and analyzing email communications within the organization to ensure security, compliance, and operational efficiency.</p>
                </div>
              </div>
              <div ref={sectionTwoRefImage} className="flex-fill d-flex col-12 flex-column rounded-3 gap-3 bg-white p-3">
                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 mb-3">
                  <div className="d-flex flex-row flex-fill rounded-3 gap-3">
                    <div className='col-12 d-flex flex-row gap-3'>
                      <div className="flex-fill rounded-3 p-3 alert alert-info mb-0">
                        <h4 style={styles.label}>Total</h4>
                        <h5>{aggregateData?.total_email_count ? numberToText(aggregateData?.total_email_count) : "--"}</h5>
                      </div>

                      <div className="flex-fill rounded-3 p-3 alert alert-success mb-0">
                        <h4 style={styles.label}>Delivered</h4>
                        <h5>{totalDelivered ? numberToText(totalDelivered) : "--"}</h5>
                      </div>

                      <div className="flex-fill rounded-3 p-3 alert alert-danger mb-0">
                        <h4 style={styles.label}>Quarantined</h4>
                        <h5>{totalQuarantined ? numberToText(totalQuarantined) : "--"}</h5>
                      </div>

                      <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                        <h4 style={styles.label}>Rejected</h4>
                        <h5>{totalRejected ? numberToText(totalRejected) : "--"}</h5>
                      </div>
                      <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                        <h4 style={styles.label}>Marked as Spam</h4>
                        <h5>{totalSpam ? numberToText(totalSpam) : "--"}</h5>
                      </div>
                      <div className="flex-fill rounded-3 p-3 alert alert-primary mb-0">
                        <h4 style={styles.label}>Other Operational Actions</h4>
                        <h5>{totalEmailOthers ? numberToText(totalEmailOthers) : "--"}</h5>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="flex-fill d-flex flex-row gap-3">
                  <div className=" col-6 flex-fill p-4 rounded-3 border">
                    <h5>Email Actions</h5>
                    {emailThreatLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {emailThreatData.length || emailThreatData.length !== 0 ? (
                          <Doughnut options={barChartoptions} ChartLabel={allowedTypeLabel} data={emailActions} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                  <div className="col-6 p-4 rounded-3 border">
                    <div className='d-flex flex-row justify-content-between'>
                      <h5>Email Threats</h5>

                      <button type="button" class="btn btn-light" onClick={() => {
                        setEmailThreatLoader(true)
                        let emailThreatsLabel = []
                        let emailThreatsData = []
                        if (aggregateData?.email_threats?.length > 0) {
                          aggregateData?.email_threats?.map((item) => {
                            if (item?.country !== "") {
                              emailThreatsLabel.push(item?.country === "" ? "--" : item?.country);
                              emailThreatsData.push(item?.count);
                            }
                          });
                          setEmailThreatLabel(emailThreatsLabel)
                          setEmailThreatData(emailThreatsData)
                          setTimeout(() => {
                            setEmailThreatLoader(false)
                          }, 1000);
                        }
                        else {
                          setEmailThreatLabel(emailThreatsLabel)
                          setEmailThreatData(emailThreatsData)
                          setEmailThreatLoader(false)
                        }
                      }}><ArrowClockwise size={20} /></button>
                    </div>
                    {emailThreatLoader ? (<PlainCardLoader width="200px" />) : (
                      <div>
                        {emailThreatData.length || emailThreatData.length !== 0 ? (
                          <BarGraphRemove options={barChartoptions} ChartLabel={emailThreatLabel} ChartData={emailThreatData} ChartTitle={""} module={module} tab={tab} fieldData={emailThreatData} fieldlabel={emailThreatLabel} setFieldData={setEmailThreatData} setFieldLabel={setEmailThreatLabel} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
              {/*  Email end */}
              {/*  Internal Firewall */}
              <div ref={(el) => (sectionRefs.current.itl = el)} id='itl'>
                <div ref={internalFirewallRefImage} className='bg-white p-3' >
                  <h2>INTERNAL THREAT LANDSCAPE</h2>
                  <div className='d-flex flex-column col-10 py-4'>
                    <h3>Internal Firewall Traffic Overview</h3>
                  </div>
                  <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 mb-3">
                    <div className='flex-fill col-6 gap-3'>
                      <div className='d-flex gap-3'>
                        <div className="flex-fill rounded-3 p-3 justify-content-center align-items-center" style={styles.overviewTile}>
                          <div>
                            <h2 style={styles.label}>Total</h2>
                            <h5>{aggregateData?.total_internal_firewall_traffic ? numberToText(aggregateData?.total_internal_firewall_traffic) : "--"}</h5>
                          </div>
                        </div>
                        <div className='col-4 d-flex flex-column gap-3'>
                          <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                            <div>
                              <h4 style={styles.label}>Allowed</h4>
                              <h5>{aggregateData?.internal_firewall_allowed ? numberToText(aggregateData?.internal_firewall_allowed) : "--"}</h5>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                            <div>
                              <h4 style={styles.label}>Denied</h4>
                              <h5>{aggregateData?.internal_firewall_denied ? numberToText(aggregateData?.internal_firewall_denied) : "--"}</h5>
                            </div>
                          </div>
                        </div>

                        <div className='flex-fill col-4 d-flex flex-column gap-3'>
                          <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                            <div>
                              <h4 style={styles.label}>Traffic between Internal Network </h4>
                              <h5>{aggregateData?.total_internal_network_traffic ? numberToText(aggregateData?.total_internal_network_traffic) : "--"}</h5>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                            <div>
                              <h4 style={styles.label}>Traffic towards Internet</h4>
                              <h5>{aggregateData?.traffic_towards_internet ? numberToText(aggregateData?.traffic_towards_internet) : "--"}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* section 2 */}
                    <div className='flex-fill col-6 position-relative'>
                      <div style={{ position: 'absolute', top: -50 }}>
                        <p style={styles.paraGraph}>The below is the summary of allowed traffic and the respective
                          services</p>
                      </div>
                      <div className='d-flex flex-row gap-3'>
                        <div className="col-4 flex-fill rounded-3 p-3 justify-content-center align-items-center" style={styles.overviewTile}>
                          <div>
                            <h2 style={styles.label}>SSH</h2>
                            <h5>{totalSSH ? numberToText(totalSSH) : "--"}</h5>
                          </div>
                        </div>
                        <div className='col-8 d-flex flex-column gap-3'>
                          <div className='d-flex gap-3'>
                            <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                              <div>
                                <h4 style={styles.label}>RDP</h4>
                                <h5>{totalRDP ? numberToText(totalRDP) : "--"}</h5>
                              </div>
                            </div>
                            <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                              <div>
                                <h4 style={styles.label}>Web (HTTP/S)</h4>
                                <h5>{totalHttp ? numberToText(totalHttp) : "--"}</h5>
                              </div>
                            </div>
                          </div>
                          <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                            <div>
                              <h4 style={styles.label}>Other Services</h4>
                              <h5>{totalOther ? numberToText(totalOther) : "--"}</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div class="d-flex flex-row gap-3">
                    <div className="col-4 flex-fill p-4 rounded-3 border">
                      <h5>Traffic By Action</h5>
                      {trafficByTypeLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {allowedTypeData.length || allowedTypeData.length !== 0 ? (
                            <Doughnut options={barChartoptions} ChartLabel={allowedTypeLabel} data={allowedDeniedInternal} ChartTitle={""} module={module} tab={tab} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                    <div className="col-4 flex-fill p-4 rounded-3 border">
                      <h5>Allowed Traffic By Type (Towards Internet) </h5>
                      {allowedTypeInternalLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {allowedTypeInternalData.length || allowedTypeInternalData.length !== 0 ? (
                            <Doughnut options={barChartoptions} ChartLabel={allowedTypeInternalLabel} data={allowedInternalByType} ChartTitle={""} module={module} tab={tab} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                    <div className="col-4 p-4 rounded-3 border">
                      <h5>Denied Traffic By Type (Towards Internet)</h5>
                      {deniedTypeInternalLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {deniedTypeInternalData.length || deniedTypeInternalData.length !== 0 ? (
                            <Doughnut options={barChartoptions} ChartLabel={deniedTypeInternalLabel} data={deniedInternalByType} ChartTitle={"Vulnerbility By Severity"} module={module} tab={tab} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                  </div>


                  {/* <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-3">
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                  <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                    
                  </div>
                </div> */}
                <div class="d-flex flex-row gap-3 mt-3">
                  <div className="col-6 p-4 rounded-3 border">
                    <div className='d-flex flex-row justify-content-between'>
                      <h5>Top Countries With Allowed Traffic Towards Internet</h5>
                      <button type="button" class="btn btn-light" onClick={() => {
                        setAllowedSourceCountryInternalLoader(true)
                        let dataAllowCountryInternal = []
                        let labelAllowCountryInternal = []
                        if (aggregateData?.allowed_internal_traffic_contries?.length > 0) {
                          aggregateData?.allowed_internal_traffic_contries?.map((item) => {
                            if (item.country !== "United" && item.country !== "%{cybDestCountry}" && item.country !== "%{cybSourceCountry}") {
                              labelAllowCountryInternal.push(item.country === "" ? "--" : item.country);
                              dataAllowCountryInternal.push(item?.count);
                            }
                          });
                          setAllowedSourceCountryInternalLabel(labelAllowCountryInternal)
                          setAllowedSourceCountryInternalData(dataAllowCountryInternal)
                          setTimeout(() => {

                            setAllowedSourceCountryInternalLoader(false)
                          }
                          )
                        }
                        else{
                          setAllowedSourceCountryInternalLabel(labelAllowCountryInternal)
                          setAllowedSourceCountryInternalData(dataAllowCountryInternal)
                          setAllowedSourceCountryInternalLoader(false)

                        }
                      }} ><ArrowClockwise size={20} /></button>
                      </div>
                      {allowedSourceCountryInternalLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {allowedSourceCountryInternalData.length || allowedSourceCountryInternalData.length !== 0 ? (
                            <BarGraphRemove options={barChartoptions} ChartLabel={allowedSourceCountryInternalLabel} ChartData={allowedSourceCountryInternalData} ChartTitle={""} module={module} tab={tab} fieldData={allowedSourceCountryInternalData} fieldlabel={allowedSourceCountryInternalLabel} setFieldData={setAllowedSourceCountryInternalData} setFieldLabel={setAllowedSourceCountryInternalLabel} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                    <div className="flex-fill p-4 rounded-3 border">
                      <div className='d-flex flex-row justify-content-between'>
                        <h5>Top Countries With Denied Traffic Towards Internet</h5>
                        <button type="button" class="btn btn-light" onClick={() => {
                          let labelDeniedCountryInternal = []
                          let dataDeniedCountryInternal = []
                          setdeniedSourceCountryInternalLoader(true)
                          if (aggregateData?.denied_internal_traffic_contries?.length > 0) {

                            aggregateData?.denied_internal_traffic_contries?.map((item) => {
                              if (item.country !== "United" && item.country !== "%{cybDestCountry}" && item.country !== "%{cybSourceCountry}") {
                                labelDeniedCountryInternal.push(item?.country === "" ? "--" : item?.country);
                                dataDeniedCountryInternal.push(item?.count);
                              }
                            });
                            setDeniedSourceCountryInternalLabel(labelDeniedCountryInternal)
                            setDeniedSourceCountryInternalData(dataDeniedCountryInternal)
                            setTimeout(() => {
                              setdeniedSourceCountryInternalLoader(false)

                            }, 1000);
                          }
                          else {
                            setDeniedSourceCountryInternalLabel(labelDeniedCountryInternal)
                            setDeniedSourceCountryInternalData(dataDeniedCountryInternal)
                            setdeniedSourceCountryInternalLoader(false)
                          }
                        }}><ArrowClockwise size={20} /></button>
                      </div>
                      {deniedSourceCountryInternalLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {deniedSourceCountryInternalData.length || deniedSourceCountryInternalData.length !== 0 ? (
                            <BarGraphRemove options={barChartoptions} ChartLabel={deniedSourceCountryInternalLabel} ChartData={deniedSourceCountryInternalData} ChartTitle={""} module={module} tab={tab} fieldData={deniedSourceCountryInternalData} fieldlabel={deniedSourceCountryInternalLabel} setFieldData={setDeniedSourceCountryInternalData} setFieldLabel={setDeniedSourceCountryInternalLabel} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>

                </div>
              </div>

              {/*  Internal Firewall end */}
              {/*  proxy */}
              <div ref={(el) => (sectionRefs.current.wproxy = el)} id="wproxy">
                <div ref={proxyRefImage} className='bg-white p-3' >
                  <div className='d-flex flex-column col-10 py-4'>
                    <h3>Web Access Summary of Users ( From proxy )</h3>
                  </div>
                  <div className='d-flex gap-3'>
                    <div className='d-flex flex-column col-4 gap-3'>
                      <div className='d-flex flex-column gap-3'>
                        <div className="rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Total</h4>

                            <h5>{aggregateData?.total_proxy_traffic ? numberToText(aggregateData?.total_proxy_traffic) : "--"}</h5>
                          </div>
                        </div>
                        <div className="rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Allowed</h4>
                            <h5>{aggregateData?.allowed_proxy_traffic ? numberToText(aggregateData?.allowed_proxy_traffic) : "--"}</h5>
                          </div>
                        </div>
                        <div className="rounded-3 p-3" style={styles.overviewTile}>
                          <div>
                            <h4 style={styles.label}>Denied</h4>
                            <h5>{aggregateData?.denied_proxy_traffic ? numberToText(aggregateData?.denied_proxy_traffic) : "--"}</h5>
                          </div>
                        </div>
                      </div>


                      <div className="col-12 d-flex flex-column gap-3 p-0 rounded-3">
                        <div className="col-12 flex-fill p-4 rounded-3 border">
                          <h5>Traffic By Action</h5>
                          {trafficByTypeLoader ? (<PlainCardLoader width="200px" />) : (
                            <div className='col-12'>
                              {allowedTypeData.length || allowedTypeData.length !== 0 ? (
                                <Doughnut options={barChartoptions} ChartLabel={allowedTypeLabel} data={allowedDeniedProxy} ChartTitle={""} module={module} tab={tab} />
                              ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                  <NoDataFound errorText={"No data found"} />
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      </div>
                    </div>

                    <div class="col-8 gap-3">
                      <div className="p-4 rounded-3 border">
                        <div className='d-flex flex-row justify-content-between'>
                          <h5>Web Browsing Categories</h5>
                          <button type="button" class="btn btn-light" onClick={() => {
                            setproxyCategoryLoader(true)
                            let proxyCatLabel = []
                            let proxyCatData = []

                            if (aggregateData?.proxy_traffic_category?.length > 0) {
                              aggregateData?.proxy_traffic_category?.map((item) => {
                                proxyCatLabel.push(item?.country === "" ? "--" : item?.country);
                                proxyCatData.push(item?.count);

                              });
                              setProxyCategoryLabel(proxyCatLabel)
                              setProxyCategoryData(proxyCatData)
                              setTimeout(() => {
                                setproxyCategoryLoader(false)
                              }, [1000])
                            }
                            else {
                              setProxyCategoryLabel(proxyCatLabel)
                              setProxyCategoryData(proxyCatData)
                              setproxyCategoryLoader(false)
                            }
                          }}><ArrowClockwise size={20} /></button>
                        </div>
                        {proxyCategoryLoader ? (<PlainCardLoader width="200px" />) : (
                          <div className='col-12'>
                            {proxyCategoryData.length || proxyCategoryData.length !== 0 ? (
                              <BarGraphRemove options={barChartoptions} ChartLabel={proxyCategoryLabel} ChartData={proxyCategoryData} ChartTitle={""} module={module} tab={tab} fieldData={proxyCategoryData} fieldlabel={proxyCategoryLabel} setFieldData={setProxyCategoryData} setFieldLabel={setProxyCategoryLabel} />
                            ) : (
                              <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  proxy end */}
              {/*  o365 */}
              <div ref={(el) => (sectionRefs.current.o365 = el)} id="o365">
                <div ref={o365RefImage} className='bg-white p-3'>
                  <div className='d-flex flex-column col-10 py-4'>
                    <h3>Threat Detections from Workstations ( Antivirus ) </h3>
                  </div>
                  <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white mb-3">
                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                      <div>
                        <h4 style={styles.label}>Total</h4>
                        <h5>{aggregateData?.total_alert_evidence_count ? numberToText(aggregateData?.total_alert_evidence_count) : "--"}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row gap-3 mb-3">
                    <div className="col-6 p-4 rounded-3 border">
                      <h5>Threats By Severity</h5>
                      {alertSeverityLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {alertSeverityData.length || alertSeverityData.length !== 0 ? (
                            <Doughnut options={barChartoptions} ChartLabel={alertSeverityLabel} data={alertSeverityGraph} ChartTitle={""} module={module} tab={tab} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                    <div className="flex-fill p-4 rounded-3 border">
                      <h5>Threats Category</h5>
                      {alertCategoryLoader ? (<PlainCardLoader width="200px" />) : (
                        <div className='col-12'>
                          {alertCategoryData.length || alertCategoryData.length !== 0 ? (
                            <BarGraph options={barChartoptions} ChartLabel={alertCategoryLabel} ChartData={alertCategoryData} ChartTitle={""} module={module} tab={tab} />
                          ) : (
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                  {aggregateData?.alert_evidence_by_title?.length !== 0 &&
                    <div class="d-flex flex-row gap-3">
                      <div className="col-6 p-4 rounded-3 border flex-fill" >
                        <div className='mt-3' >
                          {false ? (
                            <div className="col-6">
                              <TableLoader rowCount={25} />
                            </div>
                          ) : aggregateData?.alert_evidence_by_title?.length !== 0 ? (
                            <Table borderless hover>
                              <thead className="fixed-head">
                                <tr>
                                  <th>Top Threats</th>
                                  <th>Count</th>
                                  {/* <th className="text-center">Is SSO User</th>
                                                  <th>Status</th>
                                                  <th className="text-right">Manage</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {aggregateData?.alert_evidence_by_title?.map((item) => (
                                  <tr>
                                    <td>
                                      {item?.country}
                                    </td>
                                    <td>
                                      {item?.count}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <NoDataFound errorText={"No data Found"} />
                          )}
                        </div>
                      </div>
                      <div className="col-6 p-4 rounded-3 border mt-2">
                        <h5>Detections By Source</h5>
                        {alertSourceLoader ? (<PlainCardLoader width="200px" />) : (
                          <div className='col-12'>
                            {alertSourceData.length || alertSourceData.length !== 0 ? (
                              <BarGraph options={barChartoptions} ChartLabel={alertSourceLabel} ChartData={alertSourceData} ChartTitle={""} module={module} tab={tab} />
                            ) : (
                              <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )}
                          </div>
                        )}

                      </div>
                    </div>
                  }
                  <div className='d-flex col-12 flex-row gap-2 '>
                    {/* <div className=" col-6 p-4 rounded-3 border mt-2">
                    <h5>Detections By User</h5>
                    {alertUserLoader ? (<PlainCardLoader width="200px" />) : (
                      <div className='col-12'>
                        {alertUserData.length || alertUserData.length !== 0 ? (
                          <BarGraph options={barChartoptions} ChartLabel={alertUserLabel} ChartData={alertUserData} ChartTitle={""} module={module} tab={tab} />
                        ) : (
                          <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                            <NoDataFound errorText={"No data found"} />
                          </div>
                        )}
                      </div>
                    )}

                  </div> */}

                  </div>
                </div>
              </div>
              {/*softwareSectionRefImage={softwareSectionRefImage}   */}
              <div className='col-12 mb-3' ref={(el) => (sectionRefs.current.software = el)} id="software">
                <CySoftwareTabs vendorsRefImage={vendorsRefImage} setImageBlobVuln={setImageBlob} />
              </div>
              {/* vulnerabilitiesTwoRefImage={vulnerabilitiesTwoRefImage} */}
              <div className='col-12 mb-3' ref={(el) => (sectionRefs.current.vulns = el)} id="vulns">
                <CyVulnsTabs vulnerabilitiesRefImage={vulnerabilitiesRefImage} setImageBlobVuln={setImageBlob} setDownloadBtn={setDownloadBtn}/>

              </div>

            </div>

          </div>

        </div>
      </div>
      <ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CY_SOFTWARE}/_search?track_total_hits=true`} req={fpostDl} />

    </div >
  )
}

export default ThreatLandscape