import React from 'react'
import Skeleton from 'react-loading-skeleton'

const styles = {
    contentTile: {
        width: "200px",
        height: "100px",
        borderRadius: "10px",
        overflow: "hidden",
        marginRight: "10px"
    },

    contentTile2: {
        width: "260px",
        height: "100px",
        borderRadius: "10px",
        marginRight: "10px"
    }
};

const SmallTileLoader = () => {

    return (
        <div className='flex-fill rounded-3 p-4 recent-file-container bg-white'>
            <div className='d-flex align-items-center'>
                <div style={{ width: "300px", height: "80px", marginBottom: "10px", marginRight: "10px", flex: "1" }}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={{ width: "300px", height: "80px", marginBottom: "10px", marginRight: "10px" }}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={{ width: "300px", height: "80px", marginBottom: "10px", marginRight: "10px" }}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>
            </div>

            <div className='d-flex'>
                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>

                <div style={styles.contentTile}>
                    <Skeleton width={"100%"} height={"100%"} />
                </div>
            </div>
        </div>
    )
}

export default SmallTileLoader