import React, { useState, useEffect } from 'react'
import Select from "react-select";

export default function Values({ expectedOutput, selectedData, modules, setModules }) {

    const [selected, setSelected] = useState([]);
    const [description, setDescription] = useState('')


    useEffect(() => {
        viewValues()
        setInputData(selected)
        setDescription(selectedData?.default_description)



    }, [selectedData])


    const handleMultiChange = (selected) => {

        setSelected(selected);
        setInputData(selected);

    };


    const viewValues = () => {

        let value = selectedData.value;


        const index_ = modules?.findIndex((o) => o[value] === "true");

        let arr = modules?.find((obj, index) => index === index_);

        let array = []


        // if (arr?.input.length > 0 && arr?.input != undefined) {

        arr?.input?.map((data) => {

            const newObj = expectedOutput.find((obj_) => obj_.value === data);

            if (newObj) {
                array.push(newObj)
            }

        })


        let out = array.length == 0 ? selectedData?.default_values?.map((data) => { return { "value": data, "label": data } }) : array


        setSelected(out)
        // }






    }



    const setInputData = (data_) => {
        let selectedValue = selectedData.value;




        const index_ = modules?.findIndex((element) =>
            element.hasOwnProperty(selectedValue)
        );



        if (index_ !== -1 && data_ != undefined && modules != undefined) {


            let input = data_?.map((data) => {
                return data.value
            })


            const updatedObj = { ...modules[index_], input, }



            setModules([...modules?.slice(0, index_), updatedObj, ...modules?.slice(index_ + 1)])

        }


    };

    return (
        <div className="p-3 card flex-fill">
            {" "}
            <label className="control-label">
                <b>{selectedData.text}</b>
            </label>
            <div>{selectedData.description}</div>
            <Select
                isMulti
                options={expectedOutput}
                value={selected}
                onChange={handleMultiChange}
            />
            <label className="control-label mt-3">
                <b>Description</b>
            </label>
            <textarea rows="5" className="form-control h-100" onChange={(e) => setDescription(e.target.value)} value={description} />
        </div>
    )
}
