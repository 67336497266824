import React, { useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { fpost } from "../../axios/axios";
import styles from "./Settings.styles";

const DateFormat = () => {
  const [updating, setUpdating] = useState(false);
  const [format, setFormat] = useState("");
  const [period, setPeriod] = useState("");
  const [zone, setZone] = useState("");
  const apiBaseUrl =localStorage.getItem("apiBaseUrl")

  const updateDate = async () => {
    setUpdating(true);
    try {
      let data;
      const response = await fpost(
        `${apiBaseUrl}/ti/platform/misp_event_data/_search?pretty`,
        data
      );
      // console.log("update date", response);
      // setUpdating(false);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);

      console.log("getSystemVar error in settings", error);
    }
  };

  return (
    <div className="col-12 p-4 position-relative rounded-3" style={styles.section1}>
      <Tabs
        defaultActiveKey="date"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="date" title="Date">
          <div className="col-3" style={styles.tableWrap}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Date format</Form.Label>
              <Form.Select
                value={format}
                onChange={(e) => setFormat(e.currentTarget.value)}
              >
                <option value="yyyy-mm-dd">YYYY-MM-DD</option>
                <option value="mm/dd/yyyy">MM/DD/YYYY</option>
                <option value="dd.mm.yyyy">DD.MM.YYYY</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Default time period</Form.Label>
              <Form.Select
                value={period}
                onChange={(e) => setPeriod(e.currentTarget.value)}
              >
                <option value="1">12 Hour</option>
                <option value="2">24 Hour</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Default time zone</Form.Label>
              <Form.Select
                value={zone}
                onChange={(e) => setZone(e.currentTarget.value)}
              >
                <option value="1">UTC Timezone</option>
                <option value="2">Normal</option>
              </Form.Select>
            </Form.Group>

            <Button
              variant="dark"
              className=" d-flex flex-row align-items-center justify-content-center"
              onClick={updateDate}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default DateFormat;
