import axios from "../axios";
import { fget, fpostC } from "../axios/axios";
import { groupedSummayQuery } from "../Queries/UebaQueries";

let SSO_URL = "https://analyticsapi.active-bytes.com";
let baseURL = localStorage.getItem("CYBOT_URL");
let cybotAccessToken = localStorage.getItem("CYBOT_ACCESS_TOKEN");

export const getSystemVar = async () => {

  const apiBaseUrl =localStorage.getItem("apiBaseUrl")

  // return await axios.get(`${SSO_URL}/getsystemvars`, {
  return await axios.get(`${apiBaseUrl}/core/systemvars`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth"),
    },
  });
};

export const cybotLogin = async (data, url) => {
  return await axios.post(`${url}/authenticate`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUserList = async () => {
  const data = {
    from: 0,
    size: 10,
    track_total_hits: true,
    query: {
      bool: {
        should: [],
        minimum_should_match: 0,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-180d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "user_name",
          size: 1000,
        },
      },
    },
    sort: {
      detection_timestamp: "asc",
    },
  };

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
};

export const getMostAnomalouseUserList = async () => {
  const data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            exists: {
              field: "Result",
            },
          },
          {
            range: {
              "@timestamp": {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "user.name",
          size: 10,
        },
      },
    },
  };

  // return await axios.post(`${baseURL}/cybot_proxy/ueba_group_details*/_search`, data , {
  //     headers:{
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem("ACCESS_TOKEN")
  //     }
  // })

  return await axios.post(
    "https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("DATALAKE_ACCESS_TOKEN"),
      },
    }
  );
};

export const getMostAnomalousHostList = async () => {
  let data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            exists: {
              field: "Result",
            },
          },
          {
            range: {
              "@timestamp": {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "host.name",
          size: 10,
        },
      },
    },
  };

  return await axios.post(
    "https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("ACCESS_TOKEN"),
      },
    }
  );
};

export const getNumberOfAnomalous = async () => {
  let data = {
    size: 0,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-180d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [
          {
            match_phrase: {
              result: -1,
            },
          },
        ],
        minimum_should_match: 1,
        must_not: [],
      },
    },
  };

  // return await axios.post(`${baseURL}/cybot_proxy/ueba_observables*/_search`, data , {
  //   headers:{
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("ACCESS_TOKEN")
  //   }
  // })
};

export const getCountOf = async (field_name) => {
  let data = {
    track_total_hits: true,
    query: {
      bool: {
        should: [],
        minimum_should_match: 0,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-180d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: field_name,
        },
      },
      aggs: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };

  return await axios.post(
    "https://52.53.139.65:9201/cybot_proxy/ueba_group_details*/_search",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      },
    }
  );
};

export const getCountOfAnomalouse = async (field_name) => {
  let data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-365d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      1: {
        terms: {
          field: field_name,
          size: 10,
        },
      },
    },
  };

  return await axios.post(
    "https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("ACCESS_TOKEN"),
      },
    }
  );
};

export const getDetection = async () => {
  let data = {
    from: 0,
    size: 100,
    track_total_hits: true,
    query: {
      bool: {
        should: [
          {
            match_all: {},
          },
        ],
        minimum_should_match: 1,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-180d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    sort: {
      detection_timestamp: "asc",
    },
  };

  return await axios.post(
    "https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("ACCESS_TOKEN"),
      },
    }
  );
};

export const getNumberOfEventScanned = async () => {
  let data = {
    size: 10,
    track_total_hits: true,
    query: {
      bool: {
        should: [
          {
            exists: {
              field: "result",
            },
          },
        ],
        minimum_should_match: 1,
        filter: [
          {
            range: {
              detection_timestamp: {
                gte: "now-30d",
                lte: "now",
              },
            },
          },
        ],
      },
    },
    aggs: {
      events_scanned: { sum: { field: "count" } },
    },
  };

  return await axios.post(
    "https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("ACCESS_TOKEN"),
      },
    }
  );
};


// setting licence info


// checkk module access in licence page settings

export const licenseInfo = async () => {
  const apiBaseUrl =localStorage.getItem("apiBaseUrl")

  return await fget(
    `${apiBaseUrl}/core/licenseinfo`
  )

}

export async function getGroupedSummary(group_id) {

  let data = groupedSummayQuery(group_id)

  return await fpostC(
    `${baseURL}/cybot_proxy/hunt_enriched*/_search?track_total_hits=true`,
    data
  )

}