import { createContext, useState } from "react";

export const State = createContext();

const Context = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("auth"));
  const [uniqueHost, setUniqueHost] = useState(0);

  return (
    <State.Provider value={{ isLoggedIn, setIsLoggedIn,setUniqueHost,uniqueHost }}>
      {children}
    </State.Provider>
  );
};

export default Context;
