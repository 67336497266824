import React from 'react'
import { Document, Page, View, Text, Link } from '@react-pdf/renderer';
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../../Utils/Utils";
import { Chart } from "react-google-charts";
import Header from '../Header';



export default function DetailPageVul({ showMore, baseSeverity }) {

    // Define the styles for the table
    const styles = {
        page: { padding: "20px" },
        container: { flexDirection: "row", marginTop: "5px", },
        column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
        column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
        comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
        link: { padding: "2px", marginTop: "3px", }
    };




    return (
        <Document>
            <Page style={styles.page}>
                <Header data={showMore} />

                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>CVE ID</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {showMore.cve?.id}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Severity</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {baseSeverity}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Date</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getDateFromTimestamp(showMore?.cve?.published)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Time</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getTimeFromTimestamp(showMore?.cve?.published)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {showMore.cve?.descriptions[0].value}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Vulnurability status</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {showMore.cve?.vulnStatus}</Text>
                    </View>
                </View>
                {showMore?.cve?.references?.length > 0 &&
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Reference links</Text>
                        </View>
                        <View style={styles.column2}>

                            {showMore?.cve?.references?.map((item) => (
                                <Link src={styles.link} target="_blank" style={styles.link}>  {item.url}</Link>

                            ))}

                        </View>
                    </View>
                }
            </Page>
        </Document>
    )
}