import React from 'react'
import { useState } from 'react';
import { Spinner } from 'react-bootstrap'

const LoaderBtn = ({ isLoading, btnText ,isdisabled}) => {

    // const [isLoading, setIsLoading] = useState(false);

    return (
        <div className='d-flex justify-content-end'>
            <button
                type="submit"
                className="btn btn-primary"
                disabled={isdisabled?true:false}
            >
                {isLoading ? (
                    <Spinner animation="border" role="status" size="sm" />
                ) : (
                    btnText
                )}
            </button>
        </div>
    )
}

export default LoaderBtn