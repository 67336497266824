import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import Context from "./Context";
import store from "./store";
import * as Sentry from "@sentry/react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import { ToastContainer } from "react-toastify";

// disable console.* on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};

  if (window.location.href.includes("beta.cybot2.active-bytes.com")) {
    Sentry.init({
      dsn: "https://2eea588b752e48728fcfa51a316522bb@o4505518146650112.ingest.sentry.io/4505518151630848",
      environment: process.env.NODE_ENV,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    });
  }
}

// for development
// Sentry.init({
//   dsn: "https://2eea588b752e48728fcfa51a316522bb@o4505518146650112.ingest.sentry.io/4505518151630848",
// environment: process.env.NODE_ENV,
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Context>
      <BrowserRouter>
        <App />
        <ToastContainer limit={1}/>
      </BrowserRouter>
    </Context>
  </Provider>
);
