import { async } from "q";
import {
  detectionTimeLineLogSourceQuery,
  getAccountActivityQuery,
  getGroupDataQuery,
  getHostListQuery,
  getHostUserDetailsCountQuery,
  getHostUserDetailsTimeQuery,
  getProcessQuery,
  getRecentFileOperationsQuery,
  getUserActivitiesCountQuery,
  getUserDetectionCountQuery,
  getUserDetectionTypesQuery,
  getUserHostDetailsQuery,
  getUserHostListQuery,
  getUserRecentActivitiesQuery,
  getUserRecentDetectionsQuery,
  getUserRecentLocationQuery,
  getUsersWatchlistDataQuery,
  getWatchlistDataQuery,
} from "../Queries/UserProfilingQuery";
import { fdeleteC, fgetC, fpostC, fpostCy } from "../axios/axios";
import axios from "axios";

let baseURL = localStorage.getItem("CYBOT_URL");
let dataLakeURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");

// host list only

export async function getHostList() {
  let data = getHostListQuery();

  return await fpostC(`${baseURL}/cybot_proxy/cybot_user-host*/_search`, data);
}

export async function getUserHostList(field_name) {
  let data = getUserHostListQuery(field_name);

  // return await fpostC(`${baseURL}/cybot_proxy/cybot_user-host*/_search`, data);
  return await fpostC(`${baseURL}/cybot_proxy/cybot_user-host*/_search`, data);
}

// export async function getUserHostDetails(userName, hostName) {
//   let data = getUserHostDetailsQuery(userName, hostName);

//   return await fpostC(
//     `${baseURL}/cybot_proxy/ueba_training_data/_search`,
//     data
//   );
// }

export async function getHostUserDetailsCount(user_name, usecase) {
  let data = getHostUserDetailsCountQuery(user_name, usecase);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search`,
    data
  );
}

export async function getHostUserDetailsTime(user_name, usecase) {
  let data = getHostUserDetailsTimeQuery(user_name, usecase);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

export async function getUserNetworkDetails(user_name, usecase) {
  let data = getHostUserDetailsTimeQuery(user_name, usecase);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search`,
    data
  );
}

export async function getProcess(user_name, usecase) {
  let data = getProcessQuery(user_name, usecase);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

//recent detection

export async function getUserRecentDetection(field_type, field_name) {
  let data = getUserRecentDetectionsQuery(field_type, field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

export async function getUserRecentActivities(userName) {
  let data = getUserRecentActivitiesQuery(userName);

  return await fpostCy(`${dataLakeURL}/logs-*/_search`, data);
}

export async function getUserRecentLocation(field_type, field_name) {
  let data = getUserRecentLocationQuery(field_type, field_name);

  return await fpostC(
    `
  ${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

// number of user detections

export async function getUserDetectionCount(field_type, field_name) {
  let data = getUserDetectionCountQuery(field_type, field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search?track_total_hits=true`,
    data
  );
}

// number of user activities

export async function getUserActivitiesCount(field_type, field_name) {
  let data = getUserActivitiesCountQuery(field_type, field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search`,
    data
  );
}

export async function getUserDetectionTypes(field_type, field_name) {
  let data = getUserDetectionTypesQuery(field_type, field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search`,
    data
  );
}

// file operations

export async function getRecentFileOperations(size, date, user) {
  let data = getRecentFileOperationsQuery(size, date, user);

  return fpostCy(
    `${localStorage.getItem("DATALAKE_URL")}/${indexPattern}`,
    data
  );
}

export async function userActivity(size, date, username) {
  let data = getAccountActivityQuery(size, date, username);

  return fpostCy(
    `${localStorage.getItem(
      "DATALAKE_URL"
    )}/winlogbeat-*/_search?track_total_hits=true`,
    data
  );
}

// detection TimeLine LogSource

export async function detectionTimeLineLogSource(field_type, field_name) {
  let data = detectionTimeLineLogSourceQuery(field_type, field_name);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

// add watch list

export function addToWatchList(field_name, field_type) {
  let data = [];

  if (field_type === "user_name") {
    data = {
      user_name: field_name,
      current_timestamp: new Date().toISOString(),
    };
  } else {
    data = {
      host_name: field_name,
      current_timestamp: new Date().toISOString(),
    };
  }

  return fpostC(`${baseURL}/cybot_proxy/ueba_watchlist_user_host/_doc`, data);
}

// get watch list

export function getWatchList() {
  let data = {
    size: 10000,
    query: {
      match_all: {},
    },
  };

  return fpostC(
    `${baseURL}/cybot_proxy/ueba_watchlist_user_host/_search`,
    data
  );
}

// deleted watch list

export function removeFromWatchlist(id) {
  return fdeleteC(
    `${baseURL}/cybot_proxy/ueba_watchlist_group/_doc/${id}`
  );
}

export function getGroupsData(){
  let data = getGroupDataQuery()

  return fpostC(
    `${baseURL}/cybot_proxy/ueba_watchlist_group/_search`,
    data
  )
}

export async function getWatchlistData(field_type, group_name){
  let data = getWatchlistDataQuery(field_type, group_name)

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_watchlist_user_host/_search`,
    data
  )
}



export function getWatchListedData(){
  let data = {
    size: 10000, query: { match_all: {} }
  };

  return fpostC(
    `${baseURL}/cybot_proxy/ueba_watchlist_user_host/_search`,
    data
  )
}