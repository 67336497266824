import globalStyles from "../../../constants/globalStyles";

const Dropstyles = {
    section1: {
        // width: "70%",
        // height: "100%",
        // backgroundColor: "white",
        // borderRadius: "10px 10px 0 0",
        height: "calc(100vh - 8rem)",
        paddingRight: "1rem",
    },
    section2: {
        // width: "30%",
        backgroundColor: "white",
        // borderRadius: "10px 10px 0 0",
    },
    // count tile styles
    overviewTile: {
        // backgroundColor: "#f1f1f1",
        backgroundColor: "#DBEEFF",
        height: "220px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    overviewIcon: {
        width: "50px",
        height: "50px",
        fontSize: "28px",
        backgroundColor: "#47A8FF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        color: "#FFFFFF",
    },
    overviewTileOrange: {
        backgroundColor: globalStyles.orangeLite1,
    },
    overviewIconOrange: {
        backgroundColor: globalStyles.orangeDark1,
    },
    overviewTilePurple: {
        backgroundColor: globalStyles.purpleLite1,
    },
    overviewIconPurple: {
        backgroundColor: globalStyles.purpleDark1,
    },
    overviewTileGreen: {
        backgroundColor: globalStyles.greenLite1,
    },
    overviewIconGreen: {
        backgroundColor: globalStyles.greenDark1,
    },
    detectionCardUser: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.9rem",
    },
    userDetails: {
        display: "flex",
        gap: "10px",
    },
    userPic: {
        width: "50px",
        height: "50px",
        backgroundColor: "yellow",
        borderRadius: "50%",
    },
    userText: {
        lineHeight: "1",
        maxWidth: "220px",
    },
    recentDetectionsWrap: {
        height: "calc(100vh - 15.5rem)",
        overflow: "auto",
        paddingRight: "1rem",
    },
    detectionItem: {},
};

export default Dropstyles;
