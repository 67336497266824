import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import {
    getUebaCatalogueMaster,
    getUebaCatalogueTenant,
    addToOrganizationCatalogue,
    removeFromCatalogue,
    refreshUebaMasterCatalogue,
    refreshUebaTenantCatalogue,
    setLoadingFalseUeba,
    addToOrganizationCatalogueUeba,
    removeFromCatalogueUeba

} from "../../../Methods/RuleStudioMethods";
import { toastsSuccess, toastsDanger, toastsWarning } from "../../../components/Toster/Toster";
import styles from "./Ueba.styles";
import Card from "react-bootstrap/Card";
import TableLoader from "../../../components/Loader/TableLoader";


import CustomModal from "../../../components/CustomModal/CustomModal";
import { Button, Form, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
// import Custom from "../../../components/CustomModal/RSCustomModal";

import MasterCardInv from "../../../components/RuleStudio/MasterCardInv";
import LoaderList from "../../../components/Loader/LoaderList";
import { Pencil, PencilSimple, X } from "phosphor-react";
import {
    escapeHtmlChars,
    formatDate,
    getDateFromTimestamp,
    rawHTML,
    removeHtmlChars,
    dateFormat,

} from "../../../Utils/Utils.js";
import LoaderGrid from "../../../components/Loader/LoaderGrid";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import RS_Filter from "../../../components/RuleStudio/RS_Filter";
import Search from "../../../components/Search/Search";
import { ToastContainer, toast } from 'react-toastify';
import CustomUeba from "../../../components/RuleStudio/Ueba/CustomUeba";
import MasterCardUeba from "../../../components/RuleStudio/Ueba/MasterCardUeba";


const Ueba = () => {
    const dispatch = useDispatch();

    const ruleStudioAddToCat = useSelector((state) => state.ruleStudioAddToCat);
    const { success: catalogueAddSuccess, loading } = ruleStudioAddToCat;

    const listMasterRsUeba = useSelector((state) => state.listMasterRsUeba);
    const { master, loading: masterLoading } = listMasterRsUeba;

    const listCatalogueRsCtUeba = useSelector((state) => state.listCatalogueRsCtUeba);
    const { catalogues: catalogue, listLoading: catLoading, deleteSuccess, deletedId } = listCatalogueRsCtUeba;

    // const removeCatRsInv = useSelector((state) => state.removeCatRsInv);
    // const { success, error } = removeCatRsInv;

    const createUebaRsUeba = useSelector((state) => state.createUebaRsUeba);
    const { success: createSuccess, error: createError } = createUebaRsUeba;




    const [show, setShow] = useState(false);


    const [uebaId, setUebaId] = useState("");
    const [masterFilterAll, setMasterFilterAll] = useState(false);


    const [filteredDataMaster, setFilteredDataMaster] = useState([]);
    const [filteredDataCatalogue, setFilteredDataCatalogue] = useState([]);
    const [customShow, setCustomShow] = useState(false);

    const [refresh, setRefresh] = useState(false)
    const [add, setAdd] = useState(false);
    const [cat, setCat] = useState(true)


    const [searchQueryCatalogue, setSearchQueryCatalogue] = useState("");
    const [catalogueDataFinal, setCatalogueDataFinal] = useState([]);

    const [searchQueryMaster, setSearchQueryMaster] = useState("");
    const [filterQueryMaster, setFilterQueryMaster] = useState("");
    const [masterDataFinal, setMasterDataFinal] = useState([]);
    const [masterSort, setMasterSort] = useState("desc");
    const [ORGFilter, setORGFilter] = useState("");
    const [excludedElements, setExcludedElements] = useState([]);
    const [masterFilterData, setMasterFilterData] = useState([]);
    const [commonData, setCommonData] = useState([]);
    const [toggleViewAll, setToggleViewAll] = useState(false);

    const [submitLoader, setSubmitLoader] = useState(false);
    const [listLoader, setListLoader] = useState(false);





    const getFilteredItems = (state) => {

        if (state.length > 0) {
            let data = [];

            state?.map((val, index) => {

                if (!data.includes(val.log[0])) {
                    data.push(val.log[0])
                }
            })

            return data
        }



    }

    const sevFilterListTelnet = useSelector((state) => getFilteredItems(state.listCatalogueRsCtUeba.catalogues));

    const sevFilterListMaster = useSelector((state) => getFilteredItems(state.listMasterRsUeba.master));



    const filterCatalogue = (data) => {

        let filteredItems


        if (ORGFilter === 'All') {
            filteredItems = catalogue?.filter((item) =>

                item.UEBA_id.toLowerCase().includes(data.toLowerCase()) ||
                item.UEBA_name.toLowerCase().includes(data.toLowerCase()) ||
                item.Description.toLowerCase().includes(data.toLowerCase())
            )
        } else {

            filteredItems = catalogue?.filter((item) =>
                (item.UEBA_id.toLowerCase().includes(data.toLowerCase()) ||
                    item.UEBA_name.toLowerCase().includes(data.toLowerCase()) ||
                    item.Description.toLowerCase().includes(data.toLowerCase())
                )
                && (item.log[0]?.toLowerCase().includes(ORGFilter.toLowerCase()))


            )
        }


        setCatalogueDataFinal(filteredItems)

    }

    const searchDataListCatalogue = () => {

        if (searchQueryCatalogue===""||searchQueryCatalogue!="") {
            let filteredItems = catalogue?.filter((item) =>

                item.UEBA_id.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
                item.UEBA_name.toLowerCase().includes(searchQueryCatalogue.toLowerCase()) ||
                item.Description.toLowerCase().includes(searchQueryCatalogue.toLowerCase())
            )

            return filteredItems
        }

    }





    const filterMaster = () => {
        let filteredItemsMaster

        if (filterQueryMaster === 'All') {

            filteredItemsMaster = master.filter((item) =>

                item.UEBA_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                item.UEBA_name.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                item.Description.toLowerCase().includes(searchQueryMaster.toLowerCase())
            )
        } else {


            filteredItemsMaster = master.filter((item) =>
                (item.UEBA_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                    item.UEBA_name.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                    item.Description.toLowerCase().includes(searchQueryMaster.toLowerCase())
                )
                && (item.log[0].toLowerCase().includes(filterQueryMaster.toLowerCase()))


            )
        }


        setMasterDataFinal(filteredItemsMaster)

    }


    const searchDataListMaster = () => {
        if (searchQueryMaster===""||searchQueryMaster!="") {
            console.log(master)
            let filteredItems = master?.filter((item) =>

                item.UEBA_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                item.UEBA_id.toLowerCase().includes(searchQueryMaster.toLowerCase()) ||
                item.Description.toLowerCase().includes(searchQueryMaster.toLowerCase())
            )


            if (toggleViewAll === false) {
                filteredItems = filteredItems?.filter(item1 => !catalogue?.find(item2 => item1.inv_id === item2.inv_id));
            }


            return filteredItems
        }

    }


    useEffect(() => {


        if (searchQueryCatalogue || ORGFilter || deletedId) {
            filterCatalogue(searchQueryCatalogue)
            filterMaster()
            filterDataMaster(searchQueryMaster);
            filterDataCatalogue(searchQueryCatalogue);
        } else {

            if (show === false) {

                dispatch(getUebaCatalogueTenant());
            }


            setUebaId("");
        }



    }, [searchQueryCatalogue, dispatch, masterSort, ORGFilter, deletedId]);



    // Refresh
    useEffect(() => {
        refreshUebaTenantCatalogue()
        refreshUebaMasterCatalogue()
    }, [])


    // Master Data
    useEffect(() => {
        if (searchQueryMaster || filterQueryMaster) {
            filterMaster()

        }
        else {
            if (show === true) {
                dispatch(getUebaCatalogueMaster(masterSort));
            }



        }
    }, [searchQueryMaster, filterQueryMaster, show])


    useEffect(() => {
        if (masterSort) {
            dispatch(getUebaCatalogueMaster(masterSort));


        }
    }, [dispatch, masterSort])

    useEffect(() => {
        if (catalogueAddSuccess === true) {
            toastsSuccess("Data added successfully")
            toastsWarning("It might take a while for the data to be updated !")
        }
    }, [catalogueAddSuccess])

    //  Toasts
    useEffect(() => {




        if (createSuccess === true) {
            toastsSuccess("Data added successfully")
            toastsWarning("It might take a while for the data to be updated !")
            setCustomShow(false)
            dispatch(setLoadingFalseUeba())
            setSubmitLoader(false)
        }

    }, [createSuccess])

    // new one
    useEffect(() => {

        // setMasterData()

    }, [toggleViewAll, masterDataFinal, show, commonData])

    const setMasterData = () => {

        if (toggleViewAll === false) {
            const array = masterDataFinal?.filter(item1 => !catalogue?.find(item2 => item1.inv_id === item2.inv_id));
            setCommonData(array)
        }

        if (toggleViewAll === true) {
            setCommonData(masterDataFinal)
        }

    }



    if (catalogue?.length > 0 && catalogueDataFinal.length === 0 && searchQueryCatalogue === '' && ORGFilter === '') {
        filterCatalogue('')
    }

    if (master?.length > 0 && masterDataFinal.length === 0 && searchQueryMaster === '' && filterQueryMaster === '') {
        filterMaster()
    }
    // let masterData = filteredDataMaster.length === 0 && searchQueryMaster === '' ? master : filteredDataMaster



    const handleOpenModal = (display) => {
        setCat(false);
        setShow(display);
    };




    function handleCloseModal() {

        handleRefresh();
        setShow(false);
        setSearchQueryMaster("");
        setMasterFilterAll(false);

    }

    function handleClose() {
        setMasterFilterAll(false);
        setCat(true);
        setShow(false);
    }



    const filterDataMaster = (query) => {
        const filtered = filteredDataMaster?.filter((item) => {
            return (
                item[0].toLowerCase().includes(query.toLowerCase()) ||
                item[1].toLowerCase().includes(query.toLowerCase()) ||
                item[2].toLowerCase().includes(query.toLowerCase())
            )
        });



        setFilteredDataMaster(filtered);
    };

    const filterDataCatalogue = (query) => {

        const filtered = filteredDataCatalogue?.filter((item) => {
            return (
                item[0].toLowerCase().includes(query.toLowerCase()) ||
                item[1].toLowerCase().includes(query.toLowerCase()) ||
                item[2].toLowerCase().includes(query.toLowerCase())
            );
        });


        setFilteredDataCatalogue(filtered);
    };

    const AddToCatalogue = (id) => {

        setAdd(true);

        if (window.confirm("Are you sure?")) {
            dispatch(addToOrganizationCatalogueUeba(id));
            setCustomShow(false)

        }
    };

    const deleteFromCatalogue = (id, index) => {
        if (window.confirm("Delete the item?")) {
            setListLoader(true)


            // dispatch(removeFromCatalogueUeba(id, index));
            removeFromCatalogueUeba(id).then((res) => {

                if (res.status === 200) {
                    refreshUebaTenantCatalogue()
                    toastsDanger("UEBA Deleted successfully")
                    toastsWarning("It might take a while for the data to be updated !")
                    setListLoader(false)
                }
            })


            if (deleteSuccess === true || deleteSuccess === undefined) {
                refreshUebaTenantCatalogue().then((res) => {
                    if (res.status === 200) {
                        dispatch(getUebaCatalogueTenant())
                    }
                })
            }
        }
    };




    const handleToggleChange = (e) => {
        toggleViewAll === false ? setToggleViewAll(true) : setToggleViewAll(false)


        masterFilterAll === false ? setMasterFilterAll(true) : setMasterFilterAll(false)

        if (masterFilterAll === true) {
            masterDataListView(masterFilterAll)
        }

    }

    const masterDataListView = () => {


        let array = []
        // Set data
        master?.map((item, index) => {
            const filter = catalogue?.some(
                (data) => data.UEBA_id.toLowerCase() === item.UEBA_id.toLowerCase()
            );


            if (filter === false) {
                array.push(master[index])
            }

        })

        setMasterFilterData(array)

    }


    const handleRefresh = (status = false) => {

        setRefresh(true)

        if (refresh === true || status === true) {
            refreshUebaMasterCatalogue().then((res) => {

                if (res.status === 200) {
                    setRefresh(false)
                }
            })
        }


    };

    const handleCustom = (display) => {
        setUebaId("")
        setCustomShow(display);
    };

    const handleEdit = (UebaId) => {
        setUebaId(UebaId);
        setCustomShow(true);
    };


    const handleSearchMaster = (val, e, action) => {
        if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
            setSearchQueryMaster(val)
        }
    };

    const handleSearch = (val, e, action) => {

        if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {

            setSearchQueryCatalogue(val)
        }
        if (val === '') {
            filterCatalogue('')
        }

    };


    function renderModal() {
        return (
            <div className="px-3">
                <div className="mb-3 py-2 sticky-top">
                    {/* <h5>Master Catalogue</h5> */}
                    <div className="row">
                        <div className="col-12 d-flex py-2 gap-2">
                            <div className="col-3">
                                <Search changeFunction={handleSearchMaster} module='rulestudio' />

                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    {/* {masterFilterAll === true ? */}
                                    <RS_Filter
                                        filter={filterQueryMaster}
                                        setFilter={setFilterQueryMaster}
                                        count={commonData.length}
                                        allCount={commonData.length}
                                        cData={master}
                                        data={sevFilterListMaster}
                                        dataList={commonData}
                                        masterFilterAll={masterFilterAll}
                                        cat={true}
                                        searchData={searchDataListMaster()}
                                    />


                                </div>
                            </div>

                            <div className="flex-fill d-flex flex-row align-items-center gap-2">
                                <span className="w-25 text-right">Sort by</span>
                                <select className="form-select" onChange={(e) => setMasterSort(e.target.value)}>
                                    <option value="desc">Newest</option>
                                    <option value="asc">Oldest</option>
                                </select>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="toggle_view_switch"
                                        label="View All"
                                        onChange={handleToggleChange}
                                    />
                                </Form>
                            </div>
                            <div className="col-1">
                                <Link style={{ color: "white" }} onClick={() => handleRefresh(true)}>
                                    <button className="btn btn-primary">Refresh</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



                {masterLoading == true || refresh === true ? (
                    <LoaderList />
                ) : (
                    <div className="col-12" style={styles.section1}>
                        {commonData?.length === 0 ? <NoDataFound /> :
                            <div className="d-flex flex-column gap-3">
                                {commonData?.map((item, index) => {
                                    const filter = catalogue?.some(
                                        (data) => data.UEBA_id.toLowerCase() === item.UEBA_id.toLowerCase()
                                    );


                                    // if (filter === true && masterFilterAll === false) return


                                    return (
                                        <MasterCardUeba
                                            item={item}
                                            filter={filter}
                                            AddToCatalogue={AddToCatalogue}
                                            loading={loading}
                                            index={index}
                                        />
                                    );
                                })}
                            </div>
                        }
                    </div>
                )}
            </div>
        );
    }






    return (
        <div className="col-12 rounded-3 p-4" style={styles.section1}>
            <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
                <h5 className="mb-0">Organization Catalogue</h5>

                <div className="d-flex">
                    <div className="form-group mx-2">
                        <Search changeFunction={handleSearch} />

                    </div>
                    <div className="form-group">
                        <RS_Filter
                            filter={ORGFilter}
                            setFilter={setORGFilter}
                            count={catalogueDataFinal.length}
                            cData={catalogue}
                            data={sevFilterListTelnet}
                            dataList={catalogue}
                            allCount={catalogueDataFinal.length}
                            cat={cat}
                            searchData={searchDataListCatalogue()}
                        />


                    </div>
                    <Link
                        className="mx-2"
                        style={{ color: "white" }}
                        onClick={() => handleOpenModal(true)}
                    >
                        <button className="btn btn-primary">Add from master</button>
                    </Link>
                    <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
                        <button className="btn btn-light">Create Custom</button>
                    </Link>

                </div>
            </div>

            {/* <LoaderGrid /> */}
            <div style={styles.mainWrap}>
                {catLoading === true || listLoader === true ? (
                    <TableLoader rowCount={15} />
                ) : (
                    catalogueDataFinal.length === 0 ? <NoDataFound /> :
                        <div className="d-flex flex-row flex-wrap py-3">
                            <Table borderless hover>
                                <thead className="fixed-head">
                                    <tr>
                                        <th>ID</th>
                                        <th>Alert name</th>
                                        <th>Description</th>
                                        <th> Severity</th>
                                        <th>	Created on </th><th>	Updated on </th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {catalogueDataFinal.map((item, index) => {

                                        return (
                                            <tr>
                                                <td className="align-middle">
                                                    {item.UEBA_id} </td>
                                                <td className="align-middle">
                                                    {item.UEBA_name}
                                                </td>

                                                <td className="align-middle">
                                                    {removeHtmlChars(item.Description)}
                                                </td>
                                                <td className="align-middle">

                                                    {item.severity == "Critical" && (
                                                        // <div className="p-2 m-0 alert alert-danger">Critical</div>
                                                        <Badge bg="danger" className="p-2">Critical</Badge>
                                                    )}
                                                    {item.severity == "High" && (
                                                        // <div className="p-2 m-0 alert alert-danger">High</div>
                                                        <Badge bg="danger" className="p-2">High</Badge>
                                                    )}
                                                    {item.severity == "Medium" && (
                                                        // <div className="p-2 m-0 alert alert-warning">Medium</div>
                                                        <Badge bg="warning" className="p-2">Medium</Badge>
                                                    )}
                                                    {item.severity == "Low" && (
                                                        // <div className="p-2 m-0 alert alert-success">Low</div>
                                                        <Badge bg="success" className="p-2">Low</Badge>
                                                    )}
                                                </td>
                                                <td className="align-middle">
                                                    {item?.inv_created && formatDate(item?.inv_created)}</td>
                                                <td className="align-middle">
                                                    {item?.inv_last_edited && formatDate(item?.inv_last_edited)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div className="d-flex flex-row gap-3 justify-content-center">
                                                        <i
                                                            className="cursor-pointer"
                                                            onClick={(e) => handleEdit(item.UEBA_id)}
                                                        >
                                                            <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />

                                                        </i>
                                                        <i className="cursor-pointer"
                                                            onClick={(e) => deleteFromCatalogue(item.UEBA_id, index)}
                                                        >
                                                            <TableViewMore Icon={X} dataToggle={"Delete"} dataPlacement={"bottom"} />
                                                        </i>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                )}

            </div>

            {
                show === true && (
                    <CustomModal
                        title={`Master Catalogue`}
                        ShowOrHide={show}
                        bodyData={renderModal}
                        handleClose={handleClose}
                        footer={false}
                        noBodyPadding={true}
                        modalSize={'lg'}
                        overflowHidden={false}
                        modalPosition={"right"}
                        outsideClickDisabled={true}
                    />
                )
            }
            {customShow === true && (
                <CustomUeba
                    setCustomShow={setCustomShow}
                    customShow={customShow}
                    setUebaId={setUebaId}
                    uebaIdEdit={uebaId}
                    setSubmitLoader={setSubmitLoader}
                    submitLoader={submitLoader}
                />
            )}
        </div >
    );
};
export default Ueba;
