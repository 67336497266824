import React, { useState, useEffect, useRef } from "react";

import CustomModal from "../../components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../assets/images/avt.png";
import "react-quill/dist/quill.snow.css";
import { Badge, Button, Form, Dropdown } from "react-bootstrap";
import {
  getDetectionDetails,
} from "../../Methods/HunterMethods";
import { getDateFromTimestamp, getTimeFromTimestamp, convertUTCTimeToLocal } from "../../Utils/Utils";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './Case.css';
import { Eye, Plus, Trash } from "phosphor-react";
import { saveAs } from 'file-saver';
import TableViewMore from "../../components/ViewMoreBtn/TableViewMore";
import { Pencil, PencilSimple, X } from "phosphor-react";
import { toastsSuccess, toastsDanger, toastsWarning } from "../Toster/Toster";


import Loader from "../Loader/Loader";
import {
  singleListView, createCommentCm,
  closeCaseManagement, listCaseManagement,
  categoryList, editCaseManagement, activityGet,
  deleteCommentCm,
  createCaseAttached,
  singleListViewCase,
  getAttachedCases,
  removeCaseAttached,
  reOpenCaseManagement,
  editCommentCm,
  listUser
} from "../../Methods/CaseManagementMethods";
import CaseActivities from "./CaseActivities";
import globalStyles from "../../constants/globalStyles";



const styles = {
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "background"
  },
  cardBody: {
    background: "background !important"
  },
  activityWrap: {
    // height: "300px",
    height: "calc(100% - 5rem)",
    overflow: "auto",
    paddingRight: "1rem"
    // overflowY: "scroll",
    // overflowX: 'hidden',
  },
  section1: {
    height: "100%",
    overflow: "auto",
    paddingRight: "1rem"
  },
  linkStyle: {
    color: "black",
    textDecoration: "none"

  }
};


export default function DetailModal({
  setDetailShow,
  detailShow,
  caseid,
  setCase_ID,
  filteredData,
  handleActivity
}) {

  const dispatch = useDispatch();




  const caseData = useSelector((state) => state.singleListView);
  const { case: case_, loading } = caseData;

  const createComment = useSelector((state) => state.createCommentCm);
  const { success: createCommentSuccess } = createComment;

  const caseDelete = useSelector((state) => state.deleteCaseManagement);
  const { success: caseDeleteSuccess } = caseDelete;

  const updateCase_ = useSelector((state) => state.editCaseManagement);
  const { loading: sevLoading } = updateCase_;




  const [comment, setComment] = useState("");
  const [severity, setSeverity] = useState(case_?.severity);
  const [category, setCategory] = useState(case_?.category);
  const [base64String, setBase64String] = useState([]);
  const [action, setAction] = useState("");
  const [activity, setActivity] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [loadingField, setLoadingField] = useState('');
  const [attachedCase, setAttachedCase] = useState('');
  const [attachedCaseList, setAttachedCaseList] = useState([]);
  const [defSev, setDefSev] = useState("");
  const [defCat, setDefCat] = useState("");
  const [stat, setStat] = useState("");
  const [decodedString, setDecodedString] = useState('');
  const [users, setUsers] = useState([]);
  const [assignTo, setAssignTO] = useState();








  const [showFileViewer, setShowFileViewer] = useState(false);
  const [downloadFile, setDownloadFile] = useState("")

  const [editCommentData, setEditCommentData] = useState({})
  const [isEditComment, setIsEditComment] = useState(false)
  const [isChangeCommentFile, setIsChangeCommentFile] = useState(false)



  const [isLoading, setLoading] = useState(false);
  const [commentIsLoading, setCommentIsLoading] = useState(false);
  const [isCloseClicked, setIsCloseClicked] = useState(false);
  const [activityDivLoad, setActivityDivLoad] = useState(false);


  const [attachType, setAttachType] = useState('');
  const [isAttachedEmpty, setIsAttachedEmpty] = useState(false);
  const [attachmentsList, setAttachmentList] = useState();




  const uploadCommentAttachment = useRef(null);


  useEffect(() => {
    dispatch(singleListView(caseid));
    setComment("")
    setCommentIsLoading(false)

  }, [createCommentSuccess, commentIsLoading])

  // Set data into state
  useEffect(() => {
    setCategory(case_?.category);
    setSeverity(case_?.severity)
    setDefSev(case_?.severity)
    setDefCat(case_?.category)
    setStat(case_?.state)
    setAssignTO(case_?.assigned_to)
  }, [case_])



  useEffect(() => {


    setTimeout(() => {
      activityGet(caseid).then((res) => {

        setActivitiesList(res.data.activities)

        setActivityDivLoad(false)

      })
      categoryList().then((res) => {
        setCategoriesList(res.data.categories)
      })
    }, 1000);





  }, [category, severity])


  useEffect(() => {
    getAttachedCases(caseid).then((response) => {

      setAttachedCaseList(response.data.attached_cases)
    })
  }, [])

  useEffect(() => {

    if (case_?.state === "close") {
      setStat("close")
    } else if (case_?.state === "closed") {
      setStat("close")
    }
  }, [stat])


  useEffect(() => {


    let obj = {}
    if (attachedCaseList?.length > 0) {

      attachedCaseList?.map((data) => {
        let key = data?.split('_')[0];
        // let val = data.split('_', 2);
        let val = data?.replace(`${key}_`, '')


        if (!obj[key]) {
          let value = [val]
          obj[key] = value
        } else {

          obj[key].push(val)
        }
        setAttachmentList(obj)


      })
    } else {
      setAttachmentList()
    }


  }, [attachedCaseList])


  useEffect(() => {
    listUser().then((res) => {

      setUsers(res.data)
    })
  }, [])




  function fileToBase64(file, callback) {
    const reader = new FileReader();

    reader.onload = function () {
      const base64StringData = reader.result.split(',')[1];
      callback(base64StringData);
    };

    reader.readAsDataURL(file);
  }



  const handleFileChange = (event) => {
    const file = event.target.files[0];



    fileToBase64(file, function (base64StringData) {
      let obj = { mime_type: file.type, filename: file.name, file_base64: base64StringData }

      setBase64String([obj]);

    });

  };



  const handleClose = (id) => {

    if (window.confirm("Do you want close the case?")) {
      setIsCloseClicked(true)
      closeCaseManagement(id).then((res) => {
        if (res.status === 200) {

          toastsWarning('Case Closed successfully')
          setLoadingField("close")
          setStat("close")
          dispatch(singleListView(caseid));


          // setDetailShow(false);
          setIsCloseClicked(false)
        }
      })


    }
    handleActivity(caseid, "State", "CLOSE", "OPEN")


  }

  // ****************New changes on 4/8/2023

  // function to edit severity
  const changeSeverity = (e, sev) => {


    setDefSev(e.target.value)

    setLoadingField('sev')
    setActivityDivLoad(true)
    updateCase(e.target.value, category, false, assignTo)
    setSeverity(e.target.value)


    handleActivity(caseid, "Severity", e.target.value, sev)

  }

  const changeCategory = (e, cat) => {
    setDefCat(e.target.value)

    setLoadingField('cat')
    setCategory(e.target.value)
    setActivityDivLoad(true)

    updateCase(severity, e.target.value, false, assignTo)

    handleActivity(caseid, "Category", e.target.value, cat)

  }


  const changeAssignTo = (e) => {
    setAssignTO(e.target.value)
    setLoadingField('assignTo')
    updateCase(severity, category, false, e.target.value)

    handleActivity(caseid, "Assign TO", assignTo, e.target.value)

  }

  const reopenCase = (id) => {
    if (window.confirm("Do you want Re-open the case?")) {
      setIsCloseClicked(true);
      reOpenCaseManagement(id).then((res) => {
        if (res.status === 200) {

          toastsSuccess('Case Reopened successfully')
          setLoadingField("open")
          setStat("open")
          dispatch(singleListView(caseid));


          // setDetailShow(false);
          setIsCloseClicked(false)
        }
      })

      // updateCase(severity, category, true)

    }
    handleActivity(caseid, "State", "OPEN", "CLOSE")

  }

  const updateCase = (severity, category, reopen, assignTo) => {

    let data = {
      case_id: case_?.case_id,
      severity,
      category,
      state: reopen === true ? 'closed' : 'open',
      assigned_to: assignTo,
      case_created: case_?.case_created,
      case_closed: reopen === true ? 'FALSE' : case_?.case_closed,
      title: case_?.case_title,
      description: case_?.case_description
    };

    dispatch(editCaseManagement(data))

  }

  const downloadPdf = (data) => {


    const filename = data?.attached_files[0]?.filename; // Replace with the desired file name
    const mimeType = data?.attached_files[0]?.mime_type;
    // Convert the Base64 string to a Blob
    const byteCharacters = atob(data?.attached_files[0]?.file_base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Trigger the download
    link.click();
  };


  const onSubmit = (e) => {
    // const jsonString = JSON.stringify(base64String);
    setLoadingField('sub')
    setCommentIsLoading(true)

    e.preventDefault();



    if (isEditComment === false) {
      let data = {
        case_id: caseid,
        comment: comment,
        attached_files: base64String
      };
      dispatch(createCommentCm(data))
    } else {
      let data = {
        case_id: caseid,
        comment_id: editCommentData.comment_id,
        edited_comment: {
          comment: comment, attached_files: base64String,
          // comment_created: editCommentData.comment_created,
          // username: editCommentData.username
        },


      };

      editCommentCm(data)
    }

    setBase64String([])

  }

  const addAttachedCase = () => {

    setLoadingField('attach')

    if (attachType === '') {
      setIsAttachedEmpty(true)
      return
    } else {
      setIsAttachedEmpty(false)
    }
    setLoading(true)

    // singleListViewCase(attachedCase).then((res) => {
    //   if (res === 200) {
    //   } else {
    //     toastsDanger('Case not found!')
    //     setLoading(false)
    //   }
    // })


    let attachedCaseName = `${attachType}_${attachedCase}`


    createCaseAttached(caseid, [attachedCaseName]).then((res) => {

      if (res.status === 200) {
        setLoading(false)

        toastsSuccess('Attached successfully')
        getAttachedCases(caseid).then((response) => {

          setAttachedCaseList(response.data.attached_cases)
        })
      }

    })

  }

  const removeAttachedCase = (caseData, module) => {
    if (window.confirm("Do you want remove case?")) {
      let attachedCaseName = `${module}_${caseData}`

      removeCaseAttached(caseid, attachedCaseName).then((res) => {
        if (res.status === 200) {
          toastsWarning('Attachment Deleted successfully')
          getAttachedCases(caseid).then((response) => {

            setAttachedCaseList(response.data.attached_cases)

          })
        }
      })
    }
  }




  // REdirect to pages
  const redirect = (data, module) => {

    if (module === "hunt") {
      window.location.href = `http://localhost:3000/hunter/detection/${data}`;
    }

    if (module === 'INV-INC') {
      window.location.href = `http://localhost:3000/hunter/detection/${data}`;
    }


  }




  const handleEditComment = (data) => {


    setIsEditComment(true)
    setEditCommentData(data)
    setComment(data?.comment)

    if (data?.attached_files?.length === 0) {
      setBase64String([])
    } else {
      setBase64String([...base64String, data?.attached_files[0]]);
    }


  }


  const removeSelectedAttachment = () => {
    uploadCommentAttachment.current.value = '';
    setBase64String([])
  }

  const deleteComment = (commentId) => {
    setCommentIsLoading(true)
    deleteCommentCm(caseid, commentId).then((res) => {

      if (res.data.status === 200) {
        setCommentIsLoading(false)
        // const toastCommentDelete = () => toast("Comment deleted successfully");
        // toastCommentDelete()
      }
    })
  }

  function renderCommentWithLinks(comment) {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const parts = comment.split(linkRegex);

    return parts.map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  }

  const handleCloseModal = () => {
    setDetailShow(false);
    setCase_ID("")
  };





  const renderModal = () => (

    <>
      {loading === true ? (
        <Loader />
      ) : (

        <div className="col-12 d-flex flex-column h-100 bg-grey p-3">

          <div className="col-12 d-flex gap-3 h-100">
            <div className="col-8 d-flex flex-column gap-3" style={styles.section1}>
              <div className="col-12 p-3 header-actions-wrap rounded-3 bg-white p-4 sticky-top-0">
                {loading === true && loadingField === "close" ? (
                  <Loader />
                ) : (

                  stat === 'close' ?
                    <div className="col-12 d-flex justify-content-end">
                      <Link
                        style={{ color: "white" }}

                      >
                        {/* <span className="modal-label col-3 ">
                          {sevLoading === true && loadingField === 'sev' &&
                            <div className="spinner-border ml-2 spinner-border-sm" role="status">
                              <span className="sr-only"> </span>
                            </div>
                          }
                        </span> */}
                        <Button className="btn-secondary" type="submit" onClick={(e) => reopenCase(case_?.case_id)} disabled={isCloseClicked === true ? true : false}>
                          {isCloseClicked === true ? <>  Reopening &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                            <span className="sr-only"> </span>
                          </div></> : "Reopen Case"}
                        </Button>
                      </Link>
                    </div>
                    :
                    <div className="col-12 d-flex justify-content-end">
                      <Link
                        style={{ color: "white" }}

                      >

                        <Button className="btn-primary" type="submit" onClick={(e) => handleClose(case_?.case_id)} disabled={isCloseClicked === true ? true : false}>
                          {isCloseClicked === true ? <>  Closing &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                            <span className="sr-only"> </span>
                          </div></> : "Close Case"}
                        </Button>

                      </Link>
                    </div>
                )}
              </div>

              <div className=" d-flex flex-column rounded-3 bg-white p-4">
                <div className="modal-row-item">
                  <span className="modal-label col-3">Case Id</span>
                  <span className="modal-text">
                    {case_?.case_id}
                    {/* {item.caseid} */}
                  </span>
                </div>

                <div className="modal-row-item">
                  <span className="modal-label col-3">Case Title</span>
                  <span className="modal-text">
                    {case_?.case_title}
                    {/* {item.caseid} */}
                  </span>
                </div>




                <div className="modal-row-item mt-2">
                  {" "}
                  <span className="modal-label col-3">
                    <label className="control-label">Severity</label>
                  </span>
                  <span className="modal-text col-4">

                    <select
                      className="form-select input-lg"
                      name="severity"
                      value={severity}
                      onChange={(e) => changeSeverity(e, defSev)}

                    >

                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                      <option value="critical">Critical</option>
                    </select>

                  </span>
                  <span className="modal-label col-3 ">
                    {sevLoading === true && loadingField === 'sev' &&
                      <div className="spinner-border ml-2 spinner-border-sm" role="status">
                        <span className="sr-only"> </span>
                      </div>
                    }
                  </span>
                </div>


                <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">
                    <label className="control-label">Category</label>
                  </span>
                  <span className="modal-text col-4">


                    <select
                      className="form-select input-lg"
                      name="category"
                      value={category}
                      onChange={(e) => changeCategory(e, defCat)}
                    >

                      {categoriesList?.map((data, index) => (

                        <option key={index}>{data}</option>
                      ))}


                    </select>
                  </span>
                  <span className="modal-label col-3 ">
                    {sevLoading === true && loadingField === 'cat' &&
                      <div className="spinner-border ml-2 spinner-border-sm" role="status">
                        <span className="sr-only"> </span>
                      </div>
                    }
                  </span>
                </div>


                <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">State</span>
                  <span className="modal-text">
                    {case_?.state?.toLowerCase() == "open" && (
                      <Badge bg="warning" className="p-2">Open</Badge>

                    )}
                    {case_?.state?.toLowerCase() == "closed" && (
                      <Badge bg="success" className="p-2">Closed</Badge>

                    )}



                    {/* {case_?.state} */}
                  </span>
                </div>
                <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">
                    <label className="control-label">Assign to</label>
                  </span>
                  <span className="modal-text col-4">


                    <select
                      className="form-select input-lg"
                      name="category"
                      value={assignTo}
                      onChange={(e) => changeAssignTo(e)}
                    >
                      {users?.map((data, index) => (
                        <option value={data.user_name} key={index}>{data.user_name}</option>
                      ))}


                    </select>
                  </span>
                  <span className="modal-label col-3 ">
                    {sevLoading === true && loadingField === 'assignTo' &&
                      <div className="spinner-border ml-2 spinner-border-sm" role="status">
                        <span className="sr-only"> </span>
                      </div>
                    }
                  </span>
                </div>
                {/* <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">Assign to</span>
                  <span className="modal-text">
                    {case_?.assigned_to}
                  </span>
                </div> */}
                <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">Description</span>
                  <span className="modal-text">
                    {case_?.case_description}
                  </span>
                </div>

                <div className="modal-row-item mt-2">
                  <span className="modal-label col-3">Created</span>
                  <span className="modal-text">
                    {getDateFromTimestamp(case_?.case_created) + " at " + convertUTCTimeToLocal(case_?.case_created)}
                  </span>
                </div>
                {case_?.case_closed != 'FALSE' && case_?.state?.toLowerCase() == "closed" &&
                  <div className="modal-row-item mt-2">
                    <span className="modal-label col-3">Closed </span>
                    <span className="modal-text">

                      {getDateFromTimestamp(case_?.case_closed) + " at " + getTimeFromTimestamp(case_?.case_closed)}
                    </span>
                  </div>
                }
                <div className="modal-row-item mt-2">
                  <h6 className="py-2">Related Events</h6>
                </div>
                <div className="modal-row-item align-items-center">
                  <span className="modal-text col-2 mr-2">

                    <select className={isAttachedEmpty === true ? `form-select input-lg  is-invalid` : `form-select input-lg`} onChange={(e) => setAttachType(e.target.value)}>
                      <option value="">Select type</option>
                      <option value="CASE">Case</option>
                      <option value="UEBA">UEBA Detection</option>
                      <option value="HUNT">Hunt</option>
                      <option value="TI">TI_FEEDS</option>
                      <option value="TI">TI_SEC</option>
                      <option value="INV-INC">Investigate Incident</option>
                      <option value="INV-DET">Investigate Detection</option>
                    </select>


                  </span>
                  <span className="modal-text col-8">
                    <input
                      type="text"
                      className="form-control input-lg ml-1 "
                      name="attachedCase"
                      placeholder="Enter  ID"
                      onChange={(e) => setAttachedCase(e.target.value)}
                      value={attachedCase}
                      required
                    />


                  </span>
                  {attachedCase != '' &&
                    <span className="modal-label col-3 font-weight-600">
                      {isLoading === true && loadingField === 'attach' ? <div className="spinner-border ml-2 spinner-border-sm" role="status">
                        <span className="sr-only"> </span>
                      </div> :
                        <span className="mx-2 cursor-pointer" onClick={() => addAttachedCase()}><Plus size={18} /></span>

                      }

                    </span>
                  }
                </div>
                <div className="modal-row-item ">

                  {attachmentsList && attachmentsList['CASE'] && <div> <span>CASE -</span>
                    {attachmentsList['CASE']?.map((caseList, index) => (
                      <span className="tag label label-info" key={index}>
                        {/* <span>{caseList}</span> */}
                        <a> <span onClick={(e) => redirect(caseList, "case")}>{caseList}</span> </a>
                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(caseList, 'CASE')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>
                <div className="modal-row-item ">
                  {attachmentsList && attachmentsList['UEBA'] && <div> <span>UEBA -</span>
                    {attachmentsList['UEBA']?.map((list, index) => (
                      <span className="tag label label-info" key={index}>
                        <Link to={`/ueba/summary/${list}`} style={styles.linkStyle}>  {list} </Link>

                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(list, 'UEBA')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>
                <div className="modal-row-item ">
                  {attachmentsList && attachmentsList['HUNT'] && <div> <span>HUNTER -</span>
                    {attachmentsList['HUNT']?.map((list, index) => (
                      <span className="tag label label-info" key={index}>
                        <Link to={`/hunter/detection/${list}`} style={styles.linkStyle}>  {list} </Link>
                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(list, 'HUNT')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>
                <div className="modal-row-item ">
                  {attachmentsList && attachmentsList['INC-ATT'] && <div> <span>Incidents Needs Attention -</span>
                    {attachmentsList['INC-ATT']?.map((list, index) => (

                      <span className="tag label label-info" key={index} >
                        <Link to={`/investigate/grouped_investigation/${list}`} style={styles.linkStyle}>  {list} </Link>

                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(list, 'INC-ATT')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>
                <div className="modal-row-item ">
                  {attachmentsList && attachmentsList['INV-INC'] && <div> <span>Investigate Incident -</span>
                    {attachmentsList['INV-INC']?.map((list, index) => (

                      <span className="tag label label-info" key={index} >
                        <Link to={`/investigate/grouped_investigation/${list}`} style={styles.linkStyle}>  {list} </Link>

                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(list, 'INV-INC')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>
                <div className="modal-row-item ">
                  {attachmentsList && attachmentsList['INV-DET'] && <div> <span>Investigate Detection -</span>
                    {attachmentsList['INV-DET']?.map((list, index) => (
                      <span className="tag label label-info" key={index}>

                        <Link to={`/investigate/summary/${list}`} style={styles.linkStyle}>  {list} </Link>
                        <a><span className="close" aria-label="Close" onClick={(e) => removeAttachedCase(list, 'INV-DET')}>
                          <span aria-hidden="true">&times;</span>
                        </span></a>
                      </span>
                    ))}
                  </div>

                  }
                </div>

                {/* comments */}
                <div className="col-12">
                  <section>
                    <div className="col-12">
                      <h6 className="py-2">Add Comment</h6>
                      <Form className="col-12" onSubmit={onSubmit}>
                        <div className="col-12">
                          <textarea rows="5" className="form-control" onChange={(e) => setComment(e.target.value)}
                            value={comment} required></textarea>
                        </div>

                        <div className="mt-2 position-relative">
                          {isEditComment === true ? <div>

                            {base64String[0]?.filename}

                            {base64String.length != 0 ?
                              <i
                                className="cursor-pointer"
                                onClick={(e) => window.confirm("Do you want delete the file?") ? setBase64String([]) : null}
                              >
                                <TableViewMore Icon={X} dataToggle={"Delete file"} dataPlacement={"bottom"} size="14" />
                              </i> : <input className="form-control" type="file" onChange={handleFileChange} />
                            }

                          </div> : <>  <input className="form-control" type="file" onChange={handleFileChange} ref={uploadCommentAttachment} />
                            {base64String.length != 0 &&
                              <i
                                className="cursor-pointer position-absolute" style={{ right: "5px", top: "4px" }}
                                onClick={(e) => window.confirm("Do you want delete the file?") ? removeSelectedAttachment() : null}
                              >
                                <TableViewMore Icon={X} dataToggle={"Delete file"} dataPlacement={"bottom"} size="14" />
                              </i>}</>
                          }

                        </div>
                        <div className="d-flex flex-row-reverse mt-2">
                          <div className=""><button className="btn btn-primary" disabled={commentIsLoading === true ? true : false}>
                            {commentIsLoading === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">
                              <span className="sr-only"> </span>
                            </div></> : 'Save'}
                          </button></div>
                        </div>
                      </Form>
                    </div>

                    <hr />

                    <h6 className="py-2">Comments</h6>
                    <div className="container text-dark"  >
                      <div className="row d-flex justify-content-left" >
                        <div className="col-12 p-0 d-flex flex-column gap-3" >

                          {loading === true && loadingField === "sub" ? (
                            <Loader />

                          ) : (
                            case_?.comments?.map((data) => (
                              <div className="card">

                                <div className=" m-3" style={styles.cardBody}>

                                  <div className="col-md-12" >
                                    <div className="d-flex flex-start">
                                      <img className="rounded-circle shadow-1-strong me-3"
                                        src={icon} alt="avatar" width="40"
                                        height="40" />
                                      <div className="d-flex flex-fill flex-column"  >
                                        <div className="col-12 d-flex flex-column">
                                          <h6 className="mb-0">
                                            {data?.username}
                                          </h6>



                                          <p className="text-dark col-12"> {renderCommentWithLinks(data?.comment)}</p>
                                          {data?.attached_files?.length > 0 &&
                                            <div className="rounded-3 overflow-hidden" style={{ width: "100px", height: "100px" }}>
                                              {data?.attached_files?.length > 0 &&
                                                <a className="a-link" onClick={(e) => downloadPdf(data)} style={{ cursor: "pointer" }}>
                                                  <img src={"data:image/png;base64," + data?.attached_files[0].file_base64} style={{ height: "100px" }} />
                                                </a>
                                              }

                                              {/* {data?.attached_files?.length > 0 &&
                                              <a className="a-link" onClick={(e) => downloadPdf(data)} style={{ cursor: "pointer" }}>download</a>
                                            } */}
                                            </div>
                                          }


                                          <hr />

                                          <div className='d-flex gap-3 col-12 align-items-center'>
                                            <div className="text-left d-flex justify-content-start modal-label modal-label-sm event-date">{getDateFromTimestamp(data?.comment_created)}</div>
                                            <div className="text-left d-flex justify-content-start modal-label modal-label-sm event-time">{convertUTCTimeToLocal(data?.comment_created)}</div>

                                            <div className="d-flex align-items-center gap-3 pb-2" style={{ marginLeft: "auto" }}>
                                              <i
                                                className="cursor-pointer"
                                                onClick={(e) => handleEditComment(data)}
                                              >
                                                <TableViewMore Icon={PencilSimple} dataToggle={"Edit comment"} dataPlacement={"bottom"} size="14" />
                                              </i>
                                              <i
                                                className="cursor-pointer"
                                                onClick={(e) => window.confirm("Do you want delete the file?") ? deleteComment(data.comment_id) : null}
                                              >
                                                <TableViewMore Icon={X} dataToggle={"Delete comment"} dataPlacement={"bottom"} size="14" />
                                              </i>
                                            </div>
                                          </div>
                                          {/* <p className="mb-0"> {getDateFromTimestamp(data?.comment_created)}</p>
                                          <p className="mb-0"> {convertUTCTimeToLocal(data?.comment_created)}</p> */}

                                        </div>


                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="small mb-0" style={{ color: "#aaa" }}>

                                          </p>
                                          <div className="d-flex flex-row gap-2">
                                            <i className="fas fa-star text-warning me-2"></i>
                                            <i className="far fa-check-circle" style={{ color: "#aaa" }}></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            ))
                          )
                          }

                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>


            </div>

            <div className="flex-fill px-4 rounded-3 bg-white">
              <div className="modal-row-item mt-2">
                <h6 className="py-2">Activities</h6>
              </div>
              <div className="col-12" style={styles.activityWrap}>
                <CaseActivities activitiesList={activitiesList} getDateFromTimestamp={getDateFromTimestamp} convertUTCTimeToLocal={convertUTCTimeToLocal} activityDivLoad={activityDivLoad} styles={styles} />
              </div>
            </div>
          </div>


        </div >
      )
      }

    </>



  );







  return (
    <>

      {" "}

      {detailShow === true && (
        <CustomModal
          title="Case Details"
          ShowOrHide={detailShow}
          modalSize="xl"
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        />
      )}
    </>
  );


}
