import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import ViewMoreBtn from "../../components/ViewMoreBtn/TableViewMore";
import styles from "./Settings.styles";
import TableLoader from "../../components/Loader/TableLoader";
import axios from "../../axios";
import CustomModal from "../../components/CustomModal/CustomModal";
import NoDataFound from "../../components/NoDataFount/NoDataFound";
import AlertBox from "../../components/AlertBox/AlertBox";
import { Link } from "react-router-dom";
import { Pen, Trash } from "phosphor-react";
import Select from "react-select";
import { licenseInfo } from "../../Methods/Methods";
import { fdeleteC, fpostC } from "../../axios/axios";
import { getIntegrationList } from "../../Methods/SettingMethods";

const Integrations = () => {
  let baseURL = localStorage.getItem("CYBOT_URL");

  // loader
  const [tableIsLoading, setTableIsLoading] = useState(true);
  // modal
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false);
  // alert popup
  const [alertPopup, setAlertPopup] = useState(false);

  const [alertMessage, setAlertMessage] = useState('Success')

  const [integrationFormData, setIntegrationFormData] = useState({
    api_name: "",
    body: "",
    header: "",
    method: "",
    count: "",
    url: "",
  });
  const paramsCountList = [1, 2, 3, 4, 5];
  const [paramCount, setParamCount] = useState([]);
  const [methodData, setMethodData] = useState([]);
  const [tableData, setTableData] = useState([]);

  // update
  const [updateId, setUpdateId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // get tenand ID
  const [tenantId, setTenantId] = useState([])

  // console.log("tenantId", tenantId[0].tenantId)

  useEffect(() => {
    let data = {
      size: 10000,
      "query": {
        "match_all": {}
      }
    }
    fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
      let licence = []
      hits?.map((item) => {
        licence.push({ startData: item?._source.start_date, endDate: item?._source.end_date, tenantId: item?._source.tenant_id, organizationName: item?._source.organization_name })
      })
      setTenantId(licence[0].tenantId)
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])

  const handleOpenModal = (display, id = null) => {
    if (id) {
      setUpdateId(id);
      setShowAddIntegrationModal(display);
    } else {
      setUpdateId(null)
      setIntegrationFormData({
        api_name: "",
        body: "",
        header: "",
        method: "",
        count: "",
        url: "",
      })
      setShowAddIntegrationModal(display);
    }
  };

  const getIntegrationData = async (id) => {
    try {
      let data = {
        size: 10000, query: { match_all: {} }
      };
      fpostC(`${baseURL}/cybot_proxy/thp_api*/_search`, data)
        .then(
          ({
            data: {
              hits: { hits },
            },
          }) => {
            return hits.find((item) => item._id === id);
          }
        );
    } catch (err) {
      console.log("Error", err);
      return null;
    }
  };
  // const getTenantId = () => {
  //   const token = localStorage.getItem("auth");
  //   if (token?.length > 0) {
  //     const tokenSplit = token.split(".");
  //     const payload = JSON.parse(atob(tokenSplit[1]));
  //     const fullgrp = payload["cognito:groups"][0];
  //     const tenantid = fullgrp.split("_rw")[0];
  //     return tenantid;
  //   }
  // };

  // const tenantId = getTenantId();
  function handleCloseModal() {
    setShowAddIntegrationModal(false);
    setShowUpdateModal(false);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegrationFormData({
      ...integrationFormData,
      [name]: value,
    });
    console.log(value);
  };

  const setUpdateVal = (integrationDatas) => {
    // const { _source } = integrationDatas
    // const updatedValues = {
    //   count: _source.count,
    //   api_name: _source.api_name,
    //   body: _source.body,
    //   header: _source.header,
    //   method: _source.method,
    //   url: _source.url
    // }
    // setIntegrationFormData(updatedValues)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormValues = {
        ...integrationFormData,
      };
      if (updateId) {
        const data = {
          doc: {
            count: integrationFormData.count,
            api_name: integrationFormData.api_name,
            body: integrationFormData.body,
            header: integrationFormData.header,
            method: integrationFormData.method,
            url: integrationFormData.url,
            // param: integrationFormData
          },
        };
        if (data.doc.count.length === 0) {
          delete data.doc.count;
        }
        if (data.doc.api_name.length === 0) {
          delete data.doc.api_name;
        }
        if (data.doc.body.length === 0) {
          delete data.doc.body;
        }
        if (data.doc.header.length === 0) {
          delete data.doc.header;
        }
        if (data.doc.method.length === 0) {
          delete data.doc.method;
        }
        if (data.doc.url.length === 0) {
          delete data.doc.url;
        }

        await fpostC(
          `${baseURL}/cybot_proxy/thp_api_${tenantId}/_update/${updateId}`,
          data
        );
        getIntegrationAllData()
        setIntegrationFormData("");
        handleCloseModal(false);
        setAlertMessage("Integration updated")
        setAlertPopup(true);
      } else {
        const data = {
          count: integrationFormData.count,
          api_name: integrationFormData.api_name,
          body: integrationFormData.body,
          header: integrationFormData.header,
          method: integrationFormData.method,
          url: integrationFormData.url,
        };
        const response = await fpostC(
          `${baseURL}/cybot_proxy/thp_api_${tenantId}/_doc`,
          data
        );
        getIntegrationAllData()
        setIntegrationFormData("");
        handleCloseModal(false);
        setAlertMessage("Integration added")
        setAlertPopup(true);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };


  const handleClosePopup = () => {
    setAlertPopup(false);
  };

  const handleonDropDownParamsChange = (selectedVal) => {
    setIntegrationFormData({
      ...integrationFormData,
      count: selectedVal.label,
    });
  };

  const handleonDropDownMethodChange = (selectedVal) => {
    setIntegrationFormData({
      ...integrationFormData,
      method: selectedVal.label,
    });
  };

  const getIntegrationAllData = () => {
    getIntegrationList().then(({ data: { hits: { hits } } }) => {
      setTableData(hits);
      setTableIsLoading(false);

    }).catch((err) => {
      console.log("Error", err);
      setTableIsLoading(false);
    })
  }

  // table data
  useEffect(() => {
    let paramsCount = [];
    paramsCountList?.map((item) => {
      paramsCount.push({ label: item });
    });
    setParamCount(paramsCount);

    let methodsList = ["get", "post"];
    let methods = [];
    methodsList?.map((item) => {
      methods.push({ label: item });
    });
    setMethodData(methods);

    getIntegrationAllData()

    // let data = { size: 10000, query: { match_all: {} } };
    // axios
    //   .post(`${baseURL}/cybot_proxy/thp_api*/_search`, data, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
    //     },
    //   })
    //   .then(
    //     ({
    //       data: {
    //         hits: { hits },
    //       },
    //     }) => {
    //       setTableData(hits);
    //       setTableIsLoading(false);
    //     }
    //   )
    //   .catch((err) => {
    //     console.log("Error", err);
    //     setTableIsLoading(false);
    //   });
  }, [tenantId]);

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this data?"
    );

    confirmDelete &&
      fdeleteC(`${baseURL}/cybot_proxy/thp_api_${tenantId}/_doc/${id}`,
      ).then((res) => {
        getIntegrationAllData()
        setAlertMessage("Integration deleted")
        setAlertPopup(true);
      })
  };

  const handleUpdate = async (id) => {
    const integrationDatas = []

    let data = {
      size: 10000, query: { match_all: {} }
    };

    await fpostC(`${baseURL}/cybot_proxy/thp_api*/_search`, data).then(({ data: { hits: { hits } } }) => {
      hits.filter((item) => {
        if (item._id === id) {
          const updatedValues = {
            count: item?._source.count,
            api_name: item?._source.api_name,
            body: item?._source.body,
            header: item?._source.header,
            method: item?._source.method,
            url: item?._source.url
          }
          setIntegrationFormData(updatedValues)
        }

      })
    }).then((res) => {
      getIntegrationAllData()
    })

    // setUpdateVal(integrationDatas);
    handleOpenModal(true, id);
  };

  function renderModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleSubmit}>
          <div className="form-group d-flex gap-3">
            {/* <DropdownButton id="dropdown-basic-button" title="Params Count">
              {paramsCountList?.map((item) => (
                <Dropdown.Item onClick={(e) => handleInputChange(e)} >{item}</Dropdown.Item>
              ))
              }
            </DropdownButton> */}

            <div className="flex-fill">
              <label className="control-label">Param Count</label>
              {console.log(integrationFormData.count)}
              <Select
                className="w-100"
                options={paramCount}
                onChange={handleonDropDownParamsChange}
                defaultValue={paramCount[integrationFormData.count - 1]}
                required
              />
            </div>

            <div className="flex-fill">
              <label className="control-label">Method</label>
              <Select
                className="w-100"
                options={methodData}
                onChange={handleonDropDownMethodChange}
                defaultValue={methodData[1]}
                required
              />
            </div>

            {/* <div className="form-group">
              <DropdownButton id="dropdown-basic-button" title="Method">
                {["get", "post"]?.map((item) => (
                  <Dropdown.Item value={integrationFormData.method} onClick={(e) => handleInputChange(e)} >{item}</Dropdown.Item>
                ))
                }
              </DropdownButton>
            </div> */}
          </div>
          <div className="row mt-2">
            <div className="form-group d-flex gap-3">
              <div className="flex-fill">
                <label className="control-label">API Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="api_name"
                  name="api_name"
                  value={integrationFormData.api_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group d-flex gap-3">
              <div className="flex-fill">
                <label className="control-label">Body</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="body"
                  name="body"
                  value={integrationFormData.body}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group d-flex gap-3">
              <div className="flex-fill">
                <label className="control-label">Header</label>
                <input
                  type="text"
                  className="form-control"
                  id="header"
                  name="header"
                  value={integrationFormData.header}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="flex-fill">
                <label className="control-label">URL</label>
                <input
                  type="url"
                  className="form-control"
                  id="url"
                  name="url"
                  value={integrationFormData.url}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-success" type="submit">
                {updateId === null ? "Create" : "Update"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <div
        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
        style={styles.filterWrap}
      >
        <button
          className="btn btn-primary"
          onClick={() => handleOpenModal(true)}
        >
          Add
        </button>
      </div>

      <div className="detections col-12">
        <Tabs
          defaultActiveKey="investigate"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="investigate" title="Investigate">
            <div className="col-12" style={styles.tableWrap}>
              {tableIsLoading ? (
                <div className="col-12">
                  <TableLoader rowCount={25} />
                </div>
              ) : tableData.length !== 0 ? (
                <Table borderless hover>
                  <thead className="fixed-head">
                    <tr>
                      <th>API Name</th>
                      <th>Method</th>
                      <th>Header</th>
                      <th>Body</th>
                      <th>Url</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item) => (
                      <tr>
                        <td>{item._source.api_name}</td>
                        <td>{item._source.method}</td>
                        <td className="text-break">{item._source.header}</td>
                        <td className="text-break">{item._source.body}</td>
                        <td className="text-break">{item._source.url}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Edit</Tooltip>
                              )}
                              placement="bottom"
                            >
                              <Link onClick={() => handleUpdate(item._id)}>
                                <ViewMoreBtn Icon={Pen} />
                              </Link>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Delete</Tooltip>
                              )}
                              placement="bottom"
                            >
                              <Link
                                style={{ color: "red", marginLeft: "10px" }}
                                onClick={() => handleDelete(item._id)}
                              >
                                <ViewMoreBtn Icon={Trash} />
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <NoDataFound errorText={"No data Found"} />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>

      {showAddIntegrationModal && (
        <CustomModal
          title={updateId === null ? "Add Integration" : "Edit Integration"}
          ShowOrHide={showAddIntegrationModal}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          modalSize={"md"}
          footer={false}
          downloadBtn={false}
          noBodyPadding={true}
          overflowHidden={true}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}

      {/* {showUpdateModal && (
        <CustomModal
          title={"Edit Integration"}
          ShowOrHide={showUpdateModal}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          modalSize={"md"}
          footer={false}
          downloadBtn={false}
          noBodyPadding
          autoHeight
        ></CustomModal>
      )} */}

      {alertPopup && (
        <AlertBox
          setAlertPopup={setAlertPopup}
          alertPopup={alertPopup}
          message={alertMessage}
          close={handleClosePopup}
          setOutTime={5000}
          bgColor={"lightGreen"}
        />
      )}
    </div>
  );
};

export default Integrations;
