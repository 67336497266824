import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Tab, Tabs, Table, Button, Badge, Spinner } from "react-bootstrap";
import Select from "react-select";

import {
  ArrowLeft,
  ArrowRight,
  X,
  Minus,
  Plus,
  Desktop,
  Info,
  Repeat,
  ListDashes,
} from "phosphor-react";
import Pagination from "../../../components/Pagination/Pagination";
import { convertTimestamp } from "../../../Methods/TiMethods";
import { getDetections } from "../../../Methods/UebaMethods";
import EditModal from "../../../components/CaseManagement/EditModal";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  getTimeFromTimestamp,
  rawHTML,
} from "../../../Utils/Utils.js";
import { timestampConvert } from "../../../Utils/Utils";
import {
  getInlineFilter,
  getInvestigation,
  getInvestigationTable,
  getLogFilter,
  getTableData,
  getInvestigationGrouped,
  getInvestigationAttention
} from "../../../Methods/InvestigationMethods";
import styles from "./Investigation.styles";
import AddFilter from "../../../components/AddFilter/AddFilter";
import InlineFilter from "../../../components/InlineFilter/InlineFilter";
import { Link, useLocation } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import InvestigationDashboard from "../../../components/DetailsPage/InvestigationDashboard";
import { TimelineLoaderLg } from "../../../components/Loader/TimelineLoader";
import TableLoader from "../../../components/Loader/TableLoader";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import RepetitionDashboard from "../../../components/DetailsPage/RepetitionDashboard";
import FilterList from "../../../components/FilterList/FilterList";
import useOnclickOutside from "react-cool-onclickoutside";
import { fgetCy, fpostC, fpostCy } from "../../../axios/axios";
import axios from "../../../axios";
import { getLogCategory } from "../../../Queries/InvestigationQuery";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import InvestigateExport from "../Export";
import moment from "moment";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument";
import { ArrowsOutSimple, FileSearch } from "phosphor-react";
import { invFilterGenerate } from "../../../Queries/InvestigationQuery";
import globalStyles from "../../../constants/globalStyles";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";
import IncidentsExport from "../Export/incidents";
import AlertBox from "../../../components/AlertBox/AlertBox";
import { numberToLetter } from "../../../Utils/Utils.js";
import { toastsSuccess, toastsDanger } from "../../../components/Toster/Toster";
import InvestigationDocExport from '../../../components/Doc/InvestigationDocExport.jsx'
import ReactQuill from "react-quill";
import AttentionExport from "../Export/Attention.js";
import Severity from "../../../components/Severity/Severity.jsx";


const Investigation = ({ selectedDateFilter }) => {
  let baseURL = localStorage.getItem("CYBOT_URL")

  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab.investigate") ? localStorage.getItem("activeTab.investigate") : "Attention")
  const location = useLocation();

  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [timelineIsLoading, setTimelineIsLoading] = useState(true);
  const [attentionTableIsLoading, setAttentionTableIsLoading] = useState(true);

  const [investigateData, setInvestigateData] = useState([]);
  const [investigateDataError, setInvestigateDataError] = useState([]);
  const [investigateAttenData, setInvestigateAttenData] = useState([]);


  const [investigateAttenDataError, setInvestigateAttenDataError] = useState([]);

  const [investigationTableData, setInvestigationTableData] = useState([]);
  const [investigationTableDataError, setInvestigationTableDataError] = useState([]);
  const [investigationTableHead, setInvestigationTableHead] = useState([]);
  const [isRepetition, setIsRepetition] = useState(false);
  const [isInvestigationTableLoading, setIsInvestigationTableLoading] =
    useState(false);

  // states for add filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  // const [dateFilter, setDateFilter] = useState({});
  // const [dateFilter, setDateFilter] = useState({
  //   from: moment().subtract(1, "days")?.format("yyyy-MM-DDTHH:mm"),
  //   to: moment().format("yyyy-MM-DDTHH:mm"),
  //   f: moment().subtract(1, "days")?.format("yyyy-MM-DDTHH:mm"),
  //   e: moment().format("yyyy-MM-DDTHH:mm"),
  //   range: 1
  // })
  // const [dateFilter, setDateFilter] = useState({
  //   from: moment().subtract(1, "year")?.format("yyyy-MM-DDTHH:mm"),
  //   to: moment().format("yyyy-MM-DDTHH:mm"),
  //   f: moment().subtract(1, "year")?.format("yyyy-MM-DDTHH:mm"),
  //   e: moment().format("yyyy-MM-DDTHH:mm"),
  //   range: 365
  // });

  const [showAddFilterGroup, setShowAddFilterGroup] = useState(false);
  const [filterGroup, setFilterGroup] = useState([]);
  const [dateFilterGroup, setDateFilterGroup] = useState({});

  const [showAddFilterAtten, setShowAddFilterAtten] = useState(false);
  const [filterAtten, setFilterAtten] = useState([]);
  const [dateFilterAtten, setDateFilterAtten] = useState({});

  const [isTableTabSelected, setIsTableTabSelected] = useState(false);

  const [selectedItemsAttention, setSelectedItemsAttention] = useState([]);
  const [selectedItemsIncident, setSelectedItemsIncident] = useState([]);
  const [selectedItemsDetection, setSelectedItemsDetection] = useState([]);

  const [caseAttachmentList, setCaseAttachmentList] = useState([]);

  const [showInlineFilter, setShowInlineFilter] = useState({
    show: false,
    index: 0,
  });

  // pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [totalPageCount, setTotalPageCount] = useState()

  const [groupedPage, setGroupedPage] = useState(1);
  const [groupPerPage, setGroupPerPage] = useState(100);
  const [groupcurrentPageCount, setGroupCurrentPageCount] = useState(perPage);
  const [groupTotalPageCount, setGroupTotalPageCount] = useState()

  const [attentionPage, setAttentionPage] = useState(1);
  const [attentionPerPage, setAttentionPerPage] = useState(100);
  const [attentioncurrentPageCount, setAttentionCurrentPageCount] = useState(perPage);
  const [attentionTotalPageCount, setAttentionTotalPageCount] = useState()

  const [tableHead, setTableHead] = useState([]);
  const [selectedDropItem, setSelectedDropItem] = useState("ALL");

  // modal states

  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);

  const [showRepetition, setShowRepetition] = useState(false);
  const [repetitionDetailpage, setRepetitionDetailpage] = useState([]);

  const [checkBox, setCheckBox] = useState([]);
  const [showFieldBar, setShowFieldBar] = useState(false);

  const [defaultList, setDefaultList] = useState(true)
  const [expclickedNode, setExpClickedNode] = useState("")
  const [expclose, setExpClose] = useState(false)
  const [expclickedNodeLog, setExpClickedNodeLog] = useState("")
  const [expClickedIndex, setExpClickedIndex] = useState("")
  const [expcloseLog, setExpCloseLog] = useState(false)
  // table header
  const [tableHeader, setTableHeader] = useState([])
  const [tableColumns, setTableColumns] = useState([])

  const [manageColumnLoader, setManageColumnLoader] = useState(false)

  const [checkedItems, setCheckedItems] = useState([]);
  const [closeRefresh, setCloseRefresh] = useState(true)

  const [closureCategory, setClosureCategory] = useState([])
  const [externalInv, setExternalInv] = useState([])

  // Add Case
  const [caseshow, setCaseShow] = useState(false)
  const [case_id, setCase_ID] = useState("");

  const [tenandId, setTenandId] = useState([])

  const [columnValues, setColumnValues] = useState([])
  const [defaultColumnValues, setDefaultColumnValues] = useState([
    { value: "detection_name", label: "Investigation Name", isWildCard: true },
    { value: "user", label: "User Name", isWildCard: true },
    { value: "host", label: "Host", isWildCard: true },
    { value: "detection_status", label: "Status", isWildCard: true },
    { value: "detection_severity", label: "Severity", isWildCard: true },
    { value: "source_ip", label: "Source IP", isWildCard: true },
    { value: "dest_ip", label: "Destination IP", isWildCard: true },
    { value: "source_port", label: "Source Port" },
    { value: "dest_port", label: "Destination Port" },
    { value: "process_name", label: "Process Name", isWildCard: true },
    { value: "process_hash", label: "Process Hash" },
    { value: "process_commandline", label: "Command Line", isWildCard: true },
  ])

  const [activeTableRow, setActiveTableRow] = useState()
  const handleRowClick = (id) => {
    setActiveTableRow(id);
  };
  // incident manage coloum 

  const [manageDatas, setManageDatas] = useState([
    { value: "user", label: "User Name", isSelected: true },
    { value: "host", label: "Host", isSelected: true },
    { value: "detection_status", label: "Status", isSelected: true },
    { value: "detection_severity", label: "Severity", isSelected: true },
    // { value: "detection_name", label: "Incident Name", isSelected: true },
    { value: "tag", label: "Tags", isSelected: true },
    { value: "threat_classification", label: "Classification", isSelected: true },
    { value: "threat_score", label: "Threat Score", isNumber: true, isSelected: true },
    { value: "tactic_name", label: "Mitre Tactic", isSelected: true },
    { value: "technique_name", label: "Mitre Technique", isSelected: true },
    { value: "description", label: "Description", isSelected: true },
    { value: "opened_by", label: "Opened By", isSelected: true },
    { value: "closing_tag", label: "Closure Code", isSelected: true },


  ])

  const [manageDatasAtten, setManageDatasAtten] = useState([
    { value: "user", label: "User Name", isSelected: true },
    { value: "host_name", label: "Host", isSelected: true },
    { value: "detection_status", label: "Status", isSelected: true },
    { value: "detection_severity", label: "Severity", isSelected: true },
    // { value: "detection_name", label: "Incident Name", isSelected: true },
    { value: "tag", label: "Tags", isSelected: true },
    { value: "threat_classification", label: "Classification", isSelected: true },
    { value: "threat_score", label: "Threat Score", isNumber: true, isSelected: true },
    { value: "tactic_name", label: "Mitre Tactic", isSelected: true },
    { value: "technique_name", label: "Mitre Technique", isSelected: true },
    { value: "description", label: "Description", isSelected: true },
    { value: "opened_by", label: "Opened By", isSelected: true },
    { value: "closing_tag", label: "Closure Code", isSelected: true }

  ])
  const queryParams = new URLSearchParams(location.search);

  const active = queryParams.get('active');
  const severity = queryParams.get('severity');

  const DateFilter = queryParams.get('date');
  const filterSet = () => {
    if (localStorage.getItem("filter")) {
      return localStorage.getItem("filter")
    }
    else {
      let selectedDate;

      switch ("1 Day") {
        case "1 Day":
          selectedDate = 1;
          break;
        case "7 Days":
          selectedDate = 7;
          break;
        case "30 Days":
          selectedDate = 30;
          break;
        case "1 Year":
          selectedDate = 365;
          break;
        default:
      }

      let current = new Date();
      let startD = new Date(
        current.getTime() - selectedDate * 24 * 60 * 60 * 1000
      )
        .toISOString()

      let endD = current.toISOString()

      localStorage.setItem("gte", startD);
      localStorage.setItem("lte", endD);
      localStorage.setItem("filter", "1 Day");

      return "1 Day"

    }
  }



  const [detectionClosed, setDetectionClosed] = useState({
    closed_at: "",
    closed_by: "",
    comment: "",
    detection_status: "",
  });

  const [detectionOpen, setDetectionOpen] = useState({
    opened_at: "",
    opened_by: "",
    comment: "",
    detection_status: "",
  });



  const [detectionStatusCheck, setDetectionStatusCheck] = useState('')

  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Success')

  const [closeShow, setCloseShow] = useState(false)

  const caseCreate = useSelector((state) => state.createCaseManagement);
  const { success: caseCreateSuccess } = caseCreate;



  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
    setShowFieldBar(false);
    setShowAddFilterGroup(false)
    setShowAddFilterAtten(false)
  });



  const investigationTimeline = investigateData?.map((item) => {
    return Object.entries(item);
  });


  const drop = tableHead;

  // for filter
  let count = [];
  let count2 = "test"
  let count3 = [];
  let count4 = "test"
  let count5 = [];
  let count6 = "test"
  const module = 'investigation'
  const tab = 'main'
  const tabGroup = 'main-group'
  const tabAtten = 'main-atten'

  useMemo(() => {

  }, [])
  // Incident Local Storage 
  useEffect(() => {
    const savedShowFieldBar = localStorage.getItem('showFieldBar');
    if (savedShowFieldBar) {
      setShowFieldBar(savedShowFieldBar === 'true');
    }

    const savedManageDatas = localStorage.getItem('manageDatas');
    if (savedManageDatas) {
      setManageDatas(JSON.parse(savedManageDatas));
    }
  }, []);
  // Attention Local Storage 
  useEffect(() => {
    const savedShowFieldBar = localStorage.getItem('showFieldBar');
    if (savedShowFieldBar) {
      setShowFieldBar(savedShowFieldBar === 'true');
    }

    const savedManageDatasAtten = localStorage.getItem('manageDatasAtten');
    if (savedManageDatasAtten) {
      setManageDatasAtten(JSON.parse(savedManageDatasAtten));
    }
  }, []);

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filters"));
    const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
    const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

    if (currentPageFilter?.length || currentPageDateFilter?.to) {
      count = currentPageFilter;
      count2 = currentPageDateFilter?.to


      if (severity === undefined || severity === null) {
        setDateFilter(currentPageDateFilter)
        setDateFilterAtten(currentPageDateFilter)
        setDateFilterGroup(currentPageDateFilter)
        setFilter(currentPageFilter);
        getFilteredTable(currentPageFilter, currentPageDateFilter);

      }
      else {
        count2 = null
      }
      // else {
      //   setIsInvestigationTableLoading(false)
      // }

    }
    const currentPageFilterGroup = storedFilter?.[module]?.[tabGroup]?.fields
    const currentPageDateFilterGroup = storedFilter?.[module]?.[tabGroup]?.date

    if (currentPageFilterGroup?.length || currentPageDateFilterGroup?.to) {
      count3 = currentPageFilterGroup;
      count4 = currentPageDateFilterGroup?.to


      if (severity === undefined || severity === null) {
        setDateFilter(currentPageDateFilterGroup)
        setDateFilterAtten(currentPageDateFilter)
        setDateFilterGroup(currentPageDateFilter)

        setFilterGroup(currentPageFilterGroup);
        getFilteredTableGroup(currentPageFilterGroup, currentPageDateFilterGroup);
      }
      else {
        count4 = null
      }
    }
    const currentPageFilterAtten = storedFilter?.[module]?.[tabAtten]?.fields
    const currentPageDateFilterAtten = storedFilter?.[module]?.[tabAtten]?.date

    if (currentPageFilterAtten?.length || currentPageDateFilterAtten?.to) {
      count5 = currentPageFilterAtten;
      count6 = currentPageDateFilterAtten?.to
      if (severity === undefined || severity === null) {
        setDateFilter(currentPageDateFilterAtten)
        setDateFilterAtten(currentPageDateFilter)
        setDateFilterGroup(currentPageDateFilter)

        setFilterAtten(currentPageFilterAtten);
        getFilteredTableAtten(currentPageFilterAtten, currentPageDateFilterAtten);
      }
      else {
        count6 = null
      }
    }
  }, []);


  // case
  useEffect(() => {
    if (caseCreateSuccess === true) {

      setCaseShow(false)
      toastsSuccess("Case created successfully")

    }
    // setTimeout(() => {
    //   dispatch(resetReduxData())
    // }, 2000);


  }, [caseCreateSuccess])


  useEffect(() => {

    setIsInvestigationTableLoading(true);
    // get table head dropdown list

    // setTimelineIsLoading(true);
    // setAttentionTableIsLoading(true)

    // setCurrentPageCount((page - 1) * perPage + perPage);

    getTableData()
      .then(({ data }) => {
        let head = [];
        Object.entries(data).map((item) => {
          head.push({ label: item[0] });
        });
        setTableHead(head);

        // table more fields
        if (page <= 1) {
          let checked = Object.entries(data).find((item) => item[0] === "Common");
          setCheckBox(checked[1]);

          let temp = checked[1]

          temp.map((item) => {
            item.isSelected = false
          })

          var columns = data["Common"].map(v => ({ ...v, isSelected: false }));

          var tableColumns = {};
          var tempColumns = [...columns];


          for (var key in data) {
            if (key !== 'Common') {

              tableColumns[key] = columns.map(v => ({ ...v }));
              data[key].forEach((x) => {
                tempColumns.some((y, index) => {
                  if (y.value === "user") {
                    y.value = "user_name"
                  } else if (y.value === "host") {
                    y.value = "host_name"
                  }
                  if (y.value === x.value) {
                    tableColumns[key][index].isSelected = true;
                    return true;
                  }
                  return false;
                });
              });
            }
          }

          let storedData = localStorage.getItem('investigateCheckedTableColumns')

          if (storedData) {
            setTableColumns(JSON.parse(storedData))
            setTableHeader(JSON.parse(storedData)["ALL"])
          } else {
            setTableColumns(tableColumns)
            setTableHeader(tableColumns["ALL"])
          }

          // setTableColumns(tableColumns)
          // setTableHeader(tableColumns["ALL"])

          // localStorage.setItem("investigateTableColumns", JSON.stringify(tableColumns))

          setManageColumnLoader(false)
        }

        const selectedDataForTable = Object.entries(data).find(
          (item) => item[0] === selectedDropItem
        );

        setInvestigationTableHead(selectedDataForTable[1]);
        // setInvestigationTableHead(selectedDataForTable[1]);
      })
      .catch((err) => {
        console.log("Error", err);
      });

    if ((!count?.length && !count2) || count2 === "test") {
      getInvestigationTable2(filter, dateFilter, 100, "desc", (page - 1) * perPage, selectedDropItem)
        .then(
          (res) => {
            if (!res?.isError) {
              let hits = res.data.hits

              setTotalPageCount(hits.total.value)
              hits.hits.map(
                (item) =>
                (item._source.time = getTimeFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              hits.hits.map(
                (item) =>
                (item._source.date = getDateFromTimestamp(
                  item._source.detection_timestamp
                ))
              );
              setInvestigationTableData(hits.hits);
              setIsInvestigationTableLoading(false);
            } else {
              setInvestigationTableDataError(res)
              setIsInvestigationTableLoading(false);
            }
          }
        )
        .catch((err) => {
          setIsInvestigationTableLoading(false);

          console.log("Error", err);
        });
    } else {
      console.log("FOUND FILTER");
    }
    // setGroupCurrentPageCount((groupedPage - 1) * groupPerPage + groupPerPage);

  }, [page, perPage, defaultList, checkedItems, closeRefresh, alertPopup, dateFilter]);

  useEffect(() => {

    if ((!count3?.length && !count4) || count4 === "test") {
      if (tenandId.length == 0) {
        let data = {
          "query": {
            "match_all": {}
          }
        }
        fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
          var licence = []
          hits?.map((item) => {
            licence.push(item?._source.tenant_id)
          })
          setTenandId(licence[0])
          getInvestigationGrouped(licence[0], 100, "desc", (groupedPage - 1) * groupPerPage, filterGroup, dateFilter).then(
            (res) => {
              if (!res?.isError) {
                let hits = res.data.hits
                setGroupTotalPageCount(hits?.total?.value)
                setInvestigateData(hits?.hits);
                // setInvestigateData(result);
                setTimelineIsLoading(false)

              } else {
                setInvestigateDataError(res)
                setTimelineIsLoading(false)
              }
            }
          )
            .catch((err) => {
              setInvestigateData([]);
              setTimelineIsLoading(false)
              console.log("Error", err);
            });
        }).catch((err) => {
          console.log("Error", err)
          setTimelineIsLoading(false)
        })

      }
      else {
        getInvestigationGrouped(tenandId, 100, "desc", (groupedPage - 1) * groupPerPage, filterGroup, dateFilter).then(
          (res) => {
            if (!res?.isError) {
              let hits = res.data.hits
              setGroupTotalPageCount(hits?.total?.value)
              setInvestigateData(hits?.hits);
              // setInvestigateData(result);
              setTimelineIsLoading(false)
            } else {
              setInvestigateDataError(res)
              setTimelineIsLoading(false)
            }
          }
        )
          .catch((err) => {
            setInvestigateData([]);
            setTimelineIsLoading(false)
            console.log("Error", err);
          });
      }


    }
  }, [groupedPage, alertPopup, dateFilter])

  useEffect(() => {
    setAttentionTableIsLoading(true);

    if ((!count5?.length && !count6) || count6 === "test") {
      if (tenandId.length == 0) {
        let data = {
          "query": {
            "match_all": {}
          }
        }
        fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
          var licence = []
          hits?.map((item) => {
            licence.push(item?._source.tenant_id)
          })
          setTenandId(licence[0])
          getInvestigationAttention(licence[0], 100, "desc", (attentionPage - 1) * attentionPerPage, filterAtten, dateFilter).then(
            (res) => {
              if (!res?.isError) {
                let hits = res.data.hits
                setAttentionTotalPageCount(hits?.total?.value)
                setInvestigateAttenData(hits?.hits);
                // setInvestigateData(result);
                setAttentionTableIsLoading(false)
              } else {
                setInvestigateAttenDataError(res)
                setAttentionTableIsLoading(false)
              }
            }
          )
            .catch((err) => {
              setInvestigateAttenData([]);
              setAttentionTableIsLoading(false)
              console.log("Error", err);
            });
        }).catch((err) => {
          console.log("Error", err)
          setAttentionTableIsLoading(false)
        })

      }
      else {
        getInvestigationAttention(tenandId, 100, "desc", (attentionPage - 1) * attentionPerPage, filterAtten, dateFilter).then(
          (res) => {
            if (!res?.isError) {
              let hits = res.data.hits
              setAttentionTotalPageCount(hits?.total?.value)
              setInvestigateAttenData(hits?.hits);
              // setInvestigateData(result);
              setAttentionTableIsLoading(false)
            } else {
              setInvestigateAttenDataError(res)
              setAttentionTableIsLoading(false)
            }
          }
        )
          .catch((err) => {
            setInvestigateAttenData([]);
            setAttentionTableIsLoading(false)
            console.log("Error", err);
          });
      }


    }
  }, [attentionPage, alertPopup, dateFilter])




  useEffect(() => {
    let filterColumns = [];
    tableHeader?.map((item) => {
      if (item.value === "threat_score") {
        filterColumns.push({ value: item.value, label: item.text, isNumber: true });
      } else if (item.value !== "detection_timestamp") {
        if (item.value === "closing_tag") {
          filterColumns.push({ value: "closing_tag.keyword", label: item.text, isWildCard: true });
        } else {
          filterColumns.push({ value: item.value, label: item.text, isWildCard: true });
        }
      }
    });
    console.log(filterColumns);
    setColumnValues(filterColumns);
  }, [tableHeader]);

  const handleonDropDownChange = (selectedVal) => {
    if (selectedVal.label !== "ALL") {
      setIsInvestigationTableLoading(true)
      getLogFilter(selectedVal.label, filter, dateFilter, 100, (page - 1) * perPage).then(({ data: { hits } }) => {
        setTotalPageCount(hits.total.value)

        setInvestigationTableData(hits.hits)
        // setSelectedDropItem(hits[0]?._source.log_category[0]);
        setSelectedDropItem(selectedVal.label);
        setTableHeader(tableColumns[selectedVal.label])

        getTableData()
          .then(({ data }) => {
            let head = [];
            Object.entries(data).map((item) => {
              head.push({ label: item[0] });
            });


            // data[selectedVal.label].forEach((x) => {
            //   let index = columns.findIndex(y => y.value === x.value)
            //   if (index >= 0) {
            //     columns[index].isSelected = true

            //   }
            // })

            // table more fields
            let checked = Object.entries(data).find((item) => item[0] === "Common");
            setCheckBox(checked[1]);

            const selectedDataForTable = Object.entries(data).find(
              (item) => item[0] === selectedVal.label
            );


            setInvestigationTableHead(selectedDataForTable[1]);
            // setInvestigationTableHead(selectedDataForTable[1]);
            setIsInvestigationTableLoading(false)
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }).catch((err) => {
        console.log("Error", err)
        setIsInvestigationTableLoading(false)
      })
      // setSelectedDropItem(selectedVal.label);
    } else {
      setSelectedDropItem(selectedVal.label);
      setDefaultList(!defaultList)
    }

  };

  // Incident Local Storage
  const handleManageColumnClick = () => {
    setShowFieldBar(!showFieldBar);
    setShowAddFilter(false);
  };

  useEffect(() => {
    localStorage.setItem('showFieldBar', showFieldBar);
    localStorage.setItem('manageDatas', JSON.stringify(manageDatas));
  }, [showFieldBar, manageDatas]);
  // Attention Local Storage
  const handleManageColumnClickAtt = () => {
    setShowFieldBar(!showFieldBar);
    setShowAddFilter(false);
  };
  useEffect(() => {
    localStorage.setItem('showFieldBar', showFieldBar);
    localStorage.setItem('manageDatasAtten', JSON.stringify(manageDatasAtten));
  }, [showFieldBar, manageDatasAtten]);


  const numfields = [
    "process.pid",
    "destination.port",
    "source.port",
    "event.code",
    "agent.hostname",
    "winlog.event_data.SubjectUserName",
    "email.from.address",
    "email.receiver.address",
    "detection_name",
    "source_port",
    "dest_port"
  ];


  async function getInvestigationTable2(filter, date, count, sort, page, selectedDropItem) {
    var data = {
      from: page,
      size: count,
      query: {
        bool: {
          filter: [
            {
              match_phrase: {
                investigation_status: "Investigated"
              }
            },
          ],
          must: [],
          must_not: [
          ],
          should: []
        }
      }
    }
    if (selectedDropItem && selectedDropItem !== 'ALL') {
      let must = {
        match: {
          log_category: selectedDropItem
        }
      }
      data.query.bool.must.push(must)
    }
    let query = invFilterGenerate(data, filter, date, sort);

    return await fpostC(
      `${localStorage.getItem(
        "CYBOT_URL"
      )}/cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`,
      query
    );
  }



  const getFilteredTable = (filter, date = {}) => {
    setPage(1)
    setIsInvestigationTableLoading(true);
    if (filter || date?.to) {
      getInvestigationTable2(filter, date, 100, "desc", (page - 1) * perPage, selectedDropItem)
        // .then((res) => console.log("API OF FILTER", res))
        .then(
          ({
            data: {
              hits
            },
          }) => {
            hits.hits.map(
              (item) =>
              (item._source.time = getTimeFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            hits.hits.map(
              (item) =>
              (item._source.date = getDateFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            setTotalPageCount(hits.total.value)

            // const unique = [...new Map(hits.map((m) => [m._source.detection_timestamp, m])).values()];
            setInvestigationTableData(hits.hits);
            setIsInvestigationTableLoading(false);

            console.log("API OF FILTER", hits);
            // setTimelineIsLoading(false);
          }
        )
        .catch((err) => {
          setIsInvestigationTableLoading(false);
          console.log("Error", err)
        });
    } else {
      getInvestigationTable2(filter, date, 100, "desc", (page - 1) * perPage, selectedDropItem)
        .then(
          ({
            data: {
              hits
            },
          }) => {
            // hits.map((item) => {
            //   item._source.detection_timestamp = getDateFromTimestamp(
            //     item._source.detection_timestamp
            //   );
            // });
            hits.hits.map(
              (item) =>
              (item._source.time = getTimeFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            hits.hits.map(
              (item) =>
              (item._source.date = getDateFromTimestamp(
                item._source.detection_timestamp
              ))
            );
            setTotalPageCount(hits.total.value)
            setInvestigationTableData(hits.hits);
            setIsInvestigationTableLoading(false);

            // console.log("investigationtable", hits);
            // setTimelineIsLoading(false);
          }
        )
        .catch((err) => {
          setIsInvestigationTableLoading(false);

          console.log("Error", err);
        });
    }
  };
  const getFilteredTableGroup = async (filter, date = {}) => {
    setGroupedPage(1)
    await setTimelineIsLoading(true);
    var tenantid = tenandId
    if (tenandId?.length === 0) {
      let data = {
        "query": {
          "match_all": {}
        }
      }
      await fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
        var licence = []
        hits?.map((item) => {
          licence.push(item?._source.tenant_id)
        })
        setTenandId(licence[0])
        tenantid = licence[0]
      }).catch((err) => {
        console.log("Error", err)
      })
    }
    if (filter || date?.to) {
      // .then((res) => console.log("API OF FILTER", res))
      await getInvestigationGrouped(tenantid, 100, "desc", (groupedPage - 1) * groupPerPage, filter, date).then(
        ({
          data: {
            hits,
          },
        }) => {
          setGroupTotalPageCount(hits?.total?.value)


          setInvestigateData(hits?.hits);
          // setInvestigateData(result);
          setTimelineIsLoading(false)

        }
      )
        .catch((err) => {
          setInvestigateData([])
          setTimelineIsLoading(false)
          console.log("Error", err);
        });

    } else {
      await getInvestigationGrouped(tenantid, 100, "desc", (groupedPage - 1) * groupPerPage, filter, date).then(
        ({
          data: {
            hits,
          },
        }) => {
          setGroupTotalPageCount(hits?.total?.value)

          setInvestigateData(hits?.hits);
          // setInvestigateData(result);
          setTimelineIsLoading(false)

        }
      )
        .catch((err) => {
          setInvestigateData([])
          setTimelineIsLoading(false)
          console.log("Error", err);
        });

    }
  };
  const getFilteredTableAtten = async (filter, date = {}) => {
    setAttentionPage(1)
    await setAttentionTableIsLoading(true);
    var tenantid = tenandId
    if (tenandId?.length === 0) {
      let data = {
        "query": {
          "match_all": {}
        }
      }
      await fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
        var licence = []
        hits?.map((item) => {
          licence.push(item?._source.tenant_id)
        })
        setTenandId(licence[0])
        tenantid = licence[0]
      }).catch((err) => {
        console.log("Error", err)
      })
    }
    if (filter || date?.to) {
      // .then((res) => console.log("API OF FILTER", res))
      await getInvestigationAttention(tenantid, 100, "desc", (groupedPage - 1) * groupPerPage, filter, date).then(
        ({
          data: {
            hits,
          },
        }) => {
          setAttentionTotalPageCount(hits?.total?.value)


          setInvestigateAttenData(hits?.hits);
          // setInvestigateData(result);
          setAttentionTableIsLoading(false)

        }
      )
        .catch((err) => {
          setInvestigateAttenData([])
          setAttentionTableIsLoading(false)
          console.log("Error", err);
        });

    } else {
      await getInvestigationAttention(tenantid, 100, "desc", (groupedPage - 1) * groupPerPage, filter, date).then(
        ({
          data: {
            hits,
          },
        }) => {
          setAttentionTotalPageCount(hits?.total?.value)

          setInvestigateAttenData(hits?.hits);
          // setInvestigateData(result);
          setAttentionTableIsLoading(false)

        }
      )
        .catch((err) => {
          setInvestigateAttenData([])
          setAttentionTableIsLoading(false)
          console.log("Error", err);
        });

    }
  };


  useMemo(() => {

    if (active !== undefined && active !== null) {
      setActiveTab(active)
    }

    if (severity !== undefined && active !== null) {

      var number = 0

      if (DateFilter === "1 Year") {
        number = 365
      } else if (DateFilter === "30 Days") {
        number = 30
      } else if (DateFilter === "1 Day") {
        number = 1
      } else {
        number = 7
      }
      const finalFilterDate = moment().utc()
        .subtract(number, "days")
        ?.format("yyyy-MM-DDTHH:mm");

      if (active === "Detections") {
        setIsInvestigationTableLoading(true);

        let filterList = [{
          "column": {
            "label": "Severity",
            "value": "detection_severity",
            "isWildCard": "true",
            "isNumber": null
          },
          "type": "is",
          "selectedValue": severity,
          "from": "",
          "to": "",
          "isInline": false
        }]
        setFilter(filterList)


        // let dateTemp = {
        //   from: finalFilterDate,
        //   to: moment().utc().format("yyyy-MM-DDTHH:mm"),
        //   f: finalFilterDate,
        //   e: moment().utc().format("yyyy-MM-DDTHH:mm"),
        //   range: number,
        // };
        const storedFilter = JSON.parse(localStorage.getItem("filters"));

        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        setDateFilter(currentPageDateFilter)
        getFilteredTable(filterList, currentPageDateFilter)

      }
      else if (active === "Incidents") {
        let filterList = [{
          "column": {
            "label": "Severity",
            "value": "detection_severity",
            "isWildCard": "true",
            "isNumber": null
          },
          "type": "is",
          "selectedValue": severity,
          "from": "",
          "to": "",
          "isInline": false
        }]
        setFilterGroup(filterList)
        let dateTemp = {
          from: finalFilterDate,
          to: moment().utc().format("yyyy-MM-DDTHH:mm"),
          f: finalFilterDate,
          e: moment().utc().format("yyyy-MM-DDTHH:mm"),
          range: number,
        };
        setTimelineIsLoading(true);
        const storedFilter = JSON.parse(localStorage.getItem("filters"));

        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        setDateFilter(currentPageDateFilter)
        getFilteredTableGroup(filterList, currentPageDateFilter)

      }

    }


  }, [severity, active])
  const handleInlineFilter = () => {
    filter &&
      getInlineFilter({
        detection_name: filter[0]?.column,
      })
        .then(
          ({
            data: {
              hits: { hits },
            },
          }) => { }
        )
        .catch((err) => {
          console.log("Error", err);
        });
  };

  const handleOpenModal = (data, display) => {
    setDetailpage(data);
    setShow(display);
  };


  const [view, setView] = useState('')

  const handleOpenRepetitionModal = (data, display, isPage) => {
    // console.log("TIMELINE CASE :", data)
    // page !== "grouped" ? setDetailpage(data) : setDetailpage({ _source: data })
    setDetailpage(data)
    // setRepetitionDetailpage(data);
    setShowRepetition(display);
    setView(isPage === "Incidents" && true)
  };
  // Manage coloum incident
  const handleCheckboxChangeIncidents = (event) => {
    const { value, checked } = event.target;

    // Update the manageDatas state based on the checkbox change
    setManageDatas((prevManageDatas) =>
      prevManageDatas.map((item) =>
        item.value === value ? { ...item, isSelected: checked } : item
      )
    );
  };
  // Manage coloum Attention
  const handleCheckboxChangeAttention = (event) => {
    const { value, checked } = event.target;

    // Update the manageDatas state based on the checkbox change
    setManageDatasAtten((prevManageDatas) =>
      prevManageDatas.map((item) =>
        item.value === value ? { ...item, isSelected: checked } : item
      )
    );
  };

  function handleCloseModal() {
    setShow(false);
    setShowRepetition(false);
    setCloseShow(false)
  }

  function removeHtmlChars(htmlString) {
    return htmlString ? htmlString.replace(/&(lt|gt|amp|quot);/g, '') : '';
  }


  function handleTabSelect(key) {

    setCloseAttention([])
    setCloseL2Detection([])
    setCloseDetectionCheck([])
    setCheckL2(false)
    setCheckAttention(false)
    setCheckL1(false)
    // case
    setSelectedItemsIncident([])
    setSelectedItemsDetection([])
    setSelectedItemsAttention([])

    localStorage.setItem("activeTab.investigate", key)


    if (localStorage.getItem("activeTab.investigate") === "Detections") {
      setActiveTab("Detections")
      setIsTableTabSelected(true)
    }
    else if (localStorage.getItem("activeTab.investigate") === "Attention") {
      setActiveTab("Attention")
      setIsTableTabSelected(false)
    }
    else if (localStorage.getItem("activeTab.investigate") === "Incidents") {
      setActiveTab("Incidents")
      setIsTableTabSelected(false)
    }
    else {
      setIsTableTabSelected(false)
    }
  }

  function renderModal() {
    return <InvestigationDashboard investigationData={detailpage} />;
  }

  function renderRepetitionModal() {
    return <RepetitionDashboard details={detailpage} view={view} />;
  }

  function handleCheckboxChange(e) {
    let { value, checked } = e.target;

    if (checked) {
      setCheckedItems([...checkedItems, { text: value, value: value }]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item.value !== value));
    }

    let index = tableColumns[selectedDropItem].findIndex(x => x.value === value)
    tableColumns[selectedDropItem][index].isSelected = !tableColumns[selectedDropItem][index].isSelected
    setTableColumns(tableColumns)
    localStorage.setItem("investigateCheckedTableColumns", JSON.stringify(tableColumns))
  }

  const handleOpenCommentModal = (display, status) => {
    setCloseShow(display);
    setDetectionStatusCheck(status)
  };

  const handleClosePopup = () => {
    setCloseShow(false);
  };

  const handleInvestigationOpen = (tenandID, id, status) => {
    if (status !== "closed") {
      const data = { "doc": { "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString(), "detection_status": "open" } }
      fpostC(`${baseURL}/cybot_proxy/${tenandID}/_update/${id}`, data).then((res) => {
        console.log("OPEN RSE", res)
      })
    }
  }


  // close detection check
  const [closeDetectionCheck, setCloseDetectionCheck] = useState([]);
  const [closeAttention, setCloseAttention] = useState([]);
  const [closeL2Detection, setCloseL2Detection] = useState([]);


  const handleCloseDetecion = (id, tenandId, isChecked, status, playBookId) => {


    if (isChecked) {
      setCloseDetectionCheck(prevcloseDetectionCheck => [...prevcloseDetectionCheck, { id: id, tenandId: tenandId, status: status }]);
    } else {
      setCheckL1(false)
      setCloseDetectionCheck(prevcloseDetectionCheck => prevcloseDetectionCheck.filter(item => item.id !== id));
    }


    let attachedCaseName = `INV-DET_${playBookId}`

    if (selectedItemsDetection.includes(attachedCaseName)) {

      setSelectedItemsDetection(selectedItemsDetection.filter(item => item !== attachedCaseName))
    } else {

      setSelectedItemsDetection([...selectedItemsDetection, attachedCaseName])
    }
  }

  const [checkL1, setCheckL1] = useState(false)
  const handleSelectAllChange = (event, data) => {
    const { checked } = event.target;

    setCheckL1(checked)


    let dataList = data?.map((item) => (
      {
        id: item?._id,
        tenandId: item?._index,
        status: item?._source?.detection_status
      }
    ))

    if (checked) {
      setCloseDetectionCheck([...dataList, ...closeDetectionCheck]);

      let array = []
      data.map((item) => {
        let attachedCaseName = `INV-DET_${item?._source?.hunt_detection_id}`;
        array.push(attachedCaseName)
      })
      setSelectedItemsDetection(array)
    } else {
      setCloseDetectionCheck([]);
      setSelectedItemsDetection([])
    }




  };

  const handleCaseAttention = (_id, _index, checked, detection_status, playBookId) => {


    if (checked) {
      setCloseAttention(prevcloseDetectionCheck => [...prevcloseDetectionCheck, { id: _id, tenandId: _index, status: detection_status }]);
    } else {
      setCheckAttention(false)
      setCloseAttention(prevcloseDetectionCheck => prevcloseDetectionCheck.filter(item => item.id !== _id));
    }

    let attachedCaseName = `INC-ATT_${playBookId}`
    if (selectedItemsAttention.includes(attachedCaseName)) {
      setSelectedItemsAttention(selectedItemsAttention.filter(item => item !== attachedCaseName))
    } else {

      setSelectedItemsAttention([...selectedItemsAttention, attachedCaseName])
    }
  }
  const [checkAttention, setCheckAttention] = useState(false)
  const handleSelectAllAttentionChange = (event, data) => {
    const { checked } = event.target;
    setCheckAttention(checked)
    let dataList = data?.map((item) => (
      {
        id: item?._id,
        tenandId: item?._index,
        status: item?._source?.detection_status
      }
    ))

    if (checked) {
      setCloseAttention([...dataList, ...closeAttention]);

      let array = []
      data.map((item) => {
        let attachedCaseName = `INV-INC_${item?._source?.grp_id}`;
        array.push(attachedCaseName)
      })


      setSelectedItemsAttention(array)

    } else {
      setCloseAttention([]);
      setSelectedItemsAttention([])
    }





  };




  const handleInvestigateIncident = (_id, _index, checked, detection_status, playBookId) => {


    if (checked) {
      setCloseL2Detection(prevcloseDetectionCheck => [...prevcloseDetectionCheck, { id: _id, tenandId: _index, status: detection_status }]);
    } else {
      setCheckL2(false)
      setCloseL2Detection(prevcloseDetectionCheck => prevcloseDetectionCheck.filter(item => item.id !== _id));
    }

    let attachedCaseName = `INV-INC_${playBookId}`
    if (selectedItemsIncident.includes(attachedCaseName)) {
      setSelectedItemsIncident(selectedItemsIncident.filter(item => item !== attachedCaseName))
    } else {

      setSelectedItemsIncident([...selectedItemsIncident, attachedCaseName])
    }
  }

  const [checkL2, setCheckL2] = useState(false)
  const handleSelectAllL2Change = (event, data) => {
    const { checked } = event.target;

    setCheckL2(checked)

    let dataList = data?.map((item) => (
      {
        id: item?._id,
        tenandId: item?._index,
        status: item?._source?.detection_status
      }
    ))


    if (checked) {
      setCloseL2Detection([...dataList, ...closeL2Detection]);

      let array = []
      data.map((item) => {
        let attachedCaseName = `INV-INC_${item?._source?.grp_id}`;
        array.push(attachedCaseName)
      })
      setSelectedItemsIncident(array)
    } else {
      setCloseL2Detection([]);
      setSelectedItemsIncident([])
    }







  };

  // const handleCloseSelectedDetections = async () => {
  //   const bulkRequestBody = []

  //   for (let i = 0; i <= closeDetectionCheck.length; i++) {
  //     bulkRequestBody.push(
  //       { update: { _index: closeDetectionCheck.tenandId, _id: closeDetectionCheck.id } },
  //       { doc: { detection_status: 'closed' } },
  //       {}
  //     )
  //   }

  //   try {
  //     fpostC(`${baseURL}/cybot_proxy/_bulk`, bulkRequestBody.join('\n') + '\n').then((res) => {
  //       alert("suxxes finally close")
  //       console.log("res new close :", res)
  //     })
  //     // Process the response here
  //   } catch (error) {
  //     alert("bulk failed")
  //   }
  // }

  // Add Case
  const handleCreate = () => {

    if (activeTab === 'Attention') {
      setCaseAttachmentList(selectedItemsAttention)
    } else if (activeTab === 'Incidents') {
      setCaseAttachmentList(selectedItemsIncident)
    } else {
      setCaseAttachmentList(selectedItemsDetection)
    }

    setCaseShow(true);
  };


  const handleInputChange = (value) => {
    // setDetectionClosed(e.target.value)
    // setDetectionOpen(e.target.value)
    setDetectionClosed({
      comment: value,
    })
    setDetectionOpen({
      comment: value,
    })
  }

  const handleCloseSelectedDetections = (e) => {
    e.preventDefault();

    // for (let i = 0; i < closeDetectionCheck.length; i++) {
    //   const detection = closeDetectionCheck[i];
    //   bulkRequestBody.push(
    //     { update: { _index: detection.tenandId, _id: detection.id } },
    //     { doc: { detection_status: 'closed' } },
    //   );
    // }

    if (detectionStatusCheck === 'closed' && closeDetectionCheck.length > 0) {
      closeDetectionCheck.map(async (item) => {

        let data = { "doc": { "detection_status": "closed", "comment": detectionClosed?.comment, "closing_tag": closureCategory, "external_investigation": externalInv, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString } }

        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Detections Closed")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    } else {
      closeDetectionCheck.map(async (item) => {
        const data = { "doc": { "comment": detectionOpen?.comment, "closing_tag": "", "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }
        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Detections Opened")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    }


    // L2

    if (detectionStatusCheck === 'closed' && closeL2Detection.length > 0) {
      closeL2Detection.map(async (item) => {

        let data = { "doc": { "detection_status": "closed", "comment": detectionClosed?.comment, "closing_tag": closureCategory, "external_investigation": externalInv, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString } }

        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Detections Closed")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    } else {
      closeL2Detection.map(async (item) => {
        const data = { "doc": { "comment": detectionOpen?.comment, "closing_tag": "", "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }
        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Detections Opened")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    }

    // Incidents

    if (detectionStatusCheck === 'closed' && closeAttention.length > 0) {
      closeAttention.map(async (item) => {

        let data = { "doc": { "detection_status": "closed", "comment": detectionClosed?.comment, "closing_tag": closureCategory, "external_investigation": externalInv, "closed_by": localStorage.getItem("loggedUser"), "closed_at": new Date().toISOString } }

        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Incidents Closed")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    } else {
      closeAttention.map(async (item) => {
        const data = { "doc": { "comment": detectionOpen?.comment, "closing_tag": "", "opened_by": localStorage.getItem("loggedUser"), "opened_at": new Date().toISOString, "detection_status": "open" } }
        try {
          const response = await fpostC(`${baseURL}/cybot_proxy/${item.tenandId}/_update/${item.id}`, data);
          setCloseShow(false)
          setAlertMessage("Detections Opened")
          setAlertPopup(true);
          setCloseL2Detection([])
          // setCloseRefresh(response)
          setCloseDetectionCheck([])
          setCloseAttention([])
        } catch (error) {
          console.error('Error', error);
          setCloseL2Detection([])
          setCloseDetectionCheck([])
          setCloseAttention([])
        }
      })
    }



  };


  // useEffect(() => {

  //   let filterColumns = []

  //   tableHeader.map((item) => {
  //     filterColumns.push({ value: item.value, label: item.text, isWildCard: true })
  //   })

  //   setColumnValues(filterColumns)
  // }, [tableHeader])


  function renderCommentModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleCloseSelectedDetections}>
          <div className="row">
            {/* <div>
                        <h6>Technique Name</h6>
                        <span>{state?.sourceList.technique_name}</span>
                    </div> */}
            {/* <div>
              <h6>Detection Name</h6>
            </div> */}
            {detectionStatusCheck === 'closed' &&
              <>
                <div className="form-group mt-2">
                  <label className="control-label">Closure code</label>
                  <select
                    className="form-select input-lg"
                    name="severity"
                    onChange={(e) => setClosureCategory(e.target.value)}
                    // onChange={(e) => changeSeverity(e, defSev)}
                    // value={severity}
                    required
                  >
                    <option value="">Select Category</option>
                    <option value="True Positive - Confirmed Suspicious Activity">True Positive - Confirmed Suspicious Activity</option>
                    <option value="Benign Positive - Validated">Benign Positive - Validated</option>
                    <option value="Benign Positive - Expected">Benign Positive - Expected</option>
                    <option value="False Positive - Data Error">False Positive - Data Error</option>
                    <option value="False Positive - Incorrect Alert Logic">False Positive - Incorrect Alert Logic</option>

                  </select>

                </div>

                <div className="form-group mt-2">
                  <label className="control-label">External Investigation</label>
                  <select
                    className="form-select input-lg"
                    name="closing_tag"
                    onChange={(e) => setExternalInv(e.target.value)}
                    // onChange={(e) => changeSeverity(e, defSev)}
                    // value={severity}
                    required
                  >
                    <option value="">Select</option>
                    <option value="true">True</option>
                    <option value="false">False</option>

                  </select>

                </div>
              </>

            }

            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                {detectionStatusCheck === 'closed' ?
                  <ReactQuill
                    value={detectionClosed.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionClosed.comment} onChange={handleInputChange} required ></textarea>
                  :
                  <ReactQuill
                    value={detectionOpen.comment}
                    onChange={(value) => handleInputChange(value)}
                  />
                  // <textarea className="form-control" id="comment" rows="3" name="comment" value={detectionOpen.comment} onChange={handleInputChange} required ></textarea>
                }
              </div>
            </div>
          </div>
          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-primary" type="submit">
                Submit
              </Button>
            </div>
          </div>
          {/* <button type="submit" className="btn btn-primary">Create</button>
          <button type="button" className="btn btn-secondary">Close</button> */}
        </form>
      </div>
    )
  }





  const createStateForNav = (item) => {
    return (
      <Link
        style={{ marginLeft: "20px" }}
        to={`/investigate/summary/${item._source.hunt_detection_id}`} state={{ sourceList: item._source, index_id: item }}
        onClick={() => handleInvestigationOpen(item._index, item?._id, item?._source?.detection_status)}
      >
        <TableViewMore Icon={Desktop} dataToggle={"Investigation Summary"} dataPlacement={"bottom"} />
      </Link>
    )
  }

  return (
    <>
      <div
        className="col-12 p-4 position-relative rounded-3"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-8 flex-wrap gap-2"
          style={styles.filterWrap}
        >
          {!activeTab || activeTab === "Attention" ?
            <div className="position-relative d-flex gap-2">
              <Button
                ref={ref}
                onClick={() => { setShowAddFilterAtten(!showAddFilterAtten); setShowFieldBar(false) }}
              >
                Add filter
              </Button>
              {selectedItemsAttention.length > 0 && (
                <Button
                  variant="light"
                  onClick={(e) => handleCreate()}
                >
                  Create Case
                </Button>
              )}
              {showAddFilterAtten && (
                <AddFilter
                  filter={filterAtten}
                  setFilter={setFilterAtten}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  setShowAddFilter={setShowAddFilterAtten}
                  getFilteredTable={getFilteredTableAtten}
                  setPage={setAttentionPage}
                  // columnValues={columnValues.length > 0 ? columnValues : defaultColumnValues}
                  columnValues={[
                    { value: "user", label: "User Name", isWildCard: true },
                    { value: "grp_id", label: "Incident ID" },


                    { value: "host", label: "Host", isWildCard: true },
                    { value: "detection_status", label: "Status", isWildCard: true },
                    { value: "detection_severity", label: "Severity", isWildCard: true },
                    { value: "detection_name", label: "Incident Name", isWildCard: true },
                    { value: "tag", label: "Tags", isWildCard: true },
                    { value: "threat_classification", label: "Classification" },
                    { value: "threat_score", label: "Threat Score", isNumber: true },
                    { value: "tactic_name", label: "Mitre Tactic" },
                    { value: "technique_name", label: "Mitre Technique" },
                    { value: "closing_tag.keyword", label: "Closure Code" },

                    // { value: "detection_status", label: "Detection Status" },

                  ]}
                  module={module}
                  tab={tabAtten}
                />
              )}
            </div>
            : activeTab === "Detections" ?
              <div className="position-relative d-flex gap-2">
                <Button
                  ref={ref}
                  onClick={() => { setShowAddFilter(!showAddFilter); setShowFieldBar(false) }}
                >
                  Add filter
                </Button>
                {selectedItemsDetection.length > 0 && (
                  <Button
                    variant="light"
                    onClick={(e) => handleCreate()}
                  >
                    Create Case

                  </Button>
                )}
                {showAddFilter && (
                  <AddFilter
                    filter={filter}
                    setFilter={setFilter}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    setShowAddFilter={setShowAddFilter}
                    getFilteredTable={getFilteredTable}
                    setPage={setPage}
                    columnValues={columnValues.length > 0 ? columnValues : defaultColumnValues}
                    // columnValues={[
                    //   { value: "detection_name", label: "Investigation Name", isWildCard: true },
                    //   { value: "user", label: "User Name", isWildCard: true },
                    //   { value: "host", label: "Host", isWildCard: true },
                    //   { value: "detection_status", label: "Status", isWildCard: true },
                    //   { value: "detection_severity", label: "Severity", isWildCard: true },
                    //   { value: "source_ip", label: "Source IP" },
                    //   { value: "dest_ip", label: "Destination IP" },
                    //   { value: "source_port", label: "Source Port" },
                    //   { value: "dest_port", label: "Destination Port" },
                    //   { value: "process_name", label: "Process Name", isWildCard: true },
                    //   { value: "process_hash", label: "Process Hash" },
                    //   { value: "process_commandline", label: "Command Line", isWildCard: true },
                    //   { value: "closing_tag.keyword", label: "Closing Tag" },
                    //   { value: "hunt_detection_id", label: "Detection ID" },
                    //   { value: "log_category", label: "Alert Source" },
                    //   { value: "playbook_id", label: "Rulebook" },
                    //   { value: "tactic_name", label: "MITRE Tactic" },
                    //   { value: "technique_name", label: "MITRE Technique" },
                    //   { value: "threat_score", label: "Threat Score" },
                    //   { value: "url", label: "URL" },











                    // ]}

                    module={module}
                    tab={tab}
                  />
                )}
              </div>
              : activeTab === "Incidents" ?
                <div className="position-relative d-flex gap-2">
                  <Link
                    style={{ color: "white" }}

                  >
                    {/* <button className="btn btn-primary" onClick={(e) => handleCreate()}>Add Case</button> */}
                  </Link>
                  <Button
                    ref={ref}
                    onClick={() => { setShowAddFilterGroup(!showAddFilterGroup); setShowFieldBar(false) }}
                  >
                    Add filter
                  </Button>
                  {selectedItemsIncident.length > 0 && (
                    <Button
                      variant="light"
                      onClick={(e) => handleCreate()}
                    >
                      Create Case
                    </Button>
                  )}
                  {showAddFilterGroup && (
                    <AddFilter
                      filter={filterGroup}
                      setFilter={setFilterGroup}
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                      setShowAddFilter={setShowAddFilterGroup}
                      getFilteredTable={getFilteredTableGroup}
                      setPage={setGroupedPage}
                      // columnValues={columnValues.length > 0 ? columnValues : manageDatas}
                      columnValues={[
                        // { value: "user", label: "User Name", isWildCard: true },
                        { value: "host", label: "Host Name ", isWildCard: true },
                        { value: "detection_status", label: "Status", isWildCard: true },
                        { value: "detection_severity", label: "Severity", isWildCard: true },
                        { value: "detection_name", label: "Incident Name", isWildCard: true },
                        { value: "tag", label: "Tags", isWildCard: true },
                        { value: "threat_classification", label: "Classification" },
                        { value: "threat_score", label: "Threat Score", isNumber: true },
                        { value: "tactic_name", label: "Mitre Tactic" },
                        { value: "technique_name", label: "Mitre Technique" },
                        // { value: "detection_status", label: "Detection Status" },
                        { value: "opened_by", label: "Opened By" },
                        { value: "grp_id", label: "Incident ID" },
                        { value: "closing_tag.keyword", label: "Closure Code" },



                      ]}
                      module={module}
                      tab={tabGroup}
                    />
                  )}
                </div> : ""
          }

          {/* old */}
          {/* {closeDetectionCheck.map((item) => (
            <>
              {item?.status !== "closed" &&
                <div className="close-detection">
                  <button className="btn btn-danger mr-2" disabled={closeDetectionCheck.length === 0} onClick={() => handleOpenCommentModal(true)}>Close </button>
                </div>
              }
            </>
          ))
          } */}
          {closeDetectionCheck.length > 0 &&
            <>
              {closeDetectionCheck.some((item) => item.status === 'closed') && closeDetectionCheck.some((item) => item.status === 'open') ?
                <></>
                : closeDetectionCheck.some((item) => item.status === 'closed') ?
                  <div className="close-detection">
                    <button
                      className="btn btn-secondary"
                      // disabled={closeDetectionCheck.length === 0} 
                      onClick={() => handleOpenCommentModal(true, 'open')}>Open</button>
                  </div>
                  : (
                    <div className="close-detection">
                      <button className="btn btn-secondary"
                        // disabled={closeDetectionCheck.length === 0} 
                        onClick={() => handleOpenCommentModal(true, 'closed')}>Close </button>
                    </div>
                  )
              }
            </>
          }

          {/* {closeL2Detection.length > 0 &&
            <>
              {closeL2Detection.some((item) => item.status === 'closed') && closeL2Detection.some((item) => item.status === 'open') ?
                <></>
                : closeL2Detection.some((item) => item.status === 'closed') ?
                  <div className="close-detection">
                    <button
                      className="btn btn-secondary"
                      // disabled={closeL2Detection.length === 0} 
                      onClick={() => handleOpenCommentModal(true, 'open')}>Open</button>
                  </div>
                  : (
                    <div className="close-detection">
                      <button className="btn btn-secondary"
                        // disabled={closeL2Detection.length === 0} 
                        onClick={() => handleOpenCommentModal(true, 'closed')}>Close </button>
                    </div>
                  )
              }
            </>
          } */}

          {/* {closeAttention.length > 0 &&
            <>
              {closeAttention.some((item) => item.status === 'closed') && closeAttention.some((item) => item.status === 'open') ?
                <></>
                : closeAttention.some((item) => item.status === 'closed') ?
                  <div className="close-detection">
                    <button
                      className="btn btn-secondary"
                      // disabled={closeAttention.length === 0} 
                      onClick={() => handleOpenCommentModal(true, 'open')}>Open</button>
                  </div>
                  : (
                    <div className="close-detection">
                      <button className="btn btn-secondary"
                        // disabled={closeAttention.length === 0} 
                        onClick={() => handleOpenCommentModal(true, 'closed')}>Close </button>
                    </div>
                  )
              }
            </>
          } */}


          {localStorage.getItem("activeTab.investigate") === "Detections" ?
            <div className="position-relative">
              <Button
                variant="light"
                ref={ref}
                onClick={() => { setShowFieldBar(!showFieldBar); setShowAddFilter(false) }}
                disabled={investigationTableData.length === 0}
              >
                Manage columns
              </Button>
              {showFieldBar && (
                <div
                  className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: "111",
                    minWidth: "20rem",
                    maxHeight: "50vh",
                    overflow: "auto"
                  }}
                >
                  {manageColumnLoader ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spinner animation="border" role="status" size="sm" />
                    </div>
                  ) : (
                    <div className="table-fields">
                      {tableHeader?.map((item) => (
                        <div className="d-flex align-items-center py-2 gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.value}
                              onChange={handleCheckboxChange}
                              checked={item.isSelected ? true : false}
                              disabled={item.value === "detection_timestamp" || item.value === "detection_name"}
                            // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                            />
                            <label className="form-check-label" for="flexCheckDefault">
                              {item.text}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              )}
            </div> : localStorage.getItem("activeTab.investigate") === "Incidents" ?
              <div className="position-relative">
                <Button
                  variant="light"
                  ref={ref}
                  onClick={handleManageColumnClick}
                  disabled={investigateData?.length === 0}
                >
                  Manage columns
                </Button>
                {showFieldBar && (
                  <div
                    className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                    style={{
                      position: "absolute",
                      right: 0,
                      zIndex: "111",
                      minWidth: "20rem",
                      maxHeight: "50vh",
                      overflow: "auto"
                    }}
                  >
                    {manageColumnLoader ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" role="status" size="sm" />
                      </div>
                    ) : (
                      <div className="table-fields">
                        {manageDatas?.map((item) => (
                          <div className="d-flex align-items-center py-2 gap-2" key={item.value}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.value}
                                onChange={handleCheckboxChangeIncidents}
                                checked={item.isSelected ? true : false}
                                disabled={item.value === "incident_timestamp" || item.value === "incident_name"}
                              />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                {item.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              : localStorage.getItem("activeTab.investigate") || activeTab === "Attention" ?
                <div className="position-relative">
                  <Button
                    variant="light"
                    ref={ref}
                    onClick={handleManageColumnClickAtt}
                    disabled={investigateAttenData?.length === 0}
                  >
                    Manage columns
                  </Button>
                  {showFieldBar && (
                    <div
                      className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                      style={{
                        position: "absolute",
                        right: 0,
                        zIndex: "111",
                        minWidth: "20rem",
                        maxHeight: "50vh",
                        overflow: "auto"
                      }}
                    >
                      {manageColumnLoader ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Spinner animation="border" role="status" size="sm" />
                        </div>
                      ) : (
                        <div className="table-fields">
                          {manageDatasAtten?.map((item) => (
                            <div className="d-flex align-items-center py-2 gap-2" key={item.value}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item.value}
                                  onChange={handleCheckboxChangeAttention}
                                  checked={item.isSelected ? true : false}
                                  disabled={item.value === "incident_timestamp" || item.value === "incident_name"}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  {item.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                : ""
          }


          {(activeTab === "Detections") && (
            <div className="position-relative">
              <InvestigateExport getInvestigationTable2={getInvestigationTable2} filter={filter} dateFilter={dateFilter} selectedDropItem={selectedDropItem} columns={tableColumns} />
            </div>
          )}

          {(activeTab === "Incidents") && (
            <div className="position-relative">
              <IncidentsExport tenandId={tenandId} getInvestigationGrouped={getInvestigationGrouped} filterGroup={filterGroup} dateFilterGroup={dateFilterGroup} />
            </div>
          )}

          {(activeTab === "Attention") && (
            <div className="position-relative">
              <AttentionExport tenandId={tenandId} getInvestigationAttention={getInvestigationAttention} filterGroup={filterAtten} dateFilterGroup={dateFilter} />
            </div>
          )}


          {localStorage.getItem("activeTab.investigate") === "Detections" &&
            <div className="">
              <Select
                className="w-100"
                options={drop}
                defaultValue={{ label: 'ALL' }}
                onChange={handleonDropDownChange}
              />
            </div>
          }

          {/* <Form.Control type="text" placeholder="Search..." className="mx-0" /> */}

          {!activeTab || activeTab === "Attention" ?
            <>
              <Pagination
                page={attentionPage}
                setPage={setAttentionPage}
                perPage={attentionPerPage}
                currentPageCount={attentioncurrentPageCount}
                totalDataCounts={attentionTotalPageCount}
              />

            </> : activeTab === "Detections" ?
              <>
                <Pagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  currentPageCount={currentPageCount}
                  totalDataCounts={totalPageCount}
                />
              </>
              : activeTab === "Incidents" ?
                <>
                  <Pagination
                    page={groupedPage}
                    setPage={setGroupedPage}
                    perPage={groupPerPage}
                    currentPageCount={groupcurrentPageCount}
                    totalDataCounts={groupTotalPageCount}
                  />
                </> : ""
          }

          {/* <span>{totalPageCount}</span> */}
        </div>

        <div className="detections col-12">
          <Tabs
            defaultActiveKey={localStorage.getItem("activeTab.investigate") ? localStorage.getItem("activeTab.investigate") : "Incidents"}
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="Attention" title={
              <React.Fragment>
                <CustomToolTip content={<span className="text-break">Incidents Needs<br></br> Attention</span>} dataPlacement={"bottom"}
                  dataToggle={
                    <div style={styles.tooltipWrap}>
                      Incidents Investigated by CYBOT L2 and needs Security Team Attention
                    </div>
                  }>
                </CustomToolTip>
                <Badge className="ml-2" bg="info">
                  {numberToLetter(attentionTotalPageCount)}
                </Badge>
              </React.Fragment>

            }>
              <div className="col-12" style={styles.contentWrap}>

                <FilterList
                  filter={filterAtten}
                  setFilter={setFilterAtten}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTableAtten}
                  module={module}
                  tab={tabAtten}
                  tabList={[tab,tabAtten, tabGroup]}
                  multi={true}
                />
                <div
                  className="d-flex col-12 position-relative"
                  style={styles.recentDetectionsWrap}
                >

                  {attentionTableIsLoading
                    ?
                    <TableLoader rowCount={15} />
                    : (
                      <>
                        {/* {console.log("investigateData", investigateData)} */}
                        {investigateAttenData?.length !== 0 && !investigateAttenDataError?.isError ? (
                          <Table borderless hover>
                            {/* {console.log(investigateAttenData,"i")} */}
                            <thead className="fixed-head">
                              <tr>
                                <th className="auto-min-width">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="close"
                                    checked={closeAttention?.length === investigateAttenData?.length || checkAttention && true}
                                    // checked = {closeDetectionCheck?.filter((data)=> )}
                                    onChange={(e) => handleSelectAllAttentionChange(e, investigateAttenData)}
                                  />
                                </th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Incident ID</th>
                                <th className="min-width500">Incident Name</th>
                                {manageDatasAtten?.map(
                                  (item) =>
                                    item.value != "incident_timestamp" &&
                                    item.value != "incident_name" &&
                                    item.isSelected === true &&
                                    // item.value != "user_name" &&
                                    // item.value != "host_name" &&
                                    <th className={item.label === 'Description' ? "min-width500" : ""}>{item.label}</th>
                                )}

                                {/* <th>Host Name</th>
                                {/* <th>Description</th> */}
                                {/* <th>Playbook ID</th> */}
                                {/* <th>Severity</th>
                                <th>Tags</th>
                                <th>Classification</th>
                                <th className="auto-min-width">Mitre tactic</th>
                                <th className="auto-min-width">Mitre technique</th>
                                <th>Threat Score</th>
                                <th>Detection Status</th>
                                <th className="text-center auto-min-width">Opened By</th> */}
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>

                              {investigateAttenData?.map((item, index) => (
                                <tr
                                  onMouseOver={() =>
                                    setShowInlineFilter({ show: true, index: index })
                                  }
                                  onMouseOut={() =>
                                    setShowInlineFilter({ show: false, index: index })
                                  }>

                                  <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <input
                                        type="checkbox"
                                        name="close"
                                        id={item?._source?.detection_status}
                                        checked={closeAttention?.filter((data) => data.id === item._id).length > 0 ? true : false}
                                        onChange={e => handleCaseAttention(item?._id, item?._index, e.target.checked, item?._source?.detection_status, item?._source?.grp_id)}
                                      />
                                    </div>
                                  </td>

                                  <td className="font-monospace">
                                    <span>{getDateFromTimestamp(item?._source?.detection_timestamp)}</span>
                                  </td>
                                  <td className="font-monospace">
                                    <span>{getTimeFromTimestamp(item?._source?.detection_timestamp)}</span>
                                  </td>
                                  <td className="font-monospace">
                                    <span>{item?._source?.grp_id}</span>
                                  </td>
                                  <td className="min-width500">
                                    {item?._source?.detection_name}
                                    {/* {item?._source?.detection_name} */}
                                    {showInlineFilter.show &&
                                      showInlineFilter.index === index && (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filterAtten}
                                            setFilter={setFilterAtten}
                                            column={{
                                              value: "detection_name",
                                              label: "Incident Name",
                                            }}

                                            // value={item?._source.detection_name}
                                            value={item?._source?.detection_name}
                                            getFilteredTable={getFilteredTableAtten}
                                            module={module}
                                            tab={tabAtten}
                                            dateFilter={dateFilter}
                                            setPage={setAttentionPage}
                                          />
                                        </div>
                                      )}
                                  </td>
                                  {manageDatasAtten?.map(
                                    (head) =>
                                      head.isSelected === true &&
                                      head.value != "detection_timestamp" &&
                                      head.value != "detection_name" &&
                                      // head.value != "user_name" &&
                                      // head.value != "host_name" && 
                                      (
                                        <td className="auto-min-width">
                                          {/* {head.value} */}
                                          {head.value === "detection_severity" && (
                                            <span>
                                              {item._source[head.value] == "Critical" && (
                                                <Badge bg="danger" className="p-2">Critical</Badge>
                                              )}
                                              {item._source[head.value] == "High" && (
                                                <Badge bg="danger" className="p-2">High</Badge>
                                              )}
                                              {item._source[head.value] == "Medium" && (
                                                <Badge bg="warning" className="p-2">Medium</Badge>
                                              )}
                                              {item._source[head.value] == "Low" && (
                                                <Badge bg="success" className="p-2">Low</Badge>
                                              )}
                                            </span>
                                          )}
                                          {head.value === "detection_status" && (
                                            <span>
                                              {item._source[head.value] == "new" && (
                                                <Badge bg="info" className="p-2">New</Badge>
                                              )}
                                              {item._source[head.value] == "closed" && (
                                                <Badge bg="success" className="p-2">Closed</Badge>
                                              )}
                                              {item._source[head.value] == "open" && (
                                                <Badge bg="warning" className="p-2">Open</Badge>
                                              )}
                                            </span>
                                          )}
                                          {
                                            head.value === 'closing_tag' && (
                                              <span>
                                                {item?._source?.closing_tag && (
                                                  item?._source?.closing_tag
                                                )}
                                              </span>
                                            )
                                          }

                                          {
                                            head.value === 'threat_score' && (
                                              <span>
                                                {parseFloat(item?._source?.threat_score).toFixed(2)}
                                              </span>
                                            )
                                          }
                                          {
                                            head.value === 'tactic_name' && (
                                              <span>
                                                {item?._source?.tactic_name && (
                                                  item?._source?.tactic_name
                                                )}
                                              </span>
                                            )
                                          }
                                          {
                                            head.value === 'technique_name' && (
                                              <span>
                                                {item?._source?.technique_name && (
                                                  item?._source?.technique_name
                                                )}
                                              </span>
                                            )
                                          }


                                          {
                                            head.value === 'description' && (
                                              <span >
                                                {rawHTML(item._source?.description)}
                                              </span>
                                            )
                                          }

                                          {
                                            head.value === 'threat_classification' && (
                                              <span>
                                                {(item?._source?.threat_classification
                                                ) === "Improbable" && (
                                                    <Badge className="p-2" bg="success">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Unlikely" && (
                                                    <div className="position-relative">
                                                      <Badge className="p-2" bg="warning">{`${item?._source?.threat_classification
                                                        } `}</Badge>
                                                    </div>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Benign True Positive" && (
                                                    <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Possible True Positive" && (
                                                    <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                              </span>
                                            )
                                          }

                                          {
                                            head.value === 'tag' && (
                                              <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {item?._source?.tag?.map((item, index) => (
                                                  <Badge key={index} bg="secondary" className="p-2 m-1">{item}</Badge>
                                                ))}
                                              </span>)
                                          }
                                          {
                                            head.value === 'opened_by' && (
                                              <span>
                                                {item?._source?.opened_by &&
                                                  <CustomToolTip content={
                                                    <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                                      {(item?._source?.opened_by).charAt(0)}
                                                    </span>} dataPlacement={"bottom"}
                                                    dataToggle={
                                                      <div className="d-flex flex-column text-left">
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened by - </span>
                                                          <span><strong>{item?._source?.opened_by}</strong></span>
                                                        </div>
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened on - </span>
                                                          <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                                        </div>
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened at - </span>
                                                          <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                                        </div>
                                                      </div>
                                                    } />
                                                }
                                              </span>
                                            )
                                          }



                                          {head.value !== "detection_severity" && head.value !== "detection_status" && head.value !== "threat_score" && head.value !== "threat_classification" && head.value !== "tag" && head.value !== "opened_by" &&
                                            head.value !== "tactic_name" && head.value !== "technique_name" && head.value !== "description" && head.value !== "closing_tag" && (
                                              <>
                                                <span className="text-break">{item._source[head?.value]}</span>
                                                {/* <CustomToolTip
                                              content={<span className="text-break">{item._source[head] === "Process Arguments" ? item._source[head?.value].substring(0, 60) + "..." : item?.command}</span>}
                                              dataPlacement={"bottom"}
                                              style={{ maxWidth: "340px" }}
                                              dataToggle={ 
                                                <span className="text-left" style={{ textAlign: "left" }}>
                                                  {item._source[head?.value]} 
                                                </span>
                                              }
                                            /> */}
                                              </>
                                            )}
                                          {/* {console.log("{item?._source?.conclusions[head?.value]} 6666666666", item?._source?.conclusions)} */}
                                          {/* {head?.value !== Object.values(item?._source) && (
                                          <span>{item?._source?.conclusions[head?.value]}</span>
                                        )} */}
                                        </td>
                                        // <td>{item._source[head.value]}</td>
                                      )


                                  )}

                                  {/* <td>
                                    {item._source?.host_name}
                                    {showInlineFilter.show &&
                                      showInlineFilter.index === index && (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filterAtten}
                                            setFilter={setFilterAtten}
                                            column={{
                                              value: "host_name",
                                              label: "Host Name",
                                            }}

                                            // value={item?._source.host_name}
                                            value={item?._source?.host_name}
                                            getFilteredTable={getFilteredTableAtten}
                                            module={module}
                                            tab={tabAtten}
                                            dateFilter={dateFilter}
                                            setPage={setAttentionPage}
                                          />
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    {item?._source?.detection_severity && (
                                      <span>
                                        {item?._source?.detection_severity == "Critical" && (
                                          <Badge bg="danger" className="p-2">Critical</Badge>
                                        )}
                                        {item?._source?.detection_severity == "High" && (
                                          <Badge bg="danger" className="p-2">High</Badge>
                                        )}
                                        {item?._source?.detection_severity == "Medium" && (
                                          <Badge bg="warning" className="p-2">Medium</Badge>
                                        )}
                                        {item?._source?.detection_severity == "Low" && (
                                          <Badge bg="success" className="p-2">Low</Badge>
                                        )}
                                      </span>
                                    )}
                                  </td> */}
                                  {/* <td>
                                    <span>{item._source?.detection_name}</span>
                                  </td> */}
                                  {/* <td>
                                    <span>{rawHTML(item._source?.description)}</span>
                                  </td> */}
                                  {/* <td>
                                    {item?._source?.playbook_id}
                                  </td> */}
                                  {/* <td>
                                    <div className="d-flex flex-wrap gap-2">
                                      {item?._source?.tag?.map((item) => (
                                        <Badge bg="secondary" className="p-2">{item}</Badge>
                                      ))}
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {(item?._source?.threat_classification
                                      ) === "Improbable" && (
                                          <Badge className="p-2" bg="success">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Unlikely" && (
                                          <div className="position-relative">
                                            <Badge className="p-2" bg="warning">{`${item?._source?.threat_classification
                                              } `}</Badge>
                                          </div>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Benign True Positive" && (
                                          <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Possible True Positive" && (
                                          <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                    </span>
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.tactic_name && (
                                      item?._source?.tactic_name
                                    )}
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.technique_name && (
                                      item?._source?.technique_name
                                    )}
                                  </td>
                                  <td>
                                    {item?._source?.threat_score && (
                                      parseFloat(item?._source?.threat_score).toFixed(2)
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      {item?._source?.detection_status == "new" && (
                                        <Badge bg="info" className="p-2">New</Badge>
                                      )}
                                      {item?._source?.detection_status == "closed" && (
                                        <Badge bg="success" className="p-2">Closed</Badge>
                                      )}
                                      {item?._source?.detection_status == "open" && (
                                        <Badge bg="warning" className="p-2">Open</Badge>
                                      )}
                                    </span>
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.opened_by &&
                                      <CustomToolTip content={
                                        <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                          {(item?._source?.opened_by).charAt(0)}
                                        </span>} dataPlacement={"bottom"}
                                        dataToggle={
                                          <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened by - </span>
                                              <span><strong>{item?._source?.opened_by}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened on - </span>
                                              <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened at - </span>
                                              <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                            </div>
                                          </div>
                                        } />
                                    }
                                  </td> */}
                                  <td>
                                    <InvestigationDocExport exportData={item} fileName={`INV_Incidents_${item?._source?.hunt_detection_id}_${item?._source?.detection_timestamp}`} type={'inc'} />
                                    <Link
                                      style={{ marginLeft: "20px" }}
                                      to={`/investigate/grouped_investigation/${item?._source?.grp_id}`}
                                      onClick={() => handleInvestigationOpen(item._index, item?._id, item?._source?.detection_status)}
                                    >
                                      <TableViewMore Icon={Desktop} dataToggle={"View details"} dataPlacement={"bottom"} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <>
                            {investigateAttenDataError.isError ? (
                              <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} errorData={investigateAttenDataError} />
                              </div>
                            ) : (
                              <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                </div>
              </div>
            </Tab>
            {/* {console.log(investigateData,"i")} */}
            <Tab eventKey="Incidents" title={
              <React.Fragment>
                <CustomToolTip content={<span className="text-break">L2 Investigated <br></br> Incidents</span>} dataPlacement={"bottom"}
                  dataToggle={
                    <div style={styles.tooltipWrap}>
                      Incidents Investigated by CYBOT L2
                    </div>
                  }>
                </CustomToolTip>
                <Badge className="ml-2" bg="info">
                  {numberToLetter(groupTotalPageCount)}
                </Badge>
              </React.Fragment>
            }>
              <div className="col-12" style={styles.contentWrap}>

                <FilterList
                  filter={filterGroup}
                  setFilter={setFilterGroup}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTableGroup}
                  module={module}
                  tab={tabGroup}
                  tabList={[tab,tabAtten, tabGroup]}
                  multi={true}

                />
                <div
                  className="d-flex col-12 position-relative"
                  style={styles.recentDetectionsWrap}
                >

                  {timelineIsLoading
                    ?
                    <TableLoader rowCount={15} />
                    : (
                      <>
                        {/* {console.log("investigateData", investigateData)} */}
                        {/* {console.log("investigateDataError", investigateDataError)} */}
                        {investigateData?.length !== 0 && !investigateDataError?.isError ? (
                          <Table borderless hover>
                            <thead className="fixed-head">
                              <tr>
                                <th className="auto-min-width">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="close"
                                    checked={closeL2Detection?.length === investigateData?.length || checkL2 && true}
                                    // checked={closeL2Detection.length === investigateData.length && true}
                                    // checked = {closeDetectionCheck?.filter((data)=> )}
                                    onChange={(e) => handleSelectAllL2Change(e, investigateData)}
                                  />
                                </th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Incident ID</th>
                                <th className="min-width500">Incident Name</th>
                                {manageDatas?.map(
                                  (item) =>
                                    item.value != "incident_timestamp" &&
                                    item.value != "incident_name" &&
                                    item.isSelected === true &&
                                    // item.value != "user_name" &&
                                    // item.value != "host_name" &&
                                    <th className={item.label === 'Description' ? "min-width500" : ""}>{item.label}</th>
                                )}

                                {/* <th>Host Name</th>
                                {/* <th>Description</th> */}
                                {/* <th>Severity</th>
                                <th>Tags</th>
                                <th>Classification</th>
                                <th className="auto-min-width">Mitre tactic</th>
                                <th className="auto-min-width">Mitre technique</th>
                                <th>Threat Score</th>
                                <th>Detection Status</th>
                                <th className="text-center auto-min-width">Opened By</th> */} */
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {investigateData?.map((item, index) => (
                                <tr
                                  onMouseOver={() =>
                                    setShowInlineFilter({ show: true, index: index })
                                  }
                                  onMouseOut={() =>
                                    setShowInlineFilter({ show: false, index: index })
                                  }>
                                  <td className="auto-min-width">
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <input
                                        type="checkbox"
                                        name="close"
                                        id={item?._source?.detection_status}
                                        checked={closeL2Detection?.filter((data) => data.id === item._id)?.length > 0 ? true : false}
                                        onChange={e => handleInvestigateIncident(item?._id, item?._index, e.target.checked, item?._source?.detection_status, item?._source?.grp_id)}
                                      />
                                    </div>
                                  </td>

                                  <td className="font-monospace">
                                    <span>{getDateFromTimestamp(item?._source?.detection_timestamp)}</span>
                                  </td>
                                  <td className="font-monospace">
                                    <span>{getTimeFromTimestamp(item?._source?.detection_timestamp)}</span>
                                  </td>
                                  <td className="font-monospace">
                                    <span>{item?._source?.grp_id}</span>
                                  </td>
                                  <td className="min-width500">
                                    {item?._source?.detection_name}
                                    {/* {item?._source?.conclusions?.["Alert Name"]?.toString()} */}
                                    {showInlineFilter.show &&
                                      showInlineFilter.index === index && (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filterGroup}
                                            setFilter={setFilterGroup}
                                            column={{
                                              value: "detection_name",
                                              label: "Incident Name",
                                            }}

                                            // value={item?._source.detection_name}
                                            value={item?._source?.detection_name}
                                            getFilteredTable={getFilteredTableGroup}
                                            module={module}
                                            tab={tabGroup}
                                            dateFilter={dateFilter}
                                            setPage={setGroupedPage}
                                          />
                                        </div>
                                      )}
                                  </td>
                                  {manageDatas?.map(
                                    (head) =>
                                      head.isSelected === true &&
                                      head.value != "detection_timestamp" &&
                                      head.value != "detection_name" &&
                                      // head.value != "user_name" &&
                                      // head.value != "host_name" && 
                                      (
                                        <td className="auto-min-width">
                                          {/* {head.value} */}
                                          {head.value === "detection_severity" && (
                                            <span>
                                              {item._source[head.value] == "Critical" && (
                                                <Badge bg="danger" className="p-2">Critical</Badge>
                                              )}
                                              {item._source[head.value] == "High" && (
                                                <Badge bg="danger" className="p-2">High</Badge>
                                              )}
                                              {item._source[head.value] == "Medium" && (
                                                <Badge bg="warning" className="p-2">Medium</Badge>
                                              )}
                                              {item._source[head.value] == "Low" && (
                                                <Badge bg="success" className="p-2">Low</Badge>
                                              )}
                                            </span>
                                          )
                                          }
                                          {head.value === "detection_status" && (
                                            <span>
                                              {item._source[head.value] == "new" && (
                                                <Badge bg="info" className="p-2">New</Badge>
                                              )}
                                              {item._source[head.value] == "closed" && (
                                                <Badge bg="success" className="p-2">Closed</Badge>
                                              )}
                                              {item._source[head.value] == "open" && (
                                                <Badge bg="warning" className="p-2">Open</Badge>
                                              )}
                                            </span>
                                          )}
                                          {
                                            head.value === 'threat_score' && (
                                              <span>
                                                {parseFloat(item?._source?.threat_score).toFixed(2)}
                                              </span>
                                            )
                                          }
                                          {
                                            head.value === 'closing_tag' && (
                                              <span>
                                                {item?._source?.closing_tag && (
                                                  item?._source?.closing_tag
                                                )}
                                              </span>
                                            )
                                          }

                                          {
                                            head.value === 'tactic_name' && (
                                              <span>
                                                {item?._source?.tactic_name && (
                                                  item?._source?.tactic_name
                                                )}
                                              </span>
                                            )
                                          }
                                          {
                                            head.value === 'technique_name' && (
                                              <span>
                                                {item?._source?.technique_name && (
                                                  item?._source?.technique_name
                                                )}
                                              </span>
                                            )
                                          }



                                          {/* {
  head.value === 'description' && (
    <span>
      {removeHtmlChars(item._source?.description)}
    </span>
  )
} */}
                                          {
                                            head.value === 'description' && (
                                              <span>
                                                <p className="mb-0">{item._source?.description && item._source?.description.replace(/<[^>]+>/g, '')}</p>
                                              </span>
                                            )
                                          }
                                          {
                                            head.value === 'threat_classification' && (
                                              <span>
                                                {(item?._source?.threat_classification
                                                ) === "Improbable" && (
                                                    <Badge className="p-2" bg="success">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Unlikely" && (
                                                    <div className="position-relative">
                                                      <Badge className="p-2" bg="warning">{`${item?._source?.threat_classification
                                                        } `}</Badge>
                                                    </div>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Benign True Positive" && (
                                                    <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                                {(item?._source?.threat_classification
                                                ) === "Possible True Positive" && (
                                                    <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                                      }`}</Badge>
                                                  )}
                                              </span>
                                            )
                                          }

                                          {
                                            head.value === 'tag' && (
                                              <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {item?._source?.tag?.map((item, index) => (
                                                  <Badge key={index} bg="secondary" className="p-2 m-1">{item}</Badge>
                                                ))}
                                              </span>)
                                          }
                                          {
                                            head.value === 'opened_by' && (
                                              <span>
                                                {item?._source?.opened_by &&
                                                  <CustomToolTip content={
                                                    <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                                      {(item?._source?.opened_by).charAt(0)}
                                                    </span>} dataPlacement={"bottom"}
                                                    dataToggle={
                                                      <div className="d-flex flex-column text-left">
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened by - </span>
                                                          <span><strong>{item?._source?.opened_by}</strong></span>
                                                        </div>
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened on - </span>
                                                          <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                                        </div>
                                                        <div className='d-flex flex-row gap-2 tooltip-row'>
                                                          <span>Opened at - </span>
                                                          <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                                        </div>
                                                      </div>
                                                    } />
                                                }
                                              </span>
                                            )
                                          }



                                          {head.value !== "detection_severity" && head.value !== "detection_status" && head.value !== "threat_score" && head.value !== "threat_classification" && head.value !== "tag" &&
                                            head.value !== "opened_by" && head.value !== "description" && head.value !== "tactic_name" && head.value !== "technique_name" && head.value !== "closing_tag" && (
                                              <>
                                                <span className="text-break">{item._source[head?.value]}</span>
                                                {/* <CustomToolTip
                                              content={<span className="text-break">{item._source[head] === "Process Arguments" ? item._source[head?.value].substring(0, 60) + "..." : item?.command}</span>}
                                              dataPlacement={"bottom"}
                                              style={{ maxWidth: "340px" }}
                                              dataToggle={
                                                <span className="text-left" style={{ textAlign: "left" }}>
                                                  {item._source[head?.value]}
                                                </span>
                                              }
                                            /> */}
                                              </>
                                            )}
                                          {/* {console.log("{item?._source?.conclusions[head?.value]} 6666666666", item?._source?.conclusions)} */}
                                          {/* {head?.value !== Object.values(item?._source) && (
                                          <span>{item?._source?.conclusions[head?.value]}</span>
                                        )} */}
                                        </td>
                                        // <td>{item._source[head.value]}</td>
                                      )


                                  )}

                                  {/* <td>
                                    {item._source?.host_name &&
                                      <span>{item._source?.host_name}</span>
                                    }
                                  </td>
                                  <td>
                                    {item?._source?.detection_severity && (
                                      <span>
                                        {item?._source?.detection_severity == "Critical" && (
                                          <Badge bg="danger" className="p-2">Critical</Badge>
                                        )}
                                        {item?._source?.detection_severity == "High" && (
                                          <Badge bg="danger" className="p-2">High</Badge>
                                        )}
                                        {item?._source?.detection_severity == "Medium" && (
                                          <Badge bg="warning" className="p-2">Medium</Badge>
                                        )}
                                        {item?._source?.detection_severity == "Low" && (
                                          <Badge bg="success" className="p-2">Low</Badge>
                                        )}
                                      </span>
                                    )}
                                  </td>
                                  {/* <td>
                                    <span>{item._source?.detection_name}</span>
                                  </td> */}
                                  {/* <td>
                                    <span>{rawHTML(item._source?.description)}</span>
                                  </td> */}
                                  {/* <td>
                                    <div className="d-flex flex-wrap gap-2">
                                      {item?._source?.tag?.map((item) => (
                                        <Badge bg="secondary" className="p-2">{item}</Badge>
                                      ))}
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {(item?._source?.threat_classification
                                      ) === "Improbable" && (
                                          <Badge className="p-2" bg="success">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Unlikely" && (
                                          <div className="position-relative">
                                            <Badge className="p-2" bg="warning">{`${item?._source?.threat_classification
                                              } `}</Badge>
                                          </div>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Benign True Positive" && (
                                          <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                      {(item?._source?.threat_classification
                                      ) === "Possible True Positive" && (
                                          <Badge className="p-2" bg="danger">{`${item?._source?.threat_classification
                                            }`}</Badge>
                                        )}
                                    </span>
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.tactic_name && (
                                      item?._source?.tactic_name
                                    )}
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.technique_name && (
                                      item?._source?.technique_name
                                    )}
                                  </td>
                                  <td>
                                    {item?._source?.threat_score && (
                                      parseFloat(item?._source?.threat_score).toFixed(2)
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      {item?._source?.detection_status == "new" && (
                                        <Badge bg="info" className="p-2">New</Badge>
                                      )}
                                      {item?._source?.detection_status == "closed" && (
                                        <Badge bg="success" className="p-2">Closed</Badge>
                                      )}
                                      {item?._source?.detection_status == "open" && (
                                        <Badge bg="warning" className="p-2">Open</Badge>
                                      )}
                                    </span>
                                  </td>
                                  <td className="auto-min-width">
                                    {item?._source?.opened_by &&
                                      <CustomToolTip content={
                                        <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                          {(item?._source?.opened_by).charAt(0)}
                                        </span>} dataPlacement={"bottom"}
                                        dataToggle={
                                          <div className="d-flex flex-column text-left">
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened by - </span>
                                              <span><strong>{item?._source?.opened_by}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened on - </span>
                                              <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                            </div>
                                            <div className='d-flex flex-row gap-2 tooltip-row'>
                                              <span>Opened at - </span>
                                              <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                            </div>
                                          </div>
                                        } />
                                    }
                                  </td> */}
                                  <td>
                                    <InvestigationDocExport exportData={item} fileName={`INV_Investigated_Incidents_${item?._source?.hunt_detection_id}_${item?._source?.detection_timestamp}`} type={'L2'} />
                                    <Link
                                      style={{ marginLeft: "20px" }}
                                      to={`/investigate/grouped_investigation/${item?._source?.grp_id}`}
                                      onClick={() => handleInvestigationOpen(item._index, item?._id, item?._source?.detection_status)}
                                    >
                                      <TableViewMore Icon={Desktop} dataToggle={"View details"} dataPlacement={"bottom"} />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <>
                            {investigateDataError.isError ? (
                              <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} errorData={investigateDataError} />
                              </div>
                            ) : (
                              <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="Detections" title={
              <React.Fragment>

                <CustomToolTip content={<span className="text-break">L1 Investigated<br></br> Detections</span>} dataPlacement={"bottom"}
                  dataToggle={
                    <div style={styles.tooltipWrap}>
                      Detections Investigated by CYBOT L1
                    </div>
                  }>

                </CustomToolTip>
                <Badge className="ml-2" bg="info">
                  {numberToLetter(totalPageCount)}
                </Badge>
              </React.Fragment>

            }>
              {/* <div className="col-12" style={styles.section3}> */}
              <div className="col-12" style={styles.contentWrap}>

                <FilterList
                  filter={filter}
                  setFilter={setFilter}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTable}
                  module={module}
                  tab={tab}
                  tabList={[tab,tabAtten, tabGroup]}
                  multi={true}

                />


                {isInvestigationTableLoading
                  ? <TableLoader rowCount={20} />
                  :
                  (
                    <>
                      {investigationTableData.length !== 0 && !investigationTableDataError?.isError ? (
                        <Table borderless hover>
                          <thead className="fixed-head">
                            {/* <tr>
                      <th>Investigation Name</th>
                      <th>Detection Date</th>
                      <th>Detection Time</th>
                      <th>Status</th>
                      <th>Severity</th>
                      <th>Action</th>
                    </tr> */}
                            <tr>
                              <th className="auto-min-width">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="close"
                                  checked={closeDetectionCheck?.length === investigationTableData?.length || checkL1 && true}
                                  // checked = {closeDetectionCheck?.filter((data)=> )}
                                  onChange={(e) => handleSelectAllChange(e, investigationTableData)}
                                />
                              </th>
                              <th>Date</th>
                              <th>Time</th>
                              <th className="min-width500">Investigation Name</th>
                              {/* <th>User Name</th>
                      <th>Host Name</th> */}
                              {tableHeader?.map(
                                (item) =>
                                  item.value != "detection_timestamp" &&
                                  item.value != "detection_name" &&
                                  item.isSelected === true &&
                                  // item.value != "user_name" &&
                                  // item.value != "host_name" &&
                                  <th>{item.text}</th>
                              )}
                              {/* {checkedItems?.map((item) => (
                                <th>{item.text}</th>
                              ))} */}
                              <th className="text-center auto-min-width">Opened by</th>
                              <th className="text-center"></th>
                              {/* <th>Add More Field</th> */}
                              {/* <th>
                        <Plus
                          onClick={() => setShowFieldBar(!showFieldBar)}
                          size={22}
                          style={{
                            background: "white",
                            padding: "0.3rem",
                            color: "black",
                            borderRadius: "50%",
                          }}
                        />
                      </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {investigationTableData?.map(({ _source }) => (
                      <tr>
                        <td></td>
                      </tr>
                    ))} */}
                            {/* {console.log(tableHeader,"o")} */}
                            {investigationTableData?.map((item, index) => (
                              <tr
                                key={index}
                                onClick={() => handleRowClick(item._id)}
                                className={activeTableRow === item._id ? 'active_table_row' : ''}
                                onMouseOver={() =>
                                  setShowInlineFilter({ show: true, index: index })
                                }
                                onMouseOut={() =>
                                  setShowInlineFilter({ show: false, index: index })
                                }
                              >
                                <td className="auto-min-width">
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="close"
                                      // disabled={item?._source?.detection_status === "closed"}
                                      id={item?._source?.detection_status}
                                      checked={closeDetectionCheck?.filter((data) => data.id === item._id)?.length > 0 ? true : false}
                                      onChange={e => handleCloseDetecion(item?._id, item?._index, e.target.checked, item?._source?.detection_status, item?._source?.hunt_detection_id)}
                                    />
                                  </div>
                                </td>
                                <td className="font-monospace">
                                  {getDateFromTimestamp(
                                    item?._source?.detection_timestamp
                                  )}
                                </td>
                                <td className="font-monospace">
                                  {getTimeFromTimestamp(
                                    item?._source?.detection_timestamp
                                  )}
                                </td>
                                <td className="min-width500">
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    {item?._source?.detection_name}
                                    {showInlineFilter.show &&
                                      showInlineFilter.index === index && (
                                        <div className="inline-filter-wrap">
                                          <InlineFilter
                                            filter={filter}
                                            setFilter={setFilter}
                                            column={{
                                              value: "detection_name",
                                              label: "Investigation Name",
                                            }}
                                            value={item?._source.detection_name}
                                            getFilteredTable={getFilteredTable}
                                            module={module}
                                            tab={tab}
                                            dateFilter={dateFilter}
                                            setPage={setPage}

                                          />
                                        </div>
                                      )}
                                  </div>
                                </td>
                                {/* <td className="font-monospace">
                            {item?._source?.user_name}
                          </td>
                          <td className="font-monospace">
                            {item?._source?.host_name}
                          </td> */}
                                {tableHeader?.map(
                                  (head) =>
                                    head.isSelected === true &&
                                    head.value != "detection_timestamp" &&
                                    head.value != "detection_name" &&
                                    // head.value != "user_name" &&
                                    // head.value != "host_name" && 
                                    (
                                      <td className="auto-min-width">
                                        {/* {head.value} */}
                                        {head.value === "detection_severity" && (
                                          <span>
                                            {item._source[head.value] == "Critical" && (
                                              <Badge bg="danger" className="p-2">Critical</Badge>
                                            )}
                                            {item._source[head.value] == "High" && (
                                              <Badge bg="danger" className="p-2">High</Badge>
                                            )}
                                            {item._source[head.value] == "Medium" && (
                                              <Badge bg="warning" className="p-2">Medium</Badge>
                                            )}
                                            {item._source[head.value] == "Low" && (
                                              <Badge bg="success" className="p-2">Low</Badge>
                                            )}
                                          </span>
                                        )}
                                        {head.value === "detection_status" && (
                                          <span>
                                            {item._source[head.value] == "new" && (
                                              <Badge bg="info" className="p-2">New</Badge>
                                            )}
                                            {item._source[head.value] == "closed" && (
                                              <Badge bg="success" className="p-2">Closed</Badge>
                                            )}
                                            {item._source[head.value] == "open" && (
                                              <Badge bg="warning" className="p-2">Open</Badge>
                                            )}
                                          </span>
                                        )}
                                        {
                                          head.value === 'threat_score' && (
                                            <span>
                                              {parseFloat(item?._source?.threat_score).toFixed(2)}
                                            </span>
                                          )
                                        }
                                        {head.value !== "detection_severity" && head.value !== "detection_status" && head.value !== "threat_score" && (
                                          <>
                                            <span className="text-break">{item._source[head?.value]}</span>
                                            {/* <CustomToolTip
                                              content={<span className="text-break">{item._source[head] === "Process Arguments" ? item._source[head?.value].substring(0, 60) + "..." : item?.command}</span>}
                                              dataPlacement={"bottom"}
                                              style={{ maxWidth: "340px" }}
                                              dataToggle={
                                                <span className="text-left" style={{ textAlign: "left" }}>
                                                  {item._source[head?.value]}
                                                </span>
                                              }
                                            /> */}
                                          </>
                                        )}
                                        {/* {console.log("{item?._source?.conclusions[head?.value]} 6666666666", item?._source?.conclusions)} */}
                                        {/* {head?.value !== Object.values(item?._source) && (
                                          <span>{item?._source?.conclusions[head?.value]}</span>
                                        )} */}
                                      </td>
                                      // <td>{item._source[head.value]}</td>
                                    )


                                )}


                                <td className="auto-min-width">
                                  {item?._source?.opened_by &&
                                    <CustomToolTip content={
                                      <span className={"user-icon mx-auto bg-" + (item?._source?.opened_by).charAt(0).toLowerCase()}>
                                        {(item?._source?.opened_by).charAt(0)}
                                      </span>} dataPlacement={"bottom"}
                                      dataToggle={
                                        <div className="d-flex flex-column text-left">
                                          <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Opened by - </span>
                                            <span><strong>{item?._source?.opened_by}</strong></span>
                                          </div>
                                          <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Opened on - </span>
                                            <span><strong>{getDateFromTimestamp(item?._source?.opened_at)}</strong></span>
                                          </div>
                                          <div className='d-flex flex-row gap-2 tooltip-row'>
                                            <span>Opened at - </span>
                                            <span><strong>{getTimeFromTimestamp(item?._source?.opened_at)}</strong></span>
                                          </div>
                                        </div>
                                      } />
                                  }
                                </td>

                                <td className="text-right" style={{ minWidth: "160px" }}>
                                  {/* <Link onClick={() => handleOpenModal(item, true)}>
                            view
                          </Link> */}



                                  <Link
                                    onClick={() =>
                                      handleOpenRepetitionModal(item, true)
                                    }
                                  >
                                    <TableViewMore Icon={ListDashes} dataToggle={"Repetation"} dataPlacement={"bottom"} />
                                  </Link>

                                  {/* <Link
                              style={{ marginLeft: "20px" }}
                              onClick={() => handleOpenModal(item, true)}
                            >
                              <TableViewMore Icon={Info} dataToggle={"Investigation Summary"} dataPlacement={"bottom"} />
                            </Link> */}

                                  {/* LINK */}
                                  {createStateForNav(item)}
                                  <Link
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => { setExpClickedNode(item?._id); setExpClose(false) }}
                                  >
                                    <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                  </Link>

                                  {item?._source.id ?
                                    <Link
                                      style={{ marginLeft: "1.1rem" }}
                                      onClick={() => { { setExpClickedNodeLog(item?._source.id); setExpClickedIndex(item?._source?.index) }; setExpCloseLog(false) }}
                                    >
                                      <TableViewMore Icon={FileSearch} dataToggle={"View Raw Log"} dataPlacement={"bottom"} />
                                    </Link>
                                    :
                                    <Link
                                      style={{ marginLeft: "1.1rem", opacity: 0.5, cursor: "default" }}>
                                      <TableViewMore Icon={FileSearch} dataToggle={"Count-based detections are derived from the analysis of multiple logs or documents. We would recommend you to check the raw logs in Cykit by searching for detection observables"} dataPlacement={"bottom"} />
                                    </Link>
                                  }


                                  <InvestigationDocExport exportData={item} fileName={`INV_Detection_${item?._source?.hunt_detection_id}_${item?._source?.detection_timestamp}`} type={'L1'} />
                                  {/* <a onClick={() => { setExpClickedNode(item._id); setExpClose(false) }} style={{ marginLeft: "20px" }}>
                                    <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                  </a> */}
                                </td>

                                {/* <td>{item._source.detection_timestamp}</td> */}
                                {/* <td>{item._source.detection_timestamp}</td>
                        <td>{item._source.detection_timestamp}</td>
                        <td onClick={() => alert("Clicked Successfully")}>
                          <Plus />
                        </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <>
                          {investigationTableDataError?.isError ? (
                            <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} errorData={investigationTableDataError} />
                            </div>
                          ) : (
                            <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                              <NoDataFound errorText={"No data found"} />
                            </div>
                          )}
                        </>

                      )}


                    </>
                  )
                }
              </div>
            </Tab>
          </Tabs>
        </div>
        {/* <div className="detections col-12">
          {detectionData.map(({ sort, _source: { description } }) => (
            <div className="single-detection">
              <span style={{ color: "orange" }}>{sort}</span>
              <p style={{ fontWeight: "500" }}>{escapeHtmlChars(description, /(<\/?b>)/g)}</p>
            </div>
          ))
          }
        </div> */}
        <ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={localStorage.getItem("CYBOT_URL")} pattern={`cybot_proxy/thp_enriched_observables_*/_search?track_total_hits=true`} req={fpostC} />
        <ExpandedDocument title={"Raw Log"} clickedNode={expclickedNodeLog} close={expcloseLog} setClick={setExpClickedNodeLog} url={localStorage.getItem("DATALAKE_URL")} pattern={expClickedIndex !== undefined ? `${expClickedIndex}/_doc/${expclickedNodeLog}` : 'logs-*,winlogbeat*/_search?track_total_hits=true'} req={expClickedIndex !== undefined ? fgetCy : fpostCy} />

      </div >

      {alertPopup && (
        <AlertBox
          setAlertPopup={setAlertPopup}
          alertPopup={alertPopup}
          message={alertMessage}
          close={handleClosePopup}
          setOutTime={5000}
          bgColor={"lightGreen"}
        />
      )}

      {
        closeShow && (
          <CustomModal
            title={detectionStatusCheck === 'closed' ? "Close Investigations" : "Open Investigations"}
            ShowOrHide={closeShow}
            bodyData={renderCommentModal}
            handleClose={handleCloseModal}
            modalSize={"md"}
            footer={false}
            downloadBtn={false}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }



      {show && (
        <CustomModal
          title={"Investigation summary"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize={"fullscreen"}
          noBodyPadding={true}
          overflowHidden={true}
        ></CustomModal>
      )
      }



      {
        showRepetition && (
          <CustomModal
            title={"Repetitions"}
            // title={view === true ? "Grouped Investigation" : "Repetitions"}
            ShowOrHide={showRepetition}
            bodyData={renderRepetitionModal}
            handleClose={handleCloseModal}
            footer={false}
            modalSize={"md"}
            noBodyPadding={true}
            overflowHidden={true}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }

      {caseshow && (
        <EditModal
          setShow={setCaseShow}
          show={caseshow}
          autoHeight={false}
          caseidEdit={case_id}
          setCase_ID={setCase_ID}
          attachmentList={caseAttachmentList}
          id={activeTab}
          isExisting={false}
          // idd={idd}
          module='investigate_soc'
        // tab={tab}
        // preModule={"INV-DET"}
        // description_={removeHtmlChars(desc)}

        />
      )
      }
    </>
  );
};

export default Investigation;

