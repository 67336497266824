import React, { useEffect, useLayoutEffect, useMemo, useRef, useState, useCallback } from 'react'
import { getEventDetails, getHeadlines, getTenant } from '../../../Methods/ManagementDashboard'
import { useSelector } from 'react-redux';
import { getAccountCreated, getAccountDeleted, getAccountPass, getAccountPasswordChage, getInvestigation, getRecentAuthManagementDash, getRecentFileOperations, getWidgetsData, recentCounts, userActivity, getAccountLocked, getAccountChanged } from '../../../Methods/InvestigationMethods';
import Chart from 'react-google-charts';
import { getDateFromTimestamp, getTimeFromTimestamp, rawHTML } from '../../../Utils/Utils';
import { TimelineLoader } from '../../../components/Loader/TimelineLoader';
import { getAnomalousCount, getCount, getDetections, getInspectedCount } from '../../../Methods/UebaMethods';
import styles from './ManagementDashboard.styles';
import { ArrowRight, Calendar, CalendarBlank, FilePlus, FileX, Lock, Password, UserMinus, UserPlus, UserSwitch } from 'phosphor-react';
import { convertTimestamp } from '../../../Methods/TiMethods';
import LoaderList from '../../../components/Loader/LoaderList';
import { Link, useNavigate } from 'react-router-dom';
import TableViewMore from '../../../components/ViewMoreBtn/TableViewMore';
import { Badge, Button, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../components/Loader/TableLoader';
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import PlainCardLoader from '../../../components/Loader/PlainCardLoader';
import { useDispatch } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { getVulnerabilities, getVulnerabilitesEx, getSecurityEvents, getTableData } from '../../../Methods/TiMethods';
import globalStyles from '../../../constants/globalStyles';
import { getMostCommonSRA, getUniqueSRCounts, getFireWallTraffic } from '../../../Methods/CyKitInvestigate'
import moment from 'moment';
import { fpostC } from '../../../axios/axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ManagementOverviewExport from '../../../components/PDF/ManagementOverviewExport';
import { Doughnut, getElementAtEvent, } from "react-chartjs-2";
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';


const ManagementDashboard = ({ selectedDateFilter, setSelectedDateFilter }) => {

    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    // const [datePeriod, setDatePeriod] = useState("now-7d")
    useEffect(() => {
        if (selectedDateFilter === '1 Year') {
            setDateRange('now-365d')
        } else if (selectedDateFilter === '30 Days') {
            setDateRange('now-30d')
        } else if (selectedDateFilter === '7 Days') {
            setDateRange('now-7d')
        } else if (selectedDateFilter === '1 Day') {
            setDateRange('now-1d')
        }

    }, [selectedDateFilter])

    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState('now-1d')
    const dateFilter = {
        to: moment().toISOString(),
        from: moment().subtract(parseInt(dateRange.match(/\d+/)[0]), 'days').toISOString()
    }
    const [headlinesData, setHeadlinesData] = useState([])
    const [accountActivity, setAccountActivity] = useState([])
    // source country
    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [destinationCountryLabel, setDestinationCountryLabel] = useState([])
    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])
    const [geoDestinationChart, setDestinationChart] = useState([])

    // Recent Threads Observed TIMELINE VIEW

    const [timelineIsLoading, setTimelineIsLoading] = useState(true)
    const [headLoading, setHeadLoading] = useState(true)
    const [eventsLoading, setEventsLoading] = useState(true)
    // traffic loader
    const [trafficFromInternet, setTrafficFromInternet] = useState(true)
    const [trafficToInternet, setTrafficToInternet] = useState(true)
    // top receivers loader
    const [receiversLoader, setReceiversLoader] = useState(true)
    const [senderLoader, setSenderLoader] = useState(true)
    // Recent Account Activities Loader
    const [recentAccLoader, setRecentAccLoader] = useState(true)

    const [timelineData, setTimelineData] = useState([])
    // ti


    const [redirecting, setRedirecting] = useState(false)
    const handleRedirect = (path) => {
        setRedirecting(true)
        setTimeout(() => {
            setRedirecting(false)
            window.open(path, "_blank");
        }, 2000);
    }

    const [eventDetails, setEventDetails] = useState([])

    // ueba detections
    const [recentDetections, setRecentDetections] = useState([]);
    const [detectionData, setDetectionData] = useState([]);

    const detectionTimeline = detectionData.map((item) => {
        return Object.entries(item);
    });

    // ueba

    const [inspectedCountLoading, setInspectedCountLoading] = useState(true);
    const [anomaliesCountLoading, setAnomaliesCountLoading] = useState(true);
    const [usersCountLoading, setUsersCountLoading] = useState(true);
    const [hostsCountLoading, setHostCountLoading] = useState(true);

    const [anomaliesCount, setAnomaliesCount] = useState('')
    const [inspectedCount, setInspectedCount] = useState('')
    const [userCount, setUserCount] = useState("");
    const [hostCount, setHostCount] = useState("");

    // imageblob
    const uebaRefImage = useRef(null);
    const TiRefImage = useRef(null);
    const investigateRefImage = useRef(null);
    const trafficInternetRefImage = useRef(null)
    const emailRefImage = useRef(null)
    const fileOperationsRefImage = useRef(null)
    const UserManagementActivitiesRefImage = useRef(null);
    const RecentAccountActivitiesRefImage = useRef(null);
    const emailSenderRef = useRef(null);
    const emailRecieverRef = useRef(null);
    const [imageBlob, setImageBlob] = useState({
        trafficInternet: null,
        email: null,
        fileOperations: null,
        ueba: null,
        ti: null,
        investigate: null,
        UserManagementActivities: null,
        RecentAccountActivities: null,
    });



    const convertToBlob = () => {
        setImageBlob({
            ueba: null,
            ti: null,
            trafficInternet: null,
            email: null,
            fileOperations: null,
            investigate: null,
            UserManagementActivities: null,
            RecentAccountActivities: null,
        })

        // traffic internet
        htmlToImage.toBlob(trafficInternetRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                trafficInternet: blob,
            }));
        });

        // email
        htmlToImage.toBlob(emailRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                email: blob,
            }));
        });

        // fileOperations
        htmlToImage.toBlob(fileOperationsRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                fileOperations: blob,
            }));
        });

        // ueba
        htmlToImage.toBlob(uebaRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                ueba: blob,
            }));
        });

        // ueba
        htmlToImage.toBlob(TiRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                ti: blob,
            }));
        });

        // Investigate
        htmlToImage.toBlob(investigateRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                investigate: blob,
            }));

        });

        htmlToImage.toBlob(UserManagementActivitiesRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                UserManagementActivities: blob,
            }));
        });

        // RecentAccountActivities
        htmlToImage.toBlob(RecentAccountActivitiesRefImage.current).then((blob) => {

            setImageBlob((prevState) => ({
                ...prevState,
                RecentAccountActivities: blob,
            }));
        });
    };

    const [showDownloadButton, setShowDownloadButton] = useState(false)


    useEffect(() => {
        setShowDownloadButton(false)
        if (trafficFromInternet === false && trafficToInternet === false && receiversLoader === false && senderLoader === false && recentAccLoader === false) {
            setTimeout(() => {
                convertToBlob()
                setShowDownloadButton(true)
            }, 10000)
        }
    }, [trafficFromInternet,
        trafficToInternet,
        receiversLoader,
        senderLoader,
        recentAccLoader,
        selectedDateFilter])

    // Investigate

    const [threadsData, setThreadsData] = useState([])


    // geouped investigation
    const [investigateData, setInvestigateData] = useState([]);
    const recentInvestigationTimeline = investigateData.map((item) => {
        return Object.entries(item);
    });


    // vulner 
    const VulnerabilitiesList = useSelector((state) => state.VulnerabilitiesList);
    const {
        vulnerabilities,
        vulCounts,
        loading: vulLoading,
    } = VulnerabilitiesList;



    // vulner ex
    const VulnerabilitiesEXlist = useSelector(
        (state) => state.VulnerabilitiesEXlist
    );
    const {
        exVulnerabilities,
        exVulCounts,
        loading: exVulLoading,
    } = VulnerabilitiesEXlist;

    const SecurityEventsList = useSelector((state) => state.SecurityEventsList);
    const {
        securityEvents,
        secCounts,
        loading: secEventsLoading,
    } = SecurityEventsList;
    const getTableDataComTi = useSelector((state) => state.getTableDataComTi);
    const { comEvents, commCount } = getTableDataComTi;
    // ueba

    const [recentFileOperations, setRecentFileOperations] = useState([])
    const [recentAuthManagement, setRecentAuthManagement] = useState([])
    const [accountCreatedData, setAccountCreatedData] = useState([])
    const [accountDeletedData, setAccountDeletedData] = useState([])
    const [accountPasswordChangeData, setAccountPasswordChangeData] = useState([])

    const [uniqueUserCount, setUniqueUserCount] = useState()
    const [uniqueHostCount, setUniqueHostCount] = useState()
    const [recentAuthCount, setRecentAuthCount] = useState(0)
    const [recentFileOperationsCount, setRecentFileOperationsCount] = useState([])
    const [accountCreatedDataCount, setAccountCreatedDataCount] = useState(0)
    const [accountDeletedDataCount, setAccountDeletedDataCount] = useState(0)
    const [accountPasswordChangeDataCount, setAccountPasswordChangeDataCount] = useState(0)
    const [accountLockedDataCount, setAccountLockedDataCount] = useState(0)
    const [accountChangedDataCount, setAccountChangedDataCount] = useState(0)
    const navigate = useNavigate();

    // email
    const [uniqueSenderCount, setUniqueSenderCount] = useState()
    const [uniqueRecevierCount, setUniqueRecevierCount] = useState()
    const [mostCommonRecevierLabel, setmostCommonRecevierLabel] = useState([])
    const [mostCommonRecevierData, setmostCommonRecevierData] = useState([])
    const [mostCommonSenderLabel, setMostCommonSenderLabel] = useState([])
    const [mostCommonSenderData, setMostCommonSenderData] = useState([])
    const Recieverdata = {
        labels: mostCommonRecevierLabel.slice(0, 4),
        datasets: [
            {
                label: "",
                data: mostCommonRecevierData.slice(0, 4),
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            }
        ]
    }
    const [tenandId, setTenandId] = useState([])


    const Senderdata = {
        labels: mostCommonSenderLabel.slice(0, 4),
        datasets: [
            {
                label: "",
                data: mostCommonSenderData.slice(0, 4),
                backgroundColor: [
                    "rgba(255, 99, 132, 0.8)",
                    "rgba(54, 162, 235, 0.8)",
                    "rgba(255, 206, 86, 0.8)",
                    "rgba(75, 192, 192, 0.8)",
                    "rgba(153, 102, 255, 0.8)",
                    "rgba(255, 159, 64, 0.8)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 0,
                hoverOffset: 0,
            }
        ]
    }



    const donutChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Chart.js Bar Chart',
            },
        }
    };
    // // firewall network
    // const [networkIn, setNetworkIn] = useState(0)
    // const [networkOut, setNetworkOut] = useState(0)

    useEffect(() => {

        dispatch(getVulnerabilities(0, "desc", 100, "", "", dateRange));
        dispatch(getVulnerabilitesEx(0, "desc", 100, "", "", dateRange));
        dispatch(getSecurityEvents(0, "desc", 100, "", "", dateRange));
        dispatch(getTableData(0, "desc", 100, "", "", dateRange));

    }, [dateRange])


    useEffect(() => {
        getTenant().then(({ data: { hits: { hits } } }) => {
            var licence = []
            hits?.map((item) => {
                licence.push(item?._source.tenant_id)
            })
            setTenandId(licence[0])
            getInvestigation(licence[0], 50, "desc")
                .then(
                    ({
                        data: {
                            hits: { hits },
                        },
                    }) => {
                        hits.map((item) => {
                            item._source.detection_timestamp = getDateFromTimestamp(
                                item._source.detection_timestamp
                            );
                        });
                        let groups = Object.create(null);
                        let result;

                        hits.forEach((element) => {
                            groups[element._source.detection_timestamp] =
                                groups[element._source.detection_timestamp] || [];
                            groups[element._source.detection_timestamp].push(element);
                        });

                        result = Object.keys(groups).map((k) => {
                            let temp = {};
                            temp[k] = groups[k];
                            return temp;
                        });
                        setInvestigateData(result);
                        setTimelineIsLoading(false);
                        console.log("inv", result)

                    }
                )
                .catch((err) => {
                    console.log("Error", err);
                });
        }).catch((err) => {
            console.log("Error", err)
        })
        getHeadlines(8, "desc").then(({ data: { hits: { hits } } }) => {
            hits && setHeadlinesData(hits)
            setHeadLoading(false)
        }).catch((err) => {
            console.log("Error", err)
            setHeadLoading(false)
        })

        getEventDetails().then(({ data: { hits: { hits } } }) => {
            hits && setEventDetails(hits)
            setEventsLoading(false)
        }).catch((err) => {
            console.log("Error", err)
            setEventsLoading(false)
        })

        // Source Country 
        getWidgetsData("cybSourceCountry", 20, "desc", "", "", dateRange).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    setSourceCountryLabel(label)
                });
                console.log(label)
                let geoData = [];
                let locations = label
                locations.forEach((location) => {
                    geoData.push([location])

                })
                geoData = [["test"], ...geoData]
                setGeoSourceChart(geoData)
                setTrafficFromInternet(false)
            }
            else {
                setSourceCountryLabel([])
                setGeoSourceChart([])
                setTrafficFromInternet(false)
            }


        }).catch((err) => {
            console.log("error", err)
            setTrafficFromInternet(false)
        })

        getWidgetsData("cybDestCountry", 20, "desc", "", "", dateRange).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    setDestinationCountryLabel(label)
                });
                let geoData = [];
                let locations = label
                locations.forEach((location) => {
                    geoData.push([location])
                })
                geoData = [["test"], ...geoData]

                setDestinationChart(geoData)
                setTrafficToInternet(false)
            }
            else {
                setDestinationCountryLabel([])
                setDestinationChart([])
                setTrafficToInternet(false)

            }



        }).catch((err) => {
            console.log("Error", err)
            setTrafficToInternet(false)
        })

        // Recent Threads Observed
        // getDetections(count)

        getDetections(50, "desc", "")
            .then(
                ({
                    data: {
                        hits: { hits },
                    },
                }) => {
                    hits.map(
                        (item) =>
                        (item._source.detection_timestamp = getDateFromTimestamp(
                            item._source.detection_timestamp,
                            "MMM Do YY"
                        ))
                    );
                    let groups = Object.create(null);
                    let result;

                    hits.forEach((element) => {
                        groups[element._source.detection_timestamp] =
                            groups[element._source.detection_timestamp] || [];
                        groups[element._source.detection_timestamp].push(element);
                    });

                    result = Object.keys(groups).map((k) => {
                        let temp = {};
                        temp[k] = groups[k];
                        return temp;
                    });

                    setDetectionData(result);
                    setTimelineIsLoading(false);
                    console.log("timeline", result)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        // recent investigation details
        // grouped Investigation


        // get recent operation

        getRecentFileOperations(10, dateRange).then(({ data: { hits: { hits }, aggregations } }) => {
            hits && setRecentFileOperations(hits)
            aggregations && setRecentFileOperationsCount(aggregations.unique_count.buckets)

            if (aggregations) {
                let obj = {};
                aggregations.unique_count.buckets.forEach(function (item) {
                    let key = item.key;
                    obj[key] = item.doc_count;
                });
                setRecentFileOperationsCount(obj)
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        getRecentAuthManagementDash(10, dateRange).then(({ data: { hits: { hits } } }) => {
            hits && setRecentAuthManagement(hits); setRecentAuthCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })


        // account created 
        getAccountCreated(10, dateRange).then(({ data: { hits } }) => {
            hits && setAccountCreatedData(hits); setAccountCreatedDataCount(hits?.total?.value)
            console.log('hits', hits)
        }).catch((err) => {
            console.log("Error", err)
        })

        // account deleted /disabled
        getAccountDeleted(10, dateRange).then(({ data: { hits } }) => {
            hits && setAccountDeletedData(hits); setAccountDeletedDataCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })

        // account deleted /disabled
        getAccountPasswordChage(10, dateRange).then(({ data: { hits } }) => {
            hits && setAccountPasswordChangeData(hits); setAccountPasswordChangeDataCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })
        // account Locked 
        getAccountLocked(10, dateRange).then(({ data: { hits } }) => {
            hits && setAccountLockedDataCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })
        // account Changed
        getAccountChanged(10, dateRange).then(({ data: { hits } }) => {
            hits && setAccountChangedDataCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })
        // user activity mixed account delte create password change
        userActivity(10, dateRange).then(({ data: { hits: { hits } } }) => {
            console.log("hitsss new acc :", hits)
            var useracc = []
            hits && hits.map((item) => {
                var newItem = item
                if (item?._source?.event?.code === "4726" || item?._source?.event?.code === "4725") {
                    const type = "account_deleted"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4720") {
                    const type = "account_created"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4723" || item?._source?.event?.code === "4724") {
                    const type = "password_change"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4738") {
                    const type = "account_changed"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4740") {
                    const type = "account_locked"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                useracc.push(newItem)
            })
            console.log('useracc', useracc)
            setAccountActivity(useracc)
            setRecentAccLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setRecentAccLoader(false)
        })
        // user host counts
        recentCounts("user.name").then(({ data: { aggregations: { unique_users } } }) => {
            unique_users.value && setUniqueUserCount(unique_users.value)
        }).catch((err) => {
            console.log("Error", err)
        })

        recentCounts("host.name").then(({ data: { aggregations: { unique_users } } }) => {
            unique_users.value && setUniqueHostCount(unique_users.value)
        }).catch((err) => {
            console.log("Error", err)
        })


        getUniqueSRCounts("email.from.address", "", dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            console.log("email.from.address", unique_users.value)
            unique_users.value && setUniqueSenderCount(unique_users.value)
        }).catch((err) => {

        })

        // "email.receiver.address"

        getUniqueSRCounts("email.receiver.address", "", dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            console.log("email.receiver.address", unique_users.value)
            unique_users.value && setUniqueRecevierCount(unique_users.value)
        }).catch((err) => {

        })

        // "email.from.address"

        getMostCommonSRA("email.from.address", "", dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            console.log("email.from.address GRAPH", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setMostCommonSenderLabel(label);
                    setMostCommonSenderData(data);
                    setSenderLoader(false)
                });
            }
            else {
                setMostCommonSenderLabel([]);
                setMostCommonSenderData([]);
                setSenderLoader(false)
            }
        }).catch((err) => {
            setSenderLoader(false)

        })

        // "email.receiver.address"

        getMostCommonSRA("email.receiver.address", "", dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            console.log("email.receiver.address GRAPH", buckets)
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setmostCommonRecevierLabel(label);
                    setmostCommonRecevierData(data);
                    setReceiversLoader(false)
                });


            }
            else {
                setmostCommonRecevierLabel([]);
                setmostCommonRecevierData([]);
                setReceiversLoader(false)
            }
        }).catch((err) => {
            setReceiversLoader(false)
        })

        // getFireWallTraffic(dateRange).then(({ data: { aggregations} }) => {
        //     setNetworkIn(aggregations.network_in.value)
        //     setNetworkOut(aggregations.network_out.value)
        // }).catch((err) => {
        // })



        // UEBA
        getInspectedCount()
            .then(
                ({
                    data: {
                        aggregations: { events_scanned },
                    },
                }) => {
                    events_scanned && setInspectedCount(events_scanned.value);
                    setInspectedCountLoading(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        getAnomalousCount()
            .then(
                ({
                    data: {
                        hits: { total },
                    },
                }) => {
                    setAnomaliesCount(total.value);
                    setAnomaliesCountLoading(false)
                }
            )
            .catch((err) => {
                console.log("Error", err);
            });

        getCount("user_name")
            .then(
                ({
                    data: {
                        aggregations: {
                            item_count: { value },
                        },
                    },
                }) => {
                    setUserCount(value);
                    setUsersCountLoading(false)
                }
            )
            .catch((err) => {
                console.log("error", err);
            });

        getCount("host_name")
            .then(
                ({
                    data: {
                        aggregations: {
                            item_count: { value },
                        },
                    },
                }) => {
                    setHostCount(value);
                    setHostCountLoading(false)
                }
            )
            .catch((err) => {
                console.log("error", err);
            });

        // Investigate

        axios.get(`${apiBaseUrl}/investigate/catalogue?type=tenant`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth"),
            },
        }).then(({ data }) => {

            let counts = []

            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                // If the column value exists, increment the count
                if (item.log && item.log[0]) {
                    const columnName = item.log[0];
                    counts[columnName] = counts[columnName] || {
                        name: columnName,
                        count: 0,
                    };
                    counts[columnName].count++;
                }
            }
            let dist = Object.values(counts)

            setThreadsData(dist)

        }).catch((err) => {
            console.log("Error", err);
        })

    }, [dateRange, localStorage.getItem('filter')])

    useEffect(() => {
        if (investigateData.length > 0 || detectionData.length > 0) {
            var data = investigateData
            var new_data = detectionData
            for (let item of new_data) {
                let key = Object.keys(item)[0];
                let values = Object.values(item)[0];
                let existingIndex = data.findIndex(d => Object.keys(d)[0] === key);
                if (existingIndex !== -1) {
                    let existingValues = Object.values(data[existingIndex])[0];
                    for (let value of values) {
                        if (!existingValues.includes(value)) {
                            existingValues.push(value);
                        }
                    }
                } else {
                    data.push(item);
                }
            }
            setTimelineData(data)
            console.log("finl", data)
        }
    }, [investigateData, detectionData])


    const getUserName = (item)=>{
        return item?._source?.user?.target?.name || item?._source?.targetUser;
    }
    const getSub = (item)=>{
        return item?._source?.user?.name || item?._source?.winlog?.event_data?.SubjectUserName
    }
    const setFilter = (module, tab, field, label,value ) =>{
        if (module && tab && field && label) {
                let filters = Array.isArray(value) ? value.map(val => ({
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: val,
                    from: "",
                    to: "",
                    isinline: false
                    })) : [{
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: value,
                    from: "",
                    to: "",
                    isinline: false
                    }];
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: filters,
                        date: currentPageDateFilter
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
        }
    }
    const DoughnutClick = (event, ref, data, field, label,module, tab) => {
        var activeBars = getElementAtEvent(ref.current, event);
        if (activeBars?.length > 0) {
            var clickedElementIndex = activeBars?.[0].index;
            var clickedLabel = data?.labels?.[clickedElementIndex];
            console.log('Clicked label:', clickedLabel);
            if (module && tab && field && label) {
                var temp = {
                    column: { label: label, value: field },
                    type: "is",
                    selectedValue: clickedLabel,
                    from: "",
                    to: "",
                    isinline: false
                }
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date

                let temp2 = {
                    ...storedFilter,
                    [module]: {
                        [tab]: {
                            fields: [temp],
                            date: currentPageDateFilter
                        }
                    }
                };
                localStorage.setItem("filters", JSON.stringify(temp2));
            }
        }
        navigate(`/investigate/email`)
    }

    return (
        <div
            className="col-12 position-relative rounded-3"
            style={styles.section1}
        >
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto p-3 col-12 flex-wrap gap-2 bg-white rounded-3 mb-3"
                style={styles.filterWrap}
            >
                {showDownloadButton ?
                    <div
                        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                    >
                        <PDFDownloadLink document={<ManagementOverviewExport imageBlob={imageBlob} accountActivity={accountActivity} />} fileName="management-dashboard-overview">
                            {({ blob, url, loading, error }) =>
                                <Button className='btn btn-light'>Download</Button>
                            }
                        </PDFDownloadLink>
                    </div>
                    :
                    <div
                        className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                        style={styles.filterWrap}
                    >
                        <PDFDownloadLink document={<ManagementOverviewExport imageBlob={imageBlob} accountActivity={accountActivity} />} fileName="management-dashboard-overview">
                            {({ blob, url, loading, error }) =>
                                <Button className='btn btn-light' disabled>
                                    Download <Spinner animation="border" role="status" size="sm" />
                                </Button>

                            }
                        </PDFDownloadLink>
                    </div>
                }
            </div>
            <div className='col-12 d-flex flex-column gap-3' style={styles.section2}>
                <div className='col-12 flex-fill border rounded-3 p-4 mb-0'>
                    <h5>Threat intelligence</h5>
                    <p className='mb-0'>This section of dashboard presents a consolidated summary of the recent threat landscape, vulnerabilities and security events impacting the industry. By providing real-time insights into the ever-evolving realm of cyber threats, CYBOT™ equips organizations with the knowledge necessary to fortify their defences and make informed decisions to safeguard their digital assets. </p>
                    {/* <Link className='btn btn-dark mt-3' to={"/ti/activebytes"} style={{ color: globalStyles.white }}>View all</Link> */}
                </div>
                <div className="flex-fill d-flex col-12 flex-column rounded-3 p-4 bg-white gap-3" >
                    <div className='col-12 d-flex align-items-center justify-content-between'>
                        <div className='d-flex flex-column'>
                            <h5>Headlines</h5>
                            <p className='mb-0'>This section gives you an insight on on-going headlines from the internet related to cyber security so that you don’t miss a beat. </p>
                        </div>
                        <Link className='btn btn-dark' to={"/ti/activebytes"} style={{ color: globalStyles.white }}>View all</Link>
                    </div>
                    <div className='col-12 d-flex flex-row gap-3'>
                        <div className='col-12 d-flex flex-row rounded-3 mb-0 gap-3'>
                            {redirecting === true ?
                                <div className="col-6 mx-auto">
                                    <div className="alert alert-warning flex-row d-flex text-center gap-3 justify-content-center align-items-center" role="alert">
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                        <span>Redirecting...</span>
                                    </div>
                                </div>
                                :

                                // headLoading === true ? (
                                //     <LoaderList />
                                // ) : 
                                (
                                    <div className='d-flex flex-fill flex-column gap-3'>
                                        <div className='d-flex flex-row gap-3'>
                                            {
                                                headlinesData.map(
                                                    ({
                                                        _source: { event_name, attribute_timestamp, reference_link },
                                                    }, index) => (
                                                        index <= 3 &&
                                                        <a className="flex-fill reset-link cursor-pointer" onClick={(e) => handleRedirect(reference_link)}>
                                                            <div
                                                                className="col-12 d-flex flex-column p-3 rounded-3 border card ti-card alert bg-white mb-0 h-100 justify-content-between"
                                                                style={styles.tile}
                                                            >
                                                                <h6 className="">{event_name}</h6>
                                                                <div className="d-flex flex-row mt-3 gap-2 align-items-center" style={{ fontSize: "10px" }}>
                                                                    {/* <Calendar size={18} /> */}
                                                                    {convertTimestamp(attribute_timestamp)}
                                                                </div>
                                                            </div>
                                                        </a>

                                                    )
                                                )
                                            }
                                        </div>
                                        <div className='d-flex flex-row gap-3'>
                                            {
                                                headlinesData.map(
                                                    ({
                                                        _source: { event_name, attribute_timestamp, reference_link },
                                                    }, index) => (
                                                        index > 3 &&
                                                        <a className="flex-fill reset-link cursor-pointer" onClick={(e) => handleRedirect(reference_link)}>
                                                            <div
                                                                className="col-12 d-flex flex-column p-3 rounded-3 border card ti-card alert bg-white mb-0 h-100 justify-content-between"
                                                                style={styles.tile}
                                                            >
                                                                <h6 className="">{event_name}</h6>
                                                                <div className="d-flex flex-row mt-3 gap-2 align-items-center" style={{ fontSize: "10px" }}>
                                                                    {/* <Calendar size={18} /> */}
                                                                    {convertTimestamp(attribute_timestamp)}
                                                                </div>
                                                            </div>
                                                        </a>

                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>

                    <div ref={TiRefImage} className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                        <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                            <div>
                                <h5>{vulCounts ? vulCounts : "--"}</h5>
                                <span>Vulnerabilities</span>
                            </div>
                        </div>
                        <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                            <div>
                                <h5>{exVulCounts ? exVulCounts : "--"}</h5>
                                <span>Vulnerabilities Exploits</span>
                            </div>
                        </div>
                        <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                            <div>
                                <h5>{secCounts ? secCounts : "--"}</h5>
                                <span>Security Events</span>
                            </div>
                        </div>
                        <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0">
                            <div>
                                <h5>{commCount ? commCount : "--"}</h5>
                                <span>Community Indicators</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='d-flex col-12 gap-3'>
                    <div ref={uebaRefImage} className="d-flex flex-fill flex-column gap-3 rounded-3 p-4 bg-white" >
                        <h5>UEBA</h5>
                        <div className="d-flex flex-column flex-fill rounded-3 gap-3">
                            <div className='d-flex flex-column gap-3'>
                                {/* <div className="flex-fill rounded-3 p-3 alert alert-info mb-0">
                                    <h5>{inspectedCount ? inspectedCount : "--"}</h5>
                                    <span>Events inspected</span>
                                </div> */}

                                <div className="flex-fill rounded-3 p-3 alert alert-danger mb-0" onClick={() => { navigate("/ueba/detection") }}>
                                    <h5>{anomaliesCount ? anomaliesCount : "--"}</h5>
                                    <span>Anomalies detected</span>
                                </div>

                                <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0" onClick={() => { navigate("/ueba/detection") }}>
                                    <h5>{userCount}</h5>
                                    <span>Anomalous Users</span>
                                </div>

                                <div className="flex-fill rounded-3 p-3 alert alert-warning mb-0" onClick={() => { navigate("/ueba/detection") }}>
                                    <h5>{hostCount}</h5>
                                    <span>Anomalous Hosts</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ref={investigateRefImage} className="col-9 d-flex flex-column gap-3 rounded-3 p-4 bg-white" >
                        <h5>Investigate</h5>

                        <div className="flex-fill rounded-3 p-3 border mb-0">
                            <h5>Threats Being Monitored Actively</h5>
                            <p>The CYBOT™ Investigate module actively detects threats pop your organisation utilising the logs forwarded to the platform. It first detects and then investigates the threat like a human analyst and summarises the investigation report. Below is the number of use-cases or threats being monitored and investigated on the platform</p>

                            <div className="d-flex flex-column flex-fill rounded-3 gap-3">
                                <div className="col-12 d-flex flex-row gap-3">
                                    {threadsData?.map((item, index) => (
                                        (index <= 4) &&
                                        <div className="flex-fill rounded-3 p-3 alert alert-primary mb-0">
                                            <div>
                                                <h4>{item.count}</h4>
                                                <span>{item.name === "edr" ? "Endpoint" : item.name}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-12 d-flex flex-row gap-3">
                                    {threadsData?.map((item, index) => (
                                        (index > 4) &&
                                        <div className="flex-fill rounded-3 p-3 alert alert-primary mb-0">
                                            <div>
                                                <h4>{item.count}</h4>
                                                <span>{item.name === "edr" ? "Endpoint" : item.name}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='col-12 flex-fill border rounded-3 p-4 mb-0'>
                    <h5>Traffic</h5>
                    <p className='mb-0'>The network traffic section in the dashboard provides valuable information for identifying potential suspicious activity targeting the organization's assets, as well as suspicious traffic originating from within the organization towards countries of interest. By monitoring incoming and outgoing network traffic, you can gain insights into potential security threats and take appropriate actions to mitigate risks.</p>
                    <Link className='btn btn-dark mt-3' to={"/investigate/firewall"} style={{ color: globalStyles.white }}>View all</Link>
                </div>

                <div ref={trafficInternetRefImage} className="flex-fill d-flex col-12 flex-column rounded-3 p-4 gap-3 bg-white" >
                    <div className="col-12 d-flex flex-row gap-3 overflow-auto">
                        <div className='d-flex col-6 border rounded-3 p-4'>
                            <div className='col-12 d-flex flex-column gap-3 justify-content-center'>
                                <h5>Traffic from the Internet</h5>
                                {trafficFromInternet ? (
                                    <PlainCardLoader />
                                ) : (
                                    <>
                                        {geoSourceChart.length !== 0 ? (
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Chart
                                                    chartEvents={[
                                                        {
                                                            eventName: "select",
                                                            callback: ({ chartWrapper }) => {
                                                                const chart = chartWrapper.getChart();
                                                                const selection = chart.getSelection();
                                                                if (selection?.length === 0) return;
                                                                const region = geoSourceChart[selection?.[0]?.row + 1];
                                                                setFilter("investigation","cykit-firewall","cybSourceCountry","Source Country",region)
                                                                navigate(`/investigate/firewall?cybSourceCountry=${region}`)
                                                            },
                                                        },
                                                    ]}
                                                    chartType="GeoChart"
                                                    width="100%"
                                                    height="250px"
                                                    data={geoSourceChart}
                                                />
                                            </div>
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}

                                    </>
                                )}

                            </div>
                        </div>

                        <div className='d-flex flex-fill border rounded-3 p-4'>
                            <div className='col-12 d-flex flex-column gap-3 justify-content-center'>
                                <h5>Traffic to the Internet</h5>
                                {trafficToInternet ? (
                                    <PlainCardLoader />
                                ) : (
                                    <>
                                        {geoDestinationChart.length !== 0 ? (
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Chart
                                                    chartEvents={[
                                                        {
                                                            eventName: "select",
                                                            callback: ({ chartWrapper }) => {
                                                                const chart = chartWrapper.getChart();
                                                                const selection = chart.getSelection();
                                                                if (selection?.length === 0) return;
                                                                const region = geoDestinationChart[selection?.[0]?.row + 1];
                                                                setFilter("investigation","cykit-firewall", "cybDestCountry","Destination Country",region)
                                                                navigate(`/investigate/firewall?cybDestCountry=${region}`)

                                                            },
                                                        },
                                                    ]}
                                                    chartType="GeoChart"
                                                    width="100%"
                                                    height="250px"
                                                    data={geoDestinationChart}
                                                />
                                            </div>
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <Tabs
                            defaultActiveKey="from_internet"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="from_internet" title="Traffic from the Internet">
                            </Tab>
                            <Tab eventKey="to_internet" title="Traffic to the Internet">
                            </Tab>
                        </Tabs> */}
                    </div>
                </div>



                <div className='col-12 flex-fill border rounded-3 p-4 mb-0'>
                    <h5>Activities of Organization</h5>
                    <p className='mb-0'>This section presents a comprehensive summary of the activities occurring within your organization, encompassing recent file operations, authentication events, and user management activities. By consolidating this information, you gain valuable insights into the internal workings of your organization, including the handling of files, user authentication activities, and the management of user accounts. This summary allows you to track and monitor these activities, ensuring proper governance, security, and accountability within your organization. </p>
                </div>

                {/* <div className="flex-fill d-flex col-12 flex-column gap-3 rounded-3 p-4 bg-white">
                    <div className='col-12 d-flex flex-row gap-3'>

                        <div className="flex-fill d-flex flex-column gap-3 overflow-auto border rounded-3">
                            <div className="flex-fill p-4 rounded-3 bg-white" >
                                <div className='col-12'>
                                    <h5>Recent Authetications</h5>
                                </div>
                                {recentAuthManagement.length === 0 ? (
                                    <NoDataFound errorText={"No data found"} />
                                ) : (
                                    <div
                                        className="d-flex col-12 position-relative"
                                        style={styles.sectionWrap1}
                                    >
                                        <Table className='table table-borderless table-hover'>
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Date</th>
                                                    <th>User Name</th>
                                                    <th>Host Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recentAuthManagement?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td>{item._source.user?.name}</td>
                                                        <td>{item._source.host?.name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                )}

                            </div>
                        </div>

                        <div className="flex-fill p-4 rounded-3 bg-white" >
                            <div className='col-12'>
                                <h5>Unique User</h5>
                            </div>
                            <div
                                className="d-flex col-12 position-relative"
                            >
                                {uniqueUserCount}
                            </div>
                        </div>

                        <div className="flex-fill p-4 rounded-3 bg-white" >
                            <div className='col-12'>
                                <h5>Unique Host</h5>
                            </div>
                            <div
                                className="d-flex col-12 position-relative"
                            >
                                {uniqueHostCount}
                            </div>
                        </div>
                        <div className="flex-fill p-4 rounded-3 bg-white" >
                            <div className='col-12'>
                                <h5>Recent Authentication</h5>
                            </div>
                            <div
                                className="d-flex col-12 position-relative"
                            >
                                {recentAuthCount}
                            </div>
                        </div>
                        <div className="flex-fill p-4 rounded-3 bg-white" >
                            <div className='col-12'>
                                <h5>Recent Authentication</h5>
                            </div>
                            <div
                                className="d-flex col-12 position-relative"
                            >
                                {recentAuthCount}
                            </div>
                        </div>
                    </div>
                </div> */}




                <div className="flex-fill d-flex col-12 flex-row rounded-3 gap-3">
                    <div className="col-8 d-flex flex-column gap-3 rounded-3 p-4 bg-white" >
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-column col-10'>
                                <h5>Email</h5>
                                <p>The email activity section of the dashboard provides a summary of the organization's email-related information and activity. This section is crucial for monitoring and analyzing email communications within the organization to ensure security, compliance, and operational efficiency.</p>
                            </div>
                            <Link className='btn btn-dark' to={"/investigate/email"} style={{ color: globalStyles.white }}>View all</Link>
                        </div>

                        <div ref={emailRefImage}>
                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white" >
                                <div className="col-6 rounded-3 p-3 alert alert-primary mb-0" onClick={() => navigate(`/investigate/email`)}>
                                    <div>
                                        <h5>{uniqueSenderCount ? uniqueSenderCount : "--"}</h5>
                                        <span>Unique Email Senders</span>
                                    </div>
                                </div>
                                <div className="flex-fill rounded-3 p-3 alert alert-primary mb-0" onClick={() => navigate(`/investigate/email`)}>
                                    <div>
                                        <h5>{uniqueRecevierCount ? uniqueRecevierCount : "--"}</h5>
                                        <span>Unique Email Recivers</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                <div className="col-6 p-4 rounded-3 bg-white border" >
                                    <div className='col-12'>
                                        <h5>Top Receivers</h5>
                                    </div>
                                    <div
                                        className="d-flex col-12 position-relative justify-content-center"
                                        style={styles.cartWrap1}
                                    >
                                        {receiversLoader ? (
                                            <PlainCardLoader />
                                        ) : (
                                            <>
                                                {mostCommonRecevierData.length !== 0 ? (
                                                    <Doughnut data={Recieverdata} options={donutChartOptions} ref={emailRecieverRef} onClick={(event) => { DoughnutClick(event, emailRecieverRef,Recieverdata, "email.receiver.address", "Reciever Address", "investigation", "cykit-email") }} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-fill p-4 rounded-3 bg-white border" >
                                    <div className='col-12'>
                                        <h5>Top Senders</h5>
                                    </div>
                                    <div
                                        className="d-flex col-12 position-relative justify-content-center"
                                        style={styles.cartWrap1}
                                    >
                                        {senderLoader ? (
                                            <PlainCardLoader />
                                        ) : (
                                            <>
                                                {mostCommonSenderData.length !== 0 ? (
                                                    <Doughnut data={Senderdata} options={donutChartOptions} ref={emailSenderRef} onClick={(event) => { DoughnutClick(event, emailSenderRef, Senderdata,"email.from.address", "Sender Address", "investigation", "cykit-email") }} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-fill d-flex flex-column rounded-3 p-4 gap-3 bg-white">
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-column col-10'>
                                <h5>File Operations</h5>
                                <p>The section presented on the dashboard provides a comprehensive summary of the file activities that have occurred within our organization. It offers an overview of various actions related to file management, tracking changes, and monitoring file usage. This includes activities such as file creations, modifications, and deletions, as well as sharing and collaboration activities among user.</p>
                            </div>
                            <Link className='btn btn-dark' to='/hunter/cykit' state={{ data: "file" }} style={{ color: globalStyles.white }}>View all</Link>
                        </div>

                        {/* {console.log('recentFileOperationsCount', recentFileOperationsCount)} */}
                        {recentFileOperationsCount &&
                            <div ref={fileOperationsRefImage} className="col-12 d-flex flex-column gap-3 p-0 rounded-3 bg-white">
                                <div className='col-12 d-flex flex-column gap-3'>
                                    <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{localStorage.setItem("hunter.cykit.edr.activeTab","file");setFilter("hunter","cykit-edr-file", "event.action", "Event Type","creation");navigate(`/hunter/cykit`)}}>
                                        <div className='alert alert-success rounded-circle tile-icon'>
                                            <FilePlus size={20} />
                                        </div>
                                        <div>
                                            <h5>{recentFileOperationsCount.creation ? recentFileOperationsCount.creation : "--"}</h5>
                                            <span>File Creations</span>
                                        </div>
                                    </div>
                                    <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{localStorage.setItem("hunter.cykit.edr.activeTab","file");setFilter("hunter","cykit-edr-file", "event.action", "Event Type","deletion");navigate(`/hunter/cykit`)}}>
                                        <div className='alert alert-danger rounded-circle tile-icon'>
                                            <FileX size={20} />
                                        </div>
                                        <div>
                                            <h5>{recentFileOperationsCount.deletion ? recentFileOperationsCount.deletion : "--"}</h5>
                                            <span>File Deletions</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 d-flex flex-column gap-3'>
                                    <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{localStorage.setItem("hunter.cykit.edr.activeTab","file");setFilter("hunter","cykit-edr-file", "event.action"  ,"event.action","Event Type","modification");navigate(`/hunter/cykit`)}}>
                                        <div className='alert alert-warning rounded-circle tile-icon'>
                                            <FilePlus size={20} />
                                        </div>
                                        <div>
                                            <h5>{recentFileOperationsCount.modification ? recentFileOperationsCount.modification : "--"}</h5>
                                            <span>File Modifications</span>
                                        </div>
                                    </div>
                                    <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{localStorage.setItem("hunter.cykit.edr.activeTab","file");setFilter("hunter","cykit-edr-file", "event.action", "Event Type","rename");navigate(`/hunter/cykit`)}}>
                                        <div className='alert alert-info rounded-circle tile-icon'>
                                            <FilePlus size={20} />
                                        </div>
                                        <div>
                                            <h5>{recentFileOperationsCount.rename ? recentFileOperationsCount.rename : "--"}</h5>
                                            <span>File Rename</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 d-flex flex-row gap-3' onClick={()=>{localStorage.setItem("activeTab.hunter","file");setFilter("hunter","cykit-edr-file", "event.action","Event Type","overwrite");navigate(`/hunter/cykit`)}}>
                                    <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0">
                                        <div className='alert alert-warning rounded-circle tile-icon'>
                                            <FilePlus size={20} />
                                        </div>
                                        <div>
                                            <h5>{recentFileOperationsCount.overwrite ? recentFileOperationsCount.overwrite : "--"}</h5>
                                            <span>File Overwrites</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="flex-fill d-flex flex-column rounded-3 p-4 gap-3 bg-white" >
                    <div className='col-12 d-flex align-items-center justify-content-between'>
                        <div className='d-flex flex-column col-10'>
                            <h5>User Management Activities</h5>
                            <p>The section displayed on the dashboard provides a detailed summary of the user management activities that have taken place within our organization. It presents a consolidated view of various activities related to managing user accounts, permissions, and access levels. This includes activities such as user creation, deletion, and modification, as well as changes in user roles and permissions.</p>
                        </div>
                        <Link className='btn btn-dark' to={"/investigate/active_directory"} style={{ color: globalStyles.white }}>View all</Link>
                    </div>

                    <div className='col-12 d-flex flex-row gap-3'>
                        <div ref={UserManagementActivitiesRefImage} className="col-6 d-flex flex-column gap-3 p-0 rounded-3 bg-white">
                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                <div className="flex-row flex-fill gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{setFilter("investigation","cykit-ad", "event.code","Event Code","4720");navigate(`/investigate/active_directory`)}}>
                                    <div className='alert alert-success rounded-circle tile-icon'>
                                        <UserPlus size={20} />
                                    </div>
                                    <div>
                                        <h5>{accountCreatedDataCount ? accountCreatedDataCount : "--"}</h5>
                                        <span>Accounts Created</span>
                                    </div>
                                </div>

                                <div className="flex-row flex-fill gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{setFilter("investigation","cykit-ad" , "event.code","Event Code",["4726","4725"]);navigate(`/investigate/active_directory`)}}> 
                                    <div className='alert alert-danger rounded-circle tile-icon'>
                                        <UserMinus size={20} />
                                    </div>
                                    <div>
                                        <h5>{accountDeletedDataCount ? accountDeletedDataCount : "--"}</h5>
                                        <span>Accounts Deleted</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">

                                <div className="flex-row flex-fill gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{setFilter("investigation","cykit-ad","event.code", "Event Code","4740");navigate(`/investigate/active_directory`)}}>
                                    <div className='alert alert-warning rounded-circle tile-icon'>
                                        <Lock size={20} />
                                    </div>
                                    <div>
                                        <h5>{accountLockedDataCount ? accountLockedDataCount : "--"}</h5>
                                        <span>Accounts Locked</span>
                                    </div>
                                </div>

                                <div className="flex-row flex-fill gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{setFilter("investigation","cykit-ad", "event.code","Event Code","4738");navigate(`/investigate/active_directory`)}}>
                                    <div className='alert alert-info rounded-circle tile-icon'>
                                        <UserSwitch size={20} />
                                    </div>
                                    <div>
                                        <h5>{accountChangedDataCount ? accountChangedDataCount : "--"}</h5>
                                        <span>Accounts Changed</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">

                                <div className="flex-row flex-fill gap-3 rounded-3 p-3 card border mb-0" onClick={()=>{setFilter("investigation","cykit-ad", "event.code","Event Code",["4723","4724"]);navigate(`/investigate/active_directory`)}}>
                                    <div className='alert alert-info rounded-circle tile-icon'>
                                        <Password size={20} />
                                    </div>
                                    <div>
                                        <h5>{accountPasswordChangeDataCount ? accountPasswordChangeDataCount : "--"}</h5>
                                        <span>Passwords Changed</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div ref={RecentAccountActivitiesRefImage} className='d-flex flex-row flex-fill gap-3'>
                            <div className="flex-fill d-flex flex-column gap-3 overflow-auto border rounded-3">
                                <div className="flex-fill p-4 rounded-3 bg-white" >
                                    <div className='col-12 mb-3'>
                                        <h5>Recent Account Activities</h5>
                                    </div>
                                    {recentAccLoader ? (
                                        <PlainCardLoader />
                                    ) : (
                                        <>
                                            {accountActivity.length !== 0 ? (
                                                <div
                                                    className="d-flex flex-column gap-3 col-12 position-relative"
                                                    style={styles.sectionWrap1}
                                                >
                                                    {accountActivity?.map((item, i) => (
                                                        <div className='col-12 d-flex flex-row gap-3 p-3 rounded-3 border'>
                                                            {item?.type === 'account_created' &&
                                                                <>
                                                                    <div className='alert alert-success rounded-circle tile-icon text-uppercase'>
                                                                        {getUserName(item).charAt(0)}
                                                                    </div>

                                                                    <div>
                                                                        <p><strong>{getUserName(item)}</strong> created by <strong>{getSub(item)}</strong></p>
                                                                        {/* <p>{item?._source?.message}</p> */}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_deleted' &&
                                                                <>
                                                                    <div className='alert alert-danger rounded-circle tile-icon text-uppercase'>
                                                                        {getSub(item).charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        <p>The user account <strong>{getUserName(item)}</strong> was deleted by <strong>{getSub(item)}</strong></p>
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_changed' &&
                                                                <>
                                                                    <div className='alert alert-warning rounded-circle tile-icon text-uppercase'>
                                                                        {getSub(item)?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        {getUserName(item) === getSub(item) ?
                                                                            <p>The user account <strong>{getUserName(item)}</strong> was changed</p>
                                                                            :
                                                                            <p>The user account <strong>{getUserName(item)}</strong> was changed by <strong>{getSub(item)}</strong></p>}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_locked' &&
                                                                <>
                                                                    <div className='alert alert-warning rounded-circle tile-icon text-uppercase'>
                                                                        {getSub(item)?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        <p>The user account <strong>{getUserName(item)}</strong> was locked by <strong>{getSub(item)}</strong></p>
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'password_change' &&
                                                                <>
                                                                    <div className='alert alert-info rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.name?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        {getSub(item) === getUserName(item) ?
                                                                            <p>Password of the user account <strong>{getUserName(item)}</strong> was changed</p>
                                                                            :
                                                                            <p>Password of the user account <strong>{getUserName(item)}</strong> was changed by <strong>{getSub(item)}</strong></p>}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/* <div>
                                                         <h6>{item?._source?.user?.target?.name}</h6>
                                                         <p>{item?._source?.user?.name}</p>
                                                         <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                             <CalendarBlank />
                                                             <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                             <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                         </div>
                                                     </div> */}
                                                            {/* <td>{item._source.host.name}</td> */}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="flex-fill d-flex col-12 flex-column rounded-3 p-4 bg-white" >
                <div className='col-12 d-flex flex-row gap-3'>

                    
                </div>
            </div> */}
            </div>
        </div>

    )
}

export default ManagementDashboard