import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import Authentication from "./Authentication";
import Exception from "./Exception";
import Integrations from "./Integrations";
import Notifications from "./Notifications";
import LicenceInfo from "./LicenceInfo";
import DateFormat from "./DateFormat";
import styles from "./Settings.styles";
import Filters from "./Filters";
import Health from "./Health/Health";
import IndexHealth from "./Health/IndexHealth";
import Users from "./Users/Users";
import User from "./Users/CreateUser"
import EditUser from "./Users/EditUser";
import Logs from "./Logs";
const Settings = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [settingsMenu, setSettingsMenu] = useState("Authentication");
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [dateFilter, setDateFilter] = useState();

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname?.split("/")[2]);
  }, [location]);

  // useEffect(() => {
  //   setTitle("Settings");
  //   setBreadcrumb({
  //     module: "settings",
  //     path: [{ title: "Settings", url: "/settings" }],
  //   });
  // }, [module]);


  useEffect(() => {
    if (currentPath === "authentication") {
      setTitle("Authentication")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Authentication", "url": "/settings/authentication" },]
      });
    }
    if (currentPath === "exception") {
      setTitle("Exceptions")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Exception", "url": "/settings/exception" },]
      });
    }
    if (currentPath === "integrations") {
      setTitle("Integrations")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Integrations", "url": "/settings/integrations" },]
      });
    }
    if (currentPath === "notifications") {
      setTitle("Notifications")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Notifications", "url": "/settings/notifications" },]
      });
    }
    if (currentPath === "licenceinfo") {
      setTitle("Licence Info")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Licence Info", "url": "/settings/licenceinfo" },]
      });
    }
    if (currentPath === "dateformat") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Date Format", "url": "/settings/dateformat" },]
      });
    }
    if (currentPath === "health") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Log Ingestion Health", "url": "/settings/health" },]
      });
    }
    if (currentPath === "index-health") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Log Ingestion Health", "url": "/settings/health" }, { "title": "Index", "url": "/settings/health" },]
      });
    }
    if (currentPath === "create-user") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Users", "url": "/settings/users" }, { "title": "Create User", "url": "/settings/create-user" },]
      });
    }
    if (currentPath === "users") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Users", "url": "/settings/users" },]
      });
    }
    if (currentPath === "edit-user") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Users", "url": "/settings/users" }, { "title": "Edit User", "url": "/settings/edit-user" }]
      });
     
    }
    if (currentPath === "logs") {
      setTitle("Date Format")
      setBreadcrumb({
        "module": "settings",
        "path": [{ "title": "Settings", "url": "/settings/authentication" }, { "title": "Logs", "url": "/settings/logs" }]
      })
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module="settings" />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          module="settings"
          setDateFilter={setDateFilter}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "authentication" ? (
            <Authentication />
          ) : currentPath === "exception" ? (
            <Exception />
          ) : currentPath === "integrations" ? (
            <Integrations />
          ) : currentPath === "notifications" ? (
            <Notifications />
          ) : currentPath === "licenceinfo" ? (
            <LicenceInfo />
          ) : currentPath === "dateformat" ? (
            <DateFormat />
          ) : currentPath === "filters" ? (
            <Filters />
          ) : currentPath === "health" ? (
            <Health />
          ) : currentPath === "index-health" ? (
            <IndexHealth />
          ) : currentPath === "users" ? (
            <Users />
          ) : currentPath === "create-user" ? (
            <User />
          ) : currentPath === "edit-user" ? (
            <EditUser />
          ) :
          currentPath === "logs" ? (
            <Logs />
          ):
            null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
