import { create } from 'zustand';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
  NodeInternals,
  NodeOrigin,
} from 'reactflow';

const initialNodes = [

];
const initialEdges = [
];

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create((set, get) => ({
  nodes: initialNodes,
  edges: initialEdges,
  expandedNode: [],
  clickedNode:"",
  onNodesChange: (changes) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  setNodes: (nodes) => {
    set({
      nodes: nodes,
    });
  },
  setEdges: (edges) => {
    set({
      edges: edges,
    });
  },
  setCollapseNodes: (del) => {
    set({
      nodes: get().nodes.filter(object1 => {
        return !del.some(object2 => {
          return object1.id === object2.id;
        });
      }),
    });
  },
  setCollapseEdges: (del) => {
    set({
      edges: get().edges.filter(object1 => {
        return !del.some(object2 => {
          return object1.id === object2.id;
        });
      }),
    });
  },
  refresh: () => {
    set({
      edges:initialEdges ,
      nodes:initialNodes,
      expandedNode:[]
    });
  },
  setExpandedNode: (node) => {
    set({
      expandedNode: node,
    });
  },
  setClick: (node) => {
    set({
      clickedNode: node,
    });
  },
}));

export default useStore;