import React, { use, useEffect, useState } from "react";
import { fpostCy } from "../../axios/axios";
import ReactJson from "react-json-view";

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./ExpandedDocument.styles";
import TableLoader from "../Loader/TableLoader";
import NoDataFound from "../NoDataFount/NoDataFound";
import SearchBar from "../Search/ManagementDashboard/SearchBar";
import { getDateFromTimestamp, unixTimestampToDate } from "../../Utils/Utils";

export default function ThreatLandScapeSideBarVulns(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const [softwareHostData, setSoftwareHostData] = useState([])
  const [hostData, sethostData] = useState([])
  const [hostCount, setHostCount] = useState(0)
  const [CriticalCount, setCriticalCount] = useState(0)
  const [ModerateCount, setModerateCount] = useState(0)
  const [SevereCount, setSevereCount] = useState(0)
  const [LowCount, setLowCount] = useState(0)
  const [searchVulnerQuery, setSearchVulnerQuery] = useState()
  function getSeverityScore(severity) {
    switch (severity) {
      case 'Critical':
        return 5;
      case 'High':
        return 4;
      case 'Medium':
        return 3;
      case 'Low':
        return 2;
      case 'Info':
        return 1;
      default:
        return 0;
    }
  }
  useEffect(() => {
    setIsLoading(true)
    if (props.clickedNode !== undefined && props.clickedNode !== "") {
      var pattern = props?.pattern
       if (pattern?.charAt(0) === '/') {
        // If it does, remove the first character
        pattern = pattern?.slice(1);
      }
      setIsOpen(true);
      if(props.type==="host"){
        var data = JSON.stringify({
          "query": {
            "bool": {
                    must:[
                      {
                        "match": {
                          "hostname": props.clickedNode
                        }
            
                      }
                    ],
                    must_not:[
                      {
                        "match":{
                          "vulnerability_title" : "test"
                          }
                        }
                    ]
            }
          },
          "size": 0,
          "aggs": {
            "unique_software": {
              "terms": {
                "field": "vulnerability_title",
                "size": 10000
              },
              "aggs": {
                "host_count": {
                  "cardinality": {
                    "field": "hostname"
                  }
                },
                "doc": {
                  "top_hits": {
                    "size": 1  
                  }
                }
              }
            }
          }});
          var severity = {
            "query": {
                "match": {
                    "hostname": props.clickedNode
                }
              },
              "size": 0,
              "aggs": {
                "unique_vulnerability_titles": {
                  "terms": {
                    "field": "vulnerability_severity",  
                    "size": 10
                  },
                  "aggs": {
                    "vulnerability_severity": {
                      "cardinality": {
                        "field": "vulnerability_title"
                      }
                    }
                  }
                }
              }
            }
            props.req(
                `${props.url}/${pattern}`,
                severity).then((response) => {
                
                  // props.parentData(response.data)
                  console.log(response?.data);
                  response?.data?.aggregations?.unique_vulnerability_titles?.buckets?.map((item)=>{
                    if(item.key==="Critical"){
                      setCriticalCount(item?.vulnerability_severity?.value)
                    }
                    if(item.key==="High"){
                      setModerateCount(item?.vulnerability_severity?.value)
                    }
                    if(item.key==="Medium"){
                      setSevereCount(item?.vulnerability_severity?.value)
                    }
                    if(item.key==="Low"){
                      setLowCount(item?.vulnerability_severity?.value)
                    }
                  }
                 )
                })
      }
      else{
        var data = JSON.stringify({
          "query": {
            "bool": {
                    must:[
                      {
                        "match": {
                          "vulnerability_title": props.clickedNode
                        }
            
                      }
                    ],
                    must_not:[
                      {
                        "match":{
                          "vulnerability_title" : "test"
                          }
                        }
                    ]
            }
          },
          "size": 0,
          "aggs": {
            "unique_software": {
              "terms": {
                "field": "hostname",
                "size": 10000
              },
              "aggs": {
                "doc": {
                  "top_hits": {
                    "size": 1
                  }
                }
              }
            }
          }});
      }
      var count =0

      props.req(
        `${props.url}/${pattern}`,
        data).then((response) => {
        
          // props.parentData(response.data)
          console.log(response.data);
          const sortedBuckets = response?.data?.aggregations?.unique_software?.buckets.sort((a, b) => {
            const aValue = getSeverityScore(a?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
            const bValue = getSeverityScore(b?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity);
            return (isNaN(bValue) ? 0 : bValue) - (isNaN(aValue) ? 0 : aValue);
        });
          response?.data?.aggregations?.unique_software?.buckets && setSoftwareHostData(sortedBuckets);
          count=response?.data?.aggregations?.unique_software?.buckets?.length + response?.data?.aggregations?.unique_software?.sum_other_doc_count
          
          response?.data?.aggregations?.unique_software?.buckets && sethostData(sortedBuckets);

          setIsLoading(false)
        setHostCount(count)
        })
      // setIsLoading(false)

    }
  }, [props.clickedNode]);

  useEffect(() => {
    if (props.close === true) {
      setIsOpen(false)
      props.setClick('')
    }
  }, [props.close])



  const handleClose = () => {
    props.setClick('')
    setIsLoading(true)
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal mt-2"
              id="offcanvasRightLabel"
            >
              {props.title ? props.title : "Expanded Document"}
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
            </button>
          </div>

          {/* {
            //  nodeData.length > 0 ? ()=>{ debugger} : "nodata"
            nodeData && nodeData.length > 0 ? (
              <h1>{nodeData["userdata"]}</h1>
            ) : (
              "NO Data"
            )
          } */}

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar" style={styles.tableWrap}>
                {isLoading ?
                  <div style={{ "position": "fixed", "right": "16%", "top": "55%" }}><Spinner animation="border" role="status" /></div> :
                  <div className="py-0" style={styles.sidebarContentWrap}>

                                            {/* <h5>Least Installed Softwarex</h5> */}
                                            {props.type==="host"?<div className='' >
                                            <div className="modal-row-item " >
                                                    <span className="modal-text me-3" style={{fontSize:"12px"}}>Total Vulnerabilities Found On Host: </span>
                                                    <span className="modal-text" style={{fontSize:"12px"}}>
                                                      {hostCount} 
                                                    </span>
                                                </div>
                                                <div className="modal-row-item " >
                                                    <span className="modal-text me-3" style={{fontSize:"12px"}}> Critical Vulnerabilities Found : </span>
                                                    <span className="modal-text" style={{fontSize:"12px"}}>
                                                      {CriticalCount} 
                                                    </span>
                                                </div><div className="modal-row-item " >
                                                    <span className="modal-text me-3" style={{fontSize:"12px"}}> High Vulnerabilities Found : </span>
                                                    <span className="modal-text" style={{fontSize:"12px"}}>
                                                      {SevereCount} 
                                                    </span>
                                                </div>
                                                <div className="modal-row-item " >
                                                    <span className="modal-text me-3" style={{fontSize:"12px"}}> Medium Vulnerabilities Found : </span>
                                                    <span className="modal-text" style={{fontSize:"12px"}}>
                                                      {ModerateCount} 
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between" >
                                                <div className="modal-row-item mb-2" >
                                                    <span className="modal-text me-3" style={{fontSize:"12px"}}> Low Vulnerabilities Found : </span>
                                                    <span className="modal-text" style={{fontSize:"12px"}}>
                                                      {LowCount} 
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                    <SearchBar data={softwareHostData} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={sethostData} type={"SoftHost"} />
                                                </div>
                                                </div>
                                                {isLoading ? (
                                                    <div className="col-6">
                                                        <TableLoader rowCount={25} />
                                                    </div>
                                                ) : hostData?.length !== 0 ? (
                                                    <>
                                                    
                                                    <Table borderless hover style={styles.sidebarContentWrap}>
                                                        <thead className="fixed-head">
                                                        <tr>
                                                                        <th>Vulnerability</th>
                                                                        <th>Score</th>
                                                                        <th>Severity</th>
                                                                    </tr>
                                                        </thead>
                                                        <tbody>
                                                        {hostData?.map((item) => (
                                                                        <tr >
                                                                            <td>
                                                                                {item?.key}
                                                                            </td>
                                                                            <td>
                                                                                {item?.doc?.hits?.hits?.[0]?._source?.vulnerability_riskScore}
                                                                            </td>
                                                                            <td>
                                                                                {item?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity}
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                    ))}
                                                        </tbody>
                                                    </Table>
                                                    </>
                                                ) : (
                                                    <NoDataFound errorText={"No data Found"} />
                                                )}
                                            </div>:
                                            <div className='' >
                                              <div className="d-flex flex-column px-2 mb-2" >
                                                        <span className="modal-text me-3" style={{fontSize:"12px"}}>About Vulnerability : </span>
                                                        <span className="modal-label px-1" style={{fontSize:"12px"}}>
                                                          {props?.expclickedNodeDoc?._source?.vulnerability_desc} 
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-column px-2 mb-2" >
                                                        <span className="modal-text me-3" style={{fontSize:"12px"}}>Recommendation: </span>
                                                        <span className="modal-label px-1" style={{fontSize:"12px"}}>
                                                          {props?.expclickedNodeDoc?._source?.vulnerability_solution} 
                                                        </span>
                                                    </div>
                                              <div className="modal-row-item mb-0 py-1" >
                                                        <span className="modal-text me-3" style={{fontSize:"12px"}}>Total Affected Hosts : </span>
                                                        <span className="modal-text" style={{fontSize:"12px"}}>
                                                        {hostCount} 
                                                        </span>
                                                    </div>
                                                <div className="modal-row-item mb-0 py-1" >
                                                        <span className="modal-text me-3" style={{fontSize:"12px"}}>Risk Score: </span>
                                                        <span className="modal-text" style={{fontSize:"12px"}}>
                                                        {props?.expclickedNodeDoc?._source?.vulnerability_riskScore} 
                                                        </span>
                                                    </div>
                                                   
                                                <div className="d-flex flex-row justify-content-between">
                                                <div className="modal-row-item mb-0 py-1" >
                                                        <span className="modal-text me-3" style={{fontSize:"12px"}}>Vulnerability Since: </span>
                                                        <span className="modal-text" style={{fontSize:"12px"}}>
                                                          {unixTimestampToDate(parseInt(props?.expclickedNodeDoc?._source?.vulnerability_since))}
                                                        </span>
                                                    </div>     
                                                    <div className="d-flex justify-content-end mb-2">
                                                        <SearchBar data={softwareHostData} searchQuery={searchVulnerQuery} setSearchQuery={setSearchVulnerQuery} setFilteredListData={sethostData} type={"SoftHost"} />
                                                    </div>
                                                </div>
                                                    {isLoading ? (
                                                        <div className="col-12">
                                                            <TableLoader rowCount={25} />
                                                        </div>
                                                    ) : hostData?.length !== 0 ? (
                                                        <>
                                                        
                                                        <Table borderless hover style={styles.sidebarContentWrap}>
                                                            <thead className="fixed-head">
                                                                <tr>
                                                                    <th>Host Name</th>
                                                                    <th>Last Seen</th>

                                                                    {/* <th>Softwares Installed</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {hostData?.map((item) => (
                                                                    <tr style={{"height":"50px"}}>
                                                                        <td>
                                                                            {item?.key}
                                                                        </td>
                                                                        <td>
                                                                            {unixTimestampToDate(parseInt(item?.doc?.hits?.hits?.[0]?._source?.vulnerability_last_seen))}
                                                                        </td>
                                                                        {/* <td>
                                                                            {item?.software_count?.value}
                                                                        </td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        </>
                                                    ) : (
                                                        <NoDataFound errorText={"No data Found"} />
                                                    )}
                                                </div>}
                    {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                  </div>}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}