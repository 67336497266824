import React, { useEffect, useState } from 'react'
import {

    FormControl,
    InputGroup,
    Button

} from "react-bootstrap";
import './Search.css';
import useOnclickOutside from 'react-cool-onclickoutside';
import { X } from 'phosphor-react';
import { Link } from 'react-router-dom';



export default function Search({ changeFunction, globalSe, searchData }) {


    const [searchQuery, setSearchQuery] = useState('');
    const [previousSearches, setPreviousSearches] = useState([]);
    const [showRecentList, setShowRecentList] = useState(false);

    const ref = useOnclickOutside(() => {
        setShowRecentList(false);
    });

    useEffect(() => {
        const storedSearches = localStorage.getItem('recentSearches');
        if (storedSearches) {
            setPreviousSearches(JSON.parse(storedSearches));
        }
    }, [])

    useEffect(() => {

        setSearchQuery(searchData)
    }, [searchData])

    const handleClearClick = (e) => {
        setSearchQuery('');
        changeFunction('', e, 'click')
    };

    // const recentSearch = () => {
    //     if (searchQuery.trim() !== '') {
    //         if (!localStorage.getItem('recentSearches').includes(searchQuery)) {
    //             setPreviousSearches((prevSearches) => [searchQuery, ...prevSearches]);
    //             localStorage.setItem('recentSearches', JSON.stringify([searchQuery, ...previousSearches]));
    //             setSearchQuery('');
    //         }
    //     }
    // }

    const recentSearch = (e) => {
        if (e === "Enter" || e === undefined) {
            console.log(searchQuery)
            if (searchQuery?.trim() !== '') {
                const sortRecentListLength = [searchQuery, ...previousSearches];
                if (sortRecentListLength.length > 15) {
                    sortRecentListLength.splice(15, sortRecentListLength.length - 15);
                }
                if (!localStorage.getItem('recentSearches')?.includes(searchQuery)) {
                    setPreviousSearches(sortRecentListLength);
                    localStorage.setItem('recentSearches', JSON.stringify(sortRecentListLength));
                    // setSearchQuery('');
                    setShowRecentList(false)
                }
            }
        }
    };

    const handleOnSort = (value) => {
        let sortedList = JSON.parse(localStorage.getItem('recentSearches')).filter((item) => item !== value)
        setPreviousSearches([value, ...sortedList])
        setSearchQuery(value)
        localStorage.setItem('recentSearches', JSON.stringify([value, ...sortedList]));
    }

    const handleRemoveRecent = (revomeData) => {
        const storedSearches = JSON.parse(localStorage.getItem('recentSearches'));
        let index = storedSearches.indexOf(revomeData)
        if (index !== -1) {
            storedSearches.splice(index, 1);
            setPreviousSearches(storedSearches)
            setSearchQuery('')
            localStorage.setItem('recentSearches', JSON.stringify(storedSearches));
        }
    }

    const clearAllRecent = () => {
        setPreviousSearches([])
        localStorage.setItem('recentSearches', JSON.stringify([]));
    }

    const checkMatch = showRecentList ? previousSearches.filter((keyword) => keyword?.toLowerCase().includes(searchQuery?.toLowerCase())) : ''


    const getMatchingSearches = () => {
        return checkMatch;
    };

    return (

        <div style={{ position: "relative" }}>
            <InputGroup className="search-component">

                <FormControl
                    type="search"
                    className="me-2 custom-search-input"
                    placeholder="Search"
                    onClick={() => setShowRecentList(true)}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => { changeFunction(searchQuery, e, ''); recentSearch(e.code) }}
                    value={searchQuery}
                    id="search_field"
                    autoComplete='off'

                />
                {searchQuery &&
                    <span onClick={handleClearClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg custom-clear-button" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </span>
                }

                <InputGroup.Text className="bg-white  " style={{ marginLeft: "-9px", cursor: "pointer" }} >
                    <span onClick={(e) => { changeFunction(searchQuery, e, 'click'); recentSearch() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </span>
                </InputGroup.Text>
            </InputGroup>

            {checkMatch.length > 0 && (
                <div className='ignore-onclickoutside shadow-sm bg-white rounded-2 p-3 ' style={{ position: "absolute", width: "100%", zIndex: "9999", top: "40px", overflowX: "scroll", height: previousSearches.length > 6 ? "200px" : "auto" }} ref={ref}>
                    <div className='col-12 d-flex justify-content-between mb-2'>
                        <h6 className='m-0'>Recent Searches</h6>
                        {previousSearches.length > 1 && (
                            <div className="ml-2 text-right">
                                <Link onClick={() => clearAllRecent()}>
                                    Clear
                                </Link>
                            </div>
                        )}
                    </div>
                    <ul className='p-0 m-0'>
                        {getMatchingSearches().map((item) => (

                            <li className='more-table-field-container mt-0 px-1'
                                style={{ listStyle: "none", display: "flex", alignItems: "center", padding: "0.4rem 1.1rem", justifyContent: "space-between", cursor: "pointer" }}
                            >
                                <span onClick={(e) => { changeFunction(item, e, 'click'); handleOnSort(item) }}>
                                    {item}
                                </span>

                                {/* <div> */}
                                <X onClick={() => handleRemoveRecent(item)} size={14} color="#999999" />
                                {/* </div> */}
                            </li>
                        ))}
                    </ul>



                </div>
            )}

        </div>



    )
}
