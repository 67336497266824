import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./ComplianceOverview.styles";
import Dashboard from "./Dashboard/Dashboard";
import Alerts from "./Alerts/Alerts";
import Controlview from "./Controlview/Controlview";
import MainDashboard from "./MainDashboard/MainDashboard";

const ComplianceOverview = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [dateFilter, setDateFilter] = useState();

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  useEffect(() => {
    if (currentPath === "alerts") {
      setTitle("Compliance Alerts")
      setBreadcrumb({
        "module": "compliance",
        "path": [{ "title": "Compliance", "url": "/compliance/maindashboard" }, { "title": "Alerts", "url": "/compliance/alerts" },]

      });
    }
    else if (currentPath === "controlview") {
      setTitle("Compliance Control View")
      setBreadcrumb({
        "module": "compliance",
        "path": [{ "title": "Compliance", "url": "/compliance/maindashboard" }, { "title": "Control View", "url": "/compliance/controlview" },]
      });
    }
    else if (currentPath === "maindashboard") {
      setTitle("Dashboard")
      setBreadcrumb({
        "module": "compliance",
        "path": [{ "title": "Compliance", "url": "/compliance/maindashboard" }, { "title": "Dashboard", "url": "/compliance/maindashboard" },]
      });
    }

    else if (currentPath === "dashboard") {
      setTitle("Compliance Dashboard")
      setBreadcrumb({
        "module": "compliance",
        "path": [{ "title": "Compliance", "url": "/compliance/maindashboard" }, { "title": "Dashboards", "url": "/compliance/dashboard" },]

      });
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"compliance"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          globalRefresh={currentPath}
          isFilterEnabled={currentPath === "maindashboard"}
          setDateFilter={setDateFilter}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "dashboard" ? (
            <Dashboard />
          ) : currentPath === "alerts" ? (
            <Alerts selectedDateFilter={selectedDateFilter} />
          ) : currentPath === "maindashboard" ? (
            <MainDashboard selectedDateFilter={selectedDateFilter} />
          ) : currentPath === "controlview" ? (
            <Controlview selectedDateFilter={selectedDateFilter} />
          ) : currentPath === "dashboard" ? (
            <Dashboard selectedDateFilter={selectedDateFilter} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ComplianceOverview;
