import { CaretRight, CaretUp, DotsNine, GearSix, House, MagnifyingGlass } from "phosphor-react";
import React, { forwardRef, useEffect, useMemo, useState, Input } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./Topbar.styles";
import { CaretDown, Info } from "phosphor-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { State } from "../../Context";
import useOnclickOutside from "react-cool-onclickoutside";
import userIcon from "../../assets/images/user-icon.png";
import SearchBar from "../SearchBar/SearchBar";
import SearchModal from "../CustomModal/SearchModal";
import investigateIcon from "../../assets/icons/investigateIcon.png";
import hunterIcon from "../../assets/icons/hunter.png";
import complianceIcon from "../../assets/icons/compliance.png";
import ruleStudioIcon from "../../assets/icons/rule-studio.png";
import analyticsIcon from "../../assets/icons/analytics.png";
import uebaIcon from "../../assets/icons/ueba.png";
import tiIcon from "../../assets/icons/threat-inteligence.png";
import managementDashboardIcon from "../../assets/icons/management-dashboard.png";
import settingsIcon from "../../assets/icons/settings.png";
import caseManagementIcon from "../../assets/icons/case-management.png";
import threatLandscapeIcon from "../../assets/icons/warning.png"
import { Button, Badge, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import TiGlobalSearch from "../TI/TiGlobalSearch";
import Search from "../Search/Search";
import SearchTi from "../Search/SearchTi";
import { Amplify, Auth } from "aws-amplify";
import { fget, fgetC, fgetCy, fpostC, fpostCy, fpost } from "../../axios/axios";
import globalStyles from "../../constants/globalStyles";
import { toast } from "react-toastify";
import CustomModal from "../CustomModal/CustomModal";
import ChangePassword from "../ChangePassword/ChangePassword";
import FilterListDate from '../FilterListDate/FilterListDate';
import AddFilterTimeFrame from '../AddFilterTimeframe/AddFilterTimeframe';
import CustomToolTip from '../ToolTip/CustomToolTip';
import moment from "moment";



const hostname = window.location.hostname



if (
  hostname.endsWith('.active-bytes.com') ||
  hostname === 'active-bytes.com' ||
  hostname === 'localhost'
) {
  var amplifyConfig = {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
    },
  }
}
else {
  var amplifyConfig = {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,

        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    },
  }
}

Amplify.configure(amplifyConfig);
const Topbar = ({
  title,
  ti,
  inv,
  breadcrumb,
  currentUrl,
  module,
  selectedDateFilter,
  isNewFilterEnabled,
  setSelectedDateFilter,
  isFilterEnabled,
  activeTab,
  setActiveTab,
  setSearchVal,
  searchVal,
  globalSearch,
  setGlobalSearch,
  globalSearchEnabled,
  searchValCom,
  setSearchValCom,
  vulnerPage,
  setVulnerPage,
  vulnerExPage,
  setVulnerExPage,
  securityPage,
  setSecurityPage,
  headlinesPage,
  setHeadlinesPage,
  comPage,
  setComPage,
  setIsGlobalSearchClear,
  isGlobalSearchClear,
  sevChange,
  setSevChange,
  setIsApplyGlobalSearch,
  globalRefresh,
  filter,
  setFilter,
  dateFilter,
  setDateFilter,
  setShowAddFilter,
  showAddFilter,
  tab

}) => {
  const [profileLetter, setProfileLetter] = useState("");

  const [token, setToken] = useState(localStorage.getItem("auth"));
  const [showSwitchModule, setShowSwitchModule] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { setIsLoggedIn } = useContext(State);
  const [show] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const [accessModule, setAccessModule] = useState([])

  const [cybotStatus, setCybotStatus] = useState(false)
  const [datalakeStatus, setDataLakeStatus] = useState(false)
  const [statusLoader, setStatusLoader] = useState(true)
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [showChangePass, setShowChangePass] = useState(false);
  const [analyticsUrl, setAnalyticsUrl] = useState(localStorage.getItem("analytics-ui-url"))
  const analyticsMode = localStorage.getItem("ANALYTICS_MODE")
  const isOnPrem = localStorage.getItem("is_onprem", false)
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  const ref = useOnclickOutside(() => {
    setShowSwitchModule(false);
    setShowDatePicker(false);
    setShowProfileMenu(false);
  });
  const navigate = useNavigate();
  // manage column
  const [showManageColumn, setShowManageColumn] = useState(false);


  const handleStartDateChange = (date) => {
    
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }

  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSetCustomFilter = () => {
    let storedFilter = JSON.parse(localStorage.getItem("filters"));
    
    const filterStartDate = moment(startDate, "ddd MMM DD YYYY HH:mm:ss [GMT]Z (India Standard Time)");
    const filterEndDate = moment(endDate, "ddd MMM DD YYYY HH:mm:ss [GMT]Z (India Standard Time)");
    const formattedStartDate = filterStartDate.format("yyyy-MM-DDTHH:mm");
    const formattedEndDate = filterEndDate.format("yyyy-MM-DDTHH:mm");
    console.log('startDatestartDatestartDatestartDatestartDate !!! :', formattedStartDate)
    console.log("start7")
    
    localStorage.setItem("gte", formattedStartDate);
    localStorage.setItem("lte", formattedEndDate);
    setSelectedDateFilter(`custom-${formattedStartDate}-endDate-${formattedEndDate}`);
    localStorage.setItem("filter", `custom-${formattedStartDate}-endDate-${formattedEndDate}`);
    let dateTemp = {
      from: formattedStartDate,
      to: formattedEndDate,
      f: formattedStartDate,
      e: formattedEndDate,
    };

    console.log('TEMMMP Data :', dateTemp)
    setDateFilter(dateTemp);
    let temp2 = {
      ...storedFilter,
      ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
        ...acc,
        [currModule]: {
          ...storedFilter?.[currModule],
          ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
        }
      }), {}) : {
        [module]: {
          ...storedFilter?.[module],
          ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
        }
      })
    };
    localStorage.setItem("isCustomTrend", true);
    localStorage.setItem("filters", JSON.stringify(temp2));

  }


  useEffect(() => {
    const refreshTokensInterval = setInterval(async () => {
      if (isOnPrem) {
        refreshSsoToken()
      } else {
        const session = await Auth.currentSession({ bypassCache: true });

        localStorage.setItem("auth", session.getIdToken().getJwtToken());
        if (session.getIdToken().getJwtToken()) {
          localStorage.setItem("auth", session.getIdToken().getJwtToken());
        }
      }
      // Get the current session and refresh the tokens if they are expired.

    }, 60000);

    return () => clearInterval(refreshTokensInterval);
  }, [])
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location?.pathname?.includes("investigate/grouped_investigation")) {
      localStorage.setItem("historyPreviousPage", JSON.stringify({ "pathname": location.pathname, "params": params }));
    } else if (location?.pathname?.includes("investigate/summary")) {
      localStorage.setItem("historyPreviousPage", JSON.stringify({ "pathname": location.pathname, "params": params }));
    }
  }, [location, params])

  const refreshSsoToken = async () => {
    var refresh = localStorage.getItem("refresh")
    try {
      let data = {
        "refresh_token": refresh
      };

      await fpost(
        `${apiBaseUrl}/refresh`,
        data
      ).then((res) => {
        if (res?.data?.access_token !== undefined) {
          localStorage.setItem("auth", res?.data?.access_token);
        }
      })

    } catch (error) {
      console.log("refresh error", error);
    }

  }

  useEffect(() => {
    // checking expiration time
    if (token) {
      const tokenSplit = token.split(".");
      const payload = JSON.parse(atob(tokenSplit[1]));
      const exptime = payload.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (exptime < currentTime) {
        toast.error("Session Expired", {
          position: "bottom-right",
        });
        setTimeout(() => {
          logout();
          window.location.replace = "/"
        }, 2000)
      }
    } else {
      var auth_token = localStorage.getItem("auth")
      if (!auth_token) {
        toast.error("Session Expired", {
          position: "bottom-right",
        });
        setTimeout(() => {
          logout();
          window.location.replace = "/"
        }, 2000)

      }
    }
  }, [token]);

  useEffect(() => {
    let loggedUserName = localStorage.getItem("loggedUser").split(" ");

    let userProfileLetter = "";
    if (loggedUserName[1]) {
      userProfileLetter = `${loggedUserName[0].charAt(
        0
      )} ${loggedUserName[1].charAt(0)}`;
    } else {
      userProfileLetter = loggedUserName[0].charAt(0);
      // item.profileLet = userProfileletter;
    }
    // setProfileLetter(userProfileLetter)
    localStorage.setItem("userLetter", userProfileLetter);
  }, []);

  const handleLogout = () => {
    setToken(null);
  };
  // useEffect(() => {
  //   setDateFunction();
  // }, [selectedDateFilter]);



  const today = moment().format("yyyy-MM-DDTHH:mm");
  const setDateFunction = (value) => {
    let storedFilter = JSON.parse(localStorage.getItem("filters"));
    let selectedDate;
    let temp = [];
    
    setEDate(today)
    if (value === '5 minutes' || value === '1 hour') {

      switch (value) {
        case '5 minutes':
          selectedDate = 5;
          break;
        case '1 hour':
          selectedDate = 60;
          break;
        default:
      }

      const lastNumberDate = moment()
        .subtract(selectedDate, "minutes")
        ?.format("yyyy-MM-DDTHH:mm");
      setSDate(lastNumberDate);


      let dateTemp = {
        from: lastNumberDate,
        to: today,
        f: lastNumberDate,
        e: today,
        range: selectedDate,
      };

      console.log('CHEEEEEEEEEEEEEEk :', dateTemp)
      setDateFilter(dateTemp);

      let temp2 = {
        ...storedFilter,
        ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
          ...acc,
          [currModule]: {
            ...storedFilter?.[currModule],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        }), {}) : {
          [module]: {
            ...storedFilter?.[module],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        })
      };


      localStorage.setItem("filters", JSON.stringify(temp2));

      console.log('GTEEE NUM :', lastNumberDate)
      console.log('LTEEEE NUM :', today)
      console.log("start6")

      localStorage.setItem("gte", lastNumberDate);
      localStorage.setItem("lte", today);
      localStorage.setItem("filter", value);

    } else if (value === '30 minutes') {
      switch (value) {
        case '30 minutes':
          selectedDate = 1800;
          break;
        default:
      }

      const lastNumberDate = moment()
        .subtract(selectedDate, "seconds")
        ?.format("yyyy-MM-DDTHH:mm");
      setSDate(lastNumberDate);

      let dateTemp = {
        from: lastNumberDate,
        to: today,
        f: lastNumberDate,
        e: today,
        range: selectedDate,
      };
      setDateFilter(dateTemp);
      let temp2 = {
        ...storedFilter,
        ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
          ...acc,
          [currModule]: {
            ...storedFilter?.[currModule],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        }), {}) : {
          [module]: {
            ...storedFilter?.[module],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        })
      };


      localStorage.setItem("filters", JSON.stringify(temp2));
      console.log("start5")

      localStorage.setItem("gte", lastNumberDate);
      localStorage.setItem("lte", today);
      localStorage.setItem("filter", value);
    } else if (value === '6 hour') {
      switch (value) {
        case '6 hour':
          selectedDate = 6;
          break;
        default:
      }

      const lastNumberDate = moment()
        .subtract(selectedDate, "hours")
        ?.format("yyyy-MM-DDTHH:mm");
      setSDate(lastNumberDate);

      let dateTemp = {
        from: lastNumberDate,
        to: today,
        f: lastNumberDate,
        e: today,
        range: selectedDate,
      };
      setDateFilter(dateTemp);
      let temp2 = {
        ...storedFilter,
        ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
          ...acc,
          [currModule]: {
            ...storedFilter?.[currModule],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        }), {}) : {
          [module]: {
            ...storedFilter?.[module],
            ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
          }
        })
      };


      localStorage.setItem("filters", JSON.stringify(temp2));
      console.log("start4")

      localStorage.setItem("gte", lastNumberDate);
      localStorage.setItem("lte", today);
      localStorage.setItem("filter", value);
    } else {
      let lastNumberDate;
      let dateTemp = {}
      let temp2 = {}
      switch (value) {
        case '':
          selectedDate = 0;
          break;
        case "1 Day":
          selectedDate = 1;
          lastNumberDate = moment()
            .subtract(selectedDate, "days")
            ?.format("yyyy-MM-DDTHH:mm");
          setSDate(lastNumberDate);

          dateTemp = {
            from: lastNumberDate,
            to: today,
            f: lastNumberDate,
            e: today,
            range: selectedDate,
          };
          setDateFilter(dateTemp);
          temp2 = {
            ...storedFilter,
            ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
              ...acc,
              [currModule]: {
                ...storedFilter?.[currModule],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            }), {}) : {
              [module]: {
                ...storedFilter?.[module],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            })
          };

          localStorage.setItem("filters", JSON.stringify(temp2));
          break;
        case "7 Days":
          selectedDate = 7;
          lastNumberDate = moment()
            .subtract(selectedDate, "days")
            ?.format("yyyy-MM-DDTHH:mm");
          setSDate(lastNumberDate);

          dateTemp = {
            from: lastNumberDate,
            to: today,
            f: lastNumberDate,
            e: today,
            range: selectedDate,
          };
          setDateFilter(dateTemp);
          temp2 = {
            ...storedFilter,
            ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
              ...acc,
              [currModule]: {
                ...storedFilter?.[currModule],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            }), {}) : {
              [module]: {
                ...storedFilter?.[module],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            })
          };


          localStorage.setItem("filters", JSON.stringify(temp2));
          break;
        case "30 Days":
          selectedDate = 30;
          lastNumberDate = moment()
            .subtract(selectedDate, "days")
            ?.format("yyyy-MM-DDTHH:mm");
          setSDate(lastNumberDate);

          dateTemp = {
            from: lastNumberDate,
            to: today,
            f: lastNumberDate,
            e: today,
            range: selectedDate,
          };
          setDateFilter(dateTemp);
          temp2 = {
            ...storedFilter,
            ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
              ...acc,
              [currModule]: {
                ...storedFilter?.[currModule],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            }), {}) : {
              [module]: {
                ...storedFilter?.[module],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            })
          };


          localStorage.setItem("filters", JSON.stringify(temp2));
          break;
        case "1 Year":
          selectedDate = 365;
          lastNumberDate = moment()
            .subtract(selectedDate, "days")
            ?.format("yyyy-MM-DDTHH:mm");
          setSDate(lastNumberDate);

          dateTemp = {
            from: lastNumberDate,
            to: today,
            f: lastNumberDate,
            e: today,
            range: selectedDate,
          };
          setDateFilter(dateTemp);
          temp2 = {
            ...storedFilter,
            ...(Array.isArray(module) ? module.reduce((acc, currModule) => ({
              ...acc,
              [currModule]: {
                ...storedFilter?.[currModule],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            }), {}) : {
              [module]: {
                ...storedFilter?.[module],
                ...(Array.isArray(tab) ? tab.reduce((acc, currTab) => ({ ...acc, [currTab]: { date: dateTemp, fields: [] } }), {}) : { [tab]: { date: dateTemp, fields: [] } })
              }
            })
          };


          localStorage.setItem("filters", JSON.stringify(temp2));
          break;
        default:
      }
      
      let startD;
      let endD;
      let current;
      
      if (module === "ueba") {
        current = moment();
        if (selectedDate===undefined&&selectedDateFilter?.includes("custom")){
          startD = moment(localStorage.getItem("gte"));
          setSDate(startD.format("YYYY-MM-DDT HH:mm"));
          endD = moment(localStorage.getItem("lte"));
          setEDate(endD.format("YYYY-MM-DDT HH:mm"));
          setStartDate(moment(startD).format("yyyy-MM-DD HH:mm"));
          setEndDate(moment(endD).format("yyyy-MM-DD HH:mm"));
          
        }
        else{
          startD = moment(current).subtract(selectedDate, 'days').format("YYYY-MM-DDTHH:mm");
          setSDate(startD);
          endD = current.format("YYYY-MM-DDTHH:mm");
          setEDate(endD);
        }

        
      }
      else {
        if (selectedDate===undefined&&selectedDateFilter?.includes("custom")){
          startD = moment(localStorage.getItem("gte"))
          setSDate(startD.toISOString());
          endD = moment(localStorage.getItem("lte"))
          setEDate(endD.toISOString());
          setStartDate(moment(startD).format("yyyy-MM-DD HH:mm"));
          setEndDate(moment(endD).format("yyyy-MM-DD HH:mm"));
          
        }
        else{
        current = new Date();
        startD = new Date(
          current.getTime() - selectedDate * 24 * 60 * 60 * 1000
        )
          .toISOString()

        setSDate(startD);
        endD = current.toISOString()
        setEDate(endD);
        localStorage.setItem("isCustomTrend", false);
      }
      }

      console.log("start3")
      
      localStorage.setItem("gte", startD);
      localStorage.setItem("lte", endD);
      localStorage.setItem("filter", value);
    }

  };


  const handleDatePicker = (value) => {
    setSelectedDateFilter(value);
    setShowDatePicker(false);
    setDateFunction(value);
    if (location.pathname.startsWith('/ti/')) {
      setComPage(1);
      setVulnerPage(1);
      setHeadlinesPage(1);
      setVulnerExPage(1);
      setSecurityPage(1);
    }
  };

  let baseURL = localStorage.getItem("CYBOT_URL")
  const statusCheck = async () => {
    setStatusLoader(true)
    await fgetC(`${baseURL}/cybot_proxy/_cat`).then((res) => {

      if (res?.status === 200) {
        setCybotStatus(true)
      } else {
        setCybotStatus(false)
      }
    }).catch((err) => {
      setCybotStatus(false)
      setStatusLoader(false)

      console.log("Error", err)
    })
    await fgetCy(
      `${localStorage.getItem(
        "DATALAKE_URL"
      )}/_cat`
    ).then((res) => {
      if (res?.status === 200) {
        setDataLakeStatus(true)
      } else {
        setDataLakeStatus(false)
      }
      setStatusLoader(false)

    }).catch((err) => {
      setDataLakeStatus(false)
      setStatusLoader(false)

      console.log("Error", err)
    })
  }

  useEffect(() => {
    let checkStatus;
    if (location.pathname.endsWith('/hunter/cykit') || location.pathname.endsWith('/hunter/cykit/')) {
      setTimeout(() => {
        statusCheck();
        checkStatus = setInterval(statusCheck, 3 * 60000);
      }, 600000); // Delay first execution by 10 minutes
      setCybotStatus(true)

      setDataLakeStatus(true)
      setStatusLoader(false)
      // Set your states here
    } else {
      statusCheck(); // Execute immediately
      checkStatus = setInterval(statusCheck, 3 * 60000);
    }

    return () => clearInterval(checkStatus);
  }, [])

  const logout = async () => {
    localStorage.removeItem("auth");
    setIsLoggedIn(false);
    // localStorage.removeItem("CYBOT_ACCESS_TOKEN");
    // localStorage.removeItem("DATALAKE_ACCESS_TOKEN");
    // localStorage.removeItem("CYBOT_URL");
    // localStorage.removeItem("DATALAKE_URL");
    // localStorage.removeItem("DATALAKE_URL");
    let notRemove = [
      "investigateCheckedTableColumns",
      "ManageColumn.investigate.cykit.firewall",
      "ManageColumn.investigate.cykit.ad",
      "ManageColumn.investigate.cykit.email",
      "ManageColumn.investigate.cykit.waf",
      "ManageColumn.investigate.cykit.office.ad",
      "ManageColumn.investigate.cykit.office.oneDrive",
      "ManageColumn.investigate.cykit.office.exchange",
      "filters",
      "filter",
      "lte",
      "gte",
      "recentSearches",
      "activeTab.investigate",
      "ManageColumn.ueba"
    ]
    await Object.keys(localStorage).forEach((key) => {
      if (!notRemove?.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    // localStorage.clear();
    // navigate("/", { replace: true });
    window.location.href = "/";
    window.location.reload(true);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref} style={{ cursor: "pointer" }} key={"topbar_input_" + value}>
      Custom
    </div>
  ));

  const handleSearch = (val, e, action) => {
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {

      setGlobalSearch(val);


      if (val != "") {
        setCustomShow(true)
      }
    }
  }



  //   }
  useEffect(() => {
    permissions();
  }, []);
  const permissions = async () => {
    let accessVars = JSON.parse(localStorage.getItem("accessVars"));
    if (accessVars) {
      setAccessModule(accessVars.modules)
    }
    else {
      let accessCheckData = await fget(
        `${process.env.REACT_APP_SSO_URL_V2}/core/userpermissions`
      )
      localStorage.setItem("accessVars", JSON.stringify(accessCheckData.data))
      setAccessModule(accessCheckData.data.modules)
    }
  }

  const refFilter = useOnclickOutside(() => {
    setShowAddFilter(false);
    setShowManageColumn(false)

  });
  console.log("filter", filter);
  console.log("dateFilter!", dateFilter);


  // default filter

  // useEffect(() => {
  //   const filters = window.localStorage.getItem('filters');
  //   if (filters !== null) {
  //     alert("Enter");
  //     console.log("Filters from localStorage:", filters);
  //   }
  // }, []);

  useEffect(() => {
    const filters = window.localStorage.getItem('filters');

    if (filters === null) {
      if (selectedDateFilter === '1 Day') {
        setDateFunction('1 Day')
      } else if (selectedDateFilter === '7 Days') {
        setDateFunction('7 Days')
      } else if (selectedDateFilter === '30 Days') {
        setDateFunction('30 Days')
      } else if (selectedDateFilter === '1 Year') {
        setDateFunction('1 Year')
      } else if (selectedDateFilter === '5 minutes') {
        setDateFunction('5 minutes')
      } else if (selectedDateFilter === '30 minutes') {
        setDateFunction('30 minutes')
      } else if (selectedDateFilter === '1 hour') {
        setDateFunction('1 hour')
      } else if (selectedDateFilter === '6 hour') {
        setDateFunction('6 hour')
      } else if(selectedDateFilter?.includes('custom')){
        
        setDateFunction('custom')
        
      }else {
        setDateFunction('7 Days')
      }
    }
    else if(selectedDateFilter?.includes("custom")){
      setDateFunction('custom')
    }

  }, [])

  return (
    <div
      className="col-12 d-flex align-items-center px-4 rounded-3 "
      style={{
        height: "5rem",
        backgroundColor: "white",
      }}
    >
      {/* <h5 className="m-0 text-uppercase">
        {module === "settings"
          ? title ? title : currentUrl.split("/")[1]
          : `${title ? title : currentUrl.split("/")[1]} - ${currentUrl.split("/")[2]}`}
      </h5> */}

      {/* <h5 className="m-0">
        {title ? title : `${title ? title : currentUrl.split("/")[1]} - ${currentUrl.split("/")[2]}`}
      </h5> */}
      <div className="d-flex gap-2 breadcrumb-wrap align-items-center">
        <Link
          to={"/"}
          onClick={() => window.location.href = "/"}
        >
          <span>
            <House size={18} />
          </span>
        </Link>
        <CaretRight />
        {breadcrumb?.path?.map((item, index) => (
          <>
            <Link to={item.url}
              onClick={() => window.location.href = item.url}
              key={"breadcrumb_item_" + index}
            >
              <span>{item.title}</span>
            </Link>

            {index < breadcrumb.path.length - 1 && <CaretRight />}
          </>
        ))}
      </div>


      <div
        className="d-flex align-items-center gap-3"
        style={{
          marginLeft: "auto",

        }}
      >


        {/* refresh button */}

        {/* {globalRefresh !== 'controlview' && */}
        <Button variant="light" onClick={() => window.location.reload()}>
          Refresh
        </Button>
        {/* } */}

        <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Tooltip
              contentStyle={{ backgroundColor: 'light' }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex gap-2">
                  <span><strong>Server Health</strong></span>
                </div>
                <div className="d-flex gap-2">
                  <span>Cybot</span>
                  <span style={{ color: cybotStatus ? globalStyles.green : globalStyles.red }}>{cybotStatus ? "Good" : "Down"}</span>
                </div>
                <div className="d-flex gap-2">
                  <span>DataLake</span>
                  <span style={{ color: datalakeStatus ? globalStyles.green : globalStyles.red }}>{datalakeStatus ? "Good" : "Down"}</span>
                </div>
              </div>
            </Tooltip>

          }
        >
          <span className={statusLoader ? "badge badge-pill badge-light text-muted border rounded-pill p-1" : "badge badge-pill badge-light text-muted border rounded-pill ps-2 pe-3 p-1 d-flex align-items-center justify-content-center"}>
            {<><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill={(cybotStatus && datalakeStatus) ? "#1de24e" : "#f20202"} viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>{(cybotStatus && datalakeStatus) ? "Active" : "Down"}</>}
          </span>
        </OverlayTrigger>
        {console.log(inv, title)}


        {/* {inv && title === "Investigation Dashboard" ? 
            <div className="position-relative">
      
        <div className='d-flex gap-2 justify-content-between align-items-center'>
          <div className='d-flex align-items-center gap-2'>
            <FilterListDate
              filter={filter}
              setFilter={setFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              // getFilteredTable={getFilteredTable}
              module={"investigate"}
              tab={"dashboard"}
            />
           
            <div>
              <Button
                className="position-relative"
                ref={refFilter}
                onClick={() => setShowAddFilter(!showAddFilter)}
              >
                Filter
              </Button>
              {showAddFilter && <AddFilterTimeFrame
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setShowAddFilter={setShowAddFilter}
                columnValues={[
                ]}
                module={"investigate"}
                tab={"dashboard"}
              />}
            </div>
          </div>
        </div>
     

      </div>
          // )
          :""
        } */}


        {console.log('SELECETED DATE ISSS :', selectedDateFilter)}
        {
          isFilterEnabled && (
            <div className="position-relative">
              <div
                className="rounded-2 px-3 py-1"
                style={styles.dateSwitcherWrap}
                ref={ref}
                onClick={() => {
                  setShowDatePicker(!showDatePicker);
                  setShowSwitchModule(false);
                  setShowProfileMenu(false);
                }}
              >
                {selectedDateFilter !== "1 Day" &&
                  selectedDateFilter !== "7 Days" &&
                  selectedDateFilter !== "30 Days" &&
                  selectedDateFilter !== "1 Year" &&
                  selectedDateFilter !== "5 minutes" &&
                  selectedDateFilter !== "30 minutes" &&
                  selectedDateFilter !== "1 hour" &&
                  selectedDateFilter !== "6 hour"
                  ? `${moment(startDate).format('dddd MMMM DD YYYY HH:mm')} - ${moment(endDate).format('dddd MMMM DD YYYY HH:mm')}`
                  : selectedDateFilter}
                {showDatePicker ? (
                  <CaretUp
                    color="black"
                    size={16}
                    style={{ marginLeft: "10px" }}
                  />
                ) : (
                  <CaretDown
                    color="black"
                    size={16}
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </div>

            {showDatePicker && (
                <div
                  className="position-absolute rounded-3 d-flex flex-wrap gap-3 mt-3 shadow ignore-onclickoutside"
                  style={styles.dateSwitcher}
                >
                  {/* <div className="d-flex">
                    <div
                      onClick={() => handleDatePicker("1 Day")}
                      style={{ cursor: "pointer", flex: 1, textAlign: 'center' }}
                    >
                      1 Day
                    </div>

                    <div
                      onClick={() => handleDatePicker("7 Days")}
                      style={{ cursor: "pointer", flex: 1, textAlign: 'center' }}
                    >
                      7 Days
                    </div>
                  </div>


                  <div className="d-flex">
                    <div
                      onClick={() => handleDatePicker("30 Days")}
                      style={{ cursor: "pointer", flex: 1, textAlign: 'center' }}
                    >
                      30 Days
                    </div>

                    <div
                      onClick={() => handleDatePicker("1 Year")}
                      style={{ cursor: "pointer", flex: 1, textAlign: 'center' }}
                    >
                      1 Year
                    </div>
                  </div> */}

                  <div className="d-flex flex-column mb-3">
                    <div className="d-flex flex-row gap-2 text-center mb-2">
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '5 minutes' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("5 minutes")}
                      >
                        5 minutes
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '30 minutes' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("30 minutes")}
                      >
                        30 minutes
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '1 hour' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("1 hour")}
                      >
                        1 hour
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '6 hour' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("6 hour")}
                      >
                        6 hour
                      </span>
                    </div>

                    <div className="d-flex flex-row gap-2 text-center mb-2">
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '1 Day' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("1 Day")}
                      >
                        1 Day
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === '7 Days' ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("7 Days")}
                      >
                        7 Days
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === "30 Days" ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("30 Days")}
                      >
                        30 Days
                      </span>
                      <span
                        className="flex-fill rounded-2 bg-grey px-2 py-1 cursor-pointer filter-tag"
                        style={{
                          backgroundColor: selectedDateFilter === "1 Year" ? "grey" : "",
                        }}
                        onClick={() => handleDatePicker("1 Year")}
                      >
                        1 Year
                      </span>
                    </div></div>

                  {/* old working */}

                  {/* <DatePicker
                    selected={new Date()}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setStartDate(start);
                      setEndDate(end);
                      setSelectedDateFilter(`${start} - ${end}`);
                      if (end) setShowDatePicker(false);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    // inline
                    customInput={<CustomInput />}
                  // withPortal
                  /> */}

                  <div className="d-flex flex-column mb-3">
                    <h6>Timeframe</h6>

                    <div className="d-flex gap-3">
                      <div>
                        <label>Start Date:</label>
                        <DatePicker
                          selected={new Date(startDate)}
                          onChange={handleStartDateChange}
                          selectsStart
                          startDate={new Date(startDate)}
                          endDate={new Date(endDate)}
                          dateFormat="yyyy-MM-dd HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          placeholderText="Start Date"
                        />
                      </div>

                      <div>
                        <label>End Date:</label>
                        <DatePicker
                          selected={new Date(endDate)}
                          onChange={handleEndDateChange}
                          selectsEnd
                          startDate={new Date(startDate)}
                          endDate={new Date(endDate)}
                          minDate={new Date(startDate)}
                          dateFormat="yyyy-MM-dd HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          placeholderText="End Date"
                        />
                      </div>
                    </div>

                    <Button style={{ marginTop: '10px' }} onClick={handleSetCustomFilter}>Apply</Button>


                    {/* <div className="d-flex flex-row gap-3 form-group">

                      <DatePicker
                        showTimeSelect
                        className="form-control"
                        placeholderText="dd/mm/yyyy,--:--"
                        minDate={new Date(0, 0, 0, 12, 30)}
                        maxDate={new Date()}
                        // minTime={new Date(0, 0, 0, 0, 0)}
                        // maxTime={new Date(0, 0, 0, 23, 59)}
                        timeIntervals={10}
                        selected={sDate ? new Date(sDate) : ""}
                        onChange={(e) => {
                          console.log(e);
                          setStartDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                          setSDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                        }}
                        dateFormat="MMMM d, yyyy h:mmaa"
                      />

                      <DatePicker
                        showTimeSelect
                        className="form-control"
                        placeholderText="dd/mm/yyyy,--:--"
                        // minTime={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
                        // maxTime={new Date()}
                        minDate={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
                        maxDate={new Date()}
                        timeIntervals={10}

                        // startDate={sDate ? new Date(sDate) : new Date(0, 0, 0, 12, 30)}
                        selected={eDate ? new Date(eDate) : ""}
                        onChange={(e) => {
                          console.log(e);
                          setEndDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                          setEDate(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                        }}
                        dateFormat="MMMM d, yyyy h:mmaa"
                      />
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          )
        }

        {/* {ti && title === "Threat Events" ?
          <div
            className="d-flex align-items-center gap-3 position-relative"
            style={{
              marginLeft: "35%",
            }}>
            <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
              <button className="btn btn-primary" >Global Search</button>
            </Link>
          </div>
          : ""} */}
        {/* {ti && title === "Threat Events" || title === "Indicators and Feeds" ? */}

        {
          globalSearchEnabled === true ?
            <div className="position-relative">
              <SearchTi changeFunction={handleSearch} searchData={globalSearch} setIsGlobalSearchClear={setIsGlobalSearchClear} isGlobalSearch={true} />
              {/* <Search changeFunction={handleSearch} /> */}

              {/* <MagnifyingGlass
              style={{ fontSize: "24px" }}
              onClick={() => handleCustom(true)}
              ref={ref}
              type="button"
            /> */}

            </div>
            : ""
        }
        {
          globalSearchEnabled === true ?
            <div className="position-relative">
              <CustomToolTip
                content={<Info size={20} />}
                dataPlacement={"bottom"}
                dataToggle={
                  <span>Enter relevant keywords to fetch results from the Threat Events & Feeds, results will display if the keywords are matched</span>
                }
              />
            </div> : ""
        }



        <div className="position-relative">
          <DotsNine
            style={{ fontSize: "24px" }}
            onClick={() => setShowSwitchModule(!showSwitchModule)}
            ref={ref}
            type="button"
          />
          {showSwitchModule && (
            <div
              className="position-absolute rounded-3 d-flex flex-wrap gap-3 mt-3 shadow topbar-appswitcher ignore-onclickoutside"
              style={styles.appSwitcherMenu}
              onClick={() => {
                setShowSwitchModule(!showSwitchModule);
                setShowProfileMenu(false);
                setShowDatePicker(false);
              }}
            >
              {(accessModule?.ueba?.read === "true" || accessModule?.ueba?.read === true) && <Link
                className="rounded-3"
                style={styles.menuItem}
                to="/ueba/dashboard"
                onClick={() => window.location.href = "/ueba/dashboard"}

              >
                <img src={uebaIcon} className="px-2" style={styles.tileImage} />
                <span>UEBA</span>
              </Link>}
              {(accessModule?.ti?.read === "true" || accessModule?.ti?.read === true) && <Link
                className="rounded-3"
                style={styles.menuItem}
                to="/ti/activebytes"
                onClick={() => window.location.href = "/ti/activebytes"}

              >
                <img src={tiIcon} className="px-2" style={styles.tileImage} />
                <span>Threat Intelligence</span>
              </Link>}
              {(accessModule?.investigate?.read === "true" || accessModule?.investigate?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to="/investigate/dashboard"
                  onClick={() => window.location.href = "/investigate/dashboard"}
                >
                  <img
                    src={investigateIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Investigate</span>
                </Link>}
              {(accessModule?.hunter?.read === "true" || accessModule?.hunter?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to="/hunter/detections"
                  onClick={() => window.location.href = "/hunter/detections"}
                >
                  <img
                    src={hunterIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Hunter</span>
                </Link>}
              {(accessModule?.analytics?.read === "true" || accessModule?.analytics?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to={analyticsMode === "tab" ? "" : "/analytics/securityanalytics"}
                  onClick={() => analyticsMode === "tab" ? window.open(analyticsUrl, "_blank") : window.location.href = "/analytics/securityanalytics"}
                >
                  <img
                    src={analyticsIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Analytics</span>
                </Link>}
              {(accessModule?.compliance?.read === "true" || accessModule?.compliance?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to="/compliance/maindashboard"
                  onClick={() => window.location.href("/compliance/maindashboard")}
                // to="/compliance/controlview"
                // onClick={() => window.location.href = "/compliance/controlview"}

                >
                  <img
                    src={complianceIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Compliance</span>
                </Link>}
              {(accessModule?.rulesstudio?.read === "true" || accessModule?.rulesstudio?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to="/rs/investigation"
                  onClick={() => window.location.href = "/rs/investigation"}
                >
                  <img
                    src={ruleStudioIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Orchestrator</span>
                </Link>}
              {(accessModule?.management_dash?.read === "true" || accessModule?.management_dash?.read === true) && <Link
                className="rounded-3"
                style={styles.menuItem}
                to="/management/dashboard"
                onClick={() => window.location.href = "/management/dashboard"}
              >
                <img
                  src={managementDashboardIcon}
                  className="px-2"
                  style={styles.tileImage}
                />
                <span>Management Dashboard</span>
              </Link>}

              {(accessModule?.["casemgmt"]?.read === "true" || accessModule?.["casemgmt"]?.read === true) && <Link
                className="rounded-3"
                style={styles.menuItem}
                to="/case/management"
                onClick={() => window.location.href = "/case/management"}
              >
                <img
                  src={caseManagementIcon}
                  className="px-2"
                  style={styles.tileImage}
                />
                <span>Case Management</span>
              </Link>}

              {(accessModule?.threat_landscape?.read === "true" || accessModule?.threat_landscape?.read === true) &&
                <Link
                  className="rounded-3"
                  style={styles.menuItem}
                  to="/threat_landscape/dashboard"
                  onClick={() => window.location.href = "/threat_landscape/dashboard"}
                >
                  <img
                    src={threatLandscapeIcon}
                    className="px-2"
                    style={styles.tileImage}
                  />
                  <span>Threat Landscape</span>
                </Link>
              }

              <Link
                className="rounded-3"
                style={styles.menuItem}
                to="/settings/authentication"
                onClick={() => window.location.href = "/settings/authentication"}
              >
                <img
                  src={settingsIcon}
                  className="px-2"
                  style={styles.tileImage}
                />
                <span>Settings</span>
              </Link>
            </div>
          )}
        </div>

        <div>
          {/* <SearchBar  style={{
      position:"relative",
      marginRight:"20px"
        }} >
      
     </SearchBar> */}
        </div>

        <div className="position-relative">
          <div
            className="d-flex flex-row align-items-center gap-2 cursor-pointer"
            ref={ref}
            onClick={() => {
              setShowProfileMenu(!showProfileMenu);
              setShowDatePicker(false);
              setShowSwitchModule(false);
            }}
          >
            <div
              className="rounded-5 d-flex"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#72bcc7",
                color: "#FFFFFF",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="button"
            >
              <h5 className="text-uppercase m-0">
                {localStorage.getItem("userLetter")}
              </h5>
            </div>
            <h6 className="text-capitalize m-0">
              {localStorage.getItem("loggedUser")}
            </h6>
          </div>
          {showProfileMenu && (
            <div
              className="position-absolute rounded-3 d-flex flex-column flex-wrap gap-3 mt-1 shadow-sm ignore-onclickoutside"
              style={styles.profileMenu}
            >
              {!isOnPrem && <div style={{ cursor: "pointer" }} onClick={() => setShowChangePass(true)} >
                Change Password
              </div>}
              <div style={{ cursor: "pointer" }} onClick={() => logout()}>
                Logout
              </div>
            </div>
          )}
        </div>
      </div >
      {showChangePass && (
        <ChangePassword showChangePass={showChangePass} setShowChangePass={setShowChangePass} />
      )}
      {customShow && (
        <TiGlobalSearch
          setCustomShow={setCustomShow}
          customShow={customShow}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setSearchVal={setSearchVal}
          searchVal={searchVal}
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          searchValCom={searchValCom}
          setSearchValCom={setSearchValCom}
          vulnerPage={vulnerPage}
          setVulnerPage={setVulnerPage}
          vulnerExPage={vulnerExPage}
          setVulnerExPage={setVulnerExPage}
          securityPage={securityPage}
          setSecurityPage={setSecurityPage}
          headlinesPage={headlinesPage}
          setHeadlinesPage={setHeadlinesPage}
          setComPage={setComPage}
          comPage={comPage}
          sevChange={sevChange}
          setSevChange={setSevChange}
          setIsApplyGlobalSearch={setIsApplyGlobalSearch}
        />)}
    </div >


  );
};

export default Topbar;