import {
    RT_COR_ADD_CATALOGUE_REQUEST,
    RT_COR_ADD_CATALOGUE_SUCCESS,
    RT_COR_ADD_CATALOGUE_FAIL,
    RT_COR_REMOVE_CATALOGUE_REQUEST,
    RT_COR_REMOVE_CATALOGUE_SUCCESS,
    RT_COR_REMOVE_CATALOGUE_FAIL,
    RT_COR_LIST_MASTER_REQUEST,
    RT_COR_LIST_MASTER_SUCCESS,
    RT_COR_LIST_MASTER_FAIL,
    RT_COR_LIST_CAT_REQUEST,
    RT_COR_LIST_CAT_SUCCESS,
    RT_COR_LIST_CAT_FAIL,
    RT_COR_LIST_CUSTOM_MASTER_REQUEST,
    RT_COR_LIST_CUSTOM_SUCCESS,
    RT_COR_LIST_CUSTOM_FAIL,
    RT_COR_CUSTOM_MASTER_REQUEST,
    RT_COR_MASTER_SUCCESS,
    RT_COR_MASTER_FAIL,
    RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST,
    RT_COR_MASTER_SINGLE_LIST_SUCCESS,
    RT_COR_MASTER_SINGLE_LIST_FAIL,
    RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST,
    RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS,
    RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL,
    RT_COR_CUSTOM_MASTER_SUCCESS_FALSE,
    
  } from "../../reduxConstants/ruleStudio/CorrelationConstants";
  
  const initialState = {
    catalogues: [], // Initialize the data property as an empty array
    listLoading: false,
    deleteLoading: false,
    deleteSuccess: false,
    deletedId: ""
  };
  
  // CATALOGUE - CORRELATION
  export const listCatalogueRsCor = (state = initialState, action) => {
    switch (action.type) {
  
      case RT_COR_LIST_CAT_REQUEST:
        return { ...state, listLoading: true, catalogues: [] };
  
      case RT_COR_LIST_CAT_SUCCESS: {
  
        return { ...state, catalogues: action.payload.data, listLoading: false };
      }
      case RT_COR_LIST_CAT_FAIL:
        return { ...state, listLoading: false, error: action.payload };
  
      case RT_COR_REMOVE_CATALOGUE_REQUEST:
        return { ...state, deleteLoading: true, listLoading: true };
  
      case RT_COR_REMOVE_CATALOGUE_SUCCESS:
  
        return {
          ...state,
          catalogues: [
            ...state.catalogues.slice(0, action.index),
            ...state.catalogues.slice(action.index + 1)
          ],
          deleteLoading: false,
          deleteSuccess: true,
          listLoading: false,
          deletedId: action.id
        };
  
      case RT_COR_REMOVE_CATALOGUE_FAIL:
        return { ...state, deleteLoading: false, error: action.payload };
      default:
        return state;
    }
  };

  // ADD TO CAT CORRELATION
export const addToCatalogueRsCor = (state = {}, action) => {
  switch (action.type) {
    case RT_COR_ADD_CATALOGUE_REQUEST:
      return { loading: true };
    case RT_COR_ADD_CATALOGUE_SUCCESS:
      return { loading: false, success: true, address_: action.payload };
    case RT_COR_ADD_CATALOGUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION - REMOVE 
export const removeFromCatalogueRsCor = (state = initialState, action) => {
  switch (action.type) {
    case RT_COR_REMOVE_CATALOGUE_REQUEST:
      return { loading: true };
    case RT_COR_REMOVE_CATALOGUE_SUCCESS:
      console.log('Inside reducer:', state);
      console.log('initialState:', initialState);
      // const updatedRow = state.catalogues.filter(row => row.cac_id !== action.payload)
      // return { loading: false, success: true, state, catalogues: updatedRow };
    case RT_COR_REMOVE_CATALOGUE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION - LIST 
export const listMasterRsCor = (state = { master: [] }, action) => {

  switch (action.type) {
    case RT_COR_LIST_MASTER_REQUEST:
      return { loading: true, master: [] };
    case RT_COR_LIST_MASTER_SUCCESS: {
      return { loading: false, master: action.payload.data };
    }
    case RT_COR_LIST_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION - custom -LIST
export const listMasterCustomRsCor = (state = { output: [] }, action) => {
  switch (action.type) {
    case RT_COR_LIST_CUSTOM_MASTER_REQUEST:
      return { loading: true, output: [] };
    case RT_COR_LIST_CUSTOM_SUCCESS: {
      return { loading: false, output: action.payload.data };
    }
    case RT_COR_LIST_CUSTOM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION - custom - create
export const createCorrelationRsCor = (state = {}, action) => {
  switch (action.type) {
    case RT_COR_CUSTOM_MASTER_REQUEST:
      return { loading: true };
    case RT_COR_CUSTOM_MASTER_SUCCESS_FALSE:
      return { success: false };
    case RT_COR_MASTER_SUCCESS:
      return { loading: false, success: true, master: action.payload };
    case RT_COR_MASTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION DETAILS 
export const detailsCustomCorrelationRsCor = (
  state = { catalogue: {} },
  action
) => {
  switch (action.type) {
    case RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST:
      return { ...state, loading: true };
    case RT_COR_MASTER_SINGLE_LIST_SUCCESS:
      return { loading: false, catalogue: action.payload.data };
    case RT_COR_MASTER_SINGLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CORRELATION DETAILS QUERY
export const detailsCustomCorrelationQueryRsCor = (
  state = { catalogueQuery: {} },
  action
) => {
  switch (action.type) {
    case RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST:
      return { ...state, loading: true };
    case RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS:
      return {
        loading: false,
        catalogueQuery: action.payload,
      };
    case RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
