const styles = {
    section1: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      overflow: "auto",
    },
    subSection1: {
      height: "calc(100vh - 15em)",
      paddingRight: "1rem",
      overflow: "auto",
    },
    overviewTile: {
      // backgroundColor: "#f1f1f1",
      backgroundColor: "#DBEEFF",
      // height: "100px",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      // justifyContent: "space-between",
    },
    contentWrap: {
      // height: "calc(100vh - 14rem)",
      height: "calc(100vh - 17rem)",
      overflow: "auto",
    },
    filterWrap: {
      position: "absolute",
      right: "1.5rem",
      top: "14px",
    },
    tableWrap: {
      height: "calc(100vh - 13rem)",
      overflow: "auto",
      marginTop: "20px"
    },
    footerWrap: {
      position: "sticky",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      background: "#FFFFFF"
    },
    pieChart:{
      overflow:"auto",
      width: '350px', 
    height: '350px', 

    }
   
  };
  
  export default styles;