import React, { useState, useEffect, useRef } from "react";
import styles from "./TIStyles";
import { Badge, NavLink, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../CustomModal/CustomModal";
import Search from "../Search/Search";
import SearchTi from "../Search/SearchTi";
import { Link } from "react-router-dom";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableViewMore from "../ViewMoreBtn/TableViewMore";
import { getVulnerabilityExploitDetail, getTableData, getIndicatorsTableData, getVulnerabilitesExDirect, getVulnerabilitiesDirect, getHeadlines, getSecurityEventsDirect, convertTimestamp, getHeadlinesDirect } from "../../Methods/TiMethods";
import LoaderList from "../Loader/LoaderList";
import NoDataFound from "../NoDataFount/NoDataFound";
import { Calendar } from "phosphor-react";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";
import TableLoader from "../../components/Loader/LoaderList";
import Table from "react-bootstrap/Table";
import cybotLogoBlack from "../../assets/images/cybot_logo_black.png";
import { Chart } from "react-google-charts";
import DetailPageVul from "../../components/PDF/Ti/DetailPageVul";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DetailPageSe from "../../components/PDF/Ti/DetailPageSe";
import CommunityView from "../../components/DetailsPage/CommunityView";
import * as htmlToImage from 'html-to-image';
import DetailPageVulEx from "../../components/PDF/Ti/DetailPageVulEx";
import LoaderListModal from "../../components/Loader/LoaderListModal";








export default function
  TiGlobalSearch({
    setCustomShow,
    customShow,
    setActiveTab,
    activeTab,
    setSearchVal,
    globalSearch,
    setGlobalSearch,
    selectedDataFilter,
    searchValCom,
    setSearchValCom,
    setSelectedDateFilter,
    setYear,
    vulnerPage,
    setVulnerPage,
    vulnerExPage,
    setVulnerExPage,
    securityPage,
    setSecurityPage,
    headlinesPage,
    setHeadlinesPage,
    comPage,
    setComPage,
    sevChange,
    setSevChange,
    setIsApplyGlobalSearch
  }) {

  const dispatch = useDispatch();
  const divRefSecImage = useRef(null);
  const componentPDF = useRef(null);
  const currentTimestamp = Date.now();
  const [imageBlob, setImageBlob] = useState(null);




  const [redirecting, setRedirecting] = useState(false)
  const [Page, setPage] = useState(1);
  const [PerPage, setPerPage] = useState(100);
  const [sevFilter, setSevFilter] = useState("now-1y/d");
  const [vulnerabilitiesFilter, setVulnerabilitiesFilter] = useState("");
  const [vulnerabilityExploitFilter, setVulnerabilityExploitFilter] = useState("");
  const [securityEvFilter, setSecurityEvFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");
  const [tabToggle, setTabToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState([]);
  const [eventName, setEventName] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState(true);
  const [showEvent, setShowEvent] = useState(false);
  const [detailpage, setDetailpage] = useState([]);
  const [moreData, setMoreData] = useState();
  const [detail, setDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [comEvents, setComEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)
  const [eventsCount, setEventsCount] = useState(0)

  const [globalSearchLoading, setGlobalLoading] = useState(false);

  const [activeTabModal, setActiveTabModal] = useState("headlines");

  const [headlinesData, setHeadlinesData] = useState([]);
  const [headlinesTotalCount, setHeadlinesTotalCount] = useState(0);

  const [vulData, setVulData] = useState([]);
  const [VulTotalCount, setVulTotalCount] = useState(0);

  const [vulExpData, setVulExpData] = useState([]);
  const [VulExpTotalCount, setVulExpTotalCount] = useState(0);


  const [secData, setSecData] = useState([]);
  const [secTotalCount, setSecTotalCount] = useState(0);



  const Headlineslist = useSelector((state) => state.Headlineslist);
  const {
    headlines,
    loading: headLoading,
    totalCount: headTotalCount,
  } = Headlineslist;

  const VulnerabilitiesList = useSelector((state) => state.VulnerabilitiesList);
  const {
    vulnerabilities,
    vulCounts,
    loading: vulLoading,
  } = VulnerabilitiesList;

  const VulnerabilitiesEXlist = useSelector(
    (state) => state.VulnerabilitiesEXlist
  );
  const {
    exVulnerabilities,
    exVulCounts,
    loading: exVulLoading,
  } = VulnerabilitiesEXlist;

  const SecurityEventsList = useSelector((state) => state.SecurityEventsList);
  const {
    securityEvents,
    secCounts,
    loading: secEventsLoading,
  } = SecurityEventsList;

  // const getTableDataComTi = useSelector((state) => state.getTableDataComTi);
  // const {
  //   comEvents,
  //   loading: eventsLoading,
  //   commCount
  // } = getTableDataComTi;


  useEffect(() => {
    setGlobalLoading(true)

    setTimeout(() => {
      getData()
      setGlobalLoading(false)
    }, 2000);



  }, [dispatch, , Page, PerPage, globalSearch]);

  const getData = () => {

    setEventsLoading(true)

    getHeadlinesDirect(
      PerPage,
      "desc",
      (Page - 1) * PerPage,
      globalSearch,
      sevFilter
    ).then((res) => {
      setHeadlinesTotalCount(res?.data?.hits?.total?.value)
      setHeadlinesData(res?.data?.hits?.hits)
    })



    getVulnerabilitiesDirect(PerPage,
      "desc",
      (Page - 1) * PerPage,
      vulnerabilitiesFilter,
      globalSearch,
      sevFilter).then((res) => {
        setVulTotalCount(res?.data?.hits?.total?.value)
        setVulData(res?.data?.hits?.hits)
      })

    getVulnerabilitesExDirect(PerPage,
      "desc",
      (Page - 1) * PerPage,
      vulnerabilityExploitFilter,
      globalSearch,
      sevFilter).then((res) => {
        setVulExpTotalCount(res?.data?.hits?.total?.value)
        setVulExpData(res?.data?.hits?.hits)
      })


    getSecurityEventsDirect(PerPage,
      "desc",
      (Page - 1) * PerPage,
      securityEvFilter,
      globalSearch,
      sevFilter).then((res) => {
        setSecTotalCount(res?.data?.hits?.total?.value)
        setSecData(res?.data?.hits?.hits)
      })





    getIndicatorsTableData(PerPage,
      "desc",
      (Page - 1) * PerPage,
      eventFilter,
      globalSearch,
      sevFilter).then((res) => {
        if (res.status === 200) {
          setEventsLoading(false)
          setComEvents(res.data.hits.hits)
          setEventsCount(res.data.hits.total.value)

        }


      })
  }


  const convertToBlob = () => {

    htmlToImage.toBlob(divRefSecImage.current).then((blob) => {

      setImageBlob(blob);
      setShowDownloadButton(true)
    });
  };




  useEffect(() => {
    setShowDownloadButton(false)
    const timeoutId = setTimeout(() => {
      // Code to be executed after the timeout
      if (show === true) {
        convertToBlob()

      }


    }, 1000); // Timeout duration in milliseconds (2 seconds)

    return () => {
      clearTimeout(timeoutId); // Clean up the timeout on component unmount
    };

  }, [showMore])


  let geoData = [];
  geoData.push(['XX'])
  let locations = showMore?.location?.split(" ,");
  locations?.forEach((location) => {
    geoData.push([location]);
  });


  const handleOpenModalHead = (headlines, headLoading) => {
    setSearchVal(globalSearch);
    setHeadlinesPage(1);
    setActiveTabModal("headlines");
    setActiveTab('headlines')
    setCustomShow(false);
    setSelectedDateFilter("1 Year")
    setIsApplyGlobalSearch(true)
  }

  const handleTabSelect = (tabName) => {
    setActiveTabModal(tabName);
  };

  const handleOpenModalVul = (vulnerabilitiesFilter, vulnerabilitiesSearch, Page, PerPage) => {

    setSevChange(true)
    setSearchVal(globalSearch);
    setVulnerPage(1);
    setActiveTabModal("vulnerabilities");
    setActiveTab('vulnerabilities')
    setCustomShow(false);
    setSelectedDateFilter("1 Year")
    setIsApplyGlobalSearch(true)
  }

  const handleOpenModalVulEX = (vulnerabilityExploitFilter, vulnerabilityExploitSearch, Page, PerPage) => {
    setSevChange(true)
    setSearchVal(globalSearch);
    setVulnerExPage(1);
    setActiveTabModal("vulnerabilities_exploit");
    setActiveTab('vulnerabilities_exploit')
    setCustomShow(false);
    setSelectedDateFilter("1 Year")
    setIsApplyGlobalSearch(true)
  }

  const handleOpenModalSec = () => {
    setSevChange(true)
    setSearchVal(globalSearch);
    setSecurityPage(1);
    setActiveTabModal("security_events");
    setActiveTab('security_events')
    setCustomShow(false);
    setSelectedDateFilter("1 Year")
    setIsApplyGlobalSearch(true)
  }


  const handleOpenModalEvents = () => {
    setSevChange(true)
    setSearchValCom(globalSearch);
    setComPage(1)
    setCustomShow(false);
    setSelectedDateFilter("1 Year")
    setIsApplyGlobalSearch(true)

  }

  const handleRedirect = (path) => {
    setRedirecting(true)
    setTimeout(() => {
      setRedirecting(false)
      window.open(path, "_blank");
    }, 2500);

  }

  function handleCloseModal() {
    setShow(false);
  }

  const handleOpenModal = (data, display, eveName) => {
    setShowMore(data);
    setShow(display);
    setEventName(eveName);
    setShowDetail(true)
    setDetail(data)
  };

  const handleOpenEvents = (data, display) => {
    setDetailpage(data);
    setShowEvent(display);
  };


  function handleCloseEvent() {
    setShowEvent(false);
  }




  function handleCloseEvent() {
    setShowEvent(false);
  }


  const handleClose = () => {
    setSearchVal("");
    setSearchValCom("");
    setGlobalSearch("");

    setVulnerExPage(1);
    setHeadlinesPage(1);
    setVulnerExPage(1);
    setSecurityPage(1);
    setComPage(1);
    setCustomShow(false);
  };

  function renderEvent() {
    return <CommunityView eventDetails={detailpage} />;
  }

  // if(globalSearch!=""){
  const handleSearch = (val, e, action) => {
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setGlobalSearch(val);
    }
  }
  // }



  let hSlice = headlinesData.slice(0, 5);
  let vulSlice = vulData.slice(0, 5);
  let vulEXSlice = vulExpData.slice(0, 5);
  let secSlice = secData.slice(0, 5);
  // let eventSlice = comEvents.slice(0, 5);


  useEffect(() => {
    setIsLoading(true)
    if (eventName === 'exploit') {
      getVulnerabilityExploitDetail(detail?.cve).then((res) => {
        setIsLoading(false)
        setMoreData(res.data)
      })
    }



  }, [detail, eventName])



  const renderDetail = () => {
    if (eventName === 'exploit') {
      return (
        <div>
          <div className="d-grid d-md-flex justify-content-md-end mb-3">
            {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
            <PDFDownloadLink document={<DetailPageVulEx detail={detail} moreData={moreData} />} fileName={`vulnerability-explore-${currentTimestamp}.pdf`}>
              {({ blob, url, loading, error }) =>
                (<button className="form-control  btn btn-primary">Download PDF</button>)
              }
            </PDFDownloadLink>
          </div>
          <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
            <div id="printable_header"  >
              <div className="d-flex">
                <div className="p-3"><img src="/logo-sm-light.png" /></div>

              </div>
              <hr />
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Event name</span>
              <span className="modal-text">{detail?.event_name}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Event description</span>
              <span className="modal-text">{detail?.event_description}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">CVE ID</span>
              <span className="modal-text">{detail?.cve}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Severity</span>
              <span className="modal-text">
                {detail?.severity == "Critical" && (
                  <div className="p-2 m-0 alert alert-danger">Critical</div>
                )}
                {detail?.severity == "High" && (
                  <div className="p-2 m-0 alert alert-danger">High</div>
                )}
                {detail?.severity == "Medium" && (
                  <div className="p-2 m-0 alert alert-info">Medium</div>
                )}
                {detail?.severity == "Low" && (
                  <div className="p-2 m-0 alert alert-success">Low</div>
                )}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert date</span>
              <span className="modal-text">
                {getDateFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert time</span>
              <span className="modal-text">
                {getTimeFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Vendor</span>
              <span className="modal-text">{detail?.vendor}</span>
            </div>

            {isLoading === true ?
              <div className="col-12 p-2">
                <LoaderListModal />
              </div> :

              <div className="col-12">
                <div className="modal-row-item">
                  <span className="modal-label col-3">Published</span>
                  <span className="modal-text">
                    {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.published)}
                  </span>
                </div>
                <div className="modal-row-item">
                  <span className="modal-label col-3">Last Modified</span>
                  <span className="modal-text">
                    {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.lastModified)}
                  </span>
                </div>

                {moreData?.vulnerabilities[0]?.cve?.cisaExploitAdd ?
                  <div className="modal-row-item">
                    <span className="modal-label col-3">CISA Exploit
                    </span>
                    <span className="modal-text text-danger">
                      {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.cisaExploitAdd)}
                    </span>
                  </div>
                  : ''}

                {moreData?.vulnerabilities[0]?.cve?.references.length > 0 ?
                  <div className="modal-row-item">
                    <span className="modal-label col-3">References</span>
                    <div className="d-flex flex-fill flex-column text-break">
                      {moreData?.vulnerabilities[0]?.cve?.references?.map((ref) => (
                        <Link to={ref.url} target="_blank" className="w-100" style={{ color: "blue" }}>
                          {ref.url}
                        </Link>
                      ))}
                    </div>
                  </div>
                  : ''}
                <div className="modal-row-item">
                  <span className="modal-label col-3">Additional descriptions
                  </span>
                  {moreData?.vulnerabilities[0]?.cve?.descriptions[0]?.value}
                </div>

                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31?.length > 0 ?
                  <div className="modal-row-item">
                    <span className="modal-label col-3"> Base Score V3
                    </span>

                    <span className="modal-text text-primary">{moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31[0]?.cvssData
                      ?.baseScore}</span>


                  </div>
                  : ''}
                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2?.length > 0 ?
                  <div className="modal-row-item">
                    <span className="modal-label col-3">Base Score V2
                    </span>

                    <span className="modal-text text-primary">{moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2[0]?.cvssData
                      ?.baseScore}</span>


                  </div>
                  : ''}
              </div>
            }
          </div>
        </div>
      );
    }

    else if (eventName === "vulnerabilities") {
      let baseSeverity = showMore?.cve?.metrics?.cvssMetricV31 && showMore?.cve?.metrics?.cvssMetricV31[0]?.cvssData?.baseSeverity
      return (
        <>
          <div className="d-grid d-md-flex justify-content-md-end mb-3">
            {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
            <PDFDownloadLink document={<DetailPageVul showMore={showMore} baseSeverity={baseSeverity} />} fileName={`vulnerabilities-${currentTimestamp}.pdf`} type="button">
              {({ blob, url, loading, error }) =>
                (<button className="form-control  btn btn-primary">Download PDF</button>)
              }
            </PDFDownloadLink>
          </div>
          <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
            <div id="printable_header"  >
              <div className="d-flex">
                <div className="p-3"><img className="logo-pdf-header" src={cybotLogoBlack} /></div>
              </div>
              <hr />
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">CVE ID</span>
              <span className="modal-text">{showMore.cve?.id}</span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Severity</span>
              <span className="modal-text">

                {baseSeverity == "CRITICAL" && (
                  <div className="p-2 m-0 alert alert-danger">Critical</div>
                )}
                {baseSeverity == "HIGH" && (
                  <div className="p-2 m-0 alert alert-danger">High</div>
                )}
                {baseSeverity == "MEDIUM" && (
                  <div className="p-2 m-0 alert alert-warning">Medium</div>
                )}
                {baseSeverity == "LOW" && (
                  <div className="p-2 m-0 alert alert-success">Low</div>
                )}
              </span>
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Date</span>
              <span className="modal-text">
                {getDateFromTimestamp(showMore?.cve?.published)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Time</span>
              <span className="modal-text">
                {getTimeFromTimestamp(showMore?.cve?.published)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Description</span>
              <span className="modal-text">
                {showMore.cve?.descriptions[0].value}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Vulnurability status</span>
              <span className="modal-text">{showMore.cve?.vulnStatus}</span>
            </div>
            {showMore?.cve?.references?.length != 0 ? (
              <div className="modal-row-item">
                <span className="modal-label col-3">Reference links</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {showMore?.cve?.references?.map((item) => (
                    <Link to={item.url} target="_blank" style={{ color: "blue" }}>
                      {item.url}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <a href={showMore.cve?.references.map((item) => item)}>Link</a> */}
          </div>
        </>
      );
    } else {

      // geoData.push(['TZ'], ['IN'])
      return (
        <div className="col-12">
          <div className="d-grid d-md-flex justify-content-md-end mb-3">
            {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
            {showDownloadButton === true ?
              <PDFDownloadLink document={<DetailPageSe showMore={showMore} geoData={geoData} imageBlob={imageBlob} />} fileName={`security-events-${currentTimestamp}.pdf`}>
                {({ blob, url, loading, error }) =>
                  (<button className="form-control  btn btn-primary">Download PDF</button>)
                }
              </PDFDownloadLink>
              : <button className="btn btn-primary" disabled>Export as PDF</button>
            }
          </div>
          <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
            <div className="col-12" id="printable_header"  >
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="">
                  <img className="logo-pdf-header" src={cybotLogoBlack} />
                </div>
                <div className="d-flex flex-column gap-2">
                  <span>
                    Date - <strong>{getDateFromTimestamp(new Date())}</strong>
                  </span>
                  <span>
                    Time - <strong>{getTimeFromTimestamp(new Date())}</strong>
                  </span>
                </div>
              </div>
              <hr />
            </div>

            <div className="modal-row-item">
              <span className="modal-label col-3">Event name</span>
              <span className="modal-text">{showMore?.event_name}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Event description</span>
              <span className="modal-text">{showMore?.event_description}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Severity</span>
              <span className="modal-text">
                {showMore?.severity == "Critical" && (
                  <div className="p-2 m-0 alert alert-danger">Critical</div>
                )}
                {showMore?.severity == "High" && (
                  <div className="p-2 m-0 alert alert-danger">High</div>
                )}
                {showMore?.severity == "Medium" && (
                  <div className="p-2 m-0 alert alert-warning">Medium</div>
                )}
                {showMore?.severity == "Low" && (
                  <div className="p-2 m-0 alert alert-success">Low</div>
                )}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">TLP</span>
              <span className="modal-text">{showMore?.tlp}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert date</span>
              <span className="modal-text">{getDateFromTimestamp()}</span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-3">Alert time</span>
              <span className="modal-text">{getTimeFromTimestamp()}</span>
            </div>
            {/* <div className="modal-row-item">
            <span className="modal-label col-3">Comments</span>
            <span className="modal-text">{showMore?.comments?.map((item) => item)}</span>
          </div> */}

            <div className="modal-row-item">
              <span className="modal-label col-3">Impact region</span>
              <span className="modal-text" ref={divRefSecImage}>
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geoData[selection[0].row + 1];
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="300px"
                  data={geoData}
                />
              </span>
            </div>

            {showMore?.comments?.length > 0 && (
              <div className="modal-row-item">
                <span className="modal-label col-3">Comments</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {showMore?.comments?.map((item) => (
                    <p className="p-3 rounded card">{item}</p>
                  ))}
                </div>
              </div>
            )}
            {showMore?.reference_link?.length > 0 && (
              <div className="modal-row-item">
                <span className="modal-label col-3">Reference links</span>
                <div
                  className="modal-text d-flex flex-column"
                  style={{
                    fontSize: "14px",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                >
                  {showMore?.reference_link?.map((item) => (
                    <Link to={item} target="_blank">
                      {item}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {/* <p>comments :{showMore?.comments?.map((item) => item)}</p> */}
            {/* <p>impact region :{showMore?.location}</p> */}
          </div>
        </div>
      );
    }
  };



  const renderModal = () => (

    <div className="col-12">
      <div className="mx-3 border-bottom sticky-toolbar" style={{ top: "0" }}>
        <SearchTi changeFunction={handleSearch} searchData={globalSearch} />
      </div>
      {globalSearch === '' ?
        <div className="col-12 h-100 d-flex align-items-center justify-content-center">
          <p>Please type to search...</p>
        </div> :
        <div className="col-12 px-3">
          <Tabs
            id="dynamic-tab-switching"
            activeKey={activeTabModal}
            onSelect={handleTabSelect}
            defaultActiveKey='headlines'
          >

            <Tab eventKey="headlines" title={<React.Fragment>
              Headlines
              <Badge className="ml-2" bg="info">
                {headlinesData && globalSearchLoading === false ? headlinesTotalCount : 0}
              </Badge>
            </React.Fragment>}>


              <div className="col-12 d-flex flex-row p-2 mt-3 sticky-title-section" style={{ top: "53px" }}>
                {/* <h5>Headlines</h5> */}


                {globalSearch != "" && headlinesTotalCount > 5 && globalSearchLoading === false ?
                  <div className="ml-auto p-2">
                    <Link to="/ti/activebytes" onClick={() => handleOpenModalHead()}>
                      <span className="mr-2">View all</span>
                      {/* <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} /> */}
                    </Link>
                  </div> : ""}
              </div>
              <div className="col-12 d-flex flex-column mb-3 ti-card" >
                {redirecting === true ?
                  <div className="col-2 mx-auto">
                    <div className="alert alert-warning flex-row d-flex text-center gap-3 justify-content-center align-items-center" role="alert">
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                      <span>Redirecting...</span>
                    </div>
                  </div>
                  :


                  headLoading === true || globalSearchLoading === true ? (
                    <LoaderList />
                  ) : (
                    headlinesTotalCount === 0 ? <NoDataFound /> :


                      globalSearch === "" ? "" :
                        hSlice.map(
                          ({
                            _source: { event_name, attribute_timestamp, reference_link }, index
                          }) => (

                            <div
                              className="col-12 d-flex flex-row align-items-center p-4 mb-3 rounded-3 card ti-card"

                            >
                              <div className="d-flex flex-column">
                                <h6 className="">{event_name}</h6>
                                <div className="d-flex mt-3 gap-2 align-items-center">
                                  <Calendar size={18} />
                                  <h6 className="m-0">{convertTimestamp(attribute_timestamp)}</h6>
                                </div>
                              </div>
                              <div className="ml-auto p-2">
                                <a className="reset-link" onClick={(e) => handleRedirect(reference_link)}><TableViewMore Icon={ArrowRight} dataToggle={"Open Link"} dataPlacement={"bottom"} /></a>
                              </div>

                            </div>

                          ),

                        )
                  )
                }
              </div>

            </Tab>


            <Tab eventKey="vulnerabilities" title={<React.Fragment>
              Vulnerabilities
              <Badge className="ml-2" bg="info">
                {VulTotalCount && globalSearchLoading === false ? VulTotalCount : 0}
              </Badge>
            </React.Fragment>}>
              {/* Vulnarabilities */}

              <div className="col-12 d-flex flex-row align-items-center p-2 mt-3 sticky-title-section" style={{ top: "54px" }}>
                {/* <h5 className="mb-0">Vulnerabilities</h5>
            <Badge className="ml-2" bg="info">
              {vulCounts && globalSearchLoading === false ? vulCounts : 0}
            </Badge> */}
                {globalSearch != "" && VulTotalCount > 5 && globalSearchLoading === false ?

                  <div className="ml-auto p-2">
                    <Link to="/ti/activebytes" onClick={() => handleOpenModalVul()}>
                      <span className="mr-2">View all</span>
                      {/* <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} /> */}
                    </Link>
                  </div> : ""
                }
              </div>
              <div className="col-12 d-flex flex-column mb-3 ti-card" >
                {vulLoading === true || globalSearchLoading === true ? (
                  <LoaderList />
                ) : (
                  VulTotalCount === 0 ? <NoDataFound /> :

                    globalSearch === "" ? "" :
                      vulSlice.map((data) => {

                        let sev = data?._source?.cve?.metrics?.cvssMetricV31 && data?._source?.cve?.metrics?.cvssMetricV31[0]?.cvssData?.baseSeverity

                        return <div
                          className="col-12 d-flex flex-column p-4 mb-3 rounded-3 card ti-card"
                          style={styles.tile}
                        // onClick={() => handleOpenModal(data?._source, true, "vulnerabilities")}
                        >
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row gap-2 align-items-center">
                              <span>CVE ID </span>
                              <h6 className="mb-0">{'#' + data?._source?.cve?.id}</h6>
                            </div>
                          </div>

                          <div>{data?._source?.cve?.descriptions[0]?.value}</div>

                          <div className="d-flex flex-row gap-3 my-2 align-items-center">
                            <div className="d-flex flex-row gap-2">
                              <span>Vendor -</span>
                              <div><strong>Oracle</strong></div>
                            </div>
                            {sev &&
                              <div className="d-flex flex-row align-items-center gap-2">
                                <span>Severity</span>

                                {sev === "CRITICAL" && (
                                  // <div className="p-2 m-0 alert alert-danger">Critical</div>
                                  <Badge bg="danger" className="p-2">Critical</Badge>
                                )}
                                {sev === "HIGH" && (
                                  // <div className="p-2 m-0 alert alert-danger">High</div>
                                  <Badge bg="warning" className="p-2">High</Badge>
                                )}
                                {sev === "MEDIUM" && (
                                  // <div className="p-2 m-0 alert alert-warning">Medium</div>
                                  <Badge bg="warning" className="p-2">Medium</Badge>
                                )}
                                {sev == "LOW" && (
                                  // <div className="p-2 m-0 alert alert-success">Low</div>
                                  <Badge bg="success" className="p-2">Low</Badge>
                                )}
                              </div>
                            }
                            <div className="d-flex flex-row gap-2 align-items-center">
                              {/* <span>Published on - </span> */}
                              <Calendar size={18} />
                              <h6 className="m-0">{getDateFromTimestamp(data?._source?.cve.published) + " at " + getTimeFromTimestamp(data?._source?.cve?.published)}</h6>
                            </div>

                          </div>
                          <div className="ml-auto p-2">
                            <Link onClick={() => handleOpenModal(data?._source, true, "vulnerabilities")}>
                              <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                            </Link>
                            {/* <div>{_source.severity}</div> */}
                          </div>

                        </div>
                      })
                )
                }
              </div >
            </Tab>


            <Tab eventKey="vulnerabilities_exploit" title={<React.Fragment>
              Vulnerabilities Exploit
              <Badge className="ml-2" bg="info">
                {VulExpTotalCount && globalSearchLoading === false ? VulExpTotalCount : 0}
              </Badge>
            </React.Fragment>}>
              {/* Vulnarabilities Exploit */}


              <div className="col-12 d-flex flex-row align-items-center p-2 mt-3 sticky-title-section" style={{ top: "53px" }}>
                {/* <h5 className="mb-0">Vulnerabilities Exploit</h5> */}
                {/* <Badge className="ml-2" bg="info">
              {exVulCounts && globalSearchLoading === false ? exVulCounts : 0}
            </Badge> */}
                {globalSearch != "" && VulExpTotalCount > 5 && globalSearchLoading === false ?
                  <div className="ml-auto p-2">
                    <Link to="/ti/activebytes" onClick={() => handleOpenModalVulEX()}>
                      <span className="mr-2">View all</span>
                      {/* <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} /> */}
                    </Link>
                  </div> : ""
                }
              </div>
              <div className="col-12 d-flex flex-column mb-3 ti-card" >
                <div className="col-12" >
                  {exVulLoading === true || globalSearchLoading === true ? (
                    <LoaderList />
                  ) : (
                    VulExpTotalCount === 0 ? <NoDataFound /> :

                      globalSearch === "" ? "" :
                        vulEXSlice.map(({ _source }) => (
                          <div
                            className="col-12 d-flex flex-column p-4 mb-3 rounded-3 card ti-card"
                            style={styles.tile}
                          >
                            <h6>{_source.event_name}</h6>
                            <div>{_source.event_description}</div>
                            <div className="d-flex flex-row gap-3 mt-3 align-items-center">
                              <div className="d-flex flex-row gap-2">
                                <span>Vendor - </span>
                                <div><strong>{_source.vendor}</strong></div>
                              </div>
                              <div className="d-flex flex-row align-items-center gap-2">
                                <span>Severity</span>
                                {_source.severity == "Critical" && (
                                  // <div className="p-2 m-0 alert alert-danger">Critical</div>
                                  <Badge bg="danger" className="p-2">Critical</Badge>
                                )}
                                {_source.severity == "High" && (
                                  // <div className="p-2 m-0 alert alert-danger">High</div>
                                  <Badge bg="warning" className="p-2">High</Badge>
                                )}
                                {_source.severity == "Medium" && (
                                  // <div className="p-2 m-0 alert alert-warning">Medium</div>
                                  <Badge bg="warning" className="p-2">Medium</Badge>
                                )}
                                {_source.severity == "Low" && (
                                  // <div className="p-2 m-0 alert alert-success">Low</div>
                                  <Badge bg="success" className="p-2">Low</Badge>
                                )}
                              </div>
                              <div className="d-flex flex-row gap-2 align-items-center">
                                <Calendar size={18} />
                                <h6 className="m-0">{convertTimestamp(_source.attribute_timestamp)}</h6>
                              </div>
                            </div>
                            <div className="ml-auto p-2">
                              <Link onClick={() => handleOpenModal(_source, true, "exploit")}>
                                <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                              </Link>
                              {/* <div>{_source.severity}</div> */}
                            </div>                    </div>

                        )))}
                </div>

              </div>
            </Tab>


            <Tab eventKey="security_events" title={<React.Fragment>
              Security Events
              <Badge className="ml-2" bg="info">
                {secTotalCount && globalSearchLoading === false ? secTotalCount : 0}
              </Badge>
            </React.Fragment>}>



              {/* Security Events */}

              <div className="col-12 d-flex flex-row p-2 mt-3 sticky-title-section" style={{ top: "53px" }}>
                {/* <h5>Security Events</h5> */}

                {globalSearch != "" && secTotalCount > 5 ?

                  <div className="ml-auto p-2">
                    <Link to="/ti/activebytes" onClick={() => handleOpenModalSec()}>
                      <span className="mr-2">View all</span>
                      {/* <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} /> */}
                    </Link>
                  </div> : ""
                }
              </div>
              <div className="col-12 d-flex flex-column mb-3 ti-card" >
                {secEventsLoading === true || globalSearchLoading === true ? (
                  <LoaderList />
                ) : (
                  secTotalCount === 0 ? <NoDataFound /> :

                    globalSearch === "" ? "" :
                      secSlice.map(({ _source }) => (
                        <div
                          className="col-12 d-flex flex-column p-4 mb-3 rounded-3 card ti-card"
                          style={styles.tile}
                        // onClick={() => handleOpenModal(_source, true, "securityEvents")}
                        >
                          <h6>{_source.event_name}</h6>
                          <div>{_source.event_description}</div>


                          <div className="d-flex flex-row gap-3 mt-3">
                            <div className="d-flex flex-row align-items-center gap-2">
                              <span>Severity</span>
                              {_source.severity == "Critical" && (
                                // <div className="p-2 m-0 alert alert-danger">Critical</div>
                                <Badge bg="danger" className="p-2">Critical</Badge>
                              )}
                              {_source.severity == "High" && (
                                // <div className="p-2 m-0 alert alert-danger">High</div>
                                <Badge bg="danger" className="p-2">High</Badge>
                              )}
                              {_source.severity == "Medium" && (
                                // <div className="p-2 m-0 alert alert-warning">Medium</div>
                                <Badge bg="warning" className="p-2">Medium</Badge>
                              )}
                              {_source.severity == "Low" && (
                                // <div className="p-2 m-0 alert alert-success">Low</div>
                                <Badge bg="success" className="p-2">Low</Badge>
                              )}
                            </div>
                            {/* {_source.severity}</div> */}
                            <div className="d-flex flex-row align-items-center gap-2">
                              <span>TLP</span>
                              {/* <div className="p-2 my-0 mx-2 alert alert-secondary">{_source.tlp}</div> */}
                              <Badge bg="secondary" className="p-2">{_source.tlp}</Badge>
                            </div>

                            <div className="d-flex flex-row gap-2 align-items-center">
                              <Calendar size={18} />
                              <h6 className="m-0">{convertTimestamp(_source.attribute_timestamp)}</h6>
                            </div>
                          </div>
                          <div className="ml-auto p-2">
                            <Link onClick={() => handleOpenModal(_source, true, "securityEvents")}>
                              <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} />
                            </Link>
                            {/* <div>{_source.severity}</div> */}
                          </div>
                        </div>
                      ))
                )}

              </div>


            </Tab>




            <Tab eventKey="events" title={<React.Fragment>
             Feeds
              <Badge className="ml-2" bg="info">
                {eventsCount && globalSearchLoading === false ? eventsCount : 0}
              </Badge>
            </React.Fragment>}>



              {/* Events */}

              <div className="col-12 d-flex flex-row align-items-center p-2 mt-3 sticky-title-section" style={{ top: "53px" }}>

                {/* {globalSearch && globalSearchLoading === false != "" ? */}
                {globalSearch != "" && eventsCount > 5 && globalSearchLoading === false ?
                  <div className="ml-auto p-2">
                    <Link to="/ti/community" onClick={() => handleOpenModalEvents()}>
                      <span className="mr-2">View all</span>
                      {/* <TableViewMore Icon={ArrowRight} dataToggle={"View Details"} dataPlacement={"bottom"} /> */}
                    </Link>
                  </div>
                  : ""
                }
              </div>
              <div className="col-12" style={styles.tableWrap}>
                {eventsLoading === true || globalSearchLoading === true ? (
                  <TableLoader />
                ) : (
                  eventsCount === 0 ? <NoDataFound /> :

                    <Table borderless hover>
                      <thead className="">
                        <tr>
                          <th>Event Date</th>
                          <th>Event Time</th>
                          <th>Event Name</th>
                          <th>Feed Name</th>
                          <th>Severity</th>
                          <th>Event Drilldown</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comEvents?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              {getDateFromTimestamp(parseInt(item._source.attribute_timestamp) * 1000)}
                            </td>
                            <td className="align-middle">
                              {getTimeFromTimestamp(parseInt(item._source.attribute_timestamp) * 1000)}
                            </td>
                            {/* <td className="align-middle">{item._source.key}</td> */}
                            <td className="align-middle col-5">{item._source.event_name}</td>
                            <td className="align-middle">{item._source.feed_name}</td>
                            <td className="align-middle">
                              {item._source.severity.toLowerCase() == "critical" && (
                                <Badge bg="danger" className="p-2">Critical</Badge>
                              )}
                              {item._source.severity.toLowerCase() == "high" && (
                                <Badge bg="danger" className="p-2">High</Badge>
                              )}
                              {item._source.severity.toLowerCase() == "medium" && (
                                <Badge bg="warning" className="p-2">Medium</Badge>
                              )}
                              {item._source.severity.toLowerCase() == "low" && (
                                <Badge bg="success" className="p-2">Low</Badge>
                              )}
                            </td>
                            <td className="align-middle text-center">
                              <Link onClick={() => handleOpenEvents(item._source, true)}>
                                <TableViewMore Icon={ArrowRight} dataToggle={"Open Link"} dataPlacement={"bottom"} />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                )}
              </div>

            </Tab>
          </Tabs>


          {/* Deep and Dark Web */}

          {/* <div className="col-12 d-flex flex-row align-items-center p-2 mt-3 sticky-title-section" style={{ top: "54px" }}>
            <h5 className="mb-0">Deep And Dark Web</h5>


          </div> */}

        </div>
      }

    </div>
  );


  return (
    <>
      {customShow === true && (
        <CustomModal
          title={'TI Search'}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          modalSize={"lg"}
          noBodyPadding={true}
          hasStickyTab={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}

        />
      )}

      {show && (
        <CustomModal
          title={"Event Details"}
          ShowOrHide={show}
          modalSize={"lg"}
          bodyData={renderDetail}
          handleClose={handleCloseModal}
          footer={false}
          autoHeight
        ></CustomModal>
      )}

      {showEvent && (
        <CustomModal
          title={"Event details"}
          ShowOrHide={showEvent}
          modalSize={"lg"}
          bodyData={renderEvent}
          handleClose={handleCloseEvent}
          footer={false}
        ></CustomModal>
      )}
    </>
  );

}