import { Tab } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Button, Form, Tabs } from "react-bootstrap";
import { fgetC, fpostC } from "../../axios/axios";
import styles from "./Settings.styles";

const Notifications = () => {
  const [updating, setUpdating] = useState(false);
  const [thR, setThR] = useState();
  const [thRemail, setThRemail] = useState("");
  const [tiD, setTiD] = useState();
  const [tiDemail, setTiDemail] = useState("");

  const [tiW, setTiW] = useState();
  const [tiWemail, setTiWemail] = useState("");

  const [tiM, setTiM] = useState();
  const [tiMemail, setTiMemail] = useState("");

  // useEffect(() => {
  //   getEmails();
  // }, []);

  const getEmails = async (e) => {
    try {
      const response = await fgetC(
        `${localStorage.getItem(
          "CYBOT_URL"
        )}/cybot_proxy/thp_notifications_platform/_search/`
      );
      console.log("search notif", response);
      // setUpdating(false);
    } catch (error) {
      // setUpdating(false);

      console.log("getSystemVar error in settings", error);
    }
  };

  const updateNotifications = async (e) => {
    e.preventDefault();
    setUpdating(true);
    let data = {
      doc: {
        threat_hunting_report: thR ? "yes" : "no",
        threat_hunting_report_email: thRemail,
        threat_intelligence_daily_email: "",
        threat_intelligence_individual: "no",
        threat_intelligence_monthly: tiM ? "yes" : "no",
        threat_intelligence_monthly_email: tiMemail,
        threat_intelligence_weekly: tiW ? "yes" : "no",
        threat_intelligence_weekly_email: tiWemail,
      },
    };
    try {
      const response = await fpostC(
        `${localStorage.getItem(
          "CYBOT_URL"
        )}/cybot_proxy/thp_notifications_platform/_update/`,
        data
      );
      console.log("update notif", response);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);

      console.log("getSystemVar error in settings", error);
    }
  };

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <Tabs
        defaultActiveKey="investigate"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="investigate" title="Investigate">
          <div
            className="col-6 d-flex flex-column gap-3"
            style={styles.tableWrap}
          >
            <span>
              This page is for updating the settings of notification emails that
              are being sent. Please enter or update the email ID to which the
              notification emails are to be sent.
            </span>
            <Form className="d-flex flex-column gap-3" onSubmit={updateNotifications}>
              <div className="d-flex flex-row gap-3">
                <Form.Group className="col-6" controlId="formBasicEmail">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    label="Daily L2 Incidents Report"
                    onChange={(e) => setThR(e.target.checked)}
                  />
                  <Form.Control
                    type="email"
                    placeholder=""
                    className=""
                    value={thRemail}
                    onChange={(e) => setThRemail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="flex-fill" controlId="formBasicEmail">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    label="Daily L1 Detections Report"
                    onChange={(e) => setTiD(e.target.checked)}
                  />
                  <Form.Control
                    type="email"
                    placeholder=""
                    className=""
                    value={tiDemail}
                    onChange={(e) => setTiDemail(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="d-flex flex-row gap-3">
                
                <Form.Group className="col-6" controlId="formBasicEmail">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    label="Threat intelligence monthly Email"
                    onChange={(e) => setTiM(e.target.checked)}
                  />
                  <Form.Control
                    type="email"
                    placeholder=""
                    className=""
                    value={tiMemail}
                    onChange={(e) => setTiMemail(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-12 d-flex flex-column justify-content-end">
                <Button
                  type="submit"
                  variant="dark"
                  className="col-2"
                // onClick={updateNotifications}
                >
                  {updating ? "Updating..." : "Update"}
                </Button>
              </div>
            </Form>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Notifications;
