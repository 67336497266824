import { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import {
  getIncomers,
  getOutgoers,
  useReactFlow,
} from "reactflow";
import { getProcessQuery } from '../../../Queries/CykitQuery';
import { fpostCy } from '../../../axios/axios';
import { shallow } from 'zustand/shallow';
import useStore from '../Store/store';
import { getMultiQuery } from '../../../Queries/CykitQuery';
import { File, Gear, Globe, Key } from 'phosphor-react';
import globalStyles from '../../../constants/globalStyles';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../Utils/Utils';
const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  setClick: state.setClick
});
const handleStyle = { left: 10 };

let baseURL = localStorage.getItem("DATALAKE_URL");;
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

function CustomNodeProcess({ data, isConnectable }) {
  const { getNodes, getEdges, getNode } = useReactFlow();
  const [expand, setExpand] = useState(false)
  const [lexpand, setLExpand] = useState(false)
  const [show, setShow] = useState(true)
  const [lshow, setLShow] = useState(true)
  const [inner, setInner] = useState([])
  const [outer, setOuter] = useState([])
  const [fileCount, setFileCount] = useState(0)
  const [registryCount, setRegistryCount] = useState(0)
  const [networkCount, setNetworkCount] = useState(0)
  const nodeById = getNode(data.id)
  const { nodes, edges, setClick } = useStore(selector, shallow);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [file, setFile] = useState()
  const [preload, setPreload] = useState(true)
  const [registry, setRegistry] = useState()
  const [network, setNetwork] = useState()
  useEffect(() => {
    const inner = (getIncomers(nodeById, getNodes(), getEdges()))
    if (inner?.length > 0 && preload) {
      setExpand(true)
    }

    const outer = (getOutgoers(nodeById, getNodes(), getEdges()))
    if (outer?.length > 0 && preload) {
      setLExpand(true)
    }

  }, [nodes])

  useEffect(() => {
    const Postdata = getProcessQuery(data, "forward")
    fpostCy(
      `${baseURL}/${indexPattern}`,
      Postdata)
      .then((response) => {
        console.log(response.data);

        if (response?.data?.aggregations?.unique_field_name?.buckets?.length > 0) {
          setShow(true)
        }
        else {
          setShow(false)
        }
      }
      )
    if ((data?.p_pid !== 0 && edr === "atp") || (data?.parents?.length > 0 && edr === "elastic")) {

      const Postdatarev = getProcessQuery(data, "backward")
      fpostCy(
        `${baseURL}/${indexPattern}`,
        Postdatarev)
        .then((response) => {
          if (response?.data?.hits?.hits?.length > 0) {
            setLShow(true)
          }
          else {
            setLShow(false)
          }
        })
    }
    else {
      setLShow(false)
    }

  }, [])

  function handleMouseEnter() {
    setIsMenuOpen(true);
  }

  function handleMouseLeave() {
    setIsMenuOpen(false);
  }
  useEffect(() => {

    if (edr === "atp") {
      var filetype = "AdvancedHunting-DeviceFileEvents"
      var networktype = "AdvancedHunting-DeviceNetworkEvents"
      var registrytype = "AdvancedHunting-DeviceRegistryEvents"

    }
    else if (edr === "elastic") {
      var filetype = "file"
      var networktype = "network"
      var registrytype = "registry"

    }

    var filedata = getMultiQuery(data.host_name, data.p_creation_time, data.pid, filetype, data?.process_id)

    var file = []
    var network = []
    var registry = []
    fpostCy(
      `${baseURL}/${indexPattern}`,
      filedata)
      .then((response) => {
        setFile(response?.data?.hits?.hits)
      })

    var netdata = getMultiQuery(data.host_name, data.p_creation_time, data.pid, networktype, data?.process_id)
    fpostCy(
      `${baseURL}/${indexPattern}`,
      netdata)
      .then((response) => {
        setNetwork(response?.data?.hits?.hits)

      })

    var regdata = getMultiQuery(data.host_name, data.p_creation_time, data.pid, registrytype, data?.process_id)
    fpostCy(
      `${baseURL}/${indexPattern}`,
      regdata)
      .then((response) => {
        setRegistry((registry) => response?.data?.hits?.hits)

      })
  }, [])

  useEffect(() => {
    if (registry !== undefined) {
      setRegistryCount(registry?.length)
    }
  }, [registry])
  useEffect(() => {
    if (file !== undefined) {
      setFileCount(file?.length)
    }
  }, [file])
  useEffect(() => {
    if (network !== undefined) {
      setNetworkCount(network?.length)
    }
  }, [network])

  // const date = new Date(data.p_creation_time);
  // const newTime = date.toLocaleString();
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "60px"
    }}>
      <div className="custom-node p-2" style={{
        borderRadius: "50%",
        background: globalStyles.greyDark1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "29px",
        height: "28px",
      }}>

        <Handle type="target" className="handleRight" position={Position.Right} style={{
          marginLeft: '2px', marginTop: '-9px'
        }} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
        <Gear size={24} color={globalStyles.white} />
        <Handle type="source" className="handleLeft" position={Position.Left} style={{
          marginLeft: '2px', marginTop: '-9px'
        }} />
      </div>

      {lshow ? (lexpand ?
        <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"
        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setLExpand(false); setPreload(false); data.onCollapse(data, "backward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12" /></svg>
        : <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"

        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setLExpand(true); setPreload(false); data.onClick(data, "backward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>
      ) :
        <svg style={{
          marginLeft: "-32px",
          marginTop: "-17px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px",
          visibility: 'hidden'

        }} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>}
      {show && (expand ?
        <svg style={{
          marginLeft: "30px",
          marginTop: "-8px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"
        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setExpand(false); setPreload(false); data.onCollapse(data, "forward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12" /></svg>
        : <svg style={{
          marginLeft: "30px",
          marginTop: "-8px",
          backgroundColor: "white",
          borderRadius: "50%",
          borderColor: "#1c64f2",
          borderWidth: "0.5px"

        }} xmlns="http://www.w3.org/2000/svg" onClick={() => { setExpand(true); setPreload(false); data.onClick(data, "forward") }} width="8" height="8" viewBox="0 0 24 24" fill="none" stroke="#1c64f2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16" /><line x1="8" y1="12" x2="16" y2="12" /></svg>
      )}
      <div style={{
        fontSize: '10px',
        paddingTop: '8px',
        justifyContent: "center",

      }} >
        <div onClick={() => setClick(data.doc_id)}>{data.label}</div>
      </div>
      <div style={{
        fontSize: '10px',
        color: globalStyles.greyDark3
      }} >
        PID:{data.pid}
      </div>
      <div className='d-flex flex-row gap-3 mt-2' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
        <div className='d-flex flex-row gap-1 align-items-center'>
          {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="grey" height="0.5em" width="0.5em" xmlns="http://www.w3.org/2000/svg" style={{ color: "grey" }}><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M22 19h-6v-4h-2.68c-1.14 2.42-3.6 4-6.32 4-3.86 0-7-3.14-7-7s3.14-7 7-7c2.72 0 5.17 1.58 6.32 4H24v6h-2v4zm-4-2h2v-4h2v-2H11.94l-.23-.67C11.01 8.34 9.11 7 7 7c-2.76 0-5 2.24-5 5s2.24 5 5 5c2.11 0 4.01-1.34 4.71-3.33l.23-.67H18v4zM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"></path></svg> */}
          <Key size={10} />
          <span style={{ fontSize: "10px" }}>{registryCount}</span>
        </div>
        <div className='d-flex flex-row gap-1 align-items-center'>
          {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" color="grey" height="0.5em" width="0.5em" xmlns="http://www.w3.org/2000/svg" style={{ color: "grey" }}><path d="M534 352V136H232v752h560V394H576a42 42 0 0 1-42-42zm-134 50c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zm296 294H328.1c-6.7 0-10.4-7.7-6.3-12.9l99.8-127.2a8 8 0 0 1 12.6 0l41.1 52.4 77.8-99.2a8.1 8.1 0 0 1 12.7 0l136.5 174c4.1 5.2.4 12.9-6.3 12.9z"></path><path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path><path d="M553.1 509.1l-77.8 99.2-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"></path></svg> */}
          <File size={12} />
          <span style={{ fontSize: "10px" }}>{fileCount}</span>
        </div>
        <div className='d-flex flex-row gap-1 align-items-center'>
          {/* <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="grey" height="0.5em" width="0.5em" xmlns="http://www.w3.org/2000/svg" style={{ color: "grey" }}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0"></path><path d="M12 3c1.333 .333 2 2.333 2 6s-.667 5.667 -2 6"></path><path d="M12 3c-1.333 .333 -2 2.333 -2 6s.667 5.667 2 6"></path><path d="M6 9h12"></path><path d="M3 19h7"></path><path d="M14 19h7"></path><path d="M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M12 15v2"></path></svg> */}
          <Globe size={12} />
          <span style={{ fontSize: "10px" }}>{networkCount}</span>
        </div>
        {isMenuOpen && (
          <div
            className='rounded-3 position-absolute my-3 p-2'
            style={{
              zIndex: '1000',
              width: '180px',
              top: 'calc(100% + 0.2rem)',
              backgroundColor: 'black',
              color: 'white',
              opacity: '80%',
              fontSize: '8px',
            }}>
            <div className="modal-row-item">
              <span className="modal-label col-5">Date</span>
              <span className="modal-text">
                {getDateFromTimestamp(data.p_creation_time)}
              </span>
            </div>
            <div className="modal-row-item">
              <span className="modal-label col-5">Time</span>
              <span className="modal-text">
                {getTimeFromTimestamp(data.p_creation_time)}
              </span>
            </div>
            {file &&
              <div className="modal-row-item">
                <span className="modal-label col-5">File events</span>
                <span className="modal-text">
                  {file?.length}
                </span>
              </div>
            }
            {registry &&
              <div className="modal-row-item">
                <span className="modal-label col-5">Registry events</span>
                <span className="modal-text">
                  {registry?.length}
                </span>
              </div>
            }
            {network &&
              <div className="modal-row-item">
                <span className="modal-label col-5">Network events</span>
                <span className="modal-text">
                  {network?.length}
                </span>
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomNodeProcess;