import React, { useEffect, useState,  } from 'react';
import { Document, Page, View, Text, Image , FlatList} from '@react-pdf/renderer';
import Header from '../Header';
import globalStyles from "../../../constants/globalStyles";


export default function VulnerabilityDoc({ imageBlob }) {
    const [vulnerabilityImageSrc, setVulnerabilityImageSrc] = useState(null);
   

   

    useEffect(() => {
        const loadImage = async () => {
            if (imageBlob instanceof Blob) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    console.log('Image data URL:', reader.result); // Log image data URL
                    setVulnerabilityImageSrc(reader.result);
                };
                reader.onerror = (error) => {
                    console.error('Error reading file:', error); // Log any errors
                };
                reader.readAsDataURL(imageBlob);
            }
        };
    
        loadImage();
    }, [imageBlob]);

    const styles = {
        page: { padding: '5px', fontFamily: 'Noto Sans Arabic' },
        title: { fontSize: '14px', fontWeight: '600', padding: '5px', width: 600 },
        image: {
            // width: '40%', // Adjust the width as needed
            // height: 'auto', // Maintain aspect ratio
            // justifyContent: 'center',
            padding:'1px',
            // marginTop: "5px"
        }
    };

    

    return (
        <Document>
        <Page style={styles.page}>
            <Header />
    
            <View style={{marginBottom: 10, marginTop:10}}>
                <Text style={styles.title}>Vulnerabilities</Text>
            </View>

            
                <View>
                   
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                    {vulnerabilityImageSrc && (
                        <Image
                            style={styles.image}
                            src={vulnerabilityImageSrc}
                        />
                    )}                    
                    </View>                  
             </View>            
        </Page> 
    </Document>
            
    );
}
