import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData, getSoftwareList, getUniqueVendorCount, getUniqueSoftwareCount, getUniqueVendorList, getHostList, getUniqueVulnsCount, getUniqueAssetsCount, getUniqueVulnsWithExploits, getVulnsBySeverityQuery, getVulnsWithExploitListQuery, getUniqueHostWithRiskScoreQuery, getVulnsBySeverityFor7Query, getVulnsBySeverityFor30Query, getVulnsBySeverityFor90Query, getvulnsOverTime, getvulnsPerHostListQuery, getvulnsListQuery, getLatestScan } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import styleVul from '../../../ManagementDashboard/Threatlandscape/ThreatLandscape.styles'
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import { isEmptyObject } from '../../../../Utils/Utils';
import { getFirwallTableQuery } from '../../../../Queries/CyKitInvestigateQuery';
import { totalUniqueSoftwareCountsQuery } from '../../../../Queries/InvestigationQuery';
import globalStyles from '../../../../constants/globalStyles';
import SearchBar from '../../../../components/Search/ManagementDashboard/SearchBar';
import ThreatLandScapeSideBarVulns from '../../../../components/ThreatLandScapeSideBar/ThreatLandScapeSideBarVulns';

import * as htmlToImage from 'html-to-image';
import Spinner from "react-bootstrap/Spinner";
import VulnerabilityDoc from '../../../../components/PDF/managementDashboard/VulnerabilityDoc';
import { PDFDownloadLink } from "@react-pdf/renderer";


// ChartJS.register(ArcElement, Tooltip, Legend);
import { Link } from 'react-router-dom';
import { State } from '../../../../Context';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
const INVESTIGATE_CY_VULNS = localStorage.getItem("INVESTIGATE_CY_VULNS");

const CyVulnsOverall = ({ vulnerabilitiesTwoRefImage, vulnerabilitiesRefImage, scanName ,scanDataLoader, setShowDownloadButton, showDownloadButton,vulnbySeverityDataOverall,setScanCurrent,vulnAge7Data, vulnAge30Data,vulnAge90Data,vulnsOverTime,scanCurrent,scanTotal,totaluniqueAssetCount, refvuln,setLoaderList,setImageBlobVuln,scanNames,loaderList }) => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);


    const [mostUsedSoftwareLoader, setMostUsedSoftwareLoader] = useState(true)


    const [totalUniqueAssetCount, settotalUniqueAssetCount] = useState(0)
    const [totalVulnsCount, settotalVulnsCount] = useState(0)
    const [totalVulnsWithExploit, setTotalVulnsWithExploit] = useState(0);
    const [vulnbySeverityLabel, setVulnbySeverityLabel] = useState([])
    const [vulnbySeverityData, setVulnbySeverityData] = useState([])
    const [vulnbySeverityDataLoader, setVulnbySeverityDataLoader] = useState(true)
    const [vulnHostScorelabel, setVulnHostScorelabel] = useState([])
    const [vulnHostScoreData, setVulnHostScoreData] = useState([])
    const [vulnAgeLoader, setVulnAgeLoader] = useState(true)
    const [vulnAge7, setVulnAge7] = useState([])
    const [vulnAge30, setVulnAge30] = useState([])
    const [vulnAge90, setVulnAge90] = useState([])
    const [totalVulnsWithExploitList, setTotalVulnsWithExploitList] = useState([]);
    const [vulnsList, setVulnsList] = useState([]);
    const [vulnsPerHostList, setVulnsPerHostList] = useState([]);
    const [vulnHostScoreLoader, setVulnHostScoreLoader] = useState(true);


    const [moderateGraphData, setModerateGraphData] = useState({})
    const [criticalGraphData, setCriticalGraphData] = useState({})
    const [severeGraphData, setSevereGraphData] = useState({})
    const [lowGraphData, setLowGraphData] = useState({})
    const [infoGraphData, setInfoGraphData] = useState({})

    const [vulnOverTimeLoader, setVulnOverTimeLoader] = useState(true)

    // source country

    const [vulnOverTime, setVulnOverTime] = useState([
        [
        ]
    ])

    // geo chart 
    const [title, setTitle] = useState();
    const [type, setType] = useState();

    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState('');

    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)


    // console.log("LABELL :", eventCateLabel)
    const [loadTableData, setLoadTableData] = useState(true)



    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);

    // search filter
    const [searchVulnerQuery, setSearchVulnerQuery] = useState()
    const [filteredListData, setFilteredListData] = useState([])
    const [vulnerLoader, setVulnerLoader] = useState(true)
    const [lastDate, setLastDate] = useState(null)


    const [searchVulnerHostQuery, setSearchVulnerHostQuery] = useState()
    const [filteredVulnerHostListData, setFilteredVulnerHostListData] = useState([])
    const [vulnerHostLoader, setVulnerHostLoader] = useState(true)


    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false)

    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclickedNodeDoc, setexpclickedNodeDoc] = useState()

    const [expclose, setExpClose] = useState(false)



    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    // Export doc
    const vulnRefImage = useRef(null);
    const [imageBlob, setImageBlob] = useState(null);
    const [vulnsLoaderList, setVulnsLoaderList] = useState([])
    const convertToBlob = () => {
        htmlToImage.toBlob(vulnRefImage.current).then((blob) => {
            setImageBlob(blob);
            setImageBlobVuln((prevState) => ({
                ...prevState,
                vulnerabilities: blob
              }))
        }).catch((error) => {
            console.error('Error converting to blob:', error);
        });
    };

    useEffect(()=>{
        console.log("imageeeeeeeee",imageBlob);
        if(scanDataLoader === false){ 
            
            if (loaderList?.length === scanNames?.length + 1 && loaderList?.length !== 0) {
                setTimeout(()=>{
                    convertToBlob()
                },2000)
            }
            
        }
    })
    useEffect(()=>{
            if(vulnbySeverityDataLoader&&vulnOverTimeLoader){
                setLoaderList((loader)=>[...loader,"vuln"])
            }
        },[vulnbySeverityDataLoader, vulnOverTimeLoader]
    )
    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-software'

    const { uniqueHost, setUniqueHost } = useContext(State);

    const getPercentage = (numbers) => {
        var total = numbers.reduce((sum, num) => sum + num, 0);
        var percentages = numbers.map(number => (number / total) * 100);
        return percentages
    }

    const days=90
    useMemo(() => {
        getLatestScan(scanName).then(({ data }) => {
            
            setLastDate(data?.hits?.hits?.[0]?._source?.["@timestamp"])
        }).catch((err) => {
            
        })
    },[])
    useEffect(() => {
        if(scanCurrent!==0&&scanTotal===scanCurrent){
            let result = vulnbySeverityDataOverall.current.reduce((acc, item) => {
                let existing = acc.find(x => x.key === item.key);
              
                if (existing) {
                  existing.vulnerability_count.value += item.vulnerability_count.value;
                } else {
                  acc.push({ ...item });
                }
              
                return acc;
              }, []);
              let label = [];
            let data = [];

            let getPercentageData = []
            result.map((data) => {
                getPercentageData.push(data?.vulnerability_count?.value)
            })
            let percentageArr = getPercentage(getPercentageData)
              if (result
                        .length > 0) {
                            result
                            .map((item, i) => {
                                label.push(item.key === "" ? "--" : `${item.key} - ${item?.vulnerability_count?.value} (${percentageArr[i].toFixed(2)} %)`);
                                data.push(item?.vulnerability_count?.value);
                                setVulnbySeverityLabel(label)
                                setVulnbySeverityData(data)
                            });
                        
                        setVulnbySeverityDataLoader(false)
                    }
                    else {
                        setVulnbySeverityLabel(label)
                        setVulnbySeverityData(data)
                        setVulnbySeverityDataLoader(false)
                    }
                let resultvulns7 = vulnAge7Data.current.reduce((acc, item) => {
                    let existing = acc.find(x => x.key === item.key);
                  
                    if (existing) {
                      existing.vulnerability_severity.value += item.vulnerability_severity.value;
                    } else {
                      acc.push({ ...item });
                    }
                  
                    return acc;
                  }, []);
                  setVulnAge7(resultvulns7)
                  let resultvulns30 = vulnAge30Data.current.reduce((acc, item) => {
                    let existing = acc.find(x => x.key === item.key);
                  
                    if (existing) {
                      existing.vulnerability_severity.value += item.vulnerability_severity.value;
                    } else {
                      acc.push({ ...item });
                    }
                  
                    return acc;
                  }, []);
                  setVulnAge30(resultvulns30)
                  let resultvulns90 = vulnAge90Data.current.reduce((acc, item) => {
                    let existing = acc.find(x => x.key === item.key);
                  
                    if (existing) {
                      existing.vulnerability_severity.value += item.vulnerability_severity.value;
                    } else {
                      acc.push({ ...item });
                    }
                  
                    return acc;
                  }, []);
                  setVulnAge90(resultvulns90)
            //       let resultOverTime = vulnOverTime?.current?.reduce((acc, item) => {
            //         let existing = acc.find(x => x.key_as_string === item.key_as_string);
                  
            //         if (existing) {
            //           item.severity_buckets.buckets.forEach(bucket => {
            //             let existingBucket = existing.severity_buckets.buckets.find(x => x.key === bucket.key);
            //             if (existingBucket) {
            //               existingBucket.vulnerability_count.value += bucket.vulnerability_count.value;
            //             } else {
            //               existing.severity_buckets.buckets.push({ ...bucket });
            //             }
            //           });
            //         } else {
            //           acc.push({ ...item });
            //         }
                  
            //         return acc;
            //       }, []);
                  
            // console.log("vulnsOver",resultOverTime)

            // const moderateData = { labels: [], data: [] };
            // const severeData = { labels: [], data: [] };
            // const criticalData = { labels: [], data: [] };
            // if (resultOverTime
            //     ?.length > 0) {
            //     const moderateData = { labels: [], data: [] };
            //     const severeData = { labels: [], data: [] };
            //     const criticalData = { labels: [], data: [] };
            //     const lowData = { labels: [], data: [] };
            //     const infoData = { labels: [], data: [] };
                    
            //     resultOverTime?.forEach(entry => {
            //         const date = entry.key_as_string;
            //         entry.severity_buckets.buckets.forEach(bucket => {
            //             const severity = bucket.key;
            //             const count = bucket.vulnerability_count.value;
            //             if (severity === 'Medium') {
            //                 moderateData.labels.push(date);
            //                 moderateData.data.push(count);
            //             } else if (severity === 'High') {
            //                 severeData.labels.push(date);
            //                 severeData.data.push(count);
            //             }
            //             else if (severity === 'Critical') {
            //                 criticalData.labels.push(date);
            //                 criticalData.data.push(count);
            //             }
            //             else if (severity === 'Low') {
            //                 lowData.labels.push(date);
            //                 lowData.data.push(count);
            //             }
            //             else if (severity === 'Info') {
            //                 infoData.labels.push(date);
            //                 infoData.data.push(count);
            //             }
            //         });
            //     });
            //     setInfoGraphData(infoData)
            //     setLowGraphData(lowData)
            //     setModerateGraphData(moderateData)
            //     setCriticalGraphData(criticalData)
            //     setSevereGraphData(severeData)
            //     setVulnOverTimeLoader(false)
            // }
            // else {
            //     setModerateGraphData(moderateData)
            //     setCriticalGraphData(criticalData)
            //     setSevereGraphData(severeData)
            //     setVulnOverTimeLoader(false)

            // }
            settotalUniqueAssetCount(totaluniqueAssetCount)
            setUniqueHost(totaluniqueAssetCount)
        }
    },[scanCurrent])


    useEffect(() => {
        // manageDatas.map((item) => {
        //     item.isSelected = false
        // })
        if(lastDate!=undefined){
        setLoadTableData(true)
        setVulnerLoader(true)
        setVulnerHostLoader(true)
        setSourceCountryLoader(true)
        setVulnOverTimeLoader(true)
        setVulnbySeverityDataLoader(true)
        setVulnHostScoreLoader(true)
        setVulnAgeLoader(true)
        getUniqueVulnsCount(scanName,days,lastDate).then(({ data: { aggregations } }) => {
            settotalVulnsCount(aggregations?.unique_vulns?.value)
            setMostUsedSoftwareLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })
        // getUniqueAssetsCount(scanName,days,lastDate).then(({ data: { aggregations } }) => {
        //     settotalUniqueAssetCount(aggregations?.unique_assets?.value)

        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        // getUniqueVulnsWithExploits(scanName,days,lastDate).then(({ data: { aggregations } }) => {
        //     setTotalVulnsWithExploit(aggregations?.unique_vulns?.value)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityQuery(scanName,days,lastDate).then(({ data: { aggregations } }) => {
        //     let label = [];
        //     let data = [];

        //     let getPercentageData = []
        //     aggregations?.unique_vulns.buckets?.map((data) => {
        //         getPercentageData.push(data?.vulnerability_count?.value)
        //     })
        //     let percentageArr = getPercentage(getPercentageData)
        //     if (aggregations?.unique_vulns.buckets
        //         .length > 0) {
        //         aggregations?.unique_vulns.buckets
        //             .map((item, i) => {
        //                 label.push(item.key === "" ? "--" : `${item.key} - ${item?.vulnerability_count?.value} (${percentageArr[i].toFixed(2)} %)`);
        //                 data.push(item?.vulnerability_count?.value);
        //                 setVulnbySeverityLabel(label)
        //                 setVulnbySeverityData(data)
        //             });
        //         vulnbySeverityDataOverall.current.push(...aggregations?.unique_vulns.buckets)
        //         setVulnbySeverityDataLoader(false)
        //     }
        //     else {
        //         setVulnbySeverityLabel(label)
        //         setVulnbySeverityData(data)
        //         setVulnbySeverityDataLoader(false)
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor7Query(scanName,lastDate).then(({ data: { aggregations } }) => {
        //     setVulnAge7(aggregations?.unique_vulnerability_titles?.buckets)
        //     vulnAge7Data.current.push(...aggregations?.unique_vulnerability_titles?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor30Query(scanName,lastDate).then(({ data: { aggregations } }) => {
        //     setVulnAge30(aggregations?.unique_vulnerability_titles?.buckets)
        //     vulnAge30Data.current.push(...aggregations?.unique_vulnerability_titles?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsBySeverityFor90Query(scanName,lastDate).then(({ data: { aggregations } }) => {
        //     setVulnAge90(aggregations?.unique_vulnerability_titles?.buckets)
        //     vulnAge90Data.current.push(...aggregations?.unique_vulnerability_titles?.buckets)
        //     setScanCurrent(scan=>scan+1)

        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getVulnsWithExploitListQuery(scanName,days,lastDate).then(({ data: { aggregations } }) => {
        //     setTotalVulnsWithExploitList(aggregations?.vulnerability_title?.buckets)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        getUniqueHostWithRiskScoreQuery(scanName, days,lastDate).then(({ data: { aggregations } }) => {
            let label = [];
            let data = [];

            if (aggregations?.vulnerability_title.buckets
                .length > 0) {
                aggregations?.vulnerability_title.buckets
                    .map((item) => {
                        label.push(item.key === "" ? "--" : item.key);
                        data.push(item?.max_riskScore?.value);
                        setVulnHostScorelabel(label)
                        setVulnHostScoreData(data)
                    });
                setVulnHostScoreLoader(false)
            }
            else {
                setVulnHostScorelabel(label)
                setVulnHostScoreData(data)
                setVulnHostScoreLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
        })
        getvulnsOverTime(scanName,lastDate).then(({ data: { aggregations } }) => {
            const moderateData = { labels: [], data: [] };
            const severeData = { labels: [], data: [] };
            const criticalData = { labels: [], data: [] };
            vulnsOverTime.current.push(...aggregations?.vulnerabilities_over_time?.buckets)
            if (aggregations?.vulnerabilities_over_time?.buckets
                .length > 0) {
                    try{
                const moderateData = { labels: [], data: [] };
                const severeData = { labels: [], data: [] };
                const criticalData = { labels: [], data: [] };
                const lowData = { labels: [], data: [] };
                const infoData = { labels: [], data: [] };

                aggregations?.vulnerabilities_over_time?.buckets.forEach(entry => {
                    const date = entry.key_as_string;
                    entry.severity_buckets.buckets.forEach(bucket => {
                        const severity = bucket.key;
                        const count = bucket.vulnerability_count.value;
                        if (severity === 'Medium') {
                            moderateData.labels.push(date);
                            moderateData.data.push(count);
                        } else if (severity === 'High') {
                            severeData.labels.push(date);
                            severeData.data.push(count);
                        }
                        else if (severity === 'Critical') {
                            criticalData.labels.push(date);
                            criticalData.data.push(count);
                        }
                        else if (severity === 'Low') {
                            lowData.labels.push(date);
                            lowData.data.push(count);
                        }
                        else if (severity === 'Info') {
                            infoData.labels.push(date);
                            infoData.data.push(count);
                        }
                    });
                });
                setInfoGraphData(infoData)
                setLowGraphData(lowData)
                setModerateGraphData(moderateData)
                setCriticalGraphData(criticalData)
                setSevereGraphData(severeData)
                setVulnOverTimeLoader(false)
                }
                catch(e){
                }
            }
            else {
                setModerateGraphData(moderateData)
                setCriticalGraphData(criticalData)
                setSevereGraphData(severeData)
                setVulnOverTimeLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
        })
        // getvulnsPerHostListQuery(scanName,lastDate).then(({ data: { aggregations } }) => {
        //     setVulnsPerHostList(aggregations?.unique_vendors?.buckets)
        //     setFilteredVulnerHostListData(aggregations?.unique_vendors?.buckets)
        //     setVulnerHostLoader(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
        // getvulnsListQuery(scanName,lastDate).then(({ data: { aggregations } }) => {
        //     setVulnsList(aggregations?.unique_vendors?.buckets)
        //     setFilteredListData(aggregations?.unique_vendors?.buckets)
        //     setVulnerLoader(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
    }
    }, [lastDate])

    useEffect(() => {
        if (searchVulnerQuery?.length === 0) {
            setVulnerLoader(true)
            getvulnsListQuery().then(({ data: { aggregations } }) => {
                setFilteredListData(aggregations?.unique_vendors?.buckets)
                setVulnerLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setVulnerLoader(false)
            })
        }
    }, [searchVulnerQuery,])

    useEffect(() => {
        if (searchVulnerHostQuery?.length === 0) {
            setVulnerHostLoader(true)
            getvulnsPerHostListQuery().then(({ data: { aggregations } }) => {
                setFilteredVulnerHostListData(aggregations?.unique_vendors?.buckets)
                setVulnerHostLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setVulnerHostLoader(false)
            })
        }
    }, [searchVulnerHostQuery])
    

    // imageblob
  const vulnerabilityRefImage = useRef(null);
 
  //   const [imageBlob, setImageBlob] = useState({
  //     software: null,
    
  // });
  
//   const convertToBlob = () => {
//     setImageBlob({
//         vulnerability: null,  
//     })
  
//         // ISO 27001 table image
//         htmlToImage.toBlob(vulnerabilityRefImage.current).then((blob) => {
  
//           setImageBlob((prevState) => ({
//               ...prevState,
//               vulnerability: blob,
//           }));
//       });
//   }
  
//   useEffect(() => {
//       setShowDownloadButton(false)
//       if (scanDataLoader === true ) {
//           setTimeout(() => {
//               convertToBlob()
//               setShowDownloadButton(true)
//           }, 10000)
//       }
//   }, [scanDataLoader])


    const severityChartData = {
        "labels": moderateGraphData?.labels,
        "datasets": [
            {
                "label": "Info",
                "data": infoGraphData?.data,
                borderColor: "#0278b8",
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            },
            {
                "label": "Low",
                "data": lowGraphData?.data,
                borderColor: "#5eae00",
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            },
            {
                "label": "Medium",
                "data": moderateGraphData?.data,
                borderColor: '#FFFF00',
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            },
            {
                "label": "High",
                "data": severeGraphData?.data,
                borderColor: '#FFA500',
                borderWidth: 1, // Change this l
                hoverOffset: 0,
            },
            {
                "label": "Critical",
                "data": criticalGraphData?.data,
                borderColor: "#FF0000",
                borderWidth: 1, // Change this line
                hoverOffset: 0,
            },
            
        ]
    };

    const vulnbySeverity = {
        labels: vulnbySeverityLabel,
        datasets: [
            {
                label: "",
                data: vulnbySeverityData,
                backgroundColor: [
                    // "rgba(255, 99, 132, 0.8)",
                    // "rgba(54, 162, 235, 0.8)",
                    // "rgba(255, 206, 86, 0.8)",
                    // "rgba(75, 192, 192, 0.8)",
                    // "rgba(153, 102, 255, 0.8)",
                    // "rgba(255, 159, 64, 0.8)",
                    "#0278b8",
                    "#FFA500",
                    "#FFFF00",
                    "#FF0000",
                    '#5eae00',
                ],
                borderColor: [
                    // "rgba(255, 99, 132, 1)",
                    // "rgba(54, 162, 235, 1)",
                    // "rgba(255, 206, 86, 1)",
                    // "rgba(75, 192, 192, 1)",
                    // "rgba(153, 102, 255, 1)",
                    // "rgba(255, 159, 64, 1)",
                    "#0278b8",
                    "#FFA500",
                    "#FFFF00",
                    "#FF0000",
                    '#5eae00',

                ],
                borderWidth: 0,
                hoverOffset: 0,
            },
        ],
    };

    return (
        <div className="col-12 ">

                        <div className="d-flex flex-row align-items-center justify-content-end ml-auto col-7">
                                    {imageBlob ? (
                                        <PDFDownloadLink document={<VulnerabilityDoc imageBlob={imageBlob}  />}  fileName={`Vulnerabilities_overview.pdf`}>
                                            {({ blob, url, loading, error }) =>
                                                <Button className='btn btn-primary'>Download</Button>
                                            }
                                        </PDFDownloadLink>  
                                    ):
                                    <PDFDownloadLink document={<VulnerabilityDoc imageBlob={imageBlob}  />}  fileName={`Vulnerabilities.pdf`}>
                                            {({ blob, url, loading, error }) =>
                                            <Button className='btn btn-light' disabled>
                                                 Download <Spinner animation="border" role="status" size="sm" />
                                               </Button>                                            }
                                        </PDFDownloadLink> 
                                    }                               
                                    </div>


            {/* table datas */}
            <div ref={refvuln}>
            <div ref={vulnerabilityRefImage} className="col-12 d-flex flex-column rounded-3 bg-white p-3 position-relative">

                {/* <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                // onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview"> */}
                <div ref={vulnRefImage} className='col-12 d-flex flex-column'>
                    <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                        <div ref={vulnRefImage} className="d-flex flex-column gap-3" style={styles.subSection2}>
                            <div ref={vulnerabilitiesRefImage}>
                                {/* <div className='d-flex flex-column col-10 py-4'>
                                    <h2>Vulnerabilities</h2>
                                </div> */}
                                
                                <div className='d-flex gap-3'>
                                    <div className="col-4 d-flex flex-column gap-3 mb-3 p-0 rounded-3 bg-white">
                                        <div className="rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4 style={styles.label}>Total Unique Vulnerabilities</h4>
                                                <span>{totalVulnsCount ? totalVulnsCount : "--"}</span>
                                            </div>
                                        </div>
                                        <div className="rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4 style={styles.label}>Total Unique Assets</h4>
                                                <span>{totalUniqueAssetCount ? totalUniqueAssetCount : "--"}</span>
                                            </div>
                                        </div>
                                        {/* <div className="rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{totalVulnsWithExploit ? totalVulnsWithExploit : "--"}</h4>
                                                <span style={styles.label}>Vulnerabilities with exploit</span>
                                            </div>
                                        </div> */}
                                        {/*<div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationPortCount ? destinationPortCount : "--"}</h4>
                                                <span>Unique Destination Ports</span>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="col-8 p-4 rounded-3 border">
                                        <h5>Vulnerabilities by Severity</h5>
                                        {vulnbySeverityDataLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {vulnbySeverityData?.length || vulnbySeverityData?.length !== 0 ? (
                                                    <Doughnut options={barChartoptions} ChartLabel={vulnbySeverityLabel} data={vulnbySeverity} ChartTitle={"Vulnerbility By Severity"} module={module} tab={tab} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>


                                <div class="d-flex flex-row">
                                    {totalVulnsWithExploitList?.length !== 0 &&
                                        <div className="col-6 p-4 rounded-3 border flex-fill" >
                                            <h5>Vulnerabilities with exploit</h5>
                                            <div className='mt-3' >
                                                {false ? (
                                                    <div className="col-6">
                                                        <TableLoader rowCount={25} />
                                                    </div>
                                                ) : totalVulnsWithExploitList?.length !== 0 ? (
                                                    <Table borderless hover>
                                                        <thead className="fixed-head">
                                                            <tr>
                                                                <th>Vulnerbility</th>
                                                                <th>Asset Count</th>
                                                                {/* <th className="text-center">Is SSO User</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Manage</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {totalVulnsWithExploitList?.map((item) => (
                                                                <tr onClick={() => { setExpClickedNode(item?.key); setExpClose(false); setTitle(`Hosts With: ${item.key}`); setType("vulns"); setexpclickedNodeDoc(item?.doc?.hits?.hits?.[0]) }}>
                                                                    <td>
                                                                        {item?.key}
                                                                    </td>
                                                                    <td>
                                                                        {item?.unique_asset?.value}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <NoDataFound errorText={"No data Found"} />
                                                )}
                                            </div>

                                        </div>
                                    }
                                    {vulnAge90?.length !== 0 &&
                                        <div className="col-6 p-4 rounded-3 border flex-fill mt-2">
                                            <h5>Vulnerability by Age</h5>

                                            <div className='mt-3' >
                                                {false ? (
                                                    <div className="col-12">
                                                        <TableLoader rowCount={25} />
                                                    </div>
                                                ) : vulnAge90?.length !== 0 ? (
                                                    <Table borderless hover>
                                                        <thead className="fixed-head">
                                                            <tr>
                                                                <th>Age</th>
                                                                <th>Critical</th>
                                                                <th>High</th>
                                                                <th>Medium</th>
                                                                <th>Low</th>
                                                                <th>Informational</th>

                                                                {/* <th className="text-center">Is SSO User</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Manage</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    &lt;7 days
                                                                </td>
                                                                <td>
                                                                    {vulnAge7?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value ? vulnAge7?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge7?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value ? vulnAge7?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge7?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value ? vulnAge7?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge7?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value ? vulnAge7?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge7?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value ? vulnAge7?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    &lt;30 days
                                                                </td>
                                                                <td>
                                                                    {vulnAge30?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value ? vulnAge30?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge30?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value ? vulnAge30?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge30?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value ? vulnAge30?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge30?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value ? vulnAge30?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge30?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value ? vulnAge30?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    &gt;90 days
                                                                </td>
                                                                <td>
                                                                    {vulnAge90?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value ? vulnAge90?.filter(item => item.key === "Critical")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge90?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value ? vulnAge90?.filter(item => item.key === "High")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge90?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value ? vulnAge90?.filter(item => item.key === "Medium")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge90?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value ? vulnAge90?.filter(item => item.key === "Low")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                                <td>
                                                                    {vulnAge90?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value ? vulnAge90?.filter(item => item.key === "Info")[0]?.vulnerability_severity?.value : 0}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <NoDataFound errorText={"No data Found"} />
                                                )}
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>
                            <div ref={vulnerabilitiesTwoRefImage}>
                                <div className='d-flex flex-row gap-2'>
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>Most Vulnerable Assets And Their Risk Score</h5>
                                        {false ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {vulnHostScoreData?.length || vulnHostScoreData?.length !== 0 ? (
                                                    <BarGraph options={barChartoptions} ChartLabel={vulnHostScorelabel} ChartData={vulnHostScoreData} ChartTitle={"Most Vulnerable Assets And Their Risk Score"} module={module} tab={tab} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                    <div className="col-6 p-4 rounded-3 border">
                                        <h5>Vulnerabilities Over Time</h5>
                                        {vulnOverTimeLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {vulnbySeverityData?.length || vulnbySeverityData?.length !== 0 ? (
                                                    <Line options={barChartoptions} data={severityChartData} ChartTitle={"Vulnerbility Over Time"} module={module} tab={tab} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>
                        
                            </div>

                        </div>
                    </div>
                </div>
                {/* </Tab>
                    <Tab eventKey="table" title="Details"> */}



                {/* </Tab>
                </Tabs> */}
            </div>
            </div>
            <ThreatLandScapeSideBarVulns clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CY_VULNS}/_search?track_total_hits=true`} req={fpostDl} title={title} type={type} expclickedNodeDoc={expclickedNodeDoc} />

        </div >
    )
}

export default CyVulnsOverall 