import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import styles from "./Activities.styles";
import { getAllActivities, getProcessList } from "../../../Methods/UebaMethods";
import {
  Form,
  Tab,
  Tabs,
  Table,
  Button,
  Badge,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DataTable from "../../../components/Investigation/DataTable";
import Pagination from "../../../components/Pagination/Pagination";
import TableLoader from "../../../components/Loader/TableLoader";

import AddFilter from "../../../components/AddFilter/AddFilter";
import FilterList from "../../../components/FilterList/FilterList";
import InlineFilter from "../../../components/InlineFilter/InlineFilter";
import { fgetCy, fpostCy } from "../../../axios/axios";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  getDateFromTimestamp,
  getTimeLineQuery, getTimeFromTimestamp,
  numberToLetter,
  rawHTML,
  uebaTimelineItem,
  uebaCykitTimelineItem,
} from "../../../Utils/Utils";
import SideBarCommon from "../../../components/Cykit/SideBarCommon";
import UebaCykitExport from "../../../components/Cykit/Export/UebaCykit";
import { Link } from "react-router-dom";
import { Graph, LineSegments, ArrowsOutSimple, Cat, Info } from "phosphor-react";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { TimelineLoader } from "../../../components/Loader/TimelineLoader";
import globalStyles from "../../../constants/globalStyles";
import Select from "react-select";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument";
import TreeComponent from "./TreeComponent";
import 'react-dropdown-tree-select/dist/styles.css'
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import UebaCykitTimelineItem from "../../../components/UEBA/UebaCykitTimelineItem";
import moment from 'moment';

const datalakeURL = localStorage.getItem("DATALAKE_URL");

const indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
const combinedIndexPattern = localStorage.getItem("CYBOT_CYKIT_COMBINED_PATTERN");
const INVESTIGATE_CYAD = localStorage.getItem("INVESTIGATE_CYAD");
var is_unified = localStorage.getItem("CYBOT_IS_UNIFIED");
is_unified = is_unified ? is_unified.toLowerCase() === 'true' : false

let edr = localStorage.getItem("EDR_TYPE");

const Activities = ({ selectedDateFilter }) => {

  const [activities, setActivities] = useState([]);
  if (is_unified) {
    var logSource = [
      { "value": "edr", "label": "EDR" },
      { "value": "ad", "label": "AD" },
      { "value": "o365", "label": "OFFICE 365" },
      { "value": "email", "label": "Email" }
    ]
  }
  else {
    var logSource = [
      { "value": "edr", "label": "EDR" },
    ]
  }
  var eventCategory = [
    { "value": "process", "label": "Process" },
    { "value": "authentication", "label": "Authentication / Logon" },
    { "value": "network", "label": "Network" },
    { "value": "file", "label": "File" },
    { "value": "registry", "label": "Registry" },

  ]
  var eventOfficeCategory = [
    { "value": "AzureActiveDirectory", "label": "Azure AD" },
    { "value": "OneDrive", "label": "One Drive" },
    { "value": "SharePoint", "label": "SharePoint" },
    { "value": "Exchange", "label": "Exchange" },
  ]

  // expand

  const [expand, setExpand] = useState([])
  const [doc, setDoc] = useState(null)
  // anomalies filter
  const [showList, setShowList] = useState(false);
  const [anomaliFilter, setAnomaliFilter] = useState("");
  const [dropDownClick, setDropDownClick] = useState(false);
  const [dataInit, setDataInit] = useState(true)
  const dropdownRef = useRef();
  const handleButtonClick = () => {
    // Open the dropdown when the button is clicked
    if (dropdownRef.current) {
      dropdownRef.current.openDropdown();
    }
  };

  const [kqlQuery, setKqlQuery] = useState("")

  const handleSearchQuery = (val, e, action) => {

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setKqlQuery(val);
    }
  };
  const handleClearClick = (e) => {
    setSearchQuery('');
    handleSearchQuery('', e, 'click')
  };
  var Catlist = [
    {
      label: "EDR",
      value: "edr",
      type: "edr",
      isRoot: true,
      checked: true,
      children: [
        {
          label: "Process",
          value: "process",
          parent: "edr",
          root: "edr",
          children: [
            { value: "end", label: "end", parent: "process", root: "edr" },
            { value: "start", label: "start", parent: "process", root: "edr" },
            { value: "fork", label: "fork", parent: "process", root: "edr" },
          ]
        },
        {
          label: "File",
          type: "edr",
          value: "file",
          parent: "edr",
          root: "edr",
          children: [
            { value: "creation", label: "creation", parent: "file", root: "edr" },
            { value: "deletion", label: "deletion", parent: "file", root: "edr" },
            { value: "overwrite", label: "overwrite", parent: "file", root: "edr" },
            { value: "rename", label: "rename", parent: "file", root: "edr" },
          ]

        },
        {
          label: "Network",
          type: "edr",
          value: "network",
          parent: "edr",
          root: "edr",
          children: [
            { value: "connection_attempted", label: "connection_attempted", parent: "network", root: "edr" },
            { value: "lookup_result", label: "lookup_result", parent: "network", root: "edr" },
            { value: "disconnect_received", label: "disconnect_received", parent: "network", root: "edr" },
            { value: "lookup_requested", label: "lookup_requested", parent: "network", root: "edr" },
          ]

        },
        {
          label: "Registry",
          type: "edr",
          value: "registry",
          parent: "edr",
          root: "edr",

          children: [
            { value: "modification", label: "modification", parent: "registry", root: "edr" },
            { value: "query", label: "query", parent: "registry", root: "edr" },
          ]

        },
        {
          label: "Authentication",
          type: "edr",
          parent: "edr",
          root: "edr",
          value: "authentication",
        },
      ]
    },
    {
      label: "Windows Events",
      value: "ad",
      isRoot: true,
      checked: true,
      children: [
        { "value": "Account", "label": "Account Management", parent: "ad", root: "ad" },
        { "value": "Logonoff", "label": "LogOn/Off", parent: "ad", root: "ad" },
        { "value": "Objectaccess", "label": "Object Access", parent: "ad", root: "ad" },
      ]
    },
    {
      label: "Office 365",
      value: "o365",
      isRoot: true,
      checked: true,
      children: [
        { "value": "AzureActiveDirectory", "label": "Azure AD", parent: "o365", root: "o365" },
        { "value": "OneDrive", "label": "OneDrive", parent: "o365", root: "o365" },
        { "value": "SharePoint", "label": "SharePoint", parent: "o365", root: "o365" },
        { "value": "Exchange", "label": "Exchange", parent: "o365", root: "o365" },
      ]
    },
    {
      label: "Mail Trace",
      value: "email",
      checked: true,
      isRoot: true,
      children: [
        { value: 'Delivered', label: 'Delivered', parent: "email", root: "email" },
        { value: 'Resolved', label: 'Resolved', parent: "email", root: "email" },
        { value: 'Failed', label: 'Failed', parent: "email", root: "email" },
        { value: 'Quarantined', label: 'Quarantined', parent: "email", root: "email" },
      ]
    },
  ]
  const defaultSelectedOptions = ['edr', 'ad'];

  const [activitiesData, setActivitiesData] = useState([]);
  const [showSystem, setShowSystem] = useState(false);
  const [useKql, setUseKql] = useState(false);

  const [processLoading, setProcessLoading] = useState(true);
  const [networkLoading, setNetworkLoading] = useState(true);
  const [fileLoading, setFileLoading] = useState(true);
  const [registryLoading, setRegistryLoading] = useState(true);
  const [activitiesLoading, setActivitiesLoading] = useState(true);
  const [authenticationLoading, setAuthenticationLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentPageCount, setCurrentPageCount] = useState(perPage);
  const [processTotalPageCount, setProcessTotalPageCount] = useState();

  const [networkPage, setNetworkPage] = useState(1);
  const [networkPerPage, setNetworkPerPage] = useState(100);
  const [networkcurrentPageCount, setNetworkCurrentPageCount] =
    useState(networkPerPage);
  const [networkTotalPageCount, setNetworkTotalPageCount] = useState();

  const [filePage, setFilePage] = useState(1);
  const [filePerPage, setFilePerPage] = useState(100);
  const [filecurrentPageCount, setFileCurrentPageCount] = useState(filePerPage);
  const [fileTotalPageCount, setFileTotalPageCount] = useState();

  const [registeryPage, setRegisteryPage] = useState(1);
  const [registeryPerPage, setRegisteryPerPage] = useState(100);
  const [registerycurrentPageCount, setRegisteryCurrentPageCount] =
    useState(registeryPerPage);
  const [registeryTotalPageCount, setRegisteryTotalPageCount] = useState();

  const [activitiesPage, setActivitiesPage] = useState(1);
  const [activitiesPerPage, setActivitiesPerPage] = useState(100);
  const [activitiescurrentPageCount, setActivitiesCurrentPageCount] =
    useState(activitiesPerPage);
  const [activitiesTotalPageCount, setActivitiesTotalPageCount] = useState();

  const [authenticationPage, setAuthenticationPage] = useState(1);
  const [authenticationPerPage, setAuthenticationPerPage] = useState(100);
  const [authenticationcurrentPageCount, setAuthenticationCurrentPageCount] =
    useState(authenticationPerPage);
  const [authenticationTotalPageCount, setAuthenticationTotalPageCount] =
    useState();

  const [activeTab, setActiveTab] = useState("timeline");
  // states for add filter
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [filter, setFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    from: moment().subtract(1, "days")?.format("yyyy-MM-DDTHH:mm"),
    to: moment().format("yyyy-MM-DDTHH:mm"),
    f: moment().subtract(1, "days")?.format("yyyy-MM-DDTHH:mm"),
    e: moment().format("yyyy-MM-DDTHH:mm"),
    range: 1
  });
  const [searchQuery, setSearchQuery] = useState("");


  const [showInlineFilter, setShowInlineFilter] = useState({
    show: false,
    index: 0,
  });

  const [expclickedNode, setExpClickedNode] = useState("");
  const [expclose, setExpClose] = useState(false);
  const [filterStore, setFilterStore] = useState(true);

  const [active, setActive] = useState(false);
  const [expClickedNode, setExpClickedItem] = useState(null);


  const activitiesTimeline = activitiesData.map((item) => {
    return Object.entries(item);
  });

  const [isAnomaly, setIsAnomaly] = useState(false);

  const [logSrc, setLogSrc] = useState(logSource);
  const [eventCat, setEventCat] = useState([
    { "value": "process", "label": "Process" },
    { "value": "authentication", "label": "Authentication / Logon" },
    { "value": "network", "label": "Network" },
    { "value": "file", "label": "File" },
    { "value": "registry", "label": "Registry" },
  ]);

  const [eventOfficeCat, setEventOfficeCat] = useState([
    { "value": "AzureActiveDirectory", "label": "Azure AD" },
    { "value": "OneDrive", "label": "OneDrive" },
    { "value": "SharePoint", "label": "SharePoint" },
    { "value": "Exchange", "label": "Exchange" },
  ]);

  // activities error state

  const [activitiesDataError, setActivitiesDataError] = useState([])

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
  });
  const refLog = useOnclickOutside(() => {
    setDropDownClick(false);
  });
  const [selectedDataTree, setSelectedDataTree] = useState([])

  // for filter
  var count = [];
  var count2;
  const module = "ueba";
  const tab = "cykit";
  var sidebarPattern = is_unified ? combinedIndexPattern : indexPattern?.replace(/\/_search/, "")
  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filters"));
    const currentPageFilter = storedFilter?.[module]?.[tab]?.fields;
    const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date;
    console.log("currentPageFilter", currentPageFilter, currentPageDateFilter);
    if (currentPageFilter?.length || currentPageDateFilter?.to) {
      count = currentPageFilter;
      count2 = currentPageDateFilter?.to;
      setDateFilter(currentPageDateFilter);
      setFilter(currentPageFilter);
      getOrCreateAlias()
      getFilteredTable(currentPageFilter, currentPageDateFilter);
    }
  }, []);

  const getOrCreateAlias = async () => {

    if (is_unified) {
      await fgetCy(
        `${localStorage.getItem("DATALAKE_URL")}/_alias/${combinedIndexPattern}`
      ).then(async (res) => {
        let pattern1 = indexPattern?.replace(/\/_search/, "");
        let pattern2 = INVESTIGATE_CYAD?.slice(1)
        let INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";
        let pattern3 = INVESTIGATE_o365?.slice(1)
        var data1 = {
          "actions": [
            {
              "add": {
                "index": pattern1,
                "alias": combinedIndexPattern
              }
            },
            {
              "add": {
                "index": pattern2,
                "alias": combinedIndexPattern
              }
            },
            {
              "add": {
                "index": pattern3,
                "alias": combinedIndexPattern
              }
            },
          ]
        }
        await fpostCy(
          `${localStorage.getItem("DATALAKE_URL")}/_aliases`,
          data1
        );
      })
        .catch(async (err) => {
          if (err.response.status === 404) {
            let pattern1 = indexPattern?.replace(/\/_search/, "");
            let pattern2 = INVESTIGATE_CYAD?.slice(1)
            let INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";
            let pattern3 = INVESTIGATE_o365?.slice(1)
            var data1 = {
              "actions": [
                {
                  "add": {
                    "index": pattern1,
                    "alias": combinedIndexPattern
                  }
                },
                {
                  "add": {
                    "index": pattern2,
                    "alias": combinedIndexPattern
                  }
                },
                {
                  "add": {
                    "index": pattern3,
                    "alias": combinedIndexPattern
                  }
                },
              ]
            }
            await fpostCy(
              `${localStorage.getItem("DATALAKE_URL")}/_aliases`,
              data1
            );
          }

          setAuthenticationLoading(false);
          console.log("Error", err);
        });
    }
  }
  useEffect(() => {
    // getAllActivities(20).then(({ data: { hits: { hits } } }) => {
    //   hits && setActivities(hits)
    // }).catch((err) => {
    //   console.log("Error", err)
    // })

    if (!count?.length && !count2) {
      // getOrCreateAlias()
      getFilteredTable(filter, dateFilter);
    }
  }, [
    filter,
    dateFilter,
    activitiesPage,
    isAnomaly,
    // logSrc,
    // eventCat,
    showSystem,
    // eventOfficeCat
    selectedDataTree,
    kqlQuery
  ]);


  async function getProcessList2(filter, date, type, page, size, is_anomaly, showSystem, selectedDataTree, kqlQuery) {
    let index_type;
    if (edr === "atp") {
      if (type === "process") {
        index_type = "AdvancedHunting-DeviceProcessEvents";
      } else if (type === "file") {
        index_type = "AdvancedHunting-DeviceFileEvents";
      } else if (type === "network") {
        index_type = "AdvancedHunting-DeviceNetworkEvents";
      } else if (type === "registry") {
        index_type = "AdvancedHunting-DeviceRegistryEvents";
      } else if (type === "authentication") {

        index_type = "AdvancedHunting-DeviceLogonEvents";
      } else {
        index_type = "";
      }
    } else if (edr === "elastic") {
      if (type === "process") {
        index_type = "process";
      } else if (type === "file") {
        index_type = "file";
      } else if (type === "network") {
        index_type = "network";
      } else if (type === "registry") {
        index_type = "registry";
      } else if (type === "authentication") {
        index_type = "authentication";
      } else {
        index_type = "";
      }
    }

    let data = getTimeLineQuery(
      filter,
      date,
      size,
      "desc",
      page,
      index_type,
      is_anomaly,
      is_unified,
      showSystem,
      selectedDataTree,
      kqlQuery
    );
    if (is_unified) {
      return await fpostCy(
        `${localStorage.getItem("DATALAKE_URL")}/${combinedIndexPattern}/_search`,
        data
      );
    }
    else {
      return await fpostCy(
        `${localStorage.getItem("DATALAKE_URL")}/${indexPattern}`,
        data
      );
    }
  }
  const getHost = (item) => {
    var ltype = getLogType(item)
    if (ltype === 'AD') {
      if ((item?._source.host?.name !== undefined) && (item?._source.host?.name !== "") && (item?._source.host?.name !== "-")) {
        return item?._source.host?.name
      }
      else {
        return item?._source.winlog?.computer_name
      }
    }
    else {
      return item?._source?.host?.name
    }
  }
  const getLogType = (item) => {
    let pattern1 = indexPattern?.replace(/\/_search/, "");
    var type = ""
    if (item._index.includes("messagetrace")) {
      type = "OFFICE 365 EMAIL"
    }
    else if (item._index.includes("auditlogs") && item?._source?.Workload === "AzureActiveDirectory") {
      type = "AZURE AD"
    }
    else if (item._index.includes("auditlogs") && item?._source?.Workload === "OneDrive") {
      type = "OneDrive"
    }
    else if (item._index.includes("auditlogs") && item?._source?.Workload === "Exchange") {
      type = "Exchange"
    }
    else if (item._index.includes("auditlogs") && item?._source?.Workload === "SharePoint") {
      type = "SharePoint"
    }
    else if (item._index.includes("winlog")) {
      type = "AD"
      // } else if (item._index.includes(pattern1.replace(/\*/g, ''))) {
    } else if (item._index.includes("logs-edr") || item._index.includes("logs-endpoint")) {
      type = "EDR"
    }


    return type
  }
  const getFilteredTable =
    async (filter, date) => {
      // setPage(1)
      // setNetworkPage(1)
      // setFilePage(1)
      // setRegisteryPage(1)
      if (filter || date?.to || selectedDataTree.length > 0) {
        setCurrentPageCount((page - 1) * perPage + perPage);
        setActivitiesLoading(true);
        await getProcessList2(
          filter,
          date,
          "",
          (activitiesPage - 1) * activitiesPerPage,
          100,
          isAnomaly,
          showSystem,
          selectedDataTree,
          kqlQuery
        ).then((res) => {

          if (!res?.isError) {
            let hits = res?.data?.hits

            setActivitiesTotalPageCount(hits.total.value);

            // hits.hits.map(
            //   (item) =>
            //   (item._source["date"] = getDateFromTimestamp(
            //     item._source["@timestamp"],
            //     "MMM Do YY"
            //   ))
            // );

            var timelineData = [];

            hits.hits.map((item) => {
              if (edr === "atp") {
                const regex = /user '([^']+)'/;
                const match = item?._source?.message?.match(regex);
                timelineData.push({
                  process_name: item._source.process?.name,
                  timestamp: item._source["@timestamp"],
                  object_type: "Process",
                  event_action: item._source?.event?.action ? item._source?.event?.action : item._source?.Operation,
                  event_category: item._source?.event?.category,
                  os: item._source.host.os?.name ? item._source.host.os?.name : item?._source?.["DeviceProperties.OS"],
                  host_name: getHost(item),
                  command: item._source.process?.command_line,
                  id: item._id,
                  user_name: item._source.user?.name ? item._source?.user?.name : match?.[1] ? match?.[1] : item._source?.UserId,
                  user_domain: item._source.user?.domain,
                  pid: item._source.process?.pid,
                  isDetected: item?._source?.Result,
                  LogType: getLogType(item),
                  reciever: item?._source?.["email.receiver.address"],
                  subject: item?._source?.subject,
                  event_code: item?._source?.event?.code,
                  subject_account: item?._source?.winlog?.event_data?.SubjectUserName,
                  target_account: item?._source?.winlog?.event_data?.TargetUserName,
                  share_name: item?._source?.winlog?.event_data?.ShareName,
                  target_file: item?._source?.winlog?.event_data?.RelativeTargetName,
                  email_status: item?._source?.["delivery.action"],
                  browser: item?._source?.["DeviceProperties.BrowserType"],
                  requested_type: item?._source?.["ExtendedProperties.RequestType"],
                  ClientIP: item?._source?.ClientIP,
                  srcCountry: item?._source?.cybSourceCountry,
                  event_source: item?._source?.EventSource,
                  source_file: item?._source?.SourceFileName,
                  platform: item?._source?.Platform,
                  mailbox_owner: item?._source?.MailboxOwnerUPN,
                  exchange_sub: item?._source?.["Item.Subject"],
                  SourceFileName: item?._source?.SourceFileName,
                  sender: item?._source?.["email.from.address"],
                  message: item?._source?.message,
                  registry_key: item?._source?.registry?.key,
                  registry_value: item?._source?.registry?.value?.name,

                  item: item?._source,
                  file_name: item?._source?.file?.name,
                  file_path: item?._source?.file?.path,
                  obj:item

                });
              } else if (edr === "elastic") {
                const regex = /user '([^']+)'/;
                const match = item?._source?.message?.match(regex);
                timelineData.push({
                  process_name: item._source?.process?.name,
                  timestamp: item._source["@timestamp"],
                  object_type: "Process",
                  event_action: item._source?.event?.action ? item._source?.event?.action : item._source?.Operation,
                  event_category: item._source?.event?.category?.[0],
                  os: item._source.host?.os?.name ? item._source.host.os?.name : item?._source?.["DeviceProperties.OS"],
                  host_name: getHost(item),
                  command: item._source.process?.command_line,
                  id: item._id,
                  user_name: item._source?.user?.name ? item._source?.user?.name : match?.[1] ? match?.[1] : item._source?.UserId,
                  user_domain: item._source?.user?.domain,
                  pid: item._source.process?.pid,
                  file_name: item?._source?.file?.name,
                  file_path: item?._source?.file?.path,
                  destination_address: item?._source?.destination?.address,
                  destination_port: item?._source?.destination?.port,
                  process_id: item?._source?.process?.pid,
                  command_line: item?._source?.process?.command_line,
                  registry_key: item?._source?.registry?.key,
                  registry_value: item?._source?.registry?.value,
                  isDetected: item?._source?.Result,
                  LogType: getLogType(item),
                  reciever: item?._source?.["email.receiver.address"],
                  subject: item?._source?.subject,
                  event_code: item?._source?.event?.code,
                  subject_account: item?._source?.winlog?.event_data?.SubjectUserName,
                  target_account: item?._source?.winlog?.event_data?.TargetUserName,
                  share_name: item?._source?.winlog?.event_data?.ShareName,
                  target_file: item?._source?.winlog?.event_data?.RelativeTargetName,
                  email_status: item?._source?.["delivery.action"],
                  browser: item?._source?.["DeviceProperties.BrowserType"],
                  requested_type: item?._source?.["ExtendedProperties.RequestType"],
                  ClientIP: item?._source?.ClientIP,
                  srcCountry: item?._source?.cybSourceCountry,
                  event_source: item?._source?.EventSource,
                  source_file: item?._source?.SourceFileName,
                  platform: item?._source?.Platform,
                  mailbox_owner: item?._source?.MailboxOwnerUPN,
                  exchange_sub: item?._source?.["Item.Subject"],
                  SourceFileName: item?._source?.SourceFileName,
                  sender: item?._source?.["email.from.address"],
                  message: item?._source?.message,
                  outcome: item?._source?.event?.outcome,
                  dest_country: item?._source?.destination?.geo?.country_name,
                  dest_region: item?._source?.destination?.geo?.region_name,
                  source_ip: item?._source?.source?.ip,
                  source_port: item?._source?.source?.port,
                  status: item?._source?.ResultStatus,
                  objectId: item?._source?.ObjectId,
                  item: item?._source,
                  obj:item

                });
              }
            });

            let groups = Object.create(null);
            let result;

            timelineData.forEach((element) => {
              groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")] =
                groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")] ||
                [];
              groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")].push(
                element
              );
            });

            result = Object.keys(groups).map((k) => {
              let temp = {};
              temp[k] = groups[k];
              return temp;
            });

            setActivitiesData(result);
            setActivitiesLoading(false);
          } else {
            setActivitiesDataError(res)
            setActivitiesLoading(false);
          }
        }).catch((err) => {
          setActivitiesLoading(false);
        })


      } else {
        setCurrentPageCount((page - 1) * perPage + perPage);
        setActivitiesLoading(true);
        await getProcessList2(
          "",
          "",
          "",
          (activitiesPage - 1) * activitiesPerPage,
          100,
          isAnomaly,
          logSrc,
          eventCat,
          showSystem,
          eventOfficeCat,
          kqlQuery
        ).then((res) => {
          // console.log("ACTIVITIESSS :", hits)
          if (!res.isError) {
            let hits = res?.data?.hits
            setActivitiesTotalPageCount(hits.total.value);

            var timelineData = [];

            console.log("edr", edr);
            hits.hits.map((item) => {
              if (edr === "atp") {
                const regex = /user '([^']+)'/;
                const match = item?._source?.message?.match(regex);
                timelineData.push({
                  process_name: item._source.process?.name,
                  timestamp: item._source["@timestamp"],
                  object_type: "Process",
                  event_action: item._source?.event?.action ? item._source?.event?.action : item._source?.Operation,
                  event_category: item._source?.event?.category,
                  os: item._source.host.os?.name ? item._source.host.os?.name : item?._source?.["DeviceProperties.OS"],
                  host_name: getHost(item),
                  command: item._source.process?.command_line,
                  id: item._id,
                  user_name: item._source.user?.name ? item._source?.user?.name : match?.[1] ? match?.[1] : item._source?.UserId,
                  user_domain: item._source.user?.domain,
                  pid: item._source.process?.pid,
                  isDetected: item?._source?.Result,
                  LogType: getLogType(item),
                  reciever: item?._source?.["email.receiver.address"],
                  subject: item?._source?.subject,
                  event_code: item?._source?.event?.code,
                  subject_account: item?._source?.winlog?.event_data?.SubjectUserName,
                  target_account: item?._source?.winlog?.event_data?.TargetUserName,
                  share_name: item?._source?.winlog?.event_data?.ShareName,
                  target_file: item?._source?.winlog?.event_data?.RelativeTargetName,
                  email_status: item?._source?.["delivery.action"],
                  browser: item?._source?.["DeviceProperties.BrowserType"],
                  requested_type: item?._source?.["ExtendedProperties.RequestType"],
                  ClientIP: item?._source?.ClientIP,
                  srcCountry: item?._source?.cybSourceCountry,
                  event_source: item?._source?.EventSource,
                  source_file: item?._source?.SourceFileName,
                  platform: item?._source?.Platform,
                  mailbox_owner: item?._source?.MailboxOwnerUPN,
                  exchange_sub: item?._source?.["Item.Subject"],
                  SourceFileName: item?._source?.SourceFileName,
                  sender: item?._source?.["email.from.address"],
                  message: item?._source?.message,
                  item: item?._source,
                  registry_key: item?._source?.registry?.key,
                  registry_value: item?._source?.registry?.value?.name,
                  file_name: item?._source?.file?.name,
                  file_path: item?._source?.file?.path,
                  obj:item


                });
              } else if (edr === "elastic") {
                const regex = /user '([^']+)'/;
                const match = item?._source?.message?.match(regex);
                timelineData.push({
                  process_name: item._source?.process?.name,
                  timestamp: item._source["@timestamp"],
                  object_type: "Process",
                  event_action: item._source?.event?.action ? item._source?.event?.action : item._source?.Operation,
                  event_category: item._source?.event?.category?.[0],
                  os: item._source?.host.os?.name ? item._source.host.os?.name : item?._source?.["DeviceProperties.OS"],
                  host_name: getHost(item),
                  command: item._source.process?.command_line,
                  id: item._id,
                  user_name: item._source?.user?.name ? item._source?.user?.name : match?.[1] ? match?.[1] : item._source?.UserId,
                  user_domain: item._source?.user?.domain,
                  pid: item._source.process?.pid,
                  file_name: item?._source?.file?.name,
                  file_path: item?._source?.file?.path,
                  destination_address: item?._source?.destination?.address,
                  destination_port: item?._source?.destination?.port,
                  destination_ip: item?._source?.destination?.ip,
                  process_id: item?._source?.process?.pid,
                  command_line: item?._source?.process?.command_line,
                  registry_key: item?._source?.registry?.key,
                  isDetected: item?._source?.Result,
                  LogType: getLogType(item),
                  reciever: item?._source?.["email.receiver.address"],
                  sender: item?._source?.["email.from.address"],
                  subject: item?._source?.subject,
                  event_code: item?._source?.event?.code,
                  subject_account: item?._source?.winlog?.event_data?.SubjectUserName,
                  target_account: item?._source?.winlog?.event_data?.TargetUserName,
                  share_name: item?._source?.winlog?.event_data?.ShareName,
                  target_file: item?._source?.winlog?.event_data?.RelativeTargetName,
                  email_status: item?._source?.["delivery.action"],
                  browser: item?._source?.["DeviceProperties.BrowserType"],
                  requested_type: item?._source?.["ExtendedProperties.RequestType"],
                  ClientIP: item?._source?.ClientIP,
                  srcCountry: item?._source?.cybSourceCountry,
                  event_source: item?._source?.EventSource,
                  source_file: item?._source?.SourceFileName,
                  platform: item?._source?.Platform,
                  mailbox_owner: item?._source?.MailboxOwnerUPN,
                  exchange_sub: item?._source?.["Item.Subject"],
                  SourceFileName: item?._source?.SourceFileName,
                  message: item?._source?.message,
                  outcome: item?._source?.event?.outcome,
                  dest_country: item?._source?.destination?.geo?.country_name,
                  dest_region: item?._source?.destination?.geo?.region_name,
                  registry_value: item?._source?.registry?.value,
                  source_ip: item?._source?.source?.ip,
                  source_port: item?._source?.source?.port,
                  status: item?._source?.ResultStatus,
                  objectId: item?._source?.ObjectId,
                  item: item?._source,
                  obj:item
                });
              }
            });

            let groups = Object.create(null);
            let result;

            timelineData.forEach((element) => {
              groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")] =
                groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")] ||
                [];
              groups[getDateFromTimestamp(element?.timestamp, "MMM Do YY")].push(
                element
              );
            });

            result = Object.keys(groups).map((k) => {
              let temp = {};
              temp[k] = groups[k];
              return temp;
            });
            setActivitiesData(result);
            setActivitiesLoading(false);
          } else {
            setActivitiesDataError(res)
            setActivitiesLoading(false);
          }

        }).catch((err) => {
          setActivitiesLoading(false);
        })

      }
    }

  const handleSelect = (e) => {
    if (e === "Anomalies") {
      setActivitiesPage(1)
      setIsAnomaly(true);
      setAnomaliFilter("Anomalies");
    } else {
      setActivitiesPage(1)
      setIsAnomaly(false);
      setAnomaliFilter("");
    }
  };

  const handleDetailsClick = (index) => {
    if (expand.includes(index)) {
      setExpand(expand.filter((i) => i !== index));
    } else {
      setExpand([...expand, index]);
    }
  };


  const onChange = useCallback((currentNode, selectedNodes) => {
    setActivitiesPage(1)
    setDataInit(false)
    setSelectedDataTree(selectedNodes)
  }, []);
  const toggleDropdown = () => {
    setDropDownClick((prevState) => !prevState);
  };
  const tooltip = (
    <Tooltip placement="bottom" id="tooltip" >
      <div className="text-justify ms-2">Hides Noise in Logs Like</div>
      <ul className="text-justify">
        <li className="">System Users</li>
        <li>NT Authoriy Domain AD Users</li>
        <li>Temporary Files</li>
        <li>Sharepoint System Users</li>
      </ul>
    </Tooltip>
  );

  const Kqltooltip = (
    <Tooltip placement="bottom" id="tooltip" >
      <div className="text-justify ms-2">Use KQL query to check for events</div>
      <ul className="text-justify">
        <li className="">Sample query : username: Tom AND file.name: invoice.pdf</li>
        <li>Wild card search: "invoice.pdf</li>
      </ul>
    </Tooltip>
  );

  const anomaliesTooltip = (
    <Tooltip placement="bottom" id="anomalies-tooltip">
      <div className="text-justify ms-2">Unusual Occurrences in Activities</div>

    </Tooltip>
  );


  // // Define a style object with the green background color
  // const greenBackgroundColor = {
  //   backgroundColor: 'green'
  // };


  const handleItemClick = (itemId, item) => {
    setActive(true); // Update this based on your logic
    setExpClickedItem(itemId)
    setExpClickedNode(itemId);
    setDoc(item.obj)
    setExpClose(false);
  };
  // "
  // console.log(item,"i");

  return (
    <>
      <div
        className="col-12 p-4 position-relative rounded-3 w-100"
        style={styles.section1}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-end ml-auto col-11"
          style={styles.filterWrap}
        >
          <div className="d-flex flex-column">
            {/* <Select className="mr-2" defaultValue={logSource} isMulti={is_unified ? true : false} isClearable={is_unified ? true : false} options={logSource} onChange={(event) => { setLogSrc(event) }}>
            </Select> */}
          </div>
          {/* <div className="multipleToggleButtonWrapp">
            <div className="col-4 active">All</div>
            <div className="col-4">Users</div>
            <div className="col-4">Hosts</div>
          </div>

          <div className="d-flex flex-row align-items-center col-3 mx-3">
            <Form.Select>
              <option>50 items</option>
              <option>100 items</option>
              <option>150 items</option>
            </Form.Select>
          </div>

          <Form.Control type="text" placeholder="Search..." className="mx-0" /> */}

          {useKql && <div
            className="me-4"
            style={{ width: "350px" }}
          >
            <InputGroup className="search-component">
              <FormControl
                type="search"
                className="me-2 custom-search-input"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => { handleSearchQuery(searchQuery, e, ''); }}
                value={searchQuery} />
              {searchQuery &&
                <span onClick={handleClearClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg custom-clear-button" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
              }

              <InputGroup.Text className="bg-white  " style={{ marginLeft: "-9px", cursor: "pointer" }} >
                <span onClick={(e) => { handleSearchQuery(searchQuery, e, 'click'); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </InputGroup.Text>
            </InputGroup>
          </div>}
          <OverlayTrigger placement="bottom" overlay={Kqltooltip}>
            <Form.Check
              className="mb-0"
              type="switch"
              id="toggle_kql"
              value={useKql}
              // label={showSystem ? "Hide System Users" : "Show all Users"}
              // defaultChecked
              onChange={(e) => {
                if (e.target.checked) {
                  setUseKql(true)
                }
                else {
                  setUseKql(false)
                }
              }}
            />
          </OverlayTrigger>
          {/* <label className="me-2" htmlFor="toggle_kql">Use KQL</label> */}
          <label className="me-2" htmlFor="toggle_noise_filter">Use KQL</label>

          {/* <OverlayTrigger placement="right" overlay={Kqltooltip}>
            <label className="me-2" htmlFor="toggle_noise_filter">Use KQL</label>
          </OverlayTrigger> */}


          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <Form.Check
              className="mb-0"
              type="switch"
              id="toggle_noise_filter"
              value={showSystem}
              // label={showSystem ? "Hide System Users" : "Show all Users"}
              // defaultChecked
              onChange={(e) => {
                if (e.target.checked) {
                  setActivitiesPage(1)
                  setShowSystem(true)
                }
                else {
                  setActivitiesPage(1)
                  setShowSystem(false)
                }
              }}
            />
          </OverlayTrigger>

          <label htmlFor="toggle_noise_filter">Data Noise Reduction</label>
          {/* <OverlayTrigger placement="right" overlay={tooltip}>
            <label htmlFor="toggle_noise_filter">Data Noise Reduction</label>
          </OverlayTrigger> */}

          <div className="position-relative d-flex">
            {/* <div>
              <Select
                isMulti
                options={options}
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </div> */}

            <div
              className="mx-2"
              onMouseOver={() => setShowList(true)}
              onMouseOut={() => setShowList(false)}
              style={{ zIndex: 2 }}
              type="button"
            >
              <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 h-100">
                {/* <span
                  style={{
                    borderRadius: "50%",
                    backgroundColor: dotColor,
                    height: "7px",
                    width: "7px",
                  }}
                /> */}
                <span>
                  View -{" "}
                  <strong>{anomaliFilter ? anomaliFilter : "ALL"}</strong>
                </span>
              </div>
              {showList && (
                <div className="pt-3 position-absolute" style={{ zIndex: 2 }}>
                  <div className="col-12 d-flex flex-column gap-2 bg-white rounded-3 shadow-sm p-3 px-4">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span
                        style={{
                          borderRadius: "50%",
                          backgroundColor: globalStyles.greyDark3,
                          height: "7px",
                          width: "7px",
                        }}
                      />
                      <span className="" onClick={() => handleSelect("")}>
                        All
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2">
                      <OverlayTrigger placement="right" overlay={anomaliesTooltip}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span
                            style={{
                              borderRadius: '50%',
                              backgroundColor: 'red', // Change this to your desired color
                              height: '7px',
                              width: '7px',
                              marginRight: '5px', // Adjust the spacing if needed
                            }}
                          />
                          <span onClick={() => handleSelect('Anomalies')}>Anomalies</span>
                        </div>
                      </OverlayTrigger>
                    </div>        </div>
                </div>
              )}
            </div>

          </div>

          {/* <div className="sticky-toolbar mx-2">
              <DropdownButton
                className="rounded-3"
                style={{ backgroundColor: "#F6F7FB" }}
                // title={selectedValue ? selectedValue : "All Hunt"}
                title={"All"}
                variant=""
                onSelect={handleSelect}
              >
                <Dropdown.Item eventKey="all">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.green,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>All</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="anomalies">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.red,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>Anomalies</span>
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div> */}

          <div className="position-relative">
            <Button
              // className="position-relative"
              ref={ref}
              onClick={() => setShowAddFilter(!showAddFilter)}
            >
              Add filter
            </Button>
            {showAddFilter && (
              <AddFilter
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setShowAddFilter={setShowAddFilter}
                getFilteredTable={getFilteredTable}
                setPage={setActivitiesPage}

                columnValues={[
                  { value: "host.name", label: "Host", isWildCard: true },
                  { value: "user.name", label: "User Name", isWildCard: true },
                ]}
                module={module}
                tab={tab}
              />
            )}
          </div>
          {is_unified && <div className="position-relative ml-2">

            <Button
              variant="light"
              onClick={() => { setDropDownClick((prevState) => !prevState); }}
            // onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
            >
              Log sources

            </Button>
            <div
              className="more-table-field-container ignore-onclickoutside"
              style={{
                "display": dropDownClick ? "block" : "none",
              }}
              ref={refLog}
            >
              <TreeComponent data={Catlist}
                setActivitiesPage={setActivitiesPage}
                setDataInit={setDataInit}
                setSelectedDataTree={setSelectedDataTree}
                // className="ignore-onclickoutside "
                showPartiallySelected={true}
                onChange={onChange}
                isOpen={dropDownClick} // Pass the showDropdown state to the isOpen prop
                onClose={toggleDropdown}
                showDropdown="always"
              />
            </div>
          </div>}

          <div className="position-relative ms-2">
            <UebaCykitExport
              getProcessList={getProcessList2}
              kqlQuery={kqlQuery} 
              is_anomaly={isAnomaly}
              is_unified={is_unified}
              type={activeTab}
              filter={filter}
              dateFilter={dateFilter}
              isAnomaly={isAnomaly}
              showSystem={showSystem}
              selectedDataTree={selectedDataTree}
            />
          </div>
          {activitiesTimeline.length !== 0 && (dataInit || selectedDataTree.length > 0) ? <Pagination
            page={activitiesPage}
            setPage={setActivitiesPage}
            perPage={activitiesPerPage}
            currentPageCount={activitiescurrentPageCount}
            totalDataCounts={activitiesTotalPageCount}
          /> :
            <Pagination
              page={0}
              setPage={setActivitiesPage}
              perPage={0}
              currentPageCount={0}
              totalDataCounts={0}
            />}
        </div>

        <div className="detections col-12">
          <Tabs
            defaultActiveKey="timeline"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={activeTab}
            onSelect={(e) => setActiveTab(e)}
          >
            <Tab
              eventKey="timeline"
              // title="Timeline"
              title={
                <React.Fragment>
                  Timeline
                  <Badge className="ml-2" bg="info">
                    {numberToLetter(activitiesTotalPageCount)}
                  </Badge>
                </React.Fragment>
              }
            >
              <div style={styles.tableWrap}>
                <FilterList
                  filter={filter}
                  setFilter={setFilter}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  getFilteredTable={getFilteredTable}
                  module={module}
                  tab={tab}
                />
                {/* <Pagination
              page={networkPage}
              setPage={setNetworkPage}
              perPage={networkPerPage}
              currentPageCount={networkcurrentPageCount}
              totalDataCounts={networkTotalPageCount}
            />
            {networkTotalPageCount} */}
                <div
                  className="d-flex col-6 position-relative"
                  style={styles.recentDetectionsWrap}
                >
                  <div className="d-flex col-12">
                    {activitiesLoading ? (
                      <div className="col-12">
                        <TimelineLoader />
                      </div>
                    ) : (
                      <>
                        {activitiesTimeline?.length !== 0 && (dataInit || selectedDataTree.length > 0) && !activitiesDataError?.isError ? (
                          <ul className="timeline-2 d-flex col-12 gap-3 flex-column pb-4">
                            {activitiesTimeline.map((item) => (
                              <li
                                className="d-flex flex-column gap-3 justify-content-center position-relative"
                                style={styles.detectionItem}
                              >
                                <span
                                  className="dateLabel"
                                  style={{ left: 0, top: 0 }}
                                >
                                  {getDateFromTimestamp(item[0][0])}
                                </span>
                                <div className="d-flex col-12 gap-3 flex-column px-3">
                                  {item[0][1].map((item, index) => (
                                    <>
                                      {/* {console.log('item', item)} */}
                                      <p key={item.id}
                                        className={`timeline-item rounded-3 cursor-pointer position-relative`}
                                        // style={active && expClickedNode === item.id ? greenBackgroundColor : {}}
                                        onClick={() => handleItemClick(item.id, item)}>
                                        {/* <span>{index}</span> */}

                                        {/* <span className="d-flex gap-3"> */}
                                        {/* <span
                                            className={item?.LogType === "AD" ? "alert-info col-12 d-flex align-items-center justify-content-center rounded-3 alert text-center py-1"
                                              : item?.LogType === "EDR" ? "alert-success col-12 d-flex align-items-center justify-content-center rounded-3 alert text-center py-1"
                                                : "alert-warning col-12 d-flex align-items-center justify-content-center rounded-3 alert text-center py-1"}
                                            style={{ width: "60px", height: "auto", fontSize: "8px", margin: "0px" }}
                                          >
                                            {item?.LogType}
                                          </span> */}
                                        {/* <span className="d-flex">
                                            {item?.LogType === "EDR" &&
                                              <span className="tile-icon rounded-3 alert alert-success">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbklEQVR4nO2VQQqAMAwE53kWn1n9mPUbNiJE8CIaaKxFF/aUZgeyh0Il9cAMyE0nIFgAyRAu6skC2Je83j8PkBO3A7jS+wHyd1CtAzH644AFiOrsAYiH+dAkIGvw6HUiKVmy+5cZjJAtvLMAimkFhGLchJWkQUIAAAAASUVORK5CYII=" />
                                              </span>
                                            }
                                            {item?.LogType === "AD" &&
                                              <span className="tile-icon rounded-3 alert alert-info">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAv0lEQVR4nO2TTQ6CMBBGH4lLE7eycMU5iGcycaW3waVLj4LnMIGlC2tqvhIERUrZoH7JpO30daZ/A78k07B/gpbWQC6z/dG0BDLg1niDE7AKCTwDNkChgCWwlZXyFWIs662zgtidH4C4NhfL505lWW+5q0g7mDTk2/ZdaIYk6FtUZmjxTTtBBOyBq9poJPahOXB8UVCLQLZSLvAC7NQa+UPYSm4nicZJx/36sK1Fn3y+7NNkHTJvfokP+0UJpqk7lc+buHCO53QAAAAASUVORK5CYII=" />
                                              </span>
                                            }
                                            {item?.LogType === "Exchange" &&
                                              <span className="tile-icon rounded-3 alert alert-warning">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+klEQVR4nOXVrUoFQRjG8R9YNAo2P7JNmwaDiogGowgGDYJg0jvwDhSL2ewtCEZBsJwomlawWM4djAxM2HBmdwfOougDE2YY3v/M88zL8B80jTWc4gYP+BxnoZAZxcoVCg2AUesf2O0TEFD1DQhtgBPsYQULWG4AXNdqTHQFRE1iFVs4zwBe0gEGI27VCljsYMEx3tN8WAqYxT3u8NQhg2jVZQmgrosWwFGyq3PIUfOp2eJ4bQG8pSyuxp3BYW0+KM0gvqJ1bGM/A3hO3g9LX9EBNrGEOUw1WBSDLe6D3js5/HZA1Segws6PfDg5zWADZ7jFI76yu/+cvgF7XBVgTEiJewAAAABJRU5ErkJggg==" />
                                              </span>
                                            }
                                            {item?.LogType === "SharePoint" &&
                                              <span className="tile-icon rounded-3 alert alert-warning">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABAUlEQVR4nOXVPUsEMRDG8V99anEg2thpLX4AC+W0kfsSIgiWdgqC5SGCKIJfRrQQSxsbe1+w0ELczhduRZhiC9fNym1zPjAEZpL5k8wk4T+ojXms4wineBxkorzEaqssUT7UgAyXmGwKkIddYbkO4AYdtMI2KwDf1sdOKmAOY9jDAXZLAK84wxbewr+UAhjBNF5qFPkw/E/oVgFWwzcRO/hMANwVYg9VgA8cYypiawmA20LsvgrwHmOGGYzWPKKVlCL3sB/Fnk0scj+670cVF3fjbWphAdeJbbpdlnwQF63zW/K/AjJcRLdpAlBLjQPaTX84ZRrHIjZwgnM8l84eOn0B1JroIfbX1PQAAAAASUVORK5CYII=" />
                                              </span>
                                            }
                                            {item?.LogType === "AZURE AD" &&
                                              <span className="tile-icon rounded-3 alert alert-warning">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVR4nOXVyytFURTH8U+UifLoUkp5DfwhFEoGomRo6J8xYcIfYSpjz0KMFBPJY8DAI9JVHG3tW8fNdc99GMiqNTprfX9r/1r7bP5LNGAWq7hBgjusYwqNtcAHcBChpXIffdXAB3FdBl7IcLLeSuDh2DsZ4YXci3aWtGIZl3hLeZ1UmJPfwUfwUCUwKcq1YngPHusET3BbLLBYR3gS7f0SJ3UWSHCE6YJA/hcEkpjhEn5uTbnCHXSiGRM4zihwGARWMhTmkUvZ2obzDH3Pobg/44o+xVs9HkWmMvTsFiYaxn3GY1/EnpYyde8YS29TNxZwhtcfGs9jfWuJ7y/YxqgKYginUTzc+hAzEbjlF6IjtX3ztcJycdoAbY8Pz1XqHWiqVWCphNcB3qUOMYeN+BML67wZbal58r8RH6qUE8caP0mWAAAAAElFTkSuQmCC" />
                                              </span>
                                            }
                                            {item?.LogType === "OneDrive" &&
                                              <span className="tile-icon rounded-3 alert alert-warning">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9UlEQVR4nO3TsUrEQBSF4a8SsRM7QSzsbMXeUn0lCzu1VLD1DfYJdqMPo4igq0iUVVGMBKYYs2N2Z9FGcuDAkNz5D/fmhk6dIu2gwAOecYEtv6R9fKJKeC8XtoIeSjyi3wKvwrvdHPh9AvKOM2xiIbg+n+AtjGsq9RLwITZa7ixjcdqAsgF/jeDN0dXnNZkaNgKOJ4zuDqe4xTWOMJcCr2KAj3BxhEMstYzuJ9chYzqPCkaJ/S4zAupOxvQUFRxEXRV4yYDXvkkFDKKC9fCsyATHvgp//rdv0A87PZ/oqprBl+k9SndV/UVA3FU1A3x7UkCnf6IvNP2xEJDHI/8AAAAASUVORK5CYII=" />
                                              </span>
                                            }
                                            {item?.LogType === "OFFICE 365 EMAIL" &&
                                              <span className="tile-icon rounded-3 alert alert-warning">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVR4nO3UTWpCMRiF4Yd2JtQFFIpbEApdg1KETroFt+AW3IJb6KSDrkIQ3EK5t12Ahc4qkQsRLhJt/EMH98CBJN+X80ISQqNr0jO+EI50iX4KUJ4gPEQXKcC6OMDnAaHfeK3NtwIqtTDGX0bwEhPcJXKSgA904loX0x3hczzF3nu85QAq/2KEW9xgiMU/9Z+NjJ2AtWd4jPUHvEdXY7E227I3CxBqZ9yu9ebcUTYg1J7eS3SR0b83IOzpBuD6jqg892fXPxGkQC8FaHQZrQADkRs4Frx/1QAAAABJRU5ErkJggg==" />
                                              </span>
                                            }
                                            {item?.LogType !== "EDR" && item?.LogType !== "AD" && item?.LogType !== "Exchange" && item?.LogType !== "SharePoint" && item?.LogType !== "AZURE AD" && item?.LogType !== "OneDrive" && item?.LogType !== "OFFICE 365 EMAIL" &&
                                              <span className="tile-icon rounded-3 alert alert-secondary">
                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbklEQVR4nO2VQQqAMAwE53kWn1n9mPUbNiJE8CIaaKxFF/aUZgeyh0Il9cAMyE0nIFgAyRAu6skC2Je83j8PkBO3A7jS+wHyd1CtAzH644AFiOrsAYiH+dAkIGvw6HUiKVmy+5cZjJAtvLMAimkFhGLchJWkQUIAAAAASUVORK5CYII=" />
                                              </span>
                                            }
                                          </span> */}


                                        {
                                          item?.LogType === "EDR" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                  {/* {uebaCykitTimelineItem(item?.LogType, item)} */}
                                                </div>
                                                {/* <span onClick={() => handleDetailsClick(index)}>
                                                  <TableViewMore Icon={Info} />
                                                </span> */}
                                              </div>
                                              {/* {expand.includes(index) && (
                                                <div className="col-12">
                                                  <span>Process ID <strong>{item?.process_id}</strong></span>
                                                  <span>Command <strong>{item?.command}</strong></span>
                                                  <span>File <strong>{item?.file_path}</strong></span>
                                                </div>
                                              )} */}
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "AD" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "AZURE AD" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "OneDrive" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "SharePoint" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "Exchange" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                        {
                                          item?.LogType === "OFFICE 365 EMAIL" && (
                                            <div className="col-12">
                                              <div className="d-flex col-12">
                                                <div className="col-12">
                                                  <UebaCykitTimelineItem active={active} expClickedNode={expClickedNode} item={item} log_source={item?.LogType} />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }

                                        {/* </span> */}
                                      </p >
                                    </>
                                  ))}
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <>
                            {activitiesDataError.isError ? (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                              >
                                <NoDataFound errorText={"No data found"} errorData={activitiesDataError} />
                              </div>
                            ) : (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                              >
                                <NoDataFound errorText={"No data found"} />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <ExpandedDocument clickedNode={expclickedNode} doc={doc} setDoc={setDoc} close={expclose} setActive={setActive} setClick={setExpClickedNode} url={datalakeURL} pattern={`${sidebarPattern}/_search?track_total_hits=true`} req={fpostCy} />
      </div >
    </>
  );
};

export default Activities;
