import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from "react-router-dom";
import {
    getDetectionDetails,
    getDetectionTimelineDatas,
    getShowAllTime,
    getDateFilter,
    getStatusFilter,
    getExecutionData,
    getUpdateDetectionNumberData,
    getCount,
    getAckCount
} from "../../../Methods/HunterMethods";
import { escapeHtmlChars, rawHTML, removeHtmlChars, timestampConvert } from "../../../Utils/Utils";
import { Badge, Button, Form, Tab, Tabs } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DownloadSimple, X } from "phosphor-react";
import { decode } from "html-entities";
import CustomModal from "../../../components/CustomModal/CustomModal";
import GroupedDetections from "../../../components/DetailsPage/GroupedDetections";
import styles from "./HunterDetection.styles";
import HunterLoader from "../../../components/Loader/HunterLoader";
import EditModal from "../../../components/CaseManagement/EditModal";
import DownloadPdf from "../../../components/PDF/DownloadPdf/DownloadPdf";
import { toastsSuccess, toastsDanger } from "../../../components/Toster/Toster";
import AlertBox from "../../../components/AlertBox/AlertBox";
import AddFilter from "../../../components/AddFilter/AddFilter";
import useOnclickOutside from "react-cool-onclickoutside";
import moment from "moment";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";





const HunterDetection = () => {
    const module = 'hunter'
    const tab = 'detection'
    const { hunt_id } = useParams()

    const [detectionDetails, setDetectionDetails] = useState();
    const [detectionTimelineData, setDetectionTimelineData] = useState([]);
    const [executionData, setExecutionData] = useState([]);

    const [loader, setLoader] = useState(true)

    // modal
    const [show, setShow] = useState(false);
    const [detailpage, setDetailpage] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [toggleStep, setToggleStep] = useState([]);

    // filter
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({});
    const [timelineIsLoading, setTimelineIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [stepDetails, setStepDetails] = useState([]);




    const ref = useOnclickOutside(() => {
        setShowFilter(false);

    });


    const [caseshow, setCaseShow] = useState(false)
    const [case_id, setCase_ID] = useState("");

    const [name, setName] = useState("");
    const [idd, setIdd] = useState("");
    const [desc, setDesc] = useState("");


    const [showLoader, setShowLoader] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const caseCreate = useSelector((state) => state.createCaseManagement);
    const { success: caseCreateSuccess } = caseCreate;

    const [alertCasePopup, setAlertCasePopup] = useState(false)

    const [alertMessage, setAlertMessage] = useState('')
    const [alertMessageVisibility, setAlertMessageVisibility] = useState(false)

    // ACK count
    const [totalDetection, setTotalDetection] = useState([])
    const [ackTime, setAckTime] = useState(null)
    const [afterAckCount, setAfterAckCount] = useState([])
    const [size, setSize] = useState(0)
    const [ackSize, setAckSize] = useState(0)
    const [detectionDisplay, setDetectionDisplay] = useState(false)
    const [ackDisplay, setAckDisplay] = useState(false)


    // for filter
    var count = [];
    var count2;

    const badgeStyle = {
        backgroundColor: '#17a2b8', // Adjust based on your 'info' color
        padding: '8px', // Increase padding for larger badge
        borderRadius: '50%', // Make badge round
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer', // Assuming it's clickable
        minWidth: '30px', // Adjust size as needed
        height: '30px', // Adjust size as needed
        color: 'white', // Text color, assuming white
        fontSize: '12px', // Font size, adjust as needed
        fontWeight: 'bold' // Font weight, adjust as needed
    };



    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields;
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date;
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter);
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to;
            // setDateFilter(currentPageDateFilter);
            // setFilter(currentPageFilter);
            // getFilteredTable([], currentPageDateFilter);
        }
    }, []);

    // useEffect(() => {
    //     toggleStep.map((item) => {
    //         getDateFilter(item, "", "", 100, "desc", "").then(({ data: { hits } }) => {
    //             console.log("FILTERRRRR:", hits);
    //         })
    //     })
    // }, [toggleStep, filter])




    useEffect(() => {
        setLoader(true)
        getDetectionDetails(hunt_id)
            .then(({ data }) => {
                setDetectionDetails([data]);

            })
            .catch((err) => {
                // console.log("Error", err);
            });

        // getDetectionTimelineDatas(hunt_id)
        //     .then(
        //         ({
        //             data: {
        //                 hits: { hits },
        //             },
        //         }) => {
        //             setDetectionTimelineData(hits);
        //             setLoader(false)
        //         }
        //     )
        //     .catch((err) => {
        //         setLoader(false)
        //         // console.log("Error", err);
        //     });

        getDetectionTimelineDatas(hunt_id)
            .then(({ data: { hits: { hits, total } } }) => {
                setDetectionTimelineData(hits);
                // setTotalDetection(total.value); // Setting totalDetection state here
                // setAfterAckCount(total.value);

                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                // Handle error if needed
                console.error("Error fetching detection timeline data:", err);
            });

        getExecutionData(hunt_id).then((res) => {
            let data = res?.data?.hits?.hits?.[0]
            console.log(data)
            setAckTime({ gte: data?._source?.checkpoint, lte: data?._source?.timestamp })
            console.log("test",{ gte: data?._source?.checkpoint, lte: data?._source?.timestamp })
            setExecutionData(data)
            if (executionData?._source?.new_detections === 0) {
                setAfterAckCount(0)
            }

            console.log('NEWW RESPONSE DATA :', res)
        })


        // ok
    }, []);


    useEffect(() => {
        // console.log('executionData :', executionData);

        // Helper function to handle async calls
        const fetchCounts = async (item) => {
            try {
                const countRes = await getCount(item?.inv_playbook, size);
                const ackCountRes = await getAckCount(item?.inv_playbook, ackTime, ackSize);

                const countData = countRes?.data?.hits?.total?.value || 0;
                const ackCountData = ackCountRes?.data?.hits?.total?.value || 0;

                // Set step details from getCount response
                setStepDetails(countRes?.data?.hits?.hits);

                return { countData, ackCountData };
            } catch (error) {
                console.error('Error fetching counts:', error);
                return { countData: 0, ackCountData: 0 };
            }
        };

        const fetchAllCounts = async () => {
            let totalDetectionCounts = [];
            let afterAckCounts = [];

            if (detectionDetails) {
                for (const items of detectionDetails) {
                    if (items?.steps) {
                        for (const item of items.steps) {
                            const { countData, ackCountData } = await fetchCounts(item);
                            totalDetectionCounts.push(countData);
                            afterAckCounts.push(ackCountData);
                            console.log("DATA!!!!", countData, ackCountData);
                        }
                    }
                }
                console.log("totalDetectiondetails:",detectionDetails)

                console.log("totalDetection:",totalDetectionCounts)
                // Update the states after all counts are fetched
                setTotalDetection(totalDetectionCounts);
                setAfterAckCount(afterAckCounts);
            }
        };

        fetchAllCounts();

    }, [detectionDetails,ackTime]);


    // case
    useEffect(() => {

        if (caseCreateSuccess === true) {
            console.log(caseCreateSuccess)
            setCaseShow(false)
            setAlertCasePopup(true)
            // toastsSuccess("Case created successfully")

        }
        // setTimeout(() => {
        //   dispatch(resetReduxData())
        // }, 2000);


    }, [caseCreateSuccess])


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 6000);

        return () => { clearTimeout(timer); }// Clear the timer if the component unmounts
    }, []);

    console.log(showButton);

    useEffect(() => {
        detectionDetails?.map((items) => (
            setName(items?.hunt_name),
            setIdd(items?.hunt_id),
            setDesc(items?.detailed_description)
        ))
    })

    const downloadPDF = () => {
        const doc = new jsPDF();

        // download the pdf document
        doc.save(`${detectionDetails[0]?.hunt_name}`);
    };

    // Add Case
    const handleCreate = () => {
        setCaseShow(true);
    };

    const handleOpenModal = (data, display, id) => {
        setDetailpage(data);
        setShow(display);
        setToggleStep(id)
        setDateFilter({})
    };

    function handleCloseModal() {
        setShow(false);
    }

    function handleToClose() {
        setShow(false);
        setDetectionDisplay(false);
        setStepDetails([]);
        setAckDisplay(false);
    }

    function renderModal() {
        return <GroupedDetections pageDetails={detailpage} />;
    }

    const handleToggleChange = (e) => {
        if (e.target.checked && show) {
            setToggle(true)
        }
        else {
            setToggle(false)
        }
    };

    // handleUpdateDetectionNumber

    const handleUpdateDetectionNumber = () => {
        setAfterAckCount(0)

        const now = new Date();
        // Get the year, month, date, hours, minutes, seconds, and milliseconds in UTC
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');
        // Format the UTC date and time as an ISO 8601 string
        const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        console.log("TIME>>>", formattedTime);

        // const now = new Date();       
        // // Get the year, month, date, hours, minutes, seconds, and milliseconds
        // const year = now.getFullYear();
        // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        // const day = String(now.getDate()).padStart(2, '0');
        // const hours = String(now.getHours()).padStart(2, '0');
        // const minutes = String(now.getMinutes()).padStart(2, '0');
        // const seconds = String(now.getSeconds()).padStart(2, '0');
        // const milliseconds = String(now.getMilliseconds()).padStart(3, '0');    
        // // Format the local date and time as an ISO 8601 string
        // const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;   
        // console.log("TIME>>>", formattedTime);


        // setAckTime(formattedTime)
        // detectionDetails?.map((items) => (
        //     items?.steps?.map((item, index) => (
        //         getCount(item?.inv_playbook),
        //         getAckCount(item?.inv_playbook,ackTime)
        //         ))
        //      ))
        getUpdateDetectionNumberData(executionData?._index, executionData?._id, formattedTime, executionData).then((res) => {
            setAlertMessageVisibility(true)
            setAlertMessage('Acknowledged')
        }).catch((err) => {
            console.log('Error', err)
        })
    }


    const getFilteredTable = useCallback(async (filter, date = {}) => {
        setTimelineIsLoading(true)
        if (filter || date?.to) {

            await getDateFilter(toggleStep, filter, date, 100, "desc", "").then(({ data: { hits } }) => {
                console.log("FILTERRRRR:", hits.hits);
                setStepDetails(hits.hits)
                setTimelineIsLoading(false)
                console.log("t1", hits.hits)
                // setTotalPageCount(hits.total.value)
            }).catch((err) => {
                setTableData([])

                setTimelineIsLoading(false)

            })
        }
        else {
            const allItems = toggleStep.map(item => item);

            getShowAllTime(allItems)
                .then((res) => {
                    setStepDetails(res.data.hits.hits);
                    setTimelineIsLoading(false)
                    console.log("hhh", stepDetails);
                })
            // await toggleStep.map((item) => {
            // getShowAllTime(toggleStep)
            //     .then((res) => {
            //         setStepDetails(res.data.hits.hits)
            //         setTimelineIsLoading(false)
            //         console.log("hhh", stepDetails);
            //     })
            // })
        }
    }, [toggleStep, filter, dateFilter, stepDetails])




    // const handleTotalDetectionsClick = async (inv_playbook) => {
    //     console.log("HAHAHAHA",inv_playbook);
    //     setStepDetails([]);
    //     setSize(1000);
    //     setDetectionDisplay(true);
    //     setShow(true); // Assuming you want to show something when clicked

    //     try {
    //       // Fetch counts for the specified inv_playbook
    //       const [countRes] = await Promise.all([
    //         getCount(inv_playbook, size),
    //         // getAckCount(inv_playbook, ackTime, ackSize)
    //       ]);

    //       // Update stepDetails state with the counts data
    //       const stepDetails = [
    //         ...countRes?.data?.hits?.hits,
    //         // ...ackCountRes?.data?.hits?.hits
    //       ];
    //       setStepDetails(stepDetails);
    //       console.log("HAHAHAHA",stepDetails);

    //     } catch (error) {
    //       console.error('Error fetching counts:', error);
    //       // Handle errors as needed
    //     }
    //   };

    const handleTotalDetectionsClick = async (inv_playbook) => {
        console.log("HAHAHAHA", inv_playbook);

        // Reset state and show the loading indicator
        setStepDetails([]);
        // setSize(1000);
        setDetectionDisplay(true);
        setShow(true);

        try {
            // Fetch counts for the specified inv_playbook
            const countRes = await getCount(inv_playbook, 1000);

            // Update stepDetails state with the counts data
            const stepDetails = countRes?.data?.hits?.hits || [];
            setStepDetails(stepDetails);
        } catch (error) {
            console.error('Error fetching counts:', error);
            // Handle errors as needed
        }
    };

    const handleAfterAcknowledgedCountClick = async (inv_playbook) => {
        // setAckSize(1000);
        setDetectionDisplay(true);
        setShow(true);
        try {
            // Fetch counts for the specified inv_playbook
            const [ackCountRes] = await Promise.all([
                //   getCount(inv_playbook, size),
                getAckCount(inv_playbook, ackTime, 1000)
            ]);

            // Update stepDetails state with the counts data
            const stepDetails = [
                //   ...countRes?.data?.hits?.hits,
                ...ackCountRes?.data?.hits?.hits
            ];
            setStepDetails(stepDetails);
        } catch (error) {
            console.error('Error fetching counts:', error);
            // Handle errors as needed
        }
    };


    return (
        <>

            {loader ? <HunterLoader /> : (
                <div className="col-12 gap-3 d-flex flex-row bg-grey">
                    {detectionDetails?.map((items) => (


                        <>
                            <div
                                className="d-flex col-3 flex-column observable-datas rounded-3 p-4"
                                style={styles.section1}
                            >
                                <span className="modal-row-item flex-column">
                                    <span className="modal-label col-3">Hunt ID</span>
                                    <span className="modal-text">{items?.hunt_id}</span>
                                </span>
                                <span className="modal-row-item flex-column">
                                    <span className="modal-label col-3">Hunt name</span>
                                    <span className="modal-text">{items?.hunt_name}</span>
                                </span>
                                <span className="modal-row-item flex-column">
                                    <span className="modal-label col-3">Description</span>
                                    <span className="modal-text">{removeHtmlChars(items?.detailed_description)}</span>
                                </span>

                                {executionData?._source?.timestamp && (
                                    <span className="modal-row-item flex-column">
                                        <span className="modal-label col-4">Last Execution Time</span>
                                        <span className="modal-text">{moment(executionData?._source?.timestamp).format('DD-MM-YYYY HH:mm:ss')}</span>
                                    </span>
                                )}

                                {executionData?._source?.last_acknowledged_time && (
                                    <span className="modal-row-item flex-column">
                                        <span className="modal-label col-4">Last Acknowledged Time</span>
                                        <span className="modal-text">{moment(executionData?._source?.last_acknowledged_time).format('DD-MM-YYYY HH:mm:ss')}</span>
                                    </span>
                                )}
                            </div>

                            <div className="d-flex flex-column gap-3 col-9">
                                <div className="d-flex bg-white rounded-3 p-3" >
                                    <div className="d-flex ml-auto gap-2">
                                        {console.log('executionData?._source?.new_detections :', executionData?._source?.new_detections)}
                                        <Button
                                            variant="light"
                                            onClick={(e) => handleUpdateDetectionNumber()}
                                        // disabled={executionData?._source?.new_detections == 0 ? true : false}
                                        >
                                            Acknowledged
                                        </Button>
                                        <Button
                                            variant="light"
                                            onClick={(e) => handleCreate()}
                                        >
                                            Create Case
                                        </Button>
                                        {showButton ?

                                            <DownloadPdf detectionDetails={detectionDetails} detectionTimelineData={detectionTimelineData} />
                                            : <Button>Loading</Button>}
                                    </div>
                                </div>

                                <div className="d-flex flex-row gap-3 " style={styles.section2}>
                                    <div
                                        className="col-5 d-flex flex-column observable-datas gap-3 rounded-3 p-4"
                                        style={styles.section1}
                                    >
                                        {items?.steps?.map((item, index) => (
                                            <>
                                                <div className="col-12 border p-3 rounded-3 bg-white ">
                                                    <div className="d-flex justify-content-between p-2">
                                                        <h6 className="">{"Step " + item?.step}</h6>
                                                        {/* {detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source.step ==
                                                            item.step ? (
                                                            detectionTimelineData[index]?._source.detection_status &&
                                                                detectionTimelineData[index]?._source.detection_status?.toLowerCase() === "new" ?
                                                                <Badge bg="info" className="p-2">New</Badge>
                                                                : <Badge bg="success" className="p-2">Closed</Badge>
                                                        ) : ""} */}
                                                        {/* <span>{detectionTimelineData[index]?._source.detection_status === "New" ? "New" : 'Close'}</span>} */}
                                                        <div style={{ display: 'inline-flex' }}>
                                                            <CustomToolTip content={
                                                                <Badge
                                                                    bg="info" // Background color, adjust based on your theme
                                                                    className="d-flex align-items-center"
                                                                    // title={`Total detections`}
                                                                    onClick={() => handleTotalDetectionsClick(item.inv_playbook)}
                                                                    style={badgeStyle} // Apply the custom styles defined above
                                                                >
                                                                    {totalDetection[index]}
                                                                </Badge>
                                                            } dataPlacement={"bottom"}
                                                                dataToggle={
                                                                    <div style={styles.tooltipWrap}>
                                                                        Total detections
                                                                    </div>
                                                                }>
                                                            </CustomToolTip>

                                                            <CustomToolTip content={
                                                                <Badge
                                                                    bg="info"
                                                                    className="d-flex align-items-center"
                                                                    // title={`After Acknowledged Count`}
                                                                    onClick={() => handleAfterAcknowledgedCountClick(item.inv_playbook)}
                                                                    style={{ ...badgeStyle, marginLeft: '5px' }}>
                                                                    {afterAckCount[index]}
                                                                </Badge>
                                                            } dataPlacement={"bottom"}
                                                                dataToggle={
                                                                    <div style={styles.tooltipWrap}>
                                                                        After Acknowledged Count
                                                                    </div>
                                                                }>
                                                            </CustomToolTip>
                                                        </div>

                                                    </div>
                                                    <div className="modal-row-item flex-column">
                                                        <span className="modal-label col-3">Name</span>
                                                        <span className="modal-text">{item?.name}</span>
                                                    </div>
                                                    <div className="modal-row-item flex-column">
                                                        <span className="modal-label col-3">Description</span>
                                                        <span className="modal-text">
                                                            {escapeHtmlChars(item?.description, /<\/?p>/g)}
                                                        </span>
                                                    </div>
                                                    <div className="modal-row-item flex-column">
                                                        {/* {console.log('detectionTimelineData[index] = ', detectionTimelineData[index])}
                                                {console.log('item = ', item)} */}
                                                        {/* {console.log('detectionTimelineData[index] = ' + detectionTimelineData[index]?._source.step + " | item.step = " + item.step)} */}
                                                        {/* {console.log('detectionTimelineData[index] = ' + detectionTimelineData[parseInt(item.step - 1)]?._source.step + " | item.step = " + item.step)} */}
                                                        {detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source.step ==
                                                            item.step ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleOpenModal(
                                                                        detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source,
                                                                        true, item.inv_playbook
                                                                    )
                                                                }
                                                                className="rounded-3 alert alert-danger p-3 mb-0"
                                                            >
                                                                <h6>
                                                                    {
                                                                        timestampConvert(detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source
                                                                            .detection_timestamp, 'LLL')
                                                                    }
                                                                </h6>
                                                                <p className="mb-0">
                                                                    {rawHTML(decode(
                                                                        detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source
                                                                            .description
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 text-center rounded-3 alert alert-success p-3 mb-0">
                                                                No detection found!
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>

                                    <div
                                        className="d-flex flex-fill gap-3 flex-column col-7 rounded-3 p-4 bg-white"
                                        style={styles.section1}
                                    >
                                        <div className="d-flex col-12 position-relative">
                                            {show && (
                                                <div className="col-12 d-flex flex-column">
                                                    <div className="d-flex justify-content-between">
                                                        <div></div>
                                                        {/* <div className="d-flex align-items-center mb-0">
                                                            <Form.Check
                                                                className="me-2"
                                                                type="switch"
                                                                id="toggle_kql"
                                                                // value={useThirdParty}
                                                                onChange={handleToggleChange}
                                                            />
                                                            <label className="me-2 mb-0"><b>Show All Time</b></label>
                                                        </div> */}
                                                        <div className="d-flex ">
                                                            <div className="p-2 d-flex mb-3 cursor-pointer" style={{ marginLeft: "auto" }}>
                                                                <Button
                                                                    ref={ref}
                                                                    onClick={() => { setShowFilter(!showFilter) }}
                                                                >
                                                                    Add filter
                                                                </Button>
                                                                {showFilter && (
                                                                    <div style={{ position: "absolute", zIndex: 9999 }}>
                                                                        <AddFilter
                                                                            filter={filter}
                                                                            setFilter={setFilter}
                                                                            dateFilter={dateFilter}
                                                                            setDateFilter={setDateFilter}
                                                                            setShowAddFilter={setShowFilter}
                                                                            getFilteredTable={getFilteredTable}
                                                                            // setPage={setAttentionPage}
                                                                            //  columnValues={columnValues.length > 0 ? columnValues : defaultColumnValues}
                                                                            columnValues={[
                                                                                { value: "detection_status", label: "Status", },

                                                                                // { value: "detection_status", label: "Detection Status" },

                                                                            ]}
                                                                            module={module}
                                                                            tab={tab}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {/* <Link onClick={handleToClose}>
                                                        <X size={20} />
                                                    </Link> */}
                                                            <span className="p-2 d-flex mb-3 cursor-pointer" style={{ width: "30px", height: "30px", marginLeft: "auto" }} onClick={handleToClose}>
                                                                <X size={20} />
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div style={{ flex: "1" }}>
                                                        <GroupedDetections
                                                            pageDetails={detailpage}
                                                            toggle={toggle}
                                                            toggleStep={toggleStep}
                                                            filter={filter}
                                                            setFilter={setFilter}
                                                            dateFilter={dateFilter}
                                                            setDateFilter={setDateFilter}
                                                            setShowAddFilter={setShowFilter}
                                                            timelineIsLoading={timelineIsLoading}
                                                            setTimelineIsLoading={setTimelineIsLoading}
                                                            tableData={tableData}
                                                            setTableData={setTableData}
                                                            stepDetails={stepDetails}
                                                            setStepDetails={setStepDetails}
                                                            getFilteredTable={getFilteredTable}
                                                            module={module}
                                                            tab={tab}
                                                            detectionDisplay={detectionDisplay}
                                                            setDetectionDisplay={setDetectionDisplay}
                                                        />

                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}




                </div>


            )}
            {console.log(idd)}
            {alertMessageVisibility && (
                <AlertBox setAlertPopup={setAlertMessageVisibility} alertPopup={alertMessageVisibility} message={alertMessage} bgColor={"lightGreen"} />
            )}

            {alertCasePopup && (
                <AlertBox setAlertPopup={setAlertCasePopup} alertPopup={alertCasePopup} message={"Case created successfully"} bgColor={"lightGreen"} />
            )}

            {caseshow && (
                <EditModal
                    setShow={setCaseShow}
                    show={caseshow}
                    autoHeight={false}
                    caseidEdit={case_id}
                    setCase_ID={setCase_ID}
                    id={name}
                    idd={idd}
                    module={module}
                    tab={tab}
                    preModule={"HUNT"}
                    description_={removeHtmlChars(desc)}

                />
            )
            }
        </>

    )
}

export default HunterDetection