import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    Button,
    Form,
    Spinner
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { Trash } from "phosphor-react";
import { fdeleteC, fget, fput } from "../../../axios/axios";
import { toast, ToastContainer } from "react-toastify";
import { Link, useParams, useNavigate } from "react-router-dom";
import JsonEditor from "../../../components/JsonEditor/JsonEditor";
import globalStyles from "../../../constants/globalStyles";
const EditUser = () => {
    const [IsLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { user_name } = useParams();
    var userdata = {}
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")
    const [userData, setUserData] = useState();

    useMemo(() => {
        fget(`${apiBaseUrl}/core/userpermissions`).then(({ data }) => {
            data && data?.user_type === "super_admin" ? console.log() : navigate("/", { replace: true });

        }).catch((err) => {
            console.log("Error", err)
        })
        if (user_name) {
            fget(`${apiBaseUrl}/core/user?user=${user_name}`).then(({ data }) => {
                if (data?.status === "done") {
                    setIsLoading(false)
                    setModules(data?.data?.modules)
                    setUserName(data?.data?.user_name)
                    setUserType(data?.data?.user_type)
                    setIsSSOUser(data?.data?.is_sso_user ? data?.data?.is_sso_user : false)
                    setIsOnPremiseUser(data?.data?.is_onPremise ? data?.data?.is_onPremise : false)
                    setEmail(data?.data?.email)
                }
                else if (data?.status === "error exist") {
                    toast.error("User Does Not Exists", {
                        position: "bottom-right",
                    });
                    navigate("/settings/users", { replace: true });
                }
                else {
                    // toast.error("Oops, some error occured", {
                    //     position: "bottom-right",
                    // });
                    console.log("error",data)

                }
            }).catch((err) => {
                console.log("Error", err)
            })
        }
    }, [user_name])
    useEffect(() => {
        fget(`${apiBaseUrl}/core/userpermissions`).then(({ data }) => {
            data && data?.user_type === "super_admin" ? console.log() : navigate("/", { replace: true });
            setUserData(data)
          }).catch((err) => {
            console.log("Error", err)
          })
    }, [])
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user');
    const [show, setShow] = useState(false)
    const [temp, setTemp] = useState('')
    const [modules, setModules] = useState({});
    const [isOnPremiseUser, setIsOnPremiseUser] = useState(false)
    const [isSSOUser, setIsSSOUser] = useState(false)
    const [expclose, setExpClose] = useState(false);

    const Modules = {
        "hunter": "Hunter",
        "compliance": "Compliance",
        "investigate": "Investigate",
        "analytics": "Analytics",
        "ti": "Threat Intellegence",
        "ueba": "UEBA",
        "rulesstudio": "Rules Studio",
        "rulesstudioadmin": "Rules Studio Admin",
        "core": "Core",
        "controlview": "Control View",
        "dashboard": "Dashboard",
        "casemgmt": "Case Management",
        "management_dash": "Management Dashboard"
    }
    // console.log("tenantId", tenantId[0].tenantId)

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            const [moduleName, permissionType] = name.split('-');
            setModules((prevModules) => ({
                ...prevModules,
                [moduleName]: {
                    ...prevModules[moduleName],
                    [permissionType]: checked,
                },
            }));
        } else {
            if (name === 'userName') {
                setUserName(value);
            } else if (name === 'userType') {
                setUserType(value);
            }
            else if (name === 'email') {
                setEmail(value);
            }
        }
    };
    const deleteUser =()=>{
        fdeleteC(`${apiBaseUrl}/core/user/delete?user_name=${user_name}`).then(({ data }) => {
          if (data?.status === "done") {
              toast.success("User Deleted Successfully!", {
                position: "bottom-right",
            });
            navigate("/settings/users", { replace: true });
          }
          else {
              console.log("error",data)
          }
      }).catch((err) => {
          console.log("Error", err)
      })
    }
    function renderModal() {
        return  <div>
            <p className="d-flex justify-content-center align-items-center mt-2" style={{"font-size":"14px"}}>Are you sure you want to delete the User?</p>
            <div className="d-flex justify-content-end">
                <button className="btn btn-danger" onClick={()=>{deleteUser()}}>Delete</button>
            </div>
        </div>
    }
    function handleCloseModal() {
        setShow(false);
        // navigate("/settings/users", { replace: true });
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            user_name: userName,
            user_type: userType,
            is_sso_user: isSSOUser,
            is_active: true,
            is_onPremise: isOnPremiseUser,
            email: email,
            modules,
        };

        fput(`${apiBaseUrl}/core/user`, data).then(({ data }) => {
            if (data?.status === "done") {
                toast.success("User Edited Successfully!", {
                    position: "bottom-right",
                });
                setTemp(data?.temp_pass)
                setTimeout(navigate("/settings/users", { replace: true }), 1500)
            }
            else if (data?.status === "error exist") {
                toast.error("User Does Not Exists", {
                    position: "bottom-right",
                });
            }
            else {
                // toast.error("Oops, some error occured", {
                //     position: "bottom-right",
                // });
                console.log("error",data)

            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // You can add code here to handle the JSON file download.
    };

    const getLabel = (module) => {

        return Modules?.[module]
    }

    const checkExist = (module) => {
        if(modules?.is_active){
            return modules?.is_active===true ? true : false
        }
        else{
                return true 
        }
    }

    return (
        <div
            className="col-12 p-4 position-relative bg-white rounded-3"
        >
            <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-0">Edit User - <span style={{ fontWeight: 400, fontSize: "1.25rem", color: globalStyles.orangeDark1 }}>{userName}</span></h5>
            </div>

            {IsLoading ? <div style={{ position: "absolute", top: "50%", left: "50%" }}><Spinner /></div> :
                <div style={{ "overflow": "auto", maxHeight: "calc(100vh - 13rem)" }}>
                    <form className="" onSubmit={handleSubmit}>
                        {/* User Name */}
                        {/* <div className="d-flex flex-column gap-2"> */}
                        <div className="form-group border rounded-3 p-4 mb-3">
                            <div className="d-flex flex-row gap-2">
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="userName">
                                        User Name
                                    </label>
                                    <input
                                        type="text"
                                        id="userName"
                                        name="userName"
                                        className="form-control mt-2"
                                        value={userName}
                                        onChange={handleInputChange}
                                        required
                                        disabled
                                    />
                                </div>
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control mt-2"
                                        value={email}
                                        onChange={handleInputChange}
                                        required
                                        disabled
                                    />
                                </div>
                            </div>

                            {/* User Type */}
                            <div className="d-flex gap-3 mt-2">
                                <div className="form-group col-3">
                                    <label className="font-weight-bold" htmlFor="userType">
                                        User Type
                                    </label>
                                    <select
                                        id="userType"
                                        name="userType"
                                        className="form-select mt-2"
                                        value={userType}
                                        onChange={handleInputChange}
                                        disabled
                                    >
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        <option value="super_admin">Super Admin</option>
                                    </select>
                                </div>
                                <div className="form-group col-3 d-flex mt-4 gap-3 ms-2">
                                    <Form.Check // prettier-ignore
                                        className="mt-3"
                                        type="switch"
                                        id="isOnPremiseUser"
                                        label="On Premise"
                                        disabled
                                        checked={isOnPremiseUser}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIsOnPremiseUser(true)
                                            }
                                            else {
                                                setIsOnPremiseUser(false)
                                            }
                                        }}
                                    />
                                    <Form.Check // prettier-ignore
                                        className="mt-3"
                                        type="switch"
                                        id="isSSOUser"
                                        disabled
                                        label="SSO Access"
                                        checked={isSSOUser}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIsSSOUser(true)
                                            }
                                            else {
                                                setIsSSOUser(false)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Modules */}
                        <div className="form-group border rounded-3 p-4 mb-3">
                            <h5 className="font-weight-bold">Modules</h5>
                            <hr />
                            <div id="modules-wrapper ms-4 mt-2">
                                {Object.entries(modules).map(([moduleName, moduleData]) => (
                                    (getLabel(moduleName) && checkExist(moduleData) && <div className="module mb-4 ms-2" key={moduleName}>
                                        <h5 className="font-weight-bold mb-2">{getLabel(moduleName)}</h5>
                                        <div className="form-check form-check-inline ml-2">
                                            <input
                                                type="checkbox"
                                                name={`${moduleName}-read`}
                                                className="form-check-input"
                                                checked={moduleData.read}
                                                onChange={handleInputChange}
                                            />
                                            <label className="form-check-label ml-2">Read</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                name={`${moduleName}-write`}
                                                className="form-check-input"
                                                checked={moduleData.write}
                                                onChange={handleInputChange}
                                            />
                                            <label className="form-check-label ml-2">Write</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                name={`${moduleName}-delete`}
                                                className="form-check-input"
                                                checked={moduleData.delete}
                                                onChange={handleInputChange}
                                            />
                                            <label className="form-check-label ml-2">Delete</label>
                                        </div>
                                        {/* {moduleData.child && (
                                    <div className="ms-4 mt-2">
                                    {moduleData.child.map((permissionType) => (
                                        <div className="ms-4" key={`${moduleName}-${permissionType.name}`}>
                                            <span className="font-weight-bold mb-2 mt-2" style={{fontWeight:"400"}}>{getLabel(permissionType.name)}</span>
                                            <div>
                                                {moduleData.read!=undefined&&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-read`}
                                                    className="form-check-input"
                                                    checked={moduleData.read}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Read</label>
                                                </div>}
                                                {moduleData.write!=undefined&&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-write`}
                                                    className="form-check-input"
                                                    checked={moduleData.write}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Write</label>
                                                </div>}
                                                {(moduleData.delete!=undefined &&<div className="form-check form-check-inline">
                                                    <input
                                                    type="checkbox"
                                                    name={`${moduleName}-${permissionType.name}-delete`}
                                                    className="form-check-input"
                                                    checked={moduleData.delete}
                                                    onChange={handleInputChange}
                                                    />
                                                    <label className="form-check-label ml-2">Delete</label>
                                                </div>)}
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                )} */}
                                    </div>)
                                ))}
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">

                        <div> 
                        { ( userData?.user_name !== user_name)&&<Trash size={16} onClick={()=>{setShow(true)}}/>}                        
                        </div>
                        {/* Submit Button */}
                        <div className="text-right mr-4">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => navigate("/settings/users", { replace: true })}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-light me-2"
                                onClick={() => setExpClose(true)}
                            >
                                Advanced
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                            >
                                Update
                            </button>
                        </div>
                        </div>

                    </form>
                </div>}
            <JsonEditor data={Object.fromEntries(
                        Object.entries(modules).filter(([key, value]) => value?.is_active !== false)
                        )} close={expclose} setData={setModules} setClick={setExpClose} username={user_name}/>
            {show && (
                <CustomModal
                    title={"Delete User"}
                    ShowOrHide={show}
                    modalSize={"sm"}
                    bodyData={renderModal}
                    handleClose={handleCloseModal}
                    autoHeight={true}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default EditUser;
