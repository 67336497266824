import { Button, Form, Spinner, Tab, Tabs } from "react-bootstrap";
import styles from "./Settings.styles";
import { useEffect, useState } from "react";
import { fget, fpost } from "../../axios/axios";
import { X } from "phosphor-react";
import AlertBox from "../../components/AlertBox/AlertBox";

const Filters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [customFilterList, setCustomFilterList] = useState([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState({});
  const [uebaDetectionData, setUebaDetectionData] = useState([]);
  const [uebaCykitData, setUebaCykitData] = useState([]);
  const [invInvData, setInvInvData] = useState([]);
  const [currentMainTab, setCurrentMainTab] = useState("ueba");
  const [currentSubTab, setCurrentSubTab] = useState("detections");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [newFilterFieldList, setNewFilterFieldList] = useState([]);
  // alert popup
  const [alertPopup, setAlertPopup] = useState(false);
  const apiBaseUrl =localStorage.getItem("apiBaseUrl")
  useEffect(() => {
    getCustomFilters();
  }, []);

  useEffect(() => {
    if (currentMainTab === "investigation") {
      setCurrentSubTab("main");
    }
    console.log(
      "changed",
      currentSubTab,
      customFilterList?.filter(
        (item) => item?.module === currentMainTab && item?.tab === currentSubTab
      )[0]
    );

    setTitle("");
    setDesc("");
    let temp = customFilterList?.filter(
      (item) => item?.module === currentMainTab && item?.tab === currentSubTab
    )[0];
    setSelectedCustomFilter(temp);
    setNewFilterFieldList(temp?.filter);
  }, [currentMainTab, currentSubTab]);

  const getCustomFilters = async () => {
    setIsLoading(true);
    try {
      const response = await fget(
        `${apiBaseUrl}/filter/tenant`
      );
      console.log("response", response?.data);
      setCustomFilterList(response?.data);
      setUebaDetectionData(
        response?.data?.filter(
          (item) => item?.module === "ueba" && item?.tab === "detection"
        )
      );

      setUebaCykitData(
        response?.data?.filter(
          (item) => item?.module === "ueba" && item?.tab === "cykit"
        )
      );

      setInvInvData(
        response?.data?.filter(
          (item) => item?.module === "investigation" && item?.tab === "main"
        )
      );

      let temp = response?.data?.filter(
        (item) => item?.module === "ueba" && item?.tab === "detection"
      )[0];

      setSelectedCustomFilter(temp);
      setNewFilterFieldList(temp?.filter);

      setIsLoading(false);
    } catch (error) {
      console.log("get customfilter error", error);

      setIsLoading(false);
    }
  };

  const updateSavedFilter = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const data = {
      id: selectedCustomFilter?.id,
      name: title || selectedCustomFilter?.name || "",
      module: selectedCustomFilter?.module,
      tab: selectedCustomFilter?.tab,
      description: desc || selectedCustomFilter?.description || "",
      filter: newFilterFieldList,
    };
    console.log("data", data, selectedCustomFilter, newFilterFieldList);
    try {
      const response = await fpost(
        `${apiBaseUrl}/filter/tenant`,
        data
      );
      console.log("response", response);
      setIsUpdating(false);
      setAlertPopup(true);
      try {
        await fget(
          `${apiBaseUrl}/filter/tenant/refresh`
        );
      } catch (error) {
        console.log("refresh error", error);
      }
    } catch (error) {
      console.log("update error", error);
    }
  };

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <Tabs
        defaultActiveKey="ueba"
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={(e) => {
          console.log("main tab", e);
          setCurrentMainTab(e);
          // onMainTabChange(e);
        }}
      >
        <Tab eventKey="ueba" title="UEBA">
          <Tabs
            defaultActiveKey="detection"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(e) => {
              console.log("sub tab", e);
              setCurrentSubTab(e);
              // onSubTabChange(e);
            }}
          >
            <Tab eventKey="detection" title="Detections">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <>
                  {uebaDetectionData?.length ? (
                    <div className="d-flex flex-row">
                      <div
                        className="p-2 mr-2"
                        style={{ flex: 1, height: "400px", overflow: "auto" }}
                      >
                        {uebaDetectionData?.map((item) => (
                          <div
                            className="d-flex flex-column p-2 mb-2 rounded"
                            style={{
                              backgroundColor:
                                item?.id === selectedCustomFilter?.id
                                  ? "lightyellow"
                                  : "#f8f9fa",
                            }}
                            type="button"
                            onClick={() => setSelectedCustomFilter(item)}
                          >
                            <div>{item.name}</div>
                            <div>{item.description}</div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="d-flex flex-column p-2 justify-content-between"
                        style={{ flex: 2 }}
                      >
                        <Form onSubmit={updateSavedFilter}>
                          <div className="d-flex flex-column">
                            <Form.Group className="mb-3">
                              <Form.Label>Title</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={selectedCustomFilter?.name || ""}
                                className=""
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Description</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={
                                  selectedCustomFilter?.description || ""
                                }
                                className=""
                                onChange={(e) => setDesc(e.target.value)}
                              />
                            </Form.Group>

                            <span className="text-muted">Filters Applied</span>
                            {newFilterFieldList?.map((item, index) => (
                              <>
                                <div
                                  className="d-flex flex-row gap-1 rounded-2 px-2"
                                  style={{
                                    height: "250px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div className="d-flex flex-row gap-1 p-2">
                                    <span>{item.column.label}</span>
                                    <span className="px-2 rounded-1">
                                      <b>{item.type}</b>
                                    </span>
                                    <span>{item.selectedValue}</span>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setNewFilterFieldList(
                                        newFilterFieldList?.filter(
                                          (i) => i.id !== item.id
                                        )
                                      );
                                    }}
                                    type="button"
                                    className="p-2"
                                  >
                                    <X weight="bold" size={10} />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="d-flex flex-row mt-4">
                            <Button
                              style={{
                                width: "100px",
                              }}
                              type="submit"
                            >
                              {isUpdating ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                />
                              ) : (
                                "Update"
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                      }}
                    >
                      No saved filters found.
                    </div>
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="cykit" title="CyKit">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <>
                  {uebaCykitData?.length ? (
                    <div className="d-flex flex-row">
                      <div
                        className="p-2 mr-2"
                        style={{ flex: 1, height: "400px", overflow: "auto" }}
                      >
                        {uebaCykitData?.map((item) => (
                          <div
                            className="d-flex flex-column p-2 mb-2 rounded"
                            style={{
                              backgroundColor:
                                item?.id === selectedCustomFilter?.id
                                  ? "lightyellow"
                                  : "#f8f9fa",
                            }}
                            type="button"
                            onClick={() => setSelectedCustomFilter(item)}
                          >
                            <div>{item.name}</div>
                            <div>{item.description}</div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="d-flex flex-column p-2 justify-content-between"
                        style={{ flex: 2 }}
                      >
                        <Form onSubmit={updateSavedFilter}>
                          <div className="d-flex flex-column">
                            <Form.Group className="mb-3">
                              <Form.Label>Title</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={selectedCustomFilter?.name || ""}
                                className=""
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Description</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={
                                  selectedCustomFilter?.description || ""
                                }
                                className=""
                                onChange={(e) => setDesc(e.target.value)}
                              />
                            </Form.Group>

                            <span className="text-muted">Filters Applied</span>
                            {newFilterFieldList?.map((item, index) => (
                              <>
                                <div
                                  className="d-flex flex-row gap-1 rounded-2 px-2"
                                  style={{
                                    height: "250px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div className="d-flex flex-row gap-1 p-2">
                                    <span>{item.column.label}</span>
                                    <span className="px-2 rounded-1">
                                      <b>{item.type}</b>
                                    </span>
                                    <span>{item.selectedValue}</span>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setNewFilterFieldList(
                                        newFilterFieldList?.filter(
                                          (i) => i.id !== item.id
                                        )
                                      );
                                    }}
                                    type="button"
                                    className="p-2"
                                  >
                                    <X weight="bold" size={10} />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="d-flex flex-row mt-4">
                            <Button
                              style={{
                                width: "100px",
                              }}
                              type="submit"
                            >
                              {isUpdating ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                />
                              ) : (
                                "Update"
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                      }}
                    >
                      No saved filters found.
                    </div>
                  )}
                </>
              )}
            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="investigation" title="Investigation">
          <Tabs
            defaultActiveKey="main"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(e) => {
              console.log("sub tab", e);
              setCurrentSubTab(e);
              // onSubTabChange(e);
            }}
          >
            <Tab eventKey="main" title="Investigations">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <>
                  {invInvData?.length ? (
                    <div className="d-flex flex-row">
                      <div
                        className="p-2 mr-2"
                        style={{ flex: 1, height: "400px", overflow: "auto" }}
                      >
                        {invInvData?.map((item) => (
                          <div
                            className="d-flex flex-column p-2 mb-2 rounded"
                            style={{
                              backgroundColor:
                                item?.id === selectedCustomFilter?.id
                                  ? "lightyellow"
                                  : "#f8f9fa",
                            }}
                            type="button"
                            onClick={() => setSelectedCustomFilter(item)}
                          >
                            <div>{item.name}</div>
                            <div>{item.description}</div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="d-flex flex-column p-2 justify-content-between"
                        style={{ flex: 2 }}
                      >
                        <Form onSubmit={updateSavedFilter}>
                          <div className="d-flex flex-column">
                            <Form.Group className="mb-3">
                              <Form.Label>Title</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={selectedCustomFilter?.name || ""}
                                className=""
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Description</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder={
                                  selectedCustomFilter?.description || ""
                                }
                                className=""
                                onChange={(e) => setDesc(e.target.value)}
                              />
                            </Form.Group>

                            <span className="text-muted">Filters Applied</span>
                            {newFilterFieldList?.map((item, index) => (
                              <>
                                <div
                                  className="d-flex flex-row gap-1 rounded-2 px-2"
                                  style={{
                                    height: "250px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div className="d-flex flex-row gap-1 p-2">
                                    <span>{item.column.label}</span>
                                    <span className="px-2 rounded-1">
                                      <b>{item.type}</b>
                                    </span>
                                    <span>{item.selectedValue}</span>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setNewFilterFieldList(
                                        newFilterFieldList?.filter(
                                          (i) => i.id !== item.id
                                        )
                                      );
                                    }}
                                    type="button"
                                    className="p-2"
                                  >
                                    <X weight="bold" size={10} />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="d-flex flex-row mt-4">
                            <Button
                              style={{
                                width: "100px",
                              }}
                              type="submit"
                            >
                              {isUpdating ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="sm"
                                />
                              ) : (
                                "Update"
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "400px",
                      }}
                    >
                      No saved filters found.
                    </div>
                  )}
                </>
              )}
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
      {alertPopup && (
        <AlertBox
          setAlertPopup={setAlertPopup}
          alertPopup={alertPopup}
          message={"Filter updated"}
          close={() => setAlertPopup(false)}
          setOutTime={5000}
          bgColor={"lightGreen"}
        />
      )}
    </div>
  );
};

export default Filters;
