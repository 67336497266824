// .container-tab::-webkit-scrollbar {
//     display: none;
// }

import globalStyles from "../../constants/globalStyles";

// .title-section .view-btn{
//     text-decoration: none;
//     border: none;
//     background-color: transparent;
//     color: rgb(0, 132, 255);
// }
// .content-container{
//     height: 30vh;
//     overflow: scroll;
// }
// .content-container::-webkit-scrollbar {
//     display: none;
// }
// .single-tab{
//     display: flex;
//     margin-bottom: 1.1rem;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0.4rem 1rem;
//     border: 1px solid #d0d0d0;
//     border-radius: 0.5rem;
// }
// .profile-container{
//     display: flex;
//     align-items: center;
//     gap: 10px;
// }
// .profile-pic{
// display: flex;
// align - items: center;
// justify - content: center;
// font - weight: 500;
// width: 50px;
// height: 50px;
// border - radius: 50 %;
// object - fit: cover;
// background - color: rgb(220, 220, 220);
// }
// .profile-details span{
//    font-size: 1rem;
//    font-weight: 500;
//    line-height: 1;
// }

// .profile-details p{
//     font-size: 0.9rem;
//     max-width: 200px;
// }

const styles = {
  wrapper: {
    // height: "calc(35vh - 28px)"
  },
  tile: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "#f1f1f1",
    backgroundColor: globalStyles.greyLite1,
    color: "#000000",
    textDecoration: "none"
  },
  profilePic: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    backgroundColor: globalStyles.greyDark1,
    color: globalStyles.white,
    borderRadius: "50px",
    // border: "1px solid",
    // borderColor: globalStyles.greyDark1
  },
  count: {
    marginLeft: "auto",
  },
};

export default styles;
