import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./TiOverview.styles";
import Headlines from "./Headlines/Headlines";
import Activebytes from "./Activebytes/Activebytes";
import Community from "./Community/Community";
import DeepDarkWeb from "./DeepAndDark/DeepDarkWeb";

import Cykit from "./Cykit/Cykit";
const TiOverview = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  // const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter"));
  const [selectedDateFilter, setSelectedDateFilter] = useState("7 Days");
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [ti, setTI] = useState(true);
  const [activeTab, setActiveTab] = useState("headlines");
  const [searchVal, setSearchVal] = useState();
  const [globalSearch, setGlobalSearch] = useState("");
  const [isGlobalSearchClear, setIsGlobalSearchClear] = useState(false);
  const [searchValCom, setSearchValCom] = useState();
  const [vulnerPage, setVulnerPage] = useState(1);
  const [vulnerExPage, setVulnerExPage] = useState(1);
  const [securityPage, setSecurityPage] = useState(1);
  const [headlinesPage, setHeadlinesPage] = useState(1);
  const [page, setPage] = useState(1);

  // Threat events
  const [vulnerabilitiesFilter, setVulnerabilitiesFilter] = useState("");
  const [vulnerabilityExploitFilter, setVulnerabilityExploitFilter] =
    useState("");
  const [securityEvFilter, setSecurityEvFilter] = useState('');

  const [headlinesSearchQuery, setHeadlinesSearchQuery] = useState('');
  const [VulnerSearchQuery, setVulnerSearchQuery] = useState('');
  const [VulnerExpSearchQuery, setVulnerExpSearchQuery] = useState('');
  const [securityEventsSearchQuery, setSecurityEventsSearchQuery] = useState('');

  const [feedsSearchQuery, setFeedsSearchQuery] = useState('');

  const [sevChange, setSevChange] = useState(false);

  const [isApplyGlobalSearch, setIsApplyGlobalSearch] = useState(false);


  const [dateFilter, setDateFilter] = useState();



  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  useEffect(() => {
    if (currentPath === "activebytes") {
      setTitle("Threat Events")
      setBreadcrumb({
        "module": "ti",
        "path": [{ "title": "Threat Intelligence", "url": "/ti/activebytes" }, { "title": "Threat Events", "url": "/ti/activebytes" },]
      });
    }
    if (currentPath === "community") {
      setTitle("Indicators and Feeds")
      setBreadcrumb({
        "module": "ti",
        "path": [{ "title": "Threat Intelligence", "url": "/ti/activebytes" }, { "title": "Indicators & Feeds", "url": "/ti/community" },]
      });
    }
    if (currentPath === "deep_dark_web") {
      setTitle("Deep & Dark Web")
      setBreadcrumb({
        "module": "ti",
        "path": [{ "title": "Threat Intelligence", "url": "/ti/activebytes" }, { "title": "Deep & Dark Web", "url": "/ti/deep_dark_web" },]
      });
    }
    if (currentPath === "cykit") {
      setTitle("Cykit")
      setBreadcrumb({
        "module": "ti",
        "path": [{ "title": "Threat Intelligence", "url": "/ti/activebytes" }, { "title": "Cykit", "url": "/ti/cykit" },]
      });
    }
  }, [currentPath]);


  useEffect(() => {

    if (isApplyGlobalSearch === true) {
      setHeadlinesSearchQuery(globalSearch)
      setVulnerSearchQuery(globalSearch)
      setVulnerExpSearchQuery(globalSearch)
      setSecurityEventsSearchQuery(globalSearch)
      setFeedsSearchQuery(globalSearch)
    }

  }, [isApplyGlobalSearch, globalSearch])


  useEffect(() => {
    if (isGlobalSearchClear === true) {
      setHeadlinesSearchQuery('')
      setVulnerSearchQuery('')
      setVulnerExpSearchQuery('')
      setSecurityEventsSearchQuery('')
      setFeedsSearchQuery('')
    }
  }, [isGlobalSearchClear])





  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"ti"} />
      <div className="flex-fill d-flex flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          ti={ti}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          isFilterEnabled={currentPath === "activebytes" || currentPath === "community"}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setSearchVal={setSearchVal}
          searchVal={searchVal}
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          globalSearchEnabled={currentPath != "cykit" && currentPath != "deep_dark_web" ? true : false}
          searchValCom={searchValCom}
          setSearchValCom={setSearchValCom}
          vulnerPage={vulnerPage}
          setVulnerPage={setVulnerPage}
          vulnerExPage={vulnerExPage}
          setVulnerExPage={setVulnerExPage}
          securityPage={securityPage}
          setSecurityPage={setSecurityPage}
          headlinesPage={headlinesPage}
          setHeadlinesPage={setHeadlinesPage}
          setComPage={setPage}
          comPage={page}
          setIsGlobalSearchClear={setIsGlobalSearchClear}
          isGlobalSearchClear={isGlobalSearchClear}
          sevChange={sevChange}
          setSevChange={setSevChange}
          setIsApplyGlobalSearch={setIsApplyGlobalSearch}
          setDateFilter={setDateFilter}

        />
        <div className="col-12 d-flex" style={styles.main}>
          {
            currentPath === "headlines" ? (
              <Headlines
                selectedDateFilter={selectedDateFilter}
                setSelectedDateFilter={setSelectedDateFilter}

              />
            )
              :
              currentPath === "activebytes" ? (
                <Activebytes
                  selectedDateFilter={selectedDateFilter}
                  setSelectedDateFilter={setSelectedDateFilter}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  searchVal={searchVal}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  vulnerPage={vulnerPage}
                  setVulnerPage={setVulnerPage}
                  vulnerExPage={vulnerExPage}
                  setVulnerExPage={setVulnerExPage}
                  securityPage={securityPage}
                  setSecurityPage={setSecurityPage}
                  headlinesPage={headlinesPage}
                  setHeadlinesPage={setHeadlinesPage}
                  setVulnerabilitiesFilter={setVulnerabilitiesFilter}
                  vulnerabilitiesFilter={vulnerabilitiesFilter}
                  vulnerabilityExploitFilter={vulnerabilityExploitFilter}
                  setVulnerabilityExploitFilter={setVulnerabilityExploitFilter}
                  securityEvFilter={securityEvFilter}
                  setSecurityEvFilter={setSecurityEvFilter}
                  sevChange={sevChange}
                  setSevChange={setSevChange}
                  headlinesSearchQuery={headlinesSearchQuery}
                  setHeadlinesSearchQuery={setHeadlinesSearchQuery}
                  VulnerSearchQuery={VulnerSearchQuery}
                  setVulnerSearchQuery={setVulnerSearchQuery}
                  VulnerExpSearchQuery={VulnerExpSearchQuery}
                  setVulnerExpSearchQuery={setVulnerExpSearchQuery}
                  securityEventsSearchQuery={securityEventsSearchQuery}
                  setSecurityEventsSearchQuery={setSecurityEventsSearchQuery}
                  setIsApplyGlobalSearch={setIsApplyGlobalSearch}
                  isApplyGlobalSearch={isApplyGlobalSearch}

                />
              ) : currentPath === "community" ? (
                <Community
                  selectedDateFilter={selectedDateFilter}
                  setSelectedDateFilter={setSelectedDateFilter}
                  dateFilterti={dateFilter}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  searchValCom={searchValCom}
                  setSearchValCom={setSearchValCom}
                  page={page}
                  setPage={setPage}
                  isGlobalSearchClear={isGlobalSearchClear}
                  setIsGlobalSearchClear={setIsGlobalSearchClear}
                  sevChange={sevChange}
                  setSevChange={setSevChange}
                  setIsApplyGlobalSearch={setIsApplyGlobalSearch}
                  isApplyGlobalSearch={isApplyGlobalSearch}
                  setFeedsSearchQuery={setFeedsSearchQuery}
                  feedsSearchQuery={feedsSearchQuery}

                />
              ) : currentPath === "deep_dark_web" ? (
                <DeepDarkWeb />
              ) : currentPath === "cykit" ? (
                <Cykit
                  globalSearchEnabled={false}

                />
              ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default TiOverview;