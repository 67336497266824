import React, { useEffect, useState } from 'react'
import { Document, Page, View, Text, Link, Image } from '@react-pdf/renderer';
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../../Utils/Utils";
import { Chart } from "react-google-charts";
import Header from '../Header';



export default function DetailPageSe({ showMore, geoData, imageBlob }) {

    const FormatURL = Array.from(new Set(showMore?.reference_link));


    const [imageSrc, setImageSrc] = useState(null);

    // Define the styles for the table
    const styles = {
        page: { padding: "20px" },
        container: { flexDirection: "row", marginTop: "5px" },
        column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
        column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
        comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
        link: { padding: "2px", marginTop: "3px", },
        break: {
            pageBreakBefore: 'always'
        },
        country: { marginRight: "10px", lineHeight: "1.3" },
        image: {
            width: '80%', // Adjust the width as needed
            height: 'auto', // Maintain aspect ratio
            justifyContent: 'center',
            marginLeft: "10%",
            marginTop: "10px"
        },
    };

    useEffect(() => {
        if (imageBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(imageBlob);
        }
    }, [imageBlob]);



    return (
        <Document>
            <Page style={styles.page}>
                <Header data={showMore} />
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Event name</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {showMore?.event_name}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Event description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>

                            {showMore?.event_description}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Severity</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>

                            {showMore?.severity}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>TLP</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>

                            {showMore?.tlp}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Alert date</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>

                            {getDateFromTimestamp()}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Alert time</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>

                            {getTimeFromTimestamp()}</Text>
                    </View>
                </View>


                <View style={styles.container} >
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Impact region</Text>


                    </View>


                    {geoData.length > 0 && <View style={styles.column2}>
                        <Text style={styles.country}>
                            {geoData.length <= 10 && (
                                <>
                                    {geoData?.map((data) => {
                                        if (data != "XX" && data != "N/A") {
                                            let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
                                            return <Text>{regionNames?.of(data)},&nbsp; </Text>
                                        }
                                    })}
                                </>
                            )}
                        </Text>



                    </View>}
                </View>
                <View style={styles.container}>
                    {/* Content for Column 1 */}
                    {/* <View style={styles.imageDiv}> */}
                    <Image src={imageSrc} style={styles.image} />
                    {/* </View> */}
                </View>
                {showMore?.comments?.length > 0 &&
                    <View style={styles.container} break>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}*
                            <Text>Comments</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            {showMore?.comments?.map((item) => (
                                <Text style={styles.comment}>

                                    {item} </Text>
                            ))}

                        </View>
                    </View>
                }

                {FormatURL?.length > 0 &&
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Reference links</Text>
                        </View>
                        <View style={styles.column2}>

                            {FormatURL?.map((item) => (
                                <Link src={item} target="_blank" style={styles.link}> {item}</Link>
                            ))}

                        </View>
                    </View>
                }
            </Page>
        </Document>
    )
}