import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import UebaOverview from "./pages/UEBA/UebaOverview";
import TiOverview from "./pages/TI/TiOverview";
import RuleHome from "./pages/RuleStudio/RuleHome";
import InvOverview from "./pages/Investigate/InvOverview";
import CommunityView from "./components/DetailsPage/CommunityView";
import ActivebytesView from "./components/DetailsPage/ActivebytesView";
import HunterOverview from "./pages/Hunter/HunterOverview";
import ComplianceOverview from "./pages/Compliance/ComplianceOverview";
import CaseOverview from "./pages/CaseManagement/CaseOverview";
import { State } from "./Context";
import Settings from "./pages/Settings/Settings";
import HunterDetection from "./components/DetailsPage/HunterDetection";
import DetectionSummary from "./components/DetailsPage/DetectionSummary.jsx";
import UebaSummary from "./components/UEBA/UebaSummary.jsx";
import InvestigationDashboard from "./components/DetailsPage/InvestigationDashboard";
import ManagementOverview from "./pages/ManagementDashboard/ManagementOverview";
import Analytics from "./pages/Analytics/Analytics";
import SecurityAnalytics from "./pages/Analytics/SecurityAnalytics/SecurityAnalytics";
import Management from "./pages/Analytics/Management/Management";
import ThreatLandScapeOverview from "./pages/ThreatLandscape/ThreatLandscapeOverview.jsx";
import InvDetailModal from "./components/RuleStudio/Investigation/InvDetailModal.jsx";
import HuntDetail from "./components/RuleStudio/Hunter/Custom/HuntDetail.jsx";
import CompDetailModal from "./components/RuleStudio/Compliance/Custom/CompDetailModal.jsx";

function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(State);

  console.log = () => { }; console.debug = () => { }; console.info = () => { }; console.warn = () => { };
  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path="/" element={<Home />} />
          {[
            "/settings/authentication",
            "/settings/exception",
            "/settings/integrations",
            "/settings/notifications",
            "/settings/licenceinfo",
            "/settings/dateformat",
            "/settings/filters",
            "/settings/health",
            "/settings/index-health/:index_name",
            "/settings/users",
            "/settings/user/",
            "/settings/create-user/",
            "/settings/edit-user/:user_name",
            "/settings/logs"
          ].map((path) => (
            <Route key={path} path={path} element={<Settings />} />
          ))}

          {/* UEBA */}
          {[
            "/ueba/dashboard",
            "/ueba/detection",
            "/ueba/summary/:group_id",
            // "/ueba/summary",
            "/ueba/activities",
            // "/ueba/asset-discovery",
            // "/ueba/watchlist",
            "/ueba/settings",
            "/ueba/cygraph/:dashboard/:doc_id",
          ].map((path) => (
            <Route key={path} path={path} element={<UebaOverview />} />
          ))}

          <Route
            path={"/ueba/detection/detection_summary"}
            element={<UebaSummary />}
          />

          <Route
            path="/ueba/detection-summary"
            element={<DetectionSummary />}
          />

          {/* TI */}
          {[
            // "/ti/headlines",
            "/ti/community",
            // "/ti/event/:id",
            "/ti/activebytes",
            "/ti/settings",
            "/ti/deep_dark_web",
            "/ti/cykit/",
          ].map((path) => (
            <Route key={path} path={path} element={<TiOverview />} />
          ))}

          <Route path="/ti/community/event/:id" element={<CommunityView />} />
          <Route
            path="/ti/activebytes/:eventname/:id"
            element={<ActivebytesView />}
          />

          {/* ANALYTICS */}
          {["/analytics/securityanalytics", "/analytics/management"].map(
            (path) => (
              <Route key={path} path={path} element={<Analytics />} />
            )
          )}

          <Route path="/analytics/ciso" element={<Analytics />} />

          <Route path="/analytics/securityanalytics" element={<Analytics />} />

          {/* RS*/}
          {["/rs/investigation", "/rs/hunter", "/rs/compliance", "/rs/rsueba", "/rs/rscorrelation",
            "/rs/summary/:id",
            "/rs/huntsummary/:hunt_id",
            "/rs/compsummary/:comp_id"].map((path) => (
              <Route key={path} path={path} element={<RuleHome />} />
            ))}

          {/* <Route
            path={"/rs/investigation/summary"}
            element={<InvDetailModal />}
          />
          <Route
            path={"/rs/hunter/huntsummary"}
            element={<HuntDetail />}
          />
           <Route
            path={"/rs/compliance/compsummary"}
            element={<CompDetailModal />}
          /> */}
          <Route path="/rs/summary/:id" element={<InvDetailModal />} />
          <Route path="/rs/hunter/huntsummary/:hunt_id" element={<HuntDetail />} />
          <Route path="/rs/compliance/compsummary/:comp_id" element={<CompDetailModal />} />

          {/* INV*/}
          {[
            "/investigate/dashboard",
            "/investigate/cybot_soc",
            "/investigate/grouped_investigation/:id",
            "/investigate/summary/:hunt_detection_id",
            "/investigate/cykit",
            "/investigate/correlation",
            "/investigate/firewall",
            "/investigate/active_directory",
            "/investigate/linux",
            "/investigate/email",
            "/investigate/waf",
            "/investigate/atp_alerts",
            "/investigate/o365_active_directory",
            "/investigate/o365_one_drive",
            "/investigate/o365_exchange",
            "/investigate/software",
            "/investigate/vulnerabilities",

          ].map((path) => (
            <Route key={path} path={path} element={<InvOverview />} />
          ))}

          <Route
            path="investigate/investigation/dashboard/:id"
            element={<InvestigationDashboard />}
          />

          {/* Hunter*/}
          {[
            "/hunter/detections",
            "/hunter/detection/:hunt_id",
            "/hunter/cykit",
            "/hunter/cygraph/:dashboard/:doc_id",
            "/hunter/corelation",
          ].map((path) => (
            <Route key={path} path={path} element={<HunterOverview />} />
          ))}

          <Route path="/hunter/detections/:id" element={<HunterDetection />} />

          {/* Compliance*/}
          {[
            "/compliance/dashboard",
            "/compliance/alerts",
            "/compliance/controlview",
             "/compliance/maindashboard",

          ].map((path) => (
            <Route key={path} path={path} element={<ComplianceOverview />} />
          ))}

          {/* Management dashboard */}
          {["/management/dashboard"].map((path) => (
            <Route key={path} path={path} element={<ManagementOverview />} />
          ))}
          {/* Threat landscape */}
          {["/threat_landscape/dashboard"].map((path) => (
            <Route key={path} path={path} element={<ThreatLandScapeOverview />} />
          ))}

          {/* CAse management */}
          {["/case/management"].map((path) => (
            <Route key={path} path={path} element={<CaseOverview />} />
          ))}
        </>
      ) : (
        <>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path={"*"} element={<Navigate replace to={"/"} />} />
          {/* <Route path={"*"} element={<Login setIsLoggedIn={setIsLoggedIn} />} /> */}
        </>
      )}
    </Routes>
  );
}

export default App;
