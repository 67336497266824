import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getProcessList } from "../../../Methods/UebaMethods";
import { exportCSVFile } from "../../../Utils/Utils";
import moment from "moment";

const HunterExport = ({ filter, dateFilter, getDetectionTableData, isOneStep, isAlerted }) => {
  const [loader, setLoader] = useState(false);

  const getFields = () => {
    var fields = [
      {
        name: "Hunt Name",
        value: "item._source?.hunt_name",
      },
      {
        name: "New Detections",
        value: "item._source?.new_detections",
      },
      // {
      //   name: "Alerted",
      //   value: "item._source?.alerted",
      // },
      {
        name: "Detection Severity",
        value: "item._source?.detection_severity",
      },
      {
        name: "Hunt Started",
        value: "item?._source?.hunt_started",
      },
    ];
    return fields;
  };
  const convertdata = (fields, data) => {
    var convertedData = [];
    data.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {
        const { name, value } = field;
        // var test = "val."+value
        // if(test.includes(".@"))
        // {
        //     var new1 = test.split(".@")
        //     test= new1[0]+`['@${new1[1]}']`
        // }
        const value1 = eval(value);

        dynamicJSON[name] = value1;
      }
      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };
  const csvExport = async () => {
    setLoader(true);
    var size = 1000;
    var data = await getDetectionTableData(size, "desc", 0);
    var data3 = data.data.hits.hits;
    var count = data.data.hits.total.value;
    var iter = 1000;
    while (count > 1000 && iter <= count) {
      var tempdata = await getDetectionTableData(size, "desc", iter);
      data3.push(...tempdata.data.hits.hits);
      iter += size;
    }
    if (isOneStep) {
      data3 = data3.filter(item => item?._source?.steps_triggred.length > 0)
    }
    else if (isAlerted) {
      data3 = data3.filter(item => item._source.alerted === 'yes')
    }
    var fields = getFields();
    var filename = "Hunter" + "_" + moment().toISOString();
    var data1 = convertdata(fields, data3);
    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default HunterExport;
