import React, { useEffect, useState } from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../Loader/Loader";

import { dateDMY, removeHtmlChars } from "../../../../Utils/Utils";

import {
  getComplianceSelectList,
  customCompliance,
  getExpectedOutputCustomInv, getCompCatalogueDetails,
  getCompCatalogueDetailsQuery
} from "../../../../Methods/RuleStudioMethods";
import CustomComplianceInfo from "./CustomComplianceInfo";
import { Button, Form } from "react-bootstrap";


const CustomComplianceModal = ({ setCustomShow, customShow,
  setSubmitLoader,
  submitLoader, comIdEdit, setComId }) => {
  const dispatch = useDispatch();

  const [selectedList, setList] = useState([]);
  const [complianceId, setComplianceID] = useState("COM-");
  const [complianceName, setComplianceName] = useState("");
  const [description, setDescription] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");
  const [emailbody, setEmailBody] = useState("");
  const [email, setEmail] = useState("");
  const [iso, setISO] = useState("");
  const [nist, setNIST] = useState("");
  const [pci, setPCI] = useState("");
  const [extractValues, setExtractValues] = useState("");
  const [query, setQuery] = useState("");
  const [selectedExpectedOutput, setExpectedOutput] = useState([]);
  const [com_created, setComCreated] = useState(dateDMY(new Date()))
  const [com_last_edited, setComLastEdited] = useState(dateDMY(new Date()))


  const [inputValue, setInputValue] = useState('');
  const [isValidJson, setIsValidJson] = useState(true);

  const listExpectedRsInv = useSelector((state) => state.listExpectedRsInv);
  const { output } = listExpectedRsInv;



  const detailsCustomRsComp = useSelector((state) => state.detailsCustomRsComp);
  const { catalogue, loading: loading } = detailsCustomRsComp;

  // const detailsCustomQueryRsComp = useSelector((state) => state.detailsCustomQueryRsComp);
  // const { catalogueQuery } = detailsCustomQueryRsComp;
  const detailsCustomQueryRsIn = useSelector((state) => state.detailsCustomQueryRsIn);
  const { catalogueQuery } = detailsCustomQueryRsIn;



  useEffect(() => {

    dispatch(getExpectedOutputCustomInv());
    dispatch(getComplianceSelectList());
  }, []);

  const expectedOutput = output?.expected_values?.map((output) => {
    return { value: output.value, label: output.text };
  });




  const styles = {
    footerWrap: {
      position: "sticky",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      background: "#FFFFFF"
    }
  };


  const setDataInEditList = () => {



    let expOutData = catalogue?.extract_values?.map((data) => {
      return { "value": data, 'label': data }
    })

    const queryData = catalogue?.query
    delete queryData?.extract_values_from_query_output





    setComplianceID(comIdEdit === '' ? '' : catalogue?.com_id);

    setComplianceName(comIdEdit === '' ? '' : catalogue?.com_name);
    setDescription(comIdEdit === '' ? '' : catalogue?.com_description);
    setEmail(comIdEdit === '' ? '' : catalogue?.email_to);
    setEmailBody(comIdEdit === '' ? '' : catalogue?.email_body);
    setISO(comIdEdit === '' ? '' : catalogue?.ISO_27001);
    setNIST(comIdEdit === '' ? '' : catalogue?.NIST);
    setPCI(comIdEdit === '' ? '' : catalogue?.PCI_DSS);
    setExtractValues(comIdEdit === '' ? '' : catalogue?.extract_values);
    setQuery(comIdEdit === '' ? '' : JSON.stringify(queryData));
    setExpectedOutput(comIdEdit === '' ? '' : expOutData)
    setComCreated(comIdEdit === '' ? '' : catalogue?.com_created);
    setComLastEdited(comIdEdit === '' ? '' : dateDMY(new Date()));

  };

  useEffect(() => {
    dispatch(getExpectedOutputCustomInv());

    if (
      (comIdEdit || Object.keys(catalogue).length === 0) &&
      comIdEdit != catalogue?.com_id && comIdEdit != ''
    ) {
      dispatch(getCompCatalogueDetails(comIdEdit));

    }

    if (comIdEdit != '') {
      setDataInEditList();
    } else {
      setComplianceID("COM-");
      setComplianceName("");
      setDescription("");
      setEmail("");
      setEmailBody("");
      setISO("");
      setNIST("");
      setPCI();
      setExpectedOutput([]);
      setQuery('');

    }

  }, [comIdEdit, catalogue, customShow, catalogueQuery]);


  const onSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to save changes?")) {

      setSubmitLoader(true)

      let extractValuesData = selectedExpectedOutput?.map((data) => {
        return data.value
      })


      let data = {
        com_id: complianceId,
        com_name: complianceName,
        com_description: description,
        PCI_DSS: pci,
        NIST: nist,
        ISO_27001: iso,
        email_body: emailbody,
        email_to: email,
        extract_values: extractValuesData,
        com_created: com_created,
        com_last_edited: com_last_edited,
        query

      };



      dispatch(customCompliance(data));





    }



  };

  const handleMultiChange = (selectedExpectedOutput) => {
    setExpectedOutput(selectedExpectedOutput);
  };

  const handleClose = () => {
    setCustomShow(false);
  };

  const handleQuery = (val) => {

    setQuery(val.replace(/\s/g, "").replace(/"/g, "'"))
    setInputValue(val);
  
      // try {
      //   JSON.parse(val);
      //   setIsValidJson(true);
      // } catch (error) {
      //   setIsValidJson(false);
      // }
  }


  const renderModal = () => (
    <Form onSubmit={onSubmit}>
      {loading === true ? (
        <div style={{ width: '300px' }}>
          <Loader />
        </div>
      ) : (
        <div className="m-3">
          <div className="row">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-6 col-xs-12 p-1">
                {" "}
                <label for="validationTextarea" className="form-label">
                  Compliance ID <span className="text-danger">*</span>
                </label>
                <input type="text" className={complianceId?.length === 0 ? `form-control is-invalid` : 'form-control  '}
                  id="validationTextarea" name="complianceId"
                  value={complianceId}
                  placeholder="COMPLIANCE ID"
                  required readOnly={comIdEdit ? true : false}
                  onChange={(e) => setComplianceID(e.target.value)} />
                {/* <input
                type="text"
                className="form-control input-lg"
                name="complianceId"
                value={complianceId}
                onChange={(e) => setComplianceID(e.target.value)}
                placeholder="COMPLIANCE ID "
              /> */}
              </div>
              <div className="col-md-6 col-xs-12 p-1">
                {" "}
                <label className="control-label">Compliance Name *</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  name="complianceName"
                  value={complianceName}
                  onChange={(e) => setComplianceName(e.target.value)}
                  placeholder="COMPLIANCE NAME "
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-12 col-xs-12 p-1">
                {" "}
                <label className="control-label">Description *</label>
                <textarea
                  type="text"
                  rows="5"
                  className="form-control input-lg"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description Here"
                  row={6}
                />
              </div>
            </div>
          </div>



          <div className="row">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-4 col-xs-12 p-1">
                {" "}
                <label className="control-label">PCI_DSS *</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={pci}
                  name="pci"
                  onChange={(e) => setPCI(e.target.value)}
                  placeholder="PCI_DSS "
                />
              </div>
              <div className="col-md-4 col-xs-12 p-1">
                {" "}
                <label className="control-label">NIST</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={nist}
                  name="nist"
                  onChange={(e) => setNIST(e.target.value)}
                  placeholder="NIST  "
                />
              </div>
              <div className="col-md-4 col-xs-12 p-1">
                {" "}
                <label className="control-label">ISO_27001 </label>
                <input
                  type="text"
                  className="form-control input-lg"
                  value={iso}
                  name="iso"
                  onChange={(e) => setISO(e.target.value)}
                  placeholder="ISO_27001 "
                />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-12 col-xs-12 p-1">
                {" "}
                <label className="control-label">Email Body *</label>
                <textarea
                  type="text"
                  rows="5"
                  className="form-control input-lg"
                  name="emailbody"
                  value={emailbody}
                  onChange={(e) => setEmailBody(e.target.value)}
                  placeholder="Write matter here......"
                  row={6}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="col-md-6 col-xs-12 p-1">
              {" "}
              <label className="control-label">Email To *</label>
              <input
                type="text"
                className="form-control input-lg"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Id "
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-12 col-xs-12 p-1">
                {" "}
                <label className="control-label">EXTRACT VALUES *</label>
                <Select
                  isMulti
                  options={expectedOutput}
                  value={selectedExpectedOutput}
                  onChange={handleMultiChange}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group " style={{ display: "flex" }}>
              <div className="col-md-12 col-xs-12 p-1">
                {" "}
                <label className="control-label">QUERY  *</label>
                {/* <ReactQuill
                  theme="snow"
                  value={query}
                  name="query"
                  onChange={setQuery}
                /> */}
                <textarea value={query} onChange={(e) => handleQuery(e.target.value)} rows={7} className="form-control"></textarea>
              </div>
            </div>
          </div>



          <br />
          {/* <div className="row mt-4">{otherInformationDiv}</div> */}

          <br />
          <div className="row mt-3 py-3" style={styles.footerWrap}>
          <div className="form-group d-flex justify-content-end">
              {/* {!isValidJson && <p style={{ color: 'red' }}>Invalid JSON</p>} */}
              </div>
            {/* <div className="col-md-8 col-xs-12 p-2  "></div> */}
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-secondary" onClick={() => handleClose()}>Cancel</Button> &nbsp;
              <button type="submit" className="btn btn-primary" disabled={submitLoader === true ? true : false} >
                {submitLoader === true ? <>  Saving &nbsp;<div className="spinner-border spinner-border-sm" role="status">

                  <span className="sr-only"> </span>
                </div></> : 'Save'}
              </button>
            </div>
          </div >
        </div>
      )
      }
    </Form>
  );


  return (
    <>
      {" "}
      {customShow === true && (
        <CustomModal
          title={comIdEdit != '' ? 'Edit Compliance' : `Custom Compliance`}
          ShowOrHide={customShow}
          bodyData={renderModal}
          handleClose={handleClose}
          footer={false}
          modalSize={'lg'}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}{" "}
    </>
  );
};

export default CustomComplianceModal;
