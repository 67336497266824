

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogueMaster,
  getHuntCatalogueTenant,
  addToOrganizationCatalogueHunt,
  removeFromHuntCatalogue,
  refreshHuntTenantCatalogue,
  refreshHuntMasterCatalogue,
  setLoadingFalseHunter
} from "../../../Methods/RuleStudioMethods";
import Table from "react-bootstrap/Table";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import { ToastContainer, toast } from 'react-toastify';
import styles from "./Hunter.styles";
import Card from "react-bootstrap/Card";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { Button, Form, input } from "react-bootstrap";
// import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
// import Custom from "../../../components/CustomModal/RSCustomModal";
import { Pencil, PencilSimple, X, ArrowRight } from "phosphor-react";

// import CustomHunt from "../../../components/CustomModal/RSCustomModal";
import LoaderList from "../../../components/Loader/LoaderList";
import MasterCardHunt from "../../../components/RuleStudio/MasterCardHunt";
import SearchTi from "../../../components/Search/SearchTi";


import CustomMasterHunterModal from "../../../components/RuleStudio/Hunter/Custom/CustomMasterHunterModal";
import { removeHtmlChars } from "../../../Utils/Utils";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { toastsSuccess, toastsDanger, toastsWarning } from "../../../components/Toster/Toster";
import HuntDetail from "../../../components/RuleStudio/Hunter/Custom/HuntDetail";


const Hunter = () => {
  const dispatch = useDispatch();

  const [hunterId, setHunterId] = useState("");
  // const [huntDetailId, setHuntDetailId] = useState("");
  const [show, setShow] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const [detailPage, setDetailpage] = useState([]);
  const [searchQueryMaster, setSearchQueryMaster] = useState("");
  const [filteredDataMaster, setFilteredDataMaster] = useState([]);
  const [filteredDataCatalogue, setFilteredDataCatalogue] = useState([]);
  const [searchQueryCatalogue, setSearchQueryCatalogue] = useState("");

  const [submitLoader, setSubmitLoader] = useState(false);

  const ruleStudioAddToCatHunt = useSelector((state) => state.ruleStudioAddToCatHunt);
  const { success: catalogueAddSuccess, loading } = ruleStudioAddToCatHunt;


  const listCatRsHunt = useSelector((state) => state.listCatRsHunt);
  const { catalogue, loading: catLoading } = listCatRsHunt;

  const listMasterRsHunt = useSelector((state) => state.listMasterRsHunt);
  const { master, loading: masterLoading, deletedId, deleteSuccess } = listMasterRsHunt;

  // const removeCatRsHunt = useSelector((state) => state.removeCatRsHunt);
  // const { success, deletedId } = removeCatRsHunt;

  const createMasterRsHunt = useSelector((state) => state.createMasterRsHunt);
  const { success: createSuccess, error: createError } = createMasterRsHunt;


  const [refresh, setRefresh] = useState(false);
  const [add, setAdd] = useState(false)
  const [masterFilterAll, setMasterFilterAll] = useState(false);
  const [IfNoData, setIfNoData] = useState(false);
  const [commonData, setCommonData] = useState([]);
  const [toggleViewAll, setToggleViewAll] = useState(false);

  const toastDelete = () => toast("Case Deleted successfully");
  const toastsTime = () => toast.warn("It might take a while for the data to be updated !");


  // Detail page view
  const [detailShow, setDetailShow] = useState(false);
  const [huntShow, setHuntShow] = useState(false);

  
    useEffect(() => {
   console.log("hunter", hunterId);
    }, [hunterId]);
   

  useEffect(() => {

    if (master?.length === 0 || catalogue?.length === 0 || deletedId) {
      dispatch(getHuntCatalogueTenant());
      dispatch(getCatalogueMaster());


    }
    // filterDataMaster(searchQueryMaster);
  }, [deletedId]);

  // Refresh
  useEffect(() => {
    refreshHuntTenantCatalogue()
    refreshHuntMasterCatalogue()
  }, [])


  // Create
  useEffect(() => {
    dispatch(getHuntCatalogueTenant());
  }, [createSuccess])

  useEffect(() => {
    if (catalogueAddSuccess === true) {
      toastsSuccess("Data added successfully")
      toastsWarning("It might take a while for the data to be updated !")
    }
  }, [catalogueAddSuccess])


  //  Toasts
  useEffect(() => {



    if (createSuccess === true) {
      toastsSuccess("Data saved successfully")
      toastsWarning("It might take a while for the data to be updated !")
      setSubmitLoader(false)
      setCustomShow(false)
      dispatch(setLoadingFalseHunter())
    }

  }, [createSuccess])




  const handleOpenModal = (display) => {
    setShow(display);

  };

  const handleCustom = (display) => {
    setHunterId("");
    setCustomShow(display);
  };
  const handleEdit = (huntId) => {
    setHunterId(huntId);
    setCustomShow(true);
  };

  function handleCloseModal() {

    if (add === true) {
      refreshHuntMasterCatalogue();
      refreshHuntTenantCatalogue();

    }
    setShow(false);
    setSearchQueryMaster("");
    setMasterFilterAll(false);
    dispatch(getHuntCatalogueTenant());
  }

  function handleClose() {
    setToggleViewAll(false);
    setMasterFilterAll(false);
    setShow(false);
  }

  const handleSearchQueryMaster = (event) => {
    setSearchQueryMaster(event.target.value);
    filterDataMaster(event.target.value);
  };

  const handleSearchQueryCatalogue = (event) => {
    setSearchQueryCatalogue(event.target.value);
    filterDataCatalogue(event.target.value);
  };


  const filterDataCatalogue = (query) => {
console.log(query);
    if (query===""||query!="") {
      const filtered = catalogue?.filter((item) => {
        return (
          item[0].toLowerCase().includes(query.toLowerCase()) ||
          item[1].toLowerCase().includes(query.toLowerCase()) ||
          item[2].toLowerCase().includes(query.toLowerCase())
        );
      });
      setFilteredDataCatalogue(filtered);
    }


  };

  const filterDataMaster = (query) => {
    if (query===""||query!="") {
      const filtered = master?.filter((item) => {
        return item[0].toLowerCase().includes(query.toLowerCase()) ||
          item[1].toLowerCase().includes(query.toLowerCase()) ||
          item[2].toLowerCase().includes(query.toLowerCase());
      });

      setFilteredDataMaster(filtered);
    }


  };


  const AddToCatalogue = (id) => {
    setAdd(true);
    if (window.confirm("Add the item?")) {
      dispatch(addToOrganizationCatalogueHunt(id));
      // dispatch(getCatalogueMaster());

    }
  };


  const deleteFromCatalogue = (id, index) => {
    if (window.confirm("Delete the item?")) {
      toastsDanger("Deleted successfully")
      toastsWarning("It might take a while for the data to be updated !")

      dispatch(removeFromHuntCatalogue(id, index));
      if (deleteSuccess === true || deleteSuccess === undefined) {
        refreshHuntTenantCatalogue().then((res) => {
          if (res.status === 200) { dispatch(getHuntCatalogueTenant()) }
        })
      }

    }



  };

  let masterData = [];


  masterData =
    filteredDataMaster?.length === 0 && searchQueryMaster === '' ? master : filteredDataMaster;


  // new one
  useEffect(() => {

    setMasterData()
  }, [toggleViewAll, masterData, show, commonData])

  const setMasterData = () => {

    if (toggleViewAll === false) {
      const array = masterData?.filter(item1 => !catalogue?.find(item2 => item1[0] === item2[0]));

      setCommonData(array)
    }

    if (toggleViewAll === true) {

      setCommonData(masterData)
    }

  }


  const handleRefresh = (status = false) => {

    setRefresh(true)
    if (refresh === true || status === true) {
      refreshHuntMasterCatalogue()
      refreshHuntTenantCatalogue().then((res) => {
        if (res.status === 200) {
          setRefresh(false)

        }
      })
    }

  };



  const handleToggleChange = (e) => {
    toggleViewAll === false ? setToggleViewAll(true) : setToggleViewAll(false)
    IfNoData === true ? setIfNoData(true) : setIfNoData(false)

    masterFilterAll === false ? setMasterFilterAll(true) : setMasterFilterAll(false)

  }


  const handleSearchMaster = (val, e, action) => {

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryMaster(val);
      filterDataMaster(val);
    }

  };

  const handleModal =(huntid) =>{
    console.log("huntid",huntid);
    setHunterId(huntid)
    setDetailShow(true)
  }
 
  function renderModal() {
    return (
      <div className="px-3">
        <div className="mb-3 py-2 sticky-top">
          <div className="col-12 d-flex flex-row">
            <div className="col-4" style={{ marginRight: "auto" }}>
              {/* <h5>Master Catalogue</h5> */}
              <SearchTi changeFunction={handleSearchMaster} module='rulestudio' />


            </div >


            <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 mx-2">
              <span>Total Hunts</span>
              <h6 className="mb-0">{commonData && commonData?.length}</h6>
            </div>
            <div className="col-2 d-flex  align-items-center">
              <Form>
                <Form.Check
                  type="switch"
                  id="toggle_view_switch"
                  label="View All"
                  defaultChecked={toggleViewAll}
                  onChange={handleToggleChange}
                />
              </Form>
            </div>
            <div className="">
              <Link style={{ color: "white" }} onClick={() => handleRefresh(true)}>
                <button className="btn btn-primary">Refresh</button>
              </Link>
            </div>
          </div >
        </div >
        {masterLoading === true || refresh === true ? (
          <LoaderList />
        ) : (
          <div className="col-12" style={styles.section1}>
            {commonData && commonData?.length === 0 || IfNoData === true || master?.length === 0 ? <NoDataFound /> : ""}
            {commonData && commonData?.length != 0 ? setIfNoData === false : ""}
            <div className="d-flex flex-column gap-3">
              {commonData?.map((item, index) => {

                const filter = catalogue?.some(
                  (data) => data[0].toLowerCase() === item[0].toLowerCase()
                );


                return (

                  <MasterCardHunt
                    item={item}
                    filter={filter}
                    AddToCatalogue={AddToCatalogue}
                    loading={loading}
                    index={index}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div >
    );
  }
  const filterCatalogue = (val) => {

    let filtered = [];

    if (val === 'All') {
      filtered = catalogue;
    } else {
      filtered = catalogue?.filter((item) => {
        return item[3][0].toLowerCase().includes(val.toLowerCase());
      });
    }

    setFilteredDataCatalogue(filtered)
  };

  let catalogueData = [];

  catalogueData = filteredDataCatalogue?.length === 0 && searchQueryCatalogue === '' ? catalogue : filteredDataCatalogue;

  const handleSearchCat = (val, e, action) => {
    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryCatalogue(val);
      filterDataCatalogue(val);
    }
  };

  return (
    <div className="col-12 rounded-3 p-4" style={styles.section1}>
      <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
        <h5 className="mb-0">
          Organization Catalogue
        </h5>
        <div className="d-flex">
          <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 mx-2">
            <span>Total Hunts</span>
            <h6 className="mb-0">{catalogueData && catalogueData?.length}</h6>
          </div>
          <div className="form-group mx-2">

            <SearchTi changeFunction={handleSearchCat} module='rulestudio' />

            {/* <Form.Control
              type="text"
              placeholder="search"
              className=""
              style={{ width: "" }}
              onChange={(e) => handleSearchQueryCatalogue(e)}
            /> */}
          </div >

          <Link
            className="mx-2"
            style={{ color: "white" }}
            onClick={() => handleOpenModal(true)}
          >
            <button className="btn btn-primary">Add from master</button>
          </Link>
          <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
            <button className="btn btn-light">Create Custom</button>
          </Link>
        </div >
      </div >

      <div style={styles.mainWrap}>
        {catLoading === true ? (
          <TableLoader rowCount={15} />
        ) : (
          catalogueData && catalogueData?.length === 0 ? <NoDataFound /> :
            <div className="d-flex flex-row flex-wrap py-3">
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>ID</th>
                    <th>Alert name</th>
                    <th>Description</th>

                    <th>  </th>

                  </tr>
                </thead>
                <tbody>
                  {catalogueData && catalogueData?.map((item, index) => {
                    return (
                      <tr>   <td className="align-middle">
                        {item[0]}
                      </td>
                        <td className="align-middle">
                          {item[1]}
                        </td>
                        <td className="align-middle">
                          {removeHtmlChars(item[2])}
                        </td>

                        <td className="align-middle text-center">
                          <div className="d-flex flex-row gap-3 justify-content-center">
                            <i
                              className="cursor-pointer"
                              onClick={(e) => handleEdit(item[0])}
                            >
                              <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />
                            </i>
                            <i className="cursor-pointer" onClick={(e) => deleteFromCatalogue(item[0], index)}>
                              <TableViewMore Icon={X} dataToggle={"Delete"} dataPlacement={"bottom"} />
                            </i>

                            {/* <Link
                              to={{
                                pathname: `/rs/huntsummary/${item[0]}`,
                                state: { sourceList: item[0], index_id: item }                              }}
                              onClick={() => handleModal(item[0])}
                            > */}
                             <i
                              className="cursor-pointer"
                              onClick={(e) => handleModal(item[0])}
                            >
                              <TableViewMore Icon={ArrowRight} dataToggle={"View details"} dataPlacement={"bottom"} />
                            {/* </Link> */}
                            </i>
                          </div>


                        </td>
                      </tr>

                    );
                  })}    </tbody> </Table>
            </div>
        )}
      </div>
      <ToastContainer />
      {
        show === true && (
          <CustomModal
            title={"Master Catalogue"}
            ShowOrHide={show}
            bodyData={renderModal}
            handleClose={handleClose}
            footer={false}
            noBodyPadding={true}
            modalSize={'lg'}
            overflowHidden={false}
            modalPosition={"right"}
            outsideClickDisabled={true}
          ></CustomModal>
        )
      }

      {
        customShow === true && (

          <CustomMasterHunterModal
            customShow={customShow}
            setCustomShow={setCustomShow}
            setHunterIdEdit={setHunterId} 
            huntIdEdit={hunterId}
            createSuccess={createSuccess}
            submitLoader={submitLoader}
            setSubmitLoader={setSubmitLoader}
          />
          )
        }
          {
            detailShow && (
              <HuntDetail
              detailShow={detailShow}
              setDetailShow={setDetailShow}
              setHunterIdEdit={setHunterId}
              huntIdEdit={hunterId}
              createSuccess={createSuccess}
              submitLoader={submitLoader}
              setSubmitLoader={setSubmitLoader}
            />
            )
          }
       
    </div >
  );
};
export default Hunter;