import { fpostCy } from '../../../axios/axios';
import { useEffect, useMemo, useState } from 'react';
import Flow from '../../../components/Cykit/Flow';
import { ReactFlowProvider } from 'reactflow';
import { getCykitDocQuery } from '../../../Queries/CykitQuery';

import { useParams } from 'react-router-dom';
import styles from './CykitGraph.styles';
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");
let baseURL = localStorage.getItem("DATALAKE_URL");

const CykitGraph = () => {
  const [selectedHost, setSelectedHost] = useState();
  const [indexType, setIndexType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [processData, setProcessData] = useState([])
  const [qdoc_id, setqdoc_id] = useState('')
  const [type, setType] = useState('')


  const { dashboard, doc_id } = useParams();

  useMemo(() => {
    setIsLoading(true)
    if (qdoc_id !== '' && qdoc_id !== undefined) {
      const data = getCykitDocQuery(qdoc_id, indexType)
      fpostCy(
        `${baseURL}/${indexPattern}`,
        data).then((response) => {
          const filterDataTemp = []
          response.data.hits.hits && response.data.hits.hits.map((val) => {
            setSelectedHost(val._source.host.name)
            setProcessData(val)
          })
          setIsLoading(true)
          setTimeout(() => { setIsLoading(false) }, 1000)

        });
    }
  }, [qdoc_id])

  useEffect(() => {
    if (doc_id !== undefined) {
      if(edr==="elastic"){
        console.log(doc_id)
        setqdoc_id(doc_id)
        if(dashboard==="process"){
          setIndexType("process")
          setType('process')
          
        }
        if(dashboard==="file"){
          setIndexType("file")
          setType('file')
        }
        if(dashboard==="network"){
          setIndexType("network")
          setType('network')
        }
        if(dashboard==="registry"){
          setIndexType("registry")
          setType('registry')
        }
        if(dashboard==="unified"){
          setType('unified')
        }
      }else if(edr==="atp"){
        console.log(doc_id)
        setqdoc_id(doc_id)
        if(dashboard==="process"){
          setIndexType("AdvancedHunting-DeviceProcessEvents")
          setType('process')
        }
        if(dashboard==="file"){
          setIndexType("AdvancedHunting-DeviceFileEvents")
          setType('file')
        }
        if(dashboard==="network"){
          setIndexType("AdvancedHunting-DeviceNetworkEvents")
          setType('network')
        }
        if(dashboard==="registry"){
          setIndexType("AdvancedHunting-DeviceRegistryEvents")
          setType('registry')
        }
        if(dashboard==="unified"){
          setType('unified')
        }
      }
    }
  }, [doc_id])

  return (

    <div className='col-12 rounded-3' style={styles.section1}>
      <div className='col-12'>
        <ReactFlowProvider>
          <Flow host={selectedHost} processData={processData} type={type} />
          {/* <Loader isLoading={isLoading}/> */}
        </ReactFlowProvider>
      </div>
    </div>
  )
}
export default CykitGraph;
