import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import {
  formatDate,
  removeHtmlChars,
} from "../../Utils/Utils.js";

export default function MasterCardInv({
  item,
  filter,
  AddToCatalogue,
  loading,
  index,
}) {
  const [masterAddIndex, setMasterAddIndex] = useState();

  useEffect(() => {
    if (loading === false) {
      setMasterAddIndex();
    }
  }, [loading]);

  const addToCatalogueFunction = (data) => {
    AddToCatalogue(data);

    setMasterAddIndex(index);
  };

  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <Card.Title>{item.inv_id}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {item.inv_name}
        </Card.Subtitle>
        <Card.Text>
          {" "}
          <div className="d-flex">
            <div className="mr-auto"> {removeHtmlChars(item.Description)} <br /><br />
              {item?.inv_created &&
                <>
                  <span>Created on <strong>{item?.inv_created}</strong></span> &nbsp;</>}
              {item?.inv_last_edited &&
                <span>Updated on <strong>{item?.inv_last_edited}</strong></span>}

            </div>


            {filter === true ? (
              <div className="p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check-lg  "
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </div>
            ) : (
              <div className="p-2" onClick={(e) => addToCatalogueFunction(item.inv_id)}>
                {loading === true && masterAddIndex === index ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"> </span>
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg  "
                    viewBox="0 0 16 16"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>{" "}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}