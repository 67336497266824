import React, { useEffect, useState } from 'react'
import { getCyATPAlertsTable, getHD, getUniqueDetectionTypeCounts, getUniqueHDCounts } from '../../../../Methods/CyKitInvestigate'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils';
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import { Bar, Doughnut } from "react-chartjs-2";
import TableLoader from '../../../../components/Loader/TableLoader';
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination';
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const datalakeURL = localStorage.getItem("DATALAKE_URL");

const ATPAlert = () => {


    const [uniqueHostCount, setUniqueHostCount] = useState([])
    const [uniqueDetectionTypeCount, setUniqueDetectionTypeCount] = useState([])


    // graph
    const [hostGraphLabel, setHostGraphLabel] = useState([])
    const [hostGraphData, setHostGraphData] = useState([])

    const [detectionTypeLabel, setDetectionTypeLabel] = useState([])
    const [detectionTypeData, setDetectionTypeData] = useState([])

    const [detectionSourceLabel, setDetectionSourceLabel] = useState([])
    const [detectionSourceData, setDetectionSourceData] = useState([])



    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    // loaders

    const [hostGraphLoader, setHostGraphLoader] = useState(true)
    const [detectionTypeLoader, setDetectionTypeLoader] = useState(true)
    const [detectionSourceLoader, setDetectionSourceLoader] = useState(true)

    const [tableLoader, setTableLoader] = useState(true)

    // table
    const [tableData, setTableData] = useState([])

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false);
    });

    const module = 'investigation'
    const tab = 'cykit-atp-alerts'
    let count = [];
    let count2;


    // tab
    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()


    // filters
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(1, "day")?.format("yyyy-MM-DDTHH:mm"),
        to: moment().format("yyyy-MM-DDTHH:mm"),
        f: moment().subtract(1, "day")?.format("yyyy-MM-DDTHH:mm"),
        e: moment().format("yyyy-MM-DDTHH:mm"),
        range: 1
    });
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const [checkedItems, setCheckedItems] = useState([]);
    const [manageDatas, setManageDatas] = useState([
        { text: "Detection Name", value: "detection?.type", isSelected: true },
        { text: "Entity Type", value: "EntityType", isSelected: true },
        { text: "Event category", value: "event?.category", isSelected: true },
        { text: "Severity", value: "Severity", isSelected: true },
        { text: "Detection Source", value: "detection_source", isSelected: true },


        // { text: "Category", value: "Category", isSelected: true },
        { text: "Attack techniques", value: "AttackTechniques", },
        { text: "Account Name", value: "AccountName", },
        { text: "Account Upn", value: "AccountUpn", },
        { text: "Host", value: "Host.NetBiosName", isSelected: true },

    ])

    useEffect(() => {
        Object.keys(localStorage).forEach((key) => {
            if (key === 'ManageColumn.investigate.cykit.atp.alerts') {
                setManageDatas(JSON?.parse(localStorage.getItem("ManageColumn.investigate.cykit.atp.alerts")))
            }
        })
    }, [manageDatas])

    const getFilteredTable = (filter, date = {}) => {
        setTableLoader(true)
        if (filter || date?.to) {
            getCyATPAlertsTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTotalPageCount(hits.total.value)
                setTableData(hits.hits)
                setTableLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setTableLoader(false)
            })
        }
        else {
            getCyATPAlertsTable("", "", 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTotalPageCount(hits.total.value)
                setTableData(hits.hits)
                setTableLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setTableLoader(false)
            })
        }
    }

    useEffect(() => {
        setHostGraphLoader(true)
        setDetectionTypeLoader(true)
        setTableLoader(true)
        setDetectionSourceLoader(true)
        // get counts Host name
        getUniqueHDCounts("properties.DeviceName", filter, dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            unique_users?.value ? setUniqueHostCount(unique_users.value) : setUniqueHostCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        // get counts Detection type

        getUniqueDetectionTypeCounts("properties.Title", filter, dateFilter).then(({ data: { aggregations: { unique_users } } }) => {
            unique_users?.value ? setUniqueDetectionTypeCount(unique_users.value) : setUniqueDetectionTypeCount(0)
        }).catch((err) => {
            console.log("Error", err)
        })

        // get graph HD

        getHD("properties.DeviceName", filter, dateFilter, true).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setHostGraphLabel(label);
                    setHostGraphData(data);
                });
                setHostGraphLoader(false)
            }
            else {
                setHostGraphLabel([]);
                setHostGraphData([]);
                setHostGraphLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
            setHostGraphLoader(false)
        })

        getHD("properties.Title", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setDetectionTypeLabel(label);
                    setDetectionTypeData(data);
                });
                setDetectionTypeLoader(false)
            }
            else {
                setDetectionTypeLabel([]);
                setDetectionTypeData([]);
                setDetectionTypeLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
            setDetectionTypeLoader(false)
        })

        getHD("properties.DetectionSource", filter, dateFilter).then(({ data: { aggregations: { 1: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setDetectionSourceLabel(label);
                    setDetectionSourceData(data);
                });
                setDetectionSourceLoader(false)
            }
            else {
                setDetectionSourceLabel([]);
                setDetectionSourceData([]);
                setDetectionSourceLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
            setDetectionSourceLoader(false)
        })



        // table
        // setCurrentPageCount((page - 1) * perPage + perPage);
        getCyATPAlertsTable(filter, dateFilter, 100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
            setTotalPageCount(hits.total.value)
            setTableData(hits.hits)
            setTableLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setTableLoader(false)
        })

        // @timestamp
        // DetectionStatus
        // host -> name
        // Directory
    }, [page, perPage, filter])

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }


    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value));
        }

        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.atp.alerts", JSON.stringify(manageDatas))
    }
    const getCategory = (data) => {
        console.log('WHATTT :', data)
        return JSON?.parse(data)?.[0]
    }
    return (
        <div className="col-12 position-relative">
            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }}
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { label: "Detection Source", value: "properties.DetectionSource", isWildCard: true },
                                { label: "Host", value: "properties.DeviceName", isWildCard: true },
                                { label: "Directory", value: "properties.AccountDomain", isWildCard: true },
                                { label: "Detection Name", value: "properties.Title", isWildCard: true },
                                { label: "Event category", value: "properties.Categories", isWildCard: true },
                                { label: "Account Name", value: "properties.AccountName", isWildCard: true },
                                { label: "Raw Field", value: "event.original", isWildCard: true },
                                { label: "Additional Fields", value: "properties.AdditionalFields", isWildCard: true },
                                { label: "Entity Type", value: "properties.EntityType", isWildCard: true },


                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>


                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                {/* {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyEmailTable} type={"email"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )} */}

                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyATPAlertsTable} type={"atp_alert"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}


                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}


            </div>

            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                    <div className="col-6 rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{uniqueHostCount ? uniqueHostCount : "--"}</h4>
                                            <span>No of unique Host count </span>
                                        </div>
                                    </div>
                                    <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                        <div>
                                            <h4>{uniqueDetectionTypeCount ? uniqueDetectionTypeCount : "--"}</h4>
                                            <span>No of unique detection type</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">

                                    <div className="col-6 p-4 rounded-3 border" >
                                        <h5>Host</h5>
                                        {hostGraphLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {hostGraphLabel.length || hostGraphData.length !== 0 ? (
                                                    <BarGraph ChartLabel={hostGraphLabel} ChartData={hostGraphData} module={module} tab={tab} field={"properties.DeviceName"} label={"Host Name"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                    <div className="col-12 flex-fill p-4 rounded-3 border" >
                                        <h5>Detection Type</h5>
                                        {detectionTypeLoader ? (<PlainCardLoader width="200px" />) : (
                                            <div className='col-12'>
                                                {detectionTypeLabel.length || detectionTypeData.length !== 0 ? (
                                                    <BarGraph ChartLabel={detectionTypeLabel} ChartData={detectionTypeData} module={module} tab={tab} field={"properties.Title"} label={"Detection Type"} setfilter={setFilter} filter={filter} />
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                        <NoDataFound errorText={"No data found"} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="col-6 flex-fill p-4 rounded-3 border" >
                                    <h5>Detection Source</h5>
                                    {detectionSourceLoader ? (<PlainCardLoader width="200px" />) : (
                                        <div className='col-12'>
                                            {detectionSourceData.length || detectionSourceData.length !== 0 ? (
                                                <BarGraph ChartLabel={detectionSourceLabel} ChartData={detectionSourceData} module={module} tab={tab} field={"properties.DetectionSource"} label={"Detection Source"} setfilter={setFilter} filter={filter} />
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="" style={styles.tableWrap}>
                                {tableLoader ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    tableData.length !== 0 ? (
                                        <Table borderless hover>
                                            <thead className="fixed-head">
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    {manageDatas.map((item) => (
                                                        item.isSelected === true &&
                                                        <th>{item?.text}</th>
                                                    ))}
                                                    {/* <th>Sender Address</th>
                                                    <th>Recevier Address</th>
                                                    <th>Subject</th>
                                                    <th>Status</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((item, index) => (
                                                    <tr
                                                        onMouseOver={() =>
                                                            setShowInlineFilter({ show: true, index: index })
                                                        }
                                                        onMouseOut={() =>
                                                            setShowInlineFilter({ show: false, index: index })
                                                        }>
                                                        <td className='font-monospace'>{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                        <td className='font-monospace'>{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>

                                                        {manageDatas?.map((data) => (
                                                            data.value === "detection?.type" && data.isSelected === true ? (
                                                                <td>
                                                                    <span className="position-relative font-monospace">
                                                                        {item?._source?.properties?.Title}
                                                                        {showInlineFilter.show &&
                                                                            showInlineFilter.index === index && (
                                                                                <div className="inline-filter-wrap">
                                                                                    <InlineFilter
                                                                                        filter={filter}
                                                                                        setFilter={setFilter}
                                                                                        column={{
                                                                                            value: "properties.Title",
                                                                                            label: "Detection Name",
                                                                                        }}
                                                                                        value={item?._source?.properties?.Title}
                                                                                        getFilteredTable={getFilteredTable}
                                                                                        module={module}
                                                                                        tab={tab}
                                                                                        dateFilter={dateFilter}
                                                                                        setPage={setPage}

                                                                                    />
                                                                                </div>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            ) :
                                                                data.value === "EntityType" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="position-relative font-monospace">
                                                                            {item?._source?.properties?.EntityType}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "properties.EntityType",
                                                                                                label: "Entity Type",
                                                                                            }}
                                                                                            value={item?._source?.properties?.EntityType}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) :
                                                                    data.value === "detection_source" && data.isSelected === true ? (
                                                                        <td>
                                                                            <span className="position-relative font-monospace">
                                                                                {item?._source?.properties?.DetectionSource}
                                                                                {showInlineFilter.show &&
                                                                                    showInlineFilter.index === index && (
                                                                                        <div className="inline-filter-wrap">
                                                                                            <InlineFilter
                                                                                                filter={filter}
                                                                                                setFilter={setFilter}
                                                                                                column={{
                                                                                                    value: "properties.DetectionSource",
                                                                                                    label: "Detection Source",
                                                                                                }}
                                                                                                value={item?._source?.properties?.DetectionSource}
                                                                                                getFilteredTable={getFilteredTable}
                                                                                                module={module}
                                                                                                tab={tab}
                                                                                                dateFilter={dateFilter}
                                                                                                setPage={setPage}

                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                            </span>
                                                                        </td>
                                                                    ) :
                                                                        data.value === "Severity" && data.isSelected === true ? (
                                                                            <td>
                                                                                <span className="position-relative font-monospace">
                                                                                    {item?._source?.properties?.Severity}
                                                                                    {showInlineFilter.show &&
                                                                                        showInlineFilter.index === index && (
                                                                                            <div className="inline-filter-wrap">
                                                                                                <InlineFilter
                                                                                                    filter={filter}
                                                                                                    setFilter={setFilter}
                                                                                                    column={{
                                                                                                        value: "properties.Severity",
                                                                                                        label: "Severity",
                                                                                                    }}
                                                                                                    value={item?._source?.properties?.Severity}
                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                    module={module}
                                                                                                    tab={tab}
                                                                                                    dateFilter={dateFilter}
                                                                                                    setPage={setPage}

                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                </span>
                                                                            </td>
                                                                        )
                                                                            :
                                                                            data.value === "Category" && data.isSelected === true ? (
                                                                                <td>
                                                                                    <span className="position-relative font-monospace">
                                                                                        {item?._source?.properties?.Categories}
                                                                                        {showInlineFilter.show &&
                                                                                            showInlineFilter.index === index && (
                                                                                                <div className="inline-filter-wrap">
                                                                                                    <InlineFilter
                                                                                                        filter={filter}
                                                                                                        setFilter={setFilter}
                                                                                                        column={{
                                                                                                            value: "properties.Categories",
                                                                                                            label: "Category",
                                                                                                        }}
                                                                                                        value={item?._source?.properties?.Categories}
                                                                                                        getFilteredTable={getFilteredTable}
                                                                                                        module={module}
                                                                                                        tab={tab}
                                                                                                        dateFilter={dateFilter}
                                                                                                        setPage={setPage}

                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                    </span>
                                                                                </td>
                                                                            )
                                                                                :
                                                                                data.value === "AttackTechniques" && data.isSelected === true ? (
                                                                                    <td>
                                                                                        <span className="position-relative font-monospace">
                                                                                            {item?._source?.properties?.AttackTechniques && getCategory(item?._source?.properties?.AttackTechniques)}

                                                                                        </span>
                                                                                    </td>
                                                                                )
                                                                                    :
                                                                                    data.value === "AccountUpn" && data.isSelected === true ? (
                                                                                        <td>
                                                                                            <span className="position-relative font-monospace">
                                                                                                {item?._source?.properties?.AccountUpn}
                                                                                                {showInlineFilter.show &&
                                                                                                    showInlineFilter.index === index && (
                                                                                                        <div className="inline-filter-wrap">
                                                                                                            <InlineFilter
                                                                                                                filter={filter}
                                                                                                                setFilter={setFilter}
                                                                                                                column={{
                                                                                                                    value: "properties.AccountUpn",
                                                                                                                    label: "Account Upn",
                                                                                                                }}
                                                                                                                value={item?._source?.properties?.AccountUpn}
                                                                                                                getFilteredTable={getFilteredTable}
                                                                                                                module={module}
                                                                                                                tab={tab}
                                                                                                                dateFilter={dateFilter}
                                                                                                                setPage={setPage}

                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                            </span>
                                                                                        </td>
                                                                                    )
                                                                                        : data.value === "Host.NetBiosName" && data.isSelected === true ? (
                                                                                            <td>
                                                                                                <span className="position-relative font-monospace">
                                                                                                    {item?._source?.properties?.DeviceName}
                                                                                                    {showInlineFilter.show &&
                                                                                                        showInlineFilter.index === index && (
                                                                                                            <div className="inline-filter-wrap">
                                                                                                                <InlineFilter
                                                                                                                    filter={filter}
                                                                                                                    setFilter={setFilter}
                                                                                                                    column={{
                                                                                                                        value: "Host.NetBiosName",
                                                                                                                        label: "Host",
                                                                                                                    }}
                                                                                                                    value={item?._source?.Host?.NetBiosName}
                                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                                    module={module}
                                                                                                                    tab={tab}
                                                                                                                    dateFilter={dateFilter}
                                                                                                                    setPage={setPage}

                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                </span>
                                                                                            </td>
                                                                                        ) : data.value === "Directory" && data.isSelected === true ? (
                                                                                            <td>
                                                                                                <span className="position-relative font-monospace">
                                                                                                    {item?._source?.properties?.AccountDomain}
                                                                                                    {showInlineFilter.show &&
                                                                                                        showInlineFilter.index === index && (
                                                                                                            <div className="inline-filter-wrap">
                                                                                                                <InlineFilter
                                                                                                                    filter={filter}
                                                                                                                    setFilter={setFilter}
                                                                                                                    column={{
                                                                                                                        value: "properties.AccountDomain",
                                                                                                                        label: "Directory",
                                                                                                                    }}
                                                                                                                    value={item?._source?.properties?.AccountDomain}
                                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                                    module={module}
                                                                                                                    tab={tab}
                                                                                                                    dateFilter={dateFilter}
                                                                                                                    setPage={setPage}

                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                </span>
                                                                                            </td>
                                                                                        ) : data.value === "event?.category" && data.isSelected === true ? (
                                                                                            <td>
                                                                                                <span className="position-relative font-monospace">
                                                                                                    {item?._source?.properties?.Categories && getCategory(item?._source?.properties?.Categories)}
                                                                                                </span>
                                                                                            </td>
                                                                                        ) : data.value === "AccountName" && data.isSelected === true ? (
                                                                                            <td>
                                                                                                <span className="position-relative font-monospace">
                                                                                                    {item?._source?.properties?.AccountName}
                                                                                                    {showInlineFilter.show &&
                                                                                                        showInlineFilter.index === index && (
                                                                                                            <div className="inline-filter-wrap">
                                                                                                                <InlineFilter
                                                                                                                    filter={filter}
                                                                                                                    setFilter={setFilter}
                                                                                                                    column={{
                                                                                                                        value: "properties.AccountName",
                                                                                                                        label: "Account Name",
                                                                                                                    }}
                                                                                                                    value={item?._source?.properties?.AccountName}
                                                                                                                    getFilteredTable={getFilteredTable}
                                                                                                                    module={module}
                                                                                                                    tab={tab}
                                                                                                                    dateFilter={dateFilter}
                                                                                                                    setPage={setPage}

                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                </span>
                                                                                            </td>
                                                                                        ) : (<></>)
                                                        ))}
                                                        <td>
                                                            <a onClick={() => { setExpClickedNode(item._id); setExpClose(false) }} style={{ marginLeft: "20px" }}>
                                                                <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                                                {/* <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} /> */}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${localStorage.getItem("INVESTIGATE_CY_ATP_ALERTS")}/_search?track_total_hits=true`} req={fpostDl} />
        </div >
    )
}

export default ATPAlert