import { filterGen } from "./InvestigationQuery";
import { genstrut } from "../Utils/Utils";
import moment from "moment";
// cykit linux querys
export const getUniqueCountsQuery = (field_name) => {
  return {
    size: 0,
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };
};

// graph

export const getMostPerformedQuery = (field_name) => {
  return {
    aggs: {
      2: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 100,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            exists: {
              field: field_name,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: "now-30d",
                lte: "now",
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

// table

export const getCyLinuxTableQuery = () => {
  return {
    from: 0,
    size: 100,
    query: {
      bool: {
        filter: [
          {
            range: {
              "@timestamp": {
                gte: "now-100d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
          {
            exists: {
              field: "user.name",
            },
          },
          {
            exists: {
              field: "host.name",
            },
          },
          {
            exists: {
              field: "event.action",
            },
          },
          {
            exists: {
              field: "process.name",
            },
          },
        ],
      },
    },
    sort: {
      "@timestamp": {
        order: "desc",
      },
    },
  };
};

// cykit Email

// sender and recevier counts (SR)

export const getUniqueSRCountsQuery = (field_name, field, date) => {

  var data = {
    size: 0,
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
    query: {
      bool: {
        filter: [],
        must: [],
        must_not: [],
        should: [],
      },
    },
  };
  return filterGen(data, field, date);
};

// sender, recevier, action counts (SRA)

export const getMostCommonSRAQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        filter: [
        ],
      },
    },
    aggs: {
      2: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

// cy email table

export const getCyEmailTableQuery = (filter, date, size, sort, page) => {
  var data = {
    from: page,
    size: size,

    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        filter: [
          {
            exists: {
              field: "email.from.address",
            },
          },
          {
            exists: {
              field: "email.receiver.address",
            },
          },
        ],
      },
    },
    sort: {
      "@timestamp": {
        order: sort,
      },
    },
  };
  const test = filterGen(data, filter, date);
  console.log("test123", test);
  return test;
};
export const getCYUnifiedTableQuery = (filter, date, size, sort, page) => {
  var data = {
    from: page,
    size: size,

    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        filter: [

        ],
      },
    },
    sort: {
      "@timestamp": {
        order: sort,
      },
    },
  };
  const test = filterGen(data, filter, date);
  console.log("test123", test);
  return test;
};

export const getFireWallTrafficQuery = (date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              "@timestamp": {
                gte: date,
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    sort: {
      "@timestamp": {
        order: "desc",
      },
    },
    aggs: {
      network_in: {
        sum: {
          field: "bytesin",
        },
      },
      network_out: {
        sum: {
          field: "bytesout",
        },
      },
    },
  };
  return data;
};

// cykit ATP Alerts
// host and detection type counts (HD)

export const getUniqueDetectionTypeCountsQuery = (field_name, filter, dateFilter) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };
  return filterGen(data, filter, dateFilter);
};

export const getUniqueHDCountsQuery = (field_name, filter, dateFilter) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [],
        should: [],
        must_not: [
          {
            "term": {
              "properties.DeviceName": ""
            }
          },
        ],
      },
    },
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };
  return filterGen(data, filter, dateFilter);
};

// graph

export const getHDQuery = (field_name, filter, dateFilter, isGraph) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [

        ],
        should: [],
        must_not: [

        ],
      },
    },

    aggs: {
      1: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  if (isGraph) {
    var nullQuery = {
      term: {
        "properties.DeviceName": ""
      }
    }
    data.query.bool.must_not.push(nullQuery)
  }
  return filterGen(data, filter, dateFilter);
};

export const getCyATPAlertsTableQuery = (filter, date, size, order, page) => {
  var data = {
    from: page,
    size: size,
    query: {
      bool: {
        must: [],
        should: [],
        must_not: [],
        filter: [
          { match_all: {} },
          {
            range: {
              "@timestamp": {
                gte: "now-300d",
                lte: "now",
                format: "strict_date_optional_time",
              },
            },
          },
        ],
      },
    },
    "sort": { "@timestamp": { "order": "desc" } }
  };
  return filterGen(data, filter, date);
};

// office 365 ad

export const getOffice365AdCountsQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    Workload: "AzureActiveDirectory",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  if (field_name === "cybSourceCountry") {
    var fil = {
      match_phrase: {
        cybSourceCountry: "%{[geoip][geo][country_name]}",
      },
    };
    data.query.bool.must_not.push(fil);
  }
  return filterGen(data, filter, date);
};

// graph

export const getSourceCountyQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    Workload: "AzureActiveDirectory",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          },
        ],
      },
    },

    aggs: {
      1: {
        terms: {
          field: "cybSourceCountry",
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

// get " D " device os , " B " browser type ,  " O " operation

export const getDBOQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    Workload: "AzureActiveDirectory",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },

    aggs: {
      1: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getUserIdQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    Workload: "AzureActiveDirectory",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "Not Available",
            },
          },
        ],
      },
    },

    aggs: {
      1: {
        terms: {
          field: "UserId",
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getCyO365AdTableQuery = (filter, date, size, sort, page) => {
  var data = {
    from: page,
    size: size,
    "sort": [
      {
        "@timestamp": "desc"
      }
    ],
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    Workload: "AzureActiveDirectory",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            exists: {
              field: "ClientIP",
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
  return filterGen(data, filter, date);
};

// office 365 ( OD ) one drive

// counts

export const getOffice365ODCountsQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "OneDrive",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "app@sharepoint",
            },
          },
        ],
      },
    },
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

// source country

export const getOneDriveSourceCountyQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "OneDrive",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "app@sharepoint",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "cybSourceCountry",
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

// get " O " operations , " P " Platform ,  " U " Uesr Id
export const getOneDriveOPUQuery = (field_name, matchPhrase, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "OneDrive",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "app@sharepoint",
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  matchPhrase && data.query.bool.must_not.push(matchPhrase);

  return filterGen(data, filter, date);
};

// table
export const getCyO365OdTableQuery = (filter, date, size, sort, page) => {
  var data = {
    from: page,
    size: size,
    "sort": [
      {
        "@timestamp": "desc"
      }
    ],
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "OneDrive",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "app@sharepoint",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          }
        ],
      },
    },
  };
  return filterGen(data, filter, date);
};

// office 365 ( Exchange )

export const getOffice365ExchangeCountsQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "Exchange",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "NT AUTHORITY\\\\SYSTEM (Microsoft.Exchange.ServiceHost)",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          }
        ],
      },
    },
    aggs: {
      unique_users: {
        cardinality: {
          field: field_name,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getExchangeSourceCountyQuery = (filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "Exchange",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "NT AUTHORITY\\\\SYSTEM (Microsoft.Exchange.ServiceHost)",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          },
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: "cybSourceCountry",
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getExchangeOUQuery = (field_name, filter, date) => {
  var data = {
    size: 0,
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "Exchange",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "NT AUTHORITY\\\\SYSTEM (Microsoft.Exchange.ServiceHost)",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          }
        ],
      },
    },
    aggs: {
      1: {
        terms: {
          field: field_name,
          order: {
            _count: "desc",
          },
          size: 10,
        },
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getCy365ExchangeTableQuery = (filter, date, count, sort, page) => {
  var data = {
    from: page,
    size: count,
    "sort": [
      {
        "@timestamp": "desc"
      }
    ],
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              Workload: "Exchange",
            },
          },
        ],
        should: [],
        must_not: [
          {
            match_phrase: {
              UserId: "NT AUTHORITY\\\\SYSTEM (Microsoft.Exchange.ServiceHost)",
            },
          },
          {
            match_phrase: {
              cybSourceCountry: "%{[geoip][geo][country_name]}",
            },
          },
          {
            match: {
              Operation: "MailItemsAccessed"
            }
          }
        ],
      },
    },
  };
  return filterGen(data, filter, date);
};

export const getFirwallTableQuery = (fieldList, date, count, sort, page) => {
  var data = {
    query: {
      bool: {
        must: [],
        must_not: [],
        should: [],
        filter: [
          {
            match_all: {},
          },
        ],
      },
    },
  };
  if (fieldList?.length > 0) {
    let counts = [];
    for (let i = 0; i < fieldList.length; i++) {
      const item = fieldList[i];

      // If the column value exists, increment the count
      if (item.column && item.column.value) {
        const columnName = item.column.value;
        counts[columnName] = counts[columnName] || {
          name: columnName,
          count: 0,

          iscount: 0,
          notcount: 0,
        };
        counts[columnName].count++;

        // If the type value exists, increment the corresponding count
        if (item.type === "is" || item.type === "contains") {
          counts[columnName].iscount++;
        } else if (item.type === "is not" || item.type === "not contains") {
          counts[columnName].notcount++;
        }
      }
    }
    let dist = Object.values(counts);
    for (const field of fieldList) {
      if (field.type === "is" || field.type === "contains") {
        var distItem = dist.find((val) => val.name === field.column.value);
        console.log("test1", distItem);
        if (distItem && distItem.count === 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
          var must = genstrut(field);
          data.query.bool.must.push(must);
        } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
          let sindex = 0;
          let index = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;

          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must[i]?.bool?.should[j]?.term &&
                Object.keys(
                  data.query.bool.must[i]?.bool?.should[j]?.term
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must.push(shouldn);
          }
        }
      } else if (field.type === "is not" || field.type === "not contains") {
        const must_not = {
          wildcard: {},
        };

        var distItem = dist.find((val) => val.name === field.column.value);

        if (distItem && distItem.count === 1 && distItem.notcount === 1) {
          console.log("test", dist);

          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
          var must = genstrut(field);
          data.query.bool.must_not.push(must);
        }
        else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
          let index = 0;
          let sindex = 0;
          let srindex = 0;
          let smultindex = 0;
          let matchindex = 0;
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                )[0] === field.column.value
              ) {
                index = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                )[0] === "query"
              ) {
                srindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                )[0] === "query"
              ) {
                smultindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                matchindex = i + 1;
              }
            }
          }
          for (let i = 0; i < data.query.bool.must_not?.length; i++) {
            for (
              let j = 0;
              j < data.query.bool.must_not[i]?.bool?.should?.length;
              j++
            ) {
              if (
                data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                Object.keys(
                  data.query.bool.must_not[i]?.bool?.should[j]?.match
                )[0] === field.column.value
              ) {
                sindex = i + 1;
              }
            }
          }

          const shouldn = {
            bool: {
              should: [],
            },
          };

          if (sindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[sindex - 1].bool.should.push(must);
          } else if (index > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[index - 1].bool.should.push(must);
          } else if (srindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[srindex - 1].bool.should.push(must);
          }
          else if (smultindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[smultindex - 1].bool.should.push(must);
          }
          else if (matchindex > 0) {
            var must = genstrut(field);
            data.query.bool.must_not[matchindex - 1].bool.should.push(must);
          }
          else if (index === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (sindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          } else if (srindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (smultindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
          else if (matchindex === 0) {
            var must = genstrut(field);
            shouldn.bool.should.push(must);
            data.query.bool.must_not.push(shouldn);
          }
        }
      }
    }
  }
  var sortQ = [
    {
      "@timestamp": {
        order: sort,
        unmapped_type: "boolean",
      },
    },
  ];

  data.size = count;
  data.from = page;
  data.track_total_hits = true;
  data.sort = sortQ;

  if (date) {
    if (date?.from) {
      var range = {
        range: {
          "@timestamp": {
            gte: moment(date.from).toISOString(),
          },
        },
      };
      if (date?.from && date?.to) {
        var range = {
          range: {
            "@timestamp": {
              lte: moment(date.to).toISOString(),
              gte: moment(date.from).toISOString(),
            },
          },
        };
      }
      data.query.bool.filter.push(range);
    }
    if (date?.to && !date?.from) {
      var range = {
        range: {
          "@timestamp": {
            lte: moment(date.to).toISOString(),
          },
        },
      };
      data.query.bool.filter.push(range);
    }
  }
  // else {
  //   var range = {
  //     range: {
  //       "@timestamp": {
  //         gte: "now-1h/h",
  //         lte: "now",
  //       },
  //     },
  //   };
  //   data.query.bool.filter.push(range);
  // }

  return data;
};
