import React, { useEffect, useState } from 'react'
import {

    FormControl,
    InputGroup,


} from "react-bootstrap";
import '../Search.css';
import useOnclickOutside from 'react-cool-onclickoutside';
import { X } from 'phosphor-react';
import { Link } from 'react-router-dom';

export default function SecurityEventsSearch({ isApplyGlobalSearch, changeFunction, searchData,
    setIsGlobalSearchClear, setIsClearLocalSearch, searchFieldData,
    module, setSearchQuery, securityEventsLocalSearchQuery, setSecurityEventsLocalSearchQuery }) {


    const [previousSearches, setPreviousSearches] = useState([]);
    const [showRecentList, setShowRecentList] = useState(false);

    const ref = useOnclickOutside(() => {
        setShowRecentList(false);
    });


    useEffect(() => {
        const storedSearches = localStorage.getItem('recentSearches');
        const data = JSON.parse(storedSearches)?.[module];
        if (data) {

            setPreviousSearches(data);
        }
    }, [])

    useEffect(() => {
        if (isApplyGlobalSearch === true) setSecurityEventsLocalSearchQuery(searchData)
    }, [isApplyGlobalSearch])


    const handleClearClick = (e) => {

        setSearchQuery('');

        changeFunction('', e, 'click')
        setIsGlobalSearchClear && setIsGlobalSearchClear(false)
        setIsClearLocalSearch && setIsClearLocalSearch(false)
    };



    const recentSearch = (e) => {

        if (e === "Enter" || e === undefined) {

            if (securityEventsLocalSearchQuery.trim() !== '') {
                const sortRecentListLength = [securityEventsLocalSearchQuery, ...previousSearches];

                if (sortRecentListLength.length > 15) {
                    sortRecentListLength.splice(15, sortRecentListLength.length - 15);
                }

                const storedSearches = localStorage.getItem('recentSearches');

                const data = JSON.parse(storedSearches);


                if (data?.hasOwnProperty(module)) {
                    // Check if the object has the key before deleting to avoid errors
                    delete data[module];
                }

                if (!data[module]?.includes(securityEventsLocalSearchQuery)) {
                    setPreviousSearches(sortRecentListLength);



                    data[module] = sortRecentListLength

                    localStorage.setItem('recentSearches', JSON.stringify(data));
                    // setSearchQuery('');
                    setShowRecentList(false)
                } else {
                    let obj = { ti: [securityEventsLocalSearchQuery] }
                    localStorage.setItem('recentSearches', JSON.stringify(obj));
                }

            }





            // }
        }
    };

    const handleOnSort = (value) => {
        let sortedList = JSON.parse(localStorage.getItem('recentSearches')[module]).filter((item) => item !== value)
        setPreviousSearches([value, ...sortedList])
        setSearchQuery(value)
        localStorage.setItem('recentSearches', JSON.stringify([value, ...sortedList]));
    }

    const handleRemoveRecent = (revomeData) => {
        const storedSearches = JSON.parse(localStorage.getItem('recentSearches')[module]);
        let index = storedSearches.indexOf(revomeData)
        if (index !== -1) {
            storedSearches.splice(index, 1);
            setPreviousSearches(storedSearches)
            localStorage.setItem('recentSearches', JSON.stringify(storedSearches));
        }
    }

    const clearAllRecent = () => {
        setPreviousSearches([])
        localStorage.setItem('recentSearches', JSON.stringify([]));
    }


    const checkMatch = showRecentList ? previousSearches?.filter((keyword) => keyword?.toLowerCase().includes(securityEventsLocalSearchQuery?.toLowerCase())) : ''

    const getMatchingSearches = () => {
        return checkMatch;
    };

    // let searchVar = isApplyGlobalSearch === true ? searchData : securityEventsLocalSearchQuery

    return (
        <div style={{ position: "relative" }}>
            <InputGroup className="search-component">

                <FormControl
                    type="search"
                    className="me-2 custom-search-input"
                    placeholder="Search"
                    onClick={() => setShowRecentList(true)}
                    onChange={(e) => { changeFunction(e.target.value, e, ''); recentSearch(e.code) }}
                    onKeyDown={(e) => { changeFunction(e.target.value, e, ''); recentSearch(e.code) }}
                    value={securityEventsLocalSearchQuery}
                    id="search_field"
                    autoComplete='off'
                />
                {securityEventsLocalSearchQuery &&
                    <span onClick={(e) => handleClearClick(e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg custom-clear-button" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </span>
                }

                <InputGroup.Text className="bg-white  " style={{ marginLeft: "-9px", cursor: "pointer" }} >
                    <span onClick={(e) => { changeFunction(securityEventsLocalSearchQuery, e, 'click'); recentSearch() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </span>
                </InputGroup.Text>
            </InputGroup>

            {checkMatch?.length > 0 && (
                <div className='ignore-onclickoutside shadow-sm bg-white rounded-2 p-3' style={{ position: "absolute", width: "100%", zIndex: "99", top: "40px", overflowX: "scroll", height: previousSearches.length > 6 ? "200px" : "auto" }} ref={ref}>
                    <div className='col-12 d-flex justify-content-between mb-2'>
                        <h6 className='m-0'>Recent Searches</h6>
                        {previousSearches.length > 1 && (
                            <div className="ml-2 text-right">
                                <Link onClick={() => clearAllRecent()}>
                                    Clear
                                </Link>
                            </div>
                        )}
                    </div>
                    <ul className='p-0 m-0'>
                        {getMatchingSearches().map((item) => (

                            <li className='more-table-field-container mt-0 px-1'
                                style={{ listStyle: "none", display: "flex", alignItems: "center", padding: "0.4rem 1.1rem", justifyContent: "space-between", cursor: "pointer" }}
                            >
                                <span onClick={(e) => { changeFunction(item, e, 'click'); handleOnSort(item) }}>
                                    {item}
                                </span>


                                <X onClick={() => handleRemoveRecent(item)} size={14} color="#999999" />

                            </li>
                        ))}
                    </ul>



                </div>
            )}

        </div>
    )
}
