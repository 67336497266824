import {
  TI_COM_GET_TABLE_REQUEST,
  TI_COM_GET_TABLE_SUCCESS,
  TI_COM_GET_TABLE_FAIL,
} from "../../reduxConstants/TI/communityConstant";

export const getTableDataComTi = (state = {}, action) => {
  switch (action.type) {
    case TI_COM_GET_TABLE_REQUEST:
      return { loading: true };
    case TI_COM_GET_TABLE_SUCCESS:
      return {
        loading: false,
        success: true,
        comEvents: action.payload.data.hits.hits,
        commCount: action.payload.data.hits.total.value,
      };
    case TI_COM_GET_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
