import React, { Component,useState,useEffect,useCallback } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual'
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export default class TreeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { data: props.data ,selectedNodes:[]}
  }
  componentDidMount() {
    // Function to handle the button click event
    const handleButtonClick = () => {
      this.props.setActivitiesPage(1)
      this.props.setDataInit(false)
      this.props.setSelectedDataTree(this.state.selectedNodes)
    };

    const Apply = () => (
      <Button onClick={handleButtonClick} className='me-2'>Apply</Button>
    );

    // Find the dropdown-content element in the DOM
    const dropdownContentElement = document.querySelector('.dropdown-content');

    if (dropdownContentElement) {
      // Create a container element to render the React component
      const containerElement = document.createElement('div');
      containerElement.className = 'text-right';
      // Render the Apply component into the container
      ReactDOM.render(<Apply />, containerElement);

      // Append the container to the dropdown-content div
      dropdownContentElement.appendChild(containerElement);
    }
  }

  componentWillUnmount() {
    // Clean up the component when the component unmounts
    const dropdownContentElement = document.querySelector('.dropdown-content');

    if (dropdownContentElement) {
      const containerElement = dropdownContentElement.querySelector('div');
      ReactDOM.unmountComponentAtNode(containerElement);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data })
    }
  }


  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data)
  }

  render() {
    const onChange = (currentNode, selectedNodes) => {
      this.setState({ selectedNodes: selectedNodes })
      
    }
    const { data,...rest } = this.props
    return (
      <div>
        <DropdownTreeSelect data={this.state.data} {...rest} dropdownContent={<Button></Button>} onChange={onChange}/>
      </div>

    )
  }
}

