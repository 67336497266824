import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getProcessList } from "../../../Methods/UebaMethods";
import { exportCSVFile, getDateFromTimestamp, uebaUsualTimeConvert } from "../../../Utils/Utils";
import { getTimeFromTimestamp } from "../../../Utils/Utils";
import moment from "moment";

const momentobj = moment()

const UEBAExport = ({ filter, dateFilter, getDetections2 }) => {
  const [loader, setLoader] = useState(false);

  const getFields = () => {
    var fields = [
      {
        name: "Date",
        value: "item._source?.date",
      },
      {
        name: "Time",
        value:
          "`${momentobj.utc(item._source.first_timestamp).format('YYYY-MM-DD HH:mm:ss')} - ${momentobj.utc(item._source.last_timestamp).format('YYYY-MM-DD HH:mm:ss')}`",
      },
      {
        name: "Usual Time",
        value:
          "`${momentobj.utc(item?._source?.first_hour).format('HH:mm:ss')} - ${momentobj.utc(item?._source?.last_hour).format('HH:mm:ss')}`",
      },
      {
        name: "Detection ID",
        value: "item._source?.group_id",
      },
      {
        name: "User Name",
        value: "item._source?.user_name",
      },
      {
        name: "Host Name",
        value: "item._source?.host_name",
      },
      {
        name: "Detection Name",
        value: "item?._source?.detection_name",
      },
      {
        name: "Detection Status",
        value: "item?._source?.detection_status",
      },
      {
        name: "Severity",
        value: "item._source?.severity"
      },
      {
        name: "Count",
        value: "item._source?.count",
      },
      {
        name: "Threshold",
        value: "item._source?.threshold",
      },
      {
        name: "User ID",
        value: "item._source?.UserId",
      },
      {
        name: "Opened By",
        value: "item._source?.opened_by",
      },

      {
        name: "Domain",
        value: "item._source?.user_domain",
      },

      {
        name: "Process Name",
        value: "item._source?.process_name",
      },

      {
        name: "Event Action",
        value: "item._source?.event_action",
      },

      {
        name: "Event Outcome",
        value: "item._source?.event_outcome",
      },
      {
        name: "Event Category",
        value: "item._source?.event_category",
      },
      {
        name: "File Name",
        value: "item._source?.file_name",
      },
      {
        name: "File Path",
        value: "item._source?.file_path",
      },
      {
        name: "Target Username",
        value: "item._source?.target_user",
      },
      {
        name: "Source IP",
        value: "item._source?.source_ip",
      },
      {
        name: "Source Port",
        value: "item._source?.source_port",
      },
      {
        name: "Destination IP",
        value: "item._source?.dest_ip",
      },
      {
        name: "Destination Port",
        value: "item._source?.dest_port",
      },
      {
        name: "Client IP",
        value: "item._source?.ClientIP",
      },
      {
        name: "Source Filename",
        value: "item._source?.SourceFileName",
      },
      {
        name: "Workload",
        value: "item._source?.Workload",
      },
      {
        name: "Operation",
        value: "item._source?.Operation",
      },
      {
        name: "Item Type",
        value: "item._source?.ItemType",
      },
      {
        name: "Event Source",
        value: "item._source?.EventSource",
      },
      {
        name: "Source Country Name",
        value: "item._source?.cybSourceCountry",
      },
      {
        name: "Receiver Address",
        value: "item._source?.email_receiver_address",
      },
      {
        name: "Sender Address",
        value: "item._source?.email_from_address",
      },
      {
        name: "Subject",
        value: "item._source?.subject",
      },
      {
        name: "Delivery Action",
        value: "item._source?.delivery_action",
      },
      {
        name: "Country",
        value: "item._source?.country",
      },




      
      
      
      

      



    ];
    return fields;
  };
  const convertdata = (fields, data) => {
    var convertedData = [];
    data.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {
        const { name, value } = field;
        // var test = "val."+value
        // if(test.includes(".@"))
        // {
        //     var new1 = test.split(".@")
        //     test= new1[0]+`['@${new1[1]}']`
        // }
        if (item._source.type === "time based" && name === "Time") {
          dynamicJSON[name] = `${moment(item?._source?.first_timestamp).utc().format('HH:mm:ss')} - ${moment(item?._source?.last_timestamp).utc().format('HH:mm:ss')}`
        }
        else if (name === "Date") {
          dynamicJSON[name] = moment(item?._source?.detection_timestamp).utc().format('DD MMM YYYY');
        }
        else if (item._source.type === "time based" && name === "Usual Time") {
          dynamicJSON[name] = `${uebaUsualTimeConvert(item?._source?.first_hour).format('HH:mm:ss')} - ${uebaUsualTimeConvert(item?._source?.last_hour).format('HH:mm:ss')}`
        }
        else if (item._source.type === "count based" && name === "Time") {
          dynamicJSON[name] = moment(item?._source?.detection_timestamp).utc().format('HH:mm:ss');
        } else if (item._source.type === "count based" && name === "Usual Time") {
          dynamicJSON[name] = "";
        }
        else {
          const value1 = eval(value);
          dynamicJSON[name] = value1;
        }
      }
      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };
  const csvExport = async () => {
    setLoader(true);
    var size = 1000;
    var data = await getDetections2(filter, dateFilter, size, "desc", 0);
    var data3 = data.data.hits.hits;
    var count = data.data.hits.total.value;
    var iter = 1000;
    while (count > 1000 && iter < count) {
      var tempdata = await getDetections2(
        filter,
        dateFilter,
        size,
        "desc",
        iter
      );
      data3.push(...tempdata.data.hits.hits);
      iter += size;
    }
    var fields = getFields();
    var filename = "detection" + "_" + moment().toISOString();
    var data1 = convertdata(fields, data3);
    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default UEBAExport;
