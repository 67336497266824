import React, { useEffect, useState, useRef } from 'react';


import CustomModal from "../../../../components/CustomModal/CustomModal";
import { Link } from "react-router-dom";
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../../../Utils/Utils";
import {
    getVulnerabilityExploitDetail
} from "../../../../Methods/TiMethods";
import LoaderListModal from "../../../../components/Loader/LoaderListModal";
import { useReactToPrint } from 'react-to-print';
import DetailPageVulEx from "../../../../components/PDF/Ti/DetailPageVulEx";
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function DetailPage({ detail, showDetail, handleCloseModal, event }) {

    const componentPDF = useRef();
    const logoActive = useRef();

    const [moreData, setMoreData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [logo, setLogo] = useState(false);

    useEffect(() => {
        setIsLoading(true)

        if (event === 'exploit') {
            getVulnerabilityExploitDetail(detail?.cve).then((res) => {
                setIsLoading(false)
                setMoreData(res.data)
            })
        }



    }, [detail, event])

    const currentTimestamp = Date.now();

    // const generatePDF = useReactToPrint({
    //     content: () => componentPDF.current,
    //     documentTitle: "Headlines",
    //     onBeforeGetContent: () => "Loading......",
    //     // onAfterPrint:()=>alert("Data saved in PDF")
    // const generatePDF = useReactToPrint({
    //     content: () => componentPDF.current,
    //     documentTitle: "Headlines",
    //     onBeforeGetContent: () => "Loading......",
    //     // onAfterPrint:()=>alert("Data saved in PDF")

    // })



    const renderModal = () => {
        if (event === 'exploit') {
            return (
                <div>
                    <div className="d-grid d-md-flex justify-content-md-end mb-3 px-3 py-2">
                        {/* <button className="btn btn-success" onClick={generatePDF}>Export as PDF</button> */}
                        {moreData &&
                            <PDFDownloadLink document={<DetailPageVulEx detail={detail} moreData={moreData} />} fileName={`vulnerability-explore-${currentTimestamp}.pdf`}>
                                {({ blob, url, loading, error }) =>
                                    (<button className="form-control  btn btn-primary">Download PDF</button>)
                                }
                            </PDFDownloadLink>
                        }
                    </div>
                    <div className="col-12 px-3" ref={componentPDF} style={{ width: "100%" }}>
                        <div id="printable_header"  >
                            <div className="d-flex">
                                <div className="p-3"><img src="/logo-sm-light.png" /></div>

                            </div>
                            <hr />
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Event name</span>
                            <span className="modal-text">{detail?.event_name}</span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Event description</span>
                            <span className="modal-text">{detail?.event_description}</span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">CVE ID</span>
                            <span className="modal-text">{detail?.cve}</span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Severity</span>
                            <span className="modal-text">
                                {detail?.severity == "Critical" && (
                                    <div className="p-2 m-0 alert alert-danger">Critical</div>
                                )}
                                {detail?.severity == "High" && (
                                    <div className="p-2 m-0 alert alert-danger">High</div>
                                )}
                                {detail?.severity == "Medium" && (
                                    <div className="p-2 m-0 alert alert-info">Medium</div>
                                )}
                                {detail?.severity == "Low" && (
                                    <div className="p-2 m-0 alert alert-success">Low</div>
                                )}
                            </span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Alert date</span>
                            <span className="modal-text">
                                {getDateFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}
                            </span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Alert time</span>
                            <span className="modal-text">
                                {getTimeFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}
                            </span>
                        </div>
                        <div className="modal-row-item">
                            <span className="modal-label col-3">Vendor</span>
                            <span className="modal-text">{detail?.vendor}</span>
                        </div>

                        {isLoading === true ?
                            <div className="col-12 p-2">
                                <LoaderListModal />
                            </div> :

                            <div className="col-12">
                                <div className="modal-row-item">
                                    <span className="modal-label col-3">Published</span>
                                    <span className="modal-text">
                                        {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.published)}
                                    </span>
                                </div>
                                <div className="modal-row-item">
                                    <span className="modal-label col-3">Last Modified</span>
                                    <span className="modal-text">
                                        {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.lastModified)}
                                    </span>
                                </div>

                                {moreData?.vulnerabilities[0]?.cve?.cisaExploitAdd ?
                                    <div className="modal-row-item">
                                        <span className="modal-label col-3">CISA Exploit
                                        </span>
                                        <span className="modal-text text-danger">
                                            {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.cisaExploitAdd)}
                                        </span>
                                    </div>
                                    : ''}

                                {moreData?.vulnerabilities[0]?.cve?.references.length > 0 ?
                                    <div className="modal-row-item">
                                        <span className="modal-label col-3">References</span>
                                        <div className="d-flex flex-fill flex-column text-break">
                                            {moreData?.vulnerabilities[0]?.cve?.references?.map((ref) => (
                                                <Link to={ref.url} target="_blank" className="w-100" style={{ color: "blue" }}>
                                                    {ref.url}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    : ''}
                                <div className="modal-row-item">
                                    <span className="modal-label col-3">Additional descriptions
                                    </span>
                                    {moreData?.vulnerabilities[0]?.cve?.descriptions[0]?.value}
                                </div>

                                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31?.length > 0 ?
                                    <div className="modal-row-item">
                                        <span className="modal-label col-3"> Base Score V3
                                        </span>

                                        <span className="modal-text text-primary">{moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31[0]?.cvssData
                                            ?.baseScore}</span>


                                    </div>
                                    : ''}
                                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2?.length > 0 ?
                                    <div className="modal-row-item">
                                        <span className="modal-label col-3">Base Score V2
                                        </span>

                                        <span className="modal-text text-primary">{moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2[0]?.cvssData
                                            ?.baseScore}</span>


                                    </div>
                                    : ''}
                            </div>
                        }
                    </div>
                </div>
            );
        }

    }
    return (
        <div>
            <CustomModal
                title={"Event Details"}
                ShowOrHide={showDetail}
                modalSize={"lg"}
                bodyData={renderModal}
                handleClose={handleCloseModal}
                footer={false}
                noBodyPadding={true}
                overflowHidden={false}
                modalPosition={"right"}
                outsideClickDisabled={true}
            ></CustomModal>
        </div>
    )
}