const styles = {
  section1: {
    height: "calc(100vh - 180px)",
    paddingRight: "1rem",
    overflow: "auto",
  },
  tile: {
    backgroundColor: "#f1f1f1",
  },
  paginationWrap: {
    alignItems: "center",
    justifyContent: "flex-end",
    height: "50px",
    // backgroundColor: "#CCCCCC",
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
};

export default styles;