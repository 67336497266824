import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import styles from "./Community.styles";
import { Badge, Button, Form, Dropdown } from "react-bootstrap";
import { ArrowRight, DownloadSimple, Eye, Info } from "phosphor-react";
import TableLoader from "../../../components/Loader/LoaderList";
import XLfileConverter from "../../../components/XlTable/XLfileConverter";
import {
  getCommunityFChart,
  getAttributeFChart,
  getTableData,
  convertTimestamp,
  getTableDataFeedsDirect
} from "../../../Methods/TiMethods";
import TIExport from "../Export";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CommunityView from "../../../components/DetailsPage/CommunityView";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from "../../../Utils/Utils.js";
import PaginationTi from "../../../components/Pagination/PaginationTi";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import Severity from "../../../components/Severity/Severity";
import SearchTi from "../../../components/Search/SearchTi";
import FeedsSearch from "../../../components/Search/TI/FeedsSearch";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
ChartJS.register(ArcElement, Tooltip, Legend);

const Community = ({ selectedDateFilter, globalSearch, setGlobalSearch, searchValCom, page, setPage,
  setSelectedDateFilter, isGlobalSearchClear, setIsGlobalSearchClear, sevChange,
  setSevChange, isApplyGlobalSearch, setIsApplyGlobalSearch, setFeedsSearchQuery, feedsSearchQuery,dateFilterti }) => {


  const dispatch = useDispatch();
  const searchElement = useRef(null);


  const [filter, setFilter] = useState('')

  // community
  const [Labels, setLabels] = useState([]);
  const [dataChart, setDataChart] = useState([]);


  // attribute
  const [attributeLabel, setAttributeLabel] = useState([]);
  const [attributeData, setAttributeData] = useState([]);

  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);

  // pagination

  // const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(perPage);
  const [sevFilterData, setSevFilterData] = useState();
  const [query, setQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchType, setSearchType] = useState("name");

  const [isClearLocalSearch, setIsClearLocalSearch] = useState(false);
  const [feedsLocalSearchQuery, setFeedsLocalSearchQuery] = useState('');


  const [timeoutLoader, setTimeoutLoader] = useState(false);

  const [eventsData, setEventsData] = useState([]);
  const [eventsDataCount, setEventsDataCount] = useState(0);
  const [eventsLoader, setEventsLoader] = useState(false);


  const getTableDataComTi = useSelector((state) => state.getTableDataComTi);
  const { comEvents, loading: eventsLoading, commCount } = getTableDataComTi;



  const communityChart = {
    labels: Labels,
    datasets: [
      {
        label: "Community Feeds",
        data: dataChart,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 0,
        hoverOffset: 0,
      },
    ]
  };

  // const tableData = []
  const overView = `The threat intelligence sharing platform is configured to sync information with various community threat intelligence sources. These contain information about specific threats and acts as a database for Indicators of compromise to help with threat hunting and other activities requiring threat intelligence. The below visualizations show summary of the data that has been acquired from all the community sources. It includes the breakdown which shows the distribution of feeds, the timeline of data that has arrived, the type of Indicators/Attributes that have been obtained etc.`;
  const [showMore, setShowMore] = useState(true);
  const maxLength = 250;
  const [tableDatas, setTableDatas] = useState([]);

  const [useThirdParty, setUseThirdParty] = useState(false);



  // useEffect(() => {
  //   setFilter("")
  // call api when date filter applied here
  // eg: getAnomalousEntities("host", selectedDateFilter, "asc");
  // getDetections(selectedDateFilter, "desc");
  // }, [selectedDateFilter,]);

  useEffect(() => {
    setTimeout(() => {
      setIsGlobalSearchClear(false)
    }, 1000);
  }, [globalSearch])

  // console.log(sevFilterData)




  useEffect(() => {
    if (filter) {
      setPage(1)
    }
  }, [filter])

  useEffect(() => {

    if (sevChange === true) {
      setFilter("")
    }
  }, [sevChange])


  // Get data
  const getFeedsData = (searchData) => {
    setEventsLoader(true)

    let sevFilter = setFilterData(selectedDateFilter);

    // dispatch(getTableData(perPage, "desc", (page - 1) * perPage, filter, searchData, sevFilter, searchType));
    getTableDataFeedsDirect(perPage, "desc", (page - 1) * perPage, filter, searchData, sevFilter, searchType, useThirdParty).then((res) => {
      if (res.status === 200) {
        setEventsData(res?.data?.hits?.hits)
        setEventsDataCount(res?.data?.hits?.total?.value)
        setEventsLoader(false)
      }


    })

  }

  // Get diagram data
  const getDiagramData = (search) => {
    let sevFilter = setFilterData(selectedDateFilter);

    getCommunityFChart(sevFilter, filter, search, searchType,useThirdParty,useThirdParty)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {

          let label = [];
          let data = [];
          if (buckets.length > 0) {
            buckets.map((item) => {
              label.push(item.key);
              data.push(item.doc_count);
              setLabels(label);
              setDataChart(data);
            });
          }
        }
      )
      .catch((err) => { });
  }

  useEffect(() => {

    getDiagramData(feedsSearchQuery)


    getAttributeFChart(useThirdParty)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {
          let label = [];
          let data = [];
          if (buckets.length > 0) {
            buckets.map((item) => {
              label.push(item.key);
              data.push(item.doc_count);
              setAttributeLabel(label);
              setAttributeData(data);
            });
          }
        }
      )

      .catch((err) => { });


    // getFeedsData(feedsSearchQuery)


  }, [selectedDateFilter, filter, page, query, globalSearch]);


  // Clear local search
  useEffect(() => {
    if (isClearLocalSearch === true) {

      getDiagramData('');
      setIsClearLocalSearch(false)


    }


  }, [isClearLocalSearch])



// console.log("page",page);
// console.log("pageCount",pageCount);
 
  // Load when global search is applied
  useEffect(() => {

    getFeedsData(feedsSearchQuery);
    setFeedsLocalSearchQuery(feedsSearchQuery)





    getDiagramData(feedsSearchQuery)
    setTimeoutLoader(false)




  }, [feedsSearchQuery, filter, sevFilterData,page, useThirdParty,dateFilterti])






  const setFilterData = (filter) => {
    let filterData = ''
    if (filter === '1 Day') {
      filterData = 'now-1d/d'
    } else if (filter === '7 Days') {
      filterData = 'now-7d/d'
    } else if (filter === '30 Days') {
      filterData = 'now-30d/d'
    }
    else {
      filterData = 'now-1y/d'
    }
    setSevFilterData(filterData,)
    
    return filterData
  }

  const showHideHandle = () => {
    setShowMore(!showMore);
  };
  
  const truncatedText = showMore
    ? overView.slice(0, maxLength) + "..."
    : overView;

  // download pdf
  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [["Event Time", "Event Name", "Feed Name", "Severity"]],

      body: tableDatas.map((item) => [
        item[3].buckets[0][4].buckets[0][5].buckets[0][1].hits.hits[0]._source
          .attribute_timestamp,
        item.key,
        item[3].buckets[0].key,
        item[3].buckets[0][4].buckets[0][5].buckets[0].key,
        "",
      ]),
    });
    // download the pdf document
    doc.save("community.pdf");
  };

  const handleOpenModal = (data, display) => {
    setDetailpage(data);
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
  }

  function renderModal() {
    return <CommunityView eventDetails={detailpage} useThirdParty={useThirdParty}/>;
  }

  const handleSearch = (val, e, action) => {

    setPage(1)
    setIsApplyGlobalSearch(false)
    setFeedsLocalSearchQuery(val)

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setQuery(val)
      setFeedsSearchQuery(val)

      getFeedsData(val)
    };


  }




  return (
    <>
      <div
        className="d-flex flex-column p-4 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >
        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <h4>Overview</h4>
          <p>{truncatedText}</p>
          {overView.length > maxLength && (
            <span onClick={showHideHandle} className="viewMoreText">
              {showMore ? "View more..." : "View less..."}
            </span>
          )}
        </div>

        {/* <div className="community-chart-container my-4"> */}
        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <div className="position-relative col-12 d-flex align-items-center justify-content-center" style={{}}>
            {eventsLoader === true ? (
              <TableLoader />
            ) : (
              eventsData?.length === 0 ? <NoDataFound /> :
                <Doughnut data={communityChart} />
            )}
          </div>
        </div>

        {/* <div className="attribute-chart-container">
          <Doughnut data={attributeChart} />
        </div> */}
      </div>
      <div className="col-9 d-flex flex-column p-4 rounded-3 gap-4 bg-white position-relative">
        {/* <div className="col-12 p-4 rounded-3" style={styles.overviewTile}>
          <h4>Events</h4>
          <p>The below panel shows the top events which have occured according to various community feeds, Click on the view event button to view the summary of individual events and get detailed views of their IOCs.</p>
        </div> */}
        <div className="col-12 d-flex flex-row pb-4" style={styles.filterWrap}>
          {/* <div className="col-2 d-flex flex-row align-items-center">
            <Form.Select>
              <option>25</option>
              <option>50</option>
            </Form.Select>
            <span className='px-2'>items</span>
          </div> */}
          <div className="col-2 d-flex flex-row align-items-center">
            <h4 className="mb-0">Events</h4>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end ml-auto col-10">

            <div className="d-flex pagination-wrap align-items-center">
              {/* {query?
              <div className="mx-1">
                <Search changeFunction={handleSearch} searchData={query}/> 
              </div>
              : */}
              <label className="me-2">Cloud</label>
              <Form.Check
                  className="mb-0"
                  type="switch"
                  id="toggle_kql"
                  value={useThirdParty}
                  onChange={(e) => {
                      if (e.target.checked) {
                          setUseThirdParty(true)
                      }
                      else {
                          setUseThirdParty(false)
                      }
                  }}
              />
              <label className="me-2">On Prem</label>

              <div className="mx-1 d-flex flex-row gap-1 ">
                <Form.Select className="w-50" onChange={(e) => setSearchType(e.target.value)} value={searchType}>
                  <option value="name">Event Name</option>
                  <option value="hash">FileHash</option>
                  <option value="ipv4">IPV4/Domain</option>
                </Form.Select>
                
                <FeedsSearch feedsLocalSearchQuery={feedsLocalSearchQuery} className="" isApplyGlobalSearch={isApplyGlobalSearch} setIsApplyGlobalSearch={setIsApplyGlobalSearch} changeFunction={handleSearch} searchData={globalSearch} searchElement={searchElement} setIsClearLocalSearch={setIsClearLocalSearch} setSearchQuery={setFeedsSearchQuery} searchFieldData={feedsSearchQuery} module="ti" />
                {/* </div>

              <div className="position-relative mr-1">
                {/* <CykitExport
              type={activeTab}
              filter={filter}
              dateFilter={dateFilter}
            /> */}
                <TIExport perPage={perPage} page={page} filter={filter} searchQuery={feedsLocalSearchQuery} sevFilter={sevFilterData} searchType={searchType} />
              </div>
              <div className="mx-1">
                <Severity
                  filter={filter}
                  setFilter={setFilter}
                  count={""}
                  setPage={setPage}
                  setSevChange={setSevChange}
                />
              </div>
              <PaginationTi
                page={page}
                setPage={setPage}
                perPage={perPage}
                currentPageCount={pageCount}
                dataLength={tableDatas.length}
                totalDataCounts={eventsDataCount}
              />

            </div>

          </div>
        </div>

        <div className="col-12" style={styles.tableWrap}>
          {eventsLoader === true ? (
            <TableLoader />
          ) : (
            eventsData?.length === 0 ? <NoDataFound /> :
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Event Name</th>
                    <th>{useThirdParty?"Source Name":"Feed Name"}</th>
                    {
                      (query && searchType !== "name") && <th>IOC Type</th>
                    }
                    {
                      (query && searchType !== "name") && <th>IOC</th>
                    }
                    <th>Severity</th>
                    <th>Event Drilldown</th>
                  </tr>
                </thead>
                <tbody>
                  {eventsData?.map((item) => (
                    <tr>
                      <td className="align-middle">
                        {getDateFromTimestamp(parseInt(item._source.attribute_timestamp) * 1000)}
                      </td>
                      <td className="align-middle">
                        {getTimeFromTimestamp(parseInt(item._source.attribute_timestamp) * 1000)}
                      </td>
                      {/* <td className="align-middle">{item._source.key}</td> */}
                      <td className="align-middle col-5"><div className="d-flex text-break">{item?._source?.event_name}</div></td>
                      <td className="align-middle">{useThirdParty?item?._source?.source_name:item?._source?.feed_name}</td>
                      {
                        (query && searchType !== "name") && <td className="align-middle"><div className="col-12 text-break">{item._source.attribute_type}</div></td>
                      }
                      {
                        (query && searchType !== "name") && <td className="align-middle"><div className="col-12 text-break">{item._source.attribute_value}</div></td>
                      }
                      <td className="align-middle">
                        {item?._source?.severity?.toLowerCase() == "critical" && (
                          <Badge bg="danger" className="p-2">Critical</Badge>
                        )}
                        {item?._source?.severity?.toLowerCase() == "high" && (
                          <Badge bg="danger" className="p-2">High</Badge>
                        )}
                        {item?._source?.severity?.toLowerCase() == "medium" && (
                          <Badge bg="warning" className="p-2">Medium</Badge>
                        )}
                        {item?._source?.severity?.toLowerCase() == "low" && (
                          <Badge bg="success" className="p-2">Low</Badge>
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <Link onClick={() => handleOpenModal(item._source, true)}>
                          <TableViewMore Icon={ArrowRight} dataToggle={"Open Link"} dataPlacement={"bottom"} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
          )}
        </div>
      </div>

      {show && (
        <CustomModal
          title={"Event details"}
          ShowOrHide={show}
          bodyData={renderModal}
          modalSize={"lg"}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding={true}
          overflowHidden={false}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )}
    </>
  );
};

export default Community;