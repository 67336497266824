const styles = {
  section1: {
    width: "70%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
  },
  filterWrap: {
    position: "absolute",
    right: "1.5rem",
    top: "14px",
    zIndex: 3
  },
  tableWrap: {
    height: "calc(100vh - 14rem)",
    overflow: "auto",
  }
};

export default styles;
