import axios from "../axios";
import { async } from "q";
import { fpost, fget, fpostDl } from "../axios/axios";
import {
  getVulnerabilitiesExQuery,
  getSecurityEventsQuery,
  getHeadlinesQuery,
  getCommunityFeedQuery,
  getAttributeFeedQuery,
  getTopCharts,
  communityEventsListQuery,
  indicatorsTableQuery,
  communityEventsDetailQuery,
  getVulnerabilitiesQuery,
  getIocQuery,
  scanAdQuery,
  scanOfficeQuery,
} from "../Queries/TiQueries";
import {
  TI_COM_GET_TABLE_REQUEST,
  TI_COM_GET_TABLE_SUCCESS,
  TI_COM_GET_TABLE_FAIL,
} from "../reduxConstants/TI/communityConstant";

import {
  TI_HEADLINES_LIST_FAIL,
  TI_HEADLINES_LIST_REQUEST,
  TI_HEADLINES_LIST_SUCCESS,
  TI_VULNERABILITIES_LIST_REQUEST,
  TI_VULNERABILITIES_LIST_SUCCESS,
  TI_VULNERABILITIES_LIST_FAIL,
  TI_VULEX_LIST_REQUEST,
  TI_VULEX_LIST_SUCCESS,
  TI_VULEX_LIST_FAIL,
  TI_SECURITYEVENTS_LIST_REQUEST,
  TI_SECURITYEVENTS_LIST_SUCCESS,
  TI_SECURITYEVENTS_LIST_FAIL,
} from "../reduxConstants/TI/activeBytesConstant";
import { fpostCy } from "../axios/axios";
import { scanEdrQuery } from "../Queries/TiQueries";
let baseURL = `${localStorage.getItem("apiBaseUrl")}/ti/platform`;
// let baseURL = "https://analyticsapi.active-bytes.com/tiplatform";
let Token = localStorage.getItem("auth");
const datalakeURL = localStorage.getItem("DATALAKE_URL");

// Vulnerabilities
export const getVulnerabilities =
  (count, sort, page, filter, search, timeFrame) => async (dispatch) => {
    try {
      dispatch({ type: TI_VULNERABILITIES_LIST_REQUEST });
      let data = getVulnerabilitiesQuery(
        count,
        sort,
        page,
        filter,
        search,
        timeFrame
      );



      fpost(`${baseURL}/cve_data/_search?pretty`, data).then((res) => {
        dispatch({
          type: TI_VULNERABILITIES_LIST_SUCCESS,
          payload: res,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TI_VULNERABILITIES_LIST_FAIL,
        payload: error,
      });
    }
  };

export async function getVulnerabilitiesDirect(count, sort, page, filter, search, timeFrame) {

  let data = getVulnerabilitiesQuery(
    count,
    sort,
    page,
    filter,
    search,
    timeFrame
  );
  return await fpost(
    `${baseURL}/cve_data/_search?pretty`,
    data
  )
}

// async function getVulnerabilitiesSeverity(severity) {
//   return await fget(
//     `https://analyticsapi.active-bytes.com/ti/vulndb/2.0?pubStartDate=2023-01-01T00:00:00.000&pubEndDate=2023-04-30T00:00:00.000&noRejected&resultsPerPage=0&cvssV3Severity=${severity}`
//   );
// }

export async function getVulnerCount() {
  let counts = {
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
  };

  // getVulnerabilitiesSeverity("LOW").then(({ data: { vulnerabilities } }) => {
  //   counts.low = vulnerabilities.length;
  // });

  // getVulnerabilitiesSeverity("MEDIUM").then(({ data: { vulnerabilities } }) => {
  //   counts.medium = vulnerabilities.length;
  // });

  // getVulnerabilitiesSeverity("HIGH").then(({ data: { vulnerabilities } }) => {
  //   counts.high = vulnerabilities.length;
  // });

  // getVulnerabilitiesSeverity("CRITICAL").then(
  //   ({ data: { vulnerabilities } }) => {
  //     counts.critical = vulnerabilities.length;
  //   }
  // );

  return await counts;
}

// Vulnerabilities Ex
export const getVulnerabilitesEx =
  (
    count,
    sort,
    page,
    vulnerabilityExploitFilter,
    vulnerabilityExploitSearch,
    sevFilter
  ) =>
    async (dispatch) => {
      try {
        let filter = vulnerabilityExploitFilter;
        let search = vulnerabilityExploitSearch;
        let timeFrame = sevFilter;

        dispatch({ type: TI_VULEX_LIST_REQUEST });
        let data = getVulnerabilitiesExQuery(
          count,
          sort,
          page,
          filter,
          search,
          timeFrame
        );
        fpost(`${baseURL}/misp_event_data/_search?pretty`, data).then((res) => {
          dispatch({
            type: TI_VULEX_LIST_SUCCESS,
            payload: res,
          });
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: TI_VULEX_LIST_FAIL,
          payload: error,
        });
      }
    };

export async function getVulnerabilitesExDirect(count,
  sort,
  page,
  vulnerabilityExploitFilter,
  vulnerabilityExploitSearch,
  sevFilter) {

  let filter = vulnerabilityExploitFilter;
  let search = vulnerabilityExploitSearch;
  let timeFrame = sevFilter;


  let data = getVulnerabilitiesExQuery(
    count,
    sort,
    page,
    filter,
    search,
    timeFrame
  );

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}

// Security Events
export const getSecurityEvents =
  (count, sort, page, securityEvFilter, securityEvSearch, sevFilter) =>
    async (dispatch) => {
      try {
        dispatch({ type: TI_SECURITYEVENTS_LIST_REQUEST });

        let severity = securityEvFilter;
        let search = securityEvSearch;
        let timeFrame = sevFilter;

        let data = getSecurityEventsQuery(
          count,
          sort,
          page,
          severity,
          search,
          timeFrame
        );
        fpost(`${baseURL}/misp_event_data/_search?pretty`, data).then((res) => {
          dispatch({
            type: TI_SECURITYEVENTS_LIST_SUCCESS,
            payload: res,
          });
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: TI_SECURITYEVENTS_LIST_FAIL,
          payload: error,
        });
      }
    };
export async function getSecurityEventsDirect(count, sort, page, securityEvFilter, securityEvSearch, sevFilter) {
  let severity = securityEvFilter;
  let search = securityEvSearch;
  let timeFrame = sevFilter;

  let data = getSecurityEventsQuery(
    count,
    sort,
    page,
    severity,
    search,
    timeFrame
  );

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}

// Headlines
export const getHeadlines =
  (count, sort, page, headlineSearch, sevFilter) => async (dispatch) => {
    try {
      dispatch({ type: TI_HEADLINES_LIST_REQUEST });
      let search = headlineSearch;
      let timeFrame = sevFilter;

      let data = getHeadlinesQuery(count, sort, page, search, timeFrame);

      fpost(
        `${baseURL}/misp_event_data/_search?pretty&track_total_hits=true`,
        data
      ).then((res) => {
        dispatch({
          type: TI_HEADLINES_LIST_SUCCESS,
          payload: res,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TI_HEADLINES_LIST_FAIL,
        payload: error,
      });
    }
  };

export async function getHeadlinesDirect(count, sort, page, headlineSearch, sevFilter) {
  let search = headlineSearch;
  let timeFrame = sevFilter;

  let data = getHeadlinesQuery(count, sort, page, search, timeFrame);

  return await fpost(
    `${baseURL}/misp_event_data/_search?pretty&track_total_hits=true`,
    data
  )
}

export async function getCommunityFChart(sevFilter, severity, search, searchType, useThirdParty) {
  const thirdPartyIndex = localStorage.getItem("THIRD_PARTY_IOCS_INDEX", false);

  let timeFrame = sevFilter;
  let data = getCommunityFeedQuery(timeFrame, severity, search, searchType, useThirdParty);
  if (thirdPartyIndex && useThirdParty) {
    return await fpostDl(`${datalakeURL}${thirdPartyIndex}/_search?pretty`, data);

  }
  else {
    return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);

  }
}

export async function getAttributeFChart() {
  let data = getAttributeFeedQuery();

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}

export async function getTableDataFeedsDirect(count, sort, page, filter, searchQuery, sevFilter, searchType, useThirdParty) {
  let search = searchQuery;
  let severity = filter;
  let timeFrame = sevFilter;
  const thirdPartyIndex = localStorage.getItem("THIRD_PARTY_IOCS_INDEX", false);



  let data = communityEventsListQuery(
    page,
    count,
    sort,
    severity,
    search,
    timeFrame,
    searchType,
    useThirdParty
  );
  if (thirdPartyIndex && useThirdParty) {
    return await fpostDl(`${datalakeURL}${thirdPartyIndex}/_search?pretty`, data);

  }
  else {
    return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);

  }
}

export const getTableData =
  (count, sort, page, filter, searchQuery, sevFilter, searchType) => async (dispatch) => {
    try {
      let search = searchQuery;
      let severity = filter;
      let timeFrame = sevFilter;



      let data = communityEventsListQuery(
        page,
        count,
        sort,
        severity,
        search,
        timeFrame,
        searchType
      );
      dispatch({ type: TI_COM_GET_TABLE_REQUEST });

      fpost(`${baseURL}/misp_event_data/_search?pretty`, data).then((res) => {
        dispatch({
          type: TI_COM_GET_TABLE_SUCCESS,
          payload: res,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: TI_COM_GET_TABLE_FAIL,
        payload: error,
      });
    }
  };

// view event details page community

export async function getIndicatorsTableData(
  count,
  sort,
  page,
  filter,
  searchQuery,
  sevFilter
) {
  let search = searchQuery;
  let severity = filter;
  let timeFrame = sevFilter;

  let data = communityEventsListQuery(
    page,
    count,
    sort,
    severity,
    search,
    timeFrame
  );

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}

export async function getIndicatorsTable(id, useThirdParty) {
  const thirdPartyIndex = localStorage.getItem("THIRD_PARTY_IOCS_INDEX", false);

  let data = indicatorsTableQuery(id);
  if (thirdPartyIndex && useThirdParty) {
    return await fpostDl(`${datalakeURL}${thirdPartyIndex}/_search?pretty`, data);
  }
  else {
    return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);

  }
}

export async function getTableDataExport(
  count,
  sort,
  page,
  filter,
  searchQuery,
  sevFilter,
  searchType
) {
  let search = searchQuery;
  let severity = filter;
  let timeFrame = sevFilter;

  let data = communityEventsListQuery(
    page,
    count,
    sort,
    severity,
    search,
    timeFrame,
    searchType
  );

  return await fpost(`${baseURL}/misp_event_data/_search?pretty`, data);
}

// Vulnerabilities Exploit
export async function getVulnerabilityExploitDetail(cveid) {
  return await fget(
    `${localStorage.getItem("apiBaseUrl")}/ti/vulndb/2.0?cveId=${cveid}`
  );
}

export async function communityEventsDetail(id) {
  let data = communityEventsDetailQuery(id);

  return await fpost(`${baseURL}/misp_event_data/_search`, data);
}

export function convertTimestamp(time) {
  const date = new Date(time * 1000);

  const formatter = new Intl.DateTimeFormat("en-US", {
    // year: "2-digit",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  }).format(date);

  return formatter;

  // const dateString = formatter.format(date);
}

export function convertTimestampVulEx(time) {
  const date = new Date(time * 1000);

  const formatter = new Intl.DateTimeFormat("en-US", {
    // year: "2-digit",
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    hour12: true,
  }).format(date);

  return formatter;

  // const dateString = formatter.format(date);
}

export const getIocs = async (from, to, ioctype) => {
  let data = getIocQuery(from, to, ioctype);
  return await fpost(`${baseURL}/misp_event_data/_search`, data);
};

let dataLakeURL = localStorage.getItem("DATALAKE_URL");
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
const INVESTIGATE_CYAD = localStorage.getItem("INVESTIGATE_CYAD");
const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365")
  ? localStorage.getItem("INVESTIGATE_o365")
  : "/logs-o365*";

export const scanEdr = async (from, to, iocval) => {
  let data = scanEdrQuery(from, to, iocval);
  return await fpostCy(`${dataLakeURL}/${indexPattern}`, data);
}
export const scanAd = async (from, to, iocval) => {
  let data = scanAdQuery(from, to, iocval);
  return await fpostDl(
    `${dataLakeURL}${INVESTIGATE_CYAD}/_search?track_total_hits=true`,
    data
  );
}
export const scanOffice = async (from, to, iocval) => {
  let data = scanOfficeQuery(from, to, iocval);
  return await fpostDl(
    `${dataLakeURL}${INVESTIGATE_o365}/_search?track_total_hits=true`,
    data
  );
}

export const scanOtherSources = async (from, to, index_pattern, iocval,) => {
  let data = scanOfficeQuery(from, to, iocval);
  return await fpostDl(
    `${dataLakeURL}/${index_pattern}/_search?track_total_hits=true`,
    data
  );
}