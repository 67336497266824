import React, { useEffect, useState } from "react";
import { getHeadlines, convertTimestamp } from "../../../Methods/TiMethods";
import styles from "./Headlines.styles";

const Headlines = ({ selectedDateFilter }) => {
  const [headlines, setHeadlines] = useState([]);

  useEffect(() => {
    // call api when date filter applied here
    // eg: getAnomalousEntities("host", selectedDateFilter, "asc");
    // getDetections(selectedDateFilter, "desc");
  }, [selectedDateFilter]);

  useEffect(() => {
    getHeadlines()
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          hits.map(
            (item) =>
            (item._source.attribute_timestamp = convertTimestamp(
              item._source.attribute_timestamp
            ))
          );
          setHeadlines(hits);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  return (
    <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">
      <div className="overflow-auto" style={styles.section1}>
        {headlines.map(
          ({
            _source: { event_name, attribute_timestamp, reference_link },
          }) => (
            <div className="p-3 mb-4 border border-1 border-solid border-dark rounded">
              <a className="reset-link" href={reference_link} target="_blank">
                <span className="fw-bold">{event_name}</span>
                <br />
              </a>
              <span style={{ fontSize: "0.8rem" }}>{attribute_timestamp}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Headlines;