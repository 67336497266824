import React from "react";
import Skeleton from "react-loading-skeleton";

const TableLoader = ({ rowCount, col }) => {
  return (
    <table className="table">
      {col !== true &&
        <thead>
          <tr>
            <th scope="col">
              <Skeleton width={80} />
            </th>
            <th scope="col">
              <Skeleton width={150} />
            </th>
            <th scope="col">
              <Skeleton width={100} />
            </th>
            <th scope="col">
              <Skeleton width={120} />
            </th>
          </tr>
        </thead>
      }
      <tbody>
        {[...Array(rowCount)].map((_, index) => (
          <tr key={index}>
            <td style={{ padding: "2rem 0" }}>
              <Skeleton width={150} />
            </td>
            <td>
              <Skeleton width={150} />
            </td>
            <td>
              <Skeleton width={100} />
            </td>
            <td>
              <Skeleton width={120} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableLoader;
