import React, { useEffect, useState,  } from 'react';
import { Document, Page, View, Text, Image , FlatList} from '@react-pdf/renderer';
import Header from '../Header';
import globalStyles from "../../../constants/globalStyles";
 

export default function OverviewIso27001({ imageBlob ,tab}) {
    const { tableIso27, pieIso27, tableIso22, pieIso22,tableNist,pieNist,tablePci,piePci,
        tableIsa,pieIsa, iso,cardIso,cardNist,cardPci,cardIso22,cardIsa,nist, pci} = imageBlob;
    const [tableIso27ImageSrc, setTableIso27ImageSrc] = useState(null);
    const [pieIso27ImageSrc, setPieIso27ImageSrc] = useState(null);
    const [tableIso22ImageSrc, setTableIso22ImageSrc] = useState(null);
    const [pieIso22ImageSrc, setPieIso22ImageSrc] = useState(null);
    const [tableNistImageSrc, setTableNistImageSrc] = useState(null);
    const [pieNistImageSrc, setPieNistImageSrc] = useState(null);
    const [tablePciImageSrc, setTablePciImageSrc] = useState(null);
    const [piePciImageSrc, setPiePciImageSrc] = useState(null);
    const [tableIsaImageSrc, setTableIsaImageSrc] = useState(null);
    const [pieIsaImageSrc, setPieIsaImageSrc] = useState(null);

    const [isoImageSrc, setIsoImageSrc] = useState(null);
    const [cardIsoImageSrc, setCardIsoImageSrc] = useState(null);
    const [cardIsaImageSrc, setCardIsaImageSrc] = useState(null);
    const [cardIso22ImageSrc, setCardIso22ImageSrc] = useState(null);
    const [cardNistImageSrc, setCardNistImageSrc] = useState(null);
    const [cardPciImageSrc, setCardPciImageSrc] = useState(null);
    const [nistImageSrc, setNistImageSrc] = useState(null);
    const [pciImageSrc, setPciImageSrc] = useState(null);


   

    useEffect(() => {
        const loadImages = async () => {
            const loadAndSetImage = async (image, setImage) => {
                if (image instanceof Blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(image);
                }
            };

            await loadAndSetImage(tableIso27, setTableIso27ImageSrc);
            await loadAndSetImage(pieIso27, setPieIso27ImageSrc);
            await loadAndSetImage(tableIso22, setTableIso22ImageSrc);
            await loadAndSetImage(pieIso22, setPieIso22ImageSrc);
            await loadAndSetImage(tableNist, setTableNistImageSrc);
            await loadAndSetImage(pieNist, setPieNistImageSrc);
            await loadAndSetImage(tablePci, setTablePciImageSrc);
            await loadAndSetImage(piePci, setPiePciImageSrc);
            await loadAndSetImage(tableIsa, setTableIsaImageSrc);
            await loadAndSetImage(pieIsa, setPieIsaImageSrc);
            await loadAndSetImage(iso, setIsoImageSrc);
            await loadAndSetImage(cardIso, setCardIsoImageSrc);
            await loadAndSetImage(cardNist, setCardNistImageSrc);
            await loadAndSetImage(cardPci, setCardPciImageSrc);
            await loadAndSetImage(cardIsa, setCardIsaImageSrc);
            await loadAndSetImage(cardIso22, setCardIso22ImageSrc);
            await loadAndSetImage(nist, setNistImageSrc);
            await loadAndSetImage(pci, setPciImageSrc);


        };

        loadImages();
    }, [tableIso27, pieIso27, tableIso22, pieIso22,tableNist,pieNist,tablePci,piePci,tableIsa,pieIsa,iso,cardIso,cardIso22,cardIsa,cardNist,cardPci,nist, pci]);

    const styles = {
        page: { padding: '20px', fontFamily: 'Noto Sans Arabic' },
        title: { fontSize: '14px', fontWeight: '600', padding: '5px', width: 600 },
        subTitle: { fontSize: '11px', fontWeight: '600', padding: '5px', width: 600 },
        image: {
            width: '150px', // Adjust the width as needed
            height: '150px', // Maintain aspect ratio
            justifyContent: 'center',
            // marginLeft: '10%', // You can remove this line if not needed
            // marginTop: '10px', // You can remove this line if not needed
        },
        tile1: {
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#f1f1f1",
            backgroundColor: globalStyles.greyLite1,
            color: "#000000",
            textDecoration: "none"
          },
          profilePic: {
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30px",
            height: "30px",
            // color: globalStyles.white,
            borderRadius: "50px",
            // border: "1px solid",
            borderColor: globalStyles.greyDark1
          },
          count: {
            marginLeft: "auto",
          }
    };


    // console.log("isoImageSrc",isoImageSrc);
    console.log("cardImageSrc",cardIsoImageSrc);


    return (
        <Document>
        <Page style={styles.page}>
            <Header />
    
            <View style={{marginBottom: 10, marginTop:10}}>
                <Text style={styles.title}>Compliance Dashboard</Text>
                <Text style={{ ...styles.subTitle, fontSize: 10, width: 500 }}>
                Comprehensive tool that integrates data from a wide array of security solutions, including Antivirus (AV), Endpoint Detection and Response (EDR), Firewall, Intrusion Prevention System (IPS), Network Detection and Response (NDR), and Vulnerability Scanners. This dashboard provides a unified view of the organization’s security posture, delivering real-time insights into traffic attacks and system vulnerabilities. It enables the identification of emerging threats and the tracking of potential risks, thereby facilitating the development of a robust and informed defense strategy and will be instrumental in ensuring adherence to various regulatory standards and internal policies. It simplifies the complex task of compliance management by providing a clear overview of the compliance status, highlighting areas of non-compliance, and suggesting necessary remedial actions.                </Text>
            </View>

            {/* iso-27001 */}
            {tab === 'ISO-27001' && (  
                <View>
                    <Text style={{ ...styles.subTitle}}>ISO-27001</Text>
                   
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        {tableIso27ImageSrc && <Image source={tableIso27ImageSrc} />}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        {pieIso27ImageSrc && <Image source={pieIso27ImageSrc} style={{ width: '100%', height: 'auto' }} />}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 2 }}>
                        {isoImageSrc && <Image source={isoImageSrc} style={{ ...styles.image, width: '100%', height: 'auto' }} />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 2 }}>
                        {cardIsoImageSrc && <Image source={cardIsoImageSrc} style={{ ...styles.image, width: '100%', height: 'auto' }} />}
                    </View>

             </View> 
            )}

            {/* ISO-22301 */}
            {tab === 'ISO-22301' && (  
                <View>
                    <Text style={{ ...styles.subTitle}}>ISO-22301</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        {tableIso22ImageSrc && <Image source={tableIso22ImageSrc} />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        {pieIso22ImageSrc && <Image source={pieIso22ImageSrc} style={{ width: '100%', height: 'auto' }} />}
                    </View>
                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        {isoImageSrc && <Image source={isoImageSrc} style={{ ...styles.image, flex: 1, height: '170px' }} />}
                    </View> */}

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                        {cardIso22ImageSrc && <Image source={cardIso22ImageSrc} style={{ ...styles.image, flex: 1, height: '80px' }} />}
                    </View>
                </View> 
            )}    

            {/* NIST */}
            {tab === 'NIST' && (  
                <View>
                     <Text style={{ ...styles.subTitle}}>NIST</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        {tableNistImageSrc && <Image source={tableNistImageSrc} />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        {pieNistImageSrc && <Image source={pieNistImageSrc} style={{ width: '100%', height: 'auto' }} />}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                         {nistImageSrc && <Image source={nistImageSrc} style={{ ...styles.image, flex: 1, height: '170px' }} />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                        {cardNistImageSrc && <Image source={cardNistImageSrc} style={{ ...styles.image, flex: 1, height: '80px' }} />}
                    </View>
                </View> 
            )}           

              {/* PCI-DSS */}
            {tab === 'PCI-DSS' && (  
                <View>
                    <Text style={{ ...styles.subTitle}}>PCI-DSS</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        {tablePciImageSrc && <Image source={tablePciImageSrc}  />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        {piePciImageSrc && <Image source={piePciImageSrc} style={{ width: '100%', height: 'auto' }} />}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        {pciImageSrc && <Image source={pciImageSrc} style={{ ...styles.image, flex: 1, height: '170px' }} />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                        {cardPciImageSrc && <Image source={cardPciImageSrc} style={{ ...styles.image, flex: 1, height: '80px' }} />}
                    </View>
                </View> 
            )}  

             {/* ISA-62443 */}
            {tab === 'ISA-62443' && (  
                <View>
                    <Text style={{ ...styles.subTitle}}>ISA-62443</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                        {tableIsaImageSrc && <Image source={tableIsaImageSrc}  />}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        {pieIsaImageSrc && <Image source={pieIsaImageSrc} style={{ width: '100%', height: 'auto' }} />}
                    </View>
                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        {isoImageSrc && <Image source={isoImageSrc} style={{ ...styles.image, flex: 1, height: '170px' }} />}
                    </View> */}

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                        {cardIsaImageSrc && <Image source={cardIsaImageSrc} style={{ ...styles.image, flex: 1, height: '80px' }} />}
                    </View>
                </View> 
            )}         
            
        </Page> 
    </Document>
            
    );
}
