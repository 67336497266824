const styles = {
  root1: {
    display: "flex",
    backgroundColor: "#F6F7FB",
  },
  root2: {
    // width: "85%",
    height: "100vh",
    gap: "1rem",
    width: "80%",
    // padding: "20px"
    // flexDirection: "column",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    // height: "87%",
    height: "calc(100vh - 8rem)",
    // height: "80vh",
    gap: "1rem",
  },
  section1: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  contentWrap: {
    height: "calc(100vh - 14rem)",
    // height: "calc(100vh - 17rem)",
    overflow: "auto",
  },
  tableWrap: {
    height: "calc(100vh - 14rem)",
    overflow: "auto",
    // paddingRight: "1rem"
  },
  filterWrap: {
    position: "absolute",
    right: "1.5rem",
    top: "14px",
    zIndex: 3
  },
};

export default styles;
