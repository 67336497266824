import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  addToCatalogueRsInv,
  removeFromCatalogueRsInv,
  listMasterRsInv,
  listCatalogueRsCt,
  listMasterCustomRsInv,
  createInvestigateRsInv,
  detailsCustomInvestigateRsInv,
  detailsCustomInvestigateQueryRsInv,
} from "./reducers/ruleStudio/InvestigationReducer";

import {
  Headlineslist,
  Vulnerabilitieslist,
  VulnerabilitiesEXlist,
  SecurityEventsList,
} from "./reducers/TI/activeBytesReducer";

import {
  addToCatalogueRsUeba,
  removeFromCatalogueRsUeba,
  listMasterRsUeba,
  listCatalogueRsCtUeba,
  listMasterCustomRsUeba,
  createUebaRsUeba,
  detailsCustomUebaRsUeba,
  detailsCustomUebaQueryRsUeba,
} from "./reducers/ruleStudio/UebaReducer";

import {
  addToCatalogueRsHunt,
  listMasterRsHunt,
  listCatalogueRsCtHunt,
  listMasterCustomRsHunt,
  createInvestigateRsHunt,
  detailsCustomInvestigateRsHunt,
  listCustomSelectHunterRs,
} from "./reducers/ruleStudio/HunterReducer";

import {
  listCatalogueTenentCompliance,
  removeFromCatalogueCompliance,
  listMasterCustomRsCompliance,
  createCompliance,
  listCatalogueMasterCompliance,
  addToCatalogueRsComp,
  detailsCustomRsComp,
} from "./reducers/ruleStudio/ComplianceReducer";

import {
  listCaseManagement,
  createCaseManagement,
  editCaseManagement,
  deleteCaseManagement,
  singleListView

} from "./reducers/CaseManagement/CaseManagementReducer"

import {
  listCommentCm,
  createCommentCm,
  deleteCommentCm
} from "./reducers/CaseManagement/CommentReducer"

import {
  listCatalogueRsCor,
  addToCatalogueRsCor,
  removeFromCatalogueRsCor,
  listMasterRsCor,
  listMasterCustomRsCor,
  createCorrelationRsCor,
  detailsCustomCorrelationRsCor,
  detailsCustomCorrelationQueryRsCor
} from "./reducers/ruleStudio/CorrelationReducer"


import { getTableDataComTi } from "./reducers/TI/communityReducer";
import { formToJSON } from "axios";

const reducer = combineReducers({
  ruleStudioAddToCat: addToCatalogueRsInv,
  // removeCatRsInv: removeFromCatalogueRsInv,
  listMasterRsIVnV: listMasterRsInv,
  listCatRsInv: listCatalogueRsCt,
  listExpectedRsInv: listMasterCustomRsInv,
  createMasterRsInv: createInvestigateRsInv,
  detailsCustomRsIn: detailsCustomInvestigateRsInv,
  detailsCustomQueryRsIn: detailsCustomInvestigateQueryRsInv,

  ruleStudioAddToCatHunt: addToCatalogueRsHunt,
  // removeCatRsHunt: removeFromCatalogueRsHunt,
  listMasterRsHunt: listMasterRsHunt,
  listCatRsHunt: listCatalogueRsCtHunt,
  listExpectedRsHunt: listMasterCustomRsHunt,
  createMasterRsHunt: createInvestigateRsHunt,
  detailsCustomRsHunt: detailsCustomInvestigateRsHunt,
  listCustomSelectHunt: listCustomSelectHunterRs,

  listCatalogueTenentCompliance: listCatalogueTenentCompliance,
  removeFromCatalogueCompliance: removeFromCatalogueCompliance,
  listMasterCustomRsCompliance: listMasterCustomRsCompliance,
  createCompliance_: createCompliance,
  detailsCustomRsComp: detailsCustomRsComp,

  listCatalogueMasterCompliance: listCatalogueMasterCompliance,
  addToCatalogueRsComp: addToCatalogueRsComp,

  getTableDataComTi: getTableDataComTi,

  Headlineslist: Headlineslist,
  VulnerabilitiesList: Vulnerabilitieslist,
  VulnerabilitiesEXlist: VulnerabilitiesEXlist,
  SecurityEventsList: SecurityEventsList,

  listCaseManagement: listCaseManagement,
  createCaseManagement: createCaseManagement,
  editCaseManagement: editCaseManagement,
  deleteCaseManagement: deleteCaseManagement,
  singleListView: singleListView,


  listCommentCm: listCommentCm,
  createCommentCm: createCommentCm,
  deleteCommentCm: deleteCommentCm,

  addToCatalogueRsUeba: addToCatalogueRsUeba,
  removeFromCatalogueRsUeba: removeFromCatalogueRsUeba,
  listMasterRsUeba: listMasterRsUeba,
  listCatalogueRsCtUeba: listCatalogueRsCtUeba,
  listMasterCustomRsUeba: listMasterCustomRsUeba,
  createUebaRsUeba: createUebaRsUeba,
  detailsCustomUebaRsUeba: detailsCustomUebaRsUeba,
  detailsCustomUebaQueryRsUeba: detailsCustomUebaQueryRsUeba,

  listCatalogueRsCor: listCatalogueRsCor,
  addToCatalogueRsCor: addToCatalogueRsCor,
  removeFromCatalogueRsCor: removeFromCatalogueRsCor,
  listMasterRsCor: listMasterRsCor,
  listMasterCustomRsCor: listMasterCustomRsCor,
  createCorrelationRsCor: createCorrelationRsCor,
  detailsCustomCorrelationRsCor: detailsCustomCorrelationRsCor,
  detailsCustomCorrelationQueryRsCor: detailsCustomCorrelationQueryRsCor





});

const initialState = { catalogue: [] };

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
