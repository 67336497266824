import React from 'react'
import Skeleton from 'react-loading-skeleton'

const styles = {
    contentTile: {
        width: "100%",
        height: "350px",
        borderRadius: "10px",
        overflow: "hidden",
    }
};

const PlainCardLoader = ({ width }) => {
    return (
        <div style={styles.contentTile}>
            <Skeleton width={"100%"} height={"100%"} />
        </div>
    )
}

export default PlainCardLoader