import axios from "../axios";
import { fpostC, fpostDl, fpostCy } from "../axios/axios";
import {
  getMostAnomalousUsersQuery,
  getMostAnomalousHostQuery,
  getCountOfQuery,
  getCountOfAnomaliesQuery,
  getDetectionsQuery,
  getDetectionTrendsQuery,
  getNumberOfEventScannedQuery,
  getCountOfInspectedQuery,
  getUserDetectionQuery,
  getAllActivitiesQuery,
  getDetectedAnomaliesCountBased,
  getDetectionAnomaliesTimeBased,
  Ueba10,
  Ueba003,
  Ueba012,
  finalData,
  anomaliesDataTimeBasedQuery,
  getUsersListQuery,
  getDetectionSummaryQuery,
  o365AuditLogQuery,
  o365MessageLogQuery,
  getO365AuditTimeQuery,
  getO365MessageTimeQuery,
  getUebaMapQuery,
  getProcessCountQuery,
  getProcessCountThreeQuery,
  getProcessCountFourQuery,
  getProcessCountTwoQuery,
  getSeverityCountsQuery,
} from "../Queries/UebaQueries";
import { getProcessListQuery } from "../Utils/Utils";
let SSO_URL = "https://analyticsapi.active-bytes.com";
let baseURL = localStorage.getItem("CYBOT_URL");
let cybotAccessToken = localStorage.getItem("CYBOT_ACCESS_TOKEN");
let dataLakeURL = localStorage.getItem("DATALAKE_URL");
let ueba_users = ["UEBA-001", "UEBA-012", "UEBA-014", "UEBA-016"];

export async function getAnomalousEntities(type, count, order) {
  let data;
  if (type === "user") data = getMostAnomalousUsersQuery(count);
  else if (type === "host") data = getMostAnomalousHostQuery(count);
  return await fpostC(
    // `${dataLakeURL}/logs-*/_search?track_total_hits=true`,
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}


// get details from group id url

export async function getDetectionDetailsFromGroupId(group_id) {
  let data = {
    "size": 10000,
    "query": {
      "bool": {
        "must": [
          {
            "match_phrase": {
              "group_id": group_id
            }
          }
        ]
      }
    }
  };
  return await fpostC(`${baseURL}/cybot_proxy/ueba_grouped_detections*/_search`, data);
}

// get overview section details

export async function getDetectionOverviewDetails(group_id) {
  let data = {
    "size": 10000,
    "query": {
      "bool": {
        "must": [
          {
            "match_phrase": {
              "group_id": group_id
            }
          }
        ]
      }
    }
  };
  return await fpostC(`${baseURL}/cybot_proxy/ueba_group_details*/_search`, data);
}


export async function getLicenceInfo() {
  let data = {
    query: {
      match_all: {},
    },
  };
  return await fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data);
}

export async function getCount(type) {
  let data;
  if (type === "user_name" || "host_name") data = getCountOfQuery(type);
  // else if (type === "anomalous") data = getCountOfAnomaliesQuery();
  return await fpostC(
    // `${baseURL}/cybot_proxy/ueba_observables_test_kw-opencybot-os123/_search`,
    `${baseURL}/cybot_proxy/ueba_observables*/_search`,
    data
  );
}

export async function getAnomalousCount() {
  let data = getCountOfAnomaliesQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search?track_total_hits=true`,
    data
  );
}

export async function getInspectedCount() {
  let data = getCountOfInspectedQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search`,
    data
  );
}

// inspected counts Api
// https://52.53.139.65:9201/cybot_proxy/ueba_user_data_pattern_time*/_search

// detection
// export const getDetections = (count, sort, page) => async (dispatch) => {
//   try{
//        dispatchEvent({type: UEBA_DETECTION_REQUEST});
//        let data = getDetectionsQuery(count, sort, page);
//        fpostC(`${baseURL}/cybot_proxy/ueba_group_details*/_search`,data).then((res) =>{
//         dispatch({
//           type:UEBA_DETECTION_SUCCESS,
//           payload: res,
//         });
//        })
//          } catch (error) {
//           console.log(error);
//           dispatch({
//             type: UEBA_DETECTION_FAIL,
//             payload: error,
//           });
//          }
// };

export async function getDetections(count, sort, page) {
  let data = getDetectionsQuery(count, sort, page);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

export async function getSeverityCounts() {
  let data = getSeverityCountsQuery()

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details_*/_search`,
    data
  );
}

// detection trends
export async function getDetectionTrends(selectedDate, dateFilter) {
  let data = getDetectionTrendsQuery(selectedDate, dateFilter);
  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    // `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

export async function getUserDetection() {
  let data = getUserDetectionQuery();

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_group_details*/_search`,
    data
  );
}

export async function getAllActivities(size) {
  let data = getAllActivitiesQuery(size);

  return await fpostDl(
    `https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true`,
    data
  );
}

// ueba summary

// export async function detectedAnomalies(selectedItem) {
//   let data;
//   if (selectedItem.type === "count based")
//     data = getDetectedAnomaliesCountBased(selectedItem);
//   else data = getDetectionAnomaliesTimeBased(selectedItem);

//   return await fpostC(
//     `${baseURL}/cybot_proxy/ueba_grouped_detections*/_search`,
//     data
//   );
// }

export async function anomaliesDataCountBased(selectedData) {
  let userFilter = {
    match_phrase: {
      user_name: selectedData?.user_name,
    },
  };
  let hostFilter = {
    match_phrase: {
      host_name: selectedData?.host_name,
    },
  };

  let data;

  if (selectedData.ueba_id == "UEBA-010")
    data = Ueba10(selectedData.ueba_id, userFilter, hostFilter);
  else if (selectedData.ueba_id == "UEBA-003")
    data = Ueba003(
      selectedData.ueba_id,
      userFilter,
      selectedData.detection_timestamp
    );
  else if (selectedData.ueba_id == "UEBA-012")
    data = Ueba012(selectedData.ueba_id, hostFilter);
  else if (
    selectedData.ueba_id == "UEBA-006" ||
    selectedData.ueba_id == "UEBA-008" ||
    selectedData.ueba_id == "UEBA-009" ||
    selectedData.ueba_id == "UEBA-011" ||
    selectedData.ueba_id == "UEBA-013"
  )
    data = finalData(selectedData.ueba_id, userFilter, hostFilter);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

export async function o365AuditLog(ueba_id, userId_filter, ClientIp_filter) {
  let data = o365AuditLogQuery(ueba_id, userId_filter, ClientIp_filter);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

export async function o365MessageLog(
  ueba_id,
  email_receiver_address,
  email_from_address
) {
  let data = o365MessageLogQuery(
    ueba_id,
    email_receiver_address,
    email_from_address
  );

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

// timebased

export async function getO365AuditTime(ueba_id, user_id, Client_iP) {
  let data = getO365AuditTimeQuery(ueba_id, user_id, Client_iP);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

export async function getO365MessageTime(
  ueba_id,
  email_from_address,
  email_receiver_address
) {
  let data = getO365MessageTimeQuery(
    ueba_id,
    email_from_address,
    email_receiver_address
  );

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

export async function anomaliesDataTimeBased(selectedData) {
  let ueba_users_with_host = [
    "UEBA-002",
    "UEBA-004",
    "UEBA-005",
    "UEBA-015",
    "UEBA-017",
  ];

  let userFilter = {
    match_phrase: {
      user_name: selectedData?.user_name,
    },
  };
  let hostFilter = {
    match_phrase: {
      host_name: selectedData?.host_name,
    },
  };

  let shouldList = [];
  if (
    (ueba_users.includes(selectedData?.ueba_id) ||
      ueba_users_with_host.includes(selectedData?.ueba_id)) &&
    selectedData?.host_name
  )
    shouldList.push(hostFilter);
  if (!ueba_users.includes(selectedData?.ueba_id) && selectedData?.user_name)
    shouldList.push(userFilter);
  shouldList.push({
    match_phrase: {
      ueba_id: selectedData?.ueba_id,
    },
  });

  let data = anomaliesDataTimeBasedQuery(
    selectedData,
    ueba_users_with_host,
    shouldList
  );

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_user_data_pattern_time*/_search?track_total_hits=true`,
    data
  );
}

// UEBA-028 UEBA-029

export async function getUebaMap(userName, hostName, uebaId, time) {
  let data = getUebaMapQuery(userName, hostName, uebaId, time);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_concurrent-logins*/_search?track_total_hits=true`,
    data
  );
}

export async function getProcessCount(processName, time) {
  let data = getProcessCountQuery(processName, time);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_unusual_process*/_search?track_total_hits=true`,
    data
  );
}

export async function getProcessCountTwo(processName) {
  let data = getProcessCountTwoQuery(processName);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_unusual_process*/_search?track_total_hits=true`,
    data
  );
}

export async function getProcessCountThree(processName) {
  let data = getProcessCountThreeQuery(processName);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_unusual_process*/_search?track_total_hits=true`,
    data
  );
}

export async function getProcessCountFour(processName, userName) {
  let data = getProcessCountFourQuery(processName, userName);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_unusual_process*/_search?track_total_hits=true`,
    data
  );
}

// user and host list

export async function getUsersList(searchTerm, field_name) {
  let data = getUsersListQuery(searchTerm, field_name);

  return await fpostDl(
    `${dataLakeURL}/logs-*/_search?track_total_hits=true`,
    data
  );
}

// detection summary details

export async function getDetectionSummary(groudId, type) {
  let data = getDetectionSummaryQuery(groudId, type);

  return await fpostC(
    `${baseURL}/cybot_proxy/ueba_grouped_detections*/_search`,
    data
  );
}
let edr = localStorage.getItem("EDR_TYPE");
export async function getProcessList(
  type,
  fieldList,
  page,
  size,
  startTimeRange,
  endTimeRange,
  timeFrame,
  date,
  is_anomaly,
  logSrc
) {
  let index_type;
  if (edr === "atp") {
    if (type === "process") {
      index_type = "AdvancedHunting-DeviceProcessEvents";
    } else if (type === "file") {
      index_type = "AdvancedHunting-DeviceFileEvents";
    } else if (type === "network") {
      index_type = "AdvancedHunting-DeviceNetworkEvents";
    } else if (type === "registry") {
      index_type = "AdvancedHunting-DeviceRegistryEvents";
    } else if (type === "authentication") {
      index_type = "AdvancedHunting-DeviceLogonEvents";
    } else {
      index_type = "";
    }
  } else if (edr === "elastic") {
    if (type === "process") {
      index_type = "process";
    } else if (type === "file") {
      index_type = "file";
    } else if (type === "network") {
      index_type = "network";
    } else if (type === "registry") {
      index_type = "registry";
    } else if (type === "authentication") {
      index_type = "authentication";
    } else {
      index_type = "";
    }
  }
  let data = getProcessListQuery(
    fieldList,
    // startTimeRange,
    // endTimeRange,
    date,
    size,
    "desc",
    page,
    index_type,
    is_anomaly,
    logSrc
    // timeFrame
  );
  let baseURL = localStorage.getItem("DATALAKE_URL");
  let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");

  // return await fpostDl(`${baseURL}/cybot-ueba-cykit-test*/_search`, data);
  return await fpostCy(`${baseURL}/${indexPattern}`, data);
}

// exceptions

export async function createExceptions(data) {
  // let bodyData = await JSON.parse(data);
  return await fpostC(
    // `${baseURL}/cybot_proxy/thp_exceptions_platform/_doc`,
    `${baseURL}/cybot_proxy/ueba_exceptions*/_search`,
    data
  );
}
