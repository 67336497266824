import { fpostCy } from '../../../axios/axios';
import { useEffect, useMemo, useState } from 'react';
import Flow from '../../../components/Cykit/Flow';
import { ReactFlowProvider } from 'reactflow';
import { getCykitDocQuery } from '../../../Queries/CykitQuery';

import { useParams, useLocation } from 'react-router-dom';
import styles from './CykitGraph.styles';
import CorelationFlow from '../../../components/Corelation/Flow';
import { Button } from 'react-bootstrap';
import FilterList from '../../../components/FilterList/FilterList';
import useOnclickOutside from 'react-cool-onclickoutside';
import AddFilter from '../../../components/Corelation/AddFilter/AddFilter';
import { Info } from 'phosphor-react';
import CustomToolTip from '../../../components/ToolTip/CustomToolTip';
import { shallow } from 'zustand/shallow';
import useStore from '../../../components/Corelation/Store/store';
import moment from 'moment';

let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");
let baseURL = localStorage.getItem("DATALAKE_URL");
const selector = (state) => ({

  refresh: state.refresh,


});
const Corelation = () => {
  const [selectedHost, setSelectedHost] = useState();
  const [indexType, setIndexType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [processData, setProcessData] = useState([])
  const [qdoc_id, setqdoc_id] = useState('')
  const [type, setType] = useState('')

  const fields = JSON.parse(localStorage.getItem("CORELATION_FILTER_FIELDS", []))
  const { dashboard, doc_id } = useParams();
  const { refresh } = useStore(selector, shallow);
  // useMemo(() => {
  //   setIsLoading(true)
  //   if (qdoc_id !== '' && qdoc_id !== undefined) {
  //     const data = getCykitDocQuery(qdoc_id, indexType)
  //     fpostCy(
  //       `${baseURL}/${indexPattern}`,
  //       data).then((response) => {
  //         const filterDataTemp = []
  //         response.data.hits.hits && response.data.hits.hits.map((val) => {
  //           setSelectedHost(val._source.host.name)
  //           setProcessData(val)
  //         })
  //         setIsLoading(true)
  //         setTimeout(() => { setIsLoading(false) }, 1000)

  //       });
  //   }
  // }, [qdoc_id])

  // useEffect(() => {
  //   if (doc_id !== undefined) {
  //     if(edr==="elastic"){
  //       console.log(doc_id)
  //       setqdoc_id(doc_id)
  //       if(dashboard==="process"){
  //         setIndexType("process")
  //         setType('process')

  //       }
  //       if(dashboard==="file"){
  //         setIndexType("file")
  //         setType('file')
  //       }
  //       if(dashboard==="network"){
  //         setIndexType("network")
  //         setType('network')
  //       }
  //       if(dashboard==="registry"){
  //         setIndexType("registry")
  //         setType('registry')
  //       }
  //       if(dashboard==="unified"){
  //         setType('unified')
  //       }
  //     }else if(edr==="atp"){
  //       console.log(doc_id)
  //       setqdoc_id(doc_id)
  //       if(dashboard==="process"){
  //         setIndexType("AdvancedHunting-DeviceProcessEvents")
  //         setType('process')
  //       }
  //       if(dashboard==="file"){
  //         setIndexType("AdvancedHunting-DeviceFileEvents")
  //         setType('file')
  //       }
  //       if(dashboard==="network"){
  //         setIndexType("AdvancedHunting-DeviceNetworkEvents")
  //         setType('network')
  //       }
  //       if(dashboard==="registry"){
  //         setIndexType("AdvancedHunting-DeviceRegistryEvents")
  //         setType('registry')
  //       }
  //       if(dashboard==="unified"){
  //         setType('unified')
  //       }
  //     }
  //   }
  // }, [doc_id])
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);

  // Get the search term from the query string
  const search = queryParams.get('search');

  // Set the search term to a state variable
  const [searchTerm, setSearchTerm] = useState(search || '');
  const [keyword, setKeyword] = useState('');

  const [selectedOption, setSelectedOption] = useState('quick');
  const [dateFilter, setDateFilter] = useState('');
  const [filter, setFilter] = useState([]);
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [analysisStatus, setAnalysisStatus] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const ref = useOnclickOutside(() => {
    setShowAddFilter(false);
  });
  const handleSearch = () => {
    // Handle your search logic here
    setAnalysisStatus(true);
    setKeyword(searchTerm)

  };
  useEffect(() => {
    let start = moment().subtract(1800, "seconds")?.format("yyyy-MM-DDTHH:mm");
    let end = moment().format("yyyy-MM-DDTHH:mm");
    let dateTemp = {
      from: start,
      to: end,
      f: start,
      e: end,
      range: 1800,
    };
    setDateFilter(dateTemp)
  }, [])
  const handleReSearch = () => {
    // Handle your search logic here
    setRefreshSearch(true)
    setAnalysisStatus(true);
    setKeyword(searchTerm)
    setRefreshKey(oldKey => oldKey + 1);
    refresh()
  };
  const getFilteredTable = (filter, date) => { return true }
  return (

    <div className='col-12 rounded-3' style={styles.section1}>

      <div className="input-group mb-3 p-2  pt-4 d-flex col-12 border rounded-3">
        <div className='col-3'>
          <AddFilter
            filter={filter}
            setFilter={setFilter}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            setShowAddFilter={setShowAddFilter}
            getFilteredTable={getFilteredTable}
            showDateFilter={false}
            showFilter={true}
            // setPage={setPage}

            columnValues={fields}

          />
        </div>
        <div className='d-flex flex-column col-6'>
          <div className='ms-5 d-flex col-12'>
            <div className='col-6 pr-2 ml-3'>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <div className='col-2 ml-1'>

              <select className="form-control" value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                <option value="quick">Simple Search</option>
                <option value="adv">Advanced Search</option>
              </select>
            </div>
            <div className="ml-2 col-3 d-flex">
              {!analysisStatus ? <Button type="button" onClick={handleSearch}
              //  disabled={analysisStatus}
              >Analyze</Button> : <Button type="button" onClick={handleReSearch}
              //  disabled={analysisStatus}
              >ReAnalyze</Button>}
              <div>
                {/* <Button
                  className="mx-2"
                  onClick={() => { setShowAddFilter(!showAddFilter); }}
                  disabled={analysisStatus}
                  ref={ref}
                >
                  Add filter
                </Button> */}
                <div className='mt-1 ms-2'>
                  <CustomToolTip content={<Info size={22} />} dataPlacement={"bottom"}
                    dataToggle={
                      <div className="d-flex flex-column text-left" style={{ textAlign: 'left' }} >
                        <div>The <b>Simple Search</b> allows you to search for a text in multiple fields.</div>
                        <div style={styles.tooltipExample}>
                          Example: John - will get all documents that have John in any of the fields
                        </div>
                        <div style={styles.tooltipTitle}><b>Advanced search </b>: it is used to search with wildcard, regular expressions and lucene syntax </div>
                        <div style={styles.tooltipExample}>
                          Example: Joh* - will get all documents that have Joh in any of the fields
                        </div>
                      </div>
                    } />


                </div>

              </div>
            </div>


          </div>
          <div className='mt-4 ms-4'>
            <div className=' ms-4'>
              {(filter.length > 0 || dateFilter?.to) && <h6>Applied Filters</h6>}
              <FilterList
                filter={filter}
                setFilter={setFilter}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                getFilteredTable={getFilteredTable}
              />
            </div>

          </div>
        </div>
        <div className='col-3'>
          <AddFilter
            filter={[]}
            setFilter={setFilter}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            setShowAddFilter={setShowAddFilter}
            getFilteredTable={getFilteredTable}
            showDateFilter={true}
            // showFilter={true}
            // setPage={setPage}

            columnValues={[
            ]}

          />
        </div>
      </div>


      <div className='col-12'>
        <div key={refreshKey}>
          <ReactFlowProvider>
            {analysisStatus && <CorelationFlow keyword={keyword} filter={filter} searchType={selectedOption} setFilter={setFilter} analysisStatus={analysisStatus} refreshSearch={refreshKey} dateFilter={dateFilter} />}
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  )
}
export default Corelation;
