import React, { useEffect, useState } from 'react'
import { PDFDownloadLink, Document, Page, View, Text, Image, Font } from "@react-pdf/renderer";
import { CalendarBlank } from 'phosphor-react';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../Utils/Utils';
import Header from './Header';


const styles = {
    page: { padding: "20px", fontFamily: "Noto Sans Arabic" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "50%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "50%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
    tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    imageDiv: { justifyContent: 'center', },
    setBorder: { border: "1px solid black" },
    image: {
        width: '40%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "14px", fontWeight: "600", padding: "5px", width: 600 },
    subTitle: { fontSize: "11px", fontWeight: "600", padding: "5px", width: 600 },
    countsContainer: { width: '50%' }
};


const ManagementOverviewExport = ({ imageBlob, accountActivity }) => {

    // const { ueba, Investigate, TrafficFromTheInternet, TrafficToTheInternet, TopReceivers, TopSenders, FileOperations, UserManagementActivities, RecentAccountActivities } = imageBlob
    const { trafficInternet, email, fileOperations, ueba, investigate, UserManagementActivities, RecentAccountActivities, ti } = imageBlob

    // const [TrafficFromTheInternetImageSrc, setTrafficFromTheInternetImageSrc] = useState(null);
    // const [TrafficToTheInternetImageSrc, setTrafficToTheInternetImageSrc] = useState(null);
    // const [TopReceiversImageSrc, setTopReceiversImageSrc] = useState(null);
    // const [TopSendersImageSrc, setTopSendersImageSrc] = useState(null);
    // const [FileOperationsImageSrc, setFileOperationsImageSrc] = useState(null);

    const [uebaImageSrc, setUebaImageSrc] = useState(null);
    const [tiImageSrc, setTiImageSrc] = useState(null);
    const [investigateImageSrc, setinvestigateImageSrc] = useState(null);
    const [trafficInternetImageSrc, setTrafficInternetImageSrc] = useState(null);
    const [emailImageSrc, setEmailImageSrc] = useState(null);
    const [fileOperationsImageSrc, setFileOperationsImageSrc] = useState(null);
    const [UserManagementActivitiesImageSrc, setUserManagementActivitiesImageSrc] = useState(null);
    const [RecentAccountActivitiesImageSrc, setRecentAccountActivitiesImageSrc] = useState(null);

    useEffect(() => {
        if (ueba) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUebaImageSrc(reader.result);
            };
            reader.readAsDataURL(ueba);
        }
        if (ti) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTiImageSrc(reader.result);
            };
            reader.readAsDataURL(ti);
        }

        if (investigate) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setinvestigateImageSrc(reader.result);
            };
            reader.readAsDataURL(investigate);
        }

        if (UserManagementActivities) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUserManagementActivitiesImageSrc(reader.result);
            };
            reader.readAsDataURL(UserManagementActivities);
        }

        if (RecentAccountActivities) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setRecentAccountActivitiesImageSrc(reader.result);
            };
            reader.readAsDataURL(RecentAccountActivities);
        }

        if (trafficInternet) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTrafficInternetImageSrc(reader.result);
            };
            reader.readAsDataURL(trafficInternet);
        }

        if (email) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEmailImageSrc(reader.result);
            };
            reader.readAsDataURL(email);
        }

        if (fileOperations) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileOperationsImageSrc(reader.result);
            };
            reader.readAsDataURL(fileOperations);
        }

    }, [trafficInternet, email, fileOperations, ueba, investigate, UserManagementActivities, RecentAccountActivities, ti]);

    return (
        <Document>
            <Page style={{ padding: 10 }}>
                <Header />
                {/* counts */}

                <View>
                    <Text style={styles.title}>Threat intelligence</Text>
                    <Text style={{ ...styles.subTitle, fontSize: 10, width: 500 }}>This section of dashboard presents a consolidated summary of the recent threat landscape, vulnerabilities and security events impacting the industry. By providing real-time insights into the ever-evolving realm of cyber threats, CYBOT™ equips organizations with the knowledge necessary to fortify their defences and make informed decisions to safeguard their digital assets.</Text>

                    <Image source={tiImageSrc} />
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        <Image source={uebaImageSrc} />
                    </View>

                    <View style={styles.column2}>
                        <Text style={{ fontSize: 12, marginBottom: 5, marginTop: 15 }}>File Operations</Text>
                        {/* <Text>The section presented on the dashboard provides a comprehensive summary of the file activities that have occurred within our organization. It offers an overview of various actions related to file management, tracking changes, and monitoring file usage. This includes activities such as file creations, modifications, and deletions, as well as sharing and collaboration activities among user.</Text> */}
                        <Image source={fileOperationsImageSrc} />
                    </View>
                </View>


                <Text style={styles.title}>Traffic</Text>
                <Text style={{ ...styles.subTitle, fontSize: 10, width: 500 }}>The network traffic section in the dashboard provides valuable information for identifying potential suspicious activity targeting the organization's assets, as well as suspicious traffic originating from within the organization towards countries of interest. By monitoring incoming and outgoing network traffic, you can gain insights into potential security threats and take appropriate actions to mitigate risks.</Text>

                <View>
                    <Image source={trafficInternetImageSrc} />
                </View>
            </Page>

            <Page style={{ padding: 10 }}>
                {/* email */}
                <View>
                    <Text style={styles.title}>Email</Text>
                    <Text style={{ ...styles.subTitle, fontSize: 10, width: 500 }}>The email activity section of the dashboard provides a summary of the organization's email-related information and activity. This section is crucial for monitoring and analyzing email communications within the organization to ensure security, compliance, and operational efficiency.</Text>
                    <Image source={emailImageSrc} />
                </View>

                {/* investigate */}

                {/* <Text style={styles.title}>Threats Being Monitored Actively</Text>
                <Text style={styles.subTitle}>The CYBOT™ Investigate module actively detects threats pop your organisation utilising the logs forwarded to the platform. It first detects and then investigates the threat like a human analyst and summarises the investigation report. Below is the number of use-cases or threats being monitored and investigated on the platform</Text> */}

                <View>
                    <Image source={investigateImageSrc} />
                </View>
            </Page>

            <Page style={{ padding: 10 }}>
                {/* UserManagementActivitiesImageSrc */}
                <Text style={styles.title}>User Management Activities</Text>
                <Text style={{ ...styles.subTitle, fontSize: 10, width: 500 }}>The section displayed on the dashboard provides a detailed summary of the user management activities that have taken place within our organization. It presents a consolidated view of various activities related to managing user accounts, permissions, and access levels. This includes activities such as user creation, deletion, and modification, as well as changes in user roles and permissions.</Text>
                <View>
                    <Image source={UserManagementActivitiesImageSrc} />
                </View>

                {/* RecentAccountActivitiesImageSrc */}
                {/* <View style={styles.container}>
                    <Image source={RecentAccountActivitiesImageSrc} />
                </View> */}


                {accountActivity?.length > 0 &&
                    <>
                        <Text style={[styles.title, { marginTop: -120 }]}>Recent Account Activities</Text>
                        {accountActivity?.map((item, i) => (
                            <View>
                                {item?.type === 'account_created' &&
                                    <View style={{ borderWidth: 0.5, borderColor: 'gray', marginBottom: 4 }}>
                                        <Text style={{ ...styles.title, fontSize: 11 }}>{item?._source?.user?.target?.name} created by {item?._source?.user?.name}</Text>
                                        {/* <Text>{item?._source?.message}</Text> */}
                                        <View>
                                            <Text style={styles.subTitle}>{getDateFromTimestamp(item?._source?.["@timestamp"]) + " " + "at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text>
                                            {/* <Text style={styles.subTitle}>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text> */}
                                        </View>
                                    </View>
                                }
                                {item?.type === 'account_deleted' &&
                                    <View style={{ borderWidth: 0.5, borderColor: 'gray', marginBottom: 4 }}>
                                        <Text style={{ ...styles.title, fontSize: 11 }}>The user account {item?._source?.user?.target?.name} was deleted by {item?._source?.user?.name}</Text>
                                        <View>
                                            <Text style={styles.subTitle}>{getDateFromTimestamp(item?._source?.["@timestamp"]) + " " + "at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text>
                                            {/* <Text style={styles.subTitle}>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text> */}
                                        </View>
                                    </View>
                                }
                                {item?.type === 'account_changed' &&
                                    <View style={{ borderWidth: 0.5, borderColor: 'gray', marginBottom: 4 }}>
                                        {item?._source?.user?.target?.name === item?._source?.user?.name ?
                                            <Text style={{ ...styles.title, fontSize: 11 }}>The user account {item?._source?.user?.target?.name} was changed</Text>
                                            :
                                            <Text style={{ ...styles.title, fontSize: 11 }}>The user account {item?._source?.user?.target?.name} was changed by {item?._source?.user?.name}</Text>}
                                        <View>
                                            <Text style={styles.subTitle}>{getDateFromTimestamp(item?._source?.["@timestamp"]) + " " + "at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text>
                                            {/* <Text style={styles.subTitle}>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text> */}
                                        </View>
                                    </View>
                                }
                                {item?.type === 'account_locked' &&


                                    <View style={{ borderWidth: 0.5, borderColor: 'gray', marginBottom: 4 }}>
                                        <Text style={{ ...styles.title, fontSize: 11 }}>The user account {item?._source?.user?.target?.name} was locked by {item?._source?.user?.name}</Text>
                                        <View>
                                            <Text style={styles.subTitle}>{getDateFromTimestamp(item?._source?.["@timestamp"]) + " " + "at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text>
                                            {/* <Text style={styles.subTitle}>{}</Text> */}
                                        </View>
                                    </View>

                                }
                                {item?.type === 'password_change' &&


                                    <View style={{ borderWidth: 0.5, borderColor: 'gray', marginBottom: 4 }}>
                                        {item?._source?.user?.target?.name === item?._source?.user?.name ?
                                            <Text style={{ ...styles.title, fontSize: 11 }}>Password of the user account {item?._source?.user?.target?.name} was changed</Text>
                                            :
                                            <Text style={{ ...styles.title, fontSize: 11 }}>Password of the user account {item?._source?.user?.target?.name} was changed by {item?._source?.user?.name}</Text>}
                                        <View>
                                            <Text style={styles.subTitle}>{getDateFromTimestamp(item?._source?.["@timestamp"]) + " " + "at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</Text>
                                            {/* <Text style={styles.subTitle}>{ }</Text> */}
                                        </View>
                                    </View>

                                }
                            </View>
                        ))}
                    </>
                }
            </Page>
        </Document>
    )

}

export default ManagementOverviewExport