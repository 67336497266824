import React, { useEffect, useState } from "react";
import { getFieldAggs, getLogSourceQuery } from "../Queries";
import { fpostCy } from "../../../axios/axios";
const baseURL = localStorage.getItem("DATALAKE_URL");
const indexPattern = localStorage.getItem("CORELATION_INDEX");
export async function getFieldAggsData(field,filter,dateFilter,keyword,searchType) {
    let data = getFieldAggs(field,filter,dateFilter,keyword,searchType);
    return await fpostCy(
        `${baseURL}/${indexPattern}/_search?track_total_hits=true`,
        data
    );
}

export async function getLogSourceData(keyword,filter,dateFilter,searchType) {
    let data = getLogSourceQuery(keyword,filter,dateFilter,searchType);
    return await fpostCy(
        `${baseURL}/${indexPattern}/_search?track_total_hits=true`,
        data
    );
}

