import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import ManagementDashboard from "./Dashboard/ManagementDashboard";
import styles from "./ManagementOverview.styles";
import ThreatLandscape from "./Threatlandscape/ThreatLandscape";

const ManagementOverview = () => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState("");
    const [currentPath, setCurrentPath] = useState("");
    const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter") ? localStorage.getItem("filter") : "7 Days");
    const [title, setTitle] = useState("");
    const [breadcrumb, setBreadcrumb] = useState();
    const [dateFilter, setDateFilter] = useState();
    useEffect(() => {
        setCurrentUrl(location.pathname);
        setCurrentPath(location.pathname.split("/")[2]);
    }, [location]);

    useEffect(() => {
        if (currentPath === "dashboard") {
            setTitle("Management Dashboard")
            setBreadcrumb({
                "module": "management_dashboard",
                "path": [{ "title": "Management Dashboard", "url": "/management/dashboard" }, { "title": "Dashboard", "url": "/management/dashboard" },]
            });
        }

        if (currentPath === "threat_landscape") {
            setTitle("Threat Landscape")
            setBreadcrumb({
                "module": "threat_landscape",
                "path": [{ "title": "Management Dashboard", "url": "/management/dashboard" }, { "title": "Threat Landscape", "url": "/management/threat_landscape" },]
            });
        }
    }, [currentPath]);

    return (
        <div className="col-12 d-flex" style={styles.root1}>
            <Sidebar module={"management"} />
            <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
                <Topbar
                    title={title}
                    breadcrumb={breadcrumb}
                    currentUrl={currentUrl}
                    selectedDateFilter={selectedDateFilter}
                    setSelectedDateFilter={setSelectedDateFilter}
                    isFilterEnabled={currentPath === "dashboard"}
                    setDateFilter={setDateFilter}
                    module={["ueba","hunter","investigation"]}
                    tab={["detection","cykit-edr-file","cykit-firewall","cykit-email","cykit-ad"]}
                />
                <div className="col-12 d-flex" style={styles.main}>
                    {currentPath === "dashboard" ? (
                        <ManagementDashboard selectedDateFilter={selectedDateFilter}
                            setSelectedDateFilter={setSelectedDateFilter} />
                    ) : currentPath === "threat_landscape" ? (
                        <ThreatLandscape />) : null}
                </div>
            </div>
        </div>
    );
};

export default ManagementOverview;