import {
  Binoculars,
  ComputerTower,
  ShieldWarning,
  UsersThree,
} from "phosphor-react";

import React, { useEffect, useState, useContext } from "react";
import styles from "./Dashboard.styles";
import "../../../index.css";
import RecentTab from "../../../components/RecentTab/RecentTab";
import {
  getAnomalousCount,
  getAnomalousEntities,
  getCount,
  getDetections,
  getDetectionTrends,
  getInspectedCount,
  getSeverityCounts,
} from "../../../Methods/UebaMethods";
import { convertTimestamp } from "../../../Methods/TiMethods";
import globalStyles from "../../../constants/globalStyles";
// import moment from "react-moment";
import {
  timestampConvert,
  escapeHtmlChars,
  getDateFromTimestamp,
  getTimeFromTimestamp,
  rawHTML,
} from "../../../Utils/Utils";
import { TimelineLoader } from "../../../components/Loader/TimelineLoader";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CustomModal from "../../../components/CustomModal/CustomModal";
import UebaSummary from "../../../components/UEBA/UebaSummary";
import { SmallCardLoader } from "../../../components/Loader/CardLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { Link, useNavigate } from "react-router-dom";
import CustomToolTip from "../../../components/ToolTip/CustomToolTip";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ selectedDateFilter }) => {

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [timelineIsLoading, setTimelineIsLoading] = useState(true);
  // count loading
  const [inspectedCountLoading, setInspectedCountLoading] = useState(true);
  const [anomaliesCountLoading, setAnomaliesCountLoading] = useState(true);
  const [usersCountLoading, setUsersCountLoading] = useState(true);
  const [hostsCountLoading, setHostCountLoading] = useState(true);

  //* setting Most Anomalouse Users
  const [mostAnomalouseUsersList, setMostAnomalouseUsersList] = useState([]);
  const [mostAnomalouseHostList, setMostAnomalouseHostList] = useState([]);

  const [detectionData, setDetectionData] = useState([]);
  const [userCount, setUserCount] = useState("Loading");
  const [hostCount, setHostCount] = useState("Loading");
  const [anomaliesCount, setAnomaliesCount] = useState("Loading");
  const [inspectedCount, setInspectedCount] = useState("Loading");
  const [recentDetections, setRecentDetections] = useState([]);

  const [severityCounts, setSeverityCounts] = useState([])
  const severityOrder = ['Low', 'Medium', 'High', 'Critical'];

  const [detectionLabel, setDetectionLabel] = useState([]);
  const [detectionChartData, setDetectionChartData] = useState([]);
  // const [detectionTrends, setDetectionTrends] = useState([]);

  // modal
  const [show, setShow] = useState(false);
  const [detailpage, setDetailpage] = useState([]);

  const detectionTimeline = detectionData.map((item) => {
    return Object.entries(item);
  });

  // loader
  const [recentUserLoader, setRecentUserLoader] = useState(true)
  const [recentHostLoader, setRecentHostLoader] = useState(true)
  const dayFormat = {
    unit: 'day',
    unitStepSize: 1,
    displayFormats: {
      'millisecond': 'MMM DD',
      'second': 'MMM DD',
      'minute': 'MMM DD',
      'hour': 'MMM DD',
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: !(selectedDateFilter === "1 Day" || selectedDateFilter.includes("hour") || selectedDateFilter.includes("minute")) ? dayFormat : {}
      },
      y: {
        type: 'linear'
      }
    },
  };

  const labels = detectionLabel;

  const data = {
    labels,
    datasets: [
      {
        label: "Detection",
        data: detectionChartData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.4,
      },
    ],
  };

  useEffect(() => {
    // call api when date filter applied here
    // eg: getAnomalousEntities("host", selectedDateFilter, "asc");
    // getDetections(selectedDateFilter, "desc");
    // let selectedDate;
    // switch (selectedDateFilter) {
    //   case "1 Day":
    //     selectedDate = 1;
    //     break;
    //   case "7 Days":
    //     selectedDate = 7;
    //     break;
    //   case "30 Days":
    //     selectedDate = 30;
    //     break;
    //   case "1 Year":
    //     selectedDate = 365;
    //     break;
    //   default:
    // }
    // let current = new Date();
    // let startD = new Date(
    //   current.getTime() - selectedDate * 24 * 60 * 60 * 1000
    // )
    //   .toISOString()
    //   .slice(0, 10);
    // setStartDate(startD);
    // let endD = current.toISOString().slice(0, 10);
    // setEndDate(endD);
    // }, [selectedDateFilter]);
  }, []);

  useEffect(() => {
    setTimelineIsLoading(true)
    setInspectedCountLoading(true)
    setAnomaliesCountLoading(true)
    setUsersCountLoading(true)
    setHostCountLoading(true)
    setRecentUserLoader(true)
    setRecentHostLoader(true)

    getAnomalousEntities("user", "5", "asc")
      .then((response) => {
        const { buckets } = response.data.aggregations[1];

        setMostAnomalouseUsersList(buckets);
        setRecentUserLoader(false)
      })
      .catch((err) => {
        console.log("error", err);
        setRecentUserLoader(false)
      });

    getAnomalousEntities("host", "5", "asc")
      .then((response) => {
        const { buckets } = response.data.aggregations[1];
        setMostAnomalouseHostList(buckets);
        setRecentHostLoader(false)
      })
      .catch((err) => {
        console.log("error", err);
        setRecentHostLoader(false)
      });

    // getDetections(count)

    getDetections(50, "desc")
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          hits.map(
            (item) => (
              item._source.detection_timestamp = getDateFromTimestamp(
                item._source.detection_timestamp,
                "MMM Do YY"
              )
            )
          );
          let groups = Object.create(null);
          let result;

          hits.forEach((element) => {
            groups[element._source.detection_timestamp] =
              groups[element._source.detection_timestamp] || [];
            groups[element._source.detection_timestamp].push(element);
          });

          result = Object.keys(groups).map((k) => {
            let temp = {};
            temp[k] = groups[k];
            return temp;
          });

          setDetectionData(result);
          setTimelineIsLoading(false);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // get detection trends

    getDetectionTrends(selectedDateFilter)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {
          let label = [];
          let data = [];
          if (buckets?.length > 0) {
            buckets.map((item) => {
              // if (selectedDateFilter === "1 Day") {
              //   label.push((item.key = timestampConvert(item.key, "HH:mm:ss")));
              // } else {
              //   label.push((item.key = getDateFromTimestamp(item.key, "dddd")));
              // }
              
              label.push(moment.utc(item.key_as_string).format('YYYY-MM-DD HH:mm:ss'));
              
              data.push(item.doc_count);
              setDetectionLabel(label);
              setDetectionChartData(data);
            });
          }
          else {
            setDetectionLabel([]);
            setDetectionChartData([]);
          }
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // getCount(type)

    getCount("user_name")
      .then(
        ({
          data: {
            aggregations: {
              item_count: { value },
            },
          },
        }) => {
          setUserCount(value);
          setUsersCountLoading(false)
        }
      )
      .catch((err) => {
        console.log("error", err);
        setUsersCountLoading(false)
      });

    getCount("host_name")
      .then(
        ({
          data: {
            aggregations: {
              item_count: { value },
            },
          },
        }) => {
          setHostCount(value);
          setHostCountLoading(false)
        }
      )
      .catch((err) => {
        console.log("error", err);
        setHostCountLoading(false)
      });

    // getCount("anomalous")
    //   .then(
    //     ({
    //       data: {
    //         aggregations: {
    //           item_count: { value },
    //         },
    //       },
    //     }) => {
    //       alert("SUXXXX");
    //       console.log("VALLL YOUU :", value);
    //       setAnomaliesCount(value);
    //     }
    //   )
    //   .catch((err) => {
    //     alert("FILLL");
    //     console.log("error", err);
    //   });

    getAnomalousCount()
      .then(
        ({
          data: {
            hits: { total },
          },
        }) => {
          setAnomaliesCount(total.value);
          setAnomaliesCountLoading(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });
    // getCount("inspected")
    //   .then(({ data: { hits: { total: { value } } } }) => {
    //     value && setInspectedCount(value)
    //   }).catch((err) => {
    //     console.log("Error", err)
    //   })

    getInspectedCount()
      .then(
        ({
          data: {
            aggregations: { events_scanned },
          },
        }) => {
          events_scanned && setInspectedCount(events_scanned.value);
          setInspectedCountLoading(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    getDetections()
      .then(
        ({
          data: {
            aggregations: {
              item_count: { value },
            },
          },
        }) => {
          setRecentDetections(value);
        }
      )
      .catch((err) => {
        console.log("error", err);
      });


    // get severity counts

    getSeverityCounts().then((res) => {
      let data = res?.data?.aggregations?.severity?.buckets
      setSeverityCounts(data)
      console.log('RESSS SEVERITY :', data)
    }).catch((err) => {
      console.log('Error', err)
    })

    // setSeverityCounts

  }, [selectedDateFilter]);

  const handleOpenModal = (data, display) => {
    setDetailpage(data);
    setShow(display);
  };

  function handleCloseModal() {
    setShow(false);
  }

  function renderModal() {
    return <UebaSummary detectionData={detailpage} />;
  }

  return (
    <>
      <div
        className="col-8 d-flex flex-column gap-3 overflow-auto"
        style={styles.section1}
      >
        <div className="col-12 d-flex flex-row gap-3 p-3 rounded-3 bg-white">
          {/* <CustomToolTip content={
            <div className="w-25 rounded-3 p-3" style={styles.overviewTile}>
              {inspectedCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div style={styles.overviewIcon}>
                    <Binoculars size={24} />
                  </div>
                  <div>
                    <h4>{inspectedCount}</h4>
                    <span>Events inspected</span>
                  </div>
                </>
              )}
            </div>
          } dataPlacement={"bottom"}
            dataToggle={
              <div style={styles.tooltipWrap}>
                Total number of events checked over the applied period
              </div>
            }>
          </CustomToolTip> */}

          <CustomToolTip content={
            <div
              className="w-33 rounded-3 p-3"
              style={{ ...styles.overviewTile, ...styles.overviewTileOrange, flexDirection: 'column' }}
              onClick={() => { navigate("/ueba/detection") }}
            >
              {anomaliesCountLoading ? (<SmallCardLoader />) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div
                      style={{ ...styles.overviewIcon, ...styles.overviewIconOrange }}
                    >
                      <ShieldWarning size={24} />
                    </div>
                    <div>
                      <h4>{anomaliesCount}</h4>
                      <span>Anomalies detected</span>
                    </div>
                  </div>
                </div>
              )}
              {/* severity */}
              {/* <div style={{ display: 'flex', gap: '10px' }}>
                {severityOrder.map((severity) => {
                  const item = severityCounts.find((item) => item?.key === severity);              
                  return (
                    <div style={{ background: item?.key === 'Low' ? globalStyles.greenDark1 : item?.key === 'Medium' ? globalStyles.yellowDark1 : item?.key === 'High' ? globalStyles.orangeDark1 : globalStyles.red, flex: 1, padding: 4 }} className="rounded">
                      <span>{item?.key}</span>
                      <h6>{item?.doc_count}</h6>
                    </div>
                  );
                })}
              </div> */}
            </div>
          } dataPlacement={"bottom"}
            dataToggle={
              <div style={styles.tooltipWrap}>
                Count of anomalies detected over the applied period.
              </div>
            }>
          </CustomToolTip>


          <CustomToolTip content={
            <div
              className="w-33 rounded-3 p-3"
              style={{ ...styles.overviewTile, ...styles.overviewTilePurple }}
            // onClick={() => { navigate("/ueba/detection") }}
            >
              {usersCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconPurple }}
                  >
                    <UsersThree size={24} />
                  </div>
                  <div>
                    <h4>{userCount}</h4>
                    <span>Number of users</span>
                  </div>
                </>
              )}
            </div>
          } dataPlacement={"bottom"}
            dataToggle={
              <div style={styles.tooltipWrap}>
                Count of users associated with the detections.
              </div>
            }>
          </CustomToolTip>


          <CustomToolTip content={
            <div
              className="w-33 rounded-3 p-3"
              style={{ ...styles.overviewTile, ...styles.overviewTileGreen }}
            // onClick={() => { navigate("/ueba/detection") }}
            >
              {hostsCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconGreen }}
                  >
                    <ComputerTower size={24} />
                  </div>
                  <div>
                    <h4>{hostCount}</h4>
                    <span>Number of hosts</span>
                  </div>
                </>
              )}
            </div>
          } dataPlacement={"bottom"}
            dataToggle={
              <div style={styles.tooltipWrap}>
                Count of hosts associated with the detections.
              </div>
            }>
          </CustomToolTip>

        </div>

        <div className="d-flex flex-row gap-3 p-3 rounded-3 bg-white">
          {severityOrder.map((severity) => {
            const item = severityCounts?.find((item) => item?.key === severity);
            const count = item?.doc_count || 0; // Default to zero if count is undefined

            return (
              <div
                className="w-25 rounded-3 p-3 cursor-pointer"
                style={{ ...styles.overviewTile, background: severity === 'Low' ? globalStyles.greenLite1 : severity === 'Medium' ? globalStyles.yellowLite1 : severity === 'High' ? globalStyles.orangeLite1 : severity === 'Critical' ? globalStyles.redLite1 : null }}
                onClick={() => {
                  navigate(`/ueba/detection?severity=${severity}&active=detections&date=${selectedDateFilter}`)
                }}
              >
                <>
                  <div
                    style={{ ...styles.overviewIcon, background: severity === 'Low' ? globalStyles.greenDark1 : severity === 'Medium' ? globalStyles.yellowDark1 : severity === 'High' ? globalStyles.orangeDark1 : severity === 'Critical' ? globalStyles.red : null }}
                  >
                    <Binoculars size={24} />
                  </div>
                  <div>
                    <h4>{count}</h4>
                    <span>{severity}</span>
                  </div>
                </>
              </div>
            );
          })}

          {/* <div
            className="w-25 rounded-3 p-3 cursor-pointer"
            style={{ ...styles.overviewTile, ...styles.overviewTileYellow }}
            onClick={() => { navigate("/investigate/cybot_soc", { state: { severity: "Medium", active: "Detections", date: selectedDateFilter } }) }}
          >
            <>
              <div
                style={{ ...styles.overviewIcon, ...styles.overviewIconYellow }}
              >
                <ShieldWarning size={24} />
              </div>
              <div>
                <h4>{severityCounts.medium}</h4>
                <span>Medium</span>
              </div>
            </>

          </div>
          <div
            className="w-25 rounded-3 p-3 cursor-pointer"
            style={{ ...styles.overviewTile, ...styles.overviewTileOrange }}
            onClick={() => { navigate("/investigate/cybot_soc", { state: { severity: "High", active: "Detections", date: selectedDateFilter } }) }}

          >
            <>
              <div
                style={{ ...styles.overviewIcon, ...styles.overviewIconOrange }}
              >
                <ShieldWarning size={24} />
              </div>
              <div>
                <h4>{severityCounts.high}</h4>
                <span>High</span>
              </div>
            </>
          </div>
          <div
            className="w-25 rounded-3 p-3 cursor-pointer"
            style={{ ...styles.overviewTile, ...styles.overviewTileRed }}
            onClick={() => { navigate("/investigate/cybot_soc", { state: { severity: "Critical", active: "Detections", date: selectedDateFilter } }) }}

          >
            <>
              <div
                style={{ ...styles.overviewIcon, ...styles.overviewIconRed }}
              >
                <ShieldWarning size={24} />
              </div>
              <div>
                <h4>{severityCounts.critical}</h4>
                <span>Critical</span>
              </div>
            </>
          </div> */}
        </div>

        <div className="col-12 gap-3 d-flex flex-row">
          <RecentTab
            tabName={"Hosts"}
            mostAnomalouseList={mostAnomalouseHostList}
            selectedField={'host_name'}
            FieldLabel={'Host'}
            type="ueba"
            loader={recentHostLoader}
          />
          <RecentTab
            tabName={"Users"}
            mostAnomalouseList={mostAnomalouseUsersList}
            selectedField={'user_name'}
            FieldLabel={'User'}
            type="ueba"
            loader={recentUserLoader}

          />
        </div>




        <div className="col-12 d-flex flex-row gap-3 p-4 rounded-3 bg-white">
          <div className="col-12">
            <h5>Detection Trends</h5>
            {/* <p>Number of detections over {selectedDateFilter === "1 Day" ? "last day" : selectedDateFilter === '7 Days' ? "last week" : selectedDateFilter === "30 Days" ? "last month" : "last year"}</p> */}
            {/* <p>Number of detections over applied period</p> */}

            <div className="detection-chart col-12">
              <div className="position-relative col-12 d-flex align-items-center justify-content-center" style={{ height: "480px" }}>
                <Line options={options} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 rounded-3 flex-fill" style={styles.section2}>
        <div>
          <h5>Recent Detections</h5>
          <p>
            Recent abnormal events are grouped based on the anomalous behavior
            pattern of the entity
          </p>

          <div
            className="d-flex col-12 position-relative"
            style={styles.recentDetectionsWrap}
          >
            {timelineIsLoading ? (
              <div className="col-12">
                <TimelineLoader />
              </div>
            ) : (
              <>
                {detectionData.length || detectionData.length !== 0 ? (
                  <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                    {detectionTimeline.map((item) => (
                      <li
                        className="d-flex flex-row gap-3 justify-content-center position-relative"
                        style={styles.detectionItem}
                      >
                        <span className="dateLabel" style={{ left: 0, top: 0 }}>
                          {item[0][0]}
                        </span>

                        <div className="d-flex col-12 gap-3 flex-column px-3">
                          {item[0][1].map((item) => (
                            <Link style={{ textDecoration: 'none', color: globalStyles.black }} to={`/ueba/summary/${item?._source?.group_id}`} state={{ data: item }}>
                              <p className="timeline-item p-3 rounded-3 cursor-pointer" onClick={() => handleOpenModal(item, true)}>
                                {/* {escapeHtmlChars(item._source.description, /(<\/?b>)/g)} */}
                                {rawHTML(item._source.description)}
                              </p>
                            </Link>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                    <NoDataFound errorText={"No data found"} />
                  </div>
                )}

              </>
            )}
          </div>
        </div>
        <div>{recentDetections[0]}</div>
      </div>

      {show && (
        <CustomModal
          title={"Detection summary"}
          ShowOrHide={show}
          bodyData={renderModal}
          handleClose={handleCloseModal}
          footer={false}
          noBodyPadding
          hasStickyTab
          modalSize={'xl'}
        ></CustomModal>
      )}
    </>
  );
};

export default Dashboard;
