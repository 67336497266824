import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "./RuleStudio.styles";
import Hunter from "./Hunter/Hunter";
import Investigation from "./Investigation/Investigation";
import Compliance from "./Compliance/Compliance";
import Ueba from "./Ueba/Ueba";
import Correlation from "./Correlation/Correlation";
import InvDetailModal from "../../components/RuleStudio/Investigation/InvDetailModal";
import HuntDetail from "../../components/RuleStudio/Hunter/Custom/HuntDetail";
import CompDetailModal from "../../components/RuleStudio/Compliance/Custom/CompDetailModal";

const RuleHome = () => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState("7 Days");
  const [title, setTitle] = useState("");
  const [breadcrumb, setBreadcrumb] = useState();
  const [dateFilter, setDateFilter] = useState();

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  useEffect(() => {
    if (currentPath === "investigation") {
      setTitle("Orchestrator- Investigation")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" }, { "title": "Investigate", "url": "/rs/investigation" },]
      });
    }
    else if (currentPath === "summary") {
      setTitle("Summary")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" },{ "title": "Investigate", "url": "/rs/investigation" }, { "title": "Summary", "url": "/rs/summary" }]
      });
    }
    else if (currentPath === "hunter") {
      setTitle("Orchestrator - Hunter")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" }, { "title": "Hunter", "url": "/rs/hunter" },]
      });
    }
    else if (currentPath === "huntsummary") {
      setTitle("Hunt_Summary")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" },{ "title": "Hunter", "url": "/rs/hunter" }, { "title": "Hunt_Summary", "url": "/rs/huntsummary" }]
      });
    }
    else if (currentPath === "compliance") {
      setTitle("Orchestrator - Compliance")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" }, { "title": "Compliance", "url": "/rs/compliance" },]
      });
    }
    else if (currentPath === "compsummary") {
      setTitle("Summary")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/investigation" },{ "title": "Compliance", "url": "/rs/compliance" }, { "title": "Summary", "url": "/rs/compsummary" }]
      });
    }
    else if (currentPath === "rsueba") {
      setTitle("Orchestrator - UEBA")
      setBreadcrumb({
        "module": "rule_studio",
        "path": [{ "title": "Orchestrator", "url": "/rs/rsueba" }, { "title": "UEBA", "url": "/rs/rsueba" },]
      });
    }
  }, [currentPath]);

  return (
    <div className="col-12 d-flex" style={styles.root1}>
      <Sidebar module={"rs"} />
      <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
        <Topbar
          title={title}
          breadcrumb={breadcrumb}
          currentUrl={currentUrl}
          selectedDateFilter={selectedDateFilter}
          setSelectedDateFilter={setSelectedDateFilter}
          setDateFilter={setDateFilter}
        />
        <div className="col-12 d-flex" style={styles.main}>
          {currentPath === "investigation" ? (
            <Investigation />
          ) : currentPath === "hunter" ? (
            <Hunter />
          ) : currentPath === "compliance" ? (
            <Compliance />
          ) : currentPath === "rsueba" ? (
            <Ueba />
          ) : currentPath === "rscorrelation" ? (
            <Correlation />
          ) : currentPath === "summary" ? (
            <InvDetailModal /> 
          ): currentPath === "huntsummary" ? (
            <HuntDetail /> 
          ):  currentPath === "compsummary" ? (
            <CompDetailModal /> 
          ):  null}
        </div>
      </div>
    </div>
  );
};

export default RuleHome;