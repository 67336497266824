import { Binoculars, ShieldWarning } from "phosphor-react";
import React, { useEffect, useState } from "react";
import "../../../index.css";
import RecentTab from "../../../components/RecentTab/RecentTab";
import {
  mostAnomalusDetails,
  getTotalInvestigationCouts,
  getSeverityCounts,
  getGroupedInvestigationCounts,
  getViewDashboardDataTID,
  getInvestigation,
  getL2SeverityCounts,
} from "../../../Methods/InvestigationMethods";
import globalStyles from "../../../constants/globalStyles";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  rawHTML,
} from "../../../Utils/Utils";
import styles from "./Dashboard.styles";
import { TimelineLoader } from "../../../components/Loader/TimelineLoader";
import RepetitionDashboard from "../../../components/DetailsPage/RepetitionDashboard";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { getHunterSelectList } from "../../../Methods/RuleStudioMethods";
import axios from "../../../axios";
import { SmallCardLoader } from "../../../components/Loader/CardLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import { Link, useNavigate } from "react-router-dom";
import { fpostC } from "../../../axios/axios";
import { Badge } from "react-bootstrap";

const Dashboard = ({ selectedDateFilter }) => {

  let baseURL = localStorage.getItem("CYBOT_URL")

  // loader
  const [recentUserLoader, setRecentUserLoader] = useState(true)
  const [recentHostLoader, setRecentHostLoader] = useState(true)


  // count loading
  const [lowCountLoading, setlowCountLoading] = useState(true);
  const [mediumCountLoading, setmediumCountLoading] = useState(true);
  const [highCountLoading, sethighCountLoading] = useState(true);
  const [criticalCountLoading, setcriticalCountLoading] = useState(true);

  const [severityCounts, setSeverityCounts] = useState([]);
  const [l2SeverityCounts, setL2SeverityCounts] = useState([]);
  const [l2SeverityCountsLoader, setL2SeverityCountsLoader] = useState(true);

  const [totalInvestigationCount, setTotalInvestigationCount] = useState();
  const [groupedInvestigationCount, setGroupedInvestigationCount] = useState();

  const [mostAnomalouseUsersList, setMostAnomalouseUsersList] = useState([]);
  const [mostAnomalouseHostList, setMostAnomalouseHostList] = useState([]);

  // modal
  const [detailpage, setDetailpage] = useState([]);
  const [showRepetition, setShowRepetition] = useState(false);


  const [timelineIsLoading, setTimelineIsLoading] = useState(true);

  const [investigateData, setInvestigateData] = useState([]);
  const recentInvestigationTimeline = investigateData.map((item) => {
    return Object.entries(item);
  });
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  // new dashboard counts 
  const [threadsData, setThreadsData] = useState([])
  const [threadsDataMaster, setThreadsDataMaster] = useState([])

  const [tenandId, setTenandId] = useState([])

  useEffect(() => {
    let data = {
      "query": {
        "match_all": {}
      }
    }
    fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
      var licence = []
      hits?.map((item) => {
        licence.push(item?._source.tenant_id)
      })
      setTenandId(licence[0])
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])

  useEffect(() => {
    setRecentUserLoader(true)
    setRecentHostLoader(true)
    setTimelineIsLoading(true)

    setlowCountLoading(true)
    setmediumCountLoading(true)
    sethighCountLoading(true)
    setcriticalCountLoading(true)

    setL2SeverityCountsLoader(true)


    axios.get(`${apiBaseUrl}/investigate/catalogue?type=tenant`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("auth"),
      },
    }).then(({ data }) => {

      let counts = []

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        // If the column value exists, increment the count
        if (item.log && item.log[0]) {
          const columnName = item.log[0];
          counts[columnName] = counts[columnName] || {
            name: columnName,
            count: 0,
          };
          counts[columnName].count++;
        }
      }
      let dist = Object.values(counts)

      setThreadsData(dist)

    }).catch((err) => {
      console.log("Error", err);
    })

    // master 
    axios.get(`${apiBaseUrl}/investigate/catalogue?type=master`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("auth"),
      },
    }).then(({ data }) => {
      let counts = []

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        // If the column value exists, increment the count
        if (item.log && item.log[0]) {
          const columnName = item.log[0];
          counts[columnName] = counts[columnName] || {
            name: columnName,
            count: 0,
          };
          counts[columnName].count++;
        }
      }
      let dist = Object.values(counts)

      setThreadsDataMaster(dist)
    })


    getTotalInvestigationCouts()
      .then(
        ({
          data: {
            hits: { total },
          },
        }) => {
          total && setTotalInvestigationCount(total.value);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    getSeverityCounts()
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {
          let severity = {
            low: 0,
            medium: 0,
            high: 0,
            critical: 0,
          };

          buckets.map((item) => {
            if (item.key === "Low") {
              severity.low = item.doc_count;
            } else if (item.key === "Medium") {
              severity.medium = item.doc_count;
            } else if (item.key === "High") {
              severity.high = item.doc_count;
            } else {
              severity.critical = item.doc_count;
            }
          });

          setSeverityCounts(severity);
          setlowCountLoading(false)
          setmediumCountLoading(false)
          sethighCountLoading(false)
          setcriticalCountLoading(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    // L2 severity counts

    getL2SeverityCounts().then((res) => {
      let data = res?.data?.aggregations?.[2].buckets

      let severity = {
        low: 0,
        medium: 0,
        high: 0,
        critical: 0,
      };

      data.map((item) => {
        if (item.key === "Low") {
          severity.low = item.doc_count;
        } else if (item.key === "Medium") {
          severity.medium = item.doc_count;
        } else if (item.key === "High") {
          severity.high = item.doc_count;
        } else {
          severity.critical = item.doc_count;
        }
      });


      setL2SeverityCounts(severity)
      setL2SeverityCountsLoader(false)

    }).catch((err) => {
      console.log("Error", err)
      setL2SeverityCountsLoader(false)
    })

    getGroupedInvestigationCounts()
      .then(
        ({
          data: {
            hits: { total },
          },
        }) => {
          total && setGroupedInvestigationCount(total.value);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });

    mostAnomalusDetails("user", 5)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {
          buckets && setMostAnomalouseUsersList(buckets);
          setRecentUserLoader(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setRecentUserLoader(false)
      });

    mostAnomalusDetails("host", 5)
      .then(
        ({
          data: {
            aggregations: {
              2: { buckets },
            },
          },
        }) => {
          buckets && setMostAnomalouseHostList(buckets);
          setRecentHostLoader(false)
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setRecentHostLoader(false)
      });

    getViewDashboardDataTID()
      .then((res) => {
        console.log("response :", res);
      })
      .catch((err) => {
        console.log("failed :", err);
      });

    // recent investigation details

    getInvestigation(tenandId, 50, "desc", 0, false)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          hits.map((item) => {
            item._source.detection_timestamp = getDateFromTimestamp(
              item._source.detection_timestamp
            );
          });
          let groups = Object.create(null);
          let result;

          hits.forEach((element) => {
            groups[element._source.detection_timestamp] =
              groups[element._source.detection_timestamp] || [];
            groups[element._source.detection_timestamp].push(element);
          });

          result = Object.keys(groups).map((k) => {
            let temp = {};
            temp[k] = groups[k];
            return temp;
          });
          setInvestigateData(result);
          setTimelineIsLoading(false);
        }
      )
      .catch((err) => {
        console.log("Error", err);
      });
  }, [selectedDateFilter, tenandId]);

  const [view, setView] = useState('')

  const handleOpenRepetitionModal = (data, display, isPage) => {
    setDetailpage(data);
    setShowRepetition(display);
    setView(isPage === "grouped" && true)
  };

  function handleCloseModal() {
    setShowRepetition(false);
  }

  function renderRepetitionModal() {
    return <RepetitionDashboard details={detailpage} view={view} />;
  }
  const navigate = useNavigate();

  return (
    <>
      <div
        className="col-8 d-flex flex-column gap-3 overflow-auto"
        style={styles.section1}
      >
        <div className="col-12 p-3 rounded-3 bg-white">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h5>L1 Detection Summary</h5>
            {!lowCountLoading &&
              <Badge className="ml-2" bg="info">
                {severityCounts.low + severityCounts.medium + severityCounts.high + severityCounts.critical}
              </Badge>
            }
          </div>

          <div className="d-flex flex-row gap-3">
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileGreen }}
              onClick={() => { navigate(`/investigate/cybot_soc?severity=Low&active=Detections&date=${selectedDateFilter}`) }}

            >
              {lowCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconGreen }}
                  >
                    <Binoculars size={24} />
                  </div>
                  <div>
                    <h4>{severityCounts.low}</h4>
                    <span>Low</span>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileYellow }}
              onClick={() => { navigate(`/investigate/cybot_soc?severity=Medium&active=Detections&date=${selectedDateFilter}`) }}

            >
              {mediumCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconYellow }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{severityCounts.medium}</h4>
                    <span>Medium</span>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileOrange }}
              onClick={() => { navigate(`/investigate/cybot_soc?severity=High&active=Detections&date=${selectedDateFilter}`) }}

            >
              {highCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconOrange }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{severityCounts.high}</h4>
                    <span>High</span>
                  </div>
                </>
              )}

            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileRed }}
              onClick={() => { navigate(`/investigate/cybot_soc?severity=Critical&active=Detections&date=${selectedDateFilter}`) }}

            >
              {criticalCountLoading ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconRed }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{severityCounts.critical}</h4>
                    <span>Critical</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* L2 counts */}


        <div className="col-12 p-3 rounded-3 bg-white">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h5>L2 Incidents Summary</h5>
            {!l2SeverityCountsLoader &&
              <Badge className="ml-2" bg="info">
                {l2SeverityCounts.low + l2SeverityCounts.medium + l2SeverityCounts.high + l2SeverityCounts.critical}
              </Badge>
            }
          </div>
          <div className="d-flex flex-row gap-3">
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileGreen }}
              onClick={() => {
                navigate(`/investigate/cybot_soc?severity=Low&active=Incidents&date=${selectedDateFilter}`)
              }}
            >
              {l2SeverityCountsLoader ? (<SmallCardLoader />) : (
                < >
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconGreen }}
                  >
                    <Binoculars size={24} />
                  </div>
                  <div>
                    <h4>{l2SeverityCounts.low}</h4>
                    <span>Low</span>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileYellow }}
              onClick={() => {
                navigate(`/investigate/cybot_soc?severity=Medium&active=Incidents&date=${selectedDateFilter}`)
              }}
            >
              {l2SeverityCountsLoader ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconYellow }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{l2SeverityCounts.medium}</h4>
                    <span>Medium</span>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileOrange }}
              onClick={() => {
                navigate(`/investigate/cybot_soc?severity=High&active=Incidents&date=${selectedDateFilter}`)
              }}

            >
              {l2SeverityCountsLoader ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconOrange }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{l2SeverityCounts.high}</h4>
                    <span>High</span>
                  </div>
                </>
              )}

            </div>
            <div
              className="w-25 rounded-3 p-3 cursor-pointer"
              style={{ ...styles.overviewTile, ...styles.overviewTileRed }}
              onClick={() => {
                navigate(`/investigate/cybot_soc?severity=Critical&active=Incidents&date=${selectedDateFilter}`)
              }}
            >
              {l2SeverityCountsLoader ? (<SmallCardLoader />) : (
                <>
                  <div
                    style={{ ...styles.overviewIcon, ...styles.overviewIconRed }}
                  >
                    <ShieldWarning size={24} />
                  </div>
                  <div>
                    <h4>{l2SeverityCounts.critical}</h4>
                    <span>Critical</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 gap-3 d-flex flex-row">
          <RecentTab
            tabName={"Hosts"}
            mostAnomalouseList={mostAnomalouseHostList}
            type={"inv"}
            selectedField={'host'}
            FieldLabel={'Host'}
            loader={recentHostLoader}


          />
          <RecentTab
            tabName={"Users"}
            mostAnomalouseList={mostAnomalouseUsersList}
            type={"inv"}
            selectedField={'user'}
            FieldLabel={'User'}
            loader={recentUserLoader}
          />
        </div>


        <div className="col-12 gap-3 d-flex flex-column">
          <div className="p-4 rounded-3 bg-white col-12">
            <h5>Threats Being Monitored Actively</h5>
            <p>The CYBOT™ Investigate module actively detects threats pop your organisation utilising the logs forwarded to the platform. It first detects and then investigates the threat like a human analyst and summarises the investigation report. Below is the number of use-cases or threats being monitored and investigated on the platform</p>
            <div className="col-12 d-flex flex-row gap-3">
              {threadsData?.map((item) => (
                <div className="flex-fill rounded-3 p-3" style={{ ...styles.overviewTile, ...styles.purpleLite1 }} key={item.name + "_" + item.count}>
                  <div>
                    <h4>{item.count}</h4>
                    <span>{item.name === "edr" ? "Endpoint" : item.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="p-4 rounded-3 bg-white col-12">
            <h5 className="mb-2">Master Catalogue</h5>
            <div className="col-12 d-flex flex-row gap-3">
              {threadsDataMaster?.map((item) => (
                <div className="flex-fill rounded-3 p-3" style={{ ...styles.overviewTile, ...styles.purpleLite1 }}>
                  <div>
                    <h4>{item.count}</h4>
                    <span>{item.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>

      </div>
      <div className="p-4 rounded-3" style={styles.section2}>
        <div>
          <h5>Recent Incidents</h5>
          <p>
            Recent abnormal events are grouped based on the anomalous behavior
            pattern of the entity
          </p>

          <div
            className="d-flex col-12 position-relative"
            style={styles.recentDetectionsWrap}
          >
            {timelineIsLoading ? (
              <div className="col-12">
                <TimelineLoader />
              </div>
            ) : (
              <>
                {investigateData.length || investigateData.length !== 0 ? (
                  <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                    {recentInvestigationTimeline.map((item) => (
                      <li
                        className="d-flex flex-row gap-3 justify-content-center position-relative"
                        style={styles.detectionItem}

                      >
                        <span className="dateLabel" style={{ left: 0, top: 0 }}>
                          {getDateFromTimestamp(item[0][0])}
                        </span>
                        <div className="d-flex col-12 gap-3 flex-column px-3">
                          {item[0][1].map((item) => (
                            <>
                              <Link style={{ textDecoration: 'none', color: globalStyles.black }} to={`/investigate/grouped_investigation/${item?._source?.grp_id}`}>
                                <p className="timeline-item p-3 rounded-3 cursor-pointer" onClick={() =>
                                  handleOpenRepetitionModal(item, true, "grouped")
                                }>
                                  {/* {escapeHtmlChars(item._source.description, /(<\/?b>)/g)} */}
                                  {rawHTML(item._source.description)}
                                </p>
                              </Link>
                            </>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                    <NoDataFound errorText={"No data found"} />
                  </div>
                )}

              </>
            )}
          </div>
        </div>
      </div>


      {showRepetition && (
        <CustomModal
          // title={"Detection summary"}
          ShowOrHide={showRepetition}
          bodyData={renderRepetitionModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize={"xl"}
          noBodyPadding={true}
          overflow={false}
        ></CustomModal>
      )}
    </>
  );
};

export default Dashboard;
