import React, { useCallback, useEffect, useState } from "react";
import styles from "../Activities.styles";
import { getAllActivities, getProcessList } from "../../../../Methods/UebaMethods";
import {
    Form,
    Tab,
    Tabs,
    Table,
    Button,
    Tooltip,
    OverlayTrigger,
    Badge,
    Dropdown,
    DropdownButton,
} from "react-bootstrap";
import DataTable from "../../../../components/Investigation/DataTable";
import Pagination from "../../../../components/Pagination/Pagination";
import TableLoader from "../../../../components/Loader/TableLoader";

import AddFilter from "../../../../components/AddFilter/AddFilter";
import FilterList from "../../../../components/FilterList/FilterList";
import InlineFilter from "../../../../components/InlineFilter/InlineFilter";
import { fpostCy } from "../../../../axios/axios";
import useOnclickOutside from "react-cool-onclickoutside";
import {
    getDateFromTimestamp,
    getProcessListQuery,
    getTimeFromTimestamp,
    numberToLetter,
    rawHTML,
} from "../../../../Utils/Utils";
import SideBarCommon from "../../../../components/Cykit/SideBarCommon";
import CykitExport from "../../../../components/Cykit/Export";
import { Link } from "react-router-dom";
import { Graph, LineSegments, ArrowsOutSimple } from "phosphor-react";
import NoDataFound from "../../../../components/NoDataFount/NoDataFound";
import { TimelineLoader } from "../../../../components/Loader/TimelineLoader";
import globalStyles from "../../../../constants/globalStyles";
import TableViewMore from "../../../../components/ViewMoreBtn/TableViewMore";
import moment from "moment";
let indexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
let edr = localStorage.getItem("EDR_TYPE");

const Authentication = ({ selectedDateFilter, globalSearch, authenticationTotalPageCount, setAuthenticationTotalPageCount, isAnomaly, setIsAnomaly, anomaliFilter, setAnomaliFilter, dateFilter, setDateFilter, init }) => {
    const [activities, setActivities] = useState([]);

    // anomalies filter
    const [showList, setShowList] = useState(false);
    // const [anomaliFilter, setAnomaliFilter] = useState("");

    const [processTableData, setProcessTableData] = useState([]);
    const [fileTableData, setFileTableData] = useState([]);
    const [networkTableData, setNetworkTableData] = useState([]);
    const [registryTableData, setRegistryTableData] = useState([]);
    const [activitiesData, setActivitiesData] = useState([]);
    const [authenticationData, setAuthenticationData] = useState([]);

    const [processLoading, setProcessLoading] = useState(true);
    const [networkLoading, setNetworkLoading] = useState(true);
    const [fileLoading, setFileLoading] = useState(true);
    const [registryLoading, setRegistryLoading] = useState(true);
    const [activitiesLoading, setActivitiesLoading] = useState(true);
    const [authenticationLoading, setAuthenticationLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [processTotalPageCount, setProcessTotalPageCount] = useState();

    const [networkPage, setNetworkPage] = useState(1);
    const [networkPerPage, setNetworkPerPage] = useState(100);
    const [networkcurrentPageCount, setNetworkCurrentPageCount] =
        useState(networkPerPage);
    const [networkTotalPageCount, setNetworkTotalPageCount] = useState();

    const [filePage, setFilePage] = useState(1);
    const [filePerPage, setFilePerPage] = useState(100);
    const [filecurrentPageCount, setFileCurrentPageCount] = useState(filePerPage);
    const [fileTotalPageCount, setFileTotalPageCount] = useState();

    const [registeryPage, setRegisteryPage] = useState(1);
    const [registeryPerPage, setRegisteryPerPage] = useState(100);
    const [registerycurrentPageCount, setRegisteryCurrentPageCount] =
        useState(registeryPerPage);
    const [registeryTotalPageCount, setRegisteryTotalPageCount] = useState();

    const [activitiesPage, setActivitiesPage] = useState(1);
    const [activitiesPerPage, setActivitiesPerPage] = useState(100);
    const [activitiescurrentPageCount, setActivitiesCurrentPageCount] =
        useState(activitiesPerPage);
    const [activitiesTotalPageCount, setActivitiesTotalPageCount] = useState();

    const [authenticationPage, setAuthenticationPage] = useState(1);
    const [authenticationPerPage, setAuthenticationPerPage] = useState(100);
    const [authenticationcurrentPageCount, setAuthenticationCurrentPageCount] =
        useState(authenticationPerPage);


    const [activeTab, setActiveTab] = useState("process");
    // states for add filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);

    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    const [expclickedNode, setExpClickedNode] = useState("");
    const [expclose, setExpClose] = useState(false);
    const [filterStore, setFilterStore] = useState(true);

    const activitiesTimeline = activitiesData.map((item) => {
        return Object.entries(item);
    });

    const [doc,setDoc] = useState(null)
    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
    });

    // for filter
    var count = [];
    var count2;
    const module = "hunter";
    const tab = "cykit-edr-auth";
    useEffect(() => {
        if (dateFilter !== undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setAuthenticationPage(1)
            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], date: dateFilter } },
            };

            localStorage.setItem("filters", JSON.stringify(temp2));
        }
        if (dateFilter === undefined) {
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            if (!(storedFilter?.[module]?.[tab]?.date?.to) && !(storedFilter?.[module]?.[tab]?.date?.from)) {
                // let start = moment().subtract(1, "hours")?.format("yyyy-MM-DDTHH:mm");
                // let end = moment().format("yyyy-MM-DDTHH:mm");
                // let dateTemp = {
                //     from: start,
                //     to: end,
                //     f: start,
                //     e: end,
                //     range: 60,
                // };
                // setDateFilter(dateTemp)

                // let temp2 = {
                //     ...storedFilter,
                //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab],date:dateTemp } },
                // };
                // localStorage.setItem("filters", JSON.stringify(temp2));
            }
        }
    }, [dateFilter])
    useEffect(() => {
        if (globalSearch === '' && init === true) {
            console.log()
        }
        else if (globalSearch === '' && init === false) {
            console.log(filter)
            var newfilter = filter.filter(item => item?.isGlobal != true)

            setFilter(
                newfilter
            )
            setAuthenticationPage(1)

            let storedFilter = JSON.parse(localStorage.getItem("filters"));

            // let temp2 = {
            //     ...storedFilter,
            //     [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter] } },
            //   };

            //   localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable(newfilter, dateFilter)
        }
        else {
            var filterNew = [{
                "column": {
                    "label": "Raw Search",
                    "value": "raw.global",
                    "isWildCard": null,
                    "isNumber": null
                },
                "type": "is",
                "selectedValue": globalSearch,
                "from": "",
                "to": "",
                "isInline": false,
                "isGlobal": true
            }]
            var newfilter = filter.filter(item => item?.isGlobal != true)
            setFilter((filter) => [...newfilter, ...filterNew]
            )
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            setAuthenticationPage(1)

            let temp2 = {
                ...storedFilter,
                [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...newfilter,...filterNew] } },
              };

              localStorage.setItem("filters", JSON.stringify(temp2));
            getFilteredTable([...newfilter, ...filterNew], dateFilter)
        }
    }, [globalSearch])
    useEffect(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields;
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date;
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter);
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to;
            setDateFilter(currentPageDateFilter);
            if (currentPageFilter !== undefined) {
                setFilter(currentPageFilter);
            }
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    useEffect(() => {
        // getAllActivities(20).then(({ data: { hits: { hits } } }) => {
        //   hits && setActivities(hits)
        // }).catch((err) => {
        //   console.log("Error", err)
        // })

        if (!count?.length && !count2 && !(globalSearch?.length>0 && init === true)) {
            getFilteredTable(filter, dateFilter);
        }
    }, [
        authenticationPage,
        isAnomaly,
        dateFilter
    ]);

    async function getProcessList2(filter, date, type, page, size, is_anomaly) {
        let index_type;
        if (edr === "atp") {
            if (type === "process") {
                index_type = "AdvancedHunting-DeviceProcessEvents";
            } else if (type === "file") {
                index_type = "AdvancedHunting-DeviceFileEvents";
            } else if (type === "network") {
                index_type = "AdvancedHunting-DeviceNetworkEvents";
            } else if (type === "registry") {
                index_type = "AdvancedHunting-DeviceRegistryEvents";
            } else if (type === "authentication") {
                index_type = "AdvancedHunting-DeviceLogonEvents";
            } else {
                index_type = "";
            }
        } else if (edr === "elastic") {
            if (type === "process") {
                index_type = "process";
            } else if (type === "file") {
                index_type = "file";
            } else if (type === "network") {
                index_type = "network";
            } else if (type === "registry") {
                index_type = "registry";
            } else if (type === "authentication") {
                index_type = "authentication";
            } else {
                index_type = "";
            }
        }

        let data = getProcessListQuery(
            filter,
            date,
            size,
            "desc",
            page,
            index_type,
            is_anomaly
        );

        return await fpostCy(
            `${localStorage.getItem("DATALAKE_URL")}/${indexPattern}`,
            data
        );
    }

    const getFilteredTable = useCallback(
        async (filter, date) => {
            // setPage(1)
            // setNetworkPage(1)
            // setFilePage(1)
            // setRegisteryPage(1)
            if (filter || date?.to) {
                // setCurrentPageCount((page - 1) * perPage + perPage);

                setAuthenticationLoading(true);
                await getProcessList2(
                    filter,
                    date,
                    "authentication",
                    (authenticationPage - 1) * authenticationPerPage,
                    100,
                    isAnomaly
                )
                    .then(({ data: { hits } }) => {
                        setAuthenticationTotalPageCount(hits.total.value);
                        const authentication = [];
                        hits.hits.map((item) => {
                            if (edr === "atp") {
                                var authenticationData = {
                                    event_outcome: item._source.registry?.key,
                                    process_name: item._source.process?.name,
                                    timestamp: item._source["@timestamp"],
                                    object_type: "authentication",
                                    event_action: item._source.event?.action,
                                    os: item._source.host.os?.full,
                                    host_name: item._source.host?.name,
                                    id: item._id,
                                    user_name: item._source.user?.name,
                                    user_domain: item._source.user?.domain,
                                    isDetected: item?._source?.Result,
                                    obj:item

                                };
                            } else if (edr === "elastic") {
                                var authenticationData = {
                                    event_outcome: item._source.event?.outcome,
                                    process_name: item._source.process?.name,
                                    timestamp: item._source["@timestamp"],
                                    object_type: "authentication",
                                    event_action: item._source.event?.action,
                                    os: item._source.host.os?.full,
                                    host_name: item._source.host?.name,
                                    id: item._id,
                                    user_name: item._source.user?.name,
                                    user_domain: item._source.user?.domain,
                                    isDetected: item?._source?.Result,
                                    obj:item
                                };
                            }
                            authentication.push(authenticationData);
                        });
                        setAuthenticationData(authentication);
                        setAuthenticationLoading(false);
                    })
                    .catch((err) => {
                        setAuthenticationData([]);
                        setAuthenticationTotalPageCount(0)
                        setAuthenticationLoading(false);
                        console.log("Error", err);
                    });
            } else {

                setAuthenticationLoading(true);


                await getProcessList(
                    "authentication",
                    "",
                    (authenticationPage - 1) * authenticationPerPage,
                    100,
                    "",
                    "",
                    "",
                    "",
                    isAnomaly
                )
                    .then(({ data: { hits } }) => {
                        setAuthenticationTotalPageCount(hits.total.value);
                        const authentication = [];
                        hits.hits.map((item) => {
                            if (edr === "atp") {
                                var authenticationData = {
                                    event_outcome: item._source.event?.outcome,
                                    process_name: item._source?.process?.name,
                                    timestamp: item._source["@timestamp"],
                                    object_type: "authentication",
                                    event_action: item._source?.event?.action,
                                    os: item._source?.host?.os?.full,
                                    host_name: item._source?.host?.name,
                                    id: item._id,
                                    user_name: item._source?.user?.name,
                                    user_domain: item._source?.user?.domain,
                                    isDetected: item?._source?.Result,
                                    obj:item

                                };
                            } else if (edr === "elastic") {
                                var authenticationData = {
                                    event_outcome: item._source?.event?.outcome,
                                    process_name: item._source?.process?.name,
                                    timestamp: item._source["@timestamp"],
                                    object_type: "authentication",
                                    event_action: item._source?.event?.action,
                                    os: item._source?.host?.os?.full,
                                    host_name: item._source?.host?.name,
                                    id: item._id,
                                    user_name: item._source?.user?.name,
                                    user_domain: item._source?.user?.domain,
                                    isDetected: item?._source?.Result,
                                    obj:item

                                };
                            }
                            authentication.push(authenticationData);
                        });
                        setAuthenticationData(authentication);
                        setAuthenticationLoading(false);
                    })
                    .catch((err) => {
                        setAuthenticationData([]);
                        setAuthenticationTotalPageCount(0)
                        setAuthenticationLoading(false);
                        console.log("Error", err);
                    });
            }
        },
        [
            dateFilter,
            filter,
            page,
            perPage,
            networkPage,
            networkPerPage,
            filePage,
            filePerPage,
            registeryPage,
            registeryPerPage,
            activitiesPage,
            activitiesPerPage,
            authenticationPage,
            authenticationPerPage,
            isAnomaly,
        ]
    );

    // useEffect(()=>{

    //     if(localStorage.getItem("isAnomalies") === "Anomalies"){
    //         setIsAnomaly(true);
    //         setAnomaliFilter("Anomalies");
    //     } else {
    //         setIsAnomaly(false);
    //         setAnomaliFilter("");
    //     }

    // },[anomaliFilter])

    const handleSelect = (e) => {
        if (e === "Anomalies") {
            setAuthenticationPage(1)
            setIsAnomaly(true);
            setAnomaliFilter("Anomalies");
            // localStorage.setItem("isAnomalies", "Anomalies")
        } else {
            setAuthenticationPage(1)
            setIsAnomaly(false);
            setAnomaliFilter("");
            // localStorage.setItem("isAnomalies", "")
        }
    };

    const anomaliesTooltip = (
        <Tooltip placement="bottom" id="anomalies-tooltip">
          <div className="text-justify ms-2">Unusual Occurrences in Activities</div>
          {/* Add content for the tooltip */}
        </Tooltip>
      );
      


    return (
        <>
            <div
                className="col-12 position-relative rounded-3 w-100"
                style={styles.section1}
            >
                <div
                    className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                    style={{
                        position: "absolute",
                        right: "0",
                        zIndex: 3,
                        top: "-48px",

                    }}
                >
                    {/* <div className="multipleToggleButtonWrapp">
            <div className="col-4 active">All</div>
            <div className="col-4">Users</div>
            <div className="col-4">Hosts</div>
          </div>

          <div className="d-flex flex-row align-items-center col-3 mx-3">
            <Form.Select>
              <option>50 items</option>
              <option>100 items</option>
              <option>150 items</option>
            </Form.Select>
          </div>

          <Form.Control type="text" placeholder="Search..." className="mx-0" /> */}

                    <div className="position-relative d-flex">
                        {/* <div>
              <Select
                isMulti
                options={options}
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            </div> */}

                        <div
                            className="mx-2"
                            onMouseOver={() => setShowList(true)}
                            onMouseOut={() => setShowList(false)}
                            style={{ zIndex: 0 }}
                            type="button"
                        >
                            <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 ">
                                {/* <span
                  style={{
                    borderRadius: "50%",
                    backgroundColor: dotColor,
                    height: "7px",
                    width: "7px",
                  }}
                /> */}
                                <span>
                                    View -{" "}
                                    <strong>{anomaliFilter ? anomaliFilter : "ALL"}</strong>
                                </span>
                            </div>
                            {showList && (
                                <div className="pt-3 position-absolute" style={{ zIndex: 2 }}>
                                    <div className="col-12 d-flex flex-column gap-2 bg-white rounded-3 shadow-sm p-3 px-4">
                                        <div className="d-flex flex-row align-items-center gap-2">
                                            <span
                                                style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: globalStyles.greyDark3,
                                                    height: "7px",
                                                    width: "7px",
                                                }}
                                            />
                                            <span className="" onClick={() => handleSelect("")}>
                                                All
                                            </span>
                                        </div>
                                        <div className="d-flex flex-row align-items-center gap-2">
        <OverlayTrigger placement="right" overlay={anomaliesTooltip}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: 'red', // Change this to your desired color
              height: '7px',
              width: '7px',
              marginRight: '5px', // Adjust the spacing if needed
            }}
          />
          <span onClick={() => handleSelect('Anomalies')}>Anomalies</span>
        </div>
      </OverlayTrigger>
    </div>        </div>
                                </div>
                            )}
                        </div>

                        {/* <div className="sticky-toolbar mx-2">
              <DropdownButton
                className="rounded-3"
                style={{ backgroundColor: "#F6F7FB" }}
                // title={selectedValue ? selectedValue : "All Hunt"}
                title={"All"}
                variant=""
                onSelect={handleSelect}
              >
                <Dropdown.Item eventKey="all">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.green,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>All</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="anomalies">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span
                      style={{
                        borderRadius: "50%",
                        backgroundColor: globalStyles.red,
                        height: "7px",
                        width: "7px",
                      }}
                    />
                    <span>Anomalies</span>
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div> */}

                        <Button
                            // className="position-relative"
                            ref={ref}
                            onClick={() => setShowAddFilter(!showAddFilter)}
                        >
                            Add filter
                        </Button>
                        {showAddFilter && (
                            <AddFilter
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                setShowAddFilter={setShowAddFilter}
                                getFilteredTable={getFilteredTable}
                                setPage={setAuthenticationPage}
                                columnValues={[
                                    { value: "host.name", label: "Device", isWildCard: true },
                                    { value: "event.action", label: "Event Type" },
                                    { value: "host.os.full", label: "OS", isWildCard: true },
                                    {
                                        value: "process.name",
                                        label: "Process Name",
                                        isWildCard: true,
                                    },
                                    { value: "user.name", label: "User Name", isWildCard: true },
                                    {
                                        value: "user.domain",
                                        label: "User Domain",
                                        isWildCard: true,
                                    },
                                    {
                                        value: "event.outcome",
                                        label: "Event Outcome",
                                        isWildCard: true,
                                    },

                                ]}
                                module={module}
                                tab={tab}
                            />
                        )}
                    </div>
                    <div className="position-relative ms-2">
                        <CykitExport
                            getProcessList2={getProcessList2}
                            type="authentication"
                            filter={filter}
                            dateFilter={dateFilter}
                            isAnomaly={isAnomaly}
                        />
                    </div>
                    <Pagination
                        page={authenticationPage}
                        setPage={setAuthenticationPage}
                        perPage={authenticationPerPage}
                        currentPageCount={authenticationcurrentPageCount}
                        totalDataCounts={authenticationTotalPageCount}
                        isRelative={true}
                    />
                </div>

                <div className="detections col-12">

                    <div style={styles.tableWrap}>
                        <FilterList
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            getFilteredTable={getFilteredTable}
                            module={module}
                            tab={tab}
                        />
                        {/* <Pagination
                page={registeryPage}
                setPage={setRegisteryPage}
                perPage={registeryPerPage}
                currentPageCount={registerycurrentPageCount}
                totalDataCounts={registeryTotalPageCount}
              />
              {registeryTotalPageCount} */}
                        {authenticationLoading ? (
                            <TableLoader rowCount={15} />
                        ) : authenticationData?.length !== 0 ? (
                            <Table borderless hover>
                                <thead className="fixed-head">
                                    <tr>
                                        <th className="auto-min-width"></th>
                                        <th>Device</th>
                                        <th>Event Type</th>
                                        <th>Event Time</th>
                                        <th>Vendor</th>
                                        <th>Process Name</th>
                                        <th>User Name</th>
                                        <th>User Domain</th>
                                        <th>Event Outcome</th>
                                        <th style={{ minWidth: "100px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {authenticationData?.map((item, index) => (
                                        // <tr>
                                        //   <td>{item.host_name}</td>
                                        //   <td>{item.event_action}</td>
                                        //   {/* <td>{item.object_type}</td> */}
                                        //   <td>{item.timestamp}</td>
                                        //   <td>{item.os}</td>
                                        //   <td>{item.process_name}</td>
                                        //   <td>{item.user_name}</td>
                                        //   <td>{item.user_domain}</td>
                                        //   <td>{item.reg_key}</td>
                                        //   <td>{item.reg_val_name}</td>
                                        // </tr>
                                        <tr
                                            onMouseOver={() =>
                                                setShowInlineFilter({ show: true, index: index })
                                            }
                                            onMouseOut={() =>
                                                setShowInlineFilter({ show: false, index: index })
                                            }
                                        >
                                            <td className="auto-min-width">
                                                <span
                                                    className="circle-status"
                                                    style={{
                                                        backgroundColor:
                                                            item?.isDetected === 1
                                                                ? globalStyles.green
                                                                : item?.isDetected === -1
                                                                    ? globalStyles.red
                                                                    : globalStyles.greyDark3,
                                                    }}
                                                />
                                            </td>
                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.host_name}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "host.name",
                                                                        label: "Host Name",
                                                                    }}
                                                                    value={item?.host_name}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>
                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.event_action}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "event.action",
                                                                        label: "Event type",
                                                                    }}
                                                                    value={item?.event_action}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>
                                            {/* <td>{item.object_type}</td> */}
                                            <td className="font-monospace col-1">
                                                <div className="d-flex flex-column">
                                                    <span>
                                                        {getDateFromTimestamp(item?.timestamp)}
                                                    </span>
                                                    <span>
                                                        {getTimeFromTimestamp(item?.timestamp)}
                                                    </span>
                                                </div>
                                            </td>
                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.os}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "host.os.full",
                                                                        label: "OS Name",
                                                                    }}
                                                                    value={item?.os}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>
                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.process_name}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "process.name",
                                                                        label: "Process Name",
                                                                    }}
                                                                    value={item?.process_name}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>

                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.user_name}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "user.name",
                                                                        label: "User Name",
                                                                    }}
                                                                    value={item?.user_name}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>
                                            <td
                                            // style={{ display: "flex", flexDirection: "row" }}
                                            >
                                                <span>
                                                    {item?.user_domain}
                                                    {showInlineFilter.show &&
                                                        showInlineFilter.index === index && (
                                                            <div className="inline-filter-wrap">
                                                                <InlineFilter
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    column={{
                                                                        value: "user.domain",
                                                                        label: "User Domain",
                                                                    }}
                                                                    value={item?.user_domain}
                                                                    getFilteredTable={getFilteredTable}
                                                                    module={module}
                                                                    tab={tab}
                                                                    dateFilter={dateFilter}
                                                                    setPage={setAuthenticationPage}

                                                                />
                                                            </div>
                                                        )}
                                                </span>
                                            </td>
                                            <td className="col-3">
                                                <span className="d-block text-break">
                                                    {item?.event_outcome}
                                                </span>
                                            </td>
                                            <td>
                                                {/* <Link to={`/ueba/cygraph/register/${item.id}`}>
                                  <Graph style={{ color: "#666666" }} size={18} dataToggle={"Visualize"} dataPlacement={"bottom"} /> */}
                                                {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg> */}
                                                {/* </Link> */}
                                                <a
                                                    onClick={() => {
                                                        setExpClickedNode(item?.id);
                                                        setExpClose(false);
                                                        setDoc(item.obj)
                                                    }}
                                                    style={{ marginLeft: "20px" }}
                                                >
                                                    {/* <ArrowsOutSimple
                                                                size={18}
                                                                style={{ color: "#666666" }}
                                                            /> */}
                                                    <TableViewMore Icon={ArrowsOutSimple} dataToggle={"Expand document"} dataPlacement={"bottom"} />
                                                    {/* <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg> */}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                            >
                                <NoDataFound errorText={"No data found"} />
                            </div>
                        )}
                    </div>
                </div>
                <SideBarCommon
                    clickedNode={expclickedNode}
                    close={expclose}
                    setClick={setExpClickedNode}
                    doc={doc}
                    setDoc={setDoc}
                />
            </div>
        </>
    );
};

export default Authentication;
