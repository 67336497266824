import globalStyles from "../../constants/globalStyles";

const styles = {
  appSwitcherMenu: {
    backgroundColor: "#FFFFFF",
    right: 0,
    padding: "20px",
    // border: "1px solid #CCCCCC",
    width: "340px",
    zIndex: 24,
    justifyContent: "center",
  },
  menuItem: {
    // height: "76px",
    // width: "76px",
    height: "90px",
    width: "90px",
    // backgroundColor: "#f1f1f1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    textAlign: "center",
    color: "#000000",
    // fontSize: "12px",
  },
  dateSwitcherWrap: {
    backgroundColor: globalStyles.greyLite1,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dateSwitcher: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "#FFFFFF",
    right: 0,
    padding: "20px",
    zIndex: 11,
    width: "300px",
    // width: "150px",
  },
  profileMenu: {
    backgroundColor: "#FFFFFF",
    right: 0,
    padding: "20px",
    zIndex: 11,
    width: "160px"
  },
  tileImage: {
    width: "60%",
    marginBottom: "5px"
  },
};

export default styles;
