import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import styles from "./Cykit.styles";
import { Badge, Button, Form, Dropdown, Spinner } from "react-bootstrap";
import { ArrowRight, Check, DownloadSimple, Eye, Info, Warning, ArrowsOutSimple, Stop, Pause, Play } from "phosphor-react";
import XLfileConverter from "../../../components/XlTable/XLfileConverter";
import {
  getCommunityFChart,
  getAttributeFChart,
  getTableData,
  convertTimestamp,
  getIocs,
  scanEdr,
  scanAd,
  scanOffice,
  scanOtherSources,
} from "../../../Methods/TiMethods";
import TIExport from "../Export";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CommunityView from "../../../components/DetailsPage/CommunityView";
import {
  escapeHtmlChars,
  getDateFromTimestamp,
  getTimeFromTimestamp,
  splitJSONIntoGroupsAuto
} from "../../../Utils/Utils.js";
import PaginationTi from "../../../components/Pagination/PaginationTi";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import Severity from "../../../components/Severity/Severity";
import Search from "../../../components/Search/Search";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import Select from "react-select";
import LoaderBtn from "../../../components/LoaderBtn/LoaderBtn";
import ExpandedDocument from "../../../components/ExpandedDocument/ExpandedDocument";
import { fpostCy, fpostDl } from "../../../axios/axios";
import TICykitExport from "../../../components/Export/Ti/Cykit/TICykitExport"
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";

import axios from "axios";

const Cykit = ({ selectedDateFilter, globalSearch, setGlobalSearch, searchValCom }) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState('')
  const [filter, setFilter] = useState(1)



  // pagination

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [pageCount, setPageCount] = useState(perPage);
  const [sevFilterData, setSevFilterData] = useState();
  const [query, setQuery] = useState("");
  const [iocType, setIocType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [scanFrom, setScanFrom] = useState("");
  const [scanTo, setScanTo] = useState("");
  const [iocs, setIocs] = useState([])
  const [iocStatus, setIocStatus] = useState([])
  const [iocStatusComplete, setIocStatusComplete] = useState([])
  // const [edrData, setEdrData] = useState([
  //  { "_source":{"@timestamp":"","host":{"hostname":"30bfd388592873d836f5907c236f18a2"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},
  //  { "_source":{"@timestamp":"","host":{"hostname":"test"}}},

  // ])
  const [edrData, setEdrData] = useState([])
  const [adData, setAdData] = useState([])
  const [officeData, setOfficeData] = useState([])
  const [otherData, setOtherData] = useState([])
  const [index_pattern, setIndexPattern] = useState('')

  const [loader, setLoader] = useState(false)
  const [dataLoader, setDataLoader] = useState(false)

  const [scanCount, setScanCount] = useState(0)
  const [scanCurrentCount, setScanCurrentCount] = useState(0)
  const [logType, setLogType] = useState({ "label": "EDR , AD , OFFICE 365", "value": "all" });

  const [expclickedNode, setExpClickedNode] = useState("")
  const [expclose, setExpClose] = useState(false)
  // const tableData = []

  const overView = `The threat intelligence sharing platform is configured to sync information with various community threat intelligence sources. These contain information about specific threats and acts as a database for Indicators of compromise to help with threat hunting and other activities requiring threat intelligence. The below visualizations show summary of the data that has been acquired from all the community sources. It includes the breakdown which shows the distribution of feeds, the timeline of data that has arrived, the type of Indicators/Attributes that have been obtained etc.`;
  const [showMore, setShowMore] = useState(true);
  const maxLength = 250;
  const [tableDatas, setTableDatas] = useState([]);
  const datalakeURL = localStorage.getItem("DATALAKE_URL");
  const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");
  const edrIndexPattern = localStorage.getItem("CYBOT_CYKIT_INDEX_PATTERN");
  const [url, setUrl] = useState('')
  const [pattern, setPattern] = useState('')
  const [req, setReq] = useState(() => { })
  const INVESTIGATE_o365 = localStorage.getItem("INVESTIGATE_o365") ? localStorage.getItem("INVESTIGATE_o365") : "/logs-o365*";
  const INVESTIGATE_CYAD = localStorage.getItem("INVESTIGATE_CYAD");
  const [showAll, setShowAll] = useState(false)
  const [showEdr, setShowEdr] = useState(false)
  const [showAd, setShowAd] = useState(false)
  const [showOffice, setShowOffice] = useState(false)
  const [showOther, setShowOther] = useState(false)

  const [totalLogCount, setTotalLogCount] = useState(0)
  const pause = useRef(false)

  // const getAllIocs = ()=>{
  //     async function performScrollSearch() {
  //         const scrollTime = '5m'; // Scroll time window
  //         const index = 'your_index'; // Replace with your index name

  //         let scrollId = null;
  //         let searchResults = [];

  //         do {
  //           const searchParams = {
  //             size: 100, // Number of documents per scroll batch
  //             scroll: scrollTime,
  //             body: {
  //               query: {
  //                 match_all: {} // Query to match all documents
  //               }
  //             }
  //           };

  //           if (scrollId) {
  //             searchParams.scroll_id = scrollId;
  //           } else {
  //             searchParams.index = index;
  //           }

  //           const response = await fetch('http://localhost:9200/_search/scroll', {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify(searchParams)
  //           });

  //           const responseData = await response.json();

  //           scrollId = responseData._scroll_id;
  //           searchResults.push(...responseData.hits.hits);
  //         } while (scrollId && searchResults.length < responseData.hits.total.value);

  //         // Save the search results to an object
  //         const resultsObject = {
  //           totalHits: searchResults.length,
  //           hits: searchResults
  //         };

  //         return
  // }
  const scanDoc = (doc) => {
    const iocFound = iocs.map((ioc) => {
      const jsonString = JSON.stringify(doc);
      return jsonString.includes(ioc);
    })
    return iocFound[0]
  }

  // Create a new CancelToken source
  const source = axios.CancelToken.source();

  const ScanEdrData = async (iocval) => {
    if (pause.current) {
      source.cancel('Operation canceled by the user.');
      return;
    }
    try {
      const response = await scanEdr(scanFrom, scanTo, iocval, { cancelToken: source.token });
      const { data: { hits } } = response;
      hits.hits ? setEdrData((edr) => [...edr, ...hits.hits]) : setEdrData((edr) => [...edr, []])
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }
  }

  const ScanAdData = async (iocval) => {
    if (pause.current) {
      source.cancel('Operation canceled by the user.');
      return;
    }
    try {
      const response = await scanAd(scanFrom, scanTo, iocval, { cancelToken: source.token });
      const { data: { hits } } = response;
      hits.hits ? setAdData((ad) => [...ad, ...hits.hits]) : setAdData((ad) => [...ad, []])
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }
  }

  const ScanofficeData = async (iocval) => {
    if (pause.current) {
      source.cancel('Operation canceled by the user.');
      return;
    }
    try {
      const response = await scanOffice(scanFrom, scanTo, iocval, { cancelToken: source.token });
      const { data: { hits } } = response;
      hits.hits ? setOfficeData((office) => [...office, ...hits.hits]) : setOfficeData((office) => [...office, []])
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }
  }

  const ScanotherData = async (iocval, index_pattern) => {
    if (pause.current) {
      source.cancel('Operation canceled by the user.');
      return;
    }
    try {
      const response = await scanOtherSources(scanFrom, scanTo, index_pattern, iocval, { cancelToken: source.token });
      const { data: { hits } } = response;
      hits.hits ? setOfficeData((office) => [...office, ...hits.hits]) : setOfficeData((office) => [...office, []])
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        // handle error
      }
    }
  }

  const ScanPendingStatusSetter = async (ioc) => {
    let arr = ioc.map(item => {
      return {
        hash: item?._source?.attribute_value,
        type: item?._source?.attribute_type,
        status: "pending"
      }
    })
    await setIocStatus(item => [...item, ...arr])
  }
  const ScanCompleteStatusSetter = async (ioc) => {
    let arr = ioc.map(item => {
      return {
        hash: item?._source?.attribute_value,
        type: item?._source?.attribute_type,
        status: "complete"
      }
    })
    await setIocStatusComplete((ioc) => [...ioc, ...arr])
  }
  const scanData = async (ioc) => {
    await ScanPendingStatusSetter(ioc)
    if (logType.value === "all") {
      await ScanEdrData(ioc)
      await ScanAdData(ioc)
      await ScanofficeData(ioc)
      await setShowAll(true)
    }
    else if (logType.value === "edr") {
      await ScanEdrData(ioc)
      await setShowEdr(true)
    }
    else if (logType.value === "ad") {
      await ScanAdData(ioc)
      await setShowAd(true)
    }
    else if (logType.value === "o365") {
      await ScanofficeData(ioc)
      await setShowOffice(true)
    }
    else if (logType.value === "other") {
      await ScanotherData(ioc, logType.index_pattern)
      await setShowOffice(true)
    }
    await ScanCompleteStatusSetter(ioc)
  }
  const totalLogs = async () => {
    var total = 0
    if (logType.value === "all") {
      await scanEdr(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })
      await scanAd(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })
      await scanOffice(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })
    }
    else if (logType.value === "edr") {
      await scanEdr(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })

    }
    else if (logType.value === "ad") {
      await scanAd(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })
    }
    else if (logType.value === "o365") {
      await scanOffice(scanFrom, scanTo).then(({ data: { hits } }) => {
        total += hits.total.value
      })
    }
    else if (logType.value === "other") {
      
      await scanOtherSources(scanFrom, scanTo, logType?.index_pattern).then(({ data: { hits } }) => {
        total += hits.total.value
      })
    }
    await setTotalLogCount(total)
    return total
  }
  const ScanWithIoc = async (e) => {
    e.preventDefault();
    pause.current = false
    await setIocStatus([])
    await setIocStatusComplete([])
    await setIocs([])
    await setShowAll(false)
    await setShowEdr(false)
    await setShowAd(false)
    await setShowOffice(false)
    await setShowOther(false)
    await setTotalLogCount(0)
    await setLoader(true)
    await setDataLoader(true)
    var iocData = []
    await getIocs(from, to, iocType).then(({ data: { hits } }) => {
      hits.hits ? setIocs(hits.hits) : setIocs([])
      iocData = hits.hits
      setLoader(false)

      setScanCount(hits.total.value)
      if (hits?.hits?.length === 0) {
        toast.error("No IOCs Were Found", {
          position: "bottom-right",
        });
      }
    })
    if (iocData?.length > 0) {
      const totalValue = await totalLogs()
      if (totalValue > 0) {
        const groupediocs = splitJSONIntoGroupsAuto(iocData)
        for (const ioc of groupediocs) {
          !pause.current && await scanData(ioc)
          // setScanCurrentCount((prev) => prev + 1)
          pause.current && setDataLoader(false)
          console.log("current:", scanCurrentCount)
        }

      }
      else {
        let arr = iocData.map(item => {
          return {
            hash: item?._source?.attribute_value,
            type: item?._source?.attribute_type,
            status: "complete"
          }
        })
        await setIocStatusComplete((ioc) => [...ioc, ...arr])
        if (logType.value === "all") {
          await setShowAll(true)
        }
        else if (logType.value === "edr") {
          await setShowEdr(true)
        }
        else if (logType.value === "ad") {
          await setShowAd(true)

        }
        else if (logType.value === "o365") {
          await setShowOffice(true)
        }
        else if (logType.value === "other") {
          await setShowOther(true)
        }
      }
    }
    await setDataLoader(false)

  }
  const setResultStatus = async () => {
    if (logType.value === "all") {
      await setShowAll(true)
    }
    else if (logType.value === "edr") {
      await setShowEdr(true)
    }
    else if (logType.value === "ad") {
      await setShowAd(true)

    }
    else if (logType.value === "o365") {
      await setShowOffice(true)
    }
    else if (logType.value === "other") {
      await setShowOther(true)
    }
  }
  const ScanResume = async (iocsres) => {
    await setShowAll(false)
    await setShowEdr(false)
    await setShowAd(false)
    await setShowOffice(false)
    await setShowOther(false)
    await setLoader(false)
    await setDataLoader(true)
    var iocData = iocsres
    pause.current = false

    if (iocData?.length > 0) {
      const totalValue = totalLogCount
      if (totalValue > 0) {
        const groupediocs = splitJSONIntoGroupsAuto(iocData)
        for (const ioc of groupediocs) {
          !pause.current && await scanData(ioc)
          // setScanCurrentCount((prev) => prev + 1)
          console.log("current:", scanCurrentCount)
        }
      }
      else {
        let arr = iocData.map(item => {
          return {
            hash: item?._source?.attribute_value,
            type: item?._source?.attribute_type,
            status: "complete"
          }
        })
        await setIocStatusComplete((ioc) => [...ioc, ...arr])
        if (logType.value === "all") {
          await setShowAll(true)
        }
        else if (logType.value === "edr") {
          await setShowEdr(true)
        }
        else if (logType.value === "ad") {
          await setShowAd(true)

        }
        else if (logType.value === "o365") {
          await setShowOffice(true)
        }
        else if (logType.value === "other") {
          await setShowOther(true)
        }
      }
    }
    await setDataLoader(false)

  }
  const iocTypeOptions = [
    {
      value: "all",
      label: "All"
    },
    {
      value: "FileHash-MD5",
      label: "FileHash-MD5"
    },
    {
      value: "FileHash-SHA1",
      label: "FileHash-SHA1"
    },
    {
      value: "FileHash-SHA256",
      label: "FileHash-SHA256"
    },
    {
      value: "IPv4",
      label: "IPv4"
    },
    {
      value: "domain",
      label: "Domain"
    },
  ]

  const logTypeOptions = [
    {
      value: "all",
      label: "EDR , AD , OFFICE 365"
    },
    {
      value: "edr",
      label: "EDR"
    },
    {
      value: "ad",
      label: "AD"
    },
    {
      value: "o365",
      label: "OFFICE 365"
    },
  ]

  const otherLogTypeOptions = JSON.parse(localStorage.getItem("TI_CYKIT_LOG_PATTERN")) || [];
  const [logTypeOpt, setLogTypeOpt] = useState([...logTypeOptions, ...otherLogTypeOptions]);
  function TruncatedString(text, maxLength) {
    // Check if the text length exceeds the maxLength
    if (text?.length > maxLength) {
      // Truncate the text to the maxLength and add ellipsis
      const truncatedText = text.slice(0, maxLength) + '...';

      return truncatedText
    }

    return text;
  }
  return (
    <>
      <div
        className="d-flex flex-column col-3 p-4 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >
        <div
          className="col-12 p-4 rounded-3 border"
          style={styles.overviewTile}
        >
          <h4>Cykit</h4>
          <div>
            <form onSubmit={ScanWithIoc}>
              <div className="">
                <label className="mt-2 font-weight-bold" style={{ "fontWeight": 600 }}>Scan with IOCs in Timeframe</label>
                <div className="d-flex flex-column gap-2 mt-2">
                  <div className="input-group cykit" >
                    <div className="input-group-prepend">
                      <span className="">From:</span>
                    </div>
                    <DatePicker
                      portalId="root-cykit-portal"
                      wrapperClassName={"dp-full-width"}
                      showTimeSelect
                      timeIntervals={10}
                      className="form-control dp-full-width"
                      placeholderText="dd/mm/yyyy,--:--"
                      selected={from ? new Date(from) : ""}
                      onChange={(e) => {
                        console.log(e);
                        setFrom(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                      }}
                      dateFormat="MMMM d, yyyy h:mmaa"
                      required
                    />

                    {/* <input className="ppDate form-control" type="datetime-local" required onChange={(event) => { setFrom(event.target.value) }} /> */}
                  </div>
                  <div className="input-group cykit" >
                    <div className="">
                      <span className="">To:</span>
                    </div>
                    <DatePicker
                      portalId="root-cykit-portal"
                      showTimeSelect
                      className="form-control"
                      timeIntervals={10}
                      placeholderText="dd/mm/yyyy,--:--"
                      selected={to ? new Date(to) : ""}
                      onChange={(e) => {
                        console.log(e);
                        setTo(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                      }}
                      dateFormat="MMMM d, yyyy h:mmaa"
                      required

                    />
                    {/* <input className=" form-control" type="datetime-local" required onChange={(event) => { setTo(event.target.value) }} /> */}
                  </div>
                </div>
              </div>
              <label className="mt-2" style={{ "fontWeight": 600 }}>IOC Type</label>
              <Select className="mt-2" options={iocTypeOptions} onChange={(event) => { setIocType(event) }} required></Select>
              <div className="mt-2">
                <label className="font-weight-bold" style={{ "fontWeight": 600 }} >Scan Logs in Timeframe</label>
                <div className="d-flex flex-column gap-2 mt-2">
                  <div className="input-group cykit" >
                    <div className="">
                      <span className="">From:</span>
                    </div>
                    <DatePicker
                      portalId="root-cykit-portal"
                      showTimeSelect
                      className="form-control"
                      timeIntervals={10}
                      placeholderText="dd/mm/yyyy,--:--"
                      selected={scanFrom ? new Date(scanFrom) : ""}
                      onChange={(e) => {
                        console.log(e);
                        setScanFrom(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                      }}
                      dateFormat="MMMM d, yyyy h:mmaa"
                      required
                    />
                    {/* <input className="ppDate form-control" type="datetime-local" required onChange={(event) => { setScanFrom(event.target.value) }} /> */}
                  </div>
                  <div className="input-group cykit" >
                    <div className="">
                      <span className="">To:</span>
                    </div>
                    <DatePicker
                      portalId="root-cykit-portal"
                      showTimeSelect
                      timeIntervals={10}
                      className="form-control"
                      placeholderText="dd/mm/yyyy,--:--"
                      selected={scanTo ? new Date(scanTo) : ""}
                      onChange={(e) => {
                        console.log(e);
                        setScanTo(moment(e)?.format("yyyy-MM-DDTHH:mm"));
                      }}
                      dateFormat="MMMM d, yyyy h:mmaa"
                      required
                    />
                    {/* <input className=" form-control" type="datetime-local" required onChange={(event) => { setScanTo(event.target.value) }} /> */}
                  </div>
                </div>
              </div>
              <label className="mt-2 " style={{ "fontWeight": 600 }}>Log Type</label>
              <Select className="mt-2 w-80" options={logTypeOpt} value={logType} onChange={(event) => { setLogType(event) }} required></Select>

              {/* <Button className="m-2" type="submit">Scan</Button> */}
              <div className="d-flex justify-content-end mt-3">
                {(dataLoader) && <button className=" me-2 btn btn-primary" type="button" onClick={() => { pause.current = true; setResultStatus() }} ><Pause size={18} weight="fill" /></button>}
                {(pause.current) && <button className=" me-2 btn btn-primary" type="button" onClick={() => { pause.current = true; const pendingHashes = new Set(iocStatusComplete.map(item => item?.hash)); ScanResume(iocs.filter(item => !pendingHashes.has(item?._source?.attribute_value))) }} ><Play size={18} weight="fill" /></button>}

                <LoaderBtn isLoading={dataLoader} btnText={!pause.current ? "Scan" : "New Scan"} isdisabled={dataLoader} />
              </div>
            </form>

          </div>
        </div>

        {/* list  */}


      </div>
      {iocs.length > 0 || loader ?
        (loader ? (
          <div className="" style={{ "position": "relative", "left": "30%", "top": "50%" }}>
            <Spinner animation='border' size="xl" style={{ width: "4rem", height: "4rem" }} />
          </div>
        ) :
          <div className="d-flex flex-column col-9 rounded-3 bg-white p-4 gap-3 ">

            <div className="d-flex flex-row gap-3 justify-content-center">
              <div className="rounded-3 p-4 alert alert-success flex-fill">
                <div className="col-12 d-flex flex-column justify-content-center">
                  <h4 className="text-center">{iocStatusComplete.length}/{iocs.length}</h4>
                  <span className="text-center">IOCs Scanned</span>
                </div>
              </div>
              <div className="rounded-3 p-4 alert alert-danger flex-fill">
                <div className="col-12 d-flex flex-column justify-content-center">
                  <h4 className="text-center">{edrData.length + adData.length + officeData.length}</h4>
                  <span className="text-center">Issues Detected</span>
                </div>
              </div>
              <div className="rounded-3 p-4 alert alert-info flex-fill">
                <div className="col-12 d-flex flex-column justify-content-center">
                  <h4 className="text-center">{totalLogCount}</h4>
                  <span className="text-center">Total Logs</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row rounded-3 gap-3" style={{ "overflow": "hidden", "height": "calc(100% - 11rem)" }}>
              <div className="col-6 rounded-3 border px-3 h-100" style={{ "overflow": "auto" }}>
                <div className="d-flex flex-row py-2 align-items-center justify-content-between sticky-top bg-white">
                  <h3 className="m-2">IOCs Scan</h3>
                  <TICykitExport type="ioc" iocs={iocs} />
                </div>
                {iocs?.map((item) => (
                  <div className="d-flex card justify-content-between  my-3" >
                    <div className="card-body rounded-3 d-flex flex-row gap-2 justify-content-between">
                      <div className="col-10 break-word">
                        <span className="mr-2 font-weight-bold">{item?._source?.attribute_type}</span>
                        <span className="col-12 break-word">
                          {/* {TruncatedString(item?._source?.attribute_value, 20)} */}
                          {item?._source?.attribute_value}
                        </span>
                      </div>

                      <div className="my-auto ">
                        {/* {iocStatusComplete.map((item1) =>
                    <div>
                      <span className="mr-2 font-weight-bold">Hash123</span>
                      <span style={{ fontSize: "1.2rem" }}>
                      {item1.hash}  {item1.status}
                      </span>
                    </div>
                  )} */}
                        {
                          pause.current && (iocStatus.filter(item_ico => (item_ico.hash === item._source?.attribute_value)).length === 0) ? (
                            <span className="" style={{ padding: "0.4rem", borderRadius: "50%" }}>
                              <Pause /> {/* Replace with your actual pause icon component */}
                            </span>
                          ) : (iocStatus.filter(item_ico => (item_ico.hash === item._source?.attribute_value)).length > 0) && iocStatusComplete.filter(item_ico => (item_ico.hash === item._source?.attribute_value)).length === 0 ? (
                            <span className="" style={{ padding: "0.4rem", borderRadius: "50%" }}>
                              <Spinner animation="border" role="status"></Spinner>
                            </span>
                          ) : (iocStatusComplete.filter(item_ico => (item_ico.hash === item._source?.attribute_value)).length > 0) ? (
                            <span className="" style={{ background: "#41f290", padding: "0.4rem", borderRadius: "50%" }}>
                              <Check size={16} color="black" />
                            </span>
                          ) : (
                            <span className="" style={{ padding: "0.4rem", borderRadius: "50%" }}>
                              <Spinner animation="border" role="status"></Spinner>
                            </span>
                          )
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='flex-fill p-4 rounded-3 border'>
                {dataLoader ?
                  (
                    <div className="d-flex flex-column col-1" style={{ "position": "relative", "left": "50%", "top": "50%" }}>
                      <span className="" style={{ padding: "0.4rem", borderRadius: "50%", width: "200px !important" }}>
                        <Spinner animation="border" role="status"></Spinner>
                      </span>
                      <span className="ms-2 text-center">{Math.floor((iocStatusComplete.length / iocs.length) * 100)}%</span>
                    </div>
                  )
                  :
                  (<div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-between">
                      <h3 className="m-2">Results</h3>
                      {(!dataLoader && ((edrData.length > 0) || (officeData.length > 0) || (adData.length > 0))) && <TICykitExport type="data" edr={edrData} office={officeData} adData={adData} logType={logType} />}
                    </div>
                    {(showAll || showEdr) &&
                      <div className="d-flex flex-column rounded-3 border w-100 ">
                        <div className="d-flex flex-row justify-content-between" >
                          <h2 className="mt-4 ms-4">EDR </h2>
                          <span className="mt-4 p-2 alert alert-info text-muted fs-6 me-2">{edrData.length} Results</span>
                        </div>
                        <div className="col-12 px-4" style={{ "maxHeight": "198px", "overflow": "auto", }} >
                          {edrData.length > 0 && <Table borderless hover style={{ "width": "100%" }}>
                            <thead className="fixed-head">
                              <tr>
                                <th>Event Date</th>
                                <th>Event Time</th>
                                <th>Device</th>
                                <th>IOC Found</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {edrData && edrData.map((item) =>
                                <tr>
                                  <td className="align-middle">
                                    {getDateFromTimestamp(item._source["@timestamp"])}
                                  </td>
                                  <td className="align-middle">
                                    {getTimeFromTimestamp(item._source["@timestamp"])}
                                  </td>
                                  <td className="align-middle">
                                    {item._source.host.hostname}
                                  </td>
                                  <td className="align-middle">
                                    {scanDoc(item)}
                                  </td>
                                  <td className="">
                                    <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setUrl(datalakeURL); setPattern(edrIndexPattern); setReq(() => fpostCy) }}>
                                      <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                    </a>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>}
                        </div>
                      </div>}
                    {(showAll || showAd) && <div className="d-flex flex-column  rounded-3 rounded-3 border  ">
                      <div className="d-flex flex-row  justify-content-between" >
                        <h2 className="mt-4 ms-4">AD </h2>
                        <span className="mt-4 p-2 alert alert-info text-muted fs-6 me-2">{adData.length} Results</span>
                      </div>
                      <div className="col-12 px-4" style={{ "maxHeight": "198px", "overflow": "auto", }}>
                        {adData.length > 0 && <Table borderless hover style={{ "width": "100%" }}>
                          <thead className="fixed-head">
                            <tr>
                              <th>Event Date</th>
                              <th>Event Time</th>
                              <th>Device</th>
                              <th>IOC Found</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {adData && adData.map((item) =>
                              <tr>
                                <td className="align-middle">
                                  {getDateFromTimestamp(item._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {getTimeFromTimestamp(item._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {item._source.host.hostname}
                                </td>
                                <td className="align-middle">
                                  {scanDoc(item)}
                                </td>
                                <td className="align-middle">
                                  <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setUrl(datalakeURL); setPattern(`${INVESTIGATE_CYAD}/_search?track_total_hits=true`); setReq(() => fpostCy) }} >
                                    <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>}
                      </div>
                    </div>}
                    {(showAll || showOffice) && <div className="d-flex flex-column  rounded-3 rounded-3 border  ">
                      <div className="d-flex flex-row justify-content-between" >
                        <h2 className="mt-4 ms-4">Office 365 </h2>
                        <span className="mt-4 p-2 alert alert-info text-muted fs-6 me-2">{officeData.length} Results</span>
                      </div>
                      <div className="col-12 px-4" style={{ "maxHeight": "198px", "overflow": "auto", }} >
                        {officeData.length > 0 && <Table borderless hover style={{ "width": "100%" }}>
                          <thead className="fixed-head">
                            <tr>
                              <th>Event Date</th>
                              <th>Event Time</th>
                              <th>Device</th>
                              <th>IOC Found</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {officeData && officeData.map((item) =>
                              <tr>
                                <td className="align-middle">
                                  {getDateFromTimestamp(item._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {getTimeFromTimestamp(item._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {item._source.host.hostname}
                                </td>
                                <td className="align-middle">
                                  {scanDoc(item)}
                                </td>
                                <td className="align-middle">
                                  <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setUrl(datalakeURL); setPattern(`${INVESTIGATE_o365}/_search?track_total_hits=true`); setReq(() => fpostDl) }} >
                                    <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>}
                      </div>
                    </div>}
                    {( showOther) && <div className="d-flex flex-column  rounded-3 rounded-3 border  ">
                      <div className="d-flex flex-row justify-content-between" >
                        <h2 className="mt-4 ms-4">Other Logs </h2>
                        <span className="mt-4 p-2 alert alert-info text-muted fs-6 me-2">{otherData.length} Results</span>
                      </div>
                      <div className="col-12 px-4" style={{ "maxHeight": "198px", "overflow": "auto", }} >
                        {otherData.length > 0 && <Table borderless hover style={{ "width": "100%" }}>
                          <thead className="fixed-head">
                            <tr>
                              <th>Event Date</th>
                              <th>Event Time</th>
                              <th>Log Source</th>
                              <th>IOC Found</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {officeData && officeData.map((item) =>
                              <tr>
                                <td className="align-middle">
                                  {getDateFromTimestamp(item?._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {getTimeFromTimestamp(item?._source["@timestamp"])}
                                </td>
                                <td className="align-middle">
                                  {item?._source?.log_source}
                                </td>
                                <td className="align-middle">
                                  {scanDoc(item)}
                                </td>
                                <td className="align-middle">
                                  <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setUrl(datalakeURL); setPattern(`${INVESTIGATE_o365}/_search?track_total_hits=true`); setReq(() => fpostDl) }} >
                                    <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>}
                      </div>
                    </div>}
                  </div>)
                }
              </div>
            </div>
          </div>)
        :
        <div className="mx-auto my-auto " style={{ "width": "100px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 60" x="0px" y="0px"><g data-name="Scan virus"><path d="M41,39c-1.69,0-.82.25-1.57-1l.81-.81a1,1,0,0,0-1.41-1.41l-.81.81c-1.28-.76-1,.13-1-1.57a1,1,0,0,0-2,0c0,1.69.25.82-1,1.57l-.81-.81a1,1,0,0,0-1.41,1.41l.81.81c-.76,1.28.13,1-1.57,1a1,1,0,0,0,0,2c1.69,0,.82-.25,1.57,1l-.81.81a1,1,0,0,0,1.41,1.41c1.21-1.2.4-.75,1.83-.38V45a1,1,0,0,0,2,0V43.86c1.43-.37.62-.82,1.83.38a1,1,0,0,0,1.41-1.41L39.43,42c.76-1.28-.13-1,1.57-1A1,1,0,0,0,41,39Zm-5,3a2,2,0,1,1,2-2A2,2,0,0,1,36,42Z" /><path d="M22,5c0,.17-.15.08,1,0v6a13.18,13.18,0,0,0-5.61,1.76,3.83,3.83,0,0,1,1.26,1.58A10.81,10.81,0,0,1,23,13.06v5a5.6,5.6,0,0,1,2,0v-5a10.92,10.92,0,0,1,6,2.5l-3.56,3.56a6,6,0,0,1,1.42,1.42l10-10a1,1,0,0,0,0-1.42A20.88,20.88,0,0,0,25,3V1a1,1,0,0,0-2,0V3c-.49,0-1,.06-1.46.12A4,4,0,0,1,22,5ZM36.69,9.89l-4.24,4.24A12.88,12.88,0,0,0,25,11.05v-6A18.88,18.88,0,0,1,36.69,9.89Z" /><circle cx="24" cy="24" r="4" /><circle cx="8" cy="36" r="2" /><circle cx="15" cy="16" r="2" /><circle cx="18" cy="5" r="2" /><path d="M47,23H29.91a5.6,5.6,0,0,1,0,2h5A11,11,0,0,1,25,35v-5a5.6,5.6,0,0,1-2,0v5a11,11,0,0,1-9.95-10h5a5.6,5.6,0,0,1,0-2h-5a10.82,10.82,0,0,1,.78-3.18,4,4,0,0,1-1.73-1.06,13.14,13.14,0,0,0-1,4.24H5A19,19,0,0,1,14.79,7.38,4,4,0,0,1,14,5.51,21,21,0,0,0,3,23H1a1,1,0,0,0,0,2H3a20.82,20.82,0,0,0,2.1,8.21,4,4,0,0,1,1.71-1A19.06,19.06,0,0,1,5,25h6A13,13,0,0,0,23,37v6a19.06,19.06,0,0,1-11.65-4.77,4,4,0,0,1-1.47,1.35A21,21,0,0,0,23,45v2a1,1,0,0,0,2,0V45a20.57,20.57,0,0,0,3.5-.45,1,1,0,0,0-.42-2A20,20,0,0,1,25,43V37A13,13,0,0,0,37,25h6a18.8,18.8,0,0,1-2.06,7.65,1,1,0,0,0,1.78.92A20.71,20.71,0,0,0,45,25h2A1,1,0,0,0,47,23Z" /></g></svg>
        </div>}
      <ToastContainer />
      <ExpandedDocument clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={url} pattern={pattern} req={req} />
    </>
  );
}
export default Cykit;
