import { Minus, Plus } from "phosphor-react";

const InlineFilter = ({
  filter,
  setFilter,
  column,
  value,
  getFilteredTable,
  module,
  tab,
  dateFilter,
  setPage
}) => {
  const pageReset = () => {
    if (setPage !== undefined) {
      setPage(1)
    }
  }
  const addFilter = (type) => {
    let storedFilter = JSON.parse(localStorage.getItem("filters"));
    const isExisting = filter?.filter((item) => item.selectedValue === value);

    if (!isExisting.length) {
      let temp = [
        ...filter,
        {
          column: column,
          type: type,
          selectedValue: value,
          from: "",
          to: "",
          isinline: true
        },
      ];
      setFilter(temp);
      pageReset()
      let temp2 = {
        ...storedFilter,
        [module]: { ...storedFilter?.[module], [tab]: { ...storedFilter?.[module]?.[tab], fields: [...temp] } },
      };

      localStorage.setItem("filters", JSON.stringify(temp2));

      if (dateFilter?.to) {
        getFilteredTable(temp, dateFilter);
      } else {
        getFilteredTable(temp);
      }
    }
  };

  return (
    <div className="d-flex gap-1 inline-filter">
      <span onClick={() => addFilter("is")}>
        <Plus
          size={8}
          weight="bold"
          type="button"

        />
      </span>
      <span onClick={() => addFilter("is not")}>
        <Minus
          size={8}
          weight="bold"
          type="button"

        />
      </span>
    </div>
  );
};

export default InlineFilter;
