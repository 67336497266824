import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getTableDataExport } from "../../../Methods/TiMethods";
import { exportCSVFile } from "../../../Utils/Utils";
import { eventfields } from "./fields";

import moment from "moment";
function getTimeFromTimestamp(timestamp) {
  return moment.unix(timestamp).format('MMMM DD, YYYY HH:mm:ss');
}
const TIExport = ({ perPage, page, filter, searchQuery, sevFilter, searchType }) => {
  const [loader, setLoader] = useState(false);


  const convertdata = (fields, data) => {
    var convertedData = [];

    data.map((item) => {
      var dynamicJSON = {};

      for (const field of fields) {


        const { name, value } = field;


        if (value !== `item._source.attribute_timestamp`) {
          var value1 = eval(value);
        }
        else {
          var value1 = getTimeFromTimestamp(eval(value))
        }
        dynamicJSON[name] = value1;
      }

      convertedData.push(dynamicJSON);
    });
    return convertedData;
  };

  const csvExport = async () => {
    setLoader(true);

    var data = await getTableDataExport(
      10000,
      "desc",
      0,
      filter,
      searchQuery,
      sevFilter,
      searchType
    );

    var data3 = data.data.hits.hits;

    var fields = eventfields.event;

    var filename = "event" + "_" + moment().toISOString();
    var data1 = convertdata(fields, data3);

    exportCSVFile(fields, data1, filename);
    setLoader(false);
  };

  return (
    <div className="">
      <DropdownButton
        variant="light"
        title={loader ? "Downloading" : "Export as"}
        disabled={loader}
      >
        <Dropdown.Item
          onClick={() => {
            csvExport();
          }}
        >
          CSV
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default TIExport;