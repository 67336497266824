import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import styles from "../ManagementDashboard/ManagementOverview.styles";
import ThreatLandscape from "../ManagementDashboard/Threatlandscape/ThreatLandscape";

const ThreatLandScapeOverview = () => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState("");
    const [currentPath, setCurrentPath] = useState("");
    const [selectedDateFilter, setSelectedDateFilter] = useState(localStorage.getItem("filter") ? localStorage.getItem("filter") : "7 Days");
    const [title, setTitle] = useState("");
    const [breadcrumb, setBreadcrumb] = useState();
    const [dateFilter, setDateFilter] = useState();
    useEffect(() => {
        setCurrentUrl(location.pathname);
        setCurrentPath(location.pathname.split("/")[2]);
    }, [location]);

    useEffect(() => {

        if (currentPath === "dashboard") {
            setTitle("Threat Landscape")
            setBreadcrumb({
                "module": "threat_landscape",
                "path": [{ "title": "Threat Landscape ", "url": "/threat_landscape/dashboard" }, { "title": "Dashboard", "url": "/threat_landscape/dashboard" },]
            });
        }
    }, [currentPath]);

    return (
        <div className="col-12 d-flex" style={styles.root1}>
            <Sidebar module={"threat_landscape"} />
            <div className="d-flex flex-fill flex-column p-3" style={styles.root2}>
                <Topbar
                    title={title}
                    breadcrumb={breadcrumb}
                    currentUrl={currentUrl}
                    selectedDateFilter={selectedDateFilter}
                    setSelectedDateFilter={setSelectedDateFilter}
                    isFilterEnabled={currentPath === "threat_landscape"}
                    setDateFilter={setDateFilter}
                />
                <div className="col-12 d-flex" style={styles.main}>
                    { currentPath === "dashboard" ? (
                        <ThreatLandscape />) : null}
                </div>
            </div>
        </div>
    );
};

export default ThreatLandScapeOverview;