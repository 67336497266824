import React from 'react'
import { Document, Page, View, Text, Link } from '@react-pdf/renderer';
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../../Utils/Utils";
import { Chart } from "react-google-charts";
import Header from '../Header';



export default function DetailPageVulEx({ detail, moreData }) {

    // Define the styles for the table
    const styles = {
        page: { padding: "20px" },
        container: { flexDirection: "row", marginTop: "5px" },
        column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
        column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
        comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
        link: { padding: "2px", marginTop: "3px", }
    };




    return (
        <Document>
            <Page style={styles.page}>
                <Header data={detail} />
                {detail?.event_name != '' &&
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Event name</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            <Text>
                                {detail?.event_name}</Text>
                        </View>
                    </View>
                }
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Event description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.event_description}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>CVE ID</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.cve}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Severity</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.severity}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Alert date</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getDateFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Alert time</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getTimeFromTimestamp(parseInt(detail?.attribute_timestamp) * 1000)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Vendor</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detail?.vendor}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Published</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.published)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Last Modified</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.lastModified)}</Text>
                    </View>
                </View>

                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>CISA Exploit</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {getDateFromTimestamp(moreData?.vulnerabilities[0]?.cve?.cisaExploitAdd)}</Text>
                    </View>
                </View>
                {moreData?.vulnerabilities[0]?.cve?.references?.length > 0 &&
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>References</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            <Text>
                                {moreData?.vulnerabilities[0]?.cve?.references?.map((ref) => (
                                    <Link to={ref.url} target="_blank" className="w-100" style={{ color: "blue" }}>
                                        {ref.url}
                                    </Link>
                                ))}</Text>
                        </View>
                    </View>
                }
                {moreData?.vulnerabilities[0]?.cve?.descriptions[0]?.value &&
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Additional descriptions</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            <Text>
                                {moreData?.vulnerabilities[0]?.cve?.descriptions[0]?.value}</Text>
                        </View>
                    </View>
                }
                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31 && moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31[0]?.cvssData
                    ?.baseScore &&

                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Base Score V3</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            <Text>
                                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV31[0]?.cvssData
                                    ?.baseScore}</Text>
                        </View>
                    </View>
                }

                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2?.length > 0 ?
                    <View style={styles.container}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text>Base Score V2</Text>
                        </View>
                        <View style={styles.column2}>
                            {/* Content for Column 2 */}
                            <Text>
                                {moreData?.vulnerabilities[0]?.cve?.metrics?.cvssMetricV2[0]?.cvssData
                                    ?.baseScore}</Text>
                        </View>
                    </View>
                    : ''}
            </Page>
        </Document>
    )
}