import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getProcessList } from "../../../Methods/UebaMethods";
import { exportCSVFile } from "../../../Utils/Utils";
import moment from "moment";

const momentobj = moment()

const getTimeFromTimestamp = (timestamp) => {
    return moment(timestamp).format('DD MMM YYYY, hh:mm:ss A');
}
const IncidentsExport = ({ tenandId, filterGroup, dateFilterGroup, getInvestigationGrouped }) => {
    const [alert, setAlert] = useState("")
    const [loader, setLoader] = useState(false);
    const removeHtmlChars = (input) => {
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(input, 'text/html').body.textContent;
        return decodedString;
    }
    const getFields = () => {
        var fields = [
            {
                name: "Date & Time",
                value: "item?._source?.detection_timestamp",
            },
            {
                name: "Incident ID",
                value: "item?._source?.grp_id",
            },
            {
                name: "Incident Name",
                value: "item?._source?.detection_name",
            },
            {
                name: "Tags",
                value: "item?._source?.tag",
            },
            {
                name: "Classification",
                value: "item?._source?.threat_classification",
            },
            {
                name: "Severity",
                value: "item?._source?.detection_severity"
            },
            {
                name: "Mitre tactic",
                value: "item?._source?.tactic_name",
            },
            {
                name: "Mitre technique",
                value: "item?._source?.technique_name",
            },
            {
                name: "Threat Score",
                value: "item?._source?.threat_score",
            },
            {
                name: "Detection Status",
                value: "item?._source?.detection_status",
            },
            {
                name: "Opened by",
                value: "item?._source?.opened_by",
            }

        ];
        return fields;
    };


    const convertdata = (fields, data) => {
        var convertedData = [];
        data.map((item) => {
            setAlert(removeHtmlChars(item._source?.description))
            console.log(alert);

            var dynamicJSON = {};

            for (const field of fields) {
                const { name, value } = field;
                const value1 = eval(value);
                dynamicJSON[name] = value1;
              }
              convertedData.push(dynamicJSON);
            });
            return convertedData;
          };
    const csvExport = async () => {
        setLoader(true);
        try {
            var size = 1000;
            var data = await getInvestigationGrouped(tenandId, size, "desc", 0, filterGroup, dateFilterGroup);
            console.log("Data:", data); // Log the retrieved data
            var data3 = data.data.hits.hits;
    
            var count = 10000
            var iter = 1000;
            while (count > 1000 && iter < count) {
                var tempdata = await getInvestigationGrouped(
                    tenandId, size, "desc", iter, filterGroup, dateFilterGroup
                );
                console.log("Tempdata:", tempdata); // Log temporary data
                data3.push(...tempdata.data.hits.hits);
                iter += size;
            }
    
            console.log("Data3:", data3); // Log the final data
            var fields = getFields();
            var filename = "Incident" + "_" + moment().toISOString();
            // Convert UTC timestamps to a human-readable format
            data3.forEach((item) => {
                if (item._source.detection_timestamp) {
                    // Parse timestamp and format it
                    item._source.detection_timestamp = getTimeFromTimestamp(item._source.detection_timestamp);
                }
            });
            var data1 = convertdata(fields, data3);
            console.log("data1",data1);
            exportCSVFile(fields, data1, filename);
            setLoader(false); 
        } catch (error) {
            console.error("Error:", error); // Log any errors that occur
            setLoader(false); // Ensure loader is set to false even in case of error
        }
    };

    return (
        <div className="">
            <DropdownButton
                variant="light"
                title={loader ? "Downloading" : "Export as"}
                disabled={loader}
            >
                <Dropdown.Item
                    onClick={() => {
                        csvExport();
                    }}
                >
                    CSV
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};

export default IncidentsExport;
