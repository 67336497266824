const styles = {
  section1: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  contentWrap: {
    // height: "calc(100vh - 14rem)",
    height: "calc(100vh - 15rem)",
    overflow: "auto",
  },
  filterWrap: {
    position: "absolute",
    right: "1.5rem",
    top: "14px",
    zIndex: 3
  },
  tableWrap: {
    height: "calc(100vh - 14rem)",
    overflow: "auto",
  },
  // fixedbottom: {
  //   position: "fixed",
  //   bottom: "0",
  //   left: "0",
  //   width: "100%",
    // zIndex: "1000"
  // }
  footerWrap: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#FFFFFF"
  }

};


export default styles;
