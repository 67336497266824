const styles = {
  root1: {
    display: "flex",
    backgroundColor: "#F6F7FB",
  },
  root2: {
    // width: "85%",
    height: "100vh",
    gap: "1rem",
    // padding: "20px"
    // flexDirection: "column",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    // height: "87%",
    height: "calc(100vh - 8rem)",
    // height: "80vh",
    gap: "1rem",
  },
};

export default styles;
