import React, { useState, useEffect } from 'react'
import { Eye, Plus, Trash, Minus } from "phosphor-react";

export default function AttachedId({ index, attachedCaseIds, setAttachedCaseIds, data,
    module, preModule, attachmentList, detectionId, caseidEdit }) {

    const [isAttachedEmpty, setIsAttachedEmpty] = useState(false);

    const [attachType, setAttachType] = useState('');
    const [attachedCase, setAttachedCase] = useState('');
    const [listAttachment, setListAttachment] = useState([]);


    useEffect(() => {
        console.log(data)
        // if (data != '') {
        //     let type_ = data.split('_')[0];
        //     let val_ = data.replace(`${type_}_`, '')

        //     setAttachType(type_)
        //     setAttachedCase(val_)

        // }


        if (module === 'ueba' || module === 'hunter' || module === 'inv-grouped' || module === 'investigate' || module === 'ti') {
            if (index === 0) {

                setAttachedCase(detectionId)

                updateData(detectionId, 'id')
            }

        }

    }, [data])


    // Set data to edit case
    useEffect(() => {

        if (caseidEdit != '' && data != "") {


            let data_;
            let type_;

            if (data.includes('INV-DET_')) {
                data_ = data.replace('INV-DET_', '')
                type_ = "INV-DET"
            } else if (data.includes('CASE_')) {
                data_ = data.replace('CASE__', '')
                type_ = "CASE"
            } else if (data.includes('UEBA_')) {
                data_ = data.replace('UEBA_', '')
                type_ = "UEBA"
            }
            else if (data.includes('HUNT_')) {
                data_ = data.replace('HUNT_', '')
                type_ = "HUNT"
            } 
            else if (data.includes('TI_FEEDS')) {
                data_ = data.replace('TI_FEEDS', '')
                type_ = "TI_FEEDS"
            }
            else if (data.includes('TI_SEC')) {
                data_ = data.replace('TI_SEC', '')
                type_ = "TI_SEC"
            }
            else {
                data_ = data.replace('INV-INC_', '')
                type_ = "INV-INC"

            }


            setAttachedCase(data_)
            setAttachType(type_)
        }
    }, [])





    const removeAttachmentId = (index_) => {

        // Use filter to create a new array without the element at the specified index
        const newArray = attachedCaseIds.filter((item, index) => index !== index_);

        console.log(newArray)
        setAttachedCaseIds(newArray)
        // setAttachedCaseIds([
        //     ...attachedCaseIds.slice(0, index),
        //     ...attachedCaseIds.slice(index + 1),
        // ]);
    }


    const updateData = (value, key) => {

        let preMod;
        let attachName;

        if (key === "type") {
            setAttachType(value);
            preMod = value
            attachName = attachedCase
        } else {
            setAttachedCase(value);
            preMod = preModule
            attachName = value
        }



        let attachedCaseName;
        if (module != 'casemanagement') {
            attachedCaseName = `${preModule}_${attachName}`
        } else {
            attachedCaseName = `${attachType}_${attachName}`
        }



        const updatedArray = attachedCaseIds.map((item, i) => (i === index ? attachedCaseName : item));

        setAttachedCaseIds(updatedArray)

    };


    return (
        <div className="modal-row-item align-items-center">
            {module === 'investigate_soc' ? <div className="modal-row-item ">

                {attachmentList?.map((data) => {

                    let data_;
                    if (data.includes('INV-DET_')) {
                        data_ = data.replace('INV-DET_', '')
                    } else {
                        data_ = data.replace('INV-INC_', '')
                    }
                    return (
                        <span className="tag label label-info"  >

                            <span  >  {data_} </span>

                        </span>
                    )
                })}

            </div> :
                <>
                    {module === 'casemanagement' &&
                        <>

                            <span className="modal-text col-4 mr-2">

                                <select className={isAttachedEmpty === true ? `form-select input-lg  is-invalid` : `form-select input-lg`}
                                    onChange={(e) => updateData(e.target.value, 'type')}
                                    value={attachType}
                                >
                                    <option value="">Select type</option>
                                    <option value="CASE">Case</option>
                                    <option value="UEBA">UEBA Detection</option>
                                    <option value="HUNT">Hunt</option>
                                    <option value="TI_SEC">TI_SEC</option>
                                    <option value="TI_FEEDS">TI_FEEDS</option>
                                    <option value="INV-INC">Investigate Incident</option>
                                    <option value="INV-DET">Investigate Detection</option>
                                </select>


                            </span>
                        </>
                    }
                    <span className="modal-text col-7  ">
                        <input
                            type="text"
                            className={isAttachedEmpty === true ? "form-control input-lg ml-1 is-invalid" : "form-control input-lg ml-1  "}
                            name="attachedCase"
                            placeholder="Enter  ID"
                            onChange={(e) => updateData(e.target.value, "id")}
                            value={attachedCase}

                        />


                    </span>

                    <span className="modal-label col-1 font-weight-600">

                    {module === 'casemanagement' &&
                        <span className="mx-2 cursor-pointer" onClick={() => removeAttachmentId(index)} ><Minus size={18} /></span>
                }


                    </span>
                </>
            }
        </div>
    )
}
