import React from 'react'

const SearchBar = ({ data, searchQuery, setSearchQuery, setFilteredListData, type }) => {

    const handleSearch = () => {
        const filteredData = data?.filter((filterItem) => {

            if (type === "vulner") {
                return filterItem?.doc?.hits?.hits?.[0]?._source?.vulnerability_title.toLowerCase().includes(searchQuery) || filterItem?.doc?.hits?.hits?.[0]?._source?.vulnerability_severity.toLowerCase().includes(searchQuery) || filterItem?.doc?.hits?.hits?.[0]?._source?.vulnerability_severityScore?.toString().toLowerCase().includes(searchQuery) || filterItem?.doc_count?.toString().includes(searchQuery)
            } else if (type === 'vulnerHost') {
                return filterItem?.doc?.hits?.hits?.[0]?._source?.hostname.toLowerCase().includes(searchQuery) || filterItem?.doc_count?.toString().includes(searchQuery)
            } else if (type === "software") {
                return filterItem?.doc?.hits?.hits?.[0]?._source?.software_product.toLowerCase().includes(searchQuery) || filterItem?.doc?.hits?.hits?.[0]?._source?.software_vendor?.toLowerCase().includes(searchQuery) || filterItem?.doc?.hits?.hits?.[0]?._source?.software_version?.includes(searchQuery) || filterItem?.doc_count?.toString().includes(searchQuery)
            }
            else if (type === "SoftHost") {
                return filterItem?.key.toLowerCase().includes(searchQuery) || filterItem?.doc_count?.toString().includes(searchQuery)
            }
            else {
                return Object.values(filterItem?.doc?.hits?.hits?.[0]?._source).some(value =>
                    value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
                //  filterItem?.doc?.hits?.hits?.[0]?._source?.toString()?.toLowerCase().includes(searchQuery)
            }

        })
        setFilteredListData(filteredData)
    }

    const handleClearClick = () => {
        setSearchQuery('');
    }

    return (
        <div class="search-component input-group" style={{ "width": "auto" }}>
            <input
                placeholder="Search"
                type="search"
                class="me-2 custom-search-input form-control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e?.target?.value.toLowerCase())}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
            {searchQuery &&
                <span style={{    "position": "absolute","right": "8px","top": "50%",zIndex:10}} onClick={(e) => handleClearClick(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg custom-clear-button" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                    </svg>
                </span>
            }
            <span class="bg-white input-group-text" onClick={handleSearch} >
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                    </svg>
                </span>
            </span>
            
        </div>
    )
}

export default SearchBar