import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./RecentTab.style.js";
import Loader from "../Loader/Loader";
import NoDataFound from '../NoDataFount/NoDataFound.jsx'
import { TimelineLoader } from "../Loader/TimelineLoader.jsx";
import RecentTabLoader from "../Loader/RecentTabLoader.jsx";
import { useEffect } from "react";
import moment from "moment";

const RecentTab = ({ mostAnomalouseList, tabName, selectedField, FieldLabel, type, loader }) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sDate, setSDate] = useState('');
  const [eDate, setEDate] = useState('');
  const [selectedDateTab, setSelectedDateTab] = useState('');


  const today = moment().format("yyyy-MM-DDTHH:mm");


  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  mostAnomalouseList.map((item) => {
    let splitLetter = item.key.split(" ");
    let profileLetter = "";
    if (splitLetter[1]) {
      profileLetter = `${splitLetter[0].charAt(0)} ${splitLetter[1].charAt(0)}`;
      item.profileLet = profileLetter;
    } else {
      profileLetter = splitLetter[0].charAt(0);
      item.profileLet = profileLetter;
    }
  });
  const FilterUpdate = (temp) => {

    let filter = localStorage.getItem("filter")

    var number = 0
    var dtype;
    if (filter === "1 Year") {
      number = 365
      dtype = "day"
    } else if (filter === "30 Days") {
      number = 30
      dtype = "day"
    } else if (filter === "1 Day") {
      number = 1
      dtype = "day"
    } else if (filter === "6 hour") {
      number = 6
      dtype = "hours"
    } else if (filter === "1 hour") {
      number = 1
      dtype = "hour"
    }
    else if (filter === "30 minutes") {
      number = 30
      dtype = "minutes"
    }
    else if (filter === "5 minutes") {
      number = 5
      dtype = "minutes"
    }
    else if (filter === "7 Days") {
      number = 7
      dtype = "day"
    } else {
      number = 7
      dtype = "day"
    }

    const lastNumberDate = moment()

      .subtract(number, dtype)

      ?.format("yyyy-MM-DDTHH:mm");

    localStorage.setItem("activeTab.investigate", "Detections")

    let dateTemp = {
      from: lastNumberDate,
      to: today,
      f: lastNumberDate,
      e: today,
      range: number,
    }

    let storedFilter = JSON.parse(localStorage.getItem("filters"));
    
    if (type === "ueba") {
      let temp2 = {
        ...storedFilter,
        ueba: {
          detection: {
            fields: [temp], date: dateTemp
          }
        },
      };
      localStorage.setItem("filters", JSON.stringify(temp2));

    }
    else if (type === "inv") {
      let temp2 = {
        ...storedFilter,
        investigation: {
          main: {
            fields: [temp], date: dateTemp
          }
        }
      };
      localStorage.setItem("filters", JSON.stringify(temp2));

    }

  }



  return (
    <div className="w-50 d-flex flex-row gap-3 p-4 rounded-3 bg-white">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          {type === "inv" ? <h5>Most Investigated {tabName}</h5> :
            type === "comp" ? <h5>Top Complaince Failed {tabName}</h5>
              : <h5>Most Anomalous {tabName}</h5>
          }

          {/* <Link
            to={tabName === "Hosts" ? "/ueba/hosts" : "/ueba/users"}
            className="view-btn"
            onClick={() => {
                FilterUpdate(
                  {
                    column: { label: FieldLabel, value: selectedField },
                    type: "is",
                    selectedValue: key,
                    from: "",
                    to: ""
                  },"ueba"
                )
              }}
          >
            view all
          </Link> */}
        </div>
        {loader ? (
          <div className="col-12">
            <RecentTabLoader count={4} />
          </div>
        ) : (
          <>
            {mostAnomalouseList.length || mostAnomalouseList.length !== 0 ? (
              <>
                {type === "comp" ? "" :
                  <p className="mt-1">
                    Distribution of users who have performed most number of anomalous
                    activities within the organization
                  </p>
                }
                <div
                  className="overflow-auto d-flex flex-column gap-3"
                  style={styles.wrapper}
                >
                  {
                    mostAnomalouseList.map(({ key, doc_count, profileLet }) => (
                      <Link
                        key={key}
                        className="p-3 rounded-3 d-flex align-items-center"
                        style={styles.tile}
                        to={type === "inv" ? "/investigate/cybot_soc" : type === "comp" ? "/compliance/alerts" : "/ueba/detection"}
                        state={{
                          data: {
                            column: { label: FieldLabel, value: selectedField },
                            type: "is",
                            selectedValue: key,
                            from: "",
                            to: "",
                            isinline: true
                          },
                        }}
                        onClick={() => {
                          FilterUpdate(
                            {
                              column: { label: FieldLabel, value: selectedField },
                              type: "is",
                              selectedValue: key,
                              from: "",
                              to: "",
                              isinline: true
                            }
                          )
                        }}
                      >
                        <div style={styles.profilePic}>
                          <span>{profileLet}</span>
                        </div>

                        <div className="mx-3">
                          <h6 className="font-weight-bold mb-0">{key}</h6>
                          {/* {!key && ( */}
                          {/* <p className="m-0">
                  Last detection on 22 Jan 2022 at 02:05:10AM
                </p> */}
                          {/* )} */}
                        </div>

                        <span style={styles.count}>{doc_count}</span>
                      </Link>
                    ))
                  }
                  {/* </>
        ) : (
          <NoDataFound errorText={"No data found"} />
        )} */}
                </div>
              </>
            ) : (
              <div className='col-12 d-flex justify-content-center align-items-center col-12' style={{ height: "100%" }}>
                <NoDataFound errorText={"No data found"} />
              </div>
            )}
          </>
        )}


      </div>
    </div>
  );
};

export default RecentTab;
