import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import globalStyles from "../../constants/globalStyles";

const Severity = ({ filter, setFilter, count, setPage, setSevChange }) => {
  const [showList, setShowList] = useState(false);
  const [dotColor, setDotColor] = useState("white");

  useEffect(() => {

    if (filter === "") setDotColor(globalStyles.greyDark3);
    else if (filter === "LOW") setDotColor(globalStyles.green);
    else if (filter === "MEDIUM") setDotColor(globalStyles.yellowDark1);
    else if (filter === "HIGH") setDotColor(globalStyles.orangeDark1);
    else if (filter === "CRITICAL") setDotColor(globalStyles.red);
    else setDotColor(globalStyles.greyDark3);
  }, [filter]);

  return (
    <div
      className="mx-2"
      onMouseOver={() => setShowList(true)}
      onMouseOut={() => setShowList(false)}
      style={{ zIndex: 2 }}
      type="button"
    >
      <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 h-100">
        <span
          style={{
            borderRadius: "50%",
            backgroundColor: dotColor,
            height: "7px",
            width: "7px",
          }}
        />
        <span onClick={() => setSevChange(false)}>

          Severity - <strong>{filter ? filter : "ALL"} {count ? count : ""}</strong>
        </span>
      </div>
      {showList && (
        <div className="pt-3 position-absolute" style={{ zIndex: 2 }}>
          <div className="col-12 d-flex flex-column gap-2 bg-white rounded-3 shadow-sm p-3 px-4">
            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: globalStyles.greyDark3,
                  height: "7px",
                  width: "7px",
                }}
              />
              <span className="" onClick={() => setFilter("") && setPage(1)}>All</span>
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: globalStyles.green,
                  height: "7px",
                  width: "7px",
                }}
              />
              <span onClick={() => setFilter("LOW") && setPage(1)}>Low</span>
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: globalStyles.yellowDark1,
                  height: "7px",
                  width: "7px",
                }}
              />
              <span onClick={() => setFilter("MEDIUM") && setPage(1)}>Medium</span>
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: globalStyles.orangeDark1,
                  height: "7px",
                  width: "7px",
                }}
              />
              <span onClick={() => setFilter("HIGH") && setPage(1)}>High</span>
            </div>

            <div className="d-flex flex-row align-items-center gap-2">
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: globalStyles.red,
                  height: "7px",
                  width: "7px",
                }}
              />
              <span onClick={() => setFilter("CRITICAL") && setPage(1)}>Critical</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Severity;
