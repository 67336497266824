import moment from "moment";
import { genstrut } from "../../Utils/Utils";

export const CorelationfilterGen = (data, fieldList, date, keyword, searchType) => {
    var data = data;
    if (fieldList?.length > 0) {
      let counts = [];
      for (let i = 0; i < fieldList.length; i++) {
        const item = fieldList[i];
  
        // If the column value exists, increment the count
        if (item.column && item.column.value) {
          const columnName = item.column.value;
          counts[columnName] = counts[columnName] || {
            name: columnName,
            count: 0,
  
            iscount: 0,
            notcount: 0,
          };
          counts[columnName].count++;
  
          // If the type value exists, increment the corresponding count
          if (item.type === "is" || item.type === "contains") {
            counts[columnName].iscount++;
          } else if (item.type === "is not" || item.type === "not contains") {
            counts[columnName].notcount++;
          }
        }
  
      }
      let dist = Object.values(counts);
      for (const field of fieldList) {
        if (field.type === "is" || field.type === "contains") {
          var distItem = dist.find((val) => val.name === field.column.value);
  
          if (distItem && distItem.count === 1 && distItem.iscount === 1) {
            var must = genstrut(field);
            data.query.bool.must.push(must);
          } else if (distItem && distItem.count > 1 && distItem.iscount === 1) {
            var must = genstrut(field);
            data.query.bool.must.push(must);
          } else if (distItem && distItem.count > 1 && distItem.iscount > 1) {
            let sindex = 0;
            let index = 0;
            let srindex = 0;
            let smultindex = 0;
            let matchindex = 0;
  
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.wildcard &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.wildcard
                  )[0] === field.column.value
                ) {
                  sindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.query_string &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.query_string
                  )[0] === "query"
                ) {
                  srindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.multi_match &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.multi_match
                  )[0] === "query"
                ) {
                  smultindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  matchindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must[i]?.bool?.should[j]?.term &&
                  Object.keys(
                    data.query.bool.must[i]?.bool?.should[j]?.term
                  )[0] === field.column.value
                ) {
                  index = i + 1;
                }
              }
            }
  
            const shouldn = {
              bool: {
                should: [],
              },
            };
  
            if (sindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[sindex - 1].bool.should.push(must);
            } else if (index > 0) {
              var must = genstrut(field);
              data.query.bool.must[index - 1].bool.should.push(must);
            } else if (srindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[srindex - 1].bool.should.push(must);
            }
            else if (smultindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[smultindex - 1].bool.should.push(must);
            }
            else if (matchindex > 0) {
              var must = genstrut(field);
              data.query.bool.must[matchindex - 1].bool.should.push(must);
            }
            else if (index === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            } else if (sindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            } else if (srindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
            else if (smultindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
            else if (matchindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must.push(shouldn);
            }
          }
        } else if (field.type === "is not" || field.type === "not contains") {
          const must_not = {
            wildcard: {},
          };
  
          var distItem = dist.find((val) => val.name === field.column.value);
  
          if (distItem && distItem.count === 1 && distItem.notcount === 1) {
            console.log("test", dist);
  
            var must = genstrut(field);
            data.query.bool.must_not.push(must);
          }
          else if (distItem && distItem.count > 1 && distItem.notcount === 1) {
            var must = genstrut(field);
            data.query.bool.must_not.push(must);
          } else if (distItem && distItem.count > 1 && distItem.notcount > 1) {
            let index = 0;
            let sindex = 0;
            let srindex = 0;
            let smultindex = 0;
            let matchindex = 0;
  
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.wildcard &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.wildcard
                  )[0] === field.column.value
                ) {
                  index = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.query_string &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.query_string
                  )[0] === "query"
                ) {
                  srindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.multi_match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.multi_match
                  )[0] === "query"
                ) {
                  smultindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  matchindex = i + 1;
                }
              }
            }
            for (let i = 0; i < data.query.bool.must_not?.length; i++) {
              for (
                let j = 0;
                j < data.query.bool.must_not[i]?.bool?.should?.length;
                j++
              ) {
                if (
                  data.query.bool.must_not[i]?.bool?.should[j]?.match &&
                  Object.keys(
                    data.query.bool.must_not[i]?.bool?.should[j]?.match
                  )[0] === field.column.value
                ) {
                  sindex = i + 1;
                }
              }
            }
  
            const shouldn = {
              bool: {
                should: [],
              },
            };
  
            if (sindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[sindex - 1].bool.should.push(must);
            } else if (index > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[index - 1].bool.should.push(must);
            } else if (srindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[srindex - 1].bool.should.push(must);
            }
            else if (smultindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[smultindex - 1].bool.should.push(must);
            }
            else if (matchindex > 0) {
              var must = genstrut(field);
              data.query.bool.must_not[matchindex - 1].bool.should.push(must);
            }
            else if (index === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            } else if (sindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            } else if (srindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
            else if (smultindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
            else if (matchindex === 0) {
              var must = genstrut(field);
              shouldn.bool.should.push(must);
              data.query.bool.must_not.push(shouldn);
            }
          }
        }
      }
    }
  
    data.track_total_hits = true;
  
    if (date) {
      if (date?.from) {
        var range = {
          range: {
            "@timestamp": {
              gte: moment(date.from).toISOString(),
            },
          },
        };
        if (date?.from && date?.to) {
          var range = {
            range: {
              "@timestamp": {
                lte: moment(date.to).toISOString(),
                gte: moment(date.from).toISOString(),
              },
            },
          };
        }
        data.query.bool.filter.push(range);
      }
      if (date?.to && !date?.from) {
        var range = {
          range: {
            "@timestamp": {
              lte: moment(date.to).toISOString(),
            },
          },
        };
        data.query.bool.filter.push(range);
      }
    }
    // else {
    //   var range = {
    //     range: {
    //       "@timestamp": {
    //         gte: moment.utc().subtract(1, 'hour').toISOString(),
    //         lte: moment.utc().toISOString(),
    //       },
    //     },
    //   };
    //   data.query.bool.filter.push(range);
    // }
    if(keyword?.length>0)
   { if(searchType==="adv"){
      var qr = {
        "query_string": {
            "query": `*${keyword}*`
        }
      }
      data.query.bool.filter.push(qr)
      }
      else{
        var qr = {
          "multi_match": {
              "type": "best_fields",
              "query": keyword,
              "lenient": true
          }
      }
        data.query.bool.filter.push(qr)
      }}
    return data;
  };