
import coverImage from "../../assets/images/login-cover-1.jpg";
const styles = {
  mainContainer: {
    display: "flex",
    height: "100vh",
    justifyContent: "space-between",
  },
  quoteContainer: {
    backgroundColor: "#000117",
    backgroundImage: "url(" + coverImage + ")",
    backgroundPosition: "center",
    // width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    height: "30px",
    alignSelf: "flex-start",
  },
  loginContainer: {
    display: "flex",
    // width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  inputBox: {},
  button: {},
};

export default styles;
