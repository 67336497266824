import React, { useEffect } from 'react'
import { PDFDownloadLink, Document, Page, View, Text, Image, } from "@react-pdf/renderer";
import { Button } from 'react-bootstrap';
import { getTimeFromTimestamp, rawHTML, timestampConvert } from '../../../Utils/Utils';
import { decode } from 'html-entities';
import { getGroupedDetectionDetails } from '../../../Methods/HunterMethods';
import { useState } from 'react';
import Header from '../Header';

const styles = {
    page: { padding: "20px" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
    tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    hunt: { display: "flex", flexDirection: "column", padding: "10px", fontSize: "10px", fontWeight: "500", color: "#494545", border: "2px solid #CCCCCC", margin: "15px" },
    imageDiv: { justifyContent: 'center', },
    image: {
        width: '80%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "14px", fontWeight: "600", padding: "5px" }
};

const DownloadPdf = ({ detectionDetails, detectionTimelineData, pageDetails }) => {

    const [timelineData, setTimelineData] = useState([])

    useEffect(() => {

        detectionTimelineData?.map((item) => {
            getGroupedDetectionDetails(item?._source?.group_id, "hunter")
                .then(
                    ({
                        data: {
                            hits: { hits },
                        },
                    }) => {

                        let groupedInv = []
                        hits.map((item) => {
                            groupedInv.push({
                                host: item?._source?.host,
                                user_name: item?._source?.user,
                                detection_name: item?._source?.detection_name,
                                detection_id: item?._source?.playbook_id,
                                destination_ip: item?._source?.dest_ip,
                                source_ip: item?._source?.source_ip,
                                file: item?._source?.file,
                                hash: item?._source?.hash,
                                url: item?._source?.url,
                                timestamp: item?._source?.detection_timestamp,
                                hunt_detection_id: item?._source?.hunt_detection_id,

                            })
                            // groupDetailsDatas.push(item._source,);
                        });
                        setTimelineData(groupedInv)
                    }
                )
                .catch((err) => {
                    console.log("Error", err);
                });
        })

    }, [])


    const removeHtmlTags = (text) => {
        return text?.replace(/<[^>]*>/g, '');
    };


    const MyDocument = () => (
        <Document>
            <Page style={{ padding: "20px" }}>
                <Header />
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Hunt Id</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detectionDetails[0]?.hunt_id}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Hunt Name</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {detectionDetails[0]?.hunt_name}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>
                            {removeHtmlTags(detectionDetails[0]?.detailed_description)}
                        </Text>
                    </View>
                </View>


                <View style={{ marginTop: "20px" }}>
                    <Text style={styles.title}>Steps</Text>
                </View>

                {detectionDetails[0]?.steps?.map((item, index) => (
                    <>
                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>{`Step ${item?.step}`}</Text>
                            </View>
                        </View>

                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>Name</Text>
                            </View>
                            <View style={styles.column2}>
                                {/* Content for Column 2 */}
                                <Text>
                                    {item?.name}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>Description</Text>
                            </View>
                            <View style={styles.column2}>
                                {/* Content for Column 2 */}
                                <Text>
                                    {item?.description}
                                </Text>
                            </View>
                        </View>

                        {detectionTimelineData[detectionTimelineData.findIndex(x => x._source.step == item.step)]?._source.step == item.step && (
                            <View style={styles.hunt} wrap={false}>
                                <View>
                                    <Text style={styles.title}>
                                        {
                                            timestampConvert(detectionTimelineData[detectionTimelineData?.findIndex(x => x._source?.step == item?.step)]?._source?.detection_timestamp, 'LLL')
                                        }
                                    </Text>
                                </View>

                                <View>
                                    <Text style={{ marginTop: "20px" }}>
                                        {removeHtmlTags(detectionTimelineData[detectionTimelineData?.findIndex(x => x._source?.step == item?.step)]?._source?.description)}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </>
                ))}

            </Page>
        </Document>
    );

    return (
        <PDFDownloadLink document={<MyDocument />} fileName={`hunt-detections-detection${detectionDetails[0].hunt_id}`}>
            {({ blob, url, loading, error }) =>
                loading ? <Button className='btn btn-primary'>Loading document...</Button> : <Button className='btn btn-light'>Download PDF</Button>
            }
        </PDFDownloadLink>
    )
}

export default DownloadPdf