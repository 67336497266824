import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import {
  getComplianceCatalogueTenant,
  addToOrganizationCatalogueHunt,
  removeFromComplianceCatalogue,
  refreshMasterCatalogueCompliance,
  refreshTenantCatalogueCompliance,
  setLoadingFalseComp
} from "../../../Methods/RuleStudioMethods";
import styles from "./Compliance.styles";
import Card from "react-bootstrap/Card";

// import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
// import Custom from "../../../components/CustomModal/RSCustomModal";
import { Pencil, PencilSimple, X, ArrowRight } from "phosphor-react";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import CustomMasterHunterModal from "../../../components/RuleStudio/Hunter/Custom/CustomMasterHunterModal";
import { removeHtmlChars } from "../../../Utils/Utils";
import TableLoader from "../../../components/Loader/TableLoader";
import MasterModal from "./MasterModal";
import CustomComplianceModal from "../../../components/RuleStudio/Compliance/Custom/CustomComplianceModal";
import add from "./MasterModal";
import { ToastContainer, toast } from 'react-toastify';
import { toastsSuccess, toastsDanger, toastsWarning } from "../../../components/Toster/Toster";
import SearchTi from "../../../components/Search/SearchTi";
import CompDetailModal from "../../../components/RuleStudio/Compliance/Custom/CompDetailModal";
import CustomModal from "../../../components/CustomModal/CustomModal";

const Compliance = () => {
  const dispatch = useDispatch();

  const [comId, setComId] = useState("");
  const [show, setShow] = useState(false); 
  const [customShow, setCustomShow] = useState(false);
  const [detailPage, setDetailpage] = useState([]);
  const [searchQueryMaster, setSearchQueryMaster] = useState("");
  const [searchQueryCatalogue, setSearchQueryCatalogue] = useState("");
  const [filteredDataCatalogue, setFilteredDataCatalogue] = useState([]);


  const listCatalogueTenentCompliance = useSelector(
    (state) => state.listCatalogueTenentCompliance
  );
  const { catalogue, loading: catLoading, deleteSuccess } = listCatalogueTenentCompliance;

  const createCompliance = useSelector((state) => state.createCompliance_);
  const { success: createSuccess, error: createError } = createCompliance;


  const addToCatalogueRsComp = useSelector((state) => state.addToCatalogueRsComp);
  const { success: catalogueAddSuccess, loading } = addToCatalogueRsComp;



  const removeFromCatalogueCompliance = useSelector((state) => state.removeFromCatalogueCompliance);
  const { success, error } = removeFromCatalogueCompliance;
  const [toggleViewAll, setToggleViewAll] = useState(false);
  const [commonData, setCommonData] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  const toastDelete = () => toast("Case Deleted successfully");
  const toastsTime = () => toast.warn("It might take a while for the data to be updated !");

    // Detail page view
    const [detailShow, setDetailShow] = useState(false);


  useEffect(() => {

    if (catalogue?.length === 0) {
      dispatch(getComplianceCatalogueTenant());


    }
    filterDataCatalogue(searchQueryCatalogue);


    // filterDataMaster(searchQueryMaster);
  }, []);



  // Create success
  // Create success
  // useEffect(() => {
  //   if (createSuccess === true) setCustomShow(false)

  // }, [createSuccess])

  useEffect(() => {
    if (catalogueAddSuccess === true) {
      toastsSuccess("Data added successfully")
      toastsWarning("It might take a while for the data to be updated !")
    }
  }, [catalogueAddSuccess])

  useEffect(() => {


    if (createSuccess === true) {
      toastsSuccess("Data saved successfully")
      toastsWarning("It might take a while for the data to be updated !")
      setSubmitLoader(false)
      setCustomShow(false)
      dispatch(setLoadingFalseComp())
    }

  }, [createSuccess])




  const handleOpenModal = (display) => {
    setShow(display);
  };

  const handleCustom = (display) => {
    setComId("")
    setCustomShow(display);
  };
  const handleEdit = (comId) => {


    setComId(comId);
    setCustomShow(true);
  };

  function handleCloseModal() {
    setToggleViewAll(false);


    if (add === true) {
      refreshMasterCatalogueCompliance();

    }

    setShow(false);
    setSearchQueryMaster("");
  }

  function handleClose() {
    setCustomShow(false);
  }

    // close for detail page
    function handleCloseDetail  () {
      setDetailShow(false);
      setComId("");
    }

  const deleteFromCatalogue = (id) => {

    if (window.confirm("Delete the item?")) {
      toastsDanger("Deleted successfully")
      toastsWarning("It might take a while for the data to be updated !")

      dispatch(removeFromComplianceCatalogue(id));

      if (deleteSuccess === true || deleteSuccess === undefined) {
        refreshTenantCatalogueCompliance().then((res) => {
          if (res.status === 200) { dispatch(getComplianceCatalogueTenant()) }
        })
      }

    }
  };


  const filterDataCatalogue = (query) => {

    if (query===""||query!="") {
      const filtered = catalogue?.filter((item) => {
        return (
          item?.com_id.toLowerCase().includes(query.toLowerCase()) ||
          item?.com_name.toLowerCase().includes(query.toLowerCase()) ||
          item?.com_description.toLowerCase().includes(query.toLowerCase())
        );
      });
      setFilteredDataCatalogue(filtered);

    }

  };

  const handleSearch = (val, e, action) => {

    if (e.keyCode === 13 || action === 'click' || e.keyCode === 8 && val === '') {
      setSearchQueryCatalogue(val)
      filterDataCatalogue(val)

    }
  };


  let catalogueData =
    filteredDataCatalogue?.length === 0 && searchQueryCatalogue === '' ? catalogue : filteredDataCatalogue;


    // detail modal
   const handleModal = (item) => {
    setComId(item?.com_id);
    setDetailShow(true);
  };
 

    // // detail view
    // function renderModalDetail() {
    //   <CompDetailModal         
    //         setComId={setComId}
    //         comIdEdit={comId}
    //         submitLoader={submitLoader}
    //         setSubmitLoader={setSubmitLoader}
    //     />
    // }
  


  return (
    <div className="col-12 rounded-3 p-4" style={styles.section1}>
      <div className="d-flex flex-row align-items-center justify-content-between py-2 border-bottom">
        <h5 className="mb-0">Organization Catalogue</h5>
        <div className="d-flex">
          <div className="d-flex flex-row align-items-center gap-2 bg-grey py-1 px-3 rounded-2 mx-2">
            <span>
              Total Count - <strong>{catalogueData.length} </strong>
            </span>
          </div>

          <div className="form-group mx-2">
            <SearchTi changeFunction={handleSearch} module='rulestudio' />
          </div>
          <Link
            className="mx-2"
            style={{ color: "white" }}
            onClick={() => handleOpenModal(true)}
          >
            <button className="btn btn-primary">Add from master</button>
          </Link>
          <Link style={{ color: "white" }} onClick={() => handleCustom(true)}>
            <button className="btn btn-light">Create Custom</button>
          </Link>
        </div>
      </div>

      <div style={styles.mainWrap}>
        {catLoading === true ? (
          <TableLoader rowCount={15} />
        ) : (
          catalogueData.length === 0 ? <NoDataFound /> :
            <div className="d-flex flex-row flex-wrap py-3">
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>ID</th>
                    <th>Alert name</th>
                    <th>Description</th>


                    <th>  </th>

                  </tr>
                </thead>
                <tbody>
                  {catalogueData?.map((item, index) => {
                    return (
                      <tr>   <td className="align-middle">
                        {item?.com_id}
                      </td>
                        <td className="align-middle">
                          {item?.com_name}
                        </td>
                        <td className="align-middle">
                          {removeHtmlChars(item?.com_description)}
                        </td>

                        <td className="align-middle text-center">
                          <div className="d-flex flex-row gap-3 justify-content-center">
                            <i
                              className="cursor-pointer"
                              onClick={(e) => handleEdit(item?.com_id)}
                            >
                              <TableViewMore Icon={PencilSimple} dataToggle={"Edit"} dataPlacement={"bottom"} />
                            </i>
                            <i className="cursor-pointer" onClick={(e) => deleteFromCatalogue(item?.com_id)}>
                              <TableViewMore Icon={X} dataToggle={"Delete"} dataPlacement={"bottom"} />
                            </i>

                            {/* <Link
                              to={{
                                pathname: `/rs/compsummary/${item?.com_id}`,
                                state: { sourceList: item._source, index_id: item }
                              }}
                              onClick={() => handleModal(item)}
                            > */}
                            <i
                              className="cursor-pointer"
                              onClick={(e) => handleModal(item)}
                            >
                            <TableViewMore Icon={ArrowRight} dataToggle={"View details"} dataPlacement={"bottom"} />
                            </i>
                          {/* </Link> */}
                          </div>


                        </td>
                      </tr> 

                    );
                  })}
                </tbody>
              </Table>
            </div>
        )}

      </div>

      <MasterModal
        show={show}
        handleCloseModal={handleCloseModal}
        searchQueryMaster={searchQueryMaster}
        catalogueData={catalogueData}
        toggleViewAll={toggleViewAll}
        setToggleViewAll={setToggleViewAll}
        commonData={commonData}
        setCommonData={setCommonData}
        catalogue={catalogue}
      />

      {customShow === true && (
        <CustomComplianceModal
          customShow={customShow}
          setCustomShow={setCustomShow}
          setComId={setComId}
          comIdEdit={comId}
          submitLoader={submitLoader}
          setSubmitLoader={setSubmitLoader}
        />
      )}

      {detailShow && ( 
        <CompDetailModal   
        detailShow={detailShow}
        setDetailShow={setDetailShow}      
        setComId={setComId}
        comIdEdit={comId}
        submitLoader={submitLoader}
        setSubmitLoader={setSubmitLoader}
      />
       
        )}    

    </div>
  );
};
export default Compliance;
