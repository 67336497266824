import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import ViewMoreBtn from "../../components/ViewMoreBtn/TableViewMore";
import styles from "./Settings.styles";
import axios from "../../axios";
import TableLoader from "../../components/Loader/TableLoader";
import { Pen, PencilSimple, Trash } from "phosphor-react";
import { Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFount/NoDataFound";
import { getDateFromTimestamp, getTimeFromTimestamp } from "../../Utils/Utils";
import AlertBox from "../../components/AlertBox/AlertBox";
import CustomModal from "../../components/CustomModal/CustomModal";
import { fdeleteC, fpostC } from "../../axios/axios";
import moment from "moment";

const Exception = () => {
  let baseURL = localStorage.getItem("CYBOT_URL");

  // loader
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [UebaTableIsLoading, setUebaTableIsLoading] = useState(true);

  // table data
  const [tableData, setTableData] = useState([]);

  // ueba table
  const [uebaTableData, setUebaTableData] = useState([]);

  // get tenand ID
  const [tenantId, setTenantId] = useState([])

  // alert popup
  const [alertPopup, setAlertPopup] = useState(false);

  const [alertMessage, setAlertMessage] = useState('Success')

  // updates
  const [show, setShow] = useState(false);
  const [uebaExceptionShow, setUebaExceptionShow] = useState(false);
  const [updateId, setUpdateId] = useState();

  const [exceptionFormData, setExceptionFormData] = useState([])
  const [uebaExceptionFormData, setUebaExceptionFormData] = useState([])

  useEffect(() => {
    let data = {
      size: 10000,
      "query": {
        "match_all": {}
      }
    }
    fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
      let licence = []
      hits?.map((item) => {
        licence.push({ startData: item?._source.start_date, endDate: item?._source.end_date, tenantId: item?._source.tenant_id, organizationName: item?._source.organization_name })
      })
      setTenantId(licence[0].tenantId)
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])

  // delete exception
  const handleDeleteUebaException = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this data?"
    );

    confirmDelete &&
      fdeleteC(`${baseURL}/cybot_proxy/ueba_exceptions_${tenantId}/_doc/${id}`).then((res) => {
        setAlertMessage("Exception Deleted")
        setAlertPopup(true);
      }).catch((err) => {
        console.log("Error")
        setAlertMessage("Failed")
        setAlertPopup(true);
      })
  };


  const handleClosePopup = () => {
    setAlertPopup(false);
  };

  // delete investigate
  const handleDeleteInvException = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this data?");

    confirmDelete && fdeleteC(`${baseURL}/cybot_proxy/thp_exceptions_${tenantId}/_doc/${id}`).then((res) => {
      setAlertMessage("Exception Deleted")
      setAlertPopup(true);
    }).catch((err) => {
      console.log("Error")
      setAlertMessage("Failed")
      setAlertPopup(true);
    })
  }

  useEffect(() => {
    // investigation
    let dataINV = { size: 10000, query: { match_all: {} } };
    fpostC(`${baseURL}/cybot_proxy/thp_exceptions*/_search`, dataINV)
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          setTableData(hits);
          setTableIsLoading(false);
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setTableIsLoading(false);
      });

    // ueba
    let dataUEBA = { size: 10000, query: { match_all: {} } };
    fpostC(`${baseURL}/cybot_proxy/ueba_exceptions*/_search`, dataUEBA, {
      headers: {
        "Content-Type": "application/json",
        "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
      },
    })
      .then(
        ({
          data: {
            hits: { hits },
          },
        }) => {
          setUebaTableData(hits);
          setUebaTableIsLoading(false);
        }
      )
      .catch((err) => {
        console.log("Error", err);
        setUebaTableIsLoading(false);
      });
  }, [alertPopup]);


  // update investigation 
  const handleOpenModal = async (display, data, type) => {
    if (type === 'inv') {
      let dataINV = { size: 10000, query: { match_all: {} } };
      await fpostC(`${baseURL}/cybot_proxy/thp_exceptions*/_search`, dataINV).then(({ data: { hits: { hits } } }) => {
        hits.filter((item) => {
          if (item._id === data._id) {
            const updatedValues = {
              process_executable: item._source?.process_executable,
              user_name: item._source?.user_name,
              process_hash: item._source?.process_hash,
              playbook_id: item._source?.playbook_id,
              parent_process_name: item._source?.parent_process_name,
              host_name: item._source?.host_name,
              parent_process_executable: item._source?.parent_process_executable,
              source_ip: item._source?.source_ip,
              url: item._source?.url,
              process_name: item._source?.process_name,
              dest_ip: item._source?.dest_ip,
              comment: item._source?.comment
            }
            setExceptionFormData(updatedValues)
          }
        })
      }
      )
      setShow(display)
    } else {
      let dataUeba = { size: 10000, query: { match_all: {} } };
      await fpostC(`${baseURL}/cybot_proxy/ueba_exceptions*/_search`, dataUeba).then(({ data: { hits: { hits } } }) => {
        hits.filter((item) => {
          if (item._id === data._id) {
            const updatedValues = {
              created_by: localStorage.getItem("loggedUser"),
              ueba_id: item._source?.ueba_id,
              detection_name: item._source?.detection_name,
              tenant_id: item._source?.tenant_id,
              comment: item._source?.comment,
              user_name: item._source?.user_name,
              host_name: item._source?.host_name,
              expiry_date: item._source?.expiry_date
              // expiry_date: moment(exceptionFormData.expiry_date).format('DD-MM-YYYY')
            }
            setUebaExceptionFormData(updatedValues)
          }
        })
      }
      )
      setUebaExceptionShow(display)
    }

    setUpdateId(data._id)
  }

  const handleCloseModal = () => {
    setShow(false)
    setUebaExceptionShow(false)
  }

  const handleExceptionInputChange = (event) => {
    const { name, value } = event.target;
    setExceptionFormData({
      ...exceptionFormData,
      [name]: value,
    });
  };

  const handleUebaExceptionInputChange = (event) => {
    const { name, value } = event.target;
    setUebaExceptionFormData({
      ...uebaExceptionFormData,
      [name]: value,
    });
  };

  // investigation exception submit
  const handleExceptionSubmit = async (event) => {
    event.preventDefault();

    try {
      if (updateId) {
        const updatedData = {
          doc: {
            process_executable: exceptionFormData?.process_executable,
            user_name: exceptionFormData?.user_name,
            process_hash: exceptionFormData?.process_hash,
            playbook_id: exceptionFormData?.playbook_id,
            parent_process_name: exceptionFormData?.parent_process_name,
            host_name: exceptionFormData?.host_name,
            parent_process_executable: exceptionFormData?.parent_process_executable,
            source_ip: exceptionFormData?.source_ip,
            url: exceptionFormData?.url,
            process_name: exceptionFormData?.process_name,
            dest_ip: exceptionFormData?.dest_ip,
            comment: exceptionFormData?.comment
          }
        };

        await fpostC(`${baseURL}/cybot_proxy/thp_exceptions_${tenantId}/_update/${updateId}`, updatedData);

        setExceptionFormData("");
        handleCloseModal(false);
        setAlertMessage("Exception updated")
        setAlertPopup(true);
      }
    } catch (err) {
      console.log("Error", err)
    }
  };

  // ueba exception submit

  const handleUebaExceptionSubmit = async (event) => {
    event.preventDefault();

    try {
      if (updateId) {

        const updatedData = {
          doc: {
            created_by: localStorage.getItem("loggedUser"),
            ueba_id: uebaExceptionFormData?.ueba_id,
            detection_name: uebaExceptionFormData?.detection_name,
            tenant_id: uebaExceptionFormData?.tenant_id,
            comment: uebaExceptionFormData?.comment,
            user_name: uebaExceptionFormData?.user_name,
            host_name: uebaExceptionFormData?.host_name,
            expiry_date: moment(uebaExceptionFormData?.expiry_date).format('DD-MM-YYYY')
          }
        };

        await fpostC(`${baseURL}/cybot_proxy/ueba_exceptions_${tenantId}/_update/${updateId}`, updatedData);

        setExceptionFormData("");
        handleCloseModal(false);
        setAlertMessage("Ueba Exception updated")
        setAlertPopup(true);
      }
    } catch (err) {
      console.log("Error", err)
    }
  };

  function renderUpdateModal() {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleExceptionSubmit}>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Process Executable</label>
                <input type="text" className="form-control" id="username" placeholder="Enter username" name="process_executable" value={exceptionFormData?.process_executable} onChange={handleExceptionInputChange} />
              </div>
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">User name</label>
                <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="user_name" value={exceptionFormData?.user_name} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Process Hash</label>
                <input type="text" className="form-control" id="username" placeholder="Enter Process Hash" name="process_hash" value={exceptionFormData?.process_hash} onChange={handleExceptionInputChange} />
              </div>
              <div className="col-6 p-1">
                <label className="control-label">Rulebook</label>
                <input type="text" className="form-control" id="username" placeholder="playbook_id" name="playbook_id" value={exceptionFormData?.playbook_id} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Parent Process Name</label>
                <input type="text" className="form-control" id="username" placeholder="Enter Process Name" name="parent_process_name" value={exceptionFormData?.parent_process_name} onChange={handleExceptionInputChange} />
              </div>
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Host Name</label>
                <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="host_name" value={exceptionFormData?.host_name} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Parent Process Executable</label>
                <input type="text" className="form-control" id="username" placeholder="Enter Parent Process Executable" name="parent_process_executable" value={exceptionFormData?.parent_process_executable} onChange={handleExceptionInputChange} />
              </div>
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Source IP</label>
                <input type="text" className="form-control" id="hostname" placeholder="Enter Source IP" name="source_ip" value={exceptionFormData?.source_ip} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">URL</label>
                <input type="text" className="form-control" id="username" placeholder="Enter URL" name="url" value={exceptionFormData?.url} onChange={handleExceptionInputChange} />
              </div>
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Process Name</label>
                <input type="text" className="form-control" id="hostname" placeholder="Enter Process Name" name="process_name" value={exceptionFormData?.process_name} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-6 col-xs-12 p-1">
                <label className="control-label">Destination IP</label>
                <input type="text" className="form-control" id="username" placeholder="Enter Destination IP" name="dest_ip" value={exceptionFormData?.dest_ip} onChange={handleExceptionInputChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                <textarea className="form-control" id="comment" rows="3" name="comment" value={exceptionFormData?.comment} onChange={handleExceptionInputChange} required ></textarea>
              </div>
            </div>
          </div>
          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-primary" type="submit">
                Update
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  const renderUebaUpdateModal = () => {
    return (
      <div className="col-12 p-3 h-100">
        <form className="h-100" onSubmit={handleUebaExceptionSubmit}>
          <div className="row">
            <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Detection Name</label>
                <input type="text" className="form-control" id="username" placeholder="Enter Detection" name="user_name" value={uebaExceptionFormData.detection_name} onChange={handleUebaExceptionInputChange} disabled />
              </div>
              <div className="d-flex">
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">User name</label>
                  <input type="text" className="form-control" id="username" placeholder="Enter username" name="user_name" value={uebaExceptionFormData.user_name} onChange={handleUebaExceptionInputChange} />
                </div>
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">Host name</label>
                  <input type="text" className="form-control" id="hostname" placeholder="Enter host name" name="host_name" value={uebaExceptionFormData.host_name} onChange={handleUebaExceptionInputChange} />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-6 col-xs-12 p-1">
                  <label className="control-label">Expiry Date</label>
                  <input type="date" className="form-control" id="username" placeholder="DD MM YYYY" name="expiry_date" value={uebaExceptionFormData.expiry_date} onChange={handleUebaExceptionInputChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group d-flex">
              <div className="col-md-12 col-xs-12 p-1">
                <label className="control-label">Comments</label>
                <textarea className="form-control" id="comment" rows="3" name="comment" value={uebaExceptionFormData.comment} onChange={handleUebaExceptionInputChange} ></textarea>
              </div>
            </div>
          </div>


          <div className="row mt-3 py-3" style={styles.footerWrap}>
            <div className="form-group d-flex justify-content-end">
              <Button className="btn-primary" type="submit">
                Update
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div
      className="col-12 p-4 position-relative rounded-3"
      style={styles.section1}
    >
      <Tabs
        defaultActiveKey="investigate"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="investigate" title="Investigate">
          <div className="col-12" style={styles.tableWrap}>
            {tableIsLoading ? (
              <TableLoader rowCount={25} />
            ) : tableData.length !== 0 ? (
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>Rulebook</th>
                    <th>Investigation Name</th>
                    <th>Created by</th>
                    <th>Created on</th>
                    <th>Created at</th>
                    <th>Comment</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item) => (
                    <tr>
                      <td>{item._source?.playbook_id}</td>
                      <td></td>
                      <td>{item._source.created_by}</td>
                      <td>{getDateFromTimestamp(item._source.created_at)}</td>
                      <td>{getTimeFromTimestamp(item._source.created_at)}</td>
                      <td>{item._source.comment}</td>
                      <td className="text-center">
                        <OverlayTrigger
                          // delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>Edit</Tooltip>
                          )}
                          placement="bottom"
                        >
                          <Link onClick={() => handleOpenModal(true, item, "inv")}>
                            <ViewMoreBtn Icon={PencilSimple} />
                          </Link>
                        </OverlayTrigger>

                        <OverlayTrigger
                          overlay={(props) => (
                            <Tooltip {...props}>Delete</Tooltip>
                          )}
                          placement="bottom"
                        >
                          <Link
                            style={{ color: "red", marginLeft: "10px" }}
                            onClick={() => handleDeleteInvException(item._id)}
                          >
                            <ViewMoreBtn Icon={Trash} />
                          </Link>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <NoDataFound errorText={"No data found"} />
              </div>
            )}
          </div>
        </Tab>
        <Tab eventKey="ueba" title="UEBA">
          <div className="col-12" style={styles.tableWrap}>
            {tableIsLoading ? (
              <TableLoader rowCount={25} />
            ) : uebaTableData.length !== 0 ? (
              <Table borderless hover>
                <thead className="fixed-head">
                  <tr>
                    <th>UEBA ID</th>
                    <th>Detection Name</th>
                    <th>Created by</th>
                    <th>Created on</th>
                    <th>Created at</th>
                    <th>Expiry Date</th>
                    <th>Comment</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {uebaTableData.map((item) => (
                    <tr>
                      <td>{item?._source?.ueba_id}</td>
                      <td>{item?._source?.detection_name}</td>
                      <td>{item?._source?.created_by}</td>
                      <td>{getDateFromTimestamp(item?._source?.created_at)}</td>
                      <td>{getTimeFromTimestamp(item?._source?.created_at)}</td>
                      <td>{item?._source?.expiry_date}</td>
                      <td>{item?._source?.comment}</td>
                      <td className="text-center">
                        <Link onClick={() => handleOpenModal(true, item, "ueba")}>
                          <ViewMoreBtn Icon={PencilSimple} />
                        </Link>

                        <Link
                          style={{ color: "red", marginLeft: "10px" }}
                          onClick={() => handleDeleteUebaException(item._id)}
                        >
                          <ViewMoreBtn Icon={Trash} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <NoDataFound errorText={"No data found"} />
              </div>
            )}
          </div>
        </Tab>
      </Tabs>

      {show && (
        <CustomModal
          title={"Update Investigate Exception"}
          ShowOrHide={show}
          bodyData={renderUpdateModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize={"md"}
          noBodyPadding={true}
          overflowHidden={true}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )
      }

      {uebaExceptionShow && (
        <CustomModal
          title={"Update UEBA Exception"}
          ShowOrHide={uebaExceptionShow}
          bodyData={renderUebaUpdateModal}
          handleClose={handleCloseModal}
          footer={false}
          modalSize={"md"}
          noBodyPadding={true}
          overflowHidden={true}
          modalPosition={"right"}
          outsideClickDisabled={true}
        ></CustomModal>
      )
      }


      {alertPopup && (
        <AlertBox
          setAlertPopup={setAlertPopup}
          alertPopup={alertPopup}
          message={alertMessage}
          close={handleClosePopup}
          setOutTime={5000}
          bgColor={"lightGreen"}
        />
      )}
    </div>
  );
};

export default Exception;