import React, { useState, forwardRef } from 'react'
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { CaretDown } from "phosphor-react";
import styles from "../Topbar/Topbar.styles";
import { CaretUp, DotsNine, GearSix } from "phosphor-react";
import DatePicker from "react-datepicker";
import useOnclickOutside from "react-cool-onclickoutside";

export default function FilterModal({ show, autoHeight, modalSize, handleClose, downloadBtn, noBodyPadding, overflow, footer, title, downloadPDF }) {




    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={[autoHeight ? "modal-auto-height" : ""]}
            // size= "lg"
            size={modalSize ? modalSize : "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton onClick={handleClose} >
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}

                    {downloadBtn && <Button className="primary">Export as PDF</Button>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={[noBodyPadding ? "p-0" : "p-2",]}>
                <Form  ><div className="row">
                    <div className="form-group   " style={{ display: "flex" }}>
                        <div className="col-md-12 col-xs-12 p-1">
                            {" "}
                            <label className="control-label">TYPE *</label>

                            <select className="form-select input-lg">
                                <option>Select type</option>
                                <option>PDF</option>
                                <option>csv</option>
                            </select>
                        </div> </div>
                    <div className="form-group mt-3 " style={{ display: "flex" }}>
                        <div className="col-md-12 col-xs-12 p-1">
                            <label className="control-label">PERIOD *</label>
                            <select className="form-select input-lg ">
                                <option>Select Period</option>
                                <option>1 Day</option>
                                <option>7 Day</option>
                                <option>30 Day</option>
                                <option>1 year</option>
                                <option>Custom</option>
                            </select>
                        </div>
                    </div>

                </div>   <div className="form-group  mt-3" style={{ display: "flex" }}>
                        <div className="col-md-12 col-xs-12 p-1">
                            {" "}
                            <Button onClick={() => downloadPDF()}>Export</Button>
                        </div> </div></Form>
            </Modal.Body>
            {footer && (
                <Modal.Footer>
                    <Button>Close</Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}
