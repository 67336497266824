import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { Button } from "react-bootstrap";

export default function CustomHuntInformation({

  steps,
  setSteps,
  index,
  data,
  addMore,
}) {
  const [name, setName] = useState(data?.name);
  const [description, setDescription] = useState(data?.description);

  const [selectedList, setList] = useState([]);

  // const listMasterRsIVnV_ = useSelector(
  //   (state) => state.listMasterRsIVnV
  // );
  // const { list } = listMasterRsIVnV_;

  const listCatRsInv = useSelector((state) => state.listCatRsInv);
  const { catalogues: list, } = listCatRsInv;


  useEffect(() => {
    // setListData();
  }, [selectedList])

  useEffect(() => {


    let expOutData = data?.inv_playbook?.map((data) => {

      const filterData = list?.find((list) => list.inv_id === data)

      return { "value": data, 'label': filterData?.inv_name }
    })


    setList(expOutData)



  }, [list])



  const removeItem = () => {
    setSteps([
      ...steps.slice(0, index),
      ...steps.slice(index + 1),
    ]);
  };

  const selectList = list?.map((output) => {

    let str = `${output.inv_name}-${output.inv_id}`;
    return { value: output.inv_id, label: str };
  });


  const setListData = (val) => {


    const list = val?.map((data) => {

      return data.value
    })
    console.log(list)
    let newArr = [...steps];


    newArr[index]['inv_playbook'] = list;

    setSteps(newArr);

  }


  const handleMultiChange = (selectedList) => {
    setList(selectedList);
    setListData(selectedList);
  };

  const updateData = (value, key) => {

    if (key === "name") {
      setName(value);
    } else {
      setDescription(value);
    }
    let newArr = [...steps];

    newArr[index][key] = value;

    setSteps(newArr);
  };



  return (
    <div className="form-group mt-3">
      <h5>Step {index + 1}</h5>
      <div className="form-group">
        <label className="control-label">Name </label>
        <div>
          <input
            type="text"
            className="form-control input-lg form-control-style"
            name="name"
            value={name}
            onChange={(e) => updateData(e.target.value, "name")}
            placeholder="Name "
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="control-label">Description </label>
        <div>
          <textarea
            type="text"
            className="form-control input-lg form-control-style"
            name="description"
            value={description}
            onChange={(e) => updateData(e.target.value, "description")}
            placeholder="Description Here "
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="control-label">List </label>
        <Select
          isMulti
          options={selectList}
          value={selectedList}
          onChange={handleMultiChange}
        />
      </div>

      <div className="form-group">
        <br></br>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {index === 0 ? (
            <Button className="btn-warning" onClick={addMore}>
              + Add more
            </Button>
          ) : (
            <Button className="btn-danger" onClick={removeItem}>
              - Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}