import { fields } from "../../Export/Ti/Cykit/fields"
import { CorelationfilterGen } from "../utils"

export const getFieldAggs = (field,filter,date,keyword,searchType) => {
    let data ={
        query: {
            bool: {
              filter: [
              ],
              must: [],
              should: [],
              must_not: []
            },
          },
        "aggs": {
            "field": {
                "terms": {
                    "field": field,
                    "size": 10
                }
            }
        },
        "size": 0
    }
    return CorelationfilterGen(data,filter,date,keyword,searchType)
}


export const getLogSourceQuery = (keyword,filter,date,searchType) => {
    let data = {
        query: {
            bool: {
              filter: [
              ],
              must: [],
              should: [],
              must_not: []
            },
          },
        "aggs": {
            "field": {
                "terms": {
                    "field": "log_source",
                    "size": 30
                }
            }
        },
        "size": 0
    }
    return CorelationfilterGen(data,filter,date,keyword,searchType)
}