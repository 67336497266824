import React from 'react'
import { Image, View, Text, } from '@react-pdf/renderer';
import {
    getDateFromTimestamp,
    getTimeFromTimestamp,
} from "../../Utils/Utils";

import logo from '../../assets/images/cybot_logo_black.png'

export default function Header({ data }) {

    const styles = {
        container: { flexDirection: "row", marginTop: "5px" },
        containerDate: { flexDirection: "row", marginTop: "1px", display: "flex", alignItems: "center" },
        column1: { flexBasis: "80%", padding: "0", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
        column2: { flexBasis: "20%", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
        image: { height: "22px", width: "130px", marginBottom: "10px", },
        // image: { height: "30px", width: "159px", paddingTop: "5px" },
        borderLine: {
            borderBottomWidth: 0.2,
            borderBottomColor: 'black',
            marginVertical: 10,
            marginRight: "10px",
            marginRight: "10px"
        },
        dateLabel: { fontSize: "7px", color: "#999999" },
        dateValue: { fontSize: "7px", color: "black" }
    };


    return (
        <View>
            <View style={styles.container}>
                <View style={styles.column1}>
                    {/* Content for Column 1 */}
                    <Image src={logo} style={styles.image} />
                </View>
                <View style={styles.column2}>
                    <View style={styles.containerDate}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text> <Text style={styles.dateLabel}>Date - </Text><Text style={styles.dateValue}> {getDateFromTimestamp()}</Text></Text>
                        </View>

                    </View>
                    <View style={styles.containerDate}>
                        <View style={styles.column1}>
                            {/* Content for Column 1 */}
                            <Text> <Text style={styles.dateLabel}>Time - </Text><Text style={styles.dateValue}> {getTimeFromTimestamp()}</Text></Text>
                        </View>

                    </View>

                </View>

            </View>
            <Text style={styles.borderLine}>



            </Text>  </View>
    )
}
