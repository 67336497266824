import axios from "axios";
import {toast} from "react-toastify"
import ErrorToast from "../components/ErrorToast/ErrorToast";
const instance = axios.create();

const logout = () => {
    localStorage.removeItem("auth");
};
const checkErrorUrl = (url)=>{
    let cybot=localStorage.getItem("CYBOT_URL")
    let datalake=localStorage.getItem("DATALAKE_URL")
    let apibaseurl=localStorage.getItem("apiBaseUrl")
    if(url!==undefined){
      if(url?.startsWith(cybot)){
        return "Cybot Server is Currently Unreachable"
      }
      else if(url?.startsWith(datalake)){
        return "Datalake Servers are Currently Unreachable"
      }
      else if (url?.startsWith(apibaseurl)){
        return "API Servers are currently Unreachable"
      }
      else{
        return "Servers are currently Unreachable"
      }
  }
}
const checkError = (url)=>{
  let cybot=localStorage.getItem("CYBOT_URL")
  let datalake=localStorage.getItem("DATALAKE_URL")
  let apibaseurl=localStorage.getItem("apiBaseUrl")
  if(url!==undefined){
    if(url?.startsWith(cybot)){
      return "cybot"
  
    }
    else if(url?.startsWith(datalake)){
      return "datalake"
    }
    else if (url?.startsWith(apibaseurl)){
      return "Api"
    }
    else{
      return "servers"
    }
  }
}
const token = localStorage.getItem("auth");
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error ,res) {
    
    if (error?.response?.status === 401) {
      if(token?.length>0){
      const tokenSplit = token.split(".");
      const payload = JSON.parse(atob(tokenSplit[1]));
      const exptime = payload.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (exptime < currentTime) {
        toast.error("Session Expired", {
          position: "bottom-right",
          toastId: "session-expiry"
        });
        setTimeout(() => {
          logout();
          window.location.replace="/"
      }, 2000)
      }
      
    }
        return error.response
    }    
    else if(error?.response?.status === 400)
    {
      return error.response

    }
    else if(error?.response?.status >= 403 && error?.response?.status < 500)
    {
      // toast.error("Unauthorized Request", {
      //   position: "bottom-right",
      //   toastId: "bad-request"
      // });
      return error.response
    }
    else
    {
      console.log("err",error.config.url)
      if(!(error?.config?.url?.includes("investigate/catalogue?type=tenant") && error?.config?.url?.includes("investigate/catalogue?type=master")))
      {
        toast.clearWaitingQueue()
        // toast.error(<ErrorToast msg={checkErrorUrl(error?.config?.url)} title={checkError(error?.config?.url)} error={error}/>, {
        //   position: "bottom-right",
        //   autoClose: false,
        //   closeOnClick: false,
        //   toastId: checkError(error?.config?.url)
        // });
      }
      return error.response
    }
    return Promise.reject(error)

  }
);

export default instance;
