import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CustomToolTip = ({ content, dataPlacement, dataToggle ,style}) => {
    return (
        <OverlayTrigger
            placement={"bottom"}

            overlay={
                <Tooltip className='tooltip-auto-width' style={style} id={`tooltip-${dataPlacement}`}>
                    {dataToggle}
                </Tooltip>
            }
        >
            {content}
        </OverlayTrigger>
    )
}

export default CustomToolTip