// TENENT-CATALOGUE
export const RT_COMPLIANCE_LIST_CAT_REQUEST = "RT_COMPLIANCE_LIST_CAT_REQUEST";
export const RT_COMPLIANCE_LIST_CAT_SUCCESS = "RT_COMPLIANCE_LIST_CAT_SUCCESS";
export const RT_COMPLIANCE_LIST_CAT_FAIL = "RT_COMPLIANCE_LIST_CAT_FAIL";

// REMOVE-CATALOGUE
export const COMPLIANCE_REMOVE_CATALOGUE_REQUEST =
  "COMPLIANCE_REMOVE_CATALOGUE_REQUEST";
export const COMPLIANCE_REMOVE_CATALOGUE_SUCCESS =
  "COMPLIANCE_REMOVE_CATALOGUE_SUCCESS";
export const COMPLIANCE_REMOVE_CATALOGUE_FAIL =
  "COMPLIANCE_REMOVE_CATALOGUE_FAIL";

//  COMPLIANCE CUSTOM SELECT LIST
export const RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST =
  "RT_COMPLIANCE_LIST_CUSTOM_MASTER_REQUEST";
export const RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS =
  "RT_COMPLIANCE_LIST_CUSTOM_MASTER_SUCCESS";
export const RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL =
  "RT_COMPLIANCE_LIST_CUSTOM_MASTER_FAIL";

// Compliance CUSTOM ADD TO COLLOCTION
export const RT_COMPLIANCE_CUSTOM_MASTER_REQUEST =
  "RT_COMPLIANCE_CUSTOM_MASTER_REQUEST";
export const RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS =
  "RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS";
export const RT_COMPLIANCE_CUSTOM_MASTER_FAIL =
  "RT_COMPLIANCE_CUSTOM_MASTER_FAIL";
//MASTER-CATALOGUE
export const RT_COMP_LIST_MASTER_REQUEST = "RT_COMP_LIST_MASTER_REQUEST";
export const RT_COMP_LIST_MASTER_SUCCESS = "RT_COMP_LIST_MASTER_SUCCES";
export const RT_COMP_LIST_MASTER_FAIL = "RT_COMP_LIST_MASTER_FAIL";

//MASTER-CATALOGUE- ADD TO CAT
export const RT_COMP_ADD_CATALOGUE_REQUEST = "RT_COMP_ADD_CATALOGUE_REQUEST";
export const RT_COMP_ADD_CATALOGUE_SUCCESS = "RT_COMP_ADD_CATALOGUE_SUCCESS";
export const RT_COMP_ADD_CATALOGUE_FAIL = "RT_COMP_ADD_CATALOGUE_FAIL";

export const RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE ="RT_COMPLIANCE_CUSTOM_MASTER_SUCCESS_FALSE";
 

export const RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST="RT_COMP_CUSTOM_MASTER_SINGLE_LIST_REQUEST";
export const RT_COMP_MASTER_SINGLE_LIST_SUCCESS="RT_COMP_MASTER_SINGLE_LIST_SUCCESS";
export const RT_COMP_MASTER_SINGLE_LIST_FAIL="RT_COMP_MASTER_SINGLE_LIST_FAIL";

export const RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST ="RT_COMP_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST";
export const  RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL ="RT_COMP_MASTER_SINGLE_LIST_QUERY_FAIL";

export const  RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS ="RT_COMP_MASTER_SINGLE_LIST_QUERY_SUCCESS";
