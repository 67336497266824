const styles = {
    section1: {
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        overflow: "hidden"
    },
};

export default styles;
