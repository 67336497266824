import React from 'react'
import { Button, Card, Dropdown, DropdownButton, Tab, Table, Tabs } from 'react-bootstrap';
import NoDataFound from '../../../components/NoDataFount/NoDataFound';
import styles from './UserProfiling.styles';
import { useEffect, useState } from 'react';
import moment from "moment/moment.js";
import globalStyles from "../../../constants/globalStyles.js";
import { addToWatchList, detectionTimeLineLogSource, getGroupsData, getHostList, getHostUserDetailsCount, getHostUserDetailsTime, getRecentFileOperations, getUserActivitiesCount, getUserDetectionCount, getUserDetectionTypes, getUserHostDetails, getUserHostList, getUserNetworkDetails, getUserRecentActivities, getUserRecentDetection, getUserRecentLocation, getWatchList, removeFromWatchlist, userActivity } from '../../../Methods/UserProfilingMethod';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    LineElement
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import TableLoader from '../../../components/Loader/TableLoader';
import CustomModal from '../../../components/CustomModal/CustomModal';
import LoaderGrid from '../../../components/Loader/LoaderGrid';
import { Binoculars, CalendarBlank, FilePlus, FileX, BookmarkSimple, ShieldWarning, ArrowRight, Plus } from 'phosphor-react';
import { SmallCardLoader } from '../../../components/Loader/CardLoader';
import { getDateFromTimestamp, getTimeFromTimestamp, rawHTML } from '../../../Utils/Utils';
import { Chart } from "react-google-charts";
import { TimelineLoader } from '../../../components/Loader/TimelineLoader';
import PlainCardLoader from '../../../components/Loader/PlainCardLoader';
// import UebaSummary from '../../../components/UEBA/UebaSummary';
import UebaSummary from "../../../components/UEBA/UebaSummary";
import SearchBar from '../../../components/FrontendSearchBar/SearchBar';
import TableViewMore from '../../../components/ViewMoreBtn/TableViewMore';
import AlertBox from '../../../components/AlertBox/AlertBox';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from "react-select";
import { fpostC } from '../../../axios/axios';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    PointElement,
    LineElement,

);



const UserProfiling = () => {

    let baseURL = localStorage.getItem("CYBOT_URL")

    // profile letter
    const [profileLetter, setProfileLetter] = useState('')
    const [selectedFieldName, setSelectedFieldName] = useState('')


    const [userListData, setUserListData] = useState([])
    const [hostListData, setHostListData] = useState([])

    // loader
    const [hostListLoader, setHostListLoader] = useState(true)
    const [userListLoader, setUserListLoader] = useState(true)
    const [userDetectionCountLoader, setUserDetectionCountLoader] = useState(true)
    const [userActivitiesCountLoader, setUserActivitiesCountLoader] = useState(true)
    const [detectionTimelineIsLoading, setDetectionTimelineIsLoading] = useState(true)
    const [activitiesTableIsLoading, setActivitiesTableIsLoading] = useState(true)
    const [recentLocationLoader, setRecentLocationLoader] = useState(true)
    const [userDetectionTypeLoader, setUserDetectionTypeLoader] = useState(true)
    const [userDetectionNetworkLoader, setUserDetectionNetworkLoader] = useState(true)


    // modal
    const [modalVisibility, setModalVisibility] = useState(false)
    // create group
    const [createGroup, setCreateGroup] = useState(false)


    const [userRecentDetectionData, setUserRecentDetectionData] = useState([])
    const [userRecentActivitiesData, setUserRecentActivitiesData] = useState([])
    const [userLocationListData, setUserLocationListData] = useState([])
    const [userDetectionCount, setUserDetectionCount] = useState([])
    const [userActivitiesCount, setUserActivitiesCount] = useState([])
    const [userDetectionTypeData, setUserDetectionTypeData] = useState([])

    // geo chart
    const [countryLabel, setCountryLabel] = useState([])
    const [countryData, setCountryData] = useState([])
    const [userLocationListLoader, setUserLocationListLoader] = useState([])

    const [selectedUserName, setSelectedUserName] = useState([])

    const [detectionTypeLabel, setDetectionTypeLabel] = useState([])
    const [detectionTypeData, setDetectionTypeData] = useState([])
    const [detectionBgColor, setDetectionBgColor] = useState([])

    const [networkTypeData, setNetworkTypeData] = useState([])
    const [networkLegend, setNetworkLegend] = useState([])

    const [recentLocationData, setRecentLocationData] = useState([])


    // time

    const [detectionNetworkTypeLabel, setDetectionNetworkTypeLabel] = useState([])
    const [detectionNetworkTypeData, setDetectionNetworkTypeData] = useState([])

    // 
    const [recentFileOperations, setRecentFileOperations] = useState([])
    const [recentFileOperationsCount, setRecentFileOperationsCount] = useState([])

    const [recentAccLoader, setRecentAccLoader] = useState(true)
    const [accountActivity, setAccountActivity] = useState([])

    // 
    const [timeLineLogSourceLoader, setTimeLineLogSourceLoader] = useState(true)
    const [timeLineLogSourceLabel, setTimeLineLogSourceLabel] = useState([])
    const [timeLineLogSourceData, setTimeLineLogSourceData] = useState([])

    // severity data 
    const [detectionSeverityData, setDetectionSeverityData] = useState([])

    // detection summary modal 
    const [detectionTimelineVisible, setDetectionSummaryVisible] = useState(false);
    const [detectionSummaryDetailpage, setDetectionSummaryDetailpage] = useState([]);

    // search 
    const [userSearchList, setUserSearchList] = useState([]);
    const [hostSearchList, setHostSearchList] = useState([]);

    // watchlist 
    const [watchlistData, setWatchlistData] = useState([])

    // alert popup
    const [alertPopup, setAlertPopup] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Success')

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    // 
    const [selectedValue, setSelectedValue] = useState('')

    const [createGroupData, setCreateGroupData] = useState([])

    const [addToWatchlist, setAddToWatchlist] = useState({
        name: "",
        type: "",
        group: "",
        priority: "",
    });
    const [groupFieldsData, setGroupFieldsData] = useState({
        group: "",
        description: "",
    });

    const [groupLists, setGroupLists] = useState([])

    const [priorityData, setPriorityData] = useState([])

    const [createGroupModal, setCreateGroupModal] = useState(false)


    const [detectionData, setDetectionData] = useState([]);
    const detectionTimeline = detectionData.map((item) => {
        return Object.entries(item);
    });


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'User Detections',
            },
            // tooltip: {
            //     callbacks: {
            //         title: (tooltipItem) => {
            //             return ""
            //         },
            //         label: (tooltipItem) => {
            //             return `${detectionDataList[tooltipItem.dataIndex]}` + "  " + `(${detectionTypeData[tooltipItem.dataIndex]})`
            //         }
            //     }
            // }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // disable y axis inside grid lines
                },
            },
            x: {
                grid: {
                    display: false, // disable x axis inside grid lines
                },
            },
        },
    };

    const labels = detectionTypeLabel;

    const data = {
        labels,
        datasets: [
            {
                label: 'Counts',
                data: detectionTypeData?.map((item) => item),
                backgroundColor: detectionBgColor,
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: 'bottom',
            },
        ],
    };


    // line time

    const TimeOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'User Detections',
            },
            // tooltip: {
            //     callbacks: {
            //         title: (tooltipItem) => {
            //             return ""
            //         },
            //         label: (tooltipItem) => {
            //             return `${detectionDataList[tooltipItem.dataIndex]}` + "  " + `(${detectionTypeData[tooltipItem.dataIndex]})`
            //         }
            //     }
            // }
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // disable y axis inside grid lines
                },
            },
            x: {
                grid: {
                    display: false, // disable x axis inside grid lines
                },
            },
        },
    };

    const TimeData = {
        labels: detectionNetworkTypeLabel,
        datasets: [
            {
                label: 'Network Detection',
                data: detectionNetworkTypeData?.map((item) => item),
                backgroundColor: globalStyles.green,
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: 'bottom',
            },
        ],
    };


    const networdGraphOptions = {
        plugins: {
            title: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const hostName = networkLegend[tooltipItem.dataIndex]
                        const start = moment(JSON.parse(tooltipItem.formattedValue)[0]).format("HH:mm");
                        const day = moment(tooltipItem.raw.x).format('ddd')
                        const end = moment(JSON.parse(tooltipItem.formattedValue)[1]).format("HH:mm");
                        return `${day} Start Time: ${start}\nEnd Time: ${end} \n ${hostName}`;
                    },
                    title: (tooltipItem) => {

                        const day = moment(tooltipItem[0].label).format('ddd')
                        return `${tooltipItem[0].label} ${day}`;
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    parser: 'YYYY-MM-DD',
                    unit: 'day'
                },
                ticks: {
                    source: 'auto',
                    maxRotation: 0,
                    autoSkip: true
                }
            },
            y: {
                type: 'time',
                suggestedMin: "00:00",
                suggestedMax: "23:59",
                time: {
                    parser: 'HH:mm',
                    unit: 'hour',
                    stepSize: 0.5,
                    displayFormats: {
                        'minute': 'HH:mm',
                        'hour': 'HH:mm'
                    }
                },
                ticks: {
                    source: 'auto',
                    maxRotation: 0,
                    autoSkip: true
                }
            }
        },

    };

    const networkGraphData = {
        datasets: [
            {
                label: "Normal network activity",
                data: networkTypeData.filter((item) => item.result === 1),
                backgroundColor: globalStyles.green,
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
            },
            {
                label: "Anomalous network activity",
                data: networkTypeData.filter((item) => item.result === -1),
                backgroundColor: globalStyles.red,
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                barThickness: 10,
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
            },
        ],
    };

    // detection TimeLine LogSource 

    const timelineLogSourceOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Timeline Log source',
            },
        },
    };


    const timelineLogSourceData = {
        labels: timeLineLogSourceLabel,
        datasets: [
            {
                label: '',
                data: timeLineLogSourceData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    // detection severity data


    const severityDetectionChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    const severityDetectionChartData = {
        // labels: detectionSeverityData.map((item) => item[0]?.key),
        labels: detectionSeverityData.map((item) => item.map((item2) => item2.key)),
        datasets: [
            {
                label: "Low",
                data: detectionSeverityData.map((item) => item[0]?.doc_count),
                backgroundColor: detectionSeverityData.map((item) => item[0]?.key === "Low" ? "#03c610" : item[0]?.key === "Medium" ? "orange" : item[0]?.key === "High" ? "red" : "black"),
            },
            {
                label: "High",
                data: detectionSeverityData.map((item) => item[1]?.doc_count),
                backgroundColor: detectionSeverityData.map((item) => item[1]?.key === "Low" ? "#03c610" : item[1]?.key === "Medium" ? "orange" : item[1]?.key === "High" ? "red" : 'black'),
            },
        ],
    };



    // usecase list 

    const useCaseList = [{
        "ueba_id": "UEBA-001",
        "use_case_name": "logon_time_host",
        "type": "time based"
    },
    {
        "ueba_id": "UEBA-002",
        "use_case_name": "logon_time_user",
        "type": "time based"
    },
    {
        "ueba_id": "UEBA-003",
        "use_case_name": "other_hosts_logged_in",
        "type": "count based"
    },
    {
        "ueba_id": "UEBA-004",
        "use_case_name": "file_activity_time",
        "type": "time based"
    },
    {
        "ueba_id": "UEBA-005",
        "use_case_name": "user_management_time",
        "type": "time based"
    },
    {
        "ueba_id": "UEBA-008",
        "use_case_name": "file_modification_count",
        "type": "count based"
    },
    {
        "ueba_id": "UEBA-009",
        "use_case_name": "user_management_count",
        "type": "count based"
    },
    {
        "ueba_id": "UEBA-010",
        "use_case_name": "file_deletion_count",
        "type": "count based"
    },
    {
        "use_case_name": "lockout_count_domain",
        "type": "count based"
    },
    {
        "use_case_name": "file_activity_count_user",
        "type": "count based"
    },
    {
        "use_case_name": "logon_failure_count_host",
        "type": "count based"
    },
    {
        "use_case_name": "logon_failure_count_user",
        "type": "count based"
    },
    {
        "use_case_name": "network_int_host",
        "type": "time based"
    },
    {
        "use_case_name": "network_int_user",
        "type": "time based"
    },
    {
        "use_case_name": "network_internet_host",
        "type": "time based"
    },
    {
        "use_case_name": "network_internet_user",
        "type": "time based"
    },
    {
        "use_case_name": "file_preview_count",
        "type": "count based"
    },
    {
        "use_case_name": "unusual_office_authentication_failure_time",
        "type": "time based"
    },
    {
        "use_case_name": "unusual_office_365_authentication_failure _count",
        "type": "count based"
    },
    {
        "use_case_name": "unusual_authentication_success_time",
        "type": "time based"
    },
    {
        "use_case_name": "file_preview_time",
        "type": "time based"
    },
    {
        "use_case_name": "file_download_count_onedrive",
        "type": "count based"
    },
    {
        "use_case_name": "File downloaded from Onedrive at unusual time",
        "type": "time based"
    },
    {
        "use_case_name": "email_activity_unusual_time",
        "type": "time based"
    },
    {
        "use_case_name": "email_activity_unusual_count",
        "type": "count based"
    }
    ]

    // usecase fn

    const checkUsecaseType = (field_name) => {
        // getHostUserDetailsCount(field_name, "UEBA-011").then(({ data: { hits: { hits } } }) => {

        //     let label = []
        //     let data = []
        //     let bgColor = []

        //     grouping(hits).map((item) => {
        //         label.push(getDateFromTimestamp(item?.timestamp))
        //         data.push(item?.actual)
        //         bgColor.push(item?.actual > item?.threshold ? globalStyles.red : globalStyles.green)
        //     })

        //     setDetectionBgColor(bgColor)
        //     setDetectionTypeLabel(label)
        //     setDetectionTypeData(data)
        //     setUserDetectionTypeLoader(false)

        // })

        // "UEBA-015" "UEBA-017"
        getHostUserDetailsTime(field_name, "UEBA-017").then((res) => {
            let data = []
            let label = []
            res.data.aggregations["2"].buckets.map((item) => {
                data.push(item?.doc_count)
                label.push(getDateFromTimestamp(item?.key_as_string))
            })
            setDetectionNetworkTypeLabel(label)
            setDetectionNetworkTypeData(data)
            setUserDetectionNetworkLoader(false)
        }).catch((err) => {
            console.log("Error", err)
        })

        getUserNetworkDetails(field_name).then(
            ({
                data: {
                    hits: { hits },
                },
            }) => {
                let label = [];
                let data = [];
                let host_name = []

                hits &&
                    hits.map((item) => {
                        data.push({
                            x: moment(item._source.detection_timestamp).format("YYYY-MM-DD"),
                            y: [
                                moment(item._source.first_activity).format("HH:mm"),
                                moment(item._source.last_activity).format("HH:mm"),
                            ],
                            result: item._source.result
                        })
                        host_name.push(item?._source.host_name)



                    });

                setNetworkLegend(host_name)
                setNetworkTypeData(data)
            }
        )
    }


    const grouping = (fieldList) => {
        let counts = [];
        for (let i = 0; i < fieldList.length; i++) {
            const item = fieldList[i];

            // If the column value exists, increment the count
            if (item._source && item._source.detection_timestamp) {
                const columnName = item._source.detection_timestamp;
                counts[columnName] = counts[columnName] || {
                    detection_timestamp: columnName,
                    count: 0,
                };
                counts[columnName].count++;
            }
        }
        let dist = Object.values(counts);


        let list = []
        dist.map((item) => {
            let thresholdSum = 0
            let actualSum = 0

            fieldList.map((item2) => {
                if (item2._source.detection_timestamp === item?.detection_timestamp) {
                    thresholdSum += item2?._source?.threshold
                    actualSum += item2?._source?.count
                }
            })
            list.push({ "threshold": thresholdSum, "actual": actualSum, "timestamp": item?.detection_timestamp })
        })

        return list

    }

    const getProfileLetters = (fieldName) => {
        let name = fieldName?.split(" ")
        let userProfileLetter = "";
        if (name[1]) {
            userProfileLetter = `${name[0].charAt(
                0
            )} ${name[1].charAt(0)}`;
        } else {
            userProfileLetter = name[0].charAt(0);
        }
        setProfileLetter(userProfileLetter)
    }

    // handleOpenModal
    const handleOpenModal = (data, display, type) => {

        console.log("GET ALL DETAILS :", data)

        setModalVisibility(display)
        setSelectedFieldName([data?.key, "user"])

        // reset count
        setUserDetectionCount([])
        setUserActivitiesCount([])

        setRecentAccLoader(true)
        setUserDetectionCountLoader(true)
        setUserActivitiesCountLoader(true)
        setRecentLocationLoader(true)
        setDetectionTimelineIsLoading(true);
        setUserLocationListLoader(true)
        setUserDetectionTypeLoader(true)
        setUserDetectionNetworkLoader(true)
        setTimeLineLogSourceLoader(true)


        // usecase 
        checkUsecaseType(data?.key)

        // profile letter fn
        getProfileLetters(data?.key)

        // get recent operation

        getRecentFileOperations(10, "now-7d", data?.key).then(({ data: { hits: { hits }, aggregations } }) => {
            hits && setRecentFileOperations(hits)
            aggregations && setRecentFileOperationsCount(aggregations.unique_count.buckets)

            if (aggregations) {
                let obj = {};
                aggregations.unique_count.buckets.forEach(function (item) {
                    let key = item.key;
                    obj[key] = item.doc_count;
                });
                setRecentFileOperationsCount(obj)
                setUserDetectionTypeLoader(false)
            }
        }).catch((err) => {
            console.log("Error", err)
            setUserDetectionTypeLoader(false)
        })

        userActivity(10, "now-7d", data?.key).then(({ data: { hits: { hits } } }) => {
            var useracc = []
            hits && hits.map((item) => {
                var newItem = item
                if (item?._source?.event?.code === "4726" || item?._source?.event?.code === "4725") {
                    const type = "account_deleted"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4720") {
                    const type = "account_created"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4723" || item?._source?.event?.code === "4724") {
                    const type = "password_change"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4738") {
                    const type = "account_changed"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                else if (item?._source?.event?.code === "4740") {
                    const type = "account_locked"
                    newItem = {
                        ...newItem,
                    }
                    newItem.type = type;
                }
                useracc.push(newItem)
            })
            console.log('useracc', useracc)
            setAccountActivity(useracc)
            setRecentAccLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setRecentAccLoader(false)
        })

        // host details
        if (type === "host") {

            getUserRecentDetection("host_name", data?.key).then(({ data: { hits: { hits } } }) => {
                hits.map((item) => {
                    item._source.detection_timestamp = getDateFromTimestamp(
                        item._source.detection_timestamp,
                        "MMM Do YY"
                    )
                })

                let groups = Object.create(null);
                let result;

                hits.forEach((element) => {
                    groups[element._source.detection_timestamp] =
                        groups[element._source.detection_timestamp] || [];
                    groups[element._source.detection_timestamp].push(element);
                });

                result = Object.keys(groups).map((k) => {
                    let temp = {};
                    temp[k] = groups[k];
                    return temp;
                });

                setDetectionData(result);
                setDetectionTimelineIsLoading(false);

            }).catch((err) => {
                console.log("Error", err)
            })

            // host detections count
            getUserDetectionCount("host_name", data?.key).then(({ data: { hits: { total } } }) => {
                total?.value && setUserDetectionCount(total?.value)
                setUserDetectionCountLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setUserDetectionCountLoader(false)
            })

            // host activities count
            getUserActivitiesCount("host_name", data?.key).then(({ data: { aggregations: { events_scanned } } }) => {
                events_scanned?.value && setUserActivitiesCount(events_scanned?.value)
                setUserActivitiesCountLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })

            // host recent location
            getUserRecentLocation("host_name", data?.key).then(({ data: { hits: { hits } } }) => {

                let label = [];
                let data = [];

                hits &&
                    hits.map((item) => {
                        label.push(item?._source?.country === "" ? "--" : item?._source?.country);
                        data.push(item?._source?.count);
                        setCountryLabel(label)
                        setCountryData(data)
                    });

                let geoData = [];
                let locations = label
                locations.forEach((location) => {
                    geoData.push([location])
                })

                setRecentLocationData(geoData)
                setRecentLocationLoader(false)


            }).catch((err) => {
                console.log("Error", err)
            })

            detectionTimeLineLogSource("host_name", data?.key).then(({ data: { aggregations: { 2: { buckets } } } }) => {

                let label = [];
                let data = [];

                let severityLabel = []
                let severityData = []

                buckets && buckets.map((item, index) => {
                    label.push(getDateFromTimestamp(item?.key_as_string))
                    data.push(item?.doc_count)

                    severityData.push(item["3"].buckets)
                })

                setTimeLineLogSourceLabel(label)
                setTimeLineLogSourceData(data)
                setDetectionSeverityData(severityData)
                setTimeLineLogSourceLoader(false)


            }).catch((err) => {
                console.log("Error", err)
                setTimeLineLogSourceLoader(false)
            })

            // host detections type 
            // getUserDetectionTypes("host_name", data?.key).then(({ data: { aggregations: { 3: { buckets } } } }) => {
            //     let label = []
            //     let data = []
            //     let detections = []

            //     buckets.map((item) => {
            //         label.push(item?.id?.hits?.hits[0]._source.ueba_id)
            //         data.push(item?.doc_count)
            //         detections.push(item?.key)
            //     })

            //     setDetectionTypeLabel(label)
            //     setDetectionTypeData(data)
            //     setDetectionDataList(detections)
            //     setUserDetectionTypeLoader(false)
            // }).catch((err) => {
            //     console.log("Error", err)
            // })
        } else {
            // recent detection
            getUserRecentDetection("user_name", data?.key).then(({ data: { hits: { hits } } }) => {

                hits.map((item) => {
                    item._source.detection_timestamp = getDateFromTimestamp(
                        item._source.detection_timestamp,
                        "MMM Do YY"
                    )
                })

                let groups = Object.create(null);
                let result;

                hits.forEach((element) => {
                    groups[element._source.detection_timestamp] =
                        groups[element._source.detection_timestamp] || [];
                    groups[element._source.detection_timestamp].push(element);
                });

                result = Object.keys(groups).map((k) => {
                    let temp = {};
                    temp[k] = groups[k];
                    return temp;
                });

                setDetectionData(result);
                setDetectionTimelineIsLoading(false);

            }).catch((err) => {
                console.log("Error", err)
                setDetectionTimelineIsLoading(false);
            })

            // recent activities
            getUserRecentActivities(data?.key).then(({ data: { hits: { hits } } }) => {
                hits && setUserRecentActivitiesData(hits)
            }).catch((err) => {
                console.log("Error", err)
            })


            // recent location
            getUserRecentLocation("user_name", data?.key).then(({ data: { hits: { hits } } }) => {

                let label = [];
                let data = [];

                hits &&
                    hits.map((item) => {
                        label.push(item?._source?.country === "" ? "--" : item?._source?.country);
                        data.push(item?._source?.count);
                        setCountryLabel(label)
                        setCountryData(data)
                    });

                let geoData = [];
                let locations = label
                locations.forEach((location) => {
                    geoData.push([location])
                })

                setRecentLocationData(geoData)
                setRecentLocationLoader(false)


            }).catch((err) => {
                console.log("Error", err)
                setRecentLocationLoader(false)
            })

            // user detections count
            getUserDetectionCount("user_name", data?.key).then(({ data: { hits: { total } } }) => {
                total?.value && setUserDetectionCount(total?.value)
                setUserDetectionCountLoader(false)
            }).catch((err) => {
                console.log("Error", err)
                setUserDetectionCountLoader(false)
            })

            // user activities count
            getUserActivitiesCount("user_name", data?.key).then(({ data: { aggregations: { events_scanned } } }) => {
                events_scanned?.value && setUserActivitiesCount(events_scanned?.value)
                setUserActivitiesCountLoader(false)
            }).catch((err) => {
                console.log("Error", err)
            })

            detectionTimeLineLogSource("user_name", data?.key).then(({ data: { aggregations: { 2: { buckets } } } }) => {

                let label = [];
                let data = [];

                let severityLabel = []
                let severityData = []

                buckets && buckets.map((item, index) => {
                    label.push(getDateFromTimestamp(item?.key_as_string))
                    data.push(item?.doc_count)

                    severityData.push(item["3"].buckets)
                })

                setTimeLineLogSourceLabel(label)
                setTimeLineLogSourceData(data)
                setDetectionSeverityData(severityData)
                setTimeLineLogSourceLoader(false)


            }).catch((err) => {
                console.log("Error", err)
                setTimeLineLogSourceLoader(false)
            })


            // user detections type 
            // getUserDetectionTypes("user_name", data?.key).then(({ data: { aggregations: { 3: { buckets } } } }) => {
            //     let label = []
            //     let data = []
            //     let detections = []

            //     buckets.map((item) => {
            //         label.push(item?.id?.hits?.hits[0]._source.ueba_id)
            //         data.push(item?.doc_count)
            //         detections.push(item?.key)
            //     })

            //     setDetectionTypeLabel(label)
            //     setDetectionTypeData(data)
            //     setDetectionDataList(detections)
            //     setUserDetectionTypeLoader(false)
            // }).catch((err) => {
            //     console.log("Error", err)
            // })
        }

        // recent detection
        // getUserRecentDetection(data?.key).then(({ data: { hits: { hits } } }) => {
        //     hits && setUserRecentDetectionData(hits)

        //     hits.map((item) => {
        //         item._source.detection_timestamp = getDateFromTimestamp(
        //             item._source.detection_timestamp,
        //             "MMM Do YY"
        //         )
        //     })

        //     let groups = Object.create(null);
        //     let result;

        //     hits.forEach((element) => {
        //         groups[element._source.detection_timestamp] =
        //             groups[element._source.detection_timestamp] || [];
        //         groups[element._source.detection_timestamp].push(element);
        //     });

        //     result = Object.keys(groups).map((k) => {
        //         let temp = {};
        //         temp[k] = groups[k];
        //         return temp;
        //     });

        //     setDetectionData(result);
        //     setDetectionTimelineIsLoading(false);

        // }).catch((err) => {
        //     console.log("Error", err)
        //     setDetectionTimelineIsLoading(false);
        // })

        // recent activities
        // getUserRecentActivities(data?.key).then(({ data: { hits: { hits } } }) => {
        //     hits && setUserRecentActivitiesData(hits)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })


        // recent location
        getUserRecentLocation(data?.key).then(({ data: { hits: { hits } } }) => {
            // hits && setUserLocationListData(hits)

            let label = [];
            let data = [];

            hits &&
                hits.map((item) => {
                    label.push(item?._source?.country === "" ? "--" : item?._source?.country);
                    data.push(item?._source?.count);
                    setCountryLabel(label)
                    setCountryData(data)
                });

            let geoData = [];
            let locations = label
            locations.forEach((location) => {
                geoData.push([location])
            })

            setUserLocationListData(geoData)
            setUserLocationListLoader(false)


        }).catch((err) => {
            console.log("Error", err)
        })

        // user detections count
        // getUserDetectionCount(data?.key).then(({ data: { hits: { total } } }) => {
        //     total?.value && setUserDetectionCount(total?.value)
        //     setUserDetectionCountLoader(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setUserDetectionCountLoader(false)
        // })

        // user activities count
        // getUserActivitiesCount(data?.key).then(({ data: { hits: { total } } }) => {
        //     total?.value && setUserActivitiesCount(total?.value)
        //     setUserActivitiesCountLoader(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setUserActivitiesCountLoader(false)
        // })


        // user detections type 

        // getUserDetectionTypes(data?.key).then(({ data: { aggregations: { 3: { buckets } } } }) => {
        //     buckets && setUserDetectionTypeData(buckets)
        //     let label = []
        //     let data = []
        //     buckets.map((item) => {
        //         label.push(item?.key)
        //         data.push(item?.doc_count)
        //     })

        //     setDetectionTypeLabel(label)
        //     setDetectionTypeData(data)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })
    }

    function handleCloseModal() {
        setModalVisibility(false);
        setCreateGroup(false)
        setCreateGroupModal(false)

    }

    function handleDetectionSummaryCloseModal() {
        setDetectionSummaryVisible(false)
    }

    const handleDetectionSummaryOpenModal = (data, display) => {
        setDetectionSummaryDetailpage(data);
        setDetectionSummaryVisible(display);
    };

    function renderDetectionSummaryModal() {
        return <UebaSummary detectionData={detectionSummaryDetailpage} />;
    }

    // watchlist fn
    const handleAddWatchList = (filed_name, selectedField, display) => {
        let watchlistDetails = []
        watchlistDetails.push({ name: filed_name, field_type: selectedField })
        setCreateGroup(display)
        setCreateGroupData(watchlistDetails)



        // if (filed_name !== undefined || '') {
        //     addToWatchList(filed_name, selectedField).then((res) => {
        //         setAlertPopup(true);
        //         setAlertMessage(`Added ${filed_name} to watchlist`)
        //     }).catch((err) => {
        //         console.log("Error", err)
        //         setAlertPopup(true);
        //         setAlertMessage(`Failed to add`)
        //     })
        // }

    }

    // remove from watchlist 
    const handleRemoveFromWatchlist = (field, field_name) => {

        let getId = watchlistData.filter((item) => item._source?.user_name ? item._source?.user_name : item._source?.host_name === field?.key)

        removeFromWatchlist(getId[0]?._id).then((res) => {
            setAlertMessage(`Removed ${field_name} from watchlist`)
            setAlertPopup(true);
        }).catch((err) => {
            console.log("Error", err)
            setAlertMessage(`Failed to remove`)
            setAlertPopup(true);
        })
    }

    // alert
    const handleClosePopup = () => {
        setAlertPopup(false);
    };

    // drop down

    const handleSelect = (e) => {
        setSelectedValue(e === "low" ? "Low" : e === "medium" ? "Medium" : "High");
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddToWatchlist({
            ...addToWatchlist,
            [name]: value,
        })
    }

    const handleGroupInputChange = (e) => {
        const { name, value } = e.target;
        setGroupFieldsData({
            ...groupFieldsData,
            [name]: value,
        })
    }

    // drop down changes

    const handleonDropDownPriorityChange = (selectedVal) => {
        setAddToWatchlist({
            ...addToWatchlist,
            priority: selectedVal.label,
        });
    };

    const handleOnDropDownGroupChange = (selectedVal) => {
        setAddToWatchlist({
            ...addToWatchlist,
            group: selectedVal.label,
        });
    };

    // create group

    const handleGroupSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                group: groupFieldsData.group,
                description: groupFieldsData.description,
            }
            const response = await fpostC(`${baseURL}/cybot_proxy/ueba_watchlist_group/_doc`, data);
            setGroupFieldsData("")
            handleCloseModal(false)
            setAlertPopup(true)
        } catch (err) {
            console.log("Error", err)
        }
    }


    // add to watchlist

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                name: createGroupData[0].name,
                type: createGroupData[0].field_type,
                group: addToWatchlist.group,
                priority: addToWatchlist.priority,
            }
            const response = await fpostC(`${baseURL}/cybot_proxy/ueba_watchlist_user_host/_doc`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "user-token": localStorage.getItem("CYBOT_ACCESS_TOKEN"),
                }
            });
            setAddToWatchlist("")
            handleCloseModal(false)
            setAlertPopup(true)
        } catch (err) {
            console.log("Error", err)
        }
    }

    useEffect(() => {
        getUserHostList("user_name.keyword", perPage, "desc", (page - 1) * perPage).then(({ data }) => {
            setUserListLoader(true)
            setTotalPageCount(data?.hits?.total.value)
            data && setUserListData(data?.aggregations?.user?.buckets)
            setUserSearchList(data?.aggregations?.user?.buckets)
            setUserListLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setUserListLoader(false)
        })

        // getUserHostList("host_name.keyword").then(({ data }) => {
        //     console.log("FKK CONSOLE", data)
        //     // buckets && setHostListData(buckets)
        //     setHostListLoader(false)
        // }).catch((err) => {
        //     console.log("Error", err)
        //     setHostListLoader(false)
        // })
    }, [page, perPage])



    useEffect(() => {
        getHostList().then(({ data: { aggregations: { 2: { buckets } } } }) => {
            buckets && setHostListData(buckets)
            setHostSearchList(buckets)
            setHostListLoader(false)
        }).catch((err) => {
            console.log("Error", err)
            setHostListLoader(false)
        })

        // group list

        getGroupsData().then(({ data: { aggregations: { group: { buckets } } } }) => {
            let groups = [];
            buckets?.map((item) => {
                groups.push({ label: item?.key })
            })

            setGroupLists(groups)

        }).catch((err) => {
            console.log("Error", err)
        })



        let priorityList = ["low", "medium", "high"];
        let prioritys = [];
        priorityList?.map((item) => {
            prioritys.push({ label: item });
        });

        setPriorityData(prioritys)


    }, [])

    useEffect(() => {
        getWatchList().then(({ data: { hits: { hits } } }) => {
            hits && setWatchlistData(hits)
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [alertPopup])

    const renderModal = () => {
        return (
            <div className='p-4'>
                <div className='col-12 d-flex flex-row gap-3'>
                    <div className='col-8 d-flex flex-column gap-3 overflow-auto pr-3' style={{ height: "calc(100vh - 12rem)" }}>
                        <div className='col-12 d-flex flex-row gap-3'>
                            {/* <TableViewMore Icon={BookmarkSimple} size={24} dataToggle={"Add to watchlist"} dataPlacement={"bottom"} onClick={() => handleAddWatchList(selectedFieldName[0], "user_name")} /> */}
                            <div className='d-flex flex-fill flex-row alert alert-secondary mb-0 p-3 gap-3'>
                                <div className='p-4 rounded-circle d-flex align-items-center justify-content-center alert alert-warning mb-0' style={{ width: "80px", height: "80px" }}>
                                    <span>{profileLetter}</span>
                                </div>
                                <div className='flex-fill d-flex'>
                                    <div className="modal-row-item gap-3 col-12">
                                        <span className="modal-label">{selectedFieldName[1] === "user" ? "Username" : "Hostname"}</span>
                                        <span className="modal-text">
                                            {selectedFieldName[0]}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6 d-flex flex-row gap-3'>
                                {userDetectionCountLoader ? (<SmallCardLoader />) : (
                                    <div className='d-flex flex-fill flex-column alert alert-danger align-items-center justify-content-center mb-0'>
                                        <h4>{userDetectionCount ? userDetectionCount : '--'}</h4>
                                        <span>Detections</span>
                                    </div>
                                )}
                                {userActivitiesCountLoader ? (<SmallCardLoader />) : (
                                    <div className='d-flex flex-fill flex-column alert alert-info align-items-center justify-content-center mb-0'>
                                        <h4>{userActivitiesCount}</h4>
                                        <span>Activities</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex-fill d-flex flex-column rounded-3 p-4 gap-3 bg-white">
                            <div className='col-12 d-flex align-items-center justify-content-between'>
                                <div className='d-flex flex-column col-10'>
                                    <h5>File Operations</h5>
                                    {/* <p>The section presented on the dashboard provides a comprehensive summary of the file activities that have occurred within our organization. It offers an overview of various actions related to file management, tracking changes, and monitoring file usage. This includes activities such as file creations, modifications, and deletions, as well as sharing and collaboration activities among user.</p> */}
                                </div>
                            </div>

                            {userDetectionTypeLoader ? <PlainCardLoader /> : (
                                <>
                                    {recentFileOperationsCount &&
                                        <div className="col-12 d-flex flex-column gap-3 p-0 rounded-3 bg-white">
                                            <div className='col-12 d-flex flex-row gap-3'>
                                                <div className="col-6 flex-row gap-3 rounded-3 p-3 card border mb-0">
                                                    <div className='alert alert-success rounded-circle tile-icon'>
                                                        <FilePlus size={20} />
                                                    </div>
                                                    <div>
                                                        <h5>{recentFileOperationsCount.creation ? recentFileOperationsCount.creation : "--"}</h5>
                                                        <span>File Creations</span>
                                                    </div>
                                                </div>
                                                <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0">
                                                    <div className='alert alert-danger rounded-circle tile-icon'>
                                                        <FileX size={20} />
                                                    </div>
                                                    <div>
                                                        <h5>{recentFileOperationsCount.deletion ? recentFileOperationsCount.deletion : "--"}</h5>
                                                        <span>File Deletions</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 d-flex flex-row gap-3'>
                                                <div className="col-6 flex-row gap-3 rounded-3 p-3 card border mb-0">
                                                    <div className='alert alert-warning rounded-circle tile-icon'>
                                                        <FilePlus size={20} />
                                                    </div>
                                                    <div>
                                                        <h5>{recentFileOperationsCount.modification ? recentFileOperationsCount.modification : "--"}</h5>
                                                        <span>File Modifications</span>
                                                    </div>
                                                </div>
                                                <div className="flex-fill flex-row gap-3 rounded-3 p-3 card border mb-0">
                                                    <div className='alert alert-info rounded-circle tile-icon'>
                                                        <FilePlus size={20} />
                                                    </div>
                                                    <div>
                                                        <h5>{recentFileOperationsCount.rename ? recentFileOperationsCount.rename : "--"}</h5>
                                                        <span>File Rename</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 d-flex flex-row gap-3'>
                                                <div className="col-6 flex-row gap-3 rounded-3 p-3 card border mb-0">
                                                    <div className='alert alert-warning rounded-circle tile-icon'>
                                                        <FilePlus size={20} />
                                                    </div>
                                                    <div>
                                                        <h5>{recentFileOperationsCount.overwrite ? recentFileOperationsCount.overwrite : "--"}</h5>
                                                        <span>File Overwrites</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )}

                        </div>

                        <div className='d-flex flex-row flex-fill gap-3'>
                            <div className="flex-fill d-flex flex-column gap-3 overflow-auto border rounded-3">
                                <div className="flex-fill p-4 rounded-3 bg-white" >
                                    <div className='col-12 mb-3'>
                                        <h5>Recent Account Activities</h5>
                                    </div>
                                    {recentAccLoader ? (
                                        <PlainCardLoader />
                                    ) : (
                                        <>
                                            {accountActivity.length !== 0 ? (
                                                <div
                                                    className="d-flex flex-column gap-3 col-12 position-relative"
                                                    style={styles.sectionWrap1}
                                                >
                                                    {accountActivity?.map((item, i) => (
                                                        <div className='col-12 d-flex flex-row gap-3 p-3 rounded-3 border'>
                                                            {item?.type === 'account_created' &&
                                                                <>
                                                                    <div className='alert alert-success rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.target?.name?.charAt(0)}
                                                                    </div>

                                                                    <div>
                                                                        <p><strong>{item?._source?.user?.target?.name}</strong> created by <strong>{item?._source?.user?.name}</strong></p>
                                                                        {/* <p>{item?._source?.message}</p> */}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_deleted' &&
                                                                <>
                                                                    <div className='alert alert-danger rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.name?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        <p>The user account <strong>{item?._source?.user?.target?.name}</strong> was deleted by <strong>{item?._source?.user?.name}</strong></p>
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_changed' &&
                                                                <>
                                                                    <div className='alert alert-warning rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.name?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        {item?._source?.user?.target?.name === item?._source?.user?.name ?
                                                                            <p>The user account <strong>{item?._source?.user?.target?.name}</strong> was changed</p>
                                                                            :
                                                                            <p>The user account <strong>{item?._source?.user?.target?.name}</strong> was changed by <strong>{item?._source?.user?.name}</strong></p>}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'account_locked' &&
                                                                <>
                                                                    <div className='alert alert-warning rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.name?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        <p>The user account <strong>{item?._source?.user?.target?.name}</strong> was locked by <strong>{item?._source?.user?.name}</strong></p>
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {item?.type === 'password_change' &&
                                                                <>
                                                                    <div className='alert alert-info rounded-circle tile-icon text-uppercase'>
                                                                        {item?._source?.user?.name?.charAt(0)}
                                                                    </div>
                                                                    <div>
                                                                        {item?._source?.user?.target?.name === item?._source?.user?.name ?
                                                                            <p>Password of the user account <strong>{item?._source?.user?.target?.name}</strong> was changed</p>
                                                                            :
                                                                            <p>Password of the user account <strong>{item?._source?.user?.target?.name}</strong> was changed by <strong>{item?._source?.user?.name}</strong></p>}
                                                                        <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                                            <CalendarBlank />
                                                                            <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                            <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/* <div>
                                                         <h6>{item?._source?.user?.target?.name}</h6>
                                                         <p>{item?._source?.user?.name}</p>
                                                         <div className='d-flex flex-row gap-2 align-items-center modal-label'>
                                                             <CalendarBlank />
                                                             <span>{getDateFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                             <span>{"at " + getTimeFromTimestamp(item?._source?.["@timestamp"])}</span>
                                                         </div>
                                                     </div> */}
                                                            {/* <td>{item._source.host.name}</td> */}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                    <NoDataFound errorText={"No data found"} />
                                                </div>
                                            )}
                                        </>
                                    )}



                                </div>
                            </div>
                        </div>


                        {/* <div className='col-12 p-3 border rounded-3'>
                            <h5>File Activities</h5>
                            {userDetectionTypeLoader ? (
                                <PlainCardLoader width="200px" />
                            ) : (
                                <>
                                    {detectionTypeData.length > 0 ? (
                                        <Bar options={options} data={data} />
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>

                            )}

                        </div> */}

                        <div className='col-12 p-3 border rounded-3'>
                            <h5>Network</h5>
                            {userDetectionNetworkLoader ? (
                                <PlainCardLoader width="200px" />
                            ) : (
                                <>
                                    {networkTypeData.length > 0 ? (
                                        <Bar options={networdGraphOptions} data={networkGraphData} />
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>

                            )}

                        </div>


                        <div className='col-12 p-3 border rounded-3'>
                            <h5>Detection timeline by Log Source</h5>
                            {timeLineLogSourceLoader ? (
                                <PlainCardLoader width="200px" />
                            ) : (
                                <>
                                    {timeLineLogSourceData.length > 0 ? (
                                        <Line options={timelineLogSourceOptions} data={timelineLogSourceData} />
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className='col-12 p-3 border rounded-3'>
                            <h5>Detections by Severity</h5>
                            {timeLineLogSourceLoader ? (
                                <PlainCardLoader width="200px" />
                            ) : (
                                <>
                                    {detectionSeverityData.length > 0 ? (
                                        <Bar data={severityDetectionChartData} options={severityDetectionChartOptions} />
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>



                        {/* locations */}
                        <div className='col-12 p-3 border rounded-3'>
                            <h5>Recent Locations</h5>
                            {recentLocationLoader ? (
                                <PlainCardLoader width="200px" />
                            ) : (
                                <>
                                    {countryData?.length || countryLabel?.length !== 0 ? (
                                        <span className="modal-text">
                                            <Chart
                                                chartEvents={[
                                                    {
                                                        eventName: "select",
                                                        callback: ({ chartWrapper }) => {
                                                            const chart = chartWrapper.getChart();
                                                            const selection = chart.getSelection();
                                                            if (selection.length === 0) return;
                                                            const region = userLocationListData[selection[0].row + 1];
                                                            // MapClick("cybSourceCountry", "Source Country", region)
                                                        },
                                                    },
                                                ]}
                                                chartType="GeoChart"
                                                width="100%"
                                                height="300px"
                                                data={userLocationListData}
                                            />
                                        </span>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className='col-4'>
                        <div
                            className="d-flex col-12 h-100 position-relative"
                            style={styles.recentDetectionsWrap}
                        >
                            {detectionTimelineIsLoading ? (
                                <div className="col-12">
                                    <TimelineLoader />
                                </div>
                            ) : (
                                <div className='col-12 h-100'>
                                    <h5>Recent Detections</h5>
                                    {detectionData?.length !== 0 ? (
                                        <div className='col-12 h-100'>
                                            <ul className="timeline d-flex col-12 gap-3 flex-column pb-4">
                                                {detectionTimeline?.map((item) => (
                                                    <li
                                                        className="d-flex flex-row gap-3 justify-content-center position-relative"
                                                        style={styles.detectionItem}
                                                    >
                                                        <span className="dateLabel" style={{ left: 0, top: 0 }}>
                                                            {item[0][0]}
                                                        </span>
                                                        <div className="d-flex col-12 gap-3 flex-column px-3">
                                                            {item[0][1].map((item) => (
                                                                <Link style={{ textDecoration: 'none', color: globalStyles.black }} to="/ueba/summary" state={{ data: item }} >
                                                                    <p className="timeline-item p-3 rounded-3 cursor-pointer">
                                                                        {/* {escapeHtmlChars(item._source.description, /(<\/?b>)/g)} */}
                                                                        {rawHTML(item._source.description)}
                                                                    </p>
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    ) : (
                                        <div className='col-12 d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}

                                </div>

                            )}
                        </div>
                    </div>
                </div>

                <div className='row'>


                    {/* right */}
                    <div className="col-md-6">

                        {/* recent detection */}



                        {/* recent activities */}

                        {/* {detectionTableIsLoading ? (
                        <TableLoader rowCount={25} />
                    ) : (
                        <>
                            {userRecentActivitiesData.length !== 0 ? (
                                <Table borderless hover>
                                    <thead className="fixed-head">
                                        <tr>
                                            <th>Host Name</th>
                                            <th className="text-right">Time</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userRecentActivitiesData.map((item, index) => (
                                            <tr>
                                                <td>{item?._source?.agent?.hostname}</td>
                                                <td>{getDateFromTimestamp(item?._source["@timestamp"])}</td>
                                            </tr >
                                        ))}
                                    </tbody >
                                </Table >
                            ) : (
                                <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                    <NoDataFound errorText={"No data found"} />
                                </div>
                            )}
                        </>

                    )} */}
                    </div>
                </div>
            </div>

        )
    }

    // create group modal

    const renderCreateGroupModal = () => {
        return (
            createGroupModal ? (
                <div className="col-12 p-3 h-100">
                    <form className="h-100" onSubmit={handleGroupSubmit}>
                        <div className="row">
                            <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                                <div className="d-flex">
                                    <div className="col-md-6 col-xs-12 p-1">
                                        <label className="control-label">Group</label>
                                        <input type="text" className="form-control" id="group" name="group" value={groupFieldsData.group} onChange={handleGroupInputChange} required />

                                    </div>
                                    <div className="col-md-6 col-xs-12 p-1">
                                        <label className="control-label">Description</label>
                                        <input type="text" className="form-control" id="description" name="description" value={groupFieldsData.description} onChange={handleGroupInputChange} required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 py-3" style={styles.footerWrap}>
                            <div className="form-group d-flex justify-content-end" style={{ gap: "10px" }}>
                                <Button className="btn-warning" type="submit">
                                    Create group
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="col-12 p-3 h-100">
                    <form className="h-100" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                                <div className="d-flex">
                                    <div className="col-md-6 col-xs-12 p-1">
                                        <label className="control-label">User</label>
                                        <input type="text" className="form-control" id="username" name="user_name" defaultValue={createGroupData[0]?.name} onChange={handleInputChange} disabled />
                                    </div>
                                    <div className="col-md-6 col-xs-12 p-1">
                                        <label className="control-label">Group</label>
                                        {/* <input type="text" className="form-control" id="group" name="group" value={addToWatchlist.group} onChange={handleInputChange} /> */}
                                        <Select
                                            className="w-100"
                                            options={groupLists}
                                            onChange={handleOnDropDownGroupChange}
                                            defaultValue={groupLists[0]}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="col-md-6 col-xs-12 p-1">
                                        <label className="control-label">Type</label>
                                        <input type="text" className="form-control" id="type" name="type" defaultValue={createGroupData[0]?.field_type} onChange={handleInputChange} disabled />
                                    </div>

                                    <div className="flex-fill">
                                        <label className="control-label">Priority</label>
                                        <Select
                                            className="w-100"
                                            options={priorityData}
                                            onChange={handleonDropDownPriorityChange}
                                            defaultValue={priorityData[0]}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 py-3" style={styles.footerWrap}>
                            <div className="form-group d-flex justify-content-end" style={{ gap: "10px" }}>
                                <Button className="btn-primary" type="submit">
                                    Add to watchlist
                                </Button>

                                <Button className="btn-warning" onClick={() => setCreateGroupModal(!createGroupModal)}>
                                    Create group
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            )
        )
    }

    return (
        <div
            className="col-12 p-4 position-relative rounded-3"
            style={styles.section1}
        >
            {/* <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
                style={styles.filterWrap}
            >
            </div> */}
            <div className="detections col-12">
                <Tabs
                    defaultActiveKey="User"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="User" title="Users">
                        <div style={styles.tableWrap}>
                            <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                                <SearchBar setSearchData={setUserSearchList} listData={userListData} />
                            </div>
                            {userListLoader ? (
                                <TableLoader />
                            ) : (
                                <>
                                    {userSearchList?.length !== 0 ? (
                                        <div className="d-flex flex-row flex-wrap py-3">
                                            <>
                                                <Table borderless hover>
                                                    <thead className="fixed-head">
                                                        <tr>
                                                            <th>User</th>
                                                            <th>Add to Watchlist</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userSearchList?.map((item, index) => (
                                                            <tr>
                                                                <td onClick={() => handleOpenModal(item, true)}>
                                                                    {item?.key}
                                                                </td>
                                                                <td>
                                                                    <TableViewMore
                                                                        Icon={BookmarkSimple}
                                                                        size={24}
                                                                        dataToggle={"Add to watchlist"}
                                                                        dataPlacement={"bottom"}
                                                                        onClick={() => handleAddWatchList(item?.key, "user", true)} />
                                                                    {/* {watchlistData.some((data) => data._source.user_name === item?.key) ? (

                                                                        <TableViewMore
                                                                            Icon={BookmarkSimple}
                                                                            size={24}
                                                                            dataToggle={"Remove from watchlist"}
                                                                            dataPlacement={"bottom"}
                                                                            color={"orange"}
                                                                            onClick={() => handleRemoveFromWatchlist(item, "user_name")} />

                                                                    ) : (

                                                                        <TableViewMore
                                                                            Icon={BookmarkSimple}
                                                                            size={24}
                                                                            dataToggle={"Add to watchlist"}
                                                                            dataPlacement={"bottom"}
                                                                            onClick={() => handleAddWatchList(item?.key, "user", true)} />

                                                                    )} */}
                                                                </td>
                                                            </tr >
                                                        ))}
                                                    </tbody >
                                                </Table >
                                            </>
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>
                            )}

                        </div>
                    </Tab >

                    <Tab eventKey="Host" title="Hosts">
                        <div style={styles.tableWrap}>
                            <div style={{ position: "absolute", right: "20px", top: "10px" }}>
                                <SearchBar setSearchData={setHostSearchList} listData={hostListData} />
                            </div>
                            {hostListLoader ? (
                                <LoaderGrid rowCount={25} />
                            ) : (
                                <>
                                    {hostSearchList?.length !== 0 ? (
                                        <div className="d-flex flex-row flex-wrap py-3">
                                            <>
                                                <Table borderless hover>
                                                    <thead className="fixed-head">
                                                        <tr>
                                                            <th>Host</th>
                                                            <th>Add to Watchlist</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {hostSearchList.map((item, index) => (
                                                            <tr>
                                                                <td onClick={() => handleOpenModal(item, true, "host")}>
                                                                    {item?.key}
                                                                </td>
                                                                <td>
                                                                    <TableViewMore
                                                                        Icon={BookmarkSimple}
                                                                        size={24}
                                                                        dataToggle={"Add to watchlist"}
                                                                        dataPlacement={"bottom"}
                                                                        onClick={() => handleAddWatchList(item?.key, "host", true)} />

                                                                    {/* {watchlistData.some((data) => data._source.host_name === item?.key) ? (

                                                                        <TableViewMore
                                                                            Icon={BookmarkSimple}
                                                                            size={24}
                                                                            dataToggle={"Remove from watchlist"}
                                                                            dataPlacement={"bottom"}
                                                                            color={"orange"}
                                                                            onClick={() => handleRemoveFromWatchlist(item, "host_name")} />

                                                                    ) : (

                                                                        <TableViewMore
                                                                            Icon={BookmarkSimple}
                                                                            size={24}
                                                                            dataToggle={"Add to watchlist"}
                                                                            dataPlacement={"bottom"}
                                                                            onClick={() => handleAddWatchList(item?.key, "host", true)} />

                                                                    )} */}
                                                                </td>
                                                            </tr >
                                                        ))}
                                                    </tbody >
                                                </Table >
                                            </>
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                            <NoDataFound errorText={"No data found"} />
                                        </div>
                                    )}
                                </>)}

                        </div>
                    </Tab>
                </Tabs>

                <CustomModal
                    title={"Details"}
                    ShowOrHide={modalVisibility}
                    modalSize={"xl"}
                    bodyData={renderModal}
                    handleClose={handleCloseModal}
                    footer={false}
                    autoHeight
                ></CustomModal>

                {detectionTimelineVisible && (
                    <CustomModal
                        title={"Detection summary"}
                        ShowOrHide={detectionTimelineVisible}
                        bodyData={renderDetectionSummaryModal}
                        handleClose={handleDetectionSummaryCloseModal}
                        footer={false}
                        noBodyPadding
                        hasStickyTab
                    ></CustomModal>
                )}


                <CustomModal
                    title={"Add to watchlist"}
                    ShowOrHide={createGroup}
                    bodyData={renderCreateGroupModal}
                    handleClose={handleCloseModal}
                    footer={false}
                    autoHeight
                ></CustomModal>

            </div >

            {alertPopup && (
                <AlertBox
                    setAlertPopup={setAlertPopup}
                    alertPopup={alertPopup}
                    message={alertMessage}
                    close={handleClosePopup}
                    setOutTime={5000}
                    bgColor={"lightGreen"}
                />
            )}
        </div>

    )
}

export default UserProfiling
