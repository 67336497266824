import React, { useEffect, useState } from 'react'
import axios from '../../axios';
import { fget } from '../../axios/axios'
import { licenseInfo } from '../../Methods/Methods'
import { Tab, Tabs } from 'react-bootstrap'
import styles from './Settings.styles'
import { getDateFromTimestamp } from '../../Utils/Utils'
import { fpostC } from '../../axios/axios'
const LicenceInfo = () => {

    let baseURL = localStorage.getItem("CYBOT_URL")

    const [licenceInfoData, setLicenceInfoData] = useState([])
    const [accessModule, setAccessModule] = useState([])

    useEffect(() => {
        let data = {
            "query": {
                "match_all": {}
            }
        }
        fpostC(`${baseURL}/cybot_proxy/thp_license*/_search`, data).then(({ data: { hits: { hits } } }) => {
            let licence = []
            hits?.map((item) => {
                licence.push({ startData: item?._source.start_date, endDate: item?._source.end_date, tenantId: item?._source.tenant_id, organizationName: item?._source.organization_name ,license_type: item?._source.license_type})
            })
            setLicenceInfoData(licence)
        }).catch((err) => {
            console.log("Error", err)
        })

        // module access

        licenseInfo().then(({ data }) => {
            data && setAccessModule(data)
        }).catch((err) => {
            console.log("Error", err)
        })
    }, [])

    return (
        <div className="col-12 p-4 position-relative rounded-3" style={styles.section1}>
            <Tabs
                defaultActiveKey="licence_details"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="licence_details" title="Licence Details">
                    {/* {console.log('licenceInfoData', licenceInfoData)} */}
                    <div className="col-12" style={styles.tableWrap}>
                        <div className="col-12 px-3 d-flex flex-column gap-3">
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label col-2">Start Date</label>
                                {licenceInfoData[0]?.startData ? (
                                    <span>{getDateFromTimestamp(licenceInfoData[0]?.startData)}</span>
                                ) : (
                                    <span>Fetching license information...</span>
                                )}
                            </div>
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label col-2">End Date</label>
                                {licenceInfoData[0]?.endDate ? (
                                    <span>{getDateFromTimestamp(licenceInfoData[0]?.endDate)}</span>
                                ) : (
                                    <span>Fetching license information...</span>
                                )}

                            </div>
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label col-2">Tenant ID</label>
                                {licenceInfoData[0]?.tenantId ? (
                                    <span>{licenceInfoData[0]?.tenantId}</span>
                                ) : (
                                    <span>Fetching license information...</span>
                                )}
                            </div>
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label col-2">Organization Name</label>
                                {licenceInfoData[0]?.organizationName ? (
                                    <span>{licenceInfoData[0]?.organizationName}</span>
                                ) : (
                                    <span>Fetching license information...</span>
                                )}
                            </div>
                            <div className="col-md-12 col-xs-12 p-1">
                                <label className="control-label col-2">License Type</label>
                                {licenceInfoData[0]?.license_type ? (
                                    <span>{licenceInfoData[0]?.license_type}</span>
                                ) : (
                                    <span>Fetching license information...</span>
                                )}
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="module_access" title="Module Access">
                    <div className="col-12" style={styles.tableWrap}>
                        <div className="col-12 px-3 d-flex flex-column gap-3">
                            <div className="col-12">
                                <label className="control-label col-2">Analytics</label>
                                <span className={accessModule.analytics ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.analytics === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Compliance</label>
                                <span className={accessModule.compliance ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.compliance === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Hunter</label>
                                <span className={accessModule.hunter ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.hunter === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Investigate</label>
                                <span className={accessModule.investigate ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.investigate === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Rules Studio</label>
                                <span className={accessModule.rulesstudio ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.rulesstudio === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Rules Studio Admin</label>
                                <span className={accessModule.rulesstudioadmin ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.rulesstudioadmin === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">Threat Intelligence</label>
                                <span className={accessModule.ti ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.ti === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="col-12">
                                <label className="control-label col-2">UEBA</label>
                                <span className={accessModule.ueba ? "alert alert-success px-2 py-1" : "alert alert-dangerpx-2 py-1"}>{accessModule.ueba === "true" ? "Enabled" : "Disabled"}</span>
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div >
    )
}

export default LicenceInfo