import React from "react";

const VirusTotalSummary = ({ data }) => {
  return (
    <div className="col-12">
      {/* <span>Virus Total</span> */}
      {data?.map((item) => (
        <div>
          <div>
            <h6>{item.name}</h6>
          </div>
          <div className="d-flex flex-row gap-3 mb-0">
            <div className="flex-fill alert alert-danger mb-0 text-center">
              <h5>{item.malicious}</h5>
              <span>Malicious</span>
            </div>
            <div className="flex-fill alert alert-warning mb-0 text-center">
              <h5>{item.suspicious}</h5>
              <span>Suspicious</span>
            </div>
            <div className="flex-fill alert alert-success mb-0 text-center">
              <h5>{item.harmless}</h5>
              <span>Harmless</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VirusTotalSummary;
