import React from "react";
import { ArrowLeft, ArrowRight, X } from "phosphor-react";
import globalStyles from "../../constants/globalStyles";
import { Page } from "@react-pdf/renderer";
import DetailPage from "../../pages/TI/Activebytes/Tabs/DetailPage";

const Pagination = ({
    page,
    setPage,
    perPage,
    currentPageCount,
    totalDataCounts
}) => {

  



    return (



        <nav aria-label="Page navigation example" className="mx-2">
            <ul className="pagination mb-0">
                <li
                    onClick={page <= 1 ? null : () => setPage(page - 1)}
                    // className="page-item"
                    className={page <= 1 ? "disabled page-item" : "page-item"}
                    disabled={page <= 0 && setPage(1)}
                >
                    <a
                        className="page-link d-flex justify-content-center align-items-center h-100"
                        href="#"
                    >
                        <ArrowLeft />
                    </a>
                </li>

                <div className="active-count d-flex flex-row px-3 gap-1 align-items-center">
                    {totalDataCounts > 0 ?
                        <>
                            <span
                                style={{
                                    fontWeight: "600",
                                    display: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                    // height: "100%",
                                }}
                            >


                                { }
                                {(page - 1) * perPage + 1} - {totalDataCounts / perPage > page ? currentPageCount * page : totalDataCounts}

                            </span>



                            <span style={{ color: globalStyles.greyDark3 }}>{totalDataCounts ? " of " + totalDataCounts : ""}</span>
                        </>
                        : <span style={{ color: globalStyles.greyDark3 }}>No data</span>}
                </div>



                <li
                    onClick={totalDataCounts / perPage > page ? () => setPage(page + 1) : null}

                >
                    <a
                        className="page-link d-flex justify-content-center align-items-center h-100"
                        href="#"
                    >
                        <ArrowRight />
                    </a>
                </li>

            </ul>
        </nav>

    );
};

export default Pagination;
