// CATALOGUE
export const RT_COR_LIST_CAT_REQUEST = "RT_COR_LIST_CAT_REQUEST";
export const RT_COR_LIST_CAT_SUCCESS = "RT_COR_LIST_CAT_SUCCESS";
export const RT_COR_LIST_CAT_FAIL = "RT_COR_LIST_CAT_FAIL";


  // ADD TO CAT CORRELATION
  export const RT_COR_ADD_CATALOGUE_REQUEST = "RT_COR_ADD_CATALOGUE_REQUEST";
  export const RT_COR_ADD_CATALOGUE_SUCCESS = "RT_COR_ADD_CATALOGUE_SUCCESS";
  export const RT_COR_ADD_CATALOGUE_FAIL = "RT_COR_ADD_CATALOGUE_FAIL";
  
// // CORRELATION - REMOVE 
export const RT_COR_REMOVE_CATALOGUE_REQUEST = "RT_COR_REMOVE_CATALOGUE_REQUEST";
export const RT_COR_REMOVE_CATALOGUE_SUCCESS = "RT_COR_REMOVE_CATALOGUE_SUCCESS";
export const RT_COR_REMOVE_CATALOGUE_FAIL = "RT_COR_REMOVE_CATALOGUE_FAIL";

// // CORRELATION - LIST
export const RT_COR_LIST_MASTER_REQUEST = "RT_COR_LIST_MASTER_REQUEST";
export const RT_COR_LIST_MASTER_SUCCESS = "RT_COR_LIST_MASTER_SUCCESS";
export const RT_COR_LIST_MASTER_FAIL = "RT_COR_LIST_MASTER_FAIL";


// // CORRELATION - LIST - CUSTOM
export const RT_COR_LIST_CUSTOM_MASTER_REQUEST = "RT_COR_LIST_CUSTOM_MASTER_REQUEST";
export const RT_COR_LIST_CUSTOM_SUCCESS = "RT_COR_LIST_CUSTOM_SUCCESS";
export const RT_COR_LIST_CUSTOM_FAIL = "RT_COR_LIST_CUSTOM_FAIL";

// // CORRELATION - CUSTOM - CREATE 
export const RT_COR_CUSTOM_MASTER_REQUEST = "RT_COR_CUSTOM_MASTER_REQUEST";
export const RT_COR_CUSTOM_MASTER_SUCCESS_FALSE = "RT_COR_CUSTOM_MASTER_SUCCESS_FALSE";
export const RT_COR_MASTER_SUCCESS = "RT_COR_MASTER_SUCCESS";
export const RT_COR_MASTER_FAIL = "RT_COR_MASTER_FAIL";

// CORRELATION CUSTOM DETAILS  
export const RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST = "RT_COR_CUSTOM_MASTER_SINGLE_LIST_REQUEST";
export const RT_COR_MASTER_SINGLE_LIST_SUCCESS = "RT_COR_MASTER_SINGLE_LIST_SUCCESS";
export const RT_COR_MASTER_SINGLE_LIST_FAIL = "RT_COR_MASTER_SINGLE_LIST_FAIL";


// CORRELATION CUSTOM DETAILS QUERY 
export const RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST = "RT_COR_CUSTOM_MASTER_SINGLE_LIST_QUERY_REQUEST";
export const RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS = "RT_COR_MASTER_SINGLE_LIST_QUERY_SUCCESS";
export const RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL = "RT_COR_MASTER_SINGLE_LIST_QUERY_FAIL";
