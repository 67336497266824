import React from 'react'
import { PDFDownloadLink, Document, Page, View, Text, Image, Font } from "@react-pdf/renderer";
import { Button } from 'react-bootstrap';
import { escapeHtmlChars, getDateFromTimestamp, getTimeFromTimestamp, timestampConvert } from '../../../Utils/Utils';
import { anomaliesDataList } from '../../UEBA/AnomaliesList.js'
import Header from '../Header';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from "moment/moment.js";
import font from "./NotoSansArabic-Regular.ttf"
Font.register({ family: 'Noto Sans Arabic', src: font });
const styles = {
    page: { padding: "20px", fontFamily: "Noto Sans Arabic" },
    container: { flexDirection: "row", marginTop: "5px" },
    column1: { flexBasis: "30%", padding: "5px", fontSize: "10px", color: "#999999", marginLeft: "5px" }, // Column 1 (4 parts out of 12)
    column2: { flexBasis: "70%", padding: "5px", fontSize: "10px", fontWeight: "500", color: "#494545" }, // Column 2 (8 parts out of 12)
    comment: { padding: "7px", border: '0.5px solid  #999999', marginTop: "3px", marginRight: "10px", borderRadius: '3px', lineHeight: "1.3" },
    link: { padding: "2px", marginTop: "3px", },
    table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 0.5, marginTop: "10px" },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableCell: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '80%', fontSize: "10px" },
    tableCell1: { margin: 'auto', marginTop: 5, marginBottom: 5, paddingLeft: 8, paddingRight: 8, width: '20%', fontSize: "10px" },
    tableHeader: { width: '80%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    tableHeader1: { width: '20%', backgroundColor: '#eaeaea', fontSize: "10px", fontWeight: 'bold', padding: 5 },
    imageDiv: { justifyContent: 'center', },
    setBorder: { border: "1px solid black" },
    image: {
        width: '80%', // Adjust the width as needed
        height: 'auto', // Maintain aspect ratio
        justifyContent: 'center',
        marginLeft: "10%",
        marginTop: "10px"
    },
    title: { fontSize: "16px", fontWeight: "600", padding: "5px" },
    subTitle: { fontSize: "13px", fontWeight: "600", padding: "5px" }
};
 
const UebaDetectionExport = ({ detectionData, anomaliesData, imageBlob, detectionOverview }) => {
 
 
    const [imageSrc, setImageSrc] = useState(null);
 
    useEffect(() => {
        if (imageBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(imageBlob);
        }
    }, [imageBlob]);
 
    const brakeLines = (val) => {
 
        const perRowString = 80
        const stringLength = val.length;
        const rows = Math.ceil(stringLength / perRowString)
 
 
        const data = [];
        for (let i = 0; i < rows; i++) {
            data.push(<Text>{val.slice(i * perRowString, (i + 1) * perRowString)}</Text>);
        }
        return data;
 
    }
 
    const removeHtmlTags = (text) => {
        return text
            ?.replace(/<p>/g, '\n')
            .replace(/<\/p>/g, '')
            .replace(/<span.*?color:\s*(.*?);.*?>/g, (match, color) => {
                return `${color}]`;
            })
            .replace(/<\/span>/g, '')
            .replace(/<span>/g, ' ')
            .replace(/<br>/g, '\n')
            .replace(/&nbsp;/g, ' ')
            .replace(/<strong>/g, '')
            .replace(/<\/strong>/g, ' ');
    };
 
    return (
 
        <Document>
            <Page style={{ padding: "20px" }}>
                <Header />
 
                <View>
                    <Text style={styles.title}>Overview</Text>
                </View>
 
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>User</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{detectionOverview?._source?.user_name}</Text>
                    </View>
                </View>
 
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Host</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{detectionOverview?._source?.host_name}</Text>
                    </View>
                </View>
 
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Description</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{escapeHtmlChars(detectionOverview?._source?.description, /<\/?b>/gi)}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Detection Time</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{moment(detectionOverview?._source?.detection_timestamp).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Detection ID</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{detectionOverview?._source?.group_id}</Text>
                    </View>
                </View>
 
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Detection Status</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{detectionOverview?._source?.detection_status === 'new' || detectionOverview?._source?.detection_status === '' || detectionOverview?._source?.detection_status === 'open' ? "Open" : detectionOverview?._source?.detection_status}</Text>
                    </View>
                </View>
                {console.log(detectionOverview?._source?.detection_status)}
                <View style={styles.container}>
                    <View style={styles.column1}>
                        {/* Content for Column 1 */}
                        <Text>Severity</Text>
                    </View>
                    <View style={styles.column2}>
                        {/* Content for Column 2 */}
                        <Text>{detectionOverview?._source?.severity}</Text>
                    </View>
                </View>
                {detectionOverview?._source?.detection_status === "closed" ?
                    <>
                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>Closed By</Text>
                            </View>
                            <View style={styles.column2}>
                                {/* Content for Column 2 */}
                                <Text>{detectionOverview?._source?.closed_by}</Text>
                            </View>
                        </View>
 
                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>Closed At</Text>
                            </View>
                            <View style={styles.column2}>
                                {/* Content for Column 2 */}
                                <Text>{timestampConvert(detectionOverview?._source?.closed_at, 'MMMM Do YYYY, h:mm:ss a')}</Text>
                            </View>
                        </View>
 
                        <View style={styles.container}>
                            <View style={styles.column1}>
                                {/* Content for Column 1 */}
                                <Text>Comment</Text>
                            </View>
                            <View style={styles.column2}>
                                {/* Content for Column 2 */}
                                <Text>{removeHtmlTags(detectionOverview?._source?.comment)}</Text>
                            </View>
                        </View>
                    </>
                    : ""}
 
                <View style={styles.container}>
                    <Image src={imageSrc} style={styles.image} />
                </View>
 
 
 
            </Page>
 
 
 
            {anomaliesData?.map((item, index) => (
                <Page style={styles.page}>
                    {index === 0 &&
                        <View style={{ marginTop: "20px" }}>
                            <Text style={styles.title}>Anomalies Detected</Text>
                        </View>}
                    <View>
                        <View style={styles.container}>
                            {/* <View style={styles.column2}>
                                <Text style={styles.subTitle}>{`${moment.utc(item.detection_timestamp).format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                            </View> */}
 
                            <View style={styles.column2}>
                                <Text style={styles.subTitle}>{item.descriptionSummary}</Text>
                            </View>
                            {item?.details?.event_category === "network" && (
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Event : {item?.details?.event_action} from src - {item?.details?.source_ip} : {item?.details?.source_port} to dest - {item?.details?.dest_ip} : {item?.details?.dest_port}</Text>
                                </View>
                            )}
 
                            {/* {item?.details?.event_category === "file" && (
                                <View style={styles.column2}>
                                    <Text style={styles.subTitle}>Full file path : {item.details.file_path}</Text>
                                </View>
                            )} */}
                        </View>
                    </View>
 
                    <View style={{ border: "1px solid #cccccc" }}>
                        {anomaliesDataList.map((ListItem) => (
                            ListItem.key in item.details && (
                                <View style={styles.container}>
                                    <View style={styles.column1}>
                                        <Text>{ListItem?.label}</Text>
                                    </View>
                                    <View style={styles.column2}>
 
                                        {brakeLines(ListItem?.label === 'Detection date' ? getDateFromTimestamp(item?.details[ListItem?.key]) : ListItem?.label === 'Detection time' ? getTimeFromTimestamp(item?.details[ListItem?.key]) : item?.details[ListItem?.key])}
                                        {/* <Text>{}</Text> */}
                                    </View>
                                </View>
                            )
                        ))}
                    </View>
                </Page>
            ))}
 
        </Document>
    )
}
 
export default UebaDetectionExport