const styles = {
  section1: {
    height: "calc(100vh - 18em)",
    // height: "calc(85vh - 11em)",
    paddingRight: "1rem",
    overflow: "auto"
  },
  overviewWrap: {
    height: "calc(100vh - 8em)",
    overflow: "auto",
    // paddingRight: "1rem"
  },
  overviewTile: {
    // backgroundColor: "#f1f1f1",
  },
  tile: {
    backgroundColor: "#f1f1f1",
  },
  paginationWrap: {
    alignItems: "center",
    justifyContent: "flex-end",
    height: "50px",
    // backgroundColor: "#CCCCCC",
    position: "absolute",
    bottom: "1em",
    right: "0px"
  },
  huntStep: {
    width: "30px",
    height: "30px",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    marginBottom: "0px"
  },
  tableWrap: {
    height: "calc(100vh - 16em)",
    overflow: "auto"
  },
  recentDetectionsWrap: {
    height: "calc(100vh - 15.5rem)",
    // height: "400px",
    overflow: "auto",
    paddingRight: "1rem",
  },
  detectionItem: {},

};

export default styles;
