import moment from "moment";
import { X } from "phosphor-react";
import { getTimeFromTimestamp } from "../../Utils/Utils";
import { Button, Form } from "react-bootstrap";
import { fget, fpost } from "../../axios/axios";
import { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import AlertBox from "../AlertBox/AlertBox";

const FilterList = ({
  filter,
  setFilter,
  dateFilter,
  setDateFilter,
  getFilteredTable,
  module,
  tab,
  multi,
  tabList
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [saveFilterName, setSaveFilterName] = useState("");
  const [saveFilterDesc, setSaveFilterDesc] = useState("");
  const [isSaveFilterLoading, setIsSaveFilterLoading] = useState(false);
  // alert popup
  const [alertPopup, setAlertPopup] = useState(false);

  const totalFilterLength = (dateFilter?.to ? 1 : 0) + filter?.length;
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")

  const saveCustomFilter = async (e) => {
    e.preventDefault();
    setIsSaveFilterLoading(true);

    const data = {
      name: saveFilterName || "",
      module: module,
      tab: tab,
      description: saveFilterDesc || "",
      filter: filter,
    };
    console.log("data", data, filter);
    try {
      const response = await fpost(
        `${apiBaseUrl}/filter/tenant`,
        data
      );
      console.log("response", response);
      setIsSaveFilterLoading(false);
      setAlertPopup(true);
      setOpenModal(false);
      setSaveFilterName("")
      setSaveFilterDesc("")

      try {
        await fget(
          `${apiBaseUrl}/filter/tenant/refresh`
        );
      } catch (error) {
        console.log("refresh error", error);
      }
    } catch (error) {
      setIsSaveFilterLoading(false);

      console.log("save error", error);
    }
  };

  const renderModal = () => (
    <div className="col-12 p-3">
      <Form onSubmit={saveCustomFilter}>
        <Form.Control
          type="text"
          placeholder="Name"
          value={saveFilterName}
          onChange={(e) => setSaveFilterName(e.target.value)}
          required
        />
        <textarea
          rows="5"
          className="form-control mt-3"
          placeholder="Description"
          value={saveFilterDesc}
          onChange={(e) => setSaveFilterDesc(e.target.value)}
        />
        {/* <Form.Control
        type="text"
        placeholder="Description"
        className="mt-2"
        value={saveFilterDesc}
        onChange={(e) => setSaveFilterDesc(e.target.value)}
      /> */}
        <div className="col-12 mt-3 d-flex justify-content-end">
          <Button
            type="submit"
            className=""
          // onClick={updateNotifications}
          >
            {isSaveFilterLoading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-row flex-wrap gap-2 mb-2">
        {dateFilter?.to && (
          <div className="d-flex flex-row gap-1 rounded-2 px-2 filter-item align-items-center">
            <span className="px-2 py-1">
              {moment(dateFilter?.from).format("DD MMM yyyy")},{" "}
              {getTimeFromTimestamp(dateFilter?.from)} -{" "}
              {moment(dateFilter?.to).format("DD MMM yyyy")},{" "}
              {getTimeFromTimestamp(dateFilter?.to)}
            </span>
            <X
              onClick={() => {
                let storedFilter = JSON.parse(localStorage.getItem("filters"));
                let temp;
                if(multi){
                  temp = tabList.reduce((acc, tab) => {
                    let fields = acc?.[module]?.[tab]?.fields || [];
                    return {
                      ...acc,
                      [module]: {
                        ...acc?.[module],
                        [tab]: { date: { from: "", to: "" }, fields: fields },
                      },
                    };
                  }, storedFilter);
                }
                else{
                  temp = {
                    ...storedFilter,
                    [module]: {
                      ...storedFilter?.[module],
                      [tab]: { date: { from: "", to: "" }, fields: [...filter] },
                    },
                  };
                }
                
                setDateFilter({});
                localStorage.setItem("filters", JSON.stringify(temp));

                getFilteredTable(filter);
              }}
              weight="bold"
              size={10}
              className="mx-2"
              type="button"
            />
          </div>
        )}
        {filter?.map((item, index) => (
          <>
            <div className="d-flex flex-row gap-1 rounded-2 px-2 filter-item">
              <div className="d-flex flex-row gap-1 p-2">
                <span>{item.column.label}</span>
                <span className="px-2 rounded-1 text-white bg-info">
                  {item.type === "gt" || item.type === "lt" ? (item.type === "gt" ? "Greater Than" : "Less Than") : item.type}
                </span>
                <span>{item.selectedValue}</span>
              </div>
              <div
                onClick={() => {
                  let storedFilter = JSON.parse(
                    localStorage.getItem("filters")
                  );

                  let temp = filter?.filter((item, i) => i !== index);
                  setFilter(temp);

                  let temp2 = {
                    ...storedFilter,
                    [module]: {
                      ...storedFilter?.[module],
                      [tab]: { date: { ...dateFilter }, fields: [...temp] },
                    },
                  };

                  localStorage.setItem("filters", JSON.stringify(temp2));

                  if (temp.length) {
                    getFilteredTable(temp, dateFilter);
                  } else if (dateFilter?.to) {
                    getFilteredTable(temp, dateFilter);
                  } else {
                    getFilteredTable();
                  }
                }}
                type="button"
                className="p-2"
              >
                <X weight="bold" size={10} />
              </div>
            </div>
          </>
        ))}
        {filter?.length ? (
          <Button onClick={() => setOpenModal(true)}>Save</Button>
        ) : (
          ""
        )}
        {totalFilterLength > 1 && (
          <Button
            onClick={() => {
              let storedFilter = JSON.parse(localStorage.getItem("filters"));

              let temp = {
                ...storedFilter,
                [module]: {
                  ...storedFilter?.[module],
                  [tab]: { date: { from: "", to: "" }, fields: [] },
                },
              };
              localStorage.setItem("filters", JSON.stringify(temp));

              setFilter([]);
              setDateFilter({});
            }}
            variant="secondary"
          >
            Clear All
          </Button>
        )}
      </div>
      <CustomModal
        title={"Save Filter"}
        ShowOrHide={openModal}
        bodyData={renderModal}
        handleClose={() => {
          setOpenModal(false);
          setAlertPopup(false);
        }}
        footer={false}
        modalSize="sm"
        autoHeight={true}
      />
      {alertPopup && (
        <AlertBox
          setAlertPopup={setAlertPopup}
          alertPopup={alertPopup}
          message={"Filter Saved"}
          close={() => setAlertPopup(false)}
          setOutTime={5000}
          bgColor={"lightGreen"}
        />
      )}
    </>
  );
};

export default FilterList;
